'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldUrlMixin = require('@rubyapps/ruby-component-mixin-field-url');

var RCHidden = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldUrlMixin],
    propTypes: {
        reactProps: PropTypes.object
        //, label: PropTypes.string
        //, key: PropTypes.string
        //, constraints: PropTypes.object
        //, options: [
        //      //# like dropdown
        //      {
        //      
        //      }
        //  ]
        , options: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
        }))
    },
    getDefaultProps: function getDefaultProps() {
        return {
            listerConfig: {
                excludeFromColumnSelection: true,
                excludeFromFilterSelection: true
            }
        };
    },
    componentName: componentName,
    CONSTANTS: CONSTANTS,

    _formValueFromLocalState: function _formValueFromLocalState(selfState, isError) {
        var predicateFormatter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (value) {
            return value;
        };
        var entireState = arguments[3];
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        var baseFormValue = baseFieldMixin._formValueFromLocalState.apply(this, arguments);
        var key = this.props.key;
        var hydratedProps = this.getProps(true);

        return _extends({}, baseFormValue, hydratedProps.hasOwnProperty('value') ? _defineProperty({}, key, hydratedProps.value) : {});
    }
    /* 
    //# DEPRECATED 20180913 - was used by overriding formValueFromFieldValue_forKey()
    //# but we should defer responsibility to the baseFieldMixin
    , _getNumberFromValue: function (value) {
        // If its truly not a number
        // then pass string to server and let server complain
        // as opposed to passing null or 0 and letting the server save that value
        return value === ""                ? null
             : _.isNaN(parseInt(value,10)) ? value
             :                               parseInt(value, 10)
             ;
    }
    */
});

module.exports = RCHidden;