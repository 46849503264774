'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

function curriedSelfModule_forFormatRequest(selfModule) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        listerConfig = _ref.listerConfig,
        _ref$additionalQueryP = _ref.additionalQueryParams,
        additionalQueryParams = _ref$additionalQueryP === undefined ? {} : _ref$additionalQueryP;

    var _selfModule$getActive = selfModule.getActiveRubyClientInfo(),
        rubyClientId = _selfModule$getActive.client,
        subsiteId = _selfModule$getActive.subsite;

    return function (req, tableState, store) {
        var griddleState = store;

        var _ref2 = selfModule.getProps ? selfModule.getProps() : selfModule.props,
            staticFilterTags = _ref2.staticFilterTags,
            internalFilterTags = _ref2.internalFilterTags,
            _ref2$internalFieldsQ = _ref2.internalFieldsQuery,
            internalFieldsQuery = _ref2$internalFieldsQ === undefined ? {
            fields: [],
            expand: []
        } : _ref2$internalFieldsQ,
            props__listerConfig = _ref2.listerConfig,
            _ref2$templateKey = _ref2.templateKey,
            templateKey = _ref2$templateKey === undefined ? selfModule.getState().routeParams.template : _ref2$templateKey;

        listerConfig = listerConfig || props__listerConfig;

        var page = tableState.page,
            pageSize = tableState.pageSize,
            filter = tableState.filter;


        var filterAsObject = _.isString(filter) ? { filterTags: [], queryString: filter } : _extends({}, filter);

        //# include staticFilterTags and internalFilterTags in an adjacent where filter
        var filterAsObjectArray = [filterAsObject, internalFilterTags && internalFilterTags.length ? { filterTags: internalFilterTags } : undefined, staticFilterTags && staticFilterTags.length ? { filterTags: staticFilterTags } : undefined].filter(function (n) {
            return n;
        });

        var sortColumnsFromTableState = _.get(tableState, 'sortColumn');
        var sortDirectionFromTableState = _.get(tableState, 'sortDirection');
        var sortDirection = sortDirectionFromTableState;

        var sortDict = selfModule.utils.getSnameAndSdirAsDictFromSortColumns_andSortDir_usingGriddleStore(sortColumnsFromTableState, sortDirectionFromTableState, store);

        var columns = selfModule.utils.getColumnsFromGriddleState_defaultingToColumns(store, listerConfig.columns);
        var fieldsQueryObject = selfModule.utils.formatRequestPropsForColumns(columns);

        var allWhereFilters = filterAsObjectArray.map(function (filterObject) {
            return selfModule.utils.savedSearchToWhereFilter(filterObject, templateKey);
        }).filter(function (n) {
            return n && _.values(n).filter(function (o) {
                return o !== undefined;
            }).length;
        })
        //# NOTE: {key: undefined} gets translated as {} when calling on JSON.stringify
        //# We could also be explicit and perform a _.mapValue() and translate undefined to neq: [] or neq: null
        //# NOTE that we need to allow null's through though
        ;

        //# == Handling Custom Filters ===========================================//
        //# NOTE: for now we'll do this
        //# but eventually we want to fold this into the 
        //# filter.where query param
        var utilityBarState = griddleState.utilityBarProperties || {};
        var customFilters = _.get(listerConfig, 'filters', []);
        var customFiltersState = _.get(utilityBarState, 'customFilters');
        var defaultQuery = _.get(selfModule, 'props.listerConfig.defaultQuery') || {};
        var filterQuery = _.reduce(customFilters, function (result, filterSpec, idx) {
            var _ref4;

            var filterName = filterSpec.name || filterSpec.key;
            var filterKey = filterSpec.key || filterSpec.name;
            var filterValue = _.get(customFiltersState, [filterKey] //[filterName]
            , filterSpec.defaultValue);
            return filterValue ? _extends({}, result, filterSpec.isPlainArg ? _defineProperty({}, filterKey, filterValue) : (_ref4 = {}, _defineProperty(_ref4, 'filterType' + idx, filterKey), _defineProperty(_ref4, 'filterVal' + idx, filterValue), _ref4)) : result;
        }, {});

        var whereFilter = allWhereFilters.length > 1 ? {
            and: allWhereFilters
        } : allWhereFilters[0];

        //# NOTE: if we need to, we'll add a query modifier middleware to allow listers to modify the resultant queries
        var baseQuery = _extends({
            p: page - 1,
            psize: pageSize,
            search: filterAsObject.queryString,
            fields: _.uniq(fieldsQueryObject.fields.concat(internalFieldsQuery.fields))
            //# NOTE: fields can be keypaths, the server only uses the root key when deferring to loopback to retrieve specific fields
            , expand: _.uniq((fieldsQueryObject.expand || []).concat(internalFieldsQuery.expand || []))
            //# TODO: expand should contain the dataPath because the server needs the keypath to the profile selector,
            //# and the hydfration needs the last key in the path
            , sname: sortDict.sname,
            sdir: sortDict.sdir,
            ruby_client_fk: rubyClientId,
            content_subsite_fk: subsiteId,
            'filter.where': whereFilter ? JSON.stringify(whereFilter) : undefined
        }, additionalQueryParams);

        req = req.query(_extends({}, baseQuery, defaultQuery, filterQuery));

        return req;
    };
}

function formatSuccess(resp, tableState) {
    var total = resp.total;
    return {
        page: tableState.page,
        maxPage: parseInt(total / tableState.pageSize, 10) + (total % tableState.pageSize ? 1 : 0),
        totalCount: total,
        data: resp.data
    };
}

module.exports = {
    curriedSelfModule_forFormatRequest: curriedSelfModule_forFormatRequest,
    formatSuccess: formatSuccess
};