'use strict';

//== The Middleware ========================================================//

var middlewareInitializer = function middlewareInitializer(store) {
    var selfModule = this;
    var selfAction = selfModule.getAction();

    return function (next) {
        return function (action) {
            var nextRetVal = next(action); //# still allow @@router to continue propagation

            var actionTypeComponents = action.type.split('/');

            switch (actionTypeComponents[actionTypeComponents.length - 1]) {
                //# if we saved the user edit page or a role page, we want to immediately pull in new user info
                //# if case the permissions changed
                case 'FORM_SAVE_SUCCESSFUL':
                    var selfState = selfModule.getState();
                    var _action$payload = action.payload,
                        modelKey = _action$payload.modelKey,
                        pageId = _action$payload.pageId;


                    if (modelKey == 'user' && pageId == selfState.id || modelKey == 'RubyRole') {
                        //# pull new data from /me
                        store.dispatch(selfAction.generators.retrieveLoggedInUser());
                    }

                    break;
            }
            return nextRetVal;
        };
    };
};

module.exports = middlewareInitializer;