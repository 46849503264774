'use strict';

module.exports = {
    PLUGIN_NAME: 'rubyComponentPluginWebsites',
    MODULE_NAME: 'ruby-component-plugin-websites',
    COMPONENT_NAME: 'rubyComponentPluginWebsites',
    LABEL: 'Space',
    LABEL_PLURAL: 'Spaces',
    KEY: 'spaces',
    ROUTE_KEY: 'space',
    APP_ROUTE: '/app/space',
    TOTAL_DISPLAYED_ACTIVITIES: 20,
    TEMPLATE_KEYWORD: 'rubyapps_space'
    //, ICON_PATH: 'ruby/icons/content/global-data'
    , ICON_PATH: 'material-ui/svgIcons/AvWeb',
    SETTINGS_KEY: 'settings',
    AUTOCREATE_PAGES: [{
        name: 'Homepage',
        template_keyword: 'homepage',
        url_identifier: 'index',
        show_in_client_sitemap: true,
        status: 'active',
        frontend_active: true
    }],
    FRONTEND_SPACES_STATE_PATH: '/frontend-spaces-state',
    MOVED_PAGES_PROP: '_recentlyMovedPages',
    PARAM_SKIP_SITEMAP_HOOKS: 'skipSitemapHooks'
};