'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = RelevanceFormatterGenerator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _customComponent_styles = require('./customComponent_styles.cssModule');

var _customComponent_styles2 = _interopRequireDefault(_customComponent_styles);

var _IconButton = require('material-ui/IconButton/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _info = require('material-ui/svg-icons/action/info');

var _info2 = _interopRequireDefault(_info);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var queryString = require('query-string');
var Route = require('route-parser');

var request = require('@rubyapps/ruby-superagent');

var fieldBaseMixin = require('@rubyapps/ruby-component-mixin-field-base');

var HIGHLIGHT_QUERY_PROPS = ['filter.where', 'search', 'fields'];

var formJs = {
    componentName: "Form",
    children: [{
        componentName: "Hidden",
        data_type: "string",
        key: "html"
    }, {
        componentName: "Info",
        "value": "{{{pageData.html}}}",
        "interpolateValue": true,
        className: _customComponent_styles2.default.InfoField
    }]
};

function RelevanceFormatterGenerator() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var parentProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var toHTML = options.toHTML,
        highlightRouteTemplate = options.highlightRouteTemplate;

    //# parentProps.griddleState.recentRequest.url //# format query
    //# parentProps.delegateActions.formDialog.

    var hydratedToHTML = void 0;
    if (toHTML) {
        hydratedToHTML = fieldBaseMixin.hydratedToHTMLFromProps(options);
    }

    //# NOTE: griddleState doesn't get updated here 
    //# at least it's not guaranteed to update whenever the actual griddle state is updated
    //const req__queryString = _.get(parentProps, 'griddleState.recentRequest._query.0');
    var req__obj = _lodash2.default.get(parentProps, 'griddleState.recentRequest');
    var req__queryString = _lodash2.default.first(_lodash2.default.get(req__obj, '_query', [])); //# NOTE: sometimes the req url won't have the query, so we need to get it from _query
    var req__queryObj = req__queryString ? queryString.parse(req__queryString) : {};
    var highlight_queryObj = _lodash2.default.pick(req__queryObj, HIGHLIGHT_QUERY_PROPS);

    var highlightRoute = highlightRouteTemplate ? new Route(highlightRouteTemplate) : null;

    //# assume url is this structure for now: /ruby/api/v2/content/matter/lister-data?...
    //# and replace `/lister-data` with `/:id/highlight`

    return _react2.default.createClass({
        render: function render() {
            var _React$createElement,
                _this = this,
                _React$createElement2,
                _React$createElement3,
                _React$createElement4,
                _React$createElement5;

            //# this.props.originalData.id
            var data = Number(_lodash2.default.get(this, 'props.data') || NaN);

            var value = Number.isNaN(data) ? '' : data;
            var formattedValue = hydratedToHTML ? hydratedToHTML(value) : value.toLocaleString();

            return _react2.default.createElement(
                'div',
                (_React$createElement5 = {
                    style: {
                        float: 'right',
                        paddingRight: 10,
                        display: 'flex',
                        alignItems: 'center'
                    },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'RelevanceFormatterGenerator'
                }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'RelevanceFormatterGenerator'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'RelevanceFormatterGenerator'), _React$createElement5),
                _react2.default.createElement(
                    'span',
                    (_React$createElement = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'RelevanceFormatterGenerator'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'RelevanceFormatterGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'RelevanceFormatterGenerator'), _React$createElement),
                    formattedValue
                ),
                _react2.default.createElement(
                    'span',
                    (_React$createElement4 = { className: 'visibleWhenRowHovered', 'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'RelevanceFormatterGenerator'
                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'RelevanceFormatterGenerator'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'RelevanceFormatterGenerator'), _React$createElement4),
                    _react2.default.createElement(
                        _IconButton2.default,
                        (_React$createElement3 = {
                            onTouchTap: function onTouchTap(e) {
                                //# get highlights then open dialog
                                var highlightUrl = highlightRoute.reverse(_this.props.originalData);
                                request.get(highlightUrl).query(highlight_queryObj).then(function (res) {
                                    parentProps.delegateActions.formDialog.seedWithFormData(res.body);
                                    parentProps.delegateActions.formDialog.clearLoading();
                                });
                                //# open dialog
                                parentProps.delegateActions.formDialog.setOptions({
                                    title: "Search Highlights",
                                    formJs: formJs,
                                    contentStyle: {
                                        maxWidth: '100%'
                                    },
                                    dialogActions: []
                                });
                                parentProps.delegateActions.formDialog.open();
                                parentProps.delegateActions.formDialog.setLoading();
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                            },
                            'data-codecept-selector-node': 'IconButton',
                            'data-codecept-selector-file': 'RelevanceFormatterGenerator'
                        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'RelevanceFormatterGenerator'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'RelevanceFormatterGenerator'), _React$createElement3),
                        _react2.default.createElement(_info2.default, (_React$createElement2 = {
                            'data-codecept-selector-node': 'InfoIcon',
                            'data-codecept-selector-file': 'RelevanceFormatterGenerator'
                        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'InfoIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'RelevanceFormatterGenerator'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'InfoIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'RelevanceFormatterGenerator'), _React$createElement2))
                    )
                )
            );
        }
    });
}