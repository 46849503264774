'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Snackbar = require('./Snackbar/Snackbar');

var _Snackbar2 = _interopRequireDefault(_Snackbar);

var _muiThemeable = require('material-ui/styles/muiThemeable');

var _muiThemeable2 = _interopRequireDefault(_muiThemeable);

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

var _Tooltip = require('material-ui/internal/Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var TooltipWrapper = _react2.default.createClass({
    displayName: 'TooltipWrapper',

    setHoveredState: function setHoveredState(val) {
        this.setState({ hoveredTooltip: val });
    },

    render: function render() {
        var _this = this,
            _React$createElement,
            _React$createElement2,
            _extends2;

        var _props = this.props,
            tooltipMessage = _props.tooltipMessage,
            children = _props.children,
            other = _objectWithoutProperties(_props, ['tooltipMessage', 'children']);

        return _react2.default.createElement(
            'div',
            _extends({
                onMouseEnter: function onMouseEnter() {
                    _this.setHoveredState(true);
                },
                onMouseLeave: function onMouseLeave() {
                    _this.setHoveredState(false);
                }
            }, other, (_extends2 = {
                style: _extends({}, other.style, { position: 'relative' }),
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'table'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'table'), _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'table'), _extends2)),
            children,
            tooltipMessage && _react2.default.createElement(
                'div',
                (_React$createElement2 = {
                    style: {
                        position: 'absolute',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'table'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'table'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'table'), _React$createElement2),
                _react2.default.createElement(_Tooltip2.default, (_React$createElement = {
                    show: this.state && this.state.hoveredTooltip,
                    label: tooltipMessage,
                    style: {
                        top: 16
                    },
                    'data-codecept-selector-node': 'Tooltip',
                    'data-codecept-selector-file': 'table'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Tooltip'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'table'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Tooltip'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'table'), _React$createElement))
            )
        );
    }
});

var BatchMoveCustomComponent = _react2.default.createClass({
    displayName: 'BatchMoveCustomComponent',


    getDisabledMessage: function getDisabledMessage() {
        var _ref = _lodash2.default.get(this.props, ['rowData', '__metadata']) || {},
            isSelected = _ref.isSelected,
            ancestorIsSelected = _ref.ancestorIsSelected;

        if (isSelected) {
            return "This item is being moved";
        }
        if (ancestorIsSelected) {
            return "This item's ancestor is being moved";
        }
    },
    handleInsertBefore: function handleInsertBefore() {
        this.props.events.batchMoveSelectedIds_toRelativePosition_andReferenceId('before', this.props.rowData.id);
        this.props.events.onBatchMove(this.props.events, 'before', this.props.rowData.id);
    },
    handleInsertInside: function handleInsertInside() {
        this.props.events.batchMoveSelectedIds_toRelativePosition_andReferenceId('inside', this.props.rowData.id);
        this.props.events.onBatchMove(this.props.events, 'inside', this.props.rowData.id);
    },
    handleInsertAfter: function handleInsertAfter() {
        this.props.events.batchMoveSelectedIds_toRelativePosition_andReferenceId('after', this.props.rowData.id);
        this.props.events.onBatchMove(this.props.events, 'after', this.props.rowData.id);
    },

    render: function render() {
        var _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8;

        var disabledMessage = this.getDisabledMessage();

        return _react2.default.createElement(
            'div',
            (_React$createElement8 = {
                className: 'visibleWhenRowHovered',
                style: {
                    display: 'flex',
                    alignItems: 'center'
                },
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'table'
            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'table'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'table'), _React$createElement8),
            _react2.default.createElement(
                'span',
                (_React$createElement3 = {
                    'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'table'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'table'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'table'), _React$createElement3),
                'INSERT:'
            ),
            _react2.default.createElement(
                TooltipWrapper,
                (_React$createElement7 = {
                    tooltipMessage: disabledMessage,
                    'data-codecept-selector-node': 'TooltipWrapper',
                    'data-codecept-selector-file': 'table'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'TooltipWrapper'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'table'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'TooltipWrapper'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'table'), _React$createElement7),
                _react2.default.createElement(_FlatButton2.default, (_React$createElement4 = {
                    key: 'before',
                    label: '\u25B2 Before',
                    disabled: !!disabledMessage,
                    tooltipMessage: disabledMessage,
                    onTouchTap: this.handleInsertBefore,
                    'data-codecept-selector-node': 'FlatButton',
                    'data-codecept-selector-file': 'table'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'table'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'table'), _React$createElement4)),
                _react2.default.createElement(_FlatButton2.default, (_React$createElement5 = {
                    key: 'after',
                    label: '\u25BC After',
                    disabled: !!disabledMessage,
                    tooltipMessage: disabledMessage,
                    onTouchTap: this.handleInsertAfter,
                    'data-codecept-selector-node': 'FlatButton',
                    'data-codecept-selector-file': 'table'
                }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'table'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'table'), _React$createElement5)),
                _react2.default.createElement(_FlatButton2.default, (_React$createElement6 = {
                    key: 'inside',
                    label: '\u25B6 Inside',
                    disabled: !!disabledMessage,
                    tooltipMessage: disabledMessage,
                    onTouchTap: this.handleInsertInside,
                    'data-codecept-selector-node': 'FlatButton',
                    'data-codecept-selector-file': 'table'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'table'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'table'), _React$createElement6))
            )
        );
    }
});

exports.default = function (Table) {
    var _class, _temp;

    return (0, _muiThemeable2.default)()((_temp = _class = function (_Component) {
        _inherits(BatchMoveTable, _Component);

        function BatchMoveTable() {
            _classCallCheck(this, BatchMoveTable);

            return _possibleConstructorReturn(this, (BatchMoveTable.__proto__ || Object.getPrototypeOf(BatchMoveTable)).apply(this, arguments));
        }

        _createClass(BatchMoveTable, [{
            key: 'getMessage',
            value: function getMessage() {
                var _props2 = this.props,
                    _props2$selectedRecor = _props2.selectedRecords,
                    selectedRecords = _props2$selectedRecor === undefined ? {} : _props2$selectedRecor,
                    batchMoveActive = _props2.batchMoveActive;

                var count = Object.keys(selectedRecords).length;
                if (batchMoveActive) {
                    return count === 1 ? 'Moving ' + count + ' Page' : 'Moving ' + count + ' Pages';
                } else {
                    return count === 1 ? count + ' Page selected' : 'Moving ' + count + ' Pages selected';
                }
            }
        }, {
            key: 'getActions',
            value: function getActions() {
                var batchMoveActive = this.props.batchMoveActive;


                if (batchMoveActive) {
                    return [{
                        key: 'cancel',
                        label: 'CANCEL',
                        onTouchTap: this.props.endBatchMove
                    }];
                } else {
                    return [{
                        key: 'move',
                        label: 'MOVE',
                        onTouchTap: this.props.startBatchMove
                    }, {
                        key: 'cancel',
                        label: 'CANCEL',
                        onTouchTap: this.props.clearAllRowSelections
                    }];
                }
            }
        }, {
            key: 'render',
            value: function render() {
                var _React$createElement9, _React$createElement10, _extends4, _React$createElement11;

                var desktopSubheaderHeight = _lodash2.default.get(this.props, 'muiTheme.baseTheme.spacing.desktopSubheaderHeight');
                var palette = this.context.muiTheme.baseTheme.palette;

                var _props3 = this.props,
                    _props3$selectedRecor = _props3.selectedRecords,
                    selectedRecords = _props3$selectedRecor === undefined ? {} : _props3$selectedRecor,
                    batchMoveActive = _props3.batchMoveActive;

                var selectionCount = Object.keys(selectedRecords).length;
                var open = selectionCount > 0;

                var message = this.getMessage();

                var baseStyles = {
                    position: 'absolute',
                    top: 0,
                    bottom: 'auto'
                };
                var styles = _extends({}, baseStyles, {
                    transform: open ? 'translate(0, 0)' : 'translate(0, ' + -1 * desktopSubheaderHeight + 'px)',
                    visibility: open ? 'visible' : undefined
                });

                var idKey = this.props.idColumn || 'id';
                var renderProperties = _extends({}, this.props.renderProperties, batchMoveActive && {
                    columnProperties: _extends({}, _lodash2.default.get(this, 'props.renderProperties.columnProperties'), _defineProperty({}, idKey, _extends({}, _lodash2.default.get(this, ['props', 'renderProperties', 'columnProperties', idKey]), {
                        customComponent: BatchMoveCustomComponent
                    })))
                });

                return _react2.default.createElement(
                    'div',
                    (_React$createElement11 = {
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'table'
                    }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'table'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'table'), _React$createElement11),
                    _react2.default.createElement(
                        'div',
                        (_React$createElement10 = {
                            style: {
                                position: 'absolute',
                                overflow: 'hidden',
                                height: desktopSubheaderHeight,
                                width: '100%',
                                pointerEvents: 'none'
                            },
                            'data-codecept-selector-node': 'div',
                            'data-codecept-selector-file': 'table'
                        }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'table'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'table'), _React$createElement10),
                        _react2.default.createElement(_Snackbar2.default, (_React$createElement9 = {
                            open: open,
                            animateOnChange: false,
                            message: message,
                            style: styles,
                            bodyStyle: {
                                pointerEvents: 'auto',
                                backgroundColor: palette.primary1Color
                            },
                            actionStyle: {
                                color: 'white'
                            },
                            actions: this.getActions(),
                            'data-codecept-selector-node': 'Snackbar',
                            'data-codecept-selector-file': 'table'
                        }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'Snackbar'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'table'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'Snackbar'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'table'), _React$createElement9))
                    ),
                    _react2.default.createElement(Table, _extends({}, this.props, (_extends4 = {
                        renderProperties: renderProperties,
                        events: _extends({}, this.props.events, { batchMoveSelectedIds_toRelativePosition_andReferenceId: this.props.batchMoveSelectedIds_toRelativePosition_andReferenceId,
                            onBatchMove: this.props.onBatchMove
                        }),
                        'data-codecept-selector-node': 'Table',
                        'data-codecept-selector-file': 'table'
                    }, _defineProperty(_extends4, 'data-codecept-selector-node', 'Table'), _defineProperty(_extends4, 'data-codecept-selector-file', 'table'), _defineProperty(_extends4, 'data-codecept-selector-node', 'Table'), _defineProperty(_extends4, 'data-codecept-selector-file', 'table'), _extends4)))
                );
            }
        }]);

        return BatchMoveTable;
    }(_react.Component), _class.contextTypes = {
        muiTheme: _react.PropTypes.object.isRequired
    }, _class.propTypes = {
        selectedRecords: _react.PropTypes.object,
        renderProperties: _react.PropTypes.object,
        batchMoveActive: _react.PropTypes.bool,
        endBatchMove: _react.PropTypes.func,
        startBatchMove: _react.PropTypes.func,
        clearAllRowSelections: _react.PropTypes.func,
        idColumn: _react.PropTypes.string
    }, _temp));
};