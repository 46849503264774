'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _RaisedButton = require('@rubyapps/ruby-material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _asyncButton = require('../../../../ruby-react-components/src/client/hoc/asyncButton');

var _asyncButton2 = _interopRequireDefault(_asyncButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var RubyIcons = require('@rubyapps/ruby-icons');

var FieldButton = _react2.default.createClass({
    displayName: 'FieldButton',

    propTypes: {
        actions: PropTypes.object,
        delegateActions: PropTypes.object,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        styles: PropTypes.shape({
            RaisedButton: PropTypes.object
        }),
        rubyIconPath: PropTypes.string,
        isLoading: PropTypes.bool,
        ButtonComponent: PropTypes.func,
        touchTapPulseWidth: PropTypes.number
    },
    getDefaultProps: function getDefaultProps() {
        return {
            ButtonComponent: _RaisedButton2.default,
            styles: {
                RaisedButton: {},
                Button: {} //# generic customization for all buttons since we want to override not only the raised button
            },
            delegateActions: {},
            touchTapPulseWidth: 500
        };
    },
    getIcon: function getIcon() {
        if (this.props.icon) {
            return this.props.icon;
        } else if (this.props.rubyIconPath) {
            var _React$createElement;

            var IconClass = RubyIcons.getIconClassForPath(this.props.rubyIconPath);
            return _react2.default.createElement(IconClass, (_React$createElement = {
                style: { marginTop: -4 },
                'data-codecept-selector-node': 'IconClass',
                'data-codecept-selector-file': 'Button'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'IconClass'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Button'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'IconClass'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Button'), _React$createElement));
        }
    },
    _onTouchTap: function _onTouchTap() {
        var _this = this;

        var actionForButton = this.props.delegateActions.actionForButton;
        var handler = this.props.handler;

        actionForButton && actionForButton.apply(this, arguments);
        handler && handler.apply(this, arguments);

        if (this.props.fieldKey) {
            this.props.actions.setFieldValueByKey(true, this.props.fieldKey);
            setTimeout(function () {
                //# toggle the field on and off
                _this.props.actions.setFieldValueByKey(false, _this.props.fieldKey);
            }, this.props.touchTapPulseWidth);
        }
    },
    componentWillMount: function componentWillMount() {
        this._AsyncButtonComponent = (0, _asyncButton2.default)(this.props.ButtonComponent);
        this._AsyncButtonComponent__propTypeKeys = Object.keys(this._AsyncButtonComponent.propTypes);
    },
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        if (nextProps.ButtonComponent != this.props.ButtonComponent) {
            this._AsyncButtonComponent = (0, _asyncButton2.default)(nextProps.ButtonComponent);
            this._AsyncButtonComponent__propTypeKeys = Object.keys(this._AsyncButtonComponent.propTypes);
        }
    },
    render: function render() {
        var _extends2;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive || this.props.hidden) {
            return null;
        }

        var buttonProps = _.pick(this.props, this._AsyncButtonComponent__propTypeKeys);

        var AsyncButtonComponent = this._AsyncButtonComponent;
        return _react2.default.createElement(AsyncButtonComponent, _extends({
            secondary: true
        }, this.props.styles.RaisedButton, this.props.styles.Button, {
            style: {
                background: 'none'
            },
            disabledBackgroundColor: 'rgb(229, 229, 229, 0.5)'
        }, buttonProps, (_extends2 = {
            onTouchTap: this._onTouchTap,
            icon: this.getIcon(),
            'data-codecept-selector-node': 'AsyncButtonComponent',
            'data-codecept-selector-file': 'Button'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'AsyncButtonComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Button'), _defineProperty(_extends2, 'data-codecept-selector-node', 'AsyncButtonComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Button'), _extends2)));
    }
});
exports.default = FieldButton;