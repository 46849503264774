'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _qrcode = require('qrcode.react');

var _qrcode2 = _interopRequireDefault(_qrcode);

var _RaisedButton = require('@rubyapps/ruby-material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;


var styles = process.browser ? require('./TFA.cssModule').default : {};

/*
 * NOTES
 *
 * level M maintained from old Ruby (see  http://blog.qrstuff.com/2011/12/14/qr-code-error-correction)
 * for URI format, refer to https://github.com/google/google-authenticator/wiki/Key-Uri-Format
 *
 */

var PLACEHOLDER_URI = 'https://www.nist.gov/itl/tig/back-basics-multi-factor-authentication';
var TFASecret = _react2.default.createClass({
    displayName: 'TFASecret',

    propTypes: {
        generateOnClick: PropTypes.func,
        generateLabel: PropTypes.string
    },
    getDefaultProps: function getDefaultProps() {
        return {
            generateLabel: "Begin Setup"
        };
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8;

        var _props = this.props,
            value = _props.value,
            uri = _props.uri;


        return value && uri ? _react2.default.createElement(
            'div',
            (_React$createElement4 = { className: styles.secretRow, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'TFASecret'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TFASecret'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TFASecret'), _React$createElement4),
            _react2.default.createElement(
                'div',
                (_React$createElement2 = { className: styles.secretColumn, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'TFASecret'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TFASecret'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TFASecret'), _React$createElement2),
                _react2.default.createElement(_qrcode2.default, (_React$createElement = {
                    value: uri,
                    level: 'M',
                    size: 256,
                    'data-codecept-selector-node': 'QRCode',
                    'data-codecept-selector-file': 'TFASecret'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'QRCode'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TFASecret'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'QRCode'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TFASecret'), _React$createElement))
            ),
            _react2.default.createElement(
                'p',
                (_React$createElement3 = { className: styles.plainSecret, 'data-codecept-selector-node': 'p',
                    'data-codecept-selector-file': 'TFASecret'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TFASecret'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TFASecret'), _React$createElement3),
                'Registration Key: ',
                value
            )
        ) : _react2.default.createElement(
            'div',
            (_React$createElement8 = { className: styles.secretRow, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'TFASecret'
            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'TFASecret'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'TFASecret'), _React$createElement8),
            _react2.default.createElement(
                'div',
                (_React$createElement7 = { className: styles.secretColumn, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'TFASecret'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'TFASecret'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'TFASecret'), _React$createElement7),
                _react2.default.createElement(_qrcode2.default, (_React$createElement5 = {
                    value: PLACEHOLDER_URI,
                    level: 'M',
                    size: 256,
                    fgColor: '#e8e8e8',
                    className: styles.secretQRCode,
                    'data-codecept-selector-node': 'QRCode',
                    'data-codecept-selector-file': 'TFASecret'
                }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'QRCode'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'TFASecret'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'QRCode'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'TFASecret'), _React$createElement5)),
                _react2.default.createElement(_RaisedButton2.default, (_React$createElement6 = {
                    label: this.props.generateLabel,
                    secondary: true,
                    onTouchTap: this.props.generateOnClick,
                    'data-codecept-selector-node': 'RaisedButton',
                    'data-codecept-selector-file': 'TFASecret'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'TFASecret'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'TFASecret'), _React$createElement6))
            )
        );
    }
});

exports.default = TFASecret;