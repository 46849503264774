"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
var styles = {
    textarea: {
        cols: 80,
        minRows: 6,
        maxRows: 24
    }
};

exports.default = styles;