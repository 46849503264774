"use strict";

function typesWithID(id) {
    return {
        UPDATE_REFERENCED_DISPLAY_VALUES: "@@ruby-app/" + id + "/UPDATE_REFERENCED_DISPLAY_VALUES"
    };
}

var generators = {
    updateReferencedDisplayValues: function updateReferencedDisplayValues(referencedDisplayValues) {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        return {
            type: TYPES.UPDATE_REFERENCED_DISPLAY_VALUES,
            payload: {
                referencedDisplayValues: referencedDisplayValues
            }
        };
    }
};

module.exports = function () {
    var TYPES = typesWithID(this.getID());
    return {
        TYPES: TYPES,
        generators: generators
    };
};