'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _TogglesConnector = require('./reactComponents/TogglesConnector');

var _TogglesConnector2 = _interopRequireDefault(_TogglesConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var accessControlMixin = require('@rubyapps/ruby-component-mixin-access-control');
var fieldRemoteOptionsMixin = require('@rubyapps/ruby-component-mixin-field-remote-options');

var RCToggles = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin, accessControlMixin, fieldRemoteOptionsMixin],
    propTypes: {
        limit: PropTypes.number,
        count: PropTypes.number //# the count param for url
        //# Must be included because we show all options
        //# so it can never be unbounded
        , options: PropTypes.array,
        toHTML: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        optgroupKey: PropTypes.string //# keypath to the outgroup value for the list of options
        /*
         *  Eg. given a option structure like: 
         *  {
         *      text: 'my option'
         *      , value: 1
         *      , some: {
         *          other: {
         *              field: 'myOtherField'
         *          }
         *      }
         *  }
         *
         *  outgroupKey might be "some.other.field"
         */

        //# TODO: none of these alternative schemas are currently supported
        /*
         *  //# optgroupKey might also be the following
         *  optgroupKey: ['keypath', 'keypath2'] //# to support multiple values as optgroup. We just concat the values
         *  //# or if you need to specify the formatting:
         *  optgroupKey: {
         *      value: ["keypath", "keypathB"]
         *      toHTML: "${data.0} ${data.0}"
         *  }
         *
         **/
    },
    getDefaultProps: function getDefaultProps(props) {
        var count = props.count || 200; //# NOTE: default prop is 200. we always want to have an upper cap since we need to render every option
        return {
            count: count
            //, currentUserID: 'rubyComponentCurrentUser'
            , preloadOptions: true,
            preloadOptionsQuery: {
                search_input: '',
                count: count
            }
        };
    },
    componentName: componentName,
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();

        return {
            selfSelector: selfSelector
        };
    },
    getReactClass: function getReactClass() {
        return _TogglesConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var TogglesComponent = this.getReactClass();

        return React.createElement(TogglesComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'TogglesComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TogglesComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TogglesComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

    //# Mixin Overrides

});

module.exports = RCToggles;