'use strict';

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function typesWithID(id) {
    return {
        //# Overridden action types
        SET_FIELD_ERROR_OBJECT_BY_KEY: '@ruby-app/' + id + '/SET_FIELD_ERROR_OBJECT_BY_KEY',
        MERGE_FIELD_ERROR_OBJECT_BY_KEY: '@ruby-app/' + id + '/MERGE_FIELD_ERROR_OBJECT_BY_KEY',
        SET_FIELD_ERROR_MESSAGE_BY_KEY: '@ruby-app/' + id + '/SET_FIELD_ERROR_MESSAGE_BY_KEY'
        //, CLEAR_FIELD_ERRORS_WITH_KEYS_BY_KEY: `@ruby-app/${id}/CLEAR_FIELD_ERRORS_WITH_KEYS_BY_KEY`
        //# NOTE: leveraging the MERGE_FIELD_ERROR_OBJECT_BY_KEY action
        , RESET_STORE: '@ruby-app/' + id + '/RESET_STORE',
        SET_DESCENDENTS_HAVE_VALUES: '@@ruby-app/' + id + '/SET_DESCENDENTS_HAVE_VALUES'
    };
}

var generators = {
    //# Overridden field actions
    setFieldErrorMessageByKey: function setFieldErrorMessageByKey(message, key) {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        return {
            type: TYPES.SET_FIELD_ERROR_MESSAGE_BY_KEY,
            payload: {
                error: {
                    message: _lodash2.default.isString(message) ? {
                        msg: message,
                        level: 'error'
                    } : message
                },
                key: key
            }
        };
    },
    setFieldErrorObjectByKey: function setFieldErrorObjectByKey(error, key) {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES;

        return {
            type: TYPES.SET_FIELD_ERROR_OBJECT_BY_KEY,
            payload: {
                error: error,
                key: key
            }
        };
    },
    mergeFieldErrorObjectByKey: function mergeFieldErrorObjectByKey(error, key) {
        var _getAction3 = this.getAction(),
            TYPES = _getAction3.TYPES;

        return {
            type: TYPES.MERGE_FIELD_ERROR_OBJECT_BY_KEY,
            payload: {
                error: error,
                key: key
            }
        };
    }
    //# DEPRECATED: 20180521 - we shouldn't need to explicitly clearErrors anymore
    , clearErrors: function clearErrors() {
        var selfKey = this.props.key;

        var _getAction4 = this.getAction(),
            actions = _getAction4.generators;

        return actions.setFieldErrorMessageByKey(null, selfKey);
    },
    clearErrorsWithKeys_inState: function clearErrorsWithKeys_inState() {
        var errorKeys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        var inState = arguments[1];

        var _getAction5 = this.getAction(),
            TYPES = _getAction5.TYPES,
            generators = _getAction5.generators;

        var selfState = inState;
        var error = selfState.error ? selfState.error : {};
        var selfKey = this.props.key;

        var preppedError = _lodash2.default.reduce(errorKeys, function (collector, errorKey, errorKeyIndex) {
            if (error.hasOwnProperty(errorKey)) {
                collector[errorKey] = null;
            }
            return collector;
        }, {});

        return generators.mergeFieldErrorObjectByKey(preppedError, selfKey);
    },
    resetStore: function resetStore() {
        var _getAction6 = this.getAction(),
            TYPES = _getAction6.TYPES;

        return {
            type: TYPES.RESET_STORE
        };
    },
    setDescendentsHaveValues: function setDescendentsHaveValues(descendentsHaveValues) {
        var _getAction7 = this.getAction(),
            TYPES = _getAction7.TYPES;

        return {
            type: TYPES.SET_DESCENDENTS_HAVE_VALUES,
            payload: {
                descendentsHaveValues: descendentsHaveValues
            }
        };
    }

};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};