'use strict';

/* jshint -W138 */
var actionTypes = require('./action').TYPES;

//# NOTE: should not need to have a default state

var defaultState = null;

module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];

    switch (action.type) {
        case actionTypes.SET_LOGGED_IN_USER:
            return action.payload.userInfo;
        case actionTypes.CLEAR_LOGGED_IN_USER:
            return null;
        default:
            return state;
    }
};