'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = DateFormatterGenerator;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');
var moment = require('@rubyapps/friendly-ruby-moment');
var React = require('react');

var fieldBaseMixin = require('@rubyapps/ruby-component-mixin-field-base');
var default_timeZone = moment.tz.guess();

function DateFormatterGenerator(options) {
    var toHTML = _.get(options, 'toHTML');

    var hydratedToHTML = void 0;
    if (toHTML) {
        hydratedToHTML = fieldBaseMixin.hydratedToHTMLFromProps(options);
    }

    return function DateFormatter(props) {
        var _React$createElement;

        var dateFormat = _.get(options, 'format') || "MMM D, YYYY";
        var friendlyDate = _.get(options, 'friendlyDate');
        var shouldFormatDatesRelativeToInternalTimezone = _.get(options, 'formatRelativeToInternalTimeZone');

        var formattedDateString = void 0;

        var date = '';
        var timeZone = default_timeZone;

        var props__data = props.data;
        var effectiveDataPath = _.isString(props__data) ? 'data' : _.isPlainObject(props__data) && props__data.hasOwnProperty('datetime') ? 'data.datetime' : 'data.date';

        date = _.get(props, effectiveDataPath);
        if (_.isPlainObject(props__data)) {
            timeZone = _.get(props__data, ['timeZone']) || 'UTC';
        }

        if (date) {

            var momentInstance = timeZone && shouldFormatDatesRelativeToInternalTimezone ? moment.tz(date, timeZone) : moment(date);

            if (friendlyDate) {
                switch (friendlyDate) {
                    case 'long':
                        formattedDateString = momentInstance.longButFriendly();
                        break;
                    case 'short':
                    default:
                        formattedDateString = momentInstance.shortButFriendly();
                }
            } else {
                formattedDateString = momentInstance.format(dateFormat);
            }
        } else {
            formattedDateString = '';
        }

        var formattedValue = hydratedToHTML ? hydratedToHTML(_extends({ formattedDateString: formattedDateString }, props__data)) : formattedDateString;

        var className = '' + (props.className ? props.className : '');

        return React.createElement(
            'span',
            (_React$createElement = { style: props.style, className: className, 'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'DateFormatterGenerator'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'DateFormatterGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'DateFormatterGenerator'), _React$createElement),
            formattedValue
        );
    };
}