'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _browserDetect2 = require('browser-detect');

var _browserDetect3 = _interopRequireDefault(_browserDetect2);

var _AppBar = require('material-ui/AppBar');

var _AppBar2 = _interopRequireDefault(_AppBar);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _close = require('material-ui/svg-icons/navigation/close');

var _close2 = _interopRequireDefault(_close);

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

var _DropDownMenu = require('material-ui/DropDownMenu');

var _DropDownMenu2 = _interopRequireDefault(_DropDownMenu);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _RefreshIndicator = require('@rubyapps/ruby-material-ui/RefreshIndicator');

var _RefreshIndicator2 = _interopRequireDefault(_RefreshIndicator);

var _LanguageIndicator = require('../../../../ruby-component-field-manipulator-namespace/src/client/reactComponents/LanguageIndicator');

var _LanguageIndicator2 = _interopRequireDefault(_LanguageIndicator);

var _Tabs = require('material-ui/Tabs');

var _Dialog = require('material-ui/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var rubyLogger = require('@rubyapps/ruby-logger');
var logger = rubyLogger.getLogger('ruby-component-content-compare:reactComponents/ContentCompareDialog');

var _ = require('lodash');

var PropTypes = _react2.default.PropTypes;

var _browserDetect = (0, _browserDetect3.default)(),
    browserName = _browserDetect.name;

var routeParser = require('route-parser');

var rubyWords = require('@rubyapps/ruby-words');

var action = require('../action');
var CONSTANTS = require('../../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var srcDoc = require('@rubyapps/srcdoc-polyfill');

function getWindowHeight() {
    var de = document.documentElement;
    return self.innerHeight || de && de.clientHeight || document.body.clientHeight;
};
function getWindowWidth() {
    var de = document.documentElement;
    return self.innerWidth || de && de.clientWidth || document.body.clientWidth;
};

var styles = {
    title: {},
    headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400
    },
    dialogContent: {
        padding: '0px',
        margin: '0px'
    },
    tabs: {
        backgroundColor: '#eee'
    },
    tab: {
        color: '#222'
    },
    DropDownMenu: {
        style: {
            top: -8
            //, width: 106
            , height: 42,
            fontSize: 14,
            marginLeft: -16,
            marginRight: -16
        },
        floatingLabelStyle: {
            top: 42
        },
        labelStyle: {
            color: '#ffffff'
            //, top: 9
        },
        iconStyle: {
            fill: '#ffffff'
        },
        underlineStyle: {
            borderTop: 'solid 1px #ffffff'
        },
        autoWidth: true
    },
    LoadingIndicator: {
        position: 'relative',
        marginTop: '20',
        marginBottom: '20',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    LanguageIndicator: {
        styles: {
            root: {
                padding: 0,
                top: -6,
                position: 'relative'
            }
        }
    }
};

var PropTypes_languages = PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    text: PropTypes.string
}));

var LanguageDropDown = _react2.default.createClass({
    displayName: 'LanguageDropDown',

    propTypes: {
        languages: PropTypes_languages.isRequired,
        selectedLanguage: PropTypes.string.isRequired,
        delegateLanguageChange: PropTypes.func.isRequired
    },

    getDefaultProps: function getDefaultProps() {
        return {
            languages: [{ text: 'English', value: 'en' }],
            selectedLanguage: 'en',
            delegateLanguageChange: function delegateLanguageChange(event) {}
        };
    },
    _handleChange: function _handleChange(event, index, value) {
        if (value != this.props.selectedLanguage) {
            this.props.delegateLanguageChange(value);
        }
    },
    _getDefaultLanguageValue: function _getDefaultLanguageValue() {
        return this.props.languages.filter(function (langauge) {
            return langauge.isDefault;
        })[0].value;
    },
    render: function render() {
        var _extends2, _extends3, _React$createElement2;

        var languages = this.props.languages;
        return _react2.default.createElement(
            'div',
            (_React$createElement2 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'ContentCompareDialog'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement2),
            _react2.default.createElement(_LanguageIndicator2.default, _extends({
                defaultNamespace: this._getDefaultLanguageValue(),
                value: [this.props.selectedLanguage]
            }, styles.LanguageIndicator, (_extends2 = {
                'data-codecept-selector-node': 'LanguageIndicator',
                'data-codecept-selector-file': 'ContentCompareDialog'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'LanguageIndicator'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_extends2, 'data-codecept-selector-node', 'LanguageIndicator'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ContentCompareDialog'), _extends2))),
            _react2.default.createElement(
                _DropDownMenu2.default,
                _extends({
                    value: this.props.selectedLanguage,
                    onChange: this._handleChange
                }, styles.DropDownMenu, (_extends3 = {
                    'data-codecept-selector-node': 'DropDownMenu',
                    'data-codecept-selector-file': 'ContentCompareDialog'
                }, _defineProperty(_extends3, 'data-codecept-selector-node', 'DropDownMenu'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_extends3, 'data-codecept-selector-node', 'DropDownMenu'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ContentCompareDialog'), _extends3)),
                languages.map(function (menuValues) {
                    var _React$createElement;

                    return _react2.default.createElement(_MenuItem2.default, (_React$createElement = { key: menuValues.value,
                        primaryText: menuValues.text,
                        value: menuValues.value,
                        disabled: menuValues.disabled || false,
                        'data-codecept-selector-node': 'MenuItem',
                        'data-codecept-selector-file': 'ContentCompareDialog'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement));
                })
            )
        );
    }
});

/*

languages: [
    {text: 'U.S. English', value: 'us'}
    , {text: 'French', value: 'french'}
    , {text: 'German', value: 'german'}
    , {text: 'Italian', value: 'italian'}
    , {text: 'Mandarin', value: 'mandarin'}
    , {text: 'Spanish', value: 'es'}
]

*/

var CompareDialogHeader = _react2.default.createClass({
    displayName: 'CompareDialogHeader',

    propTypes: {
        languages: PropTypes_languages,
        isMultilingual: PropTypes.bool,
        selectedLanguage: PropTypes.string,
        title: PropTypes.string,
        showContentApprovalActions: PropTypes.bool //# TODO: remove DEPRECATED
        , showEditAction: PropTypes.bool,

        additionalHeaderElements: PropTypes.arrayOf(PropTypes.node),

        canApproveContent: PropTypes.bool,
        delegateLanguageChange: PropTypes.func,
        delegateEdit: PropTypes.func,
        delegateReject: PropTypes.func,
        delegateApprove: PropTypes.func,
        delegateClose: PropTypes.func,
        delegateShare: PropTypes.func,
        shareURL: PropTypes.string
    },

    getDefaultProps: function getDefaultProps() {
        return {
            languages: [{ text: 'English', value: 'en' }],
            selectedLanguage: 'en',
            title: 'Preview',
            additionalHeaderElements: []
        };
    },
    render: function render() {
        var _React$createElement3,
            _React$createElement4,
            _React$createElement5,
            _this = this,
            _React$createElement12,
            _React$createElement13;

        var myContext = this;
        var styleColor = {
            color: '#ffffff'
        };
        var iconElementRightStyle = {
            color: '#ffffff',
            display: 'flex',
            'align-items': 'center',
            marginTop: '3px'
        };

        var languageDropDownProps = _.pick(this.props, Object.keys(LanguageDropDown.propTypes));

        return _react2.default.createElement(_AppBar2.default, (_React$createElement13 = {
            title: _react2.default.createElement(
                'span',
                (_React$createElement3 = { style: styles.title, 'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'ContentCompareDialog'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement3),
                this.props.title
            ),
            iconElementLeft: _react2.default.createElement(
                _IconButton2.default,
                (_React$createElement5 = { onClick: this.props.delegateClose, 'data-codecept-selector-node': 'IconButton',
                    'data-codecept-selector-file': 'ContentCompareDialog'
                }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement5),
                _react2.default.createElement(_close2.default, (_React$createElement4 = {
                    'data-codecept-selector-node': 'NavigationClose',
                    'data-codecept-selector-file': 'ContentCompareDialog'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'NavigationClose'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'NavigationClose'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement4))
            ),
            iconElementRight: _react2.default.createElement(
                'div',
                (_React$createElement12 = { style: iconElementRightStyle, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'ContentCompareDialog'
                }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement12),
                function () {
                    if (myContext.props.isMultilingual && myContext.props.languages.length > 1) {
                        var _extends4;

                        return _react2.default.createElement(LanguageDropDown, _extends({}, languageDropDownProps, (_extends4 = {
                            'data-codecept-selector-node': 'LanguageDropDown',
                            'data-codecept-selector-file': 'ContentCompareDialog'
                        }, _defineProperty(_extends4, 'data-codecept-selector-node', 'LanguageDropDown'), _defineProperty(_extends4, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_extends4, 'data-codecept-selector-node', 'LanguageDropDown'), _defineProperty(_extends4, 'data-codecept-selector-file', 'ContentCompareDialog'), _extends4)));
                    }
                }(),
                function () {
                    if (_this.props.shareURL) {
                        var _React$createElement6, _React$createElement7;

                        return _react2.default.createElement(
                            'a',
                            (_React$createElement7 = { href: _this.props.shareURL, 'data-codecept-selector-node': 'a',
                                'data-codecept-selector-file': 'ContentCompareDialog'
                            }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement7),
                            _react2.default.createElement(_FlatButton2.default, (_React$createElement6 = { label: 'Share', style: styleColor, 'data-codecept-selector-node': 'FlatButton',
                                'data-codecept-selector-file': 'ContentCompareDialog'
                            }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement6))
                        );
                    } else if (_this.props.delegateShare) {
                        var _React$createElement8;

                        return _react2.default.createElement(_FlatButton2.default, (_React$createElement8 = { label: 'Share',
                            onTouchTap: _this.props.delegateShare,
                            style: styleColor,
                            'data-codecept-selector-node': 'FlatButton',
                            'data-codecept-selector-file': 'ContentCompareDialog'
                        }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement8));
                    }
                }(),
                function () {
                    if (myContext.props.showEditAction) {
                        var _React$createElement9;

                        return _react2.default.createElement(_FlatButton2.default, (_React$createElement9 = { label: 'Edit', onTouchTap: _this.props.delegateEdit, style: styleColor, 'data-codecept-selector-node': 'FlatButton',
                            'data-codecept-selector-file': 'ContentCompareDialog'
                        }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement9));
                    }
                }(),
                function () {
                    if (myContext.props.canApproveContent && myContext.props.showContentApprovalActions) {
                        var _React$createElement10, _React$createElement11;

                        return [_react2.default.createElement(_FlatButton2.default, (_React$createElement10 = { label: 'Reject', onTouchTap: _this.props.delegateReject, style: styleColor, 'data-codecept-selector-node': 'FlatButton',
                            'data-codecept-selector-file': 'ContentCompareDialog'
                        }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement10)), _react2.default.createElement(_FlatButton2.default, (_React$createElement11 = { label: 'Approve', onTouchTap: _this.props.delegateApprove, style: styleColor, 'data-codecept-selector-node': 'FlatButton',
                            'data-codecept-selector-file': 'ContentCompareDialog'
                        }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement11))];
                    }
                }(),
                this.props.additionalHeaderElements
            ),
            'data-codecept-selector-node': 'AppBar',
            'data-codecept-selector-file': 'ContentCompareDialog'
        }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement13));
    }
});

//# TODO: convert to reactClass
var CompareTabs = _react2.default.createClass({
    displayName: 'CompareTabs',

    propTypes: {
        previewPage: PropTypes.shape({
            url: PropTypes.string,
            doc: PropTypes.string
        }),
        result: PropTypes.shape({
            url: PropTypes.string,
            doc: PropTypes.string
        }),
        basis: PropTypes.shape({
            url: PropTypes.string,
            doc: PropTypes.string
        }),
        diff: PropTypes.shape({
            url: PropTypes.string,
            doc: PropTypes.string
        }),
        tableDiff: PropTypes.shape({
            url: PropTypes.string,
            doc: PropTypes.string
        }),
        windowHeight: PropTypes.number,
        selectedLanguage: PropTypes.string
    },
    getDefaultProps: function getDefaultProps() {
        return {
            result: {},
            basis: {}
        };
    },
    _renderIframeForDetectedBrowser_withProps: function _renderIframeForDetectedBrowser_withProps(_ref) {
        var _React$createElement14;

        var url = _ref.url,
            doc = _ref.doc,
            height = _ref.height;

        return _react2.default.createElement('iframe', (_React$createElement14 = {
            src: browserName === 'edge' ? undefined : url,
            width: '100%',
            height: height,
            ref: function ref(iframeElement) {
                if (browserName === 'edge') {
                    //# in edge, anchor links cause the parent edit page to be rendered within the iframe
                    var docWithDisabledClicks = doc ? doc.replace('</head>', '<style> body { pointer-events: none!important; } </style> </head>') : "";
                    docWithDisabledClicks && srcDoc.set(iframeElement, docWithDisabledClicks);
                    return;
                }
                if (iframeElement && doc && !url) {
                    if (browserName === 'ie') {
                        //# anchor links work in iframes using srcdoc in IE11 and Edge
                        //# but the blob url work-around doesn't work
                        srcDoc.set(iframeElement, doc);
                    } else {
                        //# chrome, safari, and firefox require this blob url
                        //# workaround to fix a bug with anchor links
                        var blob = new Blob([doc], { type: 'text/html' });
                        var blobURL = URL.createObjectURL(blob);
                        iframeElement.src = blobURL;
                    }
                }
            },
            'data-codecept-selector-node': 'iframe',
            'data-codecept-selector-file': 'ContentCompareDialog'
        }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'iframe'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'iframe'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement14));
    },
    render: function render(props) {
        var _this2 = this,
            _React$createElement18;

        var showPreviewPage = this.props.previewPage.url || this.props.previewPage.doc;

        var windowHeight = this.props.windowHeight - 120; //# adjust for height of the dialog header and tabs1
        var notification = this.props.notification;

        var tabContentSpecs = showPreviewPage ? [this.props.previewPage] : [this.props.result, this.props.basis, this.props.diff, this.props.tableDiff].filter(function (spec) {
            return spec.url || spec.doc;
        });
        return _react2.default.createElement(
            _Tabs.Tabs,
            (_React$createElement18 = { tabItemContainerStyle: styles.tabs, 'data-codecept-selector-node': 'Tabs',
                'data-codecept-selector-file': 'ContentCompareDialog'
            }, _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'Tabs'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'Tabs'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement18),
            tabContentSpecs.map(function (_ref2) {
                var _React$createElement15, _React$createElement16, _React$createElement17;

                var label = _ref2.label,
                    url = _ref2.url,
                    doc = _ref2.doc;

                return _react2.default.createElement(
                    _Tabs.Tab,
                    (_React$createElement17 = { label: label, style: styles.tab, 'data-codecept-selector-node': 'Tab',
                        'data-codecept-selector-file': 'ContentCompareDialog'
                    }, _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'Tab'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'Tab'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement17),
                    _react2.default.createElement(
                        'div',
                        (_React$createElement16 = { style: { textAlign: 'center' }, 'data-codecept-selector-node': 'div',
                            'data-codecept-selector-file': 'ContentCompareDialog'
                        }, _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement16),
                        _react2.default.createElement(
                            'div',
                            (_React$createElement15 = { style: { position: 'relative', overflow: 'hidden' }, 'data-codecept-selector-node': 'div',
                                'data-codecept-selector-file': 'ContentCompareDialog'
                            }, _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement15),
                            notification,
                            _this2._renderIframeForDetectedBrowser_withProps({
                                url: url,
                                doc: doc,
                                height: windowHeight
                            })
                        )
                    )
                );
            })
        );
    }
});

var CompareDialog = _react2.default.createClass({
    displayName: 'CompareDialog',

    propTypes: _extends({
        //# for language dropdown
        languages: PropTypes_languages,
        value: PropTypes.string,
        title: PropTypes.string,
        delegateLanguageChange: PropTypes.func

        //# for header
        , delegateEdit: PropTypes.func,
        delegateReject: PropTypes.func,
        delegateApprove: PropTypes.func

        //# for tab content
        , previewPage: PropTypes.shape({
            url: PropTypes.string,
            doc: PropTypes.string
        }),
        result: PropTypes.shape({
            url: PropTypes.string,
            doc: PropTypes.string
        }),
        basis: PropTypes.shape({
            url: PropTypes.string,
            doc: PropTypes.string
        }),
        diff: PropTypes.shape({
            url: PropTypes.string,
            doc: PropTypes.string
        }),
        showContentApprovalActions: PropTypes.bool,
        showEditAction: PropTypes.bool,
        canApproveContent: PropTypes.bool,
        isLoading: PropTypes.bool
    }, CompareDialogHeader.propTypes),
    getDefaultProps: function getDefaultProps() {
        return {
            open: true
        };
    },
    getInitialState: function getInitialState() {
        return {
            windowHeight: getWindowHeight()
        };
    },
    _handleOpen: function _handleOpen() {
        this.props.delegateOpen();
    },

    _handleClose: function _handleClose() {
        this.props.delegateClose();
    },

    render: function render() {
        var _React$createElement19,
            _React$createElement20,
            _extends5,
            _this3 = this,
            _React$createElement23;

        var actions = [_react2.default.createElement(_FlatButton2.default, (_React$createElement19 = {
            label: 'Cancel',
            primary: false,
            onTouchTap: this._handleClose,
            'data-codecept-selector-node': 'FlatButton',
            'data-codecept-selector-file': 'ContentCompareDialog'
        }, _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement19)), _react2.default.createElement(_FlatButton2.default, (_React$createElement20 = {
            label: 'Submit',
            primary: true,
            onTouchTap: this._handleClose,
            'data-codecept-selector-node': 'FlatButton',
            'data-codecept-selector-file': 'ContentCompareDialog'
        }, _defineProperty(_React$createElement20, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement20, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement20, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement20, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement20))];

        var windowHeight = this.state.windowHeight - 120;

        //const compareDialogHeaderProps = _.pick(this.props, ['delegateClose', 'delegateReject', 'delegateApprove', 'delegateEdit'])
        var compareDialogHeaderProps = _.pick(this.props, Object.keys(CompareDialogHeader.propTypes));
        var compareTabsProps = _.pick(this.props, Object.keys(CompareTabs.propTypes));

        var notification = this.props.children ? this.props.children : null; // why is children not an array

        return _react2.default.createElement(
            _Dialog2.default,
            (_React$createElement23 = {
                title: _react2.default.createElement(CompareDialogHeader, _extends({}, compareDialogHeaderProps, (_extends5 = {
                    'data-codecept-selector-node': 'CompareDialogHeader',
                    'data-codecept-selector-file': 'ContentCompareDialog'
                }, _defineProperty(_extends5, 'data-codecept-selector-node', 'CompareDialogHeader'), _defineProperty(_extends5, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_extends5, 'data-codecept-selector-node', 'CompareDialogHeader'), _defineProperty(_extends5, 'data-codecept-selector-file', 'ContentCompareDialog'), _extends5))),
                modal: false,
                open: this.props.open,
                onRequestClose: this.props.delegateClose,
                bodyStyle: styles.dialogContent,
                style: {
                    zIndex: 1501
                },
                contentStyle: {
                    height: windowHeight,
                    maxWidth: 'none'
                },
                className: 'ContentCompareDialog-' + rubyWords.slug(this.props.title),
                'data-codecept-selector-node': 'Dialog',
                'data-codecept-selector-file': 'ContentCompareDialog'
            }, _defineProperty(_React$createElement23, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_React$createElement23, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement23, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_React$createElement23, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement23),
            function () {
                if (_this3.props.isLoading) {
                    var _React$createElement21, _React$createElement22;

                    return _react2.default.createElement(
                        'div',
                        (_React$createElement22 = {
                            'data-codecept-selector-node': 'div',
                            'data-codecept-selector-file': 'ContentCompareDialog'
                        }, _defineProperty(_React$createElement22, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement22, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement22, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement22, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement22),
                        _react2.default.createElement(_RefreshIndicator2.default, (_React$createElement21 = {
                            status: 'loading',
                            style: styles.LoadingIndicator,
                            top: 0,
                            left: 0,
                            'data-codecept-selector-node': 'LoadingIndicator',
                            'data-codecept-selector-file': 'ContentCompareDialog'
                        }, _defineProperty(_React$createElement21, 'data-codecept-selector-node', 'LoadingIndicator'), _defineProperty(_React$createElement21, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_React$createElement21, 'data-codecept-selector-node', 'LoadingIndicator'), _defineProperty(_React$createElement21, 'data-codecept-selector-file', 'ContentCompareDialog'), _React$createElement21))
                    );
                } else {
                    var _extends6;

                    return _react2.default.createElement(CompareTabs, _extends({}, compareTabsProps, (_extends6 = {
                        windowHeight: windowHeight,
                        notification: notification, 'data-codecept-selector-node': 'CompareTabs',
                        'data-codecept-selector-file': 'ContentCompareDialog'
                    }, _defineProperty(_extends6, 'data-codecept-selector-node', 'CompareTabs'), _defineProperty(_extends6, 'data-codecept-selector-file', 'ContentCompareDialog'), _defineProperty(_extends6, 'data-codecept-selector-node', 'CompareTabs'), _defineProperty(_extends6, 'data-codecept-selector-file', 'ContentCompareDialog'), _extends6)));
                }
            }()
        );
    },

    componentDidMount: function componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
    },
    componentWillUnmount: function componentWillUnmount() {
        //# stop listening to layout width changes
        window.removeEventListener('resize', this.onWindowResize);
    }
    //# custom handlers
    , onWindowResize: function onWindowResize(_event) {
        this.setState({ windowHeight: getWindowHeight() });
    }
});
exports.default = CompareDialog;