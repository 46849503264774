'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _PermissionsConnector = require('./reactComponents/PermissionsConnector');

var _PermissionsConnector2 = _interopRequireDefault(_PermissionsConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var RouteParser = require('route-parser');
var React = require('react');
var request = require('@rubyapps/ruby-superagent');

var RubyComponent = require('@rubyapps/ruby-component');
var RubyComponentForms = require('@rubyapps/ruby-component-forms');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;
var getPermissionItemsByKeyForSelfModule = require('../common/permissionItems');

var TEMPLATE_TYPES = require('@rubyapps/ruby-component-plugin-template-editor/src/common/constants').TEMPLATE_TYPE_VALUES;

var PROPOSAL_SUBSITE_ID = 2;

var accessControlMixin = require('@rubyapps/ruby-component-mixin-access-control');
var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');

var rubyAccessManager = require('@rubyapps/ruby-access-manager');

var RCPermissions = RubyComponent.createClass({
    mixins: [accessControlMixin, baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {
        permissionItemsByKey: PropTypes.object,
        optionsEndpointTemplate: PropTypes.string,
        permissionItemsByKeyAugmentor: PropTypes.func
    },
    componentName: componentName,
    action: require('./action'),
    getInitialState: function getInitialState() {
        var key = this.props.key;

        var initialState = fieldValidationMixin.getInitialState.apply(this);

        var initialValue = _lodash2.default.get(initialState, ['fields', key, 'value']);

        if (_lodash2.default.isNil(initialValue)) {
            _lodash2.default.set(initialState, ['fields', key, 'value'], {
                toggledByPermId: {},
                idsByPermId: {}
            });
        }

        return initialState;
    },
    getDefaultProps: function getDefaultProps() {
        return {
            optionsEndpointTemplate: ':restApiRoot/content/:templateKeyword/options?ruby_client_fk=:client&content_subsite_fk=:subsite',
            permissionItemsByKeyAugmentor: function permissionItemsByKeyAugmentor(permissionItemsByKey) {
                return permissionItemsByKey;
            }
        };
    },
    curriedGetOptionsEndpoint: function curriedGetOptionsEndpoint() {
        var _this = this;

        var optionsEndpointParser = new RouteParser(this.props.optionsEndpointTemplate);
        return function (_endpointParams) {
            var endpointParams = _extends({}, _endpointParams, {
                templateKeyword: _lodash2.default.castArray(_endpointParams.templateKeyword).join(',')
            });
            var frontendSettings = _this.getFrontendSettings();
            var restApiRoot = frontendSettings.restApiRoot;

            var clientInfo = _this.getRubyClientInfo_forRoute('/app/content');

            return optionsEndpointParser.reverse(_extends({ restApiRoot: restApiRoot }, clientInfo, endpointParams));
        };
    },
    reducer: function reducer(state, action) {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        var type = action.type,
            payload = action.payload;


        switch (type) {
            case TYPES.SET_PERMISSION_ITEMS:
                return _extends({}, state, {
                    permissionItemsByKey: payload.items
                });
            case TYPES.SET_ROOT_PERMISSION_ITEM_KEY:
                return _extends({}, state, {
                    rootPermissionItemKey: payload.key
                });
            case TYPES.CUSTOM_RESET_STATE:
                return _extends({}, this.getInitialState(), _lodash2.default.pick(state, ['permissionItemsByKey']));
            default:
                return state;
        }
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();

        var formsID = this.props.formsID || RubyComponentForms.CONSTANTS.COMPONENT_NAME;
        var formsModule = root.findDescendentByID(formsID);
        var formsSelector = formsModule.getDefaultSelector();

        return {
            selfSelector: selfSelector,
            formsModule: formsModule,
            formsSelector: formsSelector
        };
    },
    getReactClass: function getReactClass() {
        return _PermissionsConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var PermissionsComponent = this.getReactClass();

        return React.createElement(PermissionsComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'PermissionsComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'PermissionsComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'PermissionsComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS,

    onReduxInit: function onReduxInit(dispatch) {
        var _this2 = this;

        var selfModule = this;
        var store = this.getStore();

        var _getDependencies = this.getDependencies(),
            formsModule = _getDependencies.formsModule;

        return store.subscribe(function () {

            var currentForms = _this2._forms;
            var newForms = formsModule.getState();
            if (currentForms != newForms) {
                _this2._forms = newForms;

                var props_permissionItemsByKey = _lodash2.default.get(selfModule, 'props.permissionItemsByKey');
                var self_permissionItemsByKey = !props_permissionItemsByKey ? getPermissionItemsByKeyForSelfModule(selfModule) : null;

                var permissionItemsByKeyAugmentor = _lodash2.default.get(selfModule, 'props.permissionItemsByKeyAugmentor');
                var permissionItemsByKey = permissionItemsByKeyAugmentor(props_permissionItemsByKey || self_permissionItemsByKey);
                //# NOTE: do not use 'permissionItemsByKeyAugmentor' if possible
                //# this is only there for backwards compatibility with supporting permissions for external systems (like old ruby)

                _this2._cached_permissionItemsByKey = permissionItemsByKey;

                _this2._hydratePermissionItemsByKey(permissionItemsByKey);
            }
        });
    },

    _formValueToLocalState: function _formValueToLocalState(formValue, dispatchOrCollect, isError, entireFormValue) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        var self = this;
        var selfID = this.getID();
        var selfKey = this.props.key;

        //# support omit field picker
        var shouldOmitField = _lodash2.default.isFunction(options.omitFieldPicker) && options.omitFieldPicker.apply(options, [this].concat(Array.prototype.slice.call(arguments)));
        if (shouldOmitField) {
            return undefined;
        }

        var store = this.getStore();
        var action = this.getAction();
        var _action$generators = action.generators,
            setFieldValueByKey = _action$generators.setFieldValueByKey,
            setFieldErrorMessageByKey = _action$generators.setFieldErrorMessageByKey;


        var formValueForKey = formValue[selfKey];
        var promiseArr = [];
        if (isError) {
            promiseArr.push(dispatchOrCollect(setFieldErrorMessageByKey(formValueForKey, selfKey)));
        } else {
            var preppedLocalStateValue = formValue[selfKey + '_fieldValue'];
            if (preppedLocalStateValue) {
                promiseArr.push(dispatchOrCollect(setFieldValueByKey(self.fieldValueFromFormValue_forKey(preppedLocalStateValue, selfKey), selfKey, true)));
            } else {
                promiseArr.push(this.backwardsCompatibleFormValueToLocalState(formValue, dispatchOrCollect, isError));
            }
        }

        return Promise.all(promiseArr);
    },
    _formValueFromLocalState: function _formValueFromLocalState(selfState, isError, predicateFormatter, entireState) {
        var _ref;

        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        if (selfState == undefined) {
            return undefined;
        }
        var selfID = this.getID();
        var selfKey = this.props.key;

        var selfHasFields = selfState.hasOwnProperty('fields');

        var fieldValue = this.getFieldValue() || [];
        var toggledByPermId = fieldValue.toggledByPermId,
            idsByPermId = fieldValue.idsByPermId;


        var permissionItemsByKey = _lodash2.default.get(selfState, 'permissionItemsByKey');

        //# Collect the page specific ids and keep a running tally of the permKeys that page page specific ids
        var idsByPermId_noEmptyArrs = _lodash2.default.omitBy(idsByPermId, function (idsArr) {
            return !Array.isArray(idsArr) || idsArr.length == 0;
        });

        var sitemapPageIds = _lodash2.default.get(idsByPermId, 'sitemap', []);
        var doesSitemapHavePageIds = Object.keys(sitemapPageIds).length > 0;

        var toggledByPermIdIncludingAncestors = this.toggledByPermIdIncludingAncestors(selfState);
        var filteredToggledByPermId = doesSitemapHavePageIds ? _lodash2.default.omit(toggledByPermIdIncludingAncestors, ['modifySitemap']) : toggledByPermIdIncludingAncestors;
        //# Ignore the modifySitemap toggle if we have page specific permissions
        //# NOTE: need to use toggledByPermIdIncludingAncestors because we want to implicitly include any ancestor values
        //# typically ancestor values don't have values but in the case of Spaces > Update, it does

        //# Collect permission objects that do not have associated page specific ids, 
        //# but make sure to iterate through the values and modify the action items
        //# TODO: we should refactor this module to mimic the FormJS structure
        //# whereby we allow plugins to specify their own value mutations
        //# this current implementation requires a flattened structure (toggledByPermId)
        //# but we should just leave it nested to better mimic the visual structure
        //# and datastructure that we want
        var selectedPermissions = _lodash2.default.flattenDeep(Object.keys(_lodash2.default.pickBy(filteredToggledByPermId, function (value) {
            return value;
        })).map(function (permItemKey) {
            var permItem = permissionItemsByKey[permItemKey] || {};
            var inheritPageSpecificityFromId = permItem.inheritPageSpecificityFromId;
            //# permItem.templateKeyword may exist

            var value = permItem.value;

            //# normalize to array|null;
            var finalizedValue = Array.isArray(value) ? value : value != null ? [value] : null;

            //# if the perm key exists in idsByPermId_noEmptyArrs;
            //# NOTE: hacky to support page specificity
            //const permItemKey_forPageSpecificIds = permItemKey == 'modifySitemap'? 'sitemap' :permItemKey;

            var permItemKey_forPageSpecificIds = idsByPermId_noEmptyArrs.hasOwnProperty(permItemKey) ? permItemKey : idsByPermId_noEmptyArrs.hasOwnProperty(inheritPageSpecificityFromId) ? inheritPageSpecificityFromId : null;
            //# NOTE: inheritPageSpecificityFromId is hacky, but for the nested action-specific toggles currently only used in the websites plugin


            if (permItemKey_forPageSpecificIds) {
                var pageSpecificIds = idsByPermId_noEmptyArrs[permItemKey_forPageSpecificIds];

                var permItem_templateIds = permItem.templateId;

                if (permItem_templateIds) {
                    finalizedValue = finalizedValue.map(function (permObject) {
                        if (permObject.hasOwnProperty('template') && _lodash2.default.includes(permItem_templateIds, permObject.template)) {
                            return [_extends({}, permObject, { action: 'get' }), _extends({ id: pageSpecificIds }, permObject)]; //# a bit dirty for sitemap templates because we're including all of the
                            //# page ids wholesale
                        } else {
                            return permObject;
                        }
                    });
                }
            }

            return finalizedValue;
        })).filter(function (val) {
            return val != null;
        }); //# TODO: consider removing dups

        var finalizedFieldValue = {
            toggledByPermId: toggledByPermId,
            idsByPermId: _lodash2.default.pick(idsByPermId, Object.keys(_lodash2.default.pickBy(toggledByPermId, function (toggled) {
                return toggled;
            })))
        };

        return selfHasFields ? (_ref = {}, _defineProperty(_ref, selfKey, selectedPermissions), _defineProperty(_ref, selfKey + '_fieldValue', finalizedFieldValue), _ref) : undefined;
    }

    //# == UTILITY ==
    , toggledByPermIdIncludingAncestors: function toggledByPermIdIncludingAncestors(localState) {
        if (!localState) {
            localState = this.getState();
        }

        var permissionItemsByKey = localState.permissionItemsByKey || {};
        var fieldValue = this.getFieldValueFromLocalState(localState);
        var toggledByPermId = fieldValue.toggledByPermId,
            idsByPermId = fieldValue.idsByPermId;


        return toggledByPermId;

        //# DEPRECATED: we don't need this now that we figure out the ancestor toggle state
        //# when we toggle values
        //# however, this is useful if we want to migrate the toggle state
        var parentItems = _lodash2.default.filter(permissionItemsByKey, function (permissionItem) {
            var children = permissionItem.children;
            return children && Array.isArray(children) && children.length > 0;
        });
        var parentItemKeys = parentItems.map(function (item) {
            return item.permKey;
        });

        var positivelyToggledByPermId = _lodash2.default.pickBy(toggledByPermId, function (val) {
            return val;
        });
        var toggledPermItemsByKey = _lodash2.default.pick(permissionItemsByKey, Object.keys(positivelyToggledByPermId));
        var toggledParentKeys = Object.keys(_lodash2.default.reduce(toggledPermItemsByKey, function (collector, toggledPermItem, permKey) {
            if (toggledPermItem.hasOwnProperty('parentKey')) {

                //# walk up the tree
                var currentParentKey = toggledPermItem.parentKey;
                do {
                    collector[currentParentKey] = true;
                    currentParentKey = permissionItemsByKey[currentParentKey].parentKey;
                } while (currentParentKey);
            }

            return collector;
        }, {}));

        var unToggledParentKeys = parentItemKeys.filter(function (key) {
            return toggledParentKeys.indexOf(key) === -1;
        });

        var toggledKeys = [].concat(Object.keys(_lodash2.default.pickBy(toggledByPermId, function (value) {
            return value;
        })), toggledParentKeys).filter(function (key) {
            return unToggledParentKeys.indexOf(key) === -1;
        });

        var finalToggledByPermId = toggledKeys.reduce(function (collector, value) {
            collector[value] = true;
            return collector;
        }, {});

        return finalToggledByPermId;
    },
    _hydratePermissionItemsByKey: function _hydratePermissionItemsByKey(permissionItemsByKey) {
        var self = this;
        var store = this.getStore();

        var _getDependencies2 = this.getDependencies(),
            formsSelector = _getDependencies2.formsSelector;

        var forms = formsSelector(store.getState());
        var templates = _lodash2.default.values(forms).filter(function (template) {
            return TEMPLATE_TYPES.indexOf(template.templateType) >= 0;
        }).sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });

        var permissionsMap_withHydratedValues = getAutoPopulatedPerms_fromTemplates_andPermItems(templates, permissionItemsByKey, self);

        var permissionsMap_withId = _lodash2.default.mapValues(permissionsMap_withHydratedValues, function (val, permKey) {
            return _extends({}, val, { permKey: permKey });
        });

        //# hydrating the perm items with parentKey
        _lodash2.default.forEach(permissionsMap_withId, function (permItem, permKey) {
            var children = permItem.children ? _lodash2.default.castArray(permItem.children) : [];

            children.forEach(function (childKey) {
                permissionsMap_withId[childKey].parentKey = permKey;
            });
        });

        //# enabled page specificity for sitemap and structured data
        var permissionsMap_withPageSpecificFlag = _lodash2.default.mapValues(permissionsMap_withId, function (val, key) {
            if (val.isStructuredData) {
                return _extends({ shouldEnablePageSpecificity: true }, val);
            } else if (val.isLookup) {
                return _extends({ shouldEnablePageSpecificity: true }, val);
            } else if (val.populateWithValueFor == 'sitemap') {
                //# NOTE: hacky to support page specificity
                var sitemapTemplates = templates.filter(function (template) {
                    return template.templateType === 'sitemap_template';
                });
                return _extends({
                    shouldEnablePageSpecificity: true,
                    templateKeyword: sitemapTemplates.map(function (template) {
                        return template.key;
                    }),
                    templateId: sitemapTemplates.map(function (template) {
                        return template.id.toString();
                    })
                }, val);
            } else if (val.populateWithValueFor == 'modify_sitemap') {
                //# NOTE: hacky to support page specificity
                var _sitemapTemplates = templates.filter(function (template) {
                    return template.templateType === 'sitemap_template';
                });
                return _extends({
                    templateKeyword: _sitemapTemplates.map(function (template) {
                        return template.key;
                    }),
                    templateId: _sitemapTemplates.map(function (template) {
                        return template.id.toString();
                    })
                }, val);
            } else {
                return val;
            }
        });

        store.dispatch(self.getAction().generators.setPermissionItems(permissionsMap_withPageSpecificFlag));

        return permissionsMap_withPageSpecificFlag;
    },

    backwardsCompatibleFormValueToLocalState: function backwardsCompatibleFormValueToLocalState(formValue, dispatchOrCollect, isError) {
        var selfID = this.getID();
        var selfKey = this.props.key;

        var store = this.getStore();
        var action = this.getAction();
        var setFieldValueByKey = action.generators.setFieldValueByKey;


        var formValueForKey = formValue[selfKey];

        var _getDependencies3 = this.getDependencies(),
            formsSelector = _getDependencies3.formsSelector;

        var forms = formsSelector(store.getState());
        var templates = _lodash2.default.values(forms).filter(function (template) {
            return TEMPLATE_TYPES.indexOf(template.templateType) >= 0;
        }).sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });

        var permissionItemsByKey = this._cached_permissionItemsByKey || _lodash2.default.get(this, 'props.permissionItemsByKey') || {};
        var autoPopulatedPermissions = getAutoPopulatedPerms_fromTemplates_andPermItems(templates, permissionItemsByKey, this);
        var autoPopulatedPermissionItemsByKeyWithIds = _lodash2.default.mapValues(autoPopulatedPermissions, function (val, permKey) {
            return _extends({}, val, { permKey: permKey });
        });

        var selectedAutoPopulatedPermissionItemKeys = _lodash2.default.filter(autoPopulatedPermissionItemsByKeyWithIds, function (permissionItem) {
            return getIsSelected_fromPermItem_permItemsByKey_andFormValue(permissionItem, autoPopulatedPermissionItemsByKeyWithIds, formValueForKey);
        }).reduce(function (collector, item) {
            collector[item.permKey] = true;
            return collector;
        }, {});
        //.map( item => item.permKey );


        var preppedLocalStateValue = {
            toggledByPermId: selectedAutoPopulatedPermissionItemKeys,
            idsByPermId: {}
        };

        dispatchOrCollect(setFieldValueByKey(this.fieldValueFromFormValue_forKey(preppedLocalStateValue, selfKey), selfKey, true));
    }
});

function getAutoPopulatedPerms_fromTemplates_andPermItems(templates, permissionItems, selfModule) {
    var valuesByKeyword = getPermissionValuesByKeyword_fromTemplates(templates, selfModule);

    var _getDynamicPermission = getDynamicPermissions_fromTemplates(templates),
        dynamicPermissionsByKey = _getDynamicPermission.dynamicPermissionsByKey,
        childrenByKeyword = _getDynamicPermission.childrenByKeyword;

    var mergedPermissions = _extends({}, permissionItems, dynamicPermissionsByKey);

    var retVal = getAutoPopulatedPerms_fromMergedPerms_childrenByKeyword_andValuesByKeyword(mergedPermissions, childrenByKeyword, valuesByKeyword);

    return retVal;
}

function getAutoPopulatedPerms_fromMergedPerms_childrenByKeyword_andValuesByKeyword(permissions, childrenByKeyword, valuesByKeyword) {
    return _lodash2.default.mapValues(permissions, function (item) {
        var children = [].concat(item.children, childrenByKeyword[item.populateWithChildrenFor]).filter(function (child) {
            return child != null;
        });

        var baseItemValue = item.value ? _lodash2.default.castArray(item.value) : [];
        var value = children.length !== 0 ? item.value : baseItemValue.concat(valuesByKeyword[item.populateWithValueFor]).filter(function (val) {
            return val != null;
        });

        return _extends({}, item, {
            children: children,
            value: value
        });
    });
}

function getDynamicPermissions_fromTemplates(templates) {
    var _getSDPerms_fromTempl = getSDPerms_fromTemplates(templates),
        structuredDataPermissionsByKey = _getSDPerms_fromTempl.structuredDataPermissionsByKey,
        structuredDataPermissionKeys = _getSDPerms_fromTempl.structuredDataPermissionKeys;

    var _getPerms_fromTemplat = getPerms_fromTemplates_forType(templates, 'lookup_template'),
        lookupPermissionsByKey = _getPerms_fromTemplat.permissionsByKey,
        lookupPermissionKeys = _getPerms_fromTemplat.permissionKeys;

    var childrenByKeyword = {
        'structured_data': structuredDataPermissionKeys,
        'lookups': lookupPermissionKeys
    };

    var dynamicPermissionsByKey = _extends({}, structuredDataPermissionsByKey, lookupPermissionsByKey);

    return {
        childrenByKeyword: childrenByKeyword,
        dynamicPermissionsByKey: dynamicPermissionsByKey
    };
}

function getPermissionValuesByKeyword_fromTemplates(templates, selfModule) {
    return {
        template: getTemplatePermValue_fromTemplates(templates),
        sitemap: getSitemapPermValue_fromTemplates(templates),
        modify_sitemap: getModifySitemapPermValue_fromTemplates(templates),
        lookups: getLookupPermValue_fromTemplates(templates) //# DEPRECATED - should rely on auto-determined permissionItemsByKey
        , proposals: getProposalPermValue_fromTemplates(templates, selfModule),
        edit_proposals: getEditProposalPermValue_fromTemplates(templates, selfModule),
        generate_proposals: getGenerateProposalPermValue_fromTemplates(templates, selfModule)
    };
}

function getTemplatePermValue_fromTemplates(templates) {
    return [].concat({ model: 'Template', ruby_client: 3, action: '*' }, templates.map(function (template) {
        return {
            model: 'template',
            template: template.id.toString(),
            ruby_client: 3,
            subsite: 1,
            action: '*'
        };
    }));
}

function getSitemapPermValue_fromTemplates(templates) {
    return [].concat({
        keyword: 'content_sitemap_section',
        ruby_client: 3,
        action: '*'
    }, [].concat.apply([], templates.filter(function (template) {
        return template.templateType === 'sitemap_template';
    }).map(function (template) {
        return {
            model: 'content',
            template: template.id.toString(),
            ruby_client: 3,
            subsite: 1,
            action: '*'
        };
    })));
}

function getModifySitemapPermValue_fromTemplates(templates) {
    return [].concat({
        keyword: 'modify_sitemap',
        ruby_client: 3,
        action: '*'
    }, [].concat.apply(templates.filter(function (template) {
        return template.templateType === 'sitemap_template';
    }).map(function (template) {
        return {
            model: 'content',
            template: template.id.toString(),
            ruby_client: 3,
            subsite: 1,
            action: '*'
        };
    })));
}

function getLookupPermValue_fromTemplates(templates) {
    return [].concat({
        keyword: 'admin_lookups_section',
        ruby_client: 3,
        action: '*'
    }, [].concat.apply(templates.filter(function (template) {
        return template.templateType === 'lookup_template';
    }).map(function (template) {
        return {
            model: 'content',
            template: template.id.toString(),
            ruby_client: 3,
            subsite: 1,
            action: '*'
        };
    })));
}

function getProposalPermValue_fromTemplates(templates, selfModule) {
    var proposalRoute = '/app/proposal';
    var proposalClientInfo = selfModule.getRubyClientInfo_forRoute(proposalRoute);
    var proposalSubsiteId = proposalClientInfo.subsite;
    var proposalClientId = proposalClientInfo.client;

    return [].concat({
        // allow saving wihout error to telling user to send request for approval
        keyword: 'can_generate_proposal_doc',
        subsite: proposalSubsiteId,
        ruby_client: proposalClientId,
        action: '*'
    }, {
        keyword: 'hydrate_sitemap',
        ruby_client: proposalClientId,
        action: '*'
    }, {
        // allow saving wihout error to telling user to send request for approval
        keyword: 'can_publish_content',
        subsite: proposalSubsiteId,
        ruby_client: proposalClientId,
        action: '*'
        //# TODO: find the proposal template
        //# TODO: find all propopsal page templates
        //# TODO: find all templates that can ad to proposal
    }, [].concat.apply(templates.filter(function (template) {
        return template.key == 'proposal' || template.templateType == 'proposal_page_template' || template.ruby_subsite_fk == null || _lodash2.default.isArray(template.ruby_subsite_fk) && template.ruby_subsite_fk.length === 0 || _lodash2.default.isArray(template.ruby_subsite_fk) && _lodash2.default.includes(template.ruby_subsite_fk, PROPOSAL_SUBSITE_ID);
    }).map(function (template) {
        return {
            model: 'content',
            template: template.id.toString(),
            ruby_client: 3,
            subsite: proposalSubsiteId,
            action: '*'
        };
    })));
}

function getEditProposalPermValue_fromTemplates(templates, selfModule) {
    var proposalRoute = '/app/proposal';
    var proposalClientInfo = selfModule.getRubyClientInfo_forRoute(proposalRoute);
    var proposalSubsiteId = proposalClientInfo.subsite;
    var proposalClientId = proposalClientInfo.client;

    return [].concat({
        // allow saving wihout error to telling user to send request for approval
        keyword: 'can_publish_content',
        subsite: proposalSubsiteId,
        ruby_client: proposalClientId,
        action: '*'
    }, {
        keyword: 'hydrate_sitemap',
        ruby_client: proposalClientId,
        action: '*'
        //# find the proposal template
        //# find all propopsal page templates
        //# find all templates that can be added to proposal
    }, [].concat.apply(templates.filter(function (template) {
        return template.key == 'proposal' || template.templateType == 'proposal_page_template' || template.ruby_subsite_fk == null || _lodash2.default.isArray(template.ruby_subsite_fk) && template.ruby_subsite_fk.length === 0 || _lodash2.default.isArray(template.ruby_subsite_fk) && _lodash2.default.includes(template.ruby_subsite_fk, PROPOSAL_SUBSITE_ID);
    }).map(function (template) {
        return {
            model: 'content',
            template: template.id.toString(),
            ruby_client: proposalClientId,
            subsite: proposalSubsiteId,
            action: '*'
        };
    })));
}

function getGenerateProposalPermValue_fromTemplates(templates, selfModule) {
    var proposalRoute = '/app/proposal';
    var proposalClientInfo = selfModule.getRubyClientInfo_forRoute(proposalRoute);
    var proposalSubsiteId = proposalClientInfo.subsite;
    var proposalClientId = proposalClientInfo.client;

    return [].concat({
        // allow saving wihout error to telling user to send request for approval
        keyword: 'can_publish_content',
        subsite: proposalSubsiteId,
        ruby_client: proposalClientId,
        action: '*'
    }, {
        keyword: 'hydrate_sitemap',
        ruby_client: proposalClientId,
        action: '*'
    }, {
        // allow saving the page after generating a word doc,
        // which sets the word_download_url field
        keyword: 'modify_word_download_url',
        ruby_client: proposalClientId,
        action: 'edit'
    }, {
        // allow saving wihout error to telling user to send request for approval
        keyword: 'can_generate_proposal_doc',
        subsite: proposalSubsiteId,
        ruby_client: proposalClientId,
        action: '*'
        //# find the proposal template
        //# find all propopsal page templates
        //# find all templates that can be added to proposal
    }, [].concat.apply(templates.filter(function (template) {
        return template.key == 'proposal';
    }).map(function (template) {
        return {
            model: 'content',
            template: template.id.toString(),
            ruby_client: proposalClientId,
            subsite: proposalSubsiteId,
            action: 'get'
        };
    })));
}

function getPerms_fromTemplates_forType(templates, type) {
    var permissionsByKey = templates.filter(function (template) {
        return template.templateType === type;
    }).map(function (template) {
        return {
            label: template.name,
            isLookup: true,
            templateKeyword: [template.key],
            templateId: [template.id.toString()],
            value: {
                model: 'content',
                template: template.id.toString(),
                ruby_client: 3,
                subsite: 1,
                action: '*'
            }
        };
    }).reduce(function (result, templateTogglePermObject, idx) {
        var key = 'lookup_' + templateTogglePermObject.templateKeyword; //prefix + idx;
        result[key] = templateTogglePermObject;
        return result;
    }, {});

    var permissionKeys = Object.keys(permissionsByKey);

    return {
        permissionKeys: permissionKeys,
        permissionsByKey: permissionsByKey
    };
}

function getSDPerms_fromTemplates(templates) {
    var structuredDataPermissionsByKey = templates.filter(function (template) {
        return template.templateType === 'structured_data_template' && !_lodash2.default.includes(['global_data', 'rubyapps_settings'], template.key);
    }).map(function (template) {
        return {
            label: template.name,
            isStructuredData: true,
            templateKeyword: [template.key],
            templateId: [template.id.toString()],
            value: {
                model: 'content',
                template: template.id.toString(),
                ruby_client: 3,
                subsite: 1,
                action: '*'
            }
        };
    }).reduce(function (result, templateTogglePermObject, idx) {
        var key = 'sd_' + templateTogglePermObject.templateKeyword; //prefix + idx;
        result[key] = templateTogglePermObject;
        return result;
    }, {});

    var structuredDataPermissionKeys = Object.keys(structuredDataPermissionsByKey);

    return {
        structuredDataPermissionKeys: structuredDataPermissionKeys,
        structuredDataPermissionsByKey: structuredDataPermissionsByKey
    };
}

//# == FOR BACKWARDS COMPATIBILITY MIGRATION ===============================================//

function getIsSelected_fromPermItem_permItemsByKey_andFormValue(permissionItem, permissionItemsByKey, formValue) {
    var value = permissionItem.value;
    var children = permissionItem.children;

    if ((value == null || Array.isArray(value) && value.length === 0) && (children == null || Array.isArray(children) && children.length === 0)) {
        return false;
    }

    return is_permissionValue_inFormValue(Array.isArray(value) ? value : [value], formValue);
}

function is_permissionValue_inFormValue(permissionValues, formValue) {
    return permissionValues.filter(function (val) {
        return val != null;
    }).length > 0 && _lodash2.default.every(permissionValues.map(function (value) {
        return rubyAccessManager.does_permissionList_includePermission(formValue, value);
    }));
}

function are_permissionChildren_inFormValue_withPermissionItemsByKey(children, formValue, permissionItemsByKey) {
    return children && _lodash2.default.some(children.map(function (childKey) {
        return permissionItemsByKey[childKey];
    }).map(function (permissionItem) {
        return getIsSelected_fromPermItem_permItemsByKey_andFormValue(permissionItem, permissionItemsByKey, formValue);
    }));
    return children == null || Array.isArray(children) && children.length === 0 ? true : _lodash2.default.some(children.map(function (childKey) {
        return permissionItemsByKey[childKey];
    }).map(function (permissionItem) {
        return getIsSelected_fromPermItem_permItemsByKey_andFormValue(permissionItem, permissionItemsByKey, formValue);
    }));
}

//# == END BACKGROUNDS COMPATIBILITY MIGRATION ================================================//


module.exports = RCPermissions;