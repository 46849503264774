'use strict';

var setupConfig = require('./setup_config');
// Browserify does not play nicely with dynamically loaded requires.
// See https://github.com/substack/node-browserify/issues/377

var mergedConfig = setupConfig({
    common: [{}]
});

var RubyConfig = function RubyConfig(namespace) {
    return mergedConfig[namespace];
};

RubyConfig._mergedConfig = mergedConfig;

module.exports = RubyConfig;