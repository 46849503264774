'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GRIDDLE_LOADED_DATA = GRIDDLE_LOADED_DATA;
exports.AFTER_REDUCE = AFTER_REDUCE;
exports.GRIDDLE_SET_PAGE_SIZE = GRIDDLE_SET_PAGE_SIZE;
exports.GRIDDLE_GET_PAGE = GRIDDLE_GET_PAGE;
exports.GRIDDLE_NEXT_PAGE = GRIDDLE_NEXT_PAGE;
exports.GRIDDLE_PREVIOUS_PAGE = GRIDDLE_PREVIOUS_PAGE;
exports.GRIDDLE_REFRESH_CURRENT_PAGE = GRIDDLE_REFRESH_CURRENT_PAGE;
exports.GRIDDLE_FILTERED_BY_COLUMN = GRIDDLE_FILTERED_BY_COLUMN;
exports.GRIDDLE_FILTERED = GRIDDLE_FILTERED;
exports.GRIDDLE_SORT = GRIDDLE_SORT;

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
  The handler that happens when data is loaded.
  Needs to set the:
    Properties,
    Data,
    Pagination buttons
    visible data
    it should sort the data if a sort is specified
*/
function GRIDDLE_LOADED_DATA(state, action, helpers) {
  if (action.data == undefined) {
    //# assume that some other plugin already handled loading data
    return state;
  }

  var columns = action.data.length > 0 ? Object.keys(action.data[0]) : [];
  //set state's data to this
  var tempState = state.set('data', helpers.addKeyToRows(_immutable2.default.fromJS(action.data))).set('allColumns', columns).setIn(['pageProperties', 'maxPage'], helpers.getPageCount(action.data.length, state.getIn(['pageProperties', 'pageSize']))).set('loading', false);

  var columnProperties = tempState.get('renderProperties').get('columnProperties');

  return columnProperties ? helpers.sortDataByColumns(tempState, helpers).setIn(['pageProperties', 'currentPage'], 1) : tempState;
}

function AFTER_REDUCE(state, action, helpers) {
  var tempState = state.set('visibleData', helpers.getVisibleData(state)).setIn(['pageProperties', 'maxPage'], helpers.getPageCount(helpers.getDataSetSize(state), state.getIn(['pageProperties', 'pageSize'])));

  return tempState.set('hasNext', helpers.hasNext(tempState)).set('hasPrevious', helpers.hasPrevious(tempState));
}

/*
  Needs to update:
    visible data,
    max pages,
    hasNext,
    hasPrevious
*/
function GRIDDLE_SET_PAGE_SIZE(state, action, helpers) {
  var pageSizeState = state.setIn(['pageProperties', 'currentPage'], 1).setIn(['pageProperties', 'pageSize'], action.pageSize);

  var stateWithMaxPage = pageSizeState.setIn(['pageProperties', 'maxPage'], helpers.getPageCount(pageSizeState.get('data').size, action.pageSize));

  return stateWithMaxPage;
}

//TODO: Move the helper function to the method body and call this
//      from next / previous. This will be easier since we have
//      the AFTER_REDUCE stuff now.
function GRIDDLE_GET_PAGE(state, action, helpers) {
  return helpers.getPage(state, action.pageNumber);
}

function GRIDDLE_NEXT_PAGE(state, action, helpers) {
  var currentPage = state.getIn(['pageProperties', 'currentPage']);
  var maxPage = state.getIn(['pageProperties', 'maxPage']);

  return helpers.getPage(state, currentPage < maxPage ? currentPage + 1 : currentPage);
}

function GRIDDLE_PREVIOUS_PAGE(state, action, helpers) {
  var currentPage = state.getIn(['pageProperties', 'currentPage']);

  return helpers.getPage(state, currentPage > 0 ? currentPage - 1 : currentPage);
}

function GRIDDLE_REFRESH_CURRENT_PAGE(state, action, helpers) {
  var currentPage = state.getIn(['pageProperties', 'currentPage']);

  return helpers.getPage(state, currentPage);
}

function GRIDDLE_FILTERED_BY_COLUMN(state, action, helpers) {
  var filters = state.get('columnFilters');
  var column = action.column,
      filter = action.filter;

  var setFilters = filters.filter(function (f) {
    return f.column !== column;
  });

  //are we reseting a filter?
  if (filter !== '') {
    setFilters = setFilters.concat({ filter: filter, column: column });
  }

  return state.set('columnFilters', setFilters).setIn(['pageProperties', 'currentPage'], 1);
}

function GRIDDLE_FILTERED(state, action, helpers) {
  //TODO: Just set the filter and let the visible data handle what is actually shown + next / previous
  return state.set('filter', action.filter).setIn(['pageProperties', 'currentPage'], 1);
}

//TODO: This is a really simple sort, for now
//      We need to add sort type and different sort operations
function GRIDDLE_SORT(state, action, helpers) {
  if (!action.sortColumns || action.sortColumns.length < 1) {
    return state;
  }

  // Update the sort columns
  var tempState = helpers.updateSortColumns(state, action.sortColumns);

  var columnProperties = state.get('renderProperties').get('columnProperties');
  // Sort the data
  return helpers.sortDataByColumns(tempState, helpers).setIn(['pageProperties', 'currentPage'], 1);
}