'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var request = require('@rubyapps/ruby-superagent');
var qs = require('qs');

function generateImagePath(id, width, height, query_params) {
    var path = ['', 'utils', 'modules', id, width, height, 'module-image'].join('/');

    var ext = 'jpg';

    var query_string = qs.stringify(query_params);

    return path + '.' + ext + '?' + query_string;
}

function typesWithID(id) {
    return {
        OPEN_DIALOG: '@@ruby-app/' + id + '/OPEN_DIALOG',
        CLOSE_DIALOG: '@@ruby-app/' + id + '/CLOSE_DIALOG',
        RESET_DIALOG: '@@ruby-app/' + id + '/RESET_DIALOG',
        SNAPSHOT_STATE: '@@ruby-app/' + id + '/SNAPSHOT_STATE',
        REVERT_STATE: '@@ruby-app/' + id + '/REVERT_STATE',
        SET_PREVIEW: '@@ruby-app/' + id + '/SET_PREVIEW',
        CLEAR_PREVIEW: '@@ruby-app/' + id + '/CLEAR_PREVIEW'

    };
}

var generators = {
    //# NOTE: <Repeater> expects this action to be available
    //# if we want the children to react ONLY to being newly added
    onNewlyAdded: function onNewlyAdded() {
        var _getAction = this.getAction(),
            generators = _getAction.generators;

        return generators.openDialogAndSnapshotState();
    }
    //# options.parent_id
    , openDialog: function openDialog(options) {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES;

        return {
            type: TYPES.OPEN_DIALOG
        };
    },
    closeDialog: function closeDialog() {
        var _getAction3 = this.getAction(),
            TYPES = _getAction3.TYPES;

        return {
            type: TYPES.CLOSE_DIALOG
        };
    },
    snapshotState: function snapshotState() {
        var _getAction4 = this.getAction(),
            TYPES = _getAction4.TYPES;

        var formState = this.formValueFromLocalState(this.getState());

        return {
            type: TYPES.SNAPSHOT_STATE,
            payload: {
                state: formState
            }
        };
    },
    revertState: function revertState() {
        var _getAction5 = this.getAction(),
            TYPES = _getAction5.TYPES;

        var _getState = this.getState(),
            stateSnapshot = _getState.stateSnapshot;

        this.formValueToLocalState(stateSnapshot);
        return {
            type: TYPES.REVERT_STATE
        };
    },
    openDialogAndSnapshotState: function openDialogAndSnapshotState() {
        var _getAction6 = this.getAction(),
            generators = _getAction6.generators;

        return function (dispatch) {
            dispatch(generators.openDialog());
            dispatch(generators.snapshotState());
        };
    },
    closeAndResetDialog: function closeAndResetDialog() {
        var _getAction7 = this.getAction(),
            generators = _getAction7.generators;

        return function (dispatch) {
            dispatch(generators.closeDialog());
            dispatch(generators.resetDialog());
        };
    },
    closeAndRevertStateAndResetDialog: function closeAndRevertStateAndResetDialog() {
        var _getAction8 = this.getAction(),
            generators = _getAction8.generators;

        return function (dispatch) {
            dispatch(generators.closeDialog());
            dispatch(generators.revertState());
            dispatch(generators.resetDialog());
        };
    },
    resetDialog: function resetDialog() {
        var _getAction9 = this.getAction(),
            TYPES = _getAction9.TYPES;

        return {
            type: TYPES.RESET_DIALOG
        };
    },

    setPreview: function setPreview(previewUrl) {
        var _getAction10 = this.getAction(),
            TYPES = _getAction10.TYPES;

        return {
            type: TYPES.SET_PREVIEW,
            payload: {
                previewUrl: previewUrl
            }
        };
    },
    clearPreview: function clearPreview(previewUrl) {
        var _getAction11 = this.getAction(),
            TYPES = _getAction11.TYPES;

        return {
            type: TYPES.CLEAR_PREVIEW
        };
    },
    retrievePreview_withFormValue: function retrievePreview_withFormValue(formValue) {
        var _getDependencies = this.getDependencies(),
            generators = _getDependencies.selfAction.generators,
            parentFormKey = _getDependencies.parentFormKey,
            parentKey = _getDependencies.parentKey;

        var selfKey = this.props.key;
        var fieldKey = this.props.parentKey;
        var templateKey = parentFormKey;

        var parentComponent = this.getParent();
        var selfIndex = parentComponent && parentComponent.getIndexForChildId ? parentComponent.getIndexForChildId(selfKey) : 0;

        var data = _extends({ module_type: this.props.type }, formValue[selfKey]);

        var previewImagePath = generateImagePath(selfKey, this.props.width, this.props.height, {
            field_key: fieldKey,
            template_keyword: templateKey,
            idx: selfIndex,
            data: JSON.stringify(data)
        });

        return generators.setPreview(previewImagePath);
    }
};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};