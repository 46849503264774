'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = TemplateKeyToTemplateNameGenerator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

function TemplateKeyToTemplateNameGenerator(options, parentProps) {

    return function TemplateKeyToTemplateName(props) {
        var _React$createElement;

        var templateKey = props.data;
        var formsByKey = parentProps.forms || {};
        var templateName = _lodash2.default.get(formsByKey, [templateKey, 'name']);
        return React.createElement(
            'span',
            (_React$createElement = {
                'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'TemplateKeyToTemplateNameGenerator'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TemplateKeyToTemplateNameGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TemplateKeyToTemplateNameGenerator'), _React$createElement),
            templateName
        );
    };
}