'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Loading = require('@rubyapps/ruby-react-components/src/client/view/Loading');

var _Loading2 = _interopRequireDefault(_Loading);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var styleObject = {
    loaderWrapper: {
        "display": "inline-block",
        "line-height": "10px",
        "width": "20px",
        "height": "20px",
        "margin-top": "-3px"
    }
};

function asyncifyButton(Component) {
    var Component__propTypeKeys = Object.keys(Component.propTypes).concat('onTouchTap');

    //# the assumption is that there's an icon prop
    var AsyncButton = _react2.default.createClass({
        displayName: 'AsyncButton',

        propTypes: _extends({
            isLoading: PropTypes.bool,
            onTouchTap: PropTypes.func
        }, Component.propTypes),
        getDefaultProps: function getDefaultProps() {
            return {
                isLoading: false
            };
        },
        _throttledOnTouchTap: _lodash2.default.throttle(function (event) {
            this.props.onTouchTap && this.props.onTouchTap(event);
        }, 1000, {
            trailing: false
        }),
        _renderLoader: function _renderLoader() {
            var _React$createElement, _React$createElement2;

            return _react2.default.createElement(
                'div',
                (_React$createElement2 = { style: styleObject.loaderWrapper, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'asyncButton'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'asyncButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'asyncButton'), _React$createElement2),
                _react2.default.createElement(_Loading2.default, (_React$createElement = { style: { width: 20, height: 20 }, status: 'loading', 'data-codecept-selector-node': 'RubyLoading',
                    'data-codecept-selector-file': 'asyncButton'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RubyLoading'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'asyncButton'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RubyLoading'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'asyncButton'), _React$createElement))
            );
        },
        getIcon: function getIcon() {
            if (this.props.isLoading) {
                return this._renderLoader();
            } else {
                return this.props.icon;
            }
        },
        render: function render() {
            var _extends2;

            var ComponentProps = _lodash2.default.pick(this.props, Component__propTypeKeys);
            return _react2.default.createElement(Component, _extends({}, ComponentProps, (_extends2 = {
                icon: this.getIcon(),
                disabled: this.props.disabled || this.props.isLoading,
                onTouchTap: this._throttledOnTouchTap,
                'data-codecept-selector-node': 'Component',
                'data-codecept-selector-file': 'asyncButton'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Component'), _defineProperty(_extends2, 'data-codecept-selector-file', 'asyncButton'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Component'), _defineProperty(_extends2, 'data-codecept-selector-file', 'asyncButton'), _extends2)));
        }
    });

    return AsyncButton;
}

exports.default = asyncifyButton;