'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

var _IconMenu = require('material-ui/IconMenu');

var _IconMenu2 = _interopRequireDefault(_IconMenu);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _IconButton = require('material-ui/IconButton/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _moreVert = require('material-ui/svg-icons/navigation/more-vert');

var _moreVert2 = _interopRequireDefault(_moreVert);

var _modeEdit = require('material-ui/svg-icons/editor/mode-edit');

var _modeEdit2 = _interopRequireDefault(_modeEdit);

var _launch = require('material-ui/svg-icons/action/launch');

var _launch2 = _interopRequireDefault(_launch);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var _ = require('lodash');
var routeParser = require('route-parser');

var GriddleModule = require('@rubyapps/griddle');
var customComponents = GriddleModule.customComponents,
    customComponentsByType = GriddleModule.customComponentsByType;


var COMPONENTS_BY_TYPE = customComponentsByType;

//# Material Components

var FlatButton__propTypeKeys = Object.keys(_FlatButton2.default.propTypes);

//# Material UI Icons


var jsonselect = require('JSONSelect');
var statusSelectorString = 'object:has(:root > .key:val("id")):has(.componentName:expr(x != "Hidden")), .componentName:val("ScheduledStatus")';
var statusSelector = jsonselect.compile(statusSelectorString);

module.exports = function (parentProps) {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        editPageComponent = _selfModule$getDepend.editPageComponent,
        getEditEntryUrl_forId = _selfModule$getDepend.getEditEntryUrl_forId,
        formsComponent = _selfModule$getDepend.formsComponent;

    var editPageRouteTemplate = editPageComponent.getRoutePath();
    var editPageRouteParser = new routeParser(editPageRouteTemplate);
    var pushNotification = selfModule.pushNotification.bind(selfModule);
    var showNotificationModal = selfModule.showNotificationModal.bind(selfModule);
    var hierarchical = parentProps.hierarchical;


    var currentTemplateKeyword = _.result(selfModule, 'getState.routeParams.template');
    var currentTemplate = _.result(formsComponent, ['getState', currentTemplateKeyword]) || {};

    var editableStatusFields = statusSelector.match(currentTemplate);

    var disable_unpublish = editableStatusFields.length === 0;

    var canPublish = selfModule.canUserPublish() && !disable_unpublish;

    var _selfModule$props$mod = selfModule.props.modeActionSpec,
        modeActionSpec = _selfModule$props$mod === undefined ? parentProps.modeActionSpec : _selfModule$props$mod;


    var isSelectMode = selfModule.props.mode == 'select';

    var optionsCell = {
        id: 'id',
        sortable: false,
        displayName: '',
        width: 300,
        minWidth: 300,
        customComponent: customComponents.RubyActionsGenerator({
            //# TODO: How can we make this more standardized?
            //# Once we know the url structure for these, we
            //# can start thinking abotu how to construct
            //# the hrefs for the links
            //# Hopefully we can pull the data we need from 'rowData'
            actionsList: [].concat(!_.isNil(modeActionSpec) ? //# Allow for override of the first button like Media Gallery + Media Picker
            {
                render: function modeAction() {
                    var _React$createElement,
                        _this = this,
                        _extends2;

                    var id = _.get(this.props, 'originalData.id');

                    var hydratedModeActionSpec = _.defaults(_.isFunction(modeActionSpec) ? modeActionSpec(this.props) : modeActionSpec, { icon: React.createElement(_launch2.default, (_React$createElement = {
                            'data-codecept-selector-node': 'LaunchIcon',
                            'data-codecept-selector-file': 'columnDefinitionsGenerator'
                        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'LaunchIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'LaunchIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement)) });

                    return React.createElement(_FlatButton2.default, _extends({}, _.pick(hydratedModeActionSpec, FlatButton__propTypeKeys), (_extends2 = {
                        onTouchTap: function onTouchTap(event) {
                            hydratedModeActionSpec.handler(event, id, _this.props.originalData);
                        },
                        'data-codecept-selector-node': 'FlatButton',
                        'data-codecept-selector-file': 'columnDefinitionsGenerator'
                    }, _defineProperty(_extends2, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_extends2, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _extends2)));
                }
            } : [{
                render: function render() {
                    var _React$createElement2, _React$createElement3, _React$createElement4;

                    var url = _.get(this.props, 'originalData.url');

                    if (!url) return null;

                    return React.createElement(
                        'a',
                        (_React$createElement4 = { target: '_blank', href: url, key: 'view', 'data-codecept-selector-node': 'a',
                            'data-codecept-selector-file': 'columnDefinitionsGenerator'
                        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement4),
                        React.createElement(_FlatButton2.default, (_React$createElement3 = {
                            label: 'View',
                            icon: React.createElement(_launch2.default, (_React$createElement2 = {
                                'data-codecept-selector-node': 'LaunchIcon',
                                'data-codecept-selector-file': 'columnDefinitionsGenerator'
                            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'LaunchIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'LaunchIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement2)),
                            'data-codecept-selector-node': 'FlatButton',
                            'data-codecept-selector-file': 'columnDefinitionsGenerator'
                        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement3))
                    );
                }
            }], {
                render: function render() {
                    var _React$createElement5, _React$createElement6, _extends3;

                    var id = _.get(this.props, 'rowData.__metadata.id') || _.get(this.props, 'rowData.id');
                    var disabledForCMS = _.get(this.props, 'rowData.__metadata.__disabledForCMS');
                    var editLink = getEditEntryUrl_forId(id);

                    var anchorProps = {
                        key: 'edit',
                        target: '_blank',
                        href: disabledForCMS ? undefined : editLink
                    };

                    return React.createElement(
                        'a',
                        _extends({}, anchorProps, (_extends3 = {
                            'data-codecept-selector-node': 'a',
                            'data-codecept-selector-file': 'columnDefinitionsGenerator'
                        }, _defineProperty(_extends3, 'data-codecept-selector-node', 'a'), _defineProperty(_extends3, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_extends3, 'data-codecept-selector-node', 'a'), _defineProperty(_extends3, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _extends3)),
                        React.createElement(_FlatButton2.default, (_React$createElement6 = {
                            label: 'Edit',
                            icon: React.createElement(_modeEdit2.default, (_React$createElement5 = {
                                'data-codecept-selector-node': 'PenIcon',
                                'data-codecept-selector-file': 'columnDefinitionsGenerator'
                            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'PenIcon'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'PenIcon'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement5)),
                            disabled: disabledForCMS,
                            onTouchTap: function onTouchTap(e) {
                                if (
                                // left click
                                e.nativeEvent.which === 1) {
                                    if (!isSelectMode) {
                                        //# if select mode, we allow opening in a new tab
                                        parentProps.actions.navigateToPathWithOptions({ path: editLink });
                                        e.preventDefault();
                                    }
                                }
                            },
                            'data-codecept-selector-node': 'FlatButton',
                            'data-codecept-selector-file': 'columnDefinitionsGenerator'
                        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement6))
                    );
                }
            }, {
                render: function contextMenu() {
                    var _React$createElement7, _React$createElement8, _React$createElement9;

                    var griddleInstance = this;
                    return React.createElement(
                        _IconMenu2.default,
                        (_React$createElement9 = {
                            key: 'ruby-actions_context-menu',
                            iconButtonElement: React.createElement(
                                _IconButton2.default,
                                (_React$createElement8 = {
                                    'data-codecept-selector-node': 'IconButton',
                                    'data-codecept-selector-file': 'columnDefinitionsGenerator'
                                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement8),
                                React.createElement(_moreVert2.default, (_React$createElement7 = {
                                    'data-codecept-selector-node': 'MoreVertIcon',
                                    'data-codecept-selector-file': 'columnDefinitionsGenerator'
                                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement7))
                            ),
                            anchorOrigin: { horizontal: 'right', vertical: 'top' },
                            targetOrigin: { horizontal: 'right', vertical: 'top' },
                            'data-codecept-selector-node': 'IconMenu',
                            'data-codecept-selector-file': 'columnDefinitionsGenerator'
                        }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement9),
                        selfModule.getMergedRowContextMenuItems_usingGriddleInstance_andParentProps(griddleInstance, parentProps).map(function (item) {
                            var _extends4;

                            return React.createElement(_MenuItem2.default, _extends({}, item, (_extends4 = {
                                'data-codecept-selector-node': 'MenuItem',
                                'data-codecept-selector-file': 'columnDefinitionsGenerator'
                            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends4, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_extends4, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends4, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _extends4)));
                        })
                    );
                }
            }),
            delegateActions: {
                pushNotification: pushNotification,
                showNotificationModal: showNotificationModal
            }
        })
    };

    var columnDefinitions = [];
    //# if last_modified_timestamp column definition already exists, we should use that 
    var existingLastModifiedColumnIndex = _.findIndex(parentProps.columns, ['key', 'last_modified_timestamp']);

    var columns = parentProps.columns;
    if (existingLastModifiedColumnIndex >= 0) {

        var existingLastModifiedColumn = parentProps.columns[existingLastModifiedColumnIndex];
        _.set(existingLastModifiedColumn, ['componentParams', 'disableToggles'], !canPublish || disable_unpublish);
    } else if (parentProps.show_publish_column) {
        //# DEPRECATED 20210204 - deprecating show_publish_column prop

        columns = parentProps.columns.concat([{
            key: 'last_modified_timestamp',
            sortKeys: ['status', 'last_modified_timestamp'],
            sortDirsFromDir_andKeys: function sortDirsFromDir_andKeys(dir, keys) {
                return [dir, 'desc'];
            },
            type: 'publishAndDate',
            displayName: 'Published',
            sortable: true,
            width: 145,
            resizable: true,
            alwaysShow: true,
            componentParams: {
                disableToggles: !canPublish || disable_unpublish
            }
        }]);
    }

    _.reduce(columns, function (collector, col) {
        var additionalCell = _extends({ id: col.key }, col);
        var type = col.type,
            componentOverride = col.componentOverride,
            componentParams = col.componentParams;

        var customComponentType = componentOverride || (COMPONENTS_BY_TYPE[type] ? type : undefined);
        var customComponent = COMPONENTS_BY_TYPE[customComponentType];

        if (customComponent) {
            additionalCell.customComponent = customComponent(_extends({}, componentParams, col), parentProps);
        }

        collector.push(additionalCell);
        return collector;
    }, columnDefinitions);
    columnDefinitions.push(optionsCell);

    columnDefinitions.push({
        id: 'children',
        displayName: ''
    });

    // make sure no col is sortable for hierarchical
    return hierarchical ? columnDefinitions.map(function (cdef) {
        return _extends({}, cdef, { sortable: false
        });
    }) : columnDefinitions;
};