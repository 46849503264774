'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styleHelper = require('./utils/styleHelper');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Pagination = function (_React$Component) {
  _inherits(Pagination, _React$Component);

  function Pagination(props, context) {
    _classCallCheck(this, Pagination);

    var _this = _possibleConstructorReturn(this, (Pagination.__proto__ || Object.getPrototypeOf(Pagination)).call(this, props, context));

    _this._handleChange = _this._handleChange.bind(_this);
    return _this;
  }

  _createClass(Pagination, [{
    key: 'render',
    value: function render() {
      var _getStyleProperties = (0, _styleHelper.getStyleProperties)(this.props, 'pagination'),
          style = _getStyleProperties.style,
          className = _getStyleProperties.className;

      return _react2.default.createElement(
        'div',
        { className: className, style: style, 'data-codecept-selector-node': 'div',
          'data-codecept-selector-file': 'pagination'
        },
        this.props.hasPrevious ? _react2.default.createElement(
          'button',
          { onClick: this.props.events.getPreviousPage, 'data-codecept-selector-node': 'button',
            'data-codecept-selector-file': 'pagination'
          },
          'Previous'
        ) : null,
        this._getSelect(),
        this.props.hasNext ? _react2.default.createElement(
          'button',
          { onClick: this.props.events.getNextPage, 'data-codecept-selector-node': 'button',
            'data-codecept-selector-file': 'pagination'
          },
          'Next'
        ) : null
      );
    }
  }, {
    key: '_handleChange',
    value: function _handleChange(e) {
      this.props.events.getPage(parseInt(e.target.value));
    }
  }, {
    key: '_getSelect',
    value: function _getSelect() {
      if (!this.props.pageProperties || !this.props.pageProperties.maxPage) {
        return;
      }
      //Make this nicer
      var options = [];

      for (var i = 1; i <= this.props.pageProperties.maxPage; i++) {
        options.push(_react2.default.createElement(
          'option',
          { value: i, key: i, 'data-codecept-selector-node': 'option',
            'data-codecept-selector-file': 'pagination'
          },
          i
        ));
      }

      return _react2.default.createElement(
        'select',
        { onChange: this._handleChange, value: this.props.pageProperties.currentPage, 'data-codecept-selector-node': 'select',
          'data-codecept-selector-file': 'pagination'
        },
        options
      );
    }
  }]);

  return Pagination;
}(_react2.default.Component);

exports.default = Pagination;