'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Dialog = require('./reactComponents/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _FieldsetConnector = require('@rubyapps/ruby-component-mixin-field-set/src/client/reactComponents/FieldsetConnector');

var _FieldsetConnector2 = _interopRequireDefault(_FieldsetConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var action = require('./action');
var reducer = require('./reducer');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldSetMixin = require('@rubyapps/ruby-component-mixin-field-set');

var RCDialog = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldSetMixin],
    componentName: componentName,
    reducer: reducer,
    action: action,
    getInitialState: function getInitialState() {
        return {
            open: false
        };
    },
    getDefaultProps: function getDefaultProps() {
        return {
            autoScrollBodyContent: true
        };
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();
        var selfAction = this.getAction();

        return {
            selfSelector: selfSelector,
            selfAction: selfAction
        };
    },
    getReactClass: function getReactClass() {
        return _FieldsetConnector2.default.call(this, _Dialog2.default);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var DialogComponent = this.getReactClass();

        var mixinFieldProps = this.fieldProps_fromState_andOwnProps(this.getState(), this.props);
        var mappedProps = _extends({}, this.props, mixinFieldProps);
        //# NOTE: we should update all field props to call on this.fieldProps_fromState_andOwnProps()
        //# and use that as the initial props for their respective React Components

        var retval = _react2.default.createElement(DialogComponent, _extends({}, mappedProps, (_extends2 = {
            'data-codecept-selector-node': 'DialogComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'DialogComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'DialogComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));

        return retval;
    }

    //== OVERRIDES ======================================//
    , statesSelector: baseFieldMixin.statesSelector //# do not watch for children state updates
});

module.exports = RCDialog;