'use strict';

var request = require('@rubyapps/ruby-superagent');

var TYPES = {
    RETRIEVE_FRONTEND_SETTINGS: '@@ruby-app/frontendSettings/RETRIEVE_FRONTEND_SETTINGS',
    SET_FRONTEND_SETTINGS: '@@ruby-app/frontendSettings/SET_FRONTEND_SETTINGS',
    SET_FRONTEND_SETTING: '@@ruby-app/frontendSettings/SET_FRONTEND_SETTING'
};

var FRONTEND_SETTINGS_URL = '/ruby/api/v2/settings/frontend';
var LOGIN_FRONTEND_SETTINGS_URL = '/ruby/api/v2/settings/login-frontend';

var generators = {

    retrievedFrontendSettings: function retrievedFrontendSettings(settings) {
        var self = this;

        this._retrievedFrontendSettings = true;
        return function (dispatch) {
            dispatch(generators.setFrontendSettings(settings));
        };
    },
    setFrontendSetting_forKey_toValue: function setFrontendSetting_forKey_toValue(key, value) {
        return {
            type: TYPES.SET_FRONTEND_SETTING,
            payload: {
                key: key,
                value: value
            }
        };
    },
    setFrontendSettings: function setFrontendSettings(settings) {
        return {
            type: TYPES.SET_FRONTEND_SETTINGS,
            payload: settings
        };
    }
};

module.exports = {
    TYPES: TYPES,
    generators: generators
};