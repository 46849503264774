'use strict';

var retryDelay = 2000;
var readinessProbe = '/_health/ready';

module.exports = {
    MAX_RETRIES: 2,
    RETRY_DELAY: retryDelay,
    READINESS_PROBE: readinessProbe,
    DEFAULT_RETRY_OPTIONS: {
        readinessProbe: readinessProbe,
        delay: retryDelay
    }
};