'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var ReactDOM = _interopRequireWildcard(_reactDom);

var _reactStickyEl = require('react-sticky-el');

var _reactStickyEl2 = _interopRequireDefault(_reactStickyEl);

var _Paper = require('material-ui/Paper');

var _Paper2 = _interopRequireDefault(_Paper);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;


var mixinResize = require('@rubyapps/ruby-react-components/src/client/mixins/resize');

var MaterialSticky = _react2.default.createClass(_extends({}, mixinResize, {
    propTypes: {
        stickyProps: PropTypes.shape(_reactStickyEl2.default.propTypes),
        paperProps: PropTypes.shape(_Paper2.default.propTypes),
        stickyZDepth: PropTypes.number,
        defaultZDepth: PropTypes.number,

        onFixedToggle: PropTypes.func //# (prevFixedStatus, nextFixedStatus)
        /*
        // Example usage:
        , onFixedToggle: function(prevFixedStatus, nextFixedStatus) {
            setImmediate(() => {
                const stickyComponent = this._stickyComponent;
                 stickyComponent.setState({
                    styles: Object.assign({}
                        , stickyComponent.state.styles
                        , {top: appBarHeight-3}
                    )
                })
            })
        }
        */
        , onFixedStateOverride: PropTypes.object,
        onUnFixedStateOverride: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            stickyProps: {
                topOffset: -45,
                stickyStyle: {
                    marginTop: 45
                },
                style: {
                    zIndex: 10,
                    position: 'relative'
                }
            },
            paperProps: {},
            stickyZDepth: 2,
            defaultZDepth: 0,

            onFixedStateOverride: null,
            onUnFixedStateOverride: null
        };
    },
    getInitialState: function getInitialState() {
        return {
            stickyFixed: false
        };
    },
    _onFixedToggle: function _onFixedToggle(prevFixedStatus) {
        var _this = this;

        //# NOTE: we're given the old fixed status so we need to invert
        var isFixed = !prevFixedStatus;

        this.setState({ stickyFixed: isFixed });

        //# fixing dynamic wrapper height issues
        if (!isFixed) {
            if (this.props.onUnFixedStateOverride) {
                setImmediate(function () {
                    //# NOTE: Previously we had to set height: 0 to account for changes in height calculation
                    //# but we now watch for size changes so we don't need this
                    _this._stickyComponent && _this._stickyComponent.setState(_this.props.onUnFixedStateOverride);
                }); //# NOTE: stickyComponent calls on setState in checkPosition AFTER it triggers this callback
                //# so we need to wait
            }
        } else {
            if (this.props.onFixedStateOverride) {
                setImmediate(function () {
                    _this._stickyComponent && _this._stickyComponent.setState(_this.props.onFixedStateOverride);
                });
            }
        }

        this.props.onFixedToggle && this.props.onFixedToggle.call(this, prevFixedStatus, isFixed);
    },
    render: function render() {
        var _this2 = this,
            _extends2,
            _extends3;

        var zDepth = this.state.stickyFixed ? this.props.stickyZDepth : this.props.defaultZDepth;

        return _react2.default.createElement(
            _reactStickyEl2.default,
            _extends({
                holderProps: { style: { position: 'relative' } }
            }, this.props.stickyProps, (_extends3 = {
                onFixedToggle: this._onFixedToggle,
                ref: function ref(stickyComponent) {
                    if (!stickyComponent) {
                        return;
                    }
                    _this2._stickyComponent = stickyComponent;
                },
                'data-codecept-selector-node': 'Sticky',
                'data-codecept-selector-file': 'Sticky'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Sticky'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Sticky'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Sticky'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Sticky'), _extends3)),
            _react2.default.createElement(
                _Paper2.default,
                _extends({}, this.props.paperProps, (_extends2 = { zDepth: zDepth,
                    ref: function ref(paperComponent) {
                        if (!paperComponent) {
                            return;
                        }

                        _this2._bindElementResize(ReactDOM.findDOMNode(paperComponent), function () {
                            _this2._stickyComponent && _this2._stickyComponent.checkPosition();
                        });
                    },
                    'data-codecept-selector-node': 'Paper',
                    'data-codecept-selector-file': 'Sticky'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Paper'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Sticky'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Paper'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Sticky'), _extends2)),
                this.props.children
            )
        );
    },
    componentWillUnmount: function componentWillUnmount() {
        this._unbindBoundElementResize();
    }
}));

exports.default = MaterialSticky;

/*

NOTE: react-sticky-el only checks the wrapperEl height on scroll or resize.
We can emit the 'resize event':
//# modern browsers
window.dispatchEvent(new Event('resize'));

//# IE Support
var evt = window.document.createEvent('UIEvents');
evt.initUIEvent('resize', true, false, window, 0);
window.dispatchEvent(evt);

But for our purposes, the issue is when the height changes when 'fixed' 
When scrolling back to the non-'fixed' position THEN the height changes,
nothing updates the height because the window was not scrolled or resized.

SOLUTION: it's ok to set the height to 0 when it's in the non-fixed position
*/