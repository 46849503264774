'use strict';

var _ = require('lodash');

var _require = require('./utils'),
    ancestorIdsForId_usingTogglesById = _require.ancestorIdsForId_usingTogglesById;

module.exports = function (state, fieldValue) {
    var togglesById = state.togglesById,
        toggleTree = state.toggleTree;


    var mutableTogglesById = _.cloneDeep(togglesById); //# Don't modify original togglesById - instead modify a copy
    var updatedTogglesById = setTogglesById_usingFieldValue(mutableTogglesById, fieldValue);

    return updatedTogglesById;
};

function setTogglesById_usingFieldValue(togglesById) {
    var fieldValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (fieldValue === null) {
        return togglesById;
    }

    var _fieldValue$fields = fieldValue.fields,
        keyPaths = _fieldValue$fields === undefined ? [] : _fieldValue$fields,
        _fieldValue$expand = fieldValue.expand,
        expand = _fieldValue$expand === undefined ? [] : _fieldValue$expand;


    var allIdsToHydrate = [].concat(idsToHydrateFromKeyPaths_usingTogglesById(keyPaths, togglesById), idsToHydrateFromExpandSpecs_usingTogglesById(expand, togglesById));

    _.forEach(allIdsToHydrate, function (idToHydrate) {
        togglesById[idToHydrate].toggled = true;
    });

    return togglesById;
}

function idsToHydrateFromKeyPaths_usingTogglesById(keyPaths, togglesById) {
    var keyPathPrefixArray = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

    var togglesByKeyPath = _.keyBy(togglesById, 'keyPath');

    var idsToHydrate = _.reduce(keyPaths, function (acc, keyPath) {
        var expandedKeyPath = keyPathPrefixArray.concat(keyPath).join('.');
        //# NOTE: Sometimes toggleData may not exist at `expandedKeypath` since it's possible
        //# that the field at `expandedKeypath` no longer exists (field/template removed via
        //# TemplateBuilder/migration script)
        var toggleData = togglesByKeyPath[expandedKeyPath] || {};
        var id = toggleData.id;


        return id ? acc.concat(id, ancestorIdsForId_usingTogglesById(id, togglesById)) : acc;
    }, []);

    return idsToHydrate;
}

function idsToHydrateFromExpandSpecs_usingTogglesById(expandSpecs, togglesById) {
    var keyPathPrefixArray = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

    var togglesByKeyPath = _.keyBy(togglesById, 'keyPath');

    var idsToHydrate = _.reduce(expandSpecs, function (acc, expandSpec) {
        var keyPath = Object.keys(expandSpec)[0];
        var _expandSpec$keyPath = expandSpec[keyPath],
            _expandSpec$keyPath$e = _expandSpec$keyPath.expand,
            expand = _expandSpec$keyPath$e === undefined ? [] : _expandSpec$keyPath$e,
            _expandSpec$keyPath$f = _expandSpec$keyPath.fields,
            fields = _expandSpec$keyPath$f === undefined ? [] : _expandSpec$keyPath$f;

        var updatedKeyPathPrefixArray = keyPathPrefixArray.concat(keyPath);
        var expandedKeyPath = updatedKeyPathPrefixArray.join('.');

        var toggleData = togglesByKeyPath[expandedKeyPath];
        var id = toggleData.id;


        return acc.concat(id, ancestorIdsForId_usingTogglesById(id, togglesById), idsToHydrateFromExpandSpecs_usingTogglesById(expand, togglesById, updatedKeyPathPrefixArray), idsToHydrateFromKeyPaths_usingTogglesById(fields, togglesById, updatedKeyPathPrefixArray));
    }, []);

    return idsToHydrate;
}