'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _node2fa = require('node-2fa');

var _node2fa2 = _interopRequireDefault(_node2fa);

var _Toggle = require('material-ui/Toggle');

var _Toggle2 = _interopRequireDefault(_Toggle);

var _RaisedButton = require('@rubyapps/ruby-material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _keyURIConstructor = require('../keyURI-constructor');

var _keyURIConstructor2 = _interopRequireDefault(_keyURIConstructor);

var _TFA_style = require('./TFA_style');

var _TFA_style2 = _interopRequireDefault(_TFA_style);

var _TFASecret = require('./TFASecret');

var _TFASecret2 = _interopRequireDefault(_TFASecret);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label;

var styles = process.browser ? require('./TFA.cssModule').default : {};

var TFASecret__propTypeKeys = Object.keys(_TFASecret2.default.propTypes);

var TFARegistration = _react2.default.createClass({
    displayName: 'TFARegistration',

    _onToggleValueChange: function _onToggleValueChange(evt, toggled) {
        this.props.actions.setFieldValueByKey(toggled, 'enabled');
        this.props.actions.setFieldValueByKey(null, 'token');

        if (!toggled) {
            //# only unset secret, don't generate secret automatically
            this.props.actions.setFieldValueByKey(null, 'secret');
            this.props.actions.setFieldValueByKey(false, 'registered');
        }
    },
    _onInvalidateSecret: function _onInvalidateSecret(evt) {
        this.props.actions.setFieldValueByKey(null, 'secret');
        this.props.actions.setFieldValueByKey(false, 'registered');
    },
    _onTokenValueChange: function _onTokenValueChange(evt) {
        this.props.actions.setFieldValueByKey(evt.target.value, 'token');
    },
    _onGenerateSecretClick: function _onGenerateSecretClick() {
        this.props.actions.setFieldValueByKey(this.getNewSecret(), 'secret');
    },
    getSecretIssuer: function getSecretIssuer() {
        var frontendSettings = this.props.frontendSettings;
        var RUBY_BRANDING = frontendSettings.RUBY_BRANDING,
            CLIENT_NAME = frontendSettings.CLIENT_NAME;

        var rubyBranding = RUBY_BRANDING || 'RubyContent';
        return rubyBranding + ' - ' + CLIENT_NAME;
    },
    getSecretLabel: function getSecretLabel() {
        return this.props.label || '';
    },
    getNewSecret: function getNewSecret() {
        var issuer = this.getSecretIssuer();
        var label = this.getSecretLabel();

        var _twoFactor$generateSe = _node2fa2.default.generateSecret({
            name: issuer,
            account: label
        }),
            newSecret = _twoFactor$generateSe.secret;

        return newSecret;
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _extends2, _extends3, _React$createElement3, _extends4, _React$createElement4;

        var _props = this.props,
            enabled = _props.enabled,
            secret = _props.secret,
            registered = _props.registered,
            token = _props.token,
            frontendSettings = _props.frontendSettings;

        var mfaRequired = frontendSettings["feat:MFA:required"];
        var keyURI = secret.value ? (0, _keyURIConstructor2.default)(this.getSecretIssuer(), this.getSecretLabel(), secret.value) : null;

        return _react2.default.createElement(
            'div',
            (_React$createElement4 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'TFARegistration'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TFARegistration'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TFARegistration'), _React$createElement4),
            mfaRequired ? _react2.default.createElement(_RaisedButton2.default, (_React$createElement = {
                label: 'Invalidate Multi-Factor Secret',
                secondary: true,
                disabled: registered.value ? false : true,
                onTouchTap: this._onInvalidateSecret,
                'data-codecept-selector-node': 'RaisedButton',
                'data-codecept-selector-file': 'TFARegistration'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TFARegistration'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TFARegistration'), _React$createElement)) : _react2.default.createElement(_Toggle2.default, _extends({
                label: _react2.default.createElement(Label, (_React$createElement2 = { label: 'Enable Multi-Factor Authentication', 'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'TFARegistration'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TFARegistration'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TFARegistration'), _React$createElement2)),
                onToggle: this._onToggleValueChange,
                toggled: enabled.value,
                labelPosition: 'right'
            }, _TFA_style2.default.Toggle, (_extends2 = {
                'data-codecept-selector-node': 'Toggle',
                'data-codecept-selector-file': 'TFARegistration'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_extends2, 'data-codecept-selector-file', 'TFARegistration'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_extends2, 'data-codecept-selector-file', 'TFARegistration'), _extends2))),
            enabled.value && !registered.value ? _react2.default.createElement(_TFASecret2.default, _extends({
                value: secret.value,
                getNewSecret: this.getNewSecret,
                generateOnClick: this._onGenerateSecretClick,
                uri: keyURI
            }, _lodash2.default.pick(this.props, TFASecret__propTypeKeys), (_extends3 = {
                'data-codecept-selector-node': 'TFASecret',
                'data-codecept-selector-file': 'TFARegistration'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'TFASecret'), _defineProperty(_extends3, 'data-codecept-selector-file', 'TFARegistration'), _defineProperty(_extends3, 'data-codecept-selector-node', 'TFASecret'), _defineProperty(_extends3, 'data-codecept-selector-file', 'TFARegistration'), _extends3))) : null,
            secret.value && !registered.value ? _react2.default.createElement(_TextField2.default, _extends({}, _TFA_style2.default.TextField, (_extends4 = {
                floatingLabelText: _react2.default.createElement(Label, (_React$createElement3 = { label: 'MFA Token', 'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'TFARegistration'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TFARegistration'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TFARegistration'), _React$createElement3)),
                onChange: this._onTokenValueChange,
                value: token.value,
                errorText: _lodash2.default.get(token, 'error.message.msg') || _lodash2.default.get(token, 'error.message'),
                'data-codecept-selector-node': 'TextField',
                'data-codecept-selector-file': 'TFARegistration'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends4, 'data-codecept-selector-file', 'TFARegistration'), _defineProperty(_extends4, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends4, 'data-codecept-selector-file', 'TFARegistration'), _extends4))) : null
        );
    }
});

exports.default = TFARegistration;