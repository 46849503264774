'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Dialog = require('material-ui/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

var _typography = require('@rubyapps/ruby-styles/src/client/cssModules/typography.cssModule');

var _typography2 = _interopRequireDefault(_typography);

var _TermsOfUse = require('./TermsOfUse');

var _TermsOfUse2 = _interopRequireDefault(_TermsOfUse);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var Copyright = _react2.default.createClass({
    displayName: 'Copyright',

    propTypes: {
        rubyBranding: PropTypes.string,
        rubyContributors: PropTypes.array
    },
    getDefaultProps: function getDefaultProps() {
        return {
            rubyContributors: []
        };
    },
    getInitialState: function getInitialState() {
        return {
            dialogOpen: false
        };
    },
    render: function render() {
        var _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8, _extends2, _React$createElement9, _React$createElement10, _React$createElement11, _React$createElement12;

        var props = this.props;
        var currentYear = new Date().getFullYear();
        var contributors = props.rubyContributors.join(', ');
        var attribution = void 0;
        if (contributors) {
            var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4;

            attribution = _react2.default.createElement(
                'span',
                (_React$createElement4 = {
                    'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'CopyrightFooter'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'CopyrightFooter'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'CopyrightFooter'), _React$createElement4),
                _react2.default.createElement(
                    'strong',
                    (_React$createElement = {
                        'data-codecept-selector-node': 'strong',
                        'data-codecept-selector-file': 'CopyrightFooter'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'CopyrightFooter'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'CopyrightFooter'), _React$createElement),
                    props.rubyBranding,
                    ' Development Team: '
                ),
                contributors,
                _react2.default.createElement('br', (_React$createElement2 = {
                    'data-codecept-selector-node': 'br',
                    'data-codecept-selector-file': 'CopyrightFooter'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'CopyrightFooter'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'CopyrightFooter'), _React$createElement2)),
                _react2.default.createElement('br', (_React$createElement3 = {
                    'data-codecept-selector-node': 'br',
                    'data-codecept-selector-file': 'CopyrightFooter'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'CopyrightFooter'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'CopyrightFooter'), _React$createElement3))
            );
        }

        return _react2.default.createElement(
            'div',
            (_React$createElement12 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'CopyrightFooter'
            }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'CopyrightFooter'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'CopyrightFooter'), _React$createElement12),
            _react2.default.createElement(
                'p',
                _extends({}, props, (_extends2 = {
                    'data-codecept-selector-node': 'p',
                    'data-codecept-selector-file': 'CopyrightFooter'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'p'), _defineProperty(_extends2, 'data-codecept-selector-file', 'CopyrightFooter'), _defineProperty(_extends2, 'data-codecept-selector-node', 'p'), _defineProperty(_extends2, 'data-codecept-selector-file', 'CopyrightFooter'), _extends2)),
                'By accessing this system, you agree to our ',
                _react2.default.createElement(
                    'a',
                    (_React$createElement5 = { href: '#terms-of-use',
                        className: _typography2.default['link--startUnderlined'],
                        onTouchTap: this._handleOpen,
                        'data-codecept-selector-node': 'a',
                        'data-codecept-selector-file': 'CopyrightFooter'
                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'CopyrightFooter'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'CopyrightFooter'), _React$createElement5),
                    'terms of use'
                ),
                '.',
                _react2.default.createElement('br', (_React$createElement6 = {
                    'data-codecept-selector-node': 'br',
                    'data-codecept-selector-file': 'CopyrightFooter'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'CopyrightFooter'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'CopyrightFooter'), _React$createElement6)),
                _react2.default.createElement('br', (_React$createElement7 = {
                    'data-codecept-selector-node': 'br',
                    'data-codecept-selector-file': 'CopyrightFooter'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'CopyrightFooter'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'CopyrightFooter'), _React$createElement7)),
                attribution,
                'Copyright \xA9 1999\u2013',
                currentYear,
                ',\xA0',
                _react2.default.createElement(
                    'a',
                    (_React$createElement8 = { className: 'rtg-ignore-dirty-form',
                        href: 'http://www.rubensteintech.com/',
                        target: '_blank',
                        style: { 'whiteSpace': 'nowrap' }, 'data-codecept-selector-node': 'a',
                        'data-codecept-selector-file': 'CopyrightFooter'
                    }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'CopyrightFooter'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'CopyrightFooter'), _React$createElement8),
                    'Rubenstein Technology Group, Inc.'
                ),
                ' All\xA0Rights\xA0Reserved.'
            ),
            _react2.default.createElement(
                _Dialog2.default,
                (_React$createElement11 = {
                    title: 'Terms of Use',
                    actions: [_react2.default.createElement(_FlatButton2.default, (_React$createElement9 = {
                        label: 'Ok',
                        primary: true,
                        onTouchTap: this._handleClose,
                        'data-codecept-selector-node': 'FlatButton',
                        'data-codecept-selector-file': 'CopyrightFooter'
                    }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'CopyrightFooter'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'CopyrightFooter'), _React$createElement9))],
                    modal: false,
                    open: this.state.dialogOpen,
                    onRequestClose: this._handleClose,
                    autoScrollBodyContent: true,
                    bodyStyle: {
                        padding: 24
                    },
                    'data-codecept-selector-node': 'Dialog',
                    'data-codecept-selector-file': 'CopyrightFooter'
                }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'CopyrightFooter'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'CopyrightFooter'), _React$createElement11),
                _react2.default.createElement(_TermsOfUse2.default, (_React$createElement10 = {
                    'data-codecept-selector-node': 'TermsOfUse',
                    'data-codecept-selector-file': 'CopyrightFooter'
                }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'TermsOfUse'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'CopyrightFooter'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'TermsOfUse'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'CopyrightFooter'), _React$createElement10))
            )
        );
    },

    _handleOpen: function _handleOpen() {
        this.setState({
            dialogOpen: true
        });
        return false;
    },
    _handleClose: function _handleClose() {
        this.setState({
            dialogOpen: false
        });
        return false;
    }

});

exports.default = Copyright;