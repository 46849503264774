'use strict';

var url = require('url');
var RubyComponentFeSettings__CONSTANTS = require('@rubyapps/ruby-component-frontend-settings/src/common/constants');

var supportMixin = {
    mixinName: 'rubyComponentMixinSupport',
    createSupportLink: function createSupportLink() {
        var selfModule = this;

        var store = selfModule.getStore();
        if (store == undefined) return null;
        var applicationState = store.getState();

        var userObject = selfModule.getCurrentUserState(); // depends on access-control-mixin
        if (!userObject) return null;

        var userFirstName = userObject.first_name,
            userLastName = userObject.last_name;


        var feSettingsSelector = this._feSettingsSelector;
        if (!feSettingsSelector) {
            var rootModule = selfModule.getRoot();
            var feSettingsID = selfModule.props.feSettingsID || RubyComponentFeSettings__CONSTANTS.COMPONENT_NAME;
            var feSettingsComponent = rootModule.findDescendentByID(feSettingsID);
            feSettingsSelector = feSettingsComponent.getDefaultSelector();

            this._feSettingsSelector = feSettingsSelector;
        }
        var feConstants = feSettingsSelector(applicationState);

        var rubyBranding = feConstants.RUBY_BRANDING,
            clientName = feConstants.CLIENT_NAME,
            version = feConstants.version,
            DEPLOY_VERSION = feConstants.DEPLOY_VERSION;


        if (!clientName || !rubyBranding) return null;

        var subject = clientName + ' - ' + rubyBranding + ' support request for ' + userFirstName + ' ' + userLastName;

        var details = '\n\n\n========================================================================================================================\nUser: ' + userFirstName + ' ' + userLastName + '\nRubyApps Version: ' + version + '\nProject Version: ' + DEPLOY_VERSION + '\nBrowser Info:\n    userAgent:  ' + window.navigator.userAgent + '\n    languages:  ' + JSON.stringify(window.navigator.languages) + '\n========================================================================================================================\n';

        var urlObject = {
            protocol: 'mailto',
            slashes: false,
            auth: 'support',
            host: 'rubensteintech.com',
            query: {
                subject: subject,
                body: details
            }
        };

        return url.format(urlObject);
    } // createSupportLink

};

module.exports = supportMixin;