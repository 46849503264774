'use strict';

module.exports = {
    PLUGIN_NAME: 'rubyComponentPluginProposal',
    COMPONENT_NAME: 'rubyComponentPluginProposal',
    TOTAL_DISPLAYED_ACTIVITIES: 20,
    PROPOSAL_TEMPLATE_KEYWORD: 'proposal',
    PROPOSAL_APP_ROUTE: '/app/proposal',
    SYS_EVENT_TYPE: 'generate_proposal',
    PROPOSAL_PERMISSIONS: ['can_generate_proposal_doc']
};