'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _DatePickerConnector = require('./reactComponents/DatePickerConnector');

var _DatePickerConnector2 = _interopRequireDefault(_DatePickerConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var moment = require('@rubyapps/friendly-ruby-moment');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');

var RCDatePicker = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {
        styles: PropTypes.shape({
            DatePickerWrapper: PropTypes.object,
            DatePicker: PropTypes.object
        }),
        fullWidth: PropTypes.bool,
        displayFormat: PropTypes.string
    },
    getDefaultProps: function getDefaultProps() {
        return {
            format: 'date',
            displayFormat: 'MM/DD/YYYY'
        };
    },
    componentName: componentName,
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();

        return {
            selfSelector: selfSelector
        };
    },
    getReactClass: function getReactClass() {
        return _DatePickerConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var DatePickerComponent = this.getReactClass();

        return React.createElement(DatePickerComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'DatePickerComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'DatePickerComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'DatePickerComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

    //# Mixin Overrides

    , fieldValueFromFormValue_forKey: function fieldValueFromFormValue_forKey(value, key) {
        if (value && moment(value, 'YYYY-MM-DD').isValid()) {
            return moment(value, 'YYYY-MM-DD').toDate();
        } else {
            return undefined;
        }
    },
    formValueFromFieldValue_forKey: function formValueFromFieldValue_forKey(dateInstance, key) {
        if (
        // is valid date
        dateInstance instanceof Date && !isNaN(dateInstance.getTime()) && moment(dateInstance).isValid()) {
            return moment(dateInstance).format('YYYY-MM-DD');
        } else {
            return null;
        }
    }

    //== Utility Methods ==========================================================//
    , prettyDate: function prettyDate(dateInstance) {
        if (
        // is valid date
        dateInstance instanceof Date && !isNaN(dateInstance.getTime()) && moment(dateInstance).isValid()) {
            return moment(dateInstance).format('MM/DD/YYYY');
        } else {
            return '';
        }
    }

    //== OVERRIDES ======================//
    , sampleValueFromFieldSpecs: function sampleValueFromFieldSpecs() {
        return moment().format('YYYY-MM-DD');
    }
});

module.exports = RCDatePicker;