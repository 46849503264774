"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _require = require('../constants'),
    DIALOG_FORM_TEMPLATE_TYPE = _require.DIALOG_FORM_TEMPLATE_TYPE,
    DIALOG_FORM_TEMPLATE_KEY = _require.DIALOG_FORM_TEMPLATE_KEY;

exports.default = {
    "templateType": DIALOG_FORM_TEMPLATE_TYPE,
    "key": DIALOG_FORM_TEMPLATE_KEY,
    "name": DIALOG_FORM_TEMPLATE_KEY,
    "form": {
        "componentName": "Form",
        "name": " Dialog",
        "key": "contentReportDialogForm",
        "enabledFormDiff": false
        //, "modelType": "content"
        , "children": [{
            "componentName": "Text",
            "label": "Email",
            "key": "email",
            "verify": {
                "required": true,
                "max": 256,
                "pattern": "^$|^[^,\"]*@[^,\"]*\\.[^,\"]*$"
            }
        }, {
            "componentName": "Text",
            "label": "Subject",
            "key": "subject"
        }, {
            "componentName": "Textarea",
            "label": "Body",
            "key": "body"
        }]
    }
};