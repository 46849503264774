'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Promise = require('bluebird');


//# similar to _.get() except it supports wildcard keypaths
//# Returns a flattened array if there are wildcards, otherwise the original value
function get(object, keyPath) {

    if (_lodash2.default.isArray(keyPath) && _lodash2.default.isEmpty(keyPath)) {
        return object;
    }

    var keyPathArr = _lodash2.default.isString(keyPath) ? keyPath.split('.') : keyPath;

    var wildcardIndex = keyPathArr.indexOf('*');

    var leftandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(0, wildcardIndex) : keyPathArr;
    var rightandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(wildcardIndex + 1) : undefined;

    var leftandValue = _lodash2.default.get(object, leftandKeyPathArr);

    var value = void 0;
    if (rightandKeyPathArr != undefined && leftandValue) {
        var normalizedLeftandValue = [].concat(leftandValue);

        //# map across the leftandValue and call recursively call get()
        //# if the rightandKeyPathArr doesn't have additional '*', then it'll get the appropriate value
        //# after one call
        value = _lodash2.default.flatMap(normalizedLeftandValue, function (lValue) {
            return get(lValue, rightandKeyPathArr);
        });
    } else {
        value = leftandValue;
    }

    return value;
}

//# similar to _.get() except it supports wildcard keypaths
//# Returns an unflattened array if there are wildcards, otherwise the original value
function unflattenedGet(object, keyPath) {
    var keyPathArr = _lodash2.default.isString(keyPath) ? keyPath.split('.') : keyPath;

    var wildcardIndex = keyPathArr.indexOf('*');

    var leftandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(0, wildcardIndex) : keyPathArr;
    var rightandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(wildcardIndex + 1) : undefined;

    var leftandValue = _lodash2.default.get(object, leftandKeyPathArr);

    var value = void 0;
    if (rightandKeyPathArr != undefined && leftandValue) {
        var normalizedLeftandValue = [].concat(leftandValue);

        value = _lodash2.default.map(normalizedLeftandValue, function (lValue) {
            return unflattenedGet(lValue, rightandKeyPathArr);
        });
    } else {
        value = leftandValue;
    }

    return value;
}

function forEachKeypath(object, keyPath, callback) {
    return setBy(object, keyPath, function (value, keypath) {
        return Promise.resolve(callback(value, keypath)).then(function () {
            //# returned undefined to avoid mutating object with setBy
            return undefined;
        });
    });
}

/**
 * @param {Object} object -  gets mutated
 * @param {string} keyPath
 * @param {func} valueToSetGenerator - (value, keypathArray) => {return updatedValueForKeypath}
 **/
function setBy(object, keyPath, valueToSetGenerator) {
    var trackingKeypathArr = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];

    var keyPathArr = _lodash2.default.isString(keyPath) ? keyPath.split('.') : keyPath;

    var wildcardIndex = keyPathArr.indexOf('*');

    var leftandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(0, wildcardIndex) : keyPathArr;
    var rightandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(wildcardIndex + 1) : undefined;

    var leftandValue = _lodash2.default.get(object, leftandKeyPathArr);
    if (rightandKeyPathArr != undefined) {
        var normalizedLeftandValue = [].concat(leftandValue);

        //# iterate across the leftandValue and call recursively call setBy()
        //# if the rightandKeyPathArr doesn't have additional '*', then it'll set the appropriate value
        //# after one call
        return Promise.all(normalizedLeftandValue.map(function (lValue, index) {
            return setBy(lValue, rightandKeyPathArr, valueToSetGenerator, trackingKeypathArr.concat(leftandKeyPathArr, index));
        }));
    } else {

        var setValue = function setValue(valueToSet) {
            //# only set the value if what we returned exists or is explicitly null.
            //# if it's undefined, we don't want to set it
            if (valueToSet !== undefined) {
                _lodash2.default.set(object, leftandKeyPathArr, valueToSet);
            }
        };

        var valueToSet = valueToSetGenerator(leftandValue, trackingKeypathArr.concat(leftandKeyPathArr));
        if (valueToSet && valueToSet.then) {
            //# it's a promise, wait for it to resolve
            return valueToSet.then(function (resolvedValue) {
                setValue(resolvedValue);
            });
        } else {
            //# auto wrap the result in a promise
            setValue(valueToSet);

            return Promise.resolve();
        }
    }
}

//# object does not get mutated
//# new object returned, only if valueToSetGenerator returns a defined value
//# valueToSetGenerator = (value, keypathArray) => {}
function updateBy(object, keyPath, valueToSetGenerator) {
    var trackingKeypathArr = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];

    var keyPathArr = _lodash2.default.isString(keyPath) ? keyPath.split('.') : keyPath;

    var wildcardIndex = keyPathArr.indexOf('*');

    var leftandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(0, wildcardIndex) : keyPathArr;
    var rightandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(wildcardIndex + 1) : undefined;

    var leftandValue = _lodash2.default.get(object, leftandKeyPathArr);
    if (rightandKeyPathArr != undefined) {
        var normalizedLeftandValue = [].concat(leftandValue);

        // Try to update each item, keeping track of how many were updated
        var updates = _lodash2.default.reduce(normalizedLeftandValue, function (acc, lValue, index) {
            var updatedItem = updateBy(lValue, rightandKeyPathArr, valueToSetGenerator, trackingKeypathArr.concat(leftandKeyPathArr, index));
            if (updatedItem != undefined) {
                return _lodash2.default.assign({}, acc, { items: acc.items.concat([updatedItem]), modified: acc.modified + 1 });
            } else {
                return _lodash2.default.assign({}, acc, { items: acc.items.concat([lValue]), modified: acc.modified });
            }
        }, { items: [], modified: 0 });

        // If there were updates, then return them, otherwise return nothing
        if (updates.modified > 0) {
            return _lodash2.default.merge({}, _lodash2.default.omit(object, leftandKeyPathArr), _lodash2.default.set({}, leftandKeyPathArr, updates.items));
        }
    } else {
        var valueToSet = valueToSetGenerator(leftandValue, trackingKeypathArr.concat(leftandKeyPathArr));
        if (valueToSet !== undefined) {
            return _lodash2.default.merge({}, _lodash2.default.omit(object, leftandKeyPathArr), _lodash2.default.set({}, leftandKeyPathArr, valueToSet));
        }
    }

    return undefined;
}

var tree = require('./tree'); //# NOTE: DEPRECATED 20191203 in favor of deepdash.js
var methods = _extends({
    get: get,
    setBy: setBy,
    forEachKeypath: forEachKeypath,
    unflattenedGet: unflattenedGet,
    updateBy: updateBy
}, tree);

module.exports = _extends({
    default: methods
}, methods);