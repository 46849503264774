'use strict';

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _constants = require('@rubyapps/../common/constants');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var styles = process.browser ? require('./App.cssModule').default : {};

var App = React.createClass({
    displayName: 'App',

    contextTypes: {
        router: React.PropTypes.object
    },
    componentDidMount: function componentDidMount() {
        var router = this.context.router;

        if (this.props.routes.length == 1) {
            //# only on ruby-app route
            router.replace(_constants2.default.LOGIN_ROUTE);
        }
    },
    render: function render() {
        var _this = this,
            _React$createElement,
            _React$createElement2;

        var children = _lodash2.default.isArray(this.props.children) ? this.props.children : [this.props.children];
        //const divProps = _.omit(this.props, ['history', 'location', 'params', 'route', 'routeParams', 'routes']);
        return React.createElement(
            'div',
            (_React$createElement2 = {
                className: styles.wrapper,
                ref: function ref(refDiv) {
                    if (refDiv) {
                        _this.divNativeElement = refDiv;
                    }
                },
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'App'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'App'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'App'), _React$createElement2),
            children[0],
            React.createElement('div', (_React$createElement = { id: 'rootPlaceholder', 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'App'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'App'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'App'), _React$createElement)),
            children.slice(1)
        );
    }
});

module.exports = App;