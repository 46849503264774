'use strict';

var DELETED_STATUS = 'deleted';

module.exports = {
    COMPONENT_NAME: 'rubyComponentFieldScheduledStatus',
    commonChildrenPropsByKey: {
        status: {
            default: "inactive"
        }
    },
    DELETED_STATUS: DELETED_STATUS
};