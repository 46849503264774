'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconMenu = require('material-ui/IconMenu');

var _IconMenu2 = _interopRequireDefault(_IconMenu);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _moreVert = require('material-ui/svg-icons/navigation/more-vert');

var _moreVert2 = _interopRequireDefault(_moreVert);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var rubyActivities = require('@rubyapps/ruby-component-activities');
var DELETION_ACTIVITY = rubyActivities.CONSTANTS.ACTIVITY_TYPES.DELETION_ACTIVITY;


var ActivityMenu = _react2.default.createClass({
    displayName: 'ActivityMenu',


    customMixins: [] //# non-reserved key to handle special mixins where we want to handle calling them ourselves
    , renderViewButton: function renderViewButton() {
        var _React$createElement;

        var _props = this.props,
            id = _props.id,
            activityType = _props.activity_type,
            entity = _props.entity,
            modelName = _props.entity_name,
            viewEditActions = _props.viewEditActions;


        var viewLink = _.get(entity, 'url');
        var isDeleted = activityType === DELETION_ACTIVITY;

        return _react2.default.createElement(_MenuItem2.default, (_React$createElement = {
            primaryText: 'View',
            disabled: !viewLink || isDeleted,
            onTouchTap: function onTouchTap(e) {
                window.open(viewLink, '_blank');
            },
            'data-codecept-selector-node': 'MenuItem',
            'data-codecept-selector-file': 'ActivityMenu'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ActivityMenu'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ActivityMenu'), _React$createElement));
    },
    renderEditButton: function renderEditButton() {
        var _React$createElement2;

        var _props2 = this.props,
            id = _props2.id,
            activityType = _props2.activity_type,
            entity = _props2.entity,
            modelName = _props2.entity_name,
            viewEditActions = _props2.viewEditActions;


        var viewLink = _.get(entity, 'url');
        var isDeleted = activityType === DELETION_ACTIVITY;

        return _react2.default.createElement(_MenuItem2.default, (_React$createElement2 = {
            primaryText: 'Edit',
            disabled: isDeleted,
            onTouchTap: function onTouchTap(e) {
                viewEditActions.editEntity_ofModel(entity, modelName);
            },
            'data-codecept-selector-node': 'MenuItem',
            'data-codecept-selector-file': 'ActivityMenu'
        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ActivityMenu'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ActivityMenu'), _React$createElement2));
    },
    render: function render() {
        var _this = this,
            _React$createElement3,
            _React$createElement4,
            _React$createElement5;

        var viewButton = this.customMixins.reduce(function (collector, mixin) {
            if (collector) {
                return collector;
            }

            return mixin.renderViewButton && mixin.renderViewButton.apply(_this);
        }, null) || this.renderViewButton();

        var editButton = this.customMixins.reduce(function (collector, mixin) {
            if (collector) {
                return collector;
            }

            return mixin.renderEditButton && mixin.renderEditButton.apply(_this);
        }, null) || this.renderEditButton();

        return _react2.default.createElement(
            _IconMenu2.default,
            (_React$createElement5 = {
                iconButtonElement: _react2.default.createElement(
                    _IconButton2.default,
                    (_React$createElement4 = {
                        'data-codecept-selector-node': 'IconButton',
                        'data-codecept-selector-file': 'ActivityMenu'
                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ActivityMenu'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ActivityMenu'), _React$createElement4),
                    _react2.default.createElement(_moreVert2.default, (_React$createElement3 = {
                        'data-codecept-selector-node': 'MoreVertIcon',
                        'data-codecept-selector-file': 'ActivityMenu'
                    }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ActivityMenu'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ActivityMenu'), _React$createElement3))
                ),
                style: { marginLeft: 'auto' },
                'data-codecept-selector-node': 'IconMenu',
                'data-codecept-selector-file': 'ActivityMenu'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ActivityMenu'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ActivityMenu'), _React$createElement5),
            viewButton,
            editButton
        );
    }
});

exports.default = ActivityMenu;