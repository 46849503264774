'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Paper = require('material-ui/Paper');

var _Paper2 = _interopRequireDefault(_Paper);

var _Drawer = require('material-ui/Drawer');

var _Drawer2 = _interopRequireDefault(_Drawer);

var _DropDownMenu = require('material-ui/DropDownMenu');

var _DropDownMenu2 = _interopRequireDefault(_DropDownMenu);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _RefreshIndicator = require('@rubyapps/ruby-material-ui/RefreshIndicator');

var _RefreshIndicator2 = _interopRequireDefault(_RefreshIndicator);

var _Toolbar = require('material-ui/Toolbar');

var _ActivityMenu = require('./ActivityMenu');

var _ActivityMenu2 = _interopRequireDefault(_ActivityMenu);

var _RecentUpdates_style = require('./RecentUpdates_style.js');

var _RecentUpdates_style2 = _interopRequireDefault(_RecentUpdates_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var styles = process.browser ? require('./RecentUpdates.cssModule').default : {};

var ActivityList = require('@rubyapps/ruby-component-activities/src/client/reactComponents/ActivityList').default;

var DropdownStyleObject = {
    style: {
        marginRight: 0
    },
    labelStyle: {
        fontSize: '20px',
        color: 'rgba(0, 0, 0, 0.4)',
        paddingRight: '7px'
    },
    iconStyle: {
        right: 'auto',
        left: 0
    }
};

var RecentUpdates = _react2.default.createClass({
    displayName: 'RecentUpdates',


    contextTypes: {
        muiTheme: PropTypes.object
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _extends2, _React$createElement3, _React$createElement4, _extends3, _extends4, _extends5, _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8, _extends6;

        var _props = this.props,
            actions = _props.actions,
            activities = _props.activities,
            forms = _props.forms,
            loading = _props.loading,
            mode = _props.mode,
            open = _props.open,
            rubyUser = _props.rubyUser;


        var toolbarStyleObject = this.context.muiTheme.toolbar;

        var dropdown = _react2.default.createElement(
            _DropDownMenu2.default,
            _extends({ value: mode, onChange: function onChange(e, idx, value) {
                    actions.setMode(value);
                    actions.fetchActivities();
                }
            }, DropdownStyleObject, (_extends2 = {
                'data-codecept-selector-node': 'DropDownMenu',
                'data-codecept-selector-file': 'RecentUpdates'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'DropDownMenu'), _defineProperty(_extends2, 'data-codecept-selector-file', 'RecentUpdates'), _defineProperty(_extends2, 'data-codecept-selector-node', 'DropDownMenu'), _defineProperty(_extends2, 'data-codecept-selector-file', 'RecentUpdates'), _extends2)),
            _react2.default.createElement(_MenuItem2.default, (_React$createElement = { value: 'all', primaryText: 'All', 'data-codecept-selector-node': 'MenuItem',
                'data-codecept-selector-file': 'RecentUpdates'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'RecentUpdates'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'RecentUpdates'), _React$createElement)),
            _react2.default.createElement(_MenuItem2.default, (_React$createElement2 = { value: 'user', primaryText: 'My', 'data-codecept-selector-node': 'MenuItem',
                'data-codecept-selector-file': 'RecentUpdates'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'RecentUpdates'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'RecentUpdates'), _React$createElement2))
        );
        return _react2.default.createElement(
            _Drawer2.default,
            _extends({}, _RecentUpdates_style2.default.Drawer, (_extends6 = {
                docked: false,
                openSecondary: true,
                width: 400,
                open: open,
                onRequestChange: function onRequestChange(open) {
                    return actions.setDisplay(open);
                },
                'data-codecept-selector-node': 'Drawer',
                'data-codecept-selector-file': 'RecentUpdates'
            }, _defineProperty(_extends6, 'data-codecept-selector-node', 'Drawer'), _defineProperty(_extends6, 'data-codecept-selector-file', 'RecentUpdates'), _defineProperty(_extends6, 'data-codecept-selector-node', 'Drawer'), _defineProperty(_extends6, 'data-codecept-selector-file', 'RecentUpdates'), _extends6)),
            _react2.default.createElement(
                _Paper2.default,
                _extends({}, _RecentUpdates_style2.default.Paper, (_extends5 = {
                    'data-codecept-selector-node': 'Paper',
                    'data-codecept-selector-file': 'RecentUpdates'
                }, _defineProperty(_extends5, 'data-codecept-selector-node', 'Paper'), _defineProperty(_extends5, 'data-codecept-selector-file', 'RecentUpdates'), _defineProperty(_extends5, 'data-codecept-selector-node', 'Paper'), _defineProperty(_extends5, 'data-codecept-selector-file', 'RecentUpdates'), _extends5)),
                _react2.default.createElement(
                    _Toolbar.Toolbar,
                    _extends({}, _RecentUpdates_style2.default.Toolbar, (_extends4 = {
                        'data-codecept-selector-node': 'Toolbar',
                        'data-codecept-selector-file': 'RecentUpdates'
                    }, _defineProperty(_extends4, 'data-codecept-selector-node', 'Toolbar'), _defineProperty(_extends4, 'data-codecept-selector-file', 'RecentUpdates'), _defineProperty(_extends4, 'data-codecept-selector-node', 'Toolbar'), _defineProperty(_extends4, 'data-codecept-selector-file', 'RecentUpdates'), _extends4)),
                    _react2.default.createElement(_Toolbar.ToolbarGroup, (_React$createElement3 = {
                        'data-codecept-selector-node': 'ToolbarGroup',
                        'data-codecept-selector-file': 'RecentUpdates'
                    }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'RecentUpdates'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'RecentUpdates'), _React$createElement3)),
                    _react2.default.createElement(
                        _Toolbar.ToolbarGroup,
                        _extends({}, _RecentUpdates_style2.default.ToolbarGroup, (_extends3 = {
                            'data-codecept-selector-node': 'ToolbarGroup',
                            'data-codecept-selector-file': 'RecentUpdates'
                        }, _defineProperty(_extends3, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_extends3, 'data-codecept-selector-file', 'RecentUpdates'), _defineProperty(_extends3, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_extends3, 'data-codecept-selector-file', 'RecentUpdates'), _extends3)),
                        dropdown,
                        _react2.default.createElement(_Toolbar.ToolbarTitle, (_React$createElement4 = { text: 'Recent Updates', 'data-codecept-selector-node': 'ToolbarTitle',
                            'data-codecept-selector-file': 'RecentUpdates'
                        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ToolbarTitle'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'RecentUpdates'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ToolbarTitle'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'RecentUpdates'), _React$createElement4))
                    )
                )
            ),
            loading ? _react2.default.createElement(
                'div',
                (_React$createElement6 = { className: styles.loadingWrapper, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'RecentUpdates'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'RecentUpdates'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'RecentUpdates'), _React$createElement6),
                _react2.default.createElement(_RefreshIndicator2.default, (_React$createElement5 = {
                    status: 'loading',
                    style: _RecentUpdates_style2.default.LoadingIndicator,
                    top: 0,
                    left: 0,
                    'data-codecept-selector-node': 'LoadingIndicator',
                    'data-codecept-selector-file': 'RecentUpdates'
                }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'LoadingIndicator'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'RecentUpdates'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'LoadingIndicator'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'RecentUpdates'), _React$createElement5)),
                ' '
            ) : null,
            _react2.default.createElement(
                'div',
                (_React$createElement8 = { className: styles.recentUpdatesList, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'RecentUpdates'
                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'RecentUpdates'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'RecentUpdates'), _React$createElement8),
                activities.length ? _react2.default.createElement(ActivityList, (_React$createElement7 = {
                    activities: activities,
                    forms: forms,
                    menu: _ActivityMenu2.default,
                    showAdditionalHeaderButtons: false,
                    showAll: true,
                    verbose: true,
                    viewEditActions: actions,
                    callerModule: this.props.selfModule,
                    rubyUser: rubyUser,
                    'data-codecept-selector-node': 'ActivityList',
                    'data-codecept-selector-file': 'RecentUpdates'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'ActivityList'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'RecentUpdates'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'ActivityList'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'RecentUpdates'), _React$createElement7)) : loading ? null : 'No activities to show.'
            )
        );
    }
});

exports.default = RecentUpdates;