'use strict';

var _ = require('lodash');
var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var _require = require('../common/constants'),
    FE_SETTINGS_KEY = _require.FE_SETTINGS_KEY,
    STICKY_FE_SETTINGS_KEY = _require.STICKY_FE_SETTINGS_KEY;

var mixinName = 'rubyComponentMixinAutosaveSearch';

var autosaveSearchListerMixin = {
    mixinName: mixinName,
    action: require('./action'),
    propTypes: {
        autosaveSearch_context: PropTypes.string,
        autosaveSearch_griddleStateKeys: PropTypes.arrayOf(PropTypes.string)
    },
    getDefaultProps: function getDefaultProps() {
        return {
            autosaveSearch_griddleStateKeys: [
            //# currentPage, maxPage, pageSize, sortColumns, sortAscending 
            'pageProperties'

            //# text query
            , 'filter'

            //# column widths
            , 'resizeProperties', 'utilityBarProperties', 'expandedRows']
        };
    },
    getStickySearchKeys: function getStickySearchKeys() {
        if (this.props.stickySearchKeys) {
            return this.props.stickySearchKeys;
        }

        var _getDependencies = this.getDependencies(),
            feSettingsComponent = _getDependencies.feSettingsComponent;

        var sticky_search_keys = _.get(feSettingsComponent.getState(), ['RUBYAPPS_SETTINGS.lister_settings', 'sticky_search_keys']) || [];

        return sticky_search_keys;
    },
    getAutosaveSearch_context: function getAutosaveSearch_context() {
        return this.props.autosaveSearch_context || window.location.pathname + '___' + this.getID();
    },
    getStickySearchState: function getStickySearchState() {
        var selfModule = this;

        var _selfModule$getDepend = selfModule.getDependencies(),
            feSettingsComponent = _selfModule$getDepend.feSettingsComponent;

        return _.pick(_.get(feSettingsComponent.getState(), [STICKY_FE_SETTINGS_KEY], null), this.getStickySearchKeys());
    },
    getAutosavedSearchState: function getAutosavedSearchState() {
        var selfModule = this;

        var _selfModule$getDepend2 = selfModule.getDependencies(),
            feSettingsComponent = _selfModule$getDepend2.feSettingsComponent;

        return _.get(feSettingsComponent.getState(), [FE_SETTINGS_KEY, this.getAutosaveSearch_context()], null);
    },
    getInitialGriddleState_withDefault: function getInitialGriddleState_withDefault(defaultState, options) {

        var autosavedSearchState = this.getAutosavedSearchState();
        var stickySearchState = this.getStickySearchState();

        var initialGriddleState = _.merge({}, autosavedSearchState || defaultState, stickySearchState);

        return initialGriddleState;
    },
    onReduxInit: function onReduxInit(dispatch) {
        var _this = this;

        var selfModule = this;
        var store = selfModule.getStore();

        var _selfModule$getDepend3 = selfModule.getDependencies(),
            feSettingsComponent = _selfModule$getDepend3.feSettingsComponent;

        var prevRouteParams_key = '__' + mixinName + '_prevRouteParams';

        return store.subscribe(function () {
            var routeParams = _.get(selfModule.getState(), 'routeParams', {});

            var routeActive = routeParams.routeActive;


            var prevRouteParams = _this.getStatefulCacheForKey(prevRouteParams_key);

            _this.setStatefulCacheForKey(prevRouteParams_key, routeParams);

            if (!routeActive) {
                return;
            }

            var navigatedHere = routeActive && !_.isEqual(prevRouteParams, routeParams);

            var _$get = _.get(feSettingsComponent.getState(), 'RUBYAPPS_SETTINGS.lister_settings', {}),
                reset_search_on_navigate = _$get.reset_search_on_navigate;

            if (reset_search_on_navigate && navigatedHere) {
                dispatch(selfModule.getAction().generators.clearOtherAutosavedSearches());
            }
        });
    }

};

module.exports = autosaveSearchListerMixin;