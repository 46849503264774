'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp2;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var moment = require('@rubyapps/friendly-ruby-moment');
var epochMoment = moment.unix(0).tz('UTC');

var TimeDisplay = (_temp2 = _class = function (_Component) {
  _inherits(TimeDisplay, _Component);

  function TimeDisplay() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, TimeDisplay);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = TimeDisplay.__proto__ || Object.getPrototypeOf(TimeDisplay)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      transitionDirection: 'up'
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(TimeDisplay, [{
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      if (nextProps.selectedTime !== this.props.selectedTime) {
        var direction = nextProps.selectedTime > this.props.selectedTime ? 'up' : 'down';

        this.setState({
          transitionDirection: direction
        });
      }
    }
  }, {
    key: 'sanitizeTime',
    value: function sanitizeTime() {
      var hour = this.props.selectedTime.hours();
      var min = this.props.selectedTime.minutes().toString();

      if (this.props.format === 'ampm') {
        hour %= 12;
        hour = hour || 12;
      }

      hour = hour.toString();
      if (hour.length < 2) hour = '0' + hour;
      if (min.length < 2) min = '0' + min;

      return [hour, min];
    }
  }, {
    key: 'render',
    value: function render() {
      var _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8, _React$createElement9, _React$createElement10, _React$createElement11, _extends2;

      var _props = this.props,
          affix = _props.affix,
          format = _props.format,
          mode = _props.mode,
          onSelectAffix = _props.onSelectAffix,
          onSelectHour = _props.onSelectHour,
          onSelectMin = _props.onSelectMin,
          selectedTime = _props.selectedTime,
          onSelectZoneName = _props.onSelectZoneName,
          zoneName = _props.zoneName,
          referenceDate = _props.referenceDate,
          other = _objectWithoutProperties(_props, ['affix', 'format', 'mode', 'onSelectAffix', 'onSelectHour', 'onSelectMin', 'selectedTime', 'onSelectZoneName', 'zoneName', 'referenceDate']);

      var _context$muiTheme = this.context.muiTheme,
          prepareStyles = _context$muiTheme.prepareStyles,
          timePicker = _context$muiTheme.timePicker;


      var styles = {
        root: {
          padding: '14px 0',
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
          backgroundColor: timePicker.headerColor,
          color: 'white'
        },
        text: {
          margin: '6px 0',
          lineHeight: '58px',
          height: 58,
          fontSize: 58,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'baseline'
        },
        time: {
          margin: '0 10px'
        },
        abbr: {
          flex: 1,
          position: 'relative',
          lineHeight: '17px',
          height: 17,
          fontSize: 17
        },
        affix: {
          flex: 1,
          position: 'relative',
          lineHeight: '17px',
          height: 17,
          fontSize: 17
        },
        affixTop: {
          position: 'absolute',
          top: -20,
          left: 0
        },
        clickable: {
          cursor: 'pointer'
        },
        inactive: {
          opacity: 0.7
        },
        zoneName: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 5,
          marginRight: 16,
          fontSize: 16,
          fontWeight: '500',
          lineHeight: '16px',
          height: 16
        }
      };

      var _sanitizeTime = this.sanitizeTime(),
          _sanitizeTime2 = _slicedToArray(_sanitizeTime, 2),
          hour = _sanitizeTime2[0],
          min = _sanitizeTime2[1];

      var date = void 0;
      if (referenceDate) {
        date = moment.tz(referenceDate + ' ' + hour + ':' + min).format('Z');
      }

      var buttons = [];
      if (format === 'ampm') {
        var _React$createElement, _React$createElement2;

        buttons = [_react2.default.createElement(
          'div',
          (_React$createElement = {
            key: 'pm',
            style: prepareStyles(_extends({}, styles.clickable, affix === 'pm' ? {} : styles.inactive)),
            onTouchTap: function onTouchTap() {
              return onSelectAffix('pm');
            },
            'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'TimeDisplay'
          }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TimeDisplay'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TimeDisplay'), _React$createElement),
          'PM'
        ), _react2.default.createElement(
          'div',
          (_React$createElement2 = {
            key: 'am',
            style: prepareStyles(_extends({}, styles.affixTop, styles.clickable, affix === 'am' ? {} : styles.inactive)),
            onTouchTap: function onTouchTap() {
              return onSelectAffix('am');
            },
            'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'TimeDisplay'
          }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TimeDisplay'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TimeDisplay'), _React$createElement2),
          'AM'
        )];
      }

      var refDate = epochMoment.format('YYYY-MM-DD');

      var abbr = moment.tz(refDate + ' ' + hour + ':' + min, this.props.zoneName).format('zz');

      return _react2.default.createElement(
        'div',
        _extends({}, other, (_extends2 = { style: prepareStyles(styles.root), 'data-codecept-selector-node': 'div',
          'data-codecept-selector-file': 'TimeDisplay'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'TimeDisplay'), _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'TimeDisplay'), _extends2)),
        _react2.default.createElement(
          'div',
          (_React$createElement11 = { style: prepareStyles(styles.text), 'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'TimeDisplay'
          }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'TimeDisplay'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'TimeDisplay'), _React$createElement11),
          _react2.default.createElement('div', (_React$createElement3 = { style: prepareStyles(_extends({}, styles.affix)), 'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'TimeDisplay'
          }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TimeDisplay'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TimeDisplay'), _React$createElement3)),
          _react2.default.createElement(
            'div',
            (_React$createElement7 = { style: prepareStyles(styles.time), 'data-codecept-selector-node': 'div',
              'data-codecept-selector-file': 'TimeDisplay'
            }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'TimeDisplay'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'TimeDisplay'), _React$createElement7),
            _react2.default.createElement(
              'span',
              (_React$createElement4 = {
                style: prepareStyles(_extends({}, styles.clickable, mode === 'hour' ? {} : styles.inactive)),
                onTouchTap: onSelectHour,
                'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'TimeDisplay'
              }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TimeDisplay'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TimeDisplay'), _React$createElement4),
              hour
            ),
            _react2.default.createElement(
              'span',
              (_React$createElement5 = {
                'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'TimeDisplay'
              }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'TimeDisplay'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'TimeDisplay'), _React$createElement5),
              ':'
            ),
            _react2.default.createElement(
              'span',
              (_React$createElement6 = {
                style: prepareStyles(_extends({}, styles.clickable, mode === 'minute' ? {} : styles.inactive)),
                onTouchTap: onSelectMin,
                'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'TimeDisplay'
              }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'TimeDisplay'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'TimeDisplay'), _React$createElement6),
              min
            )
          ),
          _react2.default.createElement(
            'div',
            (_React$createElement8 = { style: prepareStyles(_extends({}, styles.affix)), 'data-codecept-selector-node': 'div',
              'data-codecept-selector-file': 'TimeDisplay'
            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'TimeDisplay'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'TimeDisplay'), _React$createElement8),
            buttons
          ),
          _react2.default.createElement(
            'div',
            (_React$createElement10 = { style: prepareStyles(_extends({}, styles.abbr)), 'data-codecept-selector-node': 'div',
              'data-codecept-selector-file': 'TimeDisplay'
            }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'TimeDisplay'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'TimeDisplay'), _React$createElement10),
            _react2.default.createElement(
              'span',
              (_React$createElement9 = {
                style: prepareStyles(_extends({}, styles.zoneName, styles.clickable, mode === 'zoneName' ? {} : styles.inactive)),
                onTouchTap: onSelectZoneName,
                'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'TimeDisplay'
              }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'TimeDisplay'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'TimeDisplay'), _React$createElement9),
              abbr
            )
          )
        )
      );
    }
  }]);

  return TimeDisplay;
}(_react.Component), _class.propTypes = {
  affix: _react.PropTypes.oneOf(['', 'pm', 'am']),
  format: _react.PropTypes.oneOf(['ampm', '24hr']),
  mode: _react.PropTypes.oneOf(['hour', 'minute', 'zoneName']),
  date: _react.PropTypes.object,
  onSelectAffix: _react.PropTypes.func,
  onSelectHour: _react.PropTypes.func,
  onSelectMin: _react.PropTypes.func,
  selectedTime: _react.PropTypes.object.isRequired
}, _class.defaultProps = {
  affix: '',
  mode: 'hour'
}, _class.contextTypes = {
  muiTheme: _react.PropTypes.object.isRequired
}, _temp2);
exports.default = TimeDisplay;