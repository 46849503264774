'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Paper = require('material-ui/Paper');

var _Paper2 = _interopRequireDefault(_Paper);

var _Loading = require('@rubyapps/ruby-react-components/src/client/view/Loading');

var _Loading2 = _interopRequireDefault(_Loading);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var VIEWBOX_SIZE = 32;

function getStyles(props) {
  var padding = props.size * 0.1; // same implementation of `this.getPaddingSize()`
  return {
    root: {
      position: 'absolute',
      zIndex: 2,
      width: props.size,
      height: props.size,
      padding: padding,
      top: -10000,
      left: -10000,
      transform: 'translate(' + (10000 + props.left) + 'px, ' + (10000 + props.top) + 'px)',
      opacity: props.status === 'hide' ? 0 : 1,
      display: props.status === 'hide' ? 'none' : 'block'
    }
  };
}

var RefreshIndicator = (_temp = _class = function (_Component) {
  _inherits(RefreshIndicator, _Component);

  function RefreshIndicator() {
    _classCallCheck(this, RefreshIndicator);

    return _possibleConstructorReturn(this, (RefreshIndicator.__proto__ || Object.getPrototypeOf(RefreshIndicator)).apply(this, arguments));
  }

  _createClass(RefreshIndicator, [{
    key: 'getTheme',
    value: function getTheme() {
      return this.context.muiTheme.refreshIndicator;
    }
  }, {
    key: 'getPaddingSize',
    value: function getPaddingSize() {
      var padding = this.props.size * 0.1;
      return padding;
    }
  }, {
    key: 'getPaperSize',
    value: function getPaperSize() {
      return this.props.size - this.getPaddingSize() * 2;
    }
  }, {
    key: 'render',
    value: function render() {
      var _extends2, _extends3;

      var _props = this.props,
          style = _props.style,
          left = _props.left,
          top = _props.top,
          status = _props.status,
          percentage = _props.percentage,
          other = _objectWithoutProperties(_props, ['style', 'left', 'top', 'status', 'percentage']);

      var styles = getStyles(this.props, this.context);

      return _react2.default.createElement(
        _Paper2.default,
        _extends({
          circle: true,
          style: _extends({}, styles.root, style)
        }, other, (_extends3 = {
          'data-codecept-selector-node': 'Paper',
          'data-codecept-selector-file': 'RefreshIndicator'
        }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Paper'), _defineProperty(_extends3, 'data-codecept-selector-file', 'RefreshIndicator'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Paper'), _defineProperty(_extends3, 'data-codecept-selector-file', 'RefreshIndicator'), _extends3)),
        _react2.default.createElement(_Loading2.default, _extends({
          style: { width: 32, height: 32 }
        }, _.pick(this.props, ['status']), other, (_extends2 = {
          'data-codecept-selector-node': 'RubyLoading',
          'data-codecept-selector-file': 'RefreshIndicator'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'RubyLoading'), _defineProperty(_extends2, 'data-codecept-selector-file', 'RefreshIndicator'), _defineProperty(_extends2, 'data-codecept-selector-node', 'RubyLoading'), _defineProperty(_extends2, 'data-codecept-selector-file', 'RefreshIndicator'), _extends2)))
      );
    }
  }]);

  return RefreshIndicator;
}(_react.Component), _class.propTypes = {
  /**
   * Override the theme's color of the indicator while it's status is
   * "ready" and it's percentage is less than 100.
   */
  color: _react.PropTypes.string,
  /**
   * The absolute left position of the indicator in pixels.
   */
  left: _react.PropTypes.number.isRequired,
  /**
   * Override the theme's color of the indicator while
   * it's status is "loading" or when it's percentage is 100.
   */
  loadingColor: _react.PropTypes.string,
  /**
   * The confirmation progress to fetch data. Max value is 100.
   */
  percentage: _react.PropTypes.number,
  /**
   * Size in pixels.
   */
  size: _react.PropTypes.number,
  /**
   * The display status of the indicator. If the status is
   * "ready", the indicator will display the ready state
   * arrow. If the status is "loading", it will display
   * the loading progress indicator. If the status is "hide",
   * the indicator will be hidden.
   */
  status: _react.PropTypes.oneOf(['ready', 'loading', 'hide']),
  /**
   * Override the inline-styles of the root element.
   */
  style: _react.PropTypes.object,
  /**
   * The absolute top position of the indicator in pixels.
   */
  top: _react.PropTypes.number.isRequired
}, _class.defaultProps = {
  percentage: 0,
  size: 40,
  status: 'hide'
}, _class.contextTypes = {
  muiTheme: _react.PropTypes.object.isRequired
}, _temp);
exports.default = RefreshIndicator;