'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _index = require('@rubyapps/ruby-material-ui/Chip/index.js');

var _index2 = _interopRequireDefault(_index);

var _Avatar = require('material-ui/Avatar');

var _Avatar2 = _interopRequireDefault(_Avatar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PropTypes = _react2.default.PropTypes;

var Chip__propTypeKeys = Object.keys(_index2.default.propTypes);


var styleObject = {
    Avatar: {
        size: 28,
        style: {
            width: 28,
            height: 28
        }
    },
    Chip: {
        backgroundColor: "rgb(232, 232, 232)",
        style: {
            boxShadow: '0 1px 1px 1px rgba(0,0,0,0.2)',
            margin: '0px 5px 5px 0px',
            width: '100%'
        },
        labelStyle: {
            //color: '#ffffff'
            fontSize: '12px',
            lineHeight: '28px',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: '0px',
            width: '100%'
        },
        deleteIconStyle: {
            flexShrink: 0,
            marginRight: 3
        }
    }
};

var ChipItem = function (_Component) {
    _inherits(ChipItem, _Component);

    function ChipItem() {
        _classCallCheck(this, ChipItem);

        return _possibleConstructorReturn(this, (ChipItem.__proto__ || Object.getPrototypeOf(ChipItem)).apply(this, arguments));
    }

    _createClass(ChipItem, [{
        key: 'render',
        value: function render() {
            var _extends2, _extends3;

            var _props = this.props,
                id = _props.id,
                text = _props.text,
                icon = _props.icon,
                rest = _objectWithoutProperties(_props, ['id', 'text', 'icon']);

            var AvatarIcon = icon ? _react2.default.createElement(_Avatar2.default, _extends({}, styleObject.Avatar, (_extends2 = { icon: icon, 'data-codecept-selector-node': 'Avatar',
                'data-codecept-selector-file': 'ChipItem'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ChipItem'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ChipItem'), _extends2))) : null;
            var Content = _react2.default.createElement(
                _index2.default,
                _extends({
                    allowEventPropagation: true
                }, styleObject.Chip, _lodash2.default.pick(rest, Chip__propTypeKeys), (_extends3 = {
                    'data-codecept-selector-node': 'Chip',
                    'data-codecept-selector-file': 'ChipItem'
                }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Chip'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ChipItem'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Chip'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ChipItem'), _extends3)),
                AvatarIcon,
                text
            );

            //return (<div>{text}</div>)
            return Content;
        }
    }]);

    return ChipItem;
}(_react.Component);

;
ChipItem.contextTypes = {
    muiTheme: PropTypes.object
};
ChipItem.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string
};

exports.default = ChipItem;