'use strict';

var _ = require('lodash');

function ancestorIdsForId_usingTogglesById(toggledId, togglesById) {
    var collectedAncestorIds = [];

    var currentId = toggledId;

    while (togglesById[currentId].parentId) {
        var parentId = togglesById[currentId].parentId;
        collectedAncestorIds.push(parentId);
        currentId = parentId;
    }

    return collectedAncestorIds;
}

module.exports = {
    ancestorIdsForId_usingTogglesById: ancestorIdsForId_usingTogglesById
};