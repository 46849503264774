"use strict";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

/* jshint -W138 */

var defaultState = {
    mostRecentlyModifiedItem: {},
    griddleState: {}
};

module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];
    var type = action.type,
        payload = action.payload;

    var _getAction = this.getAction(),
        actionTypes = _getAction.TYPES;

    switch (type) {
        case actionTypes.SET_MOST_RECENTLY_MODIFIED_ITEM:
            return _extends({}, state, { mostRecentlyModifiedItem: payload.item ? payload.item : {} });
        case actionTypes.SET_GRIDDLE_STATE:
            return _extends({}, state, { griddleState: payload.griddleState ? payload.griddleState : {} });
        default:
            return state;
    }
};