'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _action = require('./action');

var _action2 = _interopRequireDefault(_action);

var _reducer = require('./reducer');

var _reducer2 = _interopRequireDefault(_reducer);

var _PasswordConnector = require('./reactComponents/PasswordConnector');

var _PasswordConnector2 = _interopRequireDefault(_PasswordConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var FieldForm = require('@rubyapps/ruby-component-field-form');
var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var RubyComponentCurrentUser = require('@rubyapps/ruby-component-current-user');

var RCPassword = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {
        //, label: PropTypes.string
        //, key: PropTypes.string
        //, constraints: PropTypes.object
    },
    componentName: componentName,
    dependencies: function dependencies() {
        var _this = this;

        var root = this.getRoot();
        var selfSelector = this.getSelfStateSelector();
        var parentForm = this.findAncestorBy(function (module) {
            return module.componentName == FieldForm.componentName;
        });
        var parentComponent = parentForm || this.getParent();

        var pairedComponent = this.props.pairedField && parentComponent.findDescendentBy(function (module) {
            return module.props.key == _this.props.pairedField;
        });
        var pairedComponentAction = pairedComponent && pairedComponent.getAction();

        var currentUserID = this.props.currentUserID || RubyComponentCurrentUser.CONSTANTS.COMPONENT_NAME;
        var currentUser = root.findDescendentByID(currentUserID);
        var currentUserSelector = currentUser.getSelfStateSelector();

        return {
            selfSelector: selfSelector,
            currentUserSelector: currentUserSelector,
            pairedComponentAction: pairedComponentAction
        };
    },
    action: _action2.default,
    reducer: _reducer2.default,
    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector,
            currentUserSelector = _getDependencies.currentUserSelector;

        return {
            self: selfSelector(state),
            user: currentUserSelector(state),
            fieldProps: this.fieldProps_fromState_andOwnProps(state, this.props),
            routing: state.routing //# need to update based on routing changes
        };
    },
    getReactClass: function getReactClass() {
        return _PasswordConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var PasswordComponent = this.getReactClass();

        return React.createElement(PasswordComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'PasswordComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'PasswordComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'PasswordComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

});

module.exports = RCPassword;