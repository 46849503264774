'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _TextConnector = require('./reactComponents/TextConnector');

var _TextConnector2 = _interopRequireDefault(_TextConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var RubyComponentFrontendSettings = require('@rubyapps/ruby-component-frontend-settings');
var FieldForm = require('@rubyapps/ruby-component-field-form');

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');

var RCText = RubyComponent.createClass({
    mixins: [baseFieldMixin],
    propTypes: {
        label: PropTypes.string,
        key: PropTypes.string,
        interpolateValue: PropTypes.oneOf([PropTypes.bool, PropTypes.oneOf(['mustache', 'lodash'])])
    },
    getDefaultProps: function getDefaultProps() {
        return {
            interpolateValue: false,
            listerConfig: {
                excludeFromColumnSelection: true,
                excludeFromFilterSelection: true
            }
        };
    },
    componentName: componentName,
    dependencies: function dependencies() {
        var rootComponent = this.getRoot();
        var selfSelector = this.getSelfStateSelector();
        var parentForm = this.findAncestorBy(function (module) {
            return module.componentName == FieldForm.componentName;
        });

        var frontendSettingsID = RubyComponentFrontendSettings.CONSTANTS.COMPONENT_NAME;
        var frontendSettingsComponent = rootComponent.findDescendentByID(frontendSettingsID);

        return {
            selfSelector: selfSelector,
            frontendSettingsComponent: frontendSettingsComponent,
            parentForm: parentForm
        };
    },
    getReactClass: function getReactClass() {
        return _TextConnector2.default.apply(this);
    },
    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector,
            parentForm = _getDependencies.parentForm,
            frontendSettingsComponent = _getDependencies.frontendSettingsComponent;

        return {
            selfState: selfSelector(state),
            parentForm: parentForm.getState(),
            feSettings: frontendSettingsComponent.getState()
        };
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var TextComponent = this.getReactClass();

        return React.createElement(TextComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'TextComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TextComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TextComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

});

module.exports = RCText;