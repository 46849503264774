'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = getSweeperReportsMenuItemsForSelfModule;
//# TODO: see if we can pass the icon component instead of the string
var urljoin = require('url-join');
var pluginSweeperComponentId = 'rubyComponentPluginSweeper';
var SWEEPER_CONSTANTS = require('@rubyapps/ruby-component-plugin-sweeper/src/common/constants');
function getSweeperReportsMenuItemsForSelfModule(selfModule) {
    var pluginSweeperComponent = selfModule.getRoot().findDescendentByID(pluginSweeperComponentId);

    return pluginSweeperComponent ? {
        name: 'Integrity Reports',
        icon: 'material-ui/svgIcons/ActionSpellcheck',
        permissions: [{ keyword: 'report_sweeper_section', ruby_client: 3, action: '*' }],
        route: urljoin('/app/admin/', SWEEPER_CONSTANTS.ROUTE, '/reports')
    } : null;
}