'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = initialState;

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initialState(dndProvider) {
    return _immutable2.default.fromJS({
        dndProvider: dndProvider
    });
};