'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _InternationalAddressConnector = require('./reactComponents/InternationalAddressConnector');

var _InternationalAddressConnector2 = _interopRequireDefault(_InternationalAddressConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Promise = require('bluebird');

var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var RubyComponentFESettings = require('@rubyapps/ruby-component-frontend-settings');
var RubyComponentHeader__CONSTANTS = require('@rubyapps/ruby-component-header/src/common/constants');

var FieldText = require('@rubyapps/ruby-component-field-text');
var FieldDropdown = require('@rubyapps/ruby-component-field-dropdown');

var mixinUtils = require('@rubyapps/ruby-component-mixin-utils');

var RCInternationalAddress = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {} //# some are already included by the baseFieldMixin
    , componentName: componentName,
    getDefaultProps: function getDefaultProps(props) {
        var selfRequired = _lodash2.default.get(props, 'verify.required', false);

        //# Overriding styles
        var dropdownStyleObject = {
            SelectField: {
                style: {
                    width: '100%'
                }
            }
        };

        var mode = props.mode,
            disabled = props.disabled,
            locked = props.locked;


        return {
            childrenPropsByKey: {
                latitude: {
                    label: "Latitude",
                    key: "latitude",
                    data_type: "number",
                    ruby_permissions: props.ruby_permissions,
                    "verify": { "required": false },
                    excludeWrapper: true,
                    mode: mode,
                    disabled: disabled,
                    locked: locked
                },
                longitude: {
                    label: "Longitude",
                    key: "longitude",
                    data_type: "number",
                    ruby_permissions: props.ruby_permissions,
                    "verify": { "required": false },
                    excludeWrapper: true,
                    mode: mode,
                    disabled: disabled,
                    locked: locked
                },
                country: {
                    label: "Country",
                    key: "country",
                    data_type: "string",
                    ruby_permissions: props.ruby_permissions,
                    url: "/ruby/api/v2/settings/countries?" + "params=" + encodeURIComponent("{\"commonOnTop\":true}"),
                    "verify": { "required": selfRequired },
                    excludeWrapper: true,
                    autoWidth: true,
                    styles: dropdownStyleObject,
                    mode: mode,
                    disabled: disabled,
                    locked: locked
                },
                address_line_1: {
                    label: "Address 1",
                    key: "address_line_1",
                    data_type: "string",
                    ruby_permissions: props.ruby_permissions,
                    "verify": { "required": selfRequired },
                    excludeWrapper: true,
                    mode: mode,
                    disabled: disabled,
                    locked: locked
                },
                address_line_2: {
                    label: "Address 2",
                    key: "address_line_2",
                    data_type: "string",
                    ruby_permissions: props.ruby_permissions,
                    "verify": { "required": false },
                    excludeWrapper: true,
                    mode: mode,
                    disabled: disabled,
                    locked: locked
                },
                city: {
                    label: "City",
                    key: "city",
                    data_type: "string",
                    ruby_permissions: props.ruby_permissions,
                    "verify": { "required": selfRequired },
                    excludeWrapper: true,
                    mode: mode,
                    disabled: disabled,
                    locked: locked
                },
                us_state: {
                    label: "State/Region",
                    key: "us_state",
                    data_type: "string",
                    ruby_permissions: props.ruby_permissions,
                    url: "/ruby/api/v2/settings/country-subdivisions?where={\"countryCode\":\"US\"}",
                    "verify": { "required": selfRequired },
                    excludeWrapper: true,
                    autoWidth: true,
                    styles: dropdownStyleObject,
                    mode: mode,
                    disabled: disabled,
                    locked: locked
                },
                ca_state: {
                    label: "State/Region",
                    key: "ca_state",
                    data_type: "string",
                    ruby_permissions: props.ruby_permissions,
                    url: "/ruby/api/v2/settings/country-subdivisions?where={\"countryCode\":\"CA\"}",
                    "verify": { "required": selfRequired },
                    excludeWrapper: true,
                    autoWidth: true,
                    styles: dropdownStyleObject,
                    mode: mode,
                    disabled: disabled,
                    locked: locked
                },
                gb_state: {
                    label: "State/Region",
                    key: "gb_state",
                    data_type: "string",
                    ruby_permissions: props.ruby_permissions,
                    url: "/ruby/api/v2/settings/country-subdivisions?where={\"countryCode\":\"GB\"}",
                    "verify": { "required": selfRequired },
                    excludeWrapper: true,
                    autoWidth: true,
                    styles: dropdownStyleObject,
                    mode: mode,
                    disabled: disabled,
                    locked: locked
                },
                other_state: {
                    label: "State/Region",
                    key: "other_state",
                    data_type: "string",
                    ruby_permissions: props.ruby_permissions,
                    "verify": { "required": selfRequired },
                    excludeWrapper: true,
                    mode: mode,
                    disabled: disabled,
                    locked: locked
                },
                postal_code: {
                    label: "Postal Code",
                    key: "postal_code",
                    data_type: "string",
                    ruby_permissions: props.ruby_permissions,
                    "verify": { "required": selfRequired },
                    excludeWrapper: true,
                    mode: mode,
                    disabled: disabled,
                    locked: locked
                }
            }
        };
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();
        var latEl = this.cachedElements.latEl;
        var lngEl = this.cachedElements.lngEl;

        var feSettingsID = this.props.feSettingsID || RubyComponentFESettings.componentName;
        var feSettingsComponent = root.findDescendentByID(feSettingsID);
        var feSettingsSelector = feSettingsComponent.getDefaultSelector();

        var headerComponent = root.findDescendentByID(RubyComponentHeader__CONSTANTS.COMPONENT_NAME);

        return {
            selfSelector: selfSelector,
            feSettingsSelector: feSettingsSelector,
            latEl: latEl,
            lngEl: lngEl,
            headerComponent: headerComponent
        };
    },
    children: function children() {
        var parentID = this.getID();
        var selfRequired = this.props.verify.required;

        var latEl = RubyComponent.createElement(FieldText, _extends({
            id: parentID + '.latitude'
        }, this.props.childrenPropsByKey.latitude));

        var lngEl = RubyComponent.createElement(FieldText, _extends({
            id: parentID + '.longitude'
        }, this.props.childrenPropsByKey.longitude));

        if (this.cachedElements == undefined) {
            this.cachedElements = {};
        }

        this.cachedElements.latEl = latEl;
        this.cachedElements.lngEl = lngEl;

        return [latEl, lngEl, RubyComponent.createElement(FieldText, _extends({
            id: parentID + '.address_line_1'
        }, this.props.childrenPropsByKey.address_line_1)), RubyComponent.createElement(FieldText, _extends({
            id: parentID + '.address_line_2'
        }, this.props.childrenPropsByKey.address_line_2)), RubyComponent.createElement(FieldText, _extends({
            id: parentID + '.city'
        }, this.props.childrenPropsByKey.city)), RubyComponent.createElement(FieldDropdown, _extends({
            id: parentID + '.country'
        }, this.props.childrenPropsByKey.country)), RubyComponent.createElement(FieldDropdown, _extends({
            id: parentID + '.us_state'
        }, this.props.childrenPropsByKey.us_state)), RubyComponent.createElement(FieldDropdown, _extends({
            id: parentID + '.ca_state'
        }, this.props.childrenPropsByKey.ca_state)), RubyComponent.createElement(FieldDropdown, _extends({
            id: parentID + '.gb_state'
        }, this.props.childrenPropsByKey.gb_state)), RubyComponent.createElement(FieldText, _extends({
            id: parentID + '.other_state'
        }, this.props.childrenPropsByKey.other_state)), RubyComponent.createElement(FieldText, _extends({
            id: parentID + '.postal_code'
        }, this.props.childrenPropsByKey.postal_code))];
    },
    getReactClass: function getReactClass() {
        return _InternationalAddressConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var InternationalAddressComponent = this.getReactClass();

        return React.createElement(InternationalAddressComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'InternationalAddressComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'InternationalAddressComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'InternationalAddressComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },

    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector;

        return {
            self: selfSelector(state),
            fieldProps: this.fieldProps_fromState_andOwnProps(state, this.props)
        };
    },

    CONSTANTS: CONSTANTS

    //# Mixin overrides
    , _formValueFromLocalState: function _formValueFromLocalState(selfState, isError, predicateFormatter, entireState) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        var formValue = baseFieldMixin._formValueFromLocalState.apply(this, arguments) || {};

        if (selfState == undefined) {
            return undefined;
        }

        var selfID = this.getID();
        var selfKey = this.props.key;

        var reducedChildrenState = (selfKey ? formValue[selfKey] : formValue) || {};

        var targetStateKey = countryCodeToStateKey(reducedChildrenState.country);

        var mappedFormValue = _extends({
            state: reducedChildrenState[targetStateKey] || null
        }, _lodash2.default.omit(reducedChildrenState, ['us_state', 'ca_state', 'gb_state', 'other_state']));

        return selfKey ? _defineProperty({}, selfKey, mappedFormValue) : mappedFormValue;
    },
    _formValueToLocalState: function _formValueToLocalState(formValue, dispatchOrCollect, isError, entireFormValue, options) {
        var selfID = this.getID();
        var selfKey = this.props.key;
        var countryElement = this.findDescendentBy(function (el) {
            return el.props.key === 'country';
        });

        var childrenValue = (formValue && selfKey ? formValue[selfKey] : formValue) || {};

        var countryValue = !isError ? childrenValue.country : countryElement.getState().fields.country.value;

        var targetStateKey = countryCodeToStateKey(countryValue);
        var mappedChildrenFormValue = _extends(_defineProperty({}, targetStateKey, childrenValue.state), _lodash2.default.omit(childrenValue, ['state']));

        var mappedFormValue = selfKey ? _defineProperty({}, selfKey, mappedChildrenFormValue) : mappedChildrenFormValue;

        return baseFieldMixin._formValueToLocalState.call(this, mappedFormValue, dispatchOrCollect, isError, entireFormValue, options);
    },

    _objectValueFromLocalState: function _objectValueFromLocalState(selfState, isError, limitToTabWithLabel) {
        // Correctly update form errors in parent fieldset/tab
        // based on the error in the active state, not the inactive states
        // (I mean the state as in city, state, zip; not the actual program state)
        var modifiedState = isError ? removeInactiveStateErrors(selfState) : selfState;

        var modifiedArguments = [modifiedState].concat(Array.prototype.slice.call(arguments, 1));

        var last_mixin = mixinUtils.superMixinContaining(this, '_objectValueFromLocalState');

        return last_mixin._objectValueFromLocalState.apply(this, modifiedArguments);
    },

    internalFormValue: function internalFormValue(selfState) {
        if (selfState == undefined) {
            return undefined;
        }
        var selfID = this.getID();
        var selfKey = this.props.key;

        var children = this.getChildren();

        var arrOfReducedChildrenState = _lodash2.default.reduce(children, function (collector, child, index) {
            var childID = child.getID();
            var childFormValue = child.formValueFromLocalState ? child._formValueFromLocalState(selfState[childID], false) : undefined;
            if (childFormValue != undefined) {
                collector.push(childFormValue);
            }
            return collector;
        }, []);

        var reducedChildrenState = _extends.apply(undefined, [{}].concat(_toConsumableArray(arrOfReducedChildrenState)));

        return _defineProperty({}, this.props.key, reducedChildrenState);
    }

    // class-level Utility methods
    /**
     *  Return fieldSpecs to be used by ruby-content-property-helper
     *  @property props
     *  @property ctx.dataPathArray - need to include this for nested components
     *  @params {Object} ctx.selfModule - the module of the root caller
     * */
    , getFieldSpecFromProps: function getFieldSpecFromProps(props, ctx) {
        var fieldSpecArr = [];
        var propsKey = props.key;
        var dataPathArray = ctx.dataPathArray;


        var updatedDataPathArray = dataPathArray.concat(propsKey || []);
        /*
        //# For complex widgets, regardless of whether the key is available
        //# We do not want to include the top-level component itself as a searchable field
        //# NOTE: if in the future, we want to support this, we will need to update the select columns feature to 
        //# be able to use a custom griddle-column-cell to display object values
        if (propsKey) {
            const preppedSpec = _.omit(props, ['children']);
            preppedSpec.dataPath = preppedSpec.key;
            fieldSpecArr.push(preppedSpec);
        }
        */

        var _props$childrenPropsB = props.childrenPropsByKey,
            childrenPropsByKey = _props$childrenPropsB === undefined ? {} : _props$childrenPropsB;


        var filteredChildrenPropsByKey = _lodash2.default.omit(childrenPropsByKey, ['ca_state', 'gb_state', 'other_state']);

        return _lodash2.default.reduce(filteredChildrenPropsByKey, function (collector, value, key) {
            var preppedValue = _extends({}, value);

            if (key == 'us_state') {
                preppedValue.key = 'state';
            }

            preppedValue.dataPath = updatedDataPathArray.concat(preppedValue.key).join('.');

            collector.push(preppedValue);

            return collector;
        }, fieldSpecArr);
    }

    //== OVERRIDES ======================//
    , _promisedFormValueFromFieldSpecs: function _promisedFormValueFromFieldSpecs() {
        var faker = require('@rubyapps/ruby-faker');
        var selfKey = this.props.key;

        var addressObject = {
            address_line_1: faker.address.streetAddress(),
            address_line_2: faker.address.secondaryAddress(),
            city: faker.address.city(),
            country: "US",
            postal_code: faker.address.zipCode(),
            state: faker.address.stateAbbr()
        };

        if (selfKey) {
            return Promise.resolve(_defineProperty({}, selfKey, addressObject));
        }

        return Promise.resolve(addressObject);
    }
});

function removeInactiveStateErrors(state) {
    if (!state) return state;

    // Get the currently selected country and its state field name
    var countryValue = getCountryValueFromState(state);
    var activeStateKey = countryCodeToStateKey(countryValue);

    // Return all fields that are not state fields
    // or are the active state field
    return _lodash2.default.reduce(state, function (modifiedState, fields, fieldId) {
        if (fields && fields.fields) {
            var fieldKey = Object.keys(fields.fields)[0];
            if (!isStateKey(fieldKey) || activeStateKey == fieldKey) {
                return _lodash2.default.assign({}, modifiedState, _defineProperty({}, fieldId, fields));
            }
        }

        return modifiedState;
    }, {});
}

//# UTILITY

var countryCodeToStateKeyMap = {
    'US': 'us_state',
    'CA': 'ca_state',
    'UK': 'gb_state'
};

function countryCodeToStateKey(countryCode) {
    return countryCodeToStateKeyMap[countryCode] || 'other_state';
}

function isStateKey(key) {
    return (/^(other|us|ca|gb)_state$/.test(key) ? true : false
    );
}

function getCountryValueFromState(state) {
    var countryField = _lodash2.default.find(state, function (field) {
        return field && field.fields && Object.keys(field.fields)[0] == "country";
    });

    return countryField ? countryField.fields.country.value : null;
}

module.exports = RCInternationalAddress;