'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Menu = undefined;

var _Menu2 = require('./Menu');

var _Menu3 = _interopRequireDefault(_Menu2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.Menu = _Menu3.default;
//export MenuItem from '../MenuItem';

exports.default = _Menu3.default;