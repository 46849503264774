'use strict';

var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = require('../../package.json').name;
var logger = rubyLogger.getLogger(packageName);
module.exports = {
    children: function children() {
        var _this = this;

        if (!this.props.__formattedChildren) {
            logger.warn('No __formattedChildren for component [' + this.getID() + ']. This shouldn\'t happen as we expect this to be provided by ruby-component-builder');

            return [];
        }

        var _require = require('@rubyapps/ruby-component-builder/src/common/index'),
            rubyComponentBuild = _require.default;

        var idPrefix = this.getID();
        var spec = this.props;
        var theComponentName = spec.key || this.componentName;
        var compoundID = spec.id ? spec.id : idPrefix ? idPrefix + '.' + theComponentName : theComponentName;

        var childrenDepth = this.props.__depth + 1;
        var childrenComponents = _.map(this.props.__formattedChildren, function (childSpec, index) {
            var builtChildComponent = rubyComponentBuild(childSpec, compoundID + '[' + index + ']', childrenDepth, index, { _parent: _this });
            return builtChildComponent;
        }).filter(function (rubyComponentChild) {
            return rubyComponentChild != undefined;
        });

        return childrenComponents;
    },
    limitedSelfKeypaths: function limitedSelfKeypaths() {
        var effectiveSpec = this.props.__originalSpec || this.props;
        if (!effectiveSpec.componentName) {
            console.error('No formJS spec found for component: [' + this.getID() + '] props:', this.props);

            return [];
        }

        return this.limitedKeypathsForNodes([effectiveSpec]);
        //# for complex components, the children wouldn't have props.__originalSpec
        //# (eg. the browser_page_title component under GooglePreview)
        //# fallback to this.props
    },
    limitedChildrenKeypaths: function limitedChildrenKeypaths() {
        var effectiveChildrenSpec = this.props.__formattedChildren;
        if (!effectiveChildrenSpec) {
            console.error('No __formattedChildren specs found for component: [' + this.getID() + '] props:', this.props);

            return [];
        }

        return this.limitedKeypathsForNodes(effectiveChildrenSpec);
    },
    limitedKeypathsForNodes: function limitedKeypathsForNodes(nodes) {
        var _getDependencies = this.getDependencies(),
            contentPropertyHelper = _getDependencies.contentPropertyHelper;
        //# need to pick only children keys  otherwise, the other keys stored here
        //# would override the actual fields


        var fieldSpecs = contentPropertyHelper.specsFromNodes_forTemplateKey(nodes, '');
        //# flatten
        //
        var flattenedFieldSpecs = contentPropertyHelper.nearestKeyedNodesFromSpecs(fieldSpecs);
        var limitedKeypaths = flattenedFieldSpecs.map(function (n) {
            return n.dataPath;
        });
        var limitedParentKeypaths = Object.keys(limitedKeypaths.reduce(function (collector, keypath) {
            var parentKeypathArr = keypath.split('.');
            if (parentKeypathArr.length > 1) {
                collector[parentKeypathArr[0]] = parentKeypathArr[0];
            }
            return collector;
        }, {})); //# NOTE: this is uniquely for matter_profile because for some reason
        //# limitKeypaths = ['matter_profile.*.type'...] but excludes 'matter_profile'
        //# whereas normal repeaters only includes the parent key 
        //# Hacky just for matter profiles

        return limitedParentKeypaths.concat(limitedKeypaths);
    }
};