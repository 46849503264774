'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _AppBar = require('material-ui/AppBar');

var _AppBar2 = _interopRequireDefault(_AppBar);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _close = require('material-ui/svg-icons/navigation/close');

var _close2 = _interopRequireDefault(_close);

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

var _Dialog = require('material-ui/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _dialog = require('@rubyapps/ruby-styles/src/client/styleObjects/dialog');

var _dialog2 = _interopRequireDefault(_dialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

var PropTypes = _react2.default.PropTypes;

var action = require('../action');

var Dialog__propTypeKeys = Object.keys(_Dialog2.default.propTypes);

var styles = {
    title: {}
};

var styleObject = {
    Dialog: _dialog2.default
};

var ConfirmDialogHeader = _react2.default.createClass({
    displayName: 'ConfirmDialogHeader',

    propTypes: {
        title: PropTypes.string,
        delegateClose: PropTypes.func,
        loading: PropTypes.bool
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4;

        var myContext = this;
        var styleColor = {
            color: '#ffffff'
        };

        return _react2.default.createElement(_AppBar2.default, (_React$createElement4 = {
            title: _react2.default.createElement(
                'span',
                (_React$createElement = { style: styles.title, 'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'ConfirmDialog'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ConfirmDialog'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ConfirmDialog'), _React$createElement),
                this.props.title || "Confirm"
            ),
            iconElementLeft: _react2.default.createElement(
                _IconButton2.default,
                (_React$createElement3 = { onClick: this.props.delegateClose, 'data-codecept-selector-node': 'IconButton',
                    'data-codecept-selector-file': 'ConfirmDialog'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ConfirmDialog'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ConfirmDialog'), _React$createElement3),
                _react2.default.createElement(_close2.default, (_React$createElement2 = {
                    'data-codecept-selector-node': 'NavigationClose',
                    'data-codecept-selector-file': 'ConfirmDialog'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'NavigationClose'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ConfirmDialog'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'NavigationClose'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ConfirmDialog'), _React$createElement2))
            ),
            'data-codecept-selector-node': 'AppBar',
            'data-codecept-selector-file': 'ConfirmDialog'
        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ConfirmDialog'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ConfirmDialog'), _React$createElement4));
    }
});

var ConfirmDialog = _react2.default.createClass({
    displayName: 'ConfirmDialog',

    propTypes: {
        //# state-like
        open: PropTypes.bool,
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        cancelLabel: PropTypes.string,
        submitLabel: PropTypes.string,
        submitLabel_secondary: PropTypes.string,
        loading: PropTypes.bool,

        endpoint: PropTypes.string
        //# delegate Actions
        , delegateClose: PropTypes.func,
        delegateSubmit: PropTypes.func
    },
    getDefaultProps: function getDefaultProps() {
        return {
            open: false
        };
    },

    render: function render() {
        var _React$createElement5, _React$createElement6, _React$createElement7, _extends2, _React$createElement8, _extends3;

        var actions = [_react2.default.createElement(_FlatButton2.default, (_React$createElement5 = {
            label: this.props.cancelLabel || "Cancel",
            primary: false,
            disabled: this.props.loading,
            onTouchTap: this.props.delegateClose,
            'data-codecept-selector-node': 'FlatButton',
            'data-codecept-selector-file': 'ConfirmDialog'
        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ConfirmDialog'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ConfirmDialog'), _React$createElement5))].concat(this.props.submitLabel_secondary ? _react2.default.createElement(_FlatButton2.default, (_React$createElement6 = {
            label: this.props.submitLabel_secondary,
            primary: false,
            disabled: this.props.loading,
            onTouchTap: this.props.delegateSubmit_secondary,
            'data-codecept-selector-node': 'FlatButton',
            'data-codecept-selector-file': 'ConfirmDialog'
        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'ConfirmDialog'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'ConfirmDialog'), _React$createElement6)) : null, _react2.default.createElement(_FlatButton2.default, (_React$createElement7 = {
            label: this.props.submitLabel || (this.props.endpoint ? "Submit" : "Ok"),
            disabled: this.props.loading,
            primary: true,
            onTouchTap: this.props.delegateSubmit,
            'data-codecept-selector-node': 'FlatButton',
            'data-codecept-selector-file': 'ConfirmDialog'
        }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'ConfirmDialog'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'ConfirmDialog'), _React$createElement7)));

        var confirmDialogHeaderProps = _.pick(this.props, Object.keys(ConfirmDialogHeader.propTypes));

        return _react2.default.createElement(
            _Dialog2.default,
            _extends({}, styleObject.Dialog, _.pick(this.props, Dialog__propTypeKeys), (_extends3 = {
                title: _react2.default.createElement(ConfirmDialogHeader, _extends({}, confirmDialogHeaderProps, (_extends2 = {
                    'data-codecept-selector-node': 'ConfirmDialogHeader',
                    'data-codecept-selector-file': 'ConfirmDialog'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'ConfirmDialogHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ConfirmDialog'), _defineProperty(_extends2, 'data-codecept-selector-node', 'ConfirmDialogHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ConfirmDialog'), _extends2))),
                modal: false,
                actions: actions,
                open: this.props.open,
                onRequestClose: this.props.delegateClose,
                'data-codecept-selector-node': 'Dialog',
                'data-codecept-selector-file': 'ConfirmDialog'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ConfirmDialog'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ConfirmDialog'), _extends3)),
            _react2.default.createElement(
                'div',
                (_React$createElement8 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'ConfirmDialog'
                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'ConfirmDialog'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'ConfirmDialog'), _React$createElement8),
                this.props.content
            )
        );
    }
});
exports.default = ConfirmDialog;