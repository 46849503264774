'use strict';

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rubyWords = require('@rubyapps/ruby-words');

module.exports = function (forms, templateKey, getContentListUrl_forTemplate) {
    var excludeFromFilter = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];


    var optKeys = Object.keys(forms);

    var defaultOption = null,
        options = [];

    options = optKeys.filter(function (option) {
        return !_lodash2.default.find(excludeFromFilter, function (exclude) {
            return option == exclude;
        });
    }).map(function (tk) {
        var form = forms[tk];
        var displayName = _lodash2.default.get(form, 'name');
        var option = {
            text: displayName || rubyWords.capitalizeWords(rubyWords.inflection.transform(tk, ['humanize', 'pluralize'])),
            value: getContentListUrl_forTemplate(tk)
        };

        if (tk === templateKey) {
            defaultOption = option;
        }

        return option;
    }).filter(function (opt) {
        return opt.text && opt.value;
    });

    var sortedOptions = _lodash2.default.sortBy(options, ['text']);

    return {
        options: sortedOptions,
        defaultOption: defaultOption
    };
};