"use strict";

function typesFromID(id) {
    var TYPES = {
        REPLACE_REDUX_STATE: "@@ruby-app/" + id + "/REPLACE_REDUX_STATE"
    };
    return TYPES;
}

var generators = {
    replaceReduxState: function replaceReduxState(reduxState) {
        var TYPES = this.getAction().TYPES;
        return {
            type: TYPES.REPLACE_REDUX_STATE,
            payload: {
                reduxState: reduxState
            }
        };
    }
};

module.exports = function () {
    var TYPES = typesFromID(this.getID());
    return {
        TYPES: TYPES,
        generators: generators
    };
};