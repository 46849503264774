'use strict';

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RubyComponentTemplateEditPage__CONSTANTS = require('@rubyapps/ruby-component-plugin-template-editor/src/local_modules/ruby-component-template-edit-page/src/common/constants');
var RubyComponentFieldForm__CONSTANTS = require('@rubyapps/ruby-component-field-form/src/common/constants');

//== The Middleware ========================================================//

var middlewareInitializer = function middlewareInitializer(store) {
    var selfModule = this;
    var selfSelector = this.getDefaultSelector();
    var selfAction = selfModule.getAction();

    var _selfModule$getDepend = selfModule.getDependencies(),
        pluginTemplateEditorComponent = _selfModule$getDepend.pluginTemplateEditorComponent;

    var formComponents = pluginTemplateEditorComponent.findDescendentsBy(function (node) {
        return node.componentName == RubyComponentFieldForm__CONSTANTS.COMPONENT_NAME;
    });
    var saveSuccess_actionTypes = formComponents.reduce(function (collector, formComponent) {
        var SAVE_SUCCESSFUL_TYPE = formComponent.getAction().TYPES.SAVE_SUCCESSFUL;
        if (SAVE_SUCCESSFUL_TYPE) {
            collector.push(SAVE_SUCCESSFUL_TYPE);
        }

        return collector;
    }, []);

    return function (next) {
        return function (action) {
            var nextRetVal = next(action); //# still allow @@router to continue propagation
            var actionType = action.type;

            if (_lodash2.default.includes(saveSuccess_actionTypes, actionType)) {
                store.dispatch(selfAction.generators.retrieveTemplates());
            }

            return nextRetVal;
        };
    };
};

module.exports = middlewareInitializer;