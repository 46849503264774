'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

/*
  It should be noted that the action types that are like
  GRIDDLE_FILTER mean that the operation has started.
  Past tense action types mean that the operation
  has completed.
*/

var GRIDDLE_FILTER = exports.GRIDDLE_FILTER = 'GRIDDLE_FILTER';
var GRIDDLE_FILTERED = exports.GRIDDLE_FILTERED = 'GRIDDLE_FILTERED';
var GRIDDLE_FILTER_BY_COLUMN = exports.GRIDDLE_FILTER_BY_COLUMN = 'GRIDDLE_FILTER_BY_COLUMN';
var GRIDDLE_FILTERED_BY_COLUMN = exports.GRIDDLE_FILTERED_BY_COLUMN = 'GRIDDLE_FILTERED_BY_COLUMN';
var GRIDDLE_FILTER_BY_ADDITIONAL_COLUMN = exports.GRIDDLE_FILTER_BY_ADDITIONAL_COLUMN = 'GRIDDLE_FILTER_BY_ADDITIONAL_COLUMN';
var GRIDDLE_FILTERED_BY_ADDITIONAL_COLUMN = exports.GRIDDLE_FILTERED_BY_ADDITIONAL_COLUMN = 'GRIDDLE_FILTERED_BY_ADDITIONAL_COLUMN';
var GRIDDLE_FILTER_REMOVED = exports.GRIDDLE_FILTER_REMOVED = 'GRIDDLE_FILTER_REMOVED';
var GRIDDLE_SORT = exports.GRIDDLE_SORT = 'GRIDDLE_SORT';
var GRIDDLE_SORTED = exports.GRIDDLE_SORTED = 'GRIDDLE_SORTED';
var GRIDDLE_SORT_ADDITIONAL = exports.GRIDDLE_SORT_ADDITIONAL = 'GRIDDLE_SORT_ADDITIONAL';
var GRIDDLE_SORTED_ADDITIONAL = exports.GRIDDLE_SORTED_ADDITIONAL = 'GRIDDLE_SORTED_ADDITIONAL';
var GRIDDLE_LOAD_DATA = exports.GRIDDLE_LOAD_DATA = 'GRIDDLE_LOAD_DATA';
var GRIDDLE_LOADED_DATA = exports.GRIDDLE_LOADED_DATA = 'GRIDDLE_LOADED_DATA';
var GRIDDLE_NEXT_PAGE = exports.GRIDDLE_NEXT_PAGE = 'GRIDDLE_NEXT_PAGE';
var GRIDDLE_PREVIOUS_PAGE = exports.GRIDDLE_PREVIOUS_PAGE = 'GRIDDLE_PREVIOUS_PAGE';
var GRIDDLE_GET_PAGE = exports.GRIDDLE_GET_PAGE = 'GRIDDLE_GET_PAGE';
var GRIDDLE_REFRESH_CURRENT_PAGE = exports.GRIDDLE_REFRESH_CURRENT_PAGE = 'GRIDDLE_REFRESH_CURRENT_PAGE';
var GRIDDLE_PAGE_LOADED = exports.GRIDDLE_PAGE_LOADED = 'GRIDDLE_PAGE_LOADED';
var GRIDDLE_SET_PAGE_SIZE = exports.GRIDDLE_SET_PAGE_SIZE = 'GRIDDLE_SET_PAGE_SIZE';
var GRIDDLE_INITIALIZE = exports.GRIDDLE_INITIALIZE = 'GRIDDLE_INITIALIZE';
var GRIDDLE_INITIALIZED = exports.GRIDDLE_INITIALIZED = 'GRIDDLE_INITIALIZED';
var GRIDDLE_REMOVED = exports.GRIDDLE_REMOVED = 'GRIDDLE_REMOVED';
var GRIDDLE_TOGGLE_COLUMN = exports.GRIDDLE_TOGGLE_COLUMN = 'GRIDDLE_TOGGLE_COLUMN';
var GRIDDLE_SET_COLUMN_PROPERTIES = exports.GRIDDLE_SET_COLUMN_PROPERTIES = 'GRIDDLE_SET_COLUMN_PROPERTIES';
var GRIDDLE_SET_COLUMN_RESIZE_PROPERTIES = exports.GRIDDLE_SET_COLUMN_RESIZE_PROPERTIES = 'GRIDDLE_SET_COLUMN_RESIZE_PROPERTIES';
var GRIDDLE_SET_COLUMN_RENDERED_SIZE_PROPERTIES = exports.GRIDDLE_SET_COLUMN_RENDERED_SIZE_PROPERTIES = 'GRIDDLE_SET_COLUMN_RENDERED_SIZE_PROPERTIES';
var GRIDDLE_ROW_TOGGLED = exports.GRIDDLE_ROW_TOGGLED = 'GRIDDLE_ROW_TOGGLED';
var GRIDDLE_ROW_SELECTION_TOGGLED = exports.GRIDDLE_ROW_SELECTION_TOGGLED = 'GRIDDLE_ROW_SELECTION_TOGGLED';

var GRIDDLE_PREPEND_STATIC_DATA = exports.GRIDDLE_PREPEND_STATIC_DATA = 'GRIDDLE_PREPEND_STATIC_DATA';
var GRIDDLE_SET_STATIC_DATA = exports.GRIDDLE_SET_STATIC_DATA = 'GRIDDLE_SET_STATIC_DATA';