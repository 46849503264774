'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react'),
    ReactDOM = require('react-dom'),
    _ = require('lodash'),
    Promise = require('bluebird'),
    request = require('superagent'),
    moment = require('moment');

var GriddleModule = require('@rubyapps/griddle');
var Griddle = GriddleModule.Griddle;
var RowDefinition = GriddleModule.GriddleRender.DefaultModules.RowDefinition;
var ColumnDefinition = GriddleModule.GriddleRender.DefaultModules.ColumnDefinition;
var GriddleSubgridPlugin = GriddleModule.SubgridPlugin;

var dndContextDecorator = require('@rubyapps/ruby-dnd-context-decorator');
var ContextualizedGriddle = dndContextDecorator(Griddle);

var GriddleWrapper = React.createClass({
    displayName: 'GriddleWrapper',

    propTypes: {
        actions: React.PropTypes.object,
        delegateActions: React.PropTypes.object,
        columnDefinitions: React.PropTypes.array,
        components: React.PropTypes.object,
        currentPage: React.PropTypes.number,
        data: React.PropTypes.array,
        filterData: React.PropTypes.func,
        griddleProps: React.PropTypes.object,
        headerLabelElement: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.node]),
        loadPage: React.PropTypes.func,
        plugins: React.PropTypes.array,
        pluginsSettings: React.PropTypes.object,
        reflectGriddleState: React.PropTypes.func,
        remoteDataArgs: React.PropTypes.object,
        results: React.PropTypes.array,
        resultsPerPage: React.PropTypes.number,
        setPageSize: React.PropTypes.func,
        sort: React.PropTypes.func
    },
    getDefaultProps: function getDefaultProps() {
        return {
            griddleProps: {},
            reflectGriddleState: function reflectGriddleState(griddleState) {
                var prevGriddleState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

                //# we have to keep this limited to what we'd reflect because if we just reflected everything
                //# it'll be stuck in a loop
                var GRIDDLE_STATE_PROPS = ['utilityBarProperties', 'recentRequest', 'loading', 'visibleData'];
                var selected_griddleState = _.pick(griddleState, GRIDDLE_STATE_PROPS);
                var selected_prevGriddleState = _.pick(prevGriddleState, GRIDDLE_STATE_PROPS);

                if (!_.isEqual(selected_griddleState, selected_prevGriddleState)) {
                    if (this.props.actions.setGriddleState) {
                        this.props.actions.setGriddleState(griddleState);
                    } else {
                        console.warn('setGriddleState is not defined, check if the current lister page is using the list-page mixin');
                    }
                    if (this.props.actions.autosaveSearch_fromGriddleState) {
                        this.props.actions.autosaveSearch_fromGriddleState(griddleState);
                    } else {
                        console.warn('autosaveSearch_fromGriddleState is not defined, check if the current lister page is using the list-page mixin');
                    }
                }
            }
        };
    },
    render: function render() {
        var _extends2, _React$createElement;

        return React.createElement(
            'div',
            (_React$createElement = { className: 'griddle', 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Lister'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Lister'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Lister'), _React$createElement),
            React.createElement(
                ContextualizedGriddle,
                _extends({
                    data: this.props.results || this.props.data,
                    remoteDataArgs: this.props.remoteDataArgs,
                    plugins: this.props.plugins,
                    components: this.props.components,
                    headerLabelElement: this.props.headerLabelElement,
                    pluginsSettings: this.props.pluginsSettings,
                    settings: { useGriddleStyles: false },
                    reflectGriddleState: this.props.reflectGriddleState.bind(this)
                }, this.props.griddleProps, (_extends2 = {
                    'data-codecept-selector-node': 'ContextualizedGriddle',
                    'data-codecept-selector-file': 'Lister'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'ContextualizedGriddle'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Lister'), _defineProperty(_extends2, 'data-codecept-selector-node', 'ContextualizedGriddle'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Lister'), _extends2)),
                this.addColumnDefinitions()
            )
        );
    },

    addColumnDefinitions: function addColumnDefinitions() {
        if (this.props.columnDefinitions.length) {
            var _React$createElement2;

            var theRowDefinition = React.createElement(
                RowDefinition,
                (_React$createElement2 = { keyColumn: 'id', 'data-codecept-selector-node': 'RowDefinition',
                    'data-codecept-selector-file': 'Lister'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'RowDefinition'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Lister'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'RowDefinition'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Lister'), _React$createElement2),
                _.map(this.props.columnDefinitions, function (columnData) {
                    var _extends3;

                    return React.createElement(ColumnDefinition, _extends({ key: columnData.id }, columnData, (_extends3 = {
                        'data-codecept-selector-node': 'ColumnDefinition',
                        'data-codecept-selector-file': 'Lister'
                    }, _defineProperty(_extends3, 'data-codecept-selector-node', 'ColumnDefinition'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Lister'), _defineProperty(_extends3, 'data-codecept-selector-node', 'ColumnDefinition'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Lister'), _extends3)));
                })
            );
            return theRowDefinition;
        }
        return null;
    }

});

module.exports = GriddleWrapper;