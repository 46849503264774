'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Tabs = require('@rubyapps/ruby-material-ui/Tabs');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

//import {Tabs} from 'material-ui/Tabs';


var TabsPropTypeKeys = Object.keys(_Tabs.Tabs.propTypes);
var FieldTabs = _react2.default.createClass({
    displayName: 'FieldTabs',

    propTypes: {
        selectedIndex: PropTypes.number
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    render: function render() {
        var _extends2;

        var alternateInkBarStyle = _lodash2.default.get(this.context, ['muiTheme', 'alternates', 'inkBar']);
        return _react2.default.createElement(
            _Tabs.Tabs,
            _extends({}, _lodash2.default.pick(this.props, TabsPropTypeKeys), (_extends2 = {
                inkBarStyle: alternateInkBarStyle,
                tabTemplate: _Tabs.TabTemplate,
                'data-codecept-selector-node': 'Tabs',
                'data-codecept-selector-file': 'Tabs'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Tabs'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Tabs'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Tabs'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Tabs'), _extends2)),
            this.props.children
        );
    }
});
FieldTabs.rubyDebug = true;
exports.default = FieldTabs;