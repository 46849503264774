'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _CodeEditorConnector = require('./reactComponents/CodeEditorConnector');

var _CodeEditorConnector2 = _interopRequireDefault(_CodeEditorConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');
var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');

var RcJsonEditor = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin]
    //, propTypes: {}
    , getDefaultProps: CONSTANTS.GET_DEFAULT_PROPS,
    componentName: componentName,
    dependencies: function dependencies() {
        //# declare dependencies here so it's easy to find
        var rootComponent = this.getRoot();

        var selfSelector = this.getDefaultSelector();

        return {
            selfSelector: selfSelector
        };
    },
    getReactClass: function getReactClass() {
        var CodeEditorComponent = _CodeEditorConnector2.default.apply(this);
        return CodeEditorComponent;
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var CodeEditorComponent = this.getReactClass();

        return React.createElement(CodeEditorComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'CodeEditorComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'CodeEditorComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'CodeEditorComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    }
    //# always normalize as object/array in localstate
    //# only convert it for formValue when saving to server
    //# this method is used for coercing from formValue to localState
    , coercedValue: function coercedValue(value, getPrimitiveValue) {
        //# if it's a string, we try converting it into a json object

        if (_.isString(value)) {
            try {
                return JSON.parse(value);
            } catch (err) {
                return value; //# string is not a json object, so we just return the string, 
                //# though we shouldn't allow for editing just a string using this field
            }
        }

        return value; //# assume it's an object
    },
    formValueFromFieldValue_forKey: function formValueFromFieldValue_forKey(value) {
        var dataType = this.props.data_type;
        var rawValue = value;

        switch (dataType) {
            case 'string':
                {
                    return JSON.stringify(rawValue, null, '  ');
                }
            default:
                return rawValue;
        }
    }
});

module.exports = RcJsonEditor;