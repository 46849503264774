'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp2;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _columnHelper = require('./utils/column-helper');

var _columnHelper2 = _interopRequireDefault(_columnHelper);

var _styleHelper = require('./utils/styleHelper');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/*
const jsondiffpatch = require('jsondiffpatch').create()
jsondiffpatch.processor.pipes.diff.before('trivial', function ignoreFunctionDiffFilter(context) {
    if (typeof context.left === 'function' || typeof context.right === 'function' ) {
        context.setResult(undefined);
        context.exit();
    }
});
*/
//# NOTE: when we drag and drop, it'll trigger a lister reload because we call on setFieldValue, which will trigger a reload
//# if you look at the jsondiffpatch, you will see that the updated data is just missing some meta properties, which is ok
//# however, becacuse it's missing those values, the _.isEqual we're using is flag them as different
//# Note that, even with the hacky workaround to use jsondiff to see if it improved speed, dragging rerenders still seem sluggish
//# Most rows are not rerendered on dragging, so I think the delay is from somewhere else (probably from the dnd wrapper)

var Row = (_temp2 = _class = function (_React$Component) {
    _inherits(Row, _React$Component);

    function Row() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, Row);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Row.__proto__ || Object.getPrototypeOf(Row)).call.apply(_ref, [this].concat(args))), _this), _this._handleHover = function (e) {
            _this.props.events.rowHover(_this.props.rowIndex, _this.props.rowData);
        }, _this._handleSelect = function (e) {
            _this.props.events.rowSelect(_this.props.rowIndex, _this.props.rowData);
        }, _this._onClick = function () {
            _this.props.events.rowClick(_this.props.rowData, _this.props.originalRowData);
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    //static defaultProps = {...Component.defaultProps};
    //constructor(props, context) {super(props, context);}

    _createClass(Row, [{
        key: 'shouldComponentUpdate',
        value: function shouldComponentUpdate(nextProps, nextState) {

            var shouldComponentUpdateByComponent = Row.staticShouldComponentUpdateByComponent(this.props, nextProps, this.state, nextState);

            this._shouldComponentUpdateByComponent = shouldComponentUpdateByComponent; //# cached for erender

            return _lodash2.default.reduce(shouldComponentUpdateByComponent, function (collector, value, key) {
                return collector || value;
            }, false);
        }
    }, {
        key: 'render',
        value: function render() {
            var _this2 = this;

            // console.log('[<Row>] rendering', this.props.rowIndex, this.props.rowData.id, this.props.rowData.name);
            //TODO: Refactor this  -- the logic to show / hide columns is kind of rough
            //      Also, it seems that if we moved this operation to a store, it could be a bit faster
            var _props = this.props,
                columnProperties = _props.columnProperties,
                _props$columns = _props.columns,
                columnKeys = _props$columns === undefined ? Object.keys(rowData) : _props$columns,
                ignoredColumns = _props.ignoredColumns,
                tableProperties = _props.tableProperties,
                rowData = _props.rowData,
                events = _props.events,
                originalRowData = _props.originalRowData,
                rowIndex = _props.rowIndex,
                absoluteRowIndex = _props.absoluteRowIndex;
            var griddleKey = rowData.__metadata.griddleKey;

            //render just the columns that are contained in the metdata

            var visibleColumnSpecs = columnKeys.reduce(function (result, column) {
                var columnProperty = _columnHelper2.default.getColumnPropertyObject(columnProperties, column);
                if (_columnHelper2.default.isColumnVisible(column, { columnProperties: columnProperties, ignoredColumns: ignoredColumns || [] })) {
                    return result.concat({ column: column, columnProperty: columnProperty });
                } else {
                    return result;
                }
            }, []);
            var columns = _lodash2.default.map(visibleColumnSpecs, function (_ref2) {
                var column = _ref2.column,
                    columnProperty = _ref2.columnProperty;

                var columnKey = column;

                var keyPathForValue = columnProperty.hasOwnProperty('keyPathOverride') ? _lodash2.default.get(columnProperty, 'keyPathOverride') : columnKey;

                return _react2.default.createElement(_this2.props.components.Column, _extends({}, _this2.props, {
                    key: columnKey + '-row-' + rowIndex,
                    originalRowData: originalRowData,
                    absoluteRowIndex: absoluteRowIndex,
                    dataKey: columnKey,
                    value: keyPathForValue ? _lodash2.default.get(rowData, keyPathForValue) : originalRowData
                }, columnProperty, {
                    style: columnKey === _lodash2.default.last(visibleColumnSpecs).column ? {
                        marginRight: 0,
                        marginLeft: 'auto',
                        justifyContent: 'flex-end'
                    } : {},
                    'data-codecept-selector-file': 'row'
                }));
            });

            var _getStyleProperties = (0, _styleHelper.getStyleProperties)(this.props, 'row'),
                style = _getStyleProperties.style,
                className = _getStyleProperties.className;

            var finalStyle = _extends({}, style, {
                display: 'flex',
                alignItems: 'center'
            });
            var evenRow = (this.props.rowIndex + 1) % 2 === 0;

            //# NOTE: need to be explicit with the even/odd row className because the react-virtualize
            //# will render out-of-order so what should be "even" would be odd if you relied on css nth-child selector
            return _react2.default.createElement(
                'div',
                {
                    style: finalStyle,
                    className: className + ' tr ' + (evenRow ? 'even-row' : 'odd-row'),
                    'data-isstatic': originalRowData.isStatic,
                    onMouseOver: this._handleHover,
                    onMouseEnter: this.props.onMouseEnter,
                    onClick: this._onClick,
                    key: griddleKey,
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'row'
                },
                _react2.default.createElement('div', { className: 'griddle-cell_overflow-shadow', 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'row'
                }),
                columns
            );
        }

        //TODO: this can go -- double confirm that nothing is using it

    }], [{
        key: 'staticShouldComponentUpdateByComponent',
        value: function staticShouldComponentUpdateByComponent(props, nextProps, state, nextState) {
            var shouldComponentUpdateByComponent_next = nextProps.shouldComponentUpdateByComponent || {};

            if (shouldComponentUpdateByComponent_next.hasOwnProperty(Row.name)) {
                return shouldComponentUpdateByComponent_next;
            }

            var PROPKEYS_TO_COMPARE = Row.PROPKEYS_TO_COMPARE;
            var propsToCompare_curr = _lodash2.default.pick(props, PROPKEYS_TO_COMPARE);
            var propsToCompare_next = _lodash2.default.pick(nextProps, PROPKEYS_TO_COMPARE);

            //# try using jsondiff to find the exact diff
            //console.time('==== isEqual');
            var shouldComponentUpdate_self = !_lodash2.default.isEqual(propsToCompare_curr, propsToCompare_next);
            //console.timeEnd('==== isEqual');

            //console.time('==== jsondiff');
            //const diff = jsondiffpatch.diff(propsToCompare_curr, propsToCompare_next);
            //console.timeEnd('==== jsondiff');

            //console.log(`==== [Row: ${props.rowData.name}] shouldComponentUpdate_self: ${shouldComponentUpdate_self}`, propsToCompare_curr, propsToCompare_next, diff);

            return _defineProperty({}, Row.name, shouldComponentUpdate_self);
        }
    }]);

    return Row;
}(_react2.default.Component), _class.PropTypes = {
    components: _react.PropTypes.object.isRequired,
    columnProperties: _react.PropTypes.object.isRequired,
    rowData: _react.PropTypes.object.isRequired }, _class.PROPKEYS_TO_COMPARE = ['events', 'value', 'rowData', 'columns', 'originalRowData', 'renderedSizeProperties', 'rowIndex' //# global changes when expanding/collapsing subgrid causing every row to update
//# NOTE: this should be ok now with react-virtualized
, 'absoluteRowIndex', 'extraData', 'styles.classNames', 'styles.getClassNames', 'styles.getStyle', 'styles.inlineStyles', 'utilityBarProperties'], _temp2);
exports.default = Row;