'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _addInfoModeProps = require('@rubyapps/ruby-react-components/src/client/hoc/addInfoModeProps');

var _addInfoModeProps2 = _interopRequireDefault(_addInfoModeProps);

var _Text_style = require('./Text_style');

var _Text_style2 = _interopRequireDefault(_Text_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label,
    RichErrorMessages = _require.RichErrorMessages;

var Text = _react2.default.createClass({
    displayName: 'Text',

    propTypes: {
        label: PropTypes.string,
        value: PropTypes.string,
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        id: PropTypes.string,
        hintText: PropTypes.string,
        actions: PropTypes.object,
        excludeWrapper: PropTypes.bool
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null
        };
    },
    _onTextChange: function _onTextChange(event) {
        this.props.actions.setFieldValueByKey(event.target.value, this.props.fieldKey);
    },
    render: function render() {
        var _React$createElement, _extends2, _extends3;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var labelProps = _.pick(this.props, Object.keys(Label.propTypes));
        var errorText = !_.isEmpty(this.props.richErrorMessages) ? _react2.default.createElement(RichErrorMessages, (_React$createElement = { messages: this.props.richErrorMessages, 'data-codecept-selector-node': 'RichErrorMessages',
            'data-codecept-selector-file': 'Text'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Text'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Text'), _React$createElement)) : null;
        var inputComponent = _react2.default.createElement(_TextField2.default, _extends({}, _Text_style2.default.TextField, {
            floatingLabelText: _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                'data-codecept-selector-node': 'Label',
                'data-codecept-selector-file': 'Text'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Text'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Text'), _extends2))),
            id: this.props.id,
            onChange: this._onTextChange,
            value: this.props.value || '',
            errorText: errorText,
            disabled: this.props.locked
        }, _.pick(this.props, ['hintText']), _.get(this, 'props.infoModeProps.default'), _.get(this, 'props.infoModeProps.TextField'), (_extends3 = {
            errorStyle: {
                color: _.get(this, 'props.errorColors.foreground')
            },
            'data-codecept-selector-node': 'TextField',
            'data-codecept-selector-file': 'Text'
        }, _defineProperty(_extends3, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Text'), _defineProperty(_extends3, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Text'), _extends3)));

        return this.props.renderGridWrapper(_extends({}, this.props, { children: inputComponent }));
    }
});

exports.default = (0, _addInfoModeProps2.default)(Text);