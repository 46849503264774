'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp; //# https://raw.githubusercontent.com/mui/material-ui/v0.15.4/src/Snackbar/Snackbar.js

//import transitions from '../styles/transitions';
//import ClickAwayListener from '../internal/ClickAwayListener';
//import SnackbarBody from './SnackbarBody';


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _transitions = require('material-ui/styles/transitions');

var _transitions2 = _interopRequireDefault(_transitions);

var _SnackbarBody = require('./SnackbarBody');

var _SnackbarBody2 = _interopRequireDefault(_SnackbarBody);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function getStyles(props, context, state) {
  var _context$muiTheme = context.muiTheme,
      desktopSubheaderHeight = _context$muiTheme.baseTheme.spacing.desktopSubheaderHeight,
      zIndex = _context$muiTheme.zIndex;
  var open = state.open;


  var styles = {
    root: {
      position: 'fixed',
      left: 0,
      display: 'flex',
      right: 0,
      bottom: 0,
      zIndex: zIndex.snackbar - 3, //# offset this by 3
      visibility: open ? 'visible' : 'hidden',
      transform: open ? 'translate(0, 0)' : 'translate(0, ' + desktopSubheaderHeight + 'px)',
      transition: _transitions2.default.easeOut('400ms', 'transform') + ', ' + _transitions2.default.easeOut('400ms', 'visibility')
    }
  };

  return styles;
}

var Snackbar = (_temp = _class = function (_Component) {
  _inherits(Snackbar, _Component);

  function Snackbar() {
    _classCallCheck(this, Snackbar);

    return _possibleConstructorReturn(this, (Snackbar.__proto__ || Object.getPrototypeOf(Snackbar)).apply(this, arguments));
  }

  _createClass(Snackbar, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      this.setState({
        open: this.props.open,
        message: this.props.message,
        action: this.props.action,
        actions: this.props.actions
      });
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      if (this.state.open) {
        this.setAutoHideTimer();
        this.setTransitionTimer();
      }
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      var _this2 = this;

      if (this.props.animateOnChange && this.props.open && nextProps.open && (nextProps.message !== this.props.message || nextProps.action !== this.props.action)) {
        this.setState({
          open: false
        });

        clearTimeout(this.timerOneAtTheTimeId);
        this.timerOneAtTheTimeId = setTimeout(function () {
          _this2.setState({
            message: nextProps.message,
            action: nextProps.action,
            actions: nextProps.actions,
            open: true
          });
        }, 400);
      } else {
        var open = nextProps.open;

        this.setState({
          open: open !== null ? open : this.state.open,
          message: nextProps.message,
          action: nextProps.action,
          actions: nextProps.actions
        });
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps, prevState) {
      if (prevState.open !== this.state.open) {
        if (this.state.open) {
          this.setAutoHideTimer();
          this.setTransitionTimer();
        } else {
          clearTimeout(this.timerAutoHideId);
        }
      }
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      clearTimeout(this.timerAutoHideId);
      clearTimeout(this.timerTransitionId);
      clearTimeout(this.timerOneAtTheTimeId);
    }

    // Timer that controls delay before snackbar auto hides

  }, {
    key: 'setAutoHideTimer',
    value: function setAutoHideTimer() {
      var _this3 = this;

      var autoHideDuration = this.props.autoHideDuration;

      if (autoHideDuration > 0) {
        clearTimeout(this.timerAutoHideId);
        this.timerAutoHideId = setTimeout(function () {
          if (_this3.props.open !== null && _this3.props.onRequestClose) {
            _this3.props.onRequestClose('timeout');
          } else {
            _this3.setState({ open: false });
          }
        }, autoHideDuration);
      }
    }

    // Timer that controls delay before click-away events are captured (based on when animation completes)

  }, {
    key: 'setTransitionTimer',
    value: function setTransitionTimer() {
      var _this4 = this;

      this.timerTransitionId = setTimeout(function () {
        _this4.timerTransitionId = undefined;
      }, 400);
    }
  }, {
    key: 'render',
    value: function render() {
      var _React$createElement, _extends2;

      var _props = this.props,
          autoHideDuration = _props.autoHideDuration,
          messageProp = _props.message,
          onRequestClose = _props.onRequestClose,
          animateOnChange = _props.animateOnChange,
          propAction = _props.action,
          propActions = _props.actions,
          onActionTouchTap = _props.onActionTouchTap,
          style = _props.style,
          bodyStyle = _props.bodyStyle,
          actionStyle = _props.actionStyle,
          other = _objectWithoutProperties(_props, ['autoHideDuration', 'message', 'onRequestClose', 'animateOnChange', 'action', 'actions', 'onActionTouchTap', 'style', 'bodyStyle', 'actionStyle']);

      var _state = this.state,
          action = _state.action,
          actions = _state.actions,
          message = _state.message,
          open = _state.open;
      var prepareStyles = this.context.muiTheme.prepareStyles;

      var styles = getStyles(this.props, this.context, this.state);

      //# NOTE: not using material-ui/internal/ClickAwayListener
      return _react2.default.createElement(
        'div',
        _extends({}, other, (_extends2 = { style: prepareStyles(_extends(styles.root, style)), 'data-codecept-selector-node': 'div',
          'data-codecept-selector-file': 'Snackbar'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Snackbar'), _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Snackbar'), _extends2)),
        _react2.default.createElement(_SnackbarBody2.default, (_React$createElement = {
          open: open,
          message: message,
          action: action,
          actions: actions,
          style: bodyStyle,
          actionStyle: actionStyle,
          onActionTouchTap: onActionTouchTap,
          'data-codecept-selector-node': 'SnackbarBody',
          'data-codecept-selector-file': 'Snackbar'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'SnackbarBody'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Snackbar'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'SnackbarBody'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Snackbar'), _React$createElement))
      );
    }
  }]);

  return Snackbar;
}(_react.Component), _class.propTypes = {
  /**
   * The label for the action on the snackbar.
   */
  action: _react.PropTypes.node,
  /**
   * Specs for multiple actions.
   */
  actions: _SnackbarBody.SnackbarBody.propTypes.actions,
  /**
   * The number of milliseconds to wait before automatically dismissing.
   * If no value is specified the snackbar will dismiss normally.
   * If a value is provided the snackbar can still be dismissed normally.
   * If a snackbar is dismissed before the timer expires, the timer will be cleared.
   */
  autoHideDuration: _react.PropTypes.number,
  /**
   * Override the inline-styles of the body element.
   */
  bodyStyle: _react.PropTypes.object,
  /**
   * Override the inline-styles of the action elements.
   */
  actionStyle: _react.PropTypes.object,
  /**
   * The css class name of the root element.
   */
  className: _react.PropTypes.string,
  /**
   * The message to be displayed.
   *
   * (Note: If the message is an element or array, and the `Snackbar` may re-render while it is still open,
   * ensure that the same object remains as the `message` property if you want to avoid the `Snackbar` hiding and
   * showing again)
   */
  message: _react.PropTypes.node.isRequired,
  /**
   * Fired when the action button is touchtapped.
   *
   * @param {object} event Action button event.
   */
  onActionTouchTap: _react.PropTypes.func,
  /**
   * Fired when the `Snackbar` is requested to be closed by a click outside the `Snackbar`, or after the
   * `autoHideDuration` timer expires.
   *
   * Typically `onRequestClose` is used to set state in the parent component, which is used to control the `Snackbar`
   * `open` prop.
   *
   * The `reason` parameter can optionally be used to control the response to `onRequestClose`,
   * for example ignoring `clickaway`.
   *
   * @param {string} reason Can be:`"timeout"` (`autoHideDuration` expired) or: `"clickaway"`
   */
  onRequestClose: _react.PropTypes.func,
  /**
   * Controls whether the `Snackbar` is opened or not.
   */
  open: _react.PropTypes.bool.isRequired,
  /**
   * Override the inline-styles of the root element.
   */
  style: _react.PropTypes.object,
  /**
   * Don't close and re-open notification when props change
   */
  animateOnChange: _react.PropTypes.bool
}, _class.contextTypes = {
  muiTheme: _react.PropTypes.object.isRequired
}, _temp);


Snackbar.defaultProps = {
  animateOnChange: true
};

exports.default = Snackbar;