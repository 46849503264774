'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _DropdownConnector = require('@rubyapps/ruby-component-field-namespace-selector/src/client/reactComponents/DropdownConnector');

var _DropdownConnector2 = _interopRequireDefault(_DropdownConnector);

var _Dropdown_style = require('./Dropdown_style');

var _Dropdown_style2 = _interopRequireDefault(_Dropdown_style);

var _LanguageIndicator = require('./reactComponents/LanguageIndicator');

var _LanguageIndicator2 = _interopRequireDefault(_LanguageIndicator);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var UAParser = require('ua-parser-js');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');

var RCManipulatorNamespace = RubyComponent.createClass({
    propTypes: {
        //, label: PropTypes.string
        //, key: PropTypes.string
        //, constraints: PropTypes.object
        referencesKey: PropTypes.string
    },
    getDefaultProps: function getDefaultProps() {
        return {
            listerConfig: {
                excludeFromColumnSelection: true,
                excludeFromFilterSelection: true
            },
            mode: 'control'
        };
    },
    componentName: componentName,
    getInitialState: function getInitialState() {
        return fieldValidationMixin.getInitialState.apply(this);
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getSelfStateSelector();

        var referencesKey = this.props.referencesKey;

        var referencesComponent = root.findDescendentBy(function (element) {
            return element.props.key == referencesKey;
        });
        var referencesSelector = referencesComponent.getSelfStateSelector();

        return {
            selfSelector: selfSelector,
            referencesComponent: referencesComponent,
            referencesSelector: referencesSelector
        };
    },
    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            referencesComponent = _getDependencies.referencesComponent;

        return referencesComponent.statesSelector(state);
    },

    getReactClass: function getReactClass() {
        var _getDependencies2 = this.getDependencies(),
            referencesComponent = _getDependencies2.referencesComponent;

        return _DropdownConnector2.default.apply(referencesComponent);
    },
    getReactElement: function getReactElement() {
        var _React$createElement, _extends2;

        var NamespaceComponent = this.getReactClass();

        //# if device is ie11, tweak position
        var browserInfo = new UAParser().getBrowser();

        var additionalStyles = {};

        if (browserInfo.name == 'IE' && browserInfo.major == '11') {
            additionalStyles.icon = {
                top: '-3px'
            };
        }
        return React.createElement(NamespaceComponent, _extends({}, this.props, (_extends2 = {
            hidden: false,
            styles: _Dropdown_style2.default.Dropdown,
            leftIcon: React.createElement(_LanguageIndicator2.default, (_React$createElement = { styles: additionalStyles, 'data-codecept-selector-node': 'LanguageIndicator',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'LanguageIndicator'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'LanguageIndicator'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement)),
            'data-codecept-selector-node': 'NamespaceComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'NamespaceComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'NamespaceComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

});

module.exports = RCManipulatorNamespace;