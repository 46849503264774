'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp2;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Clock = require('./Clock');

var _Clock2 = _interopRequireDefault(_Clock);

var _Dialog = require('material-ui/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _Popover = require('material-ui/Popover');

var _Popover2 = _interopRequireDefault(_Popover);

var _PopoverAnimationVertical = require('material-ui/Popover/PopoverAnimationVertical');

var _PopoverAnimationVertical2 = _interopRequireDefault(_PopoverAnimationVertical);

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TimePickerDialog = (_temp2 = _class = function (_Component) {
  _inherits(TimePickerDialog, _Component);

  function TimePickerDialog() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, TimePickerDialog);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = TimePickerDialog.__proto__ || Object.getPrototypeOf(TimePickerDialog)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      open: false
    }, _this.handleRequestClose = function () {
      _this.dismiss();
    }, _this.handleTouchTapCancel = function () {
      _this.dismiss();
    }, _this.handleTouchTapOK = function () {
      _this.dismiss();
      if (_this.props.onAccept) {
        _this.props.onAccept({
          time: _this.refs.clock.getSelectedTime(),
          zoneName: _this.refs.clock.getSelectedZoneName()
        });
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(TimePickerDialog, [{
    key: 'show',
    value: function show() {
      if (this.props.onShow && !this.state.open) this.props.onShow();
      this.setState({
        open: true
      });
    }
  }, {
    key: 'dismiss',
    value: function dismiss() {
      if (this.props.onDismiss && this.state.open) this.props.onDismiss();
      this.setState({
        open: false
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _extends2;

      var _props = this.props,
          animation = _props.animation,
          bodyStyle = _props.bodyStyle,
          initialZoneName = _props.initialZoneName,
          initialTime = _props.initialTime,
          referenceDate = _props.referenceDate,
          onAccept = _props.onAccept,
          format = _props.format,
          autoOk = _props.autoOk,
          okLabel = _props.okLabel,
          cancelLabel = _props.cancelLabel,
          container = _props.container,
          style = _props.style,
          customTimeZoneList = _props.customTimeZoneList,
          onShow = _props.onShow,
          onDismiss = _props.onDismiss,
          other = _objectWithoutProperties(_props, ['animation', 'bodyStyle', 'initialZoneName', 'initialTime', 'referenceDate', 'onAccept', 'format', 'autoOk', 'okLabel', 'cancelLabel', 'container', 'style', 'customTimeZoneList', 'onShow', 'onDismiss']);

      var styles = {
        root: {
          fontSize: 14,
          color: this.context.muiTheme.timePicker.clockColor
        },
        dialogContent: {},
        body: {
          padding: 0,
          minWidth: 280
        }
      };

      var actionStyles = {
        root: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          margin: 0,
          maxHeight: 48,
          padding: 0
        },
        flatButtons: {
          fontsize: 14,
          margin: '4px 8px 8px 0px',
          maxHeight: 36,
          minWidth: 64,
          padding: 0
        }
      };

      var actions = [_react2.default.createElement(_FlatButton2.default, (_React$createElement = {
        key: 0,
        label: cancelLabel,
        primary: true,
        onTouchTap: this.handleTouchTapCancel,
        style: actionStyles.flatButtons,
        'data-codecept-selector-node': 'FlatButton',
        'data-codecept-selector-file': 'TimePickerDialog'
      }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TimePickerDialog'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TimePickerDialog'), _React$createElement)), _react2.default.createElement(_FlatButton2.default, (_React$createElement2 = {
        key: 1,
        label: okLabel,
        primary: true,
        onTouchTap: this.handleTouchTapOK,
        style: actionStyles.flatButtons,
        'data-codecept-selector-node': 'FlatButton',
        'data-codecept-selector-file': 'TimePickerDialog'
      }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TimePickerDialog'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TimePickerDialog'), _React$createElement2))];

      var onClockChangeMinutes = autoOk === true ? this.handleTouchTapOK : undefined;
      var open = this.state.open;

      var Container = container === 'inline' ? _Popover2.default : _Dialog2.default;

      return _react2.default.createElement(
        'div',
        _extends({}, other, (_extends2 = { ref: 'root', 'data-codecept-selector-node': 'div',
          'data-codecept-selector-file': 'TimePickerDialog'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'TimePickerDialog'), _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'TimePickerDialog'), _extends2)),
        _react2.default.createElement(
          Container,
          (_React$createElement5 = {
            anchorEl: this.refs.root // For Popover
            , animation: animation || _PopoverAnimationVertical2.default // For Popover
            , style: _extends(styles.root, style, styles.body, styles.dialogContent),
            bodyStyle: _extends(styles.body, bodyStyle),
            contentStyle: styles.dialogContent,
            repositionOnUpdate: false,
            open: open,
            onRequestClose: this.handleRequestClose,
            'data-codecept-selector-node': 'Container',
            'data-codecept-selector-file': 'TimePickerDialog'
          }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Container'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'TimePickerDialog'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Container'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'TimePickerDialog'), _React$createElement5),
          open && _react2.default.createElement(_Clock2.default, (_React$createElement3 = {
            ref: 'clock',
            format: format,
            initialTime: initialTime,
            initialZoneName: initialZoneName,
            referenceDate: referenceDate,
            onChangeMinutes: onClockChangeMinutes,
            customTimeZoneList: customTimeZoneList,
            'data-codecept-selector-node': 'Clock',
            'data-codecept-selector-file': 'TimePickerDialog'
          }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Clock'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TimePickerDialog'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Clock'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TimePickerDialog'), _React$createElement3)),
          _react2.default.createElement(
            'div',
            (_React$createElement4 = { style: actionStyles.root, 'data-codecept-selector-node': 'div',
              'data-codecept-selector-file': 'TimePickerDialog'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TimePickerDialog'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TimePickerDialog'), _React$createElement4),
            actions
          )
        )
      );
    }
  }]);

  return TimePickerDialog;
}(_react.Component), _class.propTypes = {
  animation: _react.PropTypes.func,
  autoOk: _react.PropTypes.bool,
  bodyStyle: _react.PropTypes.object,
  cancelLabel: _react.PropTypes.node,
  container: _react.PropTypes.oneOf(['dialog', 'inline']),
  format: _react.PropTypes.oneOf(['ampm', '24hr']),
  initialZoneName: _react.PropTypes.string,
  initialTime: _react.PropTypes.object,
  okLabel: _react.PropTypes.node,
  onAccept: _react.PropTypes.func,
  onDismiss: _react.PropTypes.func,
  onShow: _react.PropTypes.func,
  style: _react.PropTypes.object
}, _class.defaultProps = {
  okLabel: 'OK',
  cancelLabel: 'Cancel',
  container: 'dialog'
}, _class.contextTypes = {
  muiTheme: _react.PropTypes.object.isRequired
}, _temp2);
exports.default = TimePickerDialog;