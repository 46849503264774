'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getTimeZones = getTimeZones;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var timeZoneList = _momentTimezone2.default.tz.names();

var deprecatedOptions = ['US/Alaska', 'US/Aleutian', 'US/Arizona', 'US/Central', 'US/East-Indiana', 'US/Eastern', 'US/Hawaii', 'US/Indiana-Starke', 'US/Michigan', 'US/Mountain', 'US/Pacific', 'US/Samoa', 'US/Pacific-New', 'CET', 'CST6CDT', 'EST', 'EST5EDT', 'MST', 'MST7MDT', 'PST8PDT'];

var timeZoneAliases = {
    'Alaska': 'America/Anchorage',
    'Aleutian': 'America/Adak',
    'Arizona': 'America/Phoenix',
    'Central Time Zone (CT)': 'America/Chicago',
    'East Indiana': 'America/Indiana/Indianapolis',
    'Eastern Time Zone (ET)': 'America/New_York',
    'Hawaii': 'Pacific/Honolulu',
    'Indiana Starke': 'America/Indiana/Knox',
    'Michigan': 'America/Detroit',
    'Mountain Time Zone (MT)': 'America/Denver',
    'Pacific Time Zone (PT)': 'America/Los_Angeles'
};

function getTimeZones() {

    var allPrettyNames = timeZoneList.map(function (tzString) {
        return getPrettyNameFromTZ(tzString);
    });

    var currentDate = (0, _momentTimezone2.default)();
    var customTimeZoneList = timeZoneList.map(function (timeZone) {
        var standardTimeOffset = _momentTimezone2.default.tz(currentDate, timeZone).format("Z");

        var prettyName = getPrettyNameFromTZ(timeZone);

        var label = allPrettyNames.filter(function (name) {
            return name === prettyName;
        }).length > 1 ? getUnambiguousPrettyNameFromTZ(timeZone) : prettyName;

        return {
            offset: _momentTimezone2.default.tz.zone(timeZone).utcOffset(currentDate),
            primaryText: '(GMT' + standardTimeOffset + ') ' + label,
            value: timeZone
        };
    });

    var augmentedTimeZones = customTimeZoneList.concat(_lodash2.default.map(timeZoneAliases, function (timeZone, alias) {
        var offset = _momentTimezone2.default.tz(currentDate, timeZone).format("Z");

        return {
            offset: _momentTimezone2.default.tz.zone(timeZone).utcOffset(currentDate),
            primaryText: '(GMT' + offset + ') ' + alias,
            value: timeZone
        };
    }));

    var filteredOptions = augmentedTimeZones.filter(function (option) {
        return !_lodash2.default.includes(deprecatedOptions, option.value);
    });

    return filteredOptions;
}

function getPrettyNameFromTZ(tzString) {
    var splitTimeZone = tzString.split('/');
    return splitTimeZone.slice(1).join(' - ').replace(/_/g, ' ');
}

function getUnambiguousPrettyNameFromTZ(tzString) {
    var prettyName = getPrettyNameFromTZ(tzString);

    var groupName = tzString.split('/')[0];

    return prettyName + ' (' + groupName + ')';
}