'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var defaultState = {
    activitiesEndpoint: null,
    activitiesListEndpoint: null,
    activitiesById: {},
    activitiesIdArray: [],
    pollIntervalId: null,
    activitiesByIdToPoll: {},
    showAll: false,
    edits: null,
    newComment: ''
};

module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];

    var _getAction = this.getAction(),
        TYPES = _getAction.TYPES;

    var type = action.type,
        payload = action.payload;


    if (!state.hasOwnProperty('activitiesIdArray')) {
        state = _extends({}, defaultState, state);
    }

    switch (type) {
        case TYPES.SET_ACTIVITIES_ENDPOINT:
            return _extends({}, state, {
                activitiesEndpoint: payload.endpoint
            });
        case TYPES.SET_ACTIVITIES_LIST_ENDPOINT:
            return _extends({}, state, {
                activitiesListEndpoint: payload.endpoint
            });
        case TYPES.SET_ACTIVITY_LIST:
            var activitiesIdArray_0 = [];
            var activitiesById_0 = payload.activities.reduce(function (collector, value, id) {
                collector[value.id] = value;
                activitiesIdArray_0[id] = value.id;
                return collector;
            }, {});

            return _extends({}, state, {
                //activities: payload.activities
                activitiesIdArray: activitiesIdArray_0,
                activitiesById: activitiesById_0
            });
        case TYPES.SET_ACTIVITIES_BY_ID_TO_POLL:
            return _extends({}, state, {
                activitiesByIdToPoll: payload.activitiesByIdToPoll
            });
        case TYPES.CLEAR_ACTIVITY_ID_FROM_POLL:
            return _extends({}, state, {
                activitiesByIdToPoll: _lodash2.default.omit(state.activitiesByIdToPoll, [payload.activityId])
            });
        case TYPES.SET_POLL_INTERVAL_ID:
            return _extends({}, state, {
                pollIntervalId: payload.pollIntervalId
            });
        case TYPES.SET_ACTIVITY_OBJECT:
            var activitiesById_1 = _extends({}, state.activitiesById, _defineProperty({}, payload.activity.id, payload.activity));

            return _extends({}, state, {
                activitiesById: activitiesById_1
            });
        case TYPES.UPDATE_ACTIVITY_OBJECT:
            var activityId = payload.activity.id;
            var currentData = state.activitiesById[activityId];
            var updatedData = payload.activity;
            var mergedData = _lodash2.default.merge({}, currentData, updatedData);

            var updatedActivities = _extends({}, state.activitiesById, _defineProperty({}, payload.activity.id, mergedData));

            return _extends({}, state, {
                activitiesById: updatedActivities
            });
        case TYPES.TOGGLE_SHOW_ALL:
            return _extends({}, state, {
                showAll: !state.showAll
            });
        case TYPES.EDIT_NEW_COMMENT:
            return _extends({}, state, {
                newComment: payload.comment
            });
        case TYPES.EDIT_ACTIVITY_COMMENT:
            return _extends({}, state, {
                edits: {
                    activityId: payload.activityId,
                    comment: payload.comment
                }
            });
        case TYPES.CLEAR_EDITS:
            return _extends({}, state, {
                edits: null
            });
        case TYPES.RESET_SHOW_ALL:
            return _extends({}, state, {
                showAll: false
            });
        case TYPES.RESET_STORE:
            return _extends({}, defaultState);
        default:
            return state;
    }
};