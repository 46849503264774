'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var defaultState = {
    loading: false
};

module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    var actionTypes = this.getAction().TYPES;

    if (!state.hasOwnProperty('loading')) {
        state = _extends({}, state, defaultState);
    }

    switch (action.type) {
        case actionTypes.SET_LOADING:
            return _extends({}, state, {
                loading: action.payload
            });

        default:
            return state;
    }
};