'use strict';

var jsonSelect = require('JSONSelect');

var UserSamlMixin = {
    mixinName: 'rubyComponentMixinUserSaml',
    augmentedTemplate: function augmentedTemplate(template, callingModule) {
        var key = template.key;


        if (key !== 'user') {
            return template;
        }

        var fieldset = {
            componentName: 'Fieldset',
            key: 'saml',
            label: 'Single Sign-On (SSO) Settings',
            children: [{
                componentName: 'Toggle',
                key: 'disable_basic_login',
                label: 'Prohibit local authentication via RubyApps'
            }]
        };

        var hasFieldsetSelector = ':has(:root > .componentName:val("Fieldset")):has(:root > .key:val("' + fieldset.key + '"))';
        if (jsonSelect.match(hasFieldsetSelector, template).length > 0) {
            return template;
        }

        jsonSelect.forEach(':has(:root > .componentName:val("Tab")):has(:root > .label:val("User Details"))', template, function (tab) {
            tab.children.push(fieldset);
        });
        return template;
    }
};

module.exports = UserSamlMixin;