'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _reactRedux = require('react-redux');

var _GooglePreview = require('./GooglePreview');

var _GooglePreview2 = _interopRequireDefault(_GooglePreview);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector,
        nameSelector = _selfModule$getDepend.nameSelector,
        languageNamespaceSelectorComponent = _selfModule$getDepend.languageNamespaceSelectorComponent,
        nameRelativeToLanguageSelector = _selfModule$getDepend.nameRelativeToLanguageSelector,
        browserPageTitleRelativeToLanguageSelector = _selfModule$getDepend.browserPageTitleRelativeToLanguageSelector;

    var languageNamespaceSelectorSelector = languageNamespaceSelectorComponent ? languageNamespaceSelectorComponent.getDefaultSelector() : function (state) {
        return state;
    };
    var languageNamespaceKey = languageNamespaceSelectorComponent ? languageNamespaceSelectorComponent.key() : undefined;

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);
        var languageNamespaceState = languageNamespaceSelectorSelector(state);

        var nameState = nameSelector(state);

        var previewUrl = selfModule.getPreviewUrl();

        var formValue = selfModule.formValueFromLocalState(selfState);
        var pageName = _lodash2.default.get(nameState, 'fields.name.value', null);
        var browserPageTitle = formValue.browser_page_title;

        var pageName_forDefaultLang = void 0;
        var browserPageTitle_forDefaultLang = void 0;
        if (languageNamespaceSelectorComponent) {
            var _languageNamespaceState = languageNamespaceSelectorComponent.getStateForNamespace('en');
            var nameState_forDefaultLang = nameRelativeToLanguageSelector(_languageNamespaceState);
            var browserPageTitleState_forDefaultLang = browserPageTitleRelativeToLanguageSelector(_languageNamespaceState);
            pageName_forDefaultLang = _lodash2.default.get(nameState_forDefaultLang, 'fields.name.value', null);
            browserPageTitle_forDefaultLang = _lodash2.default.get(browserPageTitleState_forDefaultLang, 'fields.browser_page_title.value');
        }

        var mappedProps = _extends({}, ownProps, {
            id: selfModule.getID(),
            value: formValue,
            pageName: pageName || pageName_forDefaultLang,
            browserPageTitle: browserPageTitle || browserPageTitle_forDefaultLang,
            previewUrl: previewUrl
        }, selfState.props, selfState.cachedValues);

        return mappedProps;
    }

    return (0, _reactRedux.connect)(mapStateToProps)(_GooglePreview2.default);
}