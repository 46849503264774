"use strict";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function typesWithID(id) {
    return {
        SET_SELECTED_INDEX: "@@ruby-app/" + id + "/SET_SELECTED_INDEX"
    };
}

var generators = {
    setSelectedIndex: function setSelectedIndex(selectedIndex) {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES,
            actions = _getAction.generators;

        var routeParent = this.findAncestorBy(function (el) {
            return el.getRouteElement;
        });

        var selectedTab = this.getChildren()[selectedIndex];
        return function (dispatch) {
            dispatch({
                type: TYPES.SET_SELECTED_INDEX,
                payload: {
                    selectedIndex: selectedIndex
                }
            });

            var hashRef = selectedTab.props.hashRef;


            if (hashRef) {
                var _routeParent$getState = routeParent.getState().routeParams,
                    _routeParent$getState2 = _routeParent$getState.hash,
                    hash = _routeParent$getState2 === undefined ? {} : _routeParent$getState2,
                    query = _routeParent$getState.query;

                //# call on navigateToPath, allow that action to format the url properly

                dispatch(actions.navigateToPathWithOptions({
                    path: window.location.pathname,
                    hash: _extends({}, hash, { tab: hashRef
                    })
                }));
            }

            dispatch(selectedTab.getAction().generators.onSelected());
        };
    },
    resetStore: function resetStore() {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES;

        this.clearStatefulCache();

        return {
            type: TYPES.RESET_STORE
        };
    }
};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};