'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = NumberFormatterGenerator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Tooltip = require('material-ui/internal/Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var fieldBaseMixin = require('@rubyapps/ruby-component-mixin-field-base');

function NumberFormatterGenerator() /*, parentProps={}*/{
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var toHTML = options.toHTML;


    var hydratedToHTML = void 0;
    if (toHTML) {
        hydratedToHTML = fieldBaseMixin.hydratedToHTMLFromProps(options);
    }
    return _react2.default.createClass({
        render: function render() {
            var _this = this,
                _React$createElement,
                _React$createElement2,
                _React$createElement3;

            var data = Number(_lodash2.default.get(this, 'props.data') || NaN);

            var value = Number.isNaN(data) ? '' : data;
            var formattedValue = hydratedToHTML ? hydratedToHTML(value) : value.toLocaleString();

            return _react2.default.createElement(
                'div',
                (_React$createElement3 = {
                    style: {
                        float: 'right',
                        paddingRight: 10,
                        position: 'relative'
                    },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'NumberFormatterGenerator'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'NumberFormatterGenerator'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'NumberFormatterGenerator'), _React$createElement3),
                _react2.default.createElement(
                    'span',
                    (_React$createElement = {
                        onMouseEnter: function onMouseEnter() {
                            _this.setState({ hoveredTooltip: true });
                        },
                        onMouseLeave: function onMouseLeave() {
                            _this.setState({ hoveredTooltip: false });
                        },
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'NumberFormatterGenerator'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'NumberFormatterGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'NumberFormatterGenerator'), _React$createElement),
                    formattedValue
                ),
                _react2.default.createElement(_Tooltip2.default, (_React$createElement2 = {
                    show: this.state && this.state.hoveredTooltip,
                    label: value.toLocaleString(),
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                    style: {
                        pointerEvents: 'none'
                    },
                    'data-codecept-selector-node': 'Tooltip',
                    'data-codecept-selector-file': 'NumberFormatterGenerator'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Tooltip'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'NumberFormatterGenerator'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Tooltip'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'NumberFormatterGenerator'), _React$createElement2))
            );
        }
    });
}