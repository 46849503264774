'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Drawer = require('material-ui/Drawer');

var _Drawer2 = _interopRequireDefault(_Drawer);

var _List = require('material-ui/List');

var _Subheader = require('material-ui/Subheader');

var _Subheader2 = _interopRequireDefault(_Subheader);

var _Divider = require('material-ui/Divider');

var _Divider2 = _interopRequireDefault(_Divider);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _HamburgerIcon = require('@rubyapps/ruby-react-components/src/client/element/HamburgerIcon');

var _HamburgerIcon2 = _interopRequireDefault(_HamburgerIcon);

var _MenuDrawer = require('./MenuDrawer.cssModule');

var _MenuDrawer2 = _interopRequireDefault(_MenuDrawer);

var _MenuDrawer_style = require('./MenuDrawer_style');

var _MenuDrawer_style2 = _interopRequireDefault(_MenuDrawer_style);

var _Footer = require('./Footer');

var _Footer2 = _interopRequireDefault(_Footer);

var _Paper = require('material-ui/Paper');

var _Paper2 = _interopRequireDefault(_Paper);

var _Toolbar = require('material-ui/Toolbar');

var _rubyLogo = require('@rubyapps/ruby-logo');

var _rubyLogo2 = _interopRequireDefault(_rubyLogo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

//# icons
var RubyIcons = require('@rubyapps/ruby-icons');

//# menuDrawer header


var MenuDrawer = _react2.default.createClass({
    displayName: 'MenuDrawer',

    propTypes: {
        activeRoute: PropTypes.string,
        closeOnRouteChange: PropTypes.bool,
        delegateRequestMenuOpenChange: PropTypes.func,
        delegateRouteChange: PropTypes.func,
        delegateToggleMenuPin: PropTypes.func,
        frontendSettings: PropTypes.object,
        menuConfig: PropTypes.array,
        menuIsPinned: PropTypes.bool,
        open: PropTypes.bool,
        width: PropTypes.number
    },
    contextTypes: {
        updateTheme: PropTypes.func
    },

    getDefaultProps: function getDefaultProps() {
        return {
            menuConfig: [],
            closeOnRouteChange: true,
            menuIsPinned: false,
            width: 300
        };
    },
    _onCloseDrawerRequest: function _onCloseDrawerRequest() {
        this.props.delegateRequestMenuOpenChange && this.props.delegateRequestMenuOpenChange(false);
    },
    _onDrawerRequestChange: function _onDrawerRequestChange(open) {
        this.props.delegateRequestMenuOpenChange && this.props.delegateRequestMenuOpenChange(open);
    },
    _onMenuItemTap: function _onMenuItemTap(route, menuItemConfig) {
        if (this.props.closeOnRouteChange && !this.props.menuIsPinned) {
            this._onDrawerRequestChange(false);
        }
        this.props.delegateRouteChange && this.props.delegateRouteChange(route, menuItemConfig);
    },
    _onAnchorClick: function _onAnchorClick(event) {
        event.preventDefault();
        return false;
    },
    _onToggleMenuPin: function _onToggleMenuPin(event) {
        this.props.delegateToggleMenuPin();
    },
    _renderMenuItem: function _renderMenuItem(menuItemConfig, depth, index, additionalProps) {
        var _this = this,
            _extends2,
            _extends3;

        var childrenConfigs = menuItemConfig.children;

        var childrenDepth = depth + 1;
        var nestedItems = childrenConfigs && childrenConfigs.length ? childrenConfigs.map(function (menuItemConfig, index) {
            return _this._renderMenuItem(menuItemConfig, childrenDepth, index);
        }) : undefined;

        var childRoute = menuItemConfig.route || menuItemConfig.url;

        var IconClass = menuItemConfig.icon ? RubyIcons.getIconClassForPath(menuItemConfig.icon) : undefined;
        var leftIcon = IconClass ? _react2.default.createElement(IconClass, _extends({}, _MenuDrawer_style2.default.Icon, (_extends2 = {
            'data-codecept-selector-node': 'IconClass',
            'data-codecept-selector-file': 'MenuDrawer'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'IconClass'), _defineProperty(_extends2, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_extends2, 'data-codecept-selector-node', 'IconClass'), _defineProperty(_extends2, 'data-codecept-selector-file', 'MenuDrawer'), _extends2))) : null;

        var anchorProps = {
            key: depth + '.' + index,
            className: _MenuDrawer2.default.anchor,
            href: childRoute,
            onClick: this._onAnchorClick
        };
        var listItemInstance = _react2.default.createElement(_List.ListItem, _extends({}, _MenuDrawer_style2.default.ListItem, {
            key: depth + '.' + index,
            className: childRoute == this.props.activeRoute ? _MenuDrawer2.default.activeLink : null,
            primaryText: menuItemConfig.name
        }, additionalProps ? additionalProps : {}, (_extends3 = {
            nestedItems: nestedItems ? nestedItems : undefined,
            primaryTogglesNestedList: childRoute ? false : true,
            onTouchTap: function onTouchTap() {
                _this._onMenuItemTap(childRoute, menuItemConfig);
            },
            leftIcon: leftIcon,
            'data-codecept-selector-node': 'ListItem',
            'data-codecept-selector-file': 'MenuDrawer'
        }, _defineProperty(_extends3, 'data-codecept-selector-node', 'ListItem'), _defineProperty(_extends3, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_extends3, 'data-codecept-selector-node', 'ListItem'), _defineProperty(_extends3, 'data-codecept-selector-file', 'MenuDrawer'), _extends3)));
        if (childRoute) {
            var _extends4;

            return _react2.default.createElement(
                'a',
                _extends({}, anchorProps, (_extends4 = {
                    'data-codecept-selector-node': 'a',
                    'data-codecept-selector-file': 'MenuDrawer'
                }, _defineProperty(_extends4, 'data-codecept-selector-node', 'a'), _defineProperty(_extends4, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_extends4, 'data-codecept-selector-node', 'a'), _defineProperty(_extends4, 'data-codecept-selector-file', 'MenuDrawer'), _extends4)),
                listItemInstance
            );
        } else {
            return listItemInstance;
        }
    },

    _renderRootMenuItem: function _renderRootMenuItem(menuItemConfig, depth, index) {
        var _this2 = this,
            _React$createElement,
            _React$createElement2;

        var rootLevelMenuItemProps = {
            initiallyOpen: true
        };

        var childrenConfigs = menuItemConfig.children;
        var childrenDepth = depth + 1;
        var nestedItems = childrenConfigs && childrenConfigs.length ? childrenConfigs.map(function (menuItemConfig, index) {
            return _this2._renderMenuItem(menuItemConfig, childrenDepth, index, rootLevelMenuItemProps);
        }) : undefined;

        return _react2.default.createElement(
            _List.List,
            (_React$createElement2 = {
                key: 'List:' + depth + '.' + index,
                'data-codecept-selector-node': 'List',
                'data-codecept-selector-file': 'MenuDrawer'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'List'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'List'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'MenuDrawer'), _React$createElement2),
            _react2.default.createElement(
                _Subheader2.default,
                (_React$createElement = { className: _MenuDrawer2.default.Subheader, 'data-codecept-selector-node': 'Subheader',
                    'data-codecept-selector-file': 'MenuDrawer'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Subheader'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Subheader'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'MenuDrawer'), _React$createElement),
                menuItemConfig.name
            ),
            nestedItems
        );
    },
    _renderThemeSelector: function _renderThemeSelector() {
        var _React$createElement3,
            _this3 = this,
            _React$createElement4,
            _React$createElement5,
            _React$createElement6,
            _React$createElement7;

        return [_react2.default.createElement(
            _List.List,
            (_React$createElement6 = {
                key: 'List:theme',
                'data-codecept-selector-node': 'List',
                'data-codecept-selector-file': 'MenuDrawer'
            }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'List'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'List'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'MenuDrawer'), _React$createElement6),
            _react2.default.createElement(
                _Subheader2.default,
                (_React$createElement3 = { className: _MenuDrawer2.default.Subheader, 'data-codecept-selector-node': 'Subheader',
                    'data-codecept-selector-file': 'MenuDrawer'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Subheader'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Subheader'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'MenuDrawer'), _React$createElement3),
                'Themes'
            ),
            _react2.default.createElement(_List.ListItem, (_React$createElement4 = {
                key: 'defaultTheme',
                primaryText: 'Default',
                onTouchTap: function onTouchTap() {
                    return _this3.context.updateTheme('default');
                },
                'data-codecept-selector-node': 'ListItem',
                'data-codecept-selector-file': 'MenuDrawer'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ListItem'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ListItem'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'MenuDrawer'), _React$createElement4)),
            _react2.default.createElement(_List.ListItem, (_React$createElement5 = {
                key: 'brightTheme',
                primaryText: 'Ruby Blue',
                onTouchTap: function onTouchTap() {
                    return _this3.context.updateTheme('bright');
                },
                'data-codecept-selector-node': 'ListItem',
                'data-codecept-selector-file': 'MenuDrawer'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'ListItem'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'ListItem'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'MenuDrawer'), _React$createElement5))
        ), _react2.default.createElement(_Divider2.default, (_React$createElement7 = {
            key: 'Divider:theme',
            'data-codecept-selector-node': 'Divider',
            'data-codecept-selector-file': 'MenuDrawer'
        }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'MenuDrawer'), _React$createElement7))];
    },
    render: function render() {
        var _this4 = this,
            _React$createElement9,
            _extends5,
            _React$createElement10,
            _extends6,
            _extends7,
            _extends8,
            _extends9,
            _React$createElement11,
            _React$createElement12,
            _extends10;

        var menuDrawerProps = {
            open: this.props.open,
            onRequestChange: this._onDrawerRequestChange
        };

        var rootLevelMenuItemProps = {
            initiallyOpen: true
        };

        var menuConfigLastIndex = this.props.menuConfig.length - 1;
        var Lists = this.props.menuConfig.reduce(function (collector, menuItemConfig, index) {
            collector.push(_this4._renderRootMenuItem(menuItemConfig, 0, index));

            if (1 || index != menuConfigLastIndex) {
                var _React$createElement8;

                collector.push(_react2.default.createElement(_Divider2.default, (_React$createElement8 = {
                    key: 'Divider:' + index,
                    'data-codecept-selector-node': 'Divider',
                    'data-codecept-selector-file': 'MenuDrawer'
                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'MenuDrawer'), _React$createElement8)));
            }

            return collector;
        }, []);

        return _react2.default.createElement(
            _Drawer2.default,
            _extends({}, _MenuDrawer_style2.default.Drawer, {
                width: this.props.width,
                docked: this.props.menuIsPinned
            }, menuDrawerProps, (_extends10 = {
                'data-codecept-selector-node': 'Drawer',
                'data-codecept-selector-file': 'MenuDrawer'
            }, _defineProperty(_extends10, 'data-codecept-selector-node', 'Drawer'), _defineProperty(_extends10, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_extends10, 'data-codecept-selector-node', 'Drawer'), _defineProperty(_extends10, 'data-codecept-selector-file', 'MenuDrawer'), _extends10)),
            _react2.default.createElement(
                _Paper2.default,
                _extends({}, _MenuDrawer_style2.default.Paper, (_extends9 = {
                    'data-codecept-selector-node': 'Paper',
                    'data-codecept-selector-file': 'MenuDrawer'
                }, _defineProperty(_extends9, 'data-codecept-selector-node', 'Paper'), _defineProperty(_extends9, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_extends9, 'data-codecept-selector-node', 'Paper'), _defineProperty(_extends9, 'data-codecept-selector-file', 'MenuDrawer'), _extends9)),
                _react2.default.createElement(
                    _Toolbar.Toolbar,
                    _extends({}, _MenuDrawer_style2.default.Toolbar, (_extends8 = {
                        title: 'Ruby',
                        noGutter: true,
                        'data-codecept-selector-node': 'Toolbar',
                        'data-codecept-selector-file': 'MenuDrawer'
                    }, _defineProperty(_extends8, 'data-codecept-selector-node', 'Toolbar'), _defineProperty(_extends8, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_extends8, 'data-codecept-selector-node', 'Toolbar'), _defineProperty(_extends8, 'data-codecept-selector-file', 'MenuDrawer'), _extends8)),
                    _react2.default.createElement(
                        _Toolbar.ToolbarGroup,
                        _extends({}, _MenuDrawer_style2.default.ToolbarGroup, (_extends7 = {
                            'data-codecept-selector-node': 'ToolbarGroup',
                            'data-codecept-selector-file': 'MenuDrawer'
                        }, _defineProperty(_extends7, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_extends7, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_extends7, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_extends7, 'data-codecept-selector-file', 'MenuDrawer'), _extends7)),
                        _react2.default.createElement(
                            _IconButton2.default,
                            _extends({
                                onTouchTap: this._onCloseDrawerRequest
                            }, _MenuDrawer_style2.default.MenuButton, (_extends5 = {
                                'data-codecept-selector-node': 'IconButton',
                                'data-codecept-selector-file': 'MenuDrawer'
                            }, _defineProperty(_extends5, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_extends5, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_extends5, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_extends5, 'data-codecept-selector-file', 'MenuDrawer'), _extends5)),
                            _react2.default.createElement(_HamburgerIcon2.default, (_React$createElement9 = {
                                isActive: true,
                                'data-codecept-selector-node': 'HamburgerIcon',
                                'data-codecept-selector-file': 'MenuDrawer'
                            }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'HamburgerIcon'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'HamburgerIcon'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'MenuDrawer'), _React$createElement9))
                        ),
                        _react2.default.createElement(_rubyLogo2.default, (_React$createElement10 = {
                            frontendSettings: this.props.frontendSettings,
                            'data-codecept-selector-node': 'RubyLogo',
                            'data-codecept-selector-file': 'MenuDrawer'
                        }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'RubyLogo'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'RubyLogo'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'MenuDrawer'), _React$createElement10)),
                        _react2.default.createElement(_IconButton2.default, _extends({}, this.props.menuIsPinned ? _MenuDrawer_style2.default.IconButton__thumbtack__active : _MenuDrawer_style2.default.IconButton__thumbtack__inactive, (_extends6 = {
                            iconClassName: 'fa fa-thumb-tack fa-fw',
                            onClick: this._onToggleMenuPin,
                            'data-codecept-selector-node': 'IconButton',
                            'data-codecept-selector-file': 'MenuDrawer'
                        }, _defineProperty(_extends6, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_extends6, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_extends6, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_extends6, 'data-codecept-selector-file', 'MenuDrawer'), _extends6)))
                    )
                )
            ),
            _react2.default.createElement(
                'div',
                (_React$createElement12 = { className: _MenuDrawer2.default.List, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'MenuDrawer'
                }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'MenuDrawer'), _React$createElement12),
                Lists,
                _react2.default.createElement(_Footer2.default, (_React$createElement11 = {
                    cmsVersionString: this.props.frontendSettings.version,
                    projectVersionString: this.props.frontendSettings.DEPLOY_VERSION,
                    'data-codecept-selector-node': 'Footer',
                    'data-codecept-selector-file': 'MenuDrawer'
                }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'Footer'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'MenuDrawer'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'Footer'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'MenuDrawer'), _React$createElement11))
            )
        );
    }
});

exports.default = MenuDrawer;