'use strict';

//clean these up

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.BEFORE_REDUCE = BEFORE_REDUCE;
exports.AFTER_REDUCE = AFTER_REDUCE;
exports.GRIDDLE_INITIALIZED = GRIDDLE_INITIALIZED;
exports.GRIDDLE_LOADED_DATA = GRIDDLE_LOADED_DATA;
exports.GRIDDLE_TOGGLE_COLUMN = GRIDDLE_TOGGLE_COLUMN;
exports.GRIDDLE_SET_COLUMN_PROPERTIES = GRIDDLE_SET_COLUMN_PROPERTIES;
exports.GRIDDLE_SET_COLUMN_RESIZE_PROPERTIES = GRIDDLE_SET_COLUMN_RESIZE_PROPERTIES;
exports.GRIDDLE_SET_COLUMN_RENDERED_SIZE_PROPERTIES = GRIDDLE_SET_COLUMN_RENDERED_SIZE_PROPERTIES;
exports.GRIDDLE_SET_STATIC_DATA = GRIDDLE_SET_STATIC_DATA;
exports.GRIDDLE_PREPEND_STATIC_DATA = GRIDDLE_PREPEND_STATIC_DATA;

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//TODO: Dumb bug requires this to be here for things to work
function BEFORE_REDUCE(state, action, helpers) {
    return state;
}

function AFTER_REDUCE(state, action, helpers) {
    return state;
}

function GRIDDLE_INITIALIZED(state, action, helpers) {
    return state.set('renderProperties', _immutable2.default.fromJS(action.properties));
}

function GRIDDLE_LOADED_DATA(state, action, helpers) {
    return state.set('data', helpers.addKeyToRows(_immutable2.default.fromJS(action.data))).set('loading', false);
}

function GRIDDLE_TOGGLE_COLUMN(state, action, helpers) {
    var toggleColumn = function toggleColumn(columnId, fromProperty, toProperty) {
        if (state.get('renderProperties').get(fromProperty) && state.get('renderProperties').get(fromProperty).has(columnId)) {
            var columnValue = state.getIn(['renderProperties', fromProperty, columnId]);
            return state.setIn(['renderProperties', toProperty, columnId], columnValue).removeIn(['renderProperties', fromProperty, columnId]);
        }
    };

    //check to see if the column is in hiddenColumnProperties
    //if it is move it to columnProperties
    var hidden = toggleColumn(action.columnId, 'hiddenColumnProperties', 'columnProperties');

    //if it is not check to make sure it's in columnProperties and move to hiddenColumnProperties
    var column = toggleColumn(action.columnId, 'columnProperties', 'hiddenColumnProperties');

    //if it's neither just return state for now
    return helpers.updateVisibleData(hidden || column || state);
}
function GRIDDLE_SET_COLUMN_PROPERTIES(state, action, helpers) {
    var type = action.type,
        columnId = action.columnId,
        properties = action.properties;


    var mergeColumnProperties = function mergeColumnProperties(columnId, columnPropertiesKey, properties) {
        if (state.getIn(['renderProperties', columnPropertiesKey]).has(columnId)) {
            var columnValue = state.getIn(['renderProperties', columnPropertiesKey, columnId]);
            var newColumnProperties = _immutable2.default.fromJS(properties);
            var mergedColumnProperties = columnValue.merge(newColumnProperties);
            return state.setIn(['renderProperties', columnPropertiesKey, columnId], mergedColumnProperties);
        }
    };

    var column = mergeColumnProperties(columnId, 'columnProperties', properties);
    var hidden = mergeColumnProperties(columnId, 'hiddenColumnProperties', properties);

    return helpers.updateVisibleData(hidden || column || state);
}

function GRIDDLE_SET_COLUMN_RESIZE_PROPERTIES(state, action, helpers) {
    var type = action.type,
        propertiesByID = action.propertiesByID;

    //# blanket replacement of the resize properties for now)

    var retval = state.set('resizeProperties', _immutable2.default.fromJS(propertiesByID));
    return retval;
    //return helpers.updateVisibleData(hidden || column || state);
}
function GRIDDLE_SET_COLUMN_RENDERED_SIZE_PROPERTIES(state, action, helpers) {
    var type = action.type,
        propertiesByID = action.propertiesByID;

    //# blanket replacement of the resize properties for now)

    var retval = state.set('renderedSizeProperties', _immutable2.default.fromJS(propertiesByID));
    return retval;
    //return helpers.updateVisibleData(hidden || column || state);
}

function _setStaticDataInState_withData_andHelpers(state, staticData, helpers) {
    //# add isStatic:true to staticData
    staticData.forEach(function (dataItem) {
        dataItem.isStatic = true;
    });

    var staticDataAsImmutable = _immutable2.default.fromJS(staticData);
    var staticDataWithGriddleKey = helpers.addKeyToRows(staticDataAsImmutable, 'static-');

    var columns = helpers.getDataColumns(state, staticDataWithGriddleKey);
    var visibleStaticData = helpers.getVisibleDataColumns(staticDataWithGriddleKey, columns);

    return state.set('visibleStaticData', visibleStaticData).set('staticData', staticDataWithGriddleKey);
}

function GRIDDLE_SET_STATIC_DATA(state, action, helpers) {
    var staticData = action.staticData;


    return _setStaticDataInState_withData_andHelpers(state, staticData, helpers);
}

function GRIDDLE_PREPEND_STATIC_DATA(state, action, helpers) {
    var prependStaticData = action.staticData;

    var existingStaticData = state.get('staticData');

    var staticData = prependStaticData.concat(existingStaticData.toJS());

    return _setStaticDataInState_withData_andHelpers(state, staticData, helpers);
}