'use strict';

var RubyComponent = require('@rubyapps/ruby-component');

var currentUserRubyComponent__CONSTANTS = require('@rubyapps/ruby-component-current-user/src/common/constants');

var currentUserMixin = RubyComponent.createMixin({
    mixinName: 'rubyComponentMixinCurrentUser',
    getDefaultProps: function getDefaultProps() {
        return {
            currentUserID: currentUserRubyComponent__CONSTANTS.COMPONENT_NAME
        };
    },
    dependencies: function dependencies() {
        var currentUser = this.getRoot().findDescendentByID(this.props.currentUserID);

        return {
            currentUser: currentUser,
            currentUserSelector: currentUser.getSelfStateSelector()
        };
    },
    getCurrentUserState: function getCurrentUserState() {
        // Get the full application state
        var store = this.getStore();
        if (store == undefined) {
            return undefined;
        }

        var applicationState = store.getState();

        // Get the function that can pull out the current user state
        // from the application state

        var _getDependencies = this.getDependencies(),
            currentUserSelector = _getDependencies.currentUserSelector;

        // Return the current user state


        return currentUserSelector(applicationState);
    },
    getCurrentUser: function getCurrentUser() {
        return this.getDependencies().currentUser;
    },
    augmentedTemplate: require('./augmentedTemplate')

});

module.exports = currentUserMixin;