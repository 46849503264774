'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _routeParser = require('route-parser');

var _routeParser2 = _interopRequireDefault(_routeParser);

var _AlterTemplateDialog = require('./AlterTemplateDialog');

var _AlterTemplateDialog2 = _interopRequireDefault(_AlterTemplateDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rubyWords = require('@rubyapps/ruby-words');
var RubyComponentForms = require('@rubyapps/ruby-component-forms');


module.exports = function alterTemplateDialogGenerator() {
    var selfModule = this;
    var rootModule = this.getRoot();

    var dependencies = selfModule.getDependencies();
    var selfSelector = dependencies.selfSelector;
    var frontendSettingsSelector = dependencies.frontendSettingsSelector;
    var selfActions = selfModule.getAction().generators;
    var editPageComponent = dependencies.editPageComponent;
    var formsSelector = dependencies.formsSelector;

    //# NOTE: previewsByFormKey isn't used, consider DEPRECATING
    var previewsByFormKey = this.props.previewsByFormKey;

    var previewsPrefix = this.props.previewsPrefix;
    var previewsExtension = this.props.previewsExtension;
    function formsArrayReducer(collector, formObject, formKey) {

        var availableFields = RubyComponentForms.availableFieldsInTemplateForEditing(formObject);

        collector.push({
            value: formKey,
            primaryText: _lodash2.default.get(formObject, 'name') || rubyWords.capitalizeWords(rubyWords.inflection.transform(formKey, ['humanize'])),
            availableFields: availableFields,
            previewImage: formObject.previewImage ? formObject.previewImage.url : previewsByFormKey && previewsByFormKey[formKey] ? previewsByFormKey : previewsPrefix + formKey + previewsExtension
        });
        return collector;
    }

    //# Figuring out the editPage route
    var editPageRouteTemplate = editPageComponent ? editPageComponent.getRoutePath() : ':template/:id';
    var editPageRouteParser = new _routeParser2.default(editPageRouteTemplate);

    function mapStateToProps(state, ownProps) {
        selfModule;
        var selfState = selfSelector(state);
        var allForms = formsSelector(state);
        var activeRouteComponent = rootModule.getActiveRouteComponent();
        var activeSubsiteId = _lodash2.default.isFunction(_lodash2.default.get(activeRouteComponent, 'getActiveSubsiteId')) ? activeRouteComponent.getActiveSubsiteId() : {};

        var alterableForms = _lodash2.default.reduce(allForms, function (result, curr, key) {
            var isAlterable = getIsAlterable_fromForm_forSupportedTemplateTypes(curr, selfState.supportedTemplateTypes);
            var subsiteMatches = getSubsiteMatches_fromForm_forActiveSubsite(curr, activeSubsiteId);

            if (isAlterable && subsiteMatches) {
                result[key] = curr;
                return result;
            } else {
                return result;
            }
        }, {});
        var formsArray = _lodash2.default.reduce(alterableForms, formsArrayReducer, []);
        var frontendSettingsState = frontendSettingsSelector(state);
        var mappedProps = {
            open: selfState.open,
            confirmOpen: selfState.confirmOpen
            //, hasErrors: Object.keys(selector.getFieldsThatErroredByKey(state)).length > 0
            , fields: {
                templateKey: {
                    id: 'templateKey',
                    value: _lodash2.default.get(selfState, 'fields.templateKey.value') || (formsArray.length ? formsArray[0].value : null),
                    errorText: _lodash2.default.get(selfState, ['fields', 'templateKey', 'error', 'message'], null),
                    formsArray: formsArray.sort(function (a, b) {
                        return a.primaryText.localeCompare(b.primaryText);
                    })
                }
            }

        };

        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {

        return {
            actions: (0, _redux.bindActionCreators)(selfActions, dispatch)
        };
    }

    function mergeProps(stateProps, dispatchProps, ownProps) {
        //# need to handle delegation here because it needs the bound action functions
        var modifiedStateProps = stateProps;
        modifiedStateProps.fields.templateKey.onChange = function (value) {
            dispatchProps.actions.setFieldValueByKey(value, 'templateKey');
        };
        return _extends({}, ownProps, modifiedStateProps, dispatchProps, {
            //# setup the delegate callbacks here whcih calls on dispatchProps.actions...
            delegateAlterTemplate: function delegateAlterTemplate(evt) {
                var id = _lodash2.default.get(stateProps, 'fields.id.value');
                var templateKey = _lodash2.default.get(stateProps, 'fields.templateKey.value');

                dispatchProps.actions.closeAndAlterTemplate_thenResetAlterTemplateDialog({ id: id, templateKey: templateKey });

                return;
            },
            delegateClose: function delegateClose(evt) {
                dispatchProps.actions.closeAlterTemplateDialog();
                dispatchProps.actions.resetAlterTemplateDialog();
            },
            delegateOpenConfirmation: function delegateOpenConfirmation(evt) {
                dispatchProps.actions.openConfirmation();
            }
        });
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(_AlterTemplateDialog2.default);
};

function getSubsiteMatches_fromForm_forActiveSubsite(form, activeSubsiteId) {
    var formSubsites = form.ruby_subsite_fk ? _lodash2.default.castArray(form.ruby_subsite_fk) : [activeSubsiteId];
    return _lodash2.default.includes(formSubsites, activeSubsiteId);
}

function getIsAlterable_fromForm_forSupportedTemplateTypes(form, supportedTemplateTypes) {
    var isPageContent = supportedTemplateTypes.indexOf(form.templateType) > -1;

    return _lodash2.default.every([!_lodash2.default.get(form, 'unique_template'), isPageContent]);
}