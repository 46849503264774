'use strict';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require('react-router');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _rowContextMenuItems = require('./row-context-menu-items');

var _rowContextMenuItems2 = _interopRequireDefault(_rowContextMenuItems);

var _columnDefinitionsGenerator = require('./columnDefinitionsGenerator');

var _columnDefinitionsGenerator2 = _interopRequireDefault(_columnDefinitionsGenerator);

var _constants = require('../common/constants');

var _constants2 = _interopRequireDefault(_constants);

var _action = require('./action');

var _action2 = _interopRequireDefault(_action);

var _ContentListConnector = require('./reactComponents/ContentListConnector');

var _ContentListConnector2 = _interopRequireDefault(_ContentListConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var componentName = _constants2.default.COMPONENT_NAME;

var rubyComponent = require('@rubyapps/ruby-component');
var rubyAccessManager = require('@rubyapps/ruby-access-manager');

var EDIT_PAGE_CONSTANTS = require('../../../ruby-component-material-edit-page/src/common/constants');

//# Mixins
var accessControlMixin = require('@rubyapps/ruby-component-mixin-access-control');
var listPageMixin = require('@rubyapps/ruby-component-mixin-list-page');
var listerContentDefaultsMixin = require('@rubyapps/ruby-component-mixin-lister-content-defaults');
var listerHierarchyMixin = require('@rubyapps/ruby-component-mixin-lister-hierarchy');
var savedSearchListerMixin = require('@rubyapps/ruby-component-plugin-saved-search/src/local_modules/ruby-component-mixin-lister-saved-search');

var reducer = require('./reducer');
var middleware = require('./middleware');

var RubyComponentpluginTemplateEditor__CONSTANTS = require('@rubyapps/ruby-component-plugin-template-editor/src/common/constants');
var RubyComponentAlterTemplateDialog__CONSTANTS = require('@rubyapps/ruby-component-alter-template-dialog/src/common/constants');
var RubyComponentConfirmDialog__CONSTANTS = require('@rubyapps/ruby-component-confirm-dialog/src/common/constants');
var PLUGIN_PROPOSAL_CONSTANTS = require('../../../ruby-component-plugin-proposal/src/common/constants');
var PLUGIN_PROPOSAL_COMPONENT_NAME = _lodash2.default.get(PLUGIN_PROPOSAL_CONSTANTS, 'COMPONENT_NAME');

var RCStructuredDataListPage = rubyComponent.createClass({
    mixins: [accessControlMixin, listPageMixin, listerContentDefaultsMixin, listerHierarchyMixin, savedSearchListerMixin],
    componentName: componentName,
    getDefaultProps: function getDefaultProps() {
        var _ref;

        return _ref = {
            path: 'content/:subsite/:template/list',
            show_publish_column: true //# DEPRECATED 20210204 - deprecated in favor of using lister augmentor to inject the last_modified_timestamp column 

            , editPageID: EDIT_PAGE_CONSTANTS.COMPONENT_NAME,
            confirmDialogID: 'rubyComponentConfirmDialog',
            alterTemplateDialogID: RubyComponentAlterTemplateDialog__CONSTANTS.COMPONENT_NAME
        }, _defineProperty(_ref, 'confirmDialogID', RubyComponentConfirmDialog__CONSTANTS.COMPONENT_NAME), _defineProperty(_ref, 'addToProposalDialogID', 'rubyComponentAddToProposalDialog'), _defineProperty(_ref, 'pluginProposalID', PLUGIN_PROPOSAL_COMPONENT_NAME), _defineProperty(_ref, 'apiKey', 'content'), _defineProperty(_ref, 'showTemplateFilter', false), _defineProperty(_ref, 'listerNavLabel', 'Structured Data'), _defineProperty(_ref, 'templateType', _lodash2.default.get(RubyComponentpluginTemplateEditor__CONSTANTS, 'STRUCTURED_DATA_TEMPLATE')), _defineProperty(_ref, 'searchMode', 'advanced'), _ref;
    },
    getInitialState: function getInitialState() {
        return {
            mostRecentlyModifiedItem: {}
        };
    },
    CONSTANTS: _constants2.default,
    action: _action2.default,
    reducer: reducer,
    middleware: middleware,
    dependencies: function dependencies() {
        var _this = this;

        var selfModule = this;
        var selfSelector = selfModule.getSelfStateSelector();
        var selfActions = this.getAction().generators;
        var rootModule = selfModule.getRoot();

        var pluginProposalID = this.props.pluginProposalID;
        var pluginProposalComponent = rootModule.findDescendentByID(pluginProposalID);

        var editPageID = this.props.editPageID;
        var editPageComponent = rootModule.findDescendentByID(editPageID);

        var alterTemplateDialogID = this.props.alterTemplateDialogID;
        var alterTemplateDialogComponent = rootModule.findDescendentByID(alterTemplateDialogID);
        var confirmDialogID = this.props.confirmDialogID;
        var confirmDialogComponent = rootModule.findDescendentByID(confirmDialogID);

        var addToProposalDialogID = this.props.addToProposalDialogID;
        var addToProposalDialogComponent = rootModule.findDescendentByID(addToProposalDialogID);

        var formDialogComponent = rootModule.findDescendentByID(this.props.formDialogID);

        var getAddEntryUrl = function getAddEntryUrl() {
            return rootModule.getUrlForComponent_fromModule_withParams(editPageID, _this, { id: null, action: 'create', section: 'content' });
        };

        var getEditEntryUrl_forId = function getEditEntryUrl_forId(id) {
            return rootModule.getUrlForComponent_fromModule_withParams(editPageID, _this, { id: id, action: 'edit', section: 'content' });
        };
        var getCanDeleteEntry_withId_andTemplateKeyword = function getCanDeleteEntry_withId_andTemplateKeyword(id, templateKeyword) {
            var _getDependencies = _this.getDependencies(),
                currentUserComponent = _getDependencies.currentUserComponent,
                formsComponent = _getDependencies.formsComponent,
                rubyClientFKComponent = _getDependencies.rubyClientFKComponent;

            var subsiteId = selfModule.getActiveSubsiteId();
            var formsState = formsComponent.getState();
            var templateId = _lodash2.default.get(formsState, [templateKeyword, 'id']);
            return rubyAccessManager.doesUserHavePermission(currentUserComponent.getState(), {
                id: id,
                action: 'delete',
                model: 'content',
                template: templateId,
                subsite: subsiteId,
                ruby_client: rubyClientFKComponent.getState()
            });
            return rootModule.getUrlForComponent_fromModule_withParams(editPageID, _this, { id: id, action: 'edit', section: 'content' });
        };

        var getContentListUrl_forTemplate = function getContentListUrl_forTemplate(template) {
            return rootModule.getUrlForComponent_fromModule_withParams(_this.props.id || componentName, _this, { template: template });
        };

        return {
            pluginProposalComponent: pluginProposalComponent,
            editPageComponent: editPageComponent,
            selfActions: selfActions,
            selfSelector: selfSelector,
            addToProposalDialogComponent: addToProposalDialogComponent,
            addToProposalDialogActions: addToProposalDialogComponent ? addToProposalDialogComponent.getAction().generators : undefined,
            alterTemplateDialogComponent: alterTemplateDialogComponent,
            alterTemplateDialogActions: alterTemplateDialogComponent.getAction().generators,
            confirmDialogActions: confirmDialogComponent.getAction().generators,
            formDialogComponent: formDialogComponent,
            getAddEntryUrl: getAddEntryUrl,
            getEditEntryUrl_forId: getEditEntryUrl_forId,
            getContentListUrl_forTemplate: getContentListUrl_forTemplate,
            getCanDeleteEntry_withId_andTemplateKeyword: getCanDeleteEntry_withId_andTemplateKeyword
        };
    },
    getReactClass: function getReactClass() {
        var ContentListComponent = _ContentListConnector2.default.apply(this);
        return ContentListComponent;
    },
    getRouteElement: function getRouteElement() {
        var _React$createElement;

        var ListPage = this.getReactClass();

        return _react2.default.createElement(_reactRouter.Route, (_React$createElement = { name: 'list', component: ListPage, path: this.props.path, key: componentName, 'data-codecept-selector-node': 'Route',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Route'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Route'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement));
    },
    getOverrideMethodFromMixins_byName: function getOverrideMethodFromMixins_byName(methodName) {
        var selfModule = this;
        var passedArguments = arguments;
        var lastMixinDefiningMethod = _lodash2.default.findLast(selfModule.mixins, function (mixin) {
            return mixin.hasOwnProperty(methodName);
        });
        if (lastMixinDefiningMethod) {
            return lastMixinDefiningMethod[methodName];
        } else {
            return this[methodName];
        }
    },
    getMergedRowContextMenuItems_usingGriddleInstance_andParentProps: function getMergedRowContextMenuItems_usingGriddleInstance_andParentProps() {
        return this.getOverrideMethodFromMixins_byName('getRowContextMenuItems_usingGriddleInstance_andParentProps').apply(this, arguments);
    },
    getRowContextMenuItems_usingGriddleInstance_andParentProps: _rowContextMenuItems2.default,
    columnDefinitionsGenerator: _columnDefinitionsGenerator2.default
});

module.exports = RCStructuredDataListPage;