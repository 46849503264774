'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.RichErrorMessages = exports.Label = undefined;

var _Label = require('./Label');

var _Label2 = _interopRequireDefault(_Label);

var _RichErrorMessages = require('./RichErrorMessages');

var _RichErrorMessages2 = _interopRequireDefault(_RichErrorMessages);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.Label = _Label2.default;
exports.RichErrorMessages = _RichErrorMessages2.default;