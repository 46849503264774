'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _RefreshIndicator = require('@rubyapps/ruby-material-ui/RefreshIndicator');

var _RefreshIndicator2 = _interopRequireDefault(_RefreshIndicator);

var _LoadingIndicator = require('./LoadingIndicator.cssModule');

var _LoadingIndicator2 = _interopRequireDefault(_LoadingIndicator);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

//require('./LoadingIndicator.css');

var stylesObject = {
    refreshIndicator: {
        display: 'inline-block',
        position: 'relative'
    }
};

var LoadingIndicator = _react2.default.createClass({
    displayName: 'LoadingIndicator',

    propTypes: {
        visible: PropTypes.bool
    },
    render: function render() {
        var _React$createElement, _React$createElement2;

        var visible = this.props.visible;
        var style = _extends({}, stylesObject.refreshIndicator, visible ? {} : {
            display: 'none'
        });
        return _react2.default.createElement(
            'div',
            (_React$createElement2 = { className: _LoadingIndicator2.default.loadingIndicatorWrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'LoadingIndicator'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'LoadingIndicator'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'LoadingIndicator'), _React$createElement2),
            _react2.default.createElement(_RefreshIndicator2.default, (_React$createElement = {
                key: 'LoadingIndicator',
                left: 0,
                top: 12,
                status: visible ? "loading" : "hide",
                style: style,
                className: _LoadingIndicator2.default.refreshIndicator,
                'data-codecept-selector-node': 'RefreshIndicator',
                'data-codecept-selector-file': 'LoadingIndicator'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RefreshIndicator'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'LoadingIndicator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RefreshIndicator'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'LoadingIndicator'), _React$createElement))
        );
    }
});
exports.default = LoadingIndicator;