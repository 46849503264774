'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _constants = require('./constants');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

module.exports = {
    /*
     * some error message defaults
     * based on error object from superagent
     * options.modal
     * options.type
     *
     * options.passthroughError - (bool) we want to start showing error from the server instead of the hardcoded translation, 
     *      for now, this is opt-in, but eventually the default will be true
     */
    getErrorNotificationOptions: function getErrorNotificationOptions(error) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : { passthroughError: false };

        var type = _constants2.default.NOTIFICATION_TYPES.ERROR;
        var errorResponseBody = _lodash2.default.get(error, 'response.body.error', error);

        if (options.passthroughError) {
            return _extends({
                type: type,
                modal: true
            }, options, { title: errorResponseBody.title,
                message: errorResponseBody.message
            });
        }
        if (error && error.status === 401) {
            return {
                type: type,
                modal: true,
                title: 'Authorization Error',
                message: 'A permissions error was encountered. ' + 'Please try reloading the page, logging back in, or contacting Support.'
            };
        } else if (error && error.status === 403) {
            return {
                type: type,
                modal: true,
                title: 'Forbidden',
                message: 'You do not have permission to take this action. ' + 'Please try reloading the page, logging back in, or contacting Support.'
            };
        } else if (error && error.status === 422) {
            return {
                type: type,
                modal: false,
                title: 'Data Error',
                message: 'Please review your form for any errors.'
                //# TODO: consider using error message from error object (not sure if it's ok to blindly show it)
            };
        } else if (errorResponseBody) {
            return {
                type: type,
                modal: true,
                title: errorResponseBody.title || 'RubyLaw Error',
                message: 'An unexpected error was encountered. ' + 'Please try reloading the page, logging back in, or contacting Support.'
            };
        }
    }
};