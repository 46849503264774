"use strict";

module.exports = {
    "contentForLanguages": {
        "de": {
            "name": {
                "integrityMessages": "Found 1 possible misspelling",
                "wordsToHighlight": ["fdsfsa"]
            }
        }
    },
    "office_addresses": [{
        "name": {
            "integrityMessages": "Found 2 possible misspellings",
            "wordsToHighlight": ["sdfdsfd", "sdfds"]
        }
    }]
};