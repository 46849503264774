'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var defaultState = {
    error: null //# error: {message: <string>}just like the nested fields
    , descendentsHaveValues: false
};

function _setFieldErrorObject_reducer(selfModule, state, action) {
    return _extends({}, state, {
        error: action.payload.error
    });
}

function _mergeFieldErrorObject_reducer(selfModule, state, action) {
    return _extends({}, state, {
        error: _extends({}, state.error, action.payload.error)
    });
}

module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];

    var _getAction = this.getAction(),
        TYPES = _getAction.TYPES;

    var type = action.type,
        payload = action.payload;


    if (!state.hasOwnProperty('error')) {
        state = _extends({}, state, defaultState);
    }

    switch (type) {
        case TYPES.SET_FIELD_ERROR_MESSAGE_BY_KEY:
        case TYPES.MERGE_FIELD_ERROR_OBJECT_BY_KEY:
            return _mergeFieldErrorObject_reducer(this, state, action);
        case TYPES.SET_FIELD_ERROR_OBJECT_BY_KEY:
            return _setFieldErrorObject_reducer(this, state, action);
        case TYPES.SET_DESCENDENTS_HAVE_VALUES:
            return _extends({}, state, payload);
        case TYPES.RESET_STORE:
            return _extends({}, state, defaultState);
        default:
            return state;
    }
};