"use strict";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

/* jshint -W138 */

var defaultState = {};

//# NOTE: default state is provided by the RubyComponent (via getInitialState())
//# because it has to account for the defaultState of the mixins
module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];

    var _getAction = this.getAction(),
        TYPES = _getAction.TYPES;

    var type = action.type,
        payload = action.payload;

    switch (type) {
        case TYPES.RESET_STATE:
            return _extends({}, this.getInitialState(), payload.state || {});
        default:
            return state;
    }
};