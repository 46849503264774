'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _SvgIcon = require('material-ui/SvgIcon');

var _SvgIcon2 = _interopRequireDefault(_SvgIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//require('./Loading.css');

var SvgIcon__propTypeKeys = Object.keys(_SvgIcon2.default.propTypes);
/*
const Loading = (props) => {
    return (
    <SvgIcon color="#df134c" {...SvgIcon__props} viewBox="0 0 76 76" className={`ruby-spinner ${props.status == 'loading' ? 'ruby-spinner-active' : 'ruby-spinner-inactive'}`}>
        <polygon className="rf rf1" points="23.41 55.6 35.93 60.79 35.93 74 14.07 64.94 23.41 55.6 23.41 55.6"/>
        <polygon className="rf rf2" points="11.09 61.96 2.04 40.1 15.2 40.1 20.4 52.66 11.09 61.96 11.09 61.96"/>
        <polygon className="rf rf3" points="2.04 35.89 11.09 14.03 20.43 23.37 15.24 35.89 2.04 35.89 2.04 35.89"/>
        <polygon className="rf rf4" points="14.07 11.05 35.93 2 35.93 15.16 29.66 17.78 23.38 20.36 14.07 11.05 14.07 11.05"/>
        <polygon className="rf rf5" points="62 11.06 52.66 20.39 40.14 15.21 40.14 2 62 11.06 62 11.06"/>
        <polygon className="rf rf6" points="55.67 23.34 64.98 14.03 74.04 35.89 60.87 35.89 55.67 23.34 55.67 23.34"/>
        <polygon className="rf rf7" points="55.64 52.63 60.83 40.11 74.04 40.11 64.98 61.96 55.64 52.63 55.64 52.63"/>
        <polygon className="rf rf8" points="62 64.94 40.14 74 40.14 60.84 52.69 55.63 62 64.94 62 64.94"/>
        <polygon className="rf fc" points="24.79 24.75 38.03 19.26 51.28 24.75 56.77 38 51.28 51.25 38.03 56.73 24.79 51.24 19.3 37.99 24.79 24.75 24.79 24.75"/>
    </SvgIcon>
    );
}
*/

var Loading = function Loading(props) {
    var _React$createElement, _extends2, _React$createElement2, _extends3, _React$createElement3, _extends4, _React$createElement4, _extends5, _React$createElement5, _extends6, _React$createElement6, _extends7, _React$createElement7, _extends8, _React$createElement8, _extends9, _React$createElement9, _extends10, _React$createElement10;

    var SvgIcon__props = _.pick(props, SvgIcon__propTypeKeys);
    return _react2.default.createElement(
        'div',
        (_React$createElement10 = {
            className: 'ruby-spinner-wrapper ' + (props.status == 'loading' ? 'ruby-spinner-active' : 'ruby-spinner-inactive'),
            'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'Loading'
        }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'Loading'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'Loading'), _React$createElement10),
        _react2.default.createElement(
            _SvgIcon2.default,
            _extends({ color: '#df134c' }, SvgIcon__props, (_extends2 = { viewBox: '0 0 76 76', className: 'ruby-spinner-fc ruby-spinner-svg', 'data-codecept-selector-node': 'SvgIcon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Loading'), _defineProperty(_extends2, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Loading'), _extends2)),
            _react2.default.createElement('polygon', (_React$createElement = { className: 'rf fc', points: '24.79 24.75 38.03 19.26 51.28 24.75 56.77 38 51.28 51.25 38.03 56.73 24.79 51.24 19.3 37.99 24.79 24.75 24.79 24.75', 'data-codecept-selector-node': 'polygon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Loading'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Loading'), _React$createElement))
        ),
        _react2.default.createElement(
            _SvgIcon2.default,
            _extends({ color: '#df134c' }, SvgIcon__props, (_extends3 = { viewBox: '0 0 76 76', className: 'ruby-spinner-rf ruby-spinner-rf1 ruby-spinner-svg', 'data-codecept-selector-node': 'SvgIcon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Loading'), _defineProperty(_extends3, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Loading'), _extends3)),
            _react2.default.createElement('polygon', (_React$createElement2 = { className: 'rf rf1', points: '23.41 55.6 35.93 60.79 35.93 74 14.07 64.94 23.41 55.6 23.41 55.6', 'data-codecept-selector-node': 'polygon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Loading'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Loading'), _React$createElement2))
        ),
        _react2.default.createElement(
            _SvgIcon2.default,
            _extends({ color: '#df134c' }, SvgIcon__props, (_extends4 = { viewBox: '0 0 76 76', className: 'ruby-spinner-rf ruby-spinner-rf2 ruby-spinner-svg', 'data-codecept-selector-node': 'SvgIcon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Loading'), _defineProperty(_extends4, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Loading'), _extends4)),
            _react2.default.createElement('polygon', (_React$createElement3 = { className: 'rf rf2', points: '11.09 61.96 2.04 40.1 15.2 40.1 20.4 52.66 11.09 61.96 11.09 61.96', 'data-codecept-selector-node': 'polygon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Loading'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Loading'), _React$createElement3))
        ),
        _react2.default.createElement(
            _SvgIcon2.default,
            _extends({ color: '#df134c' }, SvgIcon__props, (_extends5 = { viewBox: '0 0 76 76', className: 'ruby-spinner-rf ruby-spinner-rf3 ruby-spinner-svg', 'data-codecept-selector-node': 'SvgIcon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_extends5, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Loading'), _defineProperty(_extends5, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Loading'), _extends5)),
            _react2.default.createElement('polygon', (_React$createElement4 = { className: 'rf rf3', points: '2.04 35.89 11.09 14.03 20.43 23.37 15.24 35.89 2.04 35.89 2.04 35.89', 'data-codecept-selector-node': 'polygon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Loading'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Loading'), _React$createElement4))
        ),
        _react2.default.createElement(
            _SvgIcon2.default,
            _extends({ color: '#df134c' }, SvgIcon__props, (_extends6 = { viewBox: '0 0 76 76', className: 'ruby-spinner-rf ruby-spinner-rf4 ruby-spinner-svg', 'data-codecept-selector-node': 'SvgIcon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_extends6, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends6, 'data-codecept-selector-file', 'Loading'), _defineProperty(_extends6, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends6, 'data-codecept-selector-file', 'Loading'), _extends6)),
            _react2.default.createElement('polygon', (_React$createElement5 = { className: 'rf rf4', points: '14.07 11.05 35.93 2 35.93 15.16 29.66 17.78 23.38 20.36 14.07 11.05 14.07 11.05', 'data-codecept-selector-node': 'polygon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Loading'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Loading'), _React$createElement5))
        ),
        _react2.default.createElement(
            _SvgIcon2.default,
            _extends({ color: '#df134c' }, SvgIcon__props, (_extends7 = { viewBox: '0 0 76 76', className: 'ruby-spinner-rf ruby-spinner-rf5 ruby-spinner-svg', 'data-codecept-selector-node': 'SvgIcon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_extends7, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends7, 'data-codecept-selector-file', 'Loading'), _defineProperty(_extends7, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends7, 'data-codecept-selector-file', 'Loading'), _extends7)),
            _react2.default.createElement('polygon', (_React$createElement6 = { className: 'rf rf5', points: '62 11.06 52.66 20.39 40.14 15.21 40.14 2 62 11.06 62 11.06', 'data-codecept-selector-node': 'polygon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Loading'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Loading'), _React$createElement6))
        ),
        _react2.default.createElement(
            _SvgIcon2.default,
            _extends({ color: '#df134c' }, SvgIcon__props, (_extends8 = { viewBox: '0 0 76 76', className: 'ruby-spinner-rf ruby-spinner-rf6 ruby-spinner-svg', 'data-codecept-selector-node': 'SvgIcon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_extends8, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends8, 'data-codecept-selector-file', 'Loading'), _defineProperty(_extends8, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends8, 'data-codecept-selector-file', 'Loading'), _extends8)),
            _react2.default.createElement('polygon', (_React$createElement7 = { className: 'rf rf6', points: '55.67 23.34 64.98 14.03 74.04 35.89 60.87 35.89 55.67 23.34 55.67 23.34', 'data-codecept-selector-node': 'polygon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Loading'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Loading'), _React$createElement7))
        ),
        _react2.default.createElement(
            _SvgIcon2.default,
            _extends({ color: '#df134c' }, SvgIcon__props, (_extends9 = { viewBox: '0 0 76 76', className: 'ruby-spinner-rf ruby-spinner-rf7 ruby-spinner-svg', 'data-codecept-selector-node': 'SvgIcon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_extends9, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends9, 'data-codecept-selector-file', 'Loading'), _defineProperty(_extends9, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends9, 'data-codecept-selector-file', 'Loading'), _extends9)),
            _react2.default.createElement('polygon', (_React$createElement8 = { className: 'rf rf7', points: '55.64 52.63 60.83 40.11 74.04 40.11 64.98 61.96 55.64 52.63 55.64 52.63', 'data-codecept-selector-node': 'polygon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Loading'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Loading'), _React$createElement8))
        ),
        _react2.default.createElement(
            _SvgIcon2.default,
            _extends({ color: '#df134c' }, SvgIcon__props, (_extends10 = { viewBox: '0 0 76 76', className: 'ruby-spinner-rf ruby-spinner-rf8 ruby-spinner-svg', 'data-codecept-selector-node': 'SvgIcon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_extends10, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends10, 'data-codecept-selector-file', 'Loading'), _defineProperty(_extends10, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends10, 'data-codecept-selector-file', 'Loading'), _extends10)),
            _react2.default.createElement('polygon', (_React$createElement9 = { className: 'rf rf8', points: '62 64.94 40.14 74 40.14 60.84 52.69 55.63 62 64.94 62 64.94', 'data-codecept-selector-node': 'polygon',
                'data-codecept-selector-file': 'Loading'
            }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Loading'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'polygon'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Loading'), _React$createElement9))
        )
    );
};
/*
const ANIMATION_DURATION = 1000;
const FADE_MAP = [
    [0,1]
    , [.1, 1]
    , [.2, 1]
    , [.3, 1]
    , [.4, 1]
    , [.5, 1]
    , [.6, 1]
    , [.7, 1]
];

const Loading = React.createClass({
    propTypes: {
    }
    , getInitialState: () => ({
        animationProgress: 0 //# percentage 0 - 1
        , request: null
        , start: null
    })
    , componentWillReceiveProps: function(nextProps) {
        if (nextProps.status == 'loading') {
            if (this.state.request == null) {
                this.setState({
                    request: requestAnimationFrame(this.tick)
                });
            }
        } else {
            this.cancelAnimationFrame();
        }
    }
    , componentDidMount: function() {
        if (this.state.request == null && this.props.status == 'loading') {
            this.setState({
                request: requestAnimationFrame(this.tick)
            });
        }
    }
    , componentWillUnmount: function () {
        this.cancelAnimationFrame();
    }
    , render: function() {
        const props = this.props;
        const {
            animationProgress
        } = this.state;

        const fadeProgressArray = FADE_MAP.map((keyframePair) => {

            const [keyframeStart, keyframeEnd] = keyframePair;

            const a = keyframeEnd - keyframeStart;

            if (animationProgress >= keyframeStart) {
                return (animationProgress - keyframeStart) / a;
            } else {
                return 0;
            }

            const b = keyframePair[0];

            const currentProgress = a * animationProgress + b;

            return currentProgress;
        });

        return (
        <SvgIcon color="#df134c" {...SvgIcon__props} viewBox="0 0 76 76" className={`ruby-spinner ${props.status == 'loading' ? 'ruby-spinner-active' : 'ruby-spinner-inactive'}`}>
            <polygon
                className="rf rf1"
                points="23.41 55.6 35.93 60.79 35.93 74 14.07 64.94 23.41 55.6 23.41 55.6"
                style={{opacity: fadeProgressArray[0]}}
            />
            <polygon
                className="rf rf2"
                points="11.09 61.96 2.04 40.1 15.2 40.1 20.4 52.66 11.09 61.96 11.09 61.96"
                style={{opacity: fadeProgressArray[1]}}
            />
            <polygon
                className="rf rf3"
                points="2.04 35.89 11.09 14.03 20.43 23.37 15.24 35.89 2.04 35.89 2.04 35.89"
                style={{opacity: fadeProgressArray[2]}}
            />
            <polygon
                className="rf rf4"
                points="14.07 11.05 35.93 2 35.93 15.16 29.66 17.78 23.38 20.36 14.07 11.05 14.07 11.05"
                style={{opacity: fadeProgressArray[3]}}
            />
            <polygon
                className="rf rf5"
                points="62 11.06 52.66 20.39 40.14 15.21 40.14 2 62 11.06 62 11.06"
                style={{opacity: fadeProgressArray[4]}}
            />
            <polygon
                className="rf rf6"
                points="55.67 23.34 64.98 14.03 74.04 35.89 60.87 35.89 55.67 23.34 55.67 23.34"
                style={{opacity: fadeProgressArray[5]}}
            />
            <polygon
                className="rf rf7"
                points="55.64 52.63 60.83 40.11 74.04 40.11 64.98 61.96 55.64 52.63 55.64 52.63"
                style={{opacity: fadeProgressArray[6]}}
            />
            <polygon
                className="rf rf8"
                points="62 64.94 40.14 74 40.14 60.84 52.69 55.63 62 64.94 62 64.94"
                style={{opacity: fadeProgressArray[7]}}
            />
            <polygon
                className="rf fc"
                points="24.79 24.75 38.03 19.26 51.28 24.75 56.77 38 51.28 51.25 38.03 56.73 24.79 51.24 19.3 37.99 24.79 24.75 24.79 24.75"/>
        </SvgIcon>
        );
    }
    , tick: function(timestamp) {
        if (!this.isMounted()) {
            return this.cancelAnimationFrame();
        }

        //# figure out progress
        const {
            start = timestamp
        } = this.state;

        const progress = ((timestamp - start) % ANIMATION_DURATION) / ANIMATION_DURATION;

        this.setState({
            animationProgress: progress
            , request: requestAnimationFrame(this.tick)
            , start
        });
    }
    , cancelAnimationFrame: function() {
        cancelAnimationFrame(this.state.request);
        
        if (this.isMounted()) {
            this.setState({
                request: null
                , start: null
            });
        }
    }
});
*/

exports.default = Loading;