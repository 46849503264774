'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; //const rubyLogger = require('@rubyapps/ruby-logger');
//const logger = rubyLogger.getLogger('ruby-component-sitemap-page:reactComponents/SitemapConnector');

//# Material Components


//# styles == Note: add local styles file when we need to define custom styles


// TODO: we probably want a way to specify column definitions outside of this component
//       so that ruby-component-field-sitemap can be more generic


exports.default = sitemapConnectorGenerator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _path = require('path');

var _path2 = _interopRequireDefault(_path);

var _constants = require('@rubyapps/ruby-component-plugin-websites/src/common/constants');

var _constants2 = _interopRequireDefault(_constants);

var _columnDefinitionsGenerator = require('../columnDefinitionsGenerator');

var _columnDefinitionsGenerator2 = _interopRequireDefault(_columnDefinitionsGenerator);

var _index = require('./UtilityBar/index');

var _index2 = _interopRequireDefault(_index);

var _indexConnector = require('./UtilityBar/indexConnector');

var _indexConnector2 = _interopRequireDefault(_indexConnector);

var _filterOptions = require('../filterOptions');

var _filterOptions2 = _interopRequireDefault(_filterOptions);

var _superagent = require('superagent');

var _superagent2 = _interopRequireDefault(_superagent);

var _rubyHierarchyUtils = require('@rubyapps/ruby-hierarchy-utils');

var _header = require('./header');

var _header2 = _interopRequireDefault(_header);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var normalizedSitemapUtils = require('@rubyapps/normalized-sitemap-utils').default;
var ListPage = require('@rubyapps/ruby-component-list-page');
var rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');

var sitemapUtils = require('../../common/utils');


var GriddleModule = require('@rubyapps/griddle');
var Griddle = GriddleModule.Griddle,
    GriddleRender = GriddleModule.GriddleRender,
    GriddleDefaultsPlugin = GriddleModule.GriddleDefaultsPlugin,
    GriddleSubgridPlugin = GriddleModule.GriddleSubgridPlugin,
    GriddleDndPlugin = GriddleModule.GriddleDndPlugin,
    GriddleUtilityBarPlugin = GriddleModule.GriddleUtilityBarPlugin,
    GriddleRemotePlugin = GriddleModule.GriddleRemotePlugin,
    GriddlePreferencesPlugin = GriddleModule.GriddlePreferencesPlugin,
    GriddleSelectionPlugin = GriddleModule.GriddleSelectionPlugin,
    GriddleBatchMovePlugin = GriddleModule.GriddleBatchMovePlugin;

var RemotePlugin = GriddleRemotePlugin.RemotePlugin;
var superagentProvider = GriddleRemotePlugin.superagentProvider;

var DndPlugin = GriddleDndPlugin.DndPlugin;

var DefaultsPlugin = GriddleDefaultsPlugin.DefaultsPlugin;
var rubyDateManager = require('@rubyapps/ruby-date');

function sitemapConnectorGenerator() {
    var selfModule = this;
    var selfModuleID = selfModule.getID();

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector,
        alterTemplateDialogActions = _selfModule$getDepend.alterTemplateDialogActions,
        confirmDialogActions = _selfModule$getDepend.confirmDialogActions,
        currentUserSelector = _selfModule$getDepend.currentUserSelector,
        formsSelector = _selfModule$getDepend.formsSelector,
        feSettingsSelector = _selfModule$getDepend.feSettingsSelector,
        listerConfigsComponent = _selfModule$getDepend.listerConfigsComponent,
        parentForm = _selfModule$getDepend.parentForm,
        saveStateToRemoteAction = _selfModule$getDepend.saveStateToRemoteAction,
        newPageDialogActions = _selfModule$getDepend.newPageDialogActions,
        getEditEntryUrl_forId_withTemplateKeyword = _selfModule$getDepend.getEditEntryUrl_forId_withTemplateKeyword,
        forms = _selfModule$getDepend.forms,
        subsite = _selfModule$getDepend.subsite,
        editPageTemplateKey = _selfModule$getDepend.editPageTemplateKey;

    var listPage = ListPage.getReactClass.call(selfModule, { columnDefinitionsGenerator: _columnDefinitionsGenerator2.default.bind(selfModule) });

    var _ref = this.getProps ? this.getProps() : selfModule.props,
        staticFilterTags = _ref.staticFilterTags,
        internalFilterTags = _ref.internalFilterTags,
        _ref$internalFieldsQu = _ref.internalFieldsQuery,
        internalFieldsQuery = _ref$internalFieldsQu === undefined ? {
        fields: [],
        expand: []
    } : _ref$internalFieldsQu,
        listerConfig = _ref.listerConfig;

    listerConfig = listerConfigsComponent.getAugmentedListerConfig(listerConfig, 'sitemap', this);

    function mapStateToProps(state, ownProps) {
        /*
        return {
            plugins: [GriddleSubgridPlugin]
            , data: require('../sampleData')
        };
        */

        var selfState = selfSelector(state);
        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
        var fieldKey = mixinFieldProps.fieldKey;

        //=======================================//

        var forms = formsSelector(state);
        var feSettings = feSettingsSelector(state);

        selfModule._connectedPrevState = selfState;

        var currentUser = currentUserSelector(state);
        var currentUsername = _lodash2.default.get(currentUser, 'username');

        var _selfModule$getActive = selfModule.getActiveRubyClientInfo(),
            rubyClientId = _selfModule$getActive.client,
            subsiteId = _selfModule$getActive.subsite;

        var _listerConfig = listerConfig,
            hierarchical = _listerConfig.hierarchical;

        var columns = selfModule.utils.getColumnsFromGriddleState_defaultingToColumns(selfState.griddleState, listerConfig.columns);

        var apiUrlPrefix = _lodash2.default.get(feSettings, 'restApiRoot');

        var listApiEndpoint = _path2.default.join(apiUrlPrefix, selfModule.props.listApiEndpoint);

        var fields = _lodash2.default.map(columns, function (col) {
            return col.key;
        }).filter(function (colKey) {
            return colKey;
        });

        var griddleDefaults = DefaultsPlugin(selfModule.getInitialGriddleState_withDefault({
            pageProperties: {
                pageSize: ListPage.CONSTANTS.DEFAULT_PAGE_SIZE,
                sortColumns: []
            }
        }));

        var sitemapValue = getCurrentSitemapValue_fromFieldKey_andSelfModule(fieldKey, selfModule);

        var remoteConfig = {
            url: listApiEndpoint,
            formatRequest: function formatRequest(req, tableState, griddleState) {
                var page = tableState.page,
                    pageSize = tableState.pageSize,
                    filter = tableState.filter,
                    sortColumn = tableState.sortColumn,
                    sortDirection = tableState.sortDirection;

                var utilityBarProperties = _lodash2.default.get(griddleState, 'utilityBarProperties');

                var sitemapValue = getCurrentSitemapValue_fromFieldKey_andSelfModule(fieldKey, selfModule);

                var columns = selfModule.utils.getColumnsFromGriddleState_defaultingToColumns(griddleState, listerConfig.columns);
                var fieldsQueryObject = selfModule.utils.formatRequestPropsForColumns(columns);

                var baseQuery = {
                    p: page - 1,
                    psize: pageSize,
                    search: filter,
                    ignoreUserPermissions: utilityBarProperties.sortable_enabled,
                    fields: fieldsQueryObject.fields.concat(fields, ['children', 'id']).concat(internalFieldsQuery.fields || []),
                    expand: fieldsQueryObject.expand.concat(internalFieldsQuery.expand || [])
                };

                var filterProps = _lodash2.default.get(utilityBarProperties, 'dropdownFilters');
                var filterQuery = _lodash2.default.map(filterProps, function (value, key) {
                    return {
                        key: key,
                        value: value
                    };
                }).filter(function (filterValue) {
                    return _lodash2.default.get(filterValue, 'value') != null;
                });

                if (sitemapValue.result.length) {
                    return _superagent2.default.post(listApiEndpoint).query({
                        ruby_client_fk: rubyClientId,
                        content_subsite_fk: subsiteId
                    }).send(_extends({}, baseQuery, {
                        sitemap: sitemapValue,
                        filters: filterQuery
                    }));
                } else {
                    return null; //# cause griddle to not actually make the network request because there's no point
                }
            },
            formatSuccess: function formatSuccess(resp, tableState) {
                var total = resp.total;

                return {
                    page: tableState.page,
                    maxPage: parseInt(total / tableState.pageSize, 10) + (total % tableState.pageSize ? 1 : 0),
                    totalCount: total,
                    data: resp.data
                };
            }
        };
        var remotePluginInstance = RemotePlugin(superagentProvider, remoteConfig);

        var activeRouteComponent = selfModule.getActiveRouteComponent();

        var _ref2 = activeRouteComponent ? activeRouteComponent.getState() : {},
            _ref2$routeParams = _ref2.routeParams,
            routeParams = _ref2$routeParams === undefined ? {} : _ref2$routeParams;

        var pageID = routeParams.id;


        var plugins = [].concat(remotePluginInstance, GriddleSubgridPlugin(), griddleDefaults);

        var getUserHasPerm_forItem = function getUserHasPerm_forItem(perm, item) {
            return selfModule.doesUserHavePermission({
                action: perm,
                template: item.templateKey,
                id: item.id,
                subsite: true,
                ruby_client: true,
                model: 'content'
            });
        };

        return {
            plugins: plugins,
            apiUrlPrefix: apiUrlPrefix,
            columns: columns,
            fieldKey: fieldKey,
            selfState: selfState,
            currentUser: currentUser,
            forms: forms,
            remoteDataArgs: { sitemap: sitemapValue },
            getUserHasPerm_forItem: getUserHasPerm_forItem,
            canUserPublish: function canUserPublish() {
                return selfModule.canUserPublish();
            },
            renderLister: !!currentUser
        };
    }

    function mapDispatchToProps(dispatch, ownProps) {
        var boundActionCreators = (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch);
        return {
            actions: boundActionCreators,
            delegateActions: {
                saveStateToRemoteAction: (0, _redux.bindActionCreators)(saveStateToRemoteAction, dispatch),
                newPageDialog: (0, _redux.bindActionCreators)(newPageDialogActions, dispatch),
                alterTemplateDialog: (0, _redux.bindActionCreators)(alterTemplateDialogActions, dispatch),
                confirmDialog: (0, _redux.bindActionCreators)(confirmDialogActions, dispatch)
                //# No longer need to define reflectGriddleState here
                //# we want to rely on 'ruby-react-components/src/client/view/Lister.js'
                //, reflectGriddleState: (griddleState, prevGriddleState={}) => {}
            } };
    }

    function _startPollingForUrlUpdate(store, events, _recentlyMovedPages, refreshResponse) {
        //# TODO: poll for url in recently Moved pages
        //# if different from refreshResponse, update and call on events.refreshCurrentPageRemoteHandler
        /*
        const { 
            data
            , pageProperties //# currentPage, maxPage, pageSize, sortAscending
        } = store.getState();
         events.refreshCurrentPageRemoteHandler({
            data
            , maxPage
            , page
            , totalCount
        })
         let intervalId;
        let intervalCount = 0;
        */

        var apiUrlPrefix = selfModule.getDependencies().feSettingsComponent.getState().restApiRoot;

        //id=24412&type=content&content_subsite_fk=1
        var batchUrlEndpoint = _path2.default.join(apiUrlPrefix, 'content/getBatchURLs?id=');
    }

    function mergeProps(stateProps, dispatchProps, ownProps) {
        var _ref4;

        var filters = stateProps.filters;
        var fieldKey = stateProps.fieldKey;


        var debouncedRefresh_usingGriddleCallback = _lodash2.default.debounce(function (callback) {
            selfModule.pushNotification({
                type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.INFO,
                message: 'Refreshing Data.'
            });
            callback();
        }, 5000);

        function warnOrSaveAfterUpdate_fromValue_toValue(oldValue, newValue) {
            var _ref3 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
                saveParams = _ref3.saveParams;

            //# for now, if there is a dupe or not-explicit pruned/deleted pages, we warn and don't auto-save
            //# have logic to check if there are "removed" items
            var missingIds = sitemapUtils.getMissingPagesInSitemap_fromSitemap(newValue, oldValue);
            var dupesIds = sitemapUtils.findDupesInSitemapValue(newValue);

            if (missingIds.length || dupesIds.length) {
                var errorMessages = [];

                if (missingIds.length) {
                    errorMessages.push('Sitemap field erroneously has missing pages. Saving was stopped to prevent these pages from being deleted.');
                    console.error('===== missingIds found:', missingIds, '...  you most likely dragged the item into something that is no longer available, causing us to think it has been deleted.');
                }

                if (dupesIds.length) {
                    errorMessages.push('Sitemap field erroneously duplicated page entries. Saving was stopped to prevent the sitemap from showing duplicates');

                    console.error('===== dupesIds found:', dupesIds, '... edgecase drag case where dragging out from child to parent causes a dupe');
                }

                selfModule.showNotificationModal({
                    type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR,
                    title: 'Drag and Drop Error',
                    message: 'Error encountered on drag and drop. \n' + errorMessages.join(' \n') + ' \nPlease refresh the page and try again.'
                });

                var saveButton = parentForm.findDescendentBy(function (node) {
                    return node.componentName == 'rubyComponentFieldButton' && node.props.label == 'Save';
                });

                var disabledSaveAlert = function disabledSaveAlert() {

                    selfModule.showNotificationModal({
                        type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR,
                        title: 'Save Disabled',
                        message: 'Page saving is disabled due to issues encountered on drag and drop. \n' + 'Please refresh the page to reenable saving.'
                    });
                };

                //# TODO: hacky, forcefully override saveStateToRemote to prevent saves until refresh
                //# we leave this here until we can resolve this edgecase
                saveButton.getDependencies().actionForButton = disabledSaveAlert;
                parentForm.getAction().generators.saveStateToRemote = disabledSaveAlert;
                //# force button to rerender
                saveButton.getStore().dispatch(saveButton.getAction().generators.requestRerender());
            } else {
                selfModule.getGriddleProps().events.clearAllRowSelections();
                selfModule.util__save(saveParams);
            }
        }

        var dndPluginInstance = DndPlugin({
            onBeginDrag: function onBeginDrag() {
                debouncedRefresh_usingGriddleCallback.cancel();
            },
            onDrop: function onDrop(store, movedRowId, precedingRowId, followingRowId, parentId, events) {
                var griddleData = store.getState().get('data').toJS();
                var fieldValue = selfModule.getFieldValue();
                var currentRowById = _lodash2.default.get(fieldValue, 'entities.sitemapItem', {});

                //# TODO: this is probably miscalculated/mismanaged because an id being dragged out should hav ebeen removed from the children
                //# might be causing this issue: https://gitlab.intranet.rubensteintech.com/project/ruby-app/-/issues/759
                var newRowById = getNewRowById_fromCurrRowById_andGriddleData(currentRowById, griddleData);

                var currentRootIds = _lodash2.default.get(fieldValue, 'result', []);

                var newRootIds = getNewRootIds_currRootIds_fromMovedId_afterId_beforeId_parentId(currentRootIds, movedRowId, precedingRowId, followingRowId, parentId);

                var newFieldValue = {
                    entities: {
                        sitemapItem: newRowById
                    },
                    result: newRootIds
                };

                var sitemapValue = getCurrentSitemapValue_fromFieldKey_andSelfModule(fieldKey, selfModule);

                dispatchProps.actions.setFieldValueByKey((0, _rubyHierarchyUtils.getPrunedSitemap_fromSitemap)(newFieldValue), fieldKey);

                warnOrSaveAfterUpdate_fromValue_toValue(sitemapValue, newFieldValue, {
                    saveParams: {
                        successCallback: function successCallback(err, responseBody) {
                            var _recentlyMovedPages = responseBody[_constants2.default.MOVED_PAGES_PROP];

                            //# TODO: poll and patch the data
                            debouncedRefresh_usingGriddleCallback(events.refreshCurrentPage.bind(null, function (refreshResponse) {
                                _startPollingForUrlUpdate(store, events, _recentlyMovedPages, refreshResponse);
                            }));
                            debouncedRefresh_usingGriddleCallback.flush(); //# immediately call on it
                        },
                        errorCallback: function errorCallback(err) {
                            //# undo the local field change cause of error
                            dispatchProps.actions.setFieldValueByKey(fieldValue, fieldKey);
                        }
                    }
                });

                return;

                function getNewRootIds_currRootIds_fromMovedId_afterId_beforeId_parentId(currRootIds, movedRowId, after, before, parentId) {
                    var rootIdsWithoutMovedId = _lodash2.default.filter(currentRootIds, function (val) {
                        return val != movedRowId;
                    });

                    if (parentId != null) {
                        //# NOTE: I assume this is when the movedRowId is moved under something, we remove it from the root
                        return rootIdsWithoutMovedId;
                    } else if (after == null && before == null) {
                        //# item was removed
                        return rootIdsWithoutMovedId;
                    } else {
                        var indexOfAfterRow = _lodash2.default.findIndex(rootIdsWithoutMovedId, function (id) {
                            return id == followingRowId;
                        });
                        var indexOfBeforeRow = _lodash2.default.findIndex(rootIdsWithoutMovedId, function (id) {
                            return id == precedingRowId;
                        });

                        var newIndexFromAfterRow = indexOfAfterRow != -1 && indexOfAfterRow;
                        var newIndexFromBeforeRow = indexOfBeforeRow != -1 && indexOfBeforeRow + 1;

                        var newIndexOfMovedRow = [newIndexFromAfterRow, newIndexFromBeforeRow].find(function (val) {
                            return Number.isInteger(val);
                        });

                        var _newRootIds = newIndexOfMovedRow != null ? [].concat(rootIdsWithoutMovedId.slice(0, newIndexOfMovedRow), movedRowId, rootIdsWithoutMovedId.slice(newIndexOfMovedRow)) : rootIdsWithoutMovedId;

                        return _newRootIds;
                    }
                }

                function getNewRowById_fromCurrRowById_andGriddleData(currRowById, griddleData) {
                    // each row in griddle data contains all of it's children, so
                    var rowByIdFromGriddle = _lodash2.default.mapValues(normalizedSitemapUtils.getFlattenedNestedData_fromNestedData_withChildrenKey(griddleData, 'children'), function (row, id) {
                        return _lodash2.default.pick(row, ['id', 'children']);
                    });

                    return _extends({}, currentRowById, rowByIdFromGriddle);
                }
            }
        });

        var anyReasonsForDisabledModifySitemap = selfModule.getAnyReasonsForDisabledModifySitemap();
        var filtersFromTemplate = ownProps.listerConfig.filters;
        var filterOptions = !_lodash2.default.isEmpty(filtersFromTemplate) ? filtersFromTemplate : (0, _filterOptions2.default)(selfModule);

        var filterOptionTemplateChoices = filterOptions[0].options;

        var mergedPropsFromArgument = _extends({
            searchableFields: selfModule.searchableFieldsFor(function (template) {
                return _lodash2.default.find(filterOptionTemplateChoices, ['value', template.key]) != undefined;
            })
        }, ownProps, stateProps, dispatchProps);
        //# temporary cache of the props for access by Griddle
        selfModule._cachedPropsForConnector = mergedPropsFromArgument;

        var utilityBar = GriddleUtilityBarPlugin((0, _indexConnector2.default)(selfModule, dispatchProps.actions, dispatchProps.delegateActions, filterOptions, anyReasonsForDisabledModifySitemap, function () {
            return selfModule._cachedPropsForConnector;
        })(_index2.default));

        var plugins = (_ref4 = []).concat.apply(_ref4, [utilityBar, GriddleSelectionPlugin(), anyReasonsForDisabledModifySitemap.length > 0 ? [] : dndPluginInstance].concat(_toConsumableArray(stateProps.plugins), [GriddleBatchMovePlugin({
            onBatchMove: function onBatchMove(store, events, movedIds, relativePosition, referenceId) {
                //# - griddle might only have a subsite of the sitemap data, e.g. bc of pagination
                //# - so we can't build the entire newFieldValue from just griddle state
                //# - need to update fieldvalue based on the changes instead
                var fieldValue = selfModule.getFieldValue();
                var parentId = relativePosition === 'inside' ? referenceId : getParentId_forReferenceId_inValue(referenceId, fieldValue);

                var fieldValueWithoutMovedIds = {
                    result: _lodash2.default.difference(fieldValue.result, movedIds),
                    entities: {
                        sitemapItem: _lodash2.default.mapValues(fieldValue.entities.sitemapItem, function (item) {
                            return _extends({}, item, {
                                children: _lodash2.default.difference(item.children, movedIds)
                            });
                        })
                    }
                };

                var newRootIds = parentId ? fieldValueWithoutMovedIds.result : fieldValueWithoutMovedIds.result.reduce(function (result, curr) {
                    if (curr === referenceId && relativePosition === 'before') {
                        result.push.apply(result, _toConsumableArray(movedIds));
                    }
                    result.push(curr);
                    if (curr === referenceId && relativePosition === 'after') {
                        result.push.apply(result, _toConsumableArray(movedIds));
                    }
                    return result;
                }, []);
                var newSitemapItems = _lodash2.default.mapValues(_lodash2.default.get(fieldValueWithoutMovedIds, 'entities.sitemapItem'), function (item) {
                    if (item.id !== parentId) {
                        return item;
                    }
                    var newChildren = relativePosition === 'inside' ? item.children.concat(movedIds) : item.children.reduce(function (result, curr) {
                        if (curr === referenceId && relativePosition === 'before') {
                            result.push.apply(result, _toConsumableArray(movedIds));
                        }
                        result.push(curr);
                        if (curr === referenceId && relativePosition === 'after') {
                            result.push.apply(result, _toConsumableArray(movedIds));
                        }
                        return result;
                    }, []);
                    return _extends({}, item, {
                        children: newChildren
                    });
                });
                var newFieldValue = {
                    result: newRootIds,
                    entities: {
                        sitemapItem: newSitemapItems
                    }
                };

                var sitemapValue = getCurrentSitemapValue_fromFieldKey_andSelfModule(fieldKey, selfModule);
                dispatchProps.actions.setFieldValueByKey((0, _rubyHierarchyUtils.getPrunedSitemap_fromSitemap)(newFieldValue), fieldKey);

                warnOrSaveAfterUpdate_fromValue_toValue(sitemapValue, newFieldValue, {
                    saveParams: {
                        successCallback: function successCallback(err, responseBody) {
                            var _recentlyMovedPages = responseBody[_constants2.default.MOVED_PAGES_PROP];

                            //# TODO: poll and patch the data
                            debouncedRefresh_usingGriddleCallback(events.refreshCurrentPage.bind(null, function (refreshResponse) {
                                _startPollingForUrlUpdate(store, events, _recentlyMovedPages, refreshResponse);
                            }));
                            debouncedRefresh_usingGriddleCallback.flush(); //# immediately call on it
                        },
                        errorCallback: function errorCallback(err) {
                            //# undo the local field change cause of error
                            dispatchProps.actions.setFieldValueByKey(fieldValue, fieldKey);
                        }
                    }
                });

                function getParentId_forReferenceId_inValue(id, value) {
                    var rootIds = _lodash2.default.get(value, 'result', []);
                    if (rootIds.includes(id)) {
                        return null;
                    }
                    var dataById = _lodash2.default.get(value, 'entities.sitemapItem', {});
                    var parentData = _lodash2.default.values(dataById).find(function (el) {
                        return el.children && el.children.includes(id);
                    });
                    return parentData.id;
                }
            }
        })])); // TODO: Can we clean up & define the plugins in one place ?

        return _extends({}, ownProps, stateProps, dispatchProps, {
            filters: filters,
            plugins: plugins
            /*
            , headerLabelElement: (
                <ToolbarGroup className={styles.ToolbarGroup_first_baseline_rightMargin}>
                    <ToolbarTitle text={stateProps.clientName} className={styles.ToolbarTitle_mainLabel}/>
                </ToolbarGroup>
            )
            */
            , components: {
                Header: _header2.default
            }
        });
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(listPage);
}

function getCurrentSitemapValue_fromFieldKey_andSelfModule(fieldKey, selfModule) {

    var selfState = selfModule.getState();
    var sitemapValue = _lodash2.default.get(selfState, ['fields', fieldKey, 'value']);
    return (0, _rubyHierarchyUtils.getPrunedSitemap_fromSitemap)(sitemapValue);
}