'use strict';

var request = require('@rubyapps/ruby-superagent');
var path = require('path');
var urljoin = require('url-join');

function typesWithID(id) {
    return {
        SET_FIELD: '@@ruby-app/' + id + '/SET_FIELD' //# DEPRECATED: 20170316
        , SET_DOMAIN: '@@ruby-app/' + id + '/SET_DOMAIN'
        /*
        , SET_CACHED_URL_IDENTIFIER: `@@ruby-app/${id}/SET_CACHED_URL_IDENTIFIER`
        , SET_CACHED_BROWSER_PAGE_TITLE: `@@ruby-app/${id}/SET_CACHED_BROWSER_PAGE_TITLE`
        */
        , SET_GENERATED_URL: '@@ruby-app/' + id + '/SET_GENERATED_URL'
    };
}

var generators = {
    setField_withValue: function setField_withValue(value) {
        var _this = this;

        var actions = this.getAction().generators;
        return function (dispatch) {
            dispatch(actions.setFieldValueByKey(value, _this.props.key));
        };

        return {
            type: TYPES.SET_FIELD,
            payload: {
                value: value
            }
        };
    },

    retrieveURLForPage_withID_andRubyClientFK: function retrieveURLForPage_withID_andRubyClientFK(pageId, rubyClientFK) {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES,
            actions = _getAction.generators;

        var RESOURCE_LOCATOR_GET_URL_ENDPOINT = '/ruby/api/resource-locator/generate_url.json';
        return function (dispatch, getState) {
            return request.get(RESOURCE_LOCATOR_GET_URL_ENDPOINT).query({
                ruby_client_fk: rubyClientFK,
                id: pageId,
                fqdn: 1
            }).then(function success(response) {
                if (response.body.data) {
                    var url = new URL(response.body.data);
                    dispatch(actions.setDomain(url.hostname));
                    dispatch(actions.setURL(url.pathname));
                } else {
                    dispatch(actions.setURL(null));
                }
            }, function error(err) {
                dispatch(actions.setURL(null));
                console.warn({ error: err });
            }).catch(function (err) {
                dispatch(actions.setURL(null));
                console.warn({ error: err });
            });
        };
    },
    setDomain: function setDomain(domain) {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES,
            generators = _getAction2.generators;

        return {
            type: TYPES.SET_DOMAIN,
            payload: {
                value: domain
            }
        };
    },

    setURL: function setURL(url) {
        var _getAction3 = this.getAction(),
            TYPES = _getAction3.TYPES,
            generators = _getAction3.generators;

        return {
            type: TYPES.SET_PAGE_URL,
            payload: {
                value: url
            }
        };
    },
    setGeneratedUrl: function setGeneratedUrl(generatedUrl) {
        var _getAction4 = this.getAction(),
            TYPES = _getAction4.TYPES,
            generators = _getAction4.generators;

        return {
            type: TYPES.SET_GENERATED_URL,
            payload: {
                value: generatedUrl
            }
        };
    }
};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};