'use strict';

var _bluebird = require('bluebird');

var _bluebird2 = _interopRequireDefault(_bluebird);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;


var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldOptionsMixin = {
    mixinName: 'rubyComponentMixinFieldOptions',
    propTypes: {
        options: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
        }))

        //== UTILITIES =============================//
    }, getDisplayTextFromOption: function getDisplayTextFromOption(opt) {
        return opt.text || opt.primaryText || opt.label || opt.name || opt.value;
    },
    getOptionsMatchingOptionValueAsync: function getOptionsMatchingOptionValueAsync(optionValue) {
        var _this = this;

        var optionValue_normalized = _.castArray(optionValue);

        var options = this.props.options || [];
        var foundOptions = options.filter(function (option) {
            return option && optionValue_normalized.indexOf(_this.coercedValue(option.value || option.id, true)) >= 0;
        });

        return _bluebird2.default.resolve(foundOptions);
    }

    //== OVERRIDES =============================//
    , _displayValueFromLocalState: function _displayValueFromLocalState(selfState, entireState, options) {
        var _this2 = this;

        var selfKey = this.props.key;

        //# return the text label if possible
        var selectedOptionValue = this.getFieldValueFromLocalState(selfState);

        var promisedSelectedOptions = this.getOptionsMatchingOptionValueAsync(selectedOptionValue);

        return promisedSelectedOptions.then(function (selectedOptions) {
            var displayTextForOptions = selectedOptions.map(_this2.getDisplayTextFromOption);

            var displayObject = void 0;
            if (_.isArray(selectedOptionValue)) {
                displayObject = _defineProperty({}, selfKey, displayTextForOptions);
            } else {
                displayObject = _defineProperty({}, selfKey, displayTextForOptions[0]);
            }

            return displayObject;
        });
    }

    //# NOTE: tokentagger uses this
    //# but Dropdown doesn't
    , formValueFromFieldValue_forKey: function formValueFromFieldValue_forKey(localState, key) {
        var rawValue = localState;
        if (_.isNil(rawValue)) return rawValue;
        var dataTypeFormattedRawValue = baseFieldMixin.formValueFromFieldValue_forKey.apply(this, arguments);

        var apiExpectsLimit = this.props.limit;
        var apiExpectsArray = _.isArray(this.props.data_type);

        var value = _.flowRight(function (value) {
            return apiExpectsArray ? value : _.get(value, 0, null);
        }, function (value) {
            return value.length ? value : null;
        }, function (value) {
            return value.slice(0, apiExpectsLimit);
        }, _.castArray)(dataTypeFormattedRawValue);

        return value;
    }
};

module.exports = fieldOptionsMixin;