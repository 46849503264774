'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactColor = require('react-color');

var ReactColor = _interopRequireWildcard(_reactColor);

var _color = require('react-color/lib/helpers/color');

var _color2 = _interopRequireDefault(_color);

var _RaisedButton = require('material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _Popover = require('material-ui/Popover');

var _Popover2 = _interopRequireDefault(_Popover);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _clear = require('material-ui/svg-icons/content/clear');

var _clear2 = _interopRequireDefault(_clear);

var _colorLens = require('material-ui/svg-icons/image/color-lens');

var _colorLens2 = _interopRequireDefault(_colorLens);

var _parseColor = require('parse-color');

var _parseColor2 = _interopRequireDefault(_parseColor);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var path = require('path');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label;

var PropTypes = _react2.default.PropTypes;

var styles = process.browser ? require('./ColorPicker.cssModule').default : {};


var fallbackColorLensColor = 'black';

var ColorPicker = _react2.default.createClass({
    displayName: 'ColorPicker',

    propTypes: {
        label: PropTypes.string,
        value: PropTypes.string,
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        id: PropTypes.string,
        actions: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null
        };
    },
    _onTextChange: function _onTextChange(event) {
        this.props.actions.setFieldValueByKey(event.target.value, this.props.fieldKey);
    },
    getInitialState: function getInitialState() {
        return {
            displayColorPicker: false
        };
    },
    handleClick: function handleClick(event) {
        this.setState({ anchorEl: event.currentTarget });
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    },
    handleClose: function handleClose() {
        this.setState({ displayColorPicker: false });
    },
    handleChange: function handleChange(color) {
        var rgba = color.rgb;
        var newValue = ''.concat('rgba(', ['r', 'g', 'b', 'a'].map(function (component) {
            return rgba[component];
        }).join(','), ')');
        this.props.actions.setFieldValueByKey(newValue, this.props.fieldKey);
    },
    _parseColor: function _parseColor(input) {
        return (0, _parseColor2.default)(input || this.props.default || 'rgba(255,255,255,1)').rgba;
    },
    _getPerceivedBrightness_forRGBA: function _getPerceivedBrightness_forRGBA(red, green, blue) {
        var alpha = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;


        // approximate background as white ( the white/gray checker box )
        var backgroundRGB = {
            red: 255,
            green: 255,
            blue: 255

            // use alpha to interpolate selected color to the background color to get apparent color
        };var alphaRed = red + (1 - alpha) * (backgroundRGB.red - red);
        var alphaGreen = green + (1 - alpha) * (backgroundRGB.green - green);
        var alphaBlue = blue + (1 - alpha) * (backgroundRGB.blue - blue);

        // calculate perceived brightness based on:
        // http://www.nbdtech.com/Blog/archive/2008/04/27/Calculating-the-Perceived-Brightness-of-a-Color.aspx
        return Math.sqrt(alphaRed * alphaRed * 0.241 + alphaGreen * alphaGreen * .691 + alphaBlue * alphaBlue * 0.068);
    },
    _getHighContrastColor_forRGBA: function _getHighContrastColor_forRGBA(red, green, blue, alpha) {
        var fallbackColor = 'black';
        if (isNaN(red) || isNaN(green) || isNaN(blue) || isNaN(alpha)) {
            return fallbackColor;
        }
        var perceivedBrightness = this._getPerceivedBrightness_forRGBA(red, green, blue, alpha);

        if (perceivedBrightness > 255 / 2) {
            return 'black';
        } else {
            return 'white';
        }
    },
    _clearValue: function _clearValue() {
        this.props.actions.setFieldValueByKey(null, this.props.fieldKey);
    },
    render: function render() {
        var _extends2,
            _React$createElement,
            _React$createElement2,
            _React$createElement3,
            _React$createElement4,
            _React$createElement5,
            _React$createElement6,
            _React$createElement7,
            _this = this,
            _extends3,
            _React$createElement8,
            _React$createElement9;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var self = this;
        var labelProps = _.pick(this.props, Object.keys(Label.propTypes));
        var parsedColor = self._parseColor(self.props.value);
        var TargetColorPicker = ReactColor[this.props.ReactColorComponent];
        var targetColor = this.props.value || 'rgba(255,255,255,1)';

        var isInfoMode = this._isInfoMode();
        var isLocked = this._isLocked();

        return _react2.default.createElement(
            'div',
            (_React$createElement9 = { className: styles.wrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'ColorPicker'
            }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'ColorPicker'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'ColorPicker'), _React$createElement9),
            _react2.default.createElement(
                'label',
                (_React$createElement = { className: styles.label, 'data-codecept-selector-node': 'label',
                    'data-codecept-selector-file': 'ColorPicker'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ColorPicker'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ColorPicker'), _React$createElement),
                _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                    'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'ColorPicker'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ColorPicker'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ColorPicker'), _extends2)))
            ),
            _react2.default.createElement(
                'div',
                (_React$createElement6 = { className: styles.buttonWrapper, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'ColorPicker'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'ColorPicker'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'ColorPicker'), _React$createElement6),
                _react2.default.createElement(_RaisedButton2.default, (_React$createElement3 = {
                    onTouchTap: this.handleClick,
                    disabled: self.props.locked,
                    style: {
                        background: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC") left center'
                    },
                    disabledBackgroundColor: self.props.value,
                    backgroundColor: self.props.value,
                    icon: _react2.default.createElement(_colorLens2.default, (_React$createElement2 = {
                        color: Array.isArray(parsedColor) && self._getHighContrastColor_forRGBA.apply(self, _toConsumableArray(parsedColor)) || fallbackColorLensColor,
                        'data-codecept-selector-node': 'ColorLens',
                        'data-codecept-selector-file': 'ColorPicker'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'ColorLens'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ColorPicker'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'ColorLens'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ColorPicker'), _React$createElement2)),
                    'data-codecept-selector-node': 'RaisedButton',
                    'data-codecept-selector-file': 'ColorPicker'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ColorPicker'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ColorPicker'), _React$createElement3)),
                this.props.value && !isInfoMode ? _react2.default.createElement(
                    _IconButton2.default,
                    (_React$createElement5 = {
                        onTouchTap: this._clearValue,
                        'data-codecept-selector-node': 'IconButton',
                        'data-codecept-selector-file': 'ColorPicker'
                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ColorPicker'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ColorPicker'), _React$createElement5),
                    _react2.default.createElement(_clear2.default, (_React$createElement4 = {
                        'data-codecept-selector-node': 'ContentClear',
                        'data-codecept-selector-file': 'ColorPicker'
                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ContentClear'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ColorPicker'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ContentClear'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ColorPicker'), _React$createElement4))
                ) : null
            ),
            _react2.default.createElement(
                'div',
                (_React$createElement7 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'ColorPicker'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'ColorPicker'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'ColorPicker'), _React$createElement7),
                this.props.error ? this.props.error.message : null
            ),
            _react2.default.createElement(
                _Popover2.default,
                (_React$createElement8 = {
                    open: self.state.displayColorPicker,
                    anchorEl: self.state.anchorEl,
                    anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
                    targetOrigin: { horizontal: 'left', vertical: 'top' },
                    useLayerForClickAway: true,
                    onRequestClose: function onRequestClose() {
                        return _this.setState({ displayColorPicker: false });
                    },
                    'data-codecept-selector-node': 'Popover',
                    'data-codecept-selector-file': 'ColorPicker'
                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Popover'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'ColorPicker'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Popover'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'ColorPicker'), _React$createElement8),
                _react2.default.createElement(TargetColorPicker, _extends({}, this.props, (_extends3 = { color: targetColor, onChange: this.handleChange,
                    ref: function ref(picker) {
                        if (picker && isInfoMode) {
                            picker.setState(_color2.default.toState(targetColor));

                            picker.handleChange = function (data, event) {
                                logger.debug('ignoring handleChange', data, event);
                            };
                        }
                    },
                    'data-codecept-selector-node': 'TargetColorPicker',
                    'data-codecept-selector-file': 'ColorPicker'
                }, _defineProperty(_extends3, 'data-codecept-selector-node', 'TargetColorPicker'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ColorPicker'), _defineProperty(_extends3, 'data-codecept-selector-node', 'TargetColorPicker'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ColorPicker'), _extends3)))
            )
        );
    },
    _isInfoMode: function _isInfoMode() {
        var mode = this.props.mode;


        return mode === 'info';
    },
    _isLocked: function _isLocked() {
        return this.props.locked || this.props.disabled;
    }
});

exports.default = ColorPicker;