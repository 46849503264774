'use strict';

var _ = require('lodash');
var rubyLodash = require('@rubyapps/ruby-lodash');

function getUniqueTopLevelKeysFromDynamicFormOptions(options) {
    // first flatten the dynamic form options into a single array of options for easier iteration
    var optionValues_asFlatArray = _.flatten(options.map(function (option) {
        return _.get(option, 'value') || [];
    }));

    // then walk each option value to get the top level keys and accumulate them
    var topLevelKeys_fromOptionValues = optionValues_asFlatArray.reduce(function (keyCollector, optionValue) {
        return keyCollector.concat(getTopLevelKeysForOptionValue(optionValue));
    }, []).filter(function (key) {
        return !_.isNil(key);
    });

    return _.uniq(topLevelKeys_fromOptionValues);
}

// walk an option value depth-first to get the top level keys
// once a key is found at a node, then you have obtained a top level key, so no need to walk its children
function getTopLevelKeysForOptionValue(optionValue) {
    // first check the current node for a key
    if (optionValue.key) {
        return [optionValue.key];
    }

    // then check the children nodes for keys
    return rubyLodash.depthFirstReduce(optionValue, function (keyCollectorForOptionValue, node) {
        return node.key ? keyCollectorForOptionValue.concat(node.key) : keyCollectorForOptionValue;
    }, [], function (node) {
        return node.key ? [] : node.children;
    });
}

module.exports = {
    getUniqueTopLevelKeysFromDynamicFormOptions: getUniqueTopLevelKeysFromDynamicFormOptions,
    getTopLevelKeysForOptionValue: getTopLevelKeysForOptionValue
};