'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _ActivityListerConnector = require('./reactComponents/ActivityListerConnector');

var _ActivityListerConnector2 = _interopRequireDefault(_ActivityListerConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var inflection = require('inflection');
var urljoin = require('url-join');
var Route = require('route-parser');


var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var ACTIVITY_TYPES = CONSTANTS.ACTIVITY_TYPES;

var componentName = CONSTANTS.COMPONENT_NAME;

var accessControlMixin = require('@rubyapps/ruby-component-mixin-access-control');
var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var RubyComponentCurrentUser = require('@rubyapps/ruby-component-current-user');
var RubyComponentContentCompare = require('@rubyapps/ruby-component-content-compare');
var RubyComponentForms = require('@rubyapps/ruby-component-forms');

var RubyComponentFrontendSettings = require('@rubyapps/ruby-component-frontend-settings');

var rubyTinyMCEDefaultOptions = require('@rubyapps/ruby-component-field-rich-text-editor/src/client/coreConfig').default;

var moment = require('@rubyapps/friendly-ruby-moment');

var RCActivityLister = RubyComponent.createClass({
    CONSTANTS: CONSTANTS,
    mixins: [baseFieldMixin, fieldPropsMixin, accessControlMixin, require('./mixin/index')],
    propTypes: {
        rteMode: PropTypes.string,
        rteConfig: PropTypes.object,
        wordDocGenerationTimeoutInSec: PropTypes.number,
        hidePreviewLinks: PropTypes.bool,
        hideRevertLinks: PropTypes.bool
    },
    getDefaultProps: function getDefaultProps() {
        return {
            formsID: 'rubyComponentForms',
            activityEndpointRoute: new Route('/activities/:id')
            // TODO: till we figure this out...
            , rteMode: 'activities',
            rteConfig: _extends(rubyTinyMCEDefaultOptions, {
                plugins: 'link',
                toolbar1: 'undo redo | link | bold italic | removeformat',
                toolbar2: null,
                toolbar3: null,
                rubytest: true,
                menubar: false,
                content_style: "p {font-family: Roboto, sans-serif; font-size: 14px;}",
                default_link_target: "_blank"
            }),
            wordDocGenerationTimeoutInSec: 10 * 60,
            hidePreviewLinks: false,
            hideRevertLinks: false,
            apiKey: 'content',
            templateKey: {
                path: '< [getTemplateKey]',
                method: ['getTemplateKey']
            },
            instanceDataEndpoint: {
                path: '< .rubyComponentEditPage',
                method: ['getStateAtKeypath', 'instanceDataEndpoint']
            },
            excludeFromNamespaces: true
        };
    },
    componentName: componentName,
    dependencies: function dependencies() {
        var selfModule = this;
        var rootModule = this.getRoot();
        var selfSelector = this.getSelfStateSelector();

        var currentUserID = this.props.currentUserID || RubyComponentCurrentUser.CONSTANTS.COMPONENT_NAME;
        var currentUser = rootModule.findDescendentByID(currentUserID);
        var currentUserSelector = currentUser.getSelfStateSelector();

        var frontendSettingsID = this.props.frontendSettingsID || RubyComponentFrontendSettings.CONSTANTS.COMPONENT_NAME;
        var frontendSettingsComponent = rootModule.findDescendentByID(frontendSettingsID);
        var frontendSettingsSelector = frontendSettingsComponent.getDefaultSelector();

        var contentCompareID = this.props.contentCompareID || RubyComponentContentCompare.CONSTANTS.COMPONENT_NAME;
        var contentCompare = rootModule.findDescendentByID(contentCompareID);

        var editPage = this.findAncestorBy(function (ancestor) {
            return ancestor.saveChanges_usingOptions;
        });
        var editPageSelector = editPage && editPage.getSelfStateSelector();

        var routeParent = this.findAncestorBy(function (module) {
            return module.getRouteElement;
        });

        var parentForm = this.findAncestorBy(function (ancestor) {
            return ancestor.componentName === 'rubyComponentFieldForm';
        });

        var selfAction = this.getAction();

        var formsID = this.props.formsID || RubyComponentForms.CONSTANTS.COMPONENT_NAME;
        var forms = rootModule.findDescendentByID(formsID);

        function checkShouldDisableSave() {
            return parentForm.shouldDisableSave();
        }

        return {
            selfSelector: selfSelector,
            selfAction: selfAction,
            forms: forms,
            editPage: editPage,
            editPageSelector: editPageSelector,
            routeParent: routeParent,
            parentForm: parentForm,
            currentUserSelector: currentUserSelector,
            frontendSettingsComponent: frontendSettingsComponent,
            frontendSettingsSelector: frontendSettingsSelector,
            contentCompare: contentCompare,
            checkShouldDisableSave: checkShouldDisableSave
        };
    },
    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector,
            currentUserSelector = _getDependencies.currentUserSelector,
            frontendSettingsSelector = _getDependencies.frontendSettingsSelector;

        return {
            self: selfSelector(state),
            user: currentUserSelector(state),
            frontendSettings: frontendSettingsSelector(state),
            routing: state.routing //# need to update based on routing changes
        };
    },
    getReactClass: function getReactClass() {
        return _ActivityListerConnector2.default.call(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var ActivityLister = this.getReactClass();

        return React.createElement(ActivityLister, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'ActivityLister',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'ActivityLister'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'ActivityLister'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    onReduxInit: function onReduxInit(dispatch) {
        var _this = this;

        var store = this.getStore();

        var _getDependencies2 = this.getDependencies(),
            selfAction = _getDependencies2.selfAction,
            parentForm = _getDependencies2.parentForm,
            routeParent = _getDependencies2.routeParent,
            frontendSettingsSelector = _getDependencies2.frontendSettingsSelector;

        var actions = selfAction.generators;

        return store.subscribe(function () {
            var applicationState = store.getState();
            var routeParams = _lodash2.default.result(routeParent, 'getState.routeParams', {});
            if (!routeParams.routeActive) {
                return;
            }
            var prevPageID = _this._pageID;

            var idFromRoute = routeParams.id;
            var nextPageID = idFromRoute;

            var frontendSettings = frontendSettingsSelector(applicationState);
            var restApiRoot = frontendSettings.restApiRoot;

            var activitiesListEndpoint = urljoin(restApiRoot, _this.props.apiKey, nextPageID, '/activities'); //editPageEndpoint + '/activities';

            var activitiesEndpoint = urljoin(restApiRoot, _this.props.apiKey, nextPageID, '/activity'); //editPageEndpoint + '/activities';

            var parentFormState = parentForm.getState();
            var prevParentFormTimestamp = _this._parentFormTimestamp;
            var nextParentFormTimestamp = parentFormState.forceUpdateTimestamp;

            var pageChangeFlag = nextPageID != prevPageID && nextPageID !== undefined;
            var formPersistFlag = nextParentFormTimestamp != prevParentFormTimestamp && nextParentFormTimestamp; // was the form just saved?

            if (pageChangeFlag || formPersistFlag && nextPageID) {
                _this._pageID = nextPageID;
                _this._parentFormTimestamp = nextParentFormTimestamp;

                //dispatch(actions.retrieveActivities_withEndpoint(activitiesEndpoint));
                if (pageChangeFlag) {
                    dispatch(actions.resetShowAll());
                }
                dispatch(actions.setActivitiesEndpoint(activitiesEndpoint));
                dispatch(actions.setActivitiesListEndpoint(activitiesListEndpoint));
                dispatch(actions.refreshActivitiesList());
            }
        });
    },

    getBasisVersion: function getBasisVersion() {
        var selfState = this.getState();

        var activities = this.utils.getActivitiesFromSelfState(selfState);

        return this.utils.getBasisVersionFromActivityList(activities);
    },
    utils: {
        getActivitiesFromSelfState: function getActivitiesFromSelfState(selfState) {
            var activitiesById = selfState.activitiesById,
                activitiesIdArray = selfState.activitiesIdArray;


            return activitiesIdArray.map(function (activityId) {
                return activitiesById[activityId];
            });
        },
        getBasisVersionFromActivityList: function getBasisVersionFromActivityList(activities) {
            if (!activities) {
                return undefined;
            }
            return activities.reduce(function (mostRecentVersion, activity) {
                var isRevisionActivity = (activity.activity_type === CONSTANTS.ACTIVITY_TYPES.REVISION_ACTIVITY || activity.activity_type === CONSTANTS.ACTIVITY_TYPES.APPROVAL_APPROVE_ACTIVITY) && activity.version_num && activity.version_data_id;
                var isMostRecent = mostRecentVersion.versionNumber === undefined || activity.version_num > mostRecentVersion.versionNumber;
                if (isRevisionActivity && isMostRecent) {
                    mostRecentVersion.versionNumber = activity.version_num;
                    mostRecentVersion.versionDataId = activity.version_data_id;
                }
                return mostRecentVersion;
            }, {});
        }
    },
    getWordDocGenerationStatus_fromActivity: function getWordDocGenerationStatus_fromActivity(activity) {
        var activityType = activity.activity_type;

        var isWordDocGenerationActivity = activityType == ACTIVITY_TYPES.WORDDOC_GENERATION_ACTIVITY;

        var hasWordDocLocation = _lodash2.default.get(activity, ['details', 'word_download_url'], false);

        var nowTime = moment();
        var createdTime = moment(activity.created_timestamp);
        var secSinceCreation = nowTime.diff(createdTime, 'seconds');
        var timedOut = secSinceCreation > this.props.wordDocGenerationTimeoutInSec;

        if (isWordDocGenerationActivity && !hasWordDocLocation && !timedOut) {
            return CONSTANTS.STATUS_TYPES.PROCESSING;
        } else if (isWordDocGenerationActivity && !hasWordDocLocation && timedOut) {
            return CONSTANTS.STATUS_TYPES.ERROR;
        } else if (isWordDocGenerationActivity && hasWordDocLocation) {
            return CONSTANTS.STATUS_TYPES.COMPLETE;
        }
    },
    shouldPollActivityForWordDownload: function shouldPollActivityForWordDownload(activity) {
        var wordDocGenStatus = this.getWordDocGenerationStatus_fromActivity(activity);
        return wordDocGenStatus === CONSTANTS.STATUS_TYPES.PROCESSING;
    },
    shouldPollActivity: function shouldPollActivity(activity) {
        return _lodash2.default.some([this.shouldPollActivityForWordDownload(activity)]);
    },

    getActivitiesByIdToPoll: function getActivitiesByIdToPoll(activities) {
        var _this2 = this;

        return activities.reduce(function (collector, activity, id) {
            //# TODO: check whether activity should be polled
            if (_this2.shouldPollActivity(activity)) {
                collector[activity.id] = true;
            }
            return collector;
        }, {});
    }
});

module.exports = RCActivityLister;