'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var WrappedMenuItem = _react2.default.createClass({
    displayName: 'WrappedMenuItem',

    propTypes: {
        id: _react.PropTypes.string,
        onMouseEnter: _react.PropTypes.func,
        onMouseLeave: _react.PropTypes.func,
        onFocusStateChange: _react.PropTypes.func,
        menuItemProps: _react.PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            focusState: 'none'
        };
    },
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        if (nextProps.focusState != this.props.focusState) {
            //# NOTE: this doesn't work for now because setting this causes
            //# a rerender that causes the focusState to change for some reason
            //# this.props.onFocusStateChange && this.props.onFocusStateChange(nextProps.focusState, this.props.focusState);
        }
    },
    render: function render() {
        var _extends2, _React$createElement;

        return _react2.default.createElement(
            'div',
            (_React$createElement = {
                onMouseEnter: this.props.onMouseEnter,
                onMouseLeave: this.props.onMouseLeave,
                style: this.props.style,
                className: 'WrappedMenuItem',
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'WrappedMenuItem'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'WrappedMenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'WrappedMenuItem'), _React$createElement),
            _react2.default.createElement(_MenuItem2.default, _extends({}, _lodash2.default.omit(this.props, ['onMouseEnter', 'onMouseLeave', 'id', 'menuItemProps', 'children']), this.props.menuItemProps || {}, (_extends2 = {
                'data-codecept-selector-node': 'MenuItem',
                'data-codecept-selector-file': 'WrappedMenuItem'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends2, 'data-codecept-selector-file', 'WrappedMenuItem'), _defineProperty(_extends2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends2, 'data-codecept-selector-file', 'WrappedMenuItem'), _extends2))),
            this.props.children
        );
    }
});

exports.default = WrappedMenuItem;