'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Card = require('material-ui/Card');

var _colors = require('@rubyapps/ruby-styles/src/client/styleObjects/colors');

var _reactChartjs = require('react-chartjs-2');

var _styleObject = require('./styleObject');

var _styleObject2 = _interopRequireDefault(_styleObject);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var PieChart = _react2.default.createClass({
    displayName: 'PieChart',

    propTypes: {
        label: PropTypes.string,
        id: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.shape({}))
    },
    render: function render() {
        var _React$createElement4, _extends2, _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8;

        var total = _lodash2.default.get(this, 'props.data.total');
        var rawRows = _lodash2.default.get(this, 'props.data.rows');
        var reportedTotal = rawRows.reduce(function (result, row) {
            return result + row.value;
        }, 0);
        var remainingTotal = total - reportedTotal;
        var rows = [].concat(rawRows, {
            label: "Other",
            value: remainingTotal
        });

        var Legend = _react2.default.createElement(
            'ul',
            (_React$createElement4 = {
                style: {
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    height: 60,
                    alignItems: 'flex-start',
                    alignContent: 'center',
                    paddingTop: 5
                },
                'data-codecept-selector-node': 'ul',
                'data-codecept-selector-file': 'PieChart'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'PieChart'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'PieChart'), _React$createElement4),
            rows.map(function (_ref, idx) {
                var _React$createElement, _React$createElement2, _React$createElement3;

                var label = _ref.label;

                var color = _colors.categoryColors[idx];
                return _react2.default.createElement(
                    'li',
                    (_React$createElement3 = {
                        style: {
                            display: 'flex',
                            alignItems: 'center'
                        },
                        'data-codecept-selector-node': 'li',
                        'data-codecept-selector-file': 'PieChart'
                    }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'PieChart'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'PieChart'), _React$createElement3),
                    _react2.default.createElement('span', (_React$createElement = {
                        style: {
                            backgroundColor: color,
                            width: 20,
                            height: 16,
                            marginRight: 8,
                            marginTop: 4,
                            display: 'inline-block'
                        },
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'PieChart'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'PieChart'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'PieChart'), _React$createElement)),
                    _react2.default.createElement(
                        'span',
                        (_React$createElement2 = {
                            style: {
                                marginRight: 8
                            },
                            'data-codecept-selector-node': 'span',
                            'data-codecept-selector-file': 'PieChart'
                        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'PieChart'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'PieChart'), _React$createElement2),
                        label
                    )
                );
            })
        );

        return _react2.default.createElement(
            _Card.Card,
            (_React$createElement8 = { containerStyle: { height: "100%" }, 'data-codecept-selector-node': 'Card',
                'data-codecept-selector-file': 'PieChart'
            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'PieChart'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'PieChart'), _React$createElement8),
            _react2.default.createElement(_Card.CardHeader, _extends({
                title: this.props.label,
                subtitle: this.props.subtitle
            }, _styleObject2.default.CardHeader, (_extends2 = {
                'data-codecept-selector-node': 'CardHeader',
                'data-codecept-selector-file': 'PieChart'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'PieChart'), _defineProperty(_extends2, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'PieChart'), _extends2))),
            _react2.default.createElement(
                _Card.CardText,
                (_React$createElement7 = { style: { display: 'flex', flexDirection: 'column' }, 'data-codecept-selector-node': 'CardText',
                    'data-codecept-selector-file': 'PieChart'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'PieChart'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'PieChart'), _React$createElement7),
                _react2.default.createElement(
                    'div',
                    (_React$createElement6 = { style: { display: 'flex', justifyContent: 'center' }, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'PieChart'
                    }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'PieChart'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'PieChart'), _React$createElement6),
                    _react2.default.createElement(_reactChartjs.Pie, (_React$createElement5 = {
                        height: 260,
                        width: 260,
                        data: {
                            datasets: [{
                                data: rows.map(function (dataPoint) {
                                    return _lodash2.default.get(dataPoint, 'value');
                                }),
                                backgroundColor: _colors.categoryColors
                            }],
                            labels: rows.map(function (dataPoint) {
                                return _lodash2.default.get(dataPoint, 'label');
                            })
                        },
                        options: {
                            responsive: false,
                            legend: false,
                            tooltips: {
                                callbacks: {
                                    title: function title(tooltipItems, data) {
                                        var idx = _lodash2.default.get(tooltipItems, '0.index');
                                        var label = _lodash2.default.get(data, ['labels', idx]);
                                        return label;
                                    },
                                    label: function label(tooltipItem, data) {
                                        var idx = tooltipItem.index;
                                        var value = _lodash2.default.get(data, ['datasets', 0, 'data', idx]);
                                        return value.toLocaleString();
                                    },
                                    afterLabel: function afterLabel(tooltipItem, data) {
                                        var idx = tooltipItem.index;
                                        var value = _lodash2.default.get(data, ['datasets', 0, 'data', idx]);
                                        var percent = (value / total * 100).toFixed(1);
                                        return percent + '%';
                                    }
                                }
                            }
                        },
                        'data-codecept-selector-node': 'Pie',
                        'data-codecept-selector-file': 'PieChart'
                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Pie'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'PieChart'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Pie'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'PieChart'), _React$createElement5))
                ),
                Legend
            )
        );
    }
});

exports.default = PieChart;