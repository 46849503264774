'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var styles = {
    RaisedButton: {
        marginRight: '10px'
    }
};

exports.default = styles;