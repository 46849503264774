'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSimpleNormalizedData = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.columnPropertiesFromArray = columnPropertiesFromArray;
exports.buildColumnProperties = buildColumnProperties;

var _reselect = require('reselect');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

//# custom normalizer function because:
//# 1 - normalizr is not very performant and it does more than we need ( it handles multiple data types )
//# 2 - we already have perfomance issues with rendering large lists
var getSimpleNormalizedData = exports.getSimpleNormalizedData = (0, _reselect.createSelector)([function (state) {
  return (_lodash2.default.get(state, 'data') || []).concat(_lodash2.default.get(state, 'staticData') || []);
}, function (state) {
  return _lodash2.default.get(state, 'idColumn') || 'id';
}, function (state) {
  return _lodash2.default.get(state, 'renderProperties.rowProperties.childColumnName') || 'children';
}], function (allRowData, idKey, childrenKey) {
  var flattenedData = allRowData.reduce(function flattenReducingFunc(result, curr) {
    var children = curr[childrenKey] || [];
    return result.concat(_extends({}, curr, { children: children.map(function (child) {
        return child[idKey];
      }) }), children.reduce(flattenReducingFunc, []));
  }, []);
  var normalizedData = {
    dataById: _lodash2.default.keyBy(flattenedData, idKey),
    rootIds: allRowData.map(function (rowData) {
      return _lodash2.default.get(rowData, idKey);
    })
  };
  return normalizedData;
});

//TODO: Move most of this functionality to the component or something like that :|
function columnPropertiesFromArray(columns) {
  //TODO: Make this more efficient -- this is just kind of make it work at this point
  var properties = {};
  columns.forEach(function (column) {
    return properties[column] = { id: column };
  });

  return properties;
}

function buildColumnProperties(_ref) {
  var rowProperties = _ref.rowProperties,
      allColumns = _ref.allColumns,
      defaultColumns = _ref.defaultColumns;

  var columnProperties = defaultColumns ? columnPropertiesFromArray(defaultColumns) : {};

  if (rowProperties && rowProperties.props && !!rowProperties.props.children && Array.isArray(rowProperties.props.children)) {
    columnProperties = rowProperties.props.children.reduce(function (previous, current) {
      previous[current.props.id] = current.props;return previous;
    }, columnProperties);
  } else if (rowProperties && rowProperties.props && rowProperties.props.children) {
    //if just an object
    columnProperties[rowProperties.props.children.props.id] = rowProperties.props.children.props;
  }

  //TODO: Don't check this this way :|
  if (Object.keys(columnProperties).length === 0 && allColumns) {
    columnProperties = columnPropertiesFromArray(allColumns);
  }

  return columnProperties;
}

var PropertyHelper = {
  propertiesToJS: function propertiesToJS(_ref2) {
    var rowProperties = _ref2.rowProperties,
        allColumns = _ref2.allColumns,
        defaultColumns = _ref2.defaultColumns,
        _ref2$ignoredColumns = _ref2.ignoredColumns,
        ignoredColumns = _ref2$ignoredColumns === undefined ? [] : _ref2$ignoredColumns;

    var getHiddenColumns = function getHiddenColumns(columnProperties) {
      var visibleKeys = Object.keys(columnProperties);
      var hiddenColumns = allColumns.filter(function (column) {
        return visibleKeys.indexOf(column) < 0;
      });

      var hiddenColumnProperties = {};
      hiddenColumns.forEach(function (column) {
        return hiddenColumnProperties[column] = { id: column };
      });

      return hiddenColumnProperties;
    };

    var ignoredColumnsWithChildren = ignoredColumns.indexOfChildren > -1 ? ignoredColumns : [].concat(_toConsumableArray(ignoredColumns), ['children']);
    //if we don't have children return an empty metatdata object
    if (!rowProperties) {
      var _columnProperties = columnPropertiesFromArray(defaultColumns || allColumns);
      var _hiddenColumnProperties = getHiddenColumns(_columnProperties);

      return {
        rowProperties: null,
        columnProperties: _columnProperties,
        ignoredColumns: ignoredColumnsWithChildren,
        hiddenColumnProperties: _hiddenColumnProperties
      };
    }
    var columnProperties = buildColumnProperties({ rowProperties: rowProperties, allColumns: allColumns, defaultColumns: defaultColumns });

    var rowProps = _extends({}, rowProperties.props);
    delete rowProps.children;

    if (!rowProps.hasOwnProperty('childColumnName')) {
      rowProps.childColumnName = 'children';
    }

    var hiddenColumnProperties = getHiddenColumns(columnProperties);

    //make sure that children is in the ignored column list

    return {
      rowProperties: rowProps,
      columnProperties: columnProperties,
      hiddenColumnProperties: hiddenColumnProperties,
      ignoredColumns: ignoredColumnsWithChildren,
      metadataColumn: '__metadata'
    };
  }
};

exports.default = PropertyHelper;