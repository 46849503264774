'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _RaisedButton = require('material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var path = require('path');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

var _ = require('lodash');


var GriddleSelectionPlugin = require('@rubyapps/griddle').GriddleSelectionPlugin;

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var tokenTaggerListerMixin = {
    mixinName: 'rubyComponentMixinListerTokenTagger',
    propTypes: {},
    getDefaultProps: function getDefaultProps() {
        return {};
    },
    isTokenTaggerSelectionEnabled: function isTokenTaggerSelectionEnabled() {
        return this.props.mode == 'select';
    },
    getGriddlePluginsAugmentor_duringMergeProps: function getGriddlePluginsAugmentor_duringMergeProps(stateProps, dispatchProps, ownProps) {
        var _this = this;

        var selfModule = this;
        return function () {
            var parentPlugins = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];


            var enableSelection = _this.isTokenTaggerSelectionEnabled();

            if (!enableSelection) {
                return parentPlugins;
            }

            var parentComponent = _this.getParent();
            var fieldValue = parentComponent.getFieldValue();

            var foundOptions = parentComponent.getOptionsMatchingOptionValue(fieldValue);

            var foundOptionsById = _.keyBy(foundOptions, 'value');
            var foundOptionsOrder = foundOptions.map(_.property('value'));

            return [].concat(GriddleSelectionPlugin(undefined, {
                initialSelectedRecords: foundOptionsById,
                initialSelectionOrder: foundOptionsOrder,
                limit: parentComponent.props.limit
            }), parentPlugins);
        };
    }
    /* //# preferring the header button instead of nesting the insert in the context menu
    , getUtilityBarActionsAugmentor_usingUtilityBarProps: function( griddleInstance ) {
        const selfModule = this;
          return (parentActions = []) => {
             griddleInstance, parentActions; //# griddleInstane.plugins.*
             const enableSelection = this.isTokenTaggerSelectionEnabled();
            if (!enableSelection) {
                return parentActions;
            }
             const {
                data
                , selectedRecords
            } = griddleInstance;
             const selectedRecordsIds = Object.keys(selectedRecords);
            const selectionCount = selectedRecordsIds.length;
             //# generate the handler from modeActionSpec
            const hydratedModeActionSpec = selfModule.props.modeActionSpec();
              const options = [
                {
                    key: 'insert'
                    , primaryText: 'Insert'
                    , disabled: selectionCount < 1
                    , onTouchTap: (event) => {
                         hydratedModeActionSpec.handler(event, selectedRecordsIds, _.values(selectedRecords));
                    }
                }
            ]
             return [].concat(
                parentActions
                , options
            );
        }
    }
    */

    , additionalHeaderToolbarItems: function additionalHeaderToolbarItems(griddleProps, rubyComponentProps) {
        var _extends2;

        var sibling__mixin = _.last(this._flattenedMixins.filter(function (mixin) {
            var mixin__additionalHeaderToolbarItems = mixin.additionalHeaderToolbarItems;

            return mixin__additionalHeaderToolbarItems && mixin__additionalHeaderToolbarItems != tokenTaggerListerMixin.additionalHeaderToolbarItems;
        }));

        var toolbarItems = sibling__mixin ? sibling__mixin.additionalHeaderToolbarItems.apply(this, arguments) : [];

        var hydratedModeActionSpec = this.props.modeActionSpec();

        var data = griddleProps.data,
            _griddleProps$selecte = griddleProps.selectedRecords,
            selectedRecords = _griddleProps$selecte === undefined ? {} : _griddleProps$selecte,
            _griddleProps$selecti = griddleProps.selectionOrder,
            selectionOrder = _griddleProps$selecti === undefined ? [] : _griddleProps$selecti;


        var selectedRecordsIds = selectionOrder;
        var orderedRecords = selectionOrder.map(function (id) {
            return selectedRecords[id];
        });

        var selectionCount = selectedRecordsIds.length;

        return toolbarItems.concat(_react2.default.createElement(_RaisedButton2.default, _extends({}, hydratedModeActionSpec, (_extends2 = {
            style: { minWidth: 114, marginLeft: 24 },
            secondary: true,
            disabled: selectionCount < 1,
            onTouchTap: function onTouchTap(event) {
                hydratedModeActionSpec.handler(event, selectedRecordsIds, orderedRecords);
            },
            'data-codecept-selector-node': 'RaisedButton',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2))));
    }
};

module.exports = tokenTaggerListerMixin;