'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _ViewLinkConnector = require('./reactComponents/ViewLinkConnector');

var _ViewLinkConnector2 = _interopRequireDefault(_ViewLinkConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var FieldExpandedData = require('@rubyapps/ruby-component-field-expanded-data');
var RubyComponentFrontendSettings = require('@rubyapps/ruby-component-frontend-settings');

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var action = require('./action');

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');

/*
 *  props: {
 *      action: PropTypes.string - the name of the action on the closest ruby-component-field-form that the button should trigger
 *      action: PropTypes.shape({
 *          path: PropTypes.string - selector path for selection to node that should call on the action
 *          , action: PropTypes.string - the name of the action to trigger
 *      })
 *  }
 * */
var RCViewLink = RubyComponent.createClass({
    mixins: [baseFieldMixin],
    propTypes: {
        label: PropTypes.string
    },
    componentName: componentName,
    action: action,
    getDefaultProps: function getDefaultProps() {
        return {
            link: {
                method: ['getStateAtKeypath', 'pristineData.url'],
                path: '#__url_poller_for_view' // '#__url_poller' is mounted in 'rubyComponentFieldGooglePreview'
            }
        };
    },
    children: function children() {
        var parentID = this.getID();

        return [RubyComponent.createElement(FieldExpandedData, {
            id: '__url_poller_for_view',
            watch: {
                interval: 5000
            },
            unwatch: {
                stopOnMatchLBFilter: {
                    where: {
                        url: { neq: null }
                    }
                }
            },
            url: '/ruby/api/v2/content/:id/getResourceLinkInfo',
            mode: 'control'
        })];
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();
        var selfAction = this.getAction();

        var editPage = this.findAncestorBy(function (ancestor) {
            return ancestor.componentName === 'rubyComponentEditPage';
        });
        var editPageSelector = editPage.getSelfStateSelector();

        var parentForm = this.findAncestorBy(function (ancestor) {
            return ancestor.componentName === 'rubyComponentFieldForm';
        });

        var rubyClientFKSelector = root.findDescendentByID('rubyComponentRubyClientFK').getDefaultSelector();

        return {
            selfSelector: selfSelector,
            selfAction: selfAction,
            editPageSelector: editPageSelector,
            parentForm: parentForm,
            rubyClientFKSelector: rubyClientFKSelector
        };
    },
    getReactClass: function getReactClass() {
        return _ViewLinkConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var ViewLinkComponent = this.getReactClass();

        return _react2.default.createElement(ViewLinkComponent, _extends({}, this.props.reactProps, (_extends2 = {
            'data-codecept-selector-node': 'ViewLinkComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'ViewLinkComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'ViewLinkComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    onReduxInit: function onReduxInit() /*dispatch*/{}
});

module.exports = RCViewLink;