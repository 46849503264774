'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactVirtualized = require('react-virtualized');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _require = require('./utils/styleHelper'),
    getStyleProperties = _require.getStyleProperties;
//import cellRangeRendererGenerator from './utils/cellRangeRenderer'; //# 20210802 - deprecating. Seems like 'additionalCells' introduces an exception
//# and we never really used it (when it works, the additional cells are empty)


var PROPS_TO_COMPARE = ['data', 'selectedRecords', 'batchMoveActive', 'utilityBarProperties', 'columns', 'state.renderedSizeProperties'];

var TableBody = (_temp = _class = function (_Component) {
    _inherits(TableBody, _Component);

    _createClass(TableBody, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            if (this.props.scrollElementSelector) {
                var selfNode = (0, _reactDom.findDOMNode)(this);
                var scrollElement = selfNode.closest(this.props.scrollElementSelector);
                this.setState({ scrollElement: scrollElement });
            }
        }
    }]);

    function TableBody(props, context) {
        _classCallCheck(this, TableBody);

        return _possibleConstructorReturn(this, (TableBody.__proto__ || Object.getPrototypeOf(TableBody)).call(this, props, context));
    }

    _createClass(TableBody, [{
        key: 'shouldComponentUpdate',
        value: function shouldComponentUpdate(nextProps) {
            var propsDiff = !_lodash2.default.isEqual(_lodash2.default.pick(this.props, PROPS_TO_COMPARE), _lodash2.default.pick(nextProps, PROPS_TO_COMPARE));

            //console.trace('[table-body] shouldComponentUpdate:', propsDiff, this.props.columns, nextProps.columns);

            return propsDiff;
        }

        /* //# 20210802 - deprecating since cellRangeRendererGenerator never really worked and the defaultCellRangeRenderer seems to be ok
        componentWillMount() {
            this._renderedIndicesMap = {};
            this._cellRangeRenderer = cellRangeRendererGenerator(this._renderedIndicesMap);
        }
        */

        //# NOTE: Seems like this only gets triggered when expandedRows is different
        //# but we're deleting all the rendered rows so it doesn't seem like _renderedIndicesMap is useful
        //# Disabling this for now.
        //# NOTE: that we have a custom cellRangeRenderer because of this
        //# which was actually introducing issues if we attempted to render additional rows
        //# so we should just use the defaultCellRangeRenderer
        /*
        componentWillReceiveProps(nextProps) {
             const pagePropertiesIsDifferent = !_.isEqual(this.props.pageProperties, nextProps.pageProperties)
             const expandedRowsIsDifferent = !_.isEqual(this.props.expandedRows, nextProps.expandedRows);
             if (pagePropertiesIsDifferent || expandedRowsIsDifferent) {
                //# NOTE: since we're just deleting everything, we should  overwrite the renderedIndicesMap
                //# since the rowRenderer will get called after anyways
                _.forEach(this._renderedIndicesMap, (_, key) => {
                    delete this._renderedIndicesMap[key];
                });
             }
        }
        */
        /*
        componentWillReceiveProps(nextProps) {
             const resizeChanged = !_.isEqual(
                _.get(nextProps, 'state.renderedSizeProperties')
                , _.get(this.props, 'state.renderedSizeProperties')
            );
            const dataChanged = !_.isEqual(
                _.get(nextProps, 'data').map(child => child.id)
                , _.get(this.props, 'data').map(child => child.id)
            );
            const columnsChanged = !_.isEqual(
                nextProps.data.map(data => _.omit(data,['__metadata']))
                , this.props.data.map(data => _.omit(data,['__metadata']))
            );
            if ( dataChanged ) {
                //# no need to clear data if we cache by id
                
                const prevIdSpec = this.props.data.map((data, index) => ({id: data.id, index}));
                const newIdSpec = nextProps.data.map((data, index) => ({id: data.id, index}));
                const idxsToClear = _.difference(prevIdSpec, newIdSpec).map(spec => spec.index);
                idxsToClear.forEach((idx) => {
                    this._cache.clear(idx);
                });
                
            }
            if ( resizeChanged || columnsChanged ) {
                //# TODO: need to look at the actual selected columns
                this._cache.clearAll();
            }
        }
        */
        //# NOTE: rowRenderer is for react-virtualized only
        //# so keep that in mind when working with this

    }, {
        key: 'rowRenderer',
        value: function rowRenderer(_ref) {
            var key = _ref.key,
                index = _ref.index,
                isScrolling = _ref.isScrolling,
                isVisible = _ref.isVisible,
                style = _ref.style,
                parent = _ref.parent;

            //console.log('=== rowRenderer', key, index, isScrolling, isVisible);

            //this._renderedIndicesMap[index] = true
            var self = this;

            var _props = this.props,
                columns = _props.columns,
                tableData = _props.data,
                loading = _props.loading,
                components = _props.components,
                styles = _props.styles,
                settings = _props.settings,
                events = _props.events,
                renderProperties = _props.renderProperties,
                tableProperties = _props.tableProperties;


            var rows = this._rows;
            var data = rows[index];

            var idKey = _lodash2.default.get(this, 'props.state.idColumn') || 'id';
            var id = _lodash2.default.get(data, idKey) || _lodash2.default.get(data, ['__metadata', idKey]);
            var originalRowData = _lodash2.default.get(this, ['props', 'normalizedData', 'dataById', id]) || _extends({}, data, data.__metadata);

            //# NOTE: self.props.state.data is the original visible row data before changing the structure for Griddle (so it's the top-level rows + nested children)
            //# however, we're rendering the rows flattened so we might not have the originalRowData
            //# so we need to fallback generating the original data from the griddle-formatted data

            //# NOTE: there's an issue with the indices

            //console.log(`[rowRenderer()] key: [${key}] index: [${index}] style:`, style)
            var newStyles = _lodash2.default.merge({}, styles, {
                inlineStyles: {
                    'row': style
                }
            });

            /*
            <CellMeasurer
                cache={self._cache}
                columnIndex={0}
                key={data.id}
                parent={parent}
                rowIndex={index}
            >
            </CellMeasurer>
            */

            return _react2.default.createElement(components.Row, {
                plugins: this.props.plugins,
                rowData: data,
                absoluteRowIndex: data.__metadata.index,
                key: 'row-' + index + '-' + data.id,
                columns: columns,
                components: components,
                events: events,
                rowIndex: index,
                rowProperties: renderProperties.rowProperties,
                originalRowData: originalRowData,
                settings: settings,
                styles: newStyles,
                tableData: tableData,
                tableProperties: tableProperties,
                ignoredColumns: renderProperties.ignoredColumns,
                columnProperties: renderProperties.columnProperties,
                idColumn: self.props.state.idColumn,
                selectedRecords: self.props.state.selectedRecords,
                utilityBarProperties: self.props.utilityBarProperties,
                renderedSizeProperties: _lodash2.default.get(self, 'props.state.renderedSizeProperties'),
                'data-codecept-selector-file': 'table-body'
            });
        }
    }, {
        key: 'render',
        value: function render() {
            var _this2 = this;

            //# NOTE: tableData is already formatted for griddle
            //# but: this.props.state.data is the OG data (but a subset)
            var _props2 = this.props,
                visibleData = _props2.visibleData,
                tableData = _props2.data,
                loading = _props2.loading,
                components = _props2.components,
                styles = _props2.styles,
                settings = _props2.settings,
                events = _props2.events,
                renderProperties = _props2.renderProperties,
                tableProperties = _props2.tableProperties;

            var self = this;
            var rows = tableData.filter(function (data) {
                return data.visible === undefined || data.visible === true;
            });
            self._rows = rows;

            //console.log('on table-body render');
            //deferredMeasurementCache={self._cache}
            //rowHeight={self._cache.rowHeight}
            //# NOTE: setting autoWidth to prevent force reflow being called by <Grid> when calculating leftScroll in scrollPositionChangeReason
            //<AutoSizer disableHeight={true} style={{width: '100%'}}>
            return _react2.default.createElement(
                _reactVirtualized.AutoSizer,
                { disableHeight: true, style: { width: '100%' }, 'data-codecept-selector-node': 'AutoSizer',
                    'data-codecept-selector-file': 'table-body'
                },
                function (_ref2) {
                    var width = _ref2.width;
                    return _react2.default.createElement(
                        _reactVirtualized.WindowScroller,
                        {
                            scrollElement: _lodash2.default.get(_this2, 'state.scrollElement'),
                            'data-codecept-selector-node': 'WindowScroller',
                            'data-codecept-selector-file': 'table-body'
                        },
                        function (_ref3) {
                            var height = _ref3.height,
                                scrollTop = _ref3.scrollTop,
                                registerChild = _ref3.registerChild;

                            return _react2.default.createElement(
                                'div',
                                { ref: registerChild, 'data-codecept-selector-node': 'div',
                                    'data-codecept-selector-file': 'table-body'
                                },
                                _react2.default.createElement(_reactVirtualized.List, {
                                    autoHeight: true,
                                    autoWidth: true,
                                    width: width,
                                    height: height,
                                    rowCount: rows.length,
                                    estimatedRowSize: _this2.props.rowHeight,
                                    rowHeight: _this2.props.rowHeight,
                                    rowRenderer: _this2.rowRenderer.bind(_this2),
                                    scrollTop: scrollTop,
                                    className: 'tbody',
                                    ref: function ref(instance) {
                                        //# - suppress "Rendered cell should include style property" warning
                                        //# - react-virtualized naively checks the rendered row for the 'style' prop before giving the warning
                                        //# - we use the `styles` prop instead, so this warning is not relevant
                                        if (instance && instance.Grid) {
                                            instance.Grid.__warnedAboutMissingStyle = true;
                                        }
                                    },
                                    cellRangeRenderer: _reactVirtualized.defaultCellRangeRenderer,
                                    'data-codecept-selector-node': 'List',
                                    'data-codecept-selector-file': 'table-body'
                                })
                            );
                        }
                    );
                }
            );
        }
    }]);

    return TableBody;
}(_react.Component), _class.defaultProps = _extends({}, _react.Component.defaultProps, {
    rowHeight: 49
}), _temp);


TableBody.propTypes = {
    data: _react.PropTypes.array,
    columns: _react.PropTypes.arrayOf(_react.PropTypes.string),
    components: _react.PropTypes.object,
    componentProps: _react.PropTypes.object,
    events: _react.PropTypes.object,
    loading: _react.PropTypes.bool,
    state: _react.PropTypes.object,
    styles: _react.PropTypes.object,
    settings: _react.PropTypes.object,
    renderProperties: _react.PropTypes.object,
    tableProperties: _react.PropTypes.object
};

exports.default = TableBody;