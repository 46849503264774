'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TermsOfUse = require('./TermsOfUse.cssModule');

var _TermsOfUse2 = _interopRequireDefault(_TermsOfUse);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var currentYear = new Date().getFullYear();

var TermsOfUse = function TermsOfUse(props) {
  var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8, _React$createElement9, _React$createElement10, _React$createElement11, _React$createElement12, _React$createElement13, _React$createElement14, _React$createElement15, _React$createElement16, _React$createElement17, _React$createElement18, _React$createElement19, _React$createElement20, _React$createElement21, _React$createElement22, _React$createElement23, _React$createElement24, _React$createElement25, _React$createElement26, _React$createElement27, _React$createElement28, _React$createElement29, _React$createElement30, _React$createElement31, _React$createElement32, _React$createElement33, _React$createElement34, _React$createElement35, _React$createElement36, _React$createElement37, _React$createElement38, _React$createElement39, _React$createElement40, _React$createElement41, _React$createElement42, _React$createElement43, _React$createElement44, _React$createElement45, _React$createElement46, _React$createElement47, _React$createElement48, _React$createElement49, _React$createElement50, _React$createElement51, _React$createElement52, _React$createElement53, _React$createElement54, _React$createElement55, _React$createElement56, _React$createElement57, _React$createElement58, _React$createElement59, _React$createElement60, _React$createElement61, _React$createElement62, _React$createElement63, _React$createElement64, _React$createElement65, _React$createElement66, _React$createElement67, _React$createElement68;

  return _react2.default.createElement(
    'div',
    (_React$createElement68 = { className: _TermsOfUse2.default.wrapper, 'data-codecept-selector-node': 'div',
      'data-codecept-selector-file': 'TermsOfUse'
    }, _defineProperty(_React$createElement68, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement68, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement68, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement68, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement68),
    _react2.default.createElement(
      'p',
      (_React$createElement = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement),
      'This web application, its code, functionality, and features are the property of Rubenstein Technology Group, Inc. and is Copyright\xA0\xA91999\u2013',
      currentYear,
      ', Rubenstein Technology Group, Inc. All rights reserved.'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement2 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement2),
      'Ruby Business Platform, RubyContent, RubyCommerce, RubyCRM, RubyCommunicate, RubyCollaborate, RubyMail, RubyApps, and RubyLaw are trademarks of Rubenstein Technology Group, Inc. The RubyApps, RubyLaw, RubyContent, and Rubenstein Technology Group logos are also trademarks of Rubenstein Technology Group, Inc. The phrase, "Business applications that power profits" is a servicemark of Rubenstein Technology Group, Inc. RubyLaw is a registered trademark of Rubenstein Technology Group, Inc. The absence of a product or service name or logo from this list does not constitute a waiver of Rubenstein Technology Group\'s trademark or other intellectual property rights concerning that name or logo.'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement3 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement3),
      'No portion of this Web site, in whole or in part, may be reproduced without the prior written permission of Rubenstein Technology Group, Inc.'
    ),
    _react2.default.createElement(
      'h4',
      (_React$createElement4 = {
        'data-codecept-selector-node': 'h4',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'h4'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'h4'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement4),
      'Privacy Policy'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement5 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement5),
      'Rubenstein Technology Group, Inc. ("RubensteinTech") respects each individual\'s right to personal privacy. We will collect and use information through our Web site only in the ways disclosed in this statement. This statement applies solely to information collected at this web application\'s Web site.'
    ),
    _react2.default.createElement(
      'h5',
      (_React$createElement6 = {
        'data-codecept-selector-node': 'h5',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'h5'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'h5'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement6),
      'Part I. Information Collection'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement7 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement7),
      'RubensteinTech collects information through our Web site at several points. We collect the following information about primary visitors: name, mailing address, e-mail address, phone and fax numbers, IP address, date and time of visit, and web browser application and usage information.'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement8 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement8),
      'We may also collect other types of personally identifiable information which our users divulge about other people, such as referrals or requests for technical support or documentation. RubensteinTech does not actively market to children, and we never knowingly ask a child under 13 to divulge personal information.'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement9 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement9),
      'We collect demographic data in order to better market our products and services to site visitors and our clients.'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement10 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement10),
      'We verify the accuracy of this information by phone and or e-mail from time to time.'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement11 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement11),
      'We collect this information through registration forms, clickstream tracking, and automatic logging files. The information is collected voluntarily or involuntarily. To receive our products and services, this information is mandatory because it allows us to better target our products and services. Other information we request is optional.'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement12 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement12),
      'We employ cookies to track usage of our website and e-mail marketing campaigns. A cookie is a small text file that our Web server places on a user\'s computer hard drive to be a unique identifier. Cookies enable RubensteinTech to track usage patterns and deliver customized content to users. Our cookies do have an expiration date. Our cookies do not collect personally identifiable information, but rather consist of an identification number that can then be linked to personally identifiable information.'
    ),
    _react2.default.createElement(
      'h5',
      (_React$createElement13 = {
        'data-codecept-selector-node': 'h5',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'h5'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'h5'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement13),
      'Part II. Information Usage'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement14 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement14),
      'The information collected by RubensteinTech will be used for sales and marketing purposes. Users who provide information will receive e-mail announcements and possibly advertisements from RubensteinTech about our products and services.'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement15 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement15),
      'Registered users may receive additional announcements from us about products, services, special deals, and a newsletter. Out of respect for the privacy of our users we present the option to not receive these types of communications.'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement16 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement16),
      'The information we collect will be used to create customer profiles based on browsing or purchasing history. We will supplement information collected at our Web site with data from other sources, such as credit history to authorize purchases.'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement17 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement17),
      'We do not share data with third parties at this time.'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement18 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement18),
      'We offer links to other Web sites. Please note: When you click on links to other Web sites, we encourage you to read their privacy policies. Their standards may differ from ours.'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement19 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement19),
      'Our Web site offers public forums and message boards. Please be advised that information posted in these venues becomes public knowledge.'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement21 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement21, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement21, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement21, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement21, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement21),
      'If our policy on information collection or uses changes, will advise you by updating this policy on our website at the following address:\xA0',
      _react2.default.createElement(
        'a',
        (_React$createElement20 = { href: 'https://www.rubensteintech.com/about/privacy.html', 'data-codecept-selector-node': 'a',
          'data-codecept-selector-file': 'TermsOfUse'
        }, _defineProperty(_React$createElement20, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement20, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement20, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement20, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement20),
        'http://www.rubensteintech.com/about/privacy.html'
      ),
      '. This policy is subject to change without notice.'
    ),
    _react2.default.createElement(
      'h5',
      (_React$createElement22 = {
        'data-codecept-selector-node': 'h5',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement22, 'data-codecept-selector-node', 'h5'), _defineProperty(_React$createElement22, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement22, 'data-codecept-selector-node', 'h5'), _defineProperty(_React$createElement22, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement22),
      'Part III. Access to Information'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement24 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement24, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement24, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement24, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement24, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement24),
      'RubensteinTech maintains the accuracy of our information by periodic review of this information. Users may access their own personal information and contact us about inaccuracies they may find via phone or e-mail to\xA0',
      _react2.default.createElement(
        'a',
        (_React$createElement23 = { href: 'mailto:webmaster@rubensteintech.com', 'data-codecept-selector-node': 'a',
          'data-codecept-selector-file': 'TermsOfUse'
        }, _defineProperty(_React$createElement23, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement23, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement23, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement23, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement23),
        'webmaster@rubensteintech.com'
      ),
      '. Users may delete their information from our database by requesting RubensteinTech to do so via phone or e-mail. Database information will be removed within 30 days of receipt of any such request.'
    ),
    _react2.default.createElement(
      'h5',
      (_React$createElement25 = {
        'data-codecept-selector-node': 'h5',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement25, 'data-codecept-selector-node', 'h5'), _defineProperty(_React$createElement25, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement25, 'data-codecept-selector-node', 'h5'), _defineProperty(_React$createElement25, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement25),
      'Part IV. Problem Resolution'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement27 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement27, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement27, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement27, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement27, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement27),
      'If problems arise, users may contact RubensteinTech by phone or\xA0',
      _react2.default.createElement(
        'a',
        (_React$createElement26 = { href: 'mailto:webmaster@rubensteintech.com', 'data-codecept-selector-node': 'a',
          'data-codecept-selector-file': 'TermsOfUse'
        }, _defineProperty(_React$createElement26, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement26, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement26, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement26, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement26),
        'webmaster@rubensteintech.com'
      ),
      '. We are committed to resolving disputes as quickly as possible.'
    ),
    _react2.default.createElement(
      'h5',
      (_React$createElement28 = {
        'data-codecept-selector-node': 'h5',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement28, 'data-codecept-selector-node', 'h5'), _defineProperty(_React$createElement28, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement28, 'data-codecept-selector-node', 'h5'), _defineProperty(_React$createElement28, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement28),
      'Part V. Data Storage and Security'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement29 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement29, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement29, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement29, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement29, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement29),
      'The security of your personal information is very important to us, and we are committed to protecting the information we collect. RubensteinTech uses firewalls and Secure Socket Layer (SSL) encryption for all payment information we collect from you. We also employ many different security techniques to protect personal data against loss, misuse, alteration, and unauthorized action. But we cannot guarantee perfect security of your personal information.'
    ),
    _react2.default.createElement(
      'h4',
      (_React$createElement30 = {
        'data-codecept-selector-node': 'h4',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement30, 'data-codecept-selector-node', 'h4'), _defineProperty(_React$createElement30, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement30, 'data-codecept-selector-node', 'h4'), _defineProperty(_React$createElement30, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement30),
      'Cookie Policy'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement31 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement31, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement31, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement31, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement31, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement31),
      'We collect certain information by automated means when you visit this web application (our\xA0\u201CSite\u201D), such as how many users visited our Site and the pages accessed. By collecting this information, we learn how to best tailor the application experience to its users. We collect this information through various means such as \u201Ccookies,\u201D \u201Cweb beacons\u201D and IP addresses, as explained below.\xA0'
    ),
    _react2.default.createElement(
      'ul',
      (_React$createElement41 = {
        'data-codecept-selector-node': 'ul',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement41, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement41, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement41, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement41, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement41),
      _react2.default.createElement(
        'li',
        (_React$createElement34 = {
          'data-codecept-selector-node': 'li',
          'data-codecept-selector-file': 'TermsOfUse'
        }, _defineProperty(_React$createElement34, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement34, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement34, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement34, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement34),
        _react2.default.createElement(
          'em',
          (_React$createElement32 = {
            'data-codecept-selector-node': 'em',
            'data-codecept-selector-file': 'TermsOfUse'
          }, _defineProperty(_React$createElement32, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement32, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement32, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement32, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement32),
          'Cookies\xA0'
        ),
        _react2.default.createElement('br', (_React$createElement33 = {
          'data-codecept-selector-node': 'br',
          'data-codecept-selector-file': 'TermsOfUse'
        }, _defineProperty(_React$createElement33, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement33, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement33, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement33, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement33)),
        'Like many companies, some of our Site may use \u201Ccookies.\u201D Cookies are bits of text that are placed on your computer\u2019s hard drive when you visit certain websites. We may use cookies to tell us, for example, whether you have visited us before or if you are a new visitor and to help us identify site features in which you may have the greatest interest. Cookies may enhance your online experience by saving your preferences while you are visiting a particular site.'
      ),
      _react2.default.createElement(
        'li',
        (_React$createElement37 = {
          'data-codecept-selector-node': 'li',
          'data-codecept-selector-file': 'TermsOfUse'
        }, _defineProperty(_React$createElement37, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement37, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement37, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement37, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement37),
        _react2.default.createElement(
          'em',
          (_React$createElement36 = {
            'data-codecept-selector-node': 'em',
            'data-codecept-selector-file': 'TermsOfUse'
          }, _defineProperty(_React$createElement36, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement36, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement36, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement36, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement36),
          'Web Beacons\xA0',
          _react2.default.createElement('br', (_React$createElement35 = {
            'data-codecept-selector-node': 'br',
            'data-codecept-selector-file': 'TermsOfUse'
          }, _defineProperty(_React$createElement35, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement35, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement35, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement35, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement35))
        ),
        'Certain pages on our Site may contain\xA0\u201Cweb beacons\u201D\xA0(also known as Internet tags, pixel tags and clear GIFs). These web beacons allow third parties to obtain information such as the IP address of the computer that downloaded the page on which the beacon appears, the URL of the page on which the beacon appears, the time the page containing the beacon was viewed, the type of browser used to view the page, and the information in cookies set by the third party.'
      ),
      _react2.default.createElement(
        'li',
        (_React$createElement40 = {
          'data-codecept-selector-node': 'li',
          'data-codecept-selector-file': 'TermsOfUse'
        }, _defineProperty(_React$createElement40, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement40, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement40, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement40, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement40),
        _react2.default.createElement(
          'em',
          (_React$createElement39 = {
            'data-codecept-selector-node': 'em',
            'data-codecept-selector-file': 'TermsOfUse'
          }, _defineProperty(_React$createElement39, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement39, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement39, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement39, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement39),
          'IP Addresses\xA0',
          _react2.default.createElement('br', (_React$createElement38 = {
            'data-codecept-selector-node': 'br',
            'data-codecept-selector-file': 'TermsOfUse'
          }, _defineProperty(_React$createElement38, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement38, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement38, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement38, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement38))
        ),
        'An IP address is a unique identifier that certain electronic devices use to identify and communicate with each other on the Internet. When you visit our Site, we may view the IP address of the device you use to connect to the Internet. We use this information to determine the general physical location of the device and understand from what geographic regions our website visitors come. We also may use this information to enhance our Site.'
      )
    ),
    _react2.default.createElement(
      'h4',
      (_React$createElement42 = {
        'data-codecept-selector-node': 'h4',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement42, 'data-codecept-selector-node', 'h4'), _defineProperty(_React$createElement42, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement42, 'data-codecept-selector-node', 'h4'), _defineProperty(_React$createElement42, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement42),
      'We use one or more of the following cookies on our Site:'
    ),
    _react2.default.createElement(
      'ul',
      (_React$createElement59 = {
        'data-codecept-selector-node': 'ul',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement59, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement59, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement59, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement59, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement59),
      _react2.default.createElement(
        'li',
        (_React$createElement43 = {
          'data-codecept-selector-node': 'li',
          'data-codecept-selector-file': 'TermsOfUse'
        }, _defineProperty(_React$createElement43, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement43, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement43, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement43, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement43),
        'Google Analytics - _utma,_utmz, _ga'
      ),
      _react2.default.createElement(
        'ul',
        (_React$createElement49 = {
          'data-codecept-selector-node': 'ul',
          'data-codecept-selector-file': 'TermsOfUse'
        }, _defineProperty(_React$createElement49, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement49, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement49, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement49, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement49),
        _react2.default.createElement(
          'li',
          (_React$createElement44 = {
            'data-codecept-selector-node': 'li',
            'data-codecept-selector-file': 'TermsOfUse'
          }, _defineProperty(_React$createElement44, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement44, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement44, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement44, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement44),
          'These cookies are used to collect information about how visitors use the Site. We use this information to compile reports and to help us improve the Site. The cookies collect information in an anonymous form, including the number of visitors to the Site, where visitors have come to the Site from and the pages they visited.'
        ),
        _react2.default.createElement(
          'li',
          (_React$createElement46 = {
            'data-codecept-selector-node': 'li',
            'data-codecept-selector-file': 'TermsOfUse'
          }, _defineProperty(_React$createElement46, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement46, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement46, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement46, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement46),
          'To opt out of being tracked by Google Analytics across all websites visit\xA0',
          _react2.default.createElement(
            'a',
            (_React$createElement45 = { href: 'http://tools.google.com/dlpage/gaoptout', target: '_blank', 'data-codecept-selector-node': 'a',
              'data-codecept-selector-file': 'TermsOfUse'
            }, _defineProperty(_React$createElement45, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement45, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement45, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement45, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement45),
            'this page'
          ),
          '.'
        ),
        _react2.default.createElement(
          'li',
          (_React$createElement48 = {
            'data-codecept-selector-node': 'li',
            'data-codecept-selector-file': 'TermsOfUse'
          }, _defineProperty(_React$createElement48, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement48, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement48, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement48, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement48),
          'More information on the cookies used by Google\u2019s Universal Analytics and Tag Manager visit\xA0',
          _react2.default.createElement(
            'a',
            (_React$createElement47 = { href: 'https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id', target: '_blank', 'data-codecept-selector-node': 'a',
              'data-codecept-selector-file': 'TermsOfUse'
            }, _defineProperty(_React$createElement47, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement47, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement47, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement47, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement47),
            'this page'
          ),
          '.'
        )
      ),
      _react2.default.createElement(
        'li',
        (_React$createElement52 = {
          'data-codecept-selector-node': 'li',
          'data-codecept-selector-file': 'TermsOfUse'
        }, _defineProperty(_React$createElement52, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement52, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement52, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement52, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement52),
        'RubyLaw User Cookies',
        _react2.default.createElement(
          'ul',
          (_React$createElement51 = {
            'data-codecept-selector-node': 'ul',
            'data-codecept-selector-file': 'TermsOfUse'
          }, _defineProperty(_React$createElement51, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement51, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement51, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement51, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement51),
          _react2.default.createElement(
            'li',
            (_React$createElement50 = {
              'data-codecept-selector-node': 'li',
              'data-codecept-selector-file': 'TermsOfUse'
            }, _defineProperty(_React$createElement50, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement50, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement50, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement50, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement50),
            'Application configuration and preferences data related to the active user'
          )
        )
      ),
      _react2.default.createElement(
        'li',
        (_React$createElement56 = {
          'data-codecept-selector-node': 'li',
          'data-codecept-selector-file': 'TermsOfUse'
        }, _defineProperty(_React$createElement56, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement56, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement56, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement56, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement56),
        'RubyLaw Analytics',
        _react2.default.createElement(
          'ul',
          (_React$createElement55 = {
            'data-codecept-selector-node': 'ul',
            'data-codecept-selector-file': 'TermsOfUse'
          }, _defineProperty(_React$createElement55, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement55, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement55, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement55, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement55),
          _react2.default.createElement(
            'li',
            (_React$createElement53 = {
              'data-codecept-selector-node': 'li',
              'data-codecept-selector-file': 'TermsOfUse'
            }, _defineProperty(_React$createElement53, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement53, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement53, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement53, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement53),
            'URLs visited on the site by the end user'
          ),
          _react2.default.createElement(
            'li',
            (_React$createElement54 = {
              'data-codecept-selector-node': 'li',
              'data-codecept-selector-file': 'TermsOfUse'
            }, _defineProperty(_React$createElement54, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement54, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement54, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement54, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement54),
            'Page titles of the pages visited'
          )
        )
      ),
      _react2.default.createElement(
        'ul',
        (_React$createElement58 = {
          'data-codecept-selector-node': 'ul',
          'data-codecept-selector-file': 'TermsOfUse'
        }, _defineProperty(_React$createElement58, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement58, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement58, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement58, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement58),
        _react2.default.createElement(
          'li',
          (_React$createElement57 = {
            'data-codecept-selector-node': 'li',
            'data-codecept-selector-file': 'TermsOfUse'
          }, _defineProperty(_React$createElement57, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement57, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement57, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement57, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement57),
          'RubyLaw Analytics tracks visitors using 1st party cookies, set on the domain of a website, and is used to track:'
        )
      )
    ),
    _react2.default.createElement(
      'h4',
      (_React$createElement60 = {
        'data-codecept-selector-node': 'h4',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement60, 'data-codecept-selector-node', 'h4'), _defineProperty(_React$createElement60, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement60, 'data-codecept-selector-node', 'h4'), _defineProperty(_React$createElement60, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement60),
      'Your Choices'
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement65 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement65, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement65, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement65, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement65, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement65),
      'This application requires cookies to function correctly. Most browsers will tell you how to stop accepting new cookies, how to be notified when you receive a new cookie, and how to disable existing cookies. On Microsoft Internet Explorer, this can be done by selecting \u201CTools/Internet Options\u201D and reviewing your privacy settings or selecting\xA0\u201Cdelete cookies.\u201D\xA0You can find out how to do this for your particular browser by clicking \u201Chelp\u201D on your browser\'s menu or by visiting\xA0',
      _react2.default.createElement(
        'a',
        (_React$createElement61 = { href: 'http://www.allaboutcookies.org/', target: '_blank', 'data-codecept-selector-node': 'a',
          'data-codecept-selector-file': 'TermsOfUse'
        }, _defineProperty(_React$createElement61, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement61, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement61, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement61, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement61),
        'www.allaboutcookies.org'
      ),
      '. Please note, however, that without cookies you may not be able to take full advantage of all our site features.\xA0',
      _react2.default.createElement('br', (_React$createElement62 = {
        'data-codecept-selector-node': 'br',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement62, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement62, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement62, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement62, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement62)),
      _react2.default.createElement('br', (_React$createElement63 = {
        'data-codecept-selector-node': 'br',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement63, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement63, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement63, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement63, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement63)),
      _react2.default.createElement(
        'strong',
        (_React$createElement64 = {
          'data-codecept-selector-node': 'strong',
          'data-codecept-selector-file': 'TermsOfUse'
        }, _defineProperty(_React$createElement64, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement64, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement64, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement64, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement64),
        'How to Contact Us'
      )
    ),
    _react2.default.createElement(
      'p',
      (_React$createElement67 = {
        'data-codecept-selector-node': 'p',
        'data-codecept-selector-file': 'TermsOfUse'
      }, _defineProperty(_React$createElement67, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement67, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement67, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement67, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement67),
      'If you have any questions about our information practices, please contact via email to ',
      _react2.default.createElement(
        'a',
        (_React$createElement66 = { href: 'mailto:webmaster@rubensteintech.com', 'data-codecept-selector-node': 'a',
          'data-codecept-selector-file': 'TermsOfUse'
        }, _defineProperty(_React$createElement66, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement66, 'data-codecept-selector-file', 'TermsOfUse'), _defineProperty(_React$createElement66, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement66, 'data-codecept-selector-file', 'TermsOfUse'), _React$createElement66),
        'webmaster@rubensteintech.com'
      ),
      '.'
    )
  );
};

exports.default = TermsOfUse;