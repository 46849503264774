'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = getBioUpdatesMenuItemsForSelfModule;
//# TODO: see if we can pass the icon component instead of the string
function getBioUpdatesMenuItemsForSelfModule(selfModule) {
    var _selfModule$getForms = selfModule.getForms(),
        attorneyForm = _selfModule$getForms.attorney;

    var attorneyTemplateId = attorneyForm ? attorneyForm.id : null;

    return attorneyForm ? {
        name: 'Bio Updates',
        icon: 'material-ui/svgIcons/CommunicationContactMail',
        permissions: [{
            keyword: 'can_notify_for_updates',
            ruby_client: attorneyForm.ruby_client_fk,
            action: 'menu'
        }],
        route: '/app/content/en/bio-updates'
    } : null;
}