'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.aurl = aurl;
exports.getLargeThumbnailUrl_forAsset = getLargeThumbnailUrl_forAsset;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _path = require('path');

var _path2 = _interopRequireDefault(_path);

var _sprintfJs = require('sprintf-js');

var _constants = require('./constants');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ASSET_CONSTANTS = _constants2.default.ASSET_CONSTANTS;
function aurl(assetId, type, options) {
    if (!assetId) {
        return null;
    }

    assetId = (0, _sprintfJs.sprintf)('%03d', assetId);

    var version = _lodash2.default.get(options, 'version');
    var versionDir = version ? '/v' + version : '';

    var filename = _lodash2.default.get(options, 'filename');
    var extension = _lodash2.default.get(options, 'type') || _lodash2.default.last((filename || "").split(/\./g)) || 'jpg';

    var assetName = _lodash2.default.get(options, 'asset_name') || filename || '';

    var assetExtension = _path2.default.extname(assetName);
    var preppedAssetNameWithExtension = _path2.default.basename(assetName, assetExtension) + '.' + extension;

    var displayAssetName = preppedAssetNameWithExtension;

    var firstDigit = assetId.charAt(0);
    var secondDigit = assetId.charAt(1);

    var url;

    switch (type) {
        case ASSET_CONSTANTS.LARGETHUMB:
            url = '/utils/thumbs/' + firstDigit + '/' + secondDigit + '/' + assetId + '_largethumb.' + extension;
            break;
        case ASSET_CONSTANTS.IMAGE_PATH:
            url = '/images/content/' + firstDigit + '/' + secondDigit + '/' + assetId + '.' + extension;
            break;
        default:
            // TODO: this is messy, maybe this should be updated to accept a callback
            // pull asset info (this might change currently!), use config for www server
            url = (0, _sprintfJs.sprintf)('/images/content/%d/%d%s/%s/%s', firstDigit, secondDigit, versionDir, assetId, displayAssetName);
            break;
    };

    return url;
}

function getLargeThumbnailUrl_forAsset(assetObj) {
    return !assetObj ? null : aurl(assetObj.asset_id, ASSET_CONSTANTS.LARGETHUMB, {
        filename: assetObj.filename,
        type: assetObj.type
    });
}