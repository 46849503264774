'use strict';

module.exports = {
    COMPONENT_NAME: 'rubyComponentFieldCodeEditor',
    GET_DEFAULT_PROPS: function GET_DEFAULT_PROPS() {
        return {
            mode: 'json',
            theme: 'github'
        };
    }
};