"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.decoratedFunctionChain = decoratedFunctionChain;
exports.decoratedFunctionReduce_booleanAnd = decoratedFunctionReduce_booleanAnd;
function decoratedFunctionChain(theFunction) {
    //# TODO: change the functions instead
}

function decoratedFunctionReduce_booleanAnd(theFunction, functionName) {
    function decoratedFunction() {
        var _this = this,
            _arguments = arguments;

        var mixins = this.getMixins();
        var allFunctions = mixins.reduce(function (collector, mixin) {
            if (mixin.hasOwnProperty(functionName) && mixin[functionName] != decoratedFunction) {
                collector.push(mixin[functionName]);
            }

            return collector;
        }, [theFunction]);

        return allFunctions.reduce(function (collector, theFunction) {
            if (!collector) {
                return collector;
            }

            return collector && theFunction.apply(_this, _arguments);
        }, true);
    }
    decoratedFunction.original = theFunction;

    return decoratedFunction;
}