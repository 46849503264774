'use strict';

module.exports = {
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        if (nextProps.open == false && this.props.open == true) {
            this._unbindWindowResize();
        } else if (nextProps.open == true && this.props.open == false) {
            this._bindWindowResize();
        }
    },
    componentDidUpdate: function componentDidUpdate(prevProps) {
        var nextProps = this.props;

        if (nextProps.open == true && !prevProps.open) {
            this._updateLastRenderedTimestamp();
        }
    },
    componentDidMount: function componentDidMount() {
        this._isMounted = true;

        this._bindWindowResize();
        this._updateLastRenderedTimestamp();
    },
    componentWillUnmount: function componentWillUnmount() {
        this._isMounted = false;

        //# stop listening to window resize
        window.removeEventListener('resize', this.onWindowResize);
    },
    _updateLastRenderedTimestamp: function _updateLastRenderedTimestamp() {
        if (this._isMounted) {
            this.setState({
                lastRenderedTimestamp: new Date()
            });
        }
    },
    _bindWindowResize: function _bindWindowResize() {
        window.addEventListener('resize', this._updateLastRenderedTimestamp);
    },
    _unbindWindowResize: function _unbindWindowResize() {
        window.removeEventListener('resize', this._updateLastRenderedTimestamp);
    }
};