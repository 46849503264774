'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Tabs = require('material-ui/Tabs');

var _warning = require('material-ui/svg-icons/alert/warning');

var _warning2 = _interopRequireDefault(_warning);

var _Tab = require('./Tab.cssModule');

var _Tab2 = _interopRequireDefault(_Tab);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label;

var Label__propTypeKeys = Object.keys(Label.propTypes);

//# Icons


//# Styles


var RCTab = _react2.default.createClass({
    displayName: 'RCTab',

    propTypes: {
        //# other props that should be inserted to the tab
        actions: PropTypes.object
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    render: function render() {
        var _extends2,
            _React$createElement,
            _React$createElement2,
            _React$createElement3,
            _this = this;

        var hasError = false;
        if (this.props.error && this.props.error.message || this.props.maxLevel) {
            hasError = true;
        }
        var muiTheme = this.context.muiTheme;
        var tabs = muiTheme.tabs;
        var tabErrorStyles = {
            error: _lodash2.default.get(muiTheme, ['alternates', 'tabError'], {}),
            warning: _lodash2.default.get(muiTheme, ['alternates', 'tabWarning'], {}),
            info: _lodash2.default.get(muiTheme, ['alternates', 'tabInfo'], {})
        };
        var tabErrorStyle = hasError ? tabErrorStyles[this.props.maxLevel || 'error'] : {};

        var pluckedTabProps = _lodash2.default.pick(this.props, Object.keys(_Tabs.Tab.propTypes));
        var labelProps = _lodash2.default.pick(this.props, Label__propTypeKeys);
        var renderedLabel = _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = { style: { display: 'inline-block' }, 'data-codecept-selector-node': 'Label',
            'data-codecept-selector-file': 'Tab'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Tab'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Tab'), _extends2)));

        var tabProps = _extends({}, pluckedTabProps, hasError ? {
            label: _react2.default.createElement(
                'div',
                (_React$createElement2 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Tab'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Tab'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Tab'), _React$createElement2),
                _react2.default.createElement(_warning2.default, (_React$createElement = {
                    style: {
                        color: pluckedTabProps.selected ? tabs.selectedTextColor : tabs.textColor
                    },
                    className: _Tab2.default.WarningIcon,
                    'data-codecept-selector-node': 'WarningIcon',
                    'data-codecept-selector-file': 'Tab'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'WarningIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Tab'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'WarningIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Tab'), _React$createElement)),
                renderedLabel
            )
        } : {
            label: _react2.default.createElement(
                'div',
                (_React$createElement3 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Tab'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Tab'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Tab'), _React$createElement3),
                renderedLabel
            )
        });

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            var _React$createElement4, _extends3;

            var inactiveTabProps = _extends({}, tabProps, {
                onTouchTap: function onTouchTap(event) {},
                style: {
                    opacity: 0
                }
            });
            return _react2.default.createElement(
                _Tabs.Tab,
                _extends({}, inactiveTabProps, (_extends3 = {
                    'data-codecept-selector-node': 'Tab',
                    'data-codecept-selector-file': 'Tab'
                }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Tab'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Tab'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Tab'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Tab'), _extends3)),
                _react2.default.createElement('div', (_React$createElement4 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Tab'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Tab'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Tab'), _React$createElement4))
            );
        } else {
            var _React$createElement5, _extends4;

            //# NOTE: children isn't rendering when dynamically generated
            //# we actually have to rely on an override in material-ui/Tabs
            return _react2.default.createElement(
                _Tabs.Tab,
                _extends({}, tabProps, (_extends4 = { style: tabErrorStyle,
                    onTouchTap: function onTouchTap(event) {
                        _this.props.actions.selectSelf();
                    },
                    'data-codecept-selector-node': 'Tab',
                    'data-codecept-selector-file': 'Tab'
                }, _defineProperty(_extends4, 'data-codecept-selector-node', 'Tab'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Tab'), _defineProperty(_extends4, 'data-codecept-selector-node', 'Tab'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Tab'), _extends4)),
                _react2.default.createElement(
                    'div',
                    (_React$createElement5 = {
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'Tab'
                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Tab'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Tab'), _React$createElement5),
                    this.props.children
                )
            );
        }
    }
});
RCTab.propagatedAttributes = ['muiName'];
RCTab.muiName = _Tabs.Tab.muiName;

exports.default = RCTab;