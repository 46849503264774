'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _TFARegistration = require('./TFARegistration');

var _TFARegistration2 = _interopRequireDefault(_TFARegistration);

var _TFAInfo = require('./TFAInfo');

var _TFAInfo2 = _interopRequireDefault(_TFAInfo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label;

var TFA = _react2.default.createClass({
    displayName: 'TFA',

    propTypes: {
        value: PropTypes.string,
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        secret: PropTypes.string,
        token: PropTypes.string,
        enabled: PropTypes.bool,
        registered: PropTypes.bool,
        id: PropTypes.string,
        actions: PropTypes.object,
        excludeWrapper: PropTypes.bool,
        frontendSettings: PropTypes.shape({
            CLIENT_NAME: PropTypes.string,
            RUBY_BRANDING: PropTypes.string,
            "feat:MFA:required": PropTypes.bool
        })
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null
        };
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var _props = this.props,
            actions = _props.actions,
            enabled = _props.enabled,
            excludeWrapper = _props.excludeWrapper,
            frontendSettings = _props.frontendSettings,
            secret = _props.secret,
            registered = _props.registered,
            tfaLabel = _props.tfaLabel,
            token = _props.token;

        var inputComponent = _react2.default.createElement(
            'div',
            (_React$createElement3 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'TFA'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TFA'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TFA'), _React$createElement3),
            _react2.default.createElement(_TFAInfo2.default, (_React$createElement = {
                rubyBranding: _lodash2.default.get(this.props, 'frontendSettings.RUBY_BRANDING'),
                mode: _lodash2.default.get(this.props, 'frontendSettings.feat:MFA:required') ? 'required' : 'default',
                'data-codecept-selector-node': 'TFAInfo',
                'data-codecept-selector-file': 'TFA'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'TFAInfo'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TFA'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'TFAInfo'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TFA'), _React$createElement)),
            _react2.default.createElement(_TFARegistration2.default, (_React$createElement2 = {
                actions: actions,
                enabled: enabled,
                secret: secret,
                registered: registered,
                token: token,
                frontendSettings: frontendSettings,
                label: tfaLabel,
                'data-codecept-selector-node': 'TFARegistration',
                'data-codecept-selector-file': 'TFA'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'TFARegistration'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TFA'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'TFARegistration'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TFA'), _React$createElement2))
        );

        return excludeWrapper ? inputComponent : this.props.renderGridWrapper(_extends({}, this.props, { children: inputComponent }));
    }
});

exports.default = TFA;