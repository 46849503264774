'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _SvgIcon = require('material-ui/SvgIcon');

var _SvgIcon2 = _interopRequireDefault(_SvgIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var SvgIconCustom = _SvgIcon2.default;

exports.default = function (props) {
    var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8, _React$createElement9, _React$createElement10, _React$createElement11, _React$createElement12, _React$createElement13, _React$createElement14, _React$createElement15, _extends2;

    return _react2.default.createElement(
        SvgIconCustom,
        _extends({}, props, (_extends2 = { viewBox: '0 0 485 485', 'data-codecept-selector-node': 'SvgIconCustom',
            'data-codecept-selector-file': 'addToScheduleIcon'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'SvgIconCustom'), _defineProperty(_extends2, 'data-codecept-selector-file', 'addToScheduleIcon'), _defineProperty(_extends2, 'data-codecept-selector-node', 'SvgIconCustom'), _defineProperty(_extends2, 'data-codecept-selector-file', 'addToScheduleIcon'), _extends2)),
        _react2.default.createElement('path', (_React$createElement = { d: "M60.652,75.816V15.163C60.652,6.781,67.433,0,75.817,0c8.38,0,15.161,6.781,15.161,15.163v60.653" + "c0,8.38-6.781,15.161-15.161,15.161C67.433,90.978,60.652,84.196,60.652,75.816z", 'data-codecept-selector-node': 'path',
            'data-codecept-selector-file': 'addToScheduleIcon'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'addToScheduleIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'addToScheduleIcon'), _React$createElement)),
        _react2.default.createElement('path', (_React$createElement2 = { d: "M318.424,90.978" + "c8.378,0,15.163-6.781,15.163-15.161V15.163C333.587,6.781,326.802,0,318.424,0c-8.382,0-15.168,6.781-15.168,15.163v60.653" + "C303.256,84.196,310.042,90.978,318.424,90.978z", 'data-codecept-selector-node': 'path',
            'data-codecept-selector-file': 'addToScheduleIcon'
        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'addToScheduleIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'addToScheduleIcon'), _React$createElement2)),
        _react2.default.createElement('path', (_React$createElement3 = { d: "M30.328,360.891V151.628h333.582v60.653h30.327V94c0-18.421-14.692-33.349-32.843-33.349h-12.647v15.166" + "c0,16.701-13.596,30.325-30.322,30.325c-16.731,0-30.326-13.624-30.326-30.325V60.651H106.14v15.166" + "c0,16.701-13.593,30.325-30.322,30.325c-16.733,0-30.327-13.624-30.327-30.325V60.651H32.859C14.707,60.651,0.001,75.579,0.001,94" + "v266.892c0,18.36,14.706,33.346,32.858,33.346h179.424v-30.331H32.859C31.485,363.906,30.328,362.487,30.328,360.891z", 'data-codecept-selector-node': 'path',
            'data-codecept-selector-file': 'addToScheduleIcon'
        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'addToScheduleIcon'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'addToScheduleIcon'), _React$createElement3)),
        _react2.default.createElement('path', (_React$createElement4 = { d: 'M303.256,242.606v-60.651h-60.648v60.651H303.256z', 'data-codecept-selector-node': 'path',
            'data-codecept-selector-file': 'addToScheduleIcon'
        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'addToScheduleIcon'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'addToScheduleIcon'), _React$createElement4)),
        _react2.default.createElement('path', (_React$createElement5 = { d: 'M151.629,333.584h60.654V272.93h-60.654V333.584z', 'data-codecept-selector-node': 'path',
            'data-codecept-selector-file': 'addToScheduleIcon'
        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'addToScheduleIcon'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'addToScheduleIcon'), _React$createElement5)),
        _react2.default.createElement('path', (_React$createElement6 = { d: 'M60.652,333.584h60.653V272.93H60.652V333.584z', 'data-codecept-selector-node': 'path',
            'data-codecept-selector-file': 'addToScheduleIcon'
        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'addToScheduleIcon'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'addToScheduleIcon'), _React$createElement6)),
        _react2.default.createElement('path', (_React$createElement7 = { d: 'M151.629,242.606h60.654v-60.651h-60.654V242.606z', 'data-codecept-selector-node': 'path',
            'data-codecept-selector-file': 'addToScheduleIcon'
        }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'addToScheduleIcon'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'addToScheduleIcon'), _React$createElement7)),
        _react2.default.createElement('path', (_React$createElement8 = { d: 'M121.305,181.955H60.652v60.651h60.653V181.955z', 'data-codecept-selector-node': 'path',
            'data-codecept-selector-file': 'addToScheduleIcon'
        }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'addToScheduleIcon'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'addToScheduleIcon'), _React$createElement8)),
        _react2.default.createElement('path', (_React$createElement9 = { d: "M485.212,363.906c0,66.996-54.312,121.307-121.303,121.307" + "c-66.986,0-121.302-54.311-121.302-121.307c0-66.986,54.315-121.3,121.302-121.3C430.9,242.606,485.212,296.919,485.212,363.906z" + "M454.89,363.906c0-50.161-40.81-90.976-90.98-90.976c-50.166,0-90.976,40.814-90.976,90.976c0,50.171,40.81,90.98,90.976,90.98" + "C414.08,454.886,454.89,414.077,454.89,363.906z", 'data-codecept-selector-node': 'path',
            'data-codecept-selector-file': 'addToScheduleIcon'
        }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'addToScheduleIcon'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'addToScheduleIcon'), _React$createElement9)),
        _react2.default.createElement('circle', (_React$createElement10 = { cx: '365', cy: '310', r: '15', 'data-codecept-selector-node': 'circle',
            'data-codecept-selector-file': 'addToScheduleIcon'
        }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'circle'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'addToScheduleIcon'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'circle'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'addToScheduleIcon'), _React$createElement10)),
        _react2.default.createElement('circle', (_React$createElement11 = { cx: '365', cy: '420', r: '15', 'data-codecept-selector-node': 'circle',
            'data-codecept-selector-file': 'addToScheduleIcon'
        }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'circle'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'addToScheduleIcon'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'circle'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'addToScheduleIcon'), _React$createElement11)),
        _react2.default.createElement('circle', (_React$createElement12 = { cx: '310', cy: '365', r: '15', 'data-codecept-selector-node': 'circle',
            'data-codecept-selector-file': 'addToScheduleIcon'
        }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'circle'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'addToScheduleIcon'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'circle'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'addToScheduleIcon'), _React$createElement12)),
        _react2.default.createElement('circle', (_React$createElement13 = { cx: '415', cy: '365', r: '15', 'data-codecept-selector-node': 'circle',
            'data-codecept-selector-file': 'addToScheduleIcon'
        }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'circle'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'addToScheduleIcon'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'circle'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'addToScheduleIcon'), _React$createElement13)),
        _react2.default.createElement('path', (_React$createElement14 = { d: 'M310,350h110,v30,h-110z', 'data-codecept-selector-node': 'path',
            'data-codecept-selector-file': 'addToScheduleIcon'
        }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'addToScheduleIcon'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'addToScheduleIcon'), _React$createElement14)),
        _react2.default.createElement('path', (_React$createElement15 = { d: 'M380,310v110,h-30,v-110z', 'data-codecept-selector-node': 'path',
            'data-codecept-selector-file': 'addToScheduleIcon'
        }, _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'addToScheduleIcon'), _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'addToScheduleIcon'), _React$createElement15))
    );
};