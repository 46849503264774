'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _AppBar = require('material-ui/AppBar');

var _AppBar2 = _interopRequireDefault(_AppBar);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _close = require('material-ui/svg-icons/navigation/close');

var _close2 = _interopRequireDefault(_close);

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

var _Dialog = require('material-ui/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _CircularProgress = require('material-ui/CircularProgress');

var _CircularProgress2 = _interopRequireDefault(_CircularProgress);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//const rubyLogger = require('@rubyapps/ruby-logger');
//const logger = rubyLogger.getLogger('ruby-component-alert-dialog:reactComponents/AlertDialog');

var _ = require('lodash');

var PropTypes = _react2.default.PropTypes;

var action = require('../action');

var styles = {
    title: {}
};

var AlertDialogHeader = _react2.default.createClass({
    displayName: 'AlertDialogHeader',

    propTypes: {
        title: PropTypes.string,
        delegateClose: PropTypes.func
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4;

        var myContext = this;
        var styleColor = {
            color: '#ffffff'
        };

        return _react2.default.createElement(_AppBar2.default, (_React$createElement4 = {
            title: _react2.default.createElement(
                'span',
                (_React$createElement = { style: styles.title, 'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'AlertDialog'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'AlertDialog'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'AlertDialog'), _React$createElement),
                this.props.title || "Alert"
            ),
            iconElementLeft: _react2.default.createElement(
                _IconButton2.default,
                (_React$createElement3 = { onClick: this.props.delegateClose, 'data-codecept-selector-node': 'IconButton',
                    'data-codecept-selector-file': 'AlertDialog'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'AlertDialog'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'AlertDialog'), _React$createElement3),
                _react2.default.createElement(_close2.default, (_React$createElement2 = {
                    'data-codecept-selector-node': 'NavigationClose',
                    'data-codecept-selector-file': 'AlertDialog'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'NavigationClose'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'AlertDialog'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'NavigationClose'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'AlertDialog'), _React$createElement2))
            ),
            'data-codecept-selector-node': 'AppBar',
            'data-codecept-selector-file': 'AlertDialog'
        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'AlertDialog'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'AlertDialog'), _React$createElement4));
    }
});

var AlertDialog = _react2.default.createClass({
    displayName: 'AlertDialog',

    propTypes: {
        //# state-like
        open: PropTypes.bool,
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])

        //# delegate Actions
        , delegateClose: PropTypes.func,
        delegateSubmit: PropTypes.func
    },

    render: function render() {
        var _React$createElement5, _extends2, _React$createElement6, _React$createElement7, _React$createElement8, _React$createElement9;

        var actions = [_react2.default.createElement(_FlatButton2.default, (_React$createElement5 = {
            label: this.props.buttonLabel || 'Close',
            primary: true,
            onTouchTap: this.props.delegateClose,
            'data-codecept-selector-node': 'FlatButton',
            'data-codecept-selector-file': 'AlertDialog'
        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'AlertDialog'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'AlertDialog'), _React$createElement5))];

        var commentDialogHeaderProps = _.pick(this.props, Object.keys(AlertDialogHeader.propTypes));

        return _react2.default.createElement(
            _Dialog2.default,
            (_React$createElement9 = {
                title: _react2.default.createElement(AlertDialogHeader, _extends({}, commentDialogHeaderProps, (_extends2 = {
                    'data-codecept-selector-node': 'AlertDialogHeader',
                    'data-codecept-selector-file': 'AlertDialog'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'AlertDialogHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'AlertDialog'), _defineProperty(_extends2, 'data-codecept-selector-node', 'AlertDialogHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'AlertDialog'), _extends2))),
                modal: false,
                actions: actions,
                open: this.props.open,
                autoScrollBodyContent: true,
                onRequestClose: this.props.delegateClose,
                bodyStyle: {
                    overflowX: 'hidden'
                },
                'data-codecept-selector-node': 'Dialog',
                'data-codecept-selector-file': 'AlertDialog'
            }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'AlertDialog'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'AlertDialog'), _React$createElement9),
            this.props.loading ? _react2.default.createElement(
                'div',
                (_React$createElement7 = { style: { textAlign: 'center', marginTop: '24px' }, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'AlertDialog'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'AlertDialog'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'AlertDialog'), _React$createElement7),
                _react2.default.createElement(_CircularProgress2.default, (_React$createElement6 = {
                    'data-codecept-selector-node': 'CircularProgress',
                    'data-codecept-selector-file': 'AlertDialog'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'CircularProgress'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'AlertDialog'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'CircularProgress'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'AlertDialog'), _React$createElement6))
            ) : _react2.default.createElement(
                'div',
                (_React$createElement8 = { style: { marginTop: '24px' }, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'AlertDialog'
                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'AlertDialog'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'AlertDialog'), _React$createElement8),
                this.props.content
            )
        );
    }
});
exports.default = AlertDialog;