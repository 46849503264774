'use strict';

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

module.exports = {
    propTypes: {
        keepToolbarOpen: PropTypes.bool
    },
    getDefaultProps: function getDefaultProps() {
        return {
            keepToolbarOpen: false
        };
    }
};