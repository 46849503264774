'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _GooglePreviewConnector = require('./reactComponents/GooglePreviewConnector');

var _GooglePreviewConnector2 = _interopRequireDefault(_GooglePreviewConnector);

var _reducer = require('./reducer');

var _reducer2 = _interopRequireDefault(_reducer);

var _action = require('./action');

var _action2 = _interopRequireDefault(_action);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Promise = require('bluebird');
var _ = require('lodash');
var url = require('url');
var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var FieldForm = require('@rubyapps/ruby-component-field-form');

var CONSTANTS = require('../common/constants');
var COMPONENT_NAME = CONSTANTS.COMPONENT_NAME,
    BASIS_KEY_FOR_URL_IDENTIFIER = CONSTANTS.BASIS_KEY_FOR_URL_IDENTIFIER,
    VALUE_MAP_FN_FOR_URL_IDENTIFIER = CONSTANTS.VALUE_MAP_FN_FOR_URL_IDENTIFIER,
    GET_DEFAULT_PROPS = CONSTANTS.GET_DEFAULT_PROPS;


var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');

var RubyComponentFrontendSettings = require('@rubyapps/ruby-component-frontend-settings');
var RubyComponentResourceLocator = require('@rubyapps/ruby-component-plugin-resource-locator');

var FieldExpandedData = require('@rubyapps/ruby-component-field-expanded-data');
var FieldText = require('@rubyapps/ruby-component-field-text');
var FieldAutoPopulatedText = require('@rubyapps/ruby-component-field-auto-populated-text');
var FieldTextarea = require('@rubyapps/ruby-component-field-textarea');

var RCGooglePreview = RubyComponent.createClass({
    mixins: [baseFieldMixin],
    propTypes: {
        //# some are already included by the baseFieldMixin

        previewUrl: PropTypes.string
    },
    componentName: COMPONENT_NAME,
    getDefaultProps: GET_DEFAULT_PROPS,
    action: _action2.default,
    reducer: _reducer2.default,
    dependencies: function dependencies() {
        var _this = this;

        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();
        var parentForm = this.findAncestorBy(function (module) {
            return module.componentName == FieldForm.componentName;
        });

        var nameComponent = parentForm.findDescendentBy(function (module) {
            return module.props.key == _this.props.keyForNameComponent;
        });
        if (!nameComponent) {
            nameComponent = this; //# default to self
            //# NOTE:  we don't want to throw an error because this component is now used in the TemplateBuilder and users can select it
            //# so we need to have sane defaults
            console.error("[ruby-component-field-google-preview] This component depends on a 'name' field, but could not find one.\n" + "Please edit this template and add a field that has an API ID of 'name'.");
        }

        var nameSelector = nameComponent.getDefaultSelector();

        var formComponent = this.getRubyComponentAtPath('< .rubyComponentFieldForm');
        var formComponentName = formComponent.props.name;

        var languageNamespaceSelectorComponent = formComponent.findChildrenBy(function (element) {
            return element.componentName == 'rubyComponentFieldNamespaceSelector';
        })[0];

        var frontendSettingsID = this.props.frontendSettingsID || RubyComponentFrontendSettings.CONSTANTS.COMPONENT_NAME;
        var frontendSettingsComponent = root.findDescendentByID(frontendSettingsID);
        var frontendSettingsSelector = frontendSettingsComponent.getDefaultSelector();

        var rubyClientFKSelector = root.findDescendentByID('rubyComponentRubyClientFK').getDefaultSelector();

        var routeParent = this.findAncestorBy(function (module) {
            return module.getRouteElement;
        });
        var templateKeyword = routeParent.getState().routeParams.template;

        var selfAction = this.getAction();

        var browserPageTitleComponent = this.findChildBy(function (element) {
            return element.props.key == 'browser_page_title';
        });
        var browserPageTitleSelector = browserPageTitleComponent.getDefaultSelector();

        var urlIdentifierComponent = this.findChildBy(function (element) {
            return element.props.key == 'url_identifier';
        });
        var urlIdentifierSelector = urlIdentifierComponent.getDefaultSelector();

        var nameRelativeToLanguageSelector = languageNamespaceSelectorComponent ? nameComponent.getSelectorRelativeToComponent(languageNamespaceSelectorComponent) : undefined;
        var browserPageTitleRelativeToLanguageSelector = languageNamespaceSelectorComponent ? browserPageTitleComponent.getSelectorRelativeToComponent(languageNamespaceSelectorComponent) : undefined;
        var urlIdentifierRelativeToLanguageSelector = languageNamespaceSelectorComponent ? urlIdentifierComponent.getSelectorRelativeToComponent(languageNamespaceSelectorComponent) : undefined;

        var resourceLocatorComponent = root.findDescendentByID(RubyComponentResourceLocator.CONSTANTS.COMPONENT_NAME);

        return {
            selfSelector: selfSelector,
            selfAction: selfAction,
            routeParent: routeParent,
            parentForm: parentForm,
            nameSelector: nameSelector,
            nameComponent: nameComponent,
            frontendSettingsSelector: frontendSettingsSelector,
            rubyClientFKSelector: rubyClientFKSelector,
            formComponentName: formComponentName,
            templateKeyword: templateKeyword,
            browserPageTitleSelector: browserPageTitleSelector,
            urlIdentifierSelector: urlIdentifierSelector,
            languageNamespaceSelectorComponent: languageNamespaceSelectorComponent,
            nameRelativeToLanguageSelector: nameRelativeToLanguageSelector,
            browserPageTitleRelativeToLanguageSelector: browserPageTitleRelativeToLanguageSelector,
            urlIdentifierRelativeToLanguageSelector: urlIdentifierRelativeToLanguageSelector,
            resourceLocatorComponent: resourceLocatorComponent
        };
    },
    children: function children() {
        var parentID = this.getID();

        return [RubyComponent.createElement(FieldExpandedData, {
            id: '__url_poller',
            watch: {
                interval: 5000
            },
            unwatch: {
                stopOnMatchLBFilter: {
                    where: {
                        url: { neq: null }
                    }
                }
            },
            url: '/ruby/api/v2/content/:id/getResourceLinkInfo',
            mode: 'control'
        }), RubyComponent.createElement(FieldAutoPopulatedText, _extends({
            id: parentID + '.browser_page_title',
            suggestedMode: true
        }, this.props.childrenPropsByKey.browser_page_title)), RubyComponent.createElement(FieldAutoPopulatedText, _extends({
            id: parentID + '.url_identifier'
        }, this.props.childrenPropsByKey.url_identifier)), RubyComponent.createElement(FieldTextarea, _extends({
            id: parentID + '.redirect_from_url'
        }, this.props.childrenPropsByKey.redirect_from_url)), RubyComponent.createElement(FieldTextarea, _extends({
            id: parentID + '.meta_description',
            suggestedValue: {
                path: '< .rubyComponentFieldForm > [props.key=main_content]',
                method: ['getTextSummary', { sentenceCount: 3 }]
            }
        }, this.props.childrenPropsByKey.meta_description)), RubyComponent.createElement(FieldText, _extends({
            id: parentID + '.meta_keywords'
        }, this.props.childrenPropsByKey.meta_keywords))];
    },
    getReactClass: function getReactClass() {
        return _GooglePreviewConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var GooglePreviewComponent = this.getReactClass();

        return React.createElement(GooglePreviewComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'GooglePreviewComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'GooglePreviewComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'GooglePreviewComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS,
    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector,
            nameSelector = _getDependencies.nameSelector,
            parentForm = _getDependencies.parentForm;

        return {
            self: selfSelector(state),
            isActive: this.isFieldActiveForNamespace_fromState(state),
            nameSelector: nameSelector(state),
            pristineFormData: parentForm.getState().pristineFormData
        };
    },

    getPreviewUrl: function getPreviewUrl() {
        var _getDependencies2 = this.getDependencies(),
            parentForm = _getDependencies2.parentForm;

        //# This can be replaced with prop-hydration in later versions of RubyApps where
        //# `getStateAtKeypath` is defined for `ruby-component`


        var defaultPreviewUrl = _.result(parentForm, 'getState.pristineFormData.url');

        //# support overwrite via prop hydration
        var previewUrl = this.getProps(true).previewUrl || defaultPreviewUrl;

        return previewUrl;
    },
    _generateUrlBasedOnFields: function _generateUrlBasedOnFields(dispatch) {
        var _getDependencies3 = this.getDependencies(),
            selfAction = _getDependencies3.selfAction,
            selfSelector = _getDependencies3.selfSelector,
            templateKeyword = _getDependencies3.templateKeyword,
            nameSelector = _getDependencies3.nameSelector,
            frontendSettingsSelector = _getDependencies3.frontendSettingsSelector,
            languageNamespaceSelectorComponent = _getDependencies3.languageNamespaceSelectorComponent,
            urlIdentifierSelector = _getDependencies3.urlIdentifierSelector,
            nameRelativeToLanguageSelector = _getDependencies3.nameRelativeToLanguageSelector,
            urlIdentifierRelativeToLanguageSelector = _getDependencies3.urlIdentifierRelativeToLanguageSelector,
            resourceLocatorComponent = _getDependencies3.resourceLocatorComponent;

        var actions = selfAction.generators;

        var store = this.getStore();
        var applicationState = store.getState();
        var selfState = selfSelector(applicationState);
        var frontendSettings = frontendSettingsSelector(applicationState);
        var languages = frontendSettings.languages;

        var defaultLanguage = languages.filter(function (language) {
            return language.isDefault;
        })[0];

        var _ref = this.statesSelector(this.getState()) || {},
            pristineFormData = _ref.pristineFormData;

        var prevGeneratedUrl = _.get(selfState, 'cachedValues.generatedUrl');

        var previewUrl = this.getPreviewUrl();

        if (!previewUrl) {
            prevGeneratedUrl !== null && store.dispatch(actions.setGeneratedUrl(null));
            return;
        }

        var parsedPreviewUrl = previewUrl ? url.parse(previewUrl) : {};
        var _parsedPreviewUrl$pat = parsedPreviewUrl.pathname,
            pathname = _parsedPreviewUrl$pat === undefined ? '' : _parsedPreviewUrl$pat;


        var preferPlainUris = _.get(resourceLocatorComponent, 'props.preferPlainUris');

        var nameState = void 0;
        var pageName = void 0;
        var urlIdentifierState = void 0;
        var urlIdentifier = void 0;
        var isMultilingualPath = false;
        var activeNamespace = void 0;
        if (languageNamespaceSelectorComponent) {
            var languageNamespaceState = languageNamespaceSelectorComponent.getStateForNamespace(defaultLanguage.value);
            activeNamespace = languageNamespaceSelectorComponent.getActiveNamespace();
            nameState = nameRelativeToLanguageSelector(languageNamespaceState);
            urlIdentifierState = urlIdentifierRelativeToLanguageSelector(languageNamespaceState);
            isMultilingualPath = doesPathContainLanguage(pathname, languages);
        } else {
            nameState = nameSelector(applicationState);
            urlIdentifierState = urlIdentifierSelector(applicationState);
        }

        pageName = _.get(nameState, 'fields.name.value') || '';

        urlIdentifier = _.get(urlIdentifierState, 'fields.url_identifier.value') || VALUE_MAP_FN_FOR_URL_IDENTIFIER(pageName);

        var pathPieces = pathname.split('/').filter(function (val) {
            return val != '';
        });
        if (isMultilingualPath) {
            pathPieces[0] = activeNamespace;
        }
        var dynamicPieceIdx = void 0;

        if (urlIdentifier === "index") {
            //# just assume that it's 'index.html'
            dynamicPieceIdx = pathPieces.length - 1;
        } else {
            dynamicPieceIdx = pathPieces[pathPieces.length - 1] === 'index.html' ? pathPieces.length - 2 : pathPieces.length - 1;
        }
        if (templateKeyword === 'root' && dynamicPieceIdx > 0) {
            dynamicPieceIdx--;
        }

        pathPieces[dynamicPieceIdx] = dynamicPieceIdx == pathPieces.length - 1 ? urlIdentifier + '.html' : '' + urlIdentifier;

        var baseUrl = pathPieces.join('/');

        var baseUrlWithSlash = baseUrl[0] === '/' ? baseUrl : '/' + baseUrl;

        var maybePlainBaseUrlWithSlash = preferPlainUris ? resourceLocatorComponent.makePlainUri(baseUrlWithSlash) : baseUrlWithSlash;

        var formattedUrl = url.format(_extends({}, parsedPreviewUrl, {
            pathname: maybePlainBaseUrlWithSlash
        }));

        var generatedUrl = formattedUrl && formattedUrl !== '/' ? formattedUrl : null;

        if (prevGeneratedUrl != generatedUrl) {
            store.dispatch(actions.setGeneratedUrl(generatedUrl));
        }
    },
    onReduxInit: function onReduxInit(dispatch) {
        var _this2 = this;

        var store = this.getStore();

        // We need to update the SEO widget's URL
        // On page save or when we arrive at a different edit page
        return store.subscribe(function () {
            _this2._generateUrlBasedOnFields(dispatch);
        });
    },

    getRenderedUrl: function getRenderedUrl() {
        var _getState = this.getState(),
            generatedUrl = _getState.cachedValues.generatedUrl;

        return generatedUrl;
    }

    //== OVERRIDES ======================//
    , _promisedFormValueFromFieldSpecs: function _promisedFormValueFromFieldSpecs() {
        //const formValue = baseFieldMixin._promisedFormValueFromFieldSpecs.apply(this, arguments);
        var faker = require('@rubyapps/ruby-faker');
        return Promise.resolve({
            browser_page_title: faker.random.uuid(),
            url_identifier: faker.helpers.slugify(faker.commerce.productName()),
            meta_description: faker.random.uuid(),
            meta_keywords: faker.random.uuid()
        });
    }
});

//# does path contain language
function doesPathContainLanguage(path, languages) {
    if (path == undefined || languages == undefined) {
        return false;
    }
    var pathPieces = path.split('/').filter(function (value) {
        return value != '';
    });
    var potentialLanguagePath = pathPieces[0];

    var selectedLanguage = languages.filter(function (langOption) {
        return langOption.value == potentialLanguagePath;
    });

    return selectedLanguage.length > 0;
}

module.exports = RCGooglePreview;