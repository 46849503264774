'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _AdvancedSearchUtilityBar_style = require('./AdvancedSearchUtilityBar_style');

var _AdvancedSearchUtilityBar_style2 = _interopRequireDefault(_AdvancedSearchUtilityBar_style);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _client = require('@rubyapps/ruby-component-field-date-picker/src/client');

var _client2 = _interopRequireDefault(_client);

var _DatePicker = require('@rubyapps/ruby-component-field-date-picker/src/client/reactComponents/DatePicker');

var _DatePicker2 = _interopRequireDefault(_DatePicker);

var _client3 = require('@rubyapps/ruby-component-field-time-picker/src/client');

var _client4 = _interopRequireDefault(_client3);

var _TimePicker = require('@rubyapps/ruby-component-field-time-picker/src/client/reactComponents/TimePicker');

var _TimePicker2 = _interopRequireDefault(_TimePicker);

var _client5 = require('@rubyapps/ruby-component-field-datetime-picker/src/client');

var _client6 = _interopRequireDefault(_client5);

var _Selectize = require('@rubyapps/ruby-react-components/src/client/input/Selectize');

var _Selectize2 = _interopRequireDefault(_Selectize);

var _optionsUrl__extractParams = require('../../../../../ruby-form-js-to-json-schema/src/common/optionsUrl__extractParams.js');

var _optionsUrl__extractParams2 = _interopRequireDefault(_optionsUrl__extractParams);

var _matchConstants = require('../../../common/matchConstants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var numeral = require('numeral');

var NumberField = _TextField2.default;

//import DatePicker from 'material-ui/DatePicker';

var RouteParser = require('@rubyapps/route-parser');

function _matchOptionsForDatelikeInput(inputGenerator) {
    return {
        matchOptions: [{
            value: _matchConstants.matchConstants.IS_EQUAL_TO__DATE,
            text: _matchConstants.matchLabels.IS_EQUAL_TO,
            searchInputs: [inputGenerator]
        }, {
            value: _matchConstants.matchConstants.IS_NOT_EQUAL_TO__DATE,
            text: _matchConstants.matchLabels.IS_NOT_EQUAL_TO,
            searchInputs: [inputGenerator]
        }, {
            value: _matchConstants.matchConstants.IS_AFTER__DATE,
            text: _matchConstants.matchLabels.IS_AFTER,
            searchInputs: [inputGenerator]
        }, {
            value: _matchConstants.matchConstants.IS_BEFORE__DATE,
            text: _matchConstants.matchLabels.IS_BEFORE,
            searchInputs: [inputGenerator]
        }]
    };
}

/**
 *  fieldSpec.listerConfig.matchSpec - used to define overrides to be used here (introduced: e1e173b0fba1aa6e58ec52667f48dd0807121c9b)
 */
function matchSpecForSelfAndFieldSpec(self) {
    var fieldSpec = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var data_type = fieldSpec.data_type;
    var primitiveDatatype = _lodash2.default.isArray(data_type) ? data_type[0] : data_type;
    var componentName = _lodash2.default.get(fieldSpec, 'listerConfig.matchSpec.componentName', fieldSpec.componentName);

    var matchSpec = _lodash2.default.get(fieldSpec, 'listerConfig.matchSpec', {});

    //# TODO: IDEA: defer generating the components to the individual RubyComponents
    //# we need to eventually do this to support fields injected by plugins
    var urlProp = fieldSpec.url;
    var optionsProp = fieldSpec.options;

    //# handle special cases first
    if (urlProp || optionsProp) {
        //# assume that we're referencing other model instances
        //searchText={this.state.searchInputQuery}
        //onUpdateInput={this._delegatedSearchInput__onUpdateInput}

        var urlParams = urlProp && (0, _optionsUrl__extractParams2.default)(urlProp);
        var urlEndPointMethod = _lodash2.default.get(urlParams, 'endpointMethod');

        var dataFromListerFilters = self.props.griddleProps.utilityBarProperties.customFilters;

        var hydratedUrlProp = urlProp ? RouteParser(urlProp).reverse(dataFromListerFilters) : urlProp;

        //# NOTE: Currently - the 'profileOptions' endpoint provides data that
        //# looks like:
        //#
        //# {
        //#     data: [
        //#         {
        //#             text: ...       // profile template name
        //#             , type: ...     // profile template key
        //#             , value: [...]  // profile template fields
        //#         }
        //#         , ...
        //#     ]
        //# }
        //#
        //# so let's use the `type` prop as the `value` key in the Selectize
        var dataSourceConfig = matchSpec.dataSourceConfig || {
            text: 'text',
            value: urlEndPointMethod && urlEndPointMethod === 'profileOptions' ? 'type' : 'value'
        };

        var fieldSpecPropsForSelectize = _lodash2.default.omit(_lodash2.default.pick(fieldSpec, Object.keys(_Selectize2.default.propTypes)), ['options']);

        var displayTextFromOption = function displayTextFromOption(opt) {
            return opt.text || opt.primaryText || opt.label || opt.value;
        };

        var tokenTaggerForUrl_generator = function tokenTaggerForUrl_generator(props) {
            var _extends2;

            return _react2.default.createElement(_Selectize2.default, _extends({}, _AdvancedSearchUtilityBar_style2.default.Selectize__rightMargin, {
                valueStyleObject: function valueStyleObject() {
                    var baseTheme = this.context.muiTheme.baseTheme;

                    var primary2Color = baseTheme.palette.primary2Color;

                    return {
                        backgroundColor: primary2Color
                    };
                },
                hintText: 'Look up ' + fieldSpec.label,
                menuCloseDelay: 0,
                limit: Infinity,
                dataSource: optionsProp || [],
                dataSourceConfig: dataSourceConfig,
                openOnFocus: true,
                canAutoPosition: false,
                filter: function filter(searchText, key, dataSourceObject) {
                    //# NOTE: DEPRECATED 20201208... looks like this was the filter method for the previous dopdown component... should remove

                    var isStringFound = false;
                    if (_lodash2.default.isString(searchText)) {
                        isStringFound = key.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
                    }
                    return isStringFound;
                    //# note: searchText should never be the value from the dataSource, it should always be the Text
                    /*
                    const dataSourceConfig = this.dataSourceConfig;
                    let isNumberFound = false;
                    const searchTextAsNumber = Number(searchText);
                    if (!Number.isNaN(searchTextAsNumber)) {
                        isNumberFound = searchTextAsNumber == Number(dataSourceObject[dataSourceConfig.value]);
                    }
                     return isStringFound || isNumberFound;
                    */
                },
                filterOptions: function filterOptions(options, values, search) {
                    if (urlProp) {
                        //# let url search handle
                        return options;
                    }

                    if (arguments.length == 2) {
                        search = values;
                        values = props.value;
                    }

                    if (_lodash2.default.isNil(search)) {
                        return options;
                    }

                    return options.filter(function (option) {
                        return displayTextFromOption(option).toLowerCase().indexOf(search.toLowerCase()) > -1;
                    });
                },
                fullWidth: true,
                onOptionSelected: function onOptionSelected(selectedOptions) {
                    //# need to flatted selectedOptions as a singular object
                    var valueKey = dataSourceConfig.value;

                    var normalizedSearchSpec = {
                        text: selectedOptions.map(function (option) {
                            return option.text;
                        }).join(', '),
                        value: selectedOptions.map(function (option) {
                            return option[valueKey];
                        }),
                        rawValue: selectedOptions
                    };

                    self._delegatedSearchInput__onChange(normalizedSearchSpec);
                },
                onOptionCleared: function onOptionCleared() {
                    self._delegatedSearchInput__onChange(null);
                }
            }, fieldSpecPropsForSelectize, (_extends2 = {
                url: hydratedUrlProp //# need to override the selected fieldSpecPropsForSelectize.url since we're hydrating it
                , 'data-codecept-selector-node': 'Selectize',
                'data-codecept-selector-file': 'matchSpecForFieldSpec'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Selectize'), _defineProperty(_extends2, 'data-codecept-selector-file', 'matchSpecForFieldSpec'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Selectize'), _defineProperty(_extends2, 'data-codecept-selector-file', 'matchSpecForFieldSpec'), _extends2)));
        };

        var fieldSpec__limit = fieldSpec.limit || (!_lodash2.default.isArray(data_type) ? 1 : undefined);

        if (fieldSpec__limit == 1) {
            return {
                matchOptions: [
                /* //# No longer support singular 'is' as IS_ONE_OF will also work for the singular value case
                {
                    value: matchConstants.IS
                    , text: matchLabels.IS
                    , searchInputs: [tokenTaggerForUrl_generator]
                }
                , */{
                    value: _matchConstants.matchConstants.IS_ONE_OF,
                    text: _matchConstants.matchLabels.IS_ONE_OF,
                    searchInputs: [tokenTaggerForUrl_generator]
                }, {
                    value: _matchConstants.matchConstants.IS_NOT,
                    text: _matchConstants.matchLabels.IS_NOT,
                    searchInputs: [tokenTaggerForUrl_generator]
                }, {
                    value: _matchConstants.matchConstants.IS_EMPTY,
                    text: _matchConstants.matchLabels.IS_EMPTY
                }, {
                    value: _matchConstants.matchConstants.IS_NOT_EMPTY,
                    text: _matchConstants.matchLabels.IS_NOT_EMPTY
                }]
            };
        } else {
            //# Multiselect
            return {
                matchOptions: [{
                    value: _matchConstants.matchConstants.CONTAINS,
                    text: _matchConstants.matchLabels.CONTAINS,
                    searchInputs: [tokenTaggerForUrl_generator]
                }, {
                    value: _matchConstants.matchConstants.CONTAINS_ALL_OF,
                    text: _matchConstants.matchLabels.CONTAINS_ALL_OF,
                    searchInputs: [tokenTaggerForUrl_generator]
                }, {
                    value: _matchConstants.matchConstants.DOES_NOT_CONTAINS,
                    text: _matchConstants.matchLabels.DOES_NOT_CONTAINS,
                    searchInputs: [tokenTaggerForUrl_generator]
                }, {
                    value: _matchConstants.matchConstants.IS_EMPTY__ARRAY,
                    text: _matchConstants.matchLabels.IS_EMPTY
                }, {
                    value: _matchConstants.matchConstants.IS_NOT_EMPTY__ARRAY,
                    text: _matchConstants.matchLabels.IS_NOT_EMPTY
                }]
            };
        }
    } else if (componentName == 'DatePicker' || componentName == 'ComponentizedDatePicker') {
        var shouldNestInObject = componentName == 'ComponentizedDatePicker';
        //label="Filter term"
        var datePicker_generator = function datePicker_generator(props) {
            var _extends3;

            return _react2.default.createElement(_DatePicker2.default, _extends({}, _AdvancedSearchUtilityBar_style2.default.Generic__rightMargin, (_extends3 = {
                styles: {
                    DatePickerWrapper: {
                        style: _extends({
                            marginTop: '-25px',
                            position: 'relative'
                        }, _AdvancedSearchUtilityBar_style2.default.Generic__rightMargin.style)
                    }
                },
                actions: {
                    setFieldValueByKey: function setFieldValueByKey(newValue, fieldKey) {
                        if (_lodash2.default.isNil(newValue)) {
                            return self._delegatedSearchInput__onChange(null);
                        }

                        var formFriendly_newValue = _client2.default.formValueFromFieldValue_forKey(newValue, fieldKey);
                        var displayFriendly_newValue = _client2.default.prettyDate(newValue);

                        var normalizedNewValue = {
                            value: shouldNestInObject ? { 'date': formFriendly_newValue } : formFriendly_newValue,
                            text: displayFriendly_newValue,
                            rawValue: newValue
                        };

                        self._delegatedSearchInput__onChange(normalizedNewValue);
                    }
                },
                value: props ? _lodash2.default.get(props, 'rawValue') || _client2.default.fieldValueFromFormValue_forKey(_lodash2.default.get(props, 'value')) : null,
                'data-codecept-selector-node': 'DatePicker',
                'data-codecept-selector-file': 'matchSpecForFieldSpec'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'DatePicker'), _defineProperty(_extends3, 'data-codecept-selector-file', 'matchSpecForFieldSpec'), _defineProperty(_extends3, 'data-codecept-selector-node', 'DatePicker'), _defineProperty(_extends3, 'data-codecept-selector-file', 'matchSpecForFieldSpec'), _extends3)));
        };
        return _matchOptionsForDatelikeInput(datePicker_generator);
    } else if (componentName == 'TimePicker') {
        var timePicker_generator = function timePicker_generator(props) {
            var _extends4;

            return _react2.default.createElement(_TimePicker2.default, _extends({}, _AdvancedSearchUtilityBar_style2.default.Generic__rightMargin, (_extends4 = {
                styles: {
                    TimePickerWrapper: {
                        style: _extends({
                            marginTop: '-25px',
                            position: 'relative'
                        }, _AdvancedSearchUtilityBar_style2.default.Generic__rightMargin.style)
                    }
                },
                actions: {
                    setFieldValueByKey: function setFieldValueByKey(newValue, fieldKey) {
                        if (_lodash2.default.isNil(newValue)) {
                            return self._delegatedSearchInput__onChange(null);
                        }

                        var formFriendly_newValue = _client4.default.formValueFromFieldValue_forKey(newValue, fieldKey);
                        var displayFriendly_newValue = _client4.default.prettyTimeFromFormValue(formFriendly_newValue);

                        var normalizedNewValue = {
                            value: formFriendly_newValue,
                            text: displayFriendly_newValue,
                            rawValue: newValue
                        };

                        self._delegatedSearchInput__onChange(normalizedNewValue);
                    }
                },
                value: props ? _lodash2.default.get(props, 'rawValue') || _client4.default.fieldValueFromFormValue_forKey(_lodash2.default.get(props, 'value')) : null,
                'data-codecept-selector-node': 'TimePicker',
                'data-codecept-selector-file': 'matchSpecForFieldSpec'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'TimePicker'), _defineProperty(_extends4, 'data-codecept-selector-file', 'matchSpecForFieldSpec'), _defineProperty(_extends4, 'data-codecept-selector-node', 'TimePicker'), _defineProperty(_extends4, 'data-codecept-selector-file', 'matchSpecForFieldSpec'), _extends4)));
        };
        return _matchOptionsForDatelikeInput(timePicker_generator);
    } else if (componentName == 'DatetimePicker') {
        var DatetimePicker__dataType = data_type || 'object';
        var DatetimePicker__isDataTypeString = DatetimePicker__dataType == 'string';
        //label="Filter term"
        var datetimePicker_generator = function datetimePicker_generator(props) {
            var _extends5, _extends6, _React$createElement;

            return _react2.default.createElement(
                'div',
                (_React$createElement = { style: { display: 'flex' }, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'matchSpecForFieldSpec'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'matchSpecForFieldSpec'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'matchSpecForFieldSpec'), _React$createElement),
                _react2.default.createElement(_DatePicker2.default, _extends({}, _AdvancedSearchUtilityBar_style2.default.Generic__rightMargin, (_extends5 = {
                    styles: {
                        DatePickerWrapper: {
                            style: _extends({
                                marginTop: '-25px',
                                position: 'relative'
                            }, _AdvancedSearchUtilityBar_style2.default.Generic__rightMargin.style)
                        }
                    },
                    actions: {
                        setFieldValueByKey: function setFieldValueByKey(newValue, fieldKey) {
                            if (_lodash2.default.isNil(newValue)) {
                                return self._delegatedSearchInput__onChange(null);
                            }

                            var formFriendly_newValue = _client2.default.formValueFromFieldValue_forKey(newValue, fieldKey);

                            var mergedFormValue = _extends({}, { date: formFriendly_newValue }, { time: _lodash2.default.get(props, 'formValue__time', {}) });
                            var prettyDatetime = _client6.default.prettyDatetimeFromDateAndTimeFormValue(mergedFormValue);
                            var datetimeFormValue = _client6.default.datetimeFormValueFromDateAndTimeFormValue(mergedFormValue) || {};

                            var normalizedNewValue = _extends({}, props, {
                                value: DatetimePicker__isDataTypeString ? datetimeFormValue.datetime : datetimeFormValue,
                                text: prettyDatetime,
                                rawValue__date: newValue,
                                formValue__date: formFriendly_newValue
                            });

                            self._delegatedSearchInput__onChange(normalizedNewValue);
                        }
                    },
                    value: props ? _lodash2.default.get(props, 'rawValue__date') : null,
                    'data-codecept-selector-node': 'DatePicker',
                    'data-codecept-selector-file': 'matchSpecForFieldSpec'
                }, _defineProperty(_extends5, 'data-codecept-selector-node', 'DatePicker'), _defineProperty(_extends5, 'data-codecept-selector-file', 'matchSpecForFieldSpec'), _defineProperty(_extends5, 'data-codecept-selector-node', 'DatePicker'), _defineProperty(_extends5, 'data-codecept-selector-file', 'matchSpecForFieldSpec'), _extends5))),
                _react2.default.createElement(_TimePicker2.default, _extends({}, _AdvancedSearchUtilityBar_style2.default.Generic__rightMargin, (_extends6 = {
                    styles: {
                        TimePickerWrapper: {
                            style: _extends({
                                marginTop: '-25px',
                                position: 'relative'
                            }, _AdvancedSearchUtilityBar_style2.default.Generic__rightMargin.style)
                        }
                    },
                    actions: {
                        setFieldValueByKey: function setFieldValueByKey(newValue, fieldKey) {
                            if (_lodash2.default.isNil(newValue)) {
                                return self._delegatedSearchInput__onChange(null);
                            }

                            var formFriendly_newValue = _client4.default.formValueFromFieldValue_forKey(newValue, fieldKey);

                            var mergedFormValue = _extends({}, { date: _lodash2.default.get(props, 'formValue__date', null) }, { time: formFriendly_newValue || {} });
                            var prettyDatetime = _client6.default.prettyDatetimeFromDateAndTimeFormValue(mergedFormValue);

                            var datetimeFormValue = _client6.default.datetimeFormValueFromDateAndTimeFormValue(mergedFormValue) || {};

                            var normalizedNewValue = _extends({}, props, {
                                value: DatetimePicker__isDataTypeString ? datetimeFormValue.datetime : datetimeFormValue,
                                text: prettyDatetime,
                                rawValue__time: newValue,
                                formValue__time: formFriendly_newValue
                            });

                            self._delegatedSearchInput__onChange(normalizedNewValue);
                        }
                    },
                    value: props ? _lodash2.default.get(props, 'rawValue__time') : null,
                    'data-codecept-selector-node': 'TimePicker',
                    'data-codecept-selector-file': 'matchSpecForFieldSpec'
                }, _defineProperty(_extends6, 'data-codecept-selector-node', 'TimePicker'), _defineProperty(_extends6, 'data-codecept-selector-file', 'matchSpecForFieldSpec'), _defineProperty(_extends6, 'data-codecept-selector-node', 'TimePicker'), _defineProperty(_extends6, 'data-codecept-selector-file', 'matchSpecForFieldSpec'), _extends6)))
            );
        };
        return _matchOptionsForDatelikeInput(datetimePicker_generator);
    } else if (primitiveDatatype == 'boolean') {
        return {
            matchOptions: [{
                value: _matchConstants.matchConstants.IS_TRUE,
                text: _matchConstants.matchLabels.IS_TRUE
            }, {
                value: _matchConstants.matchConstants.IS_FALSE,
                text: _matchConstants.matchLabels.IS_FALSE
            }]
        };
    } else if (primitiveDatatype == 'number') {
        var numberField_generator = function numberField_generator(props) {
            var _extends7;

            return _react2.default.createElement(NumberField, _extends({}, _AdvancedSearchUtilityBar_style2.default.Generic__rightMargin, (_extends7 = {
                onChange: function onChange(event, newValue) {

                    var number_parsedFloat = Number.parseFloat(newValue);
                    var number_numeral = numeral(newValue);
                    var number_numeralValue = number_numeral.value();
                    var number_Number = Number(newValue);

                    //# if parsed and numeral is the same, then we need to check if Number(newValue) is valid
                    //# if it isn't valid, then the number isn't valid
                    //# eg. 1kb is not a valid numeral and you'll get '1' out of it
                    //#     1kb is '1' in parseFloat
                    //#     1kb is NaN is Number()
                    //#     it is an invalid number
                    //
                    //# eg. 1KB is 1000 in numeral()
                    //#     1KB is 1 in parseFloat()
                    //#     it is a valid number
                    //
                    //# eg. '1.' is 1 in numeral
                    //#     '1.' is 1 in parseFloat()
                    //#     '1.' is 1 in Number()
                    //#     is it a valid number

                    var resolvedValue = number_parsedFloat == number_numeralValue ? number_Number : number_numeralValue;
                    var isValidNumber = !isNaN(resolvedValue);

                    var normalizedNewValue = {
                        value: resolvedValue,
                        text: newValue
                    };

                    //# NOTE: allow it to go through but we can't allow adding to filter (the add to filter button is disabled)
                    self._delegatedSearchInput__onChange(normalizedNewValue);
                },
                value: props ? props.text : null,
                'data-codecept-selector-node': 'NumberField',
                'data-codecept-selector-file': 'matchSpecForFieldSpec'
            }, _defineProperty(_extends7, 'data-codecept-selector-node', 'NumberField'), _defineProperty(_extends7, 'data-codecept-selector-file', 'matchSpecForFieldSpec'), _defineProperty(_extends7, 'data-codecept-selector-node', 'NumberField'), _defineProperty(_extends7, 'data-codecept-selector-file', 'matchSpecForFieldSpec'), _extends7)));
        };
        return {
            matchOptions: [{
                value: _matchConstants.matchConstants.IS_EQUAL_TO,
                text: _matchConstants.matchLabels.IS_EQUAL_TO,
                searchInputs: [numberField_generator]
            }, {
                value: _matchConstants.matchConstants.IS_NOT_EQUAL_TO,
                text: _matchConstants.matchLabels.IS_NOT_EQUAL_TO,
                searchInputs: [numberField_generator]
            }, {
                value: _matchConstants.matchConstants.IS_GREATER_THAN,
                text: _matchConstants.matchLabels.IS_GREATER_THAN,
                searchInputs: [numberField_generator]
            }, {
                value: _matchConstants.matchConstants.IS_GREATER_THAN_OR_EQUAL_TO,
                text: _matchConstants.matchLabels.IS_GREATER_THAN_OR_EQUAL_TO,
                searchInputs: [numberField_generator]
            }, {
                value: _matchConstants.matchConstants.IS_LESS_THAN,
                text: _matchConstants.matchLabels.IS_LESS_THAN,
                searchInputs: [numberField_generator]
            }, {
                value: _matchConstants.matchConstants.IS_LESS_THAN_OR_EQUAL_TO,
                text: _matchConstants.matchLabels.IS_LESS_THAN_OR_EQUAL_TO,
                searchInputs: [numberField_generator]
            }, {
                value: _matchConstants.matchConstants.IS_EMPTY__NUMBER,
                text: _matchConstants.matchLabels.IS_EMPTY
            }, {
                value: _matchConstants.matchConstants.IS_NOT_EMPTY__NUMBER,
                text: _matchConstants.matchLabels.IS_NOT_EMPTY
            }]
        };
    } else {
        //# default to string
        //hintText="Filter term"
        var textField_generator = function textField_generator(props) {
            var _extends8;

            return _react2.default.createElement(_TextField2.default, _extends({}, _AdvancedSearchUtilityBar_style2.default.Generic__rightMargin, (_extends8 = {
                onChange: function onChange(event, newValue) {
                    var normalizedNewValue = {
                        value: newValue,
                        text: newValue
                    };

                    //self._delegatedSearchInput__onUpdateInput(newValue);
                    self._delegatedSearchInput__onChange(normalizedNewValue);
                },
                value: props ? props.value : null,
                'data-codecept-selector-node': 'TextField',
                'data-codecept-selector-file': 'matchSpecForFieldSpec'
            }, _defineProperty(_extends8, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends8, 'data-codecept-selector-file', 'matchSpecForFieldSpec'), _defineProperty(_extends8, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends8, 'data-codecept-selector-file', 'matchSpecForFieldSpec'), _extends8)));
        };
        return {
            matchOptions: [{
                value: _matchConstants.matchConstants.CONTAINS__STRING,
                text: _matchConstants.matchLabels.CONTAINS__STRING,
                searchInputs: [textField_generator]
            }, {
                value: _matchConstants.matchConstants.DOES_NOT_CONTAIN__STRING,
                text: _matchConstants.matchLabels.DOES_NOT_CONTAIN,
                searchInputs: [textField_generator]

                /* //# NOTE: not implemented, probably not necessary
                , {
                    //value: matchConstants.IS_EQUAL_TO__STRING
                    value: matchConstants.IS
                    , text: matchLabels.IS
                    , searchInputs: [textField_generator]
                }
                , {
                    //value: matchConstants.IS_NOT_EQUAL_TO__STRING
                    value: matchConstants.IS_NOT
                    , text: matchLabels.IS_NOT
                    , searchInputs: [textField_generator]
                }
                */
            }, {
                value: _matchConstants.matchConstants.IS_EMPTY__STRING,
                text: _matchConstants.matchLabels.IS_EMPTY
            }, {
                value: _matchConstants.matchConstants.IS_NOT_EMPTY__STRING,
                text: _matchConstants.matchLabels.IS_NOT_EMPTY
            }]
        };
    }
};

exports.default = matchSpecForSelfAndFieldSpec;