'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _VirtualMenu = require('@rubyapps/ruby-material-ui/VirtualMenu');

var _VirtualMenu2 = _interopRequireDefault(_VirtualMenu);

var _Popover = require('material-ui/Popover/Popover');

var _Popover2 = _interopRequireDefault(_Popover);

var _reactVirtualized = require('react-virtualized');

var _WrappedMenuItem = require('./WrappedMenuItem');

var _WrappedMenuItem2 = _interopRequireDefault(_WrappedMenuItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ROOT_MENU_DEPTH = 0;

var AutoCompleteMenu = (_temp = _class = function (_React$Component) {
  _inherits(AutoCompleteMenu, _React$Component);

  function AutoCompleteMenu(props) {
    _classCallCheck(this, AutoCompleteMenu);

    var _this = _possibleConstructorReturn(this, (AutoCompleteMenu.__proto__ || Object.getPrototypeOf(AutoCompleteMenu)).call(this, props));

    _this.generateHandleMouseEnter = _this.generateHandleMouseEnter.bind(_this);
    _this.generateHandleMouseLeave = _this.generateHandleMouseLeave.bind(_this);
    _this.generateHandleFocusStateChange = _this.generateHandleFocusStateChange.bind(_this);
    _this.renderMenuItem = _this.renderMenuItem.bind(_this);
    _this.onMenuItemRef = _this.onMenuItemRef.bind(_this);
    _this.onPopoverRef = _this.onPopoverRef.bind(_this);

    _this.anchorElementsByKey = {};
    return _this;
  }

  _createClass(AutoCompleteMenu, [{
    key: 'generateHandleMouseEnter',
    value: function generateHandleMouseEnter(key, depth) {
      var _this2 = this;

      return function (ev) {
        _this2.props.onMenuItemMouseEnter && _this2.props.onMenuItemMouseEnter(key, depth, ev);
      };
    }
  }, {
    key: 'generateHandleMouseLeave',
    value: function generateHandleMouseLeave(key, depth) {
      var _this3 = this;

      return function (ev) {
        _this3.props.onMenuItemMouseLeave && _this3.props.onMenuItemMouseLeave(key, depth, ev);
      };
    }
  }, {
    key: 'generateHandleFocusStateChange',
    value: function generateHandleFocusStateChange(key, depth) {
      var _this4 = this;

      return function (nextFocusState, prevFocusState) {
        _this4.props.onMenuItemFocusStateChange && _this4.props.onMenuItemFocusStateChange(key, depth, nextFocusState, prevFocusState);
      };
    }
  }, {
    key: 'onMenuItemRef',
    value: function onMenuItemRef(reactKey) {
      var _this5 = this;

      return function (el) {
        _this5.anchorElementsByKey[reactKey] = _reactDom2.default.findDOMNode(el);
      };
    }
  }, {
    key: 'onPopoverRef',
    value: function onPopoverRef(popoverEl) {
      popoverEl && this.props.canAutoPosition && this.props.open && setTimeout(function () {
        _lodash2.default.get(popoverEl, 'refs.layer') && popoverEl.setPlacement();
      });
    }
  }, {
    key: 'renderMenuItem',
    value: function renderMenuItem(menuItem) {
      var _this6 = this,
          _React$createElement;

      var depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ROOT_MENU_DEPTH;
      var _menuItem$menuItems = menuItem.menuItems,
          menuItems = _menuItem$menuItems === undefined ? [] : _menuItem$menuItems,
          reactKey = menuItem.reactKey,
          _menuItem$menuItemPro = menuItem.menuItemProps,
          menuItemProps = _menuItem$menuItemPro === undefined ? {} : _menuItem$menuItemPro;


      var anchorEl = this.anchorElementsByKey[reactKey] ? this.anchorElementsByKey[reactKey] : document.getElementById(reactKey);

      var open = !_lodash2.default.isEmpty(menuItems) && this.props.openMenuItems[depth] === reactKey && anchorEl;

      var menuItemStyle = _extends({}, menuItemProps.style ? menuItemProps.style : {}, open ? { backgroundColor: _lodash2.default.get(this.context, 'muiTheme.palette.primary4Color') } : {});

      return _react2.default.createElement(
        _WrappedMenuItem2.default,
        (_React$createElement = {
          id: reactKey,
          key: reactKey,
          onMouseEnter: this.generateHandleMouseEnter(reactKey, depth),
          onMouseLeave: this.generateHandleMouseLeave(reactKey, depth),
          onFocusStateChange: this.generateHandleFocusStateChange(reactKey, depth),
          style: open ? { backgroundColor: _lodash2.default.get(this.context, 'muiTheme.palette.primary4Color') } : {},
          ref: this.onMenuItemRef(reactKey),
          menuItemProps: _extends({
            style: menuItemStyle
          }, menuItemProps),
          'data-codecept-selector-node': 'WrappedMenuItem',
          'data-codecept-selector-file': 'AutoCompleteMenu'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'WrappedMenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'AutoCompleteMenu'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'WrappedMenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'AutoCompleteMenu'), _React$createElement),
        function () {
          if (menuItems.length) {
            var _extends2;

            return _react2.default.createElement(AutoCompleteMenu, _extends({}, _this6.props, (_extends2 = {
              //# NOTE: For nested `AutocompleteMenu`s, we want Material UI to
              //# automatically reposition the Popover components. Otherwise, it
              //# is possible that the Popover component might become occluded
              //# by the browser's window frames - therefore, we set `canAutoPosition`
              //# property to true
              canAutoPosition: true,
              anchorEl: anchorEl,
              anchorOrigin: { horizontal: 'right', vertical: 'top' }
              //# TODO need to tweak `disableAutoFocus` so that it's tied to
              //# whether the keyboard is active in this menu
              , disableAutoFocus: true,
              initiallyKeyboardFocused: false,
              menuDepth: depth + 1,
              menuItems: menuItems,
              open: open,
              'data-codecept-selector-node': 'AutoCompleteMenu',
              'data-codecept-selector-file': 'AutoCompleteMenu'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'AutoCompleteMenu'), _defineProperty(_extends2, 'data-codecept-selector-file', 'AutoCompleteMenu'), _defineProperty(_extends2, 'data-codecept-selector-node', 'AutoCompleteMenu'), _defineProperty(_extends2, 'data-codecept-selector-file', 'AutoCompleteMenu'), _extends2)));
          }
        }()
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var _this7 = this,
          _React$createElement5;

      var _props = this.props,
          anchorOrigin = _props.anchorOrigin,
          anchorEl = _props.anchorEl,
          animated = _props.animated,
          animation = _props.animation,
          canAutoPosition = _props.canAutoPosition,
          disableAutoFocus = _props.disableAutoFocus,
          initiallyKeyboardFocused = _props.initiallyKeyboardFocused,
          listStyle = _props.listStyle,
          menuProps = _props.menuProps,
          menuItems = _props.menuItems,
          menuDepth = _props.menuDepth,
          open = _props.open,
          onItemTouchTap = _props.onItemTouchTap,
          onEscKeyDown = _props.onEscKeyDown,
          onRequestClose = _props.onRequestClose,
          popoverProps = _props.popoverProps,
          targetOrigin = _props.targetOrigin,
          width = _props.width;


      var PopoverRenderer = function PopoverRenderer(_ref) {
        var _extends3, _React$createElement2, _extends4;

        var autoSizerWidth = _ref.width;

        //console.log(`===== autoSizerWidth`, autoSizerWidth)

        return _react2.default.createElement(
          _Popover2.default,
          _extends({
            canAutoPosition: canAutoPosition,
            anchorOrigin: anchorOrigin,
            targetOrigin: targetOrigin,
            open: open,
            anchorEl: anchorEl,
            useLayerForClickAway: false,
            onRequestClose: onRequestClose,
            animated: animated,
            animation: animation,
            ref: _this7.onPopoverRef
          }, popoverProps, (_extends4 = {
            'data-codecept-selector-node': 'Popover',
            'data-codecept-selector-file': 'AutoCompleteMenu'
          }, _defineProperty(_extends4, 'data-codecept-selector-node', 'Popover'), _defineProperty(_extends4, 'data-codecept-selector-file', 'AutoCompleteMenu'), _defineProperty(_extends4, 'data-codecept-selector-node', 'Popover'), _defineProperty(_extends4, 'data-codecept-selector-file', 'AutoCompleteMenu'), _extends4)),
          _react2.default.createElement(
            'div',
            (_React$createElement2 = { onMouseDown: function onMouseDown(ev) {
                return ev.preventDefault();
              }, id: 'test', 'data-codecept-selector-node': 'div',
              'data-codecept-selector-file': 'AutoCompleteMenu'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'AutoCompleteMenu'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'AutoCompleteMenu'), _React$createElement2),
            _react2.default.createElement(
              _VirtualMenu2.default,
              _extends({
                autoWidth: false,
                disableAutoFocus: disableAutoFocus,
                onEscKeyDown: onEscKeyDown,
                initiallyKeyboardFocused: initiallyKeyboardFocused,
                onItemTouchTap: onItemTouchTap,
                listStyle: _extends({}, listStyle)
              }, menuProps, (_extends3 = {
                virtualMenuMinWidth: autoSizerWidth,
                onMouseEnter: _this7.props.onMouseEnter,
                onMouseLeave: _this7.props.onMouseLeave,
                ref: function ref(el) {
                  if (el) {
                    _this7._VirtualMenu = el;
                  }
                },
                'data-codecept-selector-node': 'VirtualMenu',
                'data-codecept-selector-file': 'AutoCompleteMenu'
              }, _defineProperty(_extends3, 'data-codecept-selector-node', 'VirtualMenu'), _defineProperty(_extends3, 'data-codecept-selector-file', 'AutoCompleteMenu'), _defineProperty(_extends3, 'data-codecept-selector-node', 'VirtualMenu'), _defineProperty(_extends3, 'data-codecept-selector-file', 'AutoCompleteMenu'), _extends3)),
              menuItems.map(function (menuItem) {
                return _this7.renderMenuItem(menuItem, menuDepth);
              })
            )
          )
        );
      };

      if (!_lodash2.default.isEmpty(menuItems) && open) {
        if (width) {
          var _React$createElement3;

          //# Still need autosizer to get animation working correctly
          //# but we're using the width override
          return _react2.default.createElement(
            _reactVirtualized.AutoSizer,
            (_React$createElement3 = { disableHeight: true, 'data-codecept-selector-node': 'AutoSizer',
              'data-codecept-selector-file': 'AutoCompleteMenu'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'AutoSizer'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'AutoCompleteMenu'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'AutoSizer'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'AutoCompleteMenu'), _React$createElement3),
            function () {
              return PopoverRenderer({ width: width });
            }
          );
        } else {
          var _React$createElement4;

          return _react2.default.createElement(
            _reactVirtualized.AutoSizer,
            (_React$createElement4 = { disableHeight: true, 'data-codecept-selector-node': 'AutoSizer',
              'data-codecept-selector-file': 'AutoCompleteMenu'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'AutoSizer'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'AutoCompleteMenu'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'AutoSizer'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'AutoCompleteMenu'), _React$createElement4),
            PopoverRenderer
          );
        }
      } else {
        return null;
      }
      return !_lodash2.default.isEmpty(menuItems) && open && _react2.default.createElement(
        _reactVirtualized.AutoSizer,
        (_React$createElement5 = { disableHeight: true, 'data-codecept-selector-node': 'AutoSizer',
          'data-codecept-selector-file': 'AutoCompleteMenu'
        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'AutoSizer'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'AutoCompleteMenu'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'AutoSizer'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'AutoCompleteMenu'), _React$createElement5),
        PopoverRenderer
      );
    }
  }]);

  return AutoCompleteMenu;
}(_react2.default.Component), _class.propTypes = {
  width: _react.PropTypes.number
}, _class.defaultProps = {
  menuDepth: ROOT_MENU_DEPTH,
  menuItems: [],
  menuProps: {},
  popoverProps: {}
}, _class.contextTypes = {
  muiTheme: _react.PropTypes.object.isRequired
}, _temp);
exports.default = AutoCompleteMenu;