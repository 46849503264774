'use strict';

var _reactRouterRedux = require('react-router-redux');

var CONSTANTS = require('../../common/constants');
var path = require('path');
var TYPES = CONSTANTS.ROUTE_ACTION_TYPES;

var _ = require('lodash');
var queryString = require('query-string');

function formattedUrlForPayload(payload) {
    var _payload$path = payload.path,
        path = _payload$path === undefined ? window.location.pathname : _payload$path,
        query = payload.query,
        hash = payload.hash;


    var formattedQuery = query ? queryString.stringify(query) : undefined;
    var formattedHash = hash ? queryString.stringify(_.omit(hash, ['__original'])) : undefined;

    return '' + path + (formattedQuery ? '?' + formattedQuery : '') + (formattedHash ? '#' + formattedHash : '');
}

//# 2018103
//# NOTE: we should not use these actions in non route components.
//# we have this here for backwards compatibility with older components
module.exports = function () {
    var localTypes = {};

    return {
        TYPES: localTypes,
        generators: {

            /**
             *  @param {String} payload.path - path to navigate to
             *  @param {Object} payload.hash - hash object where key-value pairs mirror query params. So the formatted hash will look like: #param=value&param=value
             *  @param {boolean} options.ignoreChanges - skip checking for changes. Use this only when you know you've saved the page
             */
            navigateToPathWithOptions: function navigateToPathWithOptions(payload, callback) {
                var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

                var selfModule = this;

                var _selfModule$getAction = selfModule.getAction(),
                    actions = _selfModule$getAction.generators;

                var confirmDialog = selfModule.getRubyComponentAtPath('.rubyComponentConfirmDialog');

                var confirmDialogActions = confirmDialog.getAction().generators;

                var activeRouteComponent = selfModule.getActiveRouteComponent();

                var canEdit = activeRouteComponent && activeRouteComponent.hasUnsavedChanges && activeRouteComponent.canEdit();
                var hasUnsavedChanges = activeRouteComponent && activeRouteComponent.hasUnsavedChanges && activeRouteComponent.hasUnsavedChanges();

                var wrappedCallback = function wrappedCallback() {
                    var count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

                    if (selfModule.getActiveRouteComponent() == undefined) {
                        //# NOTE: not the best way to determine whether the route has changed 
                        //# but the route is changed on LOCATION_CHANGE action
                        //# which we have no control over with regards to providing additional parameters in the payload
                        if (count < 3) {
                            //# Arbitrary, try 5 times
                            setTimeout(function () {
                                wrappedCallback(count++);
                            }, 200);
                        }
                    } else {
                        callback && callback();
                    }
                };

                return function (dispatch, getState) {
                    var _options$ignoreChange = options.ignoreChanges,
                        ignoreChanges = _options$ignoreChange === undefined ? false : _options$ignoreChange;
                    //# get active route component
                    //# routeComponent && routeComponent.hasUnsavedChanges() 
                    //# then show dialog
                    //# Only perform this check if we're leaving the page
                    //# it could be the case that we're navigating to the same path but changing hashes

                    var isNavigatingToSamePath = !payload.path || payload.path == window.location.pathname;

                    if (!ignoreChanges && !isNavigatingToSamePath && canEdit && hasUnsavedChanges) {
                        dispatch(confirmDialogActions.openDialogWithOptions({
                            title: 'You have unsaved changes',
                            content: 'Are you sure you want to leave the page?',
                            submitLabel: 'Save & continue',
                            submitSuccessCallback: function submitSuccessCallback() {
                                activeRouteComponent.saveChanges_usingOptions({
                                    successCallback: function successCallback(responseBody) {
                                        dispatch((0, _reactRouterRedux.push)(formattedUrlForPayload(payload)));

                                        callback && wrappedCallback();
                                    }
                                });
                            },
                            submitLabel_secondary: 'Continue',
                            submitSuccessCallback_secondary: function submitSuccessCallback_secondary() {
                                dispatch((0, _reactRouterRedux.push)(formattedUrlForPayload(payload)));

                                callback && wrappedCallback();
                            }
                        }));
                    } else {
                        dispatch((0, _reactRouterRedux.push)(formattedUrlForPayload(payload)));

                        callback && wrappedCallback();
                    }
                };
            },
            replacePathWithOptions: function replacePathWithOptions(payload) {
                return (0, _reactRouterRedux.replace)(formattedUrlForPayload(payload));
            }

            //# DEPRECATING 20201027 - should always use the 'react-router-redux' actions. Let our middleware handle converting the url to routeParams appropriately
            , setHashForActiveRouteComponent: function setHashForActiveRouteComponent(hash) {
                var selfModule = this;

                return function (dispatch) {
                    setImmediate(function () {
                        var activeRouteComponent = selfModule.getActiveRouteComponent();

                        dispatch(activeRouteComponent.getAction().generators.setHash(hash));
                    });
                };
            }
        }
    };
};