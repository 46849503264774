'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Dialog = require('material-ui/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _AppBar = require('material-ui/AppBar');

var _AppBar2 = _interopRequireDefault(_AppBar);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _close = require('material-ui/svg-icons/navigation/close');

var _close2 = _interopRequireDefault(_close);

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

var _RaisedButton = require('material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _CircularProgress = require('material-ui/CircularProgress');

var _CircularProgress2 = _interopRequireDefault(_CircularProgress);

var _Module = require('./Module.cssModule');

var _Module2 = _interopRequireDefault(_Module);

var _Module_style = require('./Module_style');

var _Module_style2 = _interopRequireDefault(_Module_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;


var request = require('@rubyapps/ruby-superagent');

var getStyles = function getStyles(props, context, state) {
    var _context$muiTheme = context.muiTheme,
        baseTheme = _context$muiTheme.baseTheme,
        errorColor = _context$muiTheme.textField.errorColor;


    var styles = {
        error: {
            color: errorColor
        }
    };

    _extends(styles.error, props.errorStyle);

    return styles;
};

function _getBoxSizeForElement(element) {
    var style = window.getComputedStyle(element, null);
    var width = parseInt(style.getPropertyValue('width'));
    var height = parseInt(style.getPropertyValue('height'));
    return {
        width: isNaN(width) ? 0 : width,
        height: isNaN(height) ? 0 : height
    };
}

var DialogHeader = _react2.default.createClass({
    displayName: 'DialogHeader',

    propTypes: {
        title: PropTypes.string,
        delegateClose: PropTypes.func
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4;

        if (!this.props.isActive) {
            return null;
        }

        var myContext = this;
        return _react2.default.createElement(_AppBar2.default, (_React$createElement4 = {
            title: _react2.default.createElement(
                'span',
                (_React$createElement = { className: _Module2.default.title, 'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'Module'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Module'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Module'), _React$createElement),
                this.props.title || "Module"
            ),
            iconElementLeft: _react2.default.createElement(
                _IconButton2.default,
                (_React$createElement3 = { onClick: this.props.delegateClose, 'data-codecept-selector-node': 'IconButton',
                    'data-codecept-selector-file': 'Module'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Module'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Module'), _React$createElement3),
                _react2.default.createElement(_close2.default, (_React$createElement2 = {
                    'data-codecept-selector-node': 'NavigationClose',
                    'data-codecept-selector-file': 'Module'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'NavigationClose'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Module'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'NavigationClose'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Module'), _React$createElement2))
            ),
            'data-codecept-selector-node': 'AppBar',
            'data-codecept-selector-file': 'Module'
        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Module'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Module'), _React$createElement4));
    }
});

var Module = _react2.default.createClass({
    displayName: 'Module',

    propTypes: {
        label: PropTypes.string,
        open: PropTypes.bool,
        styleObject: PropTypes.object,
        actionButtons: PropTypes.node,
        childProps: PropTypes.object //# Object keyed by component name
        , leftIcon: PropTypes.node //# typicaly drag indicator
        , actions: PropTypes.object
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            childProps: {
                Dialog: {}
            }
        };
    },
    getInitialState: function getInitialState() {
        return {
            isImageLoading: false
        };
    },
    _handleRequestOpen: function _handleRequestOpen() {
        this.props.actions.openDialogAndSnapshotState();
    },
    _handleRequestDone: function _handleRequestDone() {
        this.props.actions.closeAndResetDialog();
    },
    _handleRequestCancel: function _handleRequestCancel() {
        this.props.actions.closeAndRevertStateAndResetDialog();
    },
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        if (!nextProps.stateSnapshot) {
            if (nextProps.previewUrl) {
                if (this.props.previewUrl != nextProps.previewUrl) {
                    this.setState({
                        isImageLoading: true
                    });
                }
            } else {
                this.setState({
                    isImageLoading: false
                });
            }
        }
    },
    render: function render() {
        var _React$createElement5,
            _React$createElement6,
            _this = this,
            _React$createElement8,
            _React$createElement9,
            _React$createElement10,
            _extends2,
            _React$createElement11,
            _React$createElement12,
            _React$createElement13,
            _React$createElement14,
            _React$createElement15,
            _React$createElement16,
            _extends3,
            _React$createElement17;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var actions = [_react2.default.createElement(_FlatButton2.default, (_React$createElement5 = {
            label: 'Cancel',
            onTouchTap: this._handleRequestCancel,
            'data-codecept-selector-node': 'FlatButton',
            'data-codecept-selector-file': 'Module'
        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Module'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Module'), _React$createElement5)), _react2.default.createElement(_FlatButton2.default, (_React$createElement6 = {
            label: 'Done',
            primary: true,
            onTouchTap: this._handleRequestDone,
            'data-codecept-selector-node': 'FlatButton',
            'data-codecept-selector-file': 'Module'
        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Module'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Module'), _React$createElement6))];

        var mergedStyleObject = _extends({}, _Module_style2.default, this.props.styleObject); //# shallow merge

        var prepareStyles = this.context.muiTheme.prepareStyles;

        var muiStyleObject = getStyles(this.props, this.context, this.state);
        var errorTextElement = null;

        if (this.props.error && this.props.error.message) {
            var _React$createElement7;

            errorTextElement = _react2.default.createElement(
                'div',
                (_React$createElement7 = {
                    className: _Module2.default.errorContainer,
                    style: prepareStyles(muiStyleObject.error),
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Module'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Module'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Module'), _React$createElement7),
                this.props.error.message
            );
        }

        var _props$childProps$Dia = this.props.childProps.Dialog,
            dialogProps = _props$childProps$Dia === undefined ? {} : _props$childProps$Dia;

        //# TODO: left icon and the actionButtons

        var previewImage = this.props.previewUrl;
        //const previewImage = `https://placeholdit.imgix.net/~text?txtsize=33&txt=${this.props.label}&w=350&h=150`;
        var imageElement = previewImage ? _react2.default.createElement('img', (_React$createElement8 = {
            ref: function ref(imgEl) {
                if (imgEl) {
                    imgEl.addEventListener('load', function () {
                        if (_this.isMounted()) {
                            _this.setState({ isImageLoading: false });
                            //this._componentSizeChanged();
                        }
                    });
                }
            },
            className: _Module2.default.moduleImage,
            src: previewImage,
            'data-codecept-selector-node': 'img',
            'data-codecept-selector-file': 'Module'
        }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'img'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Module'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'img'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Module'), _React$createElement8)) : null;

        var leftIcon = this.props.leftIcon ? _react2.default.createElement(
            'div',
            (_React$createElement9 = { className: _Module2.default.leftIconContainer, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Module'
            }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Module'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Module'), _React$createElement9),
            this.props.leftIcon
        ) : null;

        var actionButtons = this.props.actionButtons ? _react2.default.createElement(
            'div',
            (_React$createElement10 = { className: _Module2.default.actionButtonsContainer, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Module'
            }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'Module'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'Module'), _React$createElement10),
            this.props.actionButtons
        ) : null;
        return _react2.default.createElement(
            'div',
            (_React$createElement17 = { className: _Module2.default.module, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Module'
            }, _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'Module'), _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'Module'), _React$createElement17),
            _react2.default.createElement(
                'div',
                (_React$createElement14 = { className: _Module2.default.moduleBox, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Module'
                }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'Module'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'Module'), _React$createElement14),
                imageElement,
                errorTextElement,
                _react2.default.createElement(
                    'div',
                    (_React$createElement11 = { className: _Module2.default.moduleBoxHoverContainer, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'Module'
                    }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'Module'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'Module'), _React$createElement11),
                    leftIcon,
                    actionButtons,
                    _react2.default.createElement(_RaisedButton2.default, _extends({}, mergedStyleObject.EditButton, (_extends2 = {
                        label: 'Edit',
                        onTouchTap: this._handleRequestOpen,
                        'data-codecept-selector-node': 'RaisedButton',
                        'data-codecept-selector-file': 'Module'
                    }, _defineProperty(_extends2, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Module'), _defineProperty(_extends2, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Module'), _extends2)))
                ),
                this.state.isImageLoading ? _react2.default.createElement(
                    'div',
                    (_React$createElement13 = { className: _Module2.default.progressContainer, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'Module'
                    }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'Module'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'Module'), _React$createElement13),
                    _react2.default.createElement(_CircularProgress2.default, (_React$createElement12 = {
                        'data-codecept-selector-node': 'CircularProgress',
                        'data-codecept-selector-file': 'Module'
                    }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'CircularProgress'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'Module'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'CircularProgress'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'Module'), _React$createElement12))
                ) : null
            ),
            _react2.default.createElement(
                _Dialog2.default,
                _extends({}, mergedStyleObject.Dialog, (_extends3 = {
                    title: _react2.default.createElement(DialogHeader, (_React$createElement15 = {
                        delegateClose: this._handleRequestCancel,
                        title: this.props.label,
                        'data-codecept-selector-node': 'DialogHeader',
                        'data-codecept-selector-file': 'Module'
                    }, _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'DialogHeader'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'Module'), _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'DialogHeader'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'Module'), _React$createElement15)),
                    modal: true,
                    actions: actions,
                    open: this.props.open,
                    autoScrollBodyContent: true,
                    onRequestClose: this._handleRequestCancel,
                    'data-codecept-selector-node': 'Dialog',
                    'data-codecept-selector-file': 'Module'
                }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Module'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Module'), _extends3)),
                _react2.default.createElement(
                    'div',
                    (_React$createElement16 = {
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'Module'
                    }, _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'Module'), _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'Module'), _React$createElement16),
                    this.props.children
                )
            )
        );
    },

    componentWillUnmount: function componentWillUnmount() {
        //console.log('[componentWillUnmount()]', this.props.id);
        this.props.componentWillUnmount && this.props.componentWillUnmount();
    }

    /* //# DEPRECATED 20161017
    , componentDidUpdate: function(prevProps) {
        if (
            prevProps.isExpanded != this.props.isExpanded ||
            prevProps.containerWidth != this.props.containerWidth
        ) {
            if (this.props.onSizeChange) {
                const selfEl = ReactDOM.findDOMNode(this);
                const boxSize = _getBoxSizeForElement(selfEl);
                this.props.onSizeChange(boxSize);
            }
        }
    }
     //# Custom Methods
    , _componentSizeChanged: function() {
        if (this.props.onSizeChange) {
            const selfEl = ReactDOM.findDOMNode(this);
            const boxSize = _getBoxSizeForElement(selfEl);
            this.props.onSizeChange(boxSize);
        }
    }
    */
});

exports.default = Module;