'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _SelectColumnsToolbarGroup = require('@rubyapps/ruby-component-mixin-list-page/src/client/reactComponents/AdvancedSearchUtilityBar/SelectColumnsToolbarGroup');

var _SelectColumnsToolbarGroup2 = _interopRequireDefault(_SelectColumnsToolbarGroup);

var _columnDefinitionsFromSearchableFields = require('@rubyapps/ruby-component-mixin-list-page/src/client/columnDefinitionsFromSearchableFields');

var _columnDefinitionsFromSearchableFields2 = _interopRequireDefault(_columnDefinitionsFromSearchableFields);

var _columnSpecFromFieldSpec = require('@rubyapps/ruby-component-mixin-list-page/src/client/reactComponents/AdvancedSearchUtilityBar/columnSpecFromFieldSpec');

var _UtilityToolbarGroup = require('./UtilityToolbarGroup');

var _UtilityToolbarGroup2 = _interopRequireDefault(_UtilityToolbarGroup);

var _styles = require('@rubyapps/ruby-component-mixin-lister-content-defaults/src/client/reactComponents/UtilityBar/styles.cssModule');

var _styles2 = _interopRequireDefault(_styles);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var SelectColumnsToolbarGroup__propTypeKeys = Object.keys(_SelectColumnsToolbarGroup2.default.propTypes);

var UtilityToolbarGroup__propTypeKeys = Object.keys(_UtilityToolbarGroup2.default.propTypes);

//# styles


var UtilityBarIndex = _react2.default.createClass({
    displayName: 'UtilityBarIndex',

    propTypes: _extends({
        griddleProps: PropTypes.object,
        searchableFields__dataSourceConfig: PropTypes.object,
        selectedColumnSpecsArr: PropTypes.arrayOf(PropTypes.object),
        listerConfig: PropTypes.object
    }, _UtilityToolbarGroup2.default.propTypes, _SelectColumnsToolbarGroup2.default.propTypes),
    getDefaultProps: function getDefaultProps() {
        return {
            listerConfig: {},
            numberOfActionButtonsToShow: 0,
            searchableFields__dataSourceConfig: { text: 'label', key: 'dataPath' },
            selectedColumnSpecsArr: []
        };
    },
    getInitialState: function getInitialState() {
        //const filteredColumnProps = this._getFilteredColumnPropertiesFromProps(this.props);
        return {
            isSelectColumnsVisible: false
            //, selectedColumns: this._getColumnKeysOrderFromColumnProperties(filteredColumnProps)
        };
    },
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        //# call on setState for selectedColumns
        var selectedColumnSpecsArr = nextProps.selectedColumnSpecsArr;

        this.setState({
            selectedColumns: selectedColumnSpecsArr.reduce(function (collector, columnSpec) {
                if (columnSpec.key) {
                    collector.push(columnSpec.key);
                }
                return collector;
            }, [])
        });

        this.props.selfModule.setGriddleProps(this.props.griddleProps);
    },
    render: function render() {
        var _extends2, _React$createElement, _extends3, _React$createElement2;

        return _react2.default.createElement(
            'div',
            (_React$createElement2 = { style: { width: '100%' }, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'index'), _React$createElement2),
            _react2.default.createElement(
                'div',
                (_React$createElement = { className: _styles2.default.Header_Filter_UtilityBar__wrapper, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'index'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement),
                this.props.griddleProps.headerLabelElement,
                this.props.griddleProps.filterElement,
                _react2.default.createElement(_UtilityToolbarGroup2.default, _extends({}, _lodash2.default.pick(this.props, UtilityToolbarGroup__propTypeKeys), (_extends2 = {
                    delegateSelectColumnsButtonTap: this._onSelectColumnsButtonTap,
                    'data-codecept-selector-node': 'UtilityToolbarGroup',
                    'data-codecept-selector-file': 'index'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'UtilityToolbarGroup'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'UtilityToolbarGroup'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)))
            ),
            _react2.default.createElement(_SelectColumnsToolbarGroup2.default, _extends({}, _lodash2.default.pick(this.props, SelectColumnsToolbarGroup__propTypeKeys), _lodash2.default.pick(this.state, SelectColumnsToolbarGroup__propTypeKeys), (_extends3 = {
                columnDefinitions: this._columnDefinitionsFromSearchableFields(this.props.searchableFields),
                columnDefinitions__dataSourceConfig: this.props.searchableFields__dataSourceConfig,
                delegateOnColumnToggle: this._onColumnToggle,
                delegateSelectColumnsClose: this._onSelectColumnsClose,
                'data-codecept-selector-node': 'SelectColumnsToolbarGroup',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'SelectColumnsToolbarGroup'), _defineProperty(_extends3, 'data-codecept-selector-file', 'index'), _defineProperty(_extends3, 'data-codecept-selector-node', 'SelectColumnsToolbarGroup'), _defineProperty(_extends3, 'data-codecept-selector-file', 'index'), _extends3)))
        );
    }

    //# == START: Utility for SelectColumns ====//
    ,
    _columnDefinitionsFromSearchableFields: function _columnDefinitionsFromSearchableFields(searchableFields) {
        return (0, _columnDefinitionsFromSearchableFields2.default)(searchableFields);
    },
    _columnDefinitionsByKeyFromColumnDefinitions: function _columnDefinitionsByKeyFromColumnDefinitions(columnDefinitions) {
        var dataSourceConfig = this.props.searchableFields__dataSourceConfig;

        return _lodash2.default.reduce(columnDefinitions, function (collector, columnDefinition) {
            var columnDefinitionKey = columnDefinition[dataSourceConfig.key];
            collector[columnDefinitionKey] = columnDefinition;
            return collector;
        }, {});
    },
    _onSelectColumnsButtonTap: function _onSelectColumnsButtonTap() {
        this._onSelectColumnsVisibilityChange(!this.state.isSelectColumnsVisible);
    },
    _onSelectColumnsClose: function _onSelectColumnsClose() {
        this._onSelectColumnsVisibilityChange(false);
    },
    _onSelectColumnsVisibilityChange: function _onSelectColumnsVisibilityChange(isVisible) {
        this.setState({
            isSelectColumnsVisible: isVisible
        });
    },
    _onColumnToggle: function _onColumnToggle(columnKey, columnFieldSpec) {
        var indexOfColumnKey = this.state.selectedColumns.indexOf(columnKey);
        var newSelectedColumns = void 0;
        if (indexOfColumnKey >= 0) {
            newSelectedColumns = _lodash2.default.without(this.state.selectedColumns, columnKey);
        } else {
            newSelectedColumns = this.state.selectedColumns.concat(columnKey);
        }

        var columnDefinitionsByKey = this._columnDefinitionsByKeyFromColumnDefinitions(this._columnDefinitionsFromSearchableFields(this.props.searchableFields));

        //# get column specs and set the utility bar properties
        var fieldSpecsArr = newSelectedColumns.map(function (selectedColumnKey) {
            return columnDefinitionsByKey[selectedColumnKey];
        }).filter(function (spec) {
            return spec != undefined;
        });

        var selectedColumnSpecsArr = (0, _columnSpecFromFieldSpec.columnSpecsArrForSelf_andFieldSpecs)(this, fieldSpecsArr, this.props.searchableFields__dataSourceConfig, {
            listerConfig: this.props.listerConfig
        });

        var newUtilityBarProperties = _extends({}, this.props.griddleProps.utilityBarProperties || {}, {
            selectedColumnSpecsArr: selectedColumnSpecsArr
        });
        this.props.griddleProps.events.setUtilityBarProperties(newUtilityBarProperties);
        this.props.griddleProps.events.setColumnResizeProperties({}); //# clearing resizeProperties when column count changes

        //# trigger a refresh on the list to grab new data
        this.props.griddleProps.events.refreshCurrentPage();
    }
    //# == END: Utility for SelectColumns ====//

});

exports.default = UtilityBarIndex;