'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _colors = require('./colors');

var Colors = _interopRequireWildcard(_colors);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

exports.default = {
    style: {
        width: 'auto' //# 20170424 - might want to change to 100% 
        //# since all input fields should take up the full width
        , minWidth: 100,
        verticalAlign: 'bottom'
    },
    underlineStyle: {
        borderColor: Colors.black_25
    }
};