'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _FloatingPlusMenu = require('./FloatingPlusMenu');

var _FloatingPlusMenu2 = _interopRequireDefault(_FloatingPlusMenu);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var React = require('react');
var PropTypes = React.PropTypes;

var Placeholder = React.createClass({
    displayName: 'Placeholder',

    propTypes: _extends({
        index: PropTypes.number,
        showMenu: PropTypes.bool
    }, _FloatingPlusMenu2.default.propTypes),
    getDefaultProps: function getDefaultProps() {
        return {};
    },
    render: function render() {
        var _extends2, _extends3;

        var _props = this.props,
            index = _props.index,
            options = _props.options,
            actions = _props.actions,
            rest = _objectWithoutProperties(_props, ['index', 'options', 'actions']);

        var floatingPlusMenuProps = _lodash2.default.pick(this.props, Object.keys(_FloatingPlusMenu2.default.propTypes));
        var showMenu = this.props.showMenu && index == 0;
        return React.createElement(
            'div',
            _extends({}, rest, (_extends3 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Placeholder'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'div'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Placeholder'), _defineProperty(_extends3, 'data-codecept-selector-node', 'div'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Placeholder'), _extends3)),
            showMenu ? React.createElement(_FloatingPlusMenu2.default, _extends({}, floatingPlusMenuProps, (_extends2 = {
                'data-codecept-selector-node': 'FloatingPlusMenu',
                'data-codecept-selector-file': 'Placeholder'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'FloatingPlusMenu'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Placeholder'), _defineProperty(_extends2, 'data-codecept-selector-node', 'FloatingPlusMenu'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Placeholder'), _extends2))) : null
        );
    }
});

exports.default = Placeholder;