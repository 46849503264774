'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var request = require('@rubyapps/ruby-superagent');

function provideOptions(Component) {

    var OptionFetcher = _react2.default.createClass({
        displayName: 'OptionFetcher',

        /*
        state: {
            options //# cached server response options
            values //# selected values
        }
        */
        propTypes: _extends({}, Component.propTypes || {}, {
            defaultValue: PropTypes.node,
            defaultToFirstOption: PropTypes.bool,
            options: PropTypes.array,
            optionsKey: PropTypes.string,
            placeholderValue: PropTypes.any,
            triggerOnChangeDelegateOnLoad: PropTypes.bool,
            dishonorDisabledIfInvalidValue: PropTypes.bool
        }),
        getDefaultProps: function getDefaultProps() {
            return {
                options: [],
                optionsKey: 'options',
                triggerOnChangeDelegateOnLoad: true,
                dishonorDisabledIfInvalidValue: true
            };
        },
        getInitialState: function getInitialState() {
            return {
                options: [],
                valueIsInvalid: undefined
            };
        },
        componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
            if (this.props.url != nextProps.url) {
                this.retrieveRemoteOptions_withProps(nextProps);
            }
            if (this.props.value != nextProps.value) {
                this.setState({
                    value: nextProps.value
                });
            }
        },
        componentDidMount: function componentDidMount() {
            this.retrieveRemoteOptions_withProps(this.props);
        },
        componentDidUpdate: function componentDidUpdate(prevProps, prevState) {
            var stateOptions = this.state.options;
            var _props = this.props,
                defaultValue = _props.defaultValue,
                defaultToFirstOption = _props.defaultToFirstOption;


            var prevOptions = _lodash2.default.get(prevState, 'options');
            var optionsWereUpdated = stateOptions && !_lodash2.default.isEqual(stateOptions, prevOptions);
            if (optionsWereUpdated) {
                this.handleOptionsUpdate();
            }
        },
        handleOptionsUpdate: function handleOptionsUpdate() {
            var options = this.state.options;
            var _props2 = this.props,
                currentValue = _props2.value,
                defaultValue = _props2.defaultValue,
                defaultToFirstOption = _props2.defaultToFirstOption,
                placeholderValue = _props2.placeholderValue;

            var optionForCurrentValue = this.validValueOption_fromOptions(currentValue, options);

            if (!optionForCurrentValue) {
                var validDefaultValueOption = defaultValue ? this.validValueOption_fromOptions(defaultValue, options) : defaultToFirstOption ? options[0] : undefined;

                if (validDefaultValueOption) {
                    this.props.onChange(null, null, validDefaultValueOption.value, validDefaultValueOption);
                } else {
                    //# need to clear out selected value
                    this.props.onChange(null, null, placeholderValue);
                }
            }
        },
        render: function render() {
            var _extends2;

            var options = this.getCachedOptions();
            var preppedComponentProps = {};

            var validValueOption = this.validValueOption_fromOptions(this.props.value, options);
            var disabled = !validValueOption && this.props.dishonorDisabledIfInvalidValue ? false : this.props.disabled;
            preppedComponentProps.disabled = disabled;

            preppedComponentProps[this.props.optionsKey] = options;

            return _react2.default.createElement(Component, _extends({}, this.props, preppedComponentProps, (_extends2 = {
                'data-codecept-selector-node': 'Component',
                'data-codecept-selector-file': 'OptionsFetcher'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Component'), _defineProperty(_extends2, 'data-codecept-selector-file', 'OptionsFetcher'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Component'), _defineProperty(_extends2, 'data-codecept-selector-file', 'OptionsFetcher'), _extends2)));
        }

        //== UTILITIES =============================================//

        ,
        getCachedOptions: function getCachedOptions() {

            if (this.state.options.length) {
                return this.state.options;
            }
            if (this.props.options.length) {
                return this.props.options;
            }

            return [];
        },
        retrieveRemoteOptions_withProps: function retrieveRemoteOptions_withProps(props) {
            var self = this;

            if (this.props.url) {
                return _retrieveRemoteOptions_withProps(props);
            }

            return;
            function _retrieveRemoteOptions_withProps(props) {

                return request //# TODO: note that this is also a smell that we have to be cognizant of how the Store triggers this componentDidUpdate for us to retrieve the data. (conceptually, we should never do this unless we do it all the time regardless of state) 
                .get(props.url).query({
                    ruby_client_fk: props.ruby_client_fk
                }).then(function (resp) {
                    if (resp && resp.body) {

                        if (!self.isMounted()) {
                            return;
                        }
                        var data = _lodash2.default.isArray(resp.body) ? resp.body : resp.body.data;

                        //# normalize the data here
                        var normalizedOptions = data.map(function (item) {
                            return { text: item.name || item.text, value: item.id || item.value };
                        });
                        var mergedOptions = self.props.options.concat(normalizedOptions);
                        self.setState({
                            options: mergedOptions
                        });
                        //# TODO: THIS WILL NOT CAUSE A RERENDER IF called from componentWillReceiveProps()
                        //# this entire HOC is a smell though, so this should be ok after refactor
                        //# BUT if we still need this sort of pattern, make sure we make it stateless by dispatching the options
                        //# back into the Store
                        //
                        //self.setValueAfterRemoteOptions(mergedOptions);
                        //# NOTE: we no longer set value after remote options because there's a race condition
                        //# if new props come in before this completes, when this completes
                        //# it's prop.value would be incorrect
                        //# so instead, we generate the validValueOption dynamically right when we need to consume it
                        //# (so on render)
                    }
                });
            }
        },
        validValueOption_fromOptions: function validValueOption_fromOptions(value, options) {
            var hydratedExistingValue = this.getHydratedValue_fromValue_andOptions(value, options);

            return hydratedExistingValue;
        },
        getHydratedValue_fromValue_andOptions: function getHydratedValue_fromValue_andOptions(value, options) {
            if (_lodash2.default.isEqual(value, this.props.placeholderValue)) {
                return {
                    value: this.props.placeholderValue
                };
            }

            var optionsLength = options.length;
            for (var i = 0; i < optionsLength; i++) {
                var option = options[i];
                if (_lodash2.default.isEqual(option.value, value)) {
                    return option;
                }
            }
            return undefined;
        }
    });

    return OptionFetcher;
}; // provideOptions

exports.default = provideOptions;