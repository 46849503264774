'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = ValueInObjectGenerator;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');
var React = require('react');


var POSSIBLE_NAME_KEYS = ['name', 'full_name', ['first_name', 'last_name'], 'first_name', 'username', 'text'];

function _stringifySingularDataObject(dataObject) {
    if (_.isNil(dataObject)) {
        return null;
    }
    //# look at the posslbe name keys, if there's one, return it
    var name = POSSIBLE_NAME_KEYS.reduce(function (collector, possibleNameKey) {
        if (collector) {
            return collector;
        }

        if (_.isArray(possibleNameKey)) {
            var multipartNameArr = possibleNameKey.reduce(function (collector_1, possibleNameKey_1) {
                var foundValue = dataObject[possibleNameKey_1];
                if (foundValue) {
                    collector_1.push(foundValue);
                }

                return collector_1;
            }, []);

            if (multipartNameArr.length) {
                return multipartNameArr.join(' ');
            }
        }

        return dataObject[possibleNameKey];
    }, undefined);

    if (_.isNil(name)) {
        //# fallback to id
        return dataObject.id;
    } else {
        return name;
    }
}

function _stringifyDataObject(dataObject) {
    if (_.isNil(dataObject)) {
        return null;
    }

    var normalizedDataObjectAsArr = [].concat(dataObject);
    var stringArr = normalizedDataObjectAsArr.map(_stringifySingularDataObject);

    return stringArr.join(', ');
}

/**
 *  @param {object} options - the options from the column definition
 */
function ValueInObjectGenerator(options) {
    /*
    const options__key = options.key;
    const keyPathArr = options__key.split('.');
    */

    /**
     *  @param {props} the props for the specific cell instance (contains data)
     */
    return function ValueInObject(props) {
        var _React$createElement;

        var data = _.get(props, ['data'], '');
        var displayValue = _stringifyDataObject(data);

        return React.createElement(
            'span',
            (_React$createElement = {
                'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'ValueInObjectGenerator'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ValueInObjectGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ValueInObjectGenerator'), _React$createElement),
            displayValue
        );
    };
}