'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GriddleContainer = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _propertyHelper = require('./utils/propertyHelper');

var _propertyHelper2 = _interopRequireDefault(_propertyHelper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function areVisibleColumnsSame(original, next, columns) {
  //use some to return immediately if there is one that doesn't match
  return !columns.some(function (col) {
    return next[col] !== original[col];
  });
}

function areArraysSame(original, next, columns) {
  return !original.some(function (item, index) {
    return !areVisibleColumnsSame(item, next[index], columns);
  });
}

var GriddleContainer = exports.GriddleContainer = function GriddleContainer(Actions) {
  return function (ComposedComponent) {
    var _class, _temp, _initialiseProps;

    var Container = (_temp = _class = function (_Component) {
      _inherits(Container, _Component);

      function Container(props, context) {
        _classCallCheck(this, Container);

        var _this = _possibleConstructorReturn(this, (Container.__proto__ || Object.getPrototypeOf(Container)).call(this, props, context));

        _initialiseProps.call(_this);

        _this.state = {};
        _this.state.actionCreators = (0, _redux.bindActionCreators)(Actions, props.dispatch);

        var properties = _this.getProperties_fromProps(props);
        _this.initializeGrid_withProperties(properties);
        _this.loadData(props, properties);
        return _this;
      }

      _createClass(Container, [{
        key: 'componentWillReceiveProps',
        value: function componentWillReceiveProps(nextProps) {
          //if(nextProps.data !== this.props.data || nextProps.children != this.props.children) {
          if (nextProps.children != this.props.children) {
            var newProperties = this.getProperties_fromProps(nextProps);

            this.initializeGrid_withProperties(newProperties);
          }

          var remoteDataArgsChanged = !_lodash2.default.isEqual(nextProps.remoteDataArgs, this.props.remoteDataArgs);
          var ignoreCache = remoteDataArgsChanged;

          if (nextProps.data !== this.props.data || ignoreCache) {
            this.loadData(nextProps, undefined, ignoreCache);
          }
        }
      }, {
        key: 'render',
        value: function render() {
          var _props = this.props,
              state = _props.state,
              dispatch = _props.dispatch,
              dataKey = _props.dataKey;

          return _react2.default.createElement(ComposedComponent, _extends({}, state, this.state.actionCreators, this.props, {
            data: state[dataKey],
            normalizedData: (0, _propertyHelper.getSimpleNormalizedData)(state),
            'data-codecept-selector-node': 'ComposedComponent',
            'data-codecept-selector-file': 'griddleContainer'
          }));
        }
      }]);

      return Container;
    }(_react.Component), _class.defaultProps = {
      dataKey: 'visibleData'
    }, _initialiseProps = function _initialiseProps() {
      var _this2 = this;

      this.getProperties_fromProps = function (props) {
        return _propertyHelper2.default.propertiesToJS({
          rowProperties: props.children,
          defaultColumns: props.columns,
          ignoredColumns: props.ignoredColumns,
          allColumns: props.data && props.data.length > 0 ? Object.keys(props.data[0]) : []
        });
      };

      this.initializeGrid_withProperties = function (properties) {
        // Initialize the grid.
        _this2.state.actionCreators.initializeGrid(properties);
      };

      this.loadData = function (props, properties, ignoreCache) {
        _this2.state.actionCreators.requestToLoadData(props.data, properties, ignoreCache); //# data may be undefined
      };
    }, _temp);


    function select(state) {
      return {
        state: state.toJSON()
      };
    }

    return (0, _reactRedux.connect)(select)(Container);
  };
};