'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _Divider = require('material-ui/Divider');

var _Divider2 = _interopRequireDefault(_Divider);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function getAdditionalContextMenuItems_withProps(currentMenuItems, props) {
    var _React$createElement, _React$createElement2;

    var allowColumnSelection = _lodash2.default.get(props, 'allowColumnSelection', true);

    var menuItems = _lodash2.default.concat(allowColumnSelection && props.delegateSelectColumnsButtonTap ? _react2.default.createElement(_MenuItem2.default, (_React$createElement = {
        key: 'select-columns',
        primaryText: 'Select Columns',
        onTouchTap: props.delegateSelectColumnsButtonTap,
        'data-codecept-selector-node': 'MenuItem',
        'data-codecept-selector-file': 'additionalContextMenuItems'
    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'additionalContextMenuItems'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'additionalContextMenuItems'), _React$createElement)) : []);

    return _lodash2.default.concat(currentMenuItems.length && menuItems.length ? _react2.default.createElement(_Divider2.default, (_React$createElement2 = { key: 'divider-0', 'data-codecept-selector-node': 'Divider',
        'data-codecept-selector-file': 'additionalContextMenuItems'
    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'additionalContextMenuItems'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'additionalContextMenuItems'), _React$createElement2)) : [], menuItems);
};

exports.default = getAdditionalContextMenuItems_withProps;