'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    Toggle: {
        style: {
            width: 'auto',
            marginTop: 15
        }
    }
};