'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reduxBatchedActions = require('redux-batched-actions');

var _bluebird = require('bluebird');

var _bluebird2 = _interopRequireDefault(_bluebird);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var path = require('path');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

var _require = require('@rubyapps/react-flexbox-grid'),
    Grid = _require.Grid,
    Row = _require.Row,
    Col = _require.Col;

var RubyComponentFieldForm__CONSTANTS = require('@rubyapps/ruby-component-field-form/src/common/constants');
var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var gridWidth_keyToIntMap = {
    fullwidth: 12,
    medium: 6,
    small: 3
};

var FIELD_FORM_COMPONENT_NAME = 'rubyComponentFieldForm';

var _require2 = require('../common/index'),
    maxErrorLevel_fromLevels = _require2.maxErrorLevel_fromLevels,
    errorLevel_fromStringOrErrorObject = _require2.errorLevel_fromStringOrErrorObject;

var _require3 = require('../common/constants'),
    DEFAULT_CHILDREN_ERROR_MESSAGE = _require3.DEFAULT_CHILDREN_ERROR_MESSAGE;

var action = require('./action');
var reducer = require('./reducer');

var ACTION_PROPTYPE = PropTypes.oneOfType([PropTypes.string

//# where first entry is the action method string and the rest are parameters to be passed to the action
, PropTypes.array]);
var fieldBaseMixin = _extends({
    mixinName: 'rubyComponentMixinFieldBase',
    propTypes: {
        key: PropTypes.string //# the key to use for the form
        , action: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
            //# path format for this.getRubyComponentAtPath()
            //# if path is not provided, then it's referencing itself
            path: PropTypes.string

            //# method if we want to call something that's not an action
            , method: ACTION_PROPTYPE,
            action: ACTION_PROPTYPE
        })])

        //# a style object keyed by the name of the react components for the field component which overrides
        //# the default styles
        , styles: PropTypes.object

        //# a classname object with keys of components for the field component which adds to the default classnames
        , classNames: PropTypes.object

        //# if the component has internal children RubyComponents, you can pass an object keyed by the children keys to
        //# define the props for the children
        //# NOTE: not all complex widgets support merging in the childrenPropsByKey correctly reference DatetimePicker
        //# if you need to update the other widgets to support it
        , childrenPropsByKey: PropTypes.object
        //# if the component has internal children RubyComponents,
        //# you can pass an object keyed by the children keys
        //# to define the props for the children
        //# NOTE: not all complex widgets support merging in the 
        //# childrenPropsByKey correctly
        //# reference DatetimePicker if you need to
        //# update the other widgets to support it
        , contentPropertyHelperID: PropTypes.string,
        props: PropTypes.object //# a props object keyed by the name of the react components for the field component
        //# props.<Component> to override the component props
        , namespace: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        errorMessagesByKeyword: PropTypes.object
        //# NOTE: excludeFromAdvancedLister is being deprecated in favor of
        //# listerConfig.excludeFromColumnSelection and listerConfig.excludeFromFilterSelection
        , excludeFromAdvancedLister: PropTypes.bool

        //# Use this prop carefully. Only used by activity tab so that it doesn't have its localState replaced. It
        //# should not contain elements that returns formValues.
        //# IF we need that, we'll need to update the namespace-selector to recursively retrieve localState data to
        //# cache in order to exclude it (exclude for non-default namespaces if we want the data for *some* namespace)
        , excludeFromNamespaces: PropTypes.bool,

        excludeWrapper: PropTypes.bool,
        disabled: PropTypes.bool,
        forComponent: PropTypes.object
        //# forComponent can be used to specify props to be consumed by specific components
        //# The top level keys of forComponent are expected to be component names - and the values
        //# of these keys are expected to be objects containing props for the intended component
        , id: PropTypes.string,
        locked: PropTypes.bool //# NOTE: locked is different than disabled
        //# locked is used if the field is locked from edit, which is a permissions things
        //# disabled is a subset of that feature
        , listerConfig: PropTypes.object
        //# Field specific lister configs
        , mode: PropTypes.oneOf(['info', 'control'])

        //# Used by Info mode and ArrayMap field for formatting
        //toHTML: (selfModule, modules, data) =>
        //    `data: ${data},  state: ${selfModule.getState}, props: ${selfModule.props}`
        //# NOTE: don't know if we need state and props yet, but data is for each child
        //toHTML: 'data: ${JSON.stringify(data, null, " ")}'
        //    + ', state: ${JSON.stringify(selfModule.getState(), null, " ")}'
        //    + ', props: ${selfModule.props}'
        , toHTML: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
    },
    getDefaultProps: function getDefaultProps() {
        return {
            excludeWrapper: true,
            contentPropertyHelperID: require('@rubyapps/ruby-content-property-helper/src/common/constants').COMPONENT_NAME
        };
    },
    dependencies: function dependencies() {
        var rootModule = this.getRoot();

        var contentPropertyHelper = rootModule.findDescendentByID(this.props.contentPropertyHelperID);

        return {
            contentPropertyHelper: contentPropertyHelper
        };
    },
    getInitialState: function getInitialState() {
        return {
            displayValueSpecs: null,
            isMounted: false,
            props: {},
            rerenderTimestamp: null
        };
    },
    action: action,
    reducer: reducer

    //# statesSelector is only used for shortcircuiting the shoudlUpdate check so we shouldn't use fieldProps
    , statesSelector: function statesSelector(state) {
        var selfSelector = this.getSelfStateSelector();

        return _extends({
            self: selfSelector(state)
        }, this.statesSelector_fromState_andOwnProps ? { selectedState: this.statesSelector_fromState_andOwnProps(state, this.props) } : { isActive: this.isFieldActiveForNamespace_fromState(state) });
    },

    _hydrateActionSpec: function _hydrateActionSpec(actionSpec) {
        var hydratedValue = actionSpec;

        if (_lodash2.default.isString(actionSpec)) {
            var parentForm = this.findAncestorBy(function (module) {
                return module.componentName == FIELD_FORM_COMPONENT_NAME;
            });
            hydratedValue = parentForm.getAction().generators[actionSpec];
        } else if (_lodash2.default.isObject(actionSpec)) {
            //# assume that if there's no path, it's referencing itself
            var nodeReferencedByAction = actionSpec.hasOwnProperty('path') ? this.getRubyComponentAtPath(actionSpec.path) : this;
            var value_action = actionSpec.action,
                value_method = actionSpec.method;


            if (!nodeReferencedByAction) {
                logger.warn('[_hydrateActionSpec()] null for actionSpec:', actionSpec);
                return null;
            }

            if (value_action) {
                if (_lodash2.default.isArray(value_action)) {
                    var _nodeReferencedByActi;

                    hydratedValue = (_nodeReferencedByActi = nodeReferencedByAction.getAction().generators[value_action[0]]).bind.apply(_nodeReferencedByActi, [nodeReferencedByAction].concat(_toConsumableArray(value_action.slice(1))));
                } else {
                    hydratedValue = nodeReferencedByAction.getAction().generators[value_action];
                }
            } else if (value_method) {
                if (_lodash2.default.isArray(value_method)) {
                    var _nodeReferencedByActi2;

                    hydratedValue = (_nodeReferencedByActi2 = nodeReferencedByAction[value_method[0]]).bind.apply(_nodeReferencedByActi2, [nodeReferencedByAction].concat(_toConsumableArray(value_method.slice(1))));
                } else {
                    hydratedValue = nodeReferencedByAction[value_method].bind(nodeReferencedByAction);
                }
            } else {
                logger.warn('[_hydrateActionSpec()] actionSpec is invalid: ', actionSpec);
                hydratedValue = function hydratedValue() {};
            }
        }

        return hydratedValue;
    },
    _hydrateProp_forKey: function _hydrateProp_forKey(value, key) {
        var hydratedValue = value;

        switch (key) {
            case 'action':
                hydratedValue = this._hydrateActionSpec(value);
                break;
            default:
                //# do not assume the reference is the parent field form for any other key
                if (_lodash2.default.isObject(value) && value.hasOwnProperty && value.hasOwnProperty('path') && value.hasOwnProperty('method')) {
                    var nodeReferenced = this.getRubyComponentAtPath(value.path);
                    var value_method = value.method;

                    if (!nodeReferenced) {
                        hydratedValue = undefined;
                    } else if (_lodash2.default.isFunction(value_method)) {
                        hydratedValue = value_method.call(nodeReferenced);
                    } else if (_lodash2.default.isArray(value_method)) {
                        hydratedValue = nodeReferenced[value_method[0]].apply(nodeReferenced, value_method.slice(1));
                    } else {
                        hydratedValue = nodeReferenced[value_method]();
                    }
                }
        }

        return hydratedValue;
    }
    //# TODO: move to ruby-component as base
    //# referenceProps would be props with the following structure: {}
    , _hydrateReferenceProps_withProps: function _hydrateReferenceProps_withProps(props) {
        var _this = this;

        //# TODO: check for path and method
        return _lodash2.default.reduce(props, function (collector, propValue, propKey) {
            collector[propKey] = _this._hydrateProp_forKey(propValue, propKey);

            return collector;
        }, {});
    },
    getProps: function getProps(shouldHydrateProps) {
        var mergedProps = _extends({}, this.props, _lodash2.default.get(this.getState() || {}, 'props', {}));
        var finalProps = mergedProps;

        if (shouldHydrateProps) {
            finalProps = this._hydrateReferenceProps_withProps(mergedProps);
        }

        return finalProps;
    }

    /**
     * Get the keypath array for this field. This is *NOT* a static method
     */
    , getFieldKeypathArr: function getFieldKeypathArr() {
        var keypathArr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        if (this.componentName == RubyComponentFieldForm__CONSTANTS.COMPONENT_NAME) {
            //# exit if we reach the form without adding the form key
            return keypathArr;
        }

        this.props.key && keypathArr.unshift(this.props.key);

        var parentComponent = this.getParent();

        //# NOTE: this gets called by the MediaPicker > MediaGallery > InfoPane 
        //# via the jsondiff, which is somehow falling back to searching the nodes
        //# the MediaGallery component itself won't have getFieldKeypathArr
        //# Ok to just return existing keypathArr?
        return parentComponent && parentComponent.getFieldKeypathArr ? parentComponent.getFieldKeypathArr(keypathArr) : keypathArr;
    }

    //# Override this for your field component if you need custom conversions from form value to local state
    , fieldValueFromFormValue_forKey: function fieldValueFromFormValue_forKey(value, key, isForDefaultValue) {
        //# NOTE: isForDefaultValue is currently not necessary but there might be a point in time
        //# when we need to know when `ruby-component-mixin-field-validations/src/client/index.js` 
        //# is calling on this during `getInitialState()` and we need to coerced the dataType

        return this.coercedValue(value);
    }
    /**
     * for complex components, when hydrating to localState, we might need to change the 
     * shape of the formValue
     *
     * formValue - the formValue for child before transform
     * isError - we need to handle the error case because `_formValueToLocalState` is most likely calling this
     * 
     */
    , childrenFormValueFromFormValueForLocalState: function childrenFormValueFromFormValueForLocalState(formValue, isError) {
        return formValue; //# indentity for most components
    }

    /**
     * this method rapidly by disparate components
     * (like when checking whether dependent urls changed [in the remote-options mixin])
     * @param {Object} options
     * @property {Boolean} options.ignoreChildren - <PropTypes.bool>  basically greedy and sets the data in the current
     *     component (used by ArrayMap). Used by any component that wants to manage it's children by itself}
     * @param {Object} options.omitFieldPicker - (Boolean) function(RubyComponent, ...arguments){} - a function that returns true if
     *     we want to omit the field from being seeded
     * @param {Object} options.promisedOnceResolved - (Promise) - A pending Promise passed by the root caller of formValueToLocalState whose sole responsibility is to resolve this promise after the entire promise tree resolves
     */
    , _formValueToLocalState: function _formValueToLocalState(formValue, dispatchOrCollect, isError, entireFormValue) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        var selfKey = this.props.key;

        var children = options.ignoreChildren ? [] : this.getChildren();

        var action = this.getAction();
        var _action$generators = action.generators,
            setFieldValueByKey = _action$generators.setFieldValueByKey,
            setFieldErrorMessageByKey = _action$generators.setFieldErrorMessageByKey,
            mergeFieldErrorObjectByKey = _action$generators.mergeFieldErrorObjectByKey;


        var promiseArr = [];
        //# no key for self, so we defer to it's children
        //# NOTE: we need to check for the scenario where the formValue has a literal undefined key
        if (!selfKey && !formValue.hasOwnProperty(selfKey)) {
            promiseArr = children.reduce(function (collector, child) {
                if (child._formValueToLocalState) {
                    var retval = child._formValueToLocalState(formValue, dispatchOrCollect, isError, entireFormValue, options);
                    collector.push(retval);
                }

                return collector;
            }, []);
        } else if (!_lodash2.default.isNil(formValue) && formValue.hasOwnProperty(selfKey)) {
            var formValueForKey = this.childrenFormValueFromFormValueForLocalState(formValue[selfKey], isError);
            if (!_lodash2.default.isNil(formValueForKey) && children.length) {
                promiseArr = children.reduce(function (collector, child) {
                    if (child._formValueToLocalState) {
                        var retval = child._formValueToLocalState(formValueForKey, dispatchOrCollect, isError, entireFormValue, options);
                        collector.push(retval);
                    }
                    return collector;
                }, []);

                if (isError && formValueForKey._message) {
                    //# include message for self
                    promiseArr.push(dispatchOrCollect(setFieldErrorMessageByKey(formValueForKey._message, selfKey)));
                }
            } else {
                if (isError) {
                    if (_lodash2.default.isPlainObject(formValueForKey)) {
                        if (_lodash2.default.size(formValueForKey)) {
                            promiseArr.push(dispatchOrCollect(mergeFieldErrorObjectByKey(formValueForKey, selfKey)));
                        }
                    } else {
                        promiseArr.push(dispatchOrCollect(setFieldErrorMessageByKey(formValueForKey, selfKey)));
                    }
                } else {
                    //# support omit field picker
                    var shouldOmitField = _lodash2.default.isFunction(options.omitFieldPicker) && options.omitFieldPicker.apply(options, [this].concat(Array.prototype.slice.call(arguments)));
                    if (!shouldOmitField) {
                        promiseArr.push(dispatchOrCollect(setFieldValueByKey(this.fieldValueFromFormValue_forKey(formValueForKey, selfKey), selfKey, true)));
                    }
                }
            }
        }
        return _bluebird2.default.all(promiseArr);
    },
    formErrorToLocalState: function formErrorToLocalState(formError, dispatchOrCollect) {
        if (!dispatchOrCollect) {
            dispatchOrCollect = this.getStore().dispatch;
        }
        return this._formValueToLocalState(formError, dispatchOrCollect, true, formError);
    }
    /**
     *
     *  options.batch = //# if batch, and dispatchOrCollect isn't provided, we'll batch
     */
    , formValueToLocalState: function formValueToLocalState(formValue, dispatchOrCollect, entireFormValue) {
        var _this2 = this;

        var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

        var shouldDispatchOurselves = false;
        var actionCollector = [];
        if (!dispatchOrCollect) {
            if (options.batch) {
                var collectAction = function collectAction(value) {
                    actionCollector.push(value);
                };
                dispatchOrCollect = collectAction;
                shouldDispatchOurselves = true;
            } else {
                dispatchOrCollect = this.getStore().dispatch;
            }
        }
        if (!entireFormValue) {
            var parentFormComponent = this.getParentFormComponent();
            entireFormValue = parentFormComponent ? parentFormComponent.formValue() : formValue;

            if (!parentFormComponent) {
                console.error('formValueToLocalState() was called for [' + this.key() + '] but parentFormComponent is missing');
            }
        }

        return this._formValueToLocalState(formValue, dispatchOrCollect, false, entireFormValue, options).then(function (res) {
            if (shouldDispatchOurselves) {
                _this2.getStore().dispatch((0, _reduxBatchedActions.batchActions)(actionCollector));
            }
            return res;
        });
    },

    clearForm: function clearForm(dispatchOrCollect) {
        //# clears both value and errors for form
        if (!dispatchOrCollect) {
            dispatchOrCollect = this.getStore().dispatch;
        }

        var children = this.getChildren();

        var _getAction = this.getAction(),
            actions = _getAction.generators;

        //# getInitialState() and replace the field


        var initialState = this.getInitialState ? this.getInitialState() : {};
        if (initialState.hasOwnProperty('fields') || initialState.hasOwnProperty('error')) {
            dispatchOrCollect(actions.replaceLocalState(_lodash2.default.pick(initialState, ['fields', 'error'])));
        }

        children.forEach(function (child) {
            if (child.clearForm) {
                child.clearForm(dispatchOrCollect);
            }
        });
    }

    //# Override this for your field component if you need custom conversions from local state to form
    , formValueFromFieldValue_forKey: function formValueFromFieldValue_forKey(value) {
        //# handle this.props.data_type

        var dataType = this.props.data_type;
        var rawValue = value;

        if (_lodash2.default.isNil(rawValue) || _lodash2.default.isNil(dataType)) return rawValue;

        //# first normalize to array if necessary
        if (_lodash2.default.isArray(dataType)) {
            var normalizedValueToArray = _lodash2.default.isArray(rawValue) ? rawValue : [rawValue];
            var innerDataType = dataType[0];
            return normalizedValueToArray.map(function (valueInArr) {
                return coerceValue_intoType(valueInArr, innerDataType);
            }).filter(_lodash2.default.identity);
            //# need to filter out null values if it's an array of values
        } else {
            return coerceValue_intoType(rawValue, dataType);
        }

        function coerceValue_intoType(value, type) {
            var primitiveValue = _lodash2.default.isArray(value) ? _lodash2.default.first(value) : value;
            var isObject = _lodash2.default.isObject(primitiveValue);

            if (_lodash2.default.isNil(primitiveValue)) {
                return null;
            }

            switch (type) {
                case 'number':
                    {
                        //# if the value is an empty string, we should coerce it into null. This is an exception we need to
                        //# make with numbers since we cannot return 0 to the server
                        if (primitiveValue == '') {
                            return null;
                        }

                        if (isObject) {
                            return null;
                        } //#cannot coerce the value if it's an object

                        // If its truly not a number
                        // then pass string to server and let server complain
                        // as opposed to passing null or 0 and letting the server save that value
                        var numberValue = Number(primitiveValue);
                        return _lodash2.default.isNaN(numberValue) ? primitiveValue : numberValue;
                    }
                case 'boolean':
                    return primitiveValue ? true : false;
                case 'string':
                    if (primitiveValue == "") {
                        return null;
                    }
                    if (isObject) {
                        return null;
                    } //#cannot coerce the value if it's an object

                    return '' + primitiveValue;
                    break;
                case 'object':
                    //# if data_type is object and stored value is string
                    //# we attempt to coerce
                    //# TODO: issue: with jsondiff not diffing this type correctly
                    //# for now, we'll allow it because it's only used for template editing
                    if (_lodash2.default.isString(primitiveValue)) {
                        try {
                            return JSON.parse(primitiveValue);
                        } catch (err) {
                            //# cannot coerce the value, return null
                            //# we should not throw error because 
                            //# formValue might be used by other things 
                            return null;
                        }
                    }
                default:
                    return primitiveValue; //# return value as-is as fallback (for things like object)
            }
        }
    },
    coercedValue: function coercedValue(value, getPrimitiveValue) {
        var coercedValue = this.formValueFromFieldValue_forKey(value);
        if (getPrimitiveValue) {
            return _lodash2.default.isArray(coercedValue) ? _lodash2.default.first(coercedValue) : coercedValue;
        }

        return coercedValue;
    },
    _getValuesFromFieldsObject: function _getValuesFromFieldsObject(fieldsObject, isError) {
        var _this3 = this;

        var predicateFormatter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (value) {
            return value;
        };
        var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

        return _lodash2.default.reduce(fieldsObject, function (collector, fieldObject, key) {
            if (isError) {
                var errorObjectWithoutNil = _lodash2.default.reduce(fieldObject.error, function (collector, errorValue, errorKey) {
                    if (!_lodash2.default.isNil(errorValue)) {
                        collector[errorKey] = errorValue;
                    }
                    return collector;
                }, {});

                if (fieldObject.error && Object.keys(errorObjectWithoutNil).length > 0) {
                    //collector[key] = predicateFormatter(fieldObject.error.message);
                    collector[key] = _extends({}, fieldObject.error, {
                        message: fieldObject.error.message ? predicateFormatter(fieldObject.error.message) : null
                    });
                }
            } else {
                var resolvedValue = _lodash2.default.isUndefined(_this3.props.value) ? predicateFormatter(_this3.formValueFromFieldValue_forKey(fieldObject.value, key)) : _this3.props.value;
                if (!(_lodash2.default.isNil(resolvedValue) && options.excludeNull)) {
                    collector[key] = resolvedValue;
                }
            }
            return collector;
        }, {});
    }
    //# ruby-component-field specific methods
    //# state is the pure state from the redux store
    //# the reducedState is the state reduced from its children
    //# NOTE: that selfModule is responsible for returning:
    //# {
    //      [selfID]: selfValue
    //# }
    /**
     * @param {Object} options
     * @param {Object} options.omitFieldPicker - (Boolean) function(RubyComponent){} - a function that returns true if
     *     we want to omit the field from the returned form value
     */
    , _formValueFromLocalState: function _formValueFromLocalState(selfState, isError, predicateFormatter, entireState) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        //console.log(`[__formValueFromLocalState()] id: ${this.getID()}, options:`, options);
        if (selfState == undefined) {
            return undefined;
        }
        var isFieldActive = this.isFieldActiveForNamespace_fromState(entireState);
        if (!isFieldActive || _lodash2.default.isFunction(options.omitFieldPicker) && options.omitFieldPicker.apply(options, [this].concat(Array.prototype.slice.call(arguments)))) {
            return {};
        }

        var selfKey = this.props.key;

        var children = options.ignoreChildren ? [] : this.getChildren();

        var arrOfReducedChildrenState = _lodash2.default.reduce(children, function (collector, child) {
            var childID = child.getID();
            var childFormValue = child._formValueFromLocalState ? child._formValueFromLocalState(selfState[childID], isError, predicateFormatter, entireState, options) : undefined;
            if (childFormValue != undefined) {
                collector.push(_lodash2.default.omit(childFormValue, [undefined]));
                //# Do not allow {undefined: ... } from being merged in
                //# the field key should only be undefined for components like ArrayMap
                //# which it should internally handle
            }
            return collector;
        }, []);

        var reducedChildrenState = _extends.apply(undefined, [{}].concat(_toConsumableArray(arrOfReducedChildrenState)));
        var selfHasFieldsState = selfState.hasOwnProperty('fields');

        var reducedChildrenAvail = Object.keys(reducedChildrenState).length > 0;
        //# Note, if selfModule is an object, we would format the reducedChildrenState and return
        //# like for the case of the datePicker;
        //# prefer self value over the internal value
        //# The default response for fields would be {undefined: null}
        //# it's the responsibility of the parent component to unwrap the value (like in the case of ArrayMap, when we
        //# want ['string'])
        return reducedChildrenAvail && selfKey ? _defineProperty({}, selfKey, reducedChildrenState) : reducedChildrenAvail ? reducedChildrenState : selfHasFieldsState ? this._getValuesFromFieldsObject(selfState.fields, isError, predicateFormatter, options)
        //# return an empty object which gets merged in
        : {};
    },
    formErrorFromLocalState: function formErrorFromLocalState(selfState, predicateFormatter, entireState, options) {
        if (!entireState) {
            entireState = this.getStore().getState();
        }

        return this._formValueFromLocalState(selfState, true, predicateFormatter, entireState, options);
    },
    formValueFromLocalState: function formValueFromLocalState(selfState, predicateFormatter, entireState, options) {
        if (!entireState) {
            entireState = this.getStore().getState();
        }

        return this._formValueFromLocalState(selfState, false, predicateFormatter, entireState, options);
    }

    //# sugar method for retrieving selfState and returning the formValue
    /**
     * @typedef {Object} formValue__options
     * @property {boolean} ignoreChildren - <PropTypes.bool>  basically greedy and sets the data in the current
     *     component (used by ArrayMap). Used by any component that wants to manage it's children by itself}
     * @property {boolean} cacheTemporarily - pass true if you're performing some operation which requires calling on
     *     formValue rapidly back-to-back
     *     NOTE: this property is DEPRECATED for now. It causes issues when the formValues of nested fields are
     *     actually updating
     *     and it doesn't seem like there's a performance improvement
     *     NOTE: if you want to reintroduce this feature, we would need to hook into the setValueByFieldKey action to
     *     trigger all ancestors of the changing field to invalidate its respective cache (ie. cache invalidate a branch
     *     of the field tree)
     *
     * @property {boolean} excludeNull - <PropTypes.bool> if true, we make sure we don't propagate null values up
     *     (meaning the returned formValue contains only non-null key-value pairs)
     * @property {boolean} returnRaw - return raw form value regardless of the field component's internal rules for
     *     whether to return the formValue (useful for ExpandData)
     */
    /**
     * this method rapidly by disparate components
     * (like when checking whether dependent urls changed [in the remote-options mixin])
     * @param {formValue__options} options
     */
    , formValue: function formValue() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        /*
        const {
            cacheTemporarily
        } = options;
         if (cacheTemporarily) {
             const cachedFormValue = this._cachedFormValue;
            let returnFormValue;
            if (cachedFormValue) {
                returnFormValue = cachedFormValue;
            } else {
                this._cachedFormValue = this.formValueFromLocalState(this.getState(), undefined, undefined, options);
                returnFormValue = this._cachedFormValue;
            }
             this._refreshClearCachedFormValueTimeout();
            return returnFormValue;
        }
        */

        return this.formValueFromLocalState(this.getState(), undefined, undefined, options);
    },
    formValueAtKeypath: function formValueAtKeypath(keypath) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        return _lodash2.default.get(this.formValue(options), keypath);
    },
    _refreshClearCachedFormValueTimeout: function _refreshClearCachedFormValueTimeout() {
        var _this4 = this;

        clearTimeout(this._clearCachedFormValueTimeout);
        this._clearCachedFormValueTimeout = setTimeout(function () {
            return _this4.clearCachedFormValue();
        }, 500);
    },
    clearCachedFormValue: function clearCachedFormValue() {
        //console.log('clearing cached form value');
        this._cachedFormValue = null;
    },
    formError: function formError(options) {
        return this.formErrorFromLocalState(this.getState(), undefined, undefined, options);
    }
    //# NOTE: in the case where you need to access pending changes like in <Module> or <ArrayMap>, where the children
    //# values has not been committed yet, you should call on this method
    , childrenFormValue: function childrenFormValue(options) {
        var selfState = this.getState();
        return this.childrenFormValueFromLocalState(selfState, undefined, options);
    },
    childrenFormValueFromLocalState: function childrenFormValueFromLocalState(selfState, entireState, options) {
        if (!entireState) {
            entireState = this.getStore().getState();
        }
        var children = this.getChildren();
        var childrenFormValues = children.filter(function (child) {
            return _lodash2.default.isFunction(child.formValueFromLocalState);
        }).map(function (child) {
            return child.formValueFromLocalState(selfState[child.getID()], undefined, entireState, options);
        });

        var mergedFormValue = childrenFormValues.length ? Object.assign.apply(null, childrenFormValues) : {};
        return Object.keys(mergedFormValue).length == 1 && mergedFormValue.hasOwnProperty(undefined) ? mergedFormValue[undefined] : mergedFormValue;
    },
    maxErrorLevel_fromErrorState: function maxErrorLevel_fromErrorState(errorObject) {
        //# errorObject might look like:
        //# {
        //#     // legacy error messages are simple strings
        //#     // they are considered to have level="error"
        //#     'arbitrary_prop_name': 'simple error message'
        //#     'message': 'simple error message'
        //#
        //#     // rich error messages include a level property
        //#     , 'arbitrary_prop_name': [{msg: 'rich error message', level: 'warning'}] 
        //#     , 'integrityMessages': [{msg: 'rich error message', level: 'warning'}] 
        //#
        //#     // there may be other meta properties, e.g.
        //#     , wordsToHighlight: ['mispelled', 'woords']
        //# }
        var defaultErrorLevel = 'error';
        var stringValues = _lodash2.default.values(errorObject).filter(_lodash2.default.isString);
        if (stringValues.length) {
            return defaultErrorLevel;
        }
        var errorLevels = _lodash2.default.flatten(_lodash2.default.values(errorObject)).filter(function (el) {
            return el && el.msg && el.level;
        }).map(function (el) {
            return el.level;
        });

        return maxErrorLevel_fromLevels(errorLevels);
    }

    //==  Value mapping like formValue but with component ids =============/

    //# ruby-component-field specific methods
    //# state is the pure state from the redux store
    //# the reducedState is the state reduced from its children
    //# NOTE: that selfModule is responsible for returning:
    //# {
    //      [selfID]: selfValue
    //# }
    //# The object that gets returned looks like:
    //# {
    //#     [selfID]: {
    //#         _value: 'error message for selfID'
    //#         [childID]: {
    //#             [childFieldKey]: 'error message'
    //#         }
    //#     }
    //# }
    //#
    //#
    , _objectValueFromLocalState: function _objectValueFromLocalState(selfState, isError, limitToTabWithLabel) {
        var _this5 = this;

        var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
        var ctx = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        if (selfState == undefined) {
            return undefined;
        }
        var selfID = this.getID();

        var children = options.ignoreChildren ? [] : this.getChildren();

        var childrenHasValues = false;
        var maxChildErrorLevel = null;
        var arrOfChildrenCtx = [];
        var arrOfReducedChildrenState = _lodash2.default.reduce(children, function (collector, child) {
            var childID = child.getID();
            if (child.componentName == 'rubyComponentFieldTab' && limitToTabWithLabel && child.props.label != limitToTabWithLabel) {
                return collector;
            }

            var childCtx = {};
            var childObjectValue = child._objectValueFromLocalState ? child._objectValueFromLocalState(selfState[childID], isError, limitToTabWithLabel, undefined, childCtx) : undefined;
            if (childObjectValue != undefined) {
                collector.push(childObjectValue);
                arrOfChildrenCtx.push(childCtx);

                var childObjectValueAtID = childObjectValue[childID];
                var childObjectValueAtID_andKey = childObjectValueAtID[child.props.key];
                var childHasKey = !_lodash2.default.isNil(child.props.key);
                var doesChildObjectValueHaveErrors = isError && _lodash2.default.reduce(childObjectValueAtID_andKey, function (collector, value) {
                    return collector || !_lodash2.default.isNil(value);
                }, false);
                maxChildErrorLevel = maxErrorLevel_fromLevels([maxChildErrorLevel, _this5.maxErrorLevel_fromErrorState(childObjectValueAtID_andKey), errorLevel_fromStringOrErrorObject(childObjectValueAtID._value)]);

                //# 20170517 TODO: might need to update this check to properly set _value for parents
                //if (Object.keys(childObjectValueAtID).length && childObjectValueAtID._value !== null ) {
                if (
                //# e.g. this is a Tab field and the child Fieldset has an error
                !_lodash2.default.isNil(childObjectValueAtID._value)

                //# e.g. this is a Fieldset and a child has an error
                || childHasKey && doesChildObjectValueHaveErrors) {
                    //# only has value if the leaf nodes has value
                    childrenHasValues = true;
                }
            }
            return collector;
        }, []);

        var reducedChildrenState = _extends.apply(undefined, [{}].concat(_toConsumableArray(arrOfReducedChildrenState)));

        var reducedChildrenAvail = Object.keys(reducedChildrenState).length > 0;

        var selfValue = selfState.fields ? this._getValuesFromFieldsObject(selfState.fields, isError) : {};

        if (isError && reducedChildrenAvail) {
            //# add in _value if necessary
            var doesChildrenHaveErrors = childrenHasValues && reducedChildrenState._value !== null;

            ctx.doesChildrenHaveErrors = doesChildrenHaveErrors || _lodash2.default.some(arrOfChildrenCtx, 'doesChildrenHaveErrors');
            //# used to exfil whether children has errors
            //# primarily used by the mixin-field-dynamic mixin
            //# because there might be some local error state that needs to be cleared out

            if (doesChildrenHaveErrors) {
                selfValue._value = {
                    msg: DEFAULT_CHILDREN_ERROR_MESSAGE,
                    level: maxChildErrorLevel
                };
            } else {
                selfValue._value = null;

                //# NOTE: should not need this because the `ruby-component-mixin-field-dynamic`
                //# should be handling it by checking ctx.doesChildrenHaveErrors
                //# there was a bug with the exfiltrate where it was returning true even though
                //# no children had errors
                /*
                if (this.props.key) {
                    //# also need to clear out own field state
                    selfValue[this.props.key] = null;
                 }
                */
            }
        }

        return reducedChildrenAvail ? _defineProperty({}, selfID, _extends(reducedChildrenState, selfValue || {})) : selfValue ? _defineProperty({}, selfID, selfValue) : undefined;
    },
    objectErrorFromLocalState: function objectErrorFromLocalState(selfState, limitToTabWithLabel) {
        return this._objectValueFromLocalState(selfState, true, limitToTabWithLabel);
    },
    objectValueFromLocalState: function objectValueFromLocalState(selfState) {
        return this._objectValueFromLocalState(selfState, false);
    },
    _objectValueToLocalState: function _objectValueToLocalState(objectValue, dispatchOrCollect, isError) {
        var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

        var selfID = this.getID();
        var selfKey = this.props.key;

        var children = options.ignoreChildren ? [] : this.getChildren();

        var action = this.getAction();
        var _action$generators2 = action.generators,
            setFieldValueByKey = _action$generators2.setFieldValueByKey,
            setFieldErrorMessageByKey = _action$generators2.setFieldErrorMessageByKey,
            setFieldErrorObjectByKey = _action$generators2.setFieldErrorObjectByKey;


        if (!_lodash2.default.isNil(objectValue) && objectValue.hasOwnProperty(selfID)) {
            var objectValueForID = objectValue[selfID];
            var hasObjectValueForKey = objectValueForID.hasOwnProperty(selfKey);
            var objectValueForKey = objectValueForID[selfKey]; //# the key is nested in the object
            var objectHas_value = objectValueForID.hasOwnProperty('_value');
            if (!_lodash2.default.isNil(objectValueForID) && children.length) {
                children.forEach(function (child) {
                    if (child._objectValueToLocalState) {
                        child._objectValueToLocalState(objectValueForID, dispatchOrCollect, isError);
                    }
                });

                //# parent error
                if (isError) {
                    //# include message for self
                    if (setFieldErrorMessageByKey && objectHas_value) {
                        dispatchOrCollect(setFieldErrorMessageByKey(objectValueForID._value, selfKey));
                    }
                }
            }

            //# this sets the field value. This needs to be outside of the if-else statement
            //# for children nodes because we might have field values in a parent field
            //# like the namespaceSelector has children and its own value
            if (hasObjectValueForKey) {
                if (isError) {
                    if (setFieldErrorMessageByKey && !objectHas_value) {
                        dispatchOrCollect(setFieldErrorObjectByKey(objectValueForKey, selfKey));
                    }
                } else {
                    dispatchOrCollect(setFieldValueByKey(this.fieldValueFromFormValue_forKey(objectValueForKey, selfKey), selfKey, true));
                }
            } else {
                //# children.length == 0 but objectHas_value so it's a forceLeafNode
                if (isError) {
                    //# include message for self
                    if (setFieldErrorMessageByKey && objectHas_value) {
                        dispatchOrCollect(setFieldErrorMessageByKey(objectValueForID._value, selfKey));
                    }
                }
            }
        }
    },
    objectErrorToLocalState: function objectErrorToLocalState(objectError, dispatchOrCollect) {
        if (!dispatchOrCollect) {
            dispatchOrCollect = this.getStore().dispatch;
        }
        this._objectValueToLocalState(objectError, dispatchOrCollect, true);
    },
    objectValueToLocalState: function objectValueToLocalState(objectValue, dispatchOrCollect) {
        if (!dispatchOrCollect) {
            dispatchOrCollect = this.getStore().dispatch;
        }
        this._objectValueToLocalState(objectValue, dispatchOrCollect, false);
    }

    //# TODO: can improve performance here
    , replaceLocalStateAsync: function replaceLocalStateAsync(state, shouldReplaceLocalState) {
        var dispatch = this.getStore().dispatch;

        var arrayOfActions = [];
        var collector = function collector(action) {
            arrayOfActions.push(action);
        };

        //# TODO: based on the perf profile, the most time is taken here: ~1.65s
        this._replaceLocalState(state, collector, shouldReplaceLocalState);
        dispatch((0, _reduxBatchedActions.batchActions)(arrayOfActions));

        //# TODO: this takes ~258ms
        //# after replacing state, we walk the tree again and trigger the local state callback
        this.iterativelyTraverseChildren_withCallback(function (child) {
            child.onReplaceLocalState && child.onReplaceLocalState();
        });

        return _bluebird2.default.resolve(null);
    },
    _replaceLocalState: function _replaceLocalState(state, dispatchOrCollect, shouldReplaceLocalState) {
        var actions = this.getAction().generators;
        var children = this.getChildren();
        var childrenIds = this.getChildrenIds();
        var targetSelfState = _lodash2.default.omit(state, childrenIds);

        var shouldReplaceLocalStateResult = shouldReplaceLocalState ? shouldReplaceLocalState(this) : true;
        //# default is true;

        if (shouldReplaceLocalStateResult) {
            actions.replaceLocalState && dispatchOrCollect(actions.replaceLocalState(targetSelfState));

            //# TODO: we should be able to ignore recursing into children to replace state
            //# but I'm not 100% sure 
            //# I think it's possible that we can just swap out the data without having to actually call on batch reduce
            children.forEach(function (child) {
                var childID = child.getID();
                if (state.hasOwnProperty(childID) && child._replaceLocalState) {
                    child._replaceLocalState(_lodash2.default.get(state, childID), dispatchOrCollect, shouldReplaceLocalState);
                } else if (child.resetStore) {
                    child.resetStore.apply(child, [dispatchOrCollect]);
                }
            });
        }
    },

    resetStore: function resetStore(dispatchOrCollect, shouldResetStore) {
        var _arguments = arguments;

        dispatchOrCollect = dispatchOrCollect || this.getStore().dispatch;
        var actions = this.getAction().generators;
        var children = this.getChildren();

        var shouldResetStoreResult = shouldResetStore ? shouldResetStore(this) : true;
        //# default is true;

        if (shouldResetStoreResult) {
            //# TODO: consider calling on children resetStore first
            //# though currently we cannot get the entire state from the reducer (since the state is localized to the component in question)
            //# so the benefit of calling on children's resetStore is lost for now
            actions.resetStore && dispatchOrCollect(actions.resetStore());

            children.forEach(function (child) {
                child.resetStore && child.resetStore.apply(child, _arguments);
            });
        }
    },
    clearErrorsWithKeys: function clearErrorsWithKeys(dispatchOrCollect, pickKeys) {
        this.clearErrorsWithKeys_inState(dispatchOrCollect, pickKeys, this.getState());
    },
    clearErrorsWithKeys_inState: function clearErrorsWithKeys_inState(dispatchOrCollect, pickKeys, inState) {
        if (inState == undefined) {
            return;
        }

        var actions = this.getAction().generators;
        var children = this.getChildren();

        actions.clearErrorsWithKeys_inState && dispatchOrCollect(actions.clearErrorsWithKeys_inState(pickKeys, inState));

        children.forEach(function (child) {
            child.clearErrorsWithKeys_inState && child.clearErrorsWithKeys_inState.call(child, dispatchOrCollect, pickKeys, inState[child.getID()]);
        });
    },

    getParentFormComponent: function getParentFormComponent() {
        if (this._parentFormComponent) {
            return this._parentFormComponent;
        }

        var parentFormComponent = this.findAncestorBy(function (component) {
            return component.componentName == FIELD_FORM_COMPONENT_NAME;
        });

        this._parentFormComponent = parentFormComponent;

        return this._parentFormComponent;
    },
    refreshParentErrors: function refreshParentErrors() {
        var _this6 = this;

        var parentForm = this.getParentFormComponent ? this.getParentFormComponent() : undefined;
        var parentTab = this.findAncestorBy(function (element) {
            return element.componentName == 'rubyComponentFieldTab';
        });
        var parentTabLabel = parentTab ? parentTab.props.label : undefined;

        if (parentForm) {
            setImmediate(function () {
                var arrayOfActions = [];
                var collector = function collector(action) {
                    arrayOfActions.push(action);
                };

                //# setImmediate to make it unblocking
                var objectError = parentForm.objectErrorFromLocalState(parentForm.getState(), parentTabLabel);

                parentForm.objectErrorToLocalState(objectError, collector);

                _this6.getStore().dispatch((0, _reduxBatchedActions.batchActions)(arrayOfActions));
            });
        }
    }

    /*
     *  selfModule.props = {
     *      gridWidth: PropTypes.oneOf(['fullwidth', 'medium', 'small'])
     *  }
     * */
    , renderGridWrapper: function renderGridWrapper(reactProps) {
        var _React$createElement, _React$createElement2, _React$createElement3;

        if (reactProps.excludeWrapper) {
            //return <div style={{width: 300, display: 'inline-block'}}>{reactProps.children}</div>;
            return reactProps.children;
        }

        var gridWidth = reactProps.gridWidth || 'medium';
        var gridWidth_inInt = gridWidth_keyToIntMap[gridWidth];
        return _react2.default.createElement(
            Grid,
            (_React$createElement3 = { fluid: true, 'data-codecept-selector-node': 'Grid',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Grid'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Grid'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'index'), _React$createElement3),
            _react2.default.createElement(
                Row,
                (_React$createElement2 = {
                    'data-codecept-selector-node': 'Row',
                    'data-codecept-selector-file': 'index'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Row'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Row'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'index'), _React$createElement2),
                _react2.default.createElement(
                    Col,
                    (_React$createElement = { xs: 12, md: gridWidth_inInt, 'data-codecept-selector-node': 'Col',
                        'data-codecept-selector-file': 'index'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Col'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Col'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement),
                    reactProps.children
                )
            )
        );
    }

    //# namespace Utility methods
    //# TODO: need to have better rules for handling nested namespaces ... right now we assume that non-default
    //# namespaces should not be required
    , isFieldInDefaultNamespace_fromState: function isFieldInDefaultNamespace_fromState(state) {
        if (!state) {
            state = this.getStore().getState();
        }

        var ancestorNamespaceSelector = this.findAncestorBy(function (el) {
            return el.componentName == 'rubyComponentFieldNamespaceSelector';
        });
        if (!ancestorNamespaceSelector) {
            return true;
        }

        //# default ['contentForLanguages', 'en'];
        var activeNamespacePathArray = this.activeNamespacePathArray_fromState([], state);

        var defaultNamespacesArray = this.defaultNamespacesArray();

        var intersectedNamespaces = _lodash2.default.intersection(defaultNamespacesArray, activeNamespacePathArray);

        if (intersectedNamespaces.length == defaultNamespacesArray.length) {
            //# the current component's namespace matches all default namespaces
            return true;
        }

        return false;
    },
    isFieldActiveForNamespace_fromState: function isFieldActiveForNamespace_fromState(state) {
        if (!state) {
            state = this.getStore().getState();
        }

        var ancestorNamespaceSelector = this.findAncestorBy(function (el) {
            return el.componentName == 'rubyComponentFieldNamespaceSelector';
        });
        if (!ancestorNamespaceSelector) {
            return true;
        }

        var namespace = this.props.namespace || '*';
        var activeNamespacePath = this.activeNamespacePath_fromState(state);

        if (namespace == '*') {
            return true;
        }

        var namespaceArray = [].concat(namespace);

        var regExpArray = namespaceArray.map(function (namespace) {
            var regExp = void 0;
            if (namespace.charAt(0) == '/') {
                regExp = new RegExp(namespace.substring(1, namespace.length - 1));
            } else {
                regExp = new RegExp('\\.' + namespace + '\\.?');
            }
            return regExp;
        });

        var isActive = regExpArray.reduce(function (passed, regExp) {
            if (passed) {
                return passed;
            }

            var matches = regExp.exec(activeNamespacePath);

            return matches != null && matches.length > 0;
        }, false);

        return isActive;
    },
    _activeNamespacePathArray_fromState: function _activeNamespacePathArray_fromState() /*state*/{
        //# includes namespace keys
        return;
    },
    activeNamespacePathArray_fromState: function activeNamespacePathArray_fromState(collector, state) {
        var parentComponent = this.getParent();
        var keyArray = parentComponent && parentComponent._activeNamespacePathArray_fromState ? parentComponent._activeNamespacePathArray_fromState(state)
        //# never include own key
        : undefined;

        parentComponent && parentComponent.activeNamespacePathArray_fromState && parentComponent.activeNamespacePathArray_fromState(collector, state);

        if (keyArray) {
            keyArray.forEach(function (key) {
                collector.push(key);
            });
        }
        return collector;
    },
    activeNamespacePath_fromState: function activeNamespacePath_fromState(state) {
        if (!state) {
            state = this.getStore().getState();
        }
        var activeNamespacePathArray = this.activeNamespacePathArray_fromState([], state);
        return activeNamespacePathArray.join('.');
    },
    defaultNamespacesArray: function defaultNamespacesArray() {
        var defaultNamespacesArray = [];
        if (this.componentName == FIELD_FORM_COMPONENT_NAME) {
            return defaultNamespacesArray;
        }
        var currentComponent = this.getParent();

        while (currentComponent && currentComponent.componentName != FIELD_FORM_COMPONENT_NAME) {
            if (currentComponent.getDefaultNamespace) {
                defaultNamespacesArray.unshift(currentComponent.getDefaultNamespace());
            }

            currentComponent = currentComponent.getParent();
        }

        return defaultNamespacesArray;
    },
    richErrorMessages: function richErrorMessages(localState) {
        localState = localState || this.getState();
        var key = this.props.key;
        var localErrorState = _lodash2.default.get(localState, ['fields', key, 'error'], {}) || {};

        var richErrorMessages = _lodash2.default.flatten(_lodash2.default.values(localErrorState)).filter(_lodash2.default.conforms({
            msg: _lodash2.default.negate(_lodash2.default.isEmpty),
            level: _lodash2.default.isString
        }));

        return _lodash2.default.sortBy(richErrorMessages, ['name']);
    },
    formattedErrorFromState: function formattedErrorFromState(state) {
        //# merge in other errors if necessary
        var selfSelector = this.getDefaultSelector();
        var selfState = selfSelector(state);
        return this.formattedErrorFromLocalState_andKey(selfState, this.props.key);
    },
    formattedErrorFromLocalState_andKey: function formattedErrorFromLocalState_andKey(localState, key) {
        //# merge in other errors if necessary
        //# only for fieldset or widgets that act like fieldsets (like Repeater)
        var containerErrorMessage = _lodash2.default.get(localState, ['error', 'message']);

        var localErrorState = _lodash2.default.get(localState, ['fields', key, 'error'], {}) || {};

        var localErrorState_stringsOnly = _lodash2.default.pickBy(localErrorState, _lodash2.default.isString);

        //# prefer regular message last
        var localErrorStateKeyArr = Object.keys(localErrorState_stringsOnly).sort(function (a, b) {
            return a == 'message' ? 1 : b == 'message' ? -1 : 0;
        });
        var errorMessageArr = _lodash2.default.reduce(localErrorStateKeyArr, function (messageArr, key) {
            var value = localErrorState_stringsOnly[key];
            if (!_lodash2.default.isNil(value)) {
                messageArr.push(value);
            }
            return messageArr;
        }, _lodash2.default.isString(containerErrorMessage) ? [containerErrorMessage] : []);

        var richErrorMessages = this.richErrorMessages();
        var mainErrorMessage = errorMessageArr.join('; ');

        var errorMessages = _lodash2.default.uniqBy([].concat(_lodash2.default.isPlainObject(containerErrorMessage) ? containerErrorMessage : [], mainErrorMessage ? {
            msg: mainErrorMessage,
            name: 'Main',
            level: 'error'
        } : [], richErrorMessages || []), 'msg'); //# NOTE: need to filter dupes which happens under certain circumstances
        //# with deferred rendering for fieldset

        var errorLevels = _lodash2.default.uniq(errorMessages.map(function (el) {
            return el.level;
        }));

        var maxLevel = maxErrorLevel_fromLevels(errorLevels);
        var errorColorsByLevel = {
            error: {
                foreground: '#D8000C'
            },
            warning: {
                foreground: '#9F6000'
            },
            info: {
                foreground: '#00529B'
            }
        };

        return {
            error: errorMessageArr.length ? _extends({}, localErrorState, {
                message: errorMessageArr.join('; ')
            }) : undefined,
            richErrorMessages: errorMessages,
            maxLevel: maxLevel,
            errorColors: _lodash2.default.get(errorColorsByLevel, maxLevel, {})
        };
    }

    //# == CLASS LEVEL Utility methods ==================================== //
    //# NOTE: if calling this as a Class method, then you need to bind
    //# the calling Component as 'this'
    , hydratedToHTMLFromProps: function hydratedToHTMLFromProps(props) {
        var _this7 = this;

        var toHTML = props.toHTML;


        var hydratedToHTML = void 0;
        if (toHTML) {
            var modules = this.modules || fieldBaseMixin.modules;
            if (_lodash2.default.isFunction(toHTML)) {
                hydratedToHTML = toHTML.bind(null, this, modules);
            } else {
                var compiledTemplate = _lodash2.default.template(toHTML);
                hydratedToHTML = function hydratedToHTML(data) {
                    return compiledTemplate({ data: data, selfModule: _this7, modules: modules });
                };
            }
        }

        return hydratedToHTML;
    }
    //# NOTE: complex widgets are in charge of hydrating any complex widgets that they own
    //# ie. if <InternationalAddress> has <ScheduledStatus> as a child, it needs to return
    //# the hydrated/flattened spec for ScheduledStatus
    /**
     * @param {Object} ctx.selfModule - the caller's RubyComponent
     * @param {Array} ctx.dataPathArray - dataPath array to the parent field. specify dataPath in returned fieldSpec if you want to override the default which is dataPathArray.concat(key).join('.')
     * @param {string} ctx.templateKey - templateKey of form 
     * @param {string} ctx.parentTemplateKey - templateKey of parent (only used by profile for now since profiles are different templates so we want to track parentTemplateKey separately)
     * @param {string} ctx.nestingOf - used to determine nesteing labelling (TODO: flesh out docs)
     * @param {string} ctx.nestingLabelArray - label used if we're displaying nesting (used by Tab and Header)
     * @params {Object} ctx.selfModule - the module of the root caller
     */
    , getFieldSpecFromProps: function getFieldSpecFromProps(props, ctx) {
        var retVal = fieldBaseMixin._getFieldSpecFromProps__withInternalChildren.apply(this, arguments);

        return retVal;
    },
    _getFieldSpecFromProps__withoutInternalChildren: function _getFieldSpecFromProps__withoutInternalChildren(props, ctx) {
        var fieldSpecArr = [];
        var propsKey = props.key;
        if (propsKey) {
            var preppedSpec = _lodash2.default.omit(props, ['children']);

            //# NOTE: fallback to key if dataPath is not defined
            /* //# do not hydrate, have griddle cell call on this
            if (props.toHTML) {
                preppedSpec.toHTML = fieldBaseMixin.hydratedToHTMLFromProps.apply(this, arguments);
            }
            */

            fieldSpecArr.push(preppedSpec);
        }
        return fieldSpecArr;
    },
    _getFieldSpecFromProps__withInternalChildren: function _getFieldSpecFromProps__withInternalChildren(props, ctx) {
        var fieldSpecArr = fieldBaseMixin._getFieldSpecFromProps__withoutInternalChildren.apply(this, arguments);

        var propsKey = props.key;
        var _props$childrenPropsB = props.childrenPropsByKey,
            childrenPropsByKey = _props$childrenPropsB === undefined ? {} : _props$childrenPropsB;


        var children = _lodash2.default.reduce(childrenPropsByKey, function (collector, value, key) {
            var preppedValue = _extends({}, value);

            collector.push(preppedValue);

            return collector;
        }, []);

        //# we assume there's only one fieldSpec

        var fieldSpec = fieldSpecArr[0];

        //# NOTE: childrenPropsByKey must define a 'componentName' otherwise, the searchableFields will ignore
        //# Previously, we expected all fields to be returned as a flat array, therefore we included the children adjacent to the parent
        //# but now, we're nesting, so it's up to each complex component to decide whether they want the default functionality (nested)
        //# or to explude the parent (eg. international address)
        if (fieldSpec && children.length) {
            return [_extends({}, fieldSpec, { children: children })];
        } else {
            return fieldSpecArr.concat(children);
        }
    }

}, require('./displayValue'), require('./modules'), require('./autopopulate'), require('./children'));

module.exports = fieldBaseMixin;