'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.GRIDDLE_COLLAPSE_ROW = GRIDDLE_COLLAPSE_ROW;
exports.GRIDDLE_EXPAND_ROW = GRIDDLE_EXPAND_ROW;
exports.GRIDDLE_MOVE_ROW_WITH_ID_TO_RELATIVE_POSITION_BY_ID = GRIDDLE_MOVE_ROW_WITH_ID_TO_RELATIVE_POSITION_BY_ID;
exports.GRIDDLE_MOVE_ROW_WITH_ID_TO_CHILD_OF_ROW_WITH_ID = GRIDDLE_MOVE_ROW_WITH_ID_TO_CHILD_OF_ROW_WITH_ID;
exports.GRIDDLE_MOVE_ROW_WITH_ID = GRIDDLE_MOVE_ROW_WITH_ID;
exports.GRIDDLE_MOVE_ROW = GRIDDLE_MOVE_ROW;
exports.GRIDDLE_FILTERED_AFTER = GRIDDLE_FILTERED_AFTER;
exports.GRIDDLE_LOADED_DATA_AFTER = GRIDDLE_LOADED_DATA_AFTER;
exports.setRowProperties = setRowProperties;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function GRIDDLE_COLLAPSE_ROW(state, action, helpers) {
    var indexPath = action.indexPath;
    var rowPath = getPathInData_fromIdxPath(indexPath);

    var data = state.get('data');

    var hoverRow = data.getIn([].concat(_toConsumableArray(rowPath)));
    if (hoverRow.get('children') && hoverRow.get('children').size > 0 && state.getIn(['expandedRows', hoverRow.get('id')])) {
        return state.setIn(['expandedRows', hoverRow.get('id')], false);
    } else {
        return state;
    }
}

function GRIDDLE_EXPAND_ROW(state, action, helpers) {
    var indexPath = action.indexPath;
    var rowPath = getPathInData_fromIdxPath(indexPath);

    var data = state.get('data');

    var hoverRow = data.getIn([].concat(_toConsumableArray(rowPath)));
    if (hoverRow.get('children') && hoverRow.get('children').size > 0 && !state.getIn(['expandedRows', hoverRow.get('id')])) {
        return state.setIn(['expandedRows', hoverRow.get('id')], true);
    } else {
        return state;
    }
}

function GRIDDLE_MOVE_ROW_WITH_ID_TO_RELATIVE_POSITION_BY_ID(state, action) {
    var moveId = action.moveId,
        referenceId = action.referenceId,
        relativePosition = action.relativePosition;


    var data = state.get('data');
    var movedRowIdxPath = getIndexPath_fromNestedData_withFindFunc(data.toJS(), function (el) {
        return el.id === moveId;
    });
    var movedRow = data.getIn(getPathInData_fromIdxPath(movedRowIdxPath));
    var movedRowContainerPath = getGridRowContainerPath_fromIdxPath(movedRowIdxPath);

    var referenceRowJS = getRow_withId_fromNestedData(referenceId, data.toJS());

    if (_lodash2.default.get(referenceRowJS, 'disable_adding_children') && relativePosition === 'inside') {
        return state;
    }

    var dataWithoutMovedRow = data.updateIn(movedRowContainerPath, function (children) {
        return children.splice(_lodash2.default.last(movedRowIdxPath), 1);
    });

    var referenceComponentIdxPath = getIndexPath_fromNestedData_withFindFunc(dataWithoutMovedRow.toJS(), function (el) {
        return el.id === referenceId;
    });

    var targetContainerIdxPath = relativePosition === 'inside' ? referenceComponentIdxPath : referenceComponentIdxPath.slice(0, -1);

    var insertIdx = {
        inside: referenceRowJS.children.length,
        before: _lodash2.default.last(referenceComponentIdxPath),
        after: _lodash2.default.last(referenceComponentIdxPath) + 1
    }[relativePosition];

    var targetRowContainerPath = targetContainerIdxPath.length ? getPathInData_fromIdxPath(targetContainerIdxPath).concat('children') : [];

    var newData = dataWithoutMovedRow.updateIn(targetRowContainerPath, function (children) {
        var childrenNormalizedAsArray = children == undefined ? _immutable2.default.List.of() : children;
        return childrenNormalizedAsArray.splice(insertIdx, 0, movedRow);
    });

    // make sure the parent we just added to is expanded
    var expandedRows = state.get('expandedRows');
    var newExpandedRows = relativePosition === 'inside' && referenceId ? expandedRows.set(referenceId, true) : expandedRows;

    var newDataWithRowProps = setRowProperties(state, newData);

    return state.set('data', newDataWithRowProps).set('expandedRows', newExpandedRows);
}

function GRIDDLE_MOVE_ROW_WITH_ID_TO_CHILD_OF_ROW_WITH_ID(state, action, helpers) {
    var moveId = action.moveId;
    var targetId = action.targetId;

    var data = state.get('data');
    var movedRowJS = getRow_withId_fromNestedData(moveId, data.toJS());
    var movedRowIdxPath = movedRowJS.indexPath;
    var movedRowContainerPath = getGridRowContainerPath_fromIdxPath(movedRowIdxPath);
    var movedRow = data.getIn(getPathInData_fromIdxPath(movedRowIdxPath));

    var targetRowJS = getRow_withId_fromNestedData(targetId, data.toJS());

    if (movedRow == null || _immutable2.default.Iterable.isIterable(movedRow) && !movedRow.get('id') || _lodash2.default.get(targetRowJS, 'disable_adding_children')) {
        return state;
    }

    var dataWithoutMovedRow = data.updateIn(movedRowContainerPath, function (children) {
        return children.splice(movedRowIdxPath.slice(-1)[0], 1);
    });

    var targetComponentIdxPath = getIndexPath_fromNestedData_withFindFunc(dataWithoutMovedRow.toJS(), function (el) {
        return el.id === targetId;
    });
    var targetRowContainerPath = getGridRowContainerPath_fromIdxPath(targetComponentIdxPath.concat(0));

    var newData = dataWithoutMovedRow.updateIn(targetRowContainerPath, function (children) {
        var childrenNormalizedAsArray = children == undefined ? _immutable2.default.List.of() : children;
        return childrenNormalizedAsArray.splice(0, 0, movedRow);
    });

    // make sure the parent we just added to is expanded
    var expandedRows = state.get('expandedRows');
    var newExpandedRows = targetId ? expandedRows.set(targetId, true) : expandedRows;

    var newDataWithRowProps = setRowProperties(state, newData);

    return state.set('data', newDataWithRowProps).set('expandedRows', newExpandedRows);
}

function getIndexPath_fromNestedData_withFindFunc(nestedData, findFunc) {
    var indexPath = void 0;

    nestedSearch_children_currIdxPath(nestedData, []);
    function nestedSearch_children_currIdxPath(children, currIdxPath) {
        children.forEach(function (el, idx) {
            if (findFunc(el)) {
                indexPath = currIdxPath.concat(idx);
            } else if (Array.isArray(el.children)) {
                nestedSearch_children_currIdxPath(el.children, currIdxPath.concat(idx));
            }
        });
    }

    return indexPath;
}

function getRow_withId_fromNestedData(id, nestedData) {
    var flattenedArray = flatten_nestedData(nestedData);
    var row = flattenedArray.find(function (child) {
        return child.id === id;
    });
    return row;
}

function GRIDDLE_MOVE_ROW_WITH_ID(state, action, helpers) {
    var id = action.id;

    var data = state.get('data').toJS();
    var flattenedArray = flatten_nestedData(data);
    var movedChild = flattenedArray.find(function (child) {
        return child.id === id;
    });
    var movedChildIdxPath = movedChild.indexPath;

    var newAction = {
        type: "GRIDDLE_MOVE_ROW",
        fromIdxPath: movedChildIdxPath,
        toIdxPath: action.toIdxPath
    };

    return GRIDDLE_MOVE_ROW(state, newAction, helpers);
}

function GRIDDLE_MOVE_ROW(state, action, helpers) {

    var fromIdxPath = action.fromIdxPath;
    var toIdxPath = action.toIdxPath;

    var movedRow = getGridRow_fromData_withIdxPath(state.getIn(['data']), fromIdxPath);
    var movedRowContainerPath = getGridRowContainerPath_fromIdxPath(fromIdxPath);
    var targetRowContainerPath = getGridRowContainerPath_fromIdxPath(toIdxPath);
    var targetRowParentPath = targetRowContainerPath.slice(0, -1) || [];

    var data = state.get('data');
    var targetRowParent = targetRowParentPath.length > 0 ? data.getIn(targetRowParentPath) : undefined;

    if (movedRow == null || _immutable2.default.Iterable.isIterable(movedRow) && !movedRow.get('id') || _immutable2.default.Iterable.isIterable(targetRowParent) && (!targetRowParent.get('id') || targetRowParent.get('disable_adding_children'))) {
        return state;
    }

    var dataWithoutMovedRow = data.updateIn(movedRowContainerPath, function (children) {
        return children.splice(fromIdxPath.slice(-1)[0], 1);
    });

    var newTargetComponentIdxPath = !targetRowParent ? [] : getIndexPath_fromNestedData_withFindFunc(dataWithoutMovedRow.toJS(), function (el) {
        return el.id === targetRowParent.get('id');
    });
    var newTargetRowContainerPath = getGridRowContainerPath_fromIdxPath(newTargetComponentIdxPath.concat(toIdxPath.slice(-1)));

    var newData = dataWithoutMovedRow.updateIn(newTargetRowContainerPath, function (children) {
        children = children == undefined ? _immutable2.default.List.of() : children;
        return children.splice(toIdxPath.slice(-1)[0], 0, movedRow);
    });

    // make sure the parent we just added to is expanded
    var expandedRows = state.get('expandedRows');
    var newExpandedRows = targetRowParent ? expandedRows.set(targetRowParent.get('id'), true) : expandedRows;

    var newDataWithRowProps = setRowProperties(state, newData);

    return state.set('data', newDataWithRowProps).set('expandedRows', newExpandedRows);
}

function GRIDDLE_FILTERED_AFTER(state, action, helpers) {
    var newFilter = action.filter;

    var utilityBarState = state.get('utilityBarProperties');

    return newFilter ? setRowProperties_inState(state).setIn(['utilityBarProperties'], _extends({}, utilityBarState, { sortable_enabled: false })) : setRowProperties_inState(state);
}
function GRIDDLE_LOADED_DATA_AFTER(state, action, helpers) {
    return setRowProperties_inState(state);
}

function setRowProperties_inState(state) {
    var data = state.get('data');
    var newData = setRowProperties(state, data);

    return state.set('data', setRowProperties(state, data));
}

function setRowProperties(state, data) {
    var parentIndexPath = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var depth = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    var parent_id = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;


    var key = 0;
    var getKey = function getKey() {
        return key++;
    };

    var utilityBarState = _immutable2.default.Iterable.isIterable(state.get('utilityBarProperties')) ? state.get('utilityBarProperties').toJS() : state.get('utilityBarProperties');

    var isDraggable = !state.get('filter') && _lodash2.default.get(utilityBarState, 'sortable_enabled') === true && _lodash2.default.get(utilityBarState, 'template') == null;

    return data.map(function (row, index) {
        var id = row.get('id');
        var children = row.get('children');
        var currentIndexPath = parentIndexPath !== null ? [].concat(_toConsumableArray(parentIndexPath), [getKey()]) : [getKey()];

        if (children && children.size > 0) {
            children = setRowProperties(state, children, currentIndexPath, depth + 1, id);
        }

        return row.set('parent_id', parent_id).set('isDraggable', isDraggable).set('children', children).set('depth', depth).set('expandedFlag', state.get('expandedRows').get(row.get('id'))).set('indexPath', currentIndexPath).set('hasChildren', children && children.size > 0);
    });
}

function getGridRowContainerPath_fromIdxPath(idxPath) {

    if (idxPath.length === 1) {
        return [];
    } else {
        return getPathInData_fromIdxPath(idxPath).slice(0, -1);
    }
}

function getGridRow_fromData_withIdxPath(data, idxPath) {

    var pathToRowInData = getPathInData_fromIdxPath(idxPath);
    return data.getIn(pathToRowInData);
}

// takes in somthing like this: [0,3,2]
// and returns [0,'children',3,'chidren',2]
// used to get the data from a nested structure
function getPathInData_fromIdxPath(idxPath) {
    return idxPath.reduce(function (arr, val, idx) {
        if (idx === 0) {
            return [idxPath[0]];
        } else {
            return arr.concat('children', val);
        }
    }, []);
}

function flatten_nestedData(nestedData) {
    var flattenedArray = [];
    pluckAllChildrenIntoFlattenedArray_fromNestedArr(nestedData);
    function pluckAllChildrenIntoFlattenedArray_fromNestedArr(arr) {
        arr.forEach(function (child) {
            flattenedArray.push(child);
            if (Array.isArray(child.children)) {
                pluckAllChildrenIntoFlattenedArray_fromNestedArr(child.children);
            }
        });
    }
    return flattenedArray;
}