'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

exports.convertFormJS_toFieldEditorState = convertFormJS_toFieldEditorState;
exports.convertFieldEditorState_toFormJS = convertFieldEditorState_toFormJS;

var _normalizr = require('normalizr');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Helper functions and data

var typeToInstanceType = {
    DEFAULT: 'Field',
    Tab: 'Tab',
    Fieldset: 'Fieldset',
    SubTemplate: 'SubTemplate'
};

var instanceTypeToType = {
    DEFAULT: 'Field',
    Tab: 'Tab',
    Fieldset: 'Fieldset',
    SubTemplate: 'SubTemplate'
};

var typesToSkip = {
    Header: true,
    ScheduledStatus: true,
    ContextMenu: true,
    MenuItem: true,
    Button: true,
    ViewLink: true,
    Hidden: true,
    Table: true,
    Footer: true
};

//
//  FORM JS ==> FIELD EDITOR STATE
//

function _createIdGenerator() {
    var nextId = 1;
    return function (item) {
        item._id = '' + nextId++;
        return item._id; //# we need to mutate the item because this is the only way we can set
    };
};

function _transformConstraints(output, key, value, input) {
    // Loop through each key in the verify object
    // { verify : { required : ..., max : ... } }
    Object.keys(value).forEach(function (verifyKey) {
        var verifyVal = value[verifyKey];
        var constraintKey = "constraint_" + verifyKey;

        // Handle if the value of this key is also an object
        // { required : { value : true } }
        if (_lodash2.default.isObject(verifyVal) && verifyVal.hasOwnProperty('value')) {
            output.instanceProps[constraintKey] = { value: verifyVal.value };

            // And also handle if there is an error message
            // { required : { value : true, error : "Required!" } }
            if (verifyVal.error) {
                output.instanceProps[constraintKey + '_error'] = { value: verifyVal.error };
            }
        } else {
            // Or, handle a simple string/number
            // { max : 5 }
            output.instanceProps[constraintKey] = { value: verifyVal };
        }
    });
}

function _transformImgConstraints(output, key, value, input) {
    // Loop through each key in the constraints object
    // { constraints : { min_width : ..., type : ... } }
    Object.keys(value).forEach(function (constraintKey) {
        var constraintVal = value[constraintKey];
        var propKey = "constraint_media_" + constraintKey;

        if (constraintKey === 'type') {
            // Handle a list of media types
            // "image, video" becomes [ "image", "video" ]
            var decodedVerifyVal = constraintVal.split ? constraintVal.split(/ *, */) : constraintVal;
            output.instanceProps[propKey] = { value: decodedVerifyVal };
        } else {
            // Or, handle a simple string/number
            // { max : 5 }
            output.instanceProps[propKey] = { value: constraintVal };
        }
    });
}

function _transformComponentName(output, key, value, input) {
    // Set the instance type if in the mapping 
    // ( like for tabs/fieldset/etc )
    if (typeToInstanceType[value]) {
        output.instanceProps.instanceType = typeToInstanceType[value];
    }

    // Add a skip == true, without instance type if we want to skip
    // ( like for form_header, form_footer )
    else if (typesToSkip[input.componentName]) {
            output.skip = true;
            output.instanceProps.componentName = { value: value };
        }

        // Catch all - set instanceType to 'Field'
        // and add the type to instanceProps.type
        else {
                output.instanceProps.instanceType = typeToInstanceType.DEFAULT;
                output.instanceProps.componentName = { value: value };
            }
}

var HIJACK_CHILDREN_FOR_COMPONENTS = ['Header', 'Repeater', 'ExpandedData', 'ArrayMap']; //# NOTE: not great but we need to do this to prevent children from being rendered

function _transformItem(output, key, value, input) {
    var shouldHijackChildren = _lodash2.default.includes(HIJACK_CHILDREN_FOR_COMPONENTS, input.componentName);

    // All other keys are different,
    // so start by removing what normalizr added
    delete output[key];

    // Setup an instanceProps
    if (!output.instanceProps) output.instanceProps = {};

    // Handle the type/verify/items keys specifically 
    //# this is the artificially injected id from _createIdGenerator. We need to remove it from the input
    //# and check the '_id' in output to 'id'
    //# This is not ideal, but we need to work within the confines of the normalizr module
    if (key == '_id') {
        delete input._id;
        output.id = value;
    } else if (_lodash2.default.isPlainObject(value) && value.hasOwnProperty('_doLift')) {
        //# WARNING:
        //# NOTE: we're doing something hacky in 'setFieldErrors_reducer'
        //# so we need to just pass it through
        output.instanceProps[key] = value;
    } else if (key == 'componentName') {
        _transformComponentName(output, key, value, input);
    } else if (key == 'verify') {
        _transformConstraints(output, key, value, input);
    } else if (key == 'constraints') {
        _transformImgConstraints(output, key, value, input);
    } else if (key == 'children' && !shouldHijackChildren) {
        output.childIds = value;
    } else if (key == 'children' && shouldHijackChildren) {
        output.instanceProps.children = { value: input.children };
    } else {
        //# The assumption i
        // All other keys go from:
        // { name: "test" } ==> { instanceProps: { name: { value: "test" } } }
        output.instanceProps[key] = { value: value };

        // All instance props are meant to be editable by the Ruby User
        // So, lock any fields that have complex values for editable keys
        //# NOTE: this should never happen anymore
        if (/^(key)$/.test(key) && value !== null && !/^(undefined|boolean|number|string)$/.test(typeof value === 'undefined' ? 'undefined' : _typeof(value))) {
            output.instanceProps._locked = { value: true };
        }
    }
};

function _getAllItemIdsInOrder(allItems, pickItemIds) {
    return _lodash2.default.reduce(pickItemIds, function (allItemIds, itemId) {
        return allItemIds.concat([itemId], _getAllItemIdsInOrder(allItems, allItems[itemId].childIds || []));
    }, []);
}

// Convert formJS from the server into the state that fieldEditor expects
function convertFormJS_toFieldEditorState(formJS) {
    // Define how our data structure looks
    var child = new _normalizr.Schema('child', {
        idAttribute: _createIdGenerator(),
        assignEntity: _transformItem
    });
    child.define({
        children: (0, _normalizr.arrayOf)(child)
    });

    // Normalize our data structure
    var rawData = (0, _normalizr.normalize)({ children: _lodash2.default.isArray(formJS) ? formJS : [formJS] }, { children: (0, _normalizr.arrayOf)(child) });

    // Return a map of instances, ID => form element
    // and the list of IDs in the order that they appeared
    return {
        instances: rawData.entities.child || {},
        childIds: _getAllItemIdsInOrder(rawData.entities.child, rawData.result.children)
    };
};

function _convertInstanceType_toFormJSType(instanceType) {
    var type = instanceTypeToType[instanceType];
    return { componentName: type ? type : instanceTypeToType.DEFAULT };
}

//# TODO: check for prefix 'constraint_' instead
function _convertInstancePropsKeyValue_toFormJSVerify(key, value) {
    //const verifyCheck = /^constraint_(.*)((_)(error))?$/.exec(key);
    var verifyCheck = /^constraint_(.*)?$/.exec(key);
    var excludeMediaCheck = !/^constraint_media_(.*)?$/.exec(key);

    // { required: { value: true } } becomes { verify: { required: true } }
    // { required: { error: "required!" } } is invalid

    if (verifyCheck && excludeMediaCheck) {
        var verifyKey = verifyCheck[1];
        //const verifySubKey = verifyCheck[4];
        /*
            {
                verify: {
                    required: true
                }
            }
        */
        return {
            verify: _defineProperty({}, verifyKey, value.value)
        };
    } else {
        return null;
    }
}

function _convertInstancePropsKeyValue_toFormJSImgConstraints(key, value) {
    var constraintsCheck = /^constraint_media_(.*)?$/.exec(key);

    // { constraint_media_min_width: { value: 1000 } }
    // becomes { constraints: { media_min_width: 1000 } }

    if (constraintsCheck) {
        var constraintsKey = constraintsCheck[1];

        // Change { "type": [ "image", "video" ] }
        // To { "type": "image,video" }
        var encodeTypeValue = constraintsKey === "type" && value.value && value.value.join;
        var constraintsValue = encodeTypeValue ? value.value.join(',') : value.value;

        return {
            constraints: _defineProperty({}, constraintsKey, constraintsValue)
        };
    } else {
        return null;
    }
}

/*
{
    "a84553c0-8c1f-11e7-aaa3-81ec19669b4b": {
        "id": "a84553c0-8c1f-11e7-aaa3-81ec19669b4b",
        "instanceProps": {
            "instanceType": "Fieldset",
            "label": {
                "value": null
            }
        },
        "scrollToBottomOnMount": false
    }
}
 */
function _convertStateInstance_toFormJSItem(instance) {
    return _lodash2.default.reduce(instance.instanceProps, function (formJSItem, value, key, instanceProps) {
        // Handle verify & image constraint properties first
        var verifyObj = _convertInstancePropsKeyValue_toFormJSVerify(key, value);
        var constraintsObj = _convertInstancePropsKeyValue_toFormJSImgConstraints(key, value);
        if (verifyObj) {
            return _lodash2.default.merge({}, formJSItem, verifyObj);
        } else if (constraintsObj) {
            return _lodash2.default.merge({}, formJSItem, constraintsObj);
        }

        // Only honor the instanceType if there is no type
        else if (key == 'instanceType' && !instanceProps.componentName) {
                return _lodash2.default.merge({}, formJSItem, _convertInstanceType_toFormJSType(value));
            }

            // As long as all other keys are of the form:
            //   { param: { value: "some value" } }
            // Then transform into:
            //   { param: "some value" }
            else if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) == 'object' && value.hasOwnProperty('value')) {
                    if (key === 'options' && typeof value.value === 'string') {
                        return _lodash2.default.merge({}, formJSItem, _defineProperty({}, key, value.value ? value.value.split(/\r\n|\r|\n/).map(function (line) {
                            return {
                                text: line,
                                value: line
                            };
                        }) : undefined));
                    } else {
                        if (key == 'key' && value.value == "") {
                            //# do not allow empty string for 'key' prop
                            return formJSItem;
                        }

                        return _lodash2.default.merge({}, formJSItem, _defineProperty({}, key, value.value));
                    }
                } else {
                    return formJSItem;
                }
    }, {});
}

// Convert fieldEditor state into the formJS format
function convertFieldEditorState_toFormJS(fieldEditorState) {
    var allCards = fieldEditorState.childIds.map(function (id) {
        return fieldEditorState.instances[id];
    });

    var firstVisibleCardIndex = _lodash2.default.findIndex(allCards, function (card) {
        return !card.skip;
    });

    var hiddenFormItems = allCards.slice(0, firstVisibleCardIndex).map(function (card) {
        return _convertStateInstance_toFormJSItem(card);
    });

    var visibleFormItems = allCards.slice(firstVisibleCardIndex).map(function (card) {
        return _convertStateInstance_toFormJSItem(card);
    });

    var form = [].concat(getNestedFormItems_fromFlatFormItems(hiddenFormItems), getNestedFormItems_fromFlatFormItems(visibleFormItems));

    return form;
}

var defaultAllowableParents = { 'Tab': true, 'Fieldset': true, 'Header': true, 'Object': true };
var allowableParentsByComponent = {
    Header: {},
    Tab: {},
    Fieldset: { "Tab": true },
    Object: { "Tab": true, "Fieldset": true },
    ViewLink: { "Tab": true, "Fieldset": true, "Header": true, "ContextMenu": true },
    MenuItem: { "Tab": true, "Fieldset": true, "Header": true, "ContextMenu": true }
};

function getNestedFormItems_fromFlatFormItems() {
    var formItems = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    return formItems.reduce(function (result, curr) {
        var possibleParents_refs = function getPossibleParents(arr) {
            var lastItem = _lodash2.default.last(arr);
            var lastItemChildren = _lodash2.default.get(lastItem, 'children');
            return [].concat(lastItem || [], lastItemChildren ? getPossibleParents(lastItemChildren) : []);
        }(result);

        var targetParent_ref = _lodash2.default.findLast(possibleParents_refs, function (possibleParent) {
            return can_item_haveChild(possibleParent, curr);
        });

        if (targetParent_ref) {
            targetParent_ref.children ? targetParent_ref.children.push(curr) : targetParent_ref.children = [curr];
            return result;
        } else {
            return result.concat(curr);
        }
    }, []);

    function can_item_haveChild(parentItem, child) {
        var parentComponentName = _lodash2.default.get(parentItem, 'componentName');
        var childComponentName = _lodash2.default.get(child, 'componentName');

        var allowableParents = _lodash2.default.get(allowableParentsByComponent, childComponentName) || defaultAllowableParents;

        return _lodash2.default.get(allowableParents, parentComponentName, false);
    }
}