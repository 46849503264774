'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    Toggle: {
        style: {
            width: 'auto'
        }
    },
    Paper: {
        style: {
            padding: '5px'
        }
    }
};