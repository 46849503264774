'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _transitions = require('material-ui/styles/transitions');

var _transitions2 = _interopRequireDefault(_transitions);

var _Toggle = require('@rubyapps/ruby-material-ui/Toggle');

var _Toggle2 = _interopRequireDefault(_Toggle);

var _addInfoModeProps = require('@rubyapps/ruby-react-components/src/client/hoc/addInfoModeProps');

var _addInfoModeProps2 = _interopRequireDefault(_addInfoModeProps);

var _Toggles_style = require('./Toggles_style');

var _Toggles_style2 = _interopRequireDefault(_Toggles_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var PropTypes = _react2.default.PropTypes;

var RubyIcons = require('@rubyapps/ruby-icons');

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label,
    RichErrorMessages = _require.RichErrorMessages;

var fieldBaseStyles = process.browser ? require('@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule').default : {};
var togglesStyles = process.browser ? require('./Toggles.cssModule').default : {};
var styles = _extends({}, fieldBaseStyles, togglesStyles);

var optionUtils = require('@rubyapps/ruby-component-field-dropdown/src/client/reactComponents/optionUtils');

var getStyles = function getStyles(props, context, state) {
    var _context$muiTheme = context.muiTheme,
        baseTheme = _context$muiTheme.baseTheme,
        errorColor = _context$muiTheme.textField.errorColor,
        toggle = _context$muiTheme.toggle;


    var styles = {
        error: {
            position: 'relative',
            bottom: -6,
            fontSize: 12,
            lineHeight: '12px',
            color: errorColor,
            transition: _transitions2.default.easeOut()
        },
        label: {
            color: props.disabled ? toggle.labelDisabledColor : toggle.labelColor
        }
    };

    _extends(styles.error, props.errorStyle);

    return styles;
};

var PropTypes_value = PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]);
var FieldToggles = _react2.default.createClass({
    displayName: 'FieldToggles',

    propTypes: {
        label: PropTypes.string,
        fieldKey: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes_value, PropTypes.arrayOf(PropTypes_value)]),
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes_value,
            text: PropTypes.string
        })),
        optgroupKey: PropTypes.oneOfType([PropTypes.string
        //# TODO: none of the following are supported yet
        , PropTypes.arrayOf(PropTypes.string), PropTypes.shape({
            value: PropTypes.arrayOf(PropTypes.string),
            toHTML: PropTypes.func
        })]),
        preloadOptions: PropTypes.bool,
        id: PropTypes.string,
        actions: PropTypes.object,
        style: PropTypes.shape({
            columns: PropTypes.number
            //# number of columns to spread toggles
        })
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null,
            styles: {
                columns: 3
            }
        };
    },
    _onValueChange: function _onValueChange(event, toggled, optionItem) {
        var values = this.values();
        var valueMap = _.keyBy(values, _.identity);
        if (toggled) {
            valueMap[optionItem.value] = true;
        } else {
            delete valueMap[optionItem.value];
        }

        this.props.actions.setFieldValueByKey(Object.keys(valueMap), this.props.fieldKey);
    },

    _renderToggleColumns: function _renderToggleColumns(dataSource) {
        var _this = this,
            _React$createElement5;

        var prepareStyles = this.context.muiTheme.prepareStyles;

        var styleObjectFromTheme = getStyles(this.props, this.context, this.state);

        var groupedDataSource = this.groupedDataSourceItems(dataSource);

        var isInfoMode = this._isInfoMode();
        var infoModeStyles = isInfoMode ? {
            labelStyle: _extends({
                cursor: 'not-allowed'
            }, styleObjectFromTheme.label, this.props.labelStyle || {})
        } : {};

        var columns = this.props.styles.columns;


        var itemsPerCol = Math.ceil(dataSource.length / columns);

        var values = this.values();
        var valueMap = _.keyBy(values, _.identity);

        var bucketedRows = _.map(groupedDataSource, function (dataSource, optgroupLabel) {
            var _React$createElement2, _React$createElement3, _React$createElement4;

            if (optgroupLabel == 'undefined') {
                optgroupLabel = undefined;
            }

            var numOfRows = Math.ceil(dataSource.length / columns);

            var rowComponents = [].concat(_toConsumableArray(Array(numOfRows).keys())).map(function (index) {
                var _React$createElement;

                var itemsForRow = dataSource.slice(index * columns, (index + 1) * columns);

                return _react2.default.createElement(
                    'div',
                    (_React$createElement = { className: styles.row, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'Toggles'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Toggles'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Toggles'), _React$createElement),
                    function () {
                        return itemsForRow.map(function (item) {
                            var _extends2, _extends3;

                            var labelProps = {
                                label: item.text
                            };

                            var isToggled = valueMap.hasOwnProperty(item.value);

                            return _react2.default.createElement(_Toggle2.default, _extends({
                                label: _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                                    'data-codecept-selector-node': 'Label',
                                    'data-codecept-selector-file': 'Toggles'
                                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Toggles'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Toggles'), _extends2))),
                                className: styles.toggle,
                                onToggle: function onToggle(event, toggled) {
                                    return _this._onValueChange(event, toggled, item);
                                },
                                labelPosition: 'right'
                            }, infoModeStyles, _Toggles_style2.default.Toggle, {
                                disabled: _this.props.locked || _this.props.disabled || isInfoMode
                            }, _.get(_this, 'props.infoModeProps.default'), _.get(_this, 'props.infoModeProps.Toggle'), (_extends3 = {
                                toggled: isToggled,
                                'data-codecept-selector-node': 'Toggle',
                                'data-codecept-selector-file': 'Toggles'
                            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Toggles'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Toggles'), _extends3)));
                        });
                    }()
                );
            });

            return _react2.default.createElement(
                'div',
                (_React$createElement4 = { className: styles.rowGroup, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Toggles'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Toggles'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Toggles'), _React$createElement4),
                optgroupLabel ? [_react2.default.createElement(Label, (_React$createElement2 = { label: optgroupLabel, className: styles.label, 'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'Toggles'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Toggles'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Toggles'), _React$createElement2))] : _react2.default.createElement('hr', (_React$createElement3 = { className: styles.hr, 'data-codecept-selector-node': 'hr',
                    'data-codecept-selector-file': 'Toggles'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'hr'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Toggles'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'hr'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Toggles'), _React$createElement3)),
                rowComponents
            );
        });

        return _react2.default.createElement(
            'div',
            (_React$createElement5 = { className: styles.rows, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Toggles'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Toggles'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Toggles'), _React$createElement5),
            bucketedRows
        );
    },
    render: function render() {
        var _React$createElement6, _extends4, _React$createElement7, _React$createElement8;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var prepareStyles = this.context.muiTheme.prepareStyles;

        var styleObjectFromTheme = getStyles(this.props, this.context, this.state);

        var filteredDataSource = this.dataSourceItems(this.props.filteredOptions || []);

        var labelProps = _.pick(this.props, Object.keys(Label.propTypes));
        var errorTextElement = !_.isEmpty(this.props.richErrorMessages) ? _react2.default.createElement(RichErrorMessages, (_React$createElement6 = { messages: this.props.richErrorMessages, 'data-codecept-selector-node': 'RichErrorMessages',
            'data-codecept-selector-file': 'Toggles'
        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Toggles'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Toggles'), _React$createElement6)) : null;
        return _react2.default.createElement(
            'div',
            (_React$createElement8 = { className: styles.wrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Toggles'
            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Toggles'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Toggles'), _React$createElement8),
            _react2.default.createElement(
                'label',
                (_React$createElement7 = { className: styles.label, 'data-codecept-selector-node': 'label',
                    'data-codecept-selector-file': 'Toggles'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Toggles'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Toggles'), _React$createElement7),
                _react2.default.createElement(Label, _extends({}, labelProps, (_extends4 = {
                    'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'Toggles'
                }, _defineProperty(_extends4, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Toggles'), _defineProperty(_extends4, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Toggles'), _extends4)))
            ),
            errorTextElement,
            this._renderToggleColumns(filteredDataSource)
        );
    },

    _isInfoMode: function _isInfoMode() {
        var mode = this.props.mode;


        return mode === 'info';
    }

    //# On load
    , componentDidUpdate: function componentDidUpdate(prevProps, prevState) {
        if (this._isMounted && !this.props.isMounted) {
            if (this.props.preloadOptions) {
                this.props.actions.preloadOptions();
            }

            this.props.actions.setComponentIsMounted && this.props.actions.setComponentIsMounted();
        }
    },
    componentDidMount: function componentDidMount() {
        this._isMounted = true; //# NOTE: cannot use setState because that happens asynchronously which causes issues with the async setState call
        if (this.props.preloadOptions) {
            this.props.actions.preloadOptions();
        }

        // this.props.actions.setComponentIsMounted && this.props.actions.setComponentIsMounted();
    },
    componentWillUnmount: function componentWillUnmount() {
        this._isMounted = false;
        //console.log(`[componentWillUnmount()] ${this.props.id}`);
    },

    values: function values() {
        var dataIsArray = Array.isArray(this.props.value || []);

        //# the connect layer should have normalized the value
        return dataIsArray ? this.props.value : [this.props.value];
    }
    //# options methods 

    , disambiguatedOptions: optionUtils.disambiguatedOptions,
    displayTextFromOption: optionUtils.displayTextFromOption,
    dataSourceItems: function dataSourceItems(options) {
        //# for Selectize
        var disambiguatedOptions = this.disambiguatedOptions(options || this.props.options);
        //# filter out null values
        //return disambiguatedOptions.filter(option => option.value != null);

        return disambiguatedOptions.reduce(function (collector, opt) {
            var _React$createElement9;

            if (opt.value == null) {
                collector.push(opt);
                return collector;
            }

            var IconClass = opt.rubyIconPath && RubyIcons.getIconClassForPath(opt.rubyIconPath);
            var Icon = IconClass && _react2.default.createElement(IconClass, (_React$createElement9 = {
                'data-codecept-selector-node': 'IconClass',
                'data-codecept-selector-file': 'Toggles'
            }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'IconClass'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Toggles'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'IconClass'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Toggles'), _React$createElement9));
            collector.push(_extends({}, opt, { icon: Icon //# TODO update option render to render icon
                , text: opt.text
            }));

            return collector;
        }, []);
    },
    groupedDataSourceItems: function groupedDataSourceItems(dataSourceItems) {
        if (!this.props.optgroupKey) {
            return {
                "undefined": dataSourceItems
            };
        }
        return _.groupBy(dataSourceItems, this.props.optgroupKey);
    }
});

exports.default = (0, _addInfoModeProps2.default)(FieldToggles);