'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});


var FONT_SIZE = 14;
var FLOATING_LABEL_STYLE = {
    top: 42
};
exports.default = {
    Dropdown: {
        SelectField: {
            floatingLabelStyle: FLOATING_LABEL_STYLE,
            style: {
                fontSize: FONT_SIZE,
                width: 106,
                top: -10
            },
            labelStyle: {
                top: 9
            },
            errorStyle: {
                position: 'absolute',
                marginBottom: -22,
                width: '230px',
                right: 0,
                textAlign: 'right'
            }
        }
    }
};