'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TokenTagger = require('@rubyapps/ruby-component-field-token-tagger/src/client/reactComponents/TokenTagger');

var _TokenTagger2 = _interopRequireDefault(_TokenTagger);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var RubyWords = require('@rubyapps/ruby-words');
var request = require('@rubyapps/ruby-superagent');

var PermissionTokenTagger = _react2.default.createClass({
    displayName: 'PermissionTokenTagger',

    propTypes: {
        templateKeyword: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        templateName: PropTypes.string,
        permKey: PropTypes.string.isRequired,
        idsByPermId: PropTypes.object.isRequired,
        getOptionsEndpoint: PropTypes.func.isRequired,
        actions: PropTypes.object.isRequired,
        count: PropTypes.number
    },
    getDefaultProps: function getDefaultProps() {
        return {
            count: 50
        };
    },
    getInitialState: function getInitialState() {
        return {
            searchValue: null,
            filteredOptions: [],
            options: [],
            hasTemplateKeyword: (this.props.templateKeyword || []).length ? true : false,
            hadPreloadedOptions: false
        };
    },

    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        if ((nextProps.templateKeyword || []).length) {
            this.setState({
                hasTemplateKeyword: true
            });
        }
    },

    render: function render() {
        var _this = this,
            _React$createElement;

        var value = this.valueFromProps(this.props);

        return _react2.default.createElement(_TokenTagger2.default, (_React$createElement = {
            label: 'Limit access to the following:',
            hintText: 'Search to add ' + RubyWords.inflection.pluralize(this.props.templateName),
            style: {
                Card: {
                    style: {
                        marginTop: 4
                    }
                }
            },
            actions: {
                setFieldValueByKey: function setFieldValueByKey(value, key) {
                    _this.props.actions.setIdsForPermKey(value, _this.props.permKey);
                },
                setSearchValue: function setSearchValue(value) {
                    _this.setSearchValue(value);
                }
            },
            searchValue: this.state.searchValue,
            value: value,
            filteredOptions: this.state.filteredOptions,
            options: this.state.options,
            'data-codecept-selector-node': 'TokenTagger',
            'data-codecept-selector-file': 'PermissionTokenTagger'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'TokenTagger'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'PermissionTokenTagger'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'TokenTagger'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'PermissionTokenTagger'), _React$createElement));
    },

    componentDidMount: function componentDidMount() {
        this.performUpdatesBasedOnState();
    },
    componentDidUpdate: function componentDidUpdate() {
        this.performUpdatesBasedOnState();
    }

    //== STATE SETTERS AKA Actions ==================================================//
    , setSearchValue: function setSearchValue(value) {
        var _this2 = this;

        this.setState({
            searchValue: value
        });

        var url = this.props.getOptionsEndpoint({ templateKeyword: this.props.templateKeyword });

        this.retrieveOptionsFromUrl_withQuery(url, { search_input: value, count: this.props.count }).then(function (data) {
            _this2.setFilteredOptions(data);
        });
    },
    retrieveOptionsFromUrl_withQuery: function retrieveOptionsFromUrl_withQuery(url) {
        var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var allowAbort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

        var self = this;

        //# kill previous request
        if (allowAbort) {
            this._requestObject && this._requestObject.abort();
            this._requestObject = null;
        }

        var requestObject = request.get(url).query(query);

        this._requestObject = requestObject;

        var requestPromise = requestObject.then(function success(response) {
            self._requestObject = null;
            var data = response.body.data;

            return data;
        }, function (error) {
            self._requestObject = null;

            throw error;
        });

        return requestPromise;
    },
    setFilteredOptions: function setFilteredOptions(filteredOptions) {
        this.setState({
            filteredOptions: filteredOptions
        });
    },
    updateCachedOptions: function updateCachedOptions(options) {
        var stateOptions = this.state.options;
        var remoteOptions = (options ? options : []).map(function (option) {
            return {
                value: option.value || option.id,
                text: option.text || option.name
            };
        });

        var mergedOptions = _lodash2.default.uniqBy(stateOptions.concat(remoteOptions), 'value');

        this.setState({
            options: mergedOptions
        });
    }

    //== UTILITIES =================================================//

    , performUpdatesBasedOnState: function performUpdatesBasedOnState() {
        if (!this.state.hasTemplateKeyword) {
            return;
        }

        var hadPreloadedOptions = this.state.hadPreloadedOptions;


        if (!hadPreloadedOptions) {
            this.preloadOptions();
        }

        var currentValue = this.valueFromProps(this.props);

        //# retrieve data and update state
        var options = this.state.options;


        var pendingIdsToHydrate = this._pendingIdsToHydrate || [];

        var idsToHydrate = _lodash2.default.difference(currentValue || [], pendingIdsToHydrate).reduce(function (collector, id) {
            var foundOption = _lodash2.default.find(options, ['value', id]) || _lodash2.default.find(options, ['value', Number(id)]);
            if (!foundOption) {
                collector.push(id);
            }
            return collector;
        }, []);

        //console.log('curr:', currentValue, 'idsToHydrate:', idsToHydrate, options);

        if (idsToHydrate.length) {
            this._pendingIdsToHydrate = idsToHydrate;
            this.hydrateIds(idsToHydrate);
        }
    },
    preloadOptions: function preloadOptions() {
        var _this3 = this;

        this.setState({
            hadPreloadedOptions: true
        });

        var url = this.props.getOptionsEndpoint({ templateKeyword: this.props.templateKeyword });

        return this.retrieveOptionsFromUrl_withQuery(url, { count: this.props.count }).then(function (data) {
            _this3.setFilteredOptions(data);
            _this3.updateCachedOptions(data);
        });
    },

    hydrateIds: function hydrateIds(ids) {
        var _this4 = this;

        var url = this.props.getOptionsEndpoint({ templateKeyword: this.props.templateKeyword });

        return this.retrieveOptionsFromUrl_withQuery(url, { id: ids }).then(function (data) {
            _this4._pendingIdsToHydrate = null;
            _this4.updateCachedOptions(data);
        });
    },
    valueFromProps: function valueFromProps(props) {
        var value = _lodash2.default.get(props, ['idsByPermId', props.permKey], []);
        return value;
    }
});

exports.default = PermissionTokenTagger;