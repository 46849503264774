"use strict";

function typesWithID(id) {
    return {
        OPEN: "@@ruby-app/" + id + "/OPEN_POPOVER",
        CLOSE: "@@ruby-app/" + id + "/CLOSE_POPOVER",
        UPDATE_DISPLAY_VALUE_SPECS: "@ruby-app/" + id + "/UPDATE_DISPLAY_VALUE_SPECS"
    };
}

var generators = {
    open: function open() {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        return {
            type: TYPES.OPEN
        };
    },
    close: function close() {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES;

        return {
            type: TYPES.CLOSE
        };
    },
    updateDisplayValueSpecs: function updateDisplayValueSpecs(displayValueSpecs) {
        var _getAction3 = this.getAction(),
            TYPES = _getAction3.TYPES;

        return {
            type: TYPES.UPDATE_DISPLAY_VALUE_SPECS,
            payload: {
                displayValueSpecs: displayValueSpecs
            }
        };
    }
};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};