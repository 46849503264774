'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Card = require('material-ui/Card');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = process.browser ? require('../Activity.cssModule').default : {};

var ActivityComment = _react2.default.createClass({
    displayName: 'ActivityComment',

    render: function render() {
        var _React$createElement, _React$createElement2;

        var _props = this.props,
            comment = _props.comment,
            rteMode = _props.rteMode;

        var rteClasses = [styles.activityRTEContent, 'rte', 'rte-' + rteMode].join(' ');

        return comment ? _react2.default.createElement(
            _Card.CardText,
            (_React$createElement2 = {
                'data-codecept-selector-node': 'CardText',
                'data-codecept-selector-file': 'comment'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'comment'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'comment'), _React$createElement2),
            _react2.default.createElement('div', (_React$createElement = {
                className: rteClasses,
                dangerouslySetInnerHTML: { __html: comment },
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'comment'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'comment'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'comment'), _React$createElement))
        ) : null;
    }
});

exports.default = ActivityComment;