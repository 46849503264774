'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _bluebird = require('bluebird');

var _bluebird2 = _interopRequireDefault(_bluebird);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

module.exports = {
    //# returns a tree of promises
    //# TODO: need to call on Promise.props
    _displayValueFromLocalState: function _displayValueFromLocalState(selfState, entireState) {
        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        //console.log(`[__formValueFromLocalState()] id: ${this.getID()}, options:`, options);
        if (selfState == undefined) {
            return _bluebird2.default.resolve(undefined);
        }

        var selfID = this.getID();
        var selfKey = this.props.key;

        var children = options.ignoreChildren ? [] : this.getChildren();

        var arrOfReducedChildrenPromises = _lodash2.default.reduce(children, function (collector, child, index) {
            var childID = child.getID();
            var childDisplayValue = child._displayValueFromLocalState ? child._displayValueFromLocalState(selfState[childID], entireState, options) : undefined;
            if (childDisplayValue != undefined) {
                collector.push(childDisplayValue);
            }
            return collector;
        }, []);

        var reducedChildrenPromise = _bluebird2.default.all(arrOfReducedChildrenPromises).then(function (arrOfReducedChildrenState) {
            return _extends.apply(undefined, [{}].concat(_toConsumableArray(arrOfReducedChildrenState)));
        });

        var selfHasFieldsState = selfState.hasOwnProperty('fields');

        var reducedChildrenAvail = arrOfReducedChildrenPromises.length > 0;

        var displayObject = reducedChildrenAvail && selfKey ? _defineProperty({}, selfKey, reducedChildrenPromise) : reducedChildrenAvail ? reducedChildrenPromise : selfHasFieldsState ? this._getValuesFromFieldsObject(selfState.fields, false, undefined, options) : {}; //# return an empty object which gets merged in

        return _bluebird2.default.props(displayObject);
    },
    displayValueFromLocalState: function displayValueFromLocalState(selfState, entireState, options) {
        if (!entireState) {
            entireState = this.getStore().getState();
        }

        entireState = _lodash2.default.cloneDeep(entireState); //# prevent accidental mutations

        return this._displayValueFromLocalState(selfState, entireState, options);
    },
    displayValue: function displayValue(options) {
        return this.displayValueFromLocalState(this.getState(), options);
    },
    cloneSelf: function cloneSelf(parentNode) {
        var overrides = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var children = overrides.children || this.getChildren();

        var clonedNode = Object.create(this);
        clonedNode._isHelper = true;
        clonedNode.clearCache();

        if (parentNode) {
            clonedNode._parent = parentNode;
        };
        clonedNode.getStore = overrides.getStore;

        //console.log('cloning:', clonedNode.getID(), 'clonedNode._isHelper: ', clonedNode._isHelper);
        var clonedChildren = children.map(function (child) {
            return child.cloneSelf(clonedNode, overrides);
        });
        clonedNode._children = clonedChildren;

        return clonedNode;
    }
};