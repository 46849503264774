'use strict';

var CONSTANTS = {
    ROLES: {
        editor: 10,
        publisher: 11
    },
    PERMISSIONS: {
        EDIT: 10,
        PUBLISH: 11
    },
    PERMISSION_STRINGS: {
        PUBLISH: 'can_publish_content'
    },
    PERMISSION_ACTIONS: {
        ADD: 'add',
        EDIT: 'edit',
        GET: 'get',
        DELETE: 'delete',
        RERANK: 'rerank',
        SITEMAP: 'sitemap',
        ANY: '*'
    }
};

module.exports = CONSTANTS;