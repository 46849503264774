'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makePlainUri = makePlainUri;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function makePlainUri(uri) {
  if (!_lodash2.default.isString(uri)) {
    return uri;
  }

  return uri.replace(/\/$/, '').replace(/\/index\.html$/, '').replace(/\.html$/, '');
}