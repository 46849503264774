'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Card = require('material-ui/Card');

var _CopyrightFooter = require('@rubyapps/ruby-react-components/src/client/layout/CopyrightFooter');

var _CopyrightFooter2 = _interopRequireDefault(_CopyrightFooter);

var _ForgotPassword = require('./ForgotPassword.cssModule');

var _ForgotPassword2 = _interopRequireDefault(_ForgotPassword);

var _ForgotPassword_style = require('./ForgotPassword_style');

var _ForgotPassword_style2 = _interopRequireDefault(_ForgotPassword_style);

var _rubyLogo = require('@rubyapps/ruby-logo');

var _rubyLogo2 = _interopRequireDefault(_rubyLogo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

var PropTypes = _react2.default.PropTypes;

var _require = require('@rubyapps/react-flexbox-grid'),
    Grid = _require.Grid,
    Row = _require.Row,
    Col = _require.Col;

//# Local modules

//# icons
var CONSTANTS = require('../../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

//# Styles


var errorPropType = PropTypes.shape({
    message: PropTypes.string
});
var ForgotPassword = _react2.default.createClass({
    displayName: 'ForgotPassword',

    propTypes: {
        clientName: PropTypes.string,
        rubyBranding: PropTypes.string,
        rubyContributors: PropTypes.array
    },

    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6, _extends2, _extends3, _extends4, _React$createElement7, _React$createElement8, _React$createElement9, _React$createElement10;

        return _react2.default.createElement(
            'div',
            (_React$createElement10 = { className: _ForgotPassword2.default.forgotPasswordGridWrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'ForgotPassword'
            }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'ForgotPassword'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'ForgotPassword'), _React$createElement10),
            _react2.default.createElement(
                Grid,
                (_React$createElement9 = {
                    'data-codecept-selector-node': 'Grid',
                    'data-codecept-selector-file': 'ForgotPassword'
                }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'Grid'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'ForgotPassword'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'Grid'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'ForgotPassword'), _React$createElement9),
                _react2.default.createElement(
                    Row,
                    (_React$createElement8 = { center: 'xs', 'data-codecept-selector-node': 'Row',
                        'data-codecept-selector-file': 'ForgotPassword'
                    }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Row'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'ForgotPassword'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Row'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'ForgotPassword'), _React$createElement8),
                    _react2.default.createElement(
                        Col,
                        (_React$createElement7 = {
                            xs: 10,
                            sm: 8,
                            md: 6,
                            className: _ForgotPassword2.default.forgotPasswordBlock,
                            'data-codecept-selector-node': 'Col',
                            'data-codecept-selector-file': 'ForgotPassword'
                        }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'Col'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'ForgotPassword'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'Col'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'ForgotPassword'), _React$createElement7),
                        _react2.default.createElement(
                            'div',
                            (_React$createElement2 = {
                                className: _ForgotPassword2.default.logo,
                                'data-codecept-selector-node': 'div',
                                'data-codecept-selector-file': 'ForgotPassword'
                            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ForgotPassword'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ForgotPassword'), _React$createElement2),
                            _react2.default.createElement(_rubyLogo2.default, (_React$createElement = {
                                frontendSettings: this.props.feSettings,
                                'data-codecept-selector-node': 'RubyLogo',
                                'data-codecept-selector-file': 'ForgotPassword'
                            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RubyLogo'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ForgotPassword'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RubyLogo'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ForgotPassword'), _React$createElement))
                        ),
                        _react2.default.createElement(
                            'h1',
                            (_React$createElement3 = { className: _ForgotPassword2.default.h1, 'data-codecept-selector-node': 'h1',
                                'data-codecept-selector-file': 'ForgotPassword'
                            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'h1'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ForgotPassword'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'h1'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ForgotPassword'), _React$createElement3),
                            this.props.clientName
                        ),
                        _react2.default.createElement(
                            _Card.Card,
                            _extends({ className: _ForgotPassword2.default.forgotPasswordCard }, _ForgotPassword_style2.default.Card, (_extends3 = {
                                'data-codecept-selector-node': 'Card',
                                'data-codecept-selector-file': 'ForgotPassword'
                            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Card'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ForgotPassword'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Card'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ForgotPassword'), _extends3)),
                            _react2.default.createElement(
                                _Card.CardText,
                                _extends({}, _ForgotPassword_style2.default.CardText, (_extends2 = {
                                    'data-codecept-selector-node': 'CardText',
                                    'data-codecept-selector-file': 'ForgotPassword'
                                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'CardText'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ForgotPassword'), _defineProperty(_extends2, 'data-codecept-selector-node', 'CardText'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ForgotPassword'), _extends2)),
                                _react2.default.createElement(
                                    'p',
                                    (_React$createElement6 = { className: _ForgotPassword2.default.forgotPasswordHeader, 'data-codecept-selector-node': 'p',
                                        'data-codecept-selector-file': 'ForgotPassword'
                                    }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'ForgotPassword'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'ForgotPassword'), _React$createElement6),
                                    _react2.default.createElement(
                                        'strong',
                                        (_React$createElement4 = {
                                            'data-codecept-selector-node': 'strong',
                                            'data-codecept-selector-file': 'ForgotPassword'
                                        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ForgotPassword'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ForgotPassword'), _React$createElement4),
                                        'PASSWORD RECOVERY'
                                    ),
                                    _react2.default.createElement('br', (_React$createElement5 = {
                                        'data-codecept-selector-node': 'br',
                                        'data-codecept-selector-file': 'ForgotPassword'
                                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ForgotPassword'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ForgotPassword'), _React$createElement5)),
                                    'Enter your new password below.'
                                ),
                                this.props.children
                            )
                        ),
                        _react2.default.createElement(_CopyrightFooter2.default, _extends({}, this.props, (_extends4 = {
                            'data-codecept-selector-node': 'CopyrightFooter',
                            'data-codecept-selector-file': 'ForgotPassword'
                        }, _defineProperty(_extends4, 'data-codecept-selector-node', 'CopyrightFooter'), _defineProperty(_extends4, 'data-codecept-selector-file', 'ForgotPassword'), _defineProperty(_extends4, 'data-codecept-selector-node', 'CopyrightFooter'), _defineProperty(_extends4, 'data-codecept-selector-file', 'ForgotPassword'), _extends4)))
                    )
                )
            )
        );
    } //# TODO: remove children
});
exports.default = ForgotPassword;