'use strict';

var request = require('@rubyapps/ruby-superagent');
var urljoin = require('url-join');
var inflection = require('inflection');

var rubyWords = require('@rubyapps/ruby-words');
var rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');

var typesFromID = function typesFromID(id) {
    return {
        SET_MOST_RECENTLY_MODIFIED_ITEM: '@@ruby-app/' + id + '/SET_MOST_RECENTLY_MODIFIED_ITEM',
        SET_GRIDDLE_STATE: '@@ruby-app/' + id + '/SET_GRIDDLE_STATE',
        SET_INTERNAL_FILTER_TAGS: '@@ruby-app/' + id + '/SET_INTERNAL_FILTER_TAGS',
        SET_CUSTOM_FILTERS: '@@ruby-app/' + id + '/SET_CUSTOM_FILTERS'
    };
};

var FETCH_MOST_RECENTLY_MODIFIED_ENDPOINT = '/content/fetch-most-recently-modified';

var generators = {

    setItemStatus_withActive: function setItemStatus_withActive(item, isActive, thenableArguments) {
        var templateKey = item.templateKey,
            id = item.id;

        var selfModule = this;

        var _selfModule$getDepend = selfModule.getDependencies(),
            feSettingsSelector = _selfModule$getDepend.feSettingsSelector;

        var pushNotification = selfModule.pushNotification.bind(selfModule);
        var showNotificationModal = selfModule.showNotificationModal.bind(selfModule);

        return function (dispatch, getState) {
            var applicationState = getState();
            var apiUrlPrefix = _.get(feSettingsSelector(applicationState), 'restApiRoot');
            var endpoint = urljoin(apiUrlPrefix, inflection.pluralize(templateKey), '' + id, isActive ? 'publish' : 'unpublish');
            var requestPromise = request.post(endpoint).then(function (response) {
                var displayStatusMap = {
                    active: 'published',
                    inactive: 'unpublished'
                };

                var status = _.get(response, 'body.status');
                var contentDisplayString = rubyWords.quote(_.get(response, 'body.name')) || 'This content';
                var displayedStatus = displayStatusMap[status];

                pushNotification({
                    message: contentDisplayString + ' is now ' + displayedStatus + '.',
                    type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS
                });
                return response;
            }, function (err) {
                var notificationMethod = pushNotification,
                    title = void 0,
                    message = void 0,
                    type = rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR;
                if (err.status === 409) {
                    message = 'The entry could not be updated due to a pending content approval request. ' + 'Please try again once the request is resolved.';
                } else if (err.status === 403) {
                    message = _.get(err, 'response.body.error.message') || 'You do not have the permission to update content. ' + 'Please edit the page and submit a content approval request.';
                } else if (err.status === 422) {
                    message = 'The entry could not be updated due to invalid content. ' + 'Please edit the page to fix.';
                } else {
                    title = 'Content Update Error';
                    message = _.get(err, 'response.body.error.message') || 'An error was encountered while updating content. ' + 'Please try again later.';
                    notificationMethod = showNotificationModal;
                }

                notificationMethod({ type: type, title: title, message: message });

                throw err;
            });
            if (thenableArguments) {
                requestPromise.then.apply(requestPromise, thenableArguments);
            }
            return requestPromise;
        };
    },
    retrieveAndSetMostRecentlyModifiedItem: function retrieveAndSetMostRecentlyModifiedItem() {
        var selfModule = this;

        var _selfModule$getDepend2 = selfModule.getDependencies(),
            feSettingsSelector = _selfModule$getDepend2.feSettingsSelector,
            rubyClientFKSelector = _selfModule$getDepend2.rubyClientFKSelector;

        var _selfModule$props = selfModule.props,
            _selfModule$props$fet = _selfModule$props.fetchMostRecentlyModifiedEndpoint,
            fetchMostRecentlyModifiedEndpoint = _selfModule$props$fet === undefined ? FETCH_MOST_RECENTLY_MODIFIED_ENDPOINT : _selfModule$props$fet,
            templateKey = _selfModule$props.templateKey;


        return function (dispatch, getState) {
            var applicationState = getState();
            var apiUrlPrefix = _.get(feSettingsSelector(applicationState), 'restApiRoot');
            var rubyClientFK = rubyClientFKSelector(applicationState);
            var endpoint = urljoin(apiUrlPrefix, fetchMostRecentlyModifiedEndpoint, '?ruby_client_fk=' + rubyClientFK, '&templateType=sitemap_template', templateKey ? '&template_keyword=' + type : '');

            var requestPromise = request.get(endpoint).then(function (response) {
                var item = response.body.data[0];
                dispatch(generators.setMostRecentlyModifiedItem(item));
            }, function (err) {});
        };
    },
    setMostRecentlyModifiedItem: function setMostRecentlyModifiedItem(item) {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        return {
            type: TYPES.SET_MOST_RECENTLY_MODIFIED_ITEM,
            payload: {
                item: item
            }
        };
    },

    setGriddleState: function setGriddleState(griddleState) {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES;

        return {
            type: TYPES.SET_GRIDDLE_STATE,
            payload: {
                griddleState: griddleState
            }
        };
    }
};

module.exports = function () {
    var TYPES = typesFromID(this.getID());
    return {
        TYPES: TYPES,
        generators: generators
    };
};