'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.customComponentsByType = exports.customComponents = undefined;

var _DateFormatterGenerator = require('./DateFormatterGenerator');

var _DateFormatterGenerator2 = _interopRequireDefault(_DateFormatterGenerator);

var _DatetimeFormatterGenerator = require('./DatetimeFormatterGenerator');

var _DatetimeFormatterGenerator2 = _interopRequireDefault(_DatetimeFormatterGenerator);

var _HtmlFormatterGenerator = require('./HtmlFormatterGenerator');

var _HtmlFormatterGenerator2 = _interopRequireDefault(_HtmlFormatterGenerator);

var _IntegerFormatterGenerator = require('./IntegerFormatterGenerator');

var _IntegerFormatterGenerator2 = _interopRequireDefault(_IntegerFormatterGenerator);

var _ImageFormatterGenerator = require('./ImageFormatterGenerator');

var _ImageFormatterGenerator2 = _interopRequireDefault(_ImageFormatterGenerator);

var _KeyToHumanPrettifierGenerator = require('./KeyToHumanPrettifierGenerator');

var _KeyToHumanPrettifierGenerator2 = _interopRequireDefault(_KeyToHumanPrettifierGenerator);

var _LogLevelFormatterGenerator = require('./LogLevelFormatterGenerator');

var _LogLevelFormatterGenerator2 = _interopRequireDefault(_LogLevelFormatterGenerator);

var _NumberFormatterGenerator = require('./NumberFormatterGenerator');

var _NumberFormatterGenerator2 = _interopRequireDefault(_NumberFormatterGenerator);

var _PublishGenerator = require('./PublishGenerator');

var _PublishGenerator2 = _interopRequireDefault(_PublishGenerator);

var _PublishAndDateFormatterGenerator = require('./PublishAndDateFormatterGenerator');

var _PublishAndDateFormatterGenerator2 = _interopRequireDefault(_PublishAndDateFormatterGenerator);

var _RelevanceFormatterGenerator = require('./RelevanceFormatterGenerator');

var _RelevanceFormatterGenerator2 = _interopRequireDefault(_RelevanceFormatterGenerator);

var _RubyActionsGenerator = require('./RubyActionsGenerator');

var _RubyActionsGenerator2 = _interopRequireDefault(_RubyActionsGenerator);

var _SweeperErrorValueGenerator = require('./SweeperErrorValueGenerator');

var _SweeperErrorValueGenerator2 = _interopRequireDefault(_SweeperErrorValueGenerator);

var _TemplateKeyToTemplateNameGenerator = require('./TemplateKeyToTemplateNameGenerator');

var _TemplateKeyToTemplateNameGenerator2 = _interopRequireDefault(_TemplateKeyToTemplateNameGenerator);

var _TimeFormatterGenerator = require('./TimeFormatterGenerator');

var _TimeFormatterGenerator2 = _interopRequireDefault(_TimeFormatterGenerator);

var _ValueInObjectGenerator = require('./ValueInObjectGenerator');

var _ValueInObjectGenerator2 = _interopRequireDefault(_ValueInObjectGenerator);

var _ObjectGenerator = require('./ObjectGenerator');

var _ObjectGenerator2 = _interopRequireDefault(_ObjectGenerator);

var _BooleanFormatterGenerator = require('./BooleanFormatterGenerator');

var _BooleanFormatterGenerator2 = _interopRequireDefault(_BooleanFormatterGenerator);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//# customComponents
var customComponents = {
    DateFormatterGenerator: _DateFormatterGenerator2.default,
    KeyToHumanPrettifierGenerator: _KeyToHumanPrettifierGenerator2.default,
    PublishGenerator: _PublishGenerator2.default,
    PublishAndDateFormatterGenerator: _PublishAndDateFormatterGenerator2.default,
    RubyActionsGenerator: _RubyActionsGenerator2.default
};
var customComponentsByType = {
    date: _DateFormatterGenerator2.default,
    datetime: _DatetimeFormatterGenerator2.default,
    integer: _IntegerFormatterGenerator2.default,
    image: _ImageFormatterGenerator2.default,
    html: _HtmlFormatterGenerator2.default,
    humanize: _KeyToHumanPrettifierGenerator2.default,
    logLevel: _LogLevelFormatterGenerator2.default,
    number: _NumberFormatterGenerator2.default,
    publish: _PublishGenerator2.default,
    publishAndDate: _PublishAndDateFormatterGenerator2.default,
    relevance: _RelevanceFormatterGenerator2.default,
    rubyActions: _RubyActionsGenerator2.default,
    sweeperErrorValue: _SweeperErrorValueGenerator2.default,
    templateName: _TemplateKeyToTemplateNameGenerator2.default,
    time: _TimeFormatterGenerator2.default,
    valueInObject: _ValueInObjectGenerator2.default //# TODO: consider replacing this with just ObjectGenerator
    , object: _ObjectGenerator2.default,
    booleanString: _BooleanFormatterGenerator2.default
};

exports.customComponents = customComponents;
exports.customComponentsByType = customComponentsByType;