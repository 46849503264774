'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Card = require('material-ui/Card');

var _RaisedButton = require('material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _ApiSecret_style = require('./ApiSecret_style');

var _ApiSecret_style2 = _interopRequireDefault(_ApiSecret_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');
var moment = require('@rubyapps/friendly-ruby-moment');

var MODES = {
    CREATE: 'create',
    SECRET_UNAVAILABLE: 'unavailable',
    SECRET_AVAILABLE: 'available'
};

var Password = _react2.default.createClass({
    displayName: 'Password',

    propTypes: {
        label: PropTypes.string,
        value: PropTypes.string,
        id: PropTypes.string,
        actions: PropTypes.object,
        props: PropTypes.shape({
            TextField: PropTypes.object
        })
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null,
            props: {
                TextField: {}
            }
        };
    },
    getInfoMessage: function getInfoMessage() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8, _React$createElement9;

        var _props = this.props,
            pageId = _props.pageId,
            value = _props.value,
            frontendSettings = _props.frontendSettings;
        var RUBY_BRANDING = frontendSettings.RUBY_BRANDING;

        var mode = !pageId ? MODES.CREATE : !value ? MODES.SECRET_UNAVAILABLE : MODES.SECRET_AVAILABLE;

        switch (mode) {
            case MODES.CREATE:
                return _react2.default.createElement(
                    'p',
                    (_React$createElement = {
                        'data-codecept-selector-node': 'p',
                        'data-codecept-selector-file': 'ApiSecret'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement),
                    'In order to generate an API secret for this user, please first finish creating the user.'
                );
            case MODES.SECRET_UNAVAILABLE:
                return _react2.default.createElement(
                    'p',
                    (_React$createElement2 = {
                        'data-codecept-selector-node': 'p',
                        'data-codecept-selector-file': 'ApiSecret'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement2),
                    'In order to generate an API secret for this user, please use the "Generate Secret" button to receive an API secret that can be used to obtain access tokens for the ',
                    RUBY_BRANDING,
                    ' API.'
                );
            case MODES.SECRET_AVAILABLE:
                return _react2.default.createElement(
                    'div',
                    (_React$createElement9 = {
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'ApiSecret'
                    }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement9),
                    _react2.default.createElement(
                        'p',
                        (_React$createElement3 = {
                            'data-codecept-selector-node': 'p',
                            'data-codecept-selector-file': 'ApiSecret'
                        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement3),
                        'Please follow these guidelines when using the ',
                        RUBY_BRANDING,
                        ' API secret: '
                    ),
                    _react2.default.createElement('br', (_React$createElement4 = {
                        'data-codecept-selector-node': 'br',
                        'data-codecept-selector-file': 'ApiSecret'
                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement4)),
                    _react2.default.createElement(
                        'p',
                        (_React$createElement5 = {
                            'data-codecept-selector-node': 'p',
                            'data-codecept-selector-file': 'ApiSecret'
                        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement5),
                        '1. Do not embed the secret in code or store the secret in files managed by source code management systems like Git.'
                    ),
                    _react2.default.createElement(
                        'p',
                        (_React$createElement6 = {
                            'data-codecept-selector-node': 'p',
                            'data-codecept-selector-file': 'ApiSecret'
                        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement6),
                        '2. Invalidate any secrets not in use.'
                    ),
                    _react2.default.createElement(
                        'p',
                        (_React$createElement7 = {
                            'data-codecept-selector-node': 'p',
                            'data-codecept-selector-file': 'ApiSecret'
                        }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement7),
                        '3. Please only supply the secret when obtaining access tokens via the API and ensure that it is not part of the url.'
                    ),
                    _react2.default.createElement(
                        'p',
                        (_React$createElement8 = {
                            'data-codecept-selector-node': 'p',
                            'data-codecept-selector-file': 'ApiSecret'
                        }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement8),
                        '4. Invalidate and regenerate the secrets periodically.'
                    )
                );

        }
    },
    render: function render() {
        var _React$createElement10, _React$createElement11, _React$createElement12, _React$createElement13, _React$createElement14, _React$createElement15, _React$createElement16, _React$createElement17, _React$createElement18, _React$createElement19;

        var _props2 = this.props,
            id = _props2.id,
            _props2$value = _props2.value,
            value = _props2$value === undefined ? {} : _props2$value,
            label = _props2.label,
            pageId = _props2.pageId;

        var _ref = value || {},
            apiSecret = _ref.id,
            createdTimestamp = _ref.created_timestamp;

        var apiSecretAge = moment(createdTimestamp).longButFriendly();
        var textFieldRef = this.refs[id];
        var _props$actions = this.props.actions,
            generateSecret = _props$actions.generateSecret,
            removeSecret = _props$actions.removeSecret;
        var _props$delegateAction = this.props.delegateActions,
            pushNotification = _props$delegateAction.pushNotification,
            confirmDialog = _props$delegateAction.confirmDialog;


        var keyActions = [_react2.default.createElement(_RaisedButton2.default, (_React$createElement10 = {
            key: 'copy',
            label: 'Copy to Clipboard',
            primary: true,
            onTouchTap: function onTouchTap() {
                // based on http://stackoverflow.com/a/30810322/3326617
                var tempValueContainer = document.createElement('textarea');
                tempValueContainer.innerText = apiSecret;
                document.body.appendChild(tempValueContainer);
                tempValueContainer.select();
                document.execCommand('copy');
                tempValueContainer.remove();
                pushNotification({
                    message: 'API secret was copied to clipboard',
                    type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.INFO
                });
            },
            style: _ApiSecret_style2.default.RaisedButton,
            'data-codecept-selector-node': 'RaisedButton',
            'data-codecept-selector-file': 'ApiSecret'
        }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement10)), _react2.default.createElement(_RaisedButton2.default, (_React$createElement11 = {
            key: 'remove',
            label: 'Invalidate Secret',
            secondary: true,
            onTouchTap: function onTouchTap() {
                confirmDialog.openDialogWithOptions({
                    title: 'Invalidating Secret',
                    content: 'Once you invalidate this secret, this secret may no longer be used to access the API ' + 'and any access tokens issued for this secret will also be invalidated immediately. ' + 'Are you sure you want to invalidate this secret?',
                    submitSuccessCallback: function submitSuccessCallback(response) {
                        removeSecret();
                        //pushNotification({
                        //type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS
                        //, message: `API secret was invalidated.`
                        //});
                    }
                });
            },
            style: _ApiSecret_style2.default.RaisedButton,
            'data-codecept-selector-node': 'RaisedButton',
            'data-codecept-selector-file': 'ApiSecret'
        }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement11))];
        var newKeyActions = [_react2.default.createElement(_RaisedButton2.default, (_React$createElement12 = {
            key: 'generate',
            label: 'Generate Secret',
            primary: true,
            disabled: !pageId,
            onTouchTap: generateSecret,
            style: _ApiSecret_style2.default.RaisedButton,
            'data-codecept-selector-node': 'RaisedButton',
            'data-codecept-selector-file': 'ApiSecret'
        }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement12))];
        var actions = value ? keyActions : newKeyActions;

        var infoMessage = this.getInfoMessage();

        var inputComponent = _react2.default.createElement(
            _Card.Card,
            (_React$createElement19 = {
                'data-codecept-selector-node': 'Card',
                'data-codecept-selector-file': 'ApiSecret'
            }, _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement19),
            _react2.default.createElement(_Card.CardHeader, (_React$createElement13 = { title: 'API Details', 'data-codecept-selector-node': 'CardHeader',
                'data-codecept-selector-file': 'ApiSecret'
            }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement13)),
            _react2.default.createElement(
                _Card.CardText,
                (_React$createElement14 = {
                    'data-codecept-selector-node': 'CardText',
                    'data-codecept-selector-file': 'ApiSecret'
                }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement14),
                infoMessage
            ),
            _react2.default.createElement(
                _Card.CardText,
                (_React$createElement17 = {
                    'data-codecept-selector-node': 'CardText',
                    'data-codecept-selector-file': 'ApiSecret'
                }, _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement17),
                _react2.default.createElement(_TextField2.default, (_React$createElement15 = {
                    id: id,
                    value: apiSecret || '',
                    floatingLabelText: label,
                    disabled: true,
                    style: { width: '100%' },
                    ref: id,
                    'data-codecept-selector-node': 'TextField',
                    'data-codecept-selector-file': 'ApiSecret'
                }, _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'TextField'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'TextField'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement15)),
                value ? _react2.default.createElement(
                    'div',
                    (_React$createElement16 = { className: 'apiSecretAge', 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'ApiSecret'
                    }, _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement16),
                    'Generated: ' + apiSecretAge
                ) : null
            ),
            _react2.default.createElement(
                _Card.CardText,
                (_React$createElement18 = {
                    'data-codecept-selector-node': 'CardText',
                    'data-codecept-selector-file': 'ApiSecret'
                }, _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'ApiSecret'), _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'ApiSecret'), _React$createElement18),
                actions
            )
        );

        return this.props.renderGridWrapper(_extends({}, this.props, { children: inputComponent }));
    }
});

exports.default = Password;