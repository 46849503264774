'use strict';

var _mustache = require('mustache');

var _mustache2 = _interopRequireDefault(_mustache);

var _numeral = require('numeral');

var _numeral2 = _interopRequireDefault(_numeral);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Validator = require('validator'),
    _ = require('lodash');

//# TODO: replace the mapping and/or where the mapping is stored

var config = require('../../../../server/config.json');
var errorMessages = _.pick(config, ['error_msgs', 'pattern_error_msgs']);
//# TODO: need to update the FE side to pull it out of the feSettings

function _getTestValue(test) {
    var value;
    if (test) {
        value = test.hasOwnProperty('value') ? test.value : test;
    } else {
        value = test;
    }

    return value;
};

/**
 * @function getErrorMessage
 *
 * Retrieve the error message for the given field.
 * The error message is determined with the following precedence:
 * - User-defined error messages in `field.verify[field_name].error`
 * - Default error message for the field type, as defined in config.error_msgs
 *      - This also includes error messages for different 'formats'
 * - The default error message, defined in config.error_msgs.default_error
 *
 * @param field {object} - {
 *      label: <string>,
 *      value: <string>,
 *      verify: { required: [true] || true, min:  [30] || 30 } }
 * @param test {string} - The name of the validation test.
 * @param errorMessages {object} - The default error messages map.
 * @returns {string} - The error message for the given field.
 */

Validator.getErrorMessage = function (field, test) {
    var errorMessagesMap = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : errorMessages;
    var defaultErrorMessages = errorMessagesMap.error_msgs,
        patternErrorMessages = errorMessagesMap.pattern_error_msgs;

    // {{value}} -> The verify test's value.

    var value;
    if (field.verify) {
        value = _getTestValue(field.verify[test]);
    }

    var error_msg = test == 'pattern' ? patternErrorMessages[value] : undefined;

    if (!error_msg) {
        if (defaultErrorMessages.hasOwnProperty('default_' + test + '_error')) {
            // Get default type-specific error message
            error_msg = defaultErrorMessages['default_' + test + '_error'];
        }
        // We need to handle the case that a verify format
        // was passed down ( url, url_identifier, date, number, etc... )
        else if (test === 'format' && defaultErrorMessages.hasOwnProperty('default_' + field.verify.format + '_error')) {
                error_msg = defaultErrorMessages['default_' + field.verify.format + '_error'];
            } else {
                // Go with the default error
                error_msg = defaultErrorMessages.default_error;
            }
    }

    var hydratedErrorMsg = _mustache2.default.render(error_msg, {
        // field_title -> The field's title. Not to be confused with its name.
        field_title: field.title || field.label || '',
        value: _.isString(value) || _.isNumber(value) ? value : '',
        nbsp: '\xA0',
        formatted_values: {
            value_in_GB: _.isNumber(value) ? (0, _numeral2.default)(value).format('0b') : ''
        }
    });

    return hydratedErrorMsg;
};

Validator.required = function (value, isRequired) {
    if (isRequired && (typeof value === 'undefined' || value === null || value === '' || /^\s*$/.test(value))) {
        return false;
    }

    return true;
};

Validator.date = function (date) {
    var year, month, day;
    var date_array = date.match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/);
    if (date_array && date_array.length) {
        year = date_array[1];
        month = date_array[2];
        day = date_array[3];
    } else {
        date_array = date.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
        if (date_array && date_array.length) {
            year = date_array[3];
            month = date_array[1];
            day = date_array[2];
        }
    }

    return !isNaN(Number('' + year + month + day) && Date.parse(date));
};

Validator.time = function (time) {
    var hour, minute, second;
    var time_array = time.match(/^(\d{2}):(\d{2}):(\d{2})\.\d+([+-]\d+)?Z$/);
    if (time_array && time_array.length) {
        hour = time_array[1];
        minute = time_array[2];
        second = time_array[3];
    }

    return !isNaN(Number('' + hour + minute + second));
};

Validator.date_time = function (date_time) {
    var date_time_array = date_time.split(/T/);
    return Validator.date(date_time_array[0]) && Validator.time(date_time_array[1]);
};

Validator.min_value = function (value, min) {
    var tmp = Validator.toFloat(value);
    return tmp >= min;
};

Validator.max_value = function (value, max) {
    var tmp = Validator.toFloat(value);
    return tmp <= max;
};

Validator.url_identifier = function (url_identifier) {
    return Validator.matches(url_identifier, /^[a-z0-9-]+$/);
};

Validator.image = function (asset_id) {
    return true;
};

Validator.isArray = function (array) {
    return Array.isArray(array);
};

Validator.isInList = function (value, options) {
    return Validator.isIn(Validator.toString(value), options);
};

Validator.integer = function (value, options) {
    return Validator.isInt(value);
};

Validator.number = function (value, options) {
    return Validator.isFloat(value + ''); // Validator only works with strings
};

Validator.float = function (value, options) {
    return Validator.isFloat(value);
};

Validator.max_scale = function (value, max_scale) {
    if (!Validator.isFloat(value)) {
        return false;
    }
    var has_decimals = Validator.toString(value).match(/\.(\d+)$/);
    if (has_decimals) {
        var decimal = has_decimals[1];
        return decimal && decimal.length <= max_scale;
    }
    return true;
};

Validator.boolean = function (value) {
    var booleans = ['1', '0', 'true', 'false', 'yes', 'no'];
    return booleans.indexOf(Validator.toString(value)) !== -1 || value === undefined || value === null || value === '';
};

Validator.url = function (value, options) {
    var valid_protocols = ['http', 'https'];
    var require_protocol = !!_.get(options, 'http');

    // If it requirees a protocol,
    // it must be a full url
    if (require_protocol) {
        return Validator.isURL(value, {
            protocols: valid_protocols,
            require_protocol: require_protocol,
            require_valid_protocol: require_protocol,
            allow_underscores: true
        });
    } else {
        return Validator.pattern(value, '^[!-z~]*$');
    }
};

Validator.min = function (value, min) {
    return Validator.isLength(value, min);
};

Validator.max = function (value, max) {
    return Validator.isLength(value, -1, max);
};

Validator.max_filesize = function (files, maxFileSize) {
    var fileSizes = _.map(_.castArray(files), function (file) {
        return _.get(file, 'size');
    });
    var greatestFileSize = _.max(fileSizes);
    return greatestFileSize <= maxFileSize;
};

Validator.pattern = function (value, pattern) {
    return Validator.matches(value, pattern);
};

Validator.email = function (value) {
    return Validator.isEmail(value);
};

module.exports = Validator;