'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Popover = require('material-ui/Popover');

var _Popover2 = _interopRequireDefault(_Popover);

var _Menu = require('material-ui/Menu');

var _Menu2 = _interopRequireDefault(_Menu);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _row = require('./row.cssModule');

var _row2 = _interopRequireDefault(_row);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var RowComponentStyle = {
    position: 'relative'
};

var ExpandColumn = (_temp = _class = function (_Component) {
    _inherits(ExpandColumn, _Component);

    function ExpandColumn(props, context) {
        _classCallCheck(this, ExpandColumn);

        var _this = _possibleConstructorReturn(this, (ExpandColumn.__proto__ || Object.getPrototypeOf(ExpandColumn)).call(this, props, context));

        _this.state = {
            contextIsOpen: false,
            contextAnchorEl: null
        };

        _this._handleContextClose = _this._handleContextClose.bind(_this);
        _this._handleCollapseAll = _this._handleCollapseAll.bind(_this);
        _this._handleExpandAll = _this._handleExpandAll.bind(_this);
        return _this;
    }

    _createClass(ExpandColumn, [{
        key: '_handleContextClose',
        value: function _handleContextClose(event) {
            this.setState({ contextIsOpen: false });
        }
    }, {
        key: '_handleCollapseAll',
        value: function _handleCollapseAll(event) {
            this._handleContextClose();
            this.props.delegateExpand(event, 'collapse', true);
        }
    }, {
        key: '_handleExpandAll',
        value: function _handleExpandAll(event) {
            this._handleContextClose();
            this.props.delegateExpand(event, 'expand', true);
        }
    }, {
        key: 'render',
        value: function render() {
            var _this2 = this;

            var _props = this.props,
                expanded = _props.expanded,
                hasChildren = _props.hasChildren,
                icons = _props.icons,
                isDraggable = _props.isDraggable,
                delegateExpand = _props.delegateExpand;


            var ExpandColumn = !hasChildren ? null : expanded ? icons.parentRowExpanded : icons.parentRowCollapsed;

            var clickableExpandColumn = _react2.default.isValidElement(ExpandColumn) ? _react2.default.createElement(
                'div',
                {
                    onClick: function onClick(event) {
                        event.preventDefault();

                        delegateExpand(event);
                    },
                    onMouseDown: function onMouseDown(event) {
                        _this2.setState({ contextAnchorEl: event.currentTarget });

                        _this2._pressHoldTimeout = setTimeout(function () {
                            event.preventDefault();

                            _this2.setState({
                                contextIsOpen: true
                            });
                        }, _this2.props.contextMenuTimeout);
                    },
                    onMouseUp: function onMouseUp(event) {
                        clearTimeout(_this2._pressHoldTimeout);

                        _this2._pressHoldTimeout = null;
                    },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'row'
                },
                ExpandColumn
            ) : null;

            var contextMenu = _react2.default.createElement(
                _Popover2.default,
                {
                    open: this.state.contextIsOpen,
                    anchorEl: this.state.contextAnchorEl,
                    anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
                    targetOrigin: { horizontal: 'left', vertical: 'top' },
                    onRequestClose: this._handleContextClose,
                    'data-codecept-selector-node': 'Popover',
                    'data-codecept-selector-file': 'row'
                },
                _react2.default.createElement(
                    _Menu2.default,
                    {
                        'data-codecept-selector-node': 'Menu',
                        'data-codecept-selector-file': 'row'
                    },
                    _react2.default.createElement(_MenuItem2.default, {
                        leftIcon: icons.parentRowCollapsed,
                        primaryText: 'Collapse All',
                        onTouchTap: this._handleCollapseAll,
                        'data-codecept-selector-node': 'MenuItem',
                        'data-codecept-selector-file': 'row'
                    }),
                    _react2.default.createElement(_MenuItem2.default, {
                        leftIcon: icons.parentRowExpanded,
                        primaryText: 'Expand All',
                        onTouchTap: this._handleExpandAll,
                        'data-codecept-selector-node': 'MenuItem',
                        'data-codecept-selector-file': 'row'
                    })
                )
            );

            var wrappedExpandColumn = isDraggable ? _react2.default.createElement(
                'div',
                { className: _row2.default.wrappedExpandColumn, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'row'
                },
                icons.dragHandleIcon,
                clickableExpandColumn,
                contextMenu
            ) : _react2.default.createElement(
                'div',
                {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'row'
                },
                clickableExpandColumn,
                contextMenu
            );

            return wrappedExpandColumn;
        }
    }]);

    return ExpandColumn;
}(_react.Component), _class.propTypes = {
    contextMenuTimeout: _react.PropTypes.number
}, _class.defaultProps = {
    contextMenuTimeout: 600
}, _temp);

function getExpandColumn(expanded, hasChildren, icons, isDraggable, delegateExpand) {

    return {
        expandColumn: _react2.default.createElement(ExpandColumn, {
            key: 'expandColumn',
            expanded: expanded,
            hasChildren: hasChildren,
            icons: icons,
            isDraggable: isDraggable,
            delegateExpand: delegateExpand,
            'data-codecept-selector-node': 'ExpandColumn',
            'data-codecept-selector-file': 'row'
        })
    };
}

exports.default = function (RowComponent) {
    var _class2, _temp2;

    return _temp2 = _class2 = function (_Component2) {
        _inherits(RowComponent_Subgrid, _Component2);

        //static defaultProps = {...Component.defaultProps};
        //constructor(props, context) {super(props, context);}

        function RowComponent_Subgrid(props, context) {
            _classCallCheck(this, RowComponent_Subgrid);

            return _possibleConstructorReturn(this, (RowComponent_Subgrid.__proto__ || Object.getPrototypeOf(RowComponent_Subgrid)).call(this, props, context));
        }

        //== SHOULD COMPONENT UPDATE ================================//


        _createClass(RowComponent_Subgrid, [{
            key: 'shouldComponentUpdate',
            value: function shouldComponentUpdate(nextProps, nextState) {
                var shouldComponentUpdateByComponent = RowComponent_Subgrid.staticShouldComponentUpdateByComponent(this.props, nextProps, this.state, nextState);

                this._shouldComponentUpdateByComponent = shouldComponentUpdateByComponent; //# cached for erender

                return _lodash2.default.reduce(shouldComponentUpdateByComponent, function (collector, value, key) {
                    return collector || value;
                }, false);
            }
        }, {
            key: 'render',
            value: function render() {
                var _this4 = this;

                var isDraggable = this.props.isDraggable; //# passed down by subgrid plugin's row component

                var expanded = this.props.rowData.__metadata.expandedFlag;

                var expandColumn = getExpandColumn(expanded, this.props.rowData.__metadata.hasChildren, this.props.styles.icons, isDraggable, function (event, direction, includeDescendants) {
                    _this4.props.events.expandRow(_this4.props.rowData.__metadata.griddleKey, direction, includeDescendants);
                });

                var disabled = this.props.rowData.__metadata.__disabledForCMS;
                var rowData = _extends({}, expandColumn, this.props.rowData);

                var depth = this.props.rowData.__metadata.depth;
                var style = _extends({}, RowComponentStyle);

                if (disabled) {
                    style.color = '#a9a7a7';
                }

                return _react2.default.createElement(RowComponent, _extends({}, this.props, {
                    depth: depth || 0,
                    rowData: rowData,
                    style: style,
                    shouldComponentUpdateByComponent: this._shouldComponentUpdateByComponent,
                    'data-codecept-selector-node': 'RowComponent',
                    'data-codecept-selector-file': 'row'
                }));
            }
        }], [{
            key: 'staticShouldComponentUpdateByComponent',
            value: function staticShouldComponentUpdateByComponent(props, nextProps, state, nextState) {
                var shouldComponentUpdateByComponent_next = nextProps.shouldComponentUpdateByComponent || {};

                if (shouldComponentUpdateByComponent_next.hasOwnProperty(RowComponent_Subgrid.name) || shouldComponentUpdateByComponent_next.hasOwnProperty(RowComponent.name)) {
                    return shouldComponentUpdateByComponent_next;
                }

                var expandedIsEqual = props.rowData.__metadata.expandedFlag == nextProps.rowData.__metadata.expandedFlag;
                var shouldComponentUpdate_self = !expandedIsEqual;
                var shouldComponentUpdate_curriedByComponent = RowComponent.staticShouldComponentUpdateByComponent ? RowComponent.staticShouldComponentUpdateByComponent.apply(null, arguments) : {};

                //console.log(`[${RowComponent_Subgrid.name} > ${RowComponent.name}, : ${props.rowData.name}] shouldComponentUpdate_self: ${shouldComponentUpdate_self} ... shouldComponentUpdate_curriedByComponent:`, shouldComponentUpdate_curriedByComponent);

                //return shouldComponentUpdate_self || shouldComponentUpdate_curried;
                return _extends(_defineProperty({}, RowComponent_Subgrid.name, shouldComponentUpdate_self), shouldComponentUpdate_curriedByComponent);
            }
        }]);

        return RowComponent_Subgrid;
    }(_react.Component), _class2.PropTypes = _extends({}, RowComponent.PropTypes, {
        expandedFlag: _react.PropTypes.boolean }), _temp2;
};