'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _Toggle = require('material-ui/Toggle');

var _Toggle2 = _interopRequireDefault(_Toggle);

var _PropTable = require('./PropTable');

var _PropTable2 = _interopRequireDefault(_PropTable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var _ = require('lodash');
var PropTypes = React.PropTypes;

var rubyWords = require('@rubyapps/ruby-words');

var NAME_KEY = 'label';
var TYPE_KEY = 'componentName';

var Fieldset = React.createClass({
    displayName: 'Fieldset',

    propTypes: {
        set_property_toValue: PropTypes.func
    },
    getDefaultProps: function getDefaultProps() {
        return {
            instance: { instanceProps: {} }
        };
    },
    _onNameChange: function _onNameChange(event) {
        var propertiesWithoutPermissions_byKey = this.props.selfModule.propertiesWithoutPermissions_byKey();
        var constraintsForName = _.get(propertiesWithoutPermissions_byKey[NAME_KEY], 'constraints');
        this.props.set_property_toValue(NAME_KEY, event.target.value, constraintsForName, 'fieldset_label');
    },
    _onToggleChange_forProperty: function _onToggleChange_forProperty(property) {
        var self = this;
        return function (event, toggled) {
            self.props.set_property_toValue(property, toggled);
        };
    },
    render: function render() {
        var _React$createElement,
            _React$createElement2,
            _React$createElement3,
            _React$createElement4,
            _this = this,
            _React$createElement5,
            _React$createElement6,
            _React$createElement7,
            _React$createElement8;

        var overrideLocked = this.props.overrideLocked;

        var isLocked = this._getInstanceProp(['_locked', 'value']) && !overrideLocked;
        return React.createElement(
            'div',
            (_React$createElement8 = { style: { display: 'flex' }, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Fieldset'
            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Fieldset'), _React$createElement8),
            React.createElement(
                'div',
                (_React$createElement4 = {
                    style: { flex: 1 },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Fieldset'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Fieldset'), _React$createElement4),
                React.createElement(
                    'div',
                    (_React$createElement3 = {
                        style: { maxWidth: "256px" },
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'Fieldset'
                    }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Fieldset'), _React$createElement3),
                    React.createElement(_TextField2.default, (_React$createElement = {
                        key: NAME_KEY,
                        name: NAME_KEY,
                        onChange: this._onNameChange,
                        floatingLabelText: 'Fieldset Label *',
                        disabled: isLocked,
                        value: this._getInstanceProp([NAME_KEY, 'value']) || '',
                        errorText: this._getInstanceProp([NAME_KEY, 'errorText']),
                        'data-codecept-selector-node': 'TextField',
                        'data-codecept-selector-file': 'Fieldset'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'TextField'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'TextField'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Fieldset'), _React$createElement)),
                    React.createElement(_Toggle2.default, (_React$createElement2 = {
                        toggled: this._getInstanceProp(['_locked', 'value']) || false,
                        onToggle: this._onToggleChange_forProperty('_locked'),
                        label: 'Lock fieldset from being edited?',
                        disabled: overrideLocked ? false : true,
                        'data-codecept-selector-node': 'Toggle',
                        'data-codecept-selector-file': 'Fieldset'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Fieldset'), _React$createElement2))
                )
            ),
            React.createElement(
                'div',
                (_React$createElement7 = { style: { flex: 1 }, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Fieldset'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Fieldset'), _React$createElement7),
                React.createElement(
                    'div',
                    (_React$createElement6 = {
                        style: {
                            marginTop: 20
                            //, height: '95px'
                        },
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'Fieldset'
                    }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Fieldset'), _React$createElement6),
                    React.createElement(_PropTable2.default, (_React$createElement5 = {
                        disabled: isLocked,
                        rowProps: this._properties().map(function (propertySpec) {
                            var propKey = propertySpec.propKey;
                            var type = propertySpec.type;
                            var propValue = _this._getInstanceProp([propKey, 'value']);
                            if (propValue == null && propertySpec.hasOwnProperty('defaultValue')) {
                                propValue = propertySpec.defaultValue;
                            }

                            var isAutoPopulated = false;
                            if (propValue == null && propertySpec.defaultToProp) {
                                var defaultToProp = propertySpec.defaultToProp;
                                var referencedPropertySpec = _this._properties_byKey()[defaultToProp];
                                propValue = _this._getInstanceProp([referencedPropertySpec.propKey, 'value']) || referencedPropertySpec.defaultValue;
                                propValue = rubyWords.keyify(propValue);
                                isAutoPopulated = true;
                            }

                            return _extends({}, propertySpec, {
                                fieldProps: {
                                    errorText: _this._getInstanceProp([propKey, 'errorText']),
                                    value: propValue,
                                    isAutoPopulated: isAutoPopulated,
                                    onChange: _this._generateOnChange_ofType_forPropertySpec(type, propertySpec)
                                }
                            });
                        }),
                        'data-codecept-selector-node': 'PropTable',
                        'data-codecept-selector-file': 'Fieldset'
                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'PropTable'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'PropTable'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Fieldset'), _React$createElement5))
                )
            )
        );
    }

    //== Utility Methods ======================================================================//
    , _getInstanceProp: function _getInstanceProp(path, defaultValue) {
        if (path == undefined) {
            path = [];
        }
        var value = _.get(this, [].concat(['props', 'instance', 'instanceProps'], path), defaultValue);
        return value && value.toJSON ? value.toJSON() : value;
    },
    _properties: function _properties() {
        var permissions = _.get(this.props, ['all_permissions']) || [];
        var propsByKey = this.props.selfModule.propertiesByKey_withRubyPermissions(permissions);
        var properties = ['key_optional', 'help_text', 'namespace'].map(function (key) {
            return propsByKey[key];
        });
        return properties;
    },
    _properties_byKey: function _properties_byKey() {
        var permissions = _.get(this.props, ['all_permissions']) || [];
        var propertiesByKey = this.props.selfModule.propertiesByKey_withRubyPermissions(permissions);

        return propertiesByKey;
    },
    _generateOnChange_forPropertySpec: function _generateOnChange_forPropertySpec(propertySpec) {
        var self = this;
        if (typeof propertySpec == 'string') {
            console.error("== BAD ==. We should not be passing in the propertyKey");
        }
        var constraints = _.get(propertySpec, 'constraints');

        return function (event, value) {
            self.props.set_property_toValue(propertySpec.propKey, value, constraints);
        };
    },
    _generateOnChange_ofType_forPropertySpec: function _generateOnChange_ofType_forPropertySpec(type, propertySpec) {
        var self = this;
        switch (type) {
            case 'number':
            case 'text':
            case 'textarea':
                return this._generateOnChange_forPropertySpec(propertySpec);
            default:
                return function () {};
        }
    }
});
exports.default = Fieldset;