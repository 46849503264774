'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVisibleData = getVisibleData;
exports.updateVisibleData = updateVisibleData;
exports.getState = getState;
exports.getPageProperties = getPageProperties;
exports.addKeyToRows = addKeyToRows;
exports.getPageCount = getPageCount;
exports.getColumnTitles = getColumnTitles;
exports.getColumnProperties = getColumnProperties;
exports.getAllPossibleColumns = getAllPossibleColumns;
exports.getVisibleDataColumns = getVisibleDataColumns;
exports.getDataColumns = getDataColumns;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _maxSafeInteger = require('max-safe-integer');

var _maxSafeInteger2 = _interopRequireDefault(_maxSafeInteger);

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

var _lodash3 = require('lodash.assign');

var _lodash4 = _interopRequireDefault(_lodash3);

var _rubyLodash = require('@rubyapps/ruby-lodash');

var _rubyLodash2 = _interopRequireDefault(_rubyLodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getVisibleData(state) {
  var data = state.get('data');

  var columns = getDataColumns(state, data);
  return getVisibleDataColumns(data, columns);
}

function updateVisibleData(state) {
  return state.set('visibleData', getVisibleData(state));
}

//why? Assuming this is carry-over from old flux?
function getState(state) {
  return state;
}

function getPageProperties(state) {
  return state.get('pageProperties');
}

function addKeyToRows(data) {
  var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

  var key = 0;
  var getKey = function getKey() {
    return key++;
  };

  return data.map(function (row) {
    return row.set('griddleKey', prefix + getKey());
  });
}

function getPageCount(total, pageSize) {
  var calc = total / pageSize;
  return calc > Math.floor(calc) ? Math.floor(calc) + 1 : Math.floor(calc);
}

function getColumnTitles(state) {
  if (state.get('renderProperties') && state.get('renderProperties').get('columnProperties').size !== 0) {
    return state.get('renderProperties').get('columnProperties').filter(function (column) {
      return !!column.get('displayName');
    }).map(function (column) {
      var col = {};
      col[column.get('id')] = column.get('displayName');
      return col;
    }).toJSON();
  }

  return {};
}

function getColumnProperties(state) {
  if (state.get('renderProperties') && state.get('renderProperties').get('columnProperties') && state.get('renderProperties').get('columnProperties').size !== 0) {
    return state.get('renderProperties').get('columnProperties').toJSON();
  }

  return {};
}

//TODO: Determine if this should stay here
function getAllPossibleColumns(state) {
  if (state.get('data').size === 0) {
    return new _immutable2.default.fromJS([]);
  }

  return state.get('data').get(0).keySeq();
}

//From Immutable docs - https://github.com/facebook/immutable-js/wiki/Predicates
function keyInArray(keys) {
  var keySet = _immutable2.default.Set(keys);
  return function (v, k) {

    return keySet.has(k);
  };
}
function keyNotInArray(keys) {
  var keySet = _immutable2.default.Set(keys);
  return function (v, k) {

    return !keySet.has(k);
  };
}

function getVisibleDataColumns(data, columns) {
  if (data.size < 1) {
    return data;
  }

  var dataColumns = data.get(0).keySeq().toArray();
  var metadataColumns = dataColumns.filter(function (item) {
    return columns.indexOf(item) < 0;
  });

  //Create an object of all column keys with a value of null
  //This would be the default state of the data
  //and data received in 'result' will be merged in with the base state

  var magicColumns = columns.reduce(function (original, item) {
    original[item] = null;return original;
  }, {});

  //combine the metadata and the "magic" columns
  var extra = data.map(function (d, i) {
    return new _immutable2.default.Map((0, _lodash4.default)(magicColumns, { __metadata: d.filter(keyNotInArray(columns)).set('index', i) }));
  });

  //# non-immutable way of generating the result data as reference
  //# TODO: might want to update to using Immutable
  var dataArr = data.toJS();

  var flatMappedData = dataArr.map(function (dataItem) {
    return _lodash2.default.reduce(columns, function (collector, columnKeyPath, index) {
      var pluckedValue = _rubyLodash2.default.get(dataItem, columnKeyPath);

      var finalPluckedValue = _lodash2.default.isArray(pluckedValue) ? pluckedValue.filter(function (value) {
        return !_lodash2.default.isNil(value);
      }) : pluckedValue;

      collector[columnKeyPath] = finalPluckedValue;

      return collector;
    }, {});
  });
  var result = _immutable2.default.fromJS(flatMappedData);

  //# NOTE: we're doing extra < result so that result overrides any null data in extra
  return extra.mergeDeep(result) //# allow result to override extra
  .map(function (item) {
    return item.sortBy(function (val, key) {
      return columns.indexOf(key) > -1 ? columns.indexOf(key) : _maxSafeInteger2.default;
    });
  });
}

function getDataColumns(state, data) {
  var renderProperties = state.get('renderProperties');
  if (renderProperties && renderProperties.get('columnProperties') && renderProperties.get('columnProperties').size !== 0) {
    var keys = state.getIn(['renderProperties', 'columnProperties']).sortBy(function (col) {
      return col.get('order') || _maxSafeInteger2.default;
    }).keySeq().toJSON();

    return keys;
  }

  return getAllPossibleColumns(state).toJSON();
}