'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _baseField = require('@rubyapps/ruby-styles/src/client/styleObjects/baseField');

var _baseField2 = _interopRequireDefault(_baseField);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    CardText: {
        style: {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    TextField: _extends({}, _baseField2.default, {
        style: _extends({}, _baseField2.default.style, {
            width: '100%'
        })
    }),
    Menu: {
        style: {
            overflowX: 'hidden'
        }
    },
    MenuItem: {
        disableFocusRipple: true
    }
};