'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Toolbar = require('material-ui/Toolbar');

var _filterList = require('material-ui/svg-icons/content/filter-list');

var _filterList2 = _interopRequireDefault(_filterList);

var _RaisedButton = require('material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _AdvancedSearchUtilityBar = require('./AdvancedSearchUtilityBar.cssModule');

var _AdvancedSearchUtilityBar2 = _interopRequireDefault(_AdvancedSearchUtilityBar);

var _AdvancedSearchUtilityBar_style = require('./AdvancedSearchUtilityBar_style');

var _AdvancedSearchUtilityBar_style2 = _interopRequireDefault(_AdvancedSearchUtilityBar_style);

var _moreVert = require('material-ui/svg-icons/navigation/more-vert');

var _moreVert2 = _interopRequireDefault(_moreVert);

var _IconMenu = require('material-ui/IconMenu');

var _IconMenu2 = _interopRequireDefault(_IconMenu);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _Divider = require('material-ui/Divider');

var _Divider2 = _interopRequireDefault(_Divider);

var _IconButton = require('material-ui/IconButton/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

// Material UI Icons


// Material UI Components


//# == POTENTIALLY TEMPORARY IMPORTS ================//


contextMenu_withProps.propTypes = {
    contextMenuItemsFromProps: PropTypes.func
};
function contextMenu_withProps(props) {
    var _React$createElement, _React$createElement2, _React$createElement3;

    var menuItems = props.contextMenuItemsFromProps(props);

    return !_lodash2.default.isNil(menuItems) ? _react2.default.createElement(
        _IconMenu2.default,
        (_React$createElement3 = {
            key: 'ruby-actions_context-menu',
            iconButtonElement: _react2.default.createElement(
                _IconButton2.default,
                (_React$createElement2 = {
                    'data-codecept-selector-node': 'IconButton',
                    'data-codecept-selector-file': 'FilterToolbarGroup'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'FilterToolbarGroup'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'FilterToolbarGroup'), _React$createElement2),
                _react2.default.createElement(_moreVert2.default, (_React$createElement = {
                    'data-codecept-selector-node': 'MoreVertIcon',
                    'data-codecept-selector-file': 'FilterToolbarGroup'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FilterToolbarGroup'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FilterToolbarGroup'), _React$createElement))
            ),
            'data-codecept-selector-node': 'IconMenu',
            'data-codecept-selector-file': 'FilterToolbarGroup'
        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'FilterToolbarGroup'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'FilterToolbarGroup'), _React$createElement3),
        menuItems
    ) : null;
}

var FilterToolbarGroup = _react2.default.createClass({
    displayName: 'FilterToolbarGroup',

    propTypes: _extends({
        contextMenuItemsFromProps: PropTypes.func,
        griddleProps: PropTypes.object,
        hasSaveableSearchParams: PropTypes.bool,
        hasSelectedPageIdsForExport: PropTypes.bool,
        isAddFilterVisible: PropTypes.bool,
        queryString: PropTypes.string,

        delegateAddFilterButtonTap: PropTypes.func,
        delegateOnChange: PropTypes.func,
        delegateRequestToExportToExcel: PropTypes.func,
        delegateViewExportLister: PropTypes.func,
        delegateResetSearch: PropTypes.func,
        delegateSaveSearch__dialogOpen: PropTypes.func,
        delegateSelectColumnsButtonTap: PropTypes.func,
        filterHintText: PropTypes.node
    }, contextMenu_withProps.propTypes),
    getDefaultProps: function getDefaultProps() {
        return {
            hasSaveableSearchParams: true,
            delegateAddFilterButtonTap: function delegateAddFilterButtonTap() {},
            delegateRequestToExportToExcel: function delegateRequestToExportToExcel() {},
            delegateViewExportLister: function delegateViewExportLister() {},
            delegateViewSavedSearchLister: function delegateViewSavedSearchLister() {},
            queryString: '',

            contextMenuItemsFromProps: function contextMenuItemsFromProps(props) {
                var _React$createElement4, _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8;

                return [_react2.default.createElement(_MenuItem2.default, (_React$createElement4 = {
                    key: 'save-search',
                    primaryText: 'Save Search',
                    disabled: !props.hasSaveableSearchParams,
                    onTouchTap: props.delegateSaveSearch__dialogOpen,
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'FilterToolbarGroup'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'FilterToolbarGroup'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'FilterToolbarGroup'), _React$createElement4)), _react2.default.createElement(_MenuItem2.default, (_React$createElement5 = {
                    key: 'reset-search',
                    primaryText: 'Reset Search',
                    disabled: !props.hasSaveableSearchParams,
                    onTouchTap: props.delegateResetSearch,
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'FilterToolbarGroup'
                }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'FilterToolbarGroup'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'FilterToolbarGroup'), _React$createElement5)), _react2.default.createElement(_MenuItem2.default, (_React$createElement6 = {
                    key: 'view-saved-searched',
                    primaryText: 'View Saved Searches',
                    onTouchTap: props.delegateViewSavedSearchLister,
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'FilterToolbarGroup'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'FilterToolbarGroup'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'FilterToolbarGroup'), _React$createElement6)), _react2.default.createElement(_Divider2.default, (_React$createElement7 = { key: 'divider-0', 'data-codecept-selector-node': 'Divider',
                    'data-codecept-selector-file': 'FilterToolbarGroup'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'FilterToolbarGroup'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'FilterToolbarGroup'), _React$createElement7)), _react2.default.createElement(_MenuItem2.default, (_React$createElement8 = {
                    key: 'select-columns',
                    primaryText: 'Select Columns',
                    onTouchTap: props.delegateSelectColumnsButtonTap,
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'FilterToolbarGroup'
                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'FilterToolbarGroup'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'FilterToolbarGroup'), _React$createElement8))];
            }
        };
    },

    getInitialState: function getInitialState() {
        return {
            currValue: null
        };
    },
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        if (nextProps.queryString != this.state.currValue) {
            this.setState({
                currValue: nextProps.queryString
            });
        }
    },

    render: function render() {
        var _React$createElement9,
            _React$createElement10,
            _this = this,
            _extends2,
            _extends3,
            _extends4,
            _React$createElement11;

        var FilterIconStyle = {
            transform: 'rotate(' + (this.props.isAddFilterVisible ? 180 : 0) + 'deg)'
        };

        var textValue = _lodash2.default.get(this, 'state.currValue') != null ? _lodash2.default.get(this, 'state.currValue') : this.props.queryString;

        return _react2.default.createElement(
            _Toolbar.ToolbarGroup,
            (_React$createElement11 = { className: _AdvancedSearchUtilityBar2.default.ToolbarGroup__root, 'data-codecept-selector-node': 'ToolbarGroup',
                'data-codecept-selector-file': 'FilterToolbarGroup'
            }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'FilterToolbarGroup'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'FilterToolbarGroup'), _React$createElement11),
            _react2.default.createElement(_TextField2.default, _extends({}, _AdvancedSearchUtilityBar_style2.default.TextField, (_extends2 = {
                floatingLabelText: _react2.default.createElement(
                    'span',
                    (_React$createElement10 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'FilterToolbarGroup'
                    }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'FilterToolbarGroup'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'FilterToolbarGroup'), _React$createElement10),
                    _react2.default.createElement('i', (_React$createElement9 = { className: 'fa fa-search', 'aria-hidden': 'true', 'data-codecept-selector-node': 'i',
                        'data-codecept-selector-file': 'FilterToolbarGroup'
                    }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'i'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'FilterToolbarGroup'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'i'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'FilterToolbarGroup'), _React$createElement9))
                ),
                floatingLabelFixed: true,
                hintText: this.props.filterHintText,
                type: 'text',
                name: 'filter',
                className: 'lister__searchBox',
                value: textValue,
                onChange: function onChange(e) {
                    e.persist();
                    _this.setState({ currValue: e.target.value });
                    return _this.props.delegateOnChange(e.target.value);
                },
                onKeyDown: function onKeyDown(e) {
                    return _this.props.delegateOnKeyDown && _this.props.delegateOnKeyDown(e);
                },
                'data-codecept-selector-node': 'TextField',
                'data-codecept-selector-file': 'FilterToolbarGroup'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FilterToolbarGroup'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FilterToolbarGroup'), _extends2))),
            _react2.default.createElement(_RaisedButton2.default, _extends({}, _AdvancedSearchUtilityBar_style2.default.RaisedButton, (_extends4 = {
                key: 'add-filter',
                label: 'Filter',
                primary: true,
                icon: _react2.default.createElement(_filterList2.default, _extends({}, _AdvancedSearchUtilityBar_style2.default.FilterIcon, (_extends3 = {
                    className: _AdvancedSearchUtilityBar2.default.FilterIcon,
                    style: FilterIconStyle,
                    'data-codecept-selector-node': 'FilterIcon',
                    'data-codecept-selector-file': 'FilterToolbarGroup'
                }, _defineProperty(_extends3, 'data-codecept-selector-node', 'FilterIcon'), _defineProperty(_extends3, 'data-codecept-selector-file', 'FilterToolbarGroup'), _defineProperty(_extends3, 'data-codecept-selector-node', 'FilterIcon'), _defineProperty(_extends3, 'data-codecept-selector-file', 'FilterToolbarGroup'), _extends3))),
                onTouchTap: this.props.delegateAddFilterButtonTap,
                'data-codecept-selector-node': 'RaisedButton',
                'data-codecept-selector-file': 'FilterToolbarGroup'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends4, 'data-codecept-selector-file', 'FilterToolbarGroup'), _defineProperty(_extends4, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends4, 'data-codecept-selector-file', 'FilterToolbarGroup'), _extends4))),
            contextMenu_withProps(this.props)
        );
    }
});

exports.default = FilterToolbarGroup;