'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _htmlReactParser = require('html-react-parser');

var _htmlReactParser2 = _interopRequireDefault(_htmlReactParser);

var _Card = require('material-ui/Card');

var _Text = require('./Text.cssModule');

var _Text2 = _interopRequireDefault(_Text);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var Text = _react2.default.createClass({
    displayName: 'Text',

    propTypes: {
        label: PropTypes.string,
        value: PropTypes.string,
        id: PropTypes.string,
        className: PropTypes.string
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null
        };
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _extends2;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        return _react2.default.createElement(
            _Card.Card,
            _extends({
                className: _Text2.default.wrapper + ' ' + (this.props.className || '')
            }, _.pick(this.props, ['id', 'style']), (_extends2 = {
                'data-codecept-selector-node': 'Card',
                'data-codecept-selector-file': 'Text'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Card'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Text'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Card'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Text'), _extends2)),
            this.props.label && _react2.default.createElement(_Card.CardHeader, (_React$createElement = {
                title: this.props.label,
                'data-codecept-selector-node': 'CardHeader',
                'data-codecept-selector-file': 'Text'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Text'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Text'), _React$createElement)),
            this.props.value && _react2.default.createElement(
                _Card.CardText,
                (_React$createElement2 = { className: _Text2.default.cardText, 'data-codecept-selector-node': 'CardText',
                    'data-codecept-selector-file': 'Text'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Text'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Text'), _React$createElement2),
                this.renderHTML()
            ),
            this.props.children && _react2.default.createElement(
                'div',
                (_React$createElement3 = { style: _extends({ padding: 16 }, this.props.childrenWrapperStyle), 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Text'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Text'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Text'), _React$createElement3),
                this.props.children
            )
        );
    },
    renderHTML: function renderHTML() {
        try {
            return (0, _htmlReactParser2.default)(this.props.value);
        } catch (rawHtmlToReactParseErr) {
            var _React$createElement4;

            console.error(rawHtmlToReactParseErr);
            return _react2.default.createElement('div', (_React$createElement4 = { dangerouslySetInnerHTML: { __html: this.props.value }, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Text'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Text'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Text'), _React$createElement4));
        }
    }
});

exports.default = Text;