'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styleHelper = require('./utils/styleHelper');

var _RefreshIndicator = require('@rubyapps/ruby-material-ui/RefreshIndicator');

var _RefreshIndicator2 = _interopRequireDefault(_RefreshIndicator);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var cssStyles = process.browser ? require('./loading.cssModule').default : {};
var styles = {
    refreshIndicator: {
        display: 'inline-block',
        position: 'relative'
    }
};

var Loading = function (_React$Component) {
    _inherits(Loading, _React$Component);

    function Loading() {
        _classCallCheck(this, Loading);

        return _possibleConstructorReturn(this, (Loading.__proto__ || Object.getPrototypeOf(Loading)).apply(this, arguments));
    }

    _createClass(Loading, [{
        key: 'render',
        value: function render() {
            var loading = this.props.loading;

            var _getStyleProperties = (0, _styleHelper.getStyleProperties)(this.props, 'loading'),
                style = _getStyleProperties.style,
                className = _getStyleProperties.className;

            return loading ? _react2.default.createElement(
                'div',
                { className: cssStyles.refreshDivWrapper, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'loading'
                },
                _react2.default.createElement(
                    'div',
                    { className: cssStyles.refreshDivContent, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'loading'
                    },
                    _react2.default.createElement(_RefreshIndicator2.default, {
                        left: 0,
                        top: 18,
                        status: loading ? "loading" : "hide",
                        style: styles.refreshIndicator,
                        className: cssStyles.refreshIndicator,
                        'data-codecept-selector-node': 'RefreshIndicator',
                        'data-codecept-selector-file': 'loading'
                    })
                )
            ) : null;
        }
    }]);

    return Loading;
}(_react2.default.Component);

exports.default = Loading;