'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _HeaderConnector = require('./reactComponents/HeaderConnector');

var _HeaderConnector2 = _interopRequireDefault(_HeaderConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var request = require('@rubyapps/ruby-superagent');
var rubyWords = require('@rubyapps/ruby-words');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var FieldForm = require('@rubyapps/ruby-component-field-form');

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');

var RubyComponentConfirmDialog = require('@rubyapps/ruby-component-confirm-dialog');
var RubyComponentForms = require('@rubyapps/ruby-component-forms');

var _require = require('@rubyapps/ruby-component-material-edit-page/src/common/constants'),
    EditPageComponentName = _require.COMPONENT_NAME;

var action = require('./action');
var reducer = require('./reducer');

var RCHeader = RubyComponent.createClass({
    propTypes: {
        cancelLink: PropTypes.string,
        formsID: PropTypes.string,
        reactProps: PropTypes.shape({
            stickyProps: PropTypes.object
        }),
        breadcrumbPrefix: PropTypes.string,
        breadcrumbLabel: PropTypes.string,
        breadcrumbSeparator: PropTypes.string
    },
    mixins: [baseFieldMixin],
    componentName: componentName,
    getDefaultProps: function getDefaultProps() {
        return {
            keyForNameComponent: 'name',
            formsID: 'rubyComponentForms',
            showBreadcrumb: true,
            breadcrumbPrefix: 'Edit: ',
            breadcrumbSeparator: "&nbsp;&rsaquo;&nbsp;",
            listerConfig: {
                excludeFromColumnSelection: false,
                excludeFromFilterSelection: true
            },
            reactProps: {
                //# NOTE: this are provided as defaults so long as you don't override it
                //# the moment you provide some other reactProps,
                //# the stickyHeader will use defaults (ie. topOffset: 0)
                stickyProps: {
                    topOffset: -44,
                    stickyStyle: {
                        marginTop: 44
                    }
                }
            }
        };
    },
    getInitialState: function getInitialState() {
        return {
            childrenPropsByClass: {},
            props: {}
        };
    },
    action: action,
    reducer: reducer,
    dependencies: function dependencies() {
        var _this = this;

        var rootModule = this.getRoot();
        var selfSelector = this.getSelfStateSelector();
        var parentForm = this.findAncestorBy(function (module) {
            return module.componentName == FieldForm.componentName;
        });
        var nameComponent = parentForm.findDescendentBy(function (module) {
            return module.props.key == _this.props.keyForNameComponent;
        });

        var confirmDialogID = this.props.confirmDialogID || RubyComponentConfirmDialog.componentName;
        var confirmDialogComponent = rootModule.findDescendentByID(confirmDialogID);

        var formsComponent = rootModule.findDescendentByID(this.props.formsID);
        //# get nearest form
        var formComponent = this.getRubyComponentAtPath('< .rubyComponentFieldForm');

        var routeParent = this.findAncestorBy(function (module) {
            return module.getRouteElement;
        });

        var formsID = this.props.formsID || RubyComponentForms.CONSTANTS.COMPONENT_NAME;
        var forms = rootModule.findDescendentByID(formsID);
        var formsSelector = forms.getDefaultSelector();

        return {
            selfSelector: selfSelector,
            formsComponent: formsComponent,
            formComponent: formComponent,
            nameSelector: nameComponent && nameComponent.getSelfStateSelector(),
            confirmDialogActions: confirmDialogComponent.getAction().generators,
            routeParent: routeParent,
            rootModule: rootModule,
            forms: forms,
            formsSelector: formsSelector
        };
    },
    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector,
            nameSelector = _getDependencies.nameSelector,
            routeParent = _getDependencies.routeParent;

        var selectedState = {
            self: selfSelector(state),
            isActive: this.isFieldActiveForNamespace_fromState(state),
            name: nameSelector && nameSelector(state),
            routeParams: routeParent.getDefaultSelector()(state).routeParams
        };
        return selectedState;
    },
    getReactClass: function getReactClass() {
        return _HeaderConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var HeaderComponent = this.getReactClass();

        return _react2.default.createElement(HeaderComponent, _extends({}, _lodash2.default.get(this.props, ['reactProps'], {}), _lodash2.default.omit(this.props, ['reactProps']), (_extends2 = {
            'data-codecept-selector-node': 'HeaderComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'HeaderComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'HeaderComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    }

    //# Utilities
    , getListComponent: function getListComponent() {
        var selfModule = this;
        var rootModule = selfModule.getRoot();
        var routeParent = selfModule.findAncestorBy(function (module) {
            return module.getRouteElement;
        });

        var listPageComponentId = routeParent.props.listPageID;
        var listComponent = rootModule.findDescendentByID(listPageComponentId);

        return listComponent;
    },
    getListRoute: function getListRoute() {
        var selfModule = this;
        var rootModule = selfModule.getRoot();
        var listComponent = selfModule.getListComponent();

        if (!listComponent) {
            return undefined;
        }

        var routeParent = selfModule.findAncestorBy(function (module) {
            return module.getRouteElement;
        });
        var containerPageId = _lodash2.default.result(routeParent, 'getState.routeParams.containerPageId');

        var listRoute = rootModule.getUrlForComponent_fromModule_withParams(listComponent, routeParent, {
            id: containerPageId
        });

        return listRoute;
    },
    getStaticListName: function getStaticListName() {
        var _getDependencies2 = this.getDependencies(),
            formsComponent = _getDependencies2.formsComponent,
            routeParent = _getDependencies2.routeParent;

        var selfModule = this;
        var listComponent = selfModule.getListComponent();

        //# this is also used to take the breadcrumbLabel from props and set it in the redux state's props
        var props__breadcrumbLabel = this.props.breadcrumbLabel;
        var listComponent__listerName = _lodash2.default.get(listComponent, 'props.listerName');
        if (props__breadcrumbLabel) {
            return props__breadcrumbLabel;
        } else if (listComponent__listerName) {
            return listComponent__listerName;
        } else {
            var currentTemplateKey = _lodash2.default.result(routeParent, 'getState.routeParams.template') || routeParent.props.templateKey;
            var currentTemplateForm = _lodash2.default.result(formsComponent, ['getState', currentTemplateKey]);
            var formName_plural = currentTemplateForm && rubyWords.inflection.pluralize(currentTemplateForm.name);

            return formName_plural;
        }
    },
    getDynamicListName: function getDynamicListName() {
        var selfModule = this;
        var listComponent = selfModule.getListComponent();

        var routeParent = selfModule.findAncestorBy(function (module) {
            return module.getRouteElement;
        });
        var containerPageId = _lodash2.default.result(routeParent, 'getState.routeParams.containerPageId');

        var listPageDataEndpoint = containerPageId && _lodash2.default.isFunction(listComponent.getInstanceDataEndpoint_forId) && listComponent.getInstanceDataEndpoint_forId(containerPageId);

        return listPageDataEndpoint ? request.get(listPageDataEndpoint).then(function (response) {
            return _lodash2.default.get(response, 'body.name');
        }).catch(function (err) {
            return selfModule.getStaticListName();
        }) : Promise.resolve(selfModule.getStaticListName());
    },
    onReduxInit: function onReduxInit() {
        var _this2 = this;

        var selfModule = this;
        var store = selfModule.getStore();
        var actions = selfModule.getAction().generators;
        var routeAncestor = this.findAncestorBy(function (node) {
            return node.hasOwnProperty('getRouteElement');
        });

        return store.subscribe(_lodash2.default.debounce(function () {
            var previousRouteParams = _this2._previousRouteParams;

            var _routeAncestor$getSta = routeAncestor.getState(),
                currentRouteParams = _routeAncestor$getSta.routeParams;

            if (currentRouteParams.routeActive && !_lodash2.default.isEqual(previousRouteParams, currentRouteParams)) {
                _this2._previousRouteParams = currentRouteParams;

                selfModule.getDynamicListName().then(function (res) {
                    res && store.dispatch(actions.setListerName(res));
                });
            }
        }, 500));
    }
});

module.exports = RCHeader;