'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _navigationArrowDropRight = require('material-ui/svg-icons/navigation-arrow-drop-right');

var _navigationArrowDropRight2 = _interopRequireDefault(_navigationArrowDropRight);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _colors = require('@rubyapps/ruby-styles/src/client/styleObjects/colors');

var Colors = _interopRequireWildcard(_colors);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function filterableFieldsFromSearchableFields() {
    var searchableFields = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    // Transform searchable fields for consumption by Advanced Filter Dropdown UI
    var filterableFields = searchableFields.reduce(function (acc, field) {
        var breadcrumb = field.breadcrumb,
            _field$children = field.children,
            children = _field$children === undefined ? [] : _field$children,
            _field$childrenPropsB = field.childrenPropsByKey,
            childrenPropsByKey = _field$childrenPropsB === undefined ? {} : _field$childrenPropsB,
            componentName = field.componentName,
            key = field.key,
            label = field.label,
            _field$listerConfig = field.listerConfig,
            listerConfig = _field$listerConfig === undefined ? { excludeFromFilterSelection: false } : _field$listerConfig,
            templateType = field.templateType;


        var shouldExcludeFromFilterableFields = listerConfig.excludeFromFilterSelection;
        //# Note: we previously erroneously excluded fields with childrenPropsByKey due to a bug during refactoring from flatten searchableFields to nested searchableFields
        //# where the internal children fields were included as siblings of the parent (eg. [profile, first_name, last_name] when it should have been [{profile, children: [first_name...]}]

        if (shouldExcludeFromFilterableFields) {
            return acc;
        }

        var filterableFieldsChildren = !_lodash2.default.isEmpty(children) ? filterableFieldsFromSearchableFields(children) : [];

        //# NOTE: For Advanced Search Filter Dropdown UI we are flattening Fieldset components
        var filterableField = _extends({}, field, { children: componentName === 'Fieldset' ? [] : filterableFieldsChildren });

        var shouldIgnoreParentField = !label; //# ignore field and recurse into children

        return acc.concat(shouldIgnoreParentField ? filterableFieldsChildren : filterableFieldWithMenuItemProps(filterableField)
        //# NOTE: For Advanced Search Filter Dropdown UI we are flattening
        //# Fieldset components
        , componentName === 'Fieldset' ? filterableFieldsChildren : []);
    }, []);

    return filterableFields;
}

function filterableFieldWithMenuItemProps(filterableField) {
    var _React$createElement;

    var _filterableField$chil = filterableField.children,
        children = _filterableField$chil === undefined ? [] : _filterableField$chil,
        componentName = filterableField.componentName,
        key = filterableField.key,
        label = filterableField.label;


    var displayStyle = _extends({ paddingLeft: '7px' }, componentName === 'Fieldset' ? { color: Colors.rubyBlue, fontWeight: 700, paddingLeft: '15px' } : {});

    var menuItemProps = {
        primaryText: label,
        disabled: componentName === 'Fieldset',
        style: displayStyle,
        innerDivStyle: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline'
        },
        rightIcon: !_lodash2.default.isEmpty(children) && componentName !== 'Fieldset' ? _react2.default.createElement(_navigationArrowDropRight2.default, (_React$createElement = {
            'data-codecept-selector-node': 'ArrowDropRight',
            'data-codecept-selector-file': 'filterableFieldsFromSearchableFields'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ArrowDropRight'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'filterableFieldsFromSearchableFields'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ArrowDropRight'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'filterableFieldsFromSearchableFields'), _React$createElement)) : null,
        disabledSelection: !_lodash2.default.isEmpty(children) //# not disabled entirely, just disabling selecting
    };

    return _extends({}, filterableField, { menuItemProps: menuItemProps });
}

exports.default = filterableFieldsFromSearchableFields;