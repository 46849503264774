'use strict';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _index = require('./index.cssModule');

var _index2 = _interopRequireDefault(_index);

var _index3 = require('@rubyapps/ruby-material-ui/VirtualMenu/index.js');

var _index4 = _interopRequireDefault(_index3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; } //# monkeypatch 'react-selectize'

var PropTypes = _react2.default.PropTypes;
/*
const OptionWrapper = require('react-selectize/src/OptionWrapper');
const OptionWrapper__render = OptionWrapper.prototype.render;
OptionWrapper.prototype.render= function() {
    //# TODO: can probably override OptionWrapper from here
    return <div>OVERRIDE</div>
    return OptionWrapper__render.apply(this, arguments);
}
*/
//import Popover from 'material-ui/Popover/Popover';


var Popover = require('@rubyapps/ruby-material-ui/Popover').default;


var DivWrapper = require('react-selectize/src/DivWrapper');
var DivWrapper__render = DivWrapper.prototype.render;
DivWrapper.prototype.render = function () {
    var _this = this,
        _React$createElement,
        _React$createElement2,
        _React$createElement3;

    //this._reactInternalInstance._hostParent._hostParent._hostParent  //#
    //# this._reactInternalInstance._currentElement._owner //# NOTE: bad we shouldn't really do this

    //const anchorEl = findDOMNode(this._reactInternalInstance._currentElement._owner._currentElement);
    var anchorEl = this._reactInternalInstance._currentElement._owner._hostParent._hostNode; //# very bad
    //const anchorEl = this._reactInternalInstance._hostParent._hostNode;

    //# className={this.props.className}
    return _react2.default.createElement(
        Popover,
        (_React$createElement3 = {
            ref: 'dropdown',
            anchorEl: anchorEl,
            anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
            targetOrigin: { horizontal: 'left', vertical: 'top' },
            canAutoPosition: true,
            open: true
            //# NOTE: don't use clickAway layer because we want the user to be able to click on the 'x' button
            , useLayerForClickAway: false,
            'data-codecept-selector-node': 'Popover',
            'data-codecept-selector-file': 'Selectize'
        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Popover'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Selectize'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Popover'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Selectize'), _React$createElement3),
        _react2.default.createElement(
            'div',
            (_React$createElement2 = {
                //# NOTE: Prevent mouseDown events within the menu from closing the dropdown
                onMouseDown: function onMouseDown(ev) {
                    return ev.preventDefault();
                },
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Selectize'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Selectize'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Selectize'), _React$createElement2),
            _react2.default.createElement(
                _index4.default,
                (_React$createElement = {
                    className: _index2.default.Menu__List,
                    autoWidth: true,
                    maxHeight: 400,
                    ref: function ref(menuRef) {
                        _this._menuRef = menuRef;
                    },
                    width: anchorEl.offsetWidth,
                    style: { minWidth: anchorEl.offsetWidth },
                    initiallyKeyboardFocused: true,
                    'data-codecept-selector-node': 'Menu',
                    'data-codecept-selector-file': 'Selectize'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Menu'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Selectize'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Menu'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Selectize'), _React$createElement),
                this.props.children
            )
        )
    );
    return DivWrapper__render.apply(this, arguments);
};
//# NOTE: might need to override DropdownMenu.renderDropdown() in order to be able to ignore the open prop
//# and allow popover to handle the animation


var DropdownMenu = require('react-selectize/src/DropdownMenu');

//# NOTE: cannot override non-standard methods via prototype
//# so we need to override the instance methods instead
var DropdownMenu__componentDidMount = DropdownMenu.prototype.componentDidMount;

DropdownMenu.prototype.componentDidMount = function () {

    this.highlightAndScrollToOption = function (index, callback) {
        var _this2 = this;

        var uid = this.props.uid(this.props.options[index]);
        this.props.onHighlightedUidChange(uid, function () {
            _this2.refs.dropdownMenu && _this2.refs.dropdownMenu._menuRef && _this2.refs.dropdownMenu._menuRef.scrollToIndex(index);

            return callback && callback();
        });
    };

    DropdownMenu__componentDidMount && DropdownMenu__componentDidMount.apply(this, arguments);
};

var _require = require("react-selectize"),
    SimpleSelect = _require.SimpleSelect,
    MultiSelect = _require.MultiSelect,
    ReactSelectize = _require.ReactSelectize;

var ReactSelectize__render = ReactSelectize.prototype.render;
/*
ReactSelectize.contextTypes = {
    muiTheme: PropTypes.object
};
*/ //# enable if we need to inject context
ReactSelectize.prototype.render = function () {
    var renderedSelectize = ReactSelectize__render.apply(this, arguments);

    var inputProps = this.props.inputProps;
    var TextFieldUnderlineElement = inputProps.TextFieldUnderlineElement,
        errorTextElement = inputProps.errorTextElement;


    var control = renderedSelectize.props.children[1];
    var controlChildren = control.props.children;
    var clonedControl = _react2.default.cloneElement(control, {}, this.props.placeholder //# always show placeholder
    , [].concat(_toConsumableArray(controlChildren.slice(1))), TextFieldUnderlineElement);

    var overrideSelectizeChildren = [renderedSelectize.props.children[0], clonedControl, errorTextElement, renderedSelectize.props.children[2]];

    return _react2.default.cloneElement(renderedSelectize, {}, [].concat(overrideSelectizeChildren));
};

module.exports = {
    SimpleSelect: SimpleSelect,
    MultiSelect: MultiSelect
};