'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* jshint -W138 */

var actionTypes = require('./action').TYPES;

module.exports = function (state, action) {
    switch (action.type) {
        case actionTypes.OVERRIDE_FORM_PROPS:
            console.warn('[DEPRECATED] 20180927 - should only be used to override client-side templates like user and RubyRole, but we should not be doing that in general');
            var overrides = action.payload.config;
            var newState = JSON.parse(JSON.stringify(state));
            var fieldsByTemplateKey = getFieldsByTemplateKey_fromTemplates(newState);
            Object.keys(overrides || {}).forEach(function (templateKey) {
                var fieldsToOverrideByKey = action.payload.config[templateKey];
                Object.keys(fieldsToOverrideByKey).forEach(function (fieldKey) {
                    var fieldToOverride = _.find(fieldsByTemplateKey[templateKey], function (field) {
                        return field.key === fieldKey;
                    });
                    _extends(fieldToOverride, overrides[templateKey][fieldKey]);
                });
            });
            return newState;
        case actionTypes.SET_TEMPLATE:
            return _extends({}, state, _defineProperty({}, action.payload.key, action.payload.data));
        case actionTypes.SET_TEMPLATES:
            return _extends({}, state, action.payload.templates);
        default:
            return state;
    }
};

function getFieldsByTemplateKey_fromTemplates(templates) {
    var fieldsByTemplateKey = {};
    Object.keys(templates).forEach(function (templateKey) {
        var template = templates[templateKey];
        var flattenedForm = flatten_object_withKey(template.form, 'children');
        fieldsByTemplateKey[templateKey] = flattenedForm;
    });
    return fieldsByTemplateKey;
}

function flatten_object_withKey(object, key) {
    if (Array.isArray(object[key])) {
        return (object[key] || []).reduce(function (result, curr) {
            return result.concat(flatten_object_withKey(curr, key));
        }, []);
    } else {
        return object;
    }
}