'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = defaultMappers_forReactClass;

var _redux = require('redux');

function defaultMappers_forReactClass() {
    var reactClass = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var selfModule = this;
    var reactClass__propTypeKeys = Object.keys(reactClass.propTypes || {});

    //# figure out the toHTML ahead of time
    var hydratedToHTML = this.hydratedToHTMLFromProps(this.props);

    function mapStateToProps(state, ownProps) {
        var selfState = selfModule.getState(state);

        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
        var formattedError = selfModule.formattedErrorFromState(state);

        var fieldKey = mixinFieldProps.fieldKey;


        var mappedProps = _extends({}, ownProps, _.pick(selfState, reactClass__propTypeKeys), mixinFieldProps, fieldKey ? _.pick(selfState.fields[fieldKey], ['value']) : {}, formattedError
        //# formattedError should include richErrorMessages
        //# no need to call on selfModule.richErrorMessages()
        , hydratedToHTML ? { toHTML: hydratedToHTML } : {});

        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
        };
    }

    return {
        mapStateToProps: mapStateToProps,
        mapDispatchToProps: mapDispatchToProps
    };
}