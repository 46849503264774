'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _Header = require('./Header');

var _Header2 = _interopRequireDefault(_Header);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

module.exports = function HeaderGenerator() {
    var selfModule = this;
    var rootComponent = selfModule.getRoot();

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector,
        selfActions = _selfModule$getDepend.selfActions,
        frontendSettingsSelector = _selfModule$getDepend.frontendSettingsSelector,
        currentUserSelector = _selfModule$getDepend.currentUserSelector,
        currentUserActions = _selfModule$getDepend.currentUserActions,
        recentUpdatesActions = _selfModule$getDepend.recentUpdatesActions;

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);
        var frontendSettings = frontendSettingsSelector(state);

        var currentUserInfo = currentUserSelector(state);

        var mappedFields = {
            id: selfModule.getID(),
            userInfo: currentUserInfo ? currentUserInfo : {},
            delegateLogOut: function delegateLogOut() {
                //# deprecated
                window.location = '/logout.html';
            },
            menuConfig: selfModule.filteredMenuConfig(),
            activeRoute: window.location.pathname,
            frontendSettings: frontendSettings
        };

        return _extends({}, selfState, mappedFields);
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfActions, dispatch),
            delegateActions: {
                currentUser: (0, _redux.bindActionCreators)(currentUserActions, dispatch),
                recentUpdates: (0, _redux.bindActionCreators)(recentUpdatesActions, dispatch)
            }
        };
    }

    function mergeProps(stateProps, dispatchProps, ownProps) {
        var userEditPageID = selfModule.props.userEditPageID;

        var currentUserInfo = stateProps.userInfo;
        var currentUserId = _lodash2.default.get(currentUserInfo, 'id');
        var currentUserEditPageLocation = currentUserId ? rootComponent.getUrlForComponent_fromModule_withParams(userEditPageID, selfModule, { id: currentUserId, action: 'edit' }) : '#';

        return _extends({}, ownProps, stateProps, dispatchProps, {
            //# setup the delegate callbacks here whcih calls on dispatchProps.actions...
            delegateRouteChange: function delegateRouteChange(route, menuItemConfig) {
                if (menuItemConfig.url) {
                    if (menuItemConfig.newWindow) {
                        window.open(route);
                    } else {
                        window.location = route;
                    }
                } else {
                    dispatchProps.actions.navigateToPathWithOptions({ path: route });
                }
            },
            delegateUpdateAccount: function delegateUpdateAccount() {
                dispatchProps.actions.navigateToPathWithOptions({ path: currentUserEditPageLocation });
            },
            delegateToggleMenuPin: function delegateToggleMenuPin() {
                dispatchProps.actions.toggleMenuPin();
            }
        });
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(_Header2.default);
};