'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp2;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Checkbox = require('material-ui/Checkbox');

var _Checkbox2 = _interopRequireDefault(_Checkbox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SelectColumn = (_temp2 = _class = function (_Component) {
    _inherits(SelectColumn, _Component);

    function SelectColumn() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, SelectColumn);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SelectColumn.__proto__ || Object.getPrototypeOf(SelectColumn)).call.apply(_ref, [this].concat(args))), _this), _this._toggleSelection = function () {
            var _this$props = _this.props,
                events = _this$props.events,
                selected = _this$props.selected;

            if (selected) {
                events.unselectVisibleRows();
            } else {
                events.selectVisibleRows();
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(SelectColumn, [{
        key: 'render',
        value: function render() {
            var selected = this.props.selected;


            return _react2.default.createElement(_Checkbox2.default, {
                checked: selected,
                onClick: this._toggleSelection,
                style: { position: 'absolute', top: 11 },
                'data-codecept-selector-node': 'Checkbox',
                'data-codecept-selector-file': 'table'
            });
        }
    }]);

    return SelectColumn;
}(_react.Component), _class.propTypes = {
    selected: _react.PropTypes.bool,
    events: _react.PropTypes.object,
    parentProps: _react.PropTypes.object
}, _temp2);


function getSelectColumn(selected, events, parentProps) {
    var checkbox = _react2.default.createElement(SelectColumn, {
        selected: selected,
        events: events,
        parentProps: parentProps,
        'data-codecept-selector-node': 'SelectColumn',
        'data-codecept-selector-file': 'table'
    });

    return checkbox;
}

function isAllVisibleSelected(griddleProps) {
    var visibleData = griddleProps.visibleData,
        selectedRecords = griddleProps.selectedRecords;


    var selectedIDs = Object.keys(selectedRecords);

    var selectedAndVisibleRecords = visibleData.reduce(function (collector, value) {
        var valueID = "" + _lodash2.default.get(value, '__metadata.id', value.id);
        if (_lodash2.default.indexOf(selectedIDs, valueID) >= 0) {
            collector.push(value);
        }
        return collector;
    }, []);

    return selectedAndVisibleRecords.length == visibleData.length;
}

exports.default = function (Table) {
    var _class2, _temp3;

    return _temp3 = _class2 = function (_Component2) {
        _inherits(_class2, _Component2);

        function _class2() {
            _classCallCheck(this, _class2);

            return _possibleConstructorReturn(this, (_class2.__proto__ || Object.getPrototypeOf(_class2)).apply(this, arguments));
        }

        _createClass(_class2, [{
            key: 'render',
            value: function render() {

                //# TODO: need to check the selection status and set selected accordingly
                var allVisibleIsSelected = isAllVisibleSelected(this.props);
                var selectColumn = getSelectColumn(allVisibleIsSelected, this.props.events, this.props);
                var renderProperties = _extends({}, this.props.renderProperties, {
                    columnProperties: _extends({}, _lodash2.default.get(this, 'props.renderProperties.columnProperties'), {
                        selectColumn: {
                            id: 'selectColumn',
                            width: 40,
                            resizable: false,
                            sortable: false,
                            displayName: selectColumn,
                            order: -1
                        }
                    })
                });

                return _react2.default.createElement(Table, _extends({}, this.props, { renderProperties: renderProperties }, {
                    'data-codecept-selector-node': 'Table',
                    'data-codecept-selector-file': 'table'
                }));
            }
        }]);

        return _class2;
    }(_react.Component), _class2.PropTypes = {
        renderProperties: _react.PropTypes.object.isRequired
    }, _temp3;
};