'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _SelectField = require('@rubyapps/ruby-material-ui/SelectField');

var _SelectField2 = _interopRequireDefault(_SelectField);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _Toggle = require('material-ui/Toggle');

var _Toggle2 = _interopRequireDefault(_Toggle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var _ = require('lodash');

var PropTypes = React.PropTypes;

var NAME_KEY = 'label';
var TYPE_KEY = 'subtemplate';

var SubTemplateCard = React.createClass({
    displayName: 'SubTemplateCard',

    getDefaultProps: function getDefaultProps() {
        return {
            instance: { instanceProps: {} }
        };
    },
    _getInstanceProp: function _getInstanceProp(path, defaultValue) {
        var value = _.get(this, [].concat(['props', 'instance', 'instanceProps'], path), defaultValue);
        return value && value.toJSON ? value.toJSON() : value;
    },
    _getSubTemplates: function _getSubTemplates() {
        return this.props.subtemplates || {};
    },
    _onNameChange: function _onNameChange(event) {
        var propertiesWithoutPermissions_byKey = this.props.selfModule.propertiesWithoutPermissions_byKey();
        var constraintsForName = _.get(propertiesWithoutPermissions_byKey[NAME_KEY], 'constraints');
        this.props.set_property_toValue(NAME_KEY, event.target.value, constraintsForName);
    },
    _onToggleChange_forProperty: function _onToggleChange_forProperty(property) {
        var self = this;
        return function (event, toggled) {
            self.props.set_property_toValue(property, toggled);
        };
    },
    _onDropdownChange_forProperty: function _onDropdownChange_forProperty(property) {
        var self = this;
        return function (event, index, value) {
            self.props.set_property_toValue(property, value);
        };
    },
    render: function render() {
        var _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6;

        var overrideLocked = this.props.overrideLocked;

        var isLocked = this._getInstanceProp(['_locked', 'value']) && !overrideLocked;
        var subtemplates = this._getSubTemplates();

        return React.createElement(
            'div',
            (_React$createElement6 = { style: { display: 'flex' }, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'SubTemplate'
            }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'SubTemplate'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'SubTemplate'), _React$createElement6),
            React.createElement(
                'div',
                (_React$createElement5 = {
                    style: { flex: 1 },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'SubTemplate'
                }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'SubTemplate'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'SubTemplate'), _React$createElement5),
                React.createElement(
                    'div',
                    (_React$createElement4 = {
                        style: { maxWidth: "256px" },
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'SubTemplate'
                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'SubTemplate'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'SubTemplate'), _React$createElement4),
                    React.createElement(
                        _SelectField2.default,
                        (_React$createElement2 = {
                            floatingLabelText: 'Type *',
                            onChange: this._onDropdownChange_forProperty(TYPE_KEY),
                            disabled: isLocked,
                            value: this._getInstanceProp([TYPE_KEY, 'value']),
                            errorText: this._getInstanceProp([TYPE_KEY, 'errorText']),
                            'data-codecept-selector-node': 'SelectField',
                            'data-codecept-selector-file': 'SubTemplate'
                        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'SelectField'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'SubTemplate'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'SelectField'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'SubTemplate'), _React$createElement2),
                        subtemplates && _.map(subtemplates, function (subtemplate) {
                            var _React$createElement;

                            return React.createElement(_MenuItem2.default, (_React$createElement = {
                                key: _.get(subtemplate, 'key', ''),
                                value: _.get(subtemplate, 'key', ''),
                                primaryText: _.get(subtemplate, 'name', ''),
                                'data-codecept-selector-node': 'MenuItem',
                                'data-codecept-selector-file': 'SubTemplate'
                            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'SubTemplate'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'SubTemplate'), _React$createElement));
                        })
                    ),
                    React.createElement(_Toggle2.default, (_React$createElement3 = {
                        toggled: this._getInstanceProp(['_locked', 'value']) || false,
                        onToggle: this._onToggleChange_forProperty('_locked'),
                        label: 'Lock subtemplate from being edited?',
                        disabled: overrideLocked ? false : true,
                        'data-codecept-selector-node': 'Toggle',
                        'data-codecept-selector-file': 'SubTemplate'
                    }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'SubTemplate'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'SubTemplate'), _React$createElement3))
                )
            )
        );
    }
});

exports.default = SubTemplateCard;