"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.getStyleProperties = getStyleProperties;
function getStyleProperties(props, sectionName) {
  var className = props.styles.getClassName({
    section: sectionName,
    classNames: props.styles.classNames,
    useClassNames: props.settings.useGriddleClassNames
  });

  var style = props.styles.getStyle(_extends({
    styles: props.styles.inlineStyles,
    styleName: sectionName
  }, props.style));

  return { className: className, style: style };
}