'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconMenu = require('material-ui/IconMenu');

var _IconMenu2 = _interopRequireDefault(_IconMenu);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _moreVert = require('material-ui/svg-icons/navigation/more-vert');

var _moreVert2 = _interopRequireDefault(_moreVert);

var _Activity_style = require('../Activity_style');

var _Activity_style2 = _interopRequireDefault(_Activity_style);

var _constants = require('../../../common/constants');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

//import Tooltip from 'material-ui/internal/Tooltip';

var styles = process.browser ? require('../Activity.cssModule').default : {};

var ACTIVITY_TYPES = _constants2.default.ACTIVITY_TYPES;


var ActivityMenu = _react2.default.createClass({
    displayName: 'ActivityMenu',

    propTypes: {
        templateKey: PropTypes.string.isRequired,
        forms: PropTypes.object.isRequired
    },
    customMixins: [] //# non-reserved key to handle special mixins where we want to handle calling them ourselves
    , belongsToCurrentUser: function belongsToCurrentUser() {
        return this.isCurrentUser('user');
    },
    isCurrentUser: function isCurrentUser(dataKey) {
        // TODO: copy paste from title.js
        var userId = _lodash2.default.get(this.props, [dataKey, 'id']);
        var currentUserId = _lodash2.default.get(this.props, 'rubyUser.id');
        return currentUserId && userId == currentUserId;
    },
    getActivityRepresentation: function getActivityRepresentation() {
        // TODO: copy paste from title.js
        return {
            activityId: this.props.id,
            versionDataId: this.props.version_data_id,
            versionNumber: this.props.version_num,
            templateKey: this.props.entity_name,
            entityId: this.props.entity_id,
            details: this.props.details
        };
    },
    renderMenuItems: function renderMenuItems() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4;

        var _props = this.props,
            id = _props.id,
            activityType = _props.activity_type,
            comment = _props.comment,
            versionNumber = _props.version_num,
            versionDataId = _props.version_data_id,
            actions = _props.actions,
            shouldHidePreviewLinks = _props.shouldHidePreviewLinks,
            shouldHideRevertLinks = _props.shouldHideRevertLinks,
            forms = _props.forms;
        var editActivityComment = actions.editActivityComment,
            deleteCommentActivity = actions.deleteCommentActivity,
            previewActivityVersion = actions.previewActivityVersion,
            revertToVersion = actions.revertToVersion;

        var activity = this.getActivityRepresentation();

        var templateKeyMatch = _lodash2.default.get(activity, 'details.template_keyword') == this.props.templateKey;

        var PreviewRevertMenuItems = [].concat(shouldHidePreviewLinks ? [] : _react2.default.createElement(_MenuItem2.default, (_React$createElement = {
            primaryText: 'Preview Revision',
            disabled: !versionDataId || activityType === ACTIVITY_TYPES.RERANK_ACTIVITY || !templateKeyMatch,
            onTouchTap: function onTouchTap(e) {
                e.preventDefault();
                previewActivityVersion(activity);
            },
            'data-codecept-selector-node': 'MenuItem',
            'data-codecept-selector-file': 'menu'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'menu'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'menu'), _React$createElement)), shouldHideRevertLinks ? [] : _react2.default.createElement(_MenuItem2.default, (_React$createElement2 = {
            primaryText: 'Revert to Revision',
            disabled: !versionDataId || activityType === ACTIVITY_TYPES.RERANK_ACTIVITY || !templateKeyMatch,
            onTouchTap: function onTouchTap(e) {
                e.preventDefault();
                revertToVersion(versionNumber, versionDataId);
            },
            'data-codecept-selector-node': 'MenuItem',
            'data-codecept-selector-file': 'menu'
        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'menu'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'menu'), _React$createElement2)));

        var menuItems = [].concat(activityType !== ACTIVITY_TYPES.WORDDOC_GENERATION_ACTIVITY ? PreviewRevertMenuItems : [], [_react2.default.createElement(_MenuItem2.default, (_React$createElement3 = {
            primaryText: this.props.comment ? "Edit Comment" : "Comment",
            disabled: !this.belongsToCurrentUser(),
            onTouchTap: function onTouchTap(e) {
                e.preventDefault();
                editActivityComment(id, comment);
            },
            'data-codecept-selector-node': 'MenuItem',
            'data-codecept-selector-file': 'menu'
        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'menu'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'menu'), _React$createElement3)), _react2.default.createElement(_MenuItem2.default, (_React$createElement4 = {
            primaryText: 'Delete Comment',
            disabled: !this.belongsToCurrentUser() || activityType !== ACTIVITY_TYPES.COMMENT_ACTIVITY && activityType !== ACTIVITY_TYPES.APPROVAL_COMMENT_ACTIVITY,
            onTouchTap: function onTouchTap(e) {
                e.preventDefault();
                deleteCommentActivity(id);
            },
            'data-codecept-selector-node': 'MenuItem',
            'data-codecept-selector-file': 'menu'
        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'menu'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'menu'), _React$createElement4))]);

        return menuItems;
    },
    render: function render() {
        var _this = this,
            _React$createElement5,
            _React$createElement6,
            _extends2;

        if (!this.props.actions) return null;

        var mixinMenuItems = this.customMixins.reduce(function (collector, mixin) {
            if (collector) {
                return collector;
            }

            return mixin.renderMenuItems && mixin.renderMenuItems.apply(_this);
        }, null);

        var menuItems = mixinMenuItems || this.renderMenuItems();

        /*
                        <Tooltip
                            ref="tooltip"
                            label={activityDetails_templateName}
                            show={true}
                            touch={true}
                            verticalPosition={'top'}
                            horizontalPosition={'center'}
                        />
        */
        return _react2.default.createElement(
            _IconMenu2.default,
            _extends({
                iconButtonElement: _react2.default.createElement(
                    _IconButton2.default,
                    (_React$createElement6 = {
                        'data-codecept-selector-node': 'IconButton',
                        'data-codecept-selector-file': 'menu'
                    }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'menu'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'menu'), _React$createElement6),
                    _react2.default.createElement(_moreVert2.default, (_React$createElement5 = {
                        'data-codecept-selector-node': 'MoreVertIcon',
                        'data-codecept-selector-file': 'menu'
                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'menu'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'menu'), _React$createElement5))
                )
            }, _Activity_style2.default.IconMenu, (_extends2 = {
                'data-codecept-selector-node': 'IconMenu',
                'data-codecept-selector-file': 'menu'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_extends2, 'data-codecept-selector-file', 'menu'), _defineProperty(_extends2, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_extends2, 'data-codecept-selector-file', 'menu'), _extends2)),
            menuItems
        );
    }
});

exports.default = ActivityMenu;