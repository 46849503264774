'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _baseField = require('./baseField');

var _baseField2 = _interopRequireDefault(_baseField);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    style: _baseField2.default.style
    /*
    , underlineStyle: {
        //borderColor: Colors.black_25
    }
    , iconStyle: {
        //fill: Colors.black_25
    }
    */
};