'use strict';

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var action = require('./action');
var reducer = require('./reducer');
var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;
var ContentCompareDialogConnector = require('./reactComponents/ContentCompareDialogConnector');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;
var RubyComponentFrontendSettings = require('@rubyapps/ruby-component-frontend-settings');
var RubyComponentCurrentUser = require('@rubyapps/ruby-component-current-user');
var RubyNotifications = require('@rubyapps/ruby-component-notifications');

var accessControlMixin = require('@rubyapps/ruby-component-mixin-access-control');

var RCContentCompare = RubyComponent.createClass({
    mixins: [accessControlMixin],
    propTypes: {
        previewDuration: PropTypes.number
    },
    getDefaultProps: function getDefaultProps() {
        return {
            formsID: 'rubyComponentForms',
            previewDuration: CONSTANTS.PREVIEW_DURATION
        };
    },
    componentName: componentName,
    CONSTANTS: CONSTANTS,
    action: action,
    reducer: reducer,
    dependencies: function dependencies() {
        //# declare dependencies here so it's easy to find
        var rootComponent = this.getRoot();
        var selfSelector = this.getDefaultSelector();
        var selfActions = this.getAction().generators;

        var editPageID = this.props.editPageID || 'rubyComponentEditPage';
        var editPageComponent = rootComponent.findDescendentByID(editPageID);
        var editPageSelector = editPageComponent.getDefaultSelector();

        var frontendSettingsID = this.props.frontendSettingsID || RubyComponentFrontendSettings.CONSTANTS.COMPONENT_NAME;
        var frontendSettingsComponent = rootComponent.findDescendentByID(frontendSettingsID);
        var frontendSettingsSelector = frontendSettingsComponent.getDefaultSelector();

        var formsComponent = rootComponent.findDescendentByID(this.props.formsID);
        var currentUserID = this.props.currentUserID || RubyComponentCurrentUser.componentName;
        var currentUser = rootComponent.findDescendentByID(currentUserID);
        var currentUserSelector = currentUser.getDefaultSelector();

        return {
            selfSelector: selfSelector,
            selfActions: selfActions,
            editPageComponent: editPageComponent,
            editPageSelector: editPageSelector,
            frontendSettingsComponent: frontendSettingsComponent,
            frontendSettingsSelector: frontendSettingsSelector,
            formsComponent: formsComponent,
            formsSelector: formsComponent.getDefaultSelector(),
            currentUserSelector: currentUserSelector
        };
    },
    children: function children() {
        var parentID = this.getID();
        return [RubyComponent.createElement(RubyNotifications, {
            reactProps: {
                modal: false,
                action: "Close",
                duration: 0,
                styles: { style: { top: '0', position: 'absolute' } }
            },
            id: parentID + '.ruby_notifications'
        })];
    },
    getReactClass: function getReactClass() {
        var ContentCompareDialogComponent = ContentCompareDialogConnector.apply(this);
        return ContentCompareDialogComponent;
    },
    getReactElement: function getReactElement() {
        var _React$createElement;

        var ContentCompareDialogComponent = this.getReactClass();
        //# perform connection
        //# need to access the ruby_client_fk
        //# action to open the compare dialog should give the templatekey
        return React.createElement(ContentCompareDialogComponent, (_React$createElement = {
            'data-codecept-selector-node': 'ContentCompareDialogComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ContentCompareDialogComponent'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ContentCompareDialogComponent'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement));
    }
});

module.exports = RCContentCompare;