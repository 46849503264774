'use strict';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _uuid = require('../helpers/uuid');

var _uuid2 = _interopRequireDefault(_uuid);

var _ucFirst = require('../helpers/ucFirst');

var _ucFirst2 = _interopRequireDefault(_ucFirst);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var isEqual = _lodash2.default.isEqual;
var clone = _lodash2.default.clone;


var path = require('path');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

var tinymce = require('tinymce/tinymce');

require('./TinyMCE.css');

// Include all of the Native DOM and custom events from:
// https://github.com/tinymce/tinymce/blob/master/tools/docs/tinymce.Editor.js#L5-L12
var EVENTS = ['focusin', 'focusout', 'click', 'dblclick', 'mousedown', 'mouseup', 'mousemove', 'mouseover', 'beforepaste', 'paste', 'cut', 'copy', 'selectionchange', 'mouseout', 'mouseenter', 'mouseleave', 'keydown', 'keypress', 'keyup', 'contextmenu', 'dragend', 'dragover', 'draggesture', 'dragdrop', 'drop', 'drag', 'BeforeRenderUI', 'SetAttrib', 'PreInit', 'PostRender', 'init', 'deactivate', 'activate', 'NodeChange', 'BeforeExecCommand', 'ExecCommand', 'show', 'hide', 'ProgressState', 'LoadContent', 'SaveContent', 'BeforeSetContent', 'SetContent', 'BeforeGetContent', 'GetContent', 'VisualAid', 'remove', 'submit', 'reset', 'BeforeAddUndo', 'AddUndo', 'change', 'undo', 'redo', 'ClearUndos', 'ObjectSelected', 'ObjectResizeStart', 'ObjectResized', 'PreProcess', 'PostProcess', 'focus', 'blur', 'dirty'];

// Note: because the capitalization of the events is weird, we're going to get
// some inconsistently-named handlers, for example compare:
// 'onMouseleave' and 'onNodeChange'
var HANDLER_NAMES = EVENTS.map(function (event) {
    return 'on' + (0, _ucFirst2.default)(event);
});

var tinymceSelector = ".tox-tinymce";
var toolbarSelectors = ".tox-editor-header"; //".mce-toolbar[role = menubar], .mce-toolbar-grp";
var iframeSelector = '.tox-edit-area__iframe'; //".mce-edit-area iframe";
function totalHeightOfElements($elements) {
    var totalHeight = 0;
    $elements.each(function (index, element) {
        totalHeight += $(element).outerHeight();
    });
    return totalHeight + 1; //# 1px to account for the body top border width that's right under the toolbar
}

var TinyMCE = _react2.default.createClass({
    displayName: 'TinyMCE',

    propTypes: {
        config: _react2.default.PropTypes.object,
        content: _react2.default.PropTypes.string,
        id: _react2.default.PropTypes.string,
        className: _react2.default.PropTypes.string,
        toolbarAsHiddenByDefault: _react2.default.PropTypes.bool
    },

    getDefaultProps: function getDefaultProps() {
        return {
            config: {},
            content: '',
            toolbarAsHiddenByDefault: true
        };
    },
    componentWillMount: function componentWillMount() {
        this.id = (0, _uuid2.default)();
    },
    componentDidMount: function componentDidMount() {
        this._init(this.props.config);
    },
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        //# cannot allow the content to be set over and over again

        var contentIsDiff = true;
        var editorIsAvailable = this._editor && this._editor.initialized;
        if (editorIsAvailable && this._editor.getContent() != undefined) {
            contentIsDiff = this._editor.getContent() != nextProps.content;
        }
        var configIsDiff = !isEqual(this.props.config, nextProps.config);

        if (editorIsAvailable) {
            //# NOTE: might need to queue up additional init requests
            var content = nextProps.content || '';
            if (configIsDiff && contentIsDiff) {
                this._init(nextProps.config, content);
            } else if (contentIsDiff) {
                //# NOTE: don't set it in here
                //# we have a weird edgecase where it gets unmounted (by a fieldset) and remounted, this gets triggered with the first edit afterwards, but it thinks its nextProps is the previous probs, so content is wrong
                //this._setContent(content);
            }
        }
        if (!isEqual(this.props.id, nextProps.id)) {
            this.id = nextProps.id;
        }
    },
    shouldComponentUpdate: function shouldComponentUpdate(nextProps) {
        return !isEqual(this.props.content, nextProps.content) || !isEqual(this.props.config, nextProps.config);
    },
    componentWillUnmount: function componentWillUnmount() {
        this._remove();
    },
    render: function render() {
        var _React$createElement, _React$createElement2;

        var content = this.props.content || '';
        if (this._editor) {
            this._editor.setMode(this.props.config.readonly ? 'readonly' : 'design');
        }
        return this.props.config.inline ? _react2.default.createElement('div', (_React$createElement = {
            id: this.id,
            className: this.props.className,
            dangerouslySetInnerHTML: { __html: content },
            'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'TinyMCE'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TinyMCE'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TinyMCE'), _React$createElement)) : _react2.default.createElement('textarea', (_React$createElement2 = {
            id: this.id,
            className: this.props.className,
            defaultValue: content,
            'data-codecept-selector-node': 'textarea',
            'data-codecept-selector-file': 'TinyMCE'
        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'textarea'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TinyMCE'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'textarea'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TinyMCE'), _React$createElement2));
    },
    componentDidUpdate: function componentDidUpdate(prevProps, prevState) {
        var editorIsAvailable = this._editor && this._editor.initialized;

        var content = this.props.content || '';
        if (editorIsAvailable && this._editor.getContent) {
            var contentIsDiff = this._editor.getContent() != content;
            if (contentIsDiff) {
                this._setContent(content);
            }
        } else if (this._editor && !editorIsAvailable) {
            this._editor.on('init', function () {
                this.setContent(content);
            });
        }
    },
    _init: function _init(pristineConfig, content) {
        var _this = this;

        var self = this;

        if (this._isInit) {
            this._remove();
        }
        var config = clone(pristineConfig);

        // hide the textarea that is me so that no one sees it
        (0, _reactDom.findDOMNode)(this).style.hidden = 'hidden';

        var setupCallback = config.setup;
        var hasSetupCallback = typeof setupCallback === 'function';

        config.selector = '#' + this.id;
        config.setup = function (editor) {
            _this._editor = editor;
            EVENTS.forEach(function (event, index) {
                var handler = _this.props[HANDLER_NAMES[index]];
                if (typeof handler !== 'function') return;
                editor.on(event, function (e) {
                    // native DOM events don't have access to the editor so we pass it here

                    handler(e, editor);
                });
            });

            var originalIframeHeight = void 0;
            var originalToolbarHeight = void 0;

            //# Hide toolbar by default
            if (_this.props.toolbarAsHiddenByDefault) {
                var animationDuration = 200;
                editor.on('focus', function () {
                    var $parentElement = $(editor.contentAreaContainer.parentElement).closest(tinymceSelector);
                    var $toolbarElements = $parentElement.find(toolbarSelectors);
                    var $iframeElement = $parentElement.find(iframeSelector);

                    var iframeHeight = $iframeElement.height();
                    $toolbarElements.removeClass('reactTinyMCE_hidden');
                    var totalHeight = totalHeightOfElements($toolbarElements);

                    if (!originalIframeHeight) {
                        var _iframeHeight = $iframeElement.height();
                        originalIframeHeight = _iframeHeight;
                    }
                    $($toolbarElements[0]).css('marginTop', -1 * totalHeight + 'px');

                    $($toolbarElements[0]).animate({
                        'marginTop': '0px'
                    }, animationDuration);
                    $($iframeElement).animate({
                        'height': iframeHeight - totalHeight + 'px'
                    }, animationDuration);
                });
                editor.on('blur', function () {
                    var $parentElement = $(editor.contentAreaContainer.parentElement).closest(tinymceSelector);
                    var $toolbarElements = $parentElement.find(toolbarSelectors);
                    var $iframeElement = $parentElement.find(iframeSelector);

                    var iframeHeight = $iframeElement.height();
                    var totalHeight = totalHeightOfElements($toolbarElements);

                    $($toolbarElements[0]).animate({
                        'marginTop': -1 * totalHeight + 'px'
                    }, animationDuration);
                    $($iframeElement).animate({
                        'height': iframeHeight + totalHeight + 'px'
                    }, animationDuration);
                    originalIframeHeight = null;
                });
            }

            editor.once('init', function () {
                if (_this._initializedEditor && _this._initializedEditor.id == editor.id) {
                    logger.debug('Skipping init because \'_initializedEditor\' (id: [' + editor.id + ']) is already defined. This is an edgecase where the init handler gets called. We\'ll return early');
                    //# NOTE: currently this only seems to happen when you're an editor who "saves as draft". During the auto-select of the new draft
                    //# this fires more than once where the initial content is an empty string
                    //# this does not happen for publishers performing the same action
                    return;
                }

                if (_this.props.toolbarAsHiddenByDefault) {
                    var $parentElement = $(editor.contentAreaContainer.parentElement).closest(tinymceSelector);
                    var $toolbarElements = $parentElement.find(toolbarSelectors);
                    $toolbarElements.addClass('reactTinyMCE_hidden');
                }

                // need to set content here because the textarea will still have the
                // old `this.props.content`
                if (content != undefined) {
                    editor.setContent(content);
                }

                _this._initializedEditor = editor;
            });

            if (hasSetupCallback) {
                setupCallback(editor);
            }
        };

        try {
            tinymce.init(config);
        } catch (err) {
            console.warn('Failed to initialize TinyMCE', err);
        }

        (0, _reactDom.findDOMNode)(this).style.hidden = '';

        this._isInit = true;
    },
    _setContent: function _setContent(content) {
        this._editor.setContent(content);
    },
    _remove: function _remove() {
        try {
            var editor = this._editor;
            tinymce.EditorManager.execCommand('mceRemoveEditor', true, this.id);
            editor.off();
            this._isInit = false;
            this._editor = undefined;
            this._initializedEditor = undefined;
        } catch (err) {
            //# it's ok we can ignore
            if (err.message != 'Cannot set property \'onload\' of null') {
                //throw err;
            }
        }
    }
});

// add handler propTypes
HANDLER_NAMES.forEach(function (name) {
    TinyMCE.propTypes[name] = _react2.default.PropTypes.func;
});

module.exports = TinyMCE;