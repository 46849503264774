'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _ModuleConnector = require('./reactComponents/ModuleConnector');

var _ModuleConnector2 = _interopRequireDefault(_ModuleConnector);

var _action = require('./action');

var _action2 = _interopRequireDefault(_action);

var _reducer = require('./reducer');

var _reducer2 = _interopRequireDefault(_reducer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldSetMixin = require('@rubyapps/ruby-component-mixin-field-set');

function isEmptyDeep(collection) {
    if (_lodash2.default.isNil(collection)) {
        return true;
    }

    return _lodash2.default.reduce(collection, function (collector, item, key) {
        if (_lodash2.default.isObjectLike(item)) {
            return isEmptyDeep(item) && collector;
        } else if (_lodash2.default.isArrayLike(item)) {
            return item.length == 0 && collector;
        } else {
            return _lodash2.default.isNil(item) && collector;
        }
    }, true);
}
window.testIsEmptyDeep = isEmptyDeep;

var RCModule = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldSetMixin],
    propTypes: {
        width: PropTypes.number,
        height: PropTypes.number,
        type: PropTypes.string,
        parentKey: PropTypes.string
    },
    getDefaultProps: function getDefaultProps() {
        return {
            width: 1200,
            height: 80
        };
    },
    componentName: componentName,
    reducer: _reducer2.default,
    action: _action2.default,
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();
        var selfAction = this.getAction();

        var parentForm = this.findAncestorBy(function (component) {
            return component.componentName == 'rubyComponentFieldForm';
        });
        var parentFormKey = parentForm ? parentForm.props.key : '';

        return {
            selfSelector: selfSelector,
            selfAction: selfAction,
            parentFormKey: 'homepage' //# homepage is for testing
        };
    },
    getReactClass: function getReactClass() {
        return _ModuleConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var ModuleComponent = this.getReactClass();

        return _react2.default.createElement(ModuleComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'ModuleComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'ModuleComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'ModuleComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },

    onReduxInit: function onReduxInit() {
        var _this = this;

        var store = this.getStore();

        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector,
            actions = _getDependencies.selfAction.generators;

        return store.subscribe(function () {
            var applicationState = store.getState();
            var selfState = selfSelector(applicationState);

            var stateSnapshot = selfState.stateSnapshot;
            //# Don't watch for 'open' state because that changes 
            //# before the form state is reverted
            //# so additional preview images would be generated
            var prevFormValue = _this._formValue;
            var nextFormValue = _this.formValueFromLocalState(selfState);

            if (!stateSnapshot && nextFormValue && !_lodash2.default.isEqual(prevFormValue, nextFormValue)) {
                _this._formValue = nextFormValue;

                //# clear preview image if nextFormValue is emtpy
                //# update image
                if (!isEmptyDeep(nextFormValue)) {
                    store.dispatch(actions.retrievePreview_withFormValue(nextFormValue));
                } else {
                    store.dispatch(actions.clearPreview());
                }
            }
        });
    }
});

module.exports = RCModule;