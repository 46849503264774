"use strict";

function typesWithID(id) {
    return {
        SET_IS_LOADING: "@@ruby-app/" + id + "/SET_IS_LOADING"
    };
}

var generators = {
    setIsLoading: function setIsLoading(isLoading) {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES,
            generators = _getAction.generators;

        return {
            type: TYPES.SET_IS_LOADING,
            payload: {
                isLoading: isLoading
            }
        };
    }

};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};