'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _RadioButtonGroupConnector = require('./reactComponents/RadioButtonGroupConnector');

var _RadioButtonGroupConnector2 = _interopRequireDefault(_RadioButtonGroupConnector);

var _RadioButtonGroup = require('./reactComponents/RadioButtonGroup');

var _RadioButtonGroup2 = _interopRequireDefault(_RadioButtonGroup);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var fieldOptionsMixin = require('@rubyapps/ruby-component-mixin-field-options');

var RCRadioButtonGroup = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin, fieldOptionsMixin],
    propTypes: _extends({}, _RadioButtonGroup2.default.propTypes),
    componentName: componentName,
    dependencies: function dependencies() {
        var selfSelector = this.getDefaultSelector();

        return {
            selfSelector: selfSelector
        };
    },
    getReactClass: function getReactClass() {
        return _RadioButtonGroupConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var RadioButtonGroupComponent = this.getReactClass();

        return React.createElement(RadioButtonGroupComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'RadioButtonGroupComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'RadioButtonGroupComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'RadioButtonGroupComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

    //== OVERRIDES ======================//
    //# TODO: move to mixin-field-options in newer versions
    , sampleValueFromFieldSpecs: function sampleValueFromFieldSpecs() {
        return require('@rubyapps/ruby-faker').helpers.randomize(this.props.options).value;
    }

});

module.exports = RCRadioButtonGroup;