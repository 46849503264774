'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var rubyLogger = require('@rubyapps/ruby-logger');
var logger = rubyLogger.getLogger('ruby-redux-manager:logger-middleware');

var loggerMiddleware = function loggerMiddleware(store) {
    return function (next) {
        return function (action) {
            logger.debug('dispatching action:', action.type, action.payload);
            console.info('dispatching action:', action.type, action.payload, typeof action == 'function' ? action : undefined);
            var result = next(action);
            logger.debug({ 'next state': store.getState() });
            return result;
        };
    };
};

exports.default = loggerMiddleware;