'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = KeyToHumanPrettifierGenerator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var rubyWords = require('@rubyapps/ruby-words');

function KeyToHumanPrettifierGenerator() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _options$keyToHumanPr = options.keyToHumanPrettyMap,
        keyToHumanPrettyMap = _options$keyToHumanPr === undefined ? {} : _options$keyToHumanPr;


    return function KeyToHumanPrettifier(props) {
        var _React$createElement;

        //# check if there's an available keyToHumanMap first
        //# then default to using humanize
        var data = _lodash2.default.get(props, 'data', '') || '';
        var prettifiedData = keyToHumanPrettyMap.hasOwnProperty(data) ? keyToHumanPrettyMap[data] : rubyWords.inflection.humanize(data);
        return _react2.default.createElement(
            'span',
            (_React$createElement = {
                'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'KeyToHumanPrettifierGenerator'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'KeyToHumanPrettifierGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'KeyToHumanPrettifierGenerator'), _React$createElement),
            prettifiedData
        );
    };
}