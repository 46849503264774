'use strict';

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var request = require('@rubyapps/ruby-superagent');


var rubyLogger = require('@rubyapps/ruby-logger');
var logger = rubyLogger.getLogger('ruby-component-forms:client/action');

var TYPES = {
    OVERRIDE_FORM_PROPS: '@@ruby-app/forms/OVERRIDE_FORM_PROPS',
    SET_TEMPLATE: '@@ruby-app/forms/SET_TEMPLATE',
    SET_TEMPLATES: '@@ruby-app/forms/SET_TEMPLATES'
};

//# fetch-form gets the expanded forms
//# we should hit a different endpoint to get the expanded forms
//# templates/fetch-forms //# returns the entire list
var FETCH_TEMPLATES_URL = '/ruby/api/v2/templates/fetch-forms/';
var FETCH_TEMPLATE_URL = '/ruby/api/v2/templates/fetch-form/';
var TEMPLATE_POLLING_INTERVAL = 1000 * 60 * 1; //# ms * sec * min; //# every 1 minute;

var generators = {
    overrideProps_withConfig: function overrideProps_withConfig(config) {
        return {
            type: TYPES.OVERRIDE_FORM_PROPS,
            payload: {
                config: config
            }
        };
    },
    pollTemplates: function pollTemplates() {
        var selfModule = this;
        var selfActions = selfModule.getAction().generators;

        return function (dispatch, getState) {
            var previousTemplates = selfModule.getState();
            dispatch(selfActions.retrieveTemplates()).then(function () {
                var activeTemplateKey = selfModule.getActiveTemplateKey();
                var previousTemplate = previousTemplates[activeTemplateKey];
                if (selfModule.activeTemplatesAreDifferent_fromPrevState(previousTemplates)) {
                    selfModule.openTemplateChangedDialog_forTemplate(previousTemplate);
                }
                setTimeout(function () {
                    dispatch(selfActions.pollTemplates());
                }, TEMPLATE_POLLING_INTERVAL);
            });
        };
    },
    retrieveTemplates: function retrieveTemplates() {
        var self = this;
        var selfActions = self.getAction().generators;

        return function (dispatch, getState) {
            return request.get(FETCH_TEMPLATES_URL).then(function (response) {
                var templatesArr = _lodash2.default.get(response, ['body']);
                var templatesByKey = _lodash2.default.keyBy(templatesArr, 'key');

                //# hacky
                self._retrievedTemplates = true; //# must set this before update the state

                dispatch(generators.setTemplates(templatesByKey));
            }).catch(function (err) {
                if (_lodash2.default.get(err, 'response.status') != 401) {
                    //# only show the error for non-auth related errors
                    logger.warn("Encountered error while retrieveing templates", err.stack);
                    self.showErrorNotification({ error: err });
                }
            });
        };
    },
    retrieveTemplate_forTemplateKey: function retrieveTemplate_forTemplateKey(templateKey) {
        var self = this;
        var selfActions = self.getAction().generators;

        return function (dispatch, getState) {
            return request.get(FETCH_TEMPLATE_URL + templateKey).then(function success(response) {
                dispatch(generators.setTemplate_atKey(response.body, templateKey));
            }, function error(err) {
                //# don't show error, just redirect if necessary
                if (err.response.status != 401) {
                    //# only show the error for non-auth related errors
                    self.showErrorNotification({ error: err });
                }
            });
        };
    },
    setTemplates: function setTemplates(templates) {
        return {
            type: TYPES.SET_TEMPLATES,
            payload: {
                templates: templates
            }
        };
    },
    setTemplate_atKey: function setTemplate_atKey(data, key) {
        return {
            type: TYPES.SET_TEMPLATE,
            payload: {
                data: data,
                key: key
            }
        };
    }
};

module.exports = {
    TYPES: TYPES,
    generators: generators
};