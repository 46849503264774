'use strict';

var _ = require('lodash');

/*
    example: 
    {
        childrenPropsByKey: {
            keyA: {}
            , keyB: {}
            , keyC: {}
        }
    }
    keyPath = "childrenPropsByKey"
    we expect childrenPropsByKey.* to be merged
*/

function twoDepthMergedPropsFromArr_forKey(arrOfProps, key) {
    var selectedPropsArr = arrOfProps.reduce(function (collector, prop) {
        var selectedProp = _.get(prop, key);
        if (selectedProp) {
            collector.push(selectedProp);
        }
        return collector;
    }, [{}]);
    if (selectedPropsArr.length == 1) {
        return undefined;
    }

    var mergedProps = Object.assign.apply(Object, selectedPropsArr);
    var allChildrenKeys = Object.keys(mergedProps);

    var twoDepthMergedProps = {};
    allChildrenKeys.forEach(function (childKey) {
        var mergedChild = _shallowMergedPropsFromArr_forKey(selectedPropsArr, childKey);
        if (mergedChild) {
            twoDepthMergedProps[childKey] = mergedChild;
        }
    });

    return twoDepthMergedProps;
}

function _shallowMergedPropsFromArr_forKey(arrOfProps, key) {
    var selectedPropsArr = arrOfProps.reduce(function (collector, prop) {
        var selectedProp = _.get(prop, key);
        if (selectedProp) {
            collector.push(selectedProp);
        }
        return collector;
    }, [{}]);

    if (selectedPropsArr.length == 1) {
        return undefined;
    }

    var mergedProps = Object.assign.apply(Object, selectedPropsArr);

    return mergedProps;
}

module.exports = {
    twoDepthMergedPropsFromArr_forKey: twoDepthMergedPropsFromArr_forKey
};