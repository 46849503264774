'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.icons = exports.classNames = exports.griddleClassNames = exports.griddleStyles = exports.inlineStyles = undefined;

var _column;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.getStyle = getStyle;
exports.getClassName = getClassName;
exports.getAssignedStyles = getAssignedStyles;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _arrowDropUp = require('material-ui/svg-icons/navigation/arrow-drop-up');

var _arrowDropUp2 = _interopRequireDefault(_arrowDropUp);

var _arrowDropDown = require('material-ui/svg-icons/navigation/arrow-drop-down');

var _arrowDropDown2 = _interopRequireDefault(_arrowDropDown);

var _defaultStyles = require('./defaultStyles.cssModule');

var _defaultStyles2 = _interopRequireDefault(_defaultStyles);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//This is a method that the individual components will use to interact with the inline styles
//
// styleName: the name of the inline style
// styles: the inline styles object
// useStyles: whether or not the inline styles should be used
// mergeStyles: styles to apply in addition to the inline styling. This is usually applied with some logic in the front-end
function getStyle(_ref) {
  var styleName = _ref.styleName,
      styles = _ref.styles,
      _ref$mergeStyles = _ref.mergeStyles,
      mergeStyles = _ref$mergeStyles === undefined ? null : _ref$mergeStyles;

  if (styles.hasOwnProperty(styleName)) {
    return _extends({}, styles[styleName], mergeStyles);
  }

  return mergeStyles || null;
}

function getClassName(_ref2) {
  var section = _ref2.section,
      classNames = _ref2.classNames;

  if (classNames.hasOwnProperty(section)) {
    return classNames[section];
  }

  return null;
}

var defaultColumnPadding = 10;
var inlineStyles = exports.inlineStyles = {
  settingsToggle: {},
  filter: {},
  columnTitle: {},
  column: {
    boxSizing: 'border-box',
    minHeight: 49,
    display: 'flex',
    alignItems: 'center',
    marginLeft: defaultColumnPadding,
    paddingRight: defaultColumnPadding,
    marginRight: -defaultColumnPadding,
    wordBreak: 'break-word'
  },
  pagination: {},
  table: {},
  fixedTable: {
    tableLayout: 'fixed'
  }
};

var griddleStyles = exports.griddleStyles = {
  settingsToggle: {
    background: 'none',
    border: 'none',
    padding: 0,
    margin: 0,
    fontSize: 14,
    width: '50%',
    textAlign: 'right'
  },

  filter: {
    width: '50%',
    textAlign: 'left',
    color: '#222',
    minHeight: '1px'
  },

  columnTitle: {
    backgroundColor: '#EDEDEF',
    border: '0',
    borderBottom: '1px solid #DDD',
    color: '#222',
    padding: '5px',
    cursor: 'pointer'
  },

  column: (_column = {
    margin: '0',
    padding: '5px 5px 5px 5px',
    backgroundColor: '#FFF',
    borderTopColor: '#DDD',
    color: '#222',
    display: 'inline-block',
    boxSizing: 'border-box',
    minHeight: 49
  }, _defineProperty(_column, 'display', 'flex'), _defineProperty(_column, 'alignItems', 'center'), _column),

  pagination: {
    'padding': 5,
    border: '0',
    backgroundColor: '#EDEDED',
    color: '#222',
    width: '100%',
    textAlign: 'center'
  },

  table: {
    width: '100%'
  },

  fixedTable: {
    tableLayout: 'fixed'
  }
};

var griddleClassNames = exports.griddleClassNames = {
  column: 'griddle-column',
  filter: 'griddle-filter',
  noResults: 'griddle-noResults',
  loading: 'griddle-loadingResults',
  pagination: 'griddle-pagination',
  rowDefinition: 'griddle-row-definition',
  row: 'griddle-row',
  settingsToggle: 'griddle-settings-toggle',
  settings: 'griddle-settings',
  tableBody: 'griddle-table-body',
  tableHeading: 'griddle-table-heading',
  tableHeadingCell: 'griddle-table-heading-cell',
  table: 'griddle-table'
};

var classNames = exports.classNames = {
  column: null,
  filter: null,
  noResults: null,
  loading: null,
  pagination: null,
  rowDefinition: null,
  row: null,
  settingsToggle: null,
  settings: null,
  tableBody: null,
  tableHeading: null,
  tableHeadingCell: null,
  table: null
};

var icons = exports.icons = {
  //TODO: these need to get moved to something that adds these on as part of the subgrid 'plugin'
  parentRowCollapsed: _react2.default.createElement(_arrowDropDown2.default, { className: _defaultStyles2.default.ArrowDrop_collapsed, 'data-codecept-selector-node': 'ArrowDropDownIcon',
    'data-codecept-selector-file': 'defaultStyles'
  }),
  parentRowExpanded: _react2.default.createElement(_arrowDropDown2.default, { className: _defaultStyles2.default.ArrowDrop_expanded, 'data-codecept-selector-node': 'ArrowDropDownIcon',
    'data-codecept-selector-file': 'defaultStyles'
  }),
  sortDescending: _react2.default.createElement(_arrowDropDown2.default, { className: _defaultStyles2.default.ArrowDrop, 'data-codecept-selector-node': 'ArrowDropDownIcon',
    'data-codecept-selector-file': 'defaultStyles'
  }),
  sortAscending: _react2.default.createElement(_arrowDropUp2.default, { className: _defaultStyles2.default.ArrowDrop, 'data-codecept-selector-node': 'ArrowDropUpIcon',
    'data-codecept-selector-file': 'defaultStyles'
  })
};

function getAssignedStyles(extension, useGriddleStyles, props) {
  var styles = {
    inlineStyles: useGriddleStyles ? _extends({}, inlineStyles, griddleStyles) : inlineStyles,
    classNames: classNames,
    icons: icons,
    getStyle: getStyle,
    getClassName: getClassName
  };

  if (extension) {
    if (extension.hasOwnProperty('inlineStyles')) {
      styles.inlineStyles = _extends({}, styles.inlineStyles, extension.inlineStyles);
    }

    if (extension.hasOwnProperty('classNames')) {
      styles.classNames = _extends({}, styles.classNames, extension.classNames);
    }

    if (extension.hasOwnProperty('icons')) {
      styles.icons = _extends({}, icons, extension.icons);
    }
  }

  return styles;
}