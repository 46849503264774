"use strict";

var condense = {

    toggle: function toggle(elem) {
        //toggle plus and minus
        elem.toggleClass('open');

        //hide and show content
        var target = elem.attr("id");
        target = target.replace("trig", "targ");
        $("#" + target, elem.parent().parent()).toggle();
    },

    init: function init(elem) {
        $('.tinyMCE_Condense', elem).hide();
        $('span.trig', elem).removeClass("open");

        //initialize trigger
        $('span.trig', elem).click(function () {
            condense.toggle($(this));
        });
    },

    addExpandAllText: function addExpandAllText() {
        if ($(".headline").length == 1) {
            expandText = $('<a href="#" class="expand-text">Expand All</a>');
            expandText.appendTo($(".headline:eq(0)"));
            expandText.click(function () {
                if ($(this).text() == "Expand All") {
                    condense.expandAll();
                    $(this).text("Collapse All");
                } else {
                    $(this).text("Expand All");
                    condense.collapseAll();
                }
                return false;
            });
        }
    },

    collapseAll: function collapseAll() {

        $('.tinyMCE_Condense').hide();
        $('span.trig').removeClass("open");
    },

    expandAll: function expandAll() {

        $('.tinyMCE_Condense').show();
        $('span.trig').addClass("open");
    }
};

window.condense = condense; //# need to expose

$("document").ready(function () {
    condense.init();
    if ($(".trig").length >= 1) {
        condense.addExpandAllText();
    }
});