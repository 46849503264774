'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reduxBatchedActions = require('redux-batched-actions');

var _optionsUrl__extractParams = require('../../../ruby-form-js-to-json-schema/src/common/optionsUrl__extractParams.js');

var _optionsUrl__extractParams2 = _interopRequireDefault(_optionsUrl__extractParams);

var _FieldsPickerConnector = require('./reactComponents/FieldsPickerConnector');

var _FieldsPickerConnector2 = _interopRequireDefault(_FieldsPickerConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Promise = require('bluebird');
var React = require('react');

var _ = require('lodash');

var FieldForm = require('@rubyapps/ruby-component-field-form');
var RubyComponent = require('@rubyapps/ruby-component');
var rubyWords = require('@rubyapps/ruby-words');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var accessControl = require('@rubyapps/ruby-component-mixin-access-control');
var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var fieldLeafNodeMixin = require('@rubyapps/ruby-component-mixin-field-forced-leaf-node');
var RubyComponentCurrentUser = require('@rubyapps/ruby-component-current-user');

var action = require('./action');
var reducer = require('./reducer');
var fieldSpecsTree_fromFormChildren = require('./fieldSpecsTree');

var RubyComponentForms__CONSTANTS = require('@rubyapps/ruby-component-forms/src/common/constants');

var _require = require('@rubyapps/ruby-component-plugin-template-editor/src/common/constants'),
    PROFILE_TEMPLATE_TYPE = _require.PROFILE_TEMPLATE;

var RCFieldsPicker = RubyComponent.createClass({
    mixins: [accessControl, baseFieldMixin, fieldValidationMixin, fieldPropsMixin, fieldLeafNodeMixin],
    action: action,
    reducer: reducer,
    propTypes: {
        keyForReferenceComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf([PropTypes.string])])
    },
    getDefaultProps: function getDefaultProps() {
        return {
            keyForReferenceComponent: 'export_template',
            formsID: RubyComponentForms__CONSTANTS.COMPONENT_NAME
        };
    },
    onReduxInit: function onReduxInit(dispatch) {
        var _this = this;

        var store = this.getStore();

        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector;

        return store.subscribe(function () {
            var selfState = _this.getState();
            var referencedComponent = _this.getReferencedComponent();
            var currRootTemplateKey = referencedComponent.coercedValue(referencedComponent.getFieldValue(), true);

            _this.reinitTogglesByIdSpecStateForTemplateKey(currRootTemplateKey);
        });
    },
    getInitialState: function getInitialState() {
        var initialState = _extends({}, baseFieldMixin.getInitialState.apply(this), fieldValidationMixin.getInitialState.apply(this), {
            togglesById: {},
            toggleTree: []
        });

        return initialState;
    },
    componentName: componentName,
    dependencies: function dependencies() {
        var selfSelector = this.getSelfStateSelector();
        var parentForm = this.findAncestorBy(function (module) {
            return module.componentName == FieldForm.componentName;
        });

        var rootModule = this.getRoot();
        var formsComponent = rootModule.findDescendentByID(this.props.formsID);

        return {
            selfSelector: selfSelector,
            parentForm: parentForm,
            formsComponent: formsComponent
        };
    },
    statesSelector: function statesSelector(state) {
        var _getDependencies2 = this.getDependencies(),
            selfSelector = _getDependencies2.selfSelector;

        return {
            self: selfSelector(state),
            fieldProps: this.fieldProps_fromState_andOwnProps(state, this.props)
        };
    },
    getReactClass: function getReactClass() {
        return _FieldsPickerConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var FieldsPickerComponent = this.getReactClass();

        return React.createElement(FieldsPickerComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'FieldsPickerComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'FieldsPickerComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'FieldsPickerComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    _formValueFromLocalState: function _formValueFromLocalState(selfState, isError, predicateFormatter, entireState) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
        var _selfState$toggleTree = selfState.toggleTree,
            toggleTree = _selfState$toggleTree === undefined ? [] : _selfState$toggleTree,
            _selfState$togglesByI = selfState.togglesById,
            togglesById = _selfState$togglesByI === undefined ? {} : _selfState$togglesByI;

        if (_.isEmpty(toggleTree) && _.isEmpty(togglesById)) {
            return {};
        }

        var formValue = this.formValueFromToggleTree_usingTogglesById(toggleTree, togglesById);
        var selfKey = this.props.key;

        return _defineProperty({}, selfKey, _.isEmpty(formValue.fields) ? null : formValue);
    },
    _formValueToLocalState: function _formValueToLocalState(formValue, dispatch, isError, entireFormValue, options) {
        var selfKey = this.props.key;

        if (isError) {
            return baseFieldMixin._formValueToLocalState.apply(this, arguments);
        }

        var keyForReferenceComponent = this.props.keyForReferenceComponent;


        var fieldValue = formValue[selfKey];

        var referencedComponent = this.getReferencedComponent();
        var currentRootTemplateKey = referencedComponent.coercedValue(referencedComponent.getFieldValue(), true);
        var hydratedRootTemplateKey = formValue[keyForReferenceComponent] || referencedComponent.formValue()[keyForReferenceComponent];

        if (currentRootTemplateKey == hydratedRootTemplateKey) {

            var actionCollector = [];
            var collectAction = function collectAction(value) {
                actionCollector.push(value);
            };

            //# there's no guarantee that the togglesById state has been initialized by this point
            //# so we got to do it
            this.reinitTogglesByIdSpecStateForTemplateKey(currentRootTemplateKey, collectAction, { force: true });

            //# NOTE: this is not used
            this.setStatefulCacheForKey('_hydratedRootTemplateKey', formValue[this.props.keyForReferenceComponent]);

            collectAction(this.getAction().generators.setTogglesByIdUsingFieldValue(fieldValue));

            dispatch((0, _reduxBatchedActions.batchActions)(actionCollector));
        }

        return Promise.resolve(null);
    }

    //# HELPER METHODS
    , getReferencedComponent: function getReferencedComponent() {
        if (this._cachedReferencedComponent) {
            return this._cachedReferencedComponent;
        }

        var _getDependencies3 = this.getDependencies(),
            parentForm = _getDependencies3.parentForm;

        var keyForReferenceComponent = this.props.keyForReferenceComponent;


        var normalizedKeysForReferenceComponents = keyForReferenceComponent ? _.castArray(keyForReferenceComponent) : [];

        var referencedComponents = normalizedKeysForReferenceComponents.map(function (key) {
            return parentForm.findDescendentBy(function (module) {
                return module.props.key === key;
            });
        });

        var wantedReferencedComponent = referencedComponents[0];

        this._cachedReferencedComponent = wantedReferencedComponent;
        return wantedReferencedComponent;
    }

    //# initial state based on selected template key
    , reinitTogglesByIdSpecStateForTemplateKey: function reinitTogglesByIdSpecStateForTemplateKey(targetRootTemplateKey, dispatchOrCollect) {
        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        var force = options.force;


        if (!dispatchOrCollect) {
            dispatchOrCollect = this.getStore().dispatch;
        }
        var prevRootTemplateKey = this.getStatefulCacheForKey('rootTemplateKey');

        if (force || prevRootTemplateKey !== targetRootTemplateKey) {
            this.setStatefulCacheForKey('rootTemplateKey', targetRootTemplateKey);

            var templateData = this.getTemplateForKey(targetRootTemplateKey);
            var hydratedToggleTree = this.hydrateToggleTree_usingTemplateData(templateData, { selfModule: this });

            return dispatchOrCollect(this.getAction().generators.initTogglesByIdUsingHydratedToggleTree(hydratedToggleTree));
        }
    },

    formValueFromToggleTree_usingTogglesById: function formValueFromToggleTree_usingTogglesById(toggleTree, togglesById) {
        var formValue = {
            expand: this.expandConfigFromToggleTree_usingTogglesById(toggleTree, togglesById),
            fields: this.keyPathsFromToggleTree_usingTogglesById(toggleTree, togglesById)
        };

        return formValue;
    },

    expandConfigFromToggleTree_usingTogglesById: function expandConfigFromToggleTree_usingTogglesById(toggleTree, togglesById) {
        var _this2 = this;

        var expandConfig = _.reduce(toggleTree, function (acc, treeNode) {
            var id = treeNode.id,
                _treeNode$children = treeNode.children,
                children = _treeNode$children === undefined ? [] : _treeNode$children,
                _treeNode$expand = treeNode.expand,
                expand = _treeNode$expand === undefined ? false : _treeNode$expand;

            var toggleData = togglesById[id];
            var _toggleData$toggled = toggleData.toggled,
                toggled = _toggleData$toggled === undefined ? false : _toggleData$toggled;


            var keyPath = _this2.keyPathFromNode(treeNode);

            if (!toggled || !keyPath && _.isEmpty(children)) {
                return acc;
            }

            return expand ? acc.concat(_defineProperty({}, keyPath, {
                fields: _this2.keyPathsFromToggleTree_usingTogglesById(children, togglesById),
                expand: _this2.expandConfigFromToggleTree_usingTogglesById(children, togglesById)
            })) : acc.concat(_this2.expandConfigFromToggleTree_usingTogglesById(children, togglesById));
        }, []);

        return expandConfig;
    },

    keyPathsFromToggleTree_usingTogglesById: function keyPathsFromToggleTree_usingTogglesById(toggleTree, togglesById) {
        var _this3 = this;

        var fields = _.reduce(toggleTree, function (acc, treeNode) {
            var id = treeNode.id,
                _treeNode$children2 = treeNode.children,
                children = _treeNode$children2 === undefined ? [] : _treeNode$children2,
                _treeNode$expand2 = treeNode.expand,
                expand = _treeNode$expand2 === undefined ? false : _treeNode$expand2;

            var toggleData = togglesById[id];
            var _toggleData$toggled2 = toggleData.toggled,
                toggled = _toggleData$toggled2 === undefined ? false : _toggleData$toggled2;


            if (!toggled) {
                return acc;
            }

            var keyPath = _this3.keyPathFromNode(treeNode);

            return keyPath ? acc.concat(keyPath, expand === false ? _this3.keyPathsFromToggleTree_usingTogglesById(children, togglesById) : []) : acc.concat(_this3.keyPathsFromToggleTree_usingTogglesById(children, togglesById));
        }, []);

        return fields;
    },

    hydrateToggleTree_usingTemplateData: function hydrateToggleTree_usingTemplateData() {
        var templateData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var ctx = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var _templateData$form = templateData.form,
            form = _templateData$form === undefined ? {} : _templateData$form;
        var _form$children = form.children,
            children = _form$children === undefined ? [] : _form$children;


        var fieldSpecsTree = fieldSpecsTree_fromFormChildren(children, ctx);
        var hydratedToggleTree = this.hydrateToggleTree_fromFieldSpecsTree(fieldSpecsTree, ctx);
        return hydratedToggleTree;
    },

    hydrateToggleTree_fromFieldSpecsTree: function hydrateToggleTree_fromFieldSpecsTree() {
        var _this4 = this;

        var fieldSpecsTree = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        var ctx = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var _ctx$pathIdArr = ctx.pathIdArr,
            pathIdArr = _ctx$pathIdArr === undefined ? [] : _ctx$pathIdArr;


        var toggleTree = _.reduce(fieldSpecsTree, function (acc, fieldSpec, idx) {
            var componentName = fieldSpec.componentName,
                _fieldSpec$children = fieldSpec.children,
                children = _fieldSpec$children === undefined ? [] : _fieldSpec$children;

            var idPartial = _this4.idPartialForFieldSpec_usingArrIdx(fieldSpec, idx);

            return acc.concat(componentName === 'NamespaceSelector' ? _this4.hydrateToggleTree_fromFieldSpecsTree(children, ctx) : _this4.hydratedToggleData_fromFieldSpec(fieldSpec, _extends({}, ctx, { pathIdArr: pathIdArr.concat(idPartial) })));
        }, []);

        return toggleTree;
    },

    hydratedToggleData_fromFieldSpec: function hydratedToggleData_fromFieldSpec() {
        var fieldSpec = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var ctx = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var _fieldSpec$children2 = fieldSpec.children,
            children = _fieldSpec$children2 === undefined ? [] : _fieldSpec$children2,
            componentName = fieldSpec.componentName,
            label = fieldSpec.label,
            fieldKey = fieldSpec.key,
            _fieldSpec$dataPath = fieldSpec.dataPath,
            dataPath = _fieldSpec$dataPath === undefined ? "" : _fieldSpec$dataPath,
            url = fieldSpec.url;
        var _ctx$pathIdArr2 = ctx.pathIdArr,
            pathIdArr = _ctx$pathIdArr2 === undefined ? [] : _ctx$pathIdArr2,
            _ctx$dataPathArr = ctx.dataPathArr,
            dataPathArr = _ctx$dataPathArr === undefined ? [] : _ctx$dataPathArr,
            expansionOf = ctx.expansionOf;


        var shouldExpandFieldSpec = _.get(fieldSpec, 'forComponent.FieldsPicker.expand') || false;

        var childrenValue = void 0;

        if (!_.isEmpty(children)) {
            childrenValue = this.hydrateToggleTree_fromFieldSpecsTree(children, ctx);
        } else if (componentName === 'Repeater' && fieldSpec.url) {
            //# NOTE: We're assuming that if the component is a `Repeater` and has `url`
            //# then it's a profile repeater
            childrenValue = this.hydratedToggleData_fromProfileRepeaterSpec(fieldSpec, ctx);
        } else if (shouldExpandFieldSpec) {
            childrenValue = this.hydratedToggleData_fromExpandableFieldSpec(fieldSpec, ctx);
        } else {
            childrenValue = [];
        }

        var hydratedToggleData = _extends({}, fieldSpec, {
            title: this.titleFromFieldSpec(fieldSpec),
            children: childrenValue,
            id: pathIdArr.join('.'),
            parentId: pathIdArr.length > 1 ? _.initial(pathIdArr).join('.') : undefined,
            fieldKey: fieldKey,
            keyPath: dataPath ? dataPathArr.concat(dataPath).join('.') : fieldKey || "",
            expand: shouldExpandFieldSpec,
            expansionOf: expansionOf
        });

        return hydratedToggleData;
    },

    hydratedToggleData_fromProfileRepeaterSpec: function hydratedToggleData_fromProfileRepeaterSpec(fieldSpec) {
        var _this5 = this;

        var ctx = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var url = fieldSpec.url,
            dataPath = fieldSpec.dataPath,
            title = fieldSpec.label;
        var _ctx$pathIdArr3 = ctx.pathIdArr,
            pathIdArr = _ctx$pathIdArr3 === undefined ? [] : _ctx$pathIdArr3,
            _ctx$dataPathArr2 = ctx.dataPathArr,
            dataPathArr = _ctx$dataPathArr2 === undefined ? [] : _ctx$dataPathArr2;


        var formState = this.getDependencies().formsComponent.getState();
        var profileTemplateDataFromFormState = _.filter(formState, function (value, key) {
            return value.templateType === PROFILE_TEMPLATE_TYPE;
        });

        return _.flatMap(profileTemplateDataFromFormState, function (profileTemplateData) {
            return _this5.hydrateToggleTree_usingTemplateData(profileTemplateData, _extends({}, ctx, { dataPathArr: dataPathArr.concat([dataPath, '*', profileTemplateData.key]) }));
        });
    },

    hydratedToggleData_fromExpandableFieldSpec: function hydratedToggleData_fromExpandableFieldSpec(fieldSpec) {
        var _this6 = this;

        var ctx = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var dataPath = fieldSpec.dataPath,
            url = fieldSpec.url;
        var _ctx$dataPathArr3 = ctx.dataPathArr,
            dataPathArr = _ctx$dataPathArr3 === undefined ? [] : _ctx$dataPathArr3;

        var expand = _.get(fieldSpec, 'forComponent.FieldsPicker.expand') || false;
        var modelInfo = expand && url && (0, _optionsUrl__extractParams2.default)(url);
        var templateKeys = modelInfo ? _.castArray(modelInfo.template) : [];

        return _.flatMap(templateKeys, function (templateKey) {
            var templateData = _this6.getTemplateForKey(templateKey);
            return _this6.hydrateToggleTree_usingTemplateData(templateData, _extends({}, ctx, {
                expansionOf: dataPathArr.concat(dataPath).join('.'),
                dataPathArr: dataPathArr.concat(dataPath),
                templateKey: templateKey
            }));
        });
    },

    titleFromFieldSpec: function titleFromFieldSpec(fieldSpec) {
        var label = fieldSpec.label,
            componentName = fieldSpec.componentName,
            key = fieldSpec.key;


        if (label) {
            return label;
        }

        var templateData = key && this.getTemplateForKey(key);

        return templateData ? templateData.name : componentName;
    },

    idPartialForFieldSpec_usingArrIdx: function idPartialForFieldSpec_usingArrIdx(fieldSpec, arrIdx) {
        var componentName = fieldSpec.componentName,
            key = fieldSpec.key;

        return _.filter([arrIdx, componentName, key], function (part) {
            return _.isInteger(part) || _.identity(part);
        }).map(function (part) {
            return rubyWords.keyify(part);
        }).join('.');
    },

    keyPathFromNode: function keyPathFromNode(node) {
        var _node$expansionOf = node.expansionOf,
            expansionOf = _node$expansionOf === undefined ? "" : _node$expansionOf,
            expandedKeyPath = node.keyPath;

        var expansionOfParts = expansionOf.split('.');
        var expandedKeyPathParts = expandedKeyPath.split('.');

        var keyPath = expandedKeyPathParts.reduce(function (acc, part, idx) {
            return part === expansionOfParts[idx] ? acc : acc.concat(part);
        }, []).join('.');

        return keyPath;
    }
});

module.exports = RCFieldsPicker;