'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = ImageFormatterGenerator;

var _styles = require('./styles.cssModule');

var _styles2 = _interopRequireDefault(_styles);

var _MediaModalThumbnail = require('@rubyapps/ruby-component-plugin-media-manager/src/local_modules/ruby-component-field-media-preview/src/client/reactComponents/MediaModalThumbnail.js');

var _MediaModalThumbnail2 = _interopRequireDefault(_MediaModalThumbnail);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');
var React = require('react');

//# TODO: figure out how to register griddle components from plugin. We don't want to assume the existence of the MediaViewer
var queryString = require('query-string');

var _require = require('@rubyapps/ruby-component-plugin-media-manager/src/common/constants'),
    CMS_THUMBNAIL_TYPES = _require.CMS_THUMBNAIL_TYPES;

var defaultOptions = {
    spacing: 12,
    thumbnailKeypath: ['thumbnailUrls', CMS_THUMBNAIL_TYPES.SMALL_PADDED],
    urlKeypath: ['url']
};
function ImageFormatterGenerator(options, parentProps) {
    var mergedOptions = _extends({}, defaultOptions, options);

    var spacing = mergedOptions.spacing,
        thumbnailKeypath = mergedOptions.thumbnailKeypath,
        urlKeypath = mergedOptions.urlKeypath;


    var hydrated_thumbnailKeypath = ['originalData'].concat(_.isArray(thumbnailKeypath) ? thumbnailKeypath : thumbnailKeypath.split('.'));
    var hydrated_urlKeypath = ['originalData'].concat(_.isArray(urlKeypath) ? urlKeypath : urlKeypath.split('.'));
    var routeParams = parentProps.routeParams;
    var _routeParams$query = routeParams.query,
        query = _routeParams$query === undefined ? {} : _routeParams$query;

    //# Allow us to forcefully rerender the thumbnails

    var stringifiedQuery = queryString.stringify(_.pick(query, ['force']));

    return function ImageFormatter(props) {
        var _extends2, _React$createElement, _React$createElement2;

        var url = _.get(props, hydrated_urlKeypath);
        var thumbnailUrl = _.get(props, hydrated_thumbnailKeypath);
        if (thumbnailUrl.indexOf('?') > 0) {
            thumbnailUrl += stringifiedQuery ? '&' + stringifiedQuery : '';
        } else {
            thumbnailUrl += stringifiedQuery ? '?' + stringifiedQuery : '';
        }

        return url ? React.createElement(
            'div',
            (_React$createElement2 = {
                style: {
                    height: 45 + spacing * 2,
                    display: 'flex',
                    alignItems: 'center'
                },
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'ImageFormatterGenerator'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ImageFormatterGenerator'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ImageFormatterGenerator'), _React$createElement2),
            React.createElement(
                'div',
                (_React$createElement = {
                    style: { width: 45, height: 45 },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'ImageFormatterGenerator'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ImageFormatterGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ImageFormatterGenerator'), _React$createElement),
                React.createElement(_MediaModalThumbnail2.default, _extends({
                    url: url,
                    thumbnailUrl: thumbnailUrl
                }, (_extends2 = {
                    className: _styles2.default.ImageFormatter,
                    'data-codecept-selector-node': 'MediaViewer',
                    'data-codecept-selector-file': 'ImageFormatterGenerator'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'MediaViewer'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ImageFormatterGenerator'), _defineProperty(_extends2, 'data-codecept-selector-node', 'MediaViewer'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ImageFormatterGenerator'), _extends2)))
            )
        ) : null;
    };
}