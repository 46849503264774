'use strict';

var _ = require('lodash');

var RubyComponentUtils = require('@rubyapps/ruby-component/src/common/utils');

function mergedChildrenPropsByKeyFromForm_andDefaultProps(formJsItem, defaultProps) {
    var default_childrenPropsByKey = defaultProps.childrenPropsByKey;
    var childrenPropsByKey = formJsItem.childrenPropsByKey;


    return RubyComponentUtils.twoDepthMergedPropsFromArr_forKey([defaultProps, formJsItem], 'childrenPropsByKey');
}

module.exports = {
    mergedChildrenPropsByKeyFromForm_andDefaultProps: mergedChildrenPropsByKeyFromForm_andDefaultProps
};