'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _InternationalAddress = require('./InternationalAddress');

var _InternationalAddress2 = _interopRequireDefault(_InternationalAddress);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector,
        latEl = _selfModule$getDepend.latEl,
        lngEl = _selfModule$getDepend.lngEl,
        feSettingsSelector = _selfModule$getDepend.feSettingsSelector,
        headerComponent = _selfModule$getDepend.headerComponent;

    var fieldKey = this.props.key;

    var showErrorNotification = selfModule.showErrorNotification.bind(selfModule);

    var prevErrorObject = void 0;
    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);

        var formValue = selfModule.internalFormValue(selfState);
        var feSettings = feSettingsSelector(state);

        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);

        var newErrorObject = selfModule.formError();

        // Might need to clear the state error
        if (!_lodash2.default.isEqual(newErrorObject, prevErrorObject)) {
            prevErrorObject = newErrorObject;
            selfModule.refreshParentErrors();
        }

        var GOOGLE_API_KEY = _lodash2.default.get(feSettings, 'RUBYAPPS_SETTINGS.google_api_key', _lodash2.default.get(feSettings, 'RUBY_GOOGLE_MAPS_API_KEY'));

        //# find settings url
        var settingsEditMenuSpec = GOOGLE_API_KEY ? null : headerComponent.getPermittedMenuSpecForId('cms-settings');

        var mappedProps = _extends({}, ownProps, mixinFieldProps, {
            id: selfModule.getID(),
            showErrorNotification: showErrorNotification,
            value: fieldKey ? formValue[fieldKey] : formValue,
            GOOGLE_API_KEY: GOOGLE_API_KEY,
            settingsEditMenuSpec: settingsEditMenuSpec
        });

        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch),
            delegateActions: {
                longitudeField: (0, _redux.bindActionCreators)(lngEl.getAction().generators, dispatch),
                latitudeField: (0, _redux.bindActionCreators)(latEl.getAction().generators, dispatch)
            }
        };
    }

    function mergeProps(stateProps, dispatchProps, ownProps) {
        return _extends({}, ownProps, stateProps, dispatchProps, {
            //# setup the delegate callbacks here whcih calls on dispatchProps.actions...
            delegateRouteChange: function delegateRouteChange(route, menuItemConfig) {
                if (menuItemConfig.url) {
                    if (menuItemConfig.newWindow) {
                        window.open(route);
                    } else {
                        window.location = route;
                    }
                } else {
                    dispatchProps.actions.navigateToPathWithOptions({ path: route });
                }
            }
        });
    }
    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(_InternationalAddress2.default);
}