'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _memoizee = require('memoizee');

var _memoizee2 = _interopRequireDefault(_memoizee);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var rubyAccessManager = require('@rubyapps/ruby-access-manager');
var RubyComponentCurrentUser = require('@rubyapps/ruby-component-current-user');

var ACCESS_CONSTANTS = rubyAccessManager.CONSTANTS;
var PERMISSION_STRINGS = ACCESS_CONSTANTS.PERMISSION_STRINGS;
var PERMISSION_ACTIONS = ACCESS_CONSTANTS.PERMISSION_ACTIONS;

function _doesUserHaveFieldPermission_forComponent(fieldPermissionKeyword, rubyComponent) {
    // Get the parent RubyComponent that defines the route
    // It should have the access-control mixin applied
    // Which provides the getActiveRubyClientId and getCurrentUserState methods
    var routeParent = rubyComponent.findAncestorBy(function (module) {
        return module.getRouteElement;
    });
    if (!routeParent) return false;
    if (!routeParent.getActiveRubyClientId) return false;
    if (!routeParent.getCurrentUserState) return false;

    // Given a ruby client id and user object
    var rubyClientId = routeParent.getActiveRubyClientId();
    var userObject = routeParent.getCurrentUserState();

    var fieldPerm = _lodash2.default.isPlainObject(fieldPermissionKeyword) ? fieldPermissionKeyword : _createFieldPermission(fieldPermissionKeyword, rubyClientId, PERMISSION_ACTIONS.EDIT);

    // Check to see if the user has publish permission
    // for the client/subsite
    return rubyAccessManager.doesUserHavePermission(userObject, fieldPerm);
}

// Memoize here, so that doesUserHavePermission memoize works
var _createFieldPermission = (0, _memoizee2.default)(_raw_createFieldPermission, { length: 3 });
function _raw_createFieldPermission(keyword, ruby_client, action) {
    return {
        keyword: keyword,
        ruby_client: ruby_client,
        action: action
    };
}

var fieldPropsMixin = {
    mixinName: 'rubyComponentMixinFieldProps',
    key: function key() {

        return this.props.key;

        var selfKey = [this.namespacePrefixKey(), this.props.key].filter(function (value) {
            return value;
        }).join('.');

        return selfKey;
    },
    statesSelector_fromState_andOwnProps: function statesSelector_fromState_andOwnProps(state, ownProps) {
        var _this = this;

        var fieldPerms = _lodash2.default.get(ownProps, 'ruby_permissions', []);
        var selfKey = this.key();
        var id = this.getID();

        var parentForm = this.getParentFormComponent();
        var canEditForm = parentForm ? parentForm.canEdit() : true;

        var isActiveForNamespace = this.isFieldActiveForNamespace_fromState ? this.isFieldActiveForNamespace_fromState(state) : true;
        var hidden = _lodash2.default.result(ownProps, 'hidden') || false;

        return {
            id: id,
            fieldKey: selfKey //key is not exposed to the react component
            , isActive: isActiveForNamespace && !hidden,
            locked: ownProps.hasOwnProperty('locked') && ownProps.locked == true ? ownProps.locked : !canEditForm ? true : fieldPerms.reduce(function (lockedFlag, perm) {
                return lockedFlag || !_this._doesUserHaveFieldPermission_forComponent(perm, _this);
            }, false)
        };
    },
    fieldProps_fromState_andOwnProps: function fieldProps_fromState_andOwnProps(state, ownProps) {
        var _boundGridWrapperRenderer = void 0;
        if (ownProps.renderGridWrapper) {
            _boundGridWrapperRenderer = ownProps.renderGridWrapper;
        } else {
            if (!this._boundGridWrapperRenderer) {
                this._boundGridWrapperRenderer = this.renderGridWrapper.bind(this);
            }
            _boundGridWrapperRenderer = this._boundGridWrapperRenderer;
        }

        var selfSelector = this.getDefaultSelector();
        var selfState = selfSelector(state);
        var isFieldInDefaultNamespace = this.isFieldInDefaultNamespace_fromState();

        var selectedState = fieldPropsMixin.statesSelector_fromState_andOwnProps.apply(this, arguments);
        var selfKey = selectedState.fieldKey;


        return _extends({
            key: selectedState.id //# use id instead of selfKey for the React element key
            , required: isFieldInDefaultNamespace ? _lodash2.default.get(selfState, ['fields', selfKey, 'constraints', 'required'], false) : false
            //# TODO: 20161221 NOTE: need a better way to handle excluding required due to namespaces
            , renderGridWrapper: _boundGridWrapperRenderer
        }, selectedState);
    },

    _doesUserHaveFieldPermission_forComponent: _doesUserHaveFieldPermission_forComponent
};

module.exports = fieldPropsMixin;