'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/*
 * A simple HOC that provides facility for listening to container resizes.
 */
var WidthProvider = function WidthProvider(ComposedComponent) {
  var _class, _temp2;

  return _temp2 = _class = function (_React$Component) {
    _inherits(_class, _React$Component);

    function _class() {
      var _ref;

      var _temp, _this, _ret;

      _classCallCheck(this, _class);

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class.__proto__ || Object.getPrototypeOf(_class)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
        width: 1280
      }, _this.mounted = false, _this.onWindowResize = function (_event) {
        if (!_this.mounted) return;
        var node = _reactDom2.default.findDOMNode(_this);
        _this.setState({ width: node.offsetWidth });
      }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(_class, [{
      key: 'componentDidMount',
      value: function componentDidMount() {
        this.mounted = true;

        window.addEventListener('resize', this.onWindowResize);
        // Call to properly set the breakpoint and resize the elements.
        // Note that if you're doing a full-width element, this can get a little wonky if a scrollbar
        // appears because of the grid. In that case, fire your own resize event, or set `overflow: scroll` on your body.
        this.onWindowResize();
      }
    }, {
      key: 'componentWillUnmount',
      value: function componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', this.onWindowResize);
      }
    }, {
      key: 'render',
      value: function render() {
        var _extends2;

        if (this.props.measureBeforeMount && !this.mounted) {
          var _React$createElement;

          return _react2.default.createElement('div', (_React$createElement = { className: this.props.className, style: this.props.style, 'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'WidthProvider'
          }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'WidthProvider'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'WidthProvider'), _React$createElement));
        }

        return _react2.default.createElement(ComposedComponent, _extends({}, this.props, this.state, (_extends2 = {
          'data-codecept-selector-node': 'ComposedComponent',
          'data-codecept-selector-file': 'WidthProvider'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'ComposedComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'WidthProvider'), _defineProperty(_extends2, 'data-codecept-selector-node', 'ComposedComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'WidthProvider'), _extends2)));
      }
    }]);

    return _class;
  }(_react2.default.Component), _class.defaultProps = {
    measureBeforeMount: false
  }, _class.propTypes = {
    // If true, will not render children until mounted. Useful for getting the exact width before
    // rendering, to prevent any unsightly resizing.
    measureBeforeMount: _react2.default.PropTypes.bool
  }, _temp2;
};

exports.default = WidthProvider;