"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  useGriddleStyles: true,
  useGriddleClassNames: false,
  useFixedTable: true,
  pageSize: 10
};