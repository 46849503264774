'use strict';

function typesWithID(id) {
    return {
        EXPAND: '@@ruby-app/' + id + '/EXPAND_FIELDSET',
        COLLAPSE: '@@ruby-app/' + id + '/COLLAPSE_FIELDSET',
        EXPAND_ALL: '@@ruby-app/' + id + '/EXPAND_ALL_FIELDSETS',
        COLLAPSE_ALL: '@@ruby-app/' + id + '/COLLAPSE_ALL_FIELDSETS'
    };
}

function getAllFieldsetsReachableByElement(element) {
    var tabElement = element.findAncestorBy(function (rcElement) {
        return rcElement.componentName == 'rubyComponentFieldTab';
    });

    var allFieldsetElements = tabElement.findDescendentsBy(function (rcElement) {
        return rcElement.componentName == 'rubyComponentFieldFieldset';
    });

    return allFieldsetElements;
}

function getSiblingFieldsetsReachableByElement(element) {
    var parentElement = element.getParent();

    var siblingFieldsetElements = parentElement.findChildrenBy(function (rcElement) {
        return rcElement.componentName == 'rubyComponentFieldFieldset';
    });

    return siblingFieldsetElements;
}

var generators = {
    _expand: function _expand() {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        return {
            type: TYPES.EXPAND
        };
    },
    _collapse: function _collapse() {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES;

        return {
            type: TYPES.COLLAPSE
        };
    },
    expand: function expand() {
        var actions = this.getAction().generators;

        return function (dispatch) {
            dispatch(actions._expand());
            dispatch(actions.set_visible());
        };
    },
    collapse: function collapse() {
        var _this = this;

        var actions = this.getAction().generators;

        return function (dispatch) {
            dispatch(_this.getAction().generators._collapse());
            dispatch(_this.getAction().generators.unset_visible());
        };
    },
    expandAll: function expandAll() {
        var allFieldsetElements = getSiblingFieldsetsReachableByElement(this);
        return function (dispatch) {
            allFieldsetElements.forEach(function (fieldsetElement) {
                dispatch(fieldsetElement.getAction().generators.expand());
            });
        };
    },
    collapseAll: function collapseAll() {
        var allFieldsetElements = getSiblingFieldsetsReachableByElement(this);
        return function (dispatch) {
            allFieldsetElements.forEach(function (fieldsetElement) {
                dispatch(fieldsetElement.getAction().generators.collapse());
            });
        };
    }

    //# caching formValue if children not intitialized
};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};