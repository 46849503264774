'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var path = require('path');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

var PropTypes = _react2.default.PropTypes;

function addInfoModeProps(Component) {
    return _react2.default.createClass({
        propTypes: {
            mode: PropTypes.string,
            toHTML: PropTypes.func //# (selfModule, data) => {} 
        },
        contextTypes: {
            muiTheme: PropTypes.object.isRequired
        },
        getDefaultProps: function getDefaultProps() {
            return {
                toHTML: _lodash2.default.identity
            };
        },
        render: function render() {
            var _extends2;

            var _props = this.props,
                mode = _props.mode,
                rest = _objectWithoutProperties(_props, ['mode']);

            var _props2 = this.props,
                toHTML = _props2.toHTML,
                value = _props2.value;


            var toHTML__isFunction = _lodash2.default.isFunction(toHTML);
            if (!toHTML__isFunction) {
                logger.warn('toHTML is not a function, the field-component needs to be updated to call on `hydratedToHTMLFromProps()`', toHTML);
            }
            var isInfoMode = mode === 'info';
            var isControlMode = mode === 'control';
            var infoModeProps = isInfoMode ? {
                TextField: {
                    inputStyle: !this.props.locked ? {
                        color: _lodash2.default.get(this, 'context.muiTheme.textField.textColor')
                    } : undefined,
                    underlineStyle: {
                        display: 'none'
                    }
                },
                Toggle: {
                    disabled: true,
                    labelStyle: !this.props.locked ? {
                        color: _lodash2.default.get(this, 'context.muiTheme.textField.textColor')
                    } : undefined
                },
                default: {
                    disabled: true || this.props.disabled || this.props.locked,
                    value: toHTML__isFunction ? toHTML(value) : value
                }
            } : undefined;

            //value={isInfoMode? toHTML(value) :value}
            return _react2.default.createElement(Component, _extends({}, this.props, { infoModeProps: infoModeProps }, (_extends2 = {
                isControlMode: isControlMode,
                isInfoMode: isInfoMode,
                'data-codecept-selector-node': 'Component',
                'data-codecept-selector-file': 'addInfoModeProps'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Component'), _defineProperty(_extends2, 'data-codecept-selector-file', 'addInfoModeProps'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Component'), _defineProperty(_extends2, 'data-codecept-selector-file', 'addInfoModeProps'), _extends2)));
        }
    });
}

exports.default = addInfoModeProps;