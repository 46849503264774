'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Card = require('material-ui/Card');

var _header = require('./header');

var _header2 = _interopRequireDefault(_header);

var _comment = require('./comment');

var _comment2 = _interopRequireDefault(_comment);

var _commentEdit = require('./comment-edit');

var _commentEdit2 = _interopRequireDefault(_commentEdit);

var _viewEditButtons = require('./view-edit-buttons');

var _viewEditButtons2 = _interopRequireDefault(_viewEditButtons);

var _footer = require('./footer');

var _footer2 = _interopRequireDefault(_footer);

var _propTypes = require('./propTypes');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _constants = require('../../../common/constants');

var _constants2 = _interopRequireDefault(_constants);

var _Activity_style = require('../Activity_style');

var _Activity_style2 = _interopRequireDefault(_Activity_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var ACTIVITY_TYPES = _constants2.default.ACTIVITY_TYPES;

var styles = process.browser ? require('../Activity.cssModule').default : {};

var Activity = _react2.default.createClass({
    displayName: 'Activity',

    propTypes: _propTypes2.default,
    getDefaultProps: function getDefaultProps() {
        return {};
    },
    render: function render() {
        var _extends2, _extends3, _extends4, _extends5, _extends6, _React$createElement;

        return _react2.default.createElement(
            _Card.Card,
            (_React$createElement = { id: this.props.id,
                style: _Activity_style2.default.Card,
                zDepth: 2,
                'data-codecept-selector-node': 'Card',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement),
            _react2.default.createElement(_header2.default, _extends({}, this.props, (_extends2 = {
                'data-codecept-selector-node': 'ActivityHeader',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'ActivityHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'ActivityHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2))),
            this.props.editMode ? _react2.default.createElement(_commentEdit2.default, _extends({}, this.props, (_extends3 = {
                'data-codecept-selector-node': 'EditableActivityComment',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'EditableActivityComment'), _defineProperty(_extends3, 'data-codecept-selector-file', 'index'), _defineProperty(_extends3, 'data-codecept-selector-node', 'EditableActivityComment'), _defineProperty(_extends3, 'data-codecept-selector-file', 'index'), _extends3))) : _react2.default.createElement(_comment2.default, _extends({}, this.props, (_extends4 = {
                'data-codecept-selector-node': 'ActivityComment',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'ActivityComment'), _defineProperty(_extends4, 'data-codecept-selector-file', 'index'), _defineProperty(_extends4, 'data-codecept-selector-node', 'ActivityComment'), _defineProperty(_extends4, 'data-codecept-selector-file', 'index'), _extends4))),
            this.props.showViewEditButtons ? _react2.default.createElement(_viewEditButtons2.default, _extends({}, this.props, (_extends5 = {
                'data-codecept-selector-node': 'ViewEditButtons',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends5, 'data-codecept-selector-node', 'ViewEditButtons'), _defineProperty(_extends5, 'data-codecept-selector-file', 'index'), _defineProperty(_extends5, 'data-codecept-selector-node', 'ViewEditButtons'), _defineProperty(_extends5, 'data-codecept-selector-file', 'index'), _extends5))) : null,
            _react2.default.createElement(_footer2.default, _extends({}, this.props, (_extends6 = {
                'data-codecept-selector-node': 'ActivityFooter',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends6, 'data-codecept-selector-node', 'ActivityFooter'), _defineProperty(_extends6, 'data-codecept-selector-file', 'index'), _defineProperty(_extends6, 'data-codecept-selector-node', 'ActivityFooter'), _defineProperty(_extends6, 'data-codecept-selector-file', 'index'), _extends6)))
        );
    }
});

exports.default = Activity;