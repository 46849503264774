'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Popover = require('./reactComponents/Popover');

var _Popover2 = _interopRequireDefault(_Popover);

var _FieldsetConnector = require('@rubyapps/ruby-component-mixin-field-set/src/client/reactComponents/FieldsetConnector');

var _FieldsetConnector2 = _interopRequireDefault(_FieldsetConnector);

var _action = require('./action');

var _action2 = _interopRequireDefault(_action);

var _reducer = require('./reducer');

var _reducer2 = _interopRequireDefault(_reducer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Promise = require('bluebird');


var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldSetMixin = require('@rubyapps/ruby-component-mixin-field-set');

var RCPopover = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldSetMixin],
    propTypes: {
        openByDefault: PropTypes.bool,
        activeIconPath: PropTypes.string,
        inactiveIconPath: PropTypes.string,
        popoverStyles: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            openByDefault: false,
            inactiveIconPath: 'material-ui/svgIcons/AvPlaylistAdd',
            activeIconPath: 'material-ui/svgIcons/AvPlaylistAddCheck'
        };
    },
    componentName: componentName,
    reducer: _reducer2.default,
    action: _action2.default,
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();
        var selfAction = this.getAction();

        return {
            selfSelector: selfSelector,
            selfAction: selfAction
        };
    },
    getReactClass: function getReactClass() {
        return _FieldsetConnector2.default.call(this, _Popover2.default);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var PopoverComponent = this.getReactClass();

        return _react2.default.createElement(PopoverComponent, _extends({}, _lodash2.default.omit(this.props, ['reactProps']), this.props.reactProps, (_extends2 = {
            'data-codecept-selector-node': 'PopoverComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'PopoverComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'PopoverComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },

    getInitialState: function getInitialState() {
        return {
            isOpen: this.props.openByDefault,
            descendentsHaveValues: false,
            error: null
        };
    },

    refreshDisplayValue: function refreshDisplayValue() {
        var _this = this;

        var selfModule = this;

        var _selfModule$getAction = selfModule.getAction(),
            generators = _selfModule$getAction.generators;

        var promisedDisplayValue = selfModule.displayValue().then(function (displayValue) {
            var displayValueSpecs = _lodash2.default.reduce(displayValue, function (result, value, key) {
                var componentForKey = selfModule.findDescendentBy(function (el) {
                    return el.props.key === key;
                });
                return [].concat(result, {
                    key: key,
                    value: value,
                    label: _lodash2.default.get(componentForKey, 'props.label')
                });
            }, []);
            _this.getStore().dispatch(generators.updateDisplayValueSpecs(displayValueSpecs));
        });

        return promisedDisplayValue;
    },

    refreshDisplayValueIfFormValueIsDifferent: function refreshDisplayValueIfFormValueIsDifferent() {
        var previousFormValue = this.getStatefulCacheForKey('_cached_formValue');
        var nextFormValue = this.formValue();
        var formValueIsDifferent = !_lodash2.default.isEqual(previousFormValue, nextFormValue);

        this.setStatefulCacheForKey('_cached_formValue', nextFormValue);

        if (formValueIsDifferent) {
            return this.refreshDisplayValue();
        }
    },
    onReduxInit: function onReduxInit() {
        var _this2 = this;

        //this.refreshDisplayValueIfFormValueIsDifferent();
        //# NOTE: don't need to immediately refresh the displayValue for this component
        //# the first redux state update should be enough
        return store.subscribe(function () {
            var pendingRefreshDisplayValue = _this2.getStatefulCacheForKey('pendingRefreshDisplayValue');
            if (!pendingRefreshDisplayValue) {

                var newPendingRefreshDisplayValue = _this2.promisedOnceResolve().then(function () {
                    return _this2.refreshDisplayValueIfFormValueIsDifferent();
                }).then(function () {
                    //console.log("this.clearStatefulCacheForKey('pendingRefreshDisplayValue')", this.getID())
                    _this2.clearStatefulCacheForKey('pendingRefreshDisplayValue');
                });

                _this2.setStatefulCacheForKey('pendingRefreshDisplayValue', newPendingRefreshDisplayValue);
            }
        });
    }

    //== OVERRIDES =================================================================//
    , _formValueToLocalState: function _formValueToLocalState(formValue, dispatchOrCollect, isError, entireFormValue) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        this.cachePromisedOnceResolved(options.promisedOnceResolved);

        return baseFieldMixin._formValueToLocalState.apply(this, arguments);
    },

    resetStore: function resetStore() {
        this.clearStatefulCacheForKey('_cached_formValue');
        return baseFieldMixin.resetStore.apply(this, arguments);
    }
    //== Utility =======================================//
    , cachePromisedOnceResolved: function cachePromisedOnceResolved(promisedOnceResolved) {
        var _this3 = this;

        if (!promisedOnceResolved) {
            return false;
        }

        promisedOnceResolved.then(function () {
            //console.log("this.clearStatefulCacheForKey('promisedOnceResolved')", this.getID())
            _this3.clearStatefulCacheForKey('promisedOnceResolved');
        });

        this.setStatefulCacheForKey('promisedOnceResolved', promisedOnceResolved);
    },
    promisedOnceResolve: function promisedOnceResolve() {
        var promisedOnceResolved = this.getStatefulCacheForKey('promisedOnceResolved');

        return promisedOnceResolved || Promise.resolve();
    }
});

module.exports = RCPopover;