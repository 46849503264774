'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.DatePicker = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _DatePicker = require('@rubyapps/ruby-material-ui/DatePicker');

var _DatePicker2 = _interopRequireDefault(_DatePicker);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _clear = require('material-ui/svg-icons/content/clear');

var _clear2 = _interopRequireDefault(_clear);

var _friendlyRubyMoment = require('@rubyapps/friendly-ruby-moment');

var _friendlyRubyMoment2 = _interopRequireDefault(_friendlyRubyMoment);

var _addInfoModeProps = require('@rubyapps/ruby-react-components/src/client/hoc/addInfoModeProps');

var _addInfoModeProps2 = _interopRequireDefault(_addInfoModeProps);

var _DatePicker_style = require('./DatePicker_style');

var _DatePicker_style2 = _interopRequireDefault(_DatePicker_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label;

var RawFieldDatePicker = _react2.default.createClass({
    displayName: 'RawFieldDatePicker',

    propTypes: {
        label: PropTypes.string,
        labelProps: PropTypes.object,
        value: PropTypes.instanceOf(Date),
        clearValue: PropTypes.func,
        onValueChange: PropTypes.func,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        id: PropTypes.string,
        styles: PropTypes.shape({
            DatePickerWrapper: PropTypes.object,
            DatePicker: PropTypes.object
        }),
        displayFormat: PropTypes.string
    },
    getDefaultProps: function getDefaultProps() {
        return {
            displayFormat: 'MM/DD/YYYY'
        };
    },

    render: function render() {
        var _extends2,
            _this = this,
            _extends3,
            _React$createElement,
            _React$createElement2,
            _React$createElement3,
            _React$createElement4,
            _extends4;

        var labelProps = _.pick(this.props, Object.keys(Label.propTypes));
        var dateValue = _.get(this, 'props.value');
        var dateValueIsValid = dateValue && (0, _friendlyRubyMoment2.default)(dateValue).isValid();

        var isDisabled = this.props.disabled;

        var datePickerComponent = _react2.default.createElement(
            'div',
            _extends({
                style: {
                    //display: 'flex'
                    //, width: 150
                    width: '100%',
                    position: 'relative'
                }
            }, _.get(this.props, 'styles.DatePickerWrapper', {}), (_extends4 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'DatePicker'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'div'), _defineProperty(_extends4, 'data-codecept-selector-file', 'DatePicker'), _defineProperty(_extends4, 'data-codecept-selector-node', 'div'), _defineProperty(_extends4, 'data-codecept-selector-file', 'DatePicker'), _extends4)),
            _react2.default.createElement(_DatePicker2.default, _extends({
                fullWidth: true

            }, _DatePicker_style2.default.DatePicker, _.get(this.props, 'styles.DatePicker', {}), {

                id: this.props.id,
                floatingLabelText: _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                    'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'DatePicker'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'DatePicker'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'DatePicker'), _extends2))),
                onFocus: function onFocus(event) {
                    _this._datePicker;
                    //# NOTE: with the diff mixin, we allow for 'pointer-events' which prevents touchTap from getting fired, but focus events still work
                    //# so we should open dialog here

                    _this._datePicker.handleTouchTap(event);
                },
                onChange: this.props.onValueChange,
                value: dateValueIsValid ? _.get(this, 'props.value') : null,
                errorText: this.props.error ? this.props.error.message : null,
                mode: 'landscape',
                container: 'inline',
                disabled: isDisabled,
                formatDate: function formatDate() /*date*/{
                    //# show current input text if date is being entered with keyboard
                    return _this.props.textInput

                    //# else, show the current value
                    || (dateValueIsValid ? (0, _friendlyRubyMoment2.default)(dateValue).format(_this.props.displayFormat) : '');
                },
                firstDayOfWeek: 0 // start week with Sunday
            }, _.get(this, 'props.infoModeProps.TextField'), _.get(this, 'props.infoModeProps.default'), (_extends3 = {
                ref: function ref(datePicker) {
                    _this._datePicker = datePicker;
                }
                //# when the text input resolves to a valid date, show this date in the dialog to show the user how it was parsed
                , dialogDate: this.props.value,
                onBlur: this.props.onBlur,
                'data-codecept-selector-node': 'DatePicker',
                'data-codecept-selector-file': 'DatePicker'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'DatePicker'), _defineProperty(_extends3, 'data-codecept-selector-file', 'DatePicker'), _defineProperty(_extends3, 'data-codecept-selector-node', 'DatePicker'), _defineProperty(_extends3, 'data-codecept-selector-file', 'DatePicker'), _extends3))),
            !isDisabled ? _react2.default.createElement(
                'div',
                (_React$createElement4 = {
                    style: {
                        display: 'flex',
                        marginRight: 3,
                        visibility: dateValueIsValid ? 'visible' : 'hidden',
                        position: 'absolute',
                        right: -19,
                        top: -3
                    },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'DatePicker'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'DatePicker'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'DatePicker'), _React$createElement4),
                _react2.default.createElement(
                    'div',
                    (_React$createElement3 = {
                        style: {
                            marginTop: 30
                        },
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'DatePicker'
                    }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'DatePicker'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'DatePicker'), _React$createElement3),
                    _react2.default.createElement(
                        _IconButton2.default,
                        (_React$createElement2 = {
                            onTouchTap: this.props.clearValue,
                            'data-codecept-selector-node': 'IconButton',
                            'data-codecept-selector-file': 'DatePicker'
                        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'DatePicker'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'DatePicker'), _React$createElement2),
                        _react2.default.createElement(_clear2.default, (_React$createElement = {
                            'data-codecept-selector-node': 'ContentClear',
                            'data-codecept-selector-file': 'DatePicker'
                        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ContentClear'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'DatePicker'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ContentClear'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'DatePicker'), _React$createElement))
                    )
                )
            ) : null
        );

        return datePickerComponent;
    }
});

var FieldDatePicker = _react2.default.createClass({
    displayName: 'FieldDatePicker',

    propTypes: _extends({
        fieldKey: PropTypes.string,
        actions: PropTypes.object
    }, RawFieldDatePicker.propTypes),
    getDefaultProps: function getDefaultProps() {
        return {
            value: null,
            styles: {},
            renderGridWrapper: function renderGridWrapper(props) {
                return props.children;
            }
        };
    },
    _date_fromTextInput: function _date_fromTextInput(textInput) {
        if (!textInput) {
            return null;
        }
        var momentObj = (0, _friendlyRubyMoment2.default)(textInput, this.props.displayFormat);
        return momentObj.isValid() ? momentObj.toDate() : null;
    },
    _onValueChange: function _onValueChange(event, value) {
        //# value change via dialog
        if (value instanceof Date) {
            this.setState({
                textInput: null
            });
            this.props.actions.setFieldValueByKey(value, this.props.fieldKey);
            return;
        }

        //# value change via text input 
        this.setState({
            textInput: value
        });
        this.props.actions.setFieldValueByKey(this._date_fromTextInput(value), this.props.fieldKey);
    },
    _onBlur: function _onBlur() {
        this.setState({
            textInput: null
        });
    },
    _clearValue: function _clearValue() {
        this.props.actions.setFieldValueByKey(null, this.props.fieldKey);
    },
    render: function render() {
        var _extends5;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var isDisabled = this._isDisabled();

        var datePickerComponent = _react2.default.createElement(RawFieldDatePicker, _extends({
            disabled: isDisabled,
            clearValue: this._clearValue,
            onValueChange: this._onValueChange,
            textInput: _.get(this, 'state.textInput'),
            onBlur: this._onBlur
        }, _.pick(this.props, Object.keys(RawFieldDatePicker.propTypes)), (_extends5 = {
            'data-codecept-selector-node': 'RawFieldDatePicker',
            'data-codecept-selector-file': 'DatePicker'
        }, _defineProperty(_extends5, 'data-codecept-selector-node', 'RawFieldDatePicker'), _defineProperty(_extends5, 'data-codecept-selector-file', 'DatePicker'), _defineProperty(_extends5, 'data-codecept-selector-node', 'RawFieldDatePicker'), _defineProperty(_extends5, 'data-codecept-selector-file', 'DatePicker'), _extends5)));

        return this.props.renderGridWrapper(_extends({}, this.props, { children: datePickerComponent }));
    },

    _isDisabled: function _isDisabled() {
        return this.props.locked || this.props.disabled || this.props.isInfoMode;
    }
});

exports.default = (0, _addInfoModeProps2.default)(FieldDatePicker);
exports.DatePicker = RawFieldDatePicker;