'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _ButtonConnector = require('./reactComponents/ButtonConnector');

var _ButtonConnector2 = _interopRequireDefault(_ButtonConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Promise = require('bluebird');


var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

//const FieldForm = require('@rubyapps/ruby-component-field-form');
var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var action = require('./action');
var reducer = require('./reducer');

/*
 *  props: {
 *      action: PropTypes.string - the name of the action on the closest ruby-component-field-form that the button should trigger
 *      action: PropTypes.shape({
 *          path: PropTypes.string - selector path for selection to node that should call on the action
 *          , action: PropTypes.string - the name of the action to trigger
 *      })
 *  }
 * */ //# reference field-base-mixin for more info
var LOADER_DELAY = 500;
var RCButton = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {
        //# action: baseFieldMixin.propTypes.action
        styles: PropTypes.object //# a style object with keys of components for the field component
        , handler: PropTypes.func //# if you want to provide a handler function instead of action
        , icon: PropTypes.node,
        rubyIconPath: PropTypes.string
    },
    componentName: componentName,
    getDefaultProps: function getDefaultProps() {
        return {
            label: 'Button Label'
            //, action: 'saveStateToRemote'
            , hidden: false,
            listerConfig: {
                excludeFromColumnSelection: true,
                excludeFromFilterSelection: true
            }
        };
    },
    getInitialState: function getInitialState() {
        return {
            isLoading: false
        };
    },
    dependencies: function dependencies() {
        var _this = this;

        var selfSelector = this.getSelfStateSelector();
        var props = this.getProps(true);

        var props__action = props.action ? function (_ref) {
            var successCallback = _ref.successCallback,
                errorCallback = _ref.errorCallback;

            var dispatch = _this.getStore().dispatch;
            var setIsLoading = _this.getAction().generators.setIsLoading;

            //# dispatch to show loader
            var showLoaderTimeout = setTimeout(function () {
                showLoaderTimeout = null;
                if (callback.__registered) {
                    //# only show loading if the calling action recognizes it
                    dispatch(setIsLoading(true));
                }
            }, LOADER_DELAY);

            var callback = function callback(err, responseBody) {
                //# hide loader
                if (showLoaderTimeout) {
                    clearTimeout(showLoaderTimeout);
                } else {
                    setTimeout(function () {
                        dispatch(setIsLoading(false));
                    }, LOADER_DELAY);
                }
                //# NOTE: because we're passing callback for both error and success
                //# we are responsible for error handling
                //# IDEA: it's better if we can allow the callee to know if it needs to handle error
                //# eg. if we use unresolved Promises instead
                //# and then the caller can either rethrow the error or suppress it
                if (err) {
                    if (errorCallback) {
                        errorCallback(err);
                        //# Defer to the caller to show error notification if callback exists
                    } else {
                        console.error('Error calling on button action', err);
                        _this.showErrorNotification({ error: err, modal: true });
                    }
                } else {
                    successCallback && successCallback(err, responseBody);
                }
            };

            return props.action({
                successCallback: callback,
                errorCallback: callback
            });
        } : props.action;

        //# TODO: refactor, expect all actions to potentially support a callback
        //# expect the callback.__registered = true to be set
        //# so we know whether we should wait

        return {
            selfSelector: selfSelector,
            actionForButton: props__action
        };
    },
    action: action,
    reducer: reducer,
    statesSelector: function statesSelector(state) {
        var props = this.getProps(true);

        return {
            props: props,
            selfState: this.getState()
        };
    },
    getReactClass: function getReactClass() {
        return _ButtonConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var ButtonComponent = this.getReactClass();

        return _react2.default.createElement(ButtonComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'ButtonComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'ButtonComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'ButtonComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    }

});

module.exports = RCButton;