'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _queryString = require('query-string');

var _queryString2 = _interopRequireDefault(_queryString);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _ContentCompareDialog = require('./ContentCompareDialog');

var _ContentCompareDialog2 = _interopRequireDefault(_ContentCompareDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var rubyLogger = require('@rubyapps/ruby-logger');
var logger = rubyLogger.getLogger('ruby-component-content-compare:reactComponents/ContentCompareDialog');
var rubyWords = require('@rubyapps/ruby-words');
var rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');

var ReactDOM = require('react-dom');

var routeParser = require('route-parser');


var RubyComponentEditPage__CONSTANTS = require('@rubyapps/ruby-component-material-edit-page/src/common/constants');
var rubyAccessManager = require('@rubyapps/ruby-access-manager');

var PropTypes = _react2.default.PropTypes;

var action = require('../action');
var CONSTANTS = require('../../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

function flattenObject() {
    var o = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return _extends.apply(undefined, [{}].concat(_toConsumableArray(function _flatten(o, keypath) {
        var _ref;

        return (_ref = []).concat.apply(_ref, _toConsumableArray(Object.keys(o).map(function (k) {
            var currentKeypath = keypath + '.' + k;
            var currentValue = o[k];

            return _lodash2.default.isObject(currentValue) ? _flatten(currentValue, currentKeypath) : _defineProperty({}, currentKeypath, currentValue);
        })));
    }(o, ''))));
}

function shareMailtoGenerator(selfModule, state, url) {
    var dependencies = selfModule.getDependencies();
    var selfSelector = dependencies.selfSelector,
        frontendSettingsSelector = dependencies.frontendSettingsSelector,
        currentUserSelector = dependencies.currentUserSelector,
        formsSelector = dependencies.formsSelector;

    var currentUserState = currentUserSelector(state);
    var forms = formsSelector(state);

    var _ref3 = currentUserState || {},
        username = _ref3.username,
        first_name = _ref3.first_name,
        last_name = _ref3.last_name;

    var _ref4 = frontendSettingsSelector(state) || {},
        clientName = _ref4.CLIENT_NAME,
        rubyBranding = _ref4.RUBY_BRANDING,
        sslIsEnabled = _ref4["WWW.INIT.SSL_ONLY"];

    var selfState = selfSelector(state);
    var pageName = selfState.pageName,
        templateKey = selfState.templateKey;

    var resultURL = _lodash2.default.get(selfState, 'result.url', '');
    var hostname = window.location.hostname;
    var httpProtocol = sslIsEnabled ? 'https' : 'http';

    var template = forms[templateKey];
    var isMultilingual = _lodash2.default.get(template, 'isMultilingual', false);

    var getPreviewQuery_fromFullURL = _lodash2.default.flowRight(function (pickedArgs) {
        return _queryString2.default.stringify(pickedArgs);
    }, function (allArgs) {
        return _lodash2.default.pick(allArgs, [].concat(isMultilingual ? 'lang' : []));
    }, function (rawQuery) {
        return _queryString2.default.parse(rawQuery);
    }, function (fullUrl) {
        return fullUrl.substring(fullUrl.indexOf('?'));
    });
    var resultQuery = resultURL && getPreviewQuery_fromFullURL(resultURL);

    var shareURL = hostname && resultURL && httpProtocol + '://' + hostname + resultURL.substring(0, resultURL.indexOf('?')) + (resultQuery ? '?' + resultQuery : '');

    if (!shareURL) {
        return;
    }

    var emailSubjectText = clientName + ' Website: ' + first_name + ' ' + last_name + ' has shared a webpage update with you';
    var emailBodyText = first_name + ' ' + last_name + ' has made an update for you to preview in ' + rubyBranding + '. Please note that this update may not yet be published on the live website:\n\n' + (pageName ? pageName + '\n' : '') + '\n' + shareURL + '\n\nThis is a secure link that will expire in ' + _moment2.default.duration(selfModule.props.previewDuration, 'seconds').humanize() + '.\n\nThank you.';

    return 'mailto:?subject=' + encodeURIComponent(emailSubjectText) + '&body=' + encodeURIComponent(emailBodyText);
}

function delegateShareGenerator(selfModule, state, url) {

    var mailtoString = shareMailtoGenerator(selfModule, state, url);
    return mailtoString && function () {
        //# TODO: need to somehow let the beforeunload callback know that the requested location is sound
        window.__requestedLocation__ = mailtoString;
        window.location = mailtoString;
    };
}

module.exports = function contentCompareDialogGenerator() {
    var selfModule = this;
    var selfProps = this.props;

    //# Dependencies
    var dependencies = selfModule.getDependencies();
    var editPageComponent = dependencies.editPageComponent,
        editPageSelector = dependencies.editPageSelector,
        selfSelector = dependencies.selfSelector,
        selfActions = dependencies.selfActions,
        frontendSettingsSelector = dependencies.frontendSettingsSelector,
        formsSelector = dependencies.formsSelector,
        currentUserSelector = dependencies.currentUserSelector;

    //# Constants

    var editPageRouteTemplate = editPageComponent.getRoutePath();

    //# Routes
    var editPageRouteParser = new routeParser(editPageRouteTemplate);

    function mapStateToProps(state) {
        var selfState = selfSelector(state);

        var _frontendSettingsSele = frontendSettingsSelector(state),
            _frontendSettingsSele2 = _frontendSettingsSele.languages,
            languages = _frontendSettingsSele2 === undefined ? [] : _frontendSettingsSele2;

        var currentUserState = currentUserSelector(state);
        var forms = formsSelector(state);

        var canApproveContent = selfModule.canUserPublish();

        var rubyClientUrl = selfState.rubyClientUrl,
            subsiteUrl = selfState.subsiteUrl,
            templateKey = selfState.templateKey,
            pageID = selfState.id,
            isLoading = selfState.isLoading;


        var formData = _lodash2.default.get(selfState, ['options', 'formData'], _lodash2.default.get(selfState, ['options', 'compareURLData', 'updatedData'], {}));

        var template = forms[templateKey];

        var rootModule = selfModule.getRoot();
        var editURLSpec = _lodash2.default.get(selfState, 'options.editURLSpec') || (pageID && templateKey && subsiteUrl ? {
            path: rootModule.getBestUrlForComponentName_fromModule_withParams(RubyComponentEditPage__CONSTANTS.COMPONENT_NAME, selfModule, {
                rubyClient: rubyClientUrl,
                subsite: subsiteUrl,
                template: templateKey,
                id: pageID,
                action: 'edit'
            })
        } : null);

        var delegateShare = delegateShareGenerator(selfModule, state);

        //# handle disable language options
        var _formData$contentForL = formData.contentForLanguages,
            contentForLanguages = _formData$contentForL === undefined ? {} : _formData$contentForL;

        var languages__withAppropriateOptionsDisabled = languages.map(function (languageOption) {
            if (languageOption.isDefault) {
                return languageOption;
            }

            var formDataForLanguage = _lodash2.default.get(contentForLanguages, [languageOption.value], {});
            var flattenedFormData = flattenObject(formDataForLanguage);
            var descendentsHaveValues = _lodash2.default.reduce(flattenedFormData, function (collector, value, key) {
                if (collector) {
                    return collector;
                }
                if (!(_lodash2.default.isNil(value) || value == "")) {
                    return true;
                }
                return collector;
            }, false);
            if (!descendentsHaveValues) {
                return _extends({ disabled: true }, languageOption);
            } else {
                return languageOption;
            }
        });

        var mappedProps = _extends({
            templateKey: templateKey,
            id: pageID,
            open: selfState.isOpen,
            previewPage: selfState.previewPage,
            result: selfState.result,
            basis: selfState.basis,
            diff: selfState.diff,
            tableDiff: selfState.tableDiff,
            editURLSpec: editURLSpec,
            showContentApprovalActions: selfState.showContentApprovalActions //# TODO: DEPRECATED remove once we can remove legacy content-approval
            , additionalHeaderElements: selfState.additionalHeaderElements,
            showEditAction: selfState.showEditAction,
            canApproveContent: canApproveContent,
            title: selfState.title,
            isLoading: isLoading,
            languages: languages__withAppropriateOptionsDisabled,
            isMultilingual: _lodash2.default.get(template, 'isMultilingual', false),
            selectedLanguage: selfState.selectedLanguage,
            delegateShare: delegateShare
        });
        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfActions, dispatch),
            delegateReject: function delegateReject(evt) {
                dispatch(_delegateReject);
            },
            delegateApprove: function delegateApprove(evt) {
                dispatch(_delegateApprove);
            }
        };
    }

    function mergeProps(stateProps, dispatchProps, ownProps) {
        //# need to handle delegation here because it needs the bound action functions
        var currentURL = location.pathname;

        return _extends({}, ownProps, stateProps, dispatchProps, {
            delegateEdit: function delegateEdit(evt) {
                //logger.debug(evt, 'Edit button clicked 2');
                if (currentURL == stateProps.editURLSpec.path) {
                    dispatchProps.actions.closeAndReset();
                } else {
                    dispatchProps.actions.navigateToPathWithOptions(stateProps.editURLSpec);
                    dispatchProps.actions.closeAndReset();
                }
            },
            delegateClose: function delegateClose(evt) {
                dispatchProps.actions.closeAndReset();
            },
            delegateLanguageChange: dispatchProps.actions.setSelectedLanguage
        });
    }

    //# TODO: REMOVE once we can get rid of the legacy content approval plugin (or migrate the approve/reject dialog logic for the review dialog)
    //# DEPRECATED 20201014
    function _delegateReject(dispatch, getState) {
        var state = getState();
        var selfState = selfSelector(state);
        var id = selfState.id,
            delegateActions = selfState.delegateActions,
            editorName = selfState.editorName,
            options = selfState.options,
            templateKey = selfState.templateKey;

        var afterUpdateCallback = _lodash2.default.get(options, 'afterUpdateCallback');

        dispatch(selfActions.closeAndReset());
        //# then display reject modal
        dispatch(delegateActions.openRejectModal_usingOptions({
            id: id,
            templateKey: templateKey,
            defaultFields: {
                to: {
                    value: editorName,
                    visible: true,
                    disabled: true
                }
            },
            successCallback: function successCallback(err, response) {
                var contentDisplayString = rubyWords.quote(_lodash2.default.get(response, 'body.draft_name' //# note great but need to check for draft or approval
                , _lodash2.default.get(response, 'body.approval_name')));

                afterUpdateCallback && afterUpdateCallback(err, response);

                selfModule.pushNotification({
                    message: contentDisplayString ? 'Changes to ' + contentDisplayString + ' were rejected.' : 'The approval request was rejected.',
                    type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.INFO
                });
            },
            errorCallback: function errorCallback(err) {
                return dispatch(genericContentApprovalErrorHandler);
            }
        }));
    }

    //# TODO: REMOVE
    //# DEPRECATED 20201014
    function _delegateApprove(dispatch, getState) {
        var state = getState();
        var selfState = selfSelector(state);
        var id = selfState.id,
            delegateActions = selfState.delegateActions,
            editorName = selfState.editorName,
            options = selfState.options,
            templateKey = selfState.templateKey;

        var afterUpdateCallback = _lodash2.default.get(options, 'afterUpdateCallback');

        dispatch(selfActions.closeAndReset());
        //# then display approve modal
        dispatch(delegateActions.openApproveModal_usingOptions({
            id: id,
            templateKey: templateKey,
            defaultFields: {
                to: {
                    value: editorName,
                    visible: true,
                    disabled: true
                }
            },
            successCallback: function successCallback(err, response) {
                var contentDisplayString = rubyWords.quote(_lodash2.default.get(response, 'body.draft_name' //# note great but need to check for draft or approval
                , _lodash2.default.get(response, 'body.approval_name')));

                selfModule.pushNotification({
                    message: contentDisplayString ? 'Changes to ' + contentDisplayString + ' were approved.' : 'The approval request was approved.',
                    type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS
                });

                afterUpdateCallback && afterUpdateCallback(err, response);
            },
            errorCallback: function errorCallback() {
                return dispatch(genericContentApprovalErrorHandler);
            }
        }));
    }

    function genericContentApprovalErrorHandler(error) {
        return function (dispatch) {
            var title = 'Content Approval Request Update Error',
                message = 'An unexpected error was encountered while updating this request. ' + 'Please try again later or contact Support.';

            if (error.status === 401) {
                title = 'Authorization Error';
                message = 'You are not authorized to update approval requests. ' + 'Try logging back in or contact Support.';
            }

            dispatch(selfModule.showNotificationModal({ title: title, message: message }));
        };
    }

    //return selfModule.connect(mapStateToProps, mapDispatchToProps, mergeProps)(ContentCompareDialog);
    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(_ContentCompareDialog2.default);
};