'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _SvgIcon = require('material-ui/SvgIcon');

var _SvgIcon2 = _interopRequireDefault(_SvgIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var NavigationDragHandle = function NavigationDragHandle(props) {
    var _React$createElement, _extends2;

    return _react2.default.createElement(
        _SvgIcon2.default,
        _extends({}, props, (_extends2 = {
            'data-codecept-selector-node': 'SvgIcon',
            'data-codecept-selector-file': 'drag-handle'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends2, 'data-codecept-selector-file', 'drag-handle'), _defineProperty(_extends2, 'data-codecept-selector-node', 'SvgIcon'), _defineProperty(_extends2, 'data-codecept-selector-file', 'drag-handle'), _extends2)),
        _react2.default.createElement('path', (_React$createElement = { d: "M 5 5 a 1.5 1.5 0 1 0 0.00001 0" + "M 5 10 a 1.5 1.5 0 1 0 0.00001 0" + "M 5 15 a 1.5 1.5 0 1 0 0.00001 0" + "M 10 5 a 1.5 1.5 0 1 0 0.00001 0" + "M 10 10 a 1.5 1.5 0 1 0 0.00001 0" + "M 10 15 a 1.5 1.5 0 1 0 0.00001 0", 'data-codecept-selector-node': 'path',
            'data-codecept-selector-file': 'drag-handle'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'drag-handle'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'path'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'drag-handle'), _React$createElement))
    );
};

exports.default = NavigationDragHandle;