'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

exports.default = TimeFormatterGenerator;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');
var moment = require('moment');
var React = require('react');

function TimeFormatterGenerator(options) {

    return function TimeFormatter(props) {
        var _React$createElement;

        var timeFormat = _.get(options, 'format') || "hh:mm a zz";
        var formattedTimeString = void 0;

        var timeString = '';
        if (typeof props.data === 'string') {
            timeString = props.data;
        } else if (typeof _.get(props, ['data', 'time']) === 'string') {
            timeString = _.get(props, ['data', 'time']);
        }
        if (timeString) {

            var epochMoment = moment.unix(0).tz('UTC');
            if (timeString.length == 5) {
                var _timeString$split = timeString.split(':'),
                    _timeString$split2 = _slicedToArray(_timeString$split, 2),
                    hours = _timeString$split2[0],
                    minutes = _timeString$split2[1];

                epochMoment.hour(hours);
                epochMoment.minute(minutes);
            } else {
                epochMoment = moment(timeString);
            }

            formattedTimeString = epochMoment.format(timeFormat);
        } else {
            formattedTimeString = '';
        }

        return React.createElement(
            'span',
            (_React$createElement = {
                'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'TimeFormatterGenerator'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TimeFormatterGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TimeFormatterGenerator'), _React$createElement),
            formattedTimeString
        );
    };
}