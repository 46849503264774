'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reactRouterRedux = require('react-router-redux');

var _constants = require('../common/constants');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var urljoin = require('url-join');

var request = require('@rubyapps/ruby-superagent');
var rubyWords = require('@rubyapps/ruby-words');
var rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');

var FETCH_MOST_RECENTLY_MODIFIED_ENDPOINT = '/content/fetch-most-recently-modified';

var generators = {
    /* TODO: copy of sitemap-page component */
    setItemStatus_withActive: function setItemStatus_withActive(item, isActive, thenableArguments) {
        var templateKey = item.templateKey,
            id = item.id;

        var selfModule = this;

        var _selfModule$getDepend = selfModule.getDependencies(),
            feSettingsSelector = _selfModule$getDepend.feSettingsSelector;

        var pushNotification = selfModule.pushNotification.bind(selfModule);
        var showNotificationModal = selfModule.showNotificationModal.bind(selfModule);

        return function (dispatch, getState) {
            var applicationState = getState();
            var apiUrlPrefix = _.get(feSettingsSelector(applicationState), 'restApiRoot');
            var endpoint = urljoin(apiUrlPrefix, _constants2.default.API_KEY, '' + id, isActive ? 'publish' : 'unpublish');
            var requestPromise = request.post(endpoint).then(function (response) {
                var displayStatusMap = {
                    active: 'published',
                    inactive: 'unpublished'
                };

                var status = _.get(response, 'body.status');
                var contentDisplayString = rubyWords.quote(_.get(response, 'body.name')) || 'This content';
                var displayedStatus = displayStatusMap[status];

                pushNotification({
                    message: contentDisplayString + ' is now ' + displayedStatus + '.',
                    type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS
                });
                return response;
            }, function (err) {
                var notificationMethod = pushNotification,
                    title = void 0,
                    message = void 0,
                    type = rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR;
                if (err.status === 409) {
                    message = 'The entry could not be updated due to a pending content approval request. ' + 'Please try again once the request is resolved.';
                } else if (err.status === 403) {
                    message = _.get(err, 'response.body.error.message') || 'You do not have the permission to update content. ' + 'Please edit the page and submit a content approval request.';
                } else if (err.status === 422) {
                    message = 'The entry could not be updated due to invalid content. ' + 'Please edit the page to fix.';
                } else {
                    title = 'Content Update Error';
                    message = _.get(err, 'response.body.error.message') || 'An error was encountered while updating content. ' + 'Please try again later.';
                    notificationMethod = showNotificationModal;
                }

                notificationMethod({ type: type, title: title, message: message });
                throw err;
            });
            if (thenableArguments) {
                requestPromise.then.apply(requestPromise, thenableArguments);
            }
            return requestPromise;
        };
    }
    /* TODO: copy of action in new-page-dialog component */
    , createNewPage: function createNewPage(payload) {
        var dependencies = this.getDependencies();
        var editPageComponent = dependencies.editPageComponent;
        var editPageActions = editPageComponent.getAction().generators;
        return function (dispatch, getState) {
            //# update the editPage's data
            var formData = _extends({}, payload.data, {
                content_subsite_fk: 1
            });
            dispatch(editPageActions.hydrateFormWithData(formData));
            dispatch((0, _reactRouterRedux.push)(payload.path));
        };
    },
    retrieveAndSetMostRecentlyModifiedItem_forType: function retrieveAndSetMostRecentlyModifiedItem_forType(type) {
        if (type == undefined) {
            console.warn('No type was provided for retrieveAndSetMostRecentlyModifiedItem_forType');
            return;
        }
        var selfModule = this;

        var _selfModule$getDepend2 = selfModule.getDependencies(),
            selfActions = _selfModule$getDepend2.selfActions,
            selfSelector = _selfModule$getDepend2.selfSelector,
            feSettingsSelector = _selfModule$getDepend2.feSettingsSelector;

        var thunk = function thunk(dispatch, getState) {
            var applicationState = getState();

            var selfState = selfSelector(applicationState);

            var _selfModule$getActive = selfModule.getActiveRubyClientInfo(),
                rubyClientId = _selfModule$getActive.client,
                subsiteId = _selfModule$getActive.subsite;

            var apiUrlPrefix = _.get(feSettingsSelector(applicationState), 'restApiRoot');

            if (rubyClientId && subsiteId) {
                var endpoint = urljoin(apiUrlPrefix, FETCH_MOST_RECENTLY_MODIFIED_ENDPOINT, '?ruby_client_fk=' + rubyClientId, '&content_subsite_fk=' + subsiteId, '&template_keyword=' + type);

                var requestPromise = request.get(endpoint).then(function (response) {
                    var item = response.body.data[0];
                    dispatch(selfActions.setMostRecentlyModifiedItem(item));
                }, function (err) {});
            } else {
                setTimeout(function () {
                    dispatch(thunk);
                }, 250);
            }
        };

        return thunk;
    }
};

module.exports = function () {
    var selfModule = this;
    var selfID = selfModule.getID();

    var TYPES = {
        SET_MOST_RECENTLY_MODIFIED_ITEM: '@@ruby-app/' + selfID + '/SET_MOST_RECENTLY_MODIFIED_ITEM'
    };

    return {
        TYPES: TYPES,
        generators: _extends({
            setMostRecentlyModifiedItem: function setMostRecentlyModifiedItem(item) {
                return {
                    type: TYPES.SET_MOST_RECENTLY_MODIFIED_ITEM,
                    payload: {
                        item: item
                    }
                };
            }
        }, generators)
    };
};