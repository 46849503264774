'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reduxBatchedActions = require('redux-batched-actions');

/*
   20220826: This is an intermediary solution for what should uiltimately be replaced with:

   [RT#81884 - Collaborative Editing Requested, Page-level locks are too monolithic]( https://gitlab.intranet.rubensteintech.com/project/ruby-app/-/issues/1177)

*/

var _ = require('lodash');
var jsondiffpatch = require('jsondiffpatch');
var queryString = require('query-string');

var request = require('../../../ruby-superagent');
var rubyNotificationsComponent = require('../../../ruby-component-notifications');
var sitemapUtils = require('../common/utils');

var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = 'ruby-component-field-sitemap';
var logger = rubyLogger.getLogger(packageName);

var POLLING_INTERVAL = 20 /*seconds*/ * 1000 /*milliseconds*/;

//# Should be used by ATF when testing or visiting the sitemap page
//# NOTE: if you use this to disable polling, be aware of the consequences of this use
var DEBUG_FLAG__DISABLE_POLLING = 'debug__sitemap__disablePolling';
//# if you want to completely disable polling
var DEBUG_FLAG__SUPPRESS_POLLING_DIALOG = 'debug__sitemap__suppressPollingDialog';
//# you can also just supress the dialog
//# but be aware that the sitemap will automatically update/refresh
//# which might be an issue if you're testing the sitemap

//# Should be used by anyone when debugging the Sitemap Updated popup showing when it shouldn't
var DEBUG_FLAG__LOG_SITEMAP_DIFF = 'debug__sitemap__logSitemapDiff';

var LOCATION__QUERY_PARAMS = location && location.search ? _extends({}, queryString.parse(location.search)) : {};
var IS_DEBUG_FLAG__DISABLE_POLLING = LOCATION__QUERY_PARAMS.hasOwnProperty(DEBUG_FLAG__DISABLE_POLLING);
var IS_DEBUG_FLAG__SUPPRESS_POLLING_DIALOG = LOCATION__QUERY_PARAMS.hasOwnProperty(DEBUG_FLAG__SUPPRESS_POLLING_DIALOG);
var IS_DEBUG_FLAG__SHOW_SITEMAP_DIFF = LOCATION__QUERY_PARAMS.hasOwnProperty(DEBUG_FLAG__LOG_SITEMAP_DIFF);

module.exports = {
    iterPolling__checkIfDataChanged: function iterPolling__checkIfDataChanged() {
        var _this = this;

        var pollingTimeoutId = setTimeout(function () {
            _this.polling__checkIfDataChanged();
        }, POLLING_INTERVAL);

        this.setStatefulCacheForKey('pollingTimeoutId', pollingTimeoutId);
    },
    polling__checkIfDataChanged: function polling__checkIfDataChanged() {
        var _this2 = this;

        var _getDependencies = this.getDependencies(),
            parentForm = _getDependencies.parentForm,
            currentUser = _getDependencies.currentUser;

        var selfState = this.getState();

        var parentRouteComponent = this.findAncestorBy(function (n) {
            return n.getRouteElement;
        });

        var _parentRouteComponent = parentRouteComponent.getState(),
            _parentRouteComponent2 = _parentRouteComponent.routeParams;

        _parentRouteComponent2 = _parentRouteComponent2 === undefined ? { routeActive: false } : _parentRouteComponent2;
        var routeActive = _parentRouteComponent2.routeActive;


        var pendingAddPage = this.getStatefulCacheForKey('pendingAddPage');

        if (!routeActive || pendingAddPage) {
            return;
        }

        var retrievedFormDataID = parentRouteComponent._retrievedFormDataID;
        if (!retrievedFormDataID) {
            return;
        }

        //# check if there's a pending hydration
        var isLoading = _.get(selfState, ['griddleState', 'loading'], false);
        //# NOTE: we cannot chain to griddleState.recentRequest because the cached request value is before request.end() gets called
        //# so if you chained to this, it would cause anotehr request to be made

        var pendingSave = parentForm.getStatefulCacheForKey('submitToRoute_withID_andFormValue');
        if (pendingSave) {
            logger.info('Pending save exists, going to skip polling until next interval');

            return pendingSave.then(function () {
                //# timeout then continue polling
                _this2.iterPolling__checkIfDataChanged();
            });
        } else if (isLoading) {
            logger.info('Pending request exists, going to skip polling until next interval');

            return this.iterPolling__checkIfDataChanged();
        }

        var dataEndpoint = parentRouteComponent.getInstanceDataEndpoint_forId(retrievedFormDataID);

        var requestObject = request.get(dataEndpoint);

        return requestObject.then(function (response) {
            var _parentRouteComponent3 = parentRouteComponent.getState(),
                _parentRouteComponent4 = _parentRouteComponent3.routeParams;

            _parentRouteComponent4 = _parentRouteComponent4 === undefined ? { routeActive: false } : _parentRouteComponent4;
            var routeActive = _parentRouteComponent4.routeActive;


            var pendingAddPage = _this2.getStatefulCacheForKey('pendingAddPage');

            if (!routeActive || pendingAddPage) {
                return;
            }

            var pendingSave = parentForm.getStatefulCacheForKey('submitToRoute_withID_andFormValue');
            if (pendingSave) {
                //# a save was dispatched while pollling, we should skip and wait

                logger.info('Pending save exists, going to skip polling until next interval');
                return pendingSave.then(function () {
                    //# timeout then continue polling
                    _this2.iterPolling__checkIfDataChanged();
                });
            }

            var serverFormData = response.body;

            var _parentRouteComponent5 = parentRouteComponent.getState(),
                pristineData = _parentRouteComponent5.pristineData;

            var fieldKeypathArr = _this2.getFieldKeypathArr();
            var sitemap_formValue = _.get(_this2.formValue(), fieldKeypathArr);
            var server__sitemap_formValue = _.get(sitemapUtils.cleanSitemapFormValue_forFieldKeypathArr(serverFormData, fieldKeypathArr), fieldKeypathArr);

            if (!_.isEqual(sitemap_formValue, server__sitemap_formValue)) {
                if (IS_DEBUG_FLAG__SHOW_SITEMAP_DIFF) {
                    console.log('pollChanges: Sitemap Changes:', jsondiffpatch.diff(sitemap_formValue, server__sitemap_formValue));
                }
                //# ask for refresh
                //# then continue polling
                var parentRouteActions = parentRouteComponent.getAction().generators;

                var modifiedPristineData = _extends({}, pristineData);
                _.set(modifiedPristineData, fieldKeypathArr, server__sitemap_formValue);

                _this2.getStore().dispatch((0, _reduxBatchedActions.batchActions)([, parentRouteActions.setPristineData(modifiedPristineData), parentRouteActions.setRequestResponse(response), _this2.getAction().generators.setFieldValueByKey(server__sitemap_formValue, _this2.key(), true) //# need to update sitemap
                ]));

                //# restart polling 
                _this2.iterPolling__checkIfDataChanged();

                //# automatically reload the data and continue polling
                //# we just keep the notification modal around
                //# only show if the tab is active and has focus
                if (!document.hidden && document.hasFocus() && !IS_DEBUG_FLAG__SUPPRESS_POLLING_DIALOG) {
                    var currentUserState = currentUser.getState();
                    var currentUserName = currentUserState.first_name + ' ' + currentUserState.last_name;

                    var didCurrentUserModify = serverFormData.last_modified_by_user_name == currentUserName;

                    _this2.showNotificationModal({
                        type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR,
                        title: 'Sitemap Updated',
                        message: (didCurrentUserModify ? 'You modified the sitemap in a different tab.' : serverFormData.last_modified_by_user_name + ' modified the sitemap.') + ' This session\'s sitemap has been updated to reflect these changes.'
                    });
                }
            } else {
                //# continue polling
                _this2.iterPolling__checkIfDataChanged();
            }
        }).catch(function (err) {
            //# ignore error for now
            console.error('Error polling for page data at endpoint: [' + dataEndpoint + ']', err);
        });
    },
    onReduxInit: function onReduxInit(dispatch) {
        var _this3 = this;

        var store = this.getStore();

        if (IS_DEBUG_FLAG__DISABLE_POLLING) {
            return function () {};
        }

        var pollingTimeoutId = setTimeout(function () {

            _this3.polling__checkIfDataChanged();
        }, POLLING_INTERVAL);

        this.setStatefulCacheForKey('pollingTimeoutId', pollingTimeoutId);

        var parentRouteComponent = this.findAncestorBy(function (n) {
            return n.getRouteElement;
        });

        var storeUnsub = store.subscribe(function () {
            var _parentRouteComponent6 = parentRouteComponent.getState(),
                _parentRouteComponent7 = _parentRouteComponent6.routeParams;

            _parentRouteComponent7 = _parentRouteComponent7 === undefined ? { routeActive: true } : _parentRouteComponent7;
            var routeActive = _parentRouteComponent7.routeActive;


            if (!routeActive) {
                storeUnsub();

                var _pollingTimeoutId = _this3.getStatefulCacheForKey('pollingTimeoutId');

                if (_pollingTimeoutId) {
                    clearTimeout(_pollingTimeoutId);
                    _this3.clearStatefulCacheForKey('pollingTimeoutId');
                }
            }
        });

        return function () {
            storeUnsub();

            var pollingTimeoutId = _this3.getStatefulCacheForKey('pollingTimeoutId');

            if (pollingTimeoutId) {
                clearTimeout(pollingTimeoutId);
                _this3.clearStatefulCacheForKey('pollingTimeoutId');
            }
        };
    }
};