'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function columnDefinitionsFromSearchableFields() {
    var searchableFields = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    var uniqueColumnDefinitionsByKey = flattenedSearchableFields(searchableFields).reduce( /*merge dupes*/function (collector, field) {
        var key = field.key,
            dataPath = field.dataPath;


        if (collector.hasOwnProperty(dataPath)) {
            //# overload field definition
            var breadcrumb = collector[dataPath].breadcrumb;
            var siblingBreadcrumb = field.breadcrumb;

            collector[dataPath].breadcrumb = (breadcrumb ? _lodash2.default.castArray(breadcrumb) : []).concat(siblingBreadcrumb || []).filter(_lodash2.default.identity);

            //# keeping siblings around in case we need it
            collector[dataPath].siblings = (collector[dataPath].siblings || []).concat(field);
        } else {

            collector[dataPath] = field;
        }

        return collector;
    }, {});

    return _lodash2.default.reduce(uniqueColumnDefinitionsByKey, /*menu props*/function (acc, field) {
        var key = field.key,
            componentName = field.componentName;
        //# NOTE: We are omitting Object and Repeater components from column defintions
        //# here instead of setting `listerConfig.excludeFromColumnSelection` in these
        //# components' `getDefaultProps` since that would then mean that if these
        //# components had `children`, then the children would be omitted from column
        //# defintions. If this list starts to grow - we might want to think about how
        //# to better handle

        return key && componentName !== 'Object' && componentName !== 'Repeater' ? acc.concat(columnDefinitionWithMenuItemProps(field)) : acc;
    }, []);
}

function flattenedSearchableFields() {
    var searchableFields = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

    //# Flatten searchable fields list for consumption by select columns UI
    var flattenedFields = searchableFields.reduce(function (acc, field) {
        var _field$children = field.children,
            children = _field$children === undefined ? [] : _field$children,
            _field$childrenPropsB = field.childrenPropsByKey,
            childrenPropsByKey = _field$childrenPropsB === undefined ? {} : _field$childrenPropsB,
            _field$listerConfig = field.listerConfig,
            listerConfig = _field$listerConfig === undefined ? { excludeFromColumnSelection: false } : _field$listerConfig,
            nestingOf = field.nestingOf;


        var shouldExcludeFromColumnDefintions = listerConfig.excludeFromColumnSelection
        //# Note: we previously erroneously excluded fields with childrenPropsByKey due to a bug during refactoring from flatten searchableFields to nested searchableFields
        //# where the internal children fields were included as siblings of the parent (eg. [profile, first_name, last_name] when it should have been [{profile, children: [first_name...]}]
        //# NOTE: We're excluding nested fields for now
        //# We might look to have select columns include nested fields in the future
        || nestingOf;

        if (shouldExcludeFromColumnDefintions) {
            return acc;
        }

        //# NOTE: this happens before the check for "Object" and "Repeater"
        //# UPDATE: we'll also skip any fields with children under the assumption that it's a container element
        //# so we don't want to allow selection of container elements (this applies to complex fields like ProfileIdentity)
        //# it doesn't apply to DatetimePicker because we do not return children for DatetimePicker when calling on "SearchableFields"
        //# since the DatetimePicker is treated as a single datapoint
        var hasChildren = field.children && field.children.length;

        return acc.concat(hasChildren ? [] : _extends({}, field, { children: [] }), !_lodash2.default.isEmpty(children) ? flattenedSearchableFields(children, depth + 1) : []);
    }, []);

    return flattenedFields;
}

function columnDefinitionWithMenuItemProps(columnDefinition) {
    var _React$createElement, _extends2;

    var breadcrumb = columnDefinition.breadcrumb,
        label = columnDefinition.label,
        _columnDefinition$lis = columnDefinition.listerConfig,
        listerConfig = _columnDefinition$lis === undefined ? {} : _columnDefinition$lis;


    var stringifiedBreadcrumb = _lodash2.default.isArray(breadcrumb) ? breadcrumb.join(', ') : breadcrumb;

    var secondaryTextStyle = {
        style: {
            fontSize: '10px',
            float: 'none',
            order: 2,
            lineHeight: '1em',
            whiteSpace: 'normal'
        }
    };

    var menuItemProps = {
        style: {
            paddingLeft: '10px'
        },
        innerDivStyle: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        primaryText: listerConfig.label || label,
        secondaryText: breadcrumb ? _react2.default.createElement(
            'span',
            _extends({ className: 'filterTagBreadcrumbWrapper'
            }, secondaryTextStyle, (_extends2 = {
                'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'columnDefinitionsFromSearchableFields'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'span'), _defineProperty(_extends2, 'data-codecept-selector-file', 'columnDefinitionsFromSearchableFields'), _defineProperty(_extends2, 'data-codecept-selector-node', 'span'), _defineProperty(_extends2, 'data-codecept-selector-file', 'columnDefinitionsFromSearchableFields'), _extends2)),
            _react2.default.createElement(
                'span',
                (_React$createElement = { className: 'filterTagBreadcrumb', 'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'columnDefinitionsFromSearchableFields'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'columnDefinitionsFromSearchableFields'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'columnDefinitionsFromSearchableFields'), _React$createElement),
                stringifiedBreadcrumb
            )
        ) : null
    };

    return _extends({}, columnDefinition, { menuItemProps: menuItemProps });
}

exports.default = columnDefinitionsFromSearchableFields;