"use strict";

//import {batchActions} from 'redux-batched-actions';

module.exports = function () {
    var id = this.getID();

    var TYPES = {
        RESET_STATE: "@ruby-app/" + id + "/RESET_STATE"
    };

    return {
        TYPES: TYPES,
        generators: {
            resetState: function resetState(state) {
                return {
                    type: TYPES.RESET_STATE,
                    payload: {
                        state: state //# optional, defaults to using this.getInitialState()
                    }
                };
            }
        }
    };
};