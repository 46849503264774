'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _ActivityLister = require('./ActivityLister');

var _ActivityLister2 = _interopRequireDefault(_ActivityLister);

var _proposalPluginUtils = require('../../../../ruby-component-plugin-proposal/src/common/proposalPluginUtils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CAN_DOWNLOAD_PROPOSAL_PERM = {
    keyword: 'can_generate_proposal_doc',
    subsite: true,
    ruby_client: true
};

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector,
        forms = _selfModule$getDepend.forms,
        currentUserSelector = _selfModule$getDepend.currentUserSelector,
        editPage = _selfModule$getDepend.editPage,
        editPageSelector = _selfModule$getDepend.editPageSelector,
        checkShouldDisableSave = _selfModule$getDepend.checkShouldDisableSave,
        frontendSettingsComponent = _selfModule$getDepend.frontendSettingsComponent;

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);
        var rubyUser = currentUserSelector(state);
        var editPageState = editPageSelector(state);
        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);

        //# create activities array from selfState.activitiesById and activitiesIdArray
        var activities = selfState.activitiesIdArray.map(function (id) {
            return selfState.activitiesById[id];
        });
        var canUserDownload = selfModule.doesUserHavePermission(CAN_DOWNLOAD_PROPOSAL_PERM);

        var templateKey = selfModule.getProps(true).templateKey;

        var proposalSubsiteId = (0, _proposalPluginUtils.getProposalSubsiteId_withSettings)(frontendSettingsComponent.getState());
        var isProposalSubsite = proposalSubsiteId == selfModule.getActiveSubsiteId();

        var shouldHidePreviewLinks = isProposalSubsite;

        return _extends({}, ownProps, mixinFieldProps, selfState, {
            rubyUser: rubyUser,
            selfModule: selfModule,
            activities: activities,
            canUserPublish: selfModule.canUserPublish(),
            checkShouldDisableSave: checkShouldDisableSave,
            canUserDownload: canUserDownload,
            shouldHidePreviewLinks: ownProps.hidePreviewLinks || isProposalSubsite,
            shouldHideRevertLinks: ownProps.hideRevertLinks,
            contentApprovalFlag: _lodash2.default.get(editPageState, 'contentApprovalData') // TODO: is this a good idea?
            , getWordDocGenerationStatus_fromActivity: selfModule.getWordDocGenerationStatus_fromActivity.bind(selfModule),
            STATUS_TYPES: _lodash2.default.get(selfModule, 'CONSTANTS.STATUS_TYPES'),
            templateKey: templateKey,
            forms: forms
        });
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_ActivityLister2.default);
}