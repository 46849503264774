'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDnd = require('react-dnd');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var path = require('path');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

var deepDiff = require('deep-diff');

var DragHandleIcon = require('@rubyapps/ruby-material-ui/svg-icons/navigation/drag-handle').default;

var horizontalMoveThreshold = 100;
var minRowHeight = 49;

var hoverStartTime = null;
var prevHoverId = null;

var PROPKEYS_TO_COMPARE = ['isDragging', 'utilityBarProperties.sortable_enabled'];

exports.default = function (RowComponent) {
    var _class, _temp, _class2, _temp2;

    var initialXOffset = null;
    var initialDragId = null;
    var initialTableData = null;
    var initialIndexPath = null;

    function doHoverActions(props, monitor, component) {
        var dragIndexPath = _lodash2.default.get(monitor.getItem(), 'indexPath');
        var dragId = _lodash2.default.get(monitor.getItem(), 'id');

        var hoverIndexPath = _lodash2.default.get(props, 'rowData.__metadata.indexPath');
        var hoverId = _lodash2.default.get(props, 'rowData.id');

        // Determine mouse position
        var clientOffset = monitor.getClientOffset();
        var currentXOffset = clientOffset.x;
        var currentYOffset = clientOffset.y;

        // Depth Change
        var horizontalDelta = currentXOffset - initialXOffset;
        var depthDelta = horizontalDelta < 0 ? Math.ceil(horizontalDelta / horizontalMoveThreshold * 2) : Math.floor(horizontalDelta / horizontalMoveThreshold);

        if (dragIndexPath == null || dragId == null || hoverIndexPath == null || hoverId == null) {
            return;
        }

        if (
        // if currently hovered item is the currently dragged item, or one if it's children
        _lodash2.default.isEqual(dragIndexPath, hoverIndexPath.slice(0, dragIndexPath.length))) {

            var previousRowPathIndex = getPreviousPathIndex_fromPathIndex(dragIndexPath);
            if (previousRowPathIndex == null) {
                return;
            }
            var previousRowDepth = previousRowPathIndex.length;
            var dragRowDepth = dragIndexPath.length;
            var addAsFirstChild = false;
            var newDragIndexPath = void 0;
            if (dragRowDepth === previousRowDepth && depthDelta > 0) {
                newDragIndexPath = previousRowPathIndex.concat(0);

                initialXOffset = initialXOffset + horizontalMoveThreshold;
                addAsFirstChild = true;
            } else if (depthDelta < 0 && previousRowPathIndex.length > 0) {
                newDragIndexPath = [].concat(previousRowPathIndex.slice(0, -1), previousRowPathIndex.slice(-1)[0] + 1);
                initialXOffset = initialXOffset - horizontalMoveThreshold;
            }
            // Time to actually perform the action
            if (newDragIndexPath != null) {
                if (monitor.getItem()) {
                    if (addAsFirstChild) {
                        var previousRow = props.tableData.find(function (row) {
                            return _lodash2.default.isEqual(previousRowPathIndex, _lodash2.default.get(row, '__metadata.indexPath'));
                        });
                        props.events.moveRow_withIdToFirstChildOfRow_withId(dragId, previousRow.id);
                    } else {
                        props.events.moveRow_withId_toIdxPath(initialDragId, newDragIndexPath);
                    }
                    //                    props.events.moveRow_fromIdxPath_toIdxPath(dragIndexPath, newDragIndexPath);
                    // Note: we're mutating the monitor item here!
                    // Generally it's better to avoid mutations,
                    // but it's good here for the sake of performance
                    // to avoid expensive index searches.
                    monitor.getItem().indexPath = newDragIndexPath;
                }
            }
        } else {

            // Determine rectangle on screen
            var hoverBoundingRect = (0, _reactDom.findDOMNode)(component).getBoundingClientRect();

            // Get vertical middle
            var hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Get pixels to the top
            var hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            var dragDir = compare_indexPath1_toIndexPath2(dragIndexPath, hoverIndexPath) === 'ORDERED_ASCENDING' ? 'down' : 'up';
            var _newDragIndexPath = dragIndexPath;
            _newDragIndexPath = dragDir === 'up' && hoverClientY < minRowHeight ? hoverIndexPath : _newDragIndexPath;
            _newDragIndexPath = dragDir === 'down' && hoverClientY > hoverBoundingRect.height - minRowHeight ? hoverIndexPath : _newDragIndexPath;

            // Dragging horizontally
            if (compare_indexPath1_toIndexPath2(dragIndexPath, _newDragIndexPath) === 'ORDERED_SAME') {
                return;
            }

            var expandedFlag = _lodash2.default.get(props, 'rowData.__metadata.expandedFlag');
            var children = _lodash2.default.get(props, 'rowData.children');
            var _addAsFirstChild = false;

            if (dragId != hoverId) {
                if (hoverClientY < hoverMiddleY && !expandedFlag) {} else if (dragDir === 'down' && expandedFlag && Array.isArray(children) && children.length > 0) {
                    _newDragIndexPath = hoverIndexPath.concat(0);
                    _addAsFirstChild = true;
                }
            }
            if (monitor.getItem()) {
                // Time to actually perform the action
                if (_addAsFirstChild) {
                    props.events.moveRow_withIdToFirstChildOfRow_withId(dragId, hoverId);
                } else {
                    props.events.moveRow_withId_toIdxPath(initialDragId, _newDragIndexPath);
                }
                //props.events.moveRow_fromIdxPath_toIdxPath(dragIndexPath, newDragIndexPath);
                // Note: we're mutating the monitor item here!
                // Generally it's better to avoid mutations,
                // but it's good here for the sake of performance
                // to avoid expensive index searches.
                monitor.getItem().indexPath = _newDragIndexPath;
            }
        }
    }

    var requestedFrame = null;
    function scheduleUpdate(props, monitor, component) {
        cancelAnimationFrame(requestedFrame);
        requestedFrame = requestAnimationFrame(function () {
            doHoverActions(props, monitor, component);
        });
    }

    var rowSource = {
        beginDrag: function beginDrag(props, monitor) {
            props.events.onBeginDrag();
            document.documentElement.classList.add('griddleIsDragging');
            initialTableData = props.tableData;
            hoverStartTime = null;
            cancelAnimationFrame(requestedFrame);
            requestedFrame = null;
            var indexPath = props.rowData.__metadata.indexPath;
            initialIndexPath = indexPath;
            initialDragId = props.rowData.id;
            initialXOffset = null;
            if (_lodash2.default.get(props, 'rowData.__metadata.expandedFlag')) {
                setTimeout(function () {
                    props.events.expandRow(props.rowData.__metadata.griddleKey);
                }, 10);
            }
            return {
                originalIndexPath: indexPath,
                indexPath: indexPath,
                id: initialDragId
            };
        },
        isDragging: function isDragging(props, monitor) {
            return props.rowData.id === monitor.getItem().id;
        },
        endDrag: function endDrag(props, monitor, component) {
            document.documentElement.classList.remove('griddleIsDragging');
            var movedRow = props;
            initialXOffset = null;
            initialDragId = null;

            //const endIndexPath = props.rowData.__metadata.indexPath;
            //# endIndexPath is wrong (it looks like it's the former value, we never get the updated value).
            //# we should not rely on stateful data from props
            //# so we're selecting the data from monitor instead
            var monitoredItem = monitor.getItem();
            var initialIndexPath = monitoredItem.indexPath,
                endIndexPath = monitoredItem.originalIndexPath;


            if (movedRow !== null, !_lodash2.default.isEqual(initialIndexPath, endIndexPath)) {
                props.events.makeRerankRequest(movedRow, props.events, monitoredItem);
            }
            // cancel requested hoveractions
            cancelAnimationFrame(requestedFrame);
        }
    };

    var prevHoverArgs = null;
    var rowTarget = {
        drop: function drop(props, monitor, component) {
            logger.debug('drop', props, monitor, component);
        },
        hover: function hover(props, monitor, component) {
            var hoverIndexPath = _lodash2.default.get(props, 'rowData.__metadata.indexPath');
            var hoverId = _lodash2.default.get(props, 'rowData.id');
            var dragIndexPath = _lodash2.default.get(monitor.getItem(), 'indexPath');
            var dragId = _lodash2.default.get(monitor.getItem(), 'id');

            // Determine mouse position
            var clientOffset = monitor.getClientOffset();
            var currentXOffset = clientOffset.x;
            var currentYOffset = clientOffset.y;

            if (!initialXOffset) {
                initialXOffset = monitor.getInitialClientOffset().x;
            }

            // initialize hoverStartTime
            if (
            // just started hovering
            hoverStartTime === null
            // or hover item changed,
            || !_lodash2.default.isEqual(hoverId, prevHoverId)) {
                hoverStartTime = Date.now();
                prevHoverId = hoverId;
            }

            // Determine rectangle on screen
            var hoverBoundingRect = (0, _reactDom.findDOMNode)(component).getBoundingClientRect();

            // Get vertical middle
            var hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Get pixels to the top
            var hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Depth Change
            var horizontalDelta = currentXOffset - initialXOffset;
            var depthDelta = horizontalDelta < 0 ? Math.floor(horizontalDelta / horizontalMoveThreshold) : Math.floor(horizontalDelta / horizontalMoveThreshold);

            var newHoverArgs = {
                props: props,
                monitor: monitor,
                component: component,
                hoveringOnTop: hoverClientY < minRowHeight,
                hoveringOnBot: hoverClientY > hoverBoundingRect.height - minRowHeight,
                depthDelta: depthDelta
            };
            if (!_lodash2.default.isEqual(prevHoverArgs, newHoverArgs)) {
                prevHoverArgs = newHoverArgs;
                scheduleUpdate(props, monitor, component);
            }
        }
    };

    var DropTargetDecorator = (0, _reactDnd.DropTarget)('row', rowTarget, function (connect, monitor) {
        return {
            connectDropTarget: connect.dropTarget(),
            isOver: monitor.isOver()
        };
    });
    var DragSourceDecorator = (0, _reactDnd.DragSource)('row', rowSource, function (connect, monitor) {
        return {
            connectDragSource: connect.dragSource(),
            connectDragPreview: connect.dragPreview(),
            isDragging: monitor.isDragging()
        };
    });

    var DragRow = (_temp = _class = function (_Component) {
        _inherits(DragRow, _Component);

        function DragRow() {
            _classCallCheck(this, DragRow);

            return _possibleConstructorReturn(this, (DragRow.__proto__ || Object.getPrototypeOf(DragRow)).apply(this, arguments));
        }

        _createClass(DragRow, [{
            key: 'shouldComponentUpdate',
            value: function shouldComponentUpdate(nextProps, nextState) {

                var shouldComponentUpdateByComponent = DragRow.staticShouldComponentUpdateByComponent(this.props, nextProps, this.state, nextState);

                this._shouldComponentUpdateByComponent = shouldComponentUpdateByComponent; //# cached for erender

                return _lodash2.default.reduce(shouldComponentUpdateByComponent, function (collector, value, key) {
                    return collector || value;
                }, false);
            }
        }, {
            key: 'render',
            value: function render() {
                var modifySitemapToggledOn = _lodash2.default.get(this, 'props.utilityBarProperties.sortable_enabled');

                if (!modifySitemapToggledOn) {
                    return _react2.default.createElement(RowComponent, _extends({}, this.props, {
                        shouldComponentUpdateByComponent: this._shouldComponentUpdateByComponent,
                        'data-codecept-selector-node': 'RowComponent',
                        'data-codecept-selector-file': 'row'
                    }));
                }

                var _props = this.props,
                    connectDragSource = _props.connectDragSource,
                    connectDragPreview = _props.connectDragPreview,
                    isDragging = _props.isDragging,
                    rest = _objectWithoutProperties(_props, ['connectDragSource', 'connectDragPreview', 'isDragging']);

                var styleOverrides = {};
                var id = this.props.rowData.id;

                if (isDragging || initialDragId === id) {
                    styleOverrides = {
                        inlineStyles: {
                            row: { background: 'rgba(1, 72, 117, .2)' }
                        }
                    };
                }

                var styles = _lodash2.default.merge({}, this.props.styles, styleOverrides);
                var isDraggableRow = !_lodash2.default.get(this, 'props.rowData.__metadata.rerank_disabled');
                var dndColumn = !isDraggableRow ? undefined : connectDragSource(_react2.default.createElement(
                    'div',
                    { key: 'drag-handle-wrapper', 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'row'
                    },
                    _react2.default.createElement(DragHandleIcon, {
                        color: '#adadad',
                        style: {
                            cursor: 'move',
                            display: 'flex'
                        },
                        'data-codecept-selector-node': 'DragHandleIcon',
                        'data-codecept-selector-file': 'row'
                    })
                ));
                var newRowData = _extends({}, { dndColumn: dndColumn }, this.props.rowData);

                // connectDragSource only accepts native element nodes
                //   using workaround from:
                //   https://github.com/gaearon/react-dnd/issues/305#issuecomment-148377074
                return _react2.default.createElement(RowComponent, _extends({}, rest, {
                    isDraggable: true,
                    styles: styles,
                    ref: function ref(instance) {
                        return connectDragPreview((0, _reactDom.findDOMNode)(instance));
                    },
                    rowData: newRowData,
                    shouldComponentUpdateByComponent: this._shouldComponentUpdateByComponent,
                    'data-codecept-selector-node': 'RowComponent',
                    'data-codecept-selector-file': 'row'
                }));
            }
        }], [{
            key: 'staticShouldComponentUpdateByComponent',
            value: function staticShouldComponentUpdateByComponent(props, nextProps, state, nextState) {
                var shouldComponentUpdateByComponent_next = nextProps.shouldComponentUpdateByComponent || {};

                if (shouldComponentUpdateByComponent_next.hasOwnProperty(DragRow.name) || shouldComponentUpdateByComponent_next.hasOwnProperty(RowComponent.name)) {
                    return shouldComponentUpdateByComponent_next;
                }

                var PROPKEYS_TO_COMPARE = DragRow.PROPKEYS_TO_COMPARE;
                var propsToCompare_curr = _lodash2.default.pick(props, PROPKEYS_TO_COMPARE);
                var propsToCompare_next = _lodash2.default.pick(nextProps, PROPKEYS_TO_COMPARE);

                var shouldComponentUpdate_self = !_lodash2.default.isEqual(propsToCompare_curr, propsToCompare_next);
                var shouldComponentUpdate_curriedByComponent = RowComponent.staticShouldComponentUpdateByComponent ? RowComponent.staticShouldComponentUpdateByComponent.apply(null, arguments) : {};

                logger.debug('[' + DragRow.name + ' > ' + RowComponent.name + ', : ' + props.rowData.name + '] shouldComponentUpdate_self: ' + shouldComponentUpdate_self + ' ... shouldComponentUpdate_curriedByComponent:', shouldComponentUpdate_curriedByComponent);

                return _extends(_defineProperty({}, DragRow.name, shouldComponentUpdate_self), shouldComponentUpdate_curriedByComponent);
            }

            //static defaultProps = {...Component.defaultProps};
            //constructor(props, context) {super(props, context);}

        }]);

        return DragRow;
    }(_react.Component), _class.PropTypes = _extends({}, RowComponent.PropTypes), _class.PROPKEYS_TO_COMPARE = ['isDragging', 'utilityBarProperties.sortable_enabled'], _temp);


    DragRow.propTypes = {
        connectDragSource: _react.PropTypes.func.isRequired,
        isDragging: _react.PropTypes.bool.isRequired
    };

    /*
    DragRow.contextTypes = {
        dragDropManager: PropTypes.object
    };
    */ //# if we need to do something like this.context.dragDropManager.getAction().endDrag() programmatically

    var DropRow = (_temp2 = _class2 = function (_DragRow) {
        _inherits(DropRow, _DragRow);

        function DropRow() {
            _classCallCheck(this, DropRow);

            return _possibleConstructorReturn(this, (DropRow.__proto__ || Object.getPrototypeOf(DropRow)).apply(this, arguments));
        }

        _createClass(DropRow, [{
            key: 'shouldComponentUpdate',
            value: function shouldComponentUpdate(nextProps, nextState) {

                var shouldComponentUpdateByComponent = DropRow.staticShouldComponentUpdateByComponent(this.props, nextProps, this.state, nextState);

                this._shouldComponentUpdateByComponent = shouldComponentUpdateByComponent; //# cached for erender

                return _lodash2.default.reduce(shouldComponentUpdateByComponent, function (collector, value, key) {
                    return collector || value;
                }, false);
            }
        }, {
            key: 'render',
            value: function render() {
                var modifySitemapToggledOn = _lodash2.default.get(this, 'props.utilityBarProperties.sortable_enabled');

                if (!modifySitemapToggledOn || _lodash2.default.get(this, 'props.rowData.__metadata.rerank_disabled')) {
                    return _react2.default.createElement(DragRow, _extends({}, this.props, {
                        shouldComponentUpdateByComponent: this._shouldComponentUpdateByComponent,
                        'data-codecept-selector-node': 'DragRow',
                        'data-codecept-selector-file': 'row'
                    }));
                }

                var _props2 = this.props,
                    connectDropTarget = _props2.connectDropTarget,
                    rest = _objectWithoutProperties(_props2, ['connectDropTarget']);
                // connectDragSource only accepts native element nodes
                //   using workaround from:
                //   https://github.com/gaearon/react-dnd/issues/305#issuecomment-148377074


                return _react2.default.createElement(DragRow, _extends({}, rest, {
                    shouldComponentUpdateByComponent: this._shouldComponentUpdateByComponent,
                    ref: function ref(instance) {
                        return connectDropTarget((0, _reactDom.findDOMNode)(instance));
                    }, 'data-codecept-selector-node': 'DragRow',
                    'data-codecept-selector-file': 'row'
                }));
            }
        }], [{
            key: 'staticShouldComponentUpdateByComponent',
            value: function staticShouldComponentUpdateByComponent(props, nextProps, state, nextState) {
                var shouldComponentUpdateByComponent_next = nextProps.shouldComponentUpdateByComponent || {};

                if (shouldComponentUpdateByComponent_next.hasOwnProperty(DropRow.name) || shouldComponentUpdateByComponent_next.hasOwnProperty(RowComponent.name)) {
                    return shouldComponentUpdateByComponent_next;
                }

                var PROPKEYS_TO_COMPARE = DropRow.PROPKEYS_TO_COMPARE;
                var propsToCompare_curr = _lodash2.default.pick(props, PROPKEYS_TO_COMPARE);
                var propsToCompare_next = _lodash2.default.pick(nextProps, PROPKEYS_TO_COMPARE);

                var shouldComponentUpdate_self = !_lodash2.default.isEqual(propsToCompare_curr, propsToCompare_next);
                var shouldComponentUpdate_curriedByComponent = DragRow.staticShouldComponentUpdateByComponent ? DragRow.staticShouldComponentUpdateByComponent.apply(null, arguments) : {};

                logger.debug('[' + DropRow.name + ' > ' + DragRow.name + ', : ' + props.rowData.name + '] shouldComponentUpdate_self: ' + shouldComponentUpdate_self + ' ... shouldComponentUpdate_curriedByComponent:', shouldComponentUpdate_curriedByComponent);

                return _extends(_defineProperty({}, DropRow.name, shouldComponentUpdate_self), shouldComponentUpdate_curriedByComponent);
            }
        }]);

        return DropRow;
    }(DragRow), _class2.PROPKEYS_TO_COMPARE = ['utilityBarProperties.sortable_enabled'], _temp2);


    DropRow.propTypes = {
        connectDropTarget: _react.PropTypes.func.isRequired
    };

    return DragSourceDecorator(DropTargetDecorator(DropRow));
};

function getPreviousPathIndex_fromPathIndex(pathIndex) {
    if (
    // absolute first item
    _lodash2.default.isEqual(pathIndex, [0])) {
        return undefined;
    } else if (
    // first child
    pathIndex.slice(-1)[0] === 0 && pathIndex.length > 1) {
        return pathIndex.slice(0, -1);
    } else if (
    // not first child
    pathIndex.slice(-1)[0] > 0) {
        return pathIndex.slice(0, -1).concat(pathIndex.slice(-1) - 1);
    }
}

function compare_indexPath1_toIndexPath2(indexPath1, indexPath2) {
    if (_lodash2.default.isEqual(indexPath1, indexPath2)) {
        return 'ORDERED_SAME';
    } else {
        var minPathLength = Math.min(indexPath1.length, indexPath2.length);

        for (var idx = 0; idx < minPathLength; ++idx) {
            if (indexPath1[idx] < indexPath2[idx]) {
                return 'ORDERED_ASCENDING';
            } else if (indexPath1[idx] > indexPath2[idx]) {
                return 'ORDERED_DESCENDING';
            }
        }

        // if all the elements matched so far, then the longer path is a child
        if (indexPath1.length < indexPath2.length) {
            return 'ORDERED_ASCENDING';
        } else if (indexPath1.length > indexPath2.length) {
            return 'ORDERED_DESCENDING';
        }

        throw "Error comparing indexPaths: (" + indexPath1 + ") and (" + indexPath2 + ")";
    }
}