"use strict";

function typesFromID(id) {
    var TYPES = {
        SET_LOADING: "@@ruby-app/" + id + "/LOADING"
    };
    return TYPES;
}

var generators = {
    setLoading: function setLoading(isLoading) {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        var selfModule = this;

        return {
            type: TYPES.SET_LOADING,
            payload: isLoading
        };
    }
};

module.exports = function () {
    var TYPES = typesFromID(this.getID());
    return {
        TYPES: TYPES,
        generators: generators
    };
};