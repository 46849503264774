'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GRIDDLE_BAR_PROPS_UPDATE = GRIDDLE_BAR_PROPS_UPDATE;
function GRIDDLE_BAR_PROPS_UPDATE(state, action, helpers) {
  var newProps = action.newProps;

  return state.set('utilityBarProperties', newProps);
}