'use strict';

var request = require('@rubyapps/ruby-superagent');
var _ = require('lodash');

var rubyMiddlewareFields = require('@rubyapps/ruby-middleware-fields');

var TYPES = {
    OPEN_DIALOG: '@@ruby-app/alert-dialog/OPEN_DIALOG',
    CLOSE_DIALOG: '@@ruby-app/alert-dialog/CLOSE_DIALOG',

    SET_LOADING: '@@ruby-app/alert-dialog/SET_LOADING',
    CLEAR_LOADING: '@@ruby-app/alert-dialog/CLEAR_LOADING',

    SET_CONTENT: '@@ruby-app/alert-dialog/SET_CONTENT',

    SET_OPTIONS: '@@ruby-app/alert-dialog/SET_OPTIONS',
    RESET_DIALOG: '@@ruby-app/alert-dialog/RESET_DIALOG'
};

var generators = {
    //# options.parent_id
    openDialog: function openDialog(options) {
        return {
            type: TYPES.OPEN_DIALOG
        };
    }
    /**
     *
     *  options: {
     *      title: the title of the dialog
     *      content: the content / message of the dialog
     *      endpoint: the endpoint to submit the form to
     *      submitSuccessCallback: fn()
     *      submitFailCallback: fn()
     *  }
     *
     **/
    , openDialogWithOptions: function openDialogWithOptions(options) {
        var generators = this.getAction().generators;
        return function (dispatch, getState) {
            dispatch(generators.setOptions(options));
            dispatch(generators.openDialog());
        };
    },
    closeDialog: function closeDialog() {
        return {
            type: TYPES.CLOSE_DIALOG
        };
    },
    closeAndResetDialog: function closeAndResetDialog() {
        var _this = this;

        var selfModule = this;
        return function (dispatch) {
            var _getState = _this.getState(),
                closeCallback = _getState.closeCallback;

            if (closeCallback) {
                closeCallback();
            }
            dispatch(generators.closeDialog());
            dispatch(generators.resetDialog());
        };
    },
    setOptions: function setOptions(options) {
        var selfModule = this;
        var generators = selfModule.getAction().generators;

        return {
            type: TYPES.SET_OPTIONS,
            payload: options
        };
    },
    setLoading: function setLoading() {
        return {
            type: TYPES.SET_LOADING
        };
    },
    clearLoading: function clearLoading() {
        return {
            type: TYPES.CLEAR_LOADING
        };
    },
    setContent: function setContent(content) {
        return {
            type: TYPES.SET_CONTENT,
            payload: content
        };
    },
    resetDialog: function resetDialog() {
        return {
            type: TYPES.RESET_DIALOG
        };
    },

    submit: function submit() {
        var selfModule = this;
        var selfSelectorObj = selfModule.getSelector();
        var generators = selfModule.getAction().generators;

        return function (dispatch, getState) {
            var applicationState = getState();
            var selfState = selfSelectorObj.default(applicationState);

            var errorContext = selfState.errorContext,
                endpoint = selfState.endpoint;

            var fields = selfState.fields;
            var data = _.reduce(fields, function (collector, fieldObj, fieldKey) {
                collector[fieldKey] = fieldObj.value;
                return collector;
            }, {});

            // TODO: show loader, don't allow actions for closing or submitting to continue
            dispatch(generators.setLoading());

            return request.post(endpoint).send(data).then(function onResult(response) {
                dispatch(generators.clearLoading());

                dispatch(generators.closeDialog());

                selfState.submitSuccessCallback && selfState.submitSuccessCallback(response);
            }, function onError(err) {
                dispatch(generators.clearLoading());
                selfState.submitFailCallback && selfState.submitFailCallback(err);
            });
        };
    }

};

module.exports = {
    TYPES: TYPES,
    generators: generators
};