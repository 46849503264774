'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _VirtualMenu = require('@rubyapps/ruby-material-ui/VirtualMenu');

var _VirtualMenu2 = _interopRequireDefault(_VirtualMenu);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _Card = require('material-ui/Card');

var _RaisedButton = require('material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _ListerDialog = require('./ListerDialog');

var _ListerDialog2 = _interopRequireDefault(_ListerDialog);

var _Loading = require('@rubyapps/ruby-react-components/src/client/view/Loading');

var _Loading2 = _interopRequireDefault(_Loading);

var _keycode = require('keycode');

var _keycode2 = _interopRequireDefault(_keycode);

var _TokenTagger_style = require('./TokenTagger_style');

var _TokenTagger_style2 = _interopRequireDefault(_TokenTagger_style);

var _SuggestionList = require('./SuggestionList');

var _SuggestionList2 = _interopRequireDefault(_SuggestionList);

var _TokenTaggerList = require('./TokenTaggerList');

var _TokenTaggerList2 = _interopRequireDefault(_TokenTaggerList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var request = require('@rubyapps/ruby-superagent');

//# Material UI

var Popover = require('@rubyapps/ruby-material-ui/Popover').default;

var ListerDialog__propTypeKeys = Object.keys(_ListerDialog2.default.propTypes);

//# Icons

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label,
    RichErrorMessages = _require.RichErrorMessages;
//import addInfoModeProps from '@rubyapps/ruby-react-components/src/client/hoc/addInfoModeProps';

var RubyCodeUtils = require('@rubyapps/ruby-code-utils');

var PropTypes = _react2.default.PropTypes;

var styles = process.browser ? require('./TokenTagger.cssModule').default : {};

var TokenTaggerList_propTypeKeys = Object.keys(_TokenTaggerList2.default.propTypes);

var TokenTagger = _react2.default.createClass({
    displayName: 'TokenTagger',

    propTypes: _extends({
        actions: PropTypes.shape({
            setFieldValueByKey: PropTypes.func
        }),
        canCreateSomeEntry: PropTypes.bool,
        count: PropTypes.number //# the count param for url
        , fieldKey: PropTypes.string,
        hintText: PropTypes.string,
        isMounted: PropTypes.bool,
        label: PropTypes.string,
        limit: PropTypes.number //# the number of items you can select
        , preloadOptions: PropTypes.bool,
        requireSearchValueForDropdown: PropTypes.bool,
        options: PropTypes.array,
        templateOptions: PropTypes.array,
        filteredOptions: PropTypes.array,
        searchValue: PropTypes.string,
        showCreateEntryButton: PropTypes.bool,
        url: PropTypes.string,
        utilities: PropTypes.shape({
            createEntryAsync: PropTypes.func
        }),
        value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
        optionToHTML: PropTypes.func
    }, _ListerDialog2.default.propTypes),
    getDefaultProps: function getDefaultProps() {
        return {
            style: {},
            requireSearchValueForDropdown: false,
            options: [],
            filteredOptions: [],
            templateOptions: []
        };
    },
    getInitialState: function getInitialState() {
        return {
            isCreatingEntry: false,
            menuFocused: false

            //, primaryTextById: {}

            , shouldAutoFocusPopoverMenu: false //# synonymous with the keyboard being focused
            , showResults: false
        };
    },
    menuWidth: function menuWidth() {
        return this._anchorEl ? this._anchorEl.offsetWidth : null;
    },
    filteredMenuItems: function filteredMenuItems() {
        var _this = this,
            _React$createElement2;

        var selfValues = this.values();
        var menuWidth = this.menuWidth();

        var filteredOptions = this.props.filteredOptions || [];
        return filteredOptions.length ? filteredOptions.map(function (option, idx) {
            var _React$createElement, _extends2, _extends3;

            var isSelected = selfValues.map(function (el) {
                return el.toString();
            }).indexOf((option.value || option.id).toString()) > -1;
            return _react2.default.createElement(_MenuItem2.default, _extends({
                key: idx,
                insetChildren: true,
                checked: isSelected
            }, _TokenTagger_style2.default.MenuItem, option, (_extends3 = {
                primaryText: _react2.default.createElement(
                    'div',
                    (_React$createElement = { className: styles.primaryText, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'TokenTagger'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement),
                    _this.getPrimaryText(option)
                ),
                value: (option.value || option.id).toString(),
                style: { width: menuWidth },
                secondaryText: _react2.default.createElement(
                    'span',
                    _extends({}, _TokenTagger_style2.default.menuItem_secondaryText, (_extends2 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'TokenTagger'
                    }, _defineProperty(_extends2, 'data-codecept-selector-node', 'span'), _defineProperty(_extends2, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends2, 'data-codecept-selector-node', 'span'), _defineProperty(_extends2, 'data-codecept-selector-file', 'TokenTagger'), _extends2)),
                    option.breadcrumb
                ),
                'data-codecept-selector-node': 'MenuItem',
                'data-codecept-selector-file': 'TokenTagger'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends3, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends3, 'data-codecept-selector-file', 'TokenTagger'), _extends3)));
        }) : _react2.default.createElement(_MenuItem2.default, (_React$createElement2 = {
            key: 'noResults',
            disabled: true,
            primaryText: 'No Results Found',
            'data-codecept-selector-node': 'MenuItem',
            'data-codecept-selector-file': 'TokenTagger'
        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement2));
    },
    getPrimaryText: function getPrimaryText(option) {
        var _React$createElement3;

        var optionToHTML = this.props.optionToHTML;
        if (!optionToHTML || !_lodash2.default.isFunction(optionToHTML)) {
            return option.text || option.name;
        }

        return _react2.default.createElement('span', (_React$createElement3 = {
            dangerouslySetInnerHTML: {
                __html: RubyCodeUtils.sanitizeHTML(optionToHTML(option))
            },
            'data-codecept-selector-node': 'span',
            'data-codecept-selector-file': 'TokenTagger'
        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement3));
    },
    _onSearchValueChange: function _onSearchValueChange(event, value) {
        var self = this;
        this.props.actions.setSearchValue(value);
    },
    componentDidUpdate: function componentDidUpdate(prevProps, prevState) {
        if (this._isMounted && !this.props.isMounted) {
            if (this.props.preloadOptions) {
                this.props.actions.preloadOptions();
            }

            this.props.actions.setComponentIsMounted && this.props.actions.setComponentIsMounted();
        }
    },
    componentDidMount: function componentDidMount() {
        this._isMounted = true; //# NOTE: cannot use setState because that happens asynchronously which causes issues with the async setState call
        if (this.props.preloadOptions) {
            this.props.actions.preloadOptions();
        }

        this.props.actions.setComponentIsMounted && this.props.actions.setComponentIsMounted();
    },
    componentWillUnmount: function componentWillUnmount() {
        this._isMounted = false;
        //console.log(`[componentWillUnmount()] ${this.props.id}`);
    },
    _handleKeyDown: function _handleKeyDown(event) {
        switch ((0, _keycode2.default)(event)) {
            case 'down':
                event.preventDefault();
                if (!this.state.shouldAutoFocusPopoverMenu) {
                    //# set keyboard focused
                    this._popoverMenu && this._popoverMenu.setKeyboardFocused(true);
                }
                this.setState({
                    shouldAutoFocusPopoverMenu: true
                });
                break;
            case 'tab':
            case 'esc':
                this._onBlur_fromTextFieldKeyDown(event);
                break;
            default:
                break;
        }
    },
    _onValueChangeGenerator_forMenuItems: function _onValueChangeGenerator_forMenuItems(menuItems) {
        var _this2 = this;

        var self = this;
        return function (event, value) {
            var isLimitHit = value.length >= _this2.props.limit;

            self._onValueChange(event, value);
            if (isLimitHit) {
                //# if we hit the limit, we should close the menu and also clear the search term
                _this2._onSearchValueChange(event, '');

                _this2.setState({
                    showResults: false
                });
            }
        };
    },
    _onValueChange: function _onValueChange(event, value) {
        if (value.length > this.props.limit) {
            return false;
        }

        var newValues = _lodash2.default.uniq(value);
        this.props.actions.setFieldValueByKey(newValues, this.props.fieldKey);
    },
    _onBlur_fromTextFieldKeyDown: function _onBlur_fromTextFieldKeyDown(event) {
        //# clear textfield
        this._onSearchValueChange(event, '');
        this._anchorEl.getElementsByTagName('input')[0].blur();

        this.setState({
            shouldAutoFocusPopoverMenu: false,
            showResults: false
        });
    },
    _onBlur_fromTextFieldBlur: function _onBlur_fromTextFieldBlur(event) {
        //# Only allow textfield blur to close the popover menu when
        //# the textfield had requested the popover to autofocus
        if (!this.state.menuFocused && !this.state.shouldAutoFocusPopoverMenu) {
            this.setState({
                shouldAutoFocusPopoverMenu: false,
                showResults: false,
                menuFocused: false
            });
        }
    },
    _onItemTouchTap_forMenu: function _onItemTouchTap_forMenu(event, item, index) {
        //# NOTE: this fixes some UX inconsistencies with the <Menu> component in Material UI
        var _popoverMenu2 = this._popoverMenu,
            _popoverMenu = _popoverMenu2 === undefined ? {} : _popoverMenu2;

        var _popoverMenu__state = _popoverMenu.state || {};

        setImmediate(function () {
            if (_popoverMenu__state.isKeyboardFocused) {
                _popoverMenu.setFocusIndex && _popoverMenu.setFocusIndex(_popoverMenu__state.focusIndex, true);
            }
        });
    },
    _onEscKeyDown_fromMenu: function _onEscKeyDown_fromMenu() {

        //# focus text field
        this._anchorEl.getElementsByTagName('input')[0].focus();
    },
    _onPopoverRequestClose: function _onPopoverRequestClose(reason) {
        //# only close this is the popover was autofocused (ie the textfield was blurred)
        //# otherwise, we defer to the textfield blur event to dismiss the popover
        if (!this._searchTextField.state.isFocused) {
            this.setState({
                shouldAutoFocusPopoverMenu: false,
                showResults: false
            });
        }
    },
    _onTextFieldFocus: function _onTextFieldFocus() {
        var _this3 = this;

        //# select all text
        var searchTextField__input = _lodash2.default.get(this, ['_searchTextField', 'input']);
        searchTextField__input && searchTextField__input.setSelectionRange && searchTextField__input.setSelectionRange(0, searchTextField__input.value.length);

        //# defer triggering this until the textfield has a chance to fully open
        setTimeout(function () {
            _this3.setState({
                shouldAutoFocusPopoverMenu: false,
                showResults: true
                //, anchorEl: ReactDOM.findDOMNode(this.refs.searchTextField)
            });
        }, 100);
    },
    _removeSelectionGeneratorForId: function _removeSelectionGeneratorForId(id) {
        var _this4 = this;

        if (this.isLocked()) {
            return undefined;
        } else {
            return function () {
                var currentValue = _this4.props.value;
                var newValue = _lodash2.default.without(currentValue, id);
                _this4.props.actions.setFieldValueByKey(newValue, _this4.props.fieldKey);
            };
        }
    },
    _handleOnMove: function _handleOnMove(startIdx, endIdx) {

        if (this.isLocked()) {
            return;
        }

        // Get the sorted values
        var newValue = this.props.value;

        var dataToMove = newValue[startIdx];
        newValue = [].concat(_toConsumableArray(newValue.slice(0, startIdx)), _toConsumableArray(newValue.slice(startIdx + 1)));

        newValue = [].concat(_toConsumableArray(newValue.slice(0, endIdx)), [dataToMove], _toConsumableArray(newValue.slice(endIdx)));

        this.props.actions.setFieldValueByKey(newValue, this.props.fieldKey);
    },

    _handleAddSelections_withIds: function _handleAddSelections_withIds(ids) {
        var newValues = this.values().concat(ids);
        return this.props.actions.setFieldValueByKey(newValues, this.props.fieldKey);
    },
    _handleOnCreateEntryClick: function _handleOnCreateEntryClick(templateKey) {
        var _this5 = this;

        this.setState({
            isCreatingEntry: true
        });

        //# allocate content with name = this.props.searchValue
        //# on success/failure, set isCreatingEntry: false
        this.props.utilities.createEntryAsync(this.props.searchValue, templateKey).then(function (response) {
            _this5.setState({
                isCreatingEntry: false
            });

            var contentRecord = response.body;

            var newValues = _this5.values().concat(contentRecord.id);

            _this5.props.actions.updateCachedOptions_withAdditional([{
                text: _this5.props.searchValue,
                id: contentRecord.id
            }]);
            _this5.props.actions.setFieldValueByKey(newValues, _this5.props.fieldKey);

            //# clear out textfield
            _this5._onSearchValueChange(null, '');
        }, function (error) {
            _this5.setState({
                isCreatingEntry: false
            });

            var errorsMessages = _lodash2.default.get(error, 'response.body.error.errors', []).map(function (error) {
                return error.message;
            });

            _this5.props.utilities.showErrorNotification(_extends({
                error: error
            }, errorsMessages.length ? {
                message: errorsMessages.join(' ')
            } : {}));
            throw error;
        });
    },
    values: function values() {
        var props__value = this.props.value;
        return props__value ? _lodash2.default.castArray(props__value) : [];
    },
    suggestedValues: function suggestedValues() {
        var suggestedValues = _lodash2.default.isArray(this.props.suggestedValues) ? this.props.suggestedValues : [];
        if (_lodash2.default.isEmpty(suggestedValues)) {
            return [];
        }
        var currentValueMap = (this.values() || []).reduce(function (result, val) {
            result[val] = true;
            return result;
        }, {});
        var suggestedIds = suggestedValues.filter(function (suggestedValue) {
            return !currentValueMap[suggestedValue];
        });
        return this.hydratedValues({ values: suggestedIds });
    },
    hydratedValues: function hydratedValues() {
        var _this6 = this;

        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var values = options.values || this.values();
        var optionsById = _lodash2.default.keyBy(this.props.options || [], 'value');

        return values.reduce(function (hydratedValues, valueId) {
            var _React$createElement4;

            return optionsById[valueId] ? hydratedValues.concat([{
                id: valueId,
                text: _react2.default.createElement(
                    'div',
                    (_React$createElement4 = { className: styles.primaryText, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'TokenTagger'
                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement4),
                    _this6.getPrimaryText(optionsById[valueId])
                ) //primaryTextById[valueId]
            }]) : hydratedValues;
        }, []);
    },
    _getCanShowResults: function _getCanShowResults() {
        return this.props.requireSearchValueForDropdown ? this.props.searchValue !== '' && this.props.searchValue != null : true;
    },

    _renderLoader: function _renderLoader() {
        var _React$createElement5, _React$createElement6;

        return _react2.default.createElement(
            'div',
            (_React$createElement6 = { className: styles.loaderWrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'TokenTagger'
            }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement6),
            _react2.default.createElement(_Loading2.default, (_React$createElement5 = { style: { width: 20, height: 20 }, status: 'loading', 'data-codecept-selector-node': 'RubyLoading',
                'data-codecept-selector-file': 'TokenTagger'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'RubyLoading'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'RubyLoading'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement5))
        );
    },
    _renderTextField: function _renderTextField() {
        var _React$createElement7,
            _this7 = this,
            _extends4,
            _extends5,
            _extends6,
            _React$createElement8;

        var self = this;

        var labelProps = _lodash2.default.pick(this.props, Object.keys(Label.propTypes));
        var errorTextElement = !_lodash2.default.isEmpty(this.props.richErrorMessages) ? _react2.default.createElement(RichErrorMessages, (_React$createElement7 = { messages: this.props.richErrorMessages, 'data-codecept-selector-node': 'RichErrorMessages',
            'data-codecept-selector-file': 'TokenTagger'
        }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement7)) : null;

        var hintText = this.props.hintText || 'Search to add ' + _lodash2.default.upperFirst(this.props.originalLabel || this.props.label);

        var TextField_styleObject = this.props.error ? _TokenTagger_style2.default.TextField_hasError : _TokenTagger_style2.default.TextField;

        return !this.isInfoMode() ? [_react2.default.createElement(_TextField2.default, _extends({}, TextField_styleObject, (_extends5 = {
            ref: function ref(searchTextField) {
                if (searchTextField) {
                    _this7._searchTextField = searchTextField;
                    _this7._anchorEl = _reactDom2.default.findDOMNode(searchTextField);
                }
            },
            floatingLabelText: _react2.default.createElement(Label, _extends({}, labelProps, (_extends4 = {
                'data-codecept-selector-node': 'Label',
                'data-codecept-selector-file': 'TokenTagger'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends4, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends4, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends4, 'data-codecept-selector-file', 'TokenTagger'), _extends4))),
            floatingLabelFixed: true,
            hintText: hintText,
            onChange: this._onSearchValueChange,
            value: this.props.searchValue,
            onKeyDown: this._handleKeyDown,
            onFocus: this._onTextFieldFocus,
            onBlur: this._onBlur_fromTextFieldBlur,
            disabled: this.isLocked(),
            errorText: errorTextElement,
            'data-codecept-selector-node': 'TextField',
            'data-codecept-selector-file': 'TokenTagger'
        }, _defineProperty(_extends5, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends5, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends5, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends5, 'data-codecept-selector-file', 'TokenTagger'), _extends5))), this._renderToolbar()] : [_react2.default.createElement(
            'label',
            (_React$createElement8 = { className: styles.label, 'data-codecept-selector-node': 'label',
                'data-codecept-selector-file': 'TokenTagger'
            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement8),
            _react2.default.createElement(Label, _extends({}, labelProps, (_extends6 = {
                'data-codecept-selector-node': 'Label',
                'data-codecept-selector-file': 'TokenTagger'
            }, _defineProperty(_extends6, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends6, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends6, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends6, 'data-codecept-selector-file', 'TokenTagger'), _extends6)))
        )];
    },
    _renderToolbar: function _renderToolbar() {
        var _React$createElement9;

        return _react2.default.createElement(
            'div',
            (_React$createElement9 = { className: styles.toolbar, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'TokenTagger'
            }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement9),
            this._renderAdvancedSearchButton(),
            this._renderCreateEntryButton()
        );
    },
    _renderAdvancedSearchButton: function _renderAdvancedSearchButton() {
        var _React$createElement10, _extends7, _React$createElement11;

        var currentValue = this.props.value || [];

        var isLimitHit = currentValue.length >= this.props.limit;

        /*
                <ListerDialog
                    {..._.pick(this.props, ListerDialog__propTypeKeys)}
                    delegateRequestClose={this.props.action.closeDialog}
                />
         */
        //# TODO: insert dialog for advancedSearching
        return this.props.advancedSearch ? _react2.default.createElement(
            'div',
            (_React$createElement11 = { className: styles.toolbarButtonWrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'TokenTagger'
            }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement11),
            _react2.default.createElement(_RaisedButton2.default, (_React$createElement10 = {
                className: styles.CreateEntry,
                label: "Advanced Search",
                labelPosition: 'before',
                disabled: isLimitHit || this.isLocked(),
                primary: true,
                onClick: this.props.actions.openDialog,
                'data-codecept-selector-node': 'RaisedButton',
                'data-codecept-selector-file': 'TokenTagger'
            }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement10)),
            _react2.default.createElement(_ListerDialog2.default, _extends({}, _lodash2.default.pick(this.props, ListerDialog__propTypeKeys), (_extends7 = {
                title: this.props.originalLabel,
                delegateRequestClose: this.props.actions.closeDialog,
                'data-codecept-selector-node': 'ListerDialog',
                'data-codecept-selector-file': 'TokenTagger'
            }, _defineProperty(_extends7, 'data-codecept-selector-node', 'ListerDialog'), _defineProperty(_extends7, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends7, 'data-codecept-selector-node', 'ListerDialog'), _defineProperty(_extends7, 'data-codecept-selector-file', 'TokenTagger'), _extends7)))
        ) : null;
    },
    _renderCreateEntryButton: function _renderCreateEntryButton() {
        var _this8 = this,
            _extends8,
            _React$createElement13,
            _React$createElement14,
            _React$createElement15;

        var currentValue = this.props.value || [];
        var isCreatingEntry = this.state.isCreatingEntry;
        var isSearchValueEmpty = _lodash2.default.trim(this.props.searchValue).length == 0;
        var isLimitHit = currentValue.length >= this.props.limit;

        var templateOptions = this.props.templateOptions;
        var hasMultipleOptions = templateOptions.length > 1;
        var entryLabel = hasMultipleOptions ? '...' : _lodash2.default.get(this, 'props.templateOptions.0.text');
        return this.props.url && this.props.showCreateEntryButton ? _react2.default.createElement(
            'div',
            (_React$createElement15 = { className: styles.toolbarButtonWrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'TokenTagger'
            }, _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement15),
            _react2.default.createElement(_RaisedButton2.default, _extends({}, _TokenTagger_style2.default.FlatButton__createEntry, (_extends8 = {
                className: styles.CreateEntry,
                label: 'Create ' + entryLabel,
                labelPosition: 'before',
                disabled: isCreatingEntry || isSearchValueEmpty || isLimitHit || !this.props.canCreateSomeEntry,
                icon: this.state.isCreatingEntry ? this._renderLoader() : null,
                primary: true,
                onClick: function onClick(event) {
                    hasMultipleOptions ? _this8.setState({
                        createEntryMenuOpen: true,
                        createEntryAnchorEl: event.currentTarget
                    }) : _this8._handleOnCreateEntryClick(_lodash2.default.get(templateOptions, '0.value'));
                },
                'data-codecept-selector-node': 'RaisedButton',
                'data-codecept-selector-file': 'TokenTagger'
            }, _defineProperty(_extends8, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends8, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends8, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends8, 'data-codecept-selector-file', 'TokenTagger'), _extends8))),
            _react2.default.createElement(
                Popover,
                (_React$createElement14 = {
                    open: this.state.createEntryMenuOpen,
                    anchorEl: this.state.createEntryAnchorEl,
                    anchorOrigin: { horizontal: 'right', vertical: 'top' },
                    targetOrigin: { horizontal: 'right', vertical: 'top' },
                    onRequestClose: function onRequestClose() {
                        _this8.setState({
                            createEntryMenuOpen: false
                        });
                    },
                    'data-codecept-selector-node': 'Popover',
                    'data-codecept-selector-file': 'TokenTagger'
                }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'Popover'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'Popover'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement14),
                _react2.default.createElement(
                    _VirtualMenu2.default,
                    (_React$createElement13 = {
                        'data-codecept-selector-node': 'Menu',
                        'data-codecept-selector-file': 'TokenTagger'
                    }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'Menu'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'Menu'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement13),
                    this.props.templateOptions.map(function (_ref) {
                        var _React$createElement12;

                        var templateKey = _ref.value,
                            text = _ref.text;

                        var disabled = !_this8.props.canCreateEntryWithKey(templateKey);
                        return _react2.default.createElement(_MenuItem2.default, (_React$createElement12 = {
                            disabled: disabled,
                            primaryText: text,
                            onClick: function onClick() {
                                if (disabled) {
                                    return;
                                }
                                _this8.setState({
                                    createEntryMenuOpen: false
                                });
                                _this8._handleOnCreateEntryClick(templateKey);
                            },
                            'data-codecept-selector-node': 'MenuItem',
                            'data-codecept-selector-file': 'TokenTagger'
                        }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement12));
                    })
                )
            )
        ) : null;
    },
    _renderInfoModeLabel: function _renderInfoModeLabel() {
        var _extends9, _React$createElement16;

        var labelProps = _lodash2.default.pick(this.props, Object.keys(Label.propTypes));

        return _react2.default.createElement(
            'div',
            (_React$createElement16 = {
                className: styles.InfoModeLabelWrapper,
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'TokenTagger'
            }, _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement16),
            _react2.default.createElement(Label, _extends({}, labelProps, (_extends9 = {
                'data-codecept-selector-node': 'Label',
                'data-codecept-selector-file': 'TokenTagger'
            }, _defineProperty(_extends9, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends9, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends9, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends9, 'data-codecept-selector-file', 'TokenTagger'), _extends9)))
        );
    },
    render: function render() {
        var _this9 = this,
            _extends10,
            _React$createElement17,
            _extends11,
            _React$createElement18,
            _extends12,
            _extends13;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }
        var popoverIsOpen = this._getCanShowResults() && this.state.showResults;

        var self = this;
        var selfValues = this.values();

        var menuWidth = this.menuWidth();

        var menuItems = this.filteredMenuItems();

        var tokenTaggerListProps = _lodash2.default.pick(this.props, TokenTaggerList_propTypeKeys);

        var Card_style = this.props.style.Card || _TokenTagger_style2.default.Card || {};

        var suggestedValues = this.suggestedValues();
        return _react2.default.createElement(
            _Card.Card,
            _extends({
                className: styles.wrapper,
                id: this.props.id
            }, Card_style, (_extends13 = {
                'data-codecept-selector-node': 'Card',
                'data-codecept-selector-file': 'TokenTagger'
            }, _defineProperty(_extends13, 'data-codecept-selector-node', 'Card'), _defineProperty(_extends13, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends13, 'data-codecept-selector-node', 'Card'), _defineProperty(_extends13, 'data-codecept-selector-file', 'TokenTagger'), _extends13)),
            _react2.default.createElement(
                _Card.CardText,
                _extends({}, _TokenTagger_style2.default.CardText, (_extends12 = {
                    'data-codecept-selector-node': 'CardText',
                    'data-codecept-selector-file': 'TokenTagger'
                }, _defineProperty(_extends12, 'data-codecept-selector-node', 'CardText'), _defineProperty(_extends12, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends12, 'data-codecept-selector-node', 'CardText'), _defineProperty(_extends12, 'data-codecept-selector-file', 'TokenTagger'), _extends12)),
                this._renderTextField(),
                _react2.default.createElement(
                    Popover,
                    (_React$createElement17 = {
                        open: popoverIsOpen,
                        useLayerForClickAway: false
                        //# NOTE: don't use clickAway layer because we want the user to be able to click on "Create Entry" without needing to dismiss the popover first
                        , onRequestClose: this._onPopoverRequestClose,
                        anchorEl: this._anchorEl,
                        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                        targetOrigin: { vertical: 'top', horizontal: 'left' },
                        height: menuItems.length,
                        'data-codecept-selector-node': 'Popover',
                        'data-codecept-selector-file': 'TokenTagger'
                    }, _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'Popover'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'Popover'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement17),
                    _react2.default.createElement(
                        _VirtualMenu2.default,
                        _extends({}, _TokenTagger_style2.default.Menu, (_extends10 = {
                            value: selfValues.map(function (el) {
                                return el.toString();
                            }),
                            ref: function ref(el) {
                                if (el) {
                                    _this9._popoverMenu = el;
                                }
                            },
                            disableAutoFocus: !this.state.shouldAutoFocusPopoverMenu,
                            initiallyKeyboardFocused: true,
                            onEscKeyDown: this._onEscKeyDown_fromMenu,
                            onChange: this._onValueChangeGenerator_forMenuItems(menuItems),
                            onItemTouchTap: this._onItemTouchTap_forMenu,
                            onMouseEnter: function onMouseEnter() {
                                _this9.setState({
                                    menuFocused: true
                                });
                            },
                            onMouseLeave: function onMouseLeave() {
                                _this9.setState({
                                    menuFocused: false
                                });
                            },
                            multiple: true,
                            maxHeight: 400,
                            autoWidth: false,
                            width: menuWidth,
                            'data-codecept-selector-node': 'Menu',
                            'data-codecept-selector-file': 'TokenTagger'
                        }, _defineProperty(_extends10, 'data-codecept-selector-node', 'Menu'), _defineProperty(_extends10, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends10, 'data-codecept-selector-node', 'Menu'), _defineProperty(_extends10, 'data-codecept-selector-file', 'TokenTagger'), _extends10)),
                        menuItems
                    )
                ),
                _react2.default.createElement(_TokenTaggerList2.default, _extends({}, tokenTaggerListProps, (_extends11 = {
                    draggable: this.isDraggable(),
                    delegateValueChange: this._onValueChange,
                    delegateDeleteGeneratorForId: this._removeSelectionGeneratorForId,
                    values: this.hydratedValues(),
                    'data-codecept-selector-node': 'TokenTaggerList',
                    'data-codecept-selector-file': 'TokenTagger'
                }, _defineProperty(_extends11, 'data-codecept-selector-node', 'TokenTaggerList'), _defineProperty(_extends11, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends11, 'data-codecept-selector-node', 'TokenTaggerList'), _defineProperty(_extends11, 'data-codecept-selector-file', 'TokenTagger'), _extends11))),
                !_lodash2.default.isEmpty(suggestedValues) && _react2.default.createElement(_SuggestionList2.default, (_React$createElement18 = {
                    values: suggestedValues,
                    addSelections_withIds: this._handleAddSelections_withIds,
                    'data-codecept-selector-node': 'SuggestionList',
                    'data-codecept-selector-file': 'TokenTagger'
                }, _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'SuggestionList'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'SuggestionList'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement18))
            )
        );
    },

    isInfoMode: function isInfoMode() {
        var mode = this.props.mode;


        return mode === 'info';
    },
    isLocked: function isLocked() {
        return this.props.locked || this.props.disabled || this.isInfoMode();
    },
    isDraggable: function isDraggable() {
        return !this.isLocked() && this.props.limit != 1;
    }
});

//# NOTE: cannot use addInfoModeProps because of the assumed toHTML prop
//# TODO: this is deprecated because we renamed it to optionToHTML
exports.default = TokenTagger;