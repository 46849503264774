'use strict';

var _ = require('lodash');

module.exports = {
    doesComponentHaveMixin: function doesComponentHaveMixin(Component, needleMixin) {
        var mixinName = _.isString(needleMixin) ? needleMixin : needleMixin.mixinName;

        var mixins = Component._flattenedMixins || Component.mixins;

        return mixins.reduce(function (collector, mixin) {
            if (collector) {
                return collector;
            }

            return mixin.mixinName == mixinName;
        }, false);
    }

    //# get the "super" version of the method, eg. if you called superForMethod(node, "formValueToLocalState"), 
    //# it'll get the right-most method in the liste of flattened mixins
    , superMixinContaining: function superMixinContaining(Component, propertyKey, fromValue) {
        var mixins = Component._flattenedMixins || Component.mixins;

        var filteredMixins = mixins.filter(function (mixin) {
            return mixin.hasOwnProperty(propertyKey) && mixin[propertyKey] != fromValue;
        });

        return _.last(filteredMixins);
    }
};