'use strict';

var requestTrackerMixin = {
    mixinName: 'rubyComponentMixinRequestTracker',
    action: require('./action'),
    reducer: require('./reducer'),
    getInitialState: function getInitialState() {
        return {
            isLoading: false,
            requestObject: null,
            response: null
        };
    }
};

module.exports = requestTrackerMixin;