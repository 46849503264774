'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp2;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Checkbox = require('material-ui/Checkbox');

var _Checkbox2 = _interopRequireDefault(_Checkbox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SelectColumn = (_temp2 = _class = function (_Component) {
    _inherits(SelectColumn, _Component);

    function SelectColumn() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, SelectColumn);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SelectColumn.__proto__ || Object.getPrototypeOf(SelectColumn)).call.apply(_ref, [this].concat(args))), _this), _this._toggleSelection = function () {
            var _this$props = _this.props,
                events = _this$props.events,
                selected = _this$props.selected;

            if (selected) {
                events.unselectVisibleRows();
            } else {
                events.selectVisibleRows();
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(SelectColumn, [{
        key: 'render',
        value: function render() {
            var _props = this.props,
                selected = _props.selected,
                limit = _props.limit;


            return _react2.default.createElement(_Checkbox2.default, { checked: selected,
                onClick: this._toggleSelection,
                style: { position: 'absolute' },
                disabled: !(_lodash2.default.isNil(limit) || limit == Infinity) //# disable select all for now if we have limit
                //# TODO: figure out a better way to support select all
                , 'data-codecept-selector-node': 'Checkbox',
                'data-codecept-selector-file': 'table-heading'
            });
        }
    }]);

    return SelectColumn;
}(_react.Component), _class.propTypes = {
    selected: _react.PropTypes.bool,
    events: _react.PropTypes.object,
    parentProps: _react.PropTypes.object
}, _temp2);


function getSelectColumn(selected, events, parentProps, limit, isLimitHit) {
    var checkbox = _react2.default.createElement(SelectColumn, {
        selected: selected,
        events: events,
        parentProps: parentProps,
        limit: limit,
        isLimitHit: isLimitHit,
        'data-codecept-selector-node': 'SelectColumn',
        'data-codecept-selector-file': 'table-heading'
    });

    return checkbox;
}

function isAllVisibleSelected(griddleProps) {
    var visibleData = griddleProps.visibleData,
        visibleStaticData = griddleProps.visibleStaticData,
        selectedRecords = griddleProps.selectedRecords;


    var mergedVisibleData = (visibleData || []).concat(visibleStaticData || []);
    var selectedIDs = Object.keys(selectedRecords);

    var selectedAndVisibleRecords = mergedVisibleData.reduce(function (collector, value) {
        var valueID = "" + _lodash2.default.get(value, '__metadata.id', value.id);
        if (_lodash2.default.indexOf(selectedIDs, valueID) >= 0) {
            collector.push(value);
        }
        return collector;
    }, []);

    return selectedAndVisibleRecords.length > 0 && selectedAndVisibleRecords.length == mergedVisibleData.length;
}

exports.default = function (TableHeading) {
    var _class2, _temp3;

    return _temp3 = _class2 = function (_Component2) {
        _inherits(_class2, _Component2);

        function _class2() {
            _classCallCheck(this, _class2);

            return _possibleConstructorReturn(this, (_class2.__proto__ || Object.getPrototypeOf(_class2)).apply(this, arguments));
        }

        _createClass(_class2, [{
            key: 'render',
            value: function render() {
                var selectColumnProperties = {
                    selectColumn: {
                        id: 'selectColumn',
                        width: 40,
                        resizable: false,
                        sortable: false
                    }
                };

                var _props2 = this.props,
                    events = _props2.events,
                    columns = _props2.columns,
                    columnTitles = _props2.columnTitles,
                    plugins = _props2.plugins,
                    renderProperties = _props2.renderProperties,
                    selectedRecords = _props2.selectedRecords;


                var SelectionPlugin = _lodash2.default.find(plugins, ['name', 'GriddleSelection']);

                var limit = SelectionPlugin.options.limit;


                var isLimitHit = limit && _lodash2.default.size(selectedRecords) >= limit;

                //# TODO: need to check the selection status and set selected accordingly
                var allVisibleIsSelected = isAllVisibleSelected(this.props);
                var selectColumn = getSelectColumn(allVisibleIsSelected, events, this.props, limit, isLimitHit);
                var augmentedColumnTitles = _extends({}, { 'selectColumn': selectColumn }, columnTitles);
                var columnProperties = _extends({}, selectColumnProperties, renderProperties.columnProperties);
                var augmentedRenderProperties = _extends({}, renderProperties);
                augmentedRenderProperties.columnProperties = columnProperties;

                return _react2.default.createElement(TableHeading, _extends({}, this.props, {
                    renderProperties: augmentedRenderProperties,
                    columnTitles: augmentedColumnTitles,
                    'data-codecept-selector-node': 'TableHeading',
                    'data-codecept-selector-file': 'table-heading'
                }));
            }
        }]);

        return _class2;
    }(_react.Component), _class2.PropTypes = {
        renderProperties: _react.PropTypes.object.isRequired,
        columns: _react.PropTypes.array.isRequired
    }, _temp3;
};