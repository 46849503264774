'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _utilityBarActions = require('./utility-bar-actions');

var _utilityBarActions2 = _interopRequireDefault(_utilityBarActions);

var _savedSearchToWhereFilter = require('./utils/savedSearchToWhereFilter');

var _savedSearchToWhereFilter2 = _interopRequireDefault(_savedSearchToWhereFilter);

var _formatRequestPropsForColumns = require('./utils/formatRequestPropsForColumns');

var _formatRequestPropsForColumns2 = _interopRequireDefault(_formatRequestPropsForColumns);

var _hiddenSearchableFields = require('./hiddenSearchableFields');

var _hiddenSearchableFields2 = _interopRequireDefault(_hiddenSearchableFields);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var queryString = require('query-string');
var urljoin = require('url-join');
var Route = require('route-parser');
var request = require('@rubyapps/ruby-superagent');
var path = require('path');

var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

var rubyWords = require('@rubyapps/ruby-words');

function capitalizeTokens(str) {
    return str.replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase();
    });
}

var _require = require('@rubyapps/ruby-component-plugin-template-editor/src/common/constants'),
    PROFILE_TEMPLATE_TYPE = _require.PROFILE_TEMPLATE;

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var rubyDateManager = require('@rubyapps/ruby-date');

var action = require('./action');
var reducer = require('./reducer');

var listerFilterComponents = require('@rubyapps/ruby-component-list-page/src/client/reactComponents/components').default;

var GriddleModule = require('@rubyapps/griddle');
var customComponentsByType = GriddleModule.customComponentsByType;


var COMPONENTS_BY_TYPE = customComponentsByType;

var RubyComponentCurrentUser__CONSTANTS = require('@rubyapps/ruby-component-current-user/src/common/constants');
var RubyComponentFrontendSettings__CONSTANTS = require('@rubyapps/ruby-component-frontend-settings/src/common/constants');
var RubyComponentForms__CONSTANTS = require('@rubyapps/ruby-component-forms/src/common/constants');
var RubyComponentListerConfigs__CONSTANTS = require('@rubyapps/ruby-component-lister-configs/src/common/constants');
var RubyComponentRubyClientFK__CONSTANTS = require('@rubyapps/ruby-component-rubyclientfk/src/common/constants');

var _require2 = require('./constants'),
    FILTER_TAGS_PROPTYPE = _require2.RUBY_COMPONENT__FILTER_TAGS_PROPTYPE;

var propsMixin = require('./mixinProps');
var formdataDependencyMixin = require('../local_modules/ruby-component-mixin-list-formdata-dependency/src/client/index');
var autosaveSearchMixin = require('../local_modules/ruby-component-mixin-list-autosave-search/src/client/index');

var listPageMixin = {
    mixins: [propsMixin, formdataDependencyMixin, autosaveSearchMixin],
    mixinName: 'rubyComponentMixinListPage',
    propTypes: {
        label: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        endPointAccessor: PropTypes.arrayOf(PropTypes.string),

        internalFilterTags: FILTER_TAGS_PROPTYPE,
        listApiEndpoint: PropTypes.string,
        listerConfig: PropTypes.shape({
            columns: PropTypes.arrayOf(PropTypes.shape({
                key: PropTypes.string,
                displayName: PropTypes.string,
                type: PropTypes.string,
                minWidth: PropTypes.number,
                width: PropTypes.number,
                resizable: PropTypes.bool,
                sortable: PropTypes.bool
            })),
            filters: PropTypes.arrayOf(PropTypes.shape({
                key: PropTypes.string,
                label: PropTypes.string
            })),
            defaultSortDirection: PropTypes.string,
            defaultSortColumn: PropTypes.string
        }),
        mode: PropTypes.oneOf([null, 'select']),
        savedSearch__instanceRouteTemplate: PropTypes.string,
        staticFilterTags: FILTER_TAGS_PROPTYPE

        //# Dependencies IDs
        , currentUserID: PropTypes.string,
        feSettingsID: PropTypes.string,
        formsID: PropTypes.string,
        contentPropertyHelperID: PropTypes.string,
        listerConfigsID: PropTypes.string,
        rubyClientFKID: PropTypes.string
    },
    getDefaultProps: function getDefaultProps(props, componentClass) {
        return {
            savedSearch__instanceRouteTemplate: 'saved_searches/:id'

            //# Dependencies IDs
            , contentPropertyHelperID: require('@rubyapps/ruby-content-property-helper/src/common/constants').COMPONENT_NAME,
            currentUserID: RubyComponentCurrentUser__CONSTANTS.COMPONENT_NAME,
            feSettingsID: RubyComponentFrontendSettings__CONSTANTS.COMPONENT_NAME,
            formsID: RubyComponentForms__CONSTANTS.COMPONENT_NAME,
            formDialogID: 'rubyComponentFormDialog',
            listerConfigsID: RubyComponentListerConfigs__CONSTANTS.COMPONENT_NAME,
            rubyClientFKID: RubyComponentRubyClientFK__CONSTANTS.COMPONENT_NAME
        };
    },
    action: action,
    reducer: reducer,
    getInitialState: function getInitialState() {
        return {
            mostRecentlyModifiedItem: {},
            griddleState: {}
        };
    },
    dependencies: function dependencies() {
        var _this = this;

        var selfModule = this;
        var selfSelector = selfModule.getDefaultSelector();
        var selfActions = selfModule.getAction().generators;
        var rootModule = selfModule.getRoot();

        var feSettingsComponent = rootModule.findDescendentByID(this.props.feSettingsID);
        var feSettingsSelector = feSettingsComponent.getDefaultSelector();

        var rubyClientFKComponent = rootModule.findDescendentByID(this.props.rubyClientFKID);
        var rubyClientFKSelector = rubyClientFKComponent.getDefaultSelector();

        var currentUserComponent = rootModule.findDescendentByID(this.props.currentUserID);
        var currentUserSelector = currentUserComponent.getDefaultSelector();
        var contentPropertyHelperComponent = rootModule.findDescendentByID(this.props.contentPropertyHelperID);

        var formsComponent = rootModule.findDescendentByID(this.props.formsID);
        var formsSelector = formsComponent.getDefaultSelector();
        var listerConfigsComponent = rootModule.findDescendentByID(this.props.listerConfigsID);
        var listerConfigsSelector = listerConfigsComponent.getDefaultSelector();

        var getContentListUrl_forTemplate = function getContentListUrl_forTemplate(template) {
            return rootModule.getUrlForComponent_fromModule_withParams(_this.props.id, _this, { template: template });
        };

        return {
            selfSelector: selfSelector,
            selfActions: selfActions,
            currentUserSelector: currentUserSelector,
            feSettingsSelector: feSettingsSelector,
            formsSelector: formsSelector,
            listerConfigsSelector: listerConfigsSelector,
            rubyClientFKSelector: rubyClientFKSelector,

            contentPropertyHelperComponent: contentPropertyHelperComponent,
            currentUserComponent: currentUserComponent,
            feSettingsComponent: feSettingsComponent,
            formsComponent: formsComponent,
            listerConfigsComponent: listerConfigsComponent,
            rubyClientFKComponent: rubyClientFKComponent,
            getContentListUrl_forTemplate: getContentListUrl_forTemplate
        };
    },
    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector,
            currentUserSelector = _getDependencies.currentUserSelector,
            feSettingsSelector = _getDependencies.feSettingsSelector,
            formsSelector = _getDependencies.formsSelector,
            listerConfigsSelector = _getDependencies.listerConfigsSelector,
            rubyClientFKSelector = _getDependencies.rubyClientFKSelector;

        var states = {
            self: selfSelector(state),
            feSettings: feSettingsSelector(state),
            rubyClientFK: rubyClientFKSelector(state),
            user: currentUserSelector(state),
            forms: formsSelector(state),
            listerConfigs: listerConfigsSelector(state)
        };

        return states;
    }
    //== Utility Methods =======================================================//
    , setGriddleProps: function setGriddleProps(griddleProps) {
        //# NOTE: you should never use this as a means to get access to your own griddleProps
        //# we use this to reach into another lister in order to call on their griddle.events
        //# if you need access to your own griddleState, you should rely on reflectGriddleState
        this._griddleProps = griddleProps;
    },
    getGriddleProps: function getGriddleProps() {
        //# NOTE: you should never use this as a means to get access to your own griddleProps
        //# we use this to reach into another lister in order to call on their griddle.events
        //# if you need access to your own griddleState, you should rely on reflectGriddleState
        return this._griddleProps;
    },

    replaceFilterStateWithSavedSearch: function replaceFilterStateWithSavedSearch(savedSearchObject) {
        var _this2 = this;

        var griddleProps = this.getGriddleProps();
        var griddle__setFilter = _lodash2.default.get(griddleProps, 'events.setFilter');
        if (_lodash2.default.isNil(griddle__setFilter)) {
            return setTimeout(function () {
                _this2.replaceFilterStateWithSavedSearch(savedSearchObject);
            }, 200);
        }

        griddle__setFilter(savedSearchObject);
    },
    loadSavedSearchForId: function loadSavedSearchForId(id) {
        var _this3 = this;

        var selfModule = this;

        var savedSearch__instanceRouteParser = this._savedSearch__instanceRouteParser;

        if (savedSearch__instanceRouteParser == undefined) {
            var savedSearch__instanceRouteTemplate = selfModule.props.savedSearch__instanceRouteTemplate;

            var feSettings = this.getFrontendSettings();

            var restApiRoot = feSettings.restApiRoot;


            savedSearch__instanceRouteParser = new Route(urljoin(restApiRoot, savedSearch__instanceRouteTemplate));
            this._savedSearch__instanceRouteParser = savedSearch__instanceRouteParser;
        }

        var savedSearchInstanceEndpoint = savedSearch__instanceRouteParser.reverse({ id: id });

        //# load the saved search
        return request.get(savedSearchInstanceEndpoint).then(function (response) {
            var savedSearchObject = response.body;

            _this3.replaceFilterStateWithSavedSearch(_lodash2.default.pick(savedSearchObject, ['queryString', 'filterTags']));
            return savedSearchObject;
        });
    }

    //# move to mixin-globals
    , getFrontendSettings: function getFrontendSettings() {
        var selfModule = this;

        // Get the full application state
        var store = selfModule.getStore();
        if (store == undefined) {
            return undefined;
        }
        var applicationState = store.getState();

        var feSettingsSelector = this._feSettingsSelector;
        if (!feSettingsSelector) {
            // Get the current user rubyComponent
            var rootModule = selfModule.getRoot();
            var feSettingsID = selfModule.props.feSettingsID;
            var feSettingsComponent = rootModule.findDescendentByID(feSettingsID);
            feSettingsSelector = feSettingsComponent.getDefaultSelector();

            this._feSettingsSelector = feSettingsSelector;
        }

        return feSettingsSelector(applicationState);
    },
    utils: {
        determineDefaultSortColumn_withColumns: function determineDefaultSortColumn_withColumns(columns) {
            //# for backwards compatibility but don't use for new stuff
            console.warn('[DEPRECATED 20170831]');
            return listPageMixin.utils.determineDefaultSortColumn_withColumns_andListerConfig(columns);
        },
        determineDefaultSortColumn_withColumns_andListerConfig: function determineDefaultSortColumn_withColumns_andListerConfig(columns) {
            var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            var sortableColumns = _lodash2.default.filter(columns, function (col) {
                return col.sortable !== false;
            });

            var defaultSortColumn = void 0;
            //# preference is to use the 'isDefaultSortColumn' on the column spec
            sortableColumns.reduce(function (collector, column) {
                if (collector) {
                    return collector;
                }

                if (column.isDefaultSortColumn) {
                    defaultSortColumn = column.key;
                }
                return collector;
            }, null);

            //# if none of the columns have `isDefaultSortColumn` set, then we look at the lister config
            if (!defaultSortColumn) {
                if (config.hasOwnProperty('defaultSortColumn')) {
                    defaultSortColumn = config.defaultSortColumn;
                }
            }

            //# finally we default to the first column
            if (!defaultSortColumn) {
                defaultSortColumn = sortableColumns.length > 0 ? sortableColumns[0].key : null;
            }

            return defaultSortColumn;
        },

        determineDefaultSortDirection_withColumns_andListerConfig: function determineDefaultSortDirection_withColumns_andListerConfig(columns) {
            var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            var defaultSortDirection = void 0;
            //# preference is to use the 'defaultSortDirection' on the column spec
            columns.reduce(function (collector, column) {
                if (collector) {
                    return collector;
                }

                if (column.hasOwnProperty('defaultSortDirection')) {
                    defaultSortDirection = column.defaultSortDirection;
                }
                return collector;
            }, null);

            //# if none of the columns have `isDefaultSortColumn` set, then we look at the lister config
            if (!defaultSortDirection) {
                if (config.hasOwnProperty('defaultSortDirection')) {
                    defaultSortDirection = config.defaultSortDirection;
                }
            }

            //# finally we default to the first column
            if (!defaultSortDirection) {
                defaultSortDirection = 'asc'; // default to asc
            }

            return defaultSortDirection.toLowerCase();
        },
        getSnameFromSortColumns_usingGriddleStore: function getSnameFromSortColumns_usingGriddleStore(sortColumns, store) {
            console.warn('[DEPRECATED 20170921] you should use' + ' getSnameAndSdirAsDictFromSortColumns_andSortDir_usingGriddleStore) instead');
            var sortKeys = void 0;
            var firstSortColumnKey = sortColumns[0];

            //# not pretty but we can retrieve the utilityBarProperties.selectedColumnSpecsArr as backup
            if (sortColumns && sortColumns.length > 0) {
                //const renderProps__columnSpecPath = = ['renderProperties', 'columnProperties', firstSortColumnKey];
                var renderProps__columnPropsByKey = _lodash2.default.get(store, 'renderProperties.columnProperties', {});
                var selectedColumnSpecsArr = _lodash2.default.get(store, 'utilityBarProperties.selectedColumnSpecsArr', []);
                var selectedColumnSpecsByKey = _lodash2.default.keyBy(selectedColumnSpecsArr, 'key');

                var columnSpec = selectedColumnSpecsByKey[firstSortColumnKey] || renderProps__columnPropsByKey[firstSortColumnKey] || {};

                sortKeys = columnSpec.sortKeys || columnSpec.sortKey || columnSpec.id || columnSpec.key;

                if (!_lodash2.default.isArray(sortKeys)) {
                    sortKeys = [sortKeys];
                }
            }
            return sortKeys || (sortColumns && sortColumns.length > 0 ? sortColumns[0] : '');
        },
        getSnameAndSdirAsDictFromSortColumns_andSortDir_usingGriddleStore: function getSnameAndSdirAsDictFromSortColumns_andSortDir_usingGriddleStore(sortColumns, sortDir, store) {
            var sortKeys = void 0;
            var sortDirs = void 0;
            var firstSortColumnKey = _lodash2.default.get(sortColumns, '0', '');

            //# not pretty but we can retrieve the utilityBarProperties.selectedColumnSpecsArr as backup
            if (sortColumns && sortColumns.length > 0) {
                //const renderProps__columnSpecPath = = ['renderProperties', 'columnProperties', firstSortColumnKey];
                var renderProps__columnPropsByKey = _lodash2.default.get(store, 'renderProperties.columnProperties', {});
                var selectedColumnSpecsArr = _lodash2.default.get(store, 'utilityBarProperties.selectedColumnSpecsArr', []);
                var selectedColumnSpecsByKey = _lodash2.default.keyBy(selectedColumnSpecsArr, 'key');

                var columnSpec = selectedColumnSpecsByKey[firstSortColumnKey] || renderProps__columnPropsByKey[firstSortColumnKey] || {};

                sortKeys = columnSpec.sortKeys || columnSpec.sortKey || columnSpec.id || columnSpec.key;

                sortDirs = columnSpec.hasOwnProperty('sortDirsFromDir_andKeys') ? columnSpec.sortDirsFromDir_andKeys(sortDir, sortKeys) : [sortDir];

                if (!_lodash2.default.isArray(sortKeys)) {
                    sortKeys = [sortKeys];
                }
            }
            var finalSortKeys = sortKeys || (sortColumns && sortColumns.length > 0 ? sortColumns[0] : '');

            return {
                sname: finalSortKeys,
                sdir: sortDirs
            };
        },
        getColumnsFromGriddleState_defaultingToColumns: function getColumnsFromGriddleState_defaultingToColumns(griddleState) {
            var defaultColumns = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

            var utilityBarProperties = _lodash2.default.get(griddleState || {}, 'utilityBarProperties', {});
            var _utilityBarProperties = utilityBarProperties.selectedColumnSpecsArr,
                selectedColumnSpecsArr = _utilityBarProperties === undefined ? [] : _utilityBarProperties;

            //const alwaysIncludeColumns = defaultColumns.filter(
            //    (columnSpec) => columnSpec.alwaysShow
            //    && _.find(
            //        selectedColumnSpecsArr
            //        , ['key', columnSpec.key]
            //    ) == undefined
            //);

            var columns = selectedColumnSpecsArr.length ?
            //# don't include alwaysShow cause we're preventing it from being deleted in the SelectColumns UI
            //selectedColumnSpecsArr.concat(alwaysIncludeColumns)
            selectedColumnSpecsArr : defaultColumns;

            return columns;
        },
        constructChangelogMessage: function constructChangelogMessage(lastModifiedData) {
            var lastModifiedUser = lastModifiedData.last_modified_by_user_name,
                lastModifiedDate = lastModifiedData.last_modified_timestamp;


            var message = [];

            // If there is last modified data, start building our string
            if (lastModifiedDate || lastModifiedUser) {
                message.push('Last modified');

                if (lastModifiedDate) {
                    message.push(rubyDateManager.formatWithShortenedAdjacentDays(lastModifiedDate));
                }

                if (lastModifiedUser) {
                    message.push('by ' + lastModifiedUser);
                }
            }

            return message.join(' ');
        },
        columnDefinitionsUsingParentProps_andPropsForCustomComponent: function columnDefinitionsUsingParentProps_andPropsForCustomComponent(parentProps) {
            var propsForCustomComponent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            var listerConfigColumns = parentProps.columns,
                actions = parentProps.actions;


            return _lodash2.default.reduce(listerConfigColumns, function (collector, col) {
                var additionalCell = _extends({ id: col.key }, col);
                var type = col.type,
                    componentOverride = col.componentOverride,
                    componentParams = col.componentParams;
                //# NOTE: componentParams is deprecated 20190422 in favor of storing everything top-level in the column
                //# config

                var customComponentType = componentOverride || (COMPONENTS_BY_TYPE[type] ? type : undefined);
                var customComponent = COMPONENTS_BY_TYPE[customComponentType];

                if (customComponent) {
                    additionalCell.customComponent = customComponent(_extends({
                        successCallback: customComponentType == 'publishAndDate' ? actions.retrieveAndSetMostRecentlyModifiedItem : undefined
                    }, componentParams, col, propsForCustomComponent), parentProps);
                }

                collector.push(additionalCell);
                return collector;
            }, []);
        },
        formatRequestPropsForColumns: _formatRequestPropsForColumns2.default,
        savedSearchToWhereFilter: _savedSearchToWhereFilter2.default,

        templateOptionsFromForms: function templateOptionsFromForms(forms) {
            var optKeys = Object.keys(forms);

            var defaultOption = null,
                options = [];

            options = optKeys.map(function (optionKey) {
                var form = forms[optionKey];
                var displayName = _lodash2.default.get(form, 'name');
                var option = {
                    text: displayName || capitalizeTokens(rubyWords.inflection.transform(optionKey, ['humanize', 'pluralize'])),
                    value: optionKey
                };

                return option;
            }).filter(function (opt) {
                return opt.text && opt.value;
            }).sort(function (a, b) {
                var a_string = _lodash2.default.get(a, 'text');
                var b_string = _lodash2.default.get(b, 'text');

                return a_string.localeCompare(b_string);
            });
            return {
                options: options,
                defaultOption: defaultOption
            };
        }
    },

    searchableFieldsFor: function searchableFieldsFor(shouldIncludeForm_withFormKey) {
        var _this4 = this;

        var sort = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        var rootModule = this.getRoot();
        var formsID = this.props.formsID;
        var formsComponent = rootModule.findDescendentByID(formsID);
        var formsState = formsComponent.getState();

        var targetTemplates = _lodash2.default.reduce(formsState, function (collector, form, formKey) {
            if (shouldIncludeForm_withFormKey(form, formKey)) {
                collector.push(form);
            }
            return collector;
        }, []);

        var searchableFields = _lodash2.default.flatMapDeep(targetTemplates, function (targetTemplate) {
            return _this4.searchableFieldsForTemplate(targetTemplate, false);
        }).concat(_hiddenSearchableFields2.default);

        function sortFunction(a, b) {
            var aText = (a.label + ' ' + a.breadcrumb).toLowerCase();
            var bText = (b.label + ' ' + b.breadcrumb).toLowerCase();
            if (aText < bText) {
                return -1;
            }
            if (aText > bText) {
                return 1;
            }
            return 0;
        }

        var sortedSearchableFields = void 0;
        if (_lodash2.default.isBoolean(sort) && sort) {
            sortedSearchableFields = searchableFields.sort(sortFunction);
        } else if (_lodash2.default.isFunction(sort)) {
            sortedSearchableFields = searchableFields.sort(sort);
        } else {
            sortedSearchableFields = searchableFields;
        }

        return sortedSearchableFields;
    },
    searchableFieldsForKey: function searchableFieldsForKey(key) {
        var sort = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        var rootModule = this.getRoot();
        var formsID = this.props.formsID;
        var formsComponent = rootModule.findDescendentByID(formsID);
        var formsState = formsComponent.getState();

        var targetTemplate = _lodash2.default.get(formsState, [key]);

        return this.searchableFieldsForTemplate(targetTemplate, sort);
    },
    searchableFieldsForTemplate: function searchableFieldsForTemplate(targetTemplate) {
        var sort = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        var _getDependencies2 = this.getDependencies(),
            contentPropertyHelperComponent = _getDependencies2.contentPropertyHelperComponent;

        var searchableFieldsSpecs = targetTemplate && contentPropertyHelperComponent.fieldsSpecsFromTemplate(targetTemplate);

        var searchableFields = searchableFieldsSpecs ? searchableFieldsFromSearchableFieldsSpecs_andCtx(searchableFieldsSpecs) : [];
        //# TODO: dataPath is missing from searchableFieldsSpecs
        ////# it's searchableFieldsFromSearchableFieldsSpecs_andCtx() thatadds the dataPath
        //but nothing here suggests a reason why this is happening

        var sortedSearchableFields = void 0;
        if (_lodash2.default.isBoolean(sort) && sort) {
            sortedSearchableFields = searchableFields.sort(sortFunction);
        } else if (_lodash2.default.isFunction(sort)) {
            sortedSearchableFields = searchableFields.sort(sort);
        } else {
            sortedSearchableFields = searchableFields;
        }

        return sortedSearchableFields;

        function searchableFieldsFromSearchableFieldsSpecs_andCtx(searchableFieldsSpecs) {
            var ctx = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            var searchableFields = searchableFieldsSpecs.reduce(function (acc, spec) {
                return acc.concat(searchableFieldsFromSpec_andCtx(spec, ctx));
            }, []);

            return searchableFields;
        }

        function searchableFieldsFromSpec_andCtx(spec) {
            var ctx = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            var componentName = spec.componentName,
                _spec$children = spec.children,
                children = _spec$children === undefined ? [] : _spec$children,
                label = spec.label,
                breadcrumbPartial = spec.breadcrumbPartial,
                key = spec.key,
                dataPath = spec.dataPath,
                refKey = spec.refKey;
            var _ctx$breadcrumbArray = ctx.breadcrumbArray,
                breadcrumbArray = _ctx$breadcrumbArray === undefined ? [] : _ctx$breadcrumbArray;

            //# Don't include the leaf node's breadcrumbPartial

            var breadcrumb = (children.length ? breadcrumbArray.concat(breadcrumbPartial) : breadcrumbArray).filter(_lodash2.default.identity).join(' › ');

            var fullBreadcrumb = breadcrumbArray.concat(breadcrumbPartial).join('');
            var reactKey = _lodash2.default.compact([rubyWords.keyify(fullBreadcrumb), dataPath]).join('::');

            return _extends({}, spec, {
                breadcrumb: breadcrumb,
                dataPath: dataPath,
                children: searchableFieldsFromSearchableFieldsSpecs_andCtx(children, _extends({}, ctx, { breadcrumbArray: breadcrumbArray.concat(breadcrumbPartial) })),
                refKey: refKey,
                reactKey: reactKey
            });
        }

        function sortFunction(a, b) {
            var aText = (a.label + ' ' + a.breadcrumb).toLowerCase();
            var bText = (b.label + ' ' + b.breadcrumb).toLowerCase();
            if (aText < bText) {
                return -1;
            }
            if (aText > bText) {
                return 1;
            }
            return 0;
        }
    },

    getComposedGriddlePlugins_duringMergeProps: function getComposedGriddlePlugins_duringMergeProps(stateProps, dispatchProps, ownProps) {
        var _arguments = arguments;

        var selfModule = this;
        var mixins = selfModule.mixins || [];
        var pluginsAugmentors = mixins.map(function (mixin) {
            return mixin.getGriddlePluginsAugmentor_duringMergeProps;
        }).filter(_lodash2.default.isFunction).map(function (getGriddlePluginsAugmentor_duringMergeProps) {
            return getGriddlePluginsAugmentor_duringMergeProps.apply(selfModule, _arguments);
        });
        var augmentedPluginsList = _lodash2.default.flow(pluginsAugmentors)([]);
        return _lodash2.default.uniqBy(augmentedPluginsList, 'name');
    },

    getComposedUtilityBarActions_usingUtilityBarProps: function getComposedUtilityBarActions_usingUtilityBarProps(griddleInstance) {
        var _arguments2 = arguments;

        var selfModule = this;
        var mixins = selfModule.mixins || [];
        var utilityBarActionsAugmentors = mixins.map(function (mixin) {
            return mixin.getUtilityBarActionsAugmentor_usingUtilityBarProps;
        }).filter(_lodash2.default.isFunction).map(function (getUtilityBarActionsAugmentor_usingUtilityBarProps) {
            return getUtilityBarActionsAugmentor_usingUtilityBarProps.apply(selfModule, _arguments2);
        });
        var defaultUtilityBarActions = selfModule.getUtilityBarActions_usingUtilityBarProps.apply(selfModule, arguments);
        var augmentedUtilityBarActions = _lodash2.default.flow(utilityBarActionsAugmentors)(defaultUtilityBarActions);
        return augmentedUtilityBarActions;
    },
    children: function children() {
        var selfModule = this;
        var mixins = selfModule.mixins || [];
        var childrenAugmentors = mixins.map(function (mixin) {
            return mixin.getChildrenAugmentor;
        }).filter(_lodash2.default.isFunction).map(function (getUtilityBarActionsAugmentor) {
            return getUtilityBarActionsAugmentor.apply(selfModule);
        });
        return _lodash2.default.flow(childrenAugmentors)([]);
    },
    getUtilityBarActions_usingUtilityBarProps: _utilityBarActions2.default,

    getTemplateKey: function getTemplateKey(state) {
        var selfState = state || this.getState();
        return _lodash2.default.get(selfState, ['routeParams', 'template']) || this.props.templateKey;
    },
    getListerConfig: function getListerConfig(templateKey) {
        var _getDependencies3 = this.getDependencies(),
            listerConfigsComponent = _getDependencies3.listerConfigsComponent;

        templateKey = templateKey || this.getTemplateKey();

        return listerConfigsComponent.listerConfigForKey(templateKey, this);
    }

    //# renderListerFiltersWithListerConfig() might be called manually, like in MediaConnector
    //# in that case, we want to preempt the initialGriddleState
    //# otherwise there might be an order of operations issue
    , renderListerFiltersWithListerConfig: function renderListerFiltersWithListerConfig(listerConfig) {
        var initialGriddleState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var selfModule = this;
        listerConfig = listerConfig || this.props.listerConfig;

        var griddleState = _lodash2.default.result(selfModule, 'getState.griddleState', {}) || {};
        var utilityBarState = griddleState.utilityBarProperties || {};

        //# NOTE: not recommended but we're generating this here outside the scope of
        //# griddle so we don't have griddleProps

        var filters = _lodash2.default.get(listerConfig, 'filters', []).map(function (filter) {
            var _extends3;

            var componentName = filter.componentName || 'Dropdown';
            var Component = listerFilterComponents[componentName];

            var customFilterName = filter.name || filter.key;
            var customFilterData = utilityBarState.customFilters || {};
            var customFilterValue = customFilterData[customFilterName];
            //const value = _.get(griddleState, ['filter', 'customFilters', filter.key]);

            var delegateOnChange = componentName === 'Dropdown' && function (_event, _key, value) {
                var griddleState = _extends({}, initialGriddleState, _lodash2.default.result(selfModule, 'getState.griddleState', {}) || {});
                var utilityBarState = griddleState.utilityBarProperties || {};
                var utilityBarEvents = selfModule.getGriddleProps().events;

                /*
                const newCustomFiltersValue = Object.assign({}
                    , _.get(griddleState, 'filter.customFilters')
                    , {[filter.key]: value}
                );
                */

                var prevFilterValue = _lodash2.default.get(utilityBarState, ['customFilters', customFilterName]);
                if (_lodash2.default.isEqual(value, prevFilterValue)) {
                    //# Don't update the utilityBarProperties if the value hasn't changed
                    return;
                }

                var newUtilityBarProperties = _extends({}, utilityBarState || {}, {
                    customFilters: _extends({}, _lodash2.default.get(utilityBarState, 'customFilters'), _defineProperty({}, customFilterName, value))
                });

                utilityBarEvents.setUtilityBarProperties(newUtilityBarProperties);
                utilityBarEvents.getPage(1, true); //# jump back to the first page
                /*
                        griddleState.events.setFilter(Object.assign({}, griddleState.filter, {
                            customFilters: newCustomFiltersValue
                        }))
                */
            };
            return _react2.default.createElement(Component, _extends({}, filter, { delegateOnChange: delegateOnChange, value: customFilterValue }, (_extends3 = {
                'data-codecept-selector-node': 'Component',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Component'), _defineProperty(_extends3, 'data-codecept-selector-file', 'index'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Component'), _defineProperty(_extends3, 'data-codecept-selector-file', 'index'), _extends3)));
        });
        return filters;
    }
};

module.exports = listPageMixin;