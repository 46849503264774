'use strict';

module.exports = {
    fields: ['word_download_url'],
    columns: [{
        key: 'name',
        displayName: 'Name',
        resizable: true
    }, {
        key: 'manager_fk',
        displayName: 'Manager',
        resizable: true,
        width: 170,
        shouldExpand: true,
        type: 'valueInObject'
    }, {
        key: 'proposal_stage_fk',
        displayName: 'Proposal Stage',
        resizable: true,
        width: 140,
        shouldExpand: true,
        type: 'valueInObject'
    }, {
        key: 'last_modified_timestamp',
        type: 'date',
        displayName: 'Last Updated',
        sortable: true,
        width: 120,
        resizable: true,
        alwaysShow: true
    }],
    filters: [{
        header: "All Stages",
        key: "proposal_stage_fk",
        optionsURL: "/ruby/api/v2/content/proposal_stage/options" + "?ruby_client_fk=3" + "&content_subsite_fk=1" + "&include_all_active_option=1"
    }]
};