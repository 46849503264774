'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Card = require('material-ui/Card');

var _styleObject = require('./styleObject');

var _styleObject2 = _interopRequireDefault(_styleObject);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var SingleStat = _react2.default.createClass({
    displayName: 'SingleStat',

    propTypes: {
        label: PropTypes.string,
        data: PropTypes.string,
        dataPath: PropTypes.string,
        onValueClick: PropTypes.func
    },
    getDefaultProps: function getDefaultProps() {
        return {
            data: {},
            dataPath: 'total.displayValue'
        };
    },
    render: function render() {
        var _extends2, _React$createElement, _React$createElement2;

        return _react2.default.createElement(
            _Card.Card,
            (_React$createElement2 = {
                'data-codecept-selector-node': 'Card',
                'data-codecept-selector-file': 'SingleStat'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'SingleStat'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'SingleStat'), _React$createElement2),
            _react2.default.createElement(_Card.CardHeader, _extends({
                title: this.props.label,
                subtitle: this.props.subtitle
            }, _styleObject2.default.CardHeader, (_extends2 = {
                'data-codecept-selector-node': 'CardHeader',
                'data-codecept-selector-file': 'SingleStat'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'SingleStat'), _defineProperty(_extends2, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'SingleStat'), _extends2))),
            _react2.default.createElement(
                _Card.CardText,
                (_React$createElement = {
                    onTouchTap: this.props.onValueClick,
                    style: _extends({
                        fontSize: 28,
                        fontWeight: 'lighter',
                        paddingTop: 0
                    }, this.props.onValueClick && {
                        cursor: 'pointer'
                    }),
                    'data-codecept-selector-node': 'CardText',
                    'data-codecept-selector-file': 'SingleStat'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'SingleStat'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'SingleStat'), _React$createElement),
                _lodash2.default.get(this.props.data, this.props.dataPath)
            )
        );
    }
});

exports.default = SingleStat;