'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = UtilityBarPlugin;

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

var _constants = require('./constants');

var constants = _interopRequireWildcard(_constants);

var _actions = require('./actions');

var actions = _interopRequireWildcard(_actions);

var _reducer = require('./reducer');

var reducers = _interopRequireWildcard(_reducer);

var _initialState = require('./initial-state');

var _initialState2 = _interopRequireDefault(_initialState);

var _components = require('./components/');

var components = _interopRequireWildcard(_components);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function UtilityBarPlugin(utilityRenderer) {
  return {
    name: 'GriddleUtilityBar',
    constants: constants,
    actions: actions,
    helpers: {},
    states: _immutable2.default.fromJS(_extends({}, _initialState2.default, { utilityRenderer: utilityRenderer
    })),
    reducers: reducers,
    components: components
  };
};