'use strict';

var _aurl = require('../common/aurl');

var _utils = require('../common/utils');

var _constants = require('../common/constants');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var RCResourceLocator = RubyComponent.createClass({
    CONSTANTS: _constants2.default,
    propTypes: {
        preferPlainUris: PropTypes.bool
    },
    componentName: _constants2.default.COMPONENT_NAME,
    getDefaultProps: function getDefaultProps() {
        return {
            preferPlainUris: false
        };
    },
    aurl: _aurl.aurl,
    getLargeThumbnailUrl_forAsset: _aurl.getLargeThumbnailUrl_forAsset,
    makePlainUri: _utils.makePlainUri
});

module.exports = RCResourceLocator;