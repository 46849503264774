'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _Repeater = require('./Repeater');

var _Repeater2 = _interopRequireDefault(_Repeater);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rubyWords = require('@rubyapps/ruby-words');

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector;

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);
        var pickedSelfState = _lodash2.default.pick(selfState, ['childrenOrderById', 'childrenIdToTypeMap', 'rerenderTimestamp', 'options']);
        var options = selfState.options,
            optionsByType = selfState.optionsByType;


        var moduleProps = _extends({}, selfModule.props, { options: options, optionsByType: optionsByType });

        var optionsContainModule = options.filter(function (option) {
            return option.componentName == "Module";
        }).length > 0;

        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
        var formattedError = selfModule.formattedErrorFromState(state);

        var mappedProps = _extends({}, pickedSelfState, ownProps, moduleProps, mixinFieldProps, {
            id: selfModule.getID(),
            moduleMode: optionsContainModule,
            dragHandleClass: 'dragHandle__' + rubyWords.keyify(selfModule.getID()),
            children: ownProps.getMergedChildrenReactElements ? ownProps.getMergedChildrenReactElements() : ownProps.children
        }, formattedError);

        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Repeater2.default);
}