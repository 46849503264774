'use strict';

var React = require('react');


var request = require('@rubyapps/ruby-superagent');

var rubyComponent = require('@rubyapps/ruby-component');

var RubyComponentFESettings__CONSTANTS = require('@rubyapps/ruby-component-frontend-settings/src/common/constants');
var accessControlMixin = require('@rubyapps/ruby-component-mixin-access-control');

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;
var reducer = require('./reducer');
var action = require('./action');
var middleware = require('./middleware');

var RCCurrentUser = rubyComponent.createClass({
    componentName: componentName,
    mixins: [accessControlMixin] //# really just used for doesUserHavePermission()
    , action: action,
    reducer: reducer,
    middleware: middleware,
    getDefaultProps: function getDefaultProps() {
        return {
            feSettingsID: RubyComponentFESettings__CONSTANTS.COMPONENT_NAME
        };
    },
    dependencies: function dependencies() {
        var selfModule = this;
        var selfSelector = selfModule.getDefaultSelector();
        var selfActions = selfModule.getAction().generators;
        var rootModule = selfModule.getRoot();

        var feSettingsID = this.props.feSettingsID;
        var feSettingsComponent = rootModule.findDescendentByID(feSettingsID);
        var feSettingsSelector = feSettingsComponent.getDefaultSelector();

        var rubyClientFKSelector = rootModule.findDescendentByID('rubyComponentRubyClientFK').getDefaultSelector();

        return {
            selfSelector: selfSelector,
            selfActions: selfActions,
            feSettingsSelector: feSettingsSelector,
            rubyClientFKSelector: rubyClientFKSelector
        };
    },
    onReduxInit: function onReduxInit(dispatch) {
        var selfActions = this.getAction().generators;

        //# retrieve user information periodically to get updated role info
        //# but also be automatically redirected to the login page if logged out (session expiration)
        //# Note: since we have retry logic in ruby-superagent, we would only attempt to hit the  
        //# endpoint again once the previous request had returned. 
        dispatch(selfActions.pollLoggedInUser());

        /* //# NOTE: don't do it like this just because this gets called multiple times from other actions...
        const store = this.getStore();
        const rootModule = this.getRoot();
        const loginPageComponent = rootModule.findDescendentBy((element) => element.componentName == 'rubyComponentLoginPage');
        const loginRoute = loginPageComponent.getRoutePath();
         const store = this.getStore();
        const {
            selfSelector
            , selfActions
        } = this.getDependencies();
         store.subscribe(() => {
            const applicationState = store.getState();
            const selfState  = selfSelector(applicationState);
            
            if (!_.isNil(selfState) && Object.keys(selfState).length == 0) {
                store.dispatch(selfActions.navigateToPathWithOptions({
                    path: loginRoute
                }));
            }
          });
        */
    },
    onMount: function onMount() {},
    CONSTANTS: CONSTANTS,

    isResolved: function isResolved() {
        return !!this.getState();
    }
    //== OVERRIDES ==================================================//

    , getCurrentUser: function getCurrentUser() {
        return this;
    } //# used by accessControlMixin
});
module.exports = RCCurrentUser;