'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _SelectField = require('@rubyapps/ruby-material-ui/SelectField');

var _SelectField2 = _interopRequireDefault(_SelectField);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _Dropdown_style = require('./Dropdown_style');

var _Dropdown_style2 = _interopRequireDefault(_Dropdown_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var PropTypes_value = PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]);

var Dropdown = _react2.default.createClass({
    displayName: 'Dropdown',

    propTypes: _extends({}, _SelectField2.default.propTypes, {
        label: PropTypes.string,
        placeholder: PropTypes.string,
        placeholderValue: PropTypes.any,
        value: PropTypes_value,
        defaultValue: PropTypes_value,
        id: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes_value,
            text: PropTypes.string
        })),
        style: PropTypes.object,
        onComponentDidMount: PropTypes.func
    }),
    getDefaultProps: function getDefaultProps() {
        return {
            options: []
        };
    },
    onChange: function onChange(event, key, value, optionObject) {
        this.props.onChange && this.props.onChange(event, key, value, optionObject || this.props.options[key]);
    },
    render: function render() {
        var _extends2;

        var options = this.props.placeholder ? [{
            value: this.props.placeholderValue,
            text: this.props.placeholder
        }].concat(this.props.options) : this.props.options;

        var selectFieldProps = _lodash2.default.pick(this.props, Object.keys(_SelectField2.default.propTypes));
        var value = _lodash2.default.isUndefined(this.props.value) ? this.props.defaultValue : this.props.value;

        return _react2.default.createElement(
            _SelectField2.default,
            _extends({
                autoWidth: true
            }, _Dropdown_style2.default, selectFieldProps, (_extends2 = {
                floatingLabelText: this.props.label,
                value: value,
                onChange: this.onChange,
                'data-codecept-selector-node': 'SelectField',
                'data-codecept-selector-file': 'Dropdown'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'SelectField'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_extends2, 'data-codecept-selector-node', 'SelectField'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Dropdown'), _extends2)),
            options.map(function (option, index) {
                var _React$createElement;

                return _react2.default.createElement(_MenuItem2.default, (_React$createElement = {
                    key: index + '.' + option.value,
                    value: option.value,
                    primaryText: option.text,
                    checked: option.value === value,
                    insetChildren: true,
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'Dropdown'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Dropdown'), _React$createElement));
            })
        );
    },
    componentDidMount: function componentDidMount() {
        this.props.onComponentDidMount && this.props.onComponentDidMount.apply(this);
    }
});

exports.default = Dropdown;