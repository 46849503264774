'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _Permissions = require('./Permissions');

var _Permissions2 = _interopRequireDefault(_Permissions);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rubyAccessManager = require('@rubyapps/ruby-access-manager');

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector;

    var getOptionsEndpoint = selfModule.curriedGetOptionsEndpoint();

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);

        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);

        var permissionItemsByKey = selfState.permissionItemsByKey || {};

        var formValue = _lodash2.default.get(selfState, ['fields', selfModule.props.key, 'value']) || [];

        var _selfModule$getFieldV = selfModule.getFieldValueFromLocalState(selfState),
            toggledByPermId = _selfModule$getFieldV.toggledByPermId,
            idsByPermId = _selfModule$getFieldV.idsByPermId;

        var toggledByPermIdIncludingAncestors = selfModule.toggledByPermIdIncludingAncestors(selfState);

        var mappedProps = _extends({}, ownProps, mixinFieldProps, {
            id: selfModule.getID(),
            fieldKey: selfModule.props.key //key is not exposed to the react component
            , permissionItemsByKey: permissionItemsByKey,
            rootPermissionKey: 'root',
            getOptionsEndpoint: getOptionsEndpoint,
            idsByPermId: idsByPermId,
            toggledByPermId: toggledByPermIdIncludingAncestors
        });

        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Permissions2.default);
}