'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var uuid = require('node-uuid');

//# use a weakmap to map item to uuid
var weakMap = new WeakMap();

var guidHelperMixin = {
    mixinName: 'rubyComponentMixinFieldGuidHelper'

    /**
     * Taking the item and providing a hash for the iem
     * NOTE: in order to prevent attempting to 
     * @param {NumberLike} x - The magic number.
     **/
    , hashedItem: function hashedItem(item) {
        var itemUUID = weakMap.get(item);
        if (!itemUUID) {
            itemUUID = uuid.v1();
            weakMap.set(item, itemUUID);
        }

        return {
            _hash: itemUUID,
            _value: item
        };
    },
    unhashedItem: function unhashedItem(item) {
        return item ? item._value : null;
    },
    hashedItems: function hashedItems(items) {
        var _this = this;

        return (items || []).map(function (item, index) {
            return _extends({ _index: index }, _this.hashedItem(item));
        });
    },
    unhashedItems: function unhashedItems(items) {
        return (items || []).map(this.unhashedItem);
    },

    itemFromItemsMatchingHash: function itemFromItemsMatchingHash(items, hash) {
        var hashedItemsByHash = _.keyBy(this.hashedItems(items), '_hash');
        return this.unhashedItem(hashedItemsByHash[hash]);
    }
};

module.exports = guidHelperMixin;