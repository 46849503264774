'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rowHeight = 30;
var borderGray = '#cacaca';
var leftBackgroundGray = '#f7f7f7';
var leftColumnWidth = 200;

var TableHeaderColumnCommon = {
    style: {
        color: 'black',
        height: rowHeight,
        backgroundColor: leftBackgroundGray,
        padding: 0
    }
};

var TableRowColumnCommon = {
    style: {
        height: rowHeight,
        padding: 0,
        verticalAlign: 'top'
    }
};

var CellContentCommon = {
    style: {
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingLeft: 8
    }
};

exports.default = {
    Table: {
        bodyStyle: {},
        wrapperStyle: {
            maxHeight: '100%',
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid ' + borderGray
        }
    },
    TableRow: {
        style: {
            height: rowHeight,
            borderBottom: '1px solid ' + borderGray
        }
    },
    additionalField: {
        style: {
            borderTop: '1px solid ' + borderGray,
            width: '100%',
            minHeight: rowHeight
        }
    },
    TableRowLast: {
        style: {
            height: rowHeight
        }
    },
    TableHeaderColumnLeft: _lodash2.default.merge({}, TableHeaderColumnCommon, {
        style: {
            width: leftColumnWidth,
            borderRight: '1px solid ' + borderGray
        }
    }),
    TableHeaderColumnRight: _lodash2.default.merge({}, TableHeaderColumnCommon, {}),
    TableRowColumnLeft_fromRequired: function TableRowColumnLeft_fromRequired(required) {
        return _lodash2.default.merge({}, TableRowColumnCommon, {
            style: {
                backgroundColor: leftBackgroundGray,
                width: leftColumnWidth,
                fontWeight: required ? 'bold' : null,
                borderRight: '1px solid ' + borderGray
            }
        });
    },
    TableRowColumnRight: _lodash2.default.merge({}, TableRowColumnCommon, {}),
    HeaderContentWrapper: _lodash2.default.merge({}, CellContentCommon, {
        style: {
            textAlign: 'center'
        }
    }),
    CellContentWrapper: _lodash2.default.merge({}, CellContentCommon, {}),
    RichTextCellContentWrapper: {
        style: {
            minHeight: '100%',
            paddingLeft: 0
        }
    },
    SelectField: {
        style: {
            height: rowHeight,
            width: '100%'
        },
        labelStyle: {
            lineHeight: '35px'
        },
        iconStyle: {
            top: '3px'
        },
        underlineStyle: {
            display: 'none'
        }
    }
};