'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _immutable2.default.fromJS({
  pageProperties: {
    pageSize: 10,
    currentPage: 1,
    sortColumns: [],
    sortAscending: true
  },
  filter: '',
  columnFilters: []
});