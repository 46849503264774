'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BatchMovePlugin;

var _constants = require('./constants');

var constants = _interopRequireWildcard(_constants);

var _actions = require('./actions');

var actions = _interopRequireWildcard(_actions);

var _reducer = require('./reducer');

var reducers = _interopRequireWildcard(_reducer);

var _initialState = require('./initial-state');

var _initialState2 = _interopRequireDefault(_initialState);

var _components = require('./components/');

var components = _interopRequireWildcard(_components);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function BatchMovePlugin(batchMoveConfig) {
  return {
    name: 'GriddleBatchMove',
    constants: constants,
    actions: actions,
    storeBoundActions: [actions.onBatchMove],
    helpers: {},
    states: (0, _initialState2.default)(batchMoveConfig),
    reducers: reducers,
    components: components
  };
}