'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _Lister = require('@rubyapps/ruby-react-components/src/client/view/Lister');

var _Lister2 = _interopRequireDefault(_Lister);

var _styles = require('./styles.cssModule');

var _styles2 = _interopRequireDefault(_styles);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var PropTypes = _react2.default.PropTypes;
var _ = require('lodash');
var inflection = require('inflection');
var path = require('path');

var _require = require('@rubyapps/react-flexbox-grid'),
    Grid = _require.Grid,
    Row = _require.Row,
    Col = _require.Col;

var CONSTANTS = require('../../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var ListPagination = require('./list-pagination');

var DEFAULT_childrenPropsByClass = {
    Root: {},
    Grid: {},
    Row: {},
    Col: {},
    Lister: {} //# NOTE: this conflicts with the root-level props which gets picked and put into the Lister, so we want to consolidate eventually
};

var ListPage = _react2.default.createClass({
    displayName: 'ListPage',

    propTypes: {
        childrenPropsByClass: PropTypes.object,
        columnDefinitionsGenerator: PropTypes.func,
        components: PropTypes.object,
        currentPage: PropTypes.number,
        data: PropTypes.array //# TODO: make more concrete
        , griddleProps: PropTypes.object,
        headerLabelElement: _react2.default.PropTypes.oneOfType([_react2.default.PropTypes.string, _react2.default.PropTypes.node]),
        listPageKey: PropTypes.string,
        reflectGriddleState: PropTypes.func,
        renderLister: PropTypes.bool,
        remoteDataArgs: PropTypes.object,
        results: PropTypes.array //# TODO: make more concrete
        , resultsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortDirection: PropTypes.oneOf(['desc', 'asc'])
    },
    getDefaultProps: function getDefaultProps() {
        return {
            childrenPropsByClass: DEFAULT_childrenPropsByClass,
            listPageKey: 'list-page-key',
            renderLister: true //# NOTE: we default to true for backwards compatibility.
            //# if you're using ListPage and would like to wait on props, pass false
            , components: {},
            Lister: _Lister2.default
        };
    },
    shouldComponentUpdate: function shouldComponentUpdate(nextProps) {
        return !_.isEqual(this.props, nextProps);
    },
    getColumnDefinitions: function getColumnDefinitions() {
        var columnDefinitions = this.props.columnDefinitionsGenerator(this.props);

        //# inject order to maintain order

        //# NOTE: if order if provided in any of the columnDefinitions, they take precedence over the other columns
        //# NOTE: order cannot be 0
        var orderCounter = Math.max.apply(Math, _toConsumableArray(columnDefinitions.reduce(function (collector, colDef) {
            return colDef.hasOwnProperty('order') ? collector.concat(colDef.order) : collector;
        }, [0])));
        orderCounter++;

        //# order should only be i
        columnDefinitions.forEach(function (colDef) {
            if (colDef.hasOwnProperty('order')) {
                return;
            }
            colDef.order = orderCounter++;
        });

        return columnDefinitions;
    },
    render: function render() {
        var _this = this,
            _extends2,
            _React$createElement,
            _extends3,
            _extends4,
            _extends5,
            _extends6;

        //# TODO: deprecate listerHeader
        var components = _extends({
            Pagination: ListPagination
        }, this.props.components);

        var Lister__propTypeKeys = Object.keys(this.props.Lister.propTypes);
        var Lister__props = _.pick(this.props, Lister__propTypeKeys);

        var childrenPropsByClass = _extends({}, DEFAULT_childrenPropsByClass, this.props.childrenPropsByClass);

        return _react2.default.createElement(
            'div',
            _extends({ className: _styles2.default.Root }, childrenPropsByClass.Root, (_extends6 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'ListPage'
            }, _defineProperty(_extends6, 'data-codecept-selector-node', 'div'), _defineProperty(_extends6, 'data-codecept-selector-file', 'ListPage'), _defineProperty(_extends6, 'data-codecept-selector-node', 'div'), _defineProperty(_extends6, 'data-codecept-selector-file', 'ListPage'), _extends6)),
            _react2.default.createElement(
                Grid,
                _extends({}, childrenPropsByClass.Grid, (_extends5 = {
                    'data-codecept-selector-node': 'Grid',
                    'data-codecept-selector-file': 'ListPage'
                }, _defineProperty(_extends5, 'data-codecept-selector-node', 'Grid'), _defineProperty(_extends5, 'data-codecept-selector-file', 'ListPage'), _defineProperty(_extends5, 'data-codecept-selector-node', 'Grid'), _defineProperty(_extends5, 'data-codecept-selector-file', 'ListPage'), _extends5)),
                _react2.default.createElement(
                    Row,
                    _extends({}, childrenPropsByClass.Row, (_extends4 = {
                        'data-codecept-selector-node': 'Row',
                        'data-codecept-selector-file': 'ListPage'
                    }, _defineProperty(_extends4, 'data-codecept-selector-node', 'Row'), _defineProperty(_extends4, 'data-codecept-selector-file', 'ListPage'), _defineProperty(_extends4, 'data-codecept-selector-node', 'Row'), _defineProperty(_extends4, 'data-codecept-selector-file', 'ListPage'), _extends4)),
                    _react2.default.createElement(
                        Col,
                        _extends({ xs: 12, key: this.props.listPageKey }, childrenPropsByClass.Col, (_extends3 = {
                            'data-codecept-selector-node': 'Col',
                            'data-codecept-selector-file': 'ListPage'
                        }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Col'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ListPage'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Col'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ListPage'), _extends3)),
                        function () {
                            if (_this.props.listerHeader) {
                                return _this.props.listerHeader;
                            }
                        }(),
                        this.props.renderLister ? _react2.default.createElement(this.props.Lister, _extends({}, Lister__props, {
                            columnDefinitions: this.getColumnDefinitions(),
                            components: components,
                            currentPage: this.props.currentPage + 1,
                            data: this.props.data,
                            filterData: this.props.actions.displayListerData_withSearchTerm,
                            griddleProps: this.props.griddleProps,
                            headerLabelElement: this.props.headerLabelElement,
                            loadPage: this.loadPage,
                            plugins: this.props.plugins,
                            pluginsSettings: this.props.pluginsSettings,
                            reflectGriddleState: this.props.reflectGriddleState,
                            remoteDataArgs: this.props.remoteDataArgs,
                            results: this.props.results,
                            resultsPerPage: this.props.resultsPerPage,
                            setPageSize: this.setPageSize,
                            sort: this.sort
                        }, childrenPropsByClass.Lister, (_extends2 = {
                            'data-codecept-selector-file': 'ListPage'
                        }, _defineProperty(_extends2, 'data-codecept-selector-file', 'ListPage'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ListPage'), _extends2))) : _react2.default.createElement(
                            'div',
                            (_React$createElement = {
                                'data-codecept-selector-node': 'div',
                                'data-codecept-selector-file': 'ListPage'
                            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ListPage'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ListPage'), _React$createElement),
                            '  '
                        )
                    )
                )
            ),
            this.props.children
        );
    },

    getSortInformation: function getSortInformation(colId) {
        var columnDefinitions = this.props.columnDefinitionsGenerator(this.props);
        var colDef = columnDefinitions.find(function (def) {
            return def.id === colId;
        });
        var sortable = colDef.sortable || CONSTANTS.DEFAULT_SORTABLE_FLAG;
        var sortName = colDef.sortName || colId;
        var sortDirection = colDef.sortDirection || (isDateTimestampField(colDef.id) ? 'desc' : 'asc');

        if (this.props.sortColumn === sortName) {
            sortDirection = flipSortDir(this.props.sortDirection);
        }

        return {
            sortable: sortable,
            sortName: sortName,
            sortDirection: sortDirection
        };

        function flipSortDir(dir) {
            return dir.toLowerCase() === 'asc' ? 'desc' : 'asc';
        }
    }

    //# Should be DEPRECATED
    , sort: function sort(columnClicked) {
        var self = this;
        var sortInfo = self.getSortInformation(columnClicked);
        var sortable = sortInfo.sortable,
            sortName = sortInfo.sortName,
            sortDirection = sortInfo.sortDirection;


        return sortable ? this.props.actions.sortListerData_byColumnName_withDirection(sortName, sortDirection) : undefined;
    }

    //# DEPRECATED
    , loadPage: function loadPage(pageLoaded) {
        //# Subtract one from here becasue its not
        //# based at 0
        this.props.actions.displayListerData_forPage(pageLoaded - 1);
    }

    //# TODO: It looks like griddle hasn't
    //# gotten this fully working yet
    , setPageSize: function setPageSize(pageSize) {
        this.props.actions.displayListerData_withMaxResultsPerPage(pageSize);
    }

});

module.exports = function (props) {
    var griddleProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var selfModule = this;
    var frontendSettings = selfModule.getRoot().findDescendentByID('rubyComponentFrontendSettings');
    var frontendSettingsSelector = frontendSettings.getDefaultSelector();
    var listPageActions = selfModule.getAction().generators;
    var selfSelector = selfModule.getSelfStateSelector(); //selfModule.getDefaultSelector();
    //# NOTE: if we used getDefaultSelector, because we might have children components
    //# this might cause the ListPage to rerender unnecessarily

    var _statesSelector = function _statesSelector(state) {
        return {
            frontendSettingsState: frontendSettingsSelector(state),
            selfState: selfSelector(state)
        };
    };

    function mapStateToProps(state, ownProps) {
        var settings = frontendSettingsSelector(state);
        var www_server = settings ? settings["WWW.INIT.SSL_ONLY"] ? settings["WWW_SSL_SERVER"] : settings["WWW_SERVER"] : '';
        var listerState = selfSelector(state);
        var defaultSortColumn = ownProps.defaultSortColumn,
            defaultSortDirection = ownProps.defaultSortDirection;


        if (defaultSortColumn && !defaultSortDirection) {
            defaultSortDirection = isDateTimestampField(defaultSortColumn) ? 'desc' : 'asc';
        }

        var mergedGriddleProps = ownProps.griddleProps ? _extends({}, griddleProps, ownProps.griddleProps) : {};
        var mappedProps = _.assign({ griddleProps: mergedGriddleProps
            //, listerState
        }, {
            defaultSortColumn: defaultSortColumn,
            defaultSortDirection: defaultSortDirection,
            www_server: www_server
        }, props, listerState, selfModule.props.reactProps);

        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(listPageActions, dispatch)
        };
    }

    var connectedListPage = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(ListPage);

    //# NOTE: in lieu of refactoring this component so that we no longer have a connected ListPage, we
    //# override the shouldComponentUpdate() much like we currently do for the reactClass returned by the rubyComponent
    //# NOTE: use this tactic if you need to perform a quicker shouldComponentUpdate check
    //#     By default, redux-react relies on the mapStateToProps, etc outputs for the check, but those methods
    //#     might be heavy
    /*
    connectedListPage.prototype.shouldComponentUpdate = function(nextProps, nextState) {
        const nextApplicationState = this.store.getState();
         const nextSelectedState = _statesSelector(nextApplicationState);
        const currSelectedState = this._selectedState;
         if (!_.isEqual(nextSelectedState, currSelectedState)) {
            this._selectedState = nextSelectedState;
            return true;
        }
         return this.haveOwnPropsChanged;
    }
    */

    return connectedListPage;
};

function isDateTimestampField(field) {
    return field.indexOf('date') !== -1 || field.indexOf('timestamp') !== -1;
}