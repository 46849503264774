'use strict';

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function typesFromID(id) {
    var TYPES = {
        SHOW_LOADING: '@@ruby-app/' + id + '/SHOW_LOADING',
        CLEAR_LOADING: '@@ruby-app/' + id + '/CLEAR_LOADING',
        SET_ERROR: '@@ruby-app/' + id + '/SET_ERROR'
    };

    return TYPES;
}

var generators = {
    setError: function setError(errorPayload) {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        return {
            type: TYPES.SET_ERROR,
            payload: errorPayload
        };
    },
    showLoading: function showLoading() {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES;

        return {
            type: TYPES.SHOW_LOADING
        };
    },
    clearLoading: function clearLoading() {
        var _getAction3 = this.getAction(),
            TYPES = _getAction3.TYPES;

        return {
            type: TYPES.CLEAR_LOADING
        };
    },
    logIn: function logIn() {
        var selfModule = this;

        var _getDependencies = this.getDependencies(),
            currentUserActions = _getDependencies.currentUserActions;

        var selfSelectorObj = selfModule.getSelector();
        var generators = selfModule.getAction().generators;

        return function (dispatch, getState) {
            var applicationState = getState();
            var selfState = selfSelectorObj.default(applicationState);
            var routeParamsQuery = _lodash2.default.get(selfState, 'routeParams.query', {});

            var nextPage = routeParamsQuery.next_page ? decodeURIComponent(routeParamsQuery.next_page) : selfModule.props.nextPage;
            var data = {
                authenticationToken: routeParamsQuery.authenticationToken
            };

            dispatch(generators.showLoading());
            var thenableArguments = [function onResult(response) {
                //# navigate to page
                window.location.href = nextPage; //# we need to force a page reload to get the rest of the bundle
            }, function onError(err) {
                dispatch(generators.clearLoading());
                var errorResponseBody = err.response.body;

                //# set our own errors
                var errorMessage = _lodash2.default.get(errorResponseBody, 'error.message', '');
                var errorMessageHeader = _lodash2.default.get(errorResponseBody, 'error.messageHeader', 'Authentication token is invalid');
                dispatch(generators.setError({
                    error: {
                        message: errorMessage,
                        messageHeader: errorMessageHeader
                    }
                }));
            }];
            dispatch(currentUserActions.logIn(data, thenableArguments));
        };
    }
};

module.exports = function () {
    var TYPES = typesFromID(this.getID());
    return {
        TYPES: TYPES,
        generators: generators
    };
};