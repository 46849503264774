"use strict";

function typesWithID(id) {
    return {
        OPEN: "@@ruby-app/" + id + "/OPEN_DIALOG",
        CLOSE: "@@ruby-app/" + id + "/CLOSE_DIALOG"
    };
}

var generators = {
    open: function open() {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        return {
            type: TYPES.OPEN
        };
    },
    close: function close() {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES;

        return {
            type: TYPES.CLOSE
        };
    }

};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};