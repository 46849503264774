'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _FloatingActionButton = require('material-ui/FloatingActionButton');

var _FloatingActionButton2 = _interopRequireDefault(_FloatingActionButton);

var _add = require('material-ui/svg-icons/content/add');

var _add2 = _interopRequireDefault(_add);

var _IconMenu = require('material-ui/IconMenu');

var _IconMenu2 = _interopRequireDefault(_IconMenu);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var PropTypes = React.PropTypes;

var IconMenu__propTypeKeys = Object.keys(_IconMenu2.default.propTypes);

var TAB_KEY = 'Tab';
var FIELDSET_KEY = 'Fieldset';
var FIELD_KEY = 'Field';
var SUBTEMPLATE_KEY = 'SubTemplate';

var FloatingPlusMenu = React.createClass({
    displayName: 'FloatingPlusMenu',

    propTypes: {
        delegateMenuItemSelected: PropTypes.func,
        allowInsertSubtemplate: PropTypes.bool
    },
    getDefaultProps: function getDefaultProps() {
        return {
            allowInsertSubtemplate: true
        };
    },
    _handleMenuItemSelection: function _handleMenuItemSelection(type, event) {
        this.props.delegateMenuItemSelected && this.props.delegateMenuItemSelected(type);
    },
    _handleInsertTab: function _handleInsertTab(event) {
        this._handleMenuItemSelection(TAB_KEY, event);
    },
    _handleInsertFieldset: function _handleInsertFieldset(event) {
        this._handleMenuItemSelection(FIELDSET_KEY, event);
    },
    _handleInsertField: function _handleInsertField(event) {
        this._handleMenuItemSelection(FIELD_KEY, event);
    },
    _handleInsertSubTemplate: function _handleInsertSubTemplate(event) {
        this._handleMenuItemSelection(SUBTEMPLATE_KEY, event);
    },
    render: function render() {
        var _React$createElement, _extends2, _extends4;

        var allowInsertSubtemplate = this.props.allowInsertSubtemplate;


        var menuItems = [{
            primaryText: 'Tab',
            onTouchTap: this._handleInsertTab,
            type: TAB_KEY
        }, {
            primaryText: 'Fieldset',
            onTouchTap: this._handleInsertFieldset,
            type: FIELDSET_KEY
        }, {
            primaryText: 'Field',
            onTouchTap: this._handleInsertField,
            type: FIELD_KEY
        }, {
            primaryText: 'Subtemplate',
            onTouchTap: this._handleInsertSubTemplate,
            type: SUBTEMPLATE_KEY
        }].map(function (item) {
            if (!allowInsertSubtemplate) {
                return _lodash2.default.assign({}, item, {
                    disabled: item.type == SUBTEMPLATE_KEY
                });
            } else {
                return item;
            }
        });

        var propsToFixLongDelayOnTheBottomOfLargeTemplates = {
            //# on the bottom of large templates, the touch ripple causes a delay of
            //# approx. 4 seconds for the menu to show in chrome
            disableTouchRipple: true
        };

        return React.createElement(
            _IconMenu2.default,
            _extends({}, _lodash2.default.pick(this.props, IconMenu__propTypeKeys), (_extends4 = {
                iconButtonElement: React.createElement(
                    _FloatingActionButton2.default
                    //# on the bottom of large templates, the touch ripple causes
                    //# a delay of approx. 4 seconds for the menu to show in chrome
                    ,
                    _extends({}, propsToFixLongDelayOnTheBottomOfLargeTemplates, (_extends2 = {
                        'data-codecept-selector-node': 'FloatingActionButton',
                        'data-codecept-selector-file': 'FloatingPlusMenu'
                    }, _defineProperty(_extends2, 'data-codecept-selector-node', 'FloatingActionButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FloatingPlusMenu'), _defineProperty(_extends2, 'data-codecept-selector-node', 'FloatingActionButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FloatingPlusMenu'), _extends2)),
                    React.createElement(_add2.default, (_React$createElement = {
                        'data-codecept-selector-node': 'ContentAdd',
                        'data-codecept-selector-file': 'FloatingPlusMenu'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ContentAdd'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FloatingPlusMenu'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ContentAdd'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FloatingPlusMenu'), _React$createElement))
                ),
                anchorOrigin: { horizontal: 'right', vertical: 'top' },
                targetOrigin: { horizontal: 'right', vertical: 'bottom' },
                'data-codecept-selector-node': 'IconMenu',
                'data-codecept-selector-file': 'FloatingPlusMenu'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_extends4, 'data-codecept-selector-file', 'FloatingPlusMenu'), _defineProperty(_extends4, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_extends4, 'data-codecept-selector-file', 'FloatingPlusMenu'), _extends4)),
            menuItems.map(function (menuItem) {
                var _extends3;

                return React.createElement(_MenuItem2.default, _extends({
                    primaryText: menuItem.primaryText,
                    onTouchTap: menuItem.onTouchTap,
                    disabled: menuItem.disabled,
                    key: menuItem.type
                }, propsToFixLongDelayOnTheBottomOfLargeTemplates, (_extends3 = {
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'FloatingPlusMenu'
                }, _defineProperty(_extends3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends3, 'data-codecept-selector-file', 'FloatingPlusMenu'), _defineProperty(_extends3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends3, 'data-codecept-selector-file', 'FloatingPlusMenu'), _extends3)));
            })
        );
    }
});

exports.default = FloatingPlusMenu;