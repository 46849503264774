'use strict';

var lodash = require('lodash');
var deepdash = require('deepdash');
var _ = deepdash(lodash);

var ERROR_LEVEL_HIERARCHY = ['info', 'warning', 'error'];
function maxErrorLevel_fromLevels(levels) {
    return _.maxBy(levels, function (el) {
        return ERROR_LEVEL_HIERARCHY.indexOf(el);
    });
}

//# object might be deeply nested so iwe should deep walk this
function errorLevel_fromStringOrErrorObject(stringOrObject) {
    if (!stringOrObject) {
        return;
    }

    var defaultErrorLevel = 'error';
    if (_.isString(stringOrObject)) {
        return defaultErrorLevel;
    } else {

        //# stringOrObject might be deeply nested due to deferred rendering
        //# we need ot deeply select "level" 
        var arrayOfLevels = _.reduceDeep(stringOrObject, function (acc, value, key, parent, ctx) {

            if (key == 'level') {
                acc.push(value);
            }

            return acc;
        }, [], {
            leavesOnly: true
        });

        return maxErrorLevel_fromLevels(arrayOfLevels);
    }
}

module.exports = {
    actionTypesFromID: function actionTypesFromID(id) {
        return {
            REPLACE_LOCAL_STATE: '@@ruby-app/' + id + '/REPLACE_LOCAL_STATE',
            SET_PROPS: '@@ruby-app/' + id + '/SET_PROPS',
            SET_COMPONENT_IS_MOUNTED: '@@ruby-app/' + id + '/SET_COMPONENT_IS_MOUNTED',
            UPDATE_DISPLAY_VALUE_SPECS: '@ruby-app/' + id + '/UPDATE_DISPLAY_VALUE_SPECS',
            UPDATE_RERENDER_TIMESTAMP: '@ruby-app/' + id + '/UPDATE_RERENDER_TIMESTAMP'
        };
    },

    maxErrorLevel_fromLevels: maxErrorLevel_fromLevels,
    errorLevel_fromStringOrErrorObject: errorLevel_fromStringOrErrorObject
};