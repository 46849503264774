'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _TokenTagger = require('./TokenTagger/TokenTagger.js');

var _TokenTagger2 = _interopRequireDefault(_TokenTagger);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _TokenTagger2.default;