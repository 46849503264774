'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _ConnectorMappersGenerator = require('@rubyapps/ruby-component-mixin-field-base/src/client/reactComponents/ConnectorMappersGenerator');

var _ConnectorMappersGenerator2 = _interopRequireDefault(_ConnectorMappersGenerator);

var _RichTextEditor = require('./RichTextEditor');

var _RichTextEditor2 = _interopRequireDefault(_RichTextEditor);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {
    var selfModule = this;

    var _defaultMappersGenera = _ConnectorMappersGenerator2.default.call(this, _RichTextEditor2.default),
        defaultMapStateToProps = _defaultMappersGenera.mapStateToProps;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector;

    //# figure out the toHTML ahead of time


    var hydratedToHTML = this.hydratedToHTMLFromProps(this.props);

    var hydratedConfig = this.hydratedConfigWithChildrenReferences();

    var mergedBodyClass = hydratedConfig.body_class ? hydratedConfig.body_class + ' rte ' + (this.props.body_class || '') : 'rte ' + (this.props.body_class || '');

    hydratedConfig.body_class = _lodash2.default.uniq(mergedBodyClass.split(' ')).join(' ');
    //# TODO: 20170714 - need to review how we merge these bodyclasses, but it's good enough for now

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);

        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
        var formattedError = selfModule.formattedErrorFromState(state);
        var formattedErrorMessage = _lodash2.default.get(formattedError, 'error.message', '');
        var hasWordErrors = formattedErrorMessage.search(/word error|misspelling/i) > -1;

        var hydratedProps = selfModule.getProps(true);
        var mappedDefaultProps = defaultMapStateToProps.apply(null, arguments);
        var mappedProps = _extends({}, ownProps, mixinFieldProps, mappedDefaultProps, {
            id: selfModule.getID(),
            fieldKey: selfModule.props.key //key is not exposed to the react component
            , config: hydratedConfig,
            hasWordErrors: hasWordErrors
        }, _lodash2.default.pick(hydratedProps, ['keepToolbarOpen']), selfModule.props.key ? _lodash2.default.pick(selfState.fields[selfModule.props.key], ['value', 'error']) : {}, formattedError, hydratedToHTML ? { toHTML: hydratedToHTML } : {});

        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_RichTextEditor2.default);
}