// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoadingIndicator__refreshIndicator___kMzN0 {\n    display: inline-block;\n    position: relative;\n}\n\n.LoadingIndicator__loadingIndicatorWrapper___uQRs3 {\n    width: 100%;\n    height: 0px;\n    position: fixed;\n    top: 56px;\n    z-index: 9999;\n    text-align: center;\n    background: rgba(256,256,256,0.5);\n}\n\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-loading-indicator/src/client/reactComponents/LoadingIndicator.cssModule"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,eAAe;IACf,SAAS;IACT,aAAa;IACb,kBAAkB;IAClB,iCAAiC;AACrC","sourcesContent":[".refreshIndicator {\n    display: inline-block;\n    position: relative;\n}\n\n.loadingIndicatorWrapper {\n    width: 100%;\n    height: 0px;\n    position: fixed;\n    top: 56px;\n    z-index: 9999;\n    text-align: center;\n    background: rgba(256,256,256,0.5);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refreshIndicator": "LoadingIndicator__refreshIndicator___kMzN0",
	"loadingIndicatorWrapper": "LoadingIndicator__loadingIndicatorWrapper___uQRs3"
};
export default ___CSS_LOADER_EXPORT___;
