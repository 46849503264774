'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = require('../../package.json').name;
var logger = rubyLogger.getLogger(packageName);

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');

//const fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
//const fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
//const guidHelperMixin = require('@rubyapps/ruby-component-mixin-field-guid-helper');
var diffHelperMixin = require('@rubyapps/ruby-component-mixin-field-diff-helper');
var reduxHelperMixin = require('@rubyapps/ruby-component-mixin-redux-helper');
var fieldSelflessMixin = require('@rubyapps/ruby-component-mixin-field-selfless');

var forcedLeafNodeMixin = require('@rubyapps/ruby-component-mixin-field-forced-leaf-node');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var mixinUtils = require('@rubyapps/ruby-component-mixin-utils');

var _require = require('@rubyapps/ruby-component-mixin-field-base/src/common/constants'),
    DEFAULT_CHILDREN_ERROR_MESSAGE = _require.DEFAULT_CHILDREN_ERROR_MESSAGE;

var _require2 = require('@rubyapps/ruby-component-mixin-field-base/src/common/index'),
    maxErrorLevel_fromLevels = _require2.maxErrorLevel_fromLevels,
    errorLevel_fromStringOrErrorObject = _require2.errorLevel_fromStringOrErrorObject;

var dynamicMixin = {
    mixinName: 'rubyComponentMixinFieldDynamic',

    propTypes: {
        contentPropertyHelperID: PropTypes.string,
        visibleByDefault: PropTypes.bool
        //== OVERRIDES ===============================================//
    }, action: require('./action'),
    reducer: require('./reducer'),
    getDefaultProps: function getDefaultProps(props) {
        return {
            visibleByDefault: true,
            contentPropertyHelperID: require('@rubyapps/ruby-content-property-helper/src/common/constants').COMPONENT_NAME
        };
    },

    dependencies: function dependencies() {
        var rootModule = this.getRoot();

        var contentPropertyHelper = rootModule.findDescendentByID(this.props.contentPropertyHelperID);

        return {
            contentPropertyHelper: contentPropertyHelper
        };
    },
    getInitialState: function getInitialState() {
        var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};


        var initialState = {
            //# retain state on reset
            isVisible: /*state.isVisible || */this.props.visibleByDefault,
            isVisibleLatched: /*state.isVisibleLatched || */this.props.visibleByDefault
            //# NOTE: if we return the state.isVisible
            //# we encounter an issue where if the formValue is deferred in one language
            //# then we never seed the value correctly
        };

        return initialState;
    },

    updateChildren: function updateChildren(shouldRequestRerender, dispatchOrCollect) {
        this.replaceChildren();

        if (shouldRequestRerender) {
            var _getAction = this.getAction(),
                generators = _getAction.generators;

            if (!dispatchOrCollect) {
                dispatchOrCollect = this.getStore().dispatch;
            }
            dispatchOrCollect(generators.requestRerender());
        }
    },
    onReplaceLocalState: function onReplaceLocalState() {
        this.updateChildren();

        var _getAction2 = this.getAction(),
            actions = _getAction2.generators;

        this.getStore().dispatch(actions.requestRerender());
    },

    children: function children() {
        var _this = this;

        if (!this.props.__formattedChildren) {
            logger.warn('No __formattedChildren. This shouldn\'t happen as we expect this to be provided by ruby-component-builder');

            return [];
        }

        var existingChildren = this._children;
        if (existingChildren && existingChildren.length) {
            //# We do not want to create new children if it already exists
            return existingChildren;
        }

        var isVisibleLatched = this.isVisibleLatched();

        if (!isVisibleLatched) {
            return [];
        }

        var _require3 = require('@rubyapps/ruby-component-builder/src/common/index'),
            rubyComponentBuild = _require3.default;

        var idPrefix = this.getID();
        var spec = this.props;
        var theComponentName = spec.key || this.componentName;
        var compoundID = spec.id ? spec.id : idPrefix ? idPrefix + '.' + theComponentName : theComponentName;

        //console.log(`======= [${this.getID()}] CHLILDREN METHOD FOR [${this.getID()}] [depth: ${this.props.__depth}]`, this.props.__formattedChildren);

        var childrenDepth = this.props.__depth + 1;
        var childrenComponents = _.map(this.props.__formattedChildren, function (childSpec, index) {
            var builtChildComponent = rubyComponentBuild(childSpec, compoundID + '[' + index + ']', childrenDepth, index, { _parent: _this });
            return builtChildComponent;
        }).filter(function (rubyComponentChild) {
            return rubyComponentChild != undefined;
        });

        //console.log(`======= [FIELDSET] CHLILDREN METHOD FOR [${this.getID()}] [depth: ${this.props.__depth}] built children`, childrenComponents);

        return childrenComponents;
    }

    //# if has children but not rendered yet, we should cache the formValue and return it as-is when  requested
    , _formValueToLocalState: function _formValueToLocalState(formValue, dispatchOrCollect, isError, entireFormValue) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        var selfKey = this.props.key;

        var formValueForSelf = selfKey == undefined && !formValue.hasOwnProperty(selfKey) ? _defineProperty({}, selfKey, formValue)
        //# need to account for the case where selfKey is undefined and the formValue given isn't normalized to
        //# be {undefined: ...}
        : _.pick(formValue, [selfKey]);

        if (this.hasChildrenButNotRenderedYet()) {

            var limitedKeypaths = this.limitedChildrenKeypaths();
            var limitedFormValue = _.pick(formValueForSelf[selfKey], limitedKeypaths);

            //# if is error, we need to also store the error state locally and reseed it on expansion
            //# we're calling baseFieldMixin with an explicit key
            //# (either 'undefined' or whatever this.props.key is,
            //# in order to make sure the entirety of the formValue is cached
            //# so that when the dynamicMixin rerenders, we have the formValue to
            //# reseed the updated children

            return forcedLeafNodeMixin._formValueToLocalState.call(this, _defineProperty({}, selfKey, limitedFormValue), dispatchOrCollect, isError, entireFormValue, options);
        }

        var formValueForChildren = formValue.hasOwnProperty(selfKey) ? formValue[selfKey] : formValue;

        //# replaceChildren based on parentFormComponent's pristineFormData. `formValueFromLocalState` isn't ready
        //# unset the local form state in case this method was called after visibilty latched 
        //# ie, the children was updated after formValue was originally seeded
        return Promise.all([forcedLeafNodeMixin._formValueToLocalState.call(this, _defineProperty({}, selfKey, undefined), dispatchOrCollect, isError, entireFormValue, options), this._formValueToChildrenLocalState(formValueForChildren, dispatchOrCollect, isError, entireFormValue, options)]);
    }

    //# since we store the full formValue for DynamicForm locally in order to help rerendering
    //# we don't want to return that value if there's no key
    , _formValueFromLocalState: function _formValueFromLocalState() /*selfState, isError, predicateFormatter, entireState, options={}*/{
        if (this.hasChildrenButNotRenderedYet() || this.hasDeferredFormValue()) {
            //# since we're getting data FROM state, we need to check deferred formValue
            //# the childen *might* be rendered already because of multilingual/namespaces
            //#     if the user switched to another namespace, expanded a deferred fieldset
            //#     and then switched back, which has isVisibleLatched: false
            var selfKey = this.props.key;
            //# if no children but should have children, we need to retrieve the full form value and just use as-is
            var selfFormValue = forcedLeafNodeMixin._formValueFromLocalState.apply(this, arguments);

            return (selfKey == undefined ? selfFormValue[selfKey] : selfFormValue) || {};
            //# NOTE: value might be null, we should always return an object
        } else if (this.props.key) {
            return baseFieldMixin._formValueFromLocalState.apply(this, arguments);
        } else {
            return fieldSelflessMixin._formValueFromLocalState.apply(this, arguments);
        }
    },

    _objectValueFromLocalState: function _objectValueFromLocalState(selfState, isError, limitToTabWithLabel) {
        var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
        var ctx = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        var last_mixin = mixinUtils.superMixinContaining(this, '_objectValueFromLocalState', this._objectValueFromLocalState);
        var baseObjectValue = last_mixin._objectValueFromLocalState.apply(this, arguments);

        var selfKey = this.props.key;

        var baseObjectValueForID = baseObjectValue[this.getID()];
        var hasBaseObjectForKey = baseObjectValueForID.hasOwnProperty(selfKey);
        var baseObjectValueForKey = baseObjectValueForID[selfKey];

        if (this.hasChildrenButNotRenderedYet() || this.hasDeferredFormValue()) {
            //# since we're getting data FROM state, we need to check deferred formValue
            if (isError) {
                var _getDependencies = this.getDependencies(),
                    contentPropertyHelper = _getDependencies.contentPropertyHelper;

                //# need to pick only children keys  otherwise, the other keys stored here
                //# would override the actual fields


                var fieldSpecs = contentPropertyHelper.specsFromNodes_forTemplateKey(this.props.__formattedChildren, '');

                //# flatten
                //
                var flattenedFieldSpecs = contentPropertyHelper.nearestKeyedNodesFromSpecs(fieldSpecs);
                var limitedKeypaths = flattenedFieldSpecs.map(function (n) {
                    return n.dataPath;
                });
                var childrenErrors = _.pick(selfState.error || {}, limitedKeypaths);

                var doesChildrenHaveErrors = _.size(childrenErrors);

                var maxChildErrorLevel = maxErrorLevel_fromLevels(_.map(childrenErrors, errorLevel_fromStringOrErrorObject));

                if (baseObjectValueForKey) {
                    baseObjectValueForKey.message = doesChildrenHaveErrors ? {
                        msg: DEFAULT_CHILDREN_ERROR_MESSAGE,
                        level: maxChildErrorLevel || 'error'
                    } : null;
                } else {
                    baseObjectValueForID._value = doesChildrenHaveErrors ? {
                        msg: DEFAULT_CHILDREN_ERROR_MESSAGE,
                        level: maxChildErrorLevel || 'error'
                    } : null;
                }
            }
        } else {
            //# need to explicitly clear out the local error state
            //# it might have gotten set as part of the deferred rendering
            //# but when the deferred children are available, we no longer need to rely on a local error state set
            //# we are relying on the ctx object being passed in to last_mixin._objectValueFromLocalState
            //# to exfiltrate the doesChildrenHaveErrors status
            if (isError && !ctx.doesChildrenHaveErrors) {
                if (baseObjectValueForKey) {
                    baseObjectValueForKey.message = null;
                } else {
                    baseObjectValueForID._value = null;
                }
            }
        }

        return baseObjectValue;
    },

    _objectValueToLocalState: function _objectValueToLocalState(objectValue, dispatchOrCollect, isError) {
        var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

        if (this.hasChildrenButNotRenderedYet()) {
            //# if no children but should have children, we need to retrieve the full form value and just use as-is
            //# do NOT account for hasDeferredFormValue because we call on this to seed localState
            //# which we then intend to overwrite the deferred formValue
            return forcedLeafNodeMixin._objectValueToLocalState.apply(this, arguments);
        } else {
            return baseFieldMixin._objectValueToLocalState.apply(this, arguments);
        }
    }

    // ================================================================================ //
    // instance Utility methods

    , isVisible: function isVisible() {
        var selfState = this.getState() || {};

        return selfState.isVisible || this.props.visibleByDefault;
    },
    isVisibleLatched: function isVisibleLatched() {
        var selfState = this.getState() || {};

        return selfState.isVisibleLatched || this.props.visibleByDefault;
    },
    hasChildrenButNotRenderedYet: function hasChildrenButNotRenderedYet() {
        var expectedChildren = this.props.__formattedChildren;
        var renderedChildren = this.getChildren();

        return expectedChildren && !(renderedChildren && renderedChildren.length);
    },
    hasDeferredFormValue: function hasDeferredFormValue() {
        return !!this.getState().fields[this.props.key].value;
    },

    _formValueToChildrenLocalState: function _formValueToChildrenLocalState() /*formValue, dispatchOrCollect, isError, entireFormValue, options*/{
        var _arguments = arguments;


        var arrOfPromises = this.getChildren().reduce(function (collector, child) {
            if (child._formValueToLocalState) {
                //console.log('calling on child._formValuetoLocalState for arguments', arguments)
                var retval = child._formValueToLocalState.apply(child, _arguments);
                collector.push(retval);

                return collector;
            }
        }, []);

        return Promise.all(arrOfPromises);
    }

    // ================================================================================ //
    // class-level Utility methods
    /**
     *  Return fieldSpecs to be used by ruby-content-property-helper
     *  @property props
     *  @property ctx.dataPathArray - need to include this for nested components
     *  @params {Object} ctx.selfModule - the module of the root caller
     */
    , getFieldSpecFromProps: function getFieldSpecFromProps(props, ctx) {
        //# NOTE: fields using this mixin won't include self in result
        var Components = require('../../../ruby-component-builder/src/common/components').default;
        var dataPathArray = ctx.dataPathArray;


        var fieldSpecArr = [];
        var propsKey = props.key;
        /*
        //# For the Repeater, regardless of whether the key is available
        //# We do not want to include the repeater itself as a searchable field
        if (propsKey) {
            const preppedSpec = _.omit(props, ['children']);
            preppedSpec.dataPath = preppedSpec.key;
            fieldSpecArr.push(preppedSpec);
        }
        */

        var _props$options = props.options,
            options = _props$options === undefined ? [] : _props$options;


        var updatedDataPathArray = dataPathArray.concat(propsKey || []);

        //# need to hydrate the formJS config in props.options
        var fieldReducerFnGenerator = function fieldReducerFnGenerator(refKey) {
            var selfFieldReducerFn = function fieldReducerFn(collector, childValue, childIndex) {
                var componentForChild = Components[childValue.componentName];

                var last_mixin = mixinUtils.superMixinContaining(componentForChild, 'getFieldSpecFromProps', componentForChild.getFieldSpecFromProps);
                var getFieldSpecFromProps = componentForChild.getFieldSpecFromProps || last_mixin.getFieldSpecFromProps;
                //# need to account for getFieldSpecFromProps from all possible mixins

                var defaultProps = componentForChild.getDefaultProps ? componentForChild.getDefaultProps(childValue) : {};
                var mergedChildProps = _extends({}, defaultProps, childValue);

                var fieldSpecArrFromChild = getFieldSpecFromProps.call(componentForChild, mergedChildProps, ctx).map(function (fieldSpec) {
                    return _extends({ refKey: refKey }, fieldSpec);
                }); //# inject the refKey here
                collector = collector.concat(fieldSpecArrFromChild);

                if (childValue.hasOwnProperty('children')) {
                    return childValue.children.reduce(selfFieldReducerFn, collector);
                } else {
                    return collector;
                }
            };

            return selfFieldReducerFn;
        };

        /*
            //# Options look like:
            "options": [
                {
                    "text": "Career Card",
                    "type": "career_card",
                    "value": [
                        {
                            "componentName": "TokenTagger"
                        }
                    ]
                }
                , ...
            ]
        */

        var hydratedOptionsFieldSpecArr = _.flatMap(options, function (option) {
            var moduleType = option.type,
                moduleValue = option.value;


            return _.castArray(moduleValue).reduce(fieldReducerFnGenerator(moduleType), []);
        });

        var retval = _.reduce(hydratedOptionsFieldSpecArr, function (collector, value, key) {
            var preppedValue = _extends({}, value);

            if (propsKey) {
                preppedValue.dataPath = updatedDataPathArray.concat(preppedValue.key || []).join('.');
            }

            collector.push(preppedValue);

            return collector;
        }, fieldSpecArr);

        return retval;
    }
};

module.exports = dynamicMixin;