'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reduxBatchedActions = require('redux-batched-actions');

var path = require('path');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

var queryString = require('query-string');
var routeParser = require('route-parser');

//== The Middleware ========================================================//

var middlewareInitializer = function middlewareInitializer(store) {
    var _this = this;

    var selfModule = this;
    var rootModule = this.getRoot();
    var loadingIndicator = selfModule.getRubyComponentAtPath('.rubyComponentLoadingIndicator');
    var loadingIndicatorActions = loadingIndicator.getAction().generators;

    var allowedPathParams = this.props.allowedPathParams || {};
    var selfPath = selfModule.getRoutePath();
    var selfRouteParser = new routeParser(selfPath);
    var selfAction = selfModule.getAction();
    return function (next) {
        return function (action) {
            var nextRetVal = next(action); //# still allow @@router to continue propagation
            //# but also need to immediately call on next;

            if (action.type === '@@router/LOCATION_CHANGE') {
                var actionPayload = _extends({}, action.payload);
                //# handle the case where the payload.pathname contains hash because <Redirect/> isn't handling it correctly
                var pathnameSplitOnHash = actionPayload.pathname.split('#');
                actionPayload.pathname = pathnameSplitOnHash[0];
                if (!actionPayload.hash) {
                    actionPayload.hash = pathnameSplitOnHash[1] ? '#' + pathnameSplitOnHash[1] : '';
                }

                var pathname = actionPayload.pathname;
                var parsedRouteInfo = selfRouteParser.match(pathname);

                var parsedRouteInfoKeys = Object.keys(parsedRouteInfo);
                //get other instances of selfModule.componentName;
                var siblingModules = rootModule.findDescendentsBy(function (node) {
                    return node.componentName == selfModule.componentName;
                });
                var isSiblingPathMoreSpecific = siblingModules.reduce(function (collector, node) {
                    if (collector) {
                        return collector;
                    }

                    var siblingRouteInfo = node.getRoutePathParser().match(pathname);
                    if (siblingRouteInfo && Object.keys(siblingRouteInfo).length < parsedRouteInfoKeys.length) {
                        //console.log('deferring to sibling:', node, siblingRouteInfo);
                        return true;
                    }

                    return collector;
                }, false);

                if (isSiblingPathMoreSpecific) {
                    //# defer to sibling
                    return;
                }
                var routeInfo = allowedPathParams.action && allowedPathParams.action.filter(function (action) {
                    return action == parsedRouteInfo.action;
                }).length || allowedPathParams.action == undefined ? parsedRouteInfo : false;

                var routeInfoMatched = routeInfo;

                //console.log(selfModule.getID(), 'PATHNAME VS SELFPATH: ', pathname, selfPath, routeInfo, action);

                var selfState = selfModule.getState();
                var routeActive = selfState.routeParams.routeActive;

                var routeInfoWithQueryParams = _extends({
                    query: actionPayload.query
                }, routeInfo);

                var routeInfoKeys = Object.keys(routeInfoWithQueryParams);

                var isRouteDiff = routeInfoMatched && !_.isEqual(_.pick(selfState.routeParams, routeInfoKeys), routeInfoWithQueryParams);

                //const routeParams = selfState.routeParams;
                //const routeInfoDifferent = !_.isEqual(routeInfo, _.omit(routeParams, ['routeActive']));


                //# prefer dispatching leavingRoute FIRST
                //# Do not leave route if it's the same route and only the hash has changed
                //if (routeActive && !routeInfo) {
                //
                //# BUG: need to trigger leaving route if it's active but also need to prevent it if it's current active and htere's route info
                if (routeActive && !routeInfoMatched) {
                    //# Only dispatch leavingRoute if the route is active
                    //# LOCATION_CHANGE doesn't match, fire an event to allow
                    //# the ruby-component to clean out its store

                    store.dispatch(selfAction.generators.leavingRoute(actionPayload));
                    //# maybe we can check if the route will handle the loading hide itself
                }
                //if (routeInfo && (routeInfoDifferent || !routeActive)) {
                if (routeInfo) {
                    //console.log('==== setting route info:', routeInfo, ' for component:', selfModule.getID());
                    //# route info is different, ie it's the same route but the routeInfo is differerent
                    //# OR the route was inactive

                    if (selfModule.componentName == 'rubyComponentEditPage' && isRouteDiff) {
                        //# only if the route is already not active
                        store.dispatch(loadingIndicatorActions.show());
                    }

                    var urlHash = actionPayload.hash ? actionPayload.hash.substr(1) : undefined;
                    var urlHashObject = urlHash ? _extends(queryString.parse(urlHash), { __original: urlHash }) : undefined;
                    var routeInfoWithQueryParamsAndHash = _extends({
                        hash: urlHashObject
                    }, routeInfoWithQueryParams);

                    var _selfModule$props$def = selfModule.props.defaultRouteParams,
                        defaultRouteParams = _selfModule$props$def === undefined ? {} : _selfModule$props$def;


                    var finalRouteInfo = _extends({}, defaultRouteParams, routeInfoWithQueryParamsAndHash);

                    //console.log('==== setting route info:', finalRouteInfo, ' for component:', selfModule.getID(), action);
                    var batchActionsArray = [].concat(
                    //(selfModule.componentName == 'rubyComponentEditPage' && isRouteDiff)? loadingIndicatorActions.show() :[],
                    [{
                        type: '@@ruby-app/UPDATE_GLOBAL_ROUTE_PARAMS',
                        payload: routeInfo
                    }, selfAction.generators.setRouteParams(finalRouteInfo)
                    //, loadingIndicatorActions.show()
                    ]);

                    var batchActionsObject = (0, _reduxBatchedActions.batchActions)(batchActionsArray);

                    return selfModule.promiseForDependenciesResolution().then(function () {
                        if (selfModule.props.rerouteBasedOnBestUrl) {
                            var reroutedPath = rootModule.getBestUrlForComponentName_fromModule_withParams(selfModule.componentName, _this, routeInfo);

                            logger.trace('Handling \'rerouteBasedOnBestUrl\'. Pathname: ' + pathname + ' vs reroutedPath: ' + reroutedPath);

                            if (reroutedPath != pathname) {
                                //# replace path
                                if (selfModule.componentName == 'rubyComponentEditPage' && isRouteDiff) {
                                    //# only if the route is already not active
                                    store.dispatch(loadingIndicatorActions.hide()); //# need to clean up
                                }

                                store.dispatch(selfAction.generators.replace(reroutedPath));
                            } else {
                                store.dispatch(batchActionsObject);
                            }
                        } else {
                            store.dispatch(batchActionsObject);
                        }
                    });
                }
            }

            return nextRetVal;
        };
    };
};

module.exports = middlewareInitializer;