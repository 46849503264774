'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Toolbar = require('material-ui/Toolbar');

var _Chip = require('@rubyapps/ruby-material-ui/Chip');

var _Chip2 = _interopRequireDefault(_Chip);

var _AdvancedSearchUtilityBar = require('./AdvancedSearchUtilityBar.cssModule');

var _AdvancedSearchUtilityBar2 = _interopRequireDefault(_AdvancedSearchUtilityBar);

var _AdvancedSearchUtilityBar_style = require('./AdvancedSearchUtilityBar_style');

var _AdvancedSearchUtilityBar_style2 = _interopRequireDefault(_AdvancedSearchUtilityBar_style);

var _close = require('material-ui/svg-icons/navigation/close');

var _close2 = _interopRequireDefault(_close);

var _IconButton = require('material-ui/IconButton/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _AutoCompleteSelector = require('@rubyapps/ruby-react-components/src/client/input/AutoCompleteSelector');

var _AutoCompleteSelector2 = _interopRequireDefault(_AutoCompleteSelector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

// Material UI Components


//# == POTENTIALLY TEMPORARY IMPORTS ================//


var SelectColumnsToolbarGroup = _react2.default.createClass({
    displayName: 'SelectColumnsToolbarGroup',

    propTypes: {
        isSelectColumnsVisible: PropTypes.bool,
        columnDefinitionsByKey: PropTypes.object,
        columnDefinitions: PropTypes.array,
        columnDefinitions__dataSourceConfig: PropTypes.object
        //, allColumns: PropTypes.arrayOf(PropTypes.string)
        , selectedColumnSpecsArr: PropTypes.arrayOf(PropTypes.object) //# specifically for alwaysShow (or other listerConfig props)
        , selectedColumns: PropTypes.arrayOf(PropTypes.string)
        //# TODO: figure out how to get access to the Griddle events prop
        , events: PropTypes.shape({
            toggleColumn: PropTypes.func
        }),
        delegateOnColumnToggle: PropTypes.func,
        delegateSelectColumnsClose: PropTypes.func
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            isSelectColumnsVisible: true,
            events: {
                toggleColumn: function toggleColumn() {}
            },
            columnDefinitions: []
            //, allColumns: []
            //# NOTE: might want to change implementation so that we enforce a sort order
            , selectedColumnSpecsArr: [],
            selectedColumns: [],
            delegateOnColumnToggle: function delegateOnColumnToggle(columnValue, columnObject) {}
        };
    },
    getInitialState: function getInitialState() {
        return {
            columnSearchText: ''
        };
    },
    _delegatedSelectColumns__onUpdateInput: function _delegatedSelectColumns__onUpdateInput(searchText) {
        this.setState({
            columnSearchText: searchText
        });
    },
    _delegatedSelectColumns__onOptionSelected: function _delegatedSelectColumns__onOptionSelected(chosenRequest) {
        var dataSourceConfig = this.props.columnDefinitions__dataSourceConfig;
        var chosenRequestKey = chosenRequest[dataSourceConfig.key];
        this.props.delegateOnColumnToggle(chosenRequestKey, chosenRequest);

        this.setState({
            columnSearchText: ''
        });
    },
    _renderChip: function _renderChip(columnDefinition) {
        var _this = this,
            _extends2;

        var selectedColumnSpec = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        //# get background color from muiTheme
        var baseTheme = this.context.muiTheme.baseTheme;

        var primary1Color = baseTheme.palette.primary1Color;

        var dataSourceConfig = this.props.columnDefinitions__dataSourceConfig;

        var dataKey = columnDefinition[dataSourceConfig.key];
        var dataText = columnDefinition[dataSourceConfig.text];
        return _react2.default.createElement(
            _Chip2.default,
            _extends({}, _AdvancedSearchUtilityBar_style2.default.Chip, (_extends2 = {
                key: dataKey,
                onRequestDelete: selectedColumnSpec.alwaysShow ? null : function () {
                    return _this.props.delegateOnColumnToggle(dataKey);
                },
                backgroundColor: primary1Color,
                'data-codecept-selector-node': 'Chip',
                'data-codecept-selector-file': 'SelectColumnsToolbarGroup'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Chip'), _defineProperty(_extends2, 'data-codecept-selector-file', 'SelectColumnsToolbarGroup'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Chip'), _defineProperty(_extends2, 'data-codecept-selector-file', 'SelectColumnsToolbarGroup'), _extends2)),
            dataText
        );
    },
    render: function render() {
        var _extends3,
            _React$createElement,
            _this2 = this,
            _extends4,
            _extends5,
            _React$createElement2,
            _React$createElement3,
            _extends6;

        var ToolbarGroupStyle = _extends({}, _AdvancedSearchUtilityBar_style2.default.ToolbarGroup__nested.style, {
            maxHeight: this.props.isSelectColumnsVisible ? 200 : 0
        });

        var dataSourceConfig = this.props.columnDefinitions__dataSourceConfig;

        var columnDefinitionsByKey = this._columnDefinitionsByKey();
        var selectedColumnSpecsByKey = this._selectedColumnSpecsByKey();
        var allColumns = Object.keys(columnDefinitionsByKey);
        var remainingColumns = _lodash2.default.difference(allColumns, this.props.selectedColumns);
        var remainingColumnData = remainingColumns.map(function (columnKey) {
            return columnDefinitionsByKey[columnKey];
        });

        return _react2.default.createElement(
            _Toolbar.ToolbarGroup,
            _extends({}, _AdvancedSearchUtilityBar_style2.default.ToolbarGroup__nested, (_extends6 = {
                className: _AdvancedSearchUtilityBar2.default.ToolbarGroup__nested,
                style: ToolbarGroupStyle,
                'data-codecept-selector-node': 'ToolbarGroup',
                'data-codecept-selector-file': 'SelectColumnsToolbarGroup'
            }, _defineProperty(_extends6, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_extends6, 'data-codecept-selector-file', 'SelectColumnsToolbarGroup'), _defineProperty(_extends6, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_extends6, 'data-codecept-selector-file', 'SelectColumnsToolbarGroup'), _extends6)),
            _react2.default.createElement(
                'div',
                _extends({}, _AdvancedSearchUtilityBar_style2.default.ToolbarGroupDiv__selectColumns, (_extends5 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'SelectColumnsToolbarGroup'
                }, _defineProperty(_extends5, 'data-codecept-selector-node', 'div'), _defineProperty(_extends5, 'data-codecept-selector-file', 'SelectColumnsToolbarGroup'), _defineProperty(_extends5, 'data-codecept-selector-node', 'div'), _defineProperty(_extends5, 'data-codecept-selector-file', 'SelectColumnsToolbarGroup'), _extends5)),
                _react2.default.createElement(
                    'div',
                    (_React$createElement = {
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'SelectColumnsToolbarGroup'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'SelectColumnsToolbarGroup'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'SelectColumnsToolbarGroup'), _React$createElement),
                    _react2.default.createElement(_AutoCompleteSelector2.default, _extends({}, _AdvancedSearchUtilityBar_style2.default.AutoComplete__fullWidth, (_extends3 = {
                        hintText: 'Select columns',
                        menuCloseDelay: 0,
                        menuProps: {
                            className: _AdvancedSearchUtilityBar2.default.AutoCompleteSelector
                        },
                        canAutoPosition: false,
                        searchText: this.state.columnSearchText,
                        onUpdateInput: this._delegatedSelectColumns__onUpdateInput,
                        onOptionSelected: this._delegatedSelectColumns__onOptionSelected,
                        dataSource: remainingColumnData,
                        dataSourceConfig: this.props.columnDefinitions__dataSourceConfig,
                        openOnFocus: true,
                        filter: function filter(searchText, key) {
                            return key && key.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
                        },
                        fullWidth: true,
                        shouldClearUnfoundedSearch_onMenuClose: false,
                        'data-codecept-selector-node': 'AutoCompleteSelector',
                        'data-codecept-selector-file': 'SelectColumnsToolbarGroup'
                    }, _defineProperty(_extends3, 'data-codecept-selector-node', 'AutoCompleteSelector'), _defineProperty(_extends3, 'data-codecept-selector-file', 'SelectColumnsToolbarGroup'), _defineProperty(_extends3, 'data-codecept-selector-node', 'AutoCompleteSelector'), _defineProperty(_extends3, 'data-codecept-selector-file', 'SelectColumnsToolbarGroup'), _extends3)))
                ),
                _react2.default.createElement(
                    'div',
                    _extends({}, _AdvancedSearchUtilityBar_style2.default.ToolbarGroupDiv__selectedColumnTags, (_extends4 = {
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'SelectColumnsToolbarGroup'
                    }, _defineProperty(_extends4, 'data-codecept-selector-node', 'div'), _defineProperty(_extends4, 'data-codecept-selector-file', 'SelectColumnsToolbarGroup'), _defineProperty(_extends4, 'data-codecept-selector-node', 'div'), _defineProperty(_extends4, 'data-codecept-selector-file', 'SelectColumnsToolbarGroup'), _extends4)),
                    this.props.selectedColumns.map(function (selectedColumn) {
                        var columnDef = columnDefinitionsByKey[selectedColumn];
                        var selectedColumnSpec = selectedColumnSpecsByKey[selectedColumn];
                        return columnDef ? _this2._renderChip(columnDef, selectedColumnSpec) : null;
                    })
                )
            ),
            _react2.default.createElement(
                _IconButton2.default,
                (_React$createElement3 = { onTouchTap: this.props.delegateSelectColumnsClose, 'data-codecept-selector-node': 'IconButton',
                    'data-codecept-selector-file': 'SelectColumnsToolbarGroup'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'SelectColumnsToolbarGroup'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'SelectColumnsToolbarGroup'), _React$createElement3),
                _react2.default.createElement(_close2.default, (_React$createElement2 = {
                    'data-codecept-selector-node': 'CloseIcon',
                    'data-codecept-selector-file': 'SelectColumnsToolbarGroup'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'CloseIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'SelectColumnsToolbarGroup'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'CloseIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'SelectColumnsToolbarGroup'), _React$createElement2))
            )
        );
    }

    //== START: UTILITY =================================================================//

    ,
    _columnDefinitionsByKey: function _columnDefinitionsByKey() {
        var columnDefinitions = this.props.columnDefinitions;

        var dataSourceConfig = this.props.columnDefinitions__dataSourceConfig;

        return _lodash2.default.reduce(columnDefinitions, function (collector, columnDefinition) {
            var columnDefinitionKey = columnDefinition[dataSourceConfig.key];

            collector[columnDefinitionKey] = columnDefinition;
            return collector;
        }, {});
    },
    _selectedColumnSpecsByKey: function _selectedColumnSpecsByKey() {
        var selectedColumnSpecsArr = this.props.selectedColumnSpecsArr;

        var selectedColumnSpecsByKey = _lodash2.default.keyBy(selectedColumnSpecsArr, 'key');

        return selectedColumnSpecsByKey;
    }
    //== END: UTILITY ==================================================================//
});

exports.default = SelectColumnsToolbarGroup;