'use strict';

var baseClass = {
    mixins: []
    //# static method
    , registerMixin: function registerMixin(mixin) {
        var indexOfMixin = this.mixins.indexOf(mixin);
        if (indexOfMixin < 0) {
            this.mixins.push(mixin);
        }
    }
};

module.exports = baseClass;