'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _transitions = require('material-ui/styles/transitions');

var _transitions2 = _interopRequireDefault(_transitions);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var RichErrorMessages = _react2.default.createClass({
    displayName: 'RichErrorMessages',

    propTypes: {
        style: PropTypes.object,
        simpleMessage: PropTypes.string,
        messages: PropTypes.arrayOf(PropTypes.shape({
            msg: PropTypes.node,
            name: PropTypes.string,
            level: PropTypes.oneOf(['error', 'warning', 'info'])
        }))
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            messages: null
        };
    },
    render: function render() {
        var _React$createElement, _React$createElement5;

        var _context$muiTheme$raw = this.context.muiTheme.rawTheme.palette,
            errorColor_foreground = _context$muiTheme$raw.errorColor_foreground,
            warningColor_foreground = _context$muiTheme$raw.warningColor_foreground,
            infoColor_foreground = _context$muiTheme$raw.infoColor_foreground;


        var colorByLevel = {
            error: errorColor_foreground,
            warning: warningColor_foreground,
            info: infoColor_foreground
        };

        if (!this.props.simpleMessage && _lodash2.default.isEmpty(this.props.messages)) {
            return null;
        }

        var messageGroups = _lodash2.default.groupBy(this.props.messages, 'name');

        return _react2.default.createElement(
            'div',
            (_React$createElement5 = {
                style: _extends({
                    fontSize: 12,
                    lineHeight: '12px',
                    transition: _transitions2.default.easeOut()
                }, this.props.style),
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'RichErrorMessages'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'RichErrorMessages'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'RichErrorMessages'), _React$createElement5),
            this.props.simpleMessage && _react2.default.createElement(
                'div',
                (_React$createElement = { style: {
                        color: errorColor_foreground,
                        marginBottom: 5
                    }, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'RichErrorMessages'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'RichErrorMessages'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'RichErrorMessages'), _React$createElement),
                this.props.simpleMessage
            ),
            _lodash2.default.map(messageGroups, function (messages, groupName) {
                var _React$createElement4;

                return _react2.default.createElement(
                    'div',
                    (_React$createElement4 = {
                        key: groupName,
                        style: {
                            marginBottom: 5,
                            transition: _transitions2.default.easeOut()
                        },
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'RichErrorMessages'
                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'RichErrorMessages'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'RichErrorMessages'), _React$createElement4),
                    messages.map(function (_ref, idx) {
                        var _React$createElement3;

                        var msg = _ref.msg,
                            level = _ref.level;
                        return _react2.default.createElement(
                            'div',
                            (_React$createElement3 = {
                                key: idx,
                                style: {
                                    color: colorByLevel[level]
                                },
                                'data-codecept-selector-node': 'div',
                                'data-codecept-selector-file': 'RichErrorMessages'
                            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'RichErrorMessages'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'RichErrorMessages'), _React$createElement3),
                            (_lodash2.default.isString(msg) ? msg.split('\n') : _lodash2.default.castArray(msg)).map(function (msg) {
                                var _React$createElement2;

                                return _react2.default.createElement(
                                    'p',
                                    (_React$createElement2 = {
                                        'data-codecept-selector-node': 'p',
                                        'data-codecept-selector-file': 'RichErrorMessages'
                                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'RichErrorMessages'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'RichErrorMessages'), _React$createElement2),
                                    msg
                                );
                            })
                        );
                    })
                );
            })
        );
    }
});

exports.default = RichErrorMessages;