'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _rubyMemoize = require('../../../../../ruby-memoize');

var _selectedColumnsPropsHelper = require('@rubyapps/ruby-component-mixin-list-page/src/client/reactComponents/selectedColumnsPropsHelper');

var _selectedColumnsPropsHelper2 = _interopRequireDefault(_selectedColumnsPropsHelper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var memoize = require('memoizee');

var routeParser = require('@rubyapps/route-parser');

function raw_routeParserForUrl(url) {
    return new routeParser(url);
}
var memoized_routeParserForUrl = memoize(raw_routeParserForUrl, _extends({}, _rubyMemoize.defaultMemoizeeOptions, length));

function hydrateUrlWithData(url, data) {
    var urlRouteParser = memoized_routeParserForUrl(url);

    return urlRouteParser.reverse(data);
}

function ListUtilityBarConnector(selfModule) {
    var getRubyComponentProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {
        return {};
    };

    var getUtilityBarActions_usingUtilityBarProps = selfModule.getComposedUtilityBarActions_usingUtilityBarProps.bind(selfModule);

    var _selfModule$getDepend = selfModule.getDependencies(),
        editPageComponent = _selfModule$getDepend.editPageComponent;

    //# NOTE: this renderer is used by the GriddleUtilityBarPlugin
    //# we updated griddle-react to heavily cache the plugins
    //# so you should not pass in data that's actively changing
    //# instead, you should rely on a function that retrieves the updated info
    //# eg. do not use addEntryUrl, rather, use selfModule.getDependencies().getAddEntryUrl()


    return function (UtilityBar) {
        return function render(griddleProps) {
            var _extends2;

            var griddleInstance = this;
            var rubyComponentProps = getRubyComponentProps();

            //# get additional header items for the toolbar. WE call out to this method because 
            var additionalHeaderToolbarItems = selfModule.additionalHeaderToolbarItems(griddleProps, rubyComponentProps);

            var utilityBarActions = getUtilityBarActions_usingUtilityBarProps(griddleProps);

            //# for column selection
            var selectedColumnsProps = (0, _selectedColumnsPropsHelper2.default)(rubyComponentProps, griddleProps);

            return _react2.default.createElement(UtilityBar, _extends({
                griddleProps: griddleProps,
                additionalHeaderToolbarItems: additionalHeaderToolbarItems

            }, { utilityBarActions: utilityBarActions }, selectedColumnsProps, (_extends2 = {
                'data-codecept-selector-node': 'UtilityBar',
                'data-codecept-selector-file': 'indexConnector'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'UtilityBar'), _defineProperty(_extends2, 'data-codecept-selector-file', 'indexConnector'), _defineProperty(_extends2, 'data-codecept-selector-node', 'UtilityBar'), _defineProperty(_extends2, 'data-codecept-selector-file', 'indexConnector'), _extends2)));
        };
    };
}

exports.default = ListUtilityBarConnector;