'use strict';

var RubyComponentTemplateEditPage__CONSTANTS = require('@rubyapps/ruby-component-plugin-template-editor/src/local_modules/ruby-component-template-edit-page/src/common/constants');
var RubyComponentFieldForm__CONSTANTS = require('@rubyapps/ruby-component-field-form/src/common/constants');

//== The Middleware ========================================================//

var middlewareInitializer = function middlewareInitializer(store) {
    var selfModule = this;
    var selfSelector = this.getDefaultSelector();
    var selfAction = selfModule.getAction();

    var _selfModule$getDepend = selfModule.getDependencies(),
        formsComponent = _selfModule$getDepend.formsComponent;

    var _formsComponent$getAc = formsComponent.getAction(),
        formsComponent__TYPES = _formsComponent$getAc.TYPES;

    return function (next) {
        return function (action) {
            var nextRetVal = next(action); //# still allow @@router to continue propagation
            var actionType = action.type;

            switch (actionType) {
                case formsComponent__TYPES.SET_FORM:
                case formsComponent__TYPES.SET_FORMS:
                    store.dispatch(selfAction.generators.update_lastUpdatedTimestamp());
                    break;
            }

            return nextRetVal;
        };
    };
};

module.exports = middlewareInitializer;