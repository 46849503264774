'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _dndCore = require('dnd-core');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var HTML5Backend = require('react-dnd-html5-backend');

var defaultManager = void 0;
function getDefaultManager() {
    if (!defaultManager) {
        defaultManager = new _dndCore.DragDropManager(HTML5Backend.default);
    }
    return defaultManager;
}

module.exports = function decorateContext(DecoratedComponent) {
    var displayName = DecoratedComponent.displayName || DecoratedComponent.name || 'Component';

    return _react2.default.createClass({
        displayName: 'DragDropContext(' + displayName + ')',

        childContextTypes: {
            dragDropManager: _react.PropTypes.object.isRequired
        },

        getDecoratedComponentInstance: function getDecoratedComponentInstance() {
            return this.refs.child;
        },
        getManager: function getManager() {
            return getDefaultManager(); //childContext.dragDropManager;
        },
        getChildContext: function getChildContext() {
            return {
                dragDropManager: getDefaultManager()
            };
        },
        render: function render() {
            var _extends2;

            return _react2.default.createElement(DecoratedComponent, _extends({}, this.props, (_extends2 = {
                ref: 'child', 'data-codecept-selector-node': 'DecoratedComponent',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'DecoratedComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'DecoratedComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
        }
    });
};