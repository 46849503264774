'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = rubyMemoize;
/*
 * allow us to easily swap out memoize module
 * fast-memoize seems like it's worth a look
 * or why are we using memoizee when there's
 * lodash.memoize
 *
 * allow us to enforce default options
 * to avoid running into bugs like RT#41418
 *
 */
var _ = require('lodash');
var memoize = require('memoizee');

var defaultMemoizeeOptions = {
    length: false
    // safeguard against memory leaks, if user of memoizee
    // does not realize that their arguments are changing
    // when they don't intend for them to
    , max: 512,
    maxAge: 60 * 60 * 1000 //# cache for at most 1 hour
    , preFetch: true
};

//# NOTE: we shouldn't rely on rubyMemoize() because it obscures the source
//# when we want to use memoizee.profile
//# use the defaultMemoizeeOptions instead and include additional options that you need
//# and call on the pure memoizee module
function rubyMemoize(fn, options) {
    var memoizeOptions = _.assign({}, defaultMemoizeeOptions, options);
    return memoize(fn, memoizeOptions);
}

function initializeProfiler() {
    //# if app is provided, we mount a remote path on it
    var memProfile = require('memoizee/profile');

    return memProfile;
}

exports.defaultMemoizeeOptions = defaultMemoizeeOptions;
exports.initializeProfiler = initializeProfiler;