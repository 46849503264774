'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var Promise = require('bluebird');
var faker = require('@rubyapps/ruby-faker');

//# IDEA: expose something like 
//# Window.sampleDataByFieldKey = {
//      name: [...] //# possible names to use
//# }
module.exports = {
    //# override this for complex components like Repeater 
    //# or TokenTagger
    //# or special field types like url or email
    sampleValueFromFieldSpecs: function sampleValueFromFieldSpecs() {
        var selfKey = this.props.key;
        var data_type = this.props.data_type || 'string';

        if (this.props.default) {
            return this.props.default;
        }

        //# need to handle some special keys

        var isProcessed = false;
        var value = void 0;
        switch (selfKey) {
            case 'parent_id':
                isProcessed = true;
                break;
        }

        if (!value && !isProcessed) {
            switch (data_type) {
                case 'number':
                    value = faker.random.number();
                    break;
                case 'boolean':
                    value = faker.random.boolean();

                    break;
                case 'string':
                    value = faker.random.uuid();
                    break;
            }
        }

        return value;
    }
    /**
     * like formValueFromLocalState, but it recursively generates sample data based on field specs
     */
    , _promisedFormValueFromFieldSpecs: function _promisedFormValueFromFieldSpecs() {
        var _this = this;

        var selfID = this.getID();
        var selfKey = this.props.key;

        var children = this.getChildren();

        var promisedArrOfReducedChildrenState = Promise.reduce(children, function (collector, child, index) {
            var childID = child.getID();

            return (child._promisedFormValueFromFieldSpecs ? child._promisedFormValueFromFieldSpecs() : Promise.resolve(undefined)).then(function (childFormValue) {
                if (childFormValue != undefined) {
                    collector.push(childFormValue);
                }

                return collector;
            });
        }, []);

        return promisedArrOfReducedChildrenState.then(function (arrOfReducedChildrenState) {

            var reducedChildrenState = _extends.apply(undefined, [{}].concat(_toConsumableArray(arrOfReducedChildrenState)));

            var reducedChildrenAvail = Object.keys(reducedChildrenState).length > 0;

            return reducedChildrenAvail && selfKey ? Promise.props(_defineProperty({}, selfKey, reducedChildrenState)) : selfKey ? Promise.props(_defineProperty({}, selfKey, _this.sampleValueFromFieldSpecs())) : reducedChildrenAvail ? reducedChildrenState : undefined;
        });
    },
    promisedFormValueFromFieldSpecs: function promisedFormValueFromFieldSpecs() {
        return this._promisedFormValueFromFieldSpecs();
    }

};