'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var update = require('react-addons-update');

var defaultState = {
    props: {},
    savedLocalStateByNamespace: {},
    savedFormValueByNamespace: {},
    deferredFormValueByNamespace: {},
    deferredFormErrorByNamespace: {},
    deferredClearFormErrorByNamespace: {}
};

module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];

    var _getAction = this.getAction(),
        TYPES = _getAction.TYPES;

    var type = action.type,
        payload = action.payload;


    if (!state.hasOwnProperty('savedLocalStateByNamespace')) {
        state = _extends({}, defaultState, state);
    }
    switch (type) {
        case TYPES.SET_OPTIONS:
            if (!payload.options || payload.options.length == 0) {
                return state;
            }
            var updatedState = _extends({}, state, {
                props: _extends({}, state.props, {
                    options: payload.options
                })
            });
            return updatedState;
        case TYPES.CUSTOM_RESET_STATE:
            return _extends({}, state, this.getInitialState(), defaultState);

        case TYPES.SET_LOCAL_STATE_CACHE_FOR_NAMESPACE:
            return _extends({}, state, {
                savedLocalStateByNamespace: _extends({}, state.savedLocalStateByNamespace, _defineProperty({}, payload.namespace, payload.localState))
            });
        case TYPES.SAVE_LOCAL_STATE:
            return _extends({}, state, {
                savedLocalStateByNamespace: _extends({}, state.savedLocalStateByNamespace, _defineProperty({}, payload.namespace, payload.localState))
            });
        case TYPES.SAVE_FORM_VALUE:
            return _extends({}, state, {
                savedFormValueByNamespace: _extends({}, state.savedFormValueByNamespace, _defineProperty({}, payload.namespace, payload.formValue))
            });
        case TYPES.SET_LOCAL_STATE:
            return _extends({}, state, payload.localState);
        case TYPES.REPLACE_SAVED_LOCAL_STATE_BY_NAMESPACE:
            return _extends({}, state, {
                savedLocalStateByNamespace: payload.savedLocalStateByNamespace
            });
        case TYPES.REPLACE_DEFERRED_FORM_VALUE_BY_NAMESPACE:
            return _extends({}, state, {
                deferredFormValueByNamespace: payload.deferredFormValueByNamespace
            });
        case TYPES.REPLACE_DEFERRED_FORM_ERROR_BY_NAMESPACE:
            return _extends({}, state, {
                deferredFormErrorByNamespace: payload.deferredFormErrorByNamespace
            });
        case TYPES.REPLACE_DEFERRED_CLEAR_FORM_ERROR_BY_NAMESPACE:
            return _extends({}, state, {
                deferredClearFormErrorByNamespace: payload.deferredClearFormErrorByNamespace
            });

        case TYPES.CLEAR_DEFERRED_FORM_VALUE_FOR_NAMESPACE:
            return _extends({}, state, {
                deferredFormValueByNamespace: _lodash2.default.omit(state.deferredFormValueByNamespace, [payload.namespace])
            });
        case TYPES.CLEAR_DEFERRED_FORM_ERROR_FOR_NAMESPACE:
            return _extends({}, state, {
                deferredFormErrorByNamespace: _lodash2.default.omit(state.deferredFormErrorByNamespace, [payload.namespace])
            });
        case TYPES.CLEAR_DEFERRED_CLEAR_FORM_ERROR_FOR_NAMESPACE:
            return _extends({}, state, {
                deferredClearFormErrorByNamespace: _lodash2.default.omit(state.deferredClearFormErrorByNamespace, [payload.namespace])
            });
        default:
            return state;
    }
};