'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Card = require('material-ui/Card');

var _RaisedButton = require('material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _Activity_style = require('../Activity_style');

var _Activity_style2 = _interopRequireDefault(_Activity_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var TinyMCE = require('@rubyapps/react-tinymce');

var styles = process.browser ? require('../Activity.cssModule').default : {};

var EditableActivityComment = _react2.default.createClass({
    displayName: 'EditableActivityComment',

    onEdit: function onEdit(event, editor) {
        var editActivityComment = _lodash2.default.get(this.props, 'actions.editActivityComment');
        var value = editor.getContent();
        editActivityComment(this.props.id, value);
    },
    onCancel: function onCancel(event) {
        var clearEdits = _lodash2.default.get(this.props, 'actions.clearEdits');
        event.preventDefault();
        clearEdits();
    },
    onSave: function onSave(event) {
        var _props = this.props,
            id = _props.id,
            comment = _props.comment;

        var saveActivityComment = _lodash2.default.get(this.props, 'actions.saveActivityComment');
        event.preventDefault();
        saveActivityComment(id, comment);
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5;

        var _props2 = this.props,
            comment = _props2.comment,
            rteConfig = _props2.rteConfig,
            rteMode = _props2.rteMode;

        return _react2.default.createElement(
            _Card.CardText,
            (_React$createElement5 = {
                'data-codecept-selector-node': 'CardText',
                'data-codecept-selector-file': 'comment-edit'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'comment-edit'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'comment-edit'), _React$createElement5),
            _react2.default.createElement(TinyMCE, (_React$createElement = {
                content: comment,
                config: rteConfig,
                onChange: this.onEdit,
                'data-codecept-selector-node': 'TinyMCE',
                'data-codecept-selector-file': 'comment-edit'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'TinyMCE'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'comment-edit'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'TinyMCE'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'comment-edit'), _React$createElement)),
            _react2.default.createElement(
                'div',
                (_React$createElement4 = { className: styles.activityEditOptions, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'comment-edit'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'comment-edit'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'comment-edit'), _React$createElement4),
                _react2.default.createElement(_RaisedButton2.default, (_React$createElement2 = {
                    label: 'Cancel',
                    secondary: true,
                    onTouchTap: this.onCancel,
                    style: _Activity_style2.default.RaisedButton,
                    'data-codecept-selector-node': 'RaisedButton',
                    'data-codecept-selector-file': 'comment-edit'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'comment-edit'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'comment-edit'), _React$createElement2)),
                _react2.default.createElement(_RaisedButton2.default, (_React$createElement3 = {
                    label: 'Update Comment',
                    primary: true,
                    onTouchTap: this.onSave,
                    style: _Activity_style2.default.RaisedButton,
                    'data-codecept-selector-node': 'RaisedButton',
                    'data-codecept-selector-file': 'comment-edit'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'comment-edit'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'comment-edit'), _React$createElement3))
            )
        );
    }
});

exports.default = EditableActivityComment;