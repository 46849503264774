'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _AlertDialog = require('./AlertDialog');

var _AlertDialog2 = _interopRequireDefault(_AlertDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rubyLogger = require('@rubyapps/ruby-logger');
var logger = rubyLogger.getLogger('ruby-component-alert-dialog:reactComponents/AlertDialog');

var _ = require('lodash');

module.exports = function AlertDialogGenerator() {
    var selfModule = this;

    var dependencies = selfModule.getDependencies();
    var selfSelector = dependencies.selfSelector;
    var selfActions = selfModule.getAction().generators;

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);
        var mappedFields = {};

        return _extends({}, _.pick(selfState, ['open', 'loading', 'title', 'content', 'buttonLabel']), mappedFields);
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfActions, dispatch)
        };
    }

    function mergeProps(stateProps, dispatchProps, ownProps) {
        //# need to handle delegation here because it needs the bound action functions
        var modifiedStateProps = stateProps;
        return _extends({}, ownProps, modifiedStateProps, dispatchProps, {
            //# setup the delegate callbacks here whcih calls on dispatchProps.actions...
            delegateSubmit: function delegateSubmit(evt) {
                return dispatchProps.actions.submit();
            },
            delegateClose: function delegateClose(evt) {
                return dispatchProps.actions.closeAndResetDialog();
            }
        });
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(_AlertDialog2.default);
};