'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _ChipItem = require('./ChipItem');

var _ChipItem2 = _interopRequireDefault(_ChipItem);

var _TextFieldLabel = require('material-ui/TextField/TextFieldLabel');

var _TextFieldLabel2 = _interopRequireDefault(_TextFieldLabel);

var _TextFieldUnderline = require('material-ui/TextField/TextFieldUnderline');

var _TextFieldUnderline2 = _interopRequireDefault(_TextFieldUnderline);

var _index = require('./index.cssModule');

var _index2 = _interopRequireDefault(_index);

require('./material.css');

var _transitions = require('material-ui/styles/transitions');

var _transitions2 = _interopRequireDefault(_transitions);

var _colorManipulator = require('material-ui/utils/colorManipulator');

var colorManipulator = _interopRequireWildcard(_colorManipulator);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var request = require('@rubyapps/ruby-superagent');

var PropTypes = _react2.default.PropTypes;

var rubyWords = require('@rubyapps/ruby-words');

var _require = require("./Selectize"),
    SimpleSelect = _require.SimpleSelect,
    MultiSelect = _require.MultiSelect;

var SimpleSelect__propTypes = SimpleSelect.propTypes || {
    autofocus: PropTypes.bool,
    cancelKeyboardEventOnSelection: PropTypes.bool,
    createFromSearch: PropTypes.string,
    defaultValue: PropTypes.any,
    delimiters: PropTypes.array,
    disabled: PropTypes.bool,
    dropdownDirection: PropTypes.number,
    editable: PropTypes.func,
    filterOptions: PropTypes.func //# (options, search) =>
    , firstOptionIndexToHighlight: PropTypes.func,
    groupId: PropTypes.func,
    groups: PropTypes.array,
    groupsAsColumns: PropTypes.bool,
    hideResetButton: PropTypes.bool,
    highlightedUid: PropTypes.object,
    name: PropTypes.string,
    open: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onHighlightedUidChange: PropTypes.func,
    onKeyboardSelectionFailed: PropTypes.func,
    onOpenChange: PropTypes.func,
    onPaste: PropTypes.func,
    onSearchChange: PropTypes.func,
    onValueChange: PropTypes.func,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    renderGroupTitle: PropTypes.func,
    renderNoResultsFound: PropTypes.func,
    renderOption: PropTypes.func,
    renderResetButton: PropTypes.func,
    renderToggleButton: PropTypes.func,
    renderValue: PropTypes.func,
    restoreOnBackspace: PropTypes.func,
    search: PropTypes.string,
    serialize: PropTypes.func,
    style: PropTypes.object,
    tether: PropTypes.bool,
    'tether-props': PropTypes.object,
    theme: PropTypes.string,
    transitionEnter: PropTypes.bool,
    transitionEnterTimeout: PropTypes.number,
    transitionLeave: PropTypes.bool,
    transitionLeaveTimeout: PropTypes.number,
    uid: PropTypes.func,
    value: PropTypes.any,
    valueFromPaste: PropTypes.func //# (options, value, pastedText) => 
};
var SimpleSelect__propTypeKeys = Object.keys(SimpleSelect__propTypes);

var MultiSelect__propTypes = MultiSelect.propTypes || _extends({}, SimpleSelect__propTypes, {
    anchor: PropTypes.any,
    createFromSearch: PropTypes.func,
    defaultValues: PropTypes.array,
    filterOptions: PropTypes.func //# NOTE: function signature is different (options, values, search) =>
    , onAnchorChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onValuesChange: PropTypes.func,
    maxValues: PropTypes.number,
    closeOnSelect: PropTypes.bool,
    valuesFromPaste: PropTypes.func //# (options, values, pastedText) => 

});
var MultiSelect__propTypeKeys = Object.keys(MultiSelect__propTypes);

var getStyles = function getStyles(props, context, state) {
    var _context$muiTheme$tex = context.muiTheme.textField,
        floatingLabelColor = _context$muiTheme$tex.floatingLabelColor,
        focusColor = _context$muiTheme$tex.focusColor,
        disabledTextColor = _context$muiTheme$tex.disabledTextColor,
        hintColor = _context$muiTheme$tex.hintColor,
        errorColor = _context$muiTheme$tex.errorColor;


    var styles = {
        error: {
            position: 'relative',
            bottom: -6,
            fontSize: 12,
            lineHeight: '12px',
            color: errorColor,
            transition: _transitions2.default.easeOut()
        },
        floatingLabel: {
            color: hintColor,
            pointerEvents: 'none',
            top: 5
        }
    };

    _extends(styles.error, props.errorStyle || {});

    if (state.hasValue) {
        styles.floatingLabel.color = colorManipulator.fade(props.disabled ? disabledTextColor : floatingLabelColor, 0.5);
    }

    if (state.isFocused) {
        styles.floatingLabel.color = focusColor;
    }

    return styles;
};
//# TODO:
// custom label / placeholder to keep the label persistent? it'll be an issue since we can't override this
//      meaning the placeholder will be hidden after the change
// - Custom filtering and rendering example for handling tags
// - Handle Error message separately
//# NOTE: the API is forced to be the same as AutoCompleteSelector
//# so we hadnle some mappigns like DataSource => options
//# and onUpdateInput => onSearchChange
//
//# custom filterOptions function to set `selectable` on option based on selected values
var Selectize = _react2.default.createClass({
    displayName: 'Selectize',

    propTypes: _extends({
        // chosenRequest: PropTypes.object //# the actual chosenRequest
        //# TODO: REMOVE DEPRECATED
        count: PropTypes.number //# the count param for url, needed to limit the number of results
        , disabed: PropTypes.bool,
        errorText: PropTypes.string,
        getPrimaryText: PropTypes.func,
        onOptionSelected: PropTypes.func,
        onOptionCleared: PropTypes.func,
        requestToInvalidateCacheTimestamp: PropTypes.string,
        shouldClearUnfoundedSearch_onMenuClose: PropTypes.bool,
        url: PropTypes.string,

        limit: PropTypes.number //# can be null. If it's null, it's assumed to be Infinity
        , floatingLabelStyle: PropTypes.object,
        floatingLabelFocusStyle: PropTypes.object,
        underlineDisabledStyle: PropTypes.object,
        underlineFocusStyle: PropTypes.object,
        underlineStyle: PropTypes.object,
        valueStyleObject: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        //# for overriding the renderValue style
        //# If it's a function, then we call on it with the this context
        //, isMulti: PropTypes.bool //# TODO: review/
        /*
            dataSource: PropTypes.arrayOf(PropTypes.shape({
                text: PropTypes.string
                , value: PropTypes.string
            })
         */
        //# TODO:
        //# renderOptions to support icons ?
        //# 
    }, SimpleSelect__propTypes, MultiSelect__propTypes),
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            count: 50,
            dataSource: [],
            dataSourceConfig: {
                text: 'text',
                value: 'value'
            },
            getPrimaryText: function getPrimaryText(opt) {
                var _React$createElement;

                return _react2.default.createElement(
                    'span',
                    (_React$createElement = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'index'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement),
                    opt.text
                );
            },
            limit: 1 //# TODO: probably want to default to "multiselect" but set to 1 if we want dropdown
            , requestToInvalidateCacheTimestamp: new Date().toString(),
            shouldClearUnfoundedSearch_onMenuClose: true,
            filterOptions: _lodash2.default.identity,
            renderNoResultsFound: function renderNoResultsFound() {
                var _React$createElement2;

                return _react2.default.createElement(_MenuItem2.default, (_React$createElement2 = {
                    key: 'noResults',
                    disabled: true,
                    primaryText: 'No Results Found',
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'index'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'index'), _React$createElement2));
            },
            underlineFocusStyle: {
                left: 0,
                bottom: 0
            },
            underlineStyle: {
                left: 0,
                bottom: 0
            }
        };
    },
    getInitialState: function getInitialState() {
        return {
            searchText: '',
            value: null,
            dataSource: [],
            cumulativeDataSource: [],
            isFocused: false
        };
    }

    //== START: HANDLERS ===================================//

    //# TODO:
    //# NOTE: onUpdateInput == onSearchChange
    , _onUpdateInput: function _onUpdateInput(searchText) {
        var newState = {
            searchText: searchText
        };

        this.setState(newState);
        if (this.props.url) {
            this._queryForDataSource(searchText);
        }

        this.props.onUpdateInput && this.props.onUpdateInput.apply(this, arguments);
        //this.props.onOptionCleared && this.props.onOptionCleared();
    }

    //# TODO: UPDATE, => onValuesChange(selectedValues)
    , _onValuesChange: function _onValuesChange(selectedOptions) {
        var normalizedSelectedOptions = selectedOptions ? _lodash2.default.castArray(selectedOptions) : [];

        //# NOTE: THIS IS NOT VALUE in the literal sense. it's actually the option

        var valueKey = this.props.dataSourceConfig.value || 'value';

        var selectedValues = normalizedSelectedOptions.length ? normalizedSelectedOptions.map(function (option) {
            return option[valueKey];
        }) : null;

        this.setState({
            value: selectedValues
        });

        if (selectedValues) {
            this.props.onOptionSelected && this.props.onOptionSelected(normalizedSelectedOptions);
        } else {
            this.props.onOptionCleared && this.props.onOptionCleared();
        }
    },
    _onRequestDeleteSelectedOption: function _onRequestDeleteSelectedOption(selectedOption) {

        var cumulativeDataSource = this._completeCumulativeDataSource();
        var hydratedValues = this._optionsForValues(cumulativeDataSource, this.state.value || this.props.value);

        var valueKey = this.props.dataSourceConfig.value || 'value';

        this._onValuesChange(hydratedValues.filter(function (option) {
            return option[valueKey] != selectedOption[valueKey];
        }));
    }

    //# TODO: probably don't need if we go with multiselect all the time
    , _onFocus: function _onFocus(event) {
        //# select all text
        var searchTextField__input = _lodash2.default.get(this, ['_autocompleteComponent', 'refs', 'searchTextField', 'input'], event.target);

        searchTextField__input && searchTextField__input.setSelectionRange && searchTextField__input.setSelectionRange(0, searchTextField__input.value.length);
    }

    //== END: HANDLERS =====================================//

    , componentWillMount: function componentWillMount() {
        var shouldUpdateState = false;
        var newStateProps = {};

        if (this.props.searchText) {
            newStateProps.searchText = this.props.searchText;
            shouldUpdateState = true;
        }

        /* //# TODO: REMOVE DEPRECATED
        if (this.props.chosenRequest) {
            newStateProps.chosenRequest = this.props.chosenRequest;
            const searchText = this._chosenRequestText(this.props.chosenRequest);
            newStateProps.searchText = searchText;
            shouldUpdateState = true;
        }
        */

        if (shouldUpdateState) {
            this.setState(newStateProps);
        }
    },
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        var shouldUpdateState = false;
        var newStateProps = {};

        if (this.props.searchText !== nextProps.searchText) {
            newStateProps.searchText = nextProps.searchText;
            shouldUpdateState = true;
        }

        //# track value internally in case this component isn't backed by a redux store
        if (this.props.value !== nextProps.value) {
            newStateProps.value = nextProps.value;
            shouldUpdateState = true;
        }

        var nextProps__requestToInvalidateCacheTimestamp = nextProps.requestToInvalidateCacheTimestamp;
        var props__requestToInvalidateCacheTimestamp = this.props.requestToInvalidateCacheTimestamp;

        if (nextProps__requestToInvalidateCacheTimestamp && nextProps__requestToInvalidateCacheTimestamp > props__requestToInvalidateCacheTimestamp) {
            //# trigger a request
            this._queryForDataSource(newStateProps.searchText ? newStateProps.searchText : this.state.searchText);
        }

        if (shouldUpdateState) {
            this.setState(newStateProps);
        }
    },

    isMultiSelect: function isMultiSelect() {
        return !(this.props.limit == 1);
    },
    renderSimpleSelectValue: function renderSimpleSelectValue(item) {
        var _React$createElement3, _extends2;

        var valueStyleObject = this.props.valueStyleObject;


        var resolvedValueStyleObject = _lodash2.default.isFunction(valueStyleObject) ? valueStyleObject.apply(this, item) : valueStyleObject;

        return _react2.default.createElement(
            'div',
            _extends({
                className: 'simple-value'
            }, resolvedValueStyleObject || {}, (_extends2 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)),
            item.icon,
            ' ',
            _react2.default.createElement(
                'span',
                (_React$createElement3 = {
                    'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'index'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'index'), _React$createElement3),
                item.label
            )
        );
    },
    renderMultiSelectValue: function renderMultiSelectValue(item) {
        var _this = this,
            _extends3;

        var valueKey = this.props.dataSourceConfig.value || 'value';

        var valueStyleObject = this.props.valueStyleObject;


        var resolvedValueStyleObject = _lodash2.default.isFunction(valueStyleObject) ? valueStyleObject.apply(this, item) : valueStyleObject;

        //# chip
        return _react2.default.createElement(_ChipItem2.default, _extends({
            onRequestDelete: function onRequestDelete(event) {
                _this._onRequestDeleteSelectedOption(item);

                event.preventDefault();
                event.stopPropagation();
                return false;
            }
        }, resolvedValueStyleObject || {}, {
            id: item[valueKey]
        }, item, (_extends3 = {
            'data-codecept-selector-node': 'ChipItem',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends3, 'data-codecept-selector-node', 'ChipItem'), _defineProperty(_extends3, 'data-codecept-selector-file', 'index'), _defineProperty(_extends3, 'data-codecept-selector-node', 'ChipItem'), _defineProperty(_extends3, 'data-codecept-selector-file', 'index'), _extends3)));
    },
    renderOption: function renderOption(option) {
        var _React$createElement4, _extends4;

        /*
        const selectizeDom = this._selectizeRef ? ReactDOM.findDOMNode(this._selectizeRef) :null;
         const maxWidth = selectizeDom && selectizeDom.offsetWidth;
                style={{
                    maxWidth
                 }}
        */ //# NOTE: we can set the maxWidth like so but then we have to figure out how to
        //# support showing a larger width for menu items whose dropdown width are very narrow
        //# since we don't want the popover to also be that narrow

        var secondaryText = !option.breadcrumb ? {} : {
            secondaryText: _react2.default.createElement(
                'span',
                (_React$createElement4 = { className: _index2.default.MenuItem__SecondaryText, 'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'index'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'index'), _React$createElement4),
                option.breadcrumb
            )
        };

        return _react2.default.createElement(_MenuItem2.default, _extends({
            key: option.value,
            value: option.value,
            checked: option.hasOwnProperty('selectable') && !option.selectable,
            insetChildren: true,
            rightIcon: option.icon,
            primaryText: this.props.getPrimaryText(option) //#wrap in span in order to force display: inline
            , innerDivStyle: {
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        }, secondaryText, (_extends4 = {
            'data-codecept-selector-node': 'MenuItem',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends4, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends4, 'data-codecept-selector-file', 'index'), _defineProperty(_extends4, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends4, 'data-codecept-selector-file', 'index'), _extends4)));
    },
    filterOptions: function filterOptions(options, values) {
        if (arguments.length == 2) {
            values = this.props.value;
        }

        var valueKey = this.props.dataSourceConfig.value || 'value';

        var valuesByKey = _lodash2.default.keyBy(values, valueKey);

        var filteredOptions = options.map(function (option) {
            return _extends({
                selectable: !valuesByKey.hasOwnProperty(option[valueKey])
            }, option);
        });

        return filteredOptions;
    },
    render: function render() {
        var _React$createElement5,
            _React$createElement6,
            _React$createElement7,
            _this2 = this;

        var cumulativeDataSource = this._completeCumulativeDataSource();
        var hydratedValues = this._optionsForValues(cumulativeDataSource, this.state.value || this.props.value);

        var _props$filterOptions = this.props.filterOptions,
            _filterOptions = _props$filterOptions === undefined ? _lodash2.default.identity : _props$filterOptions;

        var state = _extends({
            hasValue: hydratedValues && !!hydratedValues.length
        }, this.props, this.state);

        var prepareStyles = this.context.muiTheme.prepareStyles;
        var materialStyles = getStyles(this.props, this.context, state);

        var placeholder = this.props.placeholder;

        var wrappedPlaceholder = _react2.default.createElement(
            _TextFieldLabel2.default,
            (_React$createElement5 = {
                muiTheme: this.context.muiTheme,
                shrink: state.hasValue || state.isFocused,
                htmlFor: this.props.key,
                disabled: this.props.disabled,
                style: _extends({}, materialStyles.floatingLabel, this.props.floatingLabelStyle || {}),
                shrinkStyle: this.props.floatingLabelFocusStyle,
                'data-codecept-selector-node': 'TextFieldLabel',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'TextFieldLabel'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'TextFieldLabel'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'index'), _React$createElement5),
            placeholder
        );

        var TextFieldUnderlineElement = _react2.default.createElement(_TextFieldUnderline2.default, (_React$createElement6 = {
            disabled: this.props.disabled,
            focus: this.state.isFocused,
            muiTheme: this.context.muiTheme,
            disabledStyle: this.props.underlineDisabledStyle,
            focusStyle: this.props.underlineFocusStyle,
            error: !!this.props.errorText,
            errorStyle: this.props.errorStyle,
            style: this.props.underlineStyle,
            'data-codecept-selector-node': 'TextFieldUnderline',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'TextFieldUnderline'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'TextFieldUnderline'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'index'), _React$createElement6));
        var errorTextElement = this.props.errorText && _react2.default.createElement(
            'div',
            (_React$createElement7 = {
                style: prepareStyles(materialStyles.error),
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'index'), _React$createElement7),
            this.props.errorText
        );

        var props = {
            options: this._completeDataSourceForDisplay(),
            theme: "material",
            transitionEnter: true,
            onSearchChange: this._onUpdateInput,
            uid: function uid(item) {
                return rubyWords.slug(item.text + '-' + item.value);
            },
            search: this.state.searchText,
            onFocus: this.onFocus,
            onBlur: this.onBlur,
            inputProps: {
                TextFieldUnderlineElement: TextFieldUnderlineElement,
                errorTextElement: errorTextElement
            }
        };

        if (this.props.limit == 1) {
            var _extends5;

            var SimpleSelect__props = _lodash2.default.pick(this.props, SimpleSelect__propTypeKeys);
            var myValue = _lodash2.default.isArray(hydratedValues) ? _lodash2.default.first(hydratedValues) : hydratedValues;

            return _react2.default.createElement(SimpleSelect, _extends({
                onValueChange: this._onValuesChange,
                renderValue: this.renderSimpleSelectValue,
                hideResetButton: true,
                transitionEnter: false,
                transitionLeave: false,
                className: _index2.default.SimpleSelect,
                ref: function ref(selectRef) {
                    _this2._selectizeRef = selectRef;
                },
                renderOption: this.renderOption
            }, props, SimpleSelect__props, (_extends5 = {
                value: myValue,
                placeholder: wrappedPlaceholder,
                filterOptions: function filterOptions(options, search) {
                    return _filterOptions(_this2.filterOptions(options, hydratedValues, search), search);
                },
                'data-codecept-selector-node': 'SimpleSelect',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends5, 'data-codecept-selector-node', 'SimpleSelect'), _defineProperty(_extends5, 'data-codecept-selector-file', 'index'), _defineProperty(_extends5, 'data-codecept-selector-node', 'SimpleSelect'), _defineProperty(_extends5, 'data-codecept-selector-file', 'index'), _extends5)));
        } else {
            var _extends6;

            var MultiSelect__props = _lodash2.default.pick(this.props, MultiSelect__propTypeKeys);

            return _react2.default.createElement(MultiSelect, _extends({
                maxValues: this.props.limit || Infinity,
                onValuesChange: this._onValuesChange,
                renderValue: this.renderMultiSelectValue,
                transitionEnter: false,
                transitionLeave: false,
                className: _index2.default.MultiSelect,
                ref: function ref(selectRef) {
                    _this2._selectizeRef = selectRef;
                },
                renderOption: this.renderOption
            }, props, MultiSelect__props, (_extends6 = {
                values: hydratedValues,
                placeholder: wrappedPlaceholder,
                filterOptions: function filterOptions(options, values, search) {
                    return _filterOptions(_this2.filterOptions(options, values, search), values, search);
                },
                'data-codecept-selector-node': 'MultiSelect',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends6, 'data-codecept-selector-node', 'MultiSelect'), _defineProperty(_extends6, 'data-codecept-selector-file', 'index'), _defineProperty(_extends6, 'data-codecept-selector-node', 'MultiSelect'), _defineProperty(_extends6, 'data-codecept-selector-file', 'index'), _extends6)));
        }
    },

    componentDidMount: function componentDidMount() {
        //# lets retrieve the initial batch of values for dataSource
        if (this.props.url) {
            this._queryForDataSource(this.state.searchText);
        }
    },
    onFocus: function onFocus() {
        this.setState({
            isFocused: true
        });
    },
    onBlur: function onBlur() {
        this.setState({
            isFocused: false
        });
    }
    //== START: UTILITY =====================================//
    , _completeDataSourceForDisplay: function _completeDataSourceForDisplay() {
        //const isMultiSelect = this.isMultiSelect();

        var completeDataSource = this.state.dataSource.concat(this.props.dataSource); /*.map(option => {
                                                                                      const isSelected = myValues.indexOf(option.value) > -1;
                                                                                      return Object.assign({isSelected}, option);
                                                                                      });*/

        return completeDataSource;
    },
    _completeCumulativeDataSource: function _completeCumulativeDataSource() {
        var completeCumulativeDataSource = this.state.cumulativeDataSource.concat(this.props.dataSource);
        return completeCumulativeDataSource;
    },
    _optionsForValues: function _optionsForValues(options, values) {
        var _this3 = this;

        var normalizedValues = values ? _lodash2.default.castArray(values) : values;

        var valueKey = this.props.dataSourceConfig.value || 'value';
        var textKey = this.props.dataSourceConfig.text || 'text';

        var selectedOptions = normalizedValues ? normalizedValues.map(function (value) {
            var foundOption = _lodash2.default.find(options, [valueKey, value]);
            if (!foundOption) {
                var _ref;

                console.warn('Could not find option for value: [' + value + ']. It might be because it\'s relying on the remote source. Going to try to fill in the missing information');
                return _ref = {}, _defineProperty(_ref, textKey, value), _defineProperty(_ref, valueKey, value), _defineProperty(_ref, 'label', value), _defineProperty(_ref, 'isSelected', true), _defineProperty(_ref, 'selectable', false), _ref;
            } else {
                return _extends({ label: _this3.props.getPrimaryText(foundOption), isSelected: true, selectable: false }, foundOption);
                //# NOTE: we polyfill label just so we don't need to handle the default renderValue
            }
        }) : []; //# normalized to array

        return selectedOptions;
    },
    _queryForDataSource: function _queryForDataSource(query) {
        var _this4 = this;

        if (this._req) {
            this._req.abort();
        }

        this._req = request.get(this.props.url).query({
            search_input: query,
            count: this.props.count
        });

        return this._req.then(function (response) {
            var data = _lodash2.default.isArray(response.body) ? response.body : response.body.data;

            _this4.setState({
                dataSource: data,
                cumulativeDataSource: _lodash2.default.uniqBy(_this4.state.cumulativeDataSource.concat(data), _this4.props.dataSourceConfig.value || 'value')
            });
        });
    }
});

exports.default = Selectize;