'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = contentListConnectorGenerator;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _redux = require('redux');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _path = require('path');

var _path2 = _interopRequireDefault(_path);

var _listerNavOptions = require('../listerNavOptions');

var _listerNavOptions2 = _interopRequireDefault(_listerNavOptions);

var _Toolbar = require('material-ui/Toolbar');

var _Subheader = require('material-ui/Subheader');

var _Subheader2 = _interopRequireDefault(_Subheader);

var _toolbar = require('@rubyapps/ruby-styles/src/client/cssModules/toolbar.cssModule');

var _toolbar2 = _interopRequireDefault(_toolbar);

var _header = require('@rubyapps/ruby-component-mixin-list-page/src/client/reactComponents/header');

var _header2 = _interopRequireDefault(_header);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

//# Material Components


//# styles == Note: add local styles file when we need to define custom styles


var rubyWords = require('@rubyapps/ruby-words');
var rubyAccessManager = require('@rubyapps/ruby-access-manager');

var ListPage = require('@rubyapps/ruby-component-list-page');

var GriddleModule = require('@rubyapps/griddle');
var Griddle = GriddleModule.Griddle,
    GriddleRender = GriddleModule.GriddleRender;
function contentListConnectorGenerator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector,
        selfActions = _selfModule$getDepend.selfActions,
        addToProposalDialogActions = _selfModule$getDepend.addToProposalDialogActions,
        alterTemplateDialogActions = _selfModule$getDepend.alterTemplateDialogActions,
        confirmDialogActions = _selfModule$getDepend.confirmDialogActions,
        currentUserSelector = _selfModule$getDepend.currentUserSelector,
        formDialogComponent = _selfModule$getDepend.formDialogComponent,
        formsSelector = _selfModule$getDepend.formsSelector,
        getAddEntryUrl = _selfModule$getDepend.getAddEntryUrl,
        getContentListUrl_forTemplate = _selfModule$getDepend.getContentListUrl_forTemplate;

    var listPage = ListPage.getReactClass.call(selfModule, { columnDefinitionsGenerator: selfModule.columnDefinitionsGenerator.bind(selfModule) });

    var disallowAlterTemplate = this.props.disallow_alter_template;
    var excludeFromFilter = this.props.exclude_from_filter;

    function getLister_fromTemplateKey(templateKey) {
        return selfModule.getListerConfig(templateKey);
    }

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);
        var griddleState = selfState.griddleState;

        var currentUserState = currentUserSelector(state);
        var template_key = selfModule.getTemplateKey();
        var allForms = formsSelector(state);
        var templateType = _lodash2.default.get(allForms, [template_key, 'templateType']);
        var forms = _lodash2.default.pickBy(allForms, function (form) {
            return _lodash2.default.get(form, 'templateType') === templateType && selfModule.doesUserHavePermission({
                template: _lodash2.default.get(form, 'id'),
                subsite: true,
                ruby_client: true,
                model: 'content',
                action: 'get'
            });
        });

        var listerConfig = getLister_fromTemplateKey(template_key);
        var hierarchical = listerConfig.hierarchical,
            _listerConfig$filters = listerConfig.filters,
            filters = _listerConfig$filters === undefined ? [] : _listerConfig$filters;

        var listPageKey = 'sd-list-' + template_key;
        var apiUrlPrefix = _lodash2.default.result(selfModule, 'getFrontendSettings.restApiRoot');
        var currentUsername = _lodash2.default.get(currentUserState, 'username');
        var showPublishColumn = selfModule.props.show_publish_column || listerConfig.showPublishColumn;

        var hasAddEntryPermission = rubyAccessManager.doesUserHavePermission(currentUserState, {
            action: 'add',
            model: 'content',
            ruby_client: selfModule.getActiveRubyClientId(),
            subsite: selfModule.getActiveSubsiteId(),
            template: _lodash2.default.get(forms, [template_key, 'id'])
        });
        var isUniqueTemplate = _lodash2.default.result(allForms, [template_key, 'unique_template'], false);

        var listerNavOptions = (0, _listerNavOptions2.default)(forms, template_key, getContentListUrl_forTemplate, excludeFromFilter);
        var listerNav_options = listerNavOptions.options;
        var listerNav_defaultOption = listerNavOptions.defaultOption;

        var listApiEndpoint = hierarchical ? _path2.default.join(apiUrlPrefix, 'content', 'sitemap-data') : template_key && _path2.default.join(apiUrlPrefix, 'content', template_key, 'lister-data');

        var columns = selfModule.utils.getColumnsFromGriddleState_defaultingToColumns(griddleState, listerConfig.columns);

        var _selfModule$getActive = selfModule.getActiveRubyClientInfo(),
            rubyClientId = _selfModule$getActive.client,
            subsiteId = _selfModule$getActive.subsite;

        // If we don't have the logged in user, then we don't have the client id
        // If we don't have the client id, then we won't be able to fetch results


        var renderLister = rubyClientId != null && template_key && Array.isArray(listerNav_options) && listerNav_options.length > 0;

        var customFilters = filters.map(function (filter, idx) {
            return _extends({}, filter, {
                name: _lodash2.default.sortBy(_lodash2.default.castArray(filter.key), _lodash2.default.identity).join('-')
            });
        });

        return _extends({
            hasAddEntryPermission: hasAddEntryPermission,
            isUniqueTemplate: isUniqueTemplate,
            apiUrlPrefix: apiUrlPrefix,
            template_key: template_key,
            hierarchical: hierarchical,
            columns: columns,
            listPageKey: listPageKey,
            show_publish_column: showPublishColumn,
            disallow_alter_template: disallowAlterTemplate,
            selfState: selfState,
            currentUserState: currentUserState,
            listerNav_defaultOption: listerNav_defaultOption,
            listerNav_options: listerNav_options,
            renderLister: renderLister,
            allForms: allForms,
            listApiEndpoint: listApiEndpoint,
            customFilters: customFilters
        }, ownProps //# ovverride any of the above
        , _lodash2.default.pick(selfModule.props, ['addEntryHash']));
    }

    function mapDispatchToProps(dispatch, ownProps) {
        var boundActionCreators = (0, _redux.bindActionCreators)(selfActions, dispatch);
        return {
            actions: boundActionCreators,
            delegateActions: {
                addToProposalDialog: addToProposalDialogActions ? (0, _redux.bindActionCreators)(addToProposalDialogActions, dispatch) : undefined,
                alterTemplateDialog: alterTemplateDialogActions ? (0, _redux.bindActionCreators)(alterTemplateDialogActions, dispatch) : undefined,
                confirmDialog: (0, _redux.bindActionCreators)(confirmDialogActions, dispatch),
                formDialog: formDialogComponent ? (0, _redux.bindActionCreators)(formDialogComponent.getAction().generators, dispatch) : null
                //# No longer need to define reflectGriddleState here
                //# we want to rely on 'ruby-react-components/src/client/view/Lister.js'
                //, reflectGriddleState: (griddleState, prevGriddleState={}) => {}
            } };
    }

    function mergeProps(stateProps, dispatchProps, ownProps) {
        var listerNav_defaultOption = stateProps.listerNav_defaultOption,
            allForms = stateProps.allForms,
            listerNav_options = stateProps.listerNav_options,
            hasAddEntryPermission = stateProps.hasAddEntryPermission,
            isUniqueTemplate = stateProps.isUniqueTemplate,
            hierarchical = stateProps.hierarchical,
            mostRecentlyModifiedItem = stateProps.selfState.mostRecentlyModifiedItem;


        var mergedStyles = _extends({}, _toolbar2.default, selfModule.props.styles || {});

        var addEntryUrl = getAddEntryUrl();

        var griddlePlugins = selfModule.getComposedGriddlePlugins_duringMergeProps.apply(selfModule, arguments);

        var templateName = _lodash2.default.get(allForms, [stateProps.template_key, 'name']);
        var listerHeaderText = templateName ? rubyWords.inflection.pluralize(templateName) : stateProps.template_key && rubyWords.capitalizeWords(rubyWords.inflection.transform(stateProps.template_key, ['humanize', 'pluralize']));

        return _extends({}, ownProps, stateProps, dispatchProps, {
            plugins: griddlePlugins,
            addEntryUrl: addEntryUrl,
            headerLabelElement: function headerLabelElement(griddleProps) {
                var _React$createElement, _React$createElement2, _React$createElement3;

                return _react2.default.createElement(
                    _Toolbar.ToolbarGroup,
                    (_React$createElement3 = { className: mergedStyles.ToolbarGroup_first_baseline_rightMargin, 'data-codecept-selector-node': 'ToolbarGroup',
                        'data-codecept-selector-file': 'ContentListConnector'
                    }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ContentListConnector'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ContentListConnector'), _React$createElement3),
                    _react2.default.createElement(_Toolbar.ToolbarTitle, (_React$createElement = { text: listerHeaderText, className: mergedStyles.ToolbarTitle_mainLabel, 'data-codecept-selector-node': 'ToolbarTitle',
                        'data-codecept-selector-file': 'ContentListConnector'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ToolbarTitle'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ContentListConnector'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ToolbarTitle'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ContentListConnector'), _React$createElement)),
                    _react2.default.createElement(
                        _Subheader2.default,
                        (_React$createElement2 = { className: mergedStyles.Subheader, 'data-codecept-selector-node': 'Subheader',
                            'data-codecept-selector-file': 'ContentListConnector'
                        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Subheader'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ContentListConnector'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Subheader'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ContentListConnector'), _React$createElement2),
                        selfModule.utils.constructChangelogMessage(mostRecentlyModifiedItem)
                    ),
                    additionalHeaderToolbarItems(griddleProps)
                );
            },
            components: {
                Header: _header2.default
            }
        });

        function additionalHeaderToolbarItems(griddleProps) {
            var _React$createElement4;

            if (selfModule.props.searchMode != 'advanced') {
                return null;
            }

            return _react2.default.createElement(
                'div',
                (_React$createElement4 = { style: {
                        display: 'flex',
                        alignItems: 'center'
                    }, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'ContentListConnector'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ContentListConnector'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ContentListConnector'), _React$createElement4),
                selfModule.additionalHeaderToolbarItems(griddleProps, selfModule._cachedPropsForConnector /*raw getRubyComponentProps data*/)
            );
        }
    }

    var connectedListPage = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(listPage);
    //# some custom PropTypes;
    _extends(connectedListPage.propTypes, {
        modeActionSpec: PropTypes.shape({
            label: PropTypes.string,
            handler: PropTypes.func
        })
    });
    return connectedListPage;
}