'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPageData = getPageData;
exports.getPage = getPage;
exports.getDataSet = getDataSet;
exports.getVisibleData = getVisibleData;
exports.getDataSetSize = getDataSetSize;
exports.filterData = filterData;
exports.getSortedData = getSortedData;
exports.sortDataByColumns = sortDataByColumns;

var _griddleCore = require('../../griddle-core');

// Override of implementation from 'local-helpers'
function getPageData(state, pageSize, currentPage) {
  var remoteDataIndex = state.get('pagesLoaded').indexOf(currentPage);
  return helpers.getDataSet(state).skip(pageSize * remoteDataIndex).take(pageSize);
}

// Override of implementation from 'local-helpers'
function getPage(state, pageNumber) {
  return state.setIn(['pageProperties', 'currentPage'], pageNumber);
}

// Override of implementation from 'local-helpers'
function getDataSet(state) {
  return state.get('data');
}

// Override of implementation from 'local-helpers'
function getVisibleData(state) {
  //get the max page / current page and the current page of data
  var pageSize = state.getIn(['pageProperties', 'pageSize']);
  var currentPage = state.getIn(['pageProperties', 'currentPage']);
  var remoteDataIndex = state.get('pagesLoaded').indexOf(currentPage);
  var _GriddleHelpers$data = _griddleCore.GriddleHelpers.data,
      getDataColumns = _GriddleHelpers$data.getDataColumns,
      getVisibleDataColumns = _GriddleHelpers$data.getVisibleDataColumns,
      getSortedColumns = _GriddleHelpers$data.getSortedColumns;


  var data = getDataSet(state).skip(pageSize * remoteDataIndex).take(pageSize);
  var columns = getDataColumns(state, data);
  return getVisibleDataColumns(data, columns);
}

// Override of implementation from 'local-helpers'
function getDataSetSize(state) {
  return state.get('totalItemCount');
}

// Override of implementation from 'local-helpers'
function filterData(data, filter) {
  // Simply return the data.
  return data;
}

// Override of implementation from 'local-helpers'
function getSortedData(data, columns) {
  var sortAscending = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

  // Simply return the data.
  return data;
}

// Override of implementation from 'local-helpers'
function sortDataByColumns(state) {
  // Simply return the data.
  return state;
}