'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var ReactDOM = _interopRequireWildcard(_reactDom);

var _Chip = require('@rubyapps/ruby-material-ui/Chip');

var _Chip2 = _interopRequireDefault(_Chip);

var _Avatar = require('material-ui/Avatar');

var _Avatar2 = _interopRequireDefault(_Avatar);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _dragHandle = require('material-ui/svg-icons/editor/drag-handle');

var _dragHandle2 = _interopRequireDefault(_dragHandle);

var _reactGridLayout = require('@rubyapps/react-grid-layout');

var _reactGridLayout2 = _interopRequireDefault(_reactGridLayout);

require('@rubyapps/react-grid-layout/css/styles.css');

require('react-resizable/css/styles.css');

var _ArrayList_style = require('./ArrayList_style');

var _ArrayList_style2 = _interopRequireDefault(_ArrayList_style);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PropTypes = _react2.default.PropTypes;

//# Material UI


//# React Grid Layout

var ReactGridLayout = (0, _reactGridLayout.WidthProvider)(_reactGridLayout2.default);

//# Styles
var fieldBaseStyles = process.browser ? require('@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule').default : {};
var arrayListStyles = process.browser ? require('./ArrayList.cssModule').default : {};
var styles = _extends({}, fieldBaseStyles, arrayListStyles);


var RubyCodeUtils = require('@rubyapps/ruby-code-utils');

var DEFAULT_BOX_SIZE = {
    height: 32
};

var ChipItem = function (_Component) {
    _inherits(ChipItem, _Component);

    function ChipItem() {
        _classCallCheck(this, ChipItem);

        return _possibleConstructorReturn(this, (ChipItem.__proto__ || Object.getPrototypeOf(ChipItem)).apply(this, arguments));
    }

    _createClass(ChipItem, [{
        key: 'render',
        value: function render() {
            var _React$createElement, _extends3, _extends4;

            var muiTheme = this.context.muiTheme;
            var primary2Color = muiTheme.baseTheme.palette.primary2Color;

            var _props = this.props,
                _props$actionButtons = _props.actionButtons,
                actionButtons = _props$actionButtons === undefined ? [] : _props$actionButtons,
                draggable = _props.draggable,
                value = _props.value,
                rest = _objectWithoutProperties(_props, ['actionButtons', 'draggable', 'value']);

            var additionalButtons = actionButtons.map(function (actionButton) {
                var _extends2;

                var icon = actionButton.icon,
                    label = actionButton.label,
                    action = actionButton.action;


                return _react2.default.createElement(
                    _IconButton2.default,
                    _extends({}, _ArrayList_style2.default.IconButton, (_extends2 = {
                        tooltip: label,
                        onTouchTap: function onTouchTap(event) {
                            action && action(value);
                        },
                        'data-codecept-selector-node': 'IconButton',
                        'data-codecept-selector-file': 'ArrayList'
                    }, _defineProperty(_extends2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ArrayList'), _defineProperty(_extends2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ArrayList'), _extends2)),
                    icon
                );
            });

            var DragAvatar = !draggable ? null : _react2.default.createElement(_Avatar2.default, _extends({}, _ArrayList_style2.default.Avatar, (_extends3 = {
                icon: _react2.default.createElement(_dragHandle2.default, (_React$createElement = { className: styles.DragHandleIcon, 'data-codecept-selector-node': 'DragIcon',
                    'data-codecept-selector-file': 'ArrayList'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'DragIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ArrayList'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'DragIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ArrayList'), _React$createElement)),
                onClick: function onClick(event) {
                    event.stopPropagation();return false;
                },
                'data-codecept-selector-node': 'Avatar',
                'data-codecept-selector-file': 'ArrayList'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ArrayList'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ArrayList'), _extends3)));

            var Content = _react2.default.createElement(
                _Chip2.default,
                _extends({
                    additionalButtons: additionalButtons,
                    backgroundColor: 'rgb(232, 232, 232)',
                    allowEventPropagation: true,
                    className: styles.chip
                }, rest, (_extends4 = {
                    labelStyle: {
                        width: '100%'
                    },
                    style: {
                        width: '100%',
                        boxShadow: '0 1px 1px 1px rgba(0,0,0,0.2)'
                    },
                    ref: this.props.chipRef,
                    'data-codecept-selector-node': 'Chip',
                    'data-codecept-selector-file': 'ArrayList'
                }, _defineProperty(_extends4, 'data-codecept-selector-node', 'Chip'), _defineProperty(_extends4, 'data-codecept-selector-file', 'ArrayList'), _defineProperty(_extends4, 'data-codecept-selector-node', 'Chip'), _defineProperty(_extends4, 'data-codecept-selector-file', 'ArrayList'), _extends4)),
                DragAvatar,
                this.props.children
            );

            return Content;
        }
    }]);

    return ChipItem;
}(_react.Component);

;
ChipItem.contextTypes = {
    muiTheme: PropTypes.object
};
ChipItem.propTypes = {
    //label: PropTypes.oneOftype([PropTypes.string, PropTypes.node])
    actionButtons: PropTypes.arrayOf(PropTypes.object),
    draggable: PropTypes.bool,
    value: PropTypes.object
};

var hydratedValuePropType = PropTypes.shape({
    _hash: PropTypes.string,
    _value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number, PropTypes.bool])
});

var ArrayList = _react2.default.createClass({
    displayName: 'ArrayList',

    propTypes: {
        actionButtons: PropTypes.arrayOf(PropTypes.object),
        delegateValueChange: PropTypes.func,
        delegateDeleteForId: PropTypes.func,
        delegateSelectForId: PropTypes.func,
        draggable: PropTypes.bool,
        disabled: PropTypes.bool,
        locked: PropTypes.bool,
        mode: PropTypes.string,
        toHTML: PropTypes.func,
        selectedValue: hydratedValuePropType,
        value: PropTypes.arrayOf(hydratedValuePropType),
        displayValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: [],
            columns: 1,
            layoutWidth: 1200,
            delegateDeleteForId: function delegateDeleteForId() {
                return function () {};
            },
            delegateSelectForId: function delegateSelectForId() {}
        };
    },
    getInitialState: function getInitialState() {
        return {
            itemBeingDragged: null,
            layoutWidth: 1200
        };
    },
    componentWillMount: function componentWillMount() {
        this._chipRefsByHash = {};
    },
    componentDidMount: function componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
    },

    _generate_delegateDeleteForId: function _generate_delegateDeleteForId(id) {
        var _this2 = this;

        return function () {
            _this2.props.delegateDeleteForId(id);
        };
    },
    _generate_delegateSelectForId: function _generate_delegateSelectForId(id) {
        var _this3 = this;

        return function (event) {
            _this3.props.delegateSelectForId(id);
        };
    },
    _renderChildren: function _renderChildren() {
        var _this4 = this;

        var _props2 = this.props,
            value = _props2.value,
            _props2$displayValue = _props2.displayValue,
            displayValue = _props2$displayValue === undefined ? [] : _props2$displayValue,
            selectedValue = _props2.selectedValue,
            disabled = _props2.disabled,
            locked = _props2.locked,
            actionButtons = _props2.actionButtons,
            draggable = _props2.draggable;


        var fieldDisabled = this._isDisabled();

        return (value || []).map(function (valueObject, index) {
            var _React$createElement2, _extends5;

            var _hash = valueObject._hash,
                _value = valueObject._value;

            var targetDisplayValue = displayValue[index];

            var focusedProps = valueObject._hash == selectedValue._hash ? {
                focused: true
            } : {};

            return _react2.default.createElement(
                ChipItem,
                _extends({
                    key: _hash,
                    value: _value,
                    draggable: draggable,
                    onRequestDelete: fieldDisabled ? null : _this4._generate_delegateDeleteForId(_hash),
                    actionButtons: actionButtons
                }, fieldDisabled ? {} : { onTouchTap: _this4._generate_delegateSelectForId(_hash) }, focusedProps, (_extends5 = {
                    chipRef: function chipRef(_chipRef) {
                        if (!_chipRef) {
                            return;
                        }

                        _this4._chipRefsByHash[_hash] = _chipRef;
                    },
                    'data-codecept-selector-node': 'ChipItem',
                    'data-codecept-selector-file': 'ArrayList'
                }, _defineProperty(_extends5, 'data-codecept-selector-node', 'ChipItem'), _defineProperty(_extends5, 'data-codecept-selector-file', 'ArrayList'), _defineProperty(_extends5, 'data-codecept-selector-node', 'ChipItem'), _defineProperty(_extends5, 'data-codecept-selector-file', 'ArrayList'), _extends5)),
                _react2.default.createElement('span', (_React$createElement2 = {
                    dangerouslySetInnerHTML: {
                        __html: RubyCodeUtils.sanitizeHTML(_this4.props.toHTML(targetDisplayValue || _value))
                    },
                    'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'ArrayList'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ArrayList'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ArrayList'), _React$createElement2))
            );
        });
    },
    render: function render() {
        var _this5 = this,
            _React$createElement4;

        var layout = [];
        var currentY = 0;
        var margin = [10, 10];

        var childrenElements = this._renderChildren().filter(function (child) {
            return !_lodash2.default.isNull(child);
        });
        var children = childrenElements.map(function (renderedChild, childIdx) {
            var _React$createElement3;

            var id = renderedChild.key; //`${childIdx}`;
            var boxSize = DEFAULT_BOX_SIZE;

            var dataGridProps = {
                key: id //# necessary to get ReactGridLayout playing nicely with new instances since the IDs would be completely different
                , i: id,
                x: 0,
                y: currentY,
                w: _this5.state.layoutWidth,
                h: boxSize.height,
                minW: 1 //# TODO: update based on layout for module
                , maxW: 1
                //, static: false //# TODO: no specific request for this
            };
            currentY += boxSize.height + margin[1];

            layout.push(dataGridProps);

            return _react2.default.createElement(
                'div',
                (_React$createElement3 = {
                    key: id,
                    'data-grid': dataGridProps,
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'ArrayList'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ArrayList'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ArrayList'), _React$createElement3),
                renderedChild
            );
        });

        //draggableHandle={`.${styles.DragHandleIcon}`}
        return _react2.default.createElement(
            ReactGridLayout,
            (_React$createElement4 = { className: 'layout ' + styles.ReactGridLayout,
                placeholderClassName: styles.Placeholder,
                layout: layout,
                isDraggable: this.props.draggable,
                cols: this.props.columns,
                rowHeight: 1,
                width: this.state.layoutWidth,
                isResizable: false,
                horizontalCompact: false,
                verticalCompact: false,
                linearCompact: true,
                margin: margin,
                containerPadding: [0, 0],
                onDragStart: this._handleDragStart,
                onDragStop: this._handleDragStop,
                onLayoutChange: this._handleLayoutChange,
                ref: function ref(theReactGridLayout) {
                    var layoutElement = theReactGridLayout ? ReactDOM.findDOMNode(theReactGridLayout) : undefined;
                    _this5._layoutElement = layoutElement;
                },
                'data-codecept-selector-node': 'ReactGridLayout',
                'data-codecept-selector-file': 'ArrayList'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ReactGridLayout'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ArrayList'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ReactGridLayout'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ArrayList'), _React$createElement4),
            children
        );
    },
    componentWillUnmount: function componentWillUnmount() {

        //# stop listening to layout width changes
        window.removeEventListener('resize', this.onWindowResize);
        this._layoutElement = undefined;
        this._chipRefsByHash = undefined;
    }

    //# Custom handlers
    , onWindowResize: function onWindowResize(_event) {
        if (!this._layoutElement) {
            return;
        };

        this.setState({ layoutWidth: this._layoutElement.offsetWidth });
    },
    setItemBeingDragged: function setItemBeingDragged(id) {
        return;
        this.setState({
            itemBeingDragged: id
        });
    },
    _handleDragStart: function _handleDragStart(layout, oldItem, newItem, placeholder, evt, htmlElement) {
        this.setItemBeingDragged(oldItem.i);
    },
    _handleDragStop: function _handleDragStop(layout, oldItem, newItem, placeholder, evt, htmlElement) {
        var sortedByXLayout = layout.sort(function (a, b) {
            return a.x - b.x;
        });
        var sortedByYLayout = sortedByXLayout.sort(function (a, b) {
            return a.y - b.y;
        });

        var indexOfNewPlacement = sortedByYLayout.indexOf(newItem);

        var leftSibling = sortedByYLayout[indexOfNewPlacement - 1];
        var rightSibling = sortedByYLayout[indexOfNewPlacement + 1];

        this.setItemBeingDragged(null);
        //# NOTE: due to how the reactGridLayout component internally calls on setState
        //# we need to call on a 'setImmediate' if we want to trigger the relayout last
        //# this would be to allow static fields to be pushed to the top
        //# however, note: the static fields would not be pushed down so we lose the opportunity to shove groups of fields around

        var itemOrder = sortedByYLayout.filter(function (item) {
            return !item.static;
        }).map(function (item) {
            return item.i;
        });

        var idStringToValueMap = this._getIdStringToValueMap();

        var finalValue = itemOrder.map(function (itemId) {
            return idStringToValueMap[itemId];
        });

        //# blur chipRefs
        var targetChip = this._chipRefsByHash[newItem.i];
        targetChip && targetChip.handleBlur();

        this.props.delegateValueChange(evt, finalValue);
    },
    _handleLayoutChange: function _handleLayoutChange(layout, oldItem, newItem, placeholder, evt, htmlElement) {},
    _updateBoxSizeForId: function _updateBoxSizeForId(boxSize, id) {
        var currentBoxSize = this.state.boxSizesByChildId[id];
        if (!_lodash2.default.isEqual(boxSize, currentBoxSize)) {
            this.setState({
                boxSizesByChildId: _extends({}, this.state.boxSizesByChildId, _defineProperty({}, id, boxSize))
            });
        }
    },
    _updateSizeChangeCallbackForId: function _updateSizeChangeCallbackForId(callback, id) {
        this.setState({
            sizeChangeCallbacksByChildId: _extends({}, this.state.sizeChangeCallbacksByChildId, _defineProperty({}, id, callback))
        });
    }

    //# UTILITIES
    , _getIdStringToValueMap: function _getIdStringToValueMap() {
        var value = this.props.value;


        return (value || []).reduce(function (collector, value) {
            collector['' + value._hash] = value; //# return the value as-is, the parent component will handle selecting the actual value
            return collector;
        }, {});
    },

    _isInfoMode: function _isInfoMode() {
        var mode = this.props.mode;


        return mode === 'info';
    },
    _isDisabled: function _isDisabled() {
        return this._isInfoMode() || this.props.locked || this.props.disabled;
    }
});

exports.default = ArrayList;