'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.startBatchMove = startBatchMove;
exports.endBatchMove = endBatchMove;
exports.batchMoveSelectedIds_toRelativePosition_andReferenceId = batchMoveSelectedIds_toRelativePosition_andReferenceId;
exports.onBatchMove = onBatchMove;

var _constants = require('./constants');

var types = _interopRequireWildcard(_constants);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function startBatchMove() {
    return {
        type: types.GRIDDLE_BATCH_MOVE_START
    };
}

function endBatchMove() {
    return {
        type: types.GRIDDLE_BATCH_MOVE_END
    };
}

//# 20230112 - DEPRECATED should only use batchMoveSelectedIds_toRelativePosition_andReferenceId
/*
export function batchMoveSelectedIds_toInsideId(id) {
    return {
        type: types.GRIDDLE_BATCH_MOVE_SELECTED_INSIDE_ID
        , targetId: id
    }
}

export function batchMoveSelectedIds_toBeforeId(id) {
    return {
        type: types.GRIDDLE_BATCH_MOVE_SELECTED_TO_BEFORE_ID
        , targetId: id
    }
}
*/

function batchMoveSelectedIds_toRelativePosition_andReferenceId(relativePosition, referenceId) {
    return {
        type: types.GRIDDLE_BATCH_MOVE_SELECTED_ROWS_RELATIVE_TO_ROW_WITH_ID,
        referenceId: referenceId,
        relativePosition: relativePosition
    };
}

function onBatchMove(store, events, relativePosition, referenceId) {
    var state = store.getState();
    var selectedRecords = state.get('selectedRecords');
    var selectionOrder = state.get('selectionOrder').toJS();

    var movedIds = selectionOrder.map(function (idStr) {
        return selectedRecords.getIn([idStr, 'id']);
    });
    return function (dispatch) {
        var batchMoveProvider = state.get('batchMoveProvider').toJS();
        batchMoveProvider.onBatchMove(store, events, movedIds, relativePosition, referenceId);

        return dispatch(endBatchMove());
    };
}