'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _DropDownMenu = require('material-ui/DropDownMenu');

var _DropDownMenu2 = _interopRequireDefault(_DropDownMenu);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _clear = require('material-ui/svg-icons/content/clear');

var _clear2 = _interopRequireDefault(_clear);

var _CalendarYear = require('material-ui/DatePicker/CalendarYear');

var _CalendarYear2 = _interopRequireDefault(_CalendarYear);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var _ = require('lodash');

var DatePicker__dateUtils = require('material-ui/DatePicker/dateUtils');

var TextField__propTypeKeys = Object.keys(_TextField2.default.propTypes);
var DropDownMenu__propTypeKeys = Object.keys(_DropDownMenu2.default.propTypes);
var CalendarYear__propTypeKeys = Object.keys(_CalendarYear2.default.propTypes);

//# only support un-conflicting proptypes as top-level props
//# others should be nested in their respective overrides
var TextFieldOnly__propTypeKeys = _.difference(TextField__propTypeKeys, DropDownMenu__propTypeKeys.concat(CalendarYear__propTypeKeys));
//["defaultValue", "errorStyle", "errorText", "floatingLabelFixed", "floatingLabelFocusStyle", "floatingLabelStyle", "floatingLabelText", "fullWidth", "hintStyle", "hintText", "id", "inputStyle", "multiLine", "name", "onBlur", "onEnterKeyDown", "onFocus", "onKeyDown", "rows", "rowsMax", "textareaStyle", "type", "underlineDisabledStyle", "underlineFocusStyle", "underlineShow"]

var DropDownMenuOnly__propTypeKeys = _.difference(DropDownMenu__propTypeKeys, TextField__propTypeKeys.concat(CalendarYear__propTypeKeys));
//["animated", "animation", "autoWidth", "iconStyle", "labelStyle", "listStyle", "maxHeight", "menuStyle", "openImmediately"]

var CalendarYearOnly__propTypeKeys = _.difference(CalendarYear__propTypeKeys, TextField__propTypeKeys.concat(DropDownMenu__propTypeKeys));
//["displayDate", "maxDate", "minDate", "onTouchTapYear", "selectedDate", "wordings"]

var WrappedCalendarYear = _react2.default.createClass({
    displayName: 'WrappedCalendarYear',

    render: function render() {
        var _this = this,
            _extends2;

        return _react2.default.createElement(_CalendarYear2.default, _extends({}, this.props, (_extends2 = {
            onTouchTapYear: function onTouchTapYear(event, year) {
                _this.props.onTouchTap(event);
                //# this triggers the injected handler that closes the 
                //# DropdownMenu
                _this.props.onTouchTapYear(event, year);
            },
            'data-codecept-selector-node': 'CalendarYear',
            'data-codecept-selector-file': 'ComponentizedYear'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'CalendarYear'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ComponentizedYear'), _defineProperty(_extends2, 'data-codecept-selector-node', 'CalendarYear'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ComponentizedYear'), _extends2)));
    }
});

module.exports = _react2.default.createClass({
    displayName: 'exports',

    propTypes: _extends({
        TextFieldOverride: PropTypes.shape(_TextField2.default.propTypes),
        DropDownMenuOverride: PropTypes.shape(_DropDownMenu2.default.propTypes),
        CalendarYearOverride: PropTypes.shape(_CalendarYear2.default.propTypes)
    }, _DropDownMenu2.default.propTypes, _TextField2.default.propTypes, _CalendarYear2.default.propTypes),

    getDefaultProps: function getDefaultProps() {
        return {
            minDate: DatePicker__dateUtils.addYears(new Date(), -100),
            maxDate: DatePicker__dateUtils.addYears(new Date(), 100),
            floatingLabelText: "Year",

            autoWidth: false,
            listStyle: {
                height: 300
            },
            labelStyle: {},
            iconStyle: {},

            TextFieldOverride: {},
            DropDownMenuOverride: {},
            CalendarYearOverride: {}
        };
    },
    _clearValue: function _clearValue() {
        this.props.onChange && this.props.onChange(null, null);
    },
    _renderDropdown: function _renderDropdown() {
        var _this2 = this,
            _extends3,
            _extends4;

        var selectedDate = new Date();
        if (this.props.value) {
            selectedDate.setFullYear(this.props.value);
        };

        var dropdown = _react2.default.createElement(
            _DropDownMenu2.default,
            _extends({}, _.pick(this.props, DropDownMenuOnly__propTypeKeys), {
                labelStyle: _extends({
                    paddingLeft: 0,
                    top: this.props.floatingLabelText ? 6 : -4
                }, this.props.labelStyle),
                iconStyle: _extends({
                    right: 0,
                    top: this.props.floatingLabelText ? 22 : 14
                }, this.props.iconStyle),
                style: { display: 'block' },
                underlineStyle: { borderTop: 'none' },
                value: this.props.value
            }, this.props.DropDownMenuOverride, (_extends4 = {
                onChange: function onChange(event, number, payload) {
                    //# This is not being used
                    //# we rely on onTouchTapYear() being triggered
                },
                'data-codecept-selector-node': 'DropDownMenu',
                'data-codecept-selector-file': 'ComponentizedYear'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'DropDownMenu'), _defineProperty(_extends4, 'data-codecept-selector-file', 'ComponentizedYear'), _defineProperty(_extends4, 'data-codecept-selector-node', 'DropDownMenu'), _defineProperty(_extends4, 'data-codecept-selector-file', 'ComponentizedYear'), _extends4)),
            _react2.default.createElement(WrappedCalendarYear, _extends({
                key: 'years',
                label: this.props.value //# this is to trick DropDownMenu matching its value with the children value
                , value: this.props.value,
                onTouchTapYear: function onTouchTapYear(event, year) {
                    _this2.props.onChange && _this2.props.onChange(event, year);
                },
                selectedDate: selectedDate

            }, _.pick(this.props, CalendarYearOnly__propTypeKeys), this.props.CalendarYearOverride, (_extends3 = {
                'data-codecept-selector-node': 'WrappedCalendarYear',
                'data-codecept-selector-file': 'ComponentizedYear'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'WrappedCalendarYear'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ComponentizedYear'), _defineProperty(_extends3, 'data-codecept-selector-node', 'WrappedCalendarYear'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ComponentizedYear'), _extends3)))
        );

        return dropdown;
    },
    render: function render() {
        var _extends5, _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5;

        return _react2.default.createElement(
            'div',
            (_React$createElement5 = {
                style: {
                    position: 'relative'
                },
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'ComponentizedYear'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ComponentizedYear'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ComponentizedYear'), _React$createElement5),
            _react2.default.createElement(
                _TextField2.default,
                _extends({
                    floatingLabelText: this.props.floatingLabelText,

                    value: this.props.value

                }, _.pick(this.props, TextFieldOnly__propTypeKeys), this.props.TextFieldOverride, (_extends5 = {
                    'data-codecept-selector-node': 'TextField',
                    'data-codecept-selector-file': 'ComponentizedYear'
                }, _defineProperty(_extends5, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends5, 'data-codecept-selector-file', 'ComponentizedYear'), _defineProperty(_extends5, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends5, 'data-codecept-selector-file', 'ComponentizedYear'), _extends5)),
                this._renderDropdown()
            ),
            _react2.default.createElement(
                'div',
                (_React$createElement4 = {
                    style: {
                        display: 'flex',
                        marginRight: 3,
                        visibility: this.props.value ? 'visible' : 'hidden',
                        position: 'absolute',
                        right: 8,
                        top: -6
                    },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'ComponentizedYear'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ComponentizedYear'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ComponentizedYear'), _React$createElement4),
                _react2.default.createElement(
                    'div',
                    (_React$createElement3 = {
                        style: {
                            marginTop: 30
                        },
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'ComponentizedYear'
                    }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ComponentizedYear'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ComponentizedYear'), _React$createElement3),
                    _react2.default.createElement(
                        _IconButton2.default,
                        (_React$createElement2 = {
                            onTouchTap: this._clearValue,
                            'data-codecept-selector-node': 'IconButton',
                            'data-codecept-selector-file': 'ComponentizedYear'
                        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ComponentizedYear'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ComponentizedYear'), _React$createElement2),
                        _react2.default.createElement(_clear2.default, (_React$createElement = {
                            'data-codecept-selector-node': 'ContentClear',
                            'data-codecept-selector-file': 'ComponentizedYear'
                        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ContentClear'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ComponentizedYear'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ContentClear'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ComponentizedYear'), _React$createElement))
                    )
                )
            )
        );
    }
});