'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.FilterComponent = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _keycode = require('keycode');

var _keycode2 = _interopRequireDefault(_keycode);

var _Toolbar = require('material-ui/Toolbar');

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _filter = require('./filter.cssModule');

var _filter2 = _interopRequireDefault(_filter);

var _filter_style = require('./filter_style');

var _filter_style2 = _interopRequireDefault(_filter_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

//# Icons


var FilterComponent = exports.FilterComponent = function (_React$Component) {
    _inherits(FilterComponent, _React$Component);

    function FilterComponent(props, context) {
        _classCallCheck(this, FilterComponent);

        var _this = _possibleConstructorReturn(this, (FilterComponent.__proto__ || Object.getPrototypeOf(FilterComponent)).call(this, props, context));

        _this._debounced_handleChange = _lodash2.default.debounce(function (e) {
            var value = e.target.value;

            if (value.length && value.length < _this.props.QUERY_STRING_MIN_LENGTH) {
                return;
            }

            _this.props.events.setFilter(value);
        }, _this.props.QUERY_DEBOUNCE_TIMEOUT);
        _this.onKeyDown = _this.onKeyDown.bind(_this);
        return _this;
    }

    _createClass(FilterComponent, [{
        key: 'onKeyDown',
        value: function onKeyDown(event) {
            switch ((0, _keycode2.default)(event)) {
                case 'enter':
                    this._debounced_handleChange.flush();
                    break;
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _this2 = this;

            var textValue = _lodash2.default.get(this, 'state.currValue') != null ? _lodash2.default.get(this, 'state.currValue') : this.props.filter;

            return _react2.default.createElement(
                _Toolbar.ToolbarGroup,
                { className: _filter2.default.ToolbarGroup, 'data-codecept-selector-node': 'ToolbarGroup',
                    'data-codecept-selector-file': 'filter'
                },
                _react2.default.createElement(_TextField2.default, _extends({}, _filter_style2.default.TextField, {
                    floatingLabelText: _react2.default.createElement(
                        'span',
                        {
                            'data-codecept-selector-node': 'span',
                            'data-codecept-selector-file': 'filter'
                        },
                        _react2.default.createElement('i', { className: 'fa fa-search', 'aria-hidden': 'true', 'data-codecept-selector-node': 'i',
                            'data-codecept-selector-file': 'filter'
                        })
                    ),
                    hintText: this.props.filterHintText,
                    type: 'text',
                    name: 'filter',
                    className: 'lister__searchBox',
                    value: textValue,
                    onKeyPress: function onKeyPress(e) {
                        var isEnter = e.key === 'Enter';
                        var shouldSet = textValue !== _this2.props.filter;
                        if (isEnter && shouldSet) {
                            _this2.props.events.setFilter(textValue);
                        }
                    },
                    onKeyDown: this.onKeyDown,
                    onChange: function onChange(e) {
                        e.persist();
                        _this2.setState({ currValue: e.target.value });
                        return _this2._debounced_handleChange(e);
                    },

                    'data-codecept-selector-node': 'TextField',
                    'data-codecept-selector-file': 'filter'
                }))
            );
        }
    }]);

    return FilterComponent;
}(_react2.default.Component);

FilterComponent.defaultProps = {
    QUERY_STRING_MIN_LENGTH: 2,
    QUERY_DEBOUNCE_TIMEOUT: 2000
};

FilterComponent.propTypes = {
    setFilter: _react2.default.PropTypes.func
};

exports.default = FilterComponent;