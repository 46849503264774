'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _serverTransforms = require('./serverTransforms');

var _FieldEditorConnector = require('./reactComponents/FieldEditorConnector');

var _FieldEditorConnector2 = _interopRequireDefault(_FieldEditorConnector);

var _baseFieldMixinOverride = require('./baseFieldMixinOverride');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');

var CONSTANTS = require('../common/constants');
var action = require('./action');
var reducer = require('./reducer');
var componentName = CONSTANTS.COMPONENT_NAME;

var FormField = require('@rubyapps/ruby-component-field-form');

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');

var fieldHelperMixin = require('./fieldHelperMixin/index');


var forcedLeafNodeMixin = require('@rubyapps/ruby-component-mixin-field-forced-leaf-node');

var RCFieldEditor = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldHelperMixin, forcedLeafNodeMixin],
    componentName: componentName,
    getInitialState: function getInitialState() {
        var _props = this.props,
            key = _props.key,
            label = _props.label,
            constraints = _props.constraints;

        if (key) {
            return {
                fields: _defineProperty({}, key, _extends({
                    value: (0, _serverTransforms.convertFormJS_toFieldEditorState)(this.props.default || []),
                    error: null,
                    label: label
                }, constraints ? { constraints: constraints } : {}))
            };
        }

        return undefined;
    },
    reducer: reducer,
    action: action,
    getDefaultProps: function getDefaultProps() {
        return {
            codeDialogID: 'rubyComponentCodeDialog',
            currentUserID: 'rubyComponentCurrentUser',
            formsID: 'rubyComponentForms',
            frontendSettingsID: 'rubyComponentFrontendSettings',
            keyForTemplateType: 'templateType'
        };
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getSelfStateSelector();
        var selfActions = this.getAction().generators;

        var parentForm = this.findAncestorBy(function (module) {
            return module.componentName == FormField.componentName;
        });

        var codeDialog = root.findDescendentByID(this.props.codeDialogID);

        var codeDialogActions = codeDialog ? codeDialog.getAction().generators : {};

        var currentUserID = this.props.currentUserID;
        var currentUser = root.findDescendentByID(currentUserID);
        var currentUserSelector = currentUser.getSelector().default;

        var frontendSettingsID = this.props.frontendSettingsID;
        var frontendSettingsComponent = root.findDescendentByID(frontendSettingsID);
        var frontendSettingsSelector = frontendSettingsComponent.getDefaultSelector();

        var rubyClientFKSelector = root.findDescendentByID('rubyComponentRubyClientFK').getDefaultSelector();

        var formsID = this.props.formsID;
        var forms = root.findDescendentByID(formsID);
        var formsSelector = forms.getDefaultSelector();

        var routeParent = this.findAncestorBy(function (module) {
            return module.getRouteElement;
        });
        return {
            selfSelector: selfSelector,
            selfActions: selfActions,
            codeDialogActions: codeDialogActions,
            currentUserSelector: currentUserSelector,
            frontendSettingsSelector: frontendSettingsSelector,
            rubyClientFKSelector: rubyClientFKSelector,
            formsSelector: formsSelector,
            getActiveRubyClientInfo: routeParent.getActiveRubyClientInfo.bind(routeParent)
        };
    },
    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector,
            currentUserSelector = _getDependencies.currentUserSelector,
            formsSelector = _getDependencies.formsSelector,
            frontendSettingsSelector = _getDependencies.frontendSettingsSelector;

        return {
            self: selfSelector(state),
            user: currentUserSelector(state),
            forms: formsSelector(state),
            frontendSettings: frontendSettingsSelector(state),
            routing: state.routing //# need to update based on routing changes
        };
    },
    _formValueFromLocalState: _baseFieldMixinOverride.formValueFromLocalState,
    getReactClass: function getReactClass() {
        return _FieldEditorConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _React$createElement;

        var FieldEditorComponent = this.getReactClass();

        return React.createElement(FieldEditorComponent, (_React$createElement = {
            'data-codecept-selector-node': 'FieldEditorComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'FieldEditorComponent'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'FieldEditorComponent'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement));
    },
    CONSTANTS: CONSTANTS
});

module.exports = RCFieldEditor;