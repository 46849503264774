'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var FieldObject = _react2.default.createClass({
    displayName: 'FieldObject',

    propTypes: {
        actions: PropTypes.object,
        style: PropTypes.object
    },
    render: function render() {
        var _React$createElement;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        //# NOTE: don't show label. If you want to show a label, use Fieldset
        //# Or create a Card component
        return _react2.default.createElement(
            'div',
            (_React$createElement = { style: this.props.style, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Object'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Object'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Object'), _React$createElement),
            this.props.children
        );
    }
});

exports.default = FieldObject;