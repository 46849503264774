'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reduxBatchedActions = require('redux-batched-actions');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var Promise = require('bluebird');


var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');

module.exports = {

    cloneSelf: function cloneSelf(parentNode) {
        var overrides = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        return baseFieldMixin.cloneSelf.call(this, parentNode, _extends({ children: [] }, overrides)); //# don't clone children since it's dynamic
        //# this might be an issue if we need to clone this node
        //# for purposes other than displayValue
        //# IF we cannot call on helperChildrenFromOptions() to
        //# generate new helper children
    },
    helperChildrenFromOptions: function helperChildrenFromOptions(options) {
        var _this = this;

        var selfModule = this;
        var selfID = this.getID();

        if (this._cachedHelperChildrenByUUID == undefined) {
            this._cachedHelperChildrenByUUID = {};
        }
        //# UUID is the index of the options for now since the order never changes
        var cachedChildrenByUUID = this._cachedHelperChildrenByUUID;
        var hashedOptions = this.hashedItems(options);
        var cachedCount = 0;

        var helperReduxStore = void 0;
        var getStore = function getStore() {
            return helperReduxStore;
        };
        /*
        const getState = function(appState) {
            const selfSelector = this.getSelectorRelativeToComponent(selfModule.getParent())
            const store = this.getStore();
            
            return selfSelector(appState || store.getState());
        }
        */
        var childrenComponents = hashedOptions.reduce(function (collector, hashedOption, index) {
            var childID = hashedOption._hash,
                option = hashedOption._value;


            var cachedChild = cachedChildrenByUUID[childID];
            if (0 && cachedChild) {
                cachedCount++;
                return collector.concat(cachedChild);
            }

            var idPrefix = selfID + '[' + childID + ']';
            var builtChildComponents = _this.buildChildComponentsForOption(option, idPrefix);

            cachedChildrenByUUID[childID] = builtChildComponents;

            //# deeply patch getStore for each of the childrenComponents
            builtChildComponents.forEach(function (childComponent) {
                //# patch the parent and getStore for accessibility
                childComponent._isHelper = true;
                childComponent._parent = _this;
                childComponent.getStore = getStore;
                //childComponent.getState = getState;
                childComponent.findDescendentsBy(function (node) {
                    node.getStore = getStore;
                    return false;
                });
            });

            return collector.concat(builtChildComponents);
        }, []).filter(function (child) {
            return child != undefined;
        });

        //# TODO: we can probably somehow cache the different combinations of options
        //# to prevent overly hitting the server whenever we need to re-query the options
        //# for displayValue
        helperReduxStore = this.helperReduxStore({ children: childrenComponents });

        return childrenComponents;
    },
    _displayValueFromLocalState: function _displayValueFromLocalState(selfState, entireState) {
        var _this2 = this;

        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};


        //# get displayValues for all of it's children
        //# so not the thing that's being edited, but the one that's been commited
        var selfKey = this.props.key;

        var componentOptions = _lodash2.default.get(selfState, 'props.options') || this.props.options || [];

        var formValueForFiltering = this.formValueForFilteringFromLocalState(selfState, entireState);

        var filteredComponentOptions = this.filteredOptionsByTheirQueries(componentOptions, formValueForFiltering);

        var helperChildren = this.helperChildrenFromOptions(filteredComponentOptions);
        var helperReduxStore = helperChildren.length ? helperChildren[0].getStore() : undefined;

        var fieldValue = this.getFieldValueFromLocalState(selfState) || {};
        var collectorSpec = this.newActionCollectorSpec();

        return Promise.all(helperChildren.map(function (helperChild) {
            return helperChild._formValueToLocalState(fieldValue, collectorSpec.collectAction);
        })).then(function (res) {
            if (helperChildren.length == 0) {
                return {};
            }

            helperReduxStore.dispatch((0, _reduxBatchedActions.batchActions)(collectorSpec.collectedActions));
            //console.log(JSON.stringify(helperReduxStore.getLocalState(), null, '  '));
            //# then create a new selfState for children
            var newSelfState = helperReduxStore.getLocalState()[_this2.getID()];
            var newEntireState = entireState;
            _lodash2.default.set(entireState, _this2.getKeypathArr(), newSelfState);

            var arrOfReducedChildrenPromises = _lodash2.default.reduce(helperChildren, function (collector, child, index) {
                var childID = child.getID();
                var childDisplayValue = child._displayValueFromLocalState ? child._displayValueFromLocalState(newSelfState[childID], newEntireState, options) : undefined;

                if (childDisplayValue != undefined) {
                    collector.push(childDisplayValue);
                }
                return collector;
            }, []);

            var reducedChildrenPromise = Promise.all(arrOfReducedChildrenPromises).then(function (arrOfReducedChildrenState) {
                return _extends.apply(undefined, [{}].concat(_toConsumableArray(arrOfReducedChildrenState)));
            });

            var promisedDisplayObject = selfKey ? Promise.props(_defineProperty({}, selfKey, reducedChildrenPromise)) : reducedChildrenPromise;

            return promisedDisplayObject;
        });
    }
};