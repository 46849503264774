'use strict';

var _baseElement;

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _redux = require('redux');

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');
var React = require('react');
var path = require('path');
var Route = require('route-parser');

var defaultMergeProps = function defaultMergeProps(stateProps, dispatchProps, parentProps) {
    return _extends({}, stateProps, dispatchProps, parentProps);
};

var baseElement = (_baseElement = {
    //# mixins: []
    //# propTypes:
    //== RUBY COMPONENT ======================================================//
    props: {},
    _reduxManager: undefined,
    setReduxManager: function setReduxManager(reduxManager) {
        this._reduxManager = reduxManager;
    },
    getReduxManager: function getReduxManager() {
        var reduxManager = this._reduxManager;
        if (reduxManager) {
            return reduxManager;
        }

        var root = this.getRoot();
        return root._reduxManager ? root._reduxManager : undefined;
    },
    getStore: function getStore() {
        var reduxManager = this._reduxManager;
        if (reduxManager) {
            return reduxManager.getStore();
        }

        var root = this.getRoot();
        return root._reduxManager ? root._reduxManager.getStore() : undefined;
    },
    getState: function getState(appState) {
        var selfSelector = this.getDefaultSelector();
        var store = this.getStore();

        var targetAppState = appState || (store ? store.getState() : undefined);
        return targetAppState ? selfSelector(targetAppState) : undefined;
    },
    getStateAtKeypath: function getStateAtKeypath(keypath, appState) {
        var selfSelector = this.getDefaultSelector();
        var store = this.getStore();

        return _.get(selfSelector(appState || store.getState()), keypath);
    },
    _flattedMixins: undefined,
    getMixins: function getMixins() {
        return this._flattenedMixins;
    },
    _ownedIndex: undefined
    //# if the rubyComponent is owned (ie. returned by the rubyComponent.children() method), then they would be tagged with an _ownedIndex
    //# which would be used to re-order the reactElements on getChildrenReactElement() call
    //#, getDefaultProps: (props)=>({}) //# define if you have defaultProps
    , _parent: undefined,
    getParent: function getParent() {
        return this._parent;
    },
    getRoot: function getRoot() {
        var currentParent = this;
        do {
            var tempParent = currentParent.getParent();

            if (_.isNil(tempParent)) {
                return currentParent;
            }

            currentParent = tempParent;
        } while (!_.isNil(currentParent));
    },
    getActiveRouteComponent: function getActiveRouteComponent() {
        var rootComponent = this.getRoot();
        var activeRouteComponents = rootComponent.findDescendentsBy(function (element) {
            return element.getRouteElement && element.getState().routeParams.routeActive;
        });

        return activeRouteComponents[activeRouteComponents.length - 1];
    },
    findAncestorBy: function findAncestorBy(predicate) {
        var currentParent = this;
        do {
            var tempParent = currentParent.getParent();

            if (predicate(currentParent)) {
                return currentParent;
            }

            currentParent = tempParent;
        } while (!_.isNil(currentParent));

        return undefined;
    },
    findAncestorsBy: function findAncestorsBy(predicate) {
        var currentParent = this;
        var collectedAncestors = [];
        do {
            var tempParent = currentParent.getParent();

            if (predicate(currentParent)) {
                collectedAncestors.push(currentParent);
            }

            currentParent = tempParent;
        } while (!_.isNil(currentParent));

        return collectedAncestors;
    }
    //# TODO: defaulting includeSelf=true for now because of assumed functionality by the callers
    , iterativelyTraverseAncestors_withCallback: function iterativelyTraverseAncestors_withCallback(callback) {
        var includeSelf = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        includeSelf && callback(this);

        var parent = this.getParent();
        if (parent) {
            parent.iterativelyTraverseAncestors_withCallback(callback);
        }
    },
    _children: [],
    _explicitChildren: [] //# any children declared that's not owned
    , newMergedExplicitAndImplicitChildren: function newMergedExplicitAndImplicitChildren() {
        var self = this;
        //# assumes that explicitChildren has been set
        var explicitChildren = this._explicitChildren;

        var childrenElements = explicitChildren.slice();
        if (self.children) {
            var internalChildren = self.children();
            //# reassign order of children ruby-components
            if (internalChildren.length && _.isArray(internalChildren[0])) {
                var headerChildren = (internalChildren[0] ? internalChildren[0] : []).map(function (rubyComponent, index) {
                    rubyComponent._ownedIndex = index;
                    return rubyComponent;
                });

                //# mark the header rubyComponents with some flag for when getChildrenReactElements() is called
                var footerChildren = (internalChildren[1] ? internalChildren[1] : []).map(function (rubyComponent, index) {
                    rubyComponent._ownedIndex = -1 * (index + 1);
                    return rubyComponent;
                });
                //# mark the footer rubyComponents with some flag for when getChildrenReactElements() is called
                childrenElements = headerChildren.concat(childrenElements, footerChildren);
            } else {
                childrenElements = childrenElements.concat(internalChildren);
            }
        }
        return childrenElements;
    }
    //# expects implcit children to be changed
    //# need to create new array of children accounting for new implicit children
    //# and overwrite the children cache
    //# returns the old children if we're replacing them
    //# NOTE: some of the children might still be reused
    , /*(bool|[RubyComponent])*/replaceChildren: function replaceChildren() {
        var _this = this;

        this.triggerTree_onReduxTearDown(); //# NOTE: ideally we want to be able to skip calling on this, but calling on this.newMergedExplicitAndImplicitChildren() causes sideeffects
        this.triggerTree_onUnmount();
        //# Reference the DynamicForm component
        //# so we need to always call on teardown

        this.clearDependencies();
        //# Replace the children rubyComponents
        var newChildren = this.newMergedExplicitAndImplicitChildren();

        var isNewChildrenSame = newChildren.length == this._children.length && newChildren.reduce(function (collector, newChild, index) {
            return collector && newChild == _this._children[index];
        }, true);

        //console.log(`[isNewChildrenSame] for id: [${this.getID()}]. isNewChildrenSame: ${isNewChildrenSame}`);
        if (isNewChildrenSame) {
            //# we still need to retrigger onReduxInit
            //# the only benefit of checking for children differents if that we don't 
            //# need to rebuild reducer
            //# let components know of update
            if (this.getStore() == undefined || !this.getState()) {
                return false;
            }
            this.triggerTree_onReduxInit(this.getStore().dispatch);
            this.triggerTree_onMount();
            return false;
        }

        newChildren.forEach(function (child) {
            child._parent = _this;
        });
        var oldChildren = this._children;
        this._children = newChildren;
        this._childrenReactElements = undefined;

        //# invalidate the cached childrenids
        this._cachedChildrenIds = undefined;

        //# Rebuild the reducer
        var reduxManager = this.getReduxManager();
        if (reduxManager) {
            reduxManager.rebuildReducer();
        } else {
            logger.warn('reduxManger not found.');
        }

        //# let components know of update
        var store = this.getStore();
        this.triggerTree_onReduxInit(store.dispatch);
        this.triggerTree_onMount();

        return oldChildren;
    },
    getChildren: function getChildren() {
        return this._children;
    },

    _cachedChildrenIds: undefined,
    getChildrenIds: function getChildrenIds() {
        if (this._cachedChildrenIds) {
            return this._cachedChildrenIds;
        }

        var children = this.getChildren();
        var flatMappedChildren = _.flatMap(children);

        var childrenIds = flatMappedChildren.map(function (child) {
            return child.getID();
        });
        this._cachedChildrenIds = childrenIds;

        return this._cachedChildrenIds;
    },
    findChildBy: function findChildBy(predicate) {
        return _.find(this._children, function (rcElement) {
            return predicate(rcElement);
        });
    },
    findChildrenBy: function findChildrenBy(predicate) {
        return _.filter(this._children, function (rcElement) {
            return predicate(rcElement);
        });
    },
    findDescendentBy: function findDescendentBy(predicate) {
        var foundChild = this.findChildBy(predicate);
        if (foundChild) {
            return foundChild;
        }

        var foundChildArr = _.reduce(this._children, function (collector, rcChild) {
            var foundGrandChild = rcChild.findDescendentBy(predicate);
            if (foundGrandChild) {
                collector.push(foundGrandChild);
            }
            return collector;
        }, []);
        return foundChildArr[0];
    },
    findDescendentsBy: function findDescendentsBy(predicate) {
        var foundChildren = this.findChildrenBy(predicate);
        var children = this.getChildren();

        var foundChildrenArr = _.reduce(children, function (collector, rcChild) {
            var foundGrandChild = rcChild.findDescendentsBy(predicate);
            if (foundGrandChild) {
                collector = collector.concat(foundGrandChild);
            }
            return collector;
        }, foundChildren);
        return foundChildrenArr;
    },
    findChildByID: function findChildByID(id) {
        return this.findChildBy(function (rcElement) {
            return rcElement.getID() == id;
        });
    },
    findDescendentByID: function findDescendentByID(id) {
        //var selector = ':has(:root > :root > .id:val("'+id+'"))'
        //var descendentElement = jsonselect.match(selector, this._children)[0];

        var foundChild = this.findChildByID(id);
        if (foundChild) {
            return foundChild;
        }

        var foundChildArr = _.reduce(this._children, function (collector, rcChild) {
            var foundGrandChild = rcChild.findDescendentByID(id);
            if (foundGrandChild) {
                collector.push(foundGrandChild);
            }
            return collector;
        }, []);
        return foundChildArr[0];
    },
    getChildAtKeypathArr: function getChildAtKeypathArr(keypathArr) {
        var currentElement = this;
        if (currentElement.getID() != keypathArr[0]) {
            return undefined;
        } else if (keypathArr.length == 1) {
            return currentElement;
        }

        for (var i = 1; i < keypathArr.length; i++) {
            var currentKey = keypathArr[i];
            if (currentElement) {
                currentElement = currentElement.findChildByID(currentKey);
            }
        }

        return currentElement;
    }
    //# TODO: defaulting includeSelf=true for now because of assumed functionality by the callers
    , iterativelyTraverseChildren_withCallback: function iterativelyTraverseChildren_withCallback(callback) {
        var includeSelf = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        includeSelf && callback(this);

        var children = this.getChildren();
        _.each(children, function (child) {
            child.iterativelyTraverseChildren_withCallback(callback);
        });
    },

    _chainedSelectorSpecificationFromPathString: function _chainedSelectorSpecificationFromPathString(path) {

        var splitPathArr = path.split(' ').filter(function (value) {
            return value != '';
        });

        var groupedByCommands = splitPathArr.reduce(function (collector, value) {
            if (value == '>' || value == '<') {
                var newCommandArgs = [value];
                collector.collection.push(collector.currentCommandArgs);
                collector.currentCommandArgs = newCommandArgs;
            } else if (collector.currentCommandArgs.length == 2) {
                var _newCommandArgs = ['ROOT', value];
                collector.collection.push(collector.currentCommandArgs);
                collector.currentCommandArgs = _newCommandArgs;
            } else {
                collector.currentCommandArgs.push(value);
            }

            return collector;
        }, {
            currentCommandArgs: ['ROOT'],
            collection: []
        });

        var groupedByCommandsArr = groupedByCommands.collection.concat([groupedByCommands.currentCommandArgs]).filter(function (commandPair) {
            return commandPair.length != 1;
        });

        return groupedByCommandsArr;
    },
    getRubyComponentAtPath: function getRubyComponentAtPath(path) {
        var chainedSelectorSpec = this._chainedSelectorSpecificationFromPathString(path);

        function findNodeFromTargetNode_inDirection_atSelector(targetNode, direction, selector) {
            //# direction = 'ancestor' || 'descendent'

            var selectorString = selector.substr(1);
            var selectorTypeChar = selector.charAt(0);

            if (selectorTypeChar == '#') {
                if (direction == 'ancestor') {
                    return targetNode.findAncestorBy(function (rcNode) {
                        return rcNode.getID() == selectorString;
                    });
                } else {
                    return targetNode.findDescendentBy(function (rcNode) {
                        return rcNode.getID() == selectorString;
                    });
                }
            } else if (selectorTypeChar == '[') {
                //# looking for attribute existence
                var attributeString = _.trim(selectorString.substring(0, selectorString.indexOf(']')));

                var _ref = /([^=]+)=(.+)/.exec(attributeString) || [],
                    _ref2 = _slicedToArray(_ref, 3),
                    isEqSelector = _ref2[0],
                    keyPath = _ref2[1],
                    val = _ref2[2];

                var _selector = isEqSelector ? function (rcNode) {
                    return _.get(rcNode, keyPath) == val;
                } : function (rcNode) {
                    return !_.isNil(_.get(rcNode, attributeString));
                };
                if (direction == 'ancestor') {
                    return targetNode.findAncestorBy(_selector);
                } else {
                    return targetNode.findDescendentBy(_selector);
                }
            } else {
                //# assume componentName
                if (direction == 'ancestor') {
                    return targetNode.findAncestorBy(function (rcNode) {
                        return rcNode.componentName == selectorString;
                    });
                } else {
                    return targetNode.findDescendentBy(function (rcNode) {
                        return rcNode.componentName == selectorString;
                    });
                }
            }
        }

        var selectedNode = chainedSelectorSpec.reduce(function (currentNode, commandPair) {
            var _commandPair = _slicedToArray(commandPair, 2),
                command = _commandPair[0],
                selector = _commandPair[1];

            switch (command) {
                case 'ROOT':
                    var rootNode = currentNode.getRoot();
                    return findNodeFromTargetNode_inDirection_atSelector(rootNode, 'descendent', selector);
                case '<':
                    return findNodeFromTargetNode_inDirection_atSelector(currentNode, 'ancestor', selector);
                case '>':
                    return findNodeFromTargetNode_inDirection_atSelector(currentNode, 'descendent', selector);
                default:
                    return currentNode;
            }
        }, this);

        return selectedNode;
    },
    getKeypathArr: function getKeypathArr() {
        var keypathArr = [];
        var currentParent = this;
        do {
            keypathArr.push(currentParent.getID());
            currentParent = currentParent.getParent();
        } while (!_.isNil(currentParent));

        return _.reverse(keypathArr);
    },
    getID: function getID() {
        return this.props.id || this.componentName;
    }

    //# _dependenciesByID: undefined
    , getDependencies: function getDependencies() {
        var _this2 = this;

        if (this._dependenciesByID) {
            return this._dependenciesByID;
        }
        var dependenciesByID;
        if (_.isArray(this.dependencies)) {
            dependenciesByID = Object.assign.apply(null, this.dependencies.reduce(function (collector, dependenciesFn) {
                collector.push(_.isFunction(dependenciesFn) ? dependenciesFn.apply(_this2) : dependenciesFn);

                return collector;
            }, [{}]));
        } else if (typeof this.dependencies == 'function') {
            dependenciesByID = this.dependencies.apply(this);
        } else {
            //# never allow the module to redefine the selector
            dependenciesByID = this.dependencies;
        }

        //# Check for nil then don't cache so that later calls will get updated values
        var containsNil = _.reduce(dependenciesByID, function (hasNil, dependency, id) {
            if (hasNil) {
                return hasNil;
            }

            var dependencyIsNil = _.isNil(dependency);
            /*
            if (dependencyIsNil) {
                console.log('=== component', this.getID(), 'has nil dependency:', id);
            }
            */

            return dependencyIsNil;
        }, false);

        if (!containsNil) {
            this._dependenciesByID = dependenciesByID;
        }

        return dependenciesByID;
    },
    clearDependencies: function clearDependencies() {
        this._dependenciesByID = undefined;
    },
    _cachedStatesSelector: undefined
    //# one selector that is given the application state and should return all of the local states that you need
    //# by key
    //# By default, it's just {self}
    , getStatesSelector: function getStatesSelector() {
        if (this._cachedStatesSelector) {
            return this._cachedStatesSelector;
        }

        if (this.statesSelector) {
            this._cachedStatesSelector = this.statesSelector.bind(this);
        } else {
            var selfStateSelector = this.getSelfStateSelector();
            this._cachedStatesSelector = function (state) {
                return {
                    self: selfStateSelector(state)
                };
            };
        }

        return this._cachedStatesSelector;
    }

    //== NOTIFICATIONS ==============================================//
    ,
    closestNotificationComponent: function closestNotificationComponent() {
        var rubyApp = this.getRoot();
        //# look for closest ancestral notification component
        //# (previously wanted to look for descendent, which is not a good idea)
        var ancestorContainingClosestNotificationCousin = this.findAncestorBy(function (node) {
            return node.findChildBy(function (child) {
                return child.componentName == 'rubyComponentNotifications';
            });
        });

        var rubyNotificationComponent = ancestorContainingClosestNotificationCousin.findChildBy(function (child) {
            return child.componentName == 'rubyComponentNotifications';
        });

        if (!rubyNotificationComponent) {
            rubyNotificationComponent = rubyApp.findDescendentByID('rubyComponentNotifications');
        }

        return rubyNotificationComponent;
    },
    pushNotification: function pushNotification(options) {
        var rubyNotificationComponent = this.closestNotificationComponent();

        if (rubyNotificationComponent) {
            var store = this.getStore();
            var notificationOptions = _extends({}, options, {
                modal: false
            });
            var notificationActionGenerator = rubyNotificationComponent.getAction().generators.showNotification(notificationOptions);
            store.dispatch(notificationActionGenerator);
        }
    },

    showErrorNotification: function showErrorNotification(options) {
        var rubyNotificationComponent = this.closestNotificationComponent();

        if (rubyNotificationComponent) {
            var store = this.getStore();
            var notificationActionGenerator = rubyNotificationComponent.getAction().generators.showErrorNotification(options);
            store.dispatch(notificationActionGenerator);
        }
    },

    showNotificationModal: function showNotificationModal(options) {
        var rubyNotificationComponent = this.closestNotificationComponent();

        if (rubyNotificationComponent) {
            var store = this.getStore();
            var notificationOptions = _extends({}, options, {
                modal: true
            });
            var notificationActionGenerator = rubyNotificationComponent.getAction().generators.showNotification(notificationOptions);
            store.dispatch(notificationActionGenerator);
        }
    }

    //== REDUX ======================================================//
    , middleware: undefined,
    getMiddleware: function getMiddleware() {
        var _this3 = this;

        var boundMiddlewareArray = _.isArray(this.middleware) ? _.map(this.middleware, function (middleware) {
            return middleware.bind(_this3);
        }) : this.middleware ? [this.middleware.bind(this)] : [];

        return boundMiddlewareArray;
    },

    getMiddlewares: function getMiddlewares() {
        return this.getMiddleware();
    },
    getMiddlewares_flatMapped: function getMiddlewares_flatMapped() {
        var children = this.getChildren();

        var childrenMiddlewares = _.flatMap(children, function (children) {
            return children.getMiddlewares_flatMapped();
        });
        if (_.isNil(childrenMiddlewares)) {
            childrenMiddlewares = [];
        }
        var selfMiddlewares = this.getMiddlewares();
        if (selfMiddlewares.length > 0) {
            childrenMiddlewares = selfMiddlewares.concat(childrenMiddlewares);
        }
        return childrenMiddlewares;
    }
    //, getInitialState: function(state, childrenState) {}
    , reducer: function reducer() {
        var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var action = arguments[1];
        return state;
    } //# DEFAULT REDUCER
    //# could also be:
    //# reducer: [function(){} .... function(){}]
    //# and getReducerByKey will sideways combine the reducers
    //# TODO
    //# options: {children} //# allows us to replace the children
    , getReducerByKey: function getReducerByKey() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var self = this;
        var _options$children = options.children,
            children = _options$children === undefined ? self.getChildren() : _options$children;


        var childrenReducersByKey = _.reduce(children, function (collector, child) {
            var childReducerByKey = child.getReducerByKey();
            if (childReducerByKey) {
                collector = _.assign(collector, childReducerByKey);
            }
            return collector;
        }, {});

        var childrenReducersKeys = Object.keys(childrenReducersByKey);

        //# NOTE: we assume it's an array since the createElement() call will return arrays regardless of whether there are reducers
        var wrappedSelfReducer = function (selfState, action, childrenState) {
            var _this4 = this;

            var selfReducer = this.reducer;
            if (selfState == undefined && this.getInitialState) {
                selfState = this.getInitialState(selfState, childrenState);
            }

            var finalState = _.reduce(selfReducer, function (collector, reducer) {
                var reducedState = reducer.call(_this4, collector, action);
                return reducedState;
            }, selfState);
            return finalState;
        }.bind(self);
        var hasSelfReducer = this.reducer.length > 0;

        if (childrenReducersKeys.length) {
            var combinedChildrenReducer = (0, _redux.combineReducers)(childrenReducersByKey);
            return _defineProperty({}, this.getID(), function (state, action) {
                var selfState = _.omit(state, childrenReducersKeys);
                if (Object.keys(selfState).length === 0) {
                    selfState = undefined;
                }

                //# NOTE:
                //# we pass selfReducerOutput to the children to allow for the parent to have overridden the state values (this requires an object assign of state and selfReducerOutput
                //# So we opt for this idea: to switch the order of the object assign to have the parent override the children
                //# ALSO NOTE: we need to rely on es6 function parameter defaults to know when to default the state, so we can't pass down children state to the wrappedSelfReducer

                var childrenState = _.pick(state, childrenReducersKeys);
                if (Object.keys(childrenState).length === 0) {
                    childrenState = undefined;
                }
                var childrenReducerOutput = combinedChildrenReducer(childrenState, action);
                var selfReducerOutput = wrappedSelfReducer(selfState, action, childrenReducerOutput);
                return _.assign({}, childrenReducerOutput, selfReducerOutput);
            });
        } else if (hasSelfReducer) {
            return _defineProperty({}, this.getID(), wrappedSelfReducer);
        } else {
            return undefined;
        }
    },
    action: { //# DEFAULT ACTION
        TYPES: {},
        generators: {}
        //# action could also be:
        //# action: [fn || {}, ... fn || {}]
        //# and getAction() will hydrate it
    }, _hydratedAction: undefined,
    _mergedActionArray_usingContext: function _mergedActionArray_usingContext(actionArray, context) {
        var allActionsInBuckets = _.reduce(actionArray, function (collector, actionObject) {
            var hydratedActionObj = typeof actionObject == 'function' ? actionObject.apply(context) : actionObject;
            collector.TYPES.push(hydratedActionObj.TYPES);
            collector.generators.push(hydratedActionObj.generators);

            return collector;
        }, {
            TYPES: [],
            generators: []
        });

        //# TODO: set meta property to actions to include _parent: [actions .... ] where the rhs is the latest action being overridden
        var mergedAction = {
            TYPES: _extends.apply(undefined, [{}].concat(_toConsumableArray(allActionsInBuckets.TYPES))),
            generators: _extends.apply(undefined, [{}].concat(_toConsumableArray(allActionsInBuckets.generators)))
        };

        var actionParentsByKey = {};
        //# TODO: see if we can use proxies to automatically detect object assign overrides
        _.forEach(mergedAction.generators, function (generator, generatorKey) {
            actionParentsByKey[generatorKey] = allActionsInBuckets.generators.reduce(function (collector, actionBucket) {
                var actionGenerator = actionBucket[generatorKey];
                if (actionGenerator) {
                    collector.push(actionGenerator);
                }

                return collector;
            }, []).slice(0, -1);
        });

        self._actionParentsByKey = actionParentsByKey; //# need to store it here, but we'll set it in the bind call in getAction

        return {
            mergedAction: mergedAction,
            actionParentsByKey: actionParentsByKey
        };
    },
    getAction: function getAction() {
        var self = this;
        if (self._hydratedAction) {
            return self._hydratedAction;
        }

        //# hydrate action if it's a function
        //# this allows us to inject the 'this' context into the action generator function
        var actionObject = void 0;
        var actionType = _typeof(self.action);

        var _ref5 = actionType == 'function' ? { mergedAction: self.action.apply(self) } : _.isArray(self.action) ? self._mergedActionArray_usingContext(self.action, self) : { mergedAction: self.action },
            origActionObject = _ref5.mergedAction,
            _ref5$actionParentsBy = _ref5.actionParentsByKey,
            actionParentsByKey = _ref5$actionParentsBy === undefined ? {} : _ref5$actionParentsBy;

        //# Auto-bind every generator to `this`


        var boundActionObjectGenerators = _.reduce(origActionObject.generators, function (collector, generator, generatorKey) {
            collector[generatorKey] = generator.bind(self);
            collector[generatorKey]._parents = actionParentsByKey[generatorKey];

            return collector;
        }, {});

        actionObject = _.assign({}, origActionObject, {
            generators: boundActionObjectGenerators
        });

        var mergedActionTypes = _.assign({}, actionObject.TYPES);
        var mergedActionGenerators = _.assign({}, actionObject.generators);
        actionObject = _.assign(actionObject, {
            TYPES: mergedActionTypes,
            generators: mergedActionGenerators
        });

        self._hydratedAction = actionObject;
        return self._hydratedAction;
    },
    selector: {},
    _hydratedSelector: undefined,
    _defaultSelector: {
        default: function _default(state) {
            return _.get(state, this.getKeypathArr());
        },
        selfState: function selfState(state) {
            var childrenIds = this.getChildrenIds();

            return _.omit(_.get(state, this.getKeypathArr()), childrenIds);
        },
        childrenState: function childrenState(state) {
            var childrenIds = this.getChildrenIds();

            return _.pick(_.get(state, this.getKeypathArr()), childrenIds);
        }
    },
    _boundDefaultSelector_default: undefined,
    _boundDefaultSelector_selfState: undefined,
    _boundDefaultSelector_childrenState: undefined,
    getDefaultSelector: function getDefaultSelector() {
        if (this._boundDefaultSelector_default) {
            return this._boundDefaultSelector_default;
        }

        this._boundDefaultSelector_default = this._defaultSelector.default.bind(this);

        return this._boundDefaultSelector_default;
    },
    getSelfStateSelector: function getSelfStateSelector() {
        if (this._boundDefaultSelector_selfState) {
            return this._boundDefaultSelector_selfState;
        }

        this._boundDefaultSelector_selfState = this._defaultSelector.selfState.bind(this);

        return this._boundDefaultSelector_selfState;
    },
    getChildrenStateSelector: function getChildrenStateSelector() {
        if (this._boundDefaultSelector_childrenState) {
            return this._boundDefaultSelector_childrenState;
        }

        this._boundDefaultSelector_childrenState = this._defaultSelector.childrenState.bind(this);

        return this._boundDefaultSelector_childrenState;
    }
    //# returns an object containing different selectors (of which one is the defaultSelector)
    //# not really used anymore by newer components. Try not to use it
    , getSelector: function getSelector() {
        //# autobind the selector
        var self = this;
        //# hydrate selector if it's a function
        //# this allows us to inject the 'this' context into the selector generator function
        var selectorObject;
        if (typeof self.selector == 'function') {
            selectorObject = _.assign({}, self.selector.apply(self), self._defaultSelector);
        } else {
            //# never allow the module to redefine the selector
            selectorObject = _.assign({}, self.selector, self._defaultSelector);
        }
        self._hydratedSelector = _.reduce(selectorObject, function (collector, selector, selectorKey) {
            collector[selectorKey] = selector.bind(self);
            return collector;
        }, {});

        return self._hydratedSelector;
    },
    getSelectorRelativeToComponent: function getSelectorRelativeToComponent(ancestorComponent) {
        var relativeComponentKeypathArr = ancestorComponent.getKeypathArr();
        var selfKeypathArr = this.getKeypathArr();

        var relativeComponentPathMatches = relativeComponentKeypathArr.reduce(function (isMatch, value, index) {
            if (!isMatch) {
                return false;
            }
            return isMatch && selfKeypathArr[index] == value;
        }, true);

        if (!relativeComponentPathMatches) {
            throw new Error('Component keypath is completely different from this module\'s keypath. It makes no sense to be calling this method');
        }

        var relativeKeypathArr = selfKeypathArr.slice(relativeComponentKeypathArr.length);
        return function (stateOfComponent) {
            return _.get(stateOfComponent, relativeKeypathArr);
        };
    },
    _injectedConnectorSelectorsByKey: {}
    //# all selectors are passed the root-level state and are expected to return the root-level state
    //# DEPRECATED 20180926 - not used. It was previously used by the plugin-content-approval module
    , registerConnectorSelector_forKey: function registerConnectorSelector_forKey(connectorSelector, callerKey) {
        this._injectedConnectorSelectorsByKey[callerKey] = connectorSelector;
    }
    //# DEPRECATED 20180926 - not used. It was previously used by the plugin-content-approval module
    , getConnectorSelector: function getConnectorSelector() {
        var connectorSelectorsByKey = this._injectedConnectorSelectorsByKey;
        return function (state) {
            if (Object.keys(connectorSelectorsByKey).length === 0) {
                return state;
            }
            var collectorInitialState = _.assign({}, state);
            var retVal = _.reduce(connectorSelectorsByKey, function (collector, connectorSelector) {
                return connectorSelector(collector);
            }, collectorInitialState);
            return retVal;
        };
    }

    //# returns [[<ReactElement/>],[<ReactElement/>]]
    , getChildrenReactElements: function getChildrenReactElements() {
        var childrenElements = this.getChildren();

        if (this._childrenReactElements) {
            return this._childrenReactElements;
        }

        var headerReactElements = [];
        var footerReactElements = _.reduce(childrenElements, function (collector, childElement) {
            if (childElement.getReactElement && !childElement.getRouteElement) {
                var childReactElement = childElement.getReactElement();
                if (childElement._ownedIndex >= 0) {
                    headerReactElements.push(childReactElement);
                } else {
                    collector.push(childReactElement);
                }
            }
            return collector;
        }, []);

        var childrenReactElements = [headerReactElements, footerReactElements];

        this._childrenReactElements = childrenReactElements;
        return childrenReactElements;
    }

    //== REACT-centric METHODS ==========================================//
    //# getReactClass() 
    //# if you have a main reactClass that you want associated with this ruby-component
    //# you *must* define a getReactClass() function

    //# getReactElement() 
    //# if this ruby-component is a nestable component with react elements 
    //# that you want inserted into the parent ruby-component's react element
    //# you *must* define a getReactElement() function

    //# getRouteElement()
    //# if this ruby-component manages routes with react-router instead of 
    //# reactElements, you *must* define a getRouteElement()
    //# that returns an instance of <Route component={this.getReactClass()}/>


    ,
    _routePath: undefined,
    _routeObject: undefined
    /**
     * @function getRoutePath
     *
     * @return {string} The joined path of the nearest parent route (which may include itself) and all of its ancestors
     */
    , getRoutePath: function getRoutePath() {
        if (this._routePath) {
            return this._routePath;
        }

        var routePathArr = [];
        this.iterativelyTraverseAncestors_withCallback(function (rubyComponent) {
            var currPath = rubyComponent.props.path;
            if (currPath) {
                routePathArr.unshift(currPath);
            }
        });
        var routePath = path.join.apply(path, routePathArr);

        this._routePath = routePath;
        return routePath;
    }
    /**
     * @function getRoutePathParser
     *
     * @return {instanceOf(Route)} the new Route(this.getRoutePath()) instance
     */
    ,
    getRoutePathParser: function getRoutePathParser() {
        if (this._routeObject) {
            return this._routeObject;
        }
        var routePath = this.getRoutePath();

        this._routeObject = new Route(routePath);

        return this._routeObject;
    }

    //== LIFECYCLE Methods ======================================//
    //# the lifecycle methods are called in bulk (all children components) in this order
    // , onMount: function() {}
    // , onUnmount: function() {}
    // , _onReduxInit_returnValues: [] //# might contain store-unsub function, which we need to call on
    // , onReduxInit: function(dispatch) {}
    // , onReduxTearDown: function() {} //# don't need to define this, RubyComponent will auto-wrap if there's an onReduxInit method
    // , onReactInit: function() {}
    // , onBeforeUnload: function() {} //# triggered on a tab/window unload
    ,
    triggerTree_onMount: function triggerTree_onMount() {
        var selfArguments = arguments;
        this.iterativelyTraverseChildren_withCallback(function (element) {
            if (element.onMount) {
                element.onMount.apply(element, selfArguments);
            }
        }, false);
    },
    triggerTree_onUnmount: function triggerTree_onUnmount() {
        var selfArguments = arguments;
        this.iterativelyTraverseChildren_withCallback(function (element) {
            if (element.onUnmount) {
                element.onUnmount.apply(element, selfArguments);
            }
        }, false);
    },
    triggerTree_onReduxInit: function triggerTree_onReduxInit(dispatch) {
        var selfArguments = arguments;
        this.iterativelyTraverseChildren_withCallback(function (element) {
            if (element.onReduxInit) {
                element.onReduxInit.apply(element, selfArguments);
            }
        }, false);
    },
    triggerTree_onReduxTearDown: function triggerTree_onReduxTearDown() {
        var selfArguments = arguments;
        this.iterativelyTraverseChildren_withCallback(function (element) {
            if (element.onReduxTearDown) {
                element.onReduxTearDown.apply(element);
            }
        }, false);
    },
    triggerTree_onReactInit: function triggerTree_onReactInit() {
        var selfArguments = arguments;
        this.iterativelyTraverseChildren_withCallback(function (element) {
            if (element.onReactInit) {
                element.onReactInit.apply(element, selfArguments);
            }
        }, false);
    }

    //== TEARDOWN =======================================================//
    , clearCache: function clearCache() {
        //# NOTE: only being used to clear cache in cloned elements.
        //# May need to reconsider naming if we use this for something else
        this._hydratedAction = undefined;
        this._routePath = undefined;
        this._routeObject = undefined;
    }

    //== HELPER METHODS ==============================================//

    //# sometimes we need to set stateful values in a way where 
    /**
     * set value on RubyComponent at key
     * @param {String|Array} key - key can be a keypath if you want to set a nested state.
     *  */
    , setStatefulCacheForKey: function setStatefulCacheForKey(key, value) {
        return _.set(this, ['_statefulCache'].concat(key), value);
    },
    getStatefulCacheForKey: function getStatefulCacheForKey(key) {
        return _.get(this, ['_statefulCache'].concat(key));
    },
    clearStatefulCacheForKey: function clearStatefulCacheForKey(key) {
        return _.unset(this, ['_statefulCache'].concat(key));
    }
}, _defineProperty(_baseElement, 'clearStatefulCacheForKey', function clearStatefulCacheForKey(key) {
    return _.unset(this, ['_statefulCache'].concat(key));
}), _defineProperty(_baseElement, 'clearStatefulCache', function clearStatefulCache() {
    this._statefulCache = undefined;
}), _baseElement);

module.exports = baseElement;