'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var rubyMiddlewareFields = require('@rubyapps/ruby-middleware-fields');

var fieldBaseMixin = {
    mixinName: 'rubyComponentMixinFieldValidations',
    propTypes: {
        key: PropTypes.string //# the key to use for the form
        , label: PropTypes.string,
        constraints: PropTypes.object,
        default: PropTypes.string,
        defaultToFirstOption: PropTypes.bool
    },
    getDefaultProps: function getDefaultProps() {
        return {
            verify: {}
        };
    },
    getInitialState: function getInitialState() {
        var _props = this.props,
            key = _props.key,
            label = _props.label,
            verify = _props.verify,
            defaultValue = _props.default,
            defaultToFirstOption = _props.defaultToFirstOption,
            options = _props.options;

        if (true || key) {
            //# always support this even if key is undefined
            var value = void 0;
            //# if defaultValue is true, and we have options, we default
            if (!_lodash2.default.isNil(defaultValue)) {
                value = defaultValue;
            } else if (options && options.length && defaultToFirstOption) {
                value = options[0].value;
            }

            return {
                fields: _defineProperty({}, key, _extends({
                    value: _lodash2.default.isUndefined(value) ? null : this.fieldValueFromFormValue_forKey(value, key, true)
                    //:this.coercedValue(value)
                    //# NOTE: we're not using coercedValue to coerce dataType, rather
                    //# we're using it to enforce whether it's array or not
                    , error: null,
                    label: label,
                    userModifiedTimestamp: null
                }, verify ? { constraints: verify } : {}))
            };
        }

        return {};
        //# return an empty object instead because regardless of whether field is returned,
        //# if the component needs to initialize itself with other states, it'll have to check the existence
        //# of whatever it needs to add
    },
    action: rubyMiddlewareFields.action,
    reducer: rubyMiddlewareFields.reducer,

    getFieldValue: function getFieldValue(state) {
        var localState = this.getState(state);
        return this.getFieldValueFromLocalState(localState);
    },
    getFieldValueFromLocalState: function getFieldValueFromLocalState(localState) {
        var fields = _lodash2.default.get(localState, 'fields', {});
        var fieldKey = this.props.key;
        var fieldSpec = fields[fieldKey] || {};

        return fieldSpec.value;
    },
    getConstraints_forKey_fromState: function getConstraints_forKey_fromState(key, state) {
        if (state == undefined) {
            state = this.getStore().getState();
        }
        var selfSelector = this.getDefaultSelector();
        var localState = selfSelector(state);
        var isFieldInDefaultNamespace = this.isFieldInDefaultNamespace_fromState ? this.isFieldInDefaultNamespace_fromState(state) : true;

        var fields = localState.fields;
        var fieldKey = key ? key : this.props.key;
        var fieldSpec = fields[fieldKey];
        var fieldConstraints = fieldSpec && fieldSpec.constraints;

        var filteredFieldConstraints = fieldSpec ? isFieldInDefaultNamespace ? fieldConstraints : _lodash2.default.omit(fieldConstraints, ['required']) : {};
        return filteredFieldConstraints;
    },
    validateValue_forField: function validateValue_forField(value, key) {
        var localState = this.getState();
        var fields = localState.fields;
        var fieldSpec = fields[key];
        var fieldLabel = fieldSpec ? fieldSpec.label : '';

        var errors = rubyMiddlewareFields.validateField_withConstraints(fieldLabel, key, value, fieldSpec ? fieldSpec.constraints : {});

        return errors;
    },
    validateValue_forField_againstConstraints: function validateValue_forField_againstConstraints(value, key, constraints) {
        var localState = this.getState();
        var fields = localState.fields;
        var fieldSpec = fields[key];
        var fieldLabel = fieldSpec ? fieldSpec.label : '';

        var errors = rubyMiddlewareFields.validateField_withConstraints(fieldLabel, key, value, constraints);

        return errors;
    }
};

module.exports = fieldBaseMixin;