'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _require = require('../common/constants'),
    CONTENT_FORM_KEY = _require.CONTENT_FORM_KEY;

var contentFormsMixin = {
    mixinName: 'rubyComponentMixinFormsContent',
    isContentTemplate: function isContentTemplate(template) {
        return _.get(template, 'form.key') === CONTENT_FORM_KEY;
    },
    augmentedTemplate: function augmentedTemplate(template, callingModule) {
        var templateKeyword = template.key;


        if (this.isContentTemplate(template) && this.isValidTopLevelTemplate(template)) {
            var rubyClientId = callingModule.getActiveRubyClientId && callingModule.getActiveRubyClientId();
            var subsiteId = callingModule.getActiveSubsiteId && callingModule.getActiveSubsiteId();
            template.form.hiddenValues = _extends({}, template.form.hiddenValues || {}, {
                ruby_client_fk: Number(rubyClientId) // need to make sure we pass a number (3), not a string ("3")
                , content_subsite_fk: Number(subsiteId) // need to make sure we pass a number (3), not a string ("3")
                , template_keyword: templateKeyword
            });
        }

        return template;
    }
};

module.exports = contentFormsMixin;