'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Dropdown = require('./Dropdown');

var _Dropdown2 = _interopRequireDefault(_Dropdown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var UNKNOWN = 10;
var NUMBER = 11;
var STRING = 12;

function optionValuesDataType(options) {
    var firstNonNullOptionValue = _lodash2.default.get(options.filter(function (option) {
        return option.value !== null;
    }), '0.value');

    if (!_lodash2.default.isNil(firstNonNullOptionValue)) {
        if (_lodash2.default.isString(firstNonNullOptionValue)) {
            return STRING;
        } else if (_lodash2.default.isNumber(firstNonNullOptionValue)) {
            return NUMBER;
        }
    } else {
        return UNKNOWN;
    }
}

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector,
        currentUserSelector = _selfModule$getDepend.currentUserSelector;

    var defaultNamespace = selfModule.getDefaultNamespace();

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);
        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);

        var options = _lodash2.default.get(selfState, 'props.options') || ownProps.options || [];
        var availableOptionsAsValues = options.map(function (option) {
            return option.value;
        });

        var fieldKey = mixinFieldProps.fieldKey;

        var _ref = fieldKey ? _lodash2.default.pick(selfState.fields[fieldKey], ['value', 'error']) : {},
            error = _ref.error,
            value = _ref.value;

        var activeNamespace = value;
        var errorsByNamespace_fromLocalState = _lodash2.default.reduce(selfState.savedLocalStateByNamespace, function (collector, savedLocalState, namespace) {
            if (namespace == activeNamespace) {
                return collector;
            }

            var error = _lodash2.default.get(savedLocalState, ['fields', fieldKey, 'error']);
            if (error && error.message) {
                collector[namespace] = error;
            }
            return collector;
        }, error && error.message ? _defineProperty({}, activeNamespace, error) : {});
        //# 20170518 - NOTE: deferring updating the check here for errors outside of error.message because we shouldn't have other error.<key> values 

        var errorsByNamespace_fromDeferred = _lodash2.default.reduce(_lodash2.default.get(selfState, ['deferredFormErrorByNamespace']), function (collector, deferredFormError, namespace) {
            collector[namespace] = {
                message: {
                    msg: "Please review. Some fields are invalid.",
                    level: "error"
                }
            };

            return collector;
        }, {});

        var clearErrorsByNamespace_fromDeferred = _lodash2.default.get(selfState, ['deferredClearFormErrorByNamespace'], {});

        var errorsByNamespace = _extends({}, errorsByNamespace_fromDeferred, _lodash2.default.omit(errorsByNamespace_fromLocalState, Object.keys(clearErrorsByNamespace_fromDeferred)));
        //# 20170629 - NOTE: if we have a standing order to clear errors that's deferred, we should exclude the localStateErrors from that namespace

        if (!error || !error.message) {
            error = errorsByNamespace[Object.keys(errorsByNamespace)[0]];
        }

        var valueNormalizedAsArray = Array.isArray(value) ? value : [value];

        var isArray = _lodash2.default.isArray(_lodash2.default.get(ownProps, 'data_type'));

        //# coercing the input values
        var optionValueDataType = optionValuesDataType(options);
        var coercedValues = void 0;
        if (optionValueDataType == STRING) {
            coercedValues = valueNormalizedAsArray.map(function (value) {
                return value == null ? value : value + '';
            });
        } else if (optionValueDataType == NUMBER) {
            coercedValues = valueNormalizedAsArray.map(function (value) {
                return value == null ? value : parseInt(value);
            });
        } else {
            coercedValues = valueNormalizedAsArray;
        }

        //# some values cannot be found in the set of options, we we filter them out;
        var filteredValues = coercedValues.filter(function (value) {
            return availableOptionsAsValues.indexOf(value) != -1;
        });

        return _extends({}, ownProps, {
            isArray: isArray
        }, mixinFieldProps, _extends({}, selfState.props), {
            error: error,
            errorsByNamespace: errorsByNamespace,
            value: filteredValues,
            defaultNamespace: defaultNamespace,
            options: mixinFieldProps.required ? options : [{ value: null, text: '\xA0' }].concat(options)
        });
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Dropdown2.default);
}