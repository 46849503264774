'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var Promise = require('bluebird');
var path = require('path');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

var React = require('react');
var PropTypes = React.PropTypes;

var _ = require('lodash');
var routeReducer = require('./reducer');
var routeMiddleware = require('./middleware');
var routeActionGenerator = require('./action');
var jsonselect = require('JSONSelect');
//#displayName: "Param"

var paramSelector = jsonselect.compile('object:has(:root > .displayName:val("Param"))');
var routeMixin = {
    propTypes: {
        defaultRouteParams: PropTypes.shape({
            template: PropTypes.string
        }),
        validateRouteParams: PropTypes.func,
        rerouteBasedOnBestUrl: PropTypes.bool,
        waitForDependenciesToResolve: PropTypes.bool
    },
    getDefaultProps: function getDefaultProps() {
        return {
            defaultRouteParams: {},
            validateRouteParams: function validateRouteParams(params) {
                //# `this` is valid
                return true;
            }
        };
    },
    getInitialState: function getInitialState() {
        return {
            routeParams: {
                routeActive: false
            }
        };
    },
    action: routeActionGenerator,
    reducer: routeReducer,
    middleware: routeMiddleware

    //# routeComponents can override and return false
    //# which I think the material edit page needs to do
    //# because it needs to check if the template is correct
    , getUrlUsingParams: function getUrlUsingParams(params) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var _options$matchedParam = options.matchedParamsValidator,
            matchedParamsValidator = _options$matchedParam === undefined ? _.stubTrue : _options$matchedParam;


        var paramsWithDefault = _extends({}, this.props.defaultRouteParams, params);

        var validRouteParams = this.props.validateRouteParams.call(this, paramsWithDefault);
        if (!validRouteParams) {
            return;
        }

        var routePathParser = this.getRoutePathParser();
        var url = routePathParser.reverse(paramsWithDefault);

        if (url) {
            var matchedParams = routePathParser.match(url);
            var validMatchedParams = _.iteratee(matchedParamsValidator)(matchedParams);
            return validMatchedParams ? url : undefined;
        }

        return url;
    },
    getRouteParamKeys: function getRouteParamKeys() {
        var routePathParser = this.getRoutePathParser();
        var ast = routePathParser.ast;


        var paramKeys = paramSelector.match(ast).map(function (paramSpec) {
            return paramSpec.props.name;
        });

        return paramKeys;
    },
    areDependenciesResolved: function areDependenciesResolved() {
        var dependencies = this.getDependencies();
        var resolved = _.reduce(dependencies, function (collector, dependency, key) {
            if (!(_.isObject(dependency) && dependency._rubyComponent && dependency.hasOwnProperty('isResolved'))) {
                return collector;
            }

            var dependency__isResolved = dependency.isResolved();

            logger.trace('Checking resolution for:', key, dependency__isResolved);

            return collector && dependency__isResolved;
        }, true);

        return resolved;
    }

    //# TODO: refactor this to support nested isResolved() calls
    //# Eg. index-redirect relies on header, which relies on website
    //# and it's website that needs ot resolve asynchronously
    //# currently we're also injecting the website dependency into the index-redirect component
    , promiseForDependenciesResolution: function promiseForDependenciesResolution() {
        var _this = this;

        var store = this.getStore();
        return new Promise(function (resolve, reject) {
            if (_this.props.waitForDependenciesToResolve) {
                //# TODO: we might want to default this to true for all routeComponents
                var areDependenciesResolved = _this.areDependenciesResolved();

                if (areDependenciesResolved) {
                    resolve(true);
                } else {
                    logger.trace('Dependencies are not resolved for ' + _this.getID() + '. Need to wait.');

                    var unsub = store.subscribe(function () {
                        var areDependenciesResolved = _this.areDependenciesResolved();
                        if (areDependenciesResolved) {
                            logger.trace('Dependencies are resolved for ' + _this.getID() + '.');
                            unsub();
                            resolve(true);
                        }
                    });
                }
            } else {
                resolve(true);
            }
        });
    }
};

module.exports = routeMixin;