'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getSortedColumns = getSortedColumns;
exports.getChildren = getChildren;
exports.filteredGetChildren = filteredGetChildren;
exports.flattenNestedDataBy = flattenNestedDataBy;
function getSortedColumns(data, columns, childrenPropertyName) {
    //# ~44ms
    return data.map(function (row, index) {
        var children = row.get(childrenPropertyName || 'children');

        if (children && children.size > 0) {
            children = getSortedColumns(row.get('children'), columns);
        }

        return row.sortBy(function (val, key) {
            return columns.indexOf(key);
        }).set('children', children);
    });
}

function getChildren(row) {
    var rowId = row.get('id');
    var hasChildren = row.getIn(['__metadata', 'hasChildren'], row.get('hasChildren'));
    return hasChildren ? row.get('children') : null;
}

function filteredGetChildren(filter) {
    return function (row) {
        var rowId = row.get('id');
        var hasChildren = row.getIn(['__metadata', 'hasChildren'], row.get('hasChildren'));
        return hasChildren ? row.get('children').filter(filter) : null;
    };
}

function flattenNestedDataBy(data, expandFunc) {
    if (!data) return [];

    return data.reduce(function (acc, row) {
        var children = flattenNestedDataBy(expandFunc(row), expandFunc);

        return acc.concat([row], children);
    }, []);
}