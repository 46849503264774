"use strict";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

/* jshint -W138 */

var defaultState = {
    pristineFormData: null,
    pristineErrors: null //# might not need
    , forceUpdateTimestamp: null
};

module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];
    var TYPES = this.getDependencies().selfAction.TYPES;
    var type = action.type,
        payload = action.payload;

    switch (type) {
        case TYPES.SEED_CHILDREN:
            return state;
        case TYPES.SET_PRISTINE_FORM_DATA:
            return _extends({}, state, {
                pristineFormData: payload.formData
            });
        case TYPES.SET_FORCE_UPDATE_TIMESTAMP:
            return _extends({}, state, {
                forceUpdateTimestamp: payload.timestamp
            });
        case TYPES.RESET_STORE:
            //# NOTE: we cannot set pristineFormData here because reset store is only given the state 
            //# for self, not the children
            //# and this might be part of a batch call so we cannot just call this.getState();

            return this.getInitialState();
        default:
            return state;
    }
};