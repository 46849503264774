'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = getBioUpdatesMenuItemsForSelfModule;
//# TODO: see if we can pass the icon component instead of the string
function getBioUpdatesMenuItemsForSelfModule(selfModule) {
    var _selfModule$getForms = selfModule.getForms(),
        globalDataForm = _selfModule$getForms.global_data;

    var _selfModule$getFronte = selfModule.getFrontendSettings(),
        globalDataInstanceId = _selfModule$getFronte.GLOBAL_DATA_ID;

    var globalDataTemplateId = globalDataForm ? globalDataForm.id : null;

    return globalDataForm ? {
        name: 'Global Data (old)',
        icon: 'ruby/icons/content/global-data',
        permissions: [{
            model: 'content',
            template: globalDataTemplateId,
            ruby_client: globalDataForm.ruby_client_fk,
            subsite: 1,
            action: '*' }],
        route: '/app/content/en/global_data/' + globalDataInstanceId + '/edit'
    } : null;
}