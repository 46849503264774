'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var PropTypes = _react2.default.PropTypes;

var Image = _react2.default.createClass({
    displayName: 'Image',

    propTypes: {
        src: PropTypes.string,
        fallbackSrc: PropTypes.string
    },

    getInitialState: function getInitialState() {
        return { failed: false };
    },

    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        if (this.props.src !== nextProps.src) {
            return this.setState({ failed: false });
        }
    },

    fallback: function fallback() {
        if (this.props.fallbackSrc) {
            this.setState({ failed: true });
        }
    },

    render: function render() {
        var _props = this.props,
            src = _props.src,
            fallbackSrc = _props.fallbackSrc,
            rest = _objectWithoutProperties(_props, ['src', 'fallbackSrc']);

        if (this.state.failed) {
            var _React$createElement;

            return _react2.default.createElement('img', (_React$createElement = { src: fallbackSrc, 'data-codecept-selector-node': 'img',
                'data-codecept-selector-file': 'Image'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'img'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Image'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'img'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Image'), _React$createElement));
        } else {
            var _extends2;

            return _react2.default.createElement('img', _extends({ src: src, onError: this.fallback }, rest, (_extends2 = {
                'data-codecept-selector-node': 'img',
                'data-codecept-selector-file': 'Image'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'img'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Image'), _defineProperty(_extends2, 'data-codecept-selector-node', 'img'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Image'), _extends2)));
        }
    }
});

exports.default = Image;