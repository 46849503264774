"use strict";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

module.exports = function (state, action) {
    var _getAction = this.getAction(),
        TYPES = _getAction.TYPES;

    var type = action.type,
        payload = action.payload;


    if (state == undefined) {
        state = this.getInitialState();
    }

    switch (type) {
        case TYPES.REPLACE_LOCAL_STATE:
            return _extends({}, state, payload.localState);
        case TYPES.SET_PROPS:
            return _extends({}, state, {
                props: _extends({}, state.props, payload.props)
            });
        case TYPES.SET_COMPONENT_IS_MOUNTED:
            return _extends({}, state, {
                isMounted: true
            });
        case TYPES.UPDATE_DISPLAY_VALUE_SPECS:
            return _extends({}, state, payload || {});
        case TYPES.UPDATE_RERENDER_TIMESTAMP:
            return _extends({}, state, {
                rerenderTimestamp: action.payload.timestamp
            });
        default:
            return state;
    }
};