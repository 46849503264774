'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _ = require('lodash');
var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;
var memoize = require('memoizee');

var REQUEST_TYPE_SUGGESTED_OPTIONS_RETRIEVAL = 'requestType_suggestedOptionsRetrieval';

var getMissingOptions_fromNeeded_andActual = memoize(_getMissingOptions_fromNeeded_andActual, { length: 2 });
function _getMissingOptions_fromNeeded_andActual(needed, actual) {
    return _.differenceWith(needed, actual, _.isEqual);
}

module.exports = {
    propTypes: {
        suggestedValues: PropTypes.arrayOf(PropTypes.oneOfType(PropTypes.number, PropTypes.string))
    },
    action: function action() {
        var id = this.getID();

        var TYPES = {
            SET_SUGGESTED_OPTIONS: '@ruby-app/' + id + '/SET_SUGGESTED_OPTIONS'
        };

        return {
            TYPES: TYPES,
            generators: {
                setSuggestedOptions: function setSuggestedOptions(suggestedOptions) {
                    return {
                        type: TYPES.SET_SUGGESTED_OPTIONS,
                        payload: suggestedOptions
                    };
                }
            }
        };
    },
    reducer: function reducer(state, action) {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        var type = action.type,
            payload = action.payload;

        switch (type) {
            case TYPES.SET_SUGGESTED_OPTIONS:
                return _extends({}, state, {
                    suggestedOptions: payload
                });
            default:
                return state;
        }
    },
    getSuggestedOptionsIfShould: function getSuggestedOptionsIfShould() {
        var _this = this;

        var selfModule = this;
        var suggestedValues = selfModule.getProps(true).suggestedValues;
        if (_.isEmpty(suggestedValues)) {
            return;
        }
        var url = selfModule.url();

        var suggestedOptionsRequestParams = {
            url: url,
            suggestedValues: suggestedValues
        };

        if (!_.isEqual(suggestedOptionsRequestParams, selfModule._prev_suggestedOptionsRequestParams)) {
            selfModule._prev_suggestedOptionsRequestParams = suggestedOptionsRequestParams;

            var suggestedValues_normalized = _.flow(_.castArray, function (arrValue) {
                return _.flatMap(arrValue, function (item) {
                    return _this.coercedValue(item);
                });
            })(suggestedValues);

            selfModule.getStore().dispatch(this.getAction().generators.setSuggestedOptions([]));

            var actions = selfModule.getAction().generators;
            return selfModule.getStore().dispatch(actions.retrieveOptionsFromUrl_withQuery(this.url(), { id: suggestedValues_normalized }, null, true //# allowAbort
            , false //# clearCache
            , REQUEST_TYPE_SUGGESTED_OPTIONS_RETRIEVAL)).then(function (data) {
                selfModule.getStore().dispatch(_this.getAction().generators.setSuggestedOptions(data));
            });
        }
    },
    addSuggestedOptions_toCache: function addSuggestedOptions_toCache() {
        var selfState = this.getState();
        var options = _.get(selfState, 'props.options') || this.props.options || [];
        var suggestedOptions = _.get(selfState, 'suggestedOptions');

        var missingOptions = getMissingOptions_fromNeeded_andActual(suggestedOptions, options);

        if (missingOptions.length) {
            this.getStore().dispatch(this.getAction().generators.updateCachedOptions_withAdditional(suggestedOptions));
        }
    },
    onReduxInit: function onReduxInit() {

        var selfModule = this;
        var store = selfModule.getStore();

        var _debounced_getSuggestedOptionsIfShould = _.throttle(selfModule.getSuggestedOptionsIfShould.bind(selfModule), 1000);

        return store.subscribe(function () {
            _debounced_getSuggestedOptionsIfShould();
            selfModule.addSuggestedOptions_toCache();
        });
    }
};