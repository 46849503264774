'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styleHelper = require('./utils/styleHelper');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Column = function (_React$Component) {
  _inherits(Column, _React$Component);

  function Column(props, context) {
    _classCallCheck(this, Column);

    var _this = _possibleConstructorReturn(this, (Column.__proto__ || Object.getPrototypeOf(Column)).call(this, props, context));

    _this._getStyles = function () {

      var columnPropertiesWithResize = _this.props.renderedSizeProperties;

      var style = _this.props.styles.getStyle({
        styles: _this.props.styles.inlineStyles,
        styleName: 'column',
        //todo: make this nicer
        mergeStyles: _extends({}, _this.props.width || _this.props.alignment || _this.props.styles ? _extends({
          width: _lodash2.default.get(columnPropertiesWithResize, [_this.props.dataKey, 'width']) || null,
          textAlign: _this.props.alignment
        }) : {}, _this.props.style, _this.props.columnStyle)
      });

      return style;
    };

    _this._handleClick = function (e) {
      if (_this.props.onClick) _this.props.onClick(e);

      _this.props.events.columnClick(_this.props.dataKey, _this.props.value, _this.props.rowIndex, _this.props.rowData);
    };

    _this._handleHover = function (e) {
      _this.props.events.columnHover(_this.props.dataKey, _this.props.value, _this.props.rowIndex, _this.props.rowData);
    };

    return _this;
  }

  _createClass(Column, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      //TODO: this is temporary -- we'll need to merge styles or something
      //  why not use the getStyle from defaultStyles?
      var styles = this._getStyles();

      var _getStyleProperties = (0, _styleHelper.getStyleProperties)(this.props, 'column'),
          className = _getStyleProperties.className;

      var classNames = (0, _classnames2.default)(className, this.props.cssClassName);

      var normalizedValueAsArr = _lodash2.default.isArray(this.props.value) ? this.props.value : [this.props.value];

      var childrenElements = void 0;
      if (this.props.hasOwnProperty('customComponent')) {
        if (this.props.customComponent.hasOwnProperty('reduce')) {
          childrenElements = this.props.customComponent.reduce(normalizedValueAsArr);
        } else {
          if (this.props.mapMode == 'reduce') {
            var arrayLength = normalizedValueAsArr.length;
            var reduced__childrenElements = normalizedValueAsArr.reduce(function (collector, value, index) {
              collector.push(_react2.default.createElement(_this2.props.customComponent, {
                key: _this2.props.dataKey + '-' + index,
                events: _this2.props.events,
                data: value,
                rowData: _this2.props.rowData,
                originalData: _this2.props.originalRowData,
                rowIndex: _this2.props.rowIndex,
                absoluteRowIndex: _this2.props.absoluteRowIndex,
                extraData: _this2.props.extraData,
                hoveredRow: _this2.props.hoveredRow,
                'data-codecept-selector-file': 'column'
              }));
              if (index < arrayLength - 1) {
                collector.push(', ');
              }

              return collector;
            }, []);

            //# NOTE: we need to wrap this with a div because of the parent's styles (which includes display:flex)
            childrenElements = _react2.default.createElement(
              'div',
              {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'column'
              },
              reduced__childrenElements
            );
          } else {
            childrenElements = [_react2.default.createElement(this.props.customComponent, {
              key: this.props.dataKey + '-0',
              events: this.props.events,
              data: this.props.value,
              rowData: this.props.rowData,
              originalData: this.props.originalRowData,
              rowIndex: this.props.rowIndex,
              absoluteRowIndex: this.props.absoluteRowIndex,
              extraData: this.props.extraData,
              hoveredRow: this.props.hoveredRow,
              'data-codecept-selector-file': 'column'
            })];
          }
        }
      } else if (_lodash2.default.isObject(normalizedValueAsArr[0])) {
        childrenElements = normalizedValueAsArr;
      } else {
        childrenElements = normalizedValueAsArr.join(', ');
      }

      return _react2.default.createElement(
        'div',
        {
          style: styles,
          key: this.props.dataKey + '-div-wrapper',
          onClick: this._handleClick,
          onMouseOver: this._handleHover,
          className: classNames + ' td', 'data-codecept-selector-node': 'div',
          'data-codecept-selector-file': 'column'
        },
        childrenElements
      );
    }

    //TODO: Figure out a way to get this hooked up with the normal styles methods
    //maybe a merge styles property or something along those lines

  }]);

  return Column;
}(_react2.default.Component);

Column.defaultProps = {
  columnProperties: {
    cssClassName: ''
  },
  mapMode: 'reduce' //# 'reduce', '' //# empty if was want to pass through the array and handle it ourselves
};

Column.propTypes = {
  alignment: _react2.default.PropTypes.oneOf(['left', 'right', 'center']),
  columnHover: _react2.default.PropTypes.func,
  columnClick: _react2.default.PropTypes.func
};

exports.default = Column;