'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _transitions = require('material-ui/styles/transitions');

var _transitions2 = _interopRequireDefault(_transitions);

var _Card = require('material-ui/Card');

var _Card2 = require('@rubyapps/ruby-material-ui/Card');

var _Popover = require('material-ui/Popover');

var _Popover2 = _interopRequireDefault(_Popover);

var _Menu = require('material-ui/Menu');

var _Menu2 = _interopRequireDefault(_Menu);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _expandMore = require('material-ui/svg-icons/navigation/expand-more');

var _expandMore2 = _interopRequireDefault(_expandMore);

var _expandLess = require('material-ui/svg-icons/navigation/expand-less');

var _expandLess2 = _interopRequireDefault(_expandLess);

var _Fieldset_style = require('./Fieldset_style');

var _Fieldset_style2 = _interopRequireDefault(_Fieldset_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

//# Material UI

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label,
    RichErrorMessages = _require.RichErrorMessages;

var Label__propTypeKeys = Object.keys(Label.propTypes);

//# Icons


function _getBoxSizeForElement(element) {
    var style = window.getComputedStyle(element, null);
    var width = parseInt(style.getPropertyValue('width'));
    var height = parseInt(style.getPropertyValue('height'));
    return {
        width: isNaN(width) ? 0 : width,
        height: isNaN(height) ? 0 : height
    };
}

var getStyles = function getStyles(props, context, state) {
    var _context$muiTheme = context.muiTheme,
        baseTheme = _context$muiTheme.baseTheme,
        _context$muiTheme$tex = _context$muiTheme.textField,
        floatingLabelColor = _context$muiTheme$tex.floatingLabelColor,
        focusColor = _context$muiTheme$tex.focusColor,
        textColor = _context$muiTheme$tex.textColor,
        disabledTextColor = _context$muiTheme$tex.disabledTextColor,
        backgroundColor = _context$muiTheme$tex.backgroundColor,
        hintColor = _context$muiTheme$tex.hintColor,
        errorColor = _context$muiTheme$tex.errorColor;


    var styles = {
        error: {
            position: 'relative',
            bottom: -6,
            fontSize: 12,
            lineHeight: '12px',
            color: errorColor,
            transition: _transitions2.default.easeOut()
        },
        CardHeader: {
            backgroundColor: props.descendentsHaveValues ? 'rgb(196, 212, 218)' : 'rgb(232, 232, 232)',
            transition: _transitions2.default.easeOut()
        }
    };

    _extends(styles.error, props.errorStyle);

    return styles;
};

var Fieldset = _react2.default.createClass({
    displayName: 'Fieldset',

    propTypes: {
        label: PropTypes.string,
        isExpanded: PropTypes.bool,
        styleObject: PropTypes.object,
        actionButtons: PropTypes.node,
        childProps: PropTypes.object,
        leftIcon: PropTypes.node
        /*
            {
                Card: 'className'
                , CardHeader: 'className'
            }
        */
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            childProps: {
                Card: {},
                CardHeader: {},
                CardText: {}
            }
        };
    },
    getInitialState: function getInitialState() {
        return {
            contextIsOpen: false,
            contextAnchorEl: null
        };
    },
    _handleOnPress: function _handleOnPress(event) {
        //event.srcEvent.stopImmediatePropagation && event.srcEvent.stopImmediatePropagation();
        //event.srcEvent.stopPropagation && event.srcEvent.stopPropagation();

        this.setState({
            contextIsOpen: true,
            contextAnchorEl: event.target
        });
    },
    _handleExpandAll: function _handleExpandAll() {
        this._handleRequestClose();
        this.props.actions.expandAll();
    },
    _handleCollapseAll: function _handleCollapseAll() {
        this._handleRequestClose();
        this.props.actions.collapseAll();
    },
    _handleRequestClose: function _handleRequestClose() {
        this.setState({
            contextIsOpen: false
        });
    },
    _handleExpandChange: function _handleExpandChange(isExpanded) {
        if (isExpanded) {
            this.props.actions.expand();
        } else {
            this.props.actions.collapse();
        }
    },
    render: function render() {
        var _React$createElement, _extends2, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6, _React$createElement7, _extends3, _extends4, _extends5;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        //# NOTE: do not use 'actAsExpander' for now
        //# with the long-press, stop propagation doesn't work with the injected 'onTouchTap'
        //# I think we'll have to wrap the header element with <Hammer> and use hammer instead
        var mergedStyleObject = _extends({}, _Fieldset_style2.default, this.props.styleObject); //# shallow merge
        var prepareStyles = this.context.muiTheme.prepareStyles;

        var muiStyleObject = getStyles(this.props, this.context, this.state);

        var errorTextElement = this.props.richErrorMessages ? _react2.default.createElement(RichErrorMessages, (_React$createElement = { messages: this.props.richErrorMessages, 'data-codecept-selector-node': 'RichErrorMessages',
            'data-codecept-selector-file': 'Fieldset'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Fieldset'), _React$createElement)) : null;

        var labelProps = _.pick(this.props, Label__propTypeKeys);
        var renderedLabel = _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
            'data-codecept-selector-node': 'Label',
            'data-codecept-selector-file': 'Fieldset'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Fieldset'), _extends2)));

        var _props$childProps = this.props.childProps,
            _props$childProps$Car = _props$childProps.Card,
            cardProps = _props$childProps$Car === undefined ? {} : _props$childProps$Car,
            _props$childProps$Car2 = _props$childProps.CardHeader,
            cardHeaderProps = _props$childProps$Car2 === undefined ? {} : _props$childProps$Car2,
            _props$childProps$Car3 = _props$childProps.CardText,
            cardTextProps = _props$childProps$Car3 === undefined ? {} : _props$childProps$Car3;

        return _react2.default.createElement(
            _Card2.Card,
            _extends({}, mergedStyleObject.Card, {
                expanded: this.props.isExpanded,
                onExpandingPress: this._handleOnPress,
                onExpandChange: this._handleExpandChange
            }, cardProps, (_extends5 = {
                className: 'Card ' + (cardProps.className || ''),
                'data-codecept-selector-node': 'Card',
                'data-codecept-selector-file': 'Fieldset'
            }, _defineProperty(_extends5, 'data-codecept-selector-node', 'Card'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_extends5, 'data-codecept-selector-node', 'Card'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Fieldset'), _extends5)),
            _react2.default.createElement(
                _Card2.CardHeader,
                _extends({ title: renderedLabel,
                    subtitle: errorTextElement,
                    actAsExpander: true,
                    showExpandableButton: true,
                    style: muiStyleObject.CardHeader,
                    actionButtons: this.props.actionButtons,
                    avatar: this.props.leftIcon
                }, cardHeaderProps, (_extends3 = {
                    className: 'CardHeader ' + (cardHeaderProps.className || ''),
                    'data-codecept-selector-node': 'CardHeader',
                    'data-codecept-selector-file': 'Fieldset'
                }, _defineProperty(_extends3, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_extends3, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Fieldset'), _extends3)),
                _react2.default.createElement(
                    _Popover2.default,
                    (_React$createElement7 = {
                        open: this.state.contextIsOpen,
                        anchorEl: this.state.contextAnchorEl,
                        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                        targetOrigin: { horizontal: 'right', vertical: 'top' },
                        onRequestClose: this._handleRequestClose,
                        'data-codecept-selector-node': 'Popover',
                        'data-codecept-selector-file': 'Fieldset'
                    }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'Popover'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'Popover'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Fieldset'), _React$createElement7),
                    _react2.default.createElement(
                        _Menu2.default,
                        (_React$createElement6 = {
                            'data-codecept-selector-node': 'Menu',
                            'data-codecept-selector-file': 'Fieldset'
                        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'Menu'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'Menu'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Fieldset'), _React$createElement6),
                        _react2.default.createElement(_MenuItem2.default, (_React$createElement3 = {
                            leftIcon: _react2.default.createElement(_expandLess2.default, (_React$createElement2 = {
                                'data-codecept-selector-node': 'ExpandLessIcon',
                                'data-codecept-selector-file': 'Fieldset'
                            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'ExpandLessIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'ExpandLessIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Fieldset'), _React$createElement2)),
                            primaryText: 'Collapse All',
                            onTouchTap: this._handleCollapseAll,
                            'data-codecept-selector-node': 'MenuItem',
                            'data-codecept-selector-file': 'Fieldset'
                        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Fieldset'), _React$createElement3)),
                        _react2.default.createElement(_MenuItem2.default, (_React$createElement5 = {
                            leftIcon: _react2.default.createElement(_expandMore2.default, (_React$createElement4 = {
                                'data-codecept-selector-node': 'ExpandMoreIcon',
                                'data-codecept-selector-file': 'Fieldset'
                            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ExpandMoreIcon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ExpandMoreIcon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Fieldset'), _React$createElement4)),
                            primaryText: 'Expand All',
                            onTouchTap: this._handleExpandAll,
                            'data-codecept-selector-node': 'MenuItem',
                            'data-codecept-selector-file': 'Fieldset'
                        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Fieldset'), _React$createElement5))
                    )
                )
            ),
            _react2.default.createElement(
                _Card.CardText,
                _extends({}, mergedStyleObject.CardText, cardTextProps, (_extends4 = {
                    className: 'CardText ' + (cardTextProps.className || ''),
                    expandable: true,
                    style: {
                        paddingLeft: 32,
                        paddingRight: 32,
                        paddingBottom: 30
                    },
                    'data-codecept-selector-node': 'CardText',
                    'data-codecept-selector-file': 'Fieldset'
                }, _defineProperty(_extends4, 'data-codecept-selector-node', 'CardText'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Fieldset'), _defineProperty(_extends4, 'data-codecept-selector-node', 'CardText'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Fieldset'), _extends4)),
                this.props.children
            )
        );
    },

    componentWillUnmount: function componentWillUnmount() {
        //console.log('[componentWillUnmount()]', this.props.id);
        this.props.componentWillUnmount && this.props.componentWillUnmount();
    }
});

exports.default = Fieldset;