'use strict';

var beautify = require('js-beautify');

function beautifyHtml(html) {
    return beautify.html(html, {
        preserve_newlines: false
    });
}

module.exports = {
    sanitizeHTML: function sanitizeHTML(html) {
        return html.replace(/<\/script/g, '<\\/script').replace(/<!--/g, '<\\!--');
    },
    beautifyHtml: beautifyHtml
};