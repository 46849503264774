'use strict';

function typesWithID(id) {
    return {
        SET_PASSWORD_DISPLAY: '@@ruby-app/' + id + '/SET_PASSWORD_DISPLAY'
    };
}

var generators = {
    showPassword: function showPassword() {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        return {
            type: TYPES.SET_PASSWORD_DISPLAY,
            payload: { showPassword: true }
        };
    },
    hidePassword: function hidePassword() {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES;

        return {
            type: TYPES.SET_PASSWORD_DISPLAY,
            payload: { showPassword: false }
        };
    },
    togglePasswordDisplay: function togglePasswordDisplay() {
        var _getAction3 = this.getAction(),
            generators = _getAction3.generators;

        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector;

        return function (dispatch, getState) {
            var selfState = selfSelector(getState());
            if (selfState.showPassword) dispatch(generators.hidePassword());else dispatch(generators.showPassword());
        };
    }
};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};