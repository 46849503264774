'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _AppBar = require('material-ui/AppBar');

var _AppBar2 = _interopRequireDefault(_AppBar);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _close = require('material-ui/svg-icons/navigation/close');

var _close2 = _interopRequireDefault(_close);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var DialogHeader = function DialogHeader(props) {
    var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4;

    return _react2.default.createElement(_AppBar2.default, (_React$createElement4 = {
        title: _react2.default.createElement(
            'span',
            (_React$createElement = {
                'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'DialogHeader'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'DialogHeader'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'DialogHeader'), _React$createElement),
            props.title
        ),
        iconElementLeft: _react2.default.createElement(
            _IconButton2.default,
            (_React$createElement3 = {
                onClick: props.delegateClose,
                'data-codecept-selector-node': 'IconButton',
                'data-codecept-selector-file': 'DialogHeader'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'DialogHeader'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'DialogHeader'), _React$createElement3),
            _react2.default.createElement(_close2.default, (_React$createElement2 = {
                'data-codecept-selector-node': 'NavigationClose',
                'data-codecept-selector-file': 'DialogHeader'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'NavigationClose'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'DialogHeader'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'NavigationClose'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'DialogHeader'), _React$createElement2))
        ),
        'data-codecept-selector-node': 'AppBar',
        'data-codecept-selector-file': 'DialogHeader'
    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'DialogHeader'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'DialogHeader'), _React$createElement4));
};

DialogHeader.propTypes = {
    title: PropTypes.string.isRequired,
    delegateClose: PropTypes.func.isRequired
};

exports.default = DialogHeader;