'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styleHelper = require('./utils/styleHelper');

var _index = require('@rubyapps/react-resizable-box/src/index');

var _index2 = _interopRequireDefault(_index);

var _Tooltip = require('material-ui/internal/Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

//http://stackoverflow.com/questions/30347722/importing-css-files-in-isomorphic-react-components
//import styles from './table-heading-cell.css';
var styles = process.browser ? require('./table-heading-cell.cssModule').default : {};

var WrappedTH = function (_React$Component) {
  _inherits(WrappedTH, _React$Component);

  function WrappedTH() {
    _classCallCheck(this, WrappedTH);

    return _possibleConstructorReturn(this, (WrappedTH.__proto__ || Object.getPrototypeOf(WrappedTH)).apply(this, arguments));
  }

  _createClass(WrappedTH, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var thProps = _lodash2.default.omit(this.props, ['maxWidth', 'minWidth', 'plugins']);
      return _react2.default.createElement(
        'th',
        _extends({
          ref: function ref(th) {
            if (!th) {
              return;
            }
            _this2.nativeElement = th;
          }
        }, thProps, {
          'data-codecept-selector-node': 'th',
          'data-codecept-selector-file': 'table-heading-cell'
        }),
        this.props.children
      );
    }
  }]);

  return WrappedTH;
}(_react2.default.Component);

var ResizableTH = (0, _index2.default)(WrappedTH);
var resizablePropKeys = Object.keys(ResizableTH.propTypes);

/*
 * Column Properties
 *  {
 *      key: 'key'
 *      name: 'displayName'
 *      sortable: false //# true by default
 *      resizable: false //# true by default
 *      width: <int>
 *      maxWidth: <int>
 *      minWidth: <int> 
 *      {...Resizable props}
 *  }
 *
 * */

var TableHeadingCell = function (_React$Component2) {
  _inherits(TableHeadingCell, _React$Component2);

  function TableHeadingCell(props, context) {
    _classCallCheck(this, TableHeadingCell);

    var _this3 = _possibleConstructorReturn(this, (TableHeadingCell.__proto__ || Object.getPrototypeOf(TableHeadingCell)).call(this, props, context));

    _this3._handleClick = _this3._handleClick.bind(_this3);
    _this3._handleHover = _this3._handleHover.bind(_this3);
    _this3._handleMouseLeave = _this3._handleMouseLeave.bind(_this3);
    _this3._onResizeStart = _this3._onResizeStart.bind(_this3);
    _this3._onResizeStop = _this3._onResizeStop.bind(_this3);
    _this3._onResize = _this3._onResize.bind(_this3);
    _this3.state = {
      hoveredTooltip: false
    };
    return _this3;
  }

  _createClass(TableHeadingCell, [{
    key: 'getSortIcon',
    value: function getSortIcon() {
      var _props = this.props,
          sorted = _props.sorted,
          sortAscending = _props.sortAscending,
          icons = _props.icons;


      if (sorted && this.isSortable()) {
        return sortAscending ? icons.sortAscending : icons.sortDescending;
      }
    }
  }, {
    key: 'headerStyle',
    value: function headerStyle() {
      var _props2 = this.props,
          column = _props2.column,
          renderProperties = _props2.renderProperties;

      var columnProperties = renderProperties.columnProperties[column];

      if (columnProperties && columnProperties.hasOwnProperty('headerStyle')) {
        return columnProperties.headerStyle;
      }
    }
  }, {
    key: 'isSortable',
    value: function isSortable() {
      //# sortable by default
      var _props3 = this.props,
          column = _props3.column,
          renderProperties = _props3.renderProperties;

      var columnProperties = renderProperties.columnProperties[column];

      if (columnProperties && columnProperties.hasOwnProperty('sortable') && columnProperties.sortable === false) {
        return false;
      }

      return true;
    }
  }, {
    key: 'isResizable',
    value: function isResizable() {
      //# resizable by default
      var _props4 = this.props,
          column = _props4.column,
          renderProperties = _props4.renderProperties;

      var columnProperties = renderProperties.columnProperties[column];
      if (columnProperties && columnProperties.hasOwnProperty('resizable') && columnProperties.resizable === false) {
        return false;
      }
      if (this.isLastColumn() || this.isSecondToLastColumn()) {
        return false;
      }
      return true;
    }
  }, {
    key: 'resizableColumnProps',
    value: function resizableColumnProps() {
      var columnProperties = this.props.columnProperty;
      return _lodash2.default.pick(columnProperties, resizablePropKeys);
    }
  }, {
    key: '_visibleColumnKeys',
    value: function _visibleColumnKeys() {
      var renderProperties = this.props.renderProperties;
      var columns = Object.keys(renderProperties.columnProperties);
      var ignoredColumns = renderProperties.ignoredColumns;
      return _lodash2.default.difference(columns, ignoredColumns);
    }
  }, {
    key: 'isSecondToLastColumn',
    value: function isSecondToLastColumn() {
      var column = this.props.column;

      var visibleColumnKeys = this._visibleColumnKeys();
      var columnOrder = this.props.columns;
      var visibleColumnOrder = _lodash2.default.intersection(columnOrder, visibleColumnKeys);

      if (visibleColumnOrder[visibleColumnOrder.length - 2] == column) {
        return true;
      }
      return false;
    }
  }, {
    key: 'isLastColumn',
    value: function isLastColumn() {
      var column = this.props.column;

      var visibleColumnKeys = this._visibleColumnKeys();
      var columnOrder = this.props.columns;
      var visibleColumnOrder = _lodash2.default.intersection(columnOrder, visibleColumnKeys);

      if (_lodash2.default.last(visibleColumnOrder) == column) {
        return true;
      }
      return false;
    }
  }, {
    key: 'render',
    value: function render() {
      var availableFlexWidth = this.props.availableFlexWidth;
      var widthFromRatio = undefined; //this.props.columnProperty.widthRatio * availableFlexWidth;
      var style = this.props.styles.getStyle({
        styles: this.props.styles.inlineStyles,
        styleName: 'columnTitle',
        mergeStyles: _extends({
          width: this.props.columnProperty.width }, this.props.alignment || this.props.headerAlignment ? { textAlign: this.props.headerAlignment || this.props.alignment } : {}, {
          boxSizing: 'border-box'
        }, this.props.style)
      });

      var finalStyle = this.isLastColumn() ? _lodash2.default.omit(style, ['width']) : style;

      var isSortable = this.isSortable();

      var _getStyleProperties = (0, _styleHelper.getStyleProperties)(this.props, 'tableHeadingCell'),
          className = _getStyleProperties.className;

      var classNames = (0, _classnames2.default)(className, this.props.columnProperty ? this.props.columnProperty.headerCssClassName : null, isSortable ? styles.sortableCell : null);
      var sorted = this.props.sorted;

      var clickEvent = isSortable ? this._handleClick : null;

      var resizableColumnProps = _extends({}, this.resizableColumnProps(), widthFromRatio ? {
        width: widthFromRatio
      } : {});

      var breadcrumb = this.props.columnProperty.breadcrumb;


      if (this.isResizable()) {
        return _react2.default.createElement(
          ResizableTH,
          _extends({
            key: this.props.column,
            style: finalStyle,
            onMouseEnter: this._handleHover,
            onMouseLeave: this._handleMouseLeave,
            className: classNames,
            isResizable: {
              top: false, right: true, bottom: false, left: false,
              topRight: false, bottomRight: false, bottomLeft: false, topLeft: false
            },
            onResizeStart: this._onResizeStart,
            onResizeStop: this._onResizeStop,
            onResize: this._onResize
          }, resizableColumnProps, {
            'data-codecept-selector-node': 'ResizableTH',
            'data-codecept-selector-file': 'table-heading-cell'
          }),
          _react2.default.createElement(
            'div',
            { onClick: clickEvent, 'data-codecept-selector-node': 'div',
              'data-codecept-selector-file': 'table-heading-cell'
            },
            this.props.customHeaderComponent ? _react2.default.createElement(this.props.customHeaderComponent, _extends({}, this.props, {
              'data-codecept-selector-file': 'table-heading-cell'
            })) : this.props.title,
            ' ',
            this.getSortIcon(),
            breadcrumb && _react2.default.createElement(_Tooltip2.default, {
              show: this.state && this.state.hoveredTooltip,
              label: breadcrumb,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              'data-codecept-selector-node': 'Tooltip',
              'data-codecept-selector-file': 'table-heading-cell'
            })
          )
        );
      }
      return _react2.default.createElement(
        WrappedTH,
        {
          plugins: this.props.plugins,
          key: this.props.column,
          style: finalStyle,
          onMouseEnter: this._handleHover,
          onMouseLeave: this._handleMouseLeave,
          className: classNames,
          'data-codecept-selector-node': 'WrappedTH',
          'data-codecept-selector-file': 'table-heading-cell'
        },
        _react2.default.createElement(
          'div',
          { onClick: clickEvent, style: this.headerStyle(), 'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'table-heading-cell'
          },
          this.props.customHeaderComponent ? _react2.default.createElement(this.props.customHeaderComponent, _extends({}, this.props, {
            'data-codecept-selector-file': 'table-heading-cell'
          })) : this.props.title,
          ' ',
          this.getSortIcon()
        )
      );
    }
  }, {
    key: '_onResizeStart',
    value: function _onResizeStart(direction, styleSize, clientSize, delta) {
      this.props.delegateResizeStart(this.props.column, styleSize, delta);
    }
  }, {
    key: '_onResizeStop',
    value: function _onResizeStop(direction, styleSize, clientSize, delta) {
      this.props.delegateResizeStop(this.props.column, styleSize, delta);
      //this.props.events.setColumnProperties(this.props.column, {width: styleSize.width});
    }
  }, {
    key: '_onResize',
    value: function _onResize(direction, styleSize, clientSize, delta) {
      this.props.delegateResize(this.props.column, styleSize, delta);
    }
  }, {
    key: '_handleHover',
    value: function _handleHover() {
      this.setState({ hoveredTooltip: true });
      this.props.headingHover(this.props.column);
    }
  }, {
    key: '_handleMouseLeave',
    value: function _handleMouseLeave() {
      this.setState({ hoveredTooltip: false });
    }
  }, {
    key: '_handleClick',
    value: function _handleClick() {
      this.props.headingClick(this.props.column);
    }
  }]);

  return TableHeadingCell;
}(_react2.default.Component);

TableHeadingCell.propTypes = {
  headingHover: _react2.default.PropTypes.func,
  headingClick: _react2.default.PropTypes.func,
  column: _react2.default.PropTypes.string,
  headerAlignment: _react2.default.PropTypes.oneOf(['left', 'right', 'center']),
  alignment: _react2.default.PropTypes.oneOf(['left', 'right', 'center']),
  sortAscending: _react2.default.PropTypes.bool,
  sorted: _react2.default.PropTypes.bool,
  //# all of the props form the Resizable module
  delegateResize: _react2.default.PropTypes.func,
  delegateResizeStart: _react2.default.PropTypes.func,
  delegateResizeStop: _react2.default.PropTypes.func,
  availableFlexWidth: _react2.default.PropTypes.number
};

exports.default = TableHeadingCell;