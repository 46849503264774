'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _FloatingActionButton = require('material-ui/FloatingActionButton');

var _FloatingActionButton2 = _interopRequireDefault(_FloatingActionButton);

var _add = require('material-ui/svg-icons/content/add');

var _add2 = _interopRequireDefault(_add);

var _IconMenu = require('material-ui/IconMenu');

var _IconMenu2 = _interopRequireDefault(_IconMenu);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var PropTypes = React.PropTypes;

var FloatingPlusMenu = React.createClass({
    displayName: 'FloatingPlusMenu',

    propTypes: {
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
            text: PropTypes.string,
            type: PropTypes.string
        })),
        actions: PropTypes.shape({
            appendItemOfType: PropTypes.func
        }),
        disabled: PropTypes.bool,
        unique: PropTypes.bool,
        childrenOrderById: PropTypes.array,
        childrenIdToTypeMap: PropTypes.object
    },
    _handleItemAdd: function _handleItemAdd() {
        var type = this.props.options[0].type;
        this.props.actions.appendItemOfType && this.props.actions.appendItemOfType(type);
    },
    _handleMenuItemSelection: function _handleMenuItemSelection(type, event) {
        this.props.actions.appendItemOfType && this.props.actions.appendItemOfType(type);
    },
    _getOptions: function _getOptions() {
        var _this = this;

        if (this.props.unique) {
            var usedTypesArr = this.props.childrenOrderById.map(function (id) {
                return _this.props.childrenIdToTypeMap[id];
            });

            //# filter out the options
            return this.props.options.filter(function (option) {
                return usedTypesArr.indexOf(option.type) < 0;
            });
        } else {
            return this.props.options;
        }
    },
    render: function render() {
        var _this2 = this;

        var optionsLength = this.props.options.length;

        if (optionsLength < 2) {
            var _React$createElement, _React$createElement2;

            return React.createElement(
                _FloatingActionButton2.default,
                (_React$createElement2 = { onTouchTap: this._handleItemAdd, disabled: this.props.disabled, 'data-codecept-selector-node': 'FloatingActionButton',
                    'data-codecept-selector-file': 'FloatingPlusMenu'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'FloatingActionButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'FloatingPlusMenu'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'FloatingActionButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'FloatingPlusMenu'), _React$createElement2),
                React.createElement(_add2.default, (_React$createElement = {
                    'data-codecept-selector-node': 'ContentAdd',
                    'data-codecept-selector-file': 'FloatingPlusMenu'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ContentAdd'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FloatingPlusMenu'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ContentAdd'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FloatingPlusMenu'), _React$createElement))
            );
        } else {
            var _React$createElement4, _React$createElement5, _extends2;

            var menuItems = this.props.options.map(function (option) {
                var _React$createElement3;

                return React.createElement(_MenuItem2.default, (_React$createElement3 = {
                    primaryText: option.text,
                    value: option.type,
                    onTouchTap: function onTouchTap(event) {
                        return _this2._handleMenuItemSelection(option.type, event);
                    },
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'FloatingPlusMenu'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'FloatingPlusMenu'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'FloatingPlusMenu'), _React$createElement3));
            });
            return React.createElement(
                _IconMenu2.default,
                _extends({}, this.props, (_extends2 = {
                    iconButtonElement: React.createElement(
                        _FloatingActionButton2.default,
                        (_React$createElement5 = { disabled: this.props.disabled, 'data-codecept-selector-node': 'FloatingActionButton',
                            'data-codecept-selector-file': 'FloatingPlusMenu'
                        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'FloatingActionButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'FloatingPlusMenu'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'FloatingActionButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'FloatingPlusMenu'), _React$createElement5),
                        React.createElement(_add2.default, (_React$createElement4 = {
                            'data-codecept-selector-node': 'ContentAdd',
                            'data-codecept-selector-file': 'FloatingPlusMenu'
                        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ContentAdd'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'FloatingPlusMenu'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ContentAdd'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'FloatingPlusMenu'), _React$createElement4))
                    ),
                    anchorOrigin: { horizontal: 'right', vertical: 'top' },
                    targetOrigin: { horizontal: 'right', vertical: 'bottom' },
                    'data-codecept-selector-node': 'IconMenu',
                    'data-codecept-selector-file': 'FloatingPlusMenu'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FloatingPlusMenu'), _defineProperty(_extends2, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FloatingPlusMenu'), _extends2)),
                menuItems
            );
        }
    }
});

exports.default = FloatingPlusMenu;