'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _TextFieldLabel = require('material-ui/TextField/TextFieldLabel');

var _TextFieldLabel2 = _interopRequireDefault(_TextFieldLabel);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label;

var InfoComponent = _react2.default.createClass({
    displayName: 'InfoComponent',

    propTypes: {
        displayValueSpecs: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string
        }))
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    render: function render() {
        var _this = this,
            _React$createElement5;

        var displayValueSpecs = _lodash2.default.get(this, 'props.displayValueSpecs') || [];
        return _react2.default.createElement(
            'div',
            (_React$createElement5 = {
                style: {
                    direction: 'ltr'
                },
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'InfoComponent'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'InfoComponent'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'InfoComponent'), _React$createElement5),
            displayValueSpecs.map(function (_ref) {
                var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4;

                var label = _ref.label,
                    value = _ref.value;
                return !_lodash2.default.isEmpty(value) && _react2.default.createElement(
                    'div',
                    (_React$createElement4 = {
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'InfoComponent'
                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'InfoComponent'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'InfoComponent'), _React$createElement4),
                    _react2.default.createElement(
                        _TextFieldLabel2.default,
                        (_React$createElement2 = {
                            muiTheme: _this.context.muiTheme,
                            style: {
                                color: _lodash2.default.get(_this, 'context.muiTheme.palette.floatingLabelColor'),
                                pointerEvents: 'none',
                                display: 'block',
                                position: 'unset',
                                lineHeight: 'unset',
                                fontSize: 10.5
                            },
                            'data-codecept-selector-node': 'TextFieldLabel',
                            'data-codecept-selector-file': 'InfoComponent'
                        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'TextFieldLabel'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'InfoComponent'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'TextFieldLabel'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'InfoComponent'), _React$createElement2),
                        _react2.default.createElement(Label, (_React$createElement = { label: label, 'data-codecept-selector-node': 'Label',
                            'data-codecept-selector-file': 'InfoComponent'
                        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'InfoComponent'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'InfoComponent'), _React$createElement))
                    ),
                    _react2.default.createElement(
                        'div',
                        (_React$createElement3 = {
                            style: {
                                fontSize: 13,
                                lineHeight: 1.5
                            },
                            'data-codecept-selector-node': 'div',
                            'data-codecept-selector-file': 'InfoComponent'
                        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'InfoComponent'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'InfoComponent'), _React$createElement3),
                        _lodash2.default.castArray(value).join(', ')
                    )
                );
            })
        );
    }
});

exports.default = InfoComponent;