"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _colors = require("@rubyapps/ruby-styles/src/client/styleObjects/colors");

var Colors = _interopRequireWildcard(_colors);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var headerHeight = 44;

var styles = {
    Header: {
        height: headerHeight
    },
    Paper: {
        backgroundColor: Colors.headerGrey
    },
    Toolbar: {
        height: headerHeight
    },
    ToolbarGroup: {
        "alignItems": "center"
    },
    MenuButton: {
        marginTop: -4,
        marginRight: -6
    }
};

exports.default = styles;