'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _dropdown = require('@rubyapps/ruby-styles/src/client/styleObjects/dropdown');

var _dropdown2 = _interopRequireDefault(_dropdown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    SelectField_fullWidth: _extends({}, _dropdown2.default, {
        style: { width: '100%' }
    }),
    SelectField_autoWidth: _extends({}, _dropdown2.default, {
        style: { width: 'auto', minWidth: 100 }
    })
};

exports.default = styles;