'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _FilteredTopPagesStats = require('./FilteredTopPagesStats');

var _FilteredTopPagesStats2 = _interopRequireDefault(_FilteredTopPagesStats);

var _WeeklyStats = require('./WeeklyStats');

var _WeeklyStats2 = _interopRequireDefault(_WeeklyStats);

var _PieChart = require('./PieChart');

var _PieChart2 = _interopRequireDefault(_PieChart);

var _Table = require('./Table');

var _Table2 = _interopRequireDefault(_Table);

var _SingleStat = require('./SingleStat');

var _SingleStat2 = _interopRequireDefault(_SingleStat);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var _require = require('@rubyapps/react-flexbox-grid'),
    Grid = _require.Grid,
    Row = _require.Row,
    Col = _require.Col;

var widgetsByType = {
    MultiLineChart: _FilteredTopPagesStats2.default,
    LineChart: _WeeklyStats2.default,
    PieChart: _PieChart2.default,
    Table: _Table2.default,
    SingleStat: _SingleStat2.default
};

var DashboardAnalytics = _react2.default.createClass({
    displayName: 'DashboardAnalytics',

    propTypes: {
        label: PropTypes.string,
        id: PropTypes.string,
        analyticsData: PropTypes.object,
        filterSpecs: PropTypes.array,
        widgetSpecs: PropTypes.array,
        host: PropTypes.string
    },
    render: function render() {
        var _React$createElement,
            _this = this,
            _React$createElement2,
            _React$createElement3;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        return _react2.default.createElement(
            Grid,
            (_React$createElement3 = { fluid: true, id: this.props.id, 'data-codecept-selector-node': 'Grid',
                'data-codecept-selector-file': 'DashboardAnalytics'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Grid'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'DashboardAnalytics'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Grid'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'DashboardAnalytics'), _React$createElement3),
            _react2.default.createElement(
                Row,
                (_React$createElement2 = {
                    'data-codecept-selector-node': 'Row',
                    'data-codecept-selector-file': 'DashboardAnalytics'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Row'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'DashboardAnalytics'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Row'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'DashboardAnalytics'), _React$createElement2),
                _react2.default.createElement(
                    Col,
                    (_React$createElement = {
                        style: {
                            paddingLeft: 8,
                            paddingRight: 8,
                            marginBottom: -16,
                            width: "100%"
                        },
                        'data-codecept-selector-node': 'Col',
                        'data-codecept-selector-file': 'DashboardAnalytics'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Col'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'DashboardAnalytics'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Col'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'DashboardAnalytics'), _React$createElement),
                    this.props.children
                ),
                this.props.analyticsData && _.get(this, 'props.widgetSpecs', []).map(function (spec) {
                    var _extends2, _extends3;

                    var Widget = widgetsByType[spec.type];
                    var data = _this.props.analyticsData[spec.key];
                    return data && _react2.default.createElement(
                        Col,
                        _extends({}, spec.colProps, (_extends3 = {
                            style: {
                                paddingLeft: 8,
                                paddingRight: 8,
                                marginTop: 16
                            }, 'data-codecept-selector-node': 'Col',
                            'data-codecept-selector-file': 'DashboardAnalytics'
                        }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Col'), _defineProperty(_extends3, 'data-codecept-selector-file', 'DashboardAnalytics'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Col'), _defineProperty(_extends3, 'data-codecept-selector-file', 'DashboardAnalytics'), _extends3)),
                        _react2.default.createElement(Widget, _extends({}, spec, (_extends2 = {
                            data: _this.props.analyticsData[spec.key],
                            host: _this.props.host,
                            onValueClick: spec.onValueClick,
                            'data-codecept-selector-node': 'Widget',
                            'data-codecept-selector-file': 'DashboardAnalytics'
                        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Widget'), _defineProperty(_extends2, 'data-codecept-selector-file', 'DashboardAnalytics'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Widget'), _defineProperty(_extends2, 'data-codecept-selector-file', 'DashboardAnalytics'), _extends2)))
                    );
                })
            )
        );
    }
});

exports.default = DashboardAnalytics;