'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = IntegerFormatterGenerator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function IntegerFormatterGenerator(options, parentProps) {

    return _react2.default.createClass({
        render: function render() {
            var _React$createElement;

            var data = _lodash2.default.get(this, 'props.data');
            var value = Number.isInteger(data) ? data : '';

            return _react2.default.createElement(
                'div',
                (_React$createElement = {
                    style: {
                        float: 'right',
                        paddingRight: 10
                    },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'IntegerFormatterGenerator'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'IntegerFormatterGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'IntegerFormatterGenerator'), _React$createElement),
                value.toLocaleString()
            );
        }
    });
}