'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _visibility = require('material-ui/svg-icons/action/visibility');

var _visibility2 = _interopRequireDefault(_visibility);

var _visibilityOff = require('material-ui/svg-icons/action/visibility-off');

var _visibilityOff2 = _interopRequireDefault(_visibilityOff);

var _PasswordInfo = require('./PasswordInfo');

var _PasswordInfo2 = _interopRequireDefault(_PasswordInfo);

var _Password_style = require('./Password_style');

var _Password_style2 = _interopRequireDefault(_Password_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label,
    RichErrorMessages = _require.RichErrorMessages;

var Password = _react2.default.createClass({
    displayName: 'Password',

    propTypes: {
        label: PropTypes.string,
        value: PropTypes.string,
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        id: PropTypes.string,
        actions: PropTypes.object,
        excludeWrapper: PropTypes.bool,
        props: PropTypes.shape({
            TextField: PropTypes.object
        }),
        autoComplete: PropTypes.string //# NOTE: React expects camelcase (https://reactjs.org/docs/dom-elements.html#all-supported-html-attributes)
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null,
            props: {
                TextField: {}
            }
        };
    },
    _onPasswordChange: function _onPasswordChange(event) {
        this.props.actions.setFieldValueByKey(event.target.value, this.props.fieldKey);
        if (this.props.pairedField && this.props.pairedComponentAction) {
            this.props.pairedComponentAction.setFieldErrorMessageByKey(undefined, this.props.pairedField);
            this.props.delegateRefreshParentErrors();
        }
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _extends2, _extends3, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6, _React$createElement7;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }
        var _context$muiTheme$raw = this.context.muiTheme.rawTheme.palette,
            errorColor_foreground = _context$muiTheme$raw.errorColor_foreground,
            warningColor_foreground = _context$muiTheme$raw.warningColor_foreground,
            infoColor_foreground = _context$muiTheme$raw.infoColor_foreground;


        var colorByLevel = {
            error: errorColor_foreground,
            warning: warningColor_foreground,
            info: infoColor_foreground
        };

        var labelProps = _lodash2.default.pick(this.props, Object.keys(Label.propTypes));
        var errorTextElement = !_lodash2.default.isEmpty(this.props.richErrorMessages) ? _react2.default.createElement(RichErrorMessages, (_React$createElement = { messages: this.props.richErrorMessages, 'data-codecept-selector-node': 'RichErrorMessages',
            'data-codecept-selector-file': 'Password'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Password'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Password'), _React$createElement)) : null;
        var _props = this.props,
            actions = _props.actions,
            showPassword = _props.showPassword,
            showStrength = _props.showStrength,
            showInfo = _props.showInfo,
            showVisibilityToggle = _props.showVisibilityToggle,
            value = _props.value;


        var inputProps = _lodash2.default.pick(this.props, ['autocomplete', 'autoComplete']);

        var richError__marginBottom = 10 * this.props.richErrorMessages.reduce(function (collector, message) {
            return collector + _lodash2.default.castArray(message.msg).length;
        }, 0);

        //# https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
        var inputComponent = _react2.default.createElement(
            'div',
            (_React$createElement7 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Password'
            }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Password'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Password'), _React$createElement7),
            showInfo ? _react2.default.createElement(_PasswordInfo2.default, (_React$createElement2 = {
                'data-codecept-selector-node': 'PasswordInfo',
                'data-codecept-selector-file': 'Password'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'PasswordInfo'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Password'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'PasswordInfo'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Password'), _React$createElement2)) : null,
            _react2.default.createElement(
                'div',
                (_React$createElement6 = { style: { display: 'flex', marginBottom: richError__marginBottom }, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Password'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Password'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Password'), _React$createElement6),
                _react2.default.createElement(_TextField2.default, _extends({}, this.props.props.TextField, _Password_style2.default.PasswordField, {
                    floatingLabelText: _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                        'data-codecept-selector-node': 'Label',
                        'data-codecept-selector-file': 'Password'
                    }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Password'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Password'), _extends2))),
                    id: this.props.id,
                    onChange: this._onPasswordChange,
                    value: value || '',
                    errorText: errorTextElement,
                    disabled: this.props.locked,
                    type: showPassword ? "text" : "password"
                }, inputProps, (_extends3 = {
                    errorStyle: {
                        color: colorByLevel[_lodash2.default.get(this, 'props.richErrorMessages[0].level')]
                    },
                    'data-codecept-selector-node': 'TextField',
                    'data-codecept-selector-file': 'Password'
                }, _defineProperty(_extends3, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Password'), _defineProperty(_extends3, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Password'), _extends3))),
                showVisibilityToggle ? _react2.default.createElement(
                    _IconButton2.default,
                    (_React$createElement5 = {
                        tooltip: showPassword ? "Hide Password" : "Show Password",
                        style: _Password_style2.default.PasswordButton,
                        onTouchTap: function onTouchTap(e) {
                            return actions.togglePasswordDisplay();
                        },
                        'data-codecept-selector-node': 'IconButton',
                        'data-codecept-selector-file': 'Password'
                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Password'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Password'), _React$createElement5),
                    showPassword ? _react2.default.createElement(_visibility2.default, (_React$createElement3 = {
                        'data-codecept-selector-node': 'VisibilityIcon',
                        'data-codecept-selector-file': 'Password'
                    }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'VisibilityIcon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Password'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'VisibilityIcon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Password'), _React$createElement3)) : _react2.default.createElement(_visibilityOff2.default, (_React$createElement4 = {
                        'data-codecept-selector-node': 'VisibilityOffIcon',
                        'data-codecept-selector-file': 'Password'
                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'VisibilityOffIcon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Password'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'VisibilityOffIcon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Password'), _React$createElement4))
                ) : null
            )
        );

        return this.props.renderGridWrapper(_extends({}, this.props, { children: inputComponent }));
    }
});

exports.default = Password;