'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = getStructuredDataMenuItemsForSelfModule;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RubyWords = require('@rubyapps/ruby-words');
var inflection = RubyWords.inflection;

//# TODO: see if we can pass the icon component instead of the string
var structuredDataTemplateType = 'structured_data_template';
var experienceManagerComponentId = 'rubyComponentPluginExperienceManager';
var pluginProposalComponentId = 'rubyComponentPluginProposal';

function getStructuredDataMenuItemsForSelfModule(selfModule) {
    var experienceManagerComponent = selfModule.getRoot().findDescendentByID(experienceManagerComponentId);
    var pluginProposalComponent = selfModule.getRoot().findDescendentByID(pluginProposalComponentId);

    var templateKeysToOmit = ['global_data', 'rubyapps_settings'].concat(experienceManagerComponent ? ['matter', 'matter_client'] : [], pluginProposalComponent ? ['proposal', 'proposal_content'] : []);

    var forms = selfModule.getForms();

    var structuredDataForms = _lodash2.default.map(_lodash2.default.omit(forms, templateKeysToOmit), function (form) {
        return form;
    }).filter(function (form) {
        return form.templateType == structuredDataTemplateType;
    });
    structuredDataForms.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        } else if (nameA > nameB) {
            return 1;
        } else {
            return 0;
        }
    });

    var structuredDataMenuItems = structuredDataForms.map(function (form) {
        return {
            name: inflection.pluralize(form.name),
            icon: form.icon || 'material-ui/svgIcons/ActionViewList',
            permissions: [{
                model: 'content',
                template: '' + form.id,
                ruby_client: form.ruby_client_fk,
                subsite: 1 //# TODO
                , action: 'get'
            }],
            route: '/app/content/en/' + form.key + '/list'
        };
    });

    return structuredDataMenuItems;
}