'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };
//# NOTE: use this if you're writing a field component


exports.default = generator;

var _reactRedux = require('react-redux');

var _ConnectorMappersGenerator = require('@rubyapps/ruby-component-mixin-field-base/src/client/reactComponents/ConnectorMappersGenerator');

var _ConnectorMappersGenerator2 = _interopRequireDefault(_ConnectorMappersGenerator);

var _RubyComponentFieldDynamicForm = require('./RubyComponentFieldDynamicForm');

var _RubyComponentFieldDynamicForm2 = _interopRequireDefault(_RubyComponentFieldDynamicForm);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {
    var selfModule = this;
    var selfSelector = this.getDefaultSelector();
    //# NOTE: use this if you're writing a field component

    var _defaultMappersGenera = _ConnectorMappersGenerator2.default.call(this),
        defaultMapStateToProps = _defaultMappersGenera.mapStateToProps,
        defaultMapDispatchToProps = _defaultMappersGenera.mapDispatchToProps;

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);
        var pickedSelfState = _.pick(selfState, ['rerenderTimestamp']);

        return _extends({}, defaultMapStateToProps.apply(null, arguments), pickedSelfState, {
            children: ownProps.getMergedChildrenReactElements ? ownProps.getMergedChildrenReactElements() : ownProps.children
        });
    }

    /*
    function mapDispatchToProps (dispatch, ownProps) {
        return Object.assign({
            delegateActions: {
                mediaGallery: bindActionCreators(mediaGalleryComponent.getAction().generators, dispatch)
            }
        }, defaultMapDispatchToProps.apply(null, arguments));
    }
     function mapStateToProps(state, ownProps) {
        return Object.assign({}, ownProps, selfModule.getState(state));
    }
     function mapDispatchToProps (dispatch, ownProps) {
        return {
            actions: bindActionCreators(selfModule.getAction().generators)
        };
    }
    */

    return (0, _reactRedux.connect)(mapStateToProps, defaultMapDispatchToProps)(_RubyComponentFieldDynamicForm2.default);
}