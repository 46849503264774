'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.formValueFromLocalState = formValueFromLocalState;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _serverTransforms = require('./serverTransforms');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var rubyWords = require('@rubyapps/ruby-words');

var TYPE_KEY = 'componentName';
var rubyComponent = require('@rubyapps/ruby-component');

function formValueFromLocalState(selfState, isError) {
    if (selfState == undefined) {
        return undefined;
    }

    var propertiesWithoutPermissions_byType = this.propertiesWithoutPermissions_byType();
    var propertiesWithoutPermissions_byKey = this.propertiesWithoutPermissions_byKey();
    var coreProperties_byInstanceType = this.coreProperties_byInstanceType();
    var Components = require('@rubyapps/ruby-component-builder').Components;

    var formValue = _lodash2.default.reduce(selfState.fields, function (collector, fieldObject, key) {
        //# iterate the instances,
        //# iterate the properties in the instances
        //
        var value = _lodash2.default.get(fieldObject, 'value');

        var usedComponentNames = _lodash2.default.uniq(value.childIds.map(function (id) {
            return value.instances[id];
        }).map(function (instance) {
            return _lodash2.default.get(instance, 'instanceProps.componentName.value') || _lodash2.default.get(instance, 'instanceProps.instanceType');
        }));
        var supportedPropsByComponent = usedComponentNames.reduce(function (result, componentName) {
            var comp = Components[componentName];
            return _extends(result, _defineProperty({}, componentName, !comp ? [] : _lodash2.default.flow(function (comp) {
                return rubyComponent.createElement(comp, { props: {} });
            }, function (element) {
                return element.propTypes;
            }, Object.keys)(comp)));
        }, {});

        var newInstances = _lodash2.default.mapValues(value.instances, function (instance) {
            var componentName = _lodash2.default.get(instance, 'instanceProps.componentName.value');
            var instanceType = _lodash2.default.get(instance, 'instanceProps.instanceType');
            var coreProperties_byKey = coreProperties_byInstanceType[instanceType];

            var coreProperties = _lodash2.default.values(coreProperties_byKey);
            var componentProperties = propertiesWithoutPermissions_byType[componentName];

            var properties = coreProperties_byKey || componentProperties ? [].concat(componentProperties || [], coreProperties || []) : undefined;

            var instancePropUpdates = (properties || []).reduce(function (result, property) {
                var propKey = property.propKey,
                    type = property.type;


                var currValue = _lodash2.default.get(instance, ['instanceProps', propKey, 'value']);

                var newPropValue = _lodash2.default.flow(function applyDefault(value) {
                    return value == null ? property.defaultValue : value;
                }, function applyDefaultToProp(value) {
                    if (!_lodash2.default.isNil(value) || !property.defaultToProp) {
                        return value;
                    } else {
                        var referencedProperty = propertiesWithoutPermissions_byKey[property.defaultToProp];
                        return rubyWords.keyify(_lodash2.default.get(instance, ['instanceProps', referencedProperty.propKey, 'value']) || referencedProperty.defaultValue);
                    }
                }, function parseNum(value) {
                    return property.type === 'number' && !isNaN(value) ? parseFloat(value) : value;
                }, function applyTransform(value) {
                    // "transform" can dynamically change the property value before converting to formJS.
                    // We needed this for the token tagger "data_type" property, which could be:
                    //   - "number", when the "limit" property is set to 1
                    //   - ["number"], when the "limit" property is set to anything else
                    // The function, if defined, is passed:
                    //   - the current value for the property as determined by the code above
                    //   - the property definition
                    //   - the field instance (as a normal JS object)
                    return property.transform ? property.transform(value, property, instance) : value;
                })(currValue);

                return _extends(result, currValue !== newPropValue && _defineProperty({}, propKey, { value: newPropValue }));
            }, {});

            var definedInstancePropKeys = properties && [].concat(properties.map(function (propertyDef) {
                return propertyDef.propKey;
            }), Object.keys(coreProperties_byKey), 'instanceType');

            var formComponentName = componentName || instanceType;
            var additionalSupportedProps = supportedPropsByComponent[formComponentName];

            return _extends({}, instance, {
                instanceProps: _lodash2.default.pickBy(_extends({}, instance.instanceProps, instancePropUpdates), function (value, propKey) {
                    return !properties || _lodash2.default.includes(definedInstancePropKeys, propKey) || _lodash2.default.includes(additionalSupportedProps, propKey);
                })
            });
        });

        collector[key] = (0, _serverTransforms.convertFieldEditorState_toFormJS)(_extends(value, { instances: newInstances }));
        return collector;
    }, {});

    return formValue;
}