'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _serverTransforms = require('./serverTransforms');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var Validator = require('@rubyapps/ruby-validator-wrapper');
var rubyWords = require('@rubyapps/ruby-words');

var TEMPLATE_CONSTANTS = require('@rubyapps/ruby-component-plugin-template-editor/src/common/constants');

var fieldTypesThatDontAutopopulate = {
    Repeater: true
};

var JSON_INDENTATION_SPACES = 4;

var customValidationsByKey = {
    'unique': function unique(_ref) {
        var value = _ref.value,
            key = _ref.key,
            instances = _ref.instances,
            shouldBeUnique = _ref.stateParams,
            currInstance = _ref.currInstance,
            selfModule = _ref.selfModule;

        if (shouldBeUnique) {

            // perform post-order traverse to check if duplicate keys exists
            // returned value is an array of children's keys from the current node
            var formDFS = function formDFS(node) {
                // leaf node
                if (!node.children) {
                    return node[key] ? [node[key]] : [];
                }

                // collect all the children's keys from the current node
                // if there are duplicates, it means it's not unique
                var childrenKeys = [];
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = node.children[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var child = _step.value;

                        var childKeys = formDFS(child);
                        childrenKeys.push.apply(childrenKeys, _toConsumableArray(childKeys));
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                if (_lodash2.default.uniq(childrenKeys).length !== childrenKeys.length) {
                    hasDuplicateKey = true;
                }
                // console.log(`at node, componentNane: ${node.componentName}, hasDuplicateKey ${hasDuplicateKey}, childrenKeys`, childrenKeys);

                // if the node has key, return it. otherwise bubble up the keys from children
                return node[key] ? [node[key]] : childrenKeys;
            };

            var currState = selfModule.getState();
            var copiedFields = _lodash2.default.cloneDeep(currState.fields);
            var copiedCurrInstanceProps = _lodash2.default.get(copiedFields, ['children', 'value', 'instances', currInstance.id, 'instanceProps']);
            copiedCurrInstanceProps[key] = _extends({}, copiedCurrInstanceProps[key], {
                value: value
            });

            var updatedState = _extends({}, currState, {
                fields: copiedFields
            });

            var updatedForm = selfModule.formValueFromLocalState(updatedState);
            var hasDuplicateKey = false;

            formDFS(updatedForm);
            return !hasDuplicateKey;
        }
        return true;
    },
    'api_id_alphanumeric': function api_id_alphanumeric(_ref2) {
        var value = _ref2.value,
            key = _ref2.key,
            instances = _ref2.instances,
            shouldBeAlphanumeric = _ref2.stateParams,
            selfModule = _ref2.selfModule;

        if (shouldBeAlphanumeric) {
            return (/^[a-zA-Z0-9_]*$/.test(value)
            );
        } else {
            return true;
        }
    },
    'profileRepeater_templateType': function profileRepeater_templateType(_ref3) {
        var value = _ref3.value,
            key = _ref3.key,
            instances = _ref3.instances,
            shouldCheckTemplateType = _ref3.stateParams,
            selfModule = _ref3.selfModule;

        var templateTypeComponent = selfModule.findAncestorBy(function (el) {
            return el.componentName === 'rubyComponentFieldForm';
        }).findDescendentBy(function (el) {
            return el.props.key === 'templateType';
        });

        var _ref4 = templateTypeComponent.formValue() || {},
            templateType = _ref4.templateType;

        if (shouldCheckTemplateType && value) {
            return _lodash2.default.includes(TEMPLATE_CONSTANTS.TOP_LEVEL_TEMPLATE_TYPES, templateType);
        } else {
            return true;
        }
    },
    'contentEndpoint_requiresTemplateSelections': function contentEndpoint_requiresTemplateSelections(_ref5) {
        var value = _ref5.value;

        if (/^\/ruby\/api\/v2\/content\/options\?ruby_client_fk=\d+&content_subsite_fk=\d+$/.test(value)) {
            return false;
        } else {
            return true;
        }
    }
};

var customValidationErrorsByKey = {
    'unique': function unique(params) {
        return params.label + ' must be unique.';
    },
    'api_id_alphanumeric': function api_id_alphanumeric(params) {
        return params.label + ' must contain only these characters: A-Z a-z 0-9 _ -';
    },
    'profileRepeater_templateType': function profileRepeater_templateType(_ref6) {
        var selfModule = _ref6.selfModule;

        var templateTypeComponent = selfModule.findAncestorBy(function (el) {
            return el.componentName === 'rubyComponentFieldForm';
        }).findDescendentBy(function (el) {
            return el.props.key === 'templateType';
        });

        var _ref7 = templateTypeComponent.formValue() || {},
            templateType = _ref7.templateType;

        var templateTypeName = TEMPLATE_CONSTANTS.TEMPLATE_TYPE_OPTIONS.find(function (option) {
            return option.value === templateType;
        }).text;
        return 'A ' + templateTypeName + ' may not use Profile Modules.';
    },
    'contentEndpoint_requiresTemplateSelections': function contentEndpoint_requiresTemplateSelections() {
        return 'At least one template selection is required.';
    }
};

function _customValidateField_forConstraintKey(fieldKey, fieldValue, constraintKey, constraintsObj, instances, selfModule, currInstance) {
    var stateParams = constraintsObj.params || constraintsObj;
    var constraintParams = {
        value: fieldValue,
        key: fieldKey,
        instances: instances,
        stateParams: stateParams,
        selfModule: selfModule,
        currInstance: currInstance
    };
    var properties_byKey = selfModule.propertiesWithoutPermissions_byKey();

    var isOK = customValidationsByKey[constraintKey].call(null, constraintParams);
    if (!isOK) {
        var errorMessage = customValidationErrorsByKey[constraintKey]({
            label: properties_byKey[fieldKey].label,
            selfModule: selfModule
        });

        return {
            message: errorMessage
        };
    }
}

function _validateField_withConstraints(fieldKey, fieldValue) {
    var constraints = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var instances = arguments[3];
    var selfModule = arguments[4];
    var currInstance = arguments[5];

    var ruleKeys = Object.keys(constraints);
    var properties_byKey = selfModule.propertiesWithoutPermissions_byKey();

    var errorObjects = ruleKeys.reduce(function (collector, ruleKey) {
        if (constraints[ruleKey] === null) {
            return collector;
        }
        // Check for validator function
        else if (customValidationsByKey.hasOwnProperty(ruleKey)) {
                var customErrorObj = _customValidateField_forConstraintKey(fieldKey, fieldValue, ruleKey, constraints[ruleKey], instances, selfModule, currInstance);
                if (customErrorObj) {
                    collector.push(customErrorObj);
                }
                return collector;
            } else if (!Validator[ruleKey]) {
                throw "Invalid validator function: " + ruleKey;
            }

        var stateParams = constraints[ruleKey].params || constraints[ruleKey];
        var constraintParams = [fieldValue].concat(stateParams ? stateParams : []);

        var isOK = Validator[ruleKey].apply(null, constraintParams);
        if (!isOK) {
            var errorMessage = Validator.getErrorMessage({
                label: properties_byKey[fieldKey].label,
                constraints: constraints
            }, ruleKey);

            collector.push({
                message: errorMessage
            });
        }
        return collector;
    }, []);

    return errorObjects;
}

module.exports = function () {
    var id = this.getID();
    var TYPES = {
        SET_FIELD_CARD_PROPERTY: '@ruby-app/' + id + '/SET_FIELD_CARD_PROPERTY',
        SET_FIELD_PROPERTY: '@ruby-app/' + id + '/SET_FIELD_PROPERTY',
        ADD_CARD: '@ruby-app/' + id + '/ADD_CARD',
        DUPLICATE_CARD: '@ruby-app/' + id + '/DUPLICATE_CARD',
        DELETE_CARD: '@ruby-app/' + id + '/DELETE_CARD',
        SET_EDIT_CARD_CODE: '@ruby-app/' + id + '/SET_EDIT_CARD_CODE',

        MOVE_CARD: '@ruby-app/' + id + '/MOVE_CARD',
        UPDATE_CARD_ORDER: '@ruby-app/' + id + '/UPDATE_CARD_ORDER',
        SET_FIELD_ERROR_TREE: '@ruby-app/' + id + '/SET_FIELD_ERROR_TREE'

    };
    return {
        TYPES: TYPES,
        generators: {
            set_property_forFieldCardKey_toValue: function set_property_forFieldCardKey_toValue(property, key, value) {
                return {
                    type: TYPES.SET_FIELD_CARD_PROPERTY,
                    payload: {
                        key: key,
                        property: property,
                        value: value
                    }
                };
            },
            set_property_forFieldKey_toValue_withErrors: function set_property_forFieldKey_toValue_withErrors(property, key, value, errors) {
                return {
                    type: TYPES.SET_FIELD_PROPERTY,
                    payload: {
                        key: key,
                        property: property,
                        value: value,
                        errors: errors,
                        error: errors ? errors[0] : undefined
                    }
                };
            },
            set_property_forFieldKey_toValue_withConstraints: function set_property_forFieldKey_toValue_withConstraints(property, key, value, constraints, propSpecKey) {
                var _this = this;

                var selfModule = this;
                var actions = selfModule.getAction().generators;

                var _selfModule$getDepend = selfModule.getDependencies(),
                    selfSelector = _selfModule$getDepend.selfSelector;

                var propertiesWithoutPermissions_byType = this.propertiesWithoutPermissions_byType();
                return function (dispatch, getState) {
                    var fieldState = _lodash2.default.get(selfSelector(getState()), ['fields', _this.props.key, 'value']);
                    var instances = _lodash2.default.get(fieldState, 'instances');
                    var currInstance = instances[key];
                    var currComponentName = _lodash2.default.get(instances, [key, 'instanceProps', 'componentName', 'value']);
                    var properties = propertiesWithoutPermissions_byType[currComponentName] || [];
                    var propertiesSpecsReferencingCurrent = properties.filter(function (spec) {
                        return spec.defaultToProp === property;
                    });

                    var errors = constraints ? _validateField_withConstraints(propSpecKey || property, value, constraints, instances, selfModule, currInstance) : undefined;

                    dispatch(actions.set_property_forFieldKey_toValue_withErrors(property, key, value, errors));

                    propertiesSpecsReferencingCurrent.map(function (_ref8) {
                        var propKey = _ref8.propKey,
                            defaultToProp = _ref8.defaultToProp,
                            constraints = _ref8.constraints;

                        var stateValue = _lodash2.default.get(instances, [key, 'instanceProps', propKey, 'value']);
                        var defaultedValue = _lodash2.default.isNil(stateValue) ? rubyWords.keyify(value) : stateValue;
                        var errors = constraints ? _validateField_withConstraints(propKey, defaultedValue, constraints, instances, selfModule, currInstance) : undefined;

                        dispatch(actions.set_property_forFieldKey_toValue_withErrors(propKey, key, stateValue, errors));
                    });
                };
            }
            //# if targetID == undefined, insert it at the end
            , addCard_ofType_afterTargetID: function addCard_ofType_afterTargetID(type, targetID) {
                return {
                    type: TYPES.ADD_CARD,
                    payload: {
                        type: type,
                        targetID: targetID,
                        scrollToBottomOnMount: targetID == null
                    }
                };
            },
            duplicateCard_withTargetID: function duplicateCard_withTargetID(targetID) {
                return {
                    type: TYPES.DUPLICATE_CARD,
                    payload: {
                        targetID: targetID
                    }
                };
            },
            deleteCard_withTargetID: function deleteCard_withTargetID(targetID) {
                return {
                    type: TYPES.DELETE_CARD,
                    payload: {
                        targetID: targetID
                    }
                };
            },
            startCodeEditingForInstance: function startCodeEditingForInstance(instance) {
                var _getDependencies = this.getDependencies(),
                    selfActions = _getDependencies.selfActions,
                    codeDialogActions = _getDependencies.codeDialogActions;

                var instanceID = _lodash2.default.get(instance, 'id');
                var instanceType = _lodash2.default.get(instance, ['instanceProps', 'instanceType']);
                var fieldType = _lodash2.default.get(instance, ['instanceProps', 'componentName', 'value']);

                var instanceState = {
                    instances: _defineProperty({}, instanceID, instance),
                    childIds: [instanceID]
                };
                var formJS_forInstance = this.formValueFromLocalState({
                    fields: _defineProperty({}, this.props.key, {
                        value: instanceState
                    })
                }).children[0];

                var formJS_forInstance_asString = JSON.stringify(formJS_forInstance, null, JSON_INDENTATION_SPACES);
                var properties_byKey = this.propertiesWithoutPermissions_byKey();

                //# TODO: get the appropriate key/props
                //# collect all the keys and form the structure that we need to mimic the store structure
                //# then run it through the transformer to get the formJS structure
                var fieldInfo_byComponentName = this.fieldInfo_byComponentName();
                var coreProperties_byInstanceType = this.coreProperties_byInstanceType();
                var corePropertyKeys = Object.keys(coreProperties_byInstanceType[instanceType]);
                var additionalPropertyKeys = _lodash2.default.get(fieldInfo_byComponentName[fieldType], 'propertyKeys', []);

                var autoPopulateData_asInstanceProps = Object.assign.apply(Object, corePropertyKeys.concat(additionalPropertyKeys).map(function (propertyKey) {
                    var propKey = properties_byKey[propertyKey].propKey;
                    return _defineProperty({}, propKey, {
                        value: null
                    });
                }));

                var autoPopulateData_asFormJS = fieldTypesThatDontAutopopulate[fieldType] ? null : (0, _serverTransforms.convertFieldEditorState_toFormJS)({
                    instances: _defineProperty({}, instanceID, _extends({}, instance, {
                        instanceProps: autoPopulateData_asInstanceProps
                    })),
                    childIds: [instanceID]
                })[0];

                return function (dispatch) {
                    dispatch(codeDialogActions.openDialogWithOptions({
                        value: formJS_forInstance_asString,
                        autoPopulateData: autoPopulateData_asFormJS,
                        onSubmit: function onSubmit(codeDialogValue) {
                            dispatch(selfActions.setEditCardCode_forInstanceID_withValue(instanceID, JSON.parse(codeDialogValue)));
                            dispatch(codeDialogActions.closeAndResetDialog());
                        }
                    }));
                };
            },
            startCodeEditingForEntireForm: function startCodeEditingForEntireForm() {
                var _this2 = this;

                var formKey = this.props.key;

                var _getDependencies2 = this.getDependencies(),
                    selfSelector = _getDependencies2.selfSelector,
                    selfActions = _getDependencies2.selfActions,
                    codeDialogActions = _getDependencies2.codeDialogActions;

                return function (dispatch, getState) {
                    var selfState = selfSelector(getState());

                    var formJS = _this2.formValueFromLocalState(selfState).children;
                    //# leveraging the overridden function that does special things
                    //# for the field-editor state

                    var formJS_asString = JSON.stringify(formJS, null, JSON_INDENTATION_SPACES);

                    dispatch(codeDialogActions.openDialogWithOptions({
                        value: formJS_asString,
                        onSubmit: function onSubmit(codeDialogValue) {
                            dispatch(selfActions.setFieldValueByKey(JSON.parse(codeDialogValue), formKey));
                            dispatch(codeDialogActions.closeAndResetDialog());
                        }
                    }));
                };
            },
            setEditCardCode_forInstanceID_withValue: function setEditCardCode_forInstanceID_withValue(instanceID, value) {
                return {
                    type: TYPES.SET_EDIT_CARD_CODE,
                    payload: {
                        instanceID: instanceID,
                        value: value
                    }
                };
            },

            moveCard_withTargetID_toAfterID: function moveCard_withTargetID_toAfterID(targetID, afterID) {
                return {
                    type: TYPES.MOVE_CARD,
                    payload: {
                        targetID: targetID,
                        afterID: afterID
                    }
                };
            },
            updateCardOrdering_withOrder: function updateCardOrdering_withOrder(order) {
                return {
                    type: TYPES.UPDATE_CARD_ORDER,
                    payload: {
                        order: order
                    }
                };
            }
            //# overriding mixin
            , setFieldErrorMessageByKey: function setFieldErrorMessageByKey(errorArray, key) {
                var TYPES = this.getAction().TYPES;
                return {
                    type: TYPES.SET_FIELD_ERROR_TREE,
                    payload: {
                        errors: errorArray,
                        key: key //# NOTE: the assumption is that there is always one key
                    }
                };
            }
        }
    };
};