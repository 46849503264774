'use strict';

var React = require('react');
var ReactRouter = require('react-router');
var Router = ReactRouter.Router;
var Route = ReactRouter.Route;

var rubyComponent = require('@rubyapps/ruby-component');

var action = require('./action');
var reducer = require('./reducer');
var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;
var routePath = CONSTANTS.ROUTE_PATH;
var ListPageGenerator = require('./reactComponents/ListPage');

var rubyDateManager = require('@rubyapps/ruby-date');

var RCListPage = rubyComponent.createClass({
    componentName: componentName,
    CONSTANTS: CONSTANTS,
    getDefaultProps: function getDefaultProps() {
        return {
            path: routePath
        };
    },
    action: action,
    reducer: reducer,
    getReactClass: function getReactClass(props, griddleProps) {
        var ListPage = ListPageGenerator.apply(this, arguments);
        return ListPage;
    },
    utils: {
        constructChangelogMessage: function constructChangelogMessage(lastModifiedData) {
            console.warn('[DEPRECATED 20180831] use this method in `ruby-component-mixin-list-page` instead');
            var lastModifiedUser = lastModifiedData.last_modified_by_user_name,
                lastModifiedDate = lastModifiedData.last_modified_timestamp;


            var message = [];

            // If there is last modified data, start building our string
            if (lastModifiedDate || lastModifiedUser) {
                message.push('Last modified');

                if (lastModifiedDate) {
                    message.push(rubyDateManager.formatWithShortenedAdjacentDays(lastModifiedDate));
                }

                if (lastModifiedUser) {
                    message.push('by ' + lastModifiedUser);
                }
            }

            return message.join(' ');
        }
    }

});

module.exports = RCListPage;