'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var _ = require('lodash');

var OMIT_KEYS = ['children', 'store', 'action']; //# need to omit special keys
var RubyComponentFieldDynamicForm = _react2.default.createClass({
    displayName: 'RubyComponentFieldDynamicForm',

    propTypes: {
        actions: PropTypes.object,
        shouldPassthroughProps: PropTypes.bool
        //# Any other props you're expecting
    },
    render: function render() {
        var _this = this,
            _React$createElement;

        var preppedChildren = this.props.children;

        if (this.props.shouldPassthroughProps) {
            preppedChildren = this.props.children.map(function (child) {
                var childPropTypeKeys = Object.keys(_.omit(child.type.propTypes, OMIT_KEYS));

                return _react2.default.cloneElement(child, _extends({}, _.pick(_this.props, childPropTypeKeys), child.props) //# prefer the original child props
                );
            });
        }
        var wrappedChildren = _react2.default.createElement(
            'span',
            (_React$createElement = { style: { width: '100%' }, 'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'RubyComponentFieldDynamicForm'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'RubyComponentFieldDynamicForm'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'RubyComponentFieldDynamicForm'), _React$createElement),
            preppedChildren
        );

        return this.props.renderGridWrapper(_extends({}, this.props, {
            children: wrappedChildren
        }));
    }
});

exports.default = RubyComponentFieldDynamicForm;