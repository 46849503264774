'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initializeGrid = initializeGrid;
exports.removeGrid = removeGrid;
exports.loadData = loadData;
exports.requestToLoadData = requestToLoadData;
exports.filterData = filterData;
exports.filterDataByColumn = filterDataByColumn;
exports.setPageSize = setPageSize;
exports.sort = sort;
exports.addSortColumn = addSortColumn;
exports.loadNext = loadNext;
exports.loadPrevious = loadPrevious;
exports.loadPage = loadPage;
exports.refreshCurrentPage = refreshCurrentPage;
exports.toggleColumn = toggleColumn;
exports.setColumnProperties = setColumnProperties;
exports.setColumnResizeProperties = setColumnResizeProperties;
exports.setColumnRenderedSizeProperties = setColumnRenderedSizeProperties;
exports.clearStaticData = clearStaticData;
exports.prependStaticData = prependStaticData;
exports.setStaticData = setStaticData;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _actionTypes = require('../constants/action-types');

var types = _interopRequireWildcard(_actionTypes);

var _reduxBatchedActions = require('redux-batched-actions');

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initializeGrid(properties) {
  return {
    type: types.GRIDDLE_INITIALIZED,
    properties: properties
  };
}

function removeGrid() {
  return {
    type: types.GRIDDLE_REMOVED
  };
}
function loadData(data) {
  return {
    type: types.GRIDDLE_LOADED_DATA,
    data: data
  };
}
function requestToLoadData(data, properties) {
  //# types.GRIDDLE_LOAD_DATA
  return function (dispatch) {
    if (data) {
      dispatch(loadData(data));
    }
  };
}

function filterData(filter) {
  return {
    type: types.GRIDDLE_FILTERED,
    filter: filter
  };
}

function filterDataByColumn(filter, column) {
  return {
    type: types.GRIDDLE_FILTERED_BY_COLUMN,
    filter: filter,
    column: column
  };
}

function setPageSize(pageSize) {
  return {
    type: types.GRIDDLE_SET_PAGE_SIZE,
    pageSize: pageSize
  };
}

function sort(column) {
  return {
    type: types.GRIDDLE_SORT,
    sortColumns: [column]
  };
}

function addSortColumn(column) {
  return {
    type: types.GRIDDLE_ADD_SORT_COLUMN,
    sortColumn: column
  };
}

function loadNext() {
  return (0, _reduxBatchedActions.batchActions)([_loadNext.apply(this, arguments), clearStaticData()]);
}

function loadPrevious() {
  return (0, _reduxBatchedActions.batchActions)([_loadPrevious.apply(this, arguments), clearStaticData()]);
}

function loadPage(number) {
  return (0, _reduxBatchedActions.batchActions)([_loadPage.apply(this, arguments), clearStaticData()]);
}

function _loadNext() {
  return {
    type: types.GRIDDLE_NEXT_PAGE
  };
}

function _loadPrevious() {
  return {
    type: types.GRIDDLE_PREVIOUS_PAGE
  };
}

function _loadPage(number) {
  return {
    type: types.GRIDDLE_GET_PAGE,
    pageNumber: number
  };
}

function refreshCurrentPage() {
  return {
    type: types.GRIDDLE_REFRESH_CURRENT_PAGE
  };
}

function toggleColumn(columnId) {
  return {
    type: types.GRIDDLE_TOGGLE_COLUMN,
    columnId: columnId
  };
}

function setColumnProperties(columnId, properties) {
  return {
    type: types.GRIDDLE_SET_COLUMN_PROPERTIES,
    columnId: columnId,
    properties: properties
  };
}

function setColumnResizeProperties(propertiesByID) {
  return {
    type: types.GRIDDLE_SET_COLUMN_RESIZE_PROPERTIES,
    propertiesByID: propertiesByID
  };
}

function setColumnRenderedSizeProperties(propertiesByID) {
  return {
    type: types.GRIDDLE_SET_COLUMN_RENDERED_SIZE_PROPERTIES,
    propertiesByID: propertiesByID
  };
}

function clearStaticData() {
  return setStaticData([]);
}

function prependStaticData(staticData) {
  return {
    type: types.GRIDDLE_PREPEND_STATIC_DATA,
    staticData: _lodash2.default.castArray(staticData)
  };
}

function setStaticData(staticData) {
  return {
    type: types.GRIDDLE_SET_STATIC_DATA,
    staticData: staticData
  };
}