'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.combineAndOverrideReducers = combineAndOverrideReducers;
exports.getReducersByWordEnding = getReducersByWordEnding;
exports.getBeforeReducers = getBeforeReducers;
exports.getAfterReducers = getAfterReducers;
exports.wrapReducer = wrapReducer;
exports.combineInitialState = combineInitialState;
exports.buildReducerWithHooks = buildReducerWithHooks;
exports.default = buildGriddleReducer;

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _lodash3 = require('lodash.assign');

var _lodash4 = _interopRequireDefault(_lodash3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var initialState = _immutable2.default.fromJS({});

//from MDN
//TODO: determine if there is a better way to make an empty immutable object
if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (searchString, position) {
    var subjectString = this.toString();
    if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
      position = subjectString.length;
    }
    position -= searchString.length;
    var lastIndex = subjectString.indexOf(searchString, position);
    return lastIndex !== -1 && lastIndex === position;
  };
}

function combineAndOverrideReducers(containers) {
  if (!containers) {
    return {};
  }
  containers.unshift({});
  var griddleReducers = _lodash4.default.apply(this, containers);
  containers.shift();
  return griddleReducers;
}

/*
  This method creates a pipeline of reducers. This is mainly
  used for the before / after reducers
*/
function getReducersByWordEnding(reducers, ending) {
  return reducers.reduce(function (previous, current) {
    var keys = Object.keys(current).filter(function (name) {
      return name.endsWith(ending);
    });

    var reducer = _lodash2.default.pick(current, keys);

    //TODO: clean this up it's a bit hacky
    for (var key in current) {
      if (!key.endsWith(ending)) {
        continue;
      }

      var keyWithoutEnding = key.replace('_' + ending, "");
      //make a new method that pipes output of previous into state of current
      //this is to allow chaining these
      var hasPrevious = previous.hasOwnProperty(keyWithoutEnding) && typeof previous[keyWithoutEnding] === 'function';
      var previousReducer = hasPrevious ? previous[keyWithoutEnding] : undefined;
      var currentReducer = reducer[key];

      reducer[keyWithoutEnding] = wrapReducer(currentReducer, previousReducer);
    }

    //override anything in previous (since this now calls previous to make sure we have helpers from both);
    return (0, _lodash4.default)(previous, reducer);
  }, {});
}

function getBeforeReducers(reducers) {
  return getReducersByWordEnding(reducers, "BEFORE");
}

function getAfterReducers(reducers) {
  return getReducersByWordEnding(reducers, "AFTER");
}

//feed the result of previous reducer into next
function wrapReducer(next, previous) {
  //if previous reducer exists -- return the result of wrapper as state to next
  return previous && typeof previous === 'function' && typeof next === 'function' ? function (state, action, helpers) {
    return next(previous(state, action, helpers), action, helpers);
  } : next;
}

//TODO: Maybe this is dumb becuase it's just wrapping a typeof
function isFunction(item) {
  return typeof item === 'function';
}

function isFunctionOrUndefined(item) {
  return isFunction(item) || typeof item === 'undefined';
}

function wrapReducers() {
  for (var _len = arguments.length, reducers = Array(_len), _key = 0; _key < _len; _key++) {
    reducers[_key] = arguments[_key];
  }

  var finalReducer = reducers.reduce(function (previous, current) {
    //get all reducer methods and either set the prop
    for (var key in current) {
      if (isFunctionOrUndefined(current[key]) && isFunctionOrUndefined(previous[key])) {
        previous[key] = wrapReducer(current[key], previous[key]);
      }
    }

    return previous;
  }, {});

  return finalReducer;
}

function combineInitialState(states) {
  //TODO: Do this in a better way
  var griddleState = initialState;

  for (var state in states) {
    griddleState = griddleState.mergeDeep(states[state]);
  }

  return griddleState;
}

//TODO: This is not the most efficient way to do this.
function buildReducerWithHooks(reducers, reducer) {
  var filteredReducerEndings = ['BEFORE', 'AFTER', 'BEFORE_REDUCE', 'AFTER_REDUCE'];

  var validKeys = Object.keys(reducer).filter(function (key) {
    return !filteredReducerEndings.some(function (reducerEnding) {
      return key.endsWith(reducerEnding);
    });
  });

  var preReduce = getReducersByWordEnding(reducers, "BEFORE_REDUCE").BEFORE_REDUCE;
  var postReduce = getReducersByWordEnding(reducers, "AFTER_REDUCE").AFTER_REDUCE;

  var retVal = {};
  validKeys.forEach(function (key) {
    return retVal[key] = wrapReducer(postReduce, wrapReducer(preReduce, reducer[key]));
  });

  return (0, _lodash4.default)({}, reducer, retVal);
}

//TODO: maybe add helpers in here too and override them on add. idk
function buildGriddleReducer(initialStates, reducers, helpers) {
  var beforeReducers = getBeforeReducers(reducers);
  var afterReducers = getAfterReducers(reducers);
  var griddleReducers = combineAndOverrideReducers(reducers);

  var wrappedReducers = buildReducerWithHooks(reducers, wrapReducers(beforeReducers, griddleReducers, afterReducers));
  var finalReducer = wrappedReducers;

  var griddleState = combineInitialState(initialStates);
  var griddleHelpers = combineAndOverrideReducers(helpers);

  //TODO: Decrease the inception
  return function griddleReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : griddleState;
    var action = arguments[1];
    var helpers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : griddleHelpers;

    return finalReducer[action.type] ? finalReducer[action.type](state, action, helpers) : state;
  };
}