'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _keycode = require('keycode');

var _keycode2 = _interopRequireDefault(_keycode);

var _FilterToolbarGroup = require('./FilterToolbarGroup');

var _FilterToolbarGroup2 = _interopRequireDefault(_FilterToolbarGroup);

var _FilterTagsToolbarGroup = require('./FilterTagsToolbarGroup');

var _FilterTagsToolbarGroup2 = _interopRequireDefault(_FilterTagsToolbarGroup);

var _AddFilterToolbarGroup = require('./AddFilterToolbarGroup');

var _AddFilterToolbarGroup2 = _interopRequireDefault(_AddFilterToolbarGroup);

var _SelectColumnsToolbarGroup = require('./SelectColumnsToolbarGroup');

var _SelectColumnsToolbarGroup2 = _interopRequireDefault(_SelectColumnsToolbarGroup);

var _SaveSearchDialog = require('./SaveSearchDialog');

var _SaveSearchDialog2 = _interopRequireDefault(_SaveSearchDialog);

var _columnSpecFromFieldSpec = require('./columnSpecFromFieldSpec');

var _columnDefinitionsFromSearchableFields = require('../../columnDefinitionsFromSearchableFields');

var _columnDefinitionsFromSearchableFields2 = _interopRequireDefault(_columnDefinitionsFromSearchableFields);

var _filterableFieldsFromSearchableFields = require('../../filterableFieldsFromSearchableFields');

var _filterableFieldsFromSearchableFields2 = _interopRequireDefault(_filterableFieldsFromSearchableFields);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var urljoin = require('url-join');
var Route = require('route-parser');

var PropTypes = _react2.default.PropTypes;

var FilterToolbarGroup__propTypeKeys = Object.keys(_FilterToolbarGroup2.default.propTypes);
var FilterTagsToolbarGroup__propTypeKeys = Object.keys(_FilterTagsToolbarGroup2.default.propTypes);
var AddFilterToolbarGroup__propTypeKeys = Object.keys(_AddFilterToolbarGroup2.default.propTypes);
var SelectColumnsToolbarGroup__propTypeKeys = Object.keys(_SelectColumnsToolbarGroup2.default.propTypes);
var SaveSearchDialog__propTypeKeys = Object.keys(_SaveSearchDialog2.default.propTypes);

var AdvancedSearchUtilityBar = _react2.default.createClass({
    displayName: 'AdvancedSearchUtilityBar',

    propTypes: _extends({
        apiUrlPrefix: PropTypes.string,
        boundActions: PropTypes.object,
        boundDelegateActions: PropTypes.object,
        export_context: PropTypes.string,
        exportListerRoute: PropTypes.string,
        savedSearchListerRoute: PropTypes.string,
        savedSearchContext: PropTypes.string,
        filterTags: PropTypes.arrayOf(PropTypes.object),
        griddleProps: PropTypes.object,
        listerConfig: PropTypes.object,
        queryString: PropTypes.string,
        rubyClientFK: PropTypes.number,
        searchableFields: PropTypes.arrayOf(PropTypes.shape({
            breadcrumb: PropTypes.string,
            componentName: PropTypes.string,
            dataPath: PropTypes.string,
            displayLabel: PropTypes.node,
            foundIn__templateKey: PropTypes.string,
            key: PropTypes.string,
            label: PropTypes.string,
            refKey: PropTypes.string
            //# excludeFromAdvancedLister: if this is true, then the field is entirely excluded from both filtering and column selection
            , excludeFromColumnSelection: PropTypes.bool,
            excludeFromFilterSelection: PropTypes.bool
        })),
        searchableFields__dataSourceConfig: PropTypes.object,
        selectedColumnSpecsArr: PropTypes.arrayOf(PropTypes.object),
        selfModule: PropTypes.object //# use sparingly. we do this only to get a ref to griddleProps
        , staticFilterTags: PropTypes.arrayOf(PropTypes.object)
        //# TODO: add this to the set of filterTags so it gets rendered correctly.
        //# This should never be included in the call to events.setFilter()

        //# Overridding children props
        , childrenPropsByClass: PropTypes.shape({
            FilterToolbarGroup: PropTypes.object,
            FilterTagsToolbarGroup: PropTypes.object,
            AddFilterToolbarGroup: PropTypes.object,
            SelectColumnsToolbarGroup: PropTypes.object,
            SaveSearchDialog: PropTypes.object
        }),

        QUERY_STRING_MIN_LENGTH: PropTypes.string,
        filterableFieldsOptions: PropTypes.shape({
            isFlat: PropTypes.bool
        })
    }, _FilterToolbarGroup2.default.propTypes, _FilterTagsToolbarGroup2.default.propTypes, _AddFilterToolbarGroup2.default.propTypes, _SelectColumnsToolbarGroup2.default.propTypes, _SaveSearchDialog2.default.propTypes),
    getDefaultProps: function getDefaultProps() {
        return {
            apiUrlPrefix: '',
            childrenPropsByClass: {
                FilterToolbarGroup: {},
                FilterTagsToolbarGroup: {},
                AddFilterToolbarGroup: {},
                SelectColumnsToolbarGroup: {},
                SaveSearchDialog: {}
            },
            filterTags: [],
            filterableFieldsOptions: {
                isFlat: false
            },
            listerConfig: {},
            queryString: '',
            rubyClientFK: 3,
            searchableFields__dataSourceConfig: { text: 'label', key: 'dataPath' },
            selectedColumnSpecsArr: [],
            QUERY_STRING_MIN_LENGTH: 2,
            QUERY_DEBOUNCE_TIMEOUT: 2000

        };
    },
    getInitialState: function getInitialState() {
        var filteredColumnProps = this._getFilteredColumnPropertiesFromProps(this.props);
        return {
            isAddFilterVisible: false,
            isSelectColumnsVisible: false,
            isSaveSearchDialogVisible: false,

            queryString: '',
            selectedColumns: this._getColumnKeysOrderFromColumnProperties(filteredColumnProps),

            requestToInvalidateCacheTimestamp: new Date().toString()
        };
    },

    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        //# call on setState for selectedColumns
        var selectedColumnSpecsArr = nextProps.selectedColumnSpecsArr;
        var griddleFilterTags = nextProps.filterTags;
        var griddleFilterQuery = nextProps.queryString;

        this.setState({
            selectedColumns: selectedColumnSpecsArr.map(function (columnSpec) {
                return columnSpec.key;
            }),
            queryString: griddleFilterQuery //# Need to keep this around because of the debounce
        });

        this.props.selfModule.setGriddleProps(this.props.griddleProps);
    },

    componentWillMount: function componentWillMount() {
        this._debounced__queryString__handleChange = _lodash2.default.debounce(function (value) {
            if (value.length && value.length < this.props.QUERY_STRING_MIN_LENGTH) {
                return;
            }

            return this._delegateSetFilterWithQueryString_andFilterTags(value);
        }, this.props.QUERY_DEBOUNCE_TIMEOUT);
    },
    componentDidMount: function componentDidMount() {
        //# sync griddleProps
        this.props.selfModule.setGriddleProps(this.props.griddleProps);
    },
    componentWillUnmount: function componentWillUnmount() {
        //# unset griddleProps
        this.props.selfModule.setGriddleProps(null);
    }

    //== START: HANDLERS ==================================================================//

    , _onAddFilterButtonTap: function _onAddFilterButtonTap() {
        this.setState({
            isAddFilterVisible: !this.state.isAddFilterVisible
        });
    },
    _onSelectColumnsButtonTap: function _onSelectColumnsButtonTap() {
        this._onSelectColumnsVisibilityChange(!this.state.isSelectColumnsVisible);
    },
    _onSelectColumnsClose: function _onSelectColumnsClose() {
        this._onSelectColumnsVisibilityChange(false);
    },
    _onSelectColumnsVisibilityChange: function _onSelectColumnsVisibilityChange(isVisible) {
        this.setState({
            isSelectColumnsVisible: isVisible
        });
    },
    _onFilterTagDelete: function _onFilterTagDelete(key) {
        var finalFilterTags = this.props.filterTags.reduce(function (collector, filterTag) {
            var filterTagKey = filterTag.fieldSpec.dataPath + '-' + filterTag.matchSpec.value;
            if (filterTagKey != key) {
                collector.push(filterTag);
            }
            return collector;
        }, []);

        this._delegateSetFilterWithQueryString_andFilterTags(null, finalFilterTags);
    },
    _onFilterTagsAppend: function _onFilterTagsAppend(filterTags) {

        var finalFilterTags = this._getMergedFilterTags_fromProps_andNewTags(this.props, filterTags);

        this._delegateSetFilterWithQueryString_andFilterTags(null, finalFilterTags);
    },

    _onColumnToggle: function _onColumnToggle(columnKey, columnFieldSpec) {
        var indexOfColumnKey = this.state.selectedColumns.indexOf(columnKey);
        var newSelectedColumns = void 0;
        if (indexOfColumnKey >= 0) {
            newSelectedColumns = _lodash2.default.without(this.state.selectedColumns, columnKey);
        } else {
            newSelectedColumns = this.state.selectedColumns.concat(columnKey);
        }

        var columnDefinitionsByKey = this._columnDefinitionsByKeyFromColumnDefinitions(this._columnDefinitionsFromSearchableFields(this.props.searchableFields));

        //# get column specs and set the utility bar properties
        var fieldSpecsArr = newSelectedColumns.map(function (selectedColumnKey) {
            return columnDefinitionsByKey[selectedColumnKey];
        }).filter(function (spec) {
            return spec != undefined;
        });

        var selectedColumnSpecsArr = (0, _columnSpecFromFieldSpec.columnSpecsArrForSelf_andFieldSpecs)(this, fieldSpecsArr, this.props.searchableFields__dataSourceConfig, {
            listerConfig: this.props.listerConfig
        });

        this.props.griddleProps.events.setUtilityBarProperties(_extends({}, this.props.griddleProps.utilityBarProperties || {}, { selectedColumnSpecsArr: selectedColumnSpecsArr
        }));
        this.props.griddleProps.events.setColumnResizeProperties({}); //# clearing resizeProperties when column count changes

        //# trigger a refresh on the list to grab new data
        this.props.griddleProps.events.refreshCurrentPage();
    },

    _queryString__handleChange: function _queryString__handleChange(value) {
        return this._debounced__queryString__handleChange.call(this, value);
    },
    _delegatedQueryString__onChange: function _delegatedQueryString__onChange(value) {
        this.setState({
            queryString: value
        });

        return this._queryString__handleChange(value);
    },
    _delegatedTextField__onKeyDown: function _delegatedTextField__onKeyDown(event) {
        switch ((0, _keycode2.default)(event)) {
            case 'enter':
                this._debounced__queryString__handleChange.flush();
                break;
        }
    },
    _delegatedAddToFilter: function _delegatedAddToFilter(fieldSpec, matchSpec, searchSpec) {
        this._onFilterTagsAppend([{
            fieldSpec: fieldSpec,
            matchSpec: matchSpec,
            searchSpec: searchSpec
        }]);
    },
    _delegatedSavedSearchLoad: function _delegatedSavedSearchLoad(savedSearchObject) {
        var _savedSearchObject$fi = savedSearchObject.filterTags,
            filterTags = _savedSearchObject$fi === undefined ? [] : _savedSearchObject$fi,
            _savedSearchObject$qu = savedSearchObject.queryString,
            queryString = _savedSearchObject$qu === undefined ? '' : _savedSearchObject$qu;


        if (filterTags.length || queryString.length) {
            var finalFilterTags = this._getMergedFilterTags_fromProps_andNewTags(this.props, filterTags);
            var finalQueryString = this._getMergedQueryString_fromProps_andNewQueryString(this.props, queryString);
            this._delegateSetFilterWithQueryString_andFilterTags(finalQueryString, finalFilterTags);
        }
    },

    _delegatedResetSearch: function _delegatedResetSearch() {
        this.setState({
            queryString: ''
            //, filterTags: []
        });
        //# trigger a lister reload
        var emptySearchStats = {
            queryString: '',
            filterTags: []
        };
        this.props.griddleProps.events.setFilter(emptySearchStats);
        //# NOTE TODO: we probably need to deal with loading in the additional filter options when the time comes
    },
    _delegatedSaveSearch__dialogOpen: function _delegatedSaveSearch__dialogOpen() {
        this.setState({
            isSaveSearchDialogVisible: true
        });
    },
    _delegatedSaveSearch__dialogClose: function _delegatedSaveSearch__dialogClose() {
        this.setState({
            isSaveSearchDialogVisible: false
        });
    },
    _delegateSetFilterWithQueryString_andFilterTags: function _delegateSetFilterWithQueryString_andFilterTags(queryString, filterTags) {
        var newSearchStats = this._searchStatsForQueryString_andFilterTags(queryString, filterTags);

        this.props.griddleProps.events.setFilter(newSearchStats);
    },

    _delegatedViewSavedSearchLister: function _delegatedViewSavedSearchLister() {
        var _props = this.props,
            savedSearchContext = _props.savedSearchContext,
            selfModule = _props.selfModule;


        var customFilterName = 'context';
        this.props.boundActions.navigateToPathWithOptions({ path: this.props.savedSearchListerRoute }, function () {
            var savedSearch__griddleProps = selfModule.getActiveRouteComponent().getGriddleProps();
            var newUtilityBarProperties = _extends({}, savedSearch__griddleProps.utilityBarProperties || {}, {
                customFilters: _extends({}, _lodash2.default.get(savedSearch__griddleProps, 'utilityBarProperties.customFilters'), _defineProperty({}, customFilterName, savedSearchContext))
            });

            savedSearch__griddleProps.events.setUtilityBarProperties(newUtilityBarProperties);
            savedSearch__griddleProps.events.refreshCurrentPage();
        });
    }

    //== END: HANDLERS ==================================================================//

    , render: function render() {
        var _extends3,
            _extends4,
            _extends5,
            _extends6,
            _this = this,
            _extends7,
            _React$createElement;

        var savedSearches_optionsUrl = this._savedSearches_optionsUrl();
        var hasSaveableSearchParams = this._hasSaveableSearchParams();
        var selectedPageIds = Object.keys(_lodash2.default.get(this.props, ['griddleProps', 'state', 'selectedRecords'], {}));
        var hasSelectedPageIdsForExport = selectedPageIds.length > 0;

        var filterableFieldsOptions = this.props.filterableFieldsOptions || {};
        var columnDefinitions = this._columnDefinitionsFromSearchableFields(this.props.searchableFields);
        var filterableFields = filterableFieldsOptions.isFlat ? columnDefinitions : this._filterableFieldsFromSearchableFields(this.props.searchableFields);

        var griddleProps = this.props.griddleProps;
        var headerLabelElement = griddleProps.headerLabelElement;

        var hydratedHeaderLabelElement = _lodash2.default.isFunction(headerLabelElement) ? headerLabelElement(griddleProps) : headerLabelElement;

        return _react2.default.createElement(
            'div',
            (_React$createElement = { style: { width: "100%" }, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement),
            hydratedHeaderLabelElement,
            _react2.default.createElement(_FilterToolbarGroup2.default, _extends({}, _lodash2.default.pick(this.props, FilterToolbarGroup__propTypeKeys), _lodash2.default.pick(this.state, FilterToolbarGroup__propTypeKeys), {
                hasSaveableSearchParams: hasSaveableSearchParams,
                hasSelectedPageIdsForExport: hasSelectedPageIdsForExport,
                delegateAddFilterButtonTap: this._onAddFilterButtonTap,
                delegateSelectColumnsButtonTap: this._onSelectColumnsButtonTap,
                delegateResetSearch: this._delegatedResetSearch,
                delegateSaveSearch__dialogOpen: this._delegatedSaveSearch__dialogOpen,
                delegateOnChange: this._delegatedQueryString__onChange,
                delegateOnKeyDown: this._delegatedTextField__onKeyDown,
                delegateRequestToExportToExcel: this._delegatedRequestToExportToExcel,
                delegateViewExportLister: this._delegatedViewExportLister,
                delegateViewSavedSearchLister: this._delegatedViewSavedSearchLister
            }, this.props.childrenPropsByClass.FilterToolbarGroup, (_extends3 = {
                'data-codecept-selector-node': 'FilterToolbarGroup',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'FilterToolbarGroup'), _defineProperty(_extends3, 'data-codecept-selector-file', 'index'), _defineProperty(_extends3, 'data-codecept-selector-node', 'FilterToolbarGroup'), _defineProperty(_extends3, 'data-codecept-selector-file', 'index'), _extends3))),
            _react2.default.createElement(_FilterTagsToolbarGroup2.default, _extends({}, _lodash2.default.pick(this.props, FilterTagsToolbarGroup__propTypeKeys), _lodash2.default.pick(this.state, FilterTagsToolbarGroup__propTypeKeys), (_extends4 = {
                delegateFilterTagDelete: this._onFilterTagDelete,
                filterableFields__dataSourceConfig: this.props.searchableFields__dataSourceConfig,
                filterableFields: filterableFields,
                'data-codecept-selector-node': 'FilterTagsToolbarGroup',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'FilterTagsToolbarGroup'), _defineProperty(_extends4, 'data-codecept-selector-file', 'index'), _defineProperty(_extends4, 'data-codecept-selector-node', 'FilterTagsToolbarGroup'), _defineProperty(_extends4, 'data-codecept-selector-file', 'index'), _extends4))),
            _react2.default.createElement(_AddFilterToolbarGroup2.default, _extends({}, _lodash2.default.pick(this.props, AddFilterToolbarGroup__propTypeKeys), _lodash2.default.pick(this.state, AddFilterToolbarGroup__propTypeKeys), (_extends5 = {
                filterableFields__dataSourceConfig: this.props.searchableFields__dataSourceConfig,
                filterableFields: filterableFields,
                savedSearches_optionsUrl: savedSearches_optionsUrl,
                savedSearches_instanceRouteParser: this._savedSearches_instanceRouteParser(),
                delegateOnSavedSearchLoad: this._delegatedSavedSearchLoad,
                delegateAddToFilter: this._delegatedAddToFilter,
                'data-codecept-selector-node': 'AddFilterToolbarGroup',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends5, 'data-codecept-selector-node', 'AddFilterToolbarGroup'), _defineProperty(_extends5, 'data-codecept-selector-file', 'index'), _defineProperty(_extends5, 'data-codecept-selector-node', 'AddFilterToolbarGroup'), _defineProperty(_extends5, 'data-codecept-selector-file', 'index'), _extends5))),
            _react2.default.createElement(_SelectColumnsToolbarGroup2.default, _extends({}, _lodash2.default.pick(this.props, SelectColumnsToolbarGroup__propTypeKeys), _lodash2.default.pick(this.state, SelectColumnsToolbarGroup__propTypeKeys), (_extends6 = {
                columnDefinitions: columnDefinitions,
                columnDefinitions__dataSourceConfig: this.props.searchableFields__dataSourceConfig,
                delegateOnColumnToggle: this._onColumnToggle,
                delegateSelectColumnsClose: this._onSelectColumnsClose,
                'data-codecept-selector-node': 'SelectColumnsToolbarGroup',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends6, 'data-codecept-selector-node', 'SelectColumnsToolbarGroup'), _defineProperty(_extends6, 'data-codecept-selector-file', 'index'), _defineProperty(_extends6, 'data-codecept-selector-node', 'SelectColumnsToolbarGroup'), _defineProperty(_extends6, 'data-codecept-selector-file', 'index'), _extends6))),
            _react2.default.createElement(_SaveSearchDialog2.default, _extends({}, _lodash2.default.pick(this.props, SaveSearchDialog__propTypeKeys), _lodash2.default.pick(this.state, SaveSearchDialog__propTypeKeys), (_extends7 = {
                open: this.state.isSaveSearchDialogVisible,
                delegateClose: this._delegatedSaveSearch__dialogClose,
                savedSearches_optionsUrl: savedSearches_optionsUrl,
                savedSearches_putEndpoint: this._savedSearches_putEndpoint(),
                savedSearches_baseParams: this._searchStatsForQueryString_andFilterTags(),
                onSave__promiseArgs: [function (result) {
                    _this.setState({
                        requestToInvalidateCacheTimestamp: new Date().toString()
                    });
                }],
                'data-codecept-selector-node': 'SaveSearchDialog',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends7, 'data-codecept-selector-node', 'SaveSearchDialog'), _defineProperty(_extends7, 'data-codecept-selector-file', 'index'), _defineProperty(_extends7, 'data-codecept-selector-node', 'SaveSearchDialog'), _defineProperty(_extends7, 'data-codecept-selector-file', 'index'), _extends7))),
            this.props.children
        );
    }

    //== START: UTILITY =================================================================//
    ,
    _savedSearches_putEndpoint: function _savedSearches_putEndpoint() {
        return urljoin(this.props.apiUrlPrefix, 'saved_searches/');
    },
    _savedSearches_instanceRouteParser: function _savedSearches_instanceRouteParser() {
        return new Route(urljoin(this.props.apiUrlPrefix, 'saved_searches/:id'));
    },
    _savedSearches_optionsUrl: function _savedSearches_optionsUrl() {
        return urljoin(this.props.apiUrlPrefix, 'saved_searches/options', '?ruby_client_fk=' + this.props.rubyClientFK, '&where=' + JSON.stringify({
            context: this.props.savedSearchContext
        }));
    },
    _hasSaveableSearchParams: function _hasSaveableSearchParams() {
        return this.props.filterTags.length > 0 || !!this.props.queryString;
    },
    _sanitizedFilterTags: function _sanitizedFilterTags(filterTags) {
        if (filterTags == undefined) {
            filterTags = this.props.filterTags;
        }
        return filterTags.map(function (filterTag) {
            return {
                //_.omit(filterTag, ['key'])
                fieldSpec: _lodash2.default.pick(filterTag.fieldSpec, ['key', 'label' //# NOTE: this is deprecated in favor of selecting the label from the searchableFields
                //# reference the FilterTagsToolbarGroup Chip element
                , 'dataPath', 'refKey', 'foundIn__templateKey', 'nestingOf', 'parentTemplateKey', 'parentDataPath']),
                matchSpec: _lodash2.default.pick(filterTag.matchSpec, ['text', 'value']),
                searchSpec: _lodash2.default.pick(filterTag.searchSpec, ['text', 'value'])
            };
        });
    },
    _columnDefinitionsByKeyFromColumnDefinitions: function _columnDefinitionsByKeyFromColumnDefinitions(columnDefinitions) {
        var dataSourceConfig = this.props.searchableFields__dataSourceConfig;

        return _lodash2.default.reduce(columnDefinitions, function (collector, columnDefinition) {
            var columnDefinitionKey = columnDefinition[dataSourceConfig.key];
            collector[columnDefinitionKey] = columnDefinition;
            return collector;
        }, {});
    },
    _columnDefinitionsFromSearchableFields: function _columnDefinitionsFromSearchableFields(searchableFields) {
        return (0, _columnDefinitionsFromSearchableFields2.default)(searchableFields);
    },
    _filterableFieldsFromSearchableFields: function _filterableFieldsFromSearchableFields(searchableFields) {
        return (0, _filterableFieldsFromSearchableFields2.default)(searchableFields);
    },

    _getFilteredColumnPropertiesFromProps: function _getFilteredColumnPropertiesFromProps(props) {
        var _props$griddleProps$s = props.griddleProps.state.renderProperties.columnProperties,
            columnProperties = _props$griddleProps$s === undefined ? {} : _props$griddleProps$s;

        var filteredColumnProps = _lodash2.default.omit(columnProperties, ['id']);

        return filteredColumnProps;
    }

    //# DEPRECATED 20170807
    , _getColumnKeysOrderFromColumnProperties: function _getColumnKeysOrderFromColumnProperties(columnProperties) {

        return Object.keys(columnProperties).sort(function (first, second) {
            var firstColumn = columnProperties[first];
            var secondColumn = columnProperties[second];

            //deal with columns without order properties
            if (!firstColumn['order'] && !secondColumn['order']) {
                return 0;
            }
            if (firstColumn['order'] && !secondColumn['order']) {
                return -1;
            }
            if (!firstColumn['order'] && secondColumn['order']) {
                return 1;
            }

            //order the columns if they both have an order property
            return firstColumn["order"] - secondColumn["order"];
        });
    },

    _searchStatsForQueryString_andFilterTags: function _searchStatsForQueryString_andFilterTags(queryString, filterTags) {
        if (_lodash2.default.isNil(queryString)) {
            queryString = this.state.queryString;
        }
        if (_lodash2.default.isNil(filterTags)) {
            filterTags = this.props.filterTags;
        }

        var newSearchStats = {
            context: this.props.savedSearchContext,
            filterTags: this._sanitizedFilterTags(filterTags),
            queryString: queryString,
            customFilters: _lodash2.default.get(this, 'props.griddleProps.filter.customFilters')
        };

        return newSearchStats;
    },

    _getMergedFilterTags_fromProps_andNewTags: function _getMergedFilterTags_fromProps_andNewTags(props, filterTags) {
        var currentFilterCount = props.filterTags.length;
        var newFilterTags = _lodash2.default.differenceWith(filterTags, props.filterTags, function (a, b) {
            return a.fieldSpec.dataPath == b.fieldSpec.dataPath && a.matchSpec.value == b.matchSpec.value && _lodash2.default.isEqual(a.searchSpec.value, b.searchSpec.value);
        });

        var preppedFilterTags = _lodash2.default.map(newFilterTags, function (filterTag, index) {
            return _extends({}, filterTag, { key: currentFilterCount + index });
        });

        var finalFilterTags = props.filterTags.concat(preppedFilterTags);

        return finalFilterTags;
    },

    _getMergedQueryString_fromProps_andNewQueryString: function _getMergedQueryString_fromProps_andNewQueryString(props, newQueryString) {
        var currentQueryString = props.queryString;
        var trimmedCurrentQueryString = _lodash2.default.trim(currentQueryString);
        var trimmedNewQueryString = _lodash2.default.trim(newQueryString);

        if (trimmedNewQueryString.length == 0) {
            return currentQueryString;
        }

        var currentQueryStringArr = trimmedCurrentQueryString.length ? currentQueryString.split(', ') : [];

        var finalQueryString = _lodash2.default.uniq(currentQueryStringArr.concat(trimmedNewQueryString)).join(', ');

        return finalQueryString;
    }

    //== END: UTILITY ==================================================================//
});

exports.default = AdvancedSearchUtilityBar;