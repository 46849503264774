'use strict';

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require('react-router');

var _keycode = require('keycode');

var _keycode2 = _interopRequireDefault(_keycode);

var _ForgotPasswordConnector = require('./reactComponents/ForgotPasswordConnector');

var _ForgotPasswordConnector2 = _interopRequireDefault(_ForgotPasswordConnector);

var _constants = require('../common/constants');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var componentName = _constants2.default.COMPONENT_NAME;

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var action = require('./action');
var reducer = require('./reducer');
var middleware = require('./middleware');

//# Mixins
var rubyMiddlewareFields = require('@rubyapps/ruby-middleware-fields');
var fieldValidationsMixin = require('@rubyapps/ruby-component-mixin-field-validations');

var RubyComponentFESettings = require('@rubyapps/ruby-component-frontend-settings');
var RubyComponentCurrentUser = require('@rubyapps/ruby-component-current-user');

var FieldForm = require('@rubyapps/ruby-component-field-form');
var FieldPassword = require('@rubyapps/ruby-component-field-password');
var FieldButton = require('@rubyapps/ruby-component-field-button');
var FieldHidden = require('@rubyapps/ruby-component-field-hidden');

var RCForgotPasswordPage = RubyComponent.createClass({
    propTypes: {
        nextPage: PropTypes.string
    },
    getDefaultProps: function getDefaultProps() {
        return {
            nextPage: '/login.html',
            clientName: 'Ruby',
            path: '/app/reset-password',
            forgotPasswordTokenKey: 'fp_token',
            forgotPasswordTokenParam: 'fp_token'
        };
    },
    componentName: componentName,
    CONSTANTS: _constants2.default,
    action: action,
    reducer: reducer,
    middleware: middleware,
    children: function children() {
        var _this = this;

        var hiddenComponent = RubyComponent.createElement(FieldHidden, {
            key: this.props.forgotPasswordTokenKey
        });
        this._hiddenComponent = hiddenComponent;

        var PasswordProps = {
            TextField: {
                onKeyDown: function onKeyDown(event) {
                    if ((0, _keycode2.default)(event) === 'enter') {
                        _this.triggerFormSubmit();
                    }
                }
            }
        };

        return [[RubyComponent.createElement(FieldForm, {
            endpoint: '/users/resetForgottenPassword',
            id: 'resetForgotPasswordForm',
            thenable: [function (res) {
                var _getDependencies = _this.getDependencies(),
                    alertDialog = _getDependencies.alertDialog;

                alertDialog.openWithOptions({
                    title: 'Password Updated',
                    content: 'You\'ve successfully updated your password. We will now redirect you to the login page so that you can log in.',
                    buttonLabel: 'OK',
                    closeCallback: function closeCallback() {
                        _this.getStore().dispatch(_this.getAction().generators.navigateToPathWithOptions({ path: _this.props.nextPage }));
                    }
                });
            }]
        }, hiddenComponent, RubyComponent.createElement(FieldPassword, {
            id: this.props.id + ':password',
            "key": "password",
            "showStrength": true,
            "showInfo": true,
            "showVisibilityToggle": true,
            "label": "New Password",
            default: '',
            excludeWrapper: true,
            props: PasswordProps
        }), RubyComponent.createElement(FieldPassword, {
            id: this.props.id + ':password_confirmation',
            "key": "password_confirmation",
            "label": "Confirm New Password",
            excludeWrapper: true,
            props: PasswordProps
        }), RubyComponent.createElement(FieldButton, {
            label: 'Reset Password',
            action: 'submitToRemote',
            styles: {
                Button: {
                    style: {
                        marginTop: 20,
                        width: '100%'
                    },
                    primary: true,
                    secondary: false
                }
            }
        }))]];
    },
    selector: function selector() {
        var selfModule = this;
        var selfSelector = selfModule.getDefaultSelector();

        return RubyComponent.composeSelectors(selfSelector, rubyMiddlewareFields.selector);
    },
    dependencies: function dependencies() {
        var selfModule = this;
        var selfSelector = selfModule.getSelfStateSelector();
        var selfActions = selfModule.getAction().generators;
        var rootModule = selfModule.getRoot();

        var feSettingsID = this.props.feSettingsID || RubyComponentFESettings.componentName;
        var feSettingsComponent = rootModule.findDescendentByID(feSettingsID);
        var feSettingsSelector = feSettingsComponent.getDefaultSelector();

        var rubyClientFKSelector = rootModule.findDescendentByID('rubyComponentRubyClientFK').getDefaultSelector();

        var currentUserID = this.props.currentUserID || RubyComponentCurrentUser.componentName;
        var currentUser = rootModule.findDescendentByID(currentUserID);
        var currentUserActions = currentUser.getAction().generators;
        var currentUserSelector = currentUser.getSelfStateSelector();

        var alertDialogID = this.props.alertDialogID || 'rubyComponentAlertDialog';
        var alertDialog = rootModule.findDescendentByID(alertDialogID);

        return {
            selfSelector: selfSelector,
            selfActions: selfActions,
            feSettingsSelector: feSettingsSelector,
            rubyClientFKSelector: rubyClientFKSelector,
            currentUserActions: currentUserActions,
            currentUserSelector: currentUserSelector,
            alertDialog: alertDialog
        };
    },
    getReactClass: function getReactClass() {
        var ForgotPasswordComponent = _ForgotPasswordConnector2.default.apply(this);
        return ForgotPasswordComponent;
    },
    getRouteElement: function getRouteElement() {
        var _React$createElement;

        var ForgotPassword = this.getReactClass();

        return _react2.default.createElement(_reactRouter.Route, (_React$createElement = { name: '', component: ForgotPassword, path: this.props.path, key: componentName, 'data-codecept-selector-node': 'Route',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Route'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Route'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement));
    },
    statesSelector: function statesSelector(state) {
        var _getDependencies2 = this.getDependencies(),
            selfSelector = _getDependencies2.selfSelector,
            feSettingsSelector = _getDependencies2.feSettingsSelector,
            rubyClientFKSelector = _getDependencies2.rubyClientFKSelector,
            currentUserSelector = _getDependencies2.currentUserSelector;

        return {
            selfState: selfSelector(state),
            feSettingsState: feSettingsSelector(state),
            rubyClientFKState: rubyClientFKSelector(state),
            currentUserState: currentUserSelector(state)
        };
    }

    //# Utility
    , triggerFormSubmit: function triggerFormSubmit() {
        var _getChildren = this.getChildren(),
            _getChildren2 = _slicedToArray(_getChildren, 1),
            form = _getChildren2[0];

        this.getStore().dispatch(form.getAction().generators['submitToRemote']());
    }
});

module.exports = RCForgotPasswordPage;