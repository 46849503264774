'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reduxBatchedActions = require('redux-batched-actions');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var Promise = require('bluebird');


module.exports = {
    //rubyApp.findDescendentBy(node => node.props.key == 'arrayMap_object_multiple').testFormValue({"arrayMap_object_multiple":[{"myText":"test","myText2":"asd"}]})
    /* 
    testHelperReduxStore: function(formValue) {
        const collectedActions = [];
        const collectAction = (value) => {collectedActions.push(value)}
         const helperReduxStore = this.helperReduxStore();
         const promisedFormValueToLocalState = this.formValueToLocalState(formValue, collectAction);
         promisedFormValueToLocalState.then((res) => {
            helperReduxStore;
            collectedActions;
             helperReduxStore.dispatch(batchActions(collectedActions));
            console.log(JSON.stringify(helperReduxStore.getLocalState(), null, '  '));
            console.log(JSON.stringify(helperReduxStore.getState(), null, '  '));
            debugger;
        })
    }
    */

    _displayValueFromLocalState: function _displayValueFromLocalState(selfState, entireState) {
        var _this = this;

        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        //const children = this.getChildren();
        var helperSelf = this.cachedHelperSelf();
        var helperChildren = helperSelf.getChildren();
        //const helperChildren = this.cachedHelperChildren();
        var helperReduxStore = helperSelf.getStore(); //this.cachedHelperReduxStore();

        //# get fieldValue from selfState
        var fieldValueArr = this.getFieldValueFromLocalState(selfState) || [];

        //# seed self's formValue into children
        var promiseArrArr = fieldValueArr.map(function (fieldValue) {
            var collectorSpec = _this.newActionCollectorSpec();

            //# NOTE: cannot call on this on the regular children, we need 
            //# to use helperChildren instances that has been patched. This is because
            //# we have children who might internally call on getStore().dispatch, which we don't want
            return Promise.all(helperSelf.formValueToChildrenLocalState(fieldValue, collectorSpec.collectAction)).then(function () {
                //# dispatch actions in helperReduxStore
                helperReduxStore.dispatch((0, _reduxBatchedActions.batchActions)(collectorSpec.collectedActions));

                //console.log(JSON.stringify(helperReduxStore.getState(), null, '  '));

                //# then create a new selfState for children
                var newSelfState = helperReduxStore.getLocalState()[_this.getID()];
                var newEntireState = entireState;
                _lodash2.default.set(entireState, _this.getKeypathArr(), newSelfState);

                var arrOfReducedChildrenPromises = _lodash2.default.reduce(helperChildren, function (collector, child, index) {
                    var childID = child.getID();
                    var childDisplayValue = child._displayValueFromLocalState ? child._displayValueFromLocalState(newSelfState[childID], newEntireState, options) : undefined;

                    if (childDisplayValue != undefined) {
                        collector.push(childDisplayValue);
                    }
                    return collector;
                }, []);

                var reducedChildrenPromise = Promise.all(arrOfReducedChildrenPromises).then(function (arrOfReducedChildrenState) {
                    var mergedDisplayValue = _extends.apply(undefined, [{}].concat(_toConsumableArray(arrOfReducedChildrenState)));

                    return Object.keys(mergedDisplayValue).length == 1 && mergedDisplayValue.hasOwnProperty(undefined) ? mergedDisplayValue[undefined] : mergedDisplayValue;
                });

                return reducedChildrenPromise;
            });
        });

        var promiseArr = [].concat.apply([], promiseArrArr);

        return Promise.all(promiseArr);
    }
};