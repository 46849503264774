'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Toolbar = require('material-ui/Toolbar');

var _IconButton = require('material-ui/IconButton/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _IconMenu = require('material-ui/IconMenu');

var _IconMenu2 = _interopRequireDefault(_IconMenu);

var _moreVert = require('material-ui/svg-icons/navigation/more-vert');

var _moreVert2 = _interopRequireDefault(_moreVert);

var _AddEntryButton = require('@rubyapps/ruby-component-list-page/src/client/reactComponents/AddEntryButton');

var _AddEntryButton2 = _interopRequireDefault(_AddEntryButton);

var _Toggle = require('@rubyapps/ruby-component-list-page/src/client/reactComponents/Toggle');

var _Toggle2 = _interopRequireDefault(_Toggle);

var _FilterDropdown = require('@rubyapps/ruby-component-list-page/src/client/reactComponents/FilterDropdown');

var _FilterDropdown2 = _interopRequireDefault(_FilterDropdown);

var _additionalContextMenuItems = require('@rubyapps/ruby-component-mixin-lister-content-defaults/src/client/reactComponents/UtilityBar/additionalContextMenuItems');

var _additionalContextMenuItems2 = _interopRequireDefault(_additionalContextMenuItems);

var _styles = require('@rubyapps/ruby-component-mixin-lister-content-defaults/src/client/reactComponents/UtilityBar/styles.cssModule');

var _styles2 = _interopRequireDefault(_styles);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;
//# styles


var SitemapUtilityBar = _react2.default.createClass({
    displayName: 'SitemapUtilityBar',

    propTypes: {
        addEntryProps: PropTypes.object,
        filterPropsArr: PropTypes.array,
        toggleProps: PropTypes.object,
        allowColumnSelection: PropTypes.bool
    },
    _renderFilters: function _renderFilters() {
        var _React$createElement2;

        var hasFilters = Array.isArray(this.props.filterPropsArr) && this.props.filterPropsArr.length > 0;

        if (!hasFilters) {
            return null;
        }

        var dropdownsWithSeparators = this.props.filterPropsArr.reduce(function (result, filterProps) {
            var _extends2, _React$createElement;

            var key = filterProps.name;
            result.push(_react2.default.createElement(_FilterDropdown2.default, _extends({ key: key }, filterProps, (_extends2 = {
                'data-codecept-selector-node': 'CustomFilterDropdown',
                'data-codecept-selector-file': 'UtilityToolbarGroup'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'CustomFilterDropdown'), _defineProperty(_extends2, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _defineProperty(_extends2, 'data-codecept-selector-node', 'CustomFilterDropdown'), _defineProperty(_extends2, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _extends2))), _react2.default.createElement(_Toolbar.ToolbarSeparator, (_React$createElement = {
                key: 'separator-' + key,
                className: _styles2.default.ToolbarSeparator,
                'data-codecept-selector-node': 'ToolbarSeparator',
                'data-codecept-selector-file': 'UtilityToolbarGroup'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ToolbarSeparator'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ToolbarSeparator'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _React$createElement)));
            return result;
        }, []);

        return [].concat(_react2.default.createElement(_Toolbar.ToolbarTitle, (_React$createElement2 = { key: 'filter-label', text: 'Filter:', 'data-codecept-selector-node': 'ToolbarTitle',
            'data-codecept-selector-file': 'UtilityToolbarGroup'
        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'ToolbarTitle'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'ToolbarTitle'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _React$createElement2)), dropdownsWithSeparators);
    },
    render: function render() {
        var _React$createElement3, _extends3, _React$createElement4, _extends4, _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8;

        var additionalMenuItems = (0, _additionalContextMenuItems2.default)([], this.props);

        return _react2.default.createElement(
            _Toolbar.ToolbarGroup,
            (_React$createElement8 = { className: _styles2.default.ToolbarGroup, 'data-codecept-selector-node': 'ToolbarGroup',
                'data-codecept-selector-file': 'UtilityToolbarGroup'
            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _React$createElement8),
            _react2.default.createElement(_Toolbar.ToolbarTitle, (_React$createElement3 = { text: 'Modify Outline:', 'data-codecept-selector-node': 'ToolbarTitle',
                'data-codecept-selector-file': 'UtilityToolbarGroup'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ToolbarTitle'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ToolbarTitle'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _React$createElement3)),
            _react2.default.createElement(_Toggle2.default, _extends({}, this.props.toggleProps, (_extends3 = {
                'data-codecept-selector-node': 'CustomToggle',
                'data-codecept-selector-file': 'UtilityToolbarGroup'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'CustomToggle'), _defineProperty(_extends3, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _defineProperty(_extends3, 'data-codecept-selector-node', 'CustomToggle'), _defineProperty(_extends3, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _extends3))),
            _react2.default.createElement(_Toolbar.ToolbarSeparator, (_React$createElement4 = { className: _styles2.default.ToolbarSeparator, 'data-codecept-selector-node': 'ToolbarSeparator',
                'data-codecept-selector-file': 'UtilityToolbarGroup'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ToolbarSeparator'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ToolbarSeparator'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _React$createElement4)),
            this._renderFilters(),
            _react2.default.createElement(_AddEntryButton2.default, _extends({}, this.props.addEntryProps, (_extends4 = {
                'data-codecept-selector-node': 'AddEntryButton',
                'data-codecept-selector-file': 'UtilityToolbarGroup'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'AddEntryButton'), _defineProperty(_extends4, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _defineProperty(_extends4, 'data-codecept-selector-node', 'AddEntryButton'), _defineProperty(_extends4, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _extends4))),
            additionalMenuItems.length > 0 && _react2.default.createElement(
                _IconMenu2.default,
                (_React$createElement7 = {
                    key: 'ruby-actions_context-menu',
                    iconButtonElement: _react2.default.createElement(
                        _IconButton2.default,
                        (_React$createElement6 = {
                            style: { marginRight: -24 },
                            'data-codecept-selector-node': 'IconButton',
                            'data-codecept-selector-file': 'UtilityToolbarGroup'
                        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _React$createElement6),
                        _react2.default.createElement(_moreVert2.default, (_React$createElement5 = {
                            'data-codecept-selector-node': 'MoreVertIcon',
                            'data-codecept-selector-file': 'UtilityToolbarGroup'
                        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _React$createElement5))
                    ),
                    anchorOrigin: { horizontal: 'right', vertical: 'top' },
                    targetOrigin: { horizontal: 'right', vertical: 'top' },
                    'data-codecept-selector-node': 'IconMenu',
                    'data-codecept-selector-file': 'UtilityToolbarGroup'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _React$createElement7),
                additionalMenuItems
            )
        );
    }
});

exports.default = SitemapUtilityBar;