'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _rubycontentLogo = require('@rubyapps/ruby-assets/rubycontent-logo.svg');

var _rubycontentLogo2 = _interopRequireDefault(_rubycontentLogo);

var _rubylawLogo = require('@rubyapps/ruby-assets/rubylaw-logo.svg');

var _rubylawLogo2 = _interopRequireDefault(_rubylawLogo);

var _rubyappsLogo = require('@rubyapps/ruby-assets/rubyapps-logo.svg');

var _rubyappsLogo2 = _interopRequireDefault(_rubyappsLogo);

var _styles = require('./styles.cssModule');

var _styles2 = _interopRequireDefault(_styles);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var brandingToLogoMap = {
    "RubyLaw": _rubylawLogo2.default,
    "RubyContent": _rubycontentLogo2.default,
    "RubyApps": _rubyappsLogo2.default,
    "default": _rubyappsLogo2.default
};

var Logo = _react2.default.createClass({
    displayName: 'Logo',

    propTypes: {
        frontendSettings: PropTypes.object.isRequired,
        onClick: PropTypes.func
    },
    render: function render() {
        var _React$createElement, _React$createElement2;

        var rubyBranding = _lodash2.default.get(this, 'props.frontendSettings.RUBY_BRANDING');
        var rubyLogo = brandingToLogoMap[rubyBranding] || brandingToLogoMap.default;

        return _react2.default.createElement(
            'div',
            (_React$createElement2 = {
                className: _styles2.default.rubyLogoWrapper + ' rubyLogoWrapper' //# NOTE: not great, but need to be able to address rubyLogoWrapper from other cssModules
                , onClick: this.props.onClick,
                style: {
                    cursor: this.props.onClick ? 'pointer' : null
                },
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'browser'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'browser'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'browser'), _React$createElement2),
            _react2.default.createElement('img', (_React$createElement = {
                className: _styles2.default.img,
                src: rubyLogo,
                alt: rubyBranding,
                'data-codecept-selector-node': 'img',
                'data-codecept-selector-file': 'browser'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'img'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'browser'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'img'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'browser'), _React$createElement))
        );
    }
});

exports.default = Logo;