'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _RaisedButton = require('material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _Activity_style = require('../Activity_style');

var _Activity_style2 = _interopRequireDefault(_Activity_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = process.browser ? require('../Activity.cssModule').default : {};

var ViewEditButtons = _react2.default.createClass({
    displayName: 'ViewEditButtons',

    customMixins: [] //# non-reserved key to handle special mixins where we want to handle calling them ourselves
    , renderViewButton: function renderViewButton() {
        var _React$createElement, _React$createElement2, _React$createElement3;

        var _props = this.props,
            entity = _props.entity,
            entityId = _props.entity_id,
            entityName = _props.entity_name,
            viewEditActions = _props.viewEditActions,
            www_server = _props.www_server;
        var viewUrl = entity.url;
        var editEntity = viewEditActions.editEntity;


        return www_server && viewUrl ? _react2.default.createElement(
            'a',
            (_React$createElement2 = { target: '_blank', href: www_server + viewUrl, key: 'view', 'data-codecept-selector-node': 'a',
                'data-codecept-selector-file': 'view-edit-buttons'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'view-edit-buttons'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'view-edit-buttons'), _React$createElement2),
            _react2.default.createElement(_RaisedButton2.default, (_React$createElement = {
                label: 'View',
                style: _Activity_style2.default.viewEditButton,
                'data-codecept-selector-node': 'Button',
                'data-codecept-selector-file': 'view-edit-buttons'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Button'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'view-edit-buttons'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Button'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'view-edit-buttons'), _React$createElement))
        ) : _react2.default.createElement(_RaisedButton2.default, (_React$createElement3 = {
            label: 'View',
            style: _Activity_style2.default.viewEditButton,
            disabled: true,
            'data-codecept-selector-node': 'Button',
            'data-codecept-selector-file': 'view-edit-buttons'
        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Button'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'view-edit-buttons'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Button'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'view-edit-buttons'), _React$createElement3));
    },
    renderEditButton: function renderEditButton() {
        var _React$createElement4;

        var _props2 = this.props,
            entity = _props2.entity,
            entityId = _props2.entity_id,
            entityName = _props2.entity_name,
            viewEditActions = _props2.viewEditActions,
            www_server = _props2.www_server;
        var viewUrl = entity.url;
        var editEntity = viewEditActions.editEntity;


        _react2.default.createElement(_RaisedButton2.default, (_React$createElement4 = {
            label: 'Edit',
            style: _extends({}, _Activity_style2.default.viewEditButton, { float: 'right' }),
            onClick: function onClick(e) {
                editEntity(entityName, entityId);
            },
            'data-codecept-selector-node': 'Button',
            'data-codecept-selector-file': 'view-edit-buttons'
        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'Button'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'view-edit-buttons'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'Button'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'view-edit-buttons'), _React$createElement4));
    },
    render: function render() {
        var _this = this,
            _React$createElement5;

        var viewButton = this.customMixins.reduce(function (collector, mixin) {
            if (collector) {
                return collector;
            }

            return mixin.renderViewButton && mixin.renderViewButton.apply(_this);
        }, null) || this.renderViewButton();

        var editButton = this.customMixins.reduce(function (collector, mixin) {
            if (collector) {
                return collector;
            }

            return mixin.renderEditButton && mixin.renderEditButton.apply(_this);
        }, null) || this.renderEditButton();

        return _react2.default.createElement(
            'div',
            (_React$createElement5 = { className: styles.viewEditButtons, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'view-edit-buttons'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'view-edit-buttons'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'view-edit-buttons'), _React$createElement5),
            viewButton,
            editButton
        );
    }
});

exports.default = ViewEditButtons;