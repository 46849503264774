'use strict';

var request = require('@rubyapps/ruby-superagent');

var rubyMiddlewareFields = require('@rubyapps/ruby-middleware-fields');

function typesFromID(id) {
    var TYPES = {
        OPEN_ALTER_TEMPLATE_DIALOG: '@@ruby-app/' + id + '/OPEN_ALTER_TEMPLATE_DIALOG',
        CLOSE_ALTER_TEMPLATE_DIALOG: '@@ruby-app/' + id + '/CLOSE_ALTER_TEMPLATE_DIALOG',

        OPEN_ALTER_TEMPLATE_DIALOG_CONFIRMATION: '@@ruby-app/' + id + '/OPEN_ALTER_TEMPLATE_DIALOG_CONFIRMATION',
        CLOSE_ALTER_TEMPLATE_DIALOG_CONFIRMATION: '@@ruby-app/' + id + '/CLOSE_ALTER_TEMPLATE_DIALOG_CONFIRMATION',

        SET_OPTIONS: '@@ruby-app/' + id + '/SET_OPTIONS',
        ALTER_TEMPLATE: '@@ruby-app/' + id + '/ALTER_TEMPLATE',

        RESET_ALTER_TEMPLATE_DIALOG: '@@ruby-app/' + id + '/RESET_ALTER_TEMPLATE_DIALOG'
    };
    return TYPES;
}

var generators = {
    openAlterTemplateDialog: function openAlterTemplateDialog() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES,
            generators = _getAction.generators;

        var selfModule = this;
        return function (dispatch, getState) {
            //# determine the initialization state of the form

            dispatch(generators.setOptions(options));
            dispatch({
                type: TYPES.OPEN_ALTER_TEMPLATE_DIALOG
            });
        };
    },
    setOptions: function setOptions(options) {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES;

        var selfModule = this;

        return {
            type: TYPES.SET_OPTIONS,
            payload: options
        };
    },
    closeAlterTemplateDialog: function closeAlterTemplateDialog() {
        var _getAction3 = this.getAction(),
            TYPES = _getAction3.TYPES;

        return {
            type: TYPES.CLOSE_ALTER_TEMPLATE_DIALOG
        };
    },
    closeAndReset: function closeAndReset() {
        var _getAction4 = this.getAction(),
            actions = _getAction4.generators;

        return function (dispatch) {
            dispatch(actions.closeAlterTemplateDialog());

            setTimeout(function () {
                dispatch(actions.resetAlterTemplateDialog());
            }, 500);
        };
    },
    openConfirmation: function openConfirmation() {
        var _getAction5 = this.getAction(),
            TYPES = _getAction5.TYPES;

        return {
            type: TYPES.OPEN_ALTER_TEMPLATE_DIALOG_CONFIRMATION
        };
    },
    closeConfirmation: function closeConfirmation() {
        var _getAction6 = this.getAction(),
            TYPES = _getAction6.TYPES;

        return {
            type: TYPES.CLOSE_ALTER_TEMPLATE_DIALOG_CONFIRMATION
        };
    },
    alterTemplate: function alterTemplate(payload) {
        var selfModule = this;
        var selfSelectorObj = selfModule.getSelector();

        return function (dispatch, getState) {
            var applicationState = getState();
            var selfState = selfSelectorObj.default(applicationState);
            var endpoint = selfState.endpoint;


            request.post(endpoint).query({ toTemplate: payload.templateKey }).then(function onResult(response) {
                selfState.submitSuccessCallback && selfState.submitSuccessCallback(response);
            }, function onError(err) {
                selfState.submitFailCallback && selfState.submitFailCallback(err);
            });
        };
    },
    resetAlterTemplateDialog: function resetAlterTemplateDialog() {
        var _getAction7 = this.getAction(),
            TYPES = _getAction7.TYPES;

        return {
            type: TYPES.RESET_ALTER_TEMPLATE_DIALOG
        };
    },
    closeAndAlterTemplate_thenResetAlterTemplateDialog: function closeAndAlterTemplate_thenResetAlterTemplateDialog(payload) {
        var _this = this;

        var _getAction8 = this.getAction(),
            generators = _getAction8.generators;

        return function (dispatch, getState) {
            dispatch(generators.closeConfirmation());
            dispatch(generators.closeAlterTemplateDialog());
            dispatch(generators.alterTemplate.call(_this, payload));
            dispatch(generators.resetAlterTemplateDialog());
        };
    }
};

module.exports = function () {
    var TYPES = typesFromID(this.getID());
    return {
        TYPES: TYPES,
        generators: generators
    };
};