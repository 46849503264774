'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _menu = require('../common/menu');

var _menu2 = _interopRequireDefault(_menu);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var HeaderConnector = require('./reactComponents/HeaderConnector');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var accessControlMixin = require('@rubyapps/ruby-component-mixin-access-control');
var persistenceMixin = require('@rubyapps/ruby-component-mixin-persistence');

var RubyComponentCurrentUser = require('@rubyapps/ruby-component-current-user');
var RubyComponentFrontendSettings = require('@rubyapps/ruby-component-frontend-settings');
var RubyComponentRecentUpdates = require('@rubyapps/ruby-component-recent-updates');
var rubyAccessManager = require('@rubyapps/ruby-access-manager');

var action = require('./action');
var reducer = require('./reducer');


var jsonselect = require('JSONSelect');

var RCHeader = RubyComponent.createClass({
    mixins: [accessControlMixin, persistenceMixin],
    propTypes: {
        menuConfig: PropTypes.object,
        reactProps: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            keysToPersist: ['menuIsPinned', 'menuDrawerOpen']
        };
    },
    componentName: componentName,
    CONSTANTS: CONSTANTS,
    action: action,
    reducer: reducer,
    dependencies: function dependencies() {
        //# declare dependencies here so it's easy to find
        var rootComponent = this.getRoot();

        var selfSelector = this.getSelfStateSelector();
        var selfActions = this.getAction().generators;

        var frontendSettingsID = this.props.frontendSettingsID || RubyComponentFrontendSettings.CONSTANTS.COMPONENT_NAME;
        var frontendSettingsComponent = rootComponent.findDescendentByID(frontendSettingsID);
        var frontendSettingsSelector = frontendSettingsComponent.getDefaultSelector();

        var currentUserID = this.props.currentUserID || RubyComponentCurrentUser.CONSTANTS.COMPONENT_NAME;
        var currentUser = rootComponent.findDescendentByID(currentUserID);
        var currentUserSelector = currentUser ? currentUser.getSelfStateSelector() : function (state) {
            return state;
        };

        var recentUpdatesID = this.props.recentUpdatesID || RubyComponentRecentUpdates.CONSTANTS.COMPONENT_NAME;
        var recentUpdates = rootComponent.findDescendentByID(recentUpdatesID);
        var recentUpdatesActions = recentUpdates.getAction().generators;

        return {
            selfSelector: selfSelector,
            selfActions: selfActions,
            frontendSettingsSelector: frontendSettingsSelector,
            currentUser: currentUser,
            currentUserSelector: currentUserSelector,
            currentUserActions: currentUser.getAction().generators,
            recentUpdatesActions: recentUpdatesActions
        };
    },

    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector,
            currentUserSelector = _getDependencies.currentUserSelector,
            frontendSettingsSelector = _getDependencies.frontendSettingsSelector;

        return {
            self: selfSelector(state),
            user: currentUserSelector(state),
            frontendSettings: frontendSettingsSelector(state),
            forms: this.getForms(state),
            routing: state.routing //# need to update based on routing changes
        };
    },
    getReactClass: function getReactClass() {
        var HeaderComponent = HeaderConnector.apply(this);
        return HeaderComponent;
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var HeaderComponent = this.getReactClass();
        var reactProps = this.props.reactProps;
        //# perform connection
        //# need to access the ruby_client_fk
        //# action to open the compare dialog should give the templatekey
        return React.createElement(HeaderComponent, _extends({}, reactProps, (_extends2 = {
            'data-codecept-selector-node': 'HeaderComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'HeaderComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'HeaderComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    }
    //#== Utility methods ====//
    , menuConfigFromSelf: function menuConfigFromSelf() {
        return (0, _menu2.default)(this);
    },

    menuConfig: function menuConfig() {
        var menuConfig = this.props.menuConfig || this.menuConfigFromSelf();
        return menuConfig;
    },

    filteredMenuConfig: function filteredMenuConfig(menuConfig, currentUserInfo) {
        if (!menuConfig) {
            menuConfig = this.menuConfig();
        }
        if (!currentUserInfo) {
            var _getDependencies2 = this.getDependencies(),
                currentUser = _getDependencies2.currentUser;

            currentUserInfo = currentUser.getState();
        }

        return rubyAccessManager.filteredMenuConfig_withUserObject(menuConfig, currentUserInfo);
    },
    getPermittedMenuSpecForId: function getPermittedMenuSpecForId(id, menuConfig, currentUserInfo) {
        var filteredMenuConfig = this.filteredMenuConfig(menuConfig, currentUserInfo);
        //# loop through and find id

        var selector = jsonselect.compile(':has(:root > .id:val("' + id + '"))');
        return selector.match(filteredMenuConfig)[0];
    }
});

module.exports = RCHeader;