'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _MissingComponentPlaceholder = require('./MissingComponentPlaceholder');

var _MissingComponentPlaceholder2 = _interopRequireDefault(_MissingComponentPlaceholder);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var rubyLogger = require('@rubyapps/ruby-logger');
var logger = rubyLogger.getLogger('ruby-component');

var _ = require('lodash');
var React = require('react');
var path = require('path');

var routeMixin = require('./routeMixin/index');
var routeHelperMixin = require('./routeHelperMixin/index');

var defaultMergeProps = function defaultMergeProps(stateProps, dispatchProps, parentProps) {
    return _extends({}, stateProps, dispatchProps, parentProps);
};

var baseElement = require('./baseComponent');
var baseClass = require('./baseClass');
var baseMixinClass = baseClass;

function getMergedWithPropsChildren(props, childrenReactElements) {
    //# split up the insertion order for the reactElements provided by all
    //# of the rubyComponent children elements;
    var headerChildrenReactElements = childrenReactElements[0];
    var footerChildrenReactElements = childrenReactElements[1];
    var mergedChildrenReactElements = void 0;
    var internalChildrenReactElements = _.get(props, ['children'], []);

    if (_.isNil(internalChildrenReactElements)) {
        mergedChildrenReactElements = childrenReactElements;
    } else {
        mergedChildrenReactElements = headerChildrenReactElements.concat(internalChildrenReactElements, footerChildrenReactElements);
    }
    return mergedChildrenReactElements;
}

var errorObject = { value: null };
function tryCatch(fn, ctx) {
    try {
        return fn.apply(ctx);
    } catch (e) {
        errorObject.value = e;
        return errorObject;
    }
}

var isDevMode = "production" !== 'production';

//# NOTE: used for debugging which specific fields are different if you need to 
//# check shouldComponentUpdate
/*
const jsondiffpatch = require('jsondiffpatch');
const myJsondiffpatch = jsondiffpatch.create({
    propertyFilter: (name, context) => {
        //console.log(`==== [DEBUG:jsondiffpatch] name [${name}]`);
        if (_.isFunction(context.left[name]) || _.isFunction(context.right[name])) {
            return false
        }
        return true;
    }
})
//myJsondiffpatch.diff(objectA, objectB)
*/

var isEqualCustomizer = function isEqualCustomizer(objValue, othValue, key, object, other, stack) {
    //# don't compare functions
    if (_.isFunction(objValue) && _.isFunction(othValue)) {
        var objValue_str = objValue.toString();
        var othValue_str = othValue.toString();

        //console.log(`===== [DEBUG:objValue]`, objValue_str);
        //console.log(`===== [DEBUG:othValue]`, othValue_str);
        //console.log(`===== [DEBUG:obj|othValue] same?`, othValue_str == objValue_str);

        return othValue_str == objValue_str;
    }
};
function patchShouldComponentUpdateInClass_usingContext(SelfReactClass, context) {
    //# it's a connected element, patch its prototype with custom shouldComponentUpdate
    //# where context = the rubyComponent

    //# patch shouldComponentUpdate
    var connectedShouldComponentUpdate = SelfReactClass.prototype.shouldComponentUpdate;

    if (SelfReactClass.prototype.shouldComponentUpdate.PATCHED_WITH_RUBYCOMPONENT) {
        return;
    }

    if (context.hasOwnProperty('getShouldComponentUpdate')) {
        SelfReactClass.prototype.shouldComponentUpdate = context.getShouldComponentUpdate(connectedShouldComponentUpdate);
    } else {

        SelfReactClass.prototype.shouldComponentUpdate = function (nextProps, nextState) {
            //console.time('header connect');
            var nextApplicationState = this.store.getState();
            //const currApplicationState = this.state.storeState;

            var statesSelector = context.getStatesSelector();

            var currSelectedState = this._selectedState;
            var nextSelectedState = statesSelector(nextApplicationState, currSelectedState);
            //const currSelectedState = statesSelector(currApplicationState);

            //# this method is unreliable for the connected list page (sitemap) for some reason
            /*
            for (const stateKey in nextStates) {
                if (nextStates[stateKey] != currStates[stateKey]) {
                    console.log("==== RERENDERING ", this.renderedElement.type.displayName, this.renderedElement.props.componentName, nextStates[stateKey]);
                     return true;
                }
            }
            */

            this._pendingSelectedState = nextSelectedState; //# We save the nextSelectedState regardless of whether it's different
            //# because _.isEqual() leverages object references to do it's comparison so if the state is the same
            //# we want to cache it to speed up the next comparison (ie. in one cycle, isEqual had to traverse the entire tree
            //# , but the next time it gets called, it can rely on the reference being the same)
            //# NOTE: we cannot set this._selectedState yet until we can confirm that the component actually rerendered
            var isEqual = _.isEqualWith(nextSelectedState, currSelectedState, isEqualCustomizer);

            if (!isEqual) {
                return true;
            }

            return this.haveOwnPropsChanged;
        };

        //# but if we were to use Connect(mapStateToProps ... ,{pure: false})
        var baseRender = SelfReactClass.prototype.render;
        SelfReactClass.prototype.render = function render() {
            //if (context.props.id && context.props.id.indexOf('background_asset__mediaGallery') >= 0) { console.log(`[DEBUG][render override] for context`, context.props) }
            var curr_renderedElement = this.renderedElement;

            var new_renderedElement = baseRender.apply(this);

            if (curr_renderedElement != new_renderedElement) {
                //# actual rerender, we can clear out stat
                this._selectedState = this._pendingSelectedState;
            }

            return new_renderedElement;
        };
    }

    SelfReactClass.prototype.shouldComponentUpdate.PATCHED_WITH_RUBYCOMPONENT = true;
}

var commonUtils = require('../common/utils');

var RubyComponent = {
    //# helper methods
    PropTypes: React.PropTypes //# leveraging React.PropTypes
    , isComponent: function isComponent(component) {
        if (component._rubyComponent) {
            return true;
        }
        return false;
    }

    /*
     *  USAGE: 
     *  createClass({
     *      componentName:
     *      middleware:
     *      action: {
     *          TYPE: {}
     *          generators: {}
     *      }
     *      reducer
     *      initialize
     *      render: function() { return <ReactComponent> }
     *  })
     * */
    ,
    createClass: function createClass(componentDefinition) {
        //# check for existence of propTypes
        if (_.isNil(componentDefinition.propTypes)) {
            if (isDevMode) {
                //# TODO: go through and define the propTypes for our current components so that we can re-enable this warning
                //logger.warn('propTypes is undefined for: ' + componentDefinition.componentName);
            }
        }

        var createdClass = _.assign({}, baseClass, componentDefinition, {
            _rubyComponent: true
        });

        //# inject routeMixin if componentDefinition has getRouteElement
        if (componentDefinition.hasOwnProperty('getRouteElement')) {
            createdClass.mixins = [routeMixin].concat(createdClass.mixins || []);
        } else {
            createdClass.mixins = [routeHelperMixin].concat(createdClass.mixins || []);
        }
        return createdClass;
    },
    createMixin: function createMixin(componentDefinition) {
        return _.assign({}, baseClass, componentDefinition, {
            _rubyComponentMixin: true
        });
    },
    _supportedMixinAttributes: ['propTypes', 'action', 'reducer', 'middleware', 'getInitialState', 'onReduxInit', 'onReduxTearDown', 'onMount', 'onUnmount', 'onReactInit', 'dependencies'
    //, 'getDefaultProps'
    ] //# other defined functions or objects would be overwritten 
    , _generateNewSelf: function _generateNewSelf(componentClass, props, _parent) {
        //# handle mixin first
        var preMixins = componentClass.mixins ? componentClass.mixins : [];

        var mixins = __deepFlatMapMixins([], preMixins, arguments).sort(function (mixinA, mixinB) {
            //# sort mixins by mixinIndex
            var mixinAIndex = mixinA.mixinIndex || 0;
            var mixinBIndex = mixinB.mixinIndex || 0;

            return mixinAIndex - mixinBIndex;
        });

        var preppedMixinObject = _.reduce(mixins.concat(componentClass), function (collector, mixinObject, index) {
            RubyComponent._supportedMixinAttributes.forEach(function (attr) {
                if (mixinObject[attr]) {
                    collector[attr] = (collector[attr] || []).concat(mixinObject[attr]);
                }
            });
            return collector;
        }, {
            propTypes: [],
            action: [],
            reducer: [],
            middleware: [],
            dependencies: []
            //, getDefaultProps: []
        });

        //# allow for component methods as well
        //# Previously, the existence of mixins would have prevented the RubyComponents from defining methods which the mixins have defined
        RubyComponent._supportedMixinAttributes.forEach(function (attr) {
            if (RubyComponent[attr]) {
                collector[attr] = (collector[attr] || []).concat(mixinObject[attr]);
            }
        });

        preppedMixinObject.propTypes = _extends.apply(undefined, [{}].concat(_toConsumableArray(preppedMixinObject.propTypes)));

        var unsupportedMixinAttributes = _.omit(_extends.apply(undefined, [{}].concat(_toConsumableArray(mixins))), RubyComponent._supportedMixinAttributes);
        //# these are overridden by the preppedComponentClass;

        var preppedComponentClass = _extends({
            _flattenedMixins: mixins
        }, preppedMixinObject, unsupportedMixinAttributes, _.omit(componentClass, RubyComponent._supportedMixinAttributes), {
            mixins: mixins //# replace the nested mixins with the flattened version of the mixins (only really used for getDefaultProps)
        });

        var newSelf = _extends(Object.create(baseElement), preppedComponentClass);
        newSelf._parent = _parent;

        //# wrap getDefaultProps
        /* //# TODO: 20170530 - we'll need to revisit this
        const preppedGetDefaultProps = preppedComponentClass.getDefaultProps;
        newSelf.getDefaultProps = function() {
            const hydratedDefaultProps = preppedGetDefaultProps.map((getDefaultProps) => {
                getDefaultProps.apply(this, arguments);
            });
             const defaultProps = Object.assign.apply(Object, defaultPropsArr);
        }
        */
        return newSelf;

        function __deepFlatMapMixins(collector, mixins, callerArguments) {
            var hydratedMixins = mixins.reduce(function (collector, mixin) {
                var mixinObject = _.isFunction(mixin) ? mixin.apply(null, callerArguments) : mixin;

                if (_.isArray(mixinObject.mixins)) {
                    collector = __deepFlatMapMixins(collector, mixinObject.mixins, callerArguments);
                }

                collector.push(_.omit(mixinObject, ['mixins']));

                return collector;
            }, collector);

            return collector;
        }
    }
    /*
     *  USAGE: 
     *  createElement(componentClass, {
     *      id:
     *      otherPropValues:
     *  }, ...children)
     * */
    , createElement: function createElement(componentClass, props /* ... children rubyComponents ...*/) {
        if (componentClass == undefined) {
            if (isDevMode) {
                logger.warn('componentClass is undefined in a call to rubyComponent/client/index.js -> _generateNewSelf. This is most likely because you are requiring a component in your src/client/index.js file that is unavailable. Check the console trace to se the origin of the call');
                console.trace();
            }
            componentClass = (0, _MissingComponentPlaceholder2.default)(RubyComponent);
        }

        var _parent = props ? props._parent : undefined;
        if (props) {
            delete props._parent;
        }

        var self = RubyComponent._generateNewSelf(componentClass, props, _parent);

        var selfMixins = self._flattenedMixins || [];
        //# allow mixins to provide default getDefaultProps() method
        var defaultPropsArr = selfMixins.reduce(function (collector, mixin) {
            if (mixin.hasOwnProperty('getDefaultProps')) {
                collector.push(mixin.getDefaultProps(props, componentClass, collector)); //# pass the collected default props to allow getDefaultProps to decide whether to merge
            }
            return collector;
        }, [{}]);

        if (self.hasOwnProperty('getDefaultProps')) {
            defaultPropsArr.push(self.getDefaultProps(props, componentClass, defaultPropsArr));
        }

        var setOfPropsArr = defaultPropsArr.concat(props ? props : {});

        var mergedProps = Object.assign.apply(Object, setOfPropsArr);

        //# NOTE: childrenPropsByKey is a reserved key
        //# we want to deep merge these
        if (mergedProps.hasOwnProperty('childrenPropsByKey')) {
            //# NOTE: 20191115 - deprecated in favor of having the ruby-component-builder template expansion handle the merge
            mergedProps.childrenPropsByKey = commonUtils.twoDepthMergedPropsFromArr_forKey(setOfPropsArr, 'childrenPropsByKey');
        }

        self.props = mergedProps;

        var explicitChildrenElements = [].slice.call(arguments, 2);
        self._explicitChildren = explicitChildrenElements;

        var childrenElements = self.newMergedExplicitAndImplicitChildren();
        //# and we should auto-bind `this` to each of the generator function
        //# so in self.children() we can call on self.getAction() and pass the actions we want into the children as delegate actions


        //# patch the getRouteElement function
        //# NOTE: most ruby-component-route modules will call on their own getReactElement() method
        //#     which will be patched to iteratively call on their children's getReactElement()
        //#     this is how we create the tree of reactElements where the reactElements are provided by
        //#     rubyComponents.
        //#     The thing to note is that if you nest routeElements (via multiple rubyComponents)
        //#     The getReactElement of a parent route will *NOT* call on the children getReactElement IF
        //#     The child rubyComponent is a route.
        //#     You can think of it as you can either have a getRouteElement or a getReactElement, not both
        //#     *AND* the getRouteElement takes precedence
        if (componentClass.getRouteElement) {
            self.getRouteElement = function () {
                var childrenElements = this.getChildren();
                var childrenRouteElements = _.reduce(childrenElements, function (collector, childElement) {
                    if (childElement.getRouteElement) {
                        collector.push(childElement.getRouteElement());
                    }
                    return collector;
                }, []);

                var reactComponent = componentClass.getRouteElement.apply(this);
                return React.cloneElement.apply(React, [reactComponent, mergedProps].concat(_toConsumableArray(childrenRouteElements)));
            };
        }

        //# patch the getReactClass function to give it this context
        if (componentClass.getReactClass) {
            self.getReactClass = function () {
                var selfModule = this;

                var SelfReactClass = componentClass.getReactClass.apply(this);

                var selfID = this.getID();

                if (SelfReactClass.hasOwnProperty('WrappedComponent')) {
                    patchShouldComponentUpdateInClass_usingContext(SelfReactClass, selfModule);
                }

                if (selfModule.getRouteElement) {
                    //# only need this for the root level because react-router will not call on getReactElement()
                    var baseRender = SelfReactClass.prototype.render;
                    SelfReactClass.prototype.render = function () {
                        var childrenReactElements = selfModule.getChildrenReactElements();
                        //# need to retrieve the external react elements provided by other rubyComponents in the render call because it might be different
                        //# IE. we might have dynamically changed the rubyComponent hierarchy
                        var renderedElement = baseRender.apply(this);

                        var mergedWithPropsChildren = getMergedWithPropsChildren(renderedElement.props, childrenReactElements);

                        return React.cloneElement(renderedElement, null, mergedWithPropsChildren);
                    };
                }

                return SelfReactClass;
            };
        }

        //# patch the getReactElement function
        if (componentClass.getReactElement) {
            //# we need to patch all other getReactElement calls because we need ensure that the called on element's children are included but also our rubyComponent side-loaded children as well

            self.getReactElement = function () {
                var _this = this;

                var selfID = this.getID();
                var props = this.props;

                var selfReactElement = componentClass.getReactElement.apply(this);
                var selfReactElement__props = _extends({}, selfReactElement.props || {}, selfReactElement.key ? { key: selfReactElement.key } : {});

                //# we need to keep the internally defined children around when merging in additional children
                //# but sometimes the children gets rebuilt
                //# so we need to expose a function to allow the reactElement
                //# to call on the updated children
                //# TODO: reconsider how to get dynamically inserted children
                //# without calling on this method inside the reactElement's render method
                //# README: need to expose the reactElements from other rubyComponents like this
                //# in case the current reactElement needs to re-render. Look at the experimental file for how we can fix this... 
                //# BUT the rerenders causes the textfield to lose focus
                //# We can probably choose the older reactElement, but this determination is hidden from the developer
                //# so it might make debugging harder
                var getMergedChildrenReactElements = function getMergedChildrenReactElements() {
                    var selfReactElement = componentClass.getReactElement.apply(_this);
                    var selfReactElement__props = _extends({}, selfReactElement.props || {}, selfReactElement.key ? { key: selfReactElement.key } : {});

                    var childrenReactElements = _this.getChildrenReactElements();

                    var mergedChildrenReactElements = getMergedWithPropsChildren(selfReactElement__props, childrenReactElements);
                    return mergedChildrenReactElements;
                };

                var mergedChildrenReactElements = getMergedChildrenReactElements();

                var patchedReactElementClassNameArr = void 0;
                if (props.className) {
                    patchedReactElementClassNameArr = [props.className, selfID];
                } else {
                    patchedReactElementClassNameArr = [selfID];
                }

                var patchedProps = _extends({}, props, {
                    className: patchedReactElementClassNameArr.join(' '),
                    key: selfID //# default to selfID, but selfReactElement__props will override if necessary
                    , getMergedChildrenReactElements: getMergedChildrenReactElements
                }, selfReactElement__props);

                var patchedReactElement = React.cloneElement.apply(React, [selfReactElement, patchedProps].concat(_toConsumableArray(mergedChildrenReactElements)));

                //# NOTE: idea: return a redux connector for each component, which would get called for state changes
                //# and we can have that connector call on getMergedChildrenReactElements() instead of having the pure react component call (like in Repeater);
                return patchedReactElement;
            };
        } else {
            self.getReactElement = function () {
                var _extends2;

                var childrenReactElements = this.getChildrenReactElements();
                //# for ease of understanding the react element hierarchy, we auto-wrap the children elements
                //# with a div if the current ruby-component does not have a react element
                var selfID = this.getID();
                var divProps = _.pick(this.props, ['key', 'id', 'style', 'className']);
                return React.createElement(
                    'div',
                    _extends({
                        className: selfID,
                        key: selfID
                    }, divProps, (_extends2 = {
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'index'
                    }, _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)),
                    childrenReactElements[0],
                    childrenReactElements[1]
                );
                /*
                if (childrenReactElements.length > 1) {
                    return (<div>
                        {childrenReactElements}
                    </div>);
                }
                return childrenReactElements[0];
                */
            };
        }

        //# patch getInitialState
        if (self.getInitialState) {
            self._mergedGetInitialState = self.getInitialState;
            self.getInitialState = function (defaultStateDefinedByReducer) {
                var _this2 = this,
                    _arguments = arguments;

                var initialStateArray = this._mergedGetInitialState.map(function (getInitialState) {
                    return getInitialState.apply(_this2, _arguments);
                });

                return initialStateArray.length ? Object.assign.apply(null, initialStateArray) : {};
            };
        }

        //# patch the onRedux function to keep track of the redux store unsub function that's returned
        if (self.onReduxInit) {
            self._mergedOnReduxInit = self.onReduxInit;
            self.onReduxInit = function () {
                var _this3 = this,
                    _arguments2 = arguments;

                this._onReduxInit_returnValues = this._mergedOnReduxInit.map(function (onReduxInit) {
                    return onReduxInit.apply(_this3, _arguments2);
                });
            };
        }

        if (self.onReduxTearDown || self.onReduxInit) {
            self.onReduxTearDown = function () {
                if (this._onReduxInit_returnValues) {
                    this._onReduxInit_returnValues.forEach(function (teardown) {
                        teardown && teardown();
                    });
                    this._onReduxInit_returnValues = undefined;
                }

                componentClass.onReduxTearDown && componentClass.onReduxTearDown.apply(this, arguments);
            };
        }

        ['onMount', 'onUnmount', 'onReactInit'].forEach(function (methodKey) {
            if (self[methodKey]) {
                var cachedArrayOfFunctions = self[methodKey];

                self[methodKey] = function () {
                    var _this4 = this,
                        _arguments3 = arguments;

                    cachedArrayOfFunctions.map(function (method) {
                        return method.apply(_this4, _arguments3);
                    });
                };
            }
        });

        _.each(childrenElements, function (childElement) {
            childElement._parent = self;
        });

        self._children = childrenElements;

        return self;
    },
    composeSelectors: function composeSelectors(baseSelector, selectorsObject) {
        var boundSelectorsObject = _.reduce(selectorsObject, function (collector, selector, selectorKey) {
            collector[selectorKey] = function (state) {
                return selector(baseSelector(state));
            };
            return collector;
        }, {});

        return boundSelectorsObject;
    }
};

module.exports = RubyComponent;