'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Paper = require('material-ui/Paper');

var _Paper2 = _interopRequireDefault(_Paper);

var _AppBar = require('material-ui/AppBar');

var _AppBar2 = _interopRequireDefault(_AppBar);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _close = require('material-ui/svg-icons/navigation/close');

var _close2 = _interopRequireDefault(_close);

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

var _SelectField = require('@rubyapps/ruby-material-ui/SelectField');

var _SelectField2 = _interopRequireDefault(_SelectField);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _List = require('material-ui/List');

var _Card = require('material-ui/Card');

var _Dialog = require('material-ui/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _Image = require('@rubyapps/ruby-react-components/src/client/element/Image');

var _Image2 = _interopRequireDefault(_Image);

var _defaultTemplatePreview = require('@rubyapps/ruby-assets/default-template-preview.png');

var _defaultTemplatePreview2 = _interopRequireDefault(_defaultTemplatePreview);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var rubyLogger = require('@rubyapps/ruby-logger');
var logger = rubyLogger.getLogger('ruby-component-alter-template-dialog:reactComponents/AlterTemplateDialog');

var styles = {
    title: {},
    headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400
    },
    dialogContent: {},
    tabs: {
        backgroundColor: '#eee'
    },
    tab: {
        color: '#222'
    }
};

var AlterTemplateDialogHeader = _react2.default.createClass({
    displayName: 'AlterTemplateDialogHeader',

    propTypes: {
        delegateClose: PropTypes.func
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4;

        var myContext = this;
        var styleColor = {
            color: '#ffffff'
        };

        return _react2.default.createElement(_AppBar2.default, (_React$createElement4 = {
            title: _react2.default.createElement(
                'span',
                (_React$createElement = { style: styles.title, 'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'AlterTemplateDialog'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement),
                'Alter Template'
            ),
            iconElementLeft: _react2.default.createElement(
                _IconButton2.default,
                (_React$createElement3 = { onClick: this.props.delegateClose, 'data-codecept-selector-node': 'IconButton',
                    'data-codecept-selector-file': 'AlterTemplateDialog'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement3),
                _react2.default.createElement(_close2.default, (_React$createElement2 = {
                    'data-codecept-selector-node': 'NavigationClose',
                    'data-codecept-selector-file': 'AlterTemplateDialog'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'NavigationClose'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'NavigationClose'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement2))
            ),
            'data-codecept-selector-node': 'AppBar',
            'data-codecept-selector-file': 'AlterTemplateDialog'
        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement4));
    }
});

var TemplateDropDown = _react2.default.createClass({
    displayName: 'TemplateDropDown',

    propTypes: {
        errorText: PropTypes.string,
        id: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func,
        formsArray: PropTypes.arrayOf(PropTypes.shape({
            primaryText: PropTypes.string,
            value: PropTypes.string,
            availableFields: PropTypes.arrayOf(PropTypes.string)
        }))
    },

    getDefaultProps: function getDefaultProps() {
        return {
            formsArray: [{ value: 'blogs', primaryText: 'Blogs', availableFields: ['Name', 'Blog Categories'] }, { value: 'teams', primaryText: 'Teams', availableFields: ['Name', 'Description'] }],
            value: 'teams',
            onChange: function onChange(event) {}
        };
    },
    _handleChange: function _handleChange(event, index, value) {
        this.props.onChange(value);
    },
    render: function render() {
        var _React$createElement5;

        var formsArray = this.props.formsArray;
        return _react2.default.createElement(
            _SelectField2.default,
            (_React$createElement5 = {
                floatingLabelText: 'Template',
                floatingLabelFixed: true,
                value: this.props.value,
                onChange: this._handleChange, 'data-codecept-selector-node': 'SelectField',
                'data-codecept-selector-file': 'AlterTemplateDialog'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'SelectField'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'SelectField'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement5),
            formsArray.map(function (menuValues) {
                var _extends2;

                return _react2.default.createElement(_MenuItem2.default, _extends({ key: menuValues.value }, menuValues, (_extends2 = {
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'AlterTemplateDialog'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends2, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_extends2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends2, 'data-codecept-selector-file', 'AlterTemplateDialog'), _extends2)));
            })
        );
    }
});

var AlterTemplateConfirmDialog = _react2.default.createClass({
    displayName: 'AlterTemplateConfirmDialog',

    propTypes: {
        //# state-like
        open: PropTypes.bool
        //# delegate Actions
        , delegateClose: PropTypes.func,
        delegateAlterTemplate: PropTypes.func
    },
    render: function render() {
        var _React$createElement6, _React$createElement7, _React$createElement8;

        var actions = [_react2.default.createElement(_FlatButton2.default, (_React$createElement6 = {
            label: 'Cancel',
            primary: true,
            onTouchTap: this.props.delegateClose,
            'data-codecept-selector-node': 'FlatButton',
            'data-codecept-selector-file': 'AlterTemplateDialog'
        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement6)), _react2.default.createElement(_FlatButton2.default, (_React$createElement7 = {
            label: 'Alter Template',
            primary: true,
            onTouchTap: this.props.delegateAlterTemplate,
            'data-codecept-selector-node': 'FlatButton',
            'data-codecept-selector-file': 'AlterTemplateDialog'
        }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement7))];

        return _react2.default.createElement(
            _Dialog2.default,
            (_React$createElement8 = {
                actions: actions,
                modal: false,
                open: this.props.open,
                onRequestClose: this.props.delegateClose,
                'data-codecept-selector-node': 'Dialog',
                'data-codecept-selector-file': 'AlterTemplateDialog'
            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement8),
            'Are you sure you want to alter the template? Any data for fields that are not shared between the two templates will be lost. Any children pages may not be accessible if the resulting template doesn\'t support them. View links may take a few minutes to update.'
        );
    }
});

var AlterTemplateDialog = _react2.default.createClass({
    displayName: 'AlterTemplateDialog',

    propTypes: {
        //# state-like
        open: PropTypes.bool,

        fields: PropTypes.shape({
            //# page name text field
            name: PropTypes.shape({
                //# can include anything else that the TextField supports
                errorText: PropTypes.string,
                id: PropTypes.string,
                value: PropTypes.string,
                onChange: PropTypes.func
            }),
            templateKey: PropTypes.shape({
                errorText: PropTypes.string,
                id: PropTypes.string,
                value: PropTypes.string,
                onChange: PropTypes.func
            }),
            formsArray: PropTypes.arrayOf(PropTypes.shape({
                primaryText: PropTypes.string,
                value: PropTypes.string,
                availableFields: PropTypes.arrayOf(PropTypes.string),
                previewImage: PropTypes.string
            }))
        })

        //# delegate Actions
        , delegateClose: PropTypes.func,
        delegateAlterTemplate: PropTypes.func
    },
    getDefaultProps: function getDefaultProps() {
        return {
            delegatePageNameChange: function delegatePageNameChange() {}
        };
    },

    render: function render() {
        var _React$createElement9, _React$createElement10, _extends3, _extends4, _React$createElement15, _React$createElement18, _React$createElement19, _React$createElement20, _React$createElement21;

        var actions = [_react2.default.createElement(_FlatButton2.default, (_React$createElement9 = {
            label: 'Cancel',
            primary: false,
            onTouchTap: this.props.delegateClose,
            'data-codecept-selector-node': 'FlatButton',
            'data-codecept-selector-file': 'AlterTemplateDialog'
        }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement9)), _react2.default.createElement(_FlatButton2.default, (_React$createElement10 = {
            label: 'Alter Template',
            primary: true,
            onTouchTap: this.props.delegateOpenConfirmation,
            'data-codecept-selector-node': 'FlatButton',
            'data-codecept-selector-file': 'AlterTemplateDialog'
        }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement10))];

        var alterTemplateDialogHeaderProps = _lodash2.default.pick(this.props, Object.keys(AlterTemplateDialogHeader.propTypes));
        var templateDropDownProps = this.props.fields.templateKey;

        var props_templateKey = this.props.fields.templateKey;
        var selectedForm = props_templateKey.formsArray.filter(function (formObject) {
            return formObject.value === props_templateKey.value;
        })[0];
        return _react2.default.createElement(
            _Dialog2.default,
            (_React$createElement21 = {
                title: _react2.default.createElement(AlterTemplateDialogHeader, _extends({}, alterTemplateDialogHeaderProps, (_extends3 = {
                    'data-codecept-selector-node': 'AlterTemplateDialogHeader',
                    'data-codecept-selector-file': 'AlterTemplateDialog'
                }, _defineProperty(_extends3, 'data-codecept-selector-node', 'AlterTemplateDialogHeader'), _defineProperty(_extends3, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_extends3, 'data-codecept-selector-node', 'AlterTemplateDialogHeader'), _defineProperty(_extends3, 'data-codecept-selector-file', 'AlterTemplateDialog'), _extends3))),
                modal: false,
                actions: actions,
                open: this.props.open,
                onRequestClose: this.props.delegateClose,
                bodyStyle: styles.dialogContent,
                'data-codecept-selector-node': 'Dialog',
                'data-codecept-selector-file': 'AlterTemplateDialog'
            }, _defineProperty(_React$createElement21, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_React$createElement21, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement21, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_React$createElement21, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement21),
            _react2.default.createElement(
                'div',
                (_React$createElement19 = { style: { display: 'flex' }, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'AlterTemplateDialog'
                }, _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement19),
                _react2.default.createElement(
                    'div',
                    (_React$createElement15 = { className: 'leftColumn', style: { height: 450, flexGrow: 1, marginRight: 10 }, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'AlterTemplateDialog'
                    }, _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement15),
                    _react2.default.createElement(TemplateDropDown, _extends({}, templateDropDownProps, (_extends4 = {
                        'data-codecept-selector-node': 'TemplateDropDown',
                        'data-codecept-selector-file': 'AlterTemplateDialog'
                    }, _defineProperty(_extends4, 'data-codecept-selector-node', 'TemplateDropDown'), _defineProperty(_extends4, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_extends4, 'data-codecept-selector-node', 'TemplateDropDown'), _defineProperty(_extends4, 'data-codecept-selector-file', 'AlterTemplateDialog'), _extends4))),
                    function () {
                        var _React$createElement11, _React$createElement13, _React$createElement14;

                        if (selectedForm === undefined) {
                            return;
                        }
                        return _react2.default.createElement(
                            'div',
                            (_React$createElement14 = { style: { width: '100%', height: '100%' }, 'data-codecept-selector-node': 'div',
                                'data-codecept-selector-file': 'AlterTemplateDialog'
                            }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement14),
                            _react2.default.createElement(
                                _Card.CardTitle,
                                (_React$createElement11 = { style: { padding: '16px 0 4px' }, 'data-codecept-selector-node': 'CardTitle',
                                    'data-codecept-selector-file': 'AlterTemplateDialog'
                                }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'CardTitle'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'CardTitle'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement11),
                                'Includes'
                            ),
                            _react2.default.createElement(
                                _List.List,
                                (_React$createElement13 = { style: { overflow: 'auto', height: '75%' }, 'data-codecept-selector-node': 'List',
                                    'data-codecept-selector-file': 'AlterTemplateDialog'
                                }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'List'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'List'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement13),
                                function () {
                                    return _lodash2.default.reduce(selectedForm.availableFields, function (collector, fieldString, fieldIndex) {
                                        var _React$createElement12;

                                        collector.push(_react2.default.createElement(_List.ListItem, (_React$createElement12 = { primaryText: fieldString, key: fieldIndex, disabled: true, 'data-codecept-selector-node': 'ListItem',
                                            'data-codecept-selector-file': 'AlterTemplateDialog'
                                        }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'ListItem'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'ListItem'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement12)));
                                        return collector;
                                    }, []);
                                }()
                            )
                        );
                    }()
                ),
                _react2.default.createElement(
                    'div',
                    (_React$createElement18 = { className: 'rightColumn', style: { minWidth: 400, width: 400, height: 450 }, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'AlterTemplateDialog'
                    }, _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement18),
                    function () {
                        var _React$createElement16, _React$createElement17;

                        if (selectedForm === undefined) {
                            return;
                        }
                        return _react2.default.createElement(
                            _Paper2.default,
                            (_React$createElement17 = { style: { marginTop: 24 }, 'data-codecept-selector-node': 'Paper',
                                'data-codecept-selector-file': 'AlterTemplateDialog'
                            }, _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'Paper'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'Paper'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement17),
                            _react2.default.createElement(_Image2.default, (_React$createElement16 = { src: selectedForm.previewImage, fallbackSrc: _defaultTemplatePreview2.default, style: { maxWidth: '100%' }, 'data-codecept-selector-node': 'Image',
                                'data-codecept-selector-file': 'AlterTemplateDialog'
                            }, _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'Image'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'Image'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement16))
                        );
                    }()
                )
            ),
            _react2.default.createElement(AlterTemplateConfirmDialog, (_React$createElement20 = {
                open: this.props.confirmOpen,
                delegateAlterTemplate: this.props.delegateAlterTemplate,
                delegateClose: this.props.actions.closeConfirmation,
                'data-codecept-selector-node': 'AlterTemplateConfirmDialog',
                'data-codecept-selector-file': 'AlterTemplateDialog'
            }, _defineProperty(_React$createElement20, 'data-codecept-selector-node', 'AlterTemplateConfirmDialog'), _defineProperty(_React$createElement20, 'data-codecept-selector-file', 'AlterTemplateDialog'), _defineProperty(_React$createElement20, 'data-codecept-selector-node', 'AlterTemplateConfirmDialog'), _defineProperty(_React$createElement20, 'data-codecept-selector-file', 'AlterTemplateDialog'), _React$createElement20))
        );
    }
});
exports.default = AlterTemplateDialog;