'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _reactRedux = require('react-redux');

var _DashboardAnalytics = require('./DashboardAnalytics');

var _DashboardAnalytics2 = _interopRequireDefault(_DashboardAnalytics);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector,
        frontendSettingsComponent = _selfModule$getDepend.frontendSettingsComponent;

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);

        var frontendSettings = frontendSettingsComponent.getState();
        var protocol = frontendSettings['WWW.INIT.SSL_ONLY'] ? 'https://' : 'http://';

        var props = selfModule.getProps(true);

        var domainURL = new URL('' + protocol + props.domain);
        var host = domainURL.protocol + '//' + domainURL.host;

        return _extends({}, ownProps, _lodash2.default.pick(selfState, Object.keys(_DashboardAnalytics2.default.propTypes)), _lodash2.default.pick(selfModule.props, Object.keys(_DashboardAnalytics2.default.propTypes)), {
            id: selfModule.getID(),
            host: host,
            children: ownProps.getMergedChildrenReactElements ? ownProps.getMergedChildrenReactElements() : ownProps.children
        });
    }

    return (0, _reactRedux.connect)(mapStateToProps)(_DashboardAnalytics2.default);
}