'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reduxBatchedActions = require('redux-batched-actions');

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

var _require = require('../common/constants'),
    FE_SETTINGS_KEY = _require.FE_SETTINGS_KEY,
    STICKY_FE_SETTINGS_KEY = _require.STICKY_FE_SETTINGS_KEY;

var generators = {

    autosaveSearch_fromGriddleState: function autosaveSearch_fromGriddleState(griddleState) {
        var selfModule = this;

        var _selfModule$getDepend = selfModule.getDependencies(),
            feSettingsComponent = _selfModule$getDepend.feSettingsComponent;

        var currSavedSearch = feSettingsComponent.getState()[FE_SETTINGS_KEY] || {};

        var stateToSave = _.pick(griddleState, selfModule.props.autosaveSearch_griddleStateKeys);

        return (0, _reduxBatchedActions.batchActions)([feSettingsComponent.getAction().generators.setFrontendSetting_forKey_toValue(FE_SETTINGS_KEY, _extends({}, currSavedSearch, _defineProperty({}, selfModule.getAutosaveSearch_context(), stateToSave))), selfModule.getAction().generators.setStickySearch_fromGriddleState(griddleState)]);
    },

    clearOtherAutosavedSearches: function clearOtherAutosavedSearches() {
        var selfModule = this;

        var _selfModule$getDepend2 = selfModule.getDependencies(),
            feSettingsComponent = _selfModule$getDepend2.feSettingsComponent;

        var currSavedSearch = feSettingsComponent.getState()[FE_SETTINGS_KEY] || {};

        return feSettingsComponent.getAction().generators.setFrontendSetting_forKey_toValue(FE_SETTINGS_KEY, _.pick(currSavedSearch, [selfModule.getAutosaveSearch_context()]));
    },

    setStickySearch_fromGriddleState: function setStickySearch_fromGriddleState(griddleState) {
        var selfModule = this;

        var _selfModule$getDepend3 = selfModule.getDependencies(),
            feSettingsComponent = _selfModule$getDepend3.feSettingsComponent;

        var currStickySearch = selfModule.getStickySearchState();

        var stateToSave = _.pick(griddleState, selfModule.getStickySearchKeys());

        var newFeSettings__stickySettings = _.merge({}, currStickySearch, stateToSave);

        return feSettingsComponent.getAction().generators.setFrontendSetting_forKey_toValue(STICKY_FE_SETTINGS_KEY, newFeSettings__stickySettings);
    }
};

module.exports = function () {
    return {
        generators: generators
    };
};