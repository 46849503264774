'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FONT_SIZE = 14;
var FLOATING_LABEL_STYLE = {
    top: 42
};

var BaseDatetimePicker = {
    DatePicker: {
        floatingLabelStyle: FLOATING_LABEL_STYLE,
        style: {
            width: 'auto'
        },
        textFieldStyle: {},
        errorStyle: {
            position: 'absolute',
            marginBottom: -22,
            width: 300
        }
    },
    TimePicker: {
        floatingLabelStyle: FLOATING_LABEL_STYLE,
        style: {
            width: 'auto'
        },
        textFieldStyle: {},
        errorStyle: {
            position: 'absolute',
            width: 160,
            marginBottom: -22
        }
    },
    DatePickerWrapper: {
        style: {
            width: 150,
            position: 'relative',
            marginRight: 20
        }
    },
    TimePickerWrapper: {
        style: {
            position: 'relative'
        }
    }
};

var StartDatetimePicker = _lodash2.default.merge({}, BaseDatetimePicker);

var EndDatetimePicker = _lodash2.default.merge({}, BaseDatetimePicker);

exports.default = {
    Dropdown: {
        SelectField: { //# old
            floatingLabelStyle: FLOATING_LABEL_STYLE,
            style: {
                fontSize: FONT_SIZE,
                width: 106,
                marginTop: -14
            },
            labelStyle: {
                top: 9
            }
        },
        Selectize: {
            floatingLabelStyle: {
                top: 12,
                fontSize: FONT_SIZE
            },
            style: {
                fontSize: FONT_SIZE,
                width: 125,
                marginTop: 8
            }
        }
    },
    StartDatetimePicker: StartDatetimePicker,
    EndDatetimePicker: EndDatetimePicker
};