'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reduxBatchedActions = require('redux-batched-actions');

var _reactRouter = require('react-router');

var _ = require('lodash');
var BluebirdPromise = require('bluebird');

var ReactRouterRedux = require('react-router-redux');
var routerReducer = ReactRouterRedux.routerReducer;
var routerMiddleware = ReactRouterRedux.routerMiddleware;

var allRoutesReducer = require('./allRoutes/reducer');

//== STORE INITIALIZATION ========================================================//
//

var _require = require('redux'),
    applyMiddleware = _require.applyMiddleware,
    combineReducers = _require.combineReducers,
    compose = _require.compose,
    createStore = _require.createStore;

var thunkMiddleware = require('redux-thunk');
var LoggerMiddleware = require('./LoggerMiddleware').default;

var routerMiddlewareInstance = routerMiddleware(_reactRouter.browserHistory);

function initializeStore_usingConfig(config) {
    var self = this;

    this._rootComponent = config.rootComponent;
    var rootComponent = this._rootComponent;

    var isDevMode = config.isDevMode;


    var middlewares = [
    //LoggerMiddleware,
    thunkMiddleware, routerMiddlewareInstance].concat(rootComponent.getMiddlewares_flatMapped());
    var middlewareEnhancer = applyMiddleware.apply(null, middlewares);

    var shouldEnhanceStoreWithDevTools = isDevMode && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__;
    var maybeDevToolsEnhancer = shouldEnhanceStoreWithDevTools ? window.__REDUX_DEVTOOLS_EXTENSION__() : _.identity;

    var storeEnhancer = compose(middlewareEnhancer, maybeDevToolsEnhancer);
    var createStoreWithEnhancers = storeEnhancer(createStore);

    var rootComponentReducers = rootComponent.getReducerByKey();
    var allReducers = _extends({
        routing: routerReducer,
        routeParams: allRoutesReducer
    }, rootComponentReducers);
    this._store = createStoreWithEnhancers((0, _reduxBatchedActions.enableBatching)(combineReducers(allReducers)));
    rootComponent.setReduxManager(this);

    return new BluebirdPromise(function (resolve, reject) {
        resolve(self);
    });
}

//# http://stackoverflow.com/questions/32968016/how-to-dynamically-load-reducers-for-code-splitting-in-a-redux-application
function rebuildReducer() {
    var rootComponent = this._rootComponent;
    var store = this._store;

    var rootComponentReducers = rootComponent.getReducerByKey();
    var allReducers = _extends({
        routing: routerReducer,
        routeParams: allRoutesReducer
    }, rootComponentReducers);

    store.replaceReducer((0, _reduxBatchedActions.enableBatching)(combineReducers(allReducers)));
}

var rubyReduxManager = {
    _rootComponent: undefined,
    _store: undefined

    //== Initialization ==========================//
    , init: initializeStore_usingConfig,

    rebuildReducer: rebuildReducer

    //== Accessors ===============================//
    , getStore: function getStore() {
        return this._store;
    }
};
module.exports = rubyReduxManager;