'use strict';

var _ = require('lodash');
var jsonselect = require('JSONSelect');

var path = require('path');
var dirname = __dirname;
var dirnameArr = dirname.split('/');
var pathsToCheck = [];
var configsArr = [];

//# polyfill isAbsolute
if (_.isNil(path.isAbsolute)) {
    path.isAbsolute = function (pathStr) {
        return pathStr.charAt(0) == '/';
    };
}

function _deepHydratePathsInConfig(config, configPath) {
    var allPaths = jsonselect.match(':has(:root > .path)', config);

    _.each(allPaths, function (pathContainingObject) {
        var possiblePath = pathContainingObject.path;
        if (!path.isAbsolute(possiblePath)) {
            pathContainingObject.path = path.join(configPath, possiblePath);
        }
    });

    return config;
}

for (var i = dirnameArr.length; i > 1; i--) {
    pathsToCheck.push(dirnameArr.slice(0, i).join('/'));
}

pathsToCheck.push(process.cwd());

var configFileName = 'rubyLogger-rc.json';
for (var i = 0; i < pathsToCheck.length; i++) {
    var targetConfigPath = path.join(pathsToCheck[i], configFileName);

    //# NOTE: previously used fs.existsSync, but our webpack cross-resolver can't deal with that yet
    //# changing this to use a try/catch block to be more robust
    try {
        //# TODO: There's an issue with trying to use webpack to glob up the json files
        //# we get the following error:
        //# ELOOP: too many symbolic links encountered, stat
        //# we will ignore for now
        var targetConfig = require(
        /* webpackIgnore: true */
        /* webpackRegExp: /^\.\/rubyLogger-rc\.json$/ */
        /* webpackRequest: '../../../../..' */
        targetConfigPath);

        configsArr.push({
            config: _deepHydratePathsInConfig(targetConfig, targetConfigPath),
            path: targetConfigPath
        });
        /* webpackRegExp: /rubyLogger-rc\.json$/ */
        /* webpackRecursive: true */
        /* webpackRequest: '../../../../..' */
        /* webpackRegExp: /^\.\/.*\.json$/ */
        /* webpackRegExp: /^\.\/.*\.json$/ */
    } catch (err) {
        //# ok to ignore. we expect that we might not have configs
    }
}

function _getMergedConfigsArr(configsArr) {
    var configsOnlyArr = _.map(configsArr, function (configObject) {
        return configObject.config;
    });

    return _.merge.apply(null, [{}].concat(configsOnlyArr));
    /*
    var mergedStreams = _.reduce(configsOnlyArr, function(collector, config) {
        if (config.streams) {
            collector = collector.concat(config.streams)
        }
        return collector;
    }, []);
     var mergedConfig = _.assign.apply(null, configsOnlyArr);
    mergedConfig.streams = mergedStreams;
    return mergedConfig;
    */
}
module.exports = {
    mergedConfig: _getMergedConfigsArr(configsArr),
    configsArray: configsArr
};