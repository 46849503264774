'use strict';

//# Register the RubyComponent against the TinyMCE Plugin
var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var emailLinkDialogTemplate = require('../common/templates/emailLinkDialog').default;

var CONSTANTS = require('../common/constants');

var RCRTEEmailLink = RubyComponent.createClass({
    componentName: CONSTANTS.COMPONENT_NAME,
    getInitialState: function getInitialState() {
        return {
            //onSubmit: null //# when "add link" is clicked
            //, open: false
        };
    },
    getDefaultProps: function getDefaultProps() {
        return {
            formDialogID: require('@rubyapps/ruby-component-form-dialog/src/common/constants').COMPONENT_NAME,
            formsID: require('@rubyapps/ruby-component-forms/src/common/constants').COMPONENT_NAME
        };
    },
    dependencies: function dependencies() {
        var selfModule = this;
        var rootModule = selfModule.getRoot();

        var formsComponent = rootModule.findDescendentByID(this.props.formsID);
        var formDialogComponent = rootModule.findDescendentByID(this.props.formDialogID);

        return {
            formsComponent: formsComponent,
            formDialogComponent: formDialogComponent
        };
    },
    action: require('./action'),
    reducer: require('./reducer'),
    onReduxInit: function onReduxInit(dispatch) {
        var _getDependencies = this.getDependencies(),
            formsComponent = _getDependencies.formsComponent;

        //# register the form


        formsComponent.setTemplate_atKey(emailLinkDialogTemplate, emailLinkDialogTemplate.key);
    }

    //# == UTILITIES =================================//

    , getAugmentedDialogTemplate: function getAugmentedDialogTemplate() {
        var _getDependencies2 = this.getDependencies(),
            formsComponent = _getDependencies2.formsComponent;

        var augmentedDialogTemplate = formsComponent.getAugmentedTemplateForKey(emailLinkDialogTemplate.key, undefined, this);

        return augmentedDialogTemplate;
    }
});

var _require = require('./tinymce/editor_plugin'),
    pluginName = _require.pluginName;

tinymce.PluginManager.lookup[pluginName].RubyComponent = RCRTEEmailLink;

module.exports = RCRTEEmailLink;