'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = generator;

var _reactRedux = require('react-redux');

var _ExpandedData = require('./ExpandedData');

var _ExpandedData2 = _interopRequireDefault(_ExpandedData);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {

    function mapStateToProps(state, ownProps) {
        return {};
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {};
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_ExpandedData2.default);
}