'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _set = require('lodash/fp/set');

var _set2 = _interopRequireDefault(_set);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function getUsedIds_fromSitemapValue() {
    var sitemapValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var usedIds = _lodash2.default.reduce(sitemapValue.result, function (accumulator, id) {
        return [].concat(accumulator, id, function getDescendentIds(id) {
            var _ref;

            var childrenIds = _lodash2.default.get(sitemapValue, ['entities', 'sitemapItem', id, 'children']) || [];
            var descendentIds = (_ref = []).concat.apply(_ref, [childrenIds].concat(_toConsumableArray(childrenIds.map(function (id) {
                return getDescendentIds(id);
            }))));
            return descendentIds;
        }(id));
    }, []);

    return usedIds;
}

function getDanglingIds_fromSitemapValue() {
    var sitemapValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    //# extract all ids from entities
    //# although the children should be accounted for as top-level entries in `entities` 
    //# that might not be the case (if the structure was badly defined) so we should include children

    var allEntityIds = Object.keys(_lodash2.default.reduce(_lodash2.default.get(sitemapValue, 'entities.sitemapItem', {}), function (collector, entitySpec) {
        var _entitySpec$children = entitySpec.children,
            children = _entitySpec$children === undefined ? [] : _entitySpec$children;


        collector[entitySpec.id] = true;

        children.forEach(function (childId) {
            collector[childId] = true;
        });

        return collector;
    }, {} //# store as a map to handle repeats 
    )).map(Number);

    var usedIds = getUsedIds_fromSitemapValue(sitemapValue);

    var danglingIds = _lodash2.default.difference(allEntityIds, usedIds);

    return danglingIds;
}

function getMissingPagesInSitemap_fromSitemap(sitemapNew, sitemapOld) {
    var prevSitemapIds = getUsedIds_fromSitemapValue(sitemapOld);
    var newSitemapIds = getUsedIds_fromSitemapValue(sitemapNew);

    var idsOfPagesRemovedFromSitemap = _lodash2.default.difference(prevSitemapIds, newSitemapIds);

    return idsOfPagesRemovedFromSitemap;
}

function findDupesInSitemapValue(sitemapValue) {

    var usedIds = getUsedIds_fromSitemapValue(sitemapValue);
    var usedIdsMap = usedIds.reduce(function (collector, id) {
        collector[id] = collector[id] + 1 || 1;

        return collector;
    }, []);

    var dupesMap = _lodash2.default.pickBy(usedIdsMap, function (val, key) {
        return val > 1;
    });

    return Object.keys(dupesMap);
}

function cleanSitemapFormValue_forFieldKeypathArr(formValue, fieldKeypathArr) {
    return _lodash2.default.flow([function removeUndefinedIds(formValue) {
        return (0, _set2.default)(fieldKeypathArr, {
            entities: {
                sitemapItem: _lodash2.default.pickBy(_lodash2.default.get(formValue, [].concat(_toConsumableArray(fieldKeypathArr), ['entities', 'sitemapItem']), {}), function (value, key) {
                    return key != 'undefined';
                })
            },
            result: _lodash2.default.get(formValue, [].concat(_toConsumableArray(fieldKeypathArr), ['result']), []).filter(function (value) {
                return !_lodash2.default.isNil(value);
            })
        }, formValue);
    }, function removeUnusedIds(formValue) {
        var sitemapValue = _lodash2.default.get(formValue, fieldKeypathArr);
        var usedIds = getUsedIds_fromSitemapValue(sitemapValue);
        var prunedSitemapValue = _extends({}, sitemapValue, {
            entities: _extends({}, formValue.entities, {
                sitemapItem: _lodash2.default.pick(_lodash2.default.get(sitemapValue, 'entities.sitemapItem'), usedIds)
            })
        });

        return (0, _set2.default)(fieldKeypathArr, prunedSitemapValue, formValue);
    }])(formValue);
}

module.exports = {
    getUsedIds_fromSitemapValue: getUsedIds_fromSitemapValue,
    getDanglingIds_fromSitemapValue: getDanglingIds_fromSitemapValue,
    getMissingPagesInSitemap_fromSitemap: getMissingPagesInSitemap_fromSitemap,
    findDupesInSitemapValue: findDupesInSitemapValue,
    cleanSitemapFormValue_forFieldKeypathArr: cleanSitemapFormValue_forFieldKeypathArr
};