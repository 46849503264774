'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var defaultState = {
    subsite: 'en',
    template: 'homepage'
};
module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];

    switch (action.type) {
        case '@@ruby-app/UPDATE_GLOBAL_ROUTE_PARAMS':
            return _extends({}, state, action.payload);
        default:
            return state;
    }
};