'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var styles = {
    Card: {
        marginTop: '15px'
    },
    IconMenu: {
        style: {
            marginLeft: 'auto'
        }
    },
    RaisedButton: {
        marginLeft: '10px'
    },
    activityFooterCardText: {
        padding: '0px'
    },
    activityTypePaper: {
        width: '145px',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: '0px',
        padding: '5px',
        fontSize: '11px',
        color: '#fff'
    },
    iconStyles: {
        width: '17px',
        height: '17px',
        verticalAlign: 'bottom',
        transition: 'none'
    },
    viewEditButton: {
        width: '155px'
    }
};

exports.default = styles;