'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = PublishGenerator;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Toggle = require('@rubyapps/ruby-material-ui/Toggle');

var _Toggle2 = _interopRequireDefault(_Toggle);

var _customComponent_styles = require('./customComponent_styles');

var _customComponent_styles2 = _interopRequireDefault(_customComponent_styles);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');
var moment = require('moment');


function _getTemplateKeyAndIDFromRowProps(rowProps) {
    var templateKey = _.get(rowProps, ['rowData', 'template_keyword']) || _.get(rowProps, ['rowData', '__metadata', 'template_keyword']);
    var id = _.get(rowProps, ['rowData', 'id']) || _.get(rowProps, ['rowData', '__metadata', 'id']);
    return { templateKey: templateKey, id: id };
}

/**
 *
 * @params {function} options.confirm - a function that returns a promise which, if resolves, means to go ahead and perform action. otherwise, cancel
 */
function PublishGenerator() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var parentProps = arguments[1];
    var _options$confirm = options.confirm,
        confirmFunction = _options$confirm === undefined ? function () {
        return Promise.resolve(true);
    } : _options$confirm;

    return _react2.default.createClass({
        getInitialState: function getInitialState(props) {
            return {
                isToggled: null
            };
        },
        componentDidMount: function componentDidMount() {
            var _this = this;

            return;
            setTimeout(function () {
                _this.setState({
                    isToggled: true
                });
            }, 5000);
        },
        render: function render() {
            var _this2 = this,
                _extends2,
                _React$createElement;

            var props = this.props;
            var activeState = _.get(props.rowData, ['__metadata', 'status'], _.get(props.rowData, 'status', 'inactive'));

            var item = _getTemplateKeyAndIDFromRowProps(props);
            var isActive = activeState == 'active' ? true : false;

            var canEdit = parentProps.getUserHasPerm_forItem ? parentProps.getUserHasPerm_forItem('edit', item) : true; //# assume `true` for backwards compatibility with parentProps that don't define getUserHasPerm_forItem
            var canPublish = parentProps.canUserPublish ? parentProps.canUserPublish() : true; //# assume `true` for backwards compatibility for parentProps that don't define canUserPublish

            var disabledForCMS = _.get(props, 'rowData.__metadata.__disabledForCMS');

            var toggledValue = !_.isNil(this.state.isToggled) ? this.state.isToggled : isActive;
            return _react2.default.createElement(
                'div',
                (_React$createElement = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'PublishGenerator'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'PublishGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'PublishGenerator'), _React$createElement),
                _react2.default.createElement(_Toggle2.default, _extends({}, _customComponent_styles2.default.Toggle, (_extends2 = {
                    disabled: disabledForCMS || !canEdit || !canPublish,
                    toggled: toggledValue,
                    onToggle: function onToggle(event, toggled) {
                        confirmFunction(event, toggled, { ctx: props }).then(function (res) {

                            parentProps.actions.setItemStatus_withActive(item, toggled, [function (response) {
                                _this2.setState({
                                    isToggled: toggled
                                }); //# keep localState updated until we can refresh
                                props.events.refreshCurrentPage(); //# we probably don't want to this to prevent flash
                                options.successCallback && options.successCallback();
                            }, function (err) {
                                //# failed
                                //# NOTE: don't know if we want to keep it uncontrolled
                                //# or rely on the list refreshing
                                _this2.theToggle.setToggled(!toggled);
                            }]);
                        });
                    },
                    ref: function ref(theToggle) {
                        if (theToggle) {
                            _this2.theToggle = theToggle;
                        }
                    },
                    'data-codecept-selector-node': 'Toggle',
                    'data-codecept-selector-file': 'PublishGenerator'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_extends2, 'data-codecept-selector-file', 'PublishGenerator'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_extends2, 'data-codecept-selector-file', 'PublishGenerator'), _extends2)))
            );
        }
    });
}