'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var ReactDOM = _interopRequireWildcard(_reactDom);

var _transitions = require('material-ui/styles/transitions');

var _transitions2 = _interopRequireDefault(_transitions);

var _Card = require('material-ui/Card');

var _reactGridLayout = require('@rubyapps/react-grid-layout');

var _reactGridLayout2 = _interopRequireDefault(_reactGridLayout);

require('@rubyapps/react-grid-layout/css/styles.css');

require('react-resizable/css/styles.css');

var _dragHandle = require('@rubyapps/ruby-material-ui/svg-icons/navigation/drag-handle');

var _dragHandle2 = _interopRequireDefault(_dragHandle);

var _ChildActions = require('./ChildActions');

var _ChildActions2 = _interopRequireDefault(_ChildActions);

var _Placeholder = require('./Placeholder');

var _Placeholder2 = _interopRequireDefault(_Placeholder);

var _TokenTagger = require('./TokenTagger');

var _TokenTagger2 = _interopRequireDefault(_TokenTagger);

var _Repeater_style = require('./Repeater_style');

var _Repeater_style2 = _interopRequireDefault(_Repeater_style);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var path = require('path');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

var PropTypes = _react2.default.PropTypes;


var onElementResize = require('element-resize-event');

//# Material UI


//# React Grid Layout

//# TEMP: REMOVE

var ReactGridLayout = (0, _reactGridLayout.WidthProvider)(_reactGridLayout2.default);

//# Icons

//# Custom Components
var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label,
    RichErrorMessages = _require.RichErrorMessages;

//# Styles
var fieldBaseStyles = process.browser ? require('@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule').default : {};
var repeaterStyles = process.browser ? require('./Repeater.cssModule').default : {};
var styles = _extends({}, fieldBaseStyles, repeaterStyles);


var getStyles = function getStyles(props, context, state) {
    var _context$muiTheme = context.muiTheme,
        baseTheme = _context$muiTheme.baseTheme,
        _context$muiTheme$tex = _context$muiTheme.textField,
        floatingLabelColor = _context$muiTheme$tex.floatingLabelColor,
        focusColor = _context$muiTheme$tex.focusColor,
        textColor = _context$muiTheme$tex.textColor,
        disabledTextColor = _context$muiTheme$tex.disabledTextColor,
        backgroundColor = _context$muiTheme$tex.backgroundColor,
        hintColor = _context$muiTheme$tex.hintColor,
        errorColor = _context$muiTheme$tex.errorColor,
        palette = _context$muiTheme.palette;


    var styles = {
        root: {
            fontSize: 16,
            lineHeight: '24px',
            width: props.fullWidth ? '100%' : 256,
            height: (props.rows - 1) * 24 + (props.floatingLabelText ? 72 : 48),
            display: 'inline-block',
            position: 'relative',
            fontFamily: baseTheme.fontFamily,
            transition: _transitions2.default.easeOut('200ms', 'height')
        },
        error: {
            position: 'relative',
            bottom: 8,
            fontSize: 12,
            lineHeight: '12px',
            color: errorColor,
            transition: _transitions2.default.easeOut()
        },
        DragHandleIcon_disabled: {
            color: palette.disabledColor_level2,
            cursor: 'not-allowed'
        }
    };

    _extends(styles.error, props.errorStyle);

    return styles;
};

function _getBoxSizeForElement(element) {
    var style = window.getComputedStyle(element, null);
    var width = parseInt(style.getPropertyValue('width'));
    var height = parseInt(style.getPropertyValue('height'));
    return {
        width: isNaN(width) ? 0 : width,
        height: isNaN(height) ? 0 : height
    };
}

var REPEATER_CHILD_RESIZE_ATTR_KEY = 'rubyComponenntFieldRepeater__child__onresize';
var Repeater = _react2.default.createClass({
    displayName: 'Repeater',

    propTypes: {
        actions: PropTypes.object,
        actionButtons: PropTypes.arrayOf(PropTypes.node),
        childrenOrderById: PropTypes.array,
        childrenIdToTypeMap: PropTypes.object,
        defaultItemSize: PropTypes.shape({
            height: PropTypes.number,
            width: PropTypes.number
        }),
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        id: PropTypes.string,
        label: PropTypes.string,
        layoutWidth: PropTypes.number,
        limit: PropTypes.number,
        moduleMode: PropTypes.bool,
        options: PropTypes.array
        // , optionsByType: PropTypes.object //# 20170617 DEPRECATED
        , showMenu: PropTypes.bool,
        showTokenTagger: PropTypes.bool,
        sortable: PropTypes.bool,
        value: PropTypes.string,
        requireSearchValueForDropdown: PropTypes.bool
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    childContextTypes: {
        registerForDirtyForceUpdate: PropTypes.func,
        unregisterForDirtyForceUpdate: PropTypes.func
    },
    getDefaultProps: function getDefaultProps() {
        return {
            actionButtons: [],
            columns: 1,
            childrenOrderById: [],
            childrenIdToTypeMap: {},
            defaultItemSize: {
                height: 80
            },
            layoutWidth: 1200,
            limit: Infinity,
            moduleMode: false,
            showMenu: true,
            showTokenTagger: false,
            sortable: true,
            value: null,
            requireSearchValueForDropdown: false
        };
    },
    getInitialState: function getInitialState() {
        this._queuedChildComponentByChildIdForBoxSizeUpdate = {};;
        this.cachedClonedChildren = {};
        //# Local cache of the cloned react component so we don't need to reclone the children
        //# NOTE: not a good idea if the children component changes dynamically
        //# The cache is to allow us to bind to the element and watch for resize, which
        //# causes this component to rerender, but we don't want the child to rerender 
        //# while it's being interacted with
        this.cachedClonedChildrenComponent = {};
        //# since we're caching the cloned children, we need to also cache the childrenComponent refs
        //# so that we can call on forceUpdate()
        //# the cloned children won't necessarily respond to state changes
        //# (if we have nested repeaters, the parent module might not reflect error messages that's informed by the children repeater error)
        this.cachedClonedChildrenEl = {};

        return {
            itemBeingDragged: null,
            boxSizesByChildId: {},
            layoutWidth: null
        };
    },
    getChildContext: function getChildContext() {
        var _this = this;

        return {
            registerForDirtyForceUpdate: function registerForDirtyForceUpdate(reactComponent, id) {
                if (_this._reactComponentsForDirtyForceUpdateById == undefined) {
                    _this._reactComponentsForDirtyForceUpdateById = {};
                }

                var reactComponentsForDirtyForceUpdateById = _this._reactComponentsForDirtyForceUpdateById;
                var cachedComponent = reactComponentsForDirtyForceUpdateById[id];
                if (cachedComponent != reactComponent) {

                    _this._reactComponentsForDirtyForceUpdateById = _extends({}, _this._reactComponentsForDirtyForceUpdateById, _defineProperty({}, id, reactComponent));
                }
            },
            unregisterForDirtyForceUpdate: function unregisterForDirtyForceUpdate(reactComponent, id) {
                if (_this._reactComponentsForDirtyForceUpdateById == undefined) {
                    _this._reactComponentsForDirtyForceUpdateById = {};
                }

                var reactComponentsForDirtyForceUpdateById = _extends({}, _this._reactComponentsForDirtyForceUpdateById);
                delete reactComponentsForDirtyForceUpdateById[id];
                _this._reactComponentsForDirtyForceUpdateById = reactComponentsForDirtyForceUpdateById;
            }
        };
    },
    componentDidMount: function componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
        this._isMounted = true;
    },
    _getChildWithKey_fromChildren: function _getChildWithKey_fromChildren(key, children) {
        var _this2 = this;

        var self = this;
        var sortable = this.props.sortable;
        var limit1 = this.props.limit == 1;
        var isInfoMode = this._isInfoMode();
        var disabled = this.props.locked;

        var showDelete = this._showDelete();

        var prepareStyles = this.context.muiTheme.prepareStyles;

        var muiStyleObject = getStyles(this.props, this.context, this.state);

        var DragHandleIcon__style_disabled = prepareStyles(muiStyleObject.DragHandleIcon_disabled);

        for (var childIdx in children) {
            var child = children[childIdx];
            if (child.key == key) {
                if (this.cachedClonedChildren[key]) {
                    //# NOTE: need to force update to get updates to the fieldset (like the error message)
                    //# NOTE: we can eliminate the need to call on forceUpdate() if we synchronously call on refreshParentErros
                    //# However, we had to wrap that in a setImmediate due to performance issues
                    this.cachedClonedChildrenComponent[key].forceUpdate();
                    return this.cachedClonedChildren[key];
                } else {
                    var _React$createElement, _React$createElement2;

                    var childProps__actionButtons = child.props.actionButtons || [];
                    //# NOTE: what we're cloning here is the Connector component, so it's pre-actionbutton-hydration
                    //# Also NOTE: because we're cloning the element here, the componentRef is always different, and it seems like 
                    //# the Unmount is going to always get triggered
                    var childClone = _react2.default.cloneElement(child, {
                        actionButtons: showDelete ? childProps__actionButtons.concat(_react2.default.createElement(_ChildActions2.default, (_React$createElement = { id: key, actions: this.props.actions, disabled: disabled, 'data-codecept-selector-node': 'ChildActions',
                            'data-codecept-selector-file': 'Repeater'
                        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ChildActions'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Repeater'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ChildActions'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Repeater'), _React$createElement))) : childProps__actionButtons,
                        leftIcon: limit1 || !sortable || isInfoMode ? null : _react2.default.createElement(_dragHandle2.default, (_React$createElement2 = { className: styles.DragHandleIcon + ' ' + this.props.dragHandleClass,
                            style: disabled ? DragHandleIcon__style_disabled : {},
                            onTouchTap: function onTouchTap(event) {
                                event.stopPropagation();
                            },
                            'data-codecept-selector-node': 'DragHandleIcon',
                            'data-codecept-selector-file': 'Repeater'
                        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'DragHandleIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Repeater'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'DragHandleIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Repeater'), _React$createElement2))

                        // , locked: this.props.locked //# TODO: should we trickle this down?
                        , defaultItemSize: this.props.defaultItemSize,
                        ref: function ref(childComponent) {
                            if (!childComponent) {
                                return;
                            }

                            var childEl = ReactDOM.findDOMNode(childComponent);
                            var cachedChildEl = _this2.cachedClonedChildrenEl[key];

                            if (childEl.getAttribute(REPEATER_CHILD_RESIZE_ATTR_KEY)) {
                                //console.log('[Repeater child] already bound to resize. Key:', key);
                                return;
                            }
                            if (cachedChildEl && cachedChildEl != childEl) {
                                //console.log('[Repeater child] cachedChildEl is different');
                                self._unbindChildWithKey(key);
                                //# need to unbind the previous child el
                            }

                            //# Doing something specific to element-resize-event module
                            var sizeChangeCallback = function sizeChangeCallback() {
                                if (self._isMounted) {
                                    self._queueChildComponentBoxSizeUpdateForId(childComponent, key);
                                }
                            };
                            onElementResize(childEl, sizeChangeCallback);
                            //console.log('[Repeater child] watching for resize. Key:', key);

                            //# Need to trigger this on a setImmediate
                            //# because on a rehydration, multiple children will call this
                            //# and if done sequentially, it's not going to get
                            //# the updated state from the previous updates 
                            //# (this is probably because we Object.assign the values)
                            setImmediate(function () {
                                //# NOTE: previously we retrieved the boxSize and 
                                //# updated the internal boxSize state immediately
                                //# but the problem is that the components might be unmounted during an updateChildren() call.
                                //# we should queue the childComponents
                                //# and then debounce the actual setState call


                                //# NOTE: this should be ok to call but we should check
                                //# whether we already called this once for the cached component
                                self._queueChildComponentBoxSizeUpdateForId(childComponent, key);
                            });

                            childEl.setAttribute(REPEATER_CHILD_RESIZE_ATTR_KEY, true);
                            _this2.cachedClonedChildrenComponent[key] = childComponent;
                            _this2.cachedClonedChildrenEl[key] = childEl;
                        },
                        componentWillUnmount: function componentWillUnmount() {
                            //console.log('[Repeater child] componentWillUnmount. Key:', key);

                            self._unbindChildWithKey(key);
                            delete self.cachedClonedChildren[key]; //# clear out local cache
                        }
                    });

                    this.cachedClonedChildren[key] = childClone;

                    return childClone;
                }
            }
        }
        return null;
    },
    _renderChildren: function _renderChildren() {
        var _this3 = this;

        var _props = this.props,
            childrenOrderById = _props.childrenOrderById,
            childrenIdToTypeMap = _props.childrenIdToTypeMap;

        //# This.props.children isn't getting children that's being regenerated because of the way we side-load the childen reactElements from 
        //# other rubyComponents
        //# We need to call on a special function

        var children = this.props.children;

        return childrenOrderById.map(function (childId, idx) {
            return _this3._getChildWithKey_fromChildren(childId, children);
        });
    },
    _renderPlaceholders: function _renderPlaceholders(children) {
        var options = this.props.options || [];
        var disabled = this.props.locked || options.length === 0;

        var childrenLength = children.length;
        var columns = this.props.columns;
        var limit = this.props.limit;

        var numberOfItemsInLastRow = childrenLength % columns;
        var rowRemainder_fromChildren = columns - numberOfItemsInLastRow;
        var rowRemainder_adjusted = rowRemainder_fromChildren == 0 ? columns : rowRemainder_fromChildren;
        var limitRemainder = limit && isFinite(limit) ? limit - childrenLength : undefined;

        var numOfPlaceholders = limitRemainder != undefined ? limitRemainder : this.props.showMenu ? rowRemainder_adjusted : 0;

        var sharedPlaceholderProps = _lodash2.default.pick(this.props, Object.keys(_Placeholder2.default.propTypes));
        var placeholders = Array.apply(null, { length: numOfPlaceholders }).map(function (x, i) {
            var _extends3;

            return _react2.default.createElement(_Placeholder2.default, _extends({}, sharedPlaceholderProps, (_extends3 = {
                index: i,
                className: styles.Placeholder,
                disabled: disabled,
                key: 'Placeholder:' + (childrenLength + i),
                'data-codecept-selector-node': 'Placeholder',
                'data-codecept-selector-file': 'Repeater'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Placeholder'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Repeater'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Placeholder'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Repeater'), _extends3)));
        });

        return placeholders;
    },
    _moduleMode: function _moduleMode(renderedChild, scaledDefaultItemSize) {
        return this.props.moduleMode;
        return this.props.columns > 1;
    },
    _renderTokenTagger: function _renderTokenTagger() {
        var _this4 = this,
            _React$createElement3;

        var _props2 = this.props,
            childrenOrderById = _props2.childrenOrderById,
            childrenIdToTypeMap = _props2.childrenIdToTypeMap,
            locked = _props2.locked,
            sortable = _props2.sortable;

        var tokenTaggerValue = childrenOrderById.map(function (id) {
            return childrenIdToTypeMap[id];
        });

        return this.props.showTokenTagger ? _react2.default.createElement(_TokenTagger2.default, (_React$createElement3 = {
            disabled: locked,
            sortable: sortable,
            hintText: 'Search for ' + this.props.label,
            url: this.props.url,
            delegateValueChange: function delegateValueChange(event, value) {
                _this4.props.actions.updateItemsWithListOfModules(value);
            },
            value: tokenTaggerValue,
            requireSearchValueForDropdown: this.props.requireSearchValueForDropdown,
            options: this.props.options,
            'data-codecept-selector-node': 'TokenTagger',
            'data-codecept-selector-file': 'Repeater'
        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'TokenTagger'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Repeater'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'TokenTagger'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Repeater'), _React$createElement3)) : null;
    },
    render: function render() {
        var _this5 = this,
            _extends6,
            _React$createElement5,
            _React$createElement6,
            _extends7,
            _extends8,
            _React$createElement7;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var prepareStyles = this.context.muiTheme.prepareStyles;

        var muiStyleObject = getStyles(this.props, this.context, this.state);

        var errorTextElement = null;
        if (this.props.error && this.props.error.message) {
            var _React$createElement4;

            errorTextElement = _react2.default.createElement(
                'div',
                (_React$createElement4 = { style: prepareStyles(muiStyleObject.error), 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Repeater'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Repeater'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Repeater'), _React$createElement4),
                this.props.error.message
            );
        }

        var labelProps = _lodash2.default.pick(this.props, Object.keys(Label.propTypes));

        var layout = [];
        var currentY = 0;
        var columns = this.props.columns;
        var margin = [10, 10];

        //# figuring out the scaled sizes for the modules
        var layoutWidth = this.state.layoutWidth || (this._layoutElement ? this._layoutElement.offsetWidth : this.props.layoutWidth);
        var marginWidthForCols = (columns - 1) * margin[0];

        var scaledDefaultItemWidth = (layoutWidth - marginWidthForCols) / columns;
        var _props$defaultItemSiz = this.props.defaultItemSize,
            defaultHeight = _props$defaultItemSiz.height,
            defaultWidth = _props$defaultItemSiz.width;

        var scaledDefaultItemHeight = defaultHeight;
        if (defaultWidth) {
            scaledDefaultItemHeight = defaultHeight / defaultWidth * scaledDefaultItemWidth;
        }
        var scaledDefaultItemSize = {
            width: scaledDefaultItemWidth,
            height: scaledDefaultItemHeight
        };

        var childrenElements = this._renderChildren().filter(function (child) {
            return !_lodash2.default.isNull(child);
        });
        var children = childrenElements.map(function (renderedChild, childIdx) {
            var _extends4;

            var childId = renderedChild.key;
            var id = renderedChild.key; //# must match the UUIDs because we use this as the UUID
            var boxSize = _this5._moduleMode(renderedChild, scaledDefaultItemSize) ? scaledDefaultItemSize : _this5.state.boxSizesByChildId[childId] || _this5.props.defaultItemSize;

            var dataGridProps = {
                key: id //# necessary to get ReactGridLayout playing nicely with new instances since the IDs would be completely different
                , i: id,
                x: childIdx % columns * boxSize.width //# TODO: update based on layout for module
                , y: currentY //y_adjustedForMargin
                , w: boxSize.width,
                h: boxSize.height,
                minW: 1 //# TODO: update based on layout for module
                , maxW: 1
                //, static: false //# TODO: no specific request for this
            };
            if (dataGridProps.x + 1 == columns) {
                currentY += boxSize.height + margin[1]; //# only increase height when we get to the last item of the row
            }

            layout.push(dataGridProps);

            //data-grid={dataGridProps}
            return _react2.default.createElement(
                'div',
                _extends({
                    key: id
                }, _Repeater_style2.default.cardWrapper, (_extends4 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Repeater'
                }, _defineProperty(_extends4, 'data-codecept-selector-node', 'div'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Repeater'), _defineProperty(_extends4, 'data-codecept-selector-node', 'div'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Repeater'), _extends4)),
                renderedChild
            );
        });
        var childrenLength = children.length;
        var placeholders = !this._isInfoMode() ? this._renderPlaceholders(childrenElements).map(function (renderedChild, childIdx) {
            var _extends5;

            var childId = renderedChild.key;
            var id = renderedChild.key; //# must match the UUIDs because we use this as the UUID
            var boxSize = _this5._moduleMode(renderedChild, scaledDefaultItemSize) ? scaledDefaultItemSize : _this5.props.defaultItemSize;

            var y_adjustedForMargin = currentY / margin[1];
            var height_adjustedForMargin = boxSize.height / y_adjustedForMargin;
            var dataGridProps = {
                key: id //# necessary to get ReactGridLayout playing nicely with new instances since the IDs would be completely different
                , i: id,
                x: (childrenLength + childIdx) % columns * boxSize.width //# TODO: update based on layout for module
                , y: currentY //y_adjustedForMargin
                , w: boxSize.width,
                h: boxSize.height //height_adjustedForMargin //boxSize.height
                , minW: 1 //# TODO: update based on layout for module
                , maxW: 1,
                static: true
            };
            if (dataGridProps.x + 1 == columns) {
                currentY += boxSize.height + margin[1]; //# only increase height when we get to the last item of the row
            }

            layout.push(dataGridProps);

            //data-grid={dataGridProps}
            return _react2.default.createElement(
                'div',
                _extends({
                    key: id
                }, _Repeater_style2.default.placeholderWrapper, (_extends5 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Repeater'
                }, _defineProperty(_extends5, 'data-codecept-selector-node', 'div'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Repeater'), _defineProperty(_extends5, 'data-codecept-selector-node', 'div'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Repeater'), _extends5)),
                renderedChild
            );
        }) : [];

        var chipProps = {
            style: {
                marginBottom: 10,
                width: '100%',
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 1px 1px'
            },
            labelStyle: {
                //color: '#ffffff'
                width: '100%'
            },
            backgroundColor: "#e8e8e8" //"#607d8b"
            , onRequestDelete: function onRequestDelete() {}
        };

        /*
                    <Card
                        className={styles.wrapper}
                    >
                        <CardText style={{
                            padding: '16px 20px 0px 20px'
                            , position: 'relative'
                        }}>
                            <AutoComplete
                                hintText="Matter Profile Name"
                                menuCloseDelay={0}
                                dataSource={[
                                    {text: 'Bankruptcy', value:'bankruptcy'}
                                    , {text: 'Litigation', value:'litigation'}
                                ]}
                                openOnFocus={true}
                                filter={(searchText, key) => (key.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)}
                                fullWidth={true}
                            />
                            <Chip {...chipProps}>
                                Litigation
                            </Chip>
                             <Chip {...chipProps}>
                                Bankruptcy
                            </Chip>
                        </CardText>
                    </Card>
        */
        return _react2.default.createElement(
            _Card.Card,
            (_React$createElement7 = {
                className: styles.wrapper,
                id: this.props.id,
                'data-codecept-selector-node': 'Card',
                'data-codecept-selector-file': 'Repeater'
            }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Repeater'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Repeater'), _React$createElement7),
            _react2.default.createElement(
                _Card.CardText,
                _extends({}, _Repeater_style2.default.MainCardText, (_extends8 = {
                    'data-codecept-selector-node': 'CardText',
                    'data-codecept-selector-file': 'Repeater'
                }, _defineProperty(_extends8, 'data-codecept-selector-node', 'CardText'), _defineProperty(_extends8, 'data-codecept-selector-file', 'Repeater'), _defineProperty(_extends8, 'data-codecept-selector-node', 'CardText'), _defineProperty(_extends8, 'data-codecept-selector-file', 'Repeater'), _extends8)),
                _react2.default.createElement(
                    'label',
                    (_React$createElement5 = { className: styles.label, 'data-codecept-selector-node': 'label',
                        'data-codecept-selector-file': 'Repeater'
                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Repeater'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Repeater'), _React$createElement5),
                    _react2.default.createElement(Label, _extends({}, labelProps, (_extends6 = {
                        'data-codecept-selector-node': 'Label',
                        'data-codecept-selector-file': 'Repeater'
                    }, _defineProperty(_extends6, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends6, 'data-codecept-selector-file', 'Repeater'), _defineProperty(_extends6, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends6, 'data-codecept-selector-file', 'Repeater'), _extends6)))
                ),
                errorTextElement,
                _react2.default.createElement(RichErrorMessages, (_React$createElement6 = { messages: this.props.richErrorMessages, 'data-codecept-selector-node': 'RichErrorMessages',
                    'data-codecept-selector-file': 'Repeater'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Repeater'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Repeater'), _React$createElement6)),
                this._renderTokenTagger(),
                _react2.default.createElement(
                    ReactGridLayout,
                    _extends({ className: 'layout',
                        layout: layout,
                        isDraggable: this._isDraggable(),
                        draggableHandle: '.' + this.props.dragHandleClass,
                        cols: this.props.columns,
                        rowHeight: 1,
                        width: this.props.layoutWidth,
                        isResizable: false,
                        horizontalCompact: false,
                        verticalCompact: false,
                        linearCompact: true,
                        margin: margin,
                        containerPadding: [0, 0],
                        onDragStart: this._handleDragStart,
                        onDragStop: this._handleDragStop,
                        onLayoutChange: this._handleLayoutChange,
                        ref: function ref(theReactGridLayout) {
                            var layoutElement = theReactGridLayout ? ReactDOM.findDOMNode(theReactGridLayout) : undefined;
                            _this5._layoutElement = layoutElement;
                        }
                    }, _Repeater_style2.default.ReactGridLayout, (_extends7 = {
                        'data-codecept-selector-node': 'ReactGridLayout',
                        'data-codecept-selector-file': 'Repeater'
                    }, _defineProperty(_extends7, 'data-codecept-selector-node', 'ReactGridLayout'), _defineProperty(_extends7, 'data-codecept-selector-file', 'Repeater'), _defineProperty(_extends7, 'data-codecept-selector-node', 'ReactGridLayout'), _defineProperty(_extends7, 'data-codecept-selector-file', 'Repeater'), _extends7)),
                    children,
                    placeholders
                )
            )
        );
    },

    componentDidUpdate: function componentDidUpdate(prevProps, prevState) {
        var newChildrenOrder = this.props.childrenOrderById;
        var prevChildrenOrder = prevProps.childrenOrderById;

        //# Force dirty updates (like reinitializing tinyMCE) only when
        //# the other has changed (We need this because on a reorder, the DOM elements
        //# might have been detached and reattached

        if (!_lodash2.default.isEqual(newChildrenOrder, prevChildrenOrder)) {
            _lodash2.default.map(this._reactComponentsForDirtyForceUpdateById, function (reactComponent) {
                reactComponent.dirtyForceUpdate();
            });
        }
    },

    componentWillUnmount: function componentWillUnmount() {
        //# delete cached cloned elements based on
        this.cachedClonedChildren = {};
        this.cachedClonedChildrenComponent = {};
        this.cachedClonedChildrenEl = {};

        //# stop listening to layout width changes
        window.removeEventListener('resize', this.onWindowResize);
        this._layoutElement = undefined;
        this._isMounted = false;
        this._queuedChildComponentByChildIdForBoxSizeUpdate = {};
    }

    //# Custom handlers
    , onWindowResize: function onWindowResize(_event) {
        if (!this._layoutElement) {
            return;
        };

        this.setState({ layoutWidth: this._layoutElement.offsetWidth });
    },
    setItemBeingDragged: function setItemBeingDragged(id) {
        return;
        this.setState({
            itemBeingDragged: id
        });
    },
    _handleDragStart: function _handleDragStart(layout, oldItem, newItem, placeholder, evt, htmlElement) {
        this.setItemBeingDragged(oldItem.i);
    },
    _handleDragStop: function _handleDragStop(layout, oldItem, newItem, placeholder, evt, htmlElement) {
        var sortedByXLayout = layout.sort(function (a, b) {
            return a.x - b.x;
        });
        var sortedByYLayout = sortedByXLayout.sort(function (a, b) {
            return a.y - b.y;
        });

        var indexOfNewPlacement = sortedByYLayout.indexOf(newItem);

        var leftSibling = sortedByYLayout[indexOfNewPlacement - 1];
        var rightSibling = sortedByYLayout[indexOfNewPlacement + 1];

        this.setItemBeingDragged(null);
        //# NOTE: due to how the reactGridLayout component internally calls on setState
        //# we need to call on a 'setImmediate' if we want to trigger the relayout last
        //# this would be to allow static fields to be pushed to the top
        //# however, note: the static fields would not be pushed down so we lose the opportunity to shove groups of fields around

        var itemOrder = sortedByYLayout.filter(function (item) {
            return !item.static;
        }).map(function (item) {
            return item.i;
        });
        this.props.actions.updateItemOrder(itemOrder);

        return;
        this.props.actions.moveItem_withId_toBetweenIDs(newItem.i, [leftSibling ? leftSibling.i : null, rightSibling ? rightSibling.i : null]);
    },
    _handleLayoutChange: function _handleLayoutChange(layout, oldItem, newItem, placeholder, evt, htmlElement) {},

    _queueChildComponentBoxSizeUpdateForId: function _queueChildComponentBoxSizeUpdateForId(childComponent, id) {
        logger.debug('[_queuedUpdateBoxSizesByChildId()] for child of component:', this.props.fieldKey, id);
        this._queuedChildComponentByChildIdForBoxSizeUpdate[id] = childComponent;
        this._updateQueuedBoxSizesByChildId();
    },
    _updateQueuedBoxSizesByChildId: function _updateQueuedBoxSizesByChildId() {
        var _this6 = this;

        clearTimeout(this._updateQueuedBoxSizesTimeoutId);
        this._updateQueuedBoxSizesTimeoutId = setTimeout(function () {
            //# box debounce
            _this6._updateQueuedBoxSizesTimeoutId = null;
            if (!_this6._isMounted) {
                return;
            }

            var hydratedBoxSizesByChildId = _lodash2.default.reduce(_this6._queuedChildComponentByChildIdForBoxSizeUpdate, function (collector, childComponent, key) {
                try {
                    var element = ReactDOM.findDOMNode(childComponent);
                    var boxSize = _getBoxSizeForElement(element);
                    collector[key] = boxSize;
                } catch (err) {
                    //# if childComponent is not mounted, we ignore
                    logger.warn('[_updateQueuedBoxSizesByChildId()] error for childComponent with key [' + key + ']', _extends({}, childComponent.props, { value: JSON.stringify(childComponent.props.value, null, '  ') }), err);
                }

                return collector;
            }, {});

            logger.debug('[_updateQueuedBoxSizesByChildId()] for child of component:', _this6.props.fieldKey, _this6._queuedChildComponentByChildIdForBoxSizeUpdate, hydratedBoxSizesByChildId);

            if (!_lodash2.default.isEqual(hydratedBoxSizesByChildId, _this6.state.boxSizesByChildId)) {
                _this6.setState({
                    boxSizesByChildId: _extends({}, _this6.state.boxSizesByChildId, hydratedBoxSizesByChildId)
                });

                _this6._queuedChildComponentByChildIdForBoxSizeUpdate = {};
            }
        }, 50);
    }

    //== Utility Methods ==================================================================//
    , _isInfoMode: function _isInfoMode() {
        var mode = this.props.mode;


        return mode === 'info';
    },
    _showDelete: function _showDelete() {
        return !this._isInfoMode() && !this.props.showTokenTagger;
    },
    _isDraggable: function _isDraggable() {
        return !this._isInfoMode() && !this.props.locked && this.props.sortable;
    },
    _unbindChildWithKey: function _unbindChildWithKey(key) {
        var cachedChildEl = this.cachedClonedChildrenEl[key];
        if (cachedChildEl) {
            cachedChildEl.removeAttribute(REPEATER_CHILD_RESIZE_ATTR_KEY);
            onElementResize.unbind(cachedChildEl);
        }

        delete this.cachedClonedChildrenComponent[key];
        delete this.cachedClonedChildrenEl[key];
    }
});

exports.default = Repeater;