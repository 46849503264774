'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _columnHelper = require('./utils/column-helper');

var _columnHelper2 = _interopRequireDefault(_columnHelper);

var _styleHelper = require('./utils/styleHelper');

var _sizingHelper = require('./utils/sizingHelper');

var _arrayHelper = require('./utils/arrayHelper');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var path = require('path');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

;


var DEFAULT_MINIMUM_CELL_WIDTH = 50;
var COLUMN_SIZE_PROPS = ['width', 'maxWidth', 'minWidth', 'id', 'rowWidth', 'defaultWidth', 'pristineConfig'];

var TableHeading = function (_React$Component) {
    _inherits(TableHeading, _React$Component);

    function TableHeading(props, context) {
        _classCallCheck(this, TableHeading);

        var _this = _possibleConstructorReturn(this, (TableHeading.__proto__ || Object.getPrototypeOf(TableHeading)).call(this, props, context));

        _this.debouncedInitializeColumnSizes = _lodash2.default.debounce(function () {
            var initialColumnSizesByID = this.initialColumnSizesByID();
            logger.debug('[debouncedInitializeColumnSizes()]');
            this.props.events.setColumnResizeProperties(initialColumnSizesByID);
        }, 500);


        _this.cellSizeChange = _this.cellSizeChange.bind(_this);
        _this.cellSizeChangeStart = _this.cellSizeChangeStart.bind(_this);
        _this.cellSizeChangeStop = _this.cellSizeChangeStop.bind(_this);
        _this.onWindowResize = _this.onWindowResize.bind(_this);
        _this.state = {
            rowWidth: 1215
        };
        return _this;
    }

    _createClass(TableHeading, [{
        key: 'shouldComponentUpdate',
        value: function shouldComponentUpdate(nextProps, nextState) {
            //TODO: Make this nicer - shouldn't be reminiscent of clojure level paran usage
            var currentVisibleColumns = this.props.renderProperties.columnProperties;
            var nextVisibleColumns = nextProps.renderProperties.columnProperties;

            var columnsAreDifferent = !(0, _arrayHelper.arraysEqual)(this.props.columns, nextProps.columns);
            var columnPropertiesAreDifferent = !_lodash2.default.isEqual(currentVisibleColumns, nextVisibleColumns);
            var resizePropertiesAreDifferent = !_lodash2.default.isEqual(this.props.resizeProperties, nextProps.resizeProperties) || !_lodash2.default.isEqual(this.state.resizeProperties, nextState.resizeProperties);
            var columnTitlesAreDifferent = !_lodash2.default.isEqual(this.props.columnTitles, nextProps.columnTitles);

            var currentPageProperties = _lodash2.default.get(this.props, 'pageProperties', {});
            var nextPageProperties = _lodash2.default.get(nextProps, 'pageProperties', {});

            var sortColumnChanged = !(0, _arrayHelper.arraysEqual)(currentPageProperties.sortColumns, nextPageProperties.sortColumns);

            var sortIsDifferent = currentPageProperties.sortAscending !== nextPageProperties.sortAscending;
            var rowWidthIsDifferent = this.state.rowWidth != nextState.rowWidth;

            var shouldUpdate = columnsAreDifferent || columnPropertiesAreDifferent || resizePropertiesAreDifferent || sortColumnChanged || sortIsDifferent || columnTitlesAreDifferent || rowWidthIsDifferent;

            return shouldUpdate;
        }
    }, {
        key: 'componentWillReceiveProps',
        value: function componentWillReceiveProps(nextProps) {
            this.setState({
                resizeProperties: nextProps.resizeProperties
            });
        }
    }, {
        key: 'getColumnTitle',
        value: function getColumnTitle(column) {
            var initial = this.props.columnTitles[column] ? this.props.columnTitles[column] : column;

            return this.props.renderProperties.columnProperties[column] && this.props.renderProperties.columnProperties[column].hasOwnProperty('displayName') ? this.props.renderProperties.columnProperties[column].displayName : initial;
        }
    }, {
        key: '_orderedVisibleColumnKeys',
        value: function _orderedVisibleColumnKeys() {
            var renderProperties = this.props.renderProperties;
            var ignoredColumns = renderProperties.ignoredColumns;
            return _lodash2.default.difference(this.props.columns, ignoredColumns.concat([renderProperties.metadataColumn]));
        }
    }, {
        key: '_columnSizeInformation',
        value: function _columnSizeInformation() {
            var visibleColumnKeys = this._orderedVisibleColumnKeys();
            var visibleColumnKeysWithoutLast = visibleColumnKeys.slice(0, -1);
            var columnPropertiesWithResize = _extends({}, this.props.renderProperties.columnProperties, this.props.resizeProperties || {}, this.state.resizeProperties || {});
            var visibleColumnPropertiesByID = _lodash2.default.pick(columnPropertiesWithResize, visibleColumnKeys);
            var lastColumnKey = _lodash2.default.last(visibleColumnKeys);

            var visibleResizableColumnProps = _lodash2.default.reduce(visibleColumnKeysWithoutLast, function (collector, id) {
                var columnProp = visibleColumnPropertiesByID[id];
                if (_lodash2.default.get(columnProp, 'resizable') !== false) {
                    collector.push(columnProp);
                }
                return collector;
            }, []);

            var nonResizableColumnPropsByID = _lodash2.default.pickBy(visibleColumnPropertiesByID, function (columnProp) {
                return _lodash2.default.get(columnProp, 'resizable') === false && _lodash2.default.get(columnProp, 'id') != lastColumnKey;
            });

            var lastColumnProps = visibleColumnPropertiesByID[lastColumnKey];
            var lastCellWidth = parseInt(lastColumnProps.width || lastColumnProps.minWidth || 0);

            var widthFromFixedCells = _lodash2.default.reduce(nonResizableColumnPropsByID, function (total, columnProp) {
                return total += parseInt(columnProp.width);
            }, 0);

            var widthFromFixedAndLastCells = widthFromFixedCells + lastCellWidth;

            //# ignore the last column since it's considerd 'fixed' by default
            var totalMinWidthFromResizables = _lodash2.default.reduce(_lodash2.default.pickBy(visibleColumnPropertiesByID, function (columnProp) {
                return _lodash2.default.get(columnProp, 'resizable') !== false && _lodash2.default.get(columnProp, 'id') != lastColumnKey;
            }), function (total, columnProp) {
                var minWidth = columnProp.minWidth ? parseInt(columnProp.minWidth) : DEFAULT_MINIMUM_CELL_WIDTH;
                total += minWidth;
                return total;
            }, 0);

            var rowWidth = this.state.rowWidth;
            var availableFlexWidth = rowWidth - widthFromFixedAndLastCells; // - totalMinWidthFromResizables;

            return {
                visibleColumnKeys: visibleColumnKeys,
                visibleColumnKeysWithoutLast: visibleColumnKeysWithoutLast,
                visibleColumnPropertiesByID: visibleColumnPropertiesByID,
                visibleResizableColumnProps: visibleResizableColumnProps,
                nonResizableColumnPropsByID: nonResizableColumnPropsByID,
                lastColumnKey: lastColumnKey,
                rowWidth: rowWidth,
                availableFlexWidth: availableFlexWidth,
                totalMinWidthFromResizables: totalMinWidthFromResizables
            };
        }
    }, {
        key: 'newColumnSizesByID_usingColumnId_withNewSize_andDelta',
        value: function newColumnSizesByID_usingColumnId_withNewSize_andDelta(columnId, newSize, delta) {
            var _columnSizeInformatio = this._columnSizeInformation(),
                visibleColumnKeys = _columnSizeInformatio.visibleColumnKeys,
                visibleColumnKeysWithoutLast = _columnSizeInformatio.visibleColumnKeysWithoutLast,
                visibleColumnPropertiesByID = _columnSizeInformatio.visibleColumnPropertiesByID,
                nonResizableColumnPropsByID = _columnSizeInformatio.nonResizableColumnPropsByID,
                lastColumnKey = _columnSizeInformatio.lastColumnKey,
                rowWidth = _columnSizeInformatio.rowWidth,
                availableFlexWidth = _columnSizeInformatio.availableFlexWidth,
                totalMinWidthFromResizables = _columnSizeInformatio.totalMinWidthFromResizables;

            var columnIndex = visibleColumnKeys.indexOf(columnId);

            var rightColumnIds = visibleColumnKeysWithoutLast.slice(columnIndex + 1); //# ignore the last element
            var leftColumnIds = visibleColumnKeys.slice(0, columnIndex);
            var leftColumnIds_reversed = _lodash2.default.reverse(leftColumnIds);

            var resizableReducer = function resizableReducer(collector, id) {
                var columnProp = visibleColumnPropertiesByID[id];
                if (_lodash2.default.get(columnProp, 'resizable') !== false) {
                    collector.push(_lodash2.default.pick(columnProp, COLUMN_SIZE_PROPS));
                }
                return collector;
            };

            var effectedSiblingSizePropsByOrder = rightColumnIds.reduce(resizableReducer, []).concat(leftColumnIds_reversed.reduce(resizableReducer, []));
            //# effectedSiblingSizePropsByOrder + newSize.width == availableFlexWidth

            //# order by all right resizable cells first
            //# then left resizable cells
            var currentColumnSizeProp = _lodash2.default.pick(visibleColumnPropertiesByID[columnId], COLUMN_SIZE_PROPS);
            var remainingAvailableDelta = currentColumnSizeProp.width ? newSize.width - parseInt(currentColumnSizeProp.width) : delta.width;
            //# amount of pixels increased or decreased from the column being adjusted

            //# NOTE: ratio is not available in effectedSiblingSizePropsByOrder
            //# we should compute total and then derive a ratio that's local to the siblings
            //# this is because the existing sibling widths might be wrong
            //# and so we need to resize them accordingly
            var targetTotalSiblingWidth = availableFlexWidth - newSize.width;
            var siblingWidthSum = effectedSiblingSizePropsByOrder.reduce(function (sum, siblingSizeProp) {
                return sum + siblingSizeProp.width;
            }, 0);

            //# the widths of the siblings may not match the desired total width
            //# so we need to recompute the widths based on available flex width
            var adjustedSiblingSizePropsByOrder = targetTotalSiblingWidth != siblingWidthSum ? effectedSiblingSizePropsByOrder.map(function (siblingSizeProp) {
                var siblingRatio = siblingSizeProp.width / siblingWidthSum;

                return _extends({}, siblingSizeProp, { width: siblingRatio * targetTotalSiblingWidth
                });
            }) : effectedSiblingSizePropsByOrder;

            var newSiblingSizeProps = _lodash2.default.map(adjustedSiblingSizePropsByOrder, function (sizeProp) {
                var minWidth = sizeProp.minWidth ? sizeProp.minWidth : DEFAULT_MINIMUM_CELL_WIDTH;
                var maxWidth = sizeProp.maxWidth && sizeProp.maxWidth < availableFlexWidth ? sizeProp.maxWidth : availableFlexWidth;
                var width = sizeProp.width;

                if (remainingAvailableDelta == 0) {
                    return _extends({}, sizeProp, {
                        widthRatio: sizeProp.width / availableFlexWidth
                    });
                }

                var newWidth = width - remainingAvailableDelta;

                if (newWidth < minWidth) {
                    //# cap at minWidth
                    remainingAvailableDelta = minWidth - newWidth;
                    newWidth = minWidth;
                } else if (newWidth > maxWidth) {
                    //# cap at maxWidth
                    remainingAvailableDelta = maxWidth - newWidth;
                    newWidth = maxWidth;
                } else {
                    remainingAvailableDelta = 0;
                }

                return _extends({}, sizeProp, {
                    width: newWidth,
                    widthRatio: newWidth / availableFlexWidth,
                    maxWidth: maxWidth,
                    minWidth: minWidth
                });
            });

            var maxWidthAdjustedForTotalMinWidths = availableFlexWidth - totalMinWidthFromResizables;

            var newSizePropsByID = _lodash2.default.reduce(newSiblingSizeProps, function (collector, prop) {
                collector[prop.id] = prop;
                return collector;
            }, _defineProperty({}, columnId, _extends({}, currentColumnSizeProp, {
                width: newSize.width,
                widthRatio: newSize.width / availableFlexWidth,
                maxWidth: currentColumnSizeProp.maxWidth < maxWidthAdjustedForTotalMinWidths ? currentColumnSizeProp.maxWidth : maxWidthAdjustedForTotalMinWidths,
                minWidth: currentColumnSizeProp.minWidth ? currentColumnSizeProp.minWidth : DEFAULT_MINIMUM_CELL_WIDTH
            })));

            return newSizePropsByID;
        }
    }, {
        key: 'cellSizeChangeStart',
        value: function cellSizeChangeStart(columnId, size, delta) {
            logger.debug.apply(logger, ['cellSizeChangeStart'].concat(Array.prototype.slice.call(arguments)));
            this.setState({
                resizing: true
            });
        }
    }, {
        key: 'cellSizeChange',
        value: function cellSizeChange(columnId, size, delta) {
            logger.debug.apply(logger, ['cellSizeChange'].concat(Array.prototype.slice.call(arguments)));

            //# only concern ourselves with width for now
            //# recalculate the allowable widths for the rest of the columns given the fact that the current column's size has changed
            var newSizePropsByID = this.newColumnSizesByID_usingColumnId_withNewSize_andDelta(columnId, size, delta);

            this.setState({
                resizeProperties: newSizePropsByID
            });

            /*
            this.props.events.setColumnResizeProperties(newSizePropsByID);
            //# Don't call on this.props.events.setColumnProperties() because it gets wiped out
            //# by changes to the columnProperties via outside of griddle/
            this.props.events.setColumnResizeProperties(newSizePropsByID);
            */
            /* //# this 
            for (let key in newSizePropsByID) {
                this.props.events.setColumnProperties(key, newSizePropsByID[key]);
            }
            */
        }
    }, {
        key: 'cellSizeChangeStop',
        value: function cellSizeChangeStop(columnId, size, delta) {
            this.props.events.setColumnResizeProperties(this.state.resizeProperties);

            var newWidthProps = this._columnRenderedSizeProperties();
            logger.debug.apply(logger, ['cellSizeChangeStop'].concat(Array.prototype.slice.call(arguments), [newWidthProps]));

            this._renderedSizeProperties = newWidthProps;
            this.props.events.setColumnRenderedSizeProperties(newWidthProps);

            this.setState({
                resizing: false
            });
        }
    }, {
        key: 'shouldInitializeColumnSizes',
        value: function shouldInitializeColumnSizes() {
            var resizeProps = this.props.resizeProperties;
            var resizeKeys = Object.keys(this.props.resizeProperties);
            var cachedRowWidth = resizeKeys.length ? resizeProps[resizeKeys[0]].rowWidth : 1215;

            var rowWidthIsDiff = cachedRowWidth && this.state.rowWidth && Math.abs(cachedRowWidth - this.state.rowWidth) > 20;
            return resizeKeys.length == 0 || rowWidthIsDiff;
        }
    }, {
        key: '_getMinWidthForColumnProp',
        value: function _getMinWidthForColumnProp(columnProp) {
            return parseInt(columnProp.minWidth) || DEFAULT_MINIMUM_CELL_WIDTH;
        }
        //# called when resizeProperties is empty. We divide the remaining columns by their suggested widths, weighted by their ratios against each other

    }, {
        key: 'initialColumnSizesByID',
        value: function initialColumnSizesByID() {
            var _this2 = this;

            var _columnSizeInformatio2 = this._columnSizeInformation(),
                visibleColumnKeys = _columnSizeInformatio2.visibleColumnKeys,
                visibleColumnKeysWithoutLast = _columnSizeInformatio2.visibleColumnKeysWithoutLast,
                visibleColumnPropertiesByID = _columnSizeInformatio2.visibleColumnPropertiesByID,
                visibleResizableColumnProps = _columnSizeInformatio2.visibleResizableColumnProps,
                lastColumnKey = _columnSizeInformatio2.lastColumnKey,
                rowWidth = _columnSizeInformatio2.rowWidth,
                availableFlexWidth = _columnSizeInformatio2.availableFlexWidth,
                totalMinWidthFromResizables = _columnSizeInformatio2.totalMinWidthFromResizables;

            //# 

            var columnsWithWidth = [];
            var columnsWithoutWidth = _lodash2.default.reduce(visibleResizableColumnProps, function (collector, columnProp) {
                if (columnProp.width) {
                    columnsWithWidth.push(_extends({}, columnProp.pristineConfig, columnProp, {
                        widthRatio: columnProp.defaultWidth / availableFlexWidth,
                        rowWidth: rowWidth,
                        pristineConfig: columnProp.pristineConfig || columnProp
                    }));
                } else {
                    collector.push(_extends({}, columnProp.pristineConfig || columnProp, {
                        pristineConfig: columnProp.pristineConfig || columnProp
                    }));
                }
                return collector;
            }, []);

            var totalWidthOfColumnsWithWidths = _lodash2.default.reduce(columnsWithWidth, function (total, columnProp) {
                return total += parseInt(columnProp.width);
            }, 0);
            var remainingAvailableFlexWidth = availableFlexWidth - totalWidthOfColumnsWithWidths;
            var totalRequestedWidth = _lodash2.default.reduce(columnsWithoutWidth, function (total, columnProp) {
                return total + _this2._getMinWidthForColumnProp(columnProp);
            }, 0);
            var additionalWidthNeeded = totalRequestedWidth - remainingAvailableFlexWidth;
            var totalRequestableWidth = _lodash2.default.reduce(columnsWithWidth, function (total, columnProp) {
                return total += parseInt(columnProp.width) - _this2._getMinWidthForColumnProp(columnProp);
            }, 0);

            if (additionalWidthNeeded > 0 && totalRequestableWidth) {
                //# need to even out the widths
                if (additionalWidthNeeded > totalRequestableWidth) {
                    console.warn('Too many columns to fit in display');
                }
                return _lodash2.default.reduce(visibleResizableColumnProps, function (collector, columnProp) {
                    var pristineConfig = columnProp.pristineConfig || columnProp || {};
                    var minWidth = _this2._getMinWidthForColumnProp(columnProp);

                    if (columnProp.width) {
                        var requestableWidth = columnProp.width - minWidth;
                        var adjustedWidth = _lodash2.default.max(minWidth, ~~(columnProp.width - _lodash2.default.min(requestableWidth, requestableWidth / totalRequestableWidth * additionalWidthNeeded)));

                        collector[columnProp.id] = _extends({}, columnProp, {
                            width: adjustedWidth,
                            widthRatio: adjustedWidth / availableFlexWidth,
                            rowWidth: rowWidth,
                            pristineConfig: pristineConfig
                        });
                    } else {
                        collector[columnProp.id] = _extends({}, columnProp, {
                            width: minWidth,
                            widthRatio: minWidth / availableFlexWidth,
                            rowWidth: rowWidth,
                            pristineConfig: pristineConfig
                        });
                    }
                    return collector;
                }, {});
            } else {
                //# the widths are ok and we should expand columns based on the ratio of their minWidths
                return _lodash2.default.reduce(visibleResizableColumnProps, function (collector, columnProp) {
                    var pristineConfig = columnProp.pristineConfig || columnProp || {};
                    columnProp = pristineConfig;
                    var minWidth = _this2._getMinWidthForColumnProp(columnProp);
                    var newWidth = minWidth / totalRequestedWidth * availableFlexWidth;

                    if (columnProp.width) {
                        collector[columnProp.id] = _extends({}, columnProp, { widthRatio: columnProp.width / availableFlexWidth }, rowWidth, pristineConfig);
                    } else {
                        collector[columnProp.id] = _extends({}, columnProp, {
                            width: newWidth,
                            widthRatio: newWidth / availableFlexWidth,
                            rowWidth: rowWidth,
                            pristineConfig: pristineConfig
                        });
                    }
                    return collector;
                }, {});
            }
        }
    }, {
        key: 'onWindowResize',
        value: function onWindowResize() {
            if (this.griddleElement) {
                if (this._isMounted) {
                    this.setState({
                        rowWidth: (0, _sizingHelper.getWidth)(this.griddleElement)
                    });
                    this.initializeColumnSizesIfShould();
                }
            }
        }
    }, {
        key: 'componentDidMount',
        value: function componentDidMount() {
            this.initializeColumnSizesIfShould();
            this._isMounted = true;
            window.addEventListener('resize', this.onWindowResize);
        }
    }, {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            this._isMounted = false;
        }
    }, {
        key: 'initializeColumnSizesIfShould',
        value: function initializeColumnSizesIfShould() {
            var _this3 = this;

            var shouldInitializeColumnSizes = this.shouldInitializeColumnSizes();
            logger.debug('[initializeColumnSizesIfShould()]', shouldInitializeColumnSizes, this.state);
            //# don't like this, but is necessary in order to get native element width for the row;
            if (shouldInitializeColumnSizes) {
                //# this.debouncedInitializeColumnSizes(); //# this doesn't work as we expected
                setImmediate(function () {
                    var initialColumnSizesByID = _this3.initialColumnSizesByID();
                    !_lodash2.default.isEqual(_this3.props.resizeProperties, initialColumnSizesByID) && _this3.props.events.setColumnResizeProperties(initialColumnSizesByID);
                });
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _this4 = this;

            var _props$events = this.props.events,
                headingClick = _props$events.headingClick,
                headingHover = _props$events.headingHover;

            var _columnSizeInformatio3 = this._columnSizeInformation(),
                availableFlexWidth = _columnSizeInformatio3.availableFlexWidth;

            var renderProperties = this.props.renderProperties;

            var _getStyleProperties = (0, _styleHelper.getStyleProperties)(this.props, 'tableHeading'),
                style = _getStyleProperties.style,
                className = _getStyleProperties.className;

            var shouldInitializeColumnSizes = this.shouldInitializeColumnSizes();

            var resizeProperties = shouldInitializeColumnSizes && this.rowElement ? this.initialColumnSizesByID() : _extends({}, this.props.resizeProperties, this.state.resizeProperties || {});
            //# this.rowElement won't be available on initial render


            var headingSpecs = this.props.columns.map(function (column) {
                var resizeProperty = _lodash2.default.get(resizeProperties, column, {});
                var columnProperty = _columnHelper2.default.getColumnPropertyObject(renderProperties.columnProperties, column);
                var columnProperty_withResize = _extends({}, columnProperty, resizeProperty);
                var showColumn = _columnHelper2.default.isColumnVisible(column, { columnProperties: renderProperties.columnProperties, ignoredColumns: renderProperties.ignoredColumns });
                var sortAscending = _this4.props.pageProperties && _this4.props.pageProperties.sortAscending;
                var sorted = _this4.props.pageProperties && _this4.props.pageProperties.sortColumns.indexOf(column) > -1;
                var title = _this4.getColumnTitle(column);
                return showColumn ? {
                    column: column,
                    sorted: sorted,
                    sortAscending: sortAscending,
                    title: title,
                    columnProperty_withResize: columnProperty_withResize
                } : null;
            }).filter(_lodash2.default.identity);
            this._prevHeadingSpecs = headingSpecs; //# cache for ease of retrieval

            var headings = headingSpecs.map(function (_ref) {
                var column = _ref.column,
                    sorted = _ref.sorted,
                    sortAscending = _ref.sortAscending,
                    title = _ref.title,
                    columnProperty_withResize = _ref.columnProperty_withResize;

                return _react2.default.createElement(_this4.props.components.TableHeadingCell, _extends({
                    key: column,
                    column: column,
                    sorted: sorted,
                    sortAscending: sortAscending,
                    settings: _this4.props.settings,
                    styles: _this4.props.styles,
                    headingClick: headingClick,
                    headingHover: headingHover,
                    icons: _this4.props.styles.icons,
                    title: title,
                    columnProperty: columnProperty_withResize,
                    delegateResizeStart: _this4.cellSizeChangeStart,
                    delegateResize: _this4.cellSizeChange,
                    delegateResizeStop: _this4.cellSizeChangeStop,
                    availableFlexWidth: availableFlexWidth
                }, columnProperty_withResize, _this4.props, {
                    'data-codecept-selector-file': 'table-heading'
                }));
            });

            return this.props.columns.length > 0 ? _react2.default.createElement(
                'thead',
                { style: style, className: className, 'data-codecept-selector-node': 'thead',
                    'data-codecept-selector-file': 'table-heading'
                },
                _react2.default.createElement(
                    'tr',
                    {
                        ref: function ref(tr) {
                            if (tr) {
                                _this4.rowElement = tr;
                                _this4.griddleElement = tr.parentElement.parentElement.parentElement;
                                _this4.setState({
                                    rowWidth: (0, _sizingHelper.getWidth)(_this4.griddleElement)
                                });

                                _this4.initializeColumnSizesIfShould();
                                var columns = headingSpecs.map(function (spec) {
                                    return spec.column;
                                });

                                var newWidthProps = _this4._columnRenderedSizeProperties();
                                var currWidthProps = _lodash2.default.get(_this4, 'props.state.renderedSizeProperties');

                                var isWidthPropsEqual = _lodash2.default.isEqual(currWidthProps, newWidthProps);
                                logger.debug('isWidthPropsEqual', isWidthPropsEqual, currWidthProps, newWidthProps, _this4.state.resizing);
                                if (!isWidthPropsEqual) {
                                    _this4._renderedSizeProperties = newWidthProps;

                                    if (!_this4.state.resizing) {
                                        //# initial seed
                                        _this4.props.events.setColumnRenderedSizeProperties(newWidthProps);
                                    }
                                }
                            }
                        },
                        'data-codecept-selector-node': 'tr',
                        'data-codecept-selector-file': 'table-heading'
                    },
                    headings
                )
            ) : null;
        }
        /**
         *    Get renderedSizeProperties from the rowElement
         *
         */

    }, {
        key: '_columnRenderedSizeProperties',
        value: function _columnRenderedSizeProperties() {
            var tr = this.rowElement;
            var headingSpecs = this._prevHeadingSpecs;
            var columns = headingSpecs.map(function (spec) {
                return spec.column;
            });

            var newWidthProps = _lodash2.default.reduce(columns, function (result, key, index) {
                return _extends(result, _defineProperty({}, key, { width: tr.children[index].offsetWidth }));
            }, {});

            return newWidthProps;
        }
    }]);

    return TableHeading;
}(_react2.default.Component);

exports.default = TableHeading;