'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var request = require('@rubyapps/ruby-superagent');
var React = require('react');
var _ = require('lodash');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;
var reducer = require('./reducer');
var action = require('./action');

var RubyComponentConfirmDialog__CONSTANTS = require('@rubyapps/ruby-component-confirm-dialog/src/common/constants');
var RubyComponentCurrentUser__CONSTANTS = require('@rubyapps/ruby-component-current-user/src/common/constants');

var persistenceMixin = require('@rubyapps/ruby-component-mixin-persistence');

var FRONTEND_SETTINGS_URL = '/ruby/api/v2/settings/frontend';
var LOGIN_FRONTEND_SETTINGS_URL = '/ruby/api/v2/settings/login-frontend';

var BROWSER_SESSION_UUID = require('node-uuid').v4(); //# generate a new UUID once on page load.
//# we do not want this to change on load
var RCFrontendSettings = RubyComponent.createClass({
    componentName: componentName,
    mixins: [persistenceMixin],
    propTypes: {
        confirmDialogID: PropTypes.string,
        currentUserID: PropTypes.string
    },
    getDefaultProps: function getDefaultProps() {
        return {
            confirmDialogID: RubyComponentConfirmDialog__CONSTANTS.COMPONENT_NAME,
            currentUserID: RubyComponentCurrentUser__CONSTANTS.COMPONENT_NAME,
            keysToPersist: ['autosavedSearch', 'stickyAutosavedSearch']
        };
    },

    getInitialState: function getInitialState() {
        var browserSessionId = void 0;

        var performance = window.performance;

        var perfEntries = performance && performance.getEntriesByType("navigation") ? performance.getEntriesByType("navigation") : [];
        var navigationTypes = perfEntries.map(function (perf) {
            return perf.type;
        });

        //# On page load, we want to check if the load type is reload
        //# if it's reload, we can use the existing browserSessionId in sessionStorage
        //# if it's not a reload, we assume it's a  new page load (we cannot differentiate a tab duplication or a tab recovery. So a duplicate tab or a closed tab that you reopen will have the same navigation type)
        //# If it's a new page, we generate a new browserSessionId.
        //# WARNING: if a tab is duplicated, the sessionStorage is also duplicated so there will be an existing browserSessionId
        if (_.includes(navigationTypes, 'reload')) {
            //# if the page is reloaded, then we can recover the stored browserSessionId
            browserSessionId = sessionStorage.getItem('browserSessionId') || BROWSER_SESSION_UUID;
        } else {
            browserSessionId = BROWSER_SESSION_UUID;
        }

        sessionStorage.setItem('browserSessionId', browserSessionId);

        return {
            cmsRoot: '/app',
            restApiRoot: '/ruby/api/v2/'
            //# TODO: we need a better way of setting the initial restApiRoot
            //# we need this before we get the frontend settings because we need to know the root in order to
            //# get the frontend settings
            , browserSessionId: browserSessionId
            //# This is used to track the different tabs that are opened
        };
    },
    reducer: reducer,
    action: action,
    onReduxInit: function onReduxInit(dispatch) {
        var selfActions = this.getAction().generators;

        this.retrieveFrontendSettingsAsync().then(function (settings) {
            dispatch(selfActions.retrievedFrontendSettings(settings));
        });

        //# retrieve user information periodically to get updated role info
        //# but also be automatically redirected to the login page if logged out (session expiration)
        this.pollFrontendSettingsAsync(dispatch);
    },
    CONSTANTS: CONSTANTS

    //== Utility methods ==========================//

    , isResolved: function isResolved() {
        return !!this._retrievedFrontendSettings;
    },
    _logOutMessageForState: function _logOutMessageForState(selfState) {
        var rubyBranding = selfState.RUBY_BRANDING;
        var title = rubyBranding + ': New Version Detected';
        var content = 'Your installation of ' + rubyBranding + ' has been updated to a newer release. To ensure the integrity of your application data, please log out of the system and log back in. You may save your current work, if\xA0necessary.';

        return {
            title: title,
            content: content
        };
    },
    _refreshMessageForState: function _refreshMessageForState(selfState) {
        var rubyBranding = selfState.RUBY_BRANDING;
        var title = rubyBranding + ': New Version Detected';
        var content = 'Your installation of ' + rubyBranding + ' has been updated to a newer release. To ensure the integrity of your application data, please refresh your browser before attempting to log\xA0in.';

        return {
            title: title,
            content: content
        };
    },
    retrieveFrontendSettingsAsync: function retrieveFrontendSettingsAsync() {
        var self = this;

        return request.get(FRONTEND_SETTINGS_URL).then(function success(response) {
            return response.body;
        }, function error(err) {
            if (err.response.status != 401) {
                //# only show the error for something that's not
                //# auth error
                //# rubyComponentCurrentUser will handle
                //# redirecting for login
                //self.showErrorNotification({ error: err });
                console.error('Error retrieving frontend settings.', err);
                throw err;
            } else {
                //# If the user is unauthorized,
                //# return a greatly sanitized settings object
                return request.get(LOGIN_FRONTEND_SETTINGS_URL).then(function (response) {
                    return response.body;
                }, function (err) {
                    //self.showErrorNotification({error: err});
                    console.error('Error retrieving frontend settings for login page.', err);
                    throw err;
                });
            }
        });
    },
    pollFrontendSettingsAsync: function pollFrontendSettingsAsync(dispatch) {
        var _this = this;

        //# Note: since we have retry logic in ruby-superagent, we would only attempt to hit the  
        //# endpoint again once the previous request is returned. 
        this.retrieveFrontendSettingsAsync().then(function (settings) {
            var selfActions = _this.getAction().generators;
            var selfState = _this.getState();
            var currentVersion = selfState.version;
            var currentDeployVersion = selfState.DEPLOY_VERSION;

            var nextVersion = settings['frontend-settings'].version;
            var nextDeployVersion = settings['frontend-settings'].DEPLOY_VERSION;

            var hasNewVersion = currentVersion && nextVersion && currentVersion != nextVersion;
            var hasNewDeployVersion = currentDeployVersion && nextDeployVersion && currentDeployVersion != nextDeployVersion;

            //# only do this check if the version numbers are defined
            if (hasNewVersion || hasNewDeployVersion) {
                var rootComponent = _this.getRoot();

                var confirmDialogComponent = rootComponent.findDescendentByID(_this.props.confirmDialogID);
                var confirmDialogActions = confirmDialogComponent.getAction().generators;

                var currentUserComponent = rootComponent.findDescendentByID(_this.props.currentUserID);

                if (currentUserComponent.getState()) {
                    //# user is logged in
                    //# show confirm dialog asking user to log out
                    dispatch(confirmDialogActions.openDialogWithOptions(_extends({
                        submitLabel: 'Log Out',
                        submitSuccessCallback: function submitSuccessCallback(response) {
                            dispatch(currentUserComponent.getAction().generators.logOut());
                        }
                    }, _this._logOutMessageForState(selfState))));
                } else {
                    dispatch(confirmDialogActions.openDialogWithOptions(_extends({
                        submitLabel: 'Refresh',
                        submitSuccessCallback: function submitSuccessCallback(response) {
                            window.location.reload();
                        }
                    }, _this._refreshMessageForState(selfState))));
                }
            }

            //# if version hasn't changed, we still want to update the local settings state with updated values
            //# e.g. google_api_key if it has been changed
            dispatch(selfActions.retrievedFrontendSettings(settings));
        }).catch(function (error) {
            //# if we should do something else with the error besides showing the error in the console
        }).then(function () {
            var PING_INTERVAL = 1000 * 60 * 1; //# ms * sec * min; //# every 1 minutes

            setTimeout(function () {
                _this.pollFrontendSettingsAsync(dispatch);
            }, PING_INTERVAL);
        });
    }
});

module.exports = RCFrontendSettings;