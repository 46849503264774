'use strict';

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _TimePickerConnector = require('./reactComponents/TimePickerConnector');

var _TimePickerConnector2 = _interopRequireDefault(_TimePickerConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var moment = require('moment-timezone');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var forcedLeafNodeMixin = require('@rubyapps/ruby-component-mixin-field-forced-leaf-node/src/client/index');

var RCTimePicker = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin, forcedLeafNodeMixin],
    propTypes: {
        isIsoFormat: PropTypes.bool,
        styles: PropTypes.shape({
            TimePickerWrapper: PropTypes.object,
            TimePicker: PropTypes.object
        })
    },
    componentName: componentName,
    getDefaultProps: function getDefaultProps() {
        return {
            isIsoFormat: true //# otherwise assume format 'HH:mm'.
            //# NOTE: we want to default to using IsoFormat because elasticsearch recognizes the datetime string format
            //# so we can leverage date minpulation
        };
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();

        return {
            selfSelector: selfSelector
        };
    },
    getReactClass: function getReactClass() {
        return _TimePickerConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var TimePickerComponent = this.getReactClass();

        return React.createElement(TimePickerComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'TimePickerComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TimePickerComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TimePickerComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

    //# Mixin Overrides

    , fieldValueFromFormValue_forKey: function fieldValueFromFormValue_forKey(value, key) {
        var epochMoment = moment.unix(0).tz('UTC');
        if (value && value.time) {
            // TimePicker expects a Moment Object
            var timeValue = value.time;


            if (timeValue.length == 5) {
                var _timeValue$split = timeValue.split(':'),
                    _timeValue$split2 = _slicedToArray(_timeValue$split, 2),
                    hours = _timeValue$split2[0],
                    minutes = _timeValue$split2[1];

                epochMoment.hour(hours);
                epochMoment.minute(minutes);
            } else {
                var timeMoment = moment(timeValue);
                epochMoment = moment(epochMoment).tz('UTC').set({
                    hour: timeMoment.tz('UTC').hour(),
                    minute: timeMoment.tz('UTC').minute()
                });
            }
            var zoneName = value.timeZone || moment.tz.guess();
            return {
                timeMoment: epochMoment,
                zoneName: zoneName
            };
        } else {
            return value;
        }
    },
    formValueFromFieldValue_forKey: function formValueFromFieldValue_forKey(value, key) {
        if (value && value.timeMoment) {
            var epochMoment = moment.unix(0).tz('UTC');

            var fieldValueTimeMoment = value.timeMoment;

            var epochWithFieldValueTimeMoment = moment(epochMoment).tz(value.zoneName).set({
                hour: fieldValueTimeMoment.tz(value.zoneName).hour(),
                minute: fieldValueTimeMoment.tz(value.zoneName).minute()
            });

            var formValueTimeString = this.isIsoFormat() ? epochWithFieldValueTimeMoment.toISOString() : epochWithFieldValueTimeMoment.tz('UTC').format('HH:mm');

            return {
                time: formValueTimeString,
                timeZone: value.zoneName
            };
        } else {
            return value;
        }
    }

    //== Utility Methods =====================================================================//
    , isIsoFormat: function isIsoFormat() {
        var isIsoFormat = _.get(this, 'props.isIsoFormat', true);
        return !!isIsoFormat;
    },

    prettyTimeFromFormValue: function prettyTimeFromFormValue(formValue) {
        var timeValue = formValue.time,
            _formValue$timeZone = formValue.timeZone,
            timeZone = _formValue$timeZone === undefined ? 'UTC' : _formValue$timeZone;


        var epochMoment = moment.unix(0).tz(timeZone);
        if (timeValue.length == 5) {
            var _timeValue$split3 = timeValue.split(':'),
                _timeValue$split4 = _slicedToArray(_timeValue$split3, 2),
                hours = _timeValue$split4[0],
                minutes = _timeValue$split4[1];

            epochMoment.hour(hours);
            epochMoment.minute(minutes);
        } else {
            epochMoment = moment(timeValue);
        }

        return epochMoment.format('HH:mm a zz');
    }

    //== OVERRIDES ======================//
    , sampleValueFromFieldSpecs: function sampleValueFromFieldSpecs() {
        return this.formValueFromFieldValue_forKey({
            timeMoment: moment(),
            zoneName: moment.tz.guess()
        }, this.props.key);
    }

});

module.exports = RCTimePicker;