'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var PropTypes = React.PropTypes;

var Field = React.createClass({
    displayName: 'Field',


    /*
    propTypes: {
        name (as id)
        title (aka label)
        isComplex
        customLabelName
        removeLabelWrapper
        madlib
        subtitle
        help
        devnote
        showDevNote
        required
        errorText
        last
        additionalParentClasses
        parentDisplayName
        config
    }
    */
    getDefaultProps: function getDefaultProps() {
        return {
            parentDisplayName: ""
        };
    },
    render: function render() {
        var _React$createElement, _React$createElement2;

        return React.createElement(
            'dl',
            (_React$createElement2 = { id: this.props.name + '-field', className: this.renderClasses(), 'data-codecept-selector-node': 'dl',
                'data-codecept-selector-file': 'Field'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'dl'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'dl'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Field'), _React$createElement2),
            this.renderLabel(),
            React.createElement(
                'dd',
                (_React$createElement = { className: this.renderInputClasses(), 'data-codecept-selector-node': 'dd',
                    'data-codecept-selector-file': 'Field'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Field'), _React$createElement),
                this.props.children
            ),
            this.renderWidgetButtons(),
            this.renderInfo(),
            this.renderMadlib(),
            this.renderCounter(),
            this.renderError(),
            this.renderWidgetConfig()
        );
    },

    renderInputClasses: function renderInputClasses() {
        var classes = ['rtg-input'];

        // I would like to deprecate this -- its a little messy and only the datepicker uses it
        if (this.props.addClearfix) classes.push('ui-helper-clearfix');

        return classes.join('  ');
    },

    renderLabel: function renderLabel() {
        var _React$createElement3, _React$createElement4, _React$createElement5;

        if (!this.props.title) return;

        if (this.props.isComplex) return React.createElement(
            'dt',
            (_React$createElement3 = { className: 'rtg-complex-label', 'data-codecept-selector-node': 'dt',
                'data-codecept-selector-file': 'Field'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'dt'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'dt'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Field'), _React$createElement3),
            this.props.title
        );

        var labelName = this.props.name + '-field';
        if (this.props.customLabelName) labelName = this.props.customLabel;

        var label = React.createElement(
            'label',
            (_React$createElement4 = { htmlFor: labelName, 'data-codecept-selector-node': 'label',
                'data-codecept-selector-file': 'Field'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Field'), _React$createElement4),
            this.props.title
        );

        if (this.props.removeLabelWrapper) label = this.props.title;

        return React.createElement(
            'dt',
            (_React$createElement5 = { className: 'rtg-label', 'data-codecept-selector-node': 'dt',
                'data-codecept-selector-file': 'Field'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'dt'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'dt'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Field'), _React$createElement5),
            label,
            this.renderSubtitle()
        );
    },

    renderMadlib: function renderMadlib() {
        var _React$createElement6;

        if (this.props.madlib) return React.createElement(
            'dd',
            (_React$createElement6 = { className: 'rtg-madlib', 'data-codecept-selector-node': 'dd',
                'data-codecept-selector-file': 'Field'
            }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Field'), _React$createElement6),
            this.props.madlib
        );
    },

    renderSubtitle: function renderSubtitle() {
        if (this.props.subtitle) {
            var _React$createElement7;

            return React.createElement(
                'div',
                (_React$createElement7 = { className: 'rtg-sublabel', 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Field'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Field'), _React$createElement7),
                this.props.subtitle
            );
        }
    },

    renderWidgetButtons: function renderWidgetButtons() {
        var _React$createElement8;

        return React.createElement(
            'dd',
            (_React$createElement8 = { className: 'rtg-widget-buttons', 'data-codecept-selector-node': 'dd',
                'data-codecept-selector-file': 'Field'
            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Field'), _React$createElement8),
            this.renderHelp(),
            this.renderDevNote()
        );
    },

    preventDefault: function preventDefault(e) {
        e.preventDefault();
    },

    renderHelp: function renderHelp() {
        if (this.props.help) {
            var _React$createElement9, _React$createElement10, _React$createElement11;

            return React.createElement(
                'div',
                (_React$createElement11 = { className: 'rtg-help ui-state-default', 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Field'
                }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'Field'), _React$createElement11),
                React.createElement(
                    'a',
                    (_React$createElement9 = { onClick: this.preventDefault, href: '#', className: 'ui-icon ui-icon-help', title: "Ruby Help|" + this.props.help, 'data-codecept-selector-node': 'a',
                        'data-codecept-selector-file': 'Field'
                    }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Field'), _React$createElement9),
                    '?'
                ),
                React.createElement(
                    'div',
                    (_React$createElement10 = { className: 'rtg-help-text hidden', 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'Field'
                    }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'Field'), _React$createElement10),
                    this.props.help
                )
            );
        }
    },

    renderDevNote: function renderDevNote() {
        if (this.props.devnote && this.props.showDevNote !== false) {
            var _React$createElement12, _React$createElement13;

            return React.createElement(
                'div',
                (_React$createElement13 = { className: 'rtg-help-dev ui-state-default', 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Field'
                }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'Field'), _React$createElement13),
                React.createElement(
                    'a',
                    (_React$createElement12 = { onClick: this.preventDefault, href: '#', className: 'ui-icon ui-icon-info', title: "Ruby Developer Notes|" + '<textarea>' + this.props.devnote + '</textarea>', 'data-codecept-selector-node': 'a',
                        'data-codecept-selector-file': 'Field'
                    }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'Field'), _React$createElement12),
                    'D'
                )
            );
        }
    },

    renderInfo: function renderInfo() {
        if (this.props.info) {
            var _React$createElement14;

            return React.createElement(
                'dd',
                (_React$createElement14 = { className: 'info', 'data-codecept-selector-node': 'dd',
                    'data-codecept-selector-file': 'Field'
                }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'Field'), _React$createElement14),
                this.props.info
            );
        }
    },

    renderCounter: function renderCounter() {
        var self = this;

        if (self.props.show_counter) {
            var _React$createElement15;

            var charCount = self.props.charCount;

            var charString = 'character';
            var classes = ['rtg-live-char-counter-area'];
            if (charCount !== 1) charString += 's';

            var finalString = [charCount, charString].join(' ');
            var max = self.props.max || self.props.soft_max;

            /// If we have a max, display 'out of' text
            if (max) {
                if (charCount > max) classes.push('rtg-live-char-counter-max-exceeded');
                finalString = [finalString, 'out of', max].join(' ');
            }

            return React.createElement(
                'dd',
                (_React$createElement15 = { className: classes.join('  '), 'data-codecept-selector-node': 'dd',
                    'data-codecept-selector-file': 'Field'
                }, _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'Field'), _React$createElement15),
                finalString
            );
        };
    },

    renderClasses: function renderClasses() {

        var classes = ['react'];

        if (this.displayNameToClass()) classes.push('rtg-' + this.displayNameToClass());

        if (this.props.isComplex) classes.push('rtg-complex-widget');else classes.push('rtg-widget');

        if (!this.props.title) classes.push('rtg-widget-no-label');

        if (this.props.required) classes.push('required');

        if (this.props.errorText) classes.push('rtg-widget-error');

        if (this.props.last) classes.push('rtg-last');

        if (this.props.additionalParentClasses) classes.push(this.props.additionalParentClasses);

        return classes.join(' ');
    },

    renderError: function renderError() {
        // Complex widget shouldn't show error;
        if (this.props.hideError) return;

        if (this.props.errorMessage) {
            var _React$createElement16;

            return React.createElement(
                'dd',
                (_React$createElement16 = { className: 'ui-state-error', 'data-codecept-selector-node': 'dd',
                    'data-codecept-selector-file': 'Field'
                }, _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'Field'), _React$createElement16),
                this.props.errorMessage
            );
        }

        return;
    },

    renderWidgetConfig: function renderWidgetConfig() {
        var _React$createElement17;

        var config = [];
        if (this.props.config) {
            var widget_config = this.props.config;
            Object.keys(widget_config).forEach(function (key) {
                config.push({
                    name: "ruby-config." + key,
                    value: widget_config[key]
                });
            });
        }

        return React.createElement(
            'dd',
            (_React$createElement17 = { className: 'rtg-config ui-helper-hidden', 'data-codecept-selector-node': 'dd',
                'data-codecept-selector-file': 'Field'
            }, _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'Field'), _React$createElement17),
            config.map(function (hidden) {
                var _extends2;

                hidden.type = "hidden";
                return React.createElement('input', _extends({}, hidden, (_extends2 = {
                    'data-codecept-selector-node': 'input',
                    'data-codecept-selector-file': 'Field'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'input'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Field'), _defineProperty(_extends2, 'data-codecept-selector-node', 'input'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Field'), _extends2)));
            })
        );
    }

    // Instead of setting this info individually in each of the files ( very
    // prone to copy and paste error ), let's manage them all here
    // This sets one of the class of the component
    , displayNameToClass: function displayNameToClass() {
        var parentDisplayName = this.props.parentDisplayName;
        var name = parentDisplayName.toLowerCase();

        var overrides = {
            Info: 'widget-info',
            GooglePreview: 'google-preview',
            DatePicker: 'date_picker',
            TimePicker: 'time_picker',
            DatetimePicker: 'datetime_picker',
            ProfileIdentity: 'profile-identity',
            IntlAddress: 'intl_address',
            ImagePicker: 'image-picker',
            ScheduledStatus: 'scheduled-status'
        };

        return overrides[parentDisplayName] || name;
    }

});

module.exports = Field;