'use strict';

var common = require('../common');
var typesFromID = common.actionTypesFromID;

var generators = {
    replaceLocalState: function replaceLocalState(localState) {
        var TYPES = this.getAction().TYPES;
        return {
            type: TYPES.REPLACE_LOCAL_STATE,
            payload: {
                localState: localState
            }
        };
    },
    setProps: function setProps(props) {
        var TYPES = this.getAction().TYPES;
        return {
            type: TYPES.SET_PROPS,
            payload: {
                props: props
            }
        };
    },
    setComponentIsMounted: function setComponentIsMounted() {
        var TYPES = this.getAction().TYPES;
        return {
            type: TYPES.SET_COMPONENT_IS_MOUNTED
        };
    },
    updateDisplayValueSpecs: function updateDisplayValueSpecs(displayValueSpecs) {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        return {
            type: TYPES.UPDATE_DISPLAY_VALUE_SPECS,
            payload: {
                displayValueSpecs: displayValueSpecs
            }
        };
    },
    requestRerender: function requestRerender() {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES;

        return {
            type: TYPES.UPDATE_RERENDER_TIMESTAMP,
            payload: {
                timestamp: new Date()
            }
        };
    }
};

module.exports = function () {
    var TYPES = typesFromID(this.getID());
    return {
        TYPES: TYPES,
        generators: generators
    };
};