'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Fieldset = require('./reactComponents/Fieldset');

var _Fieldset2 = _interopRequireDefault(_Fieldset);

var _FieldsetConnector = require('@rubyapps/ruby-component-mixin-field-set/src/client/reactComponents/FieldsetConnector');

var _FieldsetConnector2 = _interopRequireDefault(_FieldsetConnector);

var _action = require('./action');

var _action2 = _interopRequireDefault(_action);

var _reducer = require('./reducer');

var _reducer2 = _interopRequireDefault(_reducer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = require('../../package.json').name;
var logger = rubyLogger.getLogger(packageName);

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldSetMixin = require('@rubyapps/ruby-component-mixin-field-set');
//const fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldDynamicMixin = require('@rubyapps/ruby-component-mixin-field-dynamic');

var RCFieldset = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldSetMixin, fieldDynamicMixin],
    propTypes: {
        expandedByDefault: PropTypes.bool,
        interpolateLabel: PropTypes.oneOf([PropTypes.bool, PropTypes.oneOf(['mustache', 'lodash'])])
        //# Like field-info, we want to support dynamic labels
        //# label can be using mustache or lodash template literals
        //# eg. `My Module ${data.fieldKey} ${modules.rubyWords.ellipsify(data.fieldKey, 100)}`
        //# NOTE: the 'data' object is local to the fieldset
        //
    },
    getDefaultProps: function getDefaultProps(props) {
        return {
            expandedByDefault: true,
            visibleByDefault: props.hasOwnProperty('expandedByDefault') ? props.expandedByDefault : true
        };
    },
    componentName: componentName,
    reducer: _reducer2.default,
    action: _action2.default,
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();
        var selfAction = this.getAction();

        return {
            selfSelector: selfSelector,
            selfAction: selfAction
        };
    },

    getReactClass: function getReactClass() {
        return _FieldsetConnector2.default.call(this, _Fieldset2.default);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var FieldsetComponent = this.getReactClass();

        var mixinFieldProps = this.fieldProps_fromState_andOwnProps(this.getState(), this.props);
        var mappedProps = _lodash2.default.omit(_extends({}, this.props, mixinFieldProps), ['reactProps']);
        //# NOTE: we should update all field props to call on this.fieldProps_fromState_andOwnProps()
        //# and use that as the initial props for their respective React Components

        var retval = _react2.default.createElement(FieldsetComponent, _extends({}, mappedProps, this.props.reactProps, (_extends2 = {
            'data-codecept-selector-node': 'FieldsetComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'FieldsetComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'FieldsetComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));

        return retval;
    },

    getInitialState: function getInitialState() {
        var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        return {
            isExpanded: /*state.isExpanded ||*/this.props.expandedByDefault
            //# we encounter an issue where if the formValue is deferred in one language
            //# then we never seed the value correctly
            //# if we want to retain this state, we need to fix the mixin-dynamic
            , descendentsHaveValues: false,
            error: null
        };
    }

    //, onReduxInit: function(dispatch) { return this.watchForDescendentValues(dispatch); }
    //# UTILS
    //# returns true if this tab is the active tab
    , isVisible: function isVisible() {
        return this.getState().isExpanded;
    }

});

module.exports = RCFieldset;