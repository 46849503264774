'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

module.exports = {
    disambiguatedOptions: function disambiguatedOptions(options) {
        var _this = this;

        options = _.uniqBy(options, 'value') || [];
        var disambiguatedOptions = options.map(function (thisOption, index) {
            var otherOptions = options.slice(0, index).concat(options.slice(index + 1));
            var originalDisplayText = _this.displayTextFromOption(thisOption);

            var optionsWithMatchingDisplayText = otherOptions.filter(function (otherOption) {
                return originalDisplayText === _this.displayTextFromOption(otherOption);
            });
            var finalDisplayText = optionsWithMatchingDisplayText.length > 0 ? originalDisplayText + ' (' + thisOption.value + ')' : originalDisplayText;
            return _extends({}, thisOption, {
                text: finalDisplayText
            });
        });
        return disambiguatedOptions;
    },
    displayTextFromOption: function displayTextFromOption(opt) {
        return opt.text || opt.primaryText || opt.label || opt.value;
    },
    getSelectedOptions_fromValues: function getSelectedOptions_fromValues(options, values) {
        var disambiguatedOptions = this.disambiguatedOptions(options);

        var selectedOptions = values.reduce(function (collector, value) {
            var foundOption = disambiguatedOptions.find(function (option) {
                return option.value === value;
            });
            if (foundOption) {
                collector.push(foundOption);
            }

            return collector;
        }, []);

        return selectedOptions;
    }

};