'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TabTemplate = exports.Tabs = undefined;

var _Tabs2 = require('./Tabs');

var _Tabs3 = _interopRequireDefault(_Tabs2);

var _TabTemplate2 = require('./TabTemplate');

var _TabTemplate3 = _interopRequireDefault(_TabTemplate2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.Tabs = _Tabs3.default; //export Tab from './Tab';

exports.TabTemplate = _TabTemplate3.default;

//export default from './Tabs';