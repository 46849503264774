'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initialState;

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initialState() {
  var idCol = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'id';

  return _immutable2.default.fromJS({
    currentPosition: {
      xScrollChangePosition: 0,
      yScrollChangePosition: 0,
      renderedStartDisplayIndex: 0,
      renderedEndDisplayIndex: 20,
      visibleDataLength: 20,
      tableHeight: 500,
      tableWidth: 300,
      rowHeight: 20,
      defaultColumnWidth: 80,
      infiniteScrollLoadTreshold: 50
    },
    renderedData: [],
    expandedRows: _immutable2.default.Map(),
    idColumn: idCol
  });
}