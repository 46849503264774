'use strict';

var RubyComponentForms = require('@rubyapps/ruby-component-forms');

//== The Middleware ========================================================//

var middlewareInitializer = function middlewareInitializer(store) {
    var selfModule = this;
    var selfSelector = this.getDefaultSelector();

    var _selfModule$getAction = selfModule.getAction(),
        TYPES = _selfModule$getAction.TYPES,
        actions = _selfModule$getAction.generators;

    return function (next) {
        return function (action) {
            var nextRetVal = next(action); //# still allow @@router to continue propagation
            var mySetRouteAction = void 0;
            switch (action.type) {
                case 'BATCHING_REDUCER.BATCH':
                    var batchPayload = action.payload;
                    mySetRouteAction = _.find(batchPayload, { 'type': TYPES.SET_ROUTE_PARAMS });
                    if (mySetRouteAction) {
                        //# trigger login attempt
                        store.dispatch(actions.logIn());
                    }
                    break;
                case TYPES.SET_ROUTE_PARAMS:
                    //# trigger login attempt
                    store.dispatch(actions.logIn());
                    break;

            }
            return nextRetVal;
        };
    };
};

module.exports = middlewareInitializer;