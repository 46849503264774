'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _urlJoin = require('url-join');

var _urlJoin2 = _interopRequireDefault(_urlJoin);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var request = require('@rubyapps/ruby-superagent');

var RubyComponentEditPage__CONSTANTS = require('@rubyapps/ruby-component-material-edit-page/src/common/constants');

var ACTIVITIES_ENDPOINT = '/activities/recent-updates';

function typesWithID(id) {
    return {
        SET_DISPLAY: '@@ruby-app/' + id + '/SET_DISPLAY',
        SET_LOADING: '@@ruby-app/' + id + '/SET_LOADING',
        SET_ACTIVITIES: '@@ruby-app/' + id + '/SET_ACTIVITIES',
        SET_MODE: '@@ruby-app/' + id + '/SET_MODE'
    };
}

var generators = {
    openDisplay: function openDisplay() {
        var _getAction = this.getAction(),
            generators = _getAction.generators;

        return function (dispatch, getState) {
            dispatch(generators.setDisplay(true));
            dispatch(generators.fetchActivities());
        };
    },
    closeDisplay: function closeDisplay() {
        var _getAction2 = this.getAction(),
            generators = _getAction2.generators;

        return function (dispatch, getState) {
            dispatch(generators.setDisplay(false));
        };
    },
    setDisplay: function setDisplay(open) {
        var _getAction3 = this.getAction(),
            TYPES = _getAction3.TYPES;

        return {
            type: TYPES.SET_DISPLAY,
            payload: { open: open }
        };
    },
    showLoading: function showLoading() {
        var _getAction4 = this.getAction(),
            generators = _getAction4.generators;

        return function (dispatch, getState) {
            dispatch(generators.setLoading(true));
        };
    },
    clearLoading: function clearLoading() {
        var _getAction5 = this.getAction(),
            generators = _getAction5.generators;

        return function (dispatch, getState) {
            dispatch(generators.setLoading(false));
        };
    },
    setLoading: function setLoading(loading) {
        var _getAction6 = this.getAction(),
            TYPES = _getAction6.TYPES;

        return {
            type: TYPES.SET_LOADING,
            payload: { loading: loading }
        };
    },
    setActivities: function setActivities(activities) {
        var _getAction7 = this.getAction(),
            TYPES = _getAction7.TYPES;

        return {
            type: TYPES.SET_ACTIVITIES,
            payload: { activities: activities }
        };
    },
    fetchActivities: function fetchActivities() {
        var _getAction8 = this.getAction(),
            generators = _getAction8.generators;

        var _getDependencies = this.getDependencies(),
            currentUserSelector = _getDependencies.currentUserSelector,
            feSettingsSelector = _getDependencies.feSettingsSelector,
            selfSelector = _getDependencies.selfSelector;

        return function (dispatch, getState) {
            var applicationState = getState();
            var selfState = selfSelector(applicationState);
            var feSettings = feSettingsSelector(applicationState);
            var currentUser = currentUserSelector(applicationState);
            var restApiRoot = feSettings.restApiRoot;


            var mode = selfState.mode;
            var activitiesEndpointURL = mode === 'user' ? (0, _urlJoin2.default)(restApiRoot, ACTIVITIES_ENDPOINT, '?user_id=' + currentUser.id) : (0, _urlJoin2.default)(restApiRoot, ACTIVITIES_ENDPOINT);

            dispatch(generators.setLoading(true));

            request.get(activitiesEndpointURL).then(function (res) {
                dispatch(generators.setActivities(res.body));
                dispatch(generators.setLoading(false));
            }, function (err) {
                dispatch(generators.setLoading(false));
            });
        };
    }
    /**
     *  @param options.pathOptions -  additional path options 
     */
    , editEntity_ofModel: function editEntity_ofModel(entityInfo, modelName) {
        var _this = this;

        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        var rootComponent = this.getRoot();
        var id = entityInfo.id,
            template_keyword = entityInfo.template_keyword,
            content_subsite_fk = entityInfo.content_subsite_fk,
            ruby_client_fk = entityInfo.ruby_client_fk;

        var _getAction9 = this.getAction(),
            generators = _getAction9.generators;

        var _options$pathOptions = options.pathOptions,
            pathOptions = _options$pathOptions === undefined ? {} : _options$pathOptions;


        return function (dispatch, getState) {
            var applicationState = getState();

            var editLink = rootComponent.getBestUrl_fromModule_withParams(_this, {
                action: 'edit',
                template: template_keyword,
                id: id,
                subsite: _this.getSubsiteUrlIdentifier_forRubyClientId_andSubsiteId(ruby_client_fk, content_subsite_fk),
                content_subsite_fk: content_subsite_fk,
                modelName: modelName
                // TODO: ruby client url
            }, {
                //# only consider routes that have an `id` param
                //# this is more general than checking that node.componentName === 'rubyComponentEditPage'
                matchedParamsValidator: 'id'
            });

            dispatch(generators.setDisplay(false));
            dispatch(generators.navigateToPathWithOptions(_extends({ path: editLink }, pathOptions)));
        };
    },
    setMode: function setMode(mode) {
        var _getAction10 = this.getAction(),
            TYPES = _getAction10.TYPES;

        return {
            type: TYPES.SET_MODE,
            payload: { mode: mode }
        };
    }
};

module.exports = function () {
    var TYPES = typesWithID(this.getID());
    return {
        TYPES: TYPES,
        generators: generators
    };
};