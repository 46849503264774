'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = FieldsetConnectorGenerator;

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _mustache = require('mustache');

var _mustache2 = _interopRequireDefault(_mustache);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rubyWords = require('../../../../ruby-words');

function FieldsetConnectorGenerator(Component) {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector;

    var selfKey = this.key();

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);
        var interpolateLabel = ownProps.interpolateLabel,
            rawLabel = ownProps.label;


        var view = !interpolateLabel ? {} : _extends({
            data: selfKey ? selfModule.formValue()[selfKey] : selfModule.formvValue(),
            modules: {
                rubyWords: rubyWords
            }
        });

        var interpolatedLabel = rawLabel;

        if (rawLabel && interpolateLabel) {
            switch (interpolateLabel) {
                case 'lodash':
                    interpolatedLabel = _lodash2.default.template(rawLabel)(view);
                    break;
                case true:
                case 'mustache':
                    interpolatedLabel = _mustache2.default.render(rawLabel, view);
                    break;
                default:
                    interpolatedLabel = rawLabel;
                    break;
            }
        }
        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
        var formattedError = selfModule.formattedErrorFromState(state);

        var mappedProps = _extends({}, selfState, ownProps, mixinFieldProps, formattedError, {
            descendentsHaveValues: selfModule.doesDescendentsHaveValuesFromForm(selfModule.formValue()),
            label: interpolatedLabel,
            children: ownProps.getMergedChildrenReactElements ? ownProps.getMergedChildrenReactElements() : ownProps.children
        });

        //# hydrate actionButtons
        if (mappedProps.actionButtons) {
            mappedProps.actionButtons = selfModule.hydratedActionButtons(mappedProps.actionButtons);
        }

        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(Component);
}