'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _redux = require('redux');

var _action = require('./action');

var _action2 = _interopRequireDefault(_action);

var _reducer = require('./reducer');

var _reducer2 = _interopRequireDefault(_reducer);

var _IndicatingIconButton = require('../../../ruby-react-components/src/client/input/IndicatingIconButton');

var _IndicatingIconButton2 = _interopRequireDefault(_IndicatingIconButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

//import IconButton from 'material-ui/IconButton';

var RubyIcons = require('@rubyapps/ruby-icons');
var mixinFieldProps = require('../../../ruby-component-mixin-field-props/src/client/index');
var baseFieldMixin = require('../../../ruby-component-mixin-field-base/src/client/index');

function isEqualShallow(a, b) {
    if (a == undefined || b == undefined) {
        return false;
    }
    for (var key in a) {
        if (!(key in b) || a[key] !== b[key]) {
            return false;
        }
    }
    for (var key in b) {
        if (!(key in a) || a[key] !== b[key]) {
            return false;
        }
    }
    return true;
}

function flattenObject() {
    var o = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return _extends.apply(undefined, [{}].concat(_toConsumableArray(function _flatten(o, keypath) {
        var _ref;

        return (_ref = []).concat.apply(_ref, _toConsumableArray(Object.keys(o).map(function (k) {
            var currentKeypath = keypath + '.' + k;
            var currentValue = o[k];

            return _.isObject(currentValue) ? _flatten(currentValue, currentKeypath) : _defineProperty({}, currentKeypath, currentValue);
        })));
    }(o, ''))));
}

//# NOTE: field RubyComponents should either mixin fieldSetMixin or fieldValidationsMixin
var fieldSetMixin = {
    mixinName: 'rubyComponentMixinFieldSet',
    propTypes: {
        actionButtons: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({
            action: baseFieldMixin.propTypes.action,
            icon: PropTypes.node,
            rubyIconPath: PropTypes.string
        }), PropTypes.node //# the raw react component
        ]))
    },
    action: _action2.default,
    reducer: _reducer2.default,
    key: function key() {
        return this.props.key;
    },
    statesSelector: function statesSelector(state) {
        var selfSelector = this.getDefaultSelector();
        //# Since fieldsets usually wrap other components, we should use the default selector by default to 
        //# also watch for nested changes

        return _extends({
            self: selfSelector(state)
        }, this.statesSelector_fromState_andOwnProps ? { selectedState: this.statesSelector_fromState_andOwnProps(state, this.props) } : { isActive: this.isFieldActiveForNamespace_fromState(state) });
    },

    _getIconFromSpec: function _getIconFromSpec(spec) {
        if (spec.icon) {
            return icon;
        } else if (spec.rubyIconPath) {
            var _React$createElement;

            var IconClass = RubyIcons.getIconClassForPath(spec.rubyIconPath);
            return _react2.default.createElement(IconClass, (_React$createElement = {
                style: { marginTop: -4 },
                'data-codecept-selector-node': 'IconClass',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'IconClass'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'IconClass'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement));
        }
    },
    hydratedActionButtons: function hydratedActionButtons(actionButtons) {
        var _this = this;

        if (!actionButtons) {
            actionButtons = this.props.actionButtons || [];
        }
        var hydratedActionButtons = actionButtons.map(function (actionButton) {
            if (_.isPlainObject(actionButton) && actionButton.hasOwnProperty('action')) {
                var _React$createElement2;

                var hydratedActionButtonSpecs = _this._hydrateReferenceProps_withProps(actionButton);
                var hydratedAction = hydratedActionButtonSpecs.action;
                //console.log('hydratedActionButtonSpecs', actionButton, hydratedActionButtonSpecs);

                var boundAction = hydratedAction ? (0, _redux.bindActionCreators)(hydratedAction, _this.getStore().dispatch) : function () {};

                //# hydrate
                return _react2.default.createElement(
                    _IndicatingIconButton2.default,
                    (_React$createElement2 = {
                        disabled: _this.props.disabled,
                        isActive: hydratedActionButtonSpecs.isActive,
                        tooltip: hydratedActionButtonSpecs.label,
                        tooltipPosition: 'top-center',
                        tooltipStyles: {
                            top: 6
                        },
                        onTouchTap: function onTouchTap(event) {
                            event.stopPropagation();
                            boundAction();
                            return false;
                        },
                        'data-codecept-selector-node': 'IndicatingIconButton',
                        'data-codecept-selector-file': 'index'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'IndicatingIconButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'IndicatingIconButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'index'), _React$createElement2),
                    _this._getIconFromSpec(actionButton)
                );
            } else {
                //# assuming it's a React ndoe
                return actionButton;
            }
        });

        return hydratedActionButtons;
    },
    statesSelector_fromState_andOwnProps: function statesSelector_fromState_andOwnProps(state, ownProps) {
        var _this2 = this;

        var fieldPerms = _.get(ownProps, 'ruby_permissions', []);
        var selfKey = this.key();
        var id = this.getID();

        return {
            id: id,
            fieldKey: selfKey //key is not exposed to the react component
            , isActive: this.isFieldActiveForNamespace_fromState ? this.isFieldActiveForNamespace_fromState(state) : true,
            locked: ownProps.hasOwnProperty('locked') && ownProps.locked == true ? ownProps.locked : fieldPerms.reduce(function (lockedFlag, perm) {
                return lockedFlag || !_this2._doesUserHaveFieldPermission_forComponent(perm, _this2);
            }, false)
        };
    },
    fieldProps_fromState_andOwnProps: function fieldProps_fromState_andOwnProps(state, ownProps) {
        var _boundGridWrapperRenderer = void 0;
        if (ownProps.renderGridWrapper) {
            _boundGridWrapperRenderer = ownProps.renderGridWrapper;
        } else {
            if (!this._boundGridWrapperRenderer) {
                this._boundGridWrapperRenderer = this.renderGridWrapper.bind(this);
            }
            _boundGridWrapperRenderer = this._boundGridWrapperRenderer;
        }

        var selectedState = fieldSetMixin.statesSelector_fromState_andOwnProps.apply(this, arguments);

        return _extends({
            key: selectedState.id //# use id instead of selfKey for the React element key
            , renderGridWrapper: _boundGridWrapperRenderer
        }, selectedState);
    },

    _doesUserHaveFieldPermission_forComponent: mixinFieldProps._doesUserHaveFieldPermission_forComponent,
    doesDescendentsHaveValuesFromForm: function doesDescendentsHaveValuesFromForm(formValue) {
        if (!formValue) {
            formValue = this.formValue();
        }

        var flattenedFormValue = flattenObject(formValue);
        var descendentsHaveValues = _.reduce(flattenedFormValue, function (collector, value, key) {
            if (collector) {
                return collector;
            }
            if (!(_.isNil(value) || value == "")) {
                return true;
            }
            return collector;
        }, false);

        return descendentsHaveValues;
    },

    watchForDescendentValues: function watchForDescendentValues(dispatch) {
        var _this3 = this;

        console.warn('[DEPRECATED 20180720] We should prefer to use doesDescendentsHaveValuesFromForm() and call it on mapStateToProps(). If the component is not rerendering on children state updates, make sure the statesSelector is watching for children state as well');
        var store = this.getStore();
        var actions = this.getAction().generators;

        this._previousDescendentsHaveValues = false;
        this._previousState;

        var OMIT_KEYS = ['isExpanded', 'isOpen', 'descendentsHaveValues', 'error'];
        return store.subscribe(function () {
            var newState = _this3.getState();
            var previousStateToCompare = _.omit(_this3._previousState, OMIT_KEYS);
            var newStateToCompare = _.omit(newState, OMIT_KEYS);
            var previousDescendentsHaveValues = _this3._previousDescendentsHaveValues;

            //# TODO: shalllow equal not working.
            if (!isEqualShallow(previousStateToCompare, newStateToCompare)) {
                _this3._previousState = newState;

                var newFormValue = _this3.formValue();
                //# check if there are values in formValue
                //const flattenedFormValue = flattenObject(newFormValue);
                var newDescendentsHaveValues = _this3.doesDescendentsHaveValuesFromForm(newFormValue);

                if (previousDescendentsHaveValues != newDescendentsHaveValues) {
                    _this3._previousDescendentsHaveValues = newDescendentsHaveValues;
                    //# need to flip the state
                    store.dispatch(actions.setDescendentsHaveValues(newDescendentsHaveValues)); //# this value is to allow the fieldset to rerender properly
                    //# it should not be used to inform
                }
            }
        });
    }

};

module.exports = fieldSetMixin;