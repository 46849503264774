'use strict';

module.exports = {
    PLUGIN_NAME: 'rubyResourceLocator',
    ASSET_CONSTANTS: {
        LARGETHUMB: 'largethumb',
        WWW_ASSET_URL: 'wwwimageurl',
        IMAGE_PATH: 'imagepath'
    },
    COMPONENT_NAME: 'rubyComponentResourceLocator'
};