'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _arrowDropDown = require('material-ui/svg-icons/navigation/arrow-drop-down');

var _arrowDropDown2 = _interopRequireDefault(_arrowDropDown);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _IconMenu = require('material-ui/IconMenu');

var _IconMenu2 = _interopRequireDefault(_IconMenu);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _PropTable_style = require('./PropTable_style');

var _PropTable_style2 = _interopRequireDefault(_PropTable_style);

var _transitions = require('material-ui/styles/transitions');

var _transitions2 = _interopRequireDefault(_transitions);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var ReactDOM = require('react-dom');

var request = require('@rubyapps/ruby-superagent');

var PropTypes = React.PropTypes;

var TextField__propTypeKeys = Object.keys(_TextField2.default.propTypes);


var rowHeight = 30;
var underlineShow = false;
var textFieldProps = {
    underlineShow: underlineShow,
    //lineHeight: rowHeight, //# NOTE: invalid prop
    fullWidth: true,
    inputStyle: {
        textOverflow: 'ellipsis'
    },
    style: {
        height: 'auto',
        width: '100%'
    }
};

var multiselectPropTypes = {
    url: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: function value(props, propName, componentName) {
            var propValue = props[propName];
            if (!_lodash2.default.isString(propValue) && propValue != null) {
                return new Error('Invalid prop `' + propName + '` supplied to' + ' `' + componentName + '`. Validation failed.');
            }
        },
        primaryText: PropTypes.string.isRequired
    }))
};

var getStyles = function getStyles(context) {
    var errorColor = context.muiTheme.textField.errorColor;


    var styles = {
        error: {
            position: 'relative',
            fontSize: 12,
            lineHeight: '12px',
            color: errorColor,
            transition: _transitions2.default.easeOut(),
            bottom: 2,
            paddingLeft: 8
        }
    };

    return styles;
};

var Multiselect = React.createClass({
    displayName: 'Multiselect',

    propTypes: multiselectPropTypes,
    getDefaultProps: function getDefaultProps() {
        return {
            options: [],
            url: ''
        };
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    componentWillMount: function componentWillMount() {
        if (this.props.url) {
            this._retrieveOptions_withURL(this.props.url);
        } else {
            this.setState({ options: this.props.options || [] });
        }
    },
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        if (nextProps.url && this.props.url !== nextProps.url) {
            this._retrieveOptions_withURL(nextProps.url);
        } else if (nextProps.options != _lodash2.default.get(this, 'props.options', [])) {
            this.setState({ options: nextProps.options || [] });
        }
    },
    _retrieveOptions_withURL: function _retrieveOptions_withURL(url) {
        var self = this;
        url && request.get(url).then(function (response) {
            var retrievedOptions = (response.body.data || []).map(function (option) {
                return { primaryText: option.text, value: option.value };
            });
            var propOptions = _lodash2.default.get(self, 'props.options', []);
            var newOptions = _lodash2.default.flowRight(function (arr) {
                return _lodash2.default.uniqWith(arr, _lodash2.default.isEqual);
            }, function (arr) {
                return arr.filter(_lodash2.default.identity);
            }, function (arr1, arr2) {
                return [].concat(arr1, arr2);
            })(propOptions, retrievedOptions);
            self.setState({ options: newOptions });
        }).catch(function (err) {
            console.error(err);
        });
    },
    render: function render() {
        var _React$createElement,
            _extends2,
            _React$createElement2,
            _React$createElement3,
            _React$createElement4,
            _React$createElement5,
            _extends3,
            _this = this,
            _extends5,
            _React$createElement6;

        var currentValue = this.props.multiple ? (this.props.fieldProps.value || []).map(JSON.stringify) : [JSON.stringify(this.props.fieldProps.value)];
        var rawOptions = _lodash2.default.get(this, 'state.options', []);
        var options = rawOptions.map(function (option) {
            return _extends({}, option, {
                value: JSON.stringify(option.value),
                raw_value: option.value
            });
        });

        var hasMoreThanOneOption = options.length > 1;

        var prepareStyles = this.context.muiTheme.prepareStyles;

        var styleObjectFromTheme = getStyles(this.context);

        var errorTextElement = _lodash2.default.get(this, 'props.fieldProps.errorText') ? React.createElement(
            'div',
            (_React$createElement = { style: prepareStyles(styleObjectFromTheme.error), 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Multiselect'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Multiselect'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Multiselect'), _React$createElement),
            _lodash2.default.get(this, 'props.fieldProps.errorText')
        ) : null;

        return React.createElement(
            'div',
            (_React$createElement6 = {
                style: _extends({
                    width: '100%'
                }, this.props.fieldProps.style),
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Multiselect'
            }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Multiselect'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Multiselect'), _React$createElement6),
            React.createElement(
                _IconMenu2.default,
                _extends({
                    multiple: this.props.multiple,
                    touchTapCloseDelay: this.props.multiple && hasMoreThanOneOption ? 0 : null,
                    useLayerForClickAway: true,
                    anchorOrigin: {
                        horizontal: 'left',
                        vertical: this.props.multiple ? 'bottom' : 'top'
                    },
                    targetOrigin: {
                        horizontal: 'left',
                        vertical: 'top'
                    },
                    iconButtonElement: React.createElement(
                        'div',
                        _extends({}, _PropTable_style2.default.CellContentWrapper, (_extends3 = {
                            'data-codecept-selector-node': 'div',
                            'data-codecept-selector-file': 'Multiselect'
                        }, _defineProperty(_extends3, 'data-codecept-selector-node', 'div'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Multiselect'), _defineProperty(_extends3, 'data-codecept-selector-node', 'div'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Multiselect'), _extends3)),
                        React.createElement(
                            'div',
                            (_React$createElement5 = { style: { display: 'flex' }, 'data-codecept-selector-node': 'div',
                                'data-codecept-selector-file': 'Multiselect'
                            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Multiselect'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Multiselect'), _React$createElement5),
                            React.createElement(
                                'div',
                                (_React$createElement2 = { style: { flex: 1 }, 'data-codecept-selector-node': 'div',
                                    'data-codecept-selector-file': 'Multiselect'
                                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Multiselect'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Multiselect'), _React$createElement2),
                                React.createElement(_TextField2.default, _extends({
                                    name: this.props.propKey,
                                    value: options.filter(function (option) {
                                        var optionIsSelected = currentValue.includes(option.value);
                                        return optionIsSelected;
                                    }).map(function (option) {
                                        return option.primaryText || option.value;
                                    }).join(', ')
                                }, _lodash2.default.merge({}, textFieldProps, { inputStyle: { cursor: 'pointer' } }), (_extends2 = {
                                    'data-codecept-selector-node': 'TextField',
                                    'data-codecept-selector-file': 'Multiselect'
                                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Multiselect'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Multiselect'), _extends2)))
                            ),
                            React.createElement(
                                'div',
                                (_React$createElement4 = {
                                    style: {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    },
                                    'data-codecept-selector-node': 'div',
                                    'data-codecept-selector-file': 'Multiselect'
                                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Multiselect'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Multiselect'), _React$createElement4),
                                React.createElement(_arrowDropDown2.default, (_React$createElement3 = { style: _extends({}, this.props.styles.icon), 'data-codecept-selector-node': 'DropDownArrow',
                                    'data-codecept-selector-file': 'Multiselect'
                                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'DropDownArrow'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Multiselect'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'DropDownArrow'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Multiselect'), _React$createElement3))
                            )
                        )
                    ),
                    key: this.props.propKey,
                    underlineShow: underlineShow,
                    disabled: this.props.disabled
                }, _extends({}, this.props.fieldProps, {
                    value: currentValue
                }), (_extends5 = {
                    onChange: function onChange(event, value) {
                        var parsedValue = _lodash2.default.isArray(value) ? value.map(JSON.parse) : JSON.parse(value);
                        return _this.props.fieldProps.onChange(event, parsedValue);
                    },
                    style: _extends({
                        minHeight: rowHeight,
                        width: '100%'
                    }, this.props.fieldProps.style),
                    width: this.props.dropdownWidth || 400,
                    autoWidth: false,
                    desktop: true,
                    'data-codecept-selector-node': 'IconMenu',
                    'data-codecept-selector-file': 'Multiselect'
                }, _defineProperty(_extends5, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Multiselect'), _defineProperty(_extends5, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Multiselect'), _extends5)),
                options.map(function (option, idx) {
                    var _extends4;

                    var optionIsSelected = currentValue.includes(option.value);
                    return React.createElement(_MenuItem2.default, _extends({
                        key: idx,
                        checked: optionIsSelected,
                        insetChildren: true
                    }, _extends({}, option, {
                        primaryText: option.primaryText || option.value
                    }), (_extends4 = {
                        'data-codecept-selector-node': 'MenuItem',
                        'data-codecept-selector-file': 'Multiselect'
                    }, _defineProperty(_extends4, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Multiselect'), _defineProperty(_extends4, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Multiselect'), _extends4)));
                })
            ),
            errorTextElement
        );
    }
});

exports.default = Multiselect;