'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Chip = require('@rubyapps/ruby-material-ui/Chip');

var _Chip2 = _interopRequireDefault(_Chip);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _Divider = require('material-ui/Divider');

var _Divider2 = _interopRequireDefault(_Divider);

var _RaisedButton = require('material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _addCircle = require('material-ui/svg-icons/content/add-circle');

var _addCircle2 = _interopRequireDefault(_addCircle);

var _SuggestionList_style = require('./SuggestionList_style');

var _SuggestionList_style2 = _interopRequireDefault(_SuggestionList_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PropTypes = _react2.default.PropTypes;

//# Material UI


//# Styles
var fieldBaseStyles = process.browser ? require('@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule') : {};
var suggestionListStyles = process.browser ? require('./SuggestionList.cssModule') : {};
var styles = _extends({}, fieldBaseStyles, suggestionListStyles);

var ChipItem = function (_Component) {
    _inherits(ChipItem, _Component);

    function ChipItem() {
        _classCallCheck(this, ChipItem);

        return _possibleConstructorReturn(this, (ChipItem.__proto__ || Object.getPrototypeOf(ChipItem)).apply(this, arguments));
    }

    _createClass(ChipItem, [{
        key: 'render',
        value: function render() {
            var _extends3;

            var _props = this.props,
                _props$actionButtons = _props.actionButtons,
                actionButtons = _props$actionButtons === undefined ? [] : _props$actionButtons,
                id = _props.id,
                children = _props.children,
                rest = _objectWithoutProperties(_props, ['actionButtons', 'id', 'children']);

            var additionalButtons = actionButtons.map(function (actionButton, idx) {
                var _extends2;

                var icon = actionButton.icon,
                    label = actionButton.label,
                    action = actionButton.action;


                return _react2.default.createElement(
                    _IconButton2.default,
                    _extends({
                        key: 'actionButton-' + idx
                    }, _SuggestionList_style2.default.IconButton, (_extends2 = {
                        tooltip: label,
                        onTouchTap: function onTouchTap() {
                            action && action(id);
                        },
                        'data-codecept-selector-node': 'IconButton',
                        'data-codecept-selector-file': 'SuggestionList'
                    }, _defineProperty(_extends2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'SuggestionList'), _defineProperty(_extends2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'SuggestionList'), _extends2)),
                    icon
                );
            });

            var Content = _react2.default.createElement(
                _Chip2.default,
                _extends({
                    additionalButtons: additionalButtons,
                    backgroundColor: 'rgb(218 227 231)',
                    allowEventPropagation: true,
                    className: styles.chip
                }, rest, (_extends3 = {
                    labelStyle: {
                        width: '100%'
                    },
                    style: {
                        width: '100%',
                        boxShadow: '0 1px 1px 1px rgba(0,0,0,0.2)',
                        marginBottom: 10
                    },
                    'data-codecept-selector-node': 'Chip',
                    'data-codecept-selector-file': 'SuggestionList'
                }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Chip'), _defineProperty(_extends3, 'data-codecept-selector-file', 'SuggestionList'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Chip'), _defineProperty(_extends3, 'data-codecept-selector-file', 'SuggestionList'), _extends3)),
                children
            );

            return Content;
        }
    }]);

    return ChipItem;
}(_react.Component);

ChipItem.contextTypes = {
    muiTheme: PropTypes.object
};
ChipItem.propTypes = {
    actionButtons: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.node,
        label: PropTypes.node,
        action: PropTypes.func
    })),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.node
};

var SuggestionList = _react2.default.createClass({
    displayName: 'SuggestionList',

    propTypes: {
        addSelections_withIds: PropTypes.func,
        values: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            text: PropTypes.object
        }))
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            values: []
        };
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement7, _React$createElement8;

        var _props2 = this.props,
            values = _props2.values,
            addSelections_withIds = _props2.addSelections_withIds;


        var actionButtons = _lodash2.default.compact([addSelections_withIds && {
            icon: _react2.default.createElement(_addCircle2.default, (_React$createElement = {
                'data-codecept-selector-node': 'AddCircleIcon',
                'data-codecept-selector-file': 'SuggestionList'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'AddCircleIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'SuggestionList'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'AddCircleIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'SuggestionList'), _React$createElement)),
            label: 'Add',
            action: addSelections_withIds
        }]);

        return _react2.default.createElement(
            'div',
            (_React$createElement8 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'SuggestionList'
            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'SuggestionList'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'SuggestionList'), _React$createElement8),
            _react2.default.createElement(
                'div',
                (_React$createElement5 = {
                    style: {
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 10,
                        marginBottom: 14
                    },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'SuggestionList'
                }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'SuggestionList'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'SuggestionList'), _React$createElement5),
                _react2.default.createElement(_Divider2.default, (_React$createElement2 = { style: { flexGrow: 1 }, 'data-codecept-selector-node': 'Divider',
                    'data-codecept-selector-file': 'SuggestionList'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'SuggestionList'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'SuggestionList'), _React$createElement2)),
                addSelections_withIds && _react2.default.createElement(_RaisedButton2.default, (_React$createElement3 = {
                    className: styles.AddAll,
                    label: "▲ Add all suggested ▲",
                    labelPosition: 'before',
                    primary: true,
                    onClick: function onClick() {
                        var allSuggestedIds = values.map(function (el) {
                            return el.id;
                        });
                        return addSelections_withIds(allSuggestedIds);
                    },
                    'data-codecept-selector-node': 'RaisedButton',
                    'data-codecept-selector-file': 'SuggestionList'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'SuggestionList'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'SuggestionList'), _React$createElement3)),
                _react2.default.createElement(_Divider2.default, (_React$createElement4 = { style: { flexGrow: 1 }, 'data-codecept-selector-node': 'Divider',
                    'data-codecept-selector-file': 'SuggestionList'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'SuggestionList'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'SuggestionList'), _React$createElement4))
            ),
            _react2.default.createElement(
                'div',
                (_React$createElement7 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'SuggestionList'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'SuggestionList'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'SuggestionList'), _React$createElement7),
                (values || []).map(function (value) {
                    var _React$createElement6;

                    var id = value.id,
                        text = value.text;


                    return _react2.default.createElement(
                        ChipItem,
                        (_React$createElement6 = {
                            key: id,
                            id: id,
                            actionButtons: actionButtons,
                            'data-codecept-selector-node': 'ChipItem',
                            'data-codecept-selector-file': 'SuggestionList'
                        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'ChipItem'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'SuggestionList'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'ChipItem'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'SuggestionList'), _React$createElement6),
                        text
                    );
                })
            )
        );
    }
});

exports.default = SuggestionList;