'use strict';

var _lightRubyTheme = require('./materialThemes/lightRubyTheme');

var _lightRubyTheme2 = _interopRequireDefault(_lightRubyTheme);

var _lightRubyTheme_bright = require('./materialThemes/lightRubyTheme_bright');

var _lightRubyTheme_bright2 = _interopRequireDefault(_lightRubyTheme_bright);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

require('./styles/index.css');

//require('@fortawesome/fontawesome-free/css/fontawesome.css');
//require('@fortawesome/fontawesome-free/css/regular.css');
require('@fortawesome/fontawesome-free/css/all.css');
require('@fortawesome/fontawesome-free/css/v4-shims.css');

module.exports = {
    materialTheme: {
        default: _lightRubyTheme2.default,
        bright: _lightRubyTheme_bright2.default
    }
};