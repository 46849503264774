'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _selectedColumnsPropsHelper = require('@rubyapps/ruby-component-mixin-list-page/src/client/reactComponents/selectedColumnsPropsHelper');

var _selectedColumnsPropsHelper2 = _interopRequireDefault(_selectedColumnsPropsHelper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function SitemapUtilityBarConnector(selfModule, boundActions, boundDelegateActions, filterOptions)
//# Reference the AdvancedSearch utilityBar in mixin-list-page
{
    var anyReasonsForDisabledModifySitemap = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
    var getRubyComponentProps = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : function () {
        return {};
    };

    var canUserEdit = anyReasonsForDisabledModifySitemap.length === 0;
    var anyReasonsForDisabledAddEntry = selfModule.getAnyReasonsForDisabledAddEntry();

    return function (UtilityBar) {
        return function render(griddleProps) {
            var _extends4;

            var utilityBarProps = griddleProps;
            var customFilterName = 'template';
            var customSortableToggleName = 'sortable_enabled';
            var addEntry = function addEntry() {
                boundActions.addPageDialog_withParentId(null);
            };

            var utilityBarState = utilityBarProps.utilityBarProperties || {};
            var currentFilterValue = utilityBarState[customFilterName];
            var currentSortableToggleValue = utilityBarState[customSortableToggleName];

            var allowColumnSelection = _.get(selfModule.props, 'allowColumnSelection');

            var filterPropsArr = filterOptions.map(function (filter) {
                return {
                    options: filter.options,
                    optionsUrl: filter.optionsUrl,
                    name: filter.key,
                    header: filter.header,
                    headerValue: filter.headerValue,
                    value: _.get(utilityBarState, ['dropdownFilters', filter.key], null),
                    delegateOnChange: function delegateOnChange(event, key, value) {
                        var utilityBarEvents = utilityBarProps.events;
                        var currentDropdownFilters = utilityBarState.dropdownFilters;
                        var newDropdownFilters = _extends({}, currentDropdownFilters, _defineProperty({}, filter.key, value));
                        utilityBarEvents.setUtilityBarProperties(_extends({}, utilityBarState, _defineProperty({
                            dropdownFilters: newDropdownFilters
                        }, customSortableToggleName, !value && utilityBarState[customSortableToggleName])));
                        utilityBarEvents.getPage(1, true); //# jump back to the first page
                        //utilityBarEvents.refreshCurrentPage();
                    }
                };
            });
            var sortableToggle = function sortableToggle(event, toggled) {
                var _utilityBarEvents$set;

                var utilityBarEvents = utilityBarProps.events;

                utilityBarEvents.setUtilityBarProperties((_utilityBarEvents$set = {}, _defineProperty(_utilityBarEvents$set, customSortableToggleName, toggled), _defineProperty(_utilityBarEvents$set, 'dropdownFilters', {}), _utilityBarEvents$set));
                utilityBarEvents.refreshCurrentPage();
            };

            //# for column selection
            var rubyComponentProps = getRubyComponentProps();
            var selectedColumnsProps = (0, _selectedColumnsPropsHelper2.default)(rubyComponentProps, griddleProps);
            return _react2.default.createElement(UtilityBar, _extends({
                filterPropsArr: filterPropsArr,
                toggleProps: {
                    name: customSortableToggleName,
                    value: currentSortableToggleValue,
                    delegateOnToggle: sortableToggle,
                    disabled: function disabled() {
                        return utilityBarProps.state.filter != null && utilityBarProps.state.filter !== '' || !canUserEdit;
                    }
                },
                addEntryProps: {
                    disabled: !canUserEdit || anyReasonsForDisabledAddEntry[0],
                    disabledMessage: anyReasonsForDisabledAddEntry[0],
                    delegateOnClick: addEntry
                },
                griddleProps: griddleProps,
                selfModule: selfModule,
                allowColumnSelection: allowColumnSelection
            }, selectedColumnsProps, (_extends4 = {
                'data-codecept-selector-node': 'UtilityBar',
                'data-codecept-selector-file': 'indexConnector'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'UtilityBar'), _defineProperty(_extends4, 'data-codecept-selector-file', 'indexConnector'), _defineProperty(_extends4, 'data-codecept-selector-node', 'UtilityBar'), _defineProperty(_extends4, 'data-codecept-selector-file', 'indexConnector'), _extends4)));
        };
    };
}

exports.default = SitemapUtilityBarConnector;