'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Footer = require('./Footer.cssModule');

var _Footer2 = _interopRequireDefault(_Footer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

var PropTypes = _react2.default.PropTypes;

var action = require('../action');

var Footer = _react2.default.createClass({
    displayName: 'Footer',

    propTypes: {
        footerString: PropTypes.string,
        cmsVersionString: PropTypes.string,
        projectVersionString: PropTypes.string
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6;

        var currentYear = new Date().getFullYear();
        return _react2.default.createElement(
            'div',
            (_React$createElement6 = { className: _Footer2.default.footer, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Footer'
            }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Footer'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Footer'), _React$createElement6),
            _react2.default.createElement(
                'p',
                (_React$createElement5 = { className: _Footer2.default.copy, 'data-codecept-selector-node': 'p',
                    'data-codecept-selector-file': 'Footer'
                }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Footer'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Footer'), _React$createElement5),
                'RubyApps Version: ',
                this.props.cmsVersionString,
                _react2.default.createElement('br', (_React$createElement = {
                    'data-codecept-selector-node': 'br',
                    'data-codecept-selector-file': 'Footer'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Footer'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Footer'), _React$createElement)),
                'Project Version: ',
                this.props.projectVersionString,
                _react2.default.createElement('br', (_React$createElement2 = {
                    'data-codecept-selector-node': 'br',
                    'data-codecept-selector-file': 'Footer'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Footer'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Footer'), _React$createElement2)),
                'Copyright \xA9 1999\u2013',
                currentYear,
                ',',
                _react2.default.createElement('br', (_React$createElement3 = {
                    'data-codecept-selector-node': 'br',
                    'data-codecept-selector-file': 'Footer'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Footer'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Footer'), _React$createElement3)),
                _react2.default.createElement(
                    'a',
                    (_React$createElement4 = { className: 'rtg-ignore-dirty-form',
                        href: 'http://www.rubensteintech.com/',
                        target: '_blank',
                        style: { 'whiteSpace': 'nowrap' }, 'data-codecept-selector-node': 'a',
                        'data-codecept-selector-file': 'Footer'
                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Footer'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Footer'), _React$createElement4),
                    'Rubenstein Technology Group, Inc.'
                ),
                ' All\xA0Rights\xA0Reserved.'
            )
        );
    }
});
exports.default = Footer;