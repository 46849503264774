'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _RaisedButton = require('material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _Card = require('material-ui/Card');

var _keycode = require('keycode');

var _keycode2 = _interopRequireDefault(_keycode);

var _node2fa = require('node-2fa');

var _node2fa2 = _interopRequireDefault(_node2fa);

var _qrcode = require('qrcode.react');

var _qrcode2 = _interopRequireDefault(_qrcode);

var _asyncButton = require('../../../../ruby-react-components/src/client/hoc/asyncButton');

var _asyncButton2 = _interopRequireDefault(_asyncButton);

var _CopyrightFooter = require('@rubyapps/ruby-react-components/src/client/layout/CopyrightFooter');

var _CopyrightFooter2 = _interopRequireDefault(_CopyrightFooter);

var _arrowBack = require('material-ui/svg-icons/navigation/arrow-back');

var _arrowBack2 = _interopRequireDefault(_arrowBack);

var _Login = require('./Login.cssModule');

var _Login2 = _interopRequireDefault(_Login);

var _Login_style = require('./Login_style');

var _Login_style2 = _interopRequireDefault(_Login_style);

var _keyURIConstructor = require('@rubyapps/ruby-component-field-TFA/src/client/keyURI-constructor');

var _keyURIConstructor2 = _interopRequireDefault(_keyURIConstructor);

var _TFAInfo = require('@rubyapps/ruby-component-field-TFA/src/client/reactComponents/TFAInfo');

var _TFAInfo2 = _interopRequireDefault(_TFAInfo);

var _rubyLogo = require('@rubyapps/ruby-logo');

var _rubyLogo2 = _interopRequireDefault(_rubyLogo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

var PropTypes = _react2.default.PropTypes;

var _require = require('@rubyapps/react-flexbox-grid'),
    Grid = _require.Grid,
    Row = _require.Row,
    Col = _require.Col;

var AsyncRaisedButton = (0, _asyncButton2.default)(_RaisedButton2.default);
//# icons


//# Local modules
var CONSTANTS = require('../../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;
var ERROR_CODES = CONSTANTS.ERROR_CODES;

//# Styles


var errorPropType = PropTypes.shape({
    message: PropTypes.string
});
var LOADER_DELAY = 500;
var Edit = _react2.default.createClass({
    displayName: 'Edit',

    propTypes: {
        clientName: PropTypes.string,
        rubyBranding: PropTypes.string,
        rubyContributors: PropTypes.array,
        actions: PropTypes.object,
        error: errorPropType,
        fields: PropTypes.shape({
            username: PropTypes.shape({
                value: PropTypes.string,
                error: errorPropType
            }),
            password: PropTypes.shape({
                value: PropTypes.string,
                error: errorPropType
            }),
            tfa_secret: PropTypes.shape({
                value: PropTypes.string,
                error: errorPropType
            }),
            tfa_token: PropTypes.shape({
                value: PropTypes.string,
                error: errorPropType
            }),
            fp_username: PropTypes.shape({
                value: PropTypes.string,
                error: errorPropType
            })
        })
    },
    getInitialState: function getInitialState() {
        return {
            loginIsLoading: false,
            forgotPasswordIsLoading: false
        };
    },

    getSecretIssuer: function getSecretIssuer() {
        var _props = this.props,
            clientName = _props.clientName,
            _props$rubyBranding = _props.rubyBranding,
            rubyBranding = _props$rubyBranding === undefined ? 'RubyContent' : _props$rubyBranding;

        return rubyBranding + ' - ' + clientName;
    },
    getSecretLabel: function getSecretLabel() {
        return this.props.fields.username.value;
    },
    getNewSecret: function getNewSecret() {
        var issuer = this.getSecretIssuer();
        var label = this.getSecretLabel();

        var _twoFactor$generateSe = _node2fa2.default.generateSecret({
            name: issuer,
            account: label
        }),
            newSecret = _twoFactor$generateSe.secret;

        return newSecret;
    },

    _onBeginTfaSetup: function _onBeginTfaSetup(event) {
        this.props.actions.clearErrorsAndSetFieldValueByKey(this.getNewSecret(), 'tfa_secret');
    },
    _onKeyDown: function _onKeyDown(event) {
        if ((0, _keycode2.default)(event) === 'enter') {
            this.props.delegateSubmit();
        }
    },
    _onKeyDownTfa: function _onKeyDownTfa(event) {
        if ((0, _keycode2.default)(event) === 'enter') {
            this._onTfaLoginTouchTap(event);
        }
    },
    _onKeyDownForgotPassword: function _onKeyDownForgotPassword(event) {
        if ((0, _keycode2.default)(event) === 'enter') {
            this.props.delegateForgotPasswordSubmit();
        }
    },
    _onUsernameChange: function _onUsernameChange(event) {
        this.props.actions.clearErrorsAndSetFieldValueByKey(event.target.value, 'username');
    },
    _onPasswordChange: function _onPasswordChange(event) {
        this.props.actions.clearErrorsAndSetFieldValueByKey(event.target.value, 'password');
    },
    _onTokenChange: function _onTokenChange(event) {
        this.props.actions.clearErrorsAndSetFieldValueByKey(event.target.value, 'tfa_token');
    },
    _onForgotPasswordUsernameChange: function _onForgotPasswordUsernameChange(event) {
        this.props.actions.clearErrorsAndSetFieldValueByKey(event.target.value, 'fp_username');
    },
    _onLoginTouchTap: function _onLoginTouchTap(event, additionalFieldKeys) {
        var _this = this;

        var showLoaderTimeout = setTimeout(function () {
            showLoaderTimeout = null;
            _this.setState({
                loginIsLoading: true
            });
        }, LOADER_DELAY);

        var callback = function callback() {
            //# hide loader
            if (showLoaderTimeout) {
                clearTimeout(showLoaderTimeout);
            } else {
                setTimeout(function () {
                    _this.setState({
                        loginIsLoading: false
                    });
                }, LOADER_DELAY);
            }
        };
        this.props.delegateSubmit({
            successCallback: callback,
            errorCallback: callback,
            additionalFieldKeys: additionalFieldKeys
        });
    },
    _onTfaLoginTouchTap: function _onTfaLoginTouchTap(event) {
        this._onLoginTouchTap(event, ['tfa_token'].concat(_.get(this.props.fields, 'tfa_secret.value') ? 'tfa_secret' : []));
    },
    _onForgotPasswordTouchTap: function _onForgotPasswordTouchTap(event) {
        var _this2 = this;

        var showLoaderTimeout = setTimeout(function () {
            showLoaderTimeout = null;
            _this2.setState({
                forgotPasswordIsLoading: true
            });
        }, LOADER_DELAY);

        var callback = function callback() {
            //# hide loader
            if (showLoaderTimeout) {
                clearTimeout(showLoaderTimeout);
            } else {
                setTimeout(function () {
                    _this2.setState({
                        forgotPasswordIsLoading: false
                    });
                }, LOADER_DELAY);
            }
        };
        this.props.delegateForgotPasswordSubmit({
            successCallback: callback,
            errorCallback: callback
        });
    },
    componentDidMount: function componentDidMount() {
        this.refs.username.focus();
    },
    componentDidUpdate: function componentDidUpdate(prevProps) {
        /*
        if(!prevProps.hasTwoFactorAuth && this.props.hasTwoFactorAuth) {
            // We cannot focus right away or the browser will scroll to the input while it is offscreen
            // then the margin-left will be "transition"ed to, but it will transition to the incorrect location
            // To fix, we execute the focus after the animation is complete
            // Seems like we can set the timeout to anything and it will work,
            // but I left at a time that is longer then the transition animation length, in case
            window.setTimeout(() => this.refs.tfa_token.focus(), 400);
        }
        */
    },
    _activeForm_className: function _activeForm_className() {
        var errorPayload = this.props.errorPayload || {};
        var tfaSetupRequired = errorPayload.code == ERROR_CODES.LOGIN_FAILED_TWO_FACTOR_SETUP_REQUIRED;
        var tfaTokenRequired = errorPayload.code == ERROR_CODES.LOGIN_FAILED_TWO_FACTOR_TOKEN_REQUIRED;
        var tfaTokenInvalid = errorPayload.code == ERROR_CODES.LOGIN_FAILED_TWO_FACTOR_TOKEN_INVALID;
        var tfaSecret = _.get(this.props.fields, 'tfa_secret.value');

        var activeForm_className = tfaSetupRequired && !tfaSecret ? _Login2.default.loginForm_tokenSetupStep : tfaTokenRequired || tfaSecret || tfaTokenInvalid ? _Login2.default.loginForm_tokenStep : this.props.forgotPasswordState == "form" ? _Login2.default.loginForm_forgotPasswordForm : this.props.forgotPasswordState == "success" ? _Login2.default.loginForm_forgotPasswordSuccess : _Login2.default.loginForm_loginStep;
        return activeForm_className;
    },
    renderQRCode: function renderQRCode() {
        var _React$createElement2, _React$createElement3, _React$createElement4;

        var tfaSecret = _.get(this.props.fields, 'tfa_secret.value');

        if (!tfaSecret) {
            var _React$createElement;

            return _react2.default.createElement('div', (_React$createElement = { style: { height: 85 }, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Login'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Login'), _React$createElement));
        }

        var uri = (0, _keyURIConstructor2.default)(this.getSecretIssuer(), this.getSecretLabel(), tfaSecret);

        return _react2.default.createElement(
            'div',
            (_React$createElement4 = { className: _Login2.default.QRCodeRow, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Login'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Login'), _React$createElement4),
            _react2.default.createElement(_qrcode2.default, (_React$createElement2 = {
                value: uri,
                level: 'M',
                size: 128,
                'data-codecept-selector-node': 'QRCode',
                'data-codecept-selector-file': 'Login'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'QRCode'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'QRCode'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Login'), _React$createElement2)),
            _react2.default.createElement(
                'p',
                (_React$createElement3 = {
                    'data-codecept-selector-node': 'p',
                    'data-codecept-selector-file': 'Login'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Login'), _React$createElement3),
                'Registration Key: ',
                tfaSecret
            )
        );
    },
    render: function render() {
        var _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8, _extends2, _extends3, _extends4, _React$createElement9, _React$createElement10, _React$createElement11, _extends5, _React$createElement12, _React$createElement13, _extends6, _extends7, _extends8, _React$createElement14, _React$createElement15, _React$createElement16, _extends9, _React$createElement17, _extends10, _extends11, _extends12, _React$createElement18, _React$createElement19, _extends13, _React$createElement20, _React$createElement21, _React$createElement22, _React$createElement23, _React$createElement24, _React$createElement25, _extends14, _extends15, _extends16, _React$createElement26, _React$createElement27, _React$createElement28, _React$createElement29;

        var usernameObject = this.props.fields.username;
        var passwordObject = this.props.fields.password;
        var tfaTokenObject = this.props.fields.tfa_token;
        var fpUsernameObject = this.props.fields.fp_username;

        var errorMessage = this.props.error && this.props.error.message ? _react2.default.createElement(
            'label',
            (_React$createElement5 = { className: _Login2.default.errorLabel, 'data-codecept-selector-node': 'label',
                'data-codecept-selector-file': 'Login'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Login'), _React$createElement5),
            this.props.error.message
        ) : null;

        var activeForm_className = this._activeForm_className();

        return _react2.default.createElement(
            'div',
            (_React$createElement29 = { className: _Login2.default.loginGridWrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Login'
            }, _defineProperty(_React$createElement29, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement29, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement29, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement29, 'data-codecept-selector-file', 'Login'), _React$createElement29),
            _react2.default.createElement(
                Grid,
                (_React$createElement28 = {
                    'data-codecept-selector-node': 'Grid',
                    'data-codecept-selector-file': 'Login'
                }, _defineProperty(_React$createElement28, 'data-codecept-selector-node', 'Grid'), _defineProperty(_React$createElement28, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement28, 'data-codecept-selector-node', 'Grid'), _defineProperty(_React$createElement28, 'data-codecept-selector-file', 'Login'), _React$createElement28),
                _react2.default.createElement(
                    Row,
                    (_React$createElement27 = { center: 'xs', 'data-codecept-selector-node': 'Row',
                        'data-codecept-selector-file': 'Login'
                    }, _defineProperty(_React$createElement27, 'data-codecept-selector-node', 'Row'), _defineProperty(_React$createElement27, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement27, 'data-codecept-selector-node', 'Row'), _defineProperty(_React$createElement27, 'data-codecept-selector-file', 'Login'), _React$createElement27),
                    _react2.default.createElement(
                        Col,
                        (_React$createElement26 = {
                            xs: 10,
                            sm: 6,
                            md: 4,
                            className: _Login2.default.loginBlock,
                            'data-codecept-selector-node': 'Col',
                            'data-codecept-selector-file': 'Login'
                        }, _defineProperty(_React$createElement26, 'data-codecept-selector-node', 'Col'), _defineProperty(_React$createElement26, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement26, 'data-codecept-selector-node', 'Col'), _defineProperty(_React$createElement26, 'data-codecept-selector-file', 'Login'), _React$createElement26),
                        _react2.default.createElement(
                            'div',
                            (_React$createElement7 = {
                                className: _Login2.default.logo,
                                'data-codecept-selector-node': 'div',
                                'data-codecept-selector-file': 'Login'
                            }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Login'), _React$createElement7),
                            _react2.default.createElement(_rubyLogo2.default, (_React$createElement6 = {
                                frontendSettings: this.props.feSettings,
                                'data-codecept-selector-node': 'RubyLogo',
                                'data-codecept-selector-file': 'Login'
                            }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'RubyLogo'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'RubyLogo'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Login'), _React$createElement6))
                        ),
                        _react2.default.createElement(
                            'h1',
                            (_React$createElement8 = { className: _Login2.default.h1, 'data-codecept-selector-node': 'h1',
                                'data-codecept-selector-file': 'Login'
                            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'h1'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'h1'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Login'), _React$createElement8),
                            this.props.clientName
                        ),
                        _react2.default.createElement(
                            _Card.Card,
                            _extends({}, _Login_style2.default.Card, (_extends15 = {
                                'data-codecept-selector-node': 'Card',
                                'data-codecept-selector-file': 'Login'
                            }, _defineProperty(_extends15, 'data-codecept-selector-node', 'Card'), _defineProperty(_extends15, 'data-codecept-selector-file', 'Login'), _defineProperty(_extends15, 'data-codecept-selector-node', 'Card'), _defineProperty(_extends15, 'data-codecept-selector-file', 'Login'), _extends15)),
                            _react2.default.createElement(
                                _Card.CardText,
                                _extends({}, _Login_style2.default.CardText, (_extends14 = {
                                    'data-codecept-selector-node': 'CardText',
                                    'data-codecept-selector-file': 'Login'
                                }, _defineProperty(_extends14, 'data-codecept-selector-node', 'CardText'), _defineProperty(_extends14, 'data-codecept-selector-file', 'Login'), _defineProperty(_extends14, 'data-codecept-selector-node', 'CardText'), _defineProperty(_extends14, 'data-codecept-selector-file', 'Login'), _extends14)),
                                _react2.default.createElement(
                                    'form',
                                    (_React$createElement25 = { className: activeForm_className,
                                        action: '/ruby/api/v2/users/login', method: 'post',
                                        'data-codecept-selector-node': 'form',
                                        'data-codecept-selector-file': 'Login'
                                    }, _defineProperty(_React$createElement25, 'data-codecept-selector-node', 'form'), _defineProperty(_React$createElement25, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement25, 'data-codecept-selector-node', 'form'), _defineProperty(_React$createElement25, 'data-codecept-selector-file', 'Login'), _React$createElement25),
                                    _react2.default.createElement(
                                        'fieldset',
                                        (_React$createElement10 = { className: _Login2.default.loginFieldset_login, 'data-codecept-selector-node': 'fieldset',
                                            'data-codecept-selector-file': 'Login'
                                        }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'fieldset'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'fieldset'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'Login'), _React$createElement10),
                                        _react2.default.createElement(_TextField2.default, _extends({}, _Login_style2.default.TextField, (_extends2 = {
                                            name: 'username',
                                            ref: 'username',
                                            floatingLabelText: 'Username',
                                            fullWidth: true,
                                            value: usernameObject.value || '',
                                            errorText: usernameObject.error ? usernameObject.error.message : null,
                                            onChange: this._onUsernameChange,
                                            onKeyDown: this._onKeyDown,
                                            'data-codecept-selector-node': 'TextField',
                                            'data-codecept-selector-file': 'Login'
                                        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Login'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Login'), _extends2))),
                                        _react2.default.createElement(_TextField2.default, _extends({}, _Login_style2.default.LastTextField, (_extends3 = {
                                            name: 'password',
                                            floatingLabelText: 'Password',
                                            type: 'password',
                                            fullWidth: true,
                                            value: passwordObject.value || '',
                                            errorText: passwordObject.error ? passwordObject.error.message : null,
                                            onChange: this._onPasswordChange,
                                            onKeyDown: this._onKeyDown,
                                            'data-codecept-selector-node': 'TextField',
                                            'data-codecept-selector-file': 'Login'
                                        }, _defineProperty(_extends3, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Login'), _defineProperty(_extends3, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Login'), _extends3))),
                                        _react2.default.createElement(AsyncRaisedButton, _extends({}, _Login_style2.default.RaisedButton, (_extends4 = {
                                            isLoading: this.state.loginIsLoading,
                                            label: 'Log In',
                                            primary: true,
                                            fullWidth: true,
                                            onTouchTap: this._onLoginTouchTap,
                                            'data-codecept-selector-node': 'AsyncRaisedButton',
                                            'data-codecept-selector-file': 'Login'
                                        }, _defineProperty(_extends4, 'data-codecept-selector-node', 'AsyncRaisedButton'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Login'), _defineProperty(_extends4, 'data-codecept-selector-node', 'AsyncRaisedButton'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Login'), _extends4))),
                                        _react2.default.createElement(
                                            'a',
                                            (_React$createElement9 = { className: _Login2.default.forgotPasswordLink,
                                                onTouchTap: this.props.delegateForgotPasswordClick,
                                                'data-codecept-selector-node': 'a',
                                                'data-codecept-selector-file': 'Login'
                                            }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Login'), _React$createElement9),
                                            'FORGOT PASSWORD'
                                        )
                                    ),
                                    _react2.default.createElement(
                                        'fieldset',
                                        (_React$createElement12 = { className: _Login2.default.loginFieldset_tfaTokenSetup, 'data-codecept-selector-node': 'fieldset',
                                            'data-codecept-selector-file': 'Login'
                                        }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'fieldset'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'fieldset'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'Login'), _React$createElement12),
                                        _react2.default.createElement(_TFAInfo2.default, (_React$createElement11 = {
                                            rubyBranding: this.props.rubyBranding,
                                            mode: 'login',
                                            'data-codecept-selector-node': 'TFAInfo',
                                            'data-codecept-selector-file': 'Login'
                                        }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'TFAInfo'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'TFAInfo'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'Login'), _React$createElement11)),
                                        _react2.default.createElement(AsyncRaisedButton, _extends({}, _Login_style2.default.RaisedButton, (_extends5 = {
                                            label: 'Begin Setup',
                                            primary: true,
                                            fullWidth: true,
                                            onTouchTap: this._onBeginTfaSetup,
                                            'data-codecept-selector-node': 'AsyncRaisedButton',
                                            'data-codecept-selector-file': 'Login'
                                        }, _defineProperty(_extends5, 'data-codecept-selector-node', 'AsyncRaisedButton'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Login'), _defineProperty(_extends5, 'data-codecept-selector-node', 'AsyncRaisedButton'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Login'), _extends5)))
                                    ),
                                    _react2.default.createElement(
                                        'fieldset',
                                        (_React$createElement15 = { className: _Login2.default.loginFieldset_tfaToken, 'data-codecept-selector-node': 'fieldset',
                                            'data-codecept-selector-file': 'Login'
                                        }, _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'fieldset'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'fieldset'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'Login'), _React$createElement15),
                                        _react2.default.createElement(
                                            'div',
                                            (_React$createElement14 = {
                                                'data-codecept-selector-node': 'div',
                                                'data-codecept-selector-file': 'Login'
                                            }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'Login'), _React$createElement14),
                                            _react2.default.createElement(_RaisedButton2.default, _extends({}, _Login_style2.default.BackButton, (_extends6 = {
                                                label: 'Back',
                                                icon: _react2.default.createElement(_arrowBack2.default, (_React$createElement13 = {
                                                    'data-codecept-selector-node': 'NavigationBackIcon',
                                                    'data-codecept-selector-file': 'Login'
                                                }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'NavigationBackIcon'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'NavigationBackIcon'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'Login'), _React$createElement13)),
                                                onTouchTap: this.props.delegateBack,
                                                'data-codecept-selector-node': 'RaisedButton',
                                                'data-codecept-selector-file': 'Login'
                                            }, _defineProperty(_extends6, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends6, 'data-codecept-selector-file', 'Login'), _defineProperty(_extends6, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends6, 'data-codecept-selector-file', 'Login'), _extends6))),
                                            this.renderQRCode(),
                                            _react2.default.createElement(_TextField2.default, _extends({}, _Login_style2.default.LastTextField, (_extends7 = {
                                                name: 'tfa_token',
                                                ref: 'tfa_token',
                                                floatingLabelText: 'MFA Token',
                                                hintText: 'Enter the 6-digit code',
                                                fullWidth: true,
                                                value: tfaTokenObject.value || '',
                                                errorText: tfaTokenObject.error ? tfaTokenObject.error.message : null,
                                                onChange: this._onTokenChange,
                                                onKeyDown: this._onKeyDownTfa,
                                                'data-codecept-selector-node': 'TextField',
                                                'data-codecept-selector-file': 'Login'
                                            }, _defineProperty(_extends7, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends7, 'data-codecept-selector-file', 'Login'), _defineProperty(_extends7, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends7, 'data-codecept-selector-file', 'Login'), _extends7))),
                                            _react2.default.createElement(AsyncRaisedButton, _extends({}, _Login_style2.default.RaisedButton, (_extends8 = {
                                                isLoading: this.state.loginIsLoading,
                                                label: 'Done',
                                                primary: true,
                                                fullWidth: true,
                                                onTouchTap: this._onTfaLoginTouchTap,
                                                'data-codecept-selector-node': 'AsyncRaisedButton',
                                                'data-codecept-selector-file': 'Login'
                                            }, _defineProperty(_extends8, 'data-codecept-selector-node', 'AsyncRaisedButton'), _defineProperty(_extends8, 'data-codecept-selector-file', 'Login'), _defineProperty(_extends8, 'data-codecept-selector-node', 'AsyncRaisedButton'), _defineProperty(_extends8, 'data-codecept-selector-file', 'Login'), _extends8)))
                                        )
                                    ),
                                    _react2.default.createElement(
                                        'fieldset',
                                        (_React$createElement18 = { className: _Login2.default.loginFieldset_forgot, 'data-codecept-selector-node': 'fieldset',
                                            'data-codecept-selector-file': 'Login'
                                        }, _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'fieldset'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'fieldset'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'Login'), _React$createElement18),
                                        _react2.default.createElement(_RaisedButton2.default, _extends({}, _Login_style2.default.BackButton, (_extends9 = {
                                            label: 'Back',
                                            icon: _react2.default.createElement(_arrowBack2.default, (_React$createElement16 = {
                                                'data-codecept-selector-node': 'NavigationBackIcon',
                                                'data-codecept-selector-file': 'Login'
                                            }, _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'NavigationBackIcon'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'NavigationBackIcon'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'Login'), _React$createElement16)),
                                            onTouchTap: this.props.delegateBack,
                                            'data-codecept-selector-node': 'RaisedButton',
                                            'data-codecept-selector-file': 'Login'
                                        }, _defineProperty(_extends9, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends9, 'data-codecept-selector-file', 'Login'), _defineProperty(_extends9, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends9, 'data-codecept-selector-file', 'Login'), _extends9))),
                                        _react2.default.createElement(
                                            'p',
                                            _extends({}, _Login_style2.default.InfoMessage, (_extends10 = {
                                                'data-codecept-selector-node': 'p',
                                                'data-codecept-selector-file': 'Login'
                                            }, _defineProperty(_extends10, 'data-codecept-selector-node', 'p'), _defineProperty(_extends10, 'data-codecept-selector-file', 'Login'), _defineProperty(_extends10, 'data-codecept-selector-node', 'p'), _defineProperty(_extends10, 'data-codecept-selector-file', 'Login'), _extends10)),
                                            _react2.default.createElement(
                                                'strong',
                                                (_React$createElement17 = {
                                                    'data-codecept-selector-node': 'strong',
                                                    'data-codecept-selector-file': 'Login'
                                                }, _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'Login'), _React$createElement17),
                                                'PASSWORD RECOVERY'
                                            )
                                        ),
                                        _react2.default.createElement(_TextField2.default, _extends({}, _Login_style2.default.LastTextField, (_extends11 = {
                                            name: 'fp_username',
                                            floatingLabelText: 'Enter Your Username',
                                            fullWidth: true,
                                            value: fpUsernameObject.value || '',
                                            errorText: fpUsernameObject.error ? fpUsernameObject.error.message : null,
                                            onChange: this._onForgotPasswordUsernameChange,
                                            onKeyDown: this._onKeyDownForgotPassword,
                                            'data-codecept-selector-node': 'TextField',
                                            'data-codecept-selector-file': 'Login'
                                        }, _defineProperty(_extends11, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends11, 'data-codecept-selector-file', 'Login'), _defineProperty(_extends11, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends11, 'data-codecept-selector-file', 'Login'), _extends11))),
                                        _react2.default.createElement(AsyncRaisedButton, _extends({}, _Login_style2.default.RaisedButton, (_extends12 = {
                                            isLoading: this.state.forgotPasswordIsLoading,
                                            label: 'Next',
                                            primary: true,
                                            fullWidth: true,
                                            onTouchTap: this._onForgotPasswordTouchTap,
                                            'data-codecept-selector-node': 'AsyncRaisedButton',
                                            'data-codecept-selector-file': 'Login'
                                        }, _defineProperty(_extends12, 'data-codecept-selector-node', 'AsyncRaisedButton'), _defineProperty(_extends12, 'data-codecept-selector-file', 'Login'), _defineProperty(_extends12, 'data-codecept-selector-node', 'AsyncRaisedButton'), _defineProperty(_extends12, 'data-codecept-selector-file', 'Login'), _extends12)))
                                    ),
                                    _react2.default.createElement(
                                        'fieldset',
                                        (_React$createElement24 = { className: _Login2.default.loginFieldset_forgotSuccess, 'data-codecept-selector-node': 'fieldset',
                                            'data-codecept-selector-file': 'Login'
                                        }, _defineProperty(_React$createElement24, 'data-codecept-selector-node', 'fieldset'), _defineProperty(_React$createElement24, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement24, 'data-codecept-selector-node', 'fieldset'), _defineProperty(_React$createElement24, 'data-codecept-selector-file', 'Login'), _React$createElement24),
                                        _react2.default.createElement(_RaisedButton2.default, _extends({}, _Login_style2.default.BackButton, (_extends13 = {
                                            label: 'Back',
                                            icon: _react2.default.createElement(_arrowBack2.default, (_React$createElement19 = {
                                                'data-codecept-selector-node': 'NavigationBackIcon',
                                                'data-codecept-selector-file': 'Login'
                                            }, _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'NavigationBackIcon'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'NavigationBackIcon'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'Login'), _React$createElement19)),
                                            onTouchTap: this.props.delegateBack,
                                            'data-codecept-selector-node': 'RaisedButton',
                                            'data-codecept-selector-file': 'Login'
                                        }, _defineProperty(_extends13, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends13, 'data-codecept-selector-file', 'Login'), _defineProperty(_extends13, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends13, 'data-codecept-selector-file', 'Login'), _extends13))),
                                        _react2.default.createElement(
                                            'p',
                                            (_React$createElement21 = { className: _Login2.default.forgotPasswordSuccessHeader, 'data-codecept-selector-node': 'p',
                                                'data-codecept-selector-file': 'Login'
                                            }, _defineProperty(_React$createElement21, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement21, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement21, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement21, 'data-codecept-selector-file', 'Login'), _React$createElement21),
                                            _react2.default.createElement(
                                                'strong',
                                                (_React$createElement20 = {
                                                    'data-codecept-selector-node': 'strong',
                                                    'data-codecept-selector-file': 'Login'
                                                }, _defineProperty(_React$createElement20, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement20, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement20, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement20, 'data-codecept-selector-file', 'Login'), _React$createElement20),
                                                'PASSWORD RECOVERY'
                                            )
                                        ),
                                        _react2.default.createElement(
                                            'p',
                                            (_React$createElement22 = { className: _Login2.default.forgotPasswordSuccessParagraph, 'data-codecept-selector-node': 'p',
                                                'data-codecept-selector-file': 'Login'
                                            }, _defineProperty(_React$createElement22, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement22, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement22, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement22, 'data-codecept-selector-file', 'Login'), _React$createElement22),
                                            'If you provided a valid ',
                                            this.props.rubyBranding,
                                            ' username, then you should receive an email within a few minutes that will direct you to reset your password.'
                                        ),
                                        _react2.default.createElement(
                                            'p',
                                            (_React$createElement23 = { className: _Login2.default.forgotPasswordSuccessParagraph, 'data-codecept-selector-node': 'p',
                                                'data-codecept-selector-file': 'Login'
                                            }, _defineProperty(_React$createElement23, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement23, 'data-codecept-selector-file', 'Login'), _defineProperty(_React$createElement23, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement23, 'data-codecept-selector-file', 'Login'), _React$createElement23),
                                            'If you did not receive a password recovery email in a timely manner, please contact our Client Experience\xA0Team.'
                                        )
                                    )
                                ),
                                errorMessage,
                                this.props.children
                            )
                        ),
                        _react2.default.createElement(_CopyrightFooter2.default, _extends({}, this.props, (_extends16 = {
                            'data-codecept-selector-node': 'CopyrightFooter',
                            'data-codecept-selector-file': 'Login'
                        }, _defineProperty(_extends16, 'data-codecept-selector-node', 'CopyrightFooter'), _defineProperty(_extends16, 'data-codecept-selector-file', 'Login'), _defineProperty(_extends16, 'data-codecept-selector-node', 'CopyrightFooter'), _defineProperty(_extends16, 'data-codecept-selector-file', 'Login'), _extends16)))
                    )
                )
            )
        );
    } //# TODO: remove children
});
exports.default = Edit;