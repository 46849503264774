'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _reduxBatchedActions = require('redux-batched-actions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var uuid = require('node-uuid');

function newID() {
    return uuid.v1();
}

module.exports = function () {
    var id = this.getID();
    var TYPES = {
        APPEND_ITEM_OF_TYPE: '@ruby-app/' + id + '/APPEND_ITEM_OF_TYPE',
        APPEND_ITEM_OF_TYPE_IGNORED: '@ruby-app/' + id + '/APPEND_ITEM_OF_TYPE_IGNORED',
        DELETE_ITEM_WITH_ID: '@ruby-app/' + id + '/DELETE_ITEM_WITH_ID',
        MOVE_ITEM: '@ruby-app/' + id + '/MOVE_ITEM',
        UPDATE_ITEM_ORDER: '@ruby-app/' + id + '/UPDATE_ITEM_ORDER',
        REPLACE_STATE: '@ruby-app/' + id + '/REPLACE_STATE'

        //# Options handling
        , ADD_OPTIONS: '@ruby-app/' + id + '/ADD_OPTIONS'

        //# Overridden action types
        , RESET_STORE: '@ruby-app/' + id + '/RESET_STORE'

        //, SET_FIELD_ERROR_MESSAGE_BY_KEY: `@ruby-app/${id}/SET_FIELD_ERROR_MESSAGE_BY_KEY`
    };
    return {
        TYPES: TYPES,
        generators: {
            appendItemOfType: function appendItemOfType(type) {
                var _this = this;

                var actions = this.getAction().generators;

                var _getState = this.getState(),
                    childrenOrderById = _getState.childrenOrderById,
                    error = _getState.error;

                var hasParentScopedError = !_lodash2.default.isNil(_lodash2.default.get(error, 'message'));

                var batchActionsArray = [];
                if (this.props.limit && childrenOrderById.length >= this.props.limit) {
                    return {
                        type: TYPES.APPEND_ITEM_OF_TYPE_IGNORED
                    };
                } else {
                    return function (dispatch) {
                        batchActionsArray.push(actions._appendItemOfType__pure(type));
                        dispatch((0, _reduxBatchedActions.batchActions)(batchActionsArray));

                        _this.updateChildren(true, dispatch);

                        dispatch(actions.updateUserModifiedTimestamp());
                        /* 
                        //# 20171130 - enable this if we want to automatically pop open
                        //# the module dialog
                        const newlyAppendedChild = _.last(this.getChildren());
                        const newlyAppendedChild_actions = newlyAppendedChild.getAction().generators;
                        if (newlyAppendedChild_actions.onNewlyAdded) {
                            dispatch(newlyAddedChild_actions.onNewlyAdded());
                        }
                        */

                        var selfErrorMessage = _this.revalidateSelfValueAndReflectError();
                        _this.refreshParentErrors && _this.refreshParentErrors();
                    };
                }
            },
            _appendItemOfType__pure: function _appendItemOfType__pure(type) {
                var actions = this.getAction().generators;

                var _getState2 = this.getState(),
                    childrenOrderById = _getState2.childrenOrderById;

                if (this.props.limit && childrenOrderById.length >= this.props.limit) {
                    return {
                        type: TYPES.APPEND_ITEM_OF_TYPE_IGNORED
                    };
                } else {
                    var newChildId = newID();
                    return {
                        type: TYPES.APPEND_ITEM_OF_TYPE,
                        payload: {
                            type: type,
                            id: newChildId
                        }
                    };
                }
            }

            //# NOTE: currently not being used. Can we deprecate?
            , appendItemOfType_andId: function appendItemOfType_andId(type, newId) {
                var _this2 = this;

                var actions = this.getAction().generators;

                var _getState3 = this.getState(),
                    childrenOrderById = _getState3.childrenOrderById,
                    error = _getState3.error;

                var hasParentScopedError = !_lodash2.default.isNil(_lodash2.default.get(error, 'message'));
                var batchActionsArray = [];

                return function (dispatch) {
                    batchActionsArray.push(actions._appendItemOfType_andId__pure(type, newId));
                    dispatch((0, _reduxBatchedActions.batchActions)(batchActionsArray));
                    _this2.updateChildren(true, dispatch);

                    dispatch(actions.updateUserModifiedTimestamp());

                    var selfErrorMessage = _this2.revalidateSelfValueAndReflectError();
                    _this2.refreshParentErrors && _this2.refreshParentErrors();
                };
            },
            _appendItemOfType_andId__pure: function _appendItemOfType_andId__pure(type, newId) {
                var actions = this.getAction().generators;

                var _getState4 = this.getState(),
                    childrenOrderById = _getState4.childrenOrderById;

                if (this.props.limit && childrenOrderById.length >= this.props.limit) {
                    return {
                        type: TYPES.APPEND_ITEM_OF_TYPE_IGNORED
                    };
                } else {
                    var newChildId = newId;
                    return {
                        type: TYPES.APPEND_ITEM_OF_TYPE,
                        payload: {
                            type: type,
                            id: newChildId
                        }
                    };
                }
            },
            deleteItemWithId: function deleteItemWithId(id) {
                var _this3 = this;

                var actions = this.getAction().generators;

                return function (dispatch) {
                    dispatch(actions._deleteItemWithId__pure(id));

                    _this3.updateChildren();

                    dispatch(actions.updateUserModifiedTimestamp());

                    var selfError = _this3.revalidateSelfValueAndReflectError();
                    _this3.refreshParentErrors && _this3.refreshParentErrors();
                };
            },
            _deleteItemWithId__pure: function _deleteItemWithId__pure(id) {
                var actions = this.getAction().generators;

                return {
                    type: TYPES.DELETE_ITEM_WITH_ID,
                    payload: {
                        id: id
                    }
                };
            },
            updateItemsWithListOfModules: function updateItemsWithListOfModules(moduleList) {
                var _this4 = this;

                //# some of the items might be selected, others won't be
                //# need to get a list of modules already created and see if we need to amend it
                var selfState = this.getState();
                var selfAction = this.getAction();
                var childrenOrderById = selfState.childrenOrderById,
                    childrenIdToTypeMap = selfState.childrenIdToTypeMap;

                var actions = this.getAction().generators;

                var existingModuleIdsByType = childrenOrderById.reduce(function (collector, childId) {
                    var typeForChild = childrenIdToTypeMap[childId];
                    if (collector.typeForChild) {
                        collector[typeForChild].push(childId);
                    } else {
                        collector[typeForChild] = [childId];
                    }

                    return collector;
                }, {});

                var moduleListOrderWithId = moduleList.map(function (module) {
                    var foundId = _idForModuleType(module.type) || newID();
                    return _extends({
                        id: foundId
                    }, module);
                });
                var moduleListByType = moduleListOrderWithId.reduce(function (collector, module) {
                    collector[module.type] = module;
                    return collector;
                }, {});

                var modulesToAddByType = _lodash2.default.omit(moduleListByType, Object.keys(existingModuleIdsByType));

                var idsToDeleteByType = _lodash2.default.omit(existingModuleIdsByType, Object.keys(moduleListByType));

                var batchActionsArray = [selfAction.generators.addOptions(moduleList)];

                _lodash2.default.each(modulesToAddByType, function (moduleToAdd, moduleType) {
                    var newChildId = moduleToAdd.id;
                    var typeToAdd = moduleToAdd.type;
                    batchActionsArray.push(selfAction.generators._appendItemOfType_andId__pure(typeToAdd, newChildId));
                });

                _lodash2.default.forEach(idsToDeleteByType, function (idsToDeleteArr) {
                    idsToDeleteArr.forEach(function (idToDelete) {
                        batchActionsArray.push(selfAction.generators._deleteItemWithId__pure(idToDelete));
                    });
                });

                //# update item order
                var newChildrenOrderById = moduleListOrderWithId.map(function (module) {
                    return module.id;
                });
                batchActionsArray.push(selfAction.generators.updateItemOrder(newChildrenOrderById));

                return function (dispatch) {
                    dispatch((0, _reduxBatchedActions.batchActions)(batchActionsArray));
                    _this4.updateChildren();
                    dispatch(actions.updateUserModifiedTimestamp());
                    dispatch(selfAction.generators.requestRerender());

                    var hasAdditionsOrDeletions = Object.keys(modulesToAddByType).length + Object.keys(idsToDeleteByType).length;
                    if (hasAdditionsOrDeletions) {
                        var selfErrorMessage = _this4.revalidateSelfValueAndReflectError();
                        _this4.refreshParentErrors && _this4.refreshParentErrors();
                    }
                };

                function _idForModuleType(needleModuleType) {
                    return _lodash2.default.reduce(childrenIdToTypeMap, function (foundId, haystackModuleType, moduleId) {
                        if (foundId) {
                            return foundId;
                        }

                        if (haystackModuleType == needleModuleType) {
                            return moduleId;
                        }

                        return foundId;
                    }, undefined);
                }
            }
            //# DEPRECATED: we can just update the order wholesale
            , moveItem_withId_toBetweenIDs: function moveItem_withId_toBetweenIDs(id, betweenIDs) {
                return {
                    type: TYPES.MOVE_ITEM,
                    payload: {
                        id: id,
                        betweenIDs: betweenIDs
                    }
                };
            },
            updateItemOrder: function updateItemOrder(itemOrder) {
                var actions = this.getAction().generators;
                return (0, _reduxBatchedActions.batchActions)([actions._updateItemOrder__pure(itemOrder), actions.updateUserModifiedTimestamp()]);
            },
            _updateItemOrder__pure: function _updateItemOrder__pure(itemOrder) {
                return {
                    type: TYPES.UPDATE_ITEM_ORDER,
                    payload: {
                        itemOrder: itemOrder
                    }
                };
            },
            replaceState: function replaceState(withState) {
                var actions = this.getAction().generators;
                return {
                    type: TYPES.REPLACE_STATE,
                    payload: withState
                };
            },

            addOptions: function addOptions(options) {
                return {
                    type: TYPES.ADD_OPTIONS,
                    payload: {
                        options: options
                    }
                };
            }
            //# Overridden field actions
            , resetStore: function resetStore() {
                return {
                    type: TYPES.RESET_STORE
                };
            }
            /* 20170517 - DEPRECATED - howard
            , setFieldErrorMessageByKey: function(message, key) {
                return {
                    type: TYPES.SET_FIELD_ERROR_MESSAGE_BY_KEY
                    , payload: {
                        error: {
                            message
                        }
                        , key
                    }
                };
            }
            , setFieldErrorObjectByKey: function(error, key) {
                return {
                    type: TYPES.SET_FIELD_ERROR_MESSAGE_BY_KEY
                    , payload: {
                        error
                        , key
                    }
                };
            }
            */
        }
    };
};