'use strict';

var CONSTANTS = require('./constants');

var _require = require('./password-meter'),
    PasswordMeter = _require.PasswordMeter;

//# a different kind of module to check for password strength
//https://github.com/dropbox/zxcvbn


function DEPRECATED_analyze(passwordValue) {
    var valueToAnalyze = passwordValue || '';
    var hasLowercase = /[a-z]/;
    var hasUppercase = /[A-Z]/;
    var hasNumber = /\d/;
    var hasNonAlphanumeric = /[^a-zA-Z0-9]/;
    var shortFlag = valueToAnalyze.length < CONSTANTS.MIN_PASSWORD_LENGTH,
        longFlag = valueToAnalyze.length > CONSTANTS.MAX_PASSWORD_LENGTH,
        lowercaseFlag = hasLowercase.test(valueToAnalyze),
        uppercaseFlag = hasUppercase.test(valueToAnalyze),
        numericFlag = hasNumber.test(valueToAnalyze),
        nonAlphnumericFlag = hasNonAlphanumeric.test(valueToAnalyze);
    var score = [lowercaseFlag, uppercaseFlag, numericFlag, nonAlphnumericFlag].reduce(function (sum, flag) {
        return flag ? sum + 1 : sum;
    }, 0);
    var weakFlag = score < CONSTANTS.MIN_CUSTOM_SCORE;
    return { shortFlag: shortFlag, longFlag: longFlag, weakFlag: weakFlag, score: score,
        lowercaseFlag: lowercaseFlag, uppercaseFlag: uppercaseFlag, numericFlag: numericFlag, nonAlphnumericFlag: nonAlphnumericFlag };
}

/*
• [x] Minimum length of 8 characters
• [x] Allow case-sensitive characters, number, or special characters
• [x] Disallow any part of the username
• [x] Disallow any form of the word "password"
• [x] Disallow any character more than 3 time in succession
*/
//# returns one of
/*
[{
    "score" : 118,
    "status" : "medium",
    "percent" : 59
}, {
    "score" : -1,
    "status" : "needs requirement(s)",
    "errors" : ["The minimum password length is 5.", "The password must start with 1.", "The password must end with $.", "You must use at least 1 lowercase letter(s).", "You must use at least 1 number(s).", "You must use at least 1 symbol(s).", "The Password must include all the items specified.", "The Password must include at least one item specified [$]."],
    "percent" : 0
}, {
    "score" : -1,
    "status" : "needs requirement(s)",
    "errors" : ["The Password must include all the items specified."],
    "percent" : 0
}
] 
*/

function analyze(passwordValue) {
    var overrideOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    //# we want to exclude username so we at least expect overrideOptions.exclude
    var _overrideOptions$excl = overrideOptions.exclude,
        exclude = _overrideOptions$excl === undefined ? [] : _overrideOptions$excl;


    var analysisResults = new PasswordMeter({
        minLength: CONSTANTS.MIN_PASSWORD_LENGTH,
        maxLength: CONSTANTS.MAX_PASSWORD_LENGTH
        //uppercaseLettersMinLength: 1,
        //lowercaseLettersMinLength: 2,
        //numbersMinLength: 1,
        //symbolsMinLength: 1,
        , characterTypeMinLength: 3 //# out of uppercaseLetters, numbers, symbols
        , allowedConsecutiveRepeatedLetters: 3
        //include: ['a', '$'],
        //exclude: ['1baA$', '0xaZ$'],
        //startsWith: '1',
        //endsWith: '$',
        //includeOne: ['$']
        //blackList
        //uniqueLettersMinLength
        , exclude: {
            value: ['password'].concat(exclude),
            message: "Password cannot contain variants of 'password' or your username"
        }
    }, {
        "40": "very weak", // 001 <= x <  040
        "80": "weak", // 040 <= x <  080
        "120": "medium", // 080 <= x <  120
        "180": "strong", // 120 <= x <  180`
        "200": "very strong", // 180 <= x <  200
        "_": "excellent" //        x >= 200
    }).getResults([passwordValue], true);

    return analysisResults[0];
}

module.exports = {
    analyze: analyze,
    DEPRECATED_analyze: DEPRECATED_analyze
};