"use strict";

var typesFromID = function typesFromID(id) {
    return {
        PIN_MENU: "@@ruby-app/" + id + "/PIN_MENU",
        UNPIN_MENU: "@@ruby-app/" + id + "/UNPIN_MENU",
        REQUEST_MENU_OPEN_CHANGE: "@@ruby-app/" + id + "/REQUEST_MENU_OPEN_CHANGE"
    };
};

var generators = {
    pinMenu: function pinMenu() {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        return {
            type: TYPES.PIN_MENU
        };
    },
    unpinMenu: function unpinMenu() {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES;

        return {
            type: TYPES.UNPIN_MENU
        };
    },
    toggleMenuPin: function toggleMenuPin() {
        var _getAction3 = this.getAction(),
            actions = _getAction3.generators;

        var selfState = this.getState();
        if (selfState.menuIsPinned) {
            return actions.unpinMenu();
        } else {
            return actions.pinMenu();
        }
    },
    requestMenuOpenChange: function requestMenuOpenChange(requestedOpenStatus) {
        var _getAction4 = this.getAction(),
            TYPES = _getAction4.TYPES,
            actions = _getAction4.generators;

        return {
            type: TYPES.REQUEST_MENU_OPEN_CHANGE,
            payload: requestedOpenStatus
        };
    }
};

module.exports = function () {
    var TYPES = typesFromID(this.getID());
    return {
        TYPES: TYPES,
        generators: generators
    };
};