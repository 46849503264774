'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp2;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Paper = require('material-ui/Paper');

var _Paper2 = _interopRequireDefault(_Paper);

var _CardExpandable = require('./CardExpandable');

var _CardExpandable2 = _interopRequireDefault(_CardExpandable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Card = (_temp2 = _class = function (_Component) {
  _inherits(Card, _Component);

  function Card() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Card);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Card.__proto__ || Object.getPrototypeOf(Card)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      expanded: null,
      renderedOnce: false
    }, _this.handleExpandingPress = function (event) {
      _this.props.onExpandingPress && _this.props.onExpandingPress(event);
    }, _this.handleExpanding = function (event) {
      event.preventDefault();
      var newExpandedState = !_this.state.expanded;
      // no automatic state update when the component is controlled
      if (_this.props.expanded === null) {
        _this.setState({ expanded: newExpandedState });
      }
      if (_this.props.onExpandChange) {
        _this.props.onExpandChange(newExpandedState);
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Card, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var newState = {
        expanded: this.props.expanded === null ? this.props.initiallyExpanded === true : this.props.expanded
      };
      if (newState.expanded) {
        newState.renderedOnce = true;
      }
      this.setState(newState);
    }
  }, {
    key: 'renderChildrenUsingProps',
    value: function renderChildrenUsingProps(props) {
      var _this2 = this;

      var style = props.style,
          containerStyle = props.containerStyle,
          children = props.children,
          expandable = props.expandable,
          expandedProp = props.expanded,
          initiallyExpanded = props.initiallyExpanded,
          onExpandChange = props.onExpandChange,
          onExpandingPress = props.onExpandingPress,
          other = _objectWithoutProperties(props, ['style', 'containerStyle', 'children', 'expandable', 'expanded', 'initiallyExpanded', 'onExpandChange', 'onExpandingPress']);

      var lastElement = void 0;
      var expanded = this.state.expanded;
      var newChildren = _react2.default.Children.map(children, function (currentChild) {
        var _extends2;

        var doClone = false;
        var newChild = undefined;
        var newProps = {};
        var wrapperProps = {};
        var element = currentChild;
        if (!currentChild || !currentChild.props) {
          return null;
        }
        if (expanded === false && currentChild.props.expandable === true) {
          if (_this2.state.renderedOnce) {
            doClone = true;
            wrapperProps.style = {
              visibility: 'hidden',
              height: 0,
              overflow: 'hidden'
            };
          } else {
            return;
          }
        }
        if (currentChild.props.actAsExpander === true) {
          doClone = true;
          newProps.onTouchTap = _this2.handleExpanding;
          newProps.style = _extends({ cursor: 'pointer' }, currentChild.props.style);
        }
        if (currentChild.props.showExpandableButton === true) {
          var _React$createElement;

          doClone = true;
          newChild = _react2.default.createElement(_CardExpandable2.default, (_React$createElement = { expanded: expanded,
            onExpanding: _this2.handleExpanding,
            onPress: _this2.handleExpandingPress,
            style: { position: 'static' },
            'data-codecept-selector-node': 'CardExpandable',
            'data-codecept-selector-file': 'Card'
          }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'CardExpandable'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Card'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'CardExpandable'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Card'), _React$createElement));
        }
        if (doClone) {
          newProps.expandButton = newChild;
          element = _react2.default.cloneElement(currentChild, newProps, currentChild.props.children);
        }

        //# we no longer display:none the cloned element
        //# instead, we set the height to be zero and 
        //# set visibility to 'hidden'
        //# we need to do this to allow nested components to still be able to get 
        //# its own box size info
        //# We also wrap the element because the element might (probably) use padding
        //# so setting height doesn't work
        var wrappedElement = _react2.default.createElement(
          'div',
          _extends({}, wrapperProps, (_extends2 = { key: element.key, 'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'Card'
          }, _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Card'), _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Card'), _extends2)),
          element
        );
        lastElement = wrappedElement;
        return wrappedElement;
      }, this);

      return newChildren;
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      // update the state when the component is controlled.
      var newState = {};
      /*
      if (!this.state.children) {
          //newState.children = this.renderChildrenUsingProps(nextProps);
      }
      */
      if (!this.state.renderedOnce && nextProps.expanded) {
        newState.renderedOnce = true;
      }
      if (nextProps.expanded !== null) {
        newState.expanded = nextProps.expanded;
      }
      this.setState(newState);
    }
  }, {
    key: 'render',
    value: function render() {
      var _React$createElement2, _extends3;

      var _props = this.props,
          style = _props.style,
          containerStyle = _props.containerStyle,
          children = _props.children,
          expandable = _props.expandable,
          expandedProp = _props.expanded,
          initiallyExpanded = _props.initiallyExpanded,
          onExpandChange = _props.onExpandChange,
          onExpandingPress = _props.onExpandingPress,
          other = _objectWithoutProperties(_props, ['style', 'containerStyle', 'children', 'expandable', 'expanded', 'initiallyExpanded', 'onExpandChange', 'onExpandingPress']);

      var lastElement = void 0;
      var expanded = this.state.expanded;
      var newChildren = this.state.children || this.renderChildrenUsingProps(this.props);

      // If the last element is text or a title we should add
      // 8px padding to the bottom of the card
      var addBottomPadding = lastElement && (lastElement.type.muiName === 'CardText' || lastElement.type.muiName === 'CardTitle');

      var mergedStyles = _extends({
        zIndex: 1
      }, style);
      var containerMergedStyles = _extends({
        paddingBottom: addBottomPadding ? 8 : 0
      }, containerStyle);

      return _react2.default.createElement(
        _Paper2.default,
        _extends({}, other, (_extends3 = { style: mergedStyles, 'data-codecept-selector-node': 'Paper',
          'data-codecept-selector-file': 'Card'
        }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Paper'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Card'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Paper'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Card'), _extends3)),
        _react2.default.createElement(
          'div',
          (_React$createElement2 = { style: containerMergedStyles, 'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'Card'
          }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Card'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Card'), _React$createElement2),
          newChildren
        )
      );
    }
  }]);

  return Card;
}(_react.Component), _class.propTypes = {
  /**
   * Can be used to render elements inside the Card.
   */
  children: _react.PropTypes.node,
  /**
   * Override the inline-styles of the container element.
   */
  containerStyle: _react.PropTypes.object,
  /**
   * If true, this card component is expandable. Can be set on any child of the `Card` component.
   */
  expandable: _react.PropTypes.bool,
  /**
   * Whether this card is expanded.
   * If `true` or `false` the component is controlled.
   * if `null` the component is uncontrolled.
   */
  expanded: _react.PropTypes.bool,
  /**
   * Whether this card is initially expanded.
   */
  initiallyExpanded: _react.PropTypes.bool,
  /**
   * Event handle for long press (using Hammer.js)
   */
  onExpandingPress: _react.PropTypes.func,
  /**
   * Callback function fired when the `expandable` state of the card has changed.
   *
   * @param {boolean} newExpandedState Represents the new `expanded` state of the card.
   */
  onExpandChange: _react.PropTypes.func,
  /**
   * If true, this card component will include a button to expand the card. `CardTitle`,
   * `CardHeader` and `CardActions` implement `showExpandableButton`. Any child component
   * of `Card` can implements `showExpandableButton` or forwards the property to a child
   * component supporting it.
   */
  showExpandableButton: _react.PropTypes.bool,
  /**
   * Override the inline-styles of the root element.
   */
  style: _react.PropTypes.object
}, _class.defaultProps = {
  expandable: false,
  expanded: null,
  initiallyExpanded: false
}, _temp2);
exports.default = Card;