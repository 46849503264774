'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

/* jshint -W138 */
var rubyMiddlewareFields = require('@rubyapps/ruby-middleware-fields');

var constants = require('../common/constants');

//# NOTE: should not need to have a default state

/**
 *
 *  defaultState = {
 *      errorContext: the context of the error object that the comment dialog should respond to
 *      endpoint: the endpoint to submit the form to
 *      propagateErrorActionType: the action type to dispatch if the error context doesn't match
 *  }
 *
 **/
var defaultState = {
    open: false,
    message: '',
    body: [],
    modal: false,
    type: null
    //, type: constants.NOTIFICATION_TYPES.SUCCESS // don't default here or will
    //a flash of success styles when the notifications is leaving
    , customAction: null
};

module.exports = function (state, action) {
    var actionTypes = this.getAction().TYPES;
    var stateFromRC = this.props.reduxState || {};

    if (state === undefined) {
        state = _extends({}, defaultState, stateFromRC);
    }

    switch (action.type) {
        case actionTypes.OPEN_NOTIFICATION:
            return _extends({}, state, action.payload, {
                open: true
            });
        case actionTypes.CLOSE_NOTIFICATION:
            return _extends({}, state, {
                open: false
            });

        case actionTypes.SET_LOADING:
            return _extends({}, state, {
                loading: true
            });
        case actionTypes.CLEAR_LOADING:
            return _extends({}, state, {
                loading: false
            });

        case actionTypes.RESET_NOTIFICATION:
            return _extends({}, state, defaultState, stateFromRC);

        default:
            return state;
    }
};