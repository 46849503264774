'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _ObjectConnector = require('./reactComponents/ObjectConnector');

var _ObjectConnector2 = _interopRequireDefault(_ObjectConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldSetMixin = require('@rubyapps/ruby-component-mixin-field-set');

var mixinUtils = require('@rubyapps/ruby-component-mixin-utils');
var RCObject = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldSetMixin]
    //, propTypes: {}
    , componentName: componentName
    //, reducer: require('./reducer')
    //, action: require('./action')
    , dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();

        return {
            selfSelector: selfSelector
        };
    },
    getReactClass: function getReactClass() {
        return _ObjectConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var ObjectComponent = this.getReactClass();

        return _react2.default.createElement(ObjectComponent, _extends({}, this.props.reactProps, (_extends2 = {
            'data-codecept-selector-node': 'ObjectComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'ObjectComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'ObjectComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },

    _formValueFromLocalState: function _formValueFromLocalState(selfState, isError) {
        var predicateFormatter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (value) {
            return value;
        };
        var entireState = arguments[3];
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        var last_mixin = mixinUtils.superMixinContaining(this, '_formValueFromLocalState', this._formValueFromLocalState);

        var baseFormValue = last_mixin._formValueFromLocalState.apply(this, arguments);

        var selfKey = this.props.key;

        //# NOTE: this breaks error checking
        //# we should only return an empty object IFF it's nor for error computation
        //# since we only want to enforce data_type for form data
        if (!isError && selfKey && _.size(baseFormValue) == 0) {
            //# if object has its own key, we must enforce the existence of this field
            //# eg {[selfKey]: {}} 
            //# this is because the object might be part of a deferred-rendered tree for new
            //# repeater modules
            //# eg. {"type":"moduleA"}. previously, this was submitted to the server
            //# which passes validation because it is missing the inner object
            //# if there happens to be a required field (eg. moduleA.name), that won't get checked
            //# By returning {"type":"moduleA", [selfKey]: {}}
            //# our jsonschema validator will traverse the empty object
            //# you might wonder whynot update the jsonschema to declare `selfKey` as a required property? 
            //# We do in enforce the existence of those objects (if necessary, so that means it's the repeater module template expansion that injected the required prop)
            //# however, the error returned doesn't map correctly, because we need to also validate the nested fields (eg. {[selfKey]: {name:...}})
            //# if you look at the base-field mixin
            //# if children are not available and self has no value
            //# we return an empty object
            //# so it's ok to return a nested empty object
            //# if the <Object> component has a key
            return _defineProperty({}, selfKey, {});
        }

        return baseFormValue;
    }
});

module.exports = RCObject;