'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _VirtualSelectField = require('@rubyapps/ruby-material-ui/VirtualSelectField');

var _VirtualSelectField2 = _interopRequireDefault(_VirtualSelectField);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _friendlyRubyMoment = require('@rubyapps/friendly-ruby-moment');

var _friendlyRubyMoment2 = _interopRequireDefault(_friendlyRubyMoment);

var _addInfoModeProps = require('@rubyapps/ruby-react-components/src/client/hoc/addInfoModeProps');

var _addInfoModeProps2 = _interopRequireDefault(_addInfoModeProps);

var _ComponentizedDatePicker = require('./ComponentizedDatePicker.cssModule');

var _ComponentizedDatePicker2 = _interopRequireDefault(_ComponentizedDatePicker);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

var PropTypes = _react2.default.PropTypes;

var ComponentizedYear = require('./ComponentizedYear');

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label;

var FieldComponentizedDatePicker = _react2.default.createClass({
    displayName: 'FieldComponentizedDatePicker',

    propTypes: {
        label: PropTypes.string,
        fieldKey: PropTypes.string,
        value: PropTypes.object,
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        id: PropTypes.string,
        actions: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: {}
        };
    },
    render: function render() {
        var _React$createElement,
            _React$createElement2,
            _extends2,
            _this = this,
            _React$createElement3,
            _React$createElement4,
            _React$createElement5,
            _extends3,
            _React$createElement6,
            _extends4,
            _React$createElement7,
            _React$createElement8,
            _extends5,
            _React$createElement9,
            _extends6,
            _React$createElement10,
            _React$createElement11,
            _React$createElement12;

        var _ref = this.props.value || {},
            year = _ref.year,
            month = _ref.month,
            day = _ref.day;

        var labelProps = _.pick(this.props, Object.keys(Label.propTypes));

        var selectProps = {
            id: this.props.id,
            displayEmpty: true,
            labelStyle: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                paddingRight: 22
            }
        };

        var isDisabled = this._isDisabled();
        var isInfoMode = this.props.isInfoMode;

        var ComponentizedDatePickerComponent = _react2.default.createElement(
            'div',
            (_React$createElement12 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'ComponentizedDatePicker'
            }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _React$createElement12),
            _react2.default.createElement(
                'label',
                (_React$createElement = { className: _ComponentizedDatePicker2.default.label, 'data-codecept-selector-node': 'label',
                    'data-codecept-selector-file': 'ComponentizedDatePicker'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _React$createElement),
                labelProps.label
            ),
            _react2.default.createElement(
                'div',
                (_React$createElement11 = {
                    style: {
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                        position: 'relative'
                    },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'ComponentizedDatePicker'
                }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _React$createElement11),
                _react2.default.createElement(
                    'div',
                    (_React$createElement4 = { className: _ComponentizedDatePicker2.default.fieldWrapper, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'ComponentizedDatePicker'
                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _React$createElement4),
                    isInfoMode ? _react2.default.createElement(_TextField2.default, _extends({
                        floatingLabelText: _react2.default.createElement(Label, (_React$createElement2 = { label: 'Year', 'data-codecept-selector-node': 'Label',
                            'data-codecept-selector-file': 'ComponentizedDatePicker'
                        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _React$createElement2)),
                        disabled: isInfoMode,
                        value: year
                    }, _.get(this, 'props.infoModeProps.TextField'), (_extends2 = {
                        'data-codecept-selector-node': 'TextField',
                        'data-codecept-selector-file': 'ComponentizedDatePicker'
                    }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _extends2))) : _react2.default.createElement(ComponentizedYear, (_React$createElement3 = {
                        onChange: function onChange(event, year) {
                            var updatedValue = _.assign({}, _this.props.value, {
                                year: year
                            });

                            _this.props.actions.setFieldValueByKey(updatedValue, _this.props.fieldKey);
                        },
                        id: this.props.id,
                        value: year,
                        errorText: _.get(this.props, 'error.year'),
                        'data-codecept-selector-node': 'ComponentizedYear',
                        'data-codecept-selector-file': 'ComponentizedDatePicker'
                    }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ComponentizedYear'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ComponentizedYear'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _React$createElement3))
                ),
                _react2.default.createElement(
                    'div',
                    (_React$createElement7 = { className: _ComponentizedDatePicker2.default.fieldWrapper, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'ComponentizedDatePicker'
                    }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _React$createElement7),
                    isInfoMode ? _react2.default.createElement(_TextField2.default, _extends({
                        floatingLabelText: _react2.default.createElement(Label, (_React$createElement5 = { label: 'Month', 'data-codecept-selector-node': 'Label',
                            'data-codecept-selector-file': 'ComponentizedDatePicker'
                        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _React$createElement5)),
                        disabled: isInfoMode,
                        value: month_asString(month)
                    }, _.get(this, 'props.infoModeProps.TextField'), (_extends3 = {
                        'data-codecept-selector-node': 'TextField',
                        'data-codecept-selector-file': 'ComponentizedDatePicker'
                    }, _defineProperty(_extends3, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_extends3, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _extends3))) : _react2.default.createElement(
                        _VirtualSelectField2.default,
                        _extends({}, selectProps, (_extends4 = {
                            disabled: isDisabled,
                            floatingLabelText: _react2.default.createElement(Label, (_React$createElement6 = { label: 'Month', 'data-codecept-selector-node': 'Label',
                                'data-codecept-selector-file': 'ComponentizedDatePicker'
                            }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _React$createElement6)),
                            value: month,
                            onChange: function onChange(event, index, value) {
                                var updatedValue = _.assign({}, _this.props.value, {
                                    month: value
                                });
                                _this.props.actions.setFieldValueByKey(updatedValue, _this.props.fieldKey);
                            },
                            errorText: _.get(this.props, 'error.month'),
                            'data-codecept-selector-node': 'SelectField',
                            'data-codecept-selector-file': 'ComponentizedDatePicker'
                        }, _defineProperty(_extends4, 'data-codecept-selector-node', 'SelectField'), _defineProperty(_extends4, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_extends4, 'data-codecept-selector-node', 'SelectField'), _defineProperty(_extends4, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _extends4)),
                        this.monthItems()
                    )
                ),
                _react2.default.createElement(
                    'div',
                    (_React$createElement10 = { className: _ComponentizedDatePicker2.default.fieldWrapper, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'ComponentizedDatePicker'
                    }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _React$createElement10),
                    isInfoMode ? _react2.default.createElement(_TextField2.default, _extends({
                        floatingLabelText: _react2.default.createElement(Label, (_React$createElement8 = { label: 'Day', 'data-codecept-selector-node': 'Label',
                            'data-codecept-selector-file': 'ComponentizedDatePicker'
                        }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _React$createElement8)),
                        disabled: isInfoMode,
                        value: day + 1
                    }, _.get(this, 'props.infoModeProps.TextField'), (_extends5 = {
                        'data-codecept-selector-node': 'TextField',
                        'data-codecept-selector-file': 'ComponentizedDatePicker'
                    }, _defineProperty(_extends5, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends5, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_extends5, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends5, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _extends5))) : _react2.default.createElement(
                        _VirtualSelectField2.default,
                        _extends({}, selectProps, (_extends6 = {
                            disabled: isDisabled,
                            floatingLabelText: _react2.default.createElement(Label, (_React$createElement9 = { label: 'Day', 'data-codecept-selector-node': 'Label',
                                'data-codecept-selector-file': 'ComponentizedDatePicker'
                            }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _React$createElement9)),
                            value: day,
                            onChange: function onChange(event, index, value) {
                                var updatedValue = _.assign({}, _this.props.value, {
                                    day: value
                                });
                                _this.props.actions.setFieldValueByKey(updatedValue, _this.props.fieldKey);
                            },
                            errorText: _.get(this.props, 'error.day'),
                            'data-codecept-selector-node': 'SelectField',
                            'data-codecept-selector-file': 'ComponentizedDatePicker'
                        }, _defineProperty(_extends6, 'data-codecept-selector-node', 'SelectField'), _defineProperty(_extends6, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_extends6, 'data-codecept-selector-node', 'SelectField'), _defineProperty(_extends6, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _extends6)),
                        this.dayItems()
                    )
                )
            )
        );

        return this.props.renderGridWrapper(_extends({}, this.props, { children: ComponentizedDatePickerComponent }));
    },
    monthItems: function monthItems() {
        var _ref2 = this.props.value || {},
            month = _ref2.month;

        var months = [].concat(_toConsumableArray(Array(12).keys())).map(function (month) {
            return {
                value: month,
                text: month_asString(month)
            };
        });

        var monthOpts = [{
            value: null,
            text: '\xA0'
        }].concat(_toConsumableArray(months));

        return monthOpts.map(function (opt, index) {
            var _React$createElement13;

            var isSelected = opt === month;
            return _react2.default.createElement(_MenuItem2.default, (_React$createElement13 = {
                key: index,
                value: opt.value,
                checked: isSelected,
                insetChildren: true,
                primaryText: opt.text,
                'data-codecept-selector-node': 'MenuItem',
                'data-codecept-selector-file': 'ComponentizedDatePicker'
            }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _React$createElement13));
        });
    },
    dayItems: function dayItems() {
        var _ref3 = this.props.value || {},
            year = _ref3.year,
            month = _ref3.month,
            day = _ref3.day;

        var currDate = (0, _friendlyRubyMoment2.default)();
        var yearAsNumber = _.toNumber(year);
        var yearIsFinite = _.isFinite(yearAsNumber);
        var monthIsFinite = _.isFinite(month);
        if (yearIsFinite) {
            currDate.year(yearAsNumber);
        }
        if (monthIsFinite) {
            currDate.month(month);
        }
        var numDays = monthIsFinite && yearIsFinite ? currDate.daysInMonth() : 31;
        var days = [].concat(_toConsumableArray(Array(numDays).keys())).map(function (day) {
            return {
                value: day + 1,
                text: day + 1
            };
        });

        var dayOpts = [{
            value: null,
            text: '\xA0'
        }].concat(_toConsumableArray(days));

        return dayOpts.map(function (opt, index) {
            var _React$createElement14;

            var isSelected = opt.value === day;
            return _react2.default.createElement(_MenuItem2.default, (_React$createElement14 = {
                key: index,
                value: opt.value,
                checked: isSelected,
                insetChildren: true,
                primaryText: opt.text,
                'data-codecept-selector-node': 'MenuItem',
                'data-codecept-selector-file': 'ComponentizedDatePicker'
            }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'ComponentizedDatePicker'), _React$createElement14));
        });
    },

    _isDisabled: function _isDisabled() {
        return this.props.locked || this.props.disabled;
    }
});

function month_asString(month) {
    return _.isNumber(month) ? (0, _friendlyRubyMoment2.default)().month(month).format('MMMM') : '';
}

exports.default = (0, _addInfoModeProps2.default)(FieldComponentizedDatePicker);