'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var request = require('@rubyapps/ruby-superagent');
var rubyWords = require('@rubyapps/ruby-words');
var path = require('path');
var CONSTANTS = require('../common/constants');
var rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');

var _require = require('@rubyapps/ruby-component-plugin-template-editor/src/common/constants'),
    STRUCTURED_DATA_TEMPLATE = _require.STRUCTURED_DATA_TEMPLATE;

function getRowContextMenuItems_usingGriddleInstance_andParentProps(griddleInstance, parentProps) {
    var selfModule = this;

    var addChildMenuItem = getAddChildMenuItem_usingSelfModule_andParentProps.apply(griddleInstance, [selfModule, parentProps]);
    var duplicateMenuItem = duplicateMenuItem_usingSelfModule_andParentProps.apply(griddleInstance, [selfModule, parentProps]);
    var alterTemplateMenuItem = getAlterTemplateMenuItem_usingSelfModule_andParentProps.apply(griddleInstance, [selfModule, parentProps]);
    var addToProposalMenuItem = getAddToProposalMenuItem_usingSelfModule_andParentProps.apply(griddleInstance, [selfModule, parentProps]);
    var deleteMenuItem = getDeleteMenuItem_usingSelfModule_andParentProps.apply(griddleInstance, [selfModule, parentProps]);
    return [].concat(addChildMenuItem, duplicateMenuItem, alterTemplateMenuItem, addToProposalMenuItem, deleteMenuItem).filter(function (item) {
        return item;
    });
}

module.exports = getRowContextMenuItems_usingGriddleInstance_andParentProps;

function getDeleteMenuItem_usingSelfModule_andParentProps(selfModule, parentProps) {
    var griddleInstance = this;
    var griddleProps = griddleInstance.props;

    var _selfModule$getDepend = selfModule.getDependencies(),
        getCanDeleteEntry_withId_andTemplateKeyword = _selfModule$getDepend.getCanDeleteEntry_withId_andTemplateKeyword,
        formsComponent = _selfModule$getDepend.formsComponent;

    var confirmDialog = parentProps.delegateActions.confirmDialog,
        apiUrlPrefix = parentProps.apiUrlPrefix;

    var _Object$assign = _extends({}, _.get(griddleProps, 'rowData.__metadata'), _.get(griddleProps, 'rowData')),
        id = _Object$assign.id,
        templateKeyword = _Object$assign.template_keyword;

    var _ref = _.result(formsComponent, ['getState', templateKeyword]) || {},
        unique_template = _ref.unique_template;

    var canDelete = getCanDeleteEntry_withId_andTemplateKeyword(id, templateKeyword);
    var deleteEndpoint = path.join(apiUrlPrefix, CONSTANTS.API_KEY, '' + id, 'delete');
    var pushNotification = selfModule.pushNotification.bind(selfModule);
    var showNotificationModal = selfModule.showNotificationModal.bind(selfModule);

    return {
        key: "delete",
        primaryText: "Delete",
        disabled: !canDelete || unique_template,
        onTouchTap: function onTouchTap(e) {
            confirmDialog.openDialogWithOptions({
                title: 'Delete',
                submitLabel: 'Delete',
                content: 'Are you sure you want to delete this item?',
                endpoint: deleteEndpoint,
                submitSuccessCallback: function submitSuccessCallback(response) {
                    var contentDisplayString = rubyWords.quote(_.get(response, 'body.name')) || 'This content';

                    griddleInstance.props.events.refreshCurrentPage();

                    pushNotification({
                        type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS,
                        message: contentDisplayString + ' was deleted.'
                    });
                },
                submitFailCallback: function submitFailCallback(err) {
                    var notificationMethod = showNotificationModal;
                    var type = rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR;
                    var title = 'Content Deletion Error';
                    var message = 'An unexpected error was encountered while deleting content. ' + 'Please try again later or contact Support.';

                    if (err.status === 403) {
                        confirmDialog.closeDialog();

                        message = 'You do not have the permission to delete content.';
                        notificationMethod = pushNotification;
                    } else if (err.status === 409) {
                        confirmDialog.closeDialog();

                        var errName = _.get(err, 'response.body.error.name');
                        if (errName === 'OutstandingApprovalError') {
                            message = 'There is an outstanding approval request on this entry. ' + 'Please try again once this request is resolved.';
                            notificationMethod = pushNotification;
                        }
                    }

                    notificationMethod({
                        title: title,
                        message: message,
                        type: type
                    });
                }
            });
        }
    };
}
function getAddToProposalMenuItem_usingSelfModule_andParentProps(selfModule, parentProps) {
    var griddleInstance = this;
    var griddleProps = griddleInstance.props;

    var _Object$assign2 = _extends({}, _.get(griddleProps, 'rowData.__metadata'), _.get(griddleProps, 'rowData')),
        templateKeyword = _Object$assign2.template_keyword;

    var addToProposalDialog = parentProps.delegateActions.addToProposalDialog;

    var _selfModule$getDepend2 = selfModule.getDependencies(),
        formsComponent = _selfModule$getDepend2.formsComponent,
        pluginProposalComponent = _selfModule$getDepend2.pluginProposalComponent;

    if (!pluginProposalComponent) {
        return null;
    }

    var canAddToProposal = _.isFunction(formsComponent.canAddTemplateToProposal) && formsComponent.canAddTemplateToProposal(templateKeyword);

    var canUserAddToProposal = selfModule.doesUserHavePermission({
        template: templateKeyword,
        subsite: formsComponent.proposalSubsiteId(),
        ruby_client: true,
        action: "add",
        model: "content"
    });

    return {
        key: "add-to-proposal",
        primaryText: "Add to Proposal",
        disabled: !(canAddToProposal && canUserAddToProposal) || !addToProposalDialog,
        onTouchTap: function onTouchTap(e) {
            var pluginProposalState = pluginProposalComponent && pluginProposalComponent.getState && pluginProposalComponent.getState();
            var proposalId = _.get(pluginProposalState, 'activeProposalId');
            var parentId = _.get(pluginProposalState, 'activeProposalParentPageId');

            var griddleProps = griddleInstance.props;
            var id = _.get(griddleProps, 'rowData.__metadata.id') || _.get(griddleProps, 'rowData.id');

            var name = _.get(griddleProps, 'rowData.__metadata.id') || _.get(griddleProps, 'rowData.name');

            addToProposalDialog.open({
                contentId: id,
                defaultFormData: {
                    name: name,
                    proposalId: proposalId,
                    parentId: parentId
                }
            });
        }
    };
}

function getAlterTemplateMenuItem_usingSelfModule_andParentProps(selfModule, parentProps) {
    var griddleInstance = this;
    var griddleProps = griddleInstance.props;
    var canPublish = selfModule.canUserPublish();

    var _selfModule$getDepend3 = selfModule.getDependencies(),
        formsComponent = _selfModule$getDepend3.formsComponent;

    var alterTemplateDialog = parentProps.delegateActions.alterTemplateDialog,
        apiUrlPrefix = parentProps.apiUrlPrefix;

    var _Object$assign3 = _extends({}, _.get(griddleProps, 'rowData.__metadata'), _.get(griddleProps, 'rowData')),
        templateKeyword = _Object$assign3.template_keyword;

    var _ref2 = _.result(formsComponent, ['getState', templateKeyword]) || {},
        unique_template = _ref2.unique_template;

    var itemsForm = _.get(parentProps.allForms, templateKeyword);
    var canAlterTemplate = _.get(itemsForm, 'templateType', '') === STRUCTURED_DATA_TEMPLATE;
    var pushNotification = selfModule.pushNotification.bind(selfModule);
    var showErrorNotification = selfModule.showErrorNotification.bind(selfModule);

    return {
        key: "alter-template",
        primaryText: "Alter Template",
        disabled: !canAlterTemplate || !canPublish || parentProps.disallow_alter_template || unique_template,
        onTouchTap: function onTouchTap(e) {
            var id = _.get(griddleProps, 'rowData.__metadata.id') || _.get(griddleProps, 'rowData.id');
            alterTemplateDialog.openAlterTemplateDialog({
                fields: { templateKey: { value: templateKeyword } },
                supportedTemplateTypes: [STRUCTURED_DATA_TEMPLATE],
                endpoint: path.join(apiUrlPrefix, 'content', '' + id, 'alter-template'),
                submitSuccessCallback: function submitSuccessCallback(response) {
                    var entryName = rubyWords.quote(_.get(response, 'body.name'));
                    var griddleProps = griddleInstance.props;

                    griddleProps.events.refreshCurrentPage();
                    pushNotification({
                        message: 'Altered the template of ' + entryName + '.',
                        type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS
                    });
                },
                submitFailCallback: function submitFailCallback(error) {
                    showErrorNotification({ error: error });
                }
            });
        }
    };
}

function getAddChildMenuItem_usingSelfModule_andParentProps(selfModule, parentProps) {
    var griddleInstance = this;
    var hierarchical = parentProps.hierarchical,
        addEntryUrl = parentProps.addEntryUrl,
        createNewPage = parentProps.actions.createNewPage;

    var griddleProps = griddleInstance.props;

    var _selfModule$getDepend4 = selfModule.getDependencies(),
        formsComponent = _selfModule$getDepend4.formsComponent;

    var forms = formsComponent.getState();

    var _Object$assign4 = _extends({}, _.get(griddleProps, 'rowData.__metadata'), _.get(griddleProps, 'rowData')),
        templateKeyword = _Object$assign4.template_keyword;

    var disableAddingChildren = _.get(forms, [templateKeyword, 'disable_adding_children'], false);

    return {
        key: "add-child",
        primaryText: "Add Child Page",
        disabled: !hierarchical || disableAddingChildren,
        onTouchTap: function onTouchTap(e) {
            var griddleProps = griddleInstance.props;
            var parentId = _.get(griddleProps, 'rowData.id');
            var actions = parentProps.actions;

            var _selfModule$getState = selfModule.getState(),
                griddleState = _selfModule$getState.griddleState;

            var customFilterData = griddleState.utilityBarProperties.customFilters;

            createNewPage({
                data: _extends({
                    parent_id: parentId
                }, customFilterData),
                path: addEntryUrl
            });
        }
    };
}

function duplicateMenuItem_usingSelfModule_andParentProps(selfModule, parentProps) {
    var griddleInstance = this;
    var apiUrlPrefix = parentProps.apiUrlPrefix;

    var _selfModule$getDepend5 = selfModule.getDependencies(),
        formsComponent = _selfModule$getDepend5.formsComponent;

    var griddleProps = griddleInstance.props;
    var canPublish = selfModule.canUserPublish();
    var pushNotification = selfModule.pushNotification.bind(selfModule);
    var showNotificationModal = selfModule.showNotificationModal.bind(selfModule);

    var _Object$assign5 = _extends({}, _.get(griddleProps, 'rowData.__metadata'), _.get(griddleProps, 'rowData')),
        template_keyword = _Object$assign5.template_keyword;

    var _ref3 = _.result(formsComponent, ['getState', template_keyword]) || {},
        unique_template = _ref3.unique_template;

    return {
        key: "duplicate",
        primaryText: "Duplicate",
        disabled: !canPublish || unique_template,
        onTouchTap: function onTouchTap(e) {
            var griddleProps = griddleInstance.props;
            var rowData = griddleProps.rowData;

            copy({
                entryData: rowData,
                refreshCurrentPage: _.get(griddleProps, 'events.refreshCurrentPage'),
                apiUrlPrefix: apiUrlPrefix,
                pushNotification: pushNotification,
                showNotificationModal: showNotificationModal
            });
        }
    };
}

function copyApiEndpointGenerator_forId_withOptions(id, _ref4) {
    var apiUrlPrefix = _ref4.apiUrlPrefix;

    return path.join(apiUrlPrefix, CONSTANTS.API_KEY
    //# TODO: We need to force this to a string because of
    //# how this function works.  Is this alright?
    , '' + id, 'copy');
}

function copy(_ref5) {
    var entryData = _ref5.entryData,
        refreshCurrentPage = _ref5.refreshCurrentPage,
        apiUrlPrefix = _ref5.apiUrlPrefix,
        pushNotification = _ref5.pushNotification,
        showNotificationModal = _ref5.showNotificationModal,
        _ref5$skipValidation = _ref5.skipValidation,
        skipValidation = _ref5$skipValidation === undefined ? false : _ref5$skipValidation;

    var entryId = _.get(entryData, '__metadata.id') || _.get(entryData, 'id');
    var entryName = rubyWords.quote(_.get(entryData, 'name'));

    request.post(copyApiEndpointGenerator_forId_withOptions(entryId, { apiUrlPrefix: apiUrlPrefix }) + (skipValidation ? '?skipValidation=true' : '')).then(function (response) {
        var newEntryName = rubyWords.quote(_.get(response, 'body.name'));

        refreshCurrentPage();

        pushNotification({
            message: 'Copied ' + entryName + ' to ' + newEntryName + '.',
            type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS
        });
    }).catch(function (err) {
        var notificationMethod = pushNotification;

        var notificationOptions = {
            title: null,
            message: null,
            additionalActions: null,
            type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR
        };

        if (err.status === 403) {
            // should not see this ultimately, since the lister will constrai    ned to valid entries
            notificationOptions.message = 'You do not have the permission to copy this content.';
        } else if (err.status === 422) {
            var _React$createElement2, _React$createElement3, _React$createElement4;

            var fieldErrors = err.response.body.error.errors.map(function (fieldError) {
                return fieldError.message;
            }).reduce(function (collector, fieldError) {
                var _React$createElement;

                collector.push(fieldError);
                collector.push(React.createElement('br', (_React$createElement = {
                    'data-codecept-selector-node': 'br',
                    'data-codecept-selector-file': 'row-context-menu-items'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'row-context-menu-items'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'row-context-menu-items'), _React$createElement)));

                return collector;
            }, []);

            notificationOptions.title = 'Content Copy Validation Error';
            notificationOptions.message = React.createElement(
                'p',
                (_React$createElement4 = {
                    'data-codecept-selector-node': 'p',
                    'data-codecept-selector-file': 'row-context-menu-items'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'row-context-menu-items'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'row-context-menu-items'), _React$createElement4),
                'Validation error while copying content:',
                React.createElement('br', (_React$createElement2 = {
                    'data-codecept-selector-node': 'br',
                    'data-codecept-selector-file': 'row-context-menu-items'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'row-context-menu-items'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'row-context-menu-items'), _React$createElement2)),
                React.createElement('br', (_React$createElement3 = {
                    'data-codecept-selector-node': 'br',
                    'data-codecept-selector-file': 'row-context-menu-items'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'row-context-menu-items'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'row-context-menu-items'), _React$createElement3)),
                ' ',
                fieldErrors
            );

            notificationOptions.additionalActions = [{
                label: 'Copy without Validating',
                onTouchTap: function onTouchTap(evt, callerReactComponent) {
                    callerReactComponent.props.delegateClose();
                    copy({
                        entryData: entryData,
                        refreshCurrentPage: refreshCurrentPage,
                        apiUrlPrefix: apiUrlPrefix,
                        pushNotification: pushNotification,
                        showNotificationModal: showNotificationModal,
                        skipValidation: true
                    });
                }
            }];

            notificationMethod = showNotificationModal;
        } else {
            notificationOptions.title = 'Content Copy Error';
            notificationOptions.message = 'An error was encountered while copying content. ' + 'Please try again later or contact an administrator.';
            notificationMethod = showNotificationModal;
        }

        notificationMethod(notificationOptions);
    });
}