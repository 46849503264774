'use strict';

var _React$createClass;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _constants = require('../../common/constants');

var CONSTANTS = _interopRequireWildcard(_constants);

var _keycode = require('keycode');

var _keycode2 = _interopRequireDefault(_keycode);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _Dropdown = require('@rubyapps/ruby-react-components/src/client/input/Dropdown');

var _Dropdown2 = _interopRequireDefault(_Dropdown);

var _Toolbar = require('material-ui/Toolbar');

var _firstPage = require('material-ui/svg-icons/navigation/first-page');

var _firstPage2 = _interopRequireDefault(_firstPage);

var _lastPage = require('material-ui/svg-icons/navigation/last-page');

var _lastPage2 = _interopRequireDefault(_lastPage);

var _chevronRight = require('material-ui/svg-icons/navigation/chevron-right');

var _chevronRight2 = _interopRequireDefault(_chevronRight);

var _chevronLeft = require('material-ui/svg-icons/navigation/chevron-left');

var _chevronLeft2 = _interopRequireDefault(_chevronLeft);

var _listPagination = require('./list-pagination.cssModule');

var _listPagination2 = _interopRequireDefault(_listPagination);

var _listPagination_style = require('./list-pagination_style');

var _listPagination_style2 = _interopRequireDefault(_listPagination_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//# Icons


//# Styles


var ListPagination = React.createClass((_React$createClass = {
    displayName: 'ListPagination',

    getDefaultProps: function getDefaultProps() {
        return {
            QUERY_DEBOUNCE_TIMEOUT: 2000
        };
    },
    getInitialState: function getInitialState() {

        var initialState = {
            loading: false,
            pageInput: null,
            shouldScrollTop: false
        };

        this._state = initialState; //# not ideal, but something is unsetting the initialState when going from page 2 => page 1
        return initialState;
    },
    renderAddEntryButton: function renderAddEntryButton() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4;

        var self = this;
        return this.props.addEntryUrl ? React.createElement(
            'div',
            (_React$createElement4 = { className: 'filter-column filter-col-1', 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'list-pagination'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'list-pagination'), _React$createElement4),
            React.createElement(
                'button',
                (_React$createElement3 = { className: 'ui-state-default rtg-button rtg-addButton-top', 'data-codecept-selector-node': 'button',
                    'data-codecept-selector-file': 'list-pagination'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'button'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'button'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'list-pagination'), _React$createElement3),
                React.createElement('span', (_React$createElement = { className: 'ui-icon ui-icon-plus', 'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'list-pagination'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'list-pagination'), _React$createElement)),
                React.createElement(
                    'a',
                    (_React$createElement2 = { href: this.props.addEntryUrl, className: 'text', 'data-codecept-selector-node': 'a',
                        'data-codecept-selector-file': 'list-pagination'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'list-pagination'), _React$createElement2),
                    'Add entry'
                )
            )
        ) : undefined;
    }

}, _defineProperty(_React$createClass, 'renderAddEntryButton', function renderAddEntryButton() {
    var _React$createElement5, _React$createElement6;

    var self = this;
    var addEntryUrl = this.props.addEntryUrl;

    return addEntryUrl ? React.createElement(
        'button',
        (_React$createElement6 = {
            'data-codecept-selector-node': 'button',
            'data-codecept-selector-file': 'list-pagination'
        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'button'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'button'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'list-pagination'), _React$createElement6),
        React.createElement(
            'a',
            (_React$createElement5 = { href: addEntryUrl, 'data-codecept-selector-node': 'a',
                'data-codecept-selector-file': 'list-pagination'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'list-pagination'), _React$createElement5),
            'Add entry'
        )
    ) : undefined;
}), _defineProperty(_React$createClass, 'handleFirstPageNavEvent', function handleFirstPageNavEvent() {
    var newState = _extends({ pageInput: 1 }, this.getNewState_requestToScrollTopOnLoadingFinished(), this.getNewState_requestingPageLoad());
    this._state = newState;
    this.setState(newState);
    this.props.events.getPage(1);
}), _defineProperty(_React$createClass, 'handleLastPageNavEvent', function handleLastPageNavEvent() {
    var newState = _extends({ pageInput: this.props.pageProperties.maxPage }, this.getNewState_requestToScrollTopOnLoadingFinished(), this.getNewState_requestingPageLoad());
    this._state = newState;
    this.setState(newState);

    this.props.events.getPage(this.props.pageProperties.maxPage);
}), _defineProperty(_React$createClass, 'handlePreviousPageNavEvent', function handlePreviousPageNavEvent() {
    var currentPage = this.props.pageProperties.currentPage;
    var getPreviousPage = this.props.events.getPreviousPage;


    var newState = _extends({
        pageInput: currentPage - 1
    }, this.getNewState_requestToScrollTopOnLoadingFinished(), this.getNewState_requestingPageLoad());
    this._state = newState;
    this.setState(newState);

    getPreviousPage();
}), _defineProperty(_React$createClass, 'handleNextPageNavEvent', function handleNextPageNavEvent() {
    var currentPage = this.props.pageProperties.currentPage;
    var getNextPage = this.props.events.getNextPage;


    var newState = _extends({
        pageInput: currentPage + 1
    }, this.getNewState_requestToScrollTopOnLoadingFinished(), this.getNewState_requestingPageLoad());
    this._state = newState;
    this.setState(newState);

    getNextPage();
}), _defineProperty(_React$createClass, 'handlePageInputEvent', function handlePageInputEvent(value) {
    var selectedPage = parseInt(value);
    var _props$pageProperties = this.props.pageProperties,
        currentPage = _props$pageProperties.currentPage,
        maxPage = _props$pageProperties.maxPage;

    var isValidPageSelection = !isNaN(selectedPage) && selectedPage != currentPage && selectedPage > 0 && selectedPage <= maxPage;

    if (isValidPageSelection) {
        var newState = _extends({}, this.getNewState_requestToScrollTopOnLoadingFinished(), this.getNewState_requestingPageLoad());
        this._state = newState;
        this.setState(newState);

        this.props.events.getPage(selectedPage);
    }
}), _defineProperty(_React$createClass, 'componentWillMount', function componentWillMount() {
    this._debounced_handlePageInputEvent = _.debounce(this.handlePageInputEvent, this.props.QUERY_DEBOUNCE_TIMEOUT);
}), _defineProperty(_React$createClass, 'componentWillReceiveProps', function componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this._state.loading) {
        //# just finished loading
        if (this._state.shouldScrollTop) {
            setImmediate(function () {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            });
        }

        var newState = {
            loading: false,
            pageInput: null,
            shouldScrollTop: false
        };
        this._state = newState;
        this.setState(newState);
    }
}), _defineProperty(_React$createClass, 'debouncedInputHandler', function debouncedInputHandler(e) {
    e.persist();
    var value = e.target.value;
    this.setState({ pageInput: value });
    this._debounced_handlePageInputEvent(value);
}), _defineProperty(_React$createClass, 'onKeyDown', function onKeyDown(event) {
    switch ((0, _keycode2.default)(event)) {
        case 'enter':
            this._debounced_handlePageInputEvent.flush();
            break;
    }
}), _defineProperty(_React$createClass, 'renderPageInput', function renderPageInput() {
    var _React$createElement7, _React$createElement8;

    var _props$pageProperties2 = this.props.pageProperties,
        currentPage = _props$pageProperties2.currentPage,
        maxPage = _props$pageProperties2.maxPage;

    var pageInputRaw = _.get(this, 'state.pageInput');
    var value = pageInputRaw != null ? pageInputRaw : currentPage;

    //# NOTE: figure out how to handle using currentPage when we reload the page
    return React.createElement(
        'span',
        (_React$createElement8 = { className: 'pager-nav__input', 'data-codecept-selector-node': 'span',
            'data-codecept-selector-file': 'list-pagination'
        }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'list-pagination'), _React$createElement8),
        React.createElement('input', (_React$createElement7 = { type: 'text',
            onChange: this.debouncedInputHandler,
            onKeyDown: this.onKeyDown,
            value: value,
            className: _listPagination2.default.pagerInput,
            'data-codecept-selector-node': 'input',
            'data-codecept-selector-file': 'list-pagination'
        }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'input'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'input'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'list-pagination'), _React$createElement7)),
        ' of ',
        this.props.pageProperties.maxPage
    );
}), _defineProperty(_React$createClass, 'renderNav', function renderNav() {
    var _React$createElement9, _React$createElement10, _React$createElement11, _React$createElement12, _React$createElement13, _React$createElement14, _React$createElement15, _React$createElement16, _React$createElement17;

    var _props$pageProperties3 = this.props.pageProperties,
        currentPage = _props$pageProperties3.currentPage,
        maxPage = _props$pageProperties3.maxPage;

    var showFirstPage = currentPage !== 1;
    var showLastPage = currentPage !== maxPage;

    var firstPageIcon = React.createElement(
        _IconButton2.default,
        (_React$createElement10 = {
            onTouchTap: this.handleFirstPageNavEvent,
            disabled: !showFirstPage,
            'data-codecept-selector-node': 'IconButton',
            'data-codecept-selector-file': 'list-pagination'
        }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'list-pagination'), _React$createElement10),
        React.createElement(_firstPage2.default, (_React$createElement9 = {
            'data-codecept-selector-node': 'FirstPageIcon',
            'data-codecept-selector-file': 'list-pagination'
        }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'FirstPageIcon'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'FirstPageIcon'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'list-pagination'), _React$createElement9))
    );

    var lastPageIcon = React.createElement(
        _IconButton2.default,
        (_React$createElement12 = {
            onTouchTap: this.handleLastPageNavEvent,
            disabled: !showLastPage,
            'data-codecept-selector-node': 'IconButton',
            'data-codecept-selector-file': 'list-pagination'
        }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'list-pagination'), _React$createElement12),
        React.createElement(_lastPage2.default, (_React$createElement11 = {
            'data-codecept-selector-node': 'LastPageIcon',
            'data-codecept-selector-file': 'list-pagination'
        }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'LastPageIcon'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'LastPageIcon'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'list-pagination'), _React$createElement11))
    );

    var prevPageIcon = React.createElement(
        _IconButton2.default,
        (_React$createElement14 = {
            onTouchTap: this.handlePreviousPageNavEvent,
            disabled: !this.props.hasPrevious,
            'data-codecept-selector-node': 'IconButton',
            'data-codecept-selector-file': 'list-pagination'
        }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'list-pagination'), _React$createElement14),
        React.createElement(_chevronLeft2.default, (_React$createElement13 = {
            'data-codecept-selector-node': 'ChevronLeftIcon',
            'data-codecept-selector-file': 'list-pagination'
        }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'ChevronLeftIcon'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'ChevronLeftIcon'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'list-pagination'), _React$createElement13))
    );

    var nextPageIcon = React.createElement(
        _IconButton2.default,
        (_React$createElement16 = {
            onTouchTap: this.handleNextPageNavEvent,
            disabled: !this.props.hasNext,
            'data-codecept-selector-node': 'IconButton',
            'data-codecept-selector-file': 'list-pagination'
        }, _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'list-pagination'), _React$createElement16),
        React.createElement(_chevronRight2.default, (_React$createElement15 = {
            'data-codecept-selector-node': 'ChevronRightIcon',
            'data-codecept-selector-file': 'list-pagination'
        }, _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'ChevronRightIcon'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'ChevronRightIcon'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'list-pagination'), _React$createElement15))
    );

    return React.createElement(
        'span',
        (_React$createElement17 = { className: _listPagination2.default.pagerNav, 'data-codecept-selector-node': 'span',
            'data-codecept-selector-file': 'list-pagination'
        }, _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'list-pagination'), _React$createElement17),
        firstPageIcon,
        prevPageIcon,
        this.renderPageInput(),
        nextPageIcon,
        lastPageIcon
    );
}), _defineProperty(_React$createClass, 'getPageSizeOptions', function getPageSizeOptions() {
    var total = this.props.totalItemCount || 9999;

    var options = CONSTANTS.DEFAULT_PAGE_SIZE_OPTIONS.filter(function (pageSizeOption) {
        return pageSizeOption <= total;
    }).map(function (pageSizeOption) {
        var value = pageSizeOption,
            text = pageSizeOption.toString();
        return { value: value, text: text };
    });

    if (options.length !== CONSTANTS.DEFAULT_PAGE_SIZE_OPTIONS.length) {
        var value = CONSTANTS.DEFAULT_PAGE_SIZE_OPTIONS[options.length];
        var text = 'ALL';
        options.push({ value: value, text: text });
    }

    return options;
}), _defineProperty(_React$createClass, 'handlePageSizeEvent', function handlePageSizeEvent(event, index, value) {
    this.props.setPageSize(value);
}), _defineProperty(_React$createClass, 'renderPageSizeSelectionDropdown', function renderPageSizeSelectionDropdown() {
    var _extends2, _React$createElement18;

    var _props$pageProperties4 = this.props.pageProperties,
        pageSize = _props$pageProperties4.pageSize,
        defaultPageSize = _props$pageProperties4.defaultPageSize;

    var pageSizeOptions = this.getPageSizeOptions();

    var maxPageSizeOptionValue = _.get(_.maxBy(pageSizeOptions, 'value'), 'value');
    var pageSizeOption = pageSizeOptions.filter(function (option) {
        return option.value == pageSize;
    });

    // If pageSize is greater than the largest value of available options
    // it means the current number of items is less than the current page size, so show all
    // Otherwise, pageSize should match an option, so use that value
    var pageSizeValue = pageSize > maxPageSizeOptionValue ? maxPageSizeOptionValue : pageSizeOption.length ? pageSizeOption[0].value : undefined;

    var defaultPageSizeValue = defaultPageSize || pageSizeOptions[0].value;

    return React.createElement(
        'span',
        (_React$createElement18 = { className: _listPagination2.default.pagerRowsPerPage, 'data-codecept-selector-node': 'span',
            'data-codecept-selector-file': 'list-pagination'
        }, _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'list-pagination'), _React$createElement18),
        React.createElement(_Dropdown2.default, _extends({}, _listPagination_style2.default.Dropdown, (_extends2 = {
            key: 'pageSize',
            name: 'pageSize',
            value: pageSizeValue || defaultPageSizeValue,
            options: pageSizeOptions,
            onChange: this.handlePageSizeEvent,
            autoWidth: true,
            'data-codecept-selector-node': 'Dropdown',
            'data-codecept-selector-file': 'list-pagination'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Dropdown'), _defineProperty(_extends2, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Dropdown'), _defineProperty(_extends2, 'data-codecept-selector-file', 'list-pagination'), _extends2)))
    );
}), _defineProperty(_React$createClass, 'renderCurrentPageInfo', function renderCurrentPageInfo() {
    var _React$createElement19;

    var _props$pageProperties5 = this.props.pageProperties,
        currentPage = _props$pageProperties5.currentPage,
        pageSize = _props$pageProperties5.pageSize;

    var total = this.props.totalItemCount;
    var firstItemIdx = (currentPage - 1) * pageSize + 1;
    var lastItemIdx = Math.min(total, firstItemIdx + pageSize - 1);

    var displayedNumbersAreValid = !(isNaN(total) || isNaN(firstItemIdx) || isNaN(lastItemIdx));

    var statusString = total && displayedNumbersAreValid ? firstItemIdx.toLocaleString() + ' - ' + lastItemIdx.toLocaleString() + ' of ' + total.toLocaleString() : '';

    return React.createElement(
        'span',
        (_React$createElement19 = { className: 'pager-status', 'data-codecept-selector-node': 'span',
            'data-codecept-selector-file': 'list-pagination'
        }, _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'list-pagination'), _React$createElement19),
        statusString
    );
}), _defineProperty(_React$createClass, 'render', function render() {
    var _React$createElement20, _React$createElement21, _React$createElement22, _React$createElement23, _React$createElement24, _React$createElement25, _React$createElement26;

    var total = this.props.totalItemCount;
    //{this.renderAddEntryButton()}
    return total ? React.createElement(
        _Toolbar.Toolbar,
        (_React$createElement25 = { className: _listPagination2.default.Toolbar, 'data-codecept-selector-node': 'Toolbar',
            'data-codecept-selector-file': 'list-pagination'
        }, _defineProperty(_React$createElement25, 'data-codecept-selector-node', 'Toolbar'), _defineProperty(_React$createElement25, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement25, 'data-codecept-selector-node', 'Toolbar'), _defineProperty(_React$createElement25, 'data-codecept-selector-file', 'list-pagination'), _React$createElement25),
        React.createElement(_Toolbar.ToolbarGroup, (_React$createElement20 = {
            'data-codecept-selector-node': 'ToolbarGroup',
            'data-codecept-selector-file': 'list-pagination'
        }, _defineProperty(_React$createElement20, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement20, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement20, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement20, 'data-codecept-selector-file', 'list-pagination'), _React$createElement20)),
        React.createElement(
            _Toolbar.ToolbarGroup,
            (_React$createElement24 = { className: _listPagination2.default.ToolbarGroup, 'data-codecept-selector-node': 'ToolbarGroup',
                'data-codecept-selector-file': 'list-pagination'
            }, _defineProperty(_React$createElement24, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement24, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement24, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement24, 'data-codecept-selector-file', 'list-pagination'), _React$createElement24),
            React.createElement(
                'span',
                (_React$createElement21 = { className: _listPagination2.default.pagerLabel, 'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'list-pagination'
                }, _defineProperty(_React$createElement21, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement21, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement21, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement21, 'data-codecept-selector-file', 'list-pagination'), _React$createElement21),
                'Rows per page:'
            ),
            this.renderPageSizeSelectionDropdown(),
            React.createElement(_Toolbar.ToolbarSeparator, (_React$createElement22 = { className: _listPagination2.default.ToolbarSeparator, 'data-codecept-selector-node': 'ToolbarSeparator',
                'data-codecept-selector-file': 'list-pagination'
            }, _defineProperty(_React$createElement22, 'data-codecept-selector-node', 'ToolbarSeparator'), _defineProperty(_React$createElement22, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement22, 'data-codecept-selector-node', 'ToolbarSeparator'), _defineProperty(_React$createElement22, 'data-codecept-selector-file', 'list-pagination'), _React$createElement22)),
            this.renderCurrentPageInfo(),
            React.createElement(_Toolbar.ToolbarSeparator, (_React$createElement23 = { className: _listPagination2.default.ToolbarSeparator, 'data-codecept-selector-node': 'ToolbarSeparator',
                'data-codecept-selector-file': 'list-pagination'
            }, _defineProperty(_React$createElement23, 'data-codecept-selector-node', 'ToolbarSeparator'), _defineProperty(_React$createElement23, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement23, 'data-codecept-selector-node', 'ToolbarSeparator'), _defineProperty(_React$createElement23, 'data-codecept-selector-file', 'list-pagination'), _React$createElement23)),
            this.renderNav()
        )
    ) : React.createElement(_Toolbar.Toolbar, (_React$createElement26 = { className: _listPagination2.default.Toolbar, 'data-codecept-selector-node': 'Toolbar',
        'data-codecept-selector-file': 'list-pagination'
    }, _defineProperty(_React$createElement26, 'data-codecept-selector-node', 'Toolbar'), _defineProperty(_React$createElement26, 'data-codecept-selector-file', 'list-pagination'), _defineProperty(_React$createElement26, 'data-codecept-selector-node', 'Toolbar'), _defineProperty(_React$createElement26, 'data-codecept-selector-file', 'list-pagination'), _React$createElement26));
}), _defineProperty(_React$createClass, 'getNewState_requestToScrollTopOnLoadingFinished', function getNewState_requestToScrollTopOnLoadingFinished() {
    return {
        shouldScrollTop: true
    };
}), _defineProperty(_React$createClass, 'getNewState_requestingPageLoad', function getNewState_requestingPageLoad() {
    return {
        loading: true
    };
}), _React$createClass));

module.exports = ListPagination;