'use strict';

var request = require('@rubyapps/ruby-superagent');
var _ = require('lodash');

var rubyMiddlewareFields = require('@rubyapps/ruby-middleware-fields');

var TYPES = {
    OPEN_DIALOG: '@@ruby-app/confirm-dialog/OPEN_DIALOG',
    CLOSE_DIALOG: '@@ruby-app/confirm-dialog/CLOSE_DIALOG',

    SET_LOADING: '@@ruby-app/confirm-dialog/SET_LOADING',
    CLEAR_LOADING: '@@ruby-app/confirm-dialog/CLEAR_LOADING',

    SET_OPTIONS: '@@ruby-app/confirm-dialog/SET_OPTIONS',

    RESET_DIALOG: '@@ruby-app/confirm-dialog/RESET_DIALOG'
};

var generators = {
    //# options.parent_id
    openDialog: function openDialog(options) {
        return {
            type: TYPES.OPEN_DIALOG
        };
    }
    /**
     *
     *  options: {
     *      title: the title of the dialog
     *      content: the content / message of the dialog
     *      endpoint: the endpoint to submit the form to
     *      submitSuccessCallback: fn()
     *      submitFailCallback: fn()
     *  }
     *
     **/
    , openDialogWithOptions: function openDialogWithOptions(options) {
        var generators = this.getAction().generators;
        return function (dispatch, getState) {
            dispatch(generators.setOptions(options));
            dispatch(generators.openDialog());
        };
    },
    closeDialog: function closeDialog() {
        return {
            type: TYPES.CLOSE_DIALOG
        };
    }
    //# Deprecated: prefer closeAndReset();
    , closeAndResetDialog: function closeAndResetDialog() {
        return generators.closeAndReset();
    },
    closeAndReset: function closeAndReset() {
        return function (dispatch) {
            dispatch(generators.closeDialog());
            setTimeout(function () {
                dispatch(generators.resetDialog());
            }, 500);
        };
    },
    setOptions: function setOptions(options) {
        var selfModule = this;
        var generators = selfModule.getAction().generators;

        return {
            type: TYPES.SET_OPTIONS,
            payload: options
        };
    },
    setLoading: function setLoading() {
        return {
            type: TYPES.SET_LOADING
        };
    },
    clearLoading: function clearLoading() {
        return {
            type: TYPES.CLEAR_LOADING
        };
    },

    resetDialog: function resetDialog() {
        return {
            type: TYPES.RESET_DIALOG
        };
    },

    submit: function submit() {
        var selfModule = this;
        var selfSelectorObj = selfModule.getSelector();
        var generators = selfModule.getAction().generators;

        return function (dispatch, getState) {
            var applicationState = getState();
            var selfState = selfSelectorObj.default(applicationState);

            var errorContext = selfState.errorContext,
                endpoint = selfState.endpoint;

            var fields = selfState.fields;
            var data = selfState.data || _.reduce(fields, function (collector, fieldObj, fieldKey) {
                collector[fieldKey] = fieldObj.value;
                return collector;
            }, {});

            if (endpoint) {
                // TODO: show loader, don't allow actions for closing or submitting to continue
                dispatch(generators.setLoading());

                return request.post(endpoint).send(data).then(function onResult(response) {
                    dispatch(generators.clearLoading());

                    dispatch(generators.closeAndResetDialog());

                    selfState.submitSuccessCallback && selfState.submitSuccessCallback(response);
                }, function onError(err) {
                    dispatch(generators.clearLoading());
                    selfState.submitFailCallback && selfState.submitFailCallback(err);
                });
            } else {
                dispatch(generators.closeAndResetDialog());
                selfState.submitSuccessCallback && selfState.submitSuccessCallback();
            }
        };
    },
    submit_secondary: function submit_secondary() {
        var selfModule = this;
        var selfState = this.getState();

        return function (dispatch) {
            dispatch(generators.closeAndResetDialog());
            selfState.submitSuccessCallback_secondary && selfState.submitSuccessCallback_secondary();
        };
    }

};

module.exports = {
    TYPES: TYPES,
    generators: generators
};