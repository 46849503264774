'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _ = require('lodash');
var inflection = require('inflection');
var _slug = require('slug');

var pluralMap = {
    //# singular: plural
};

var Words = {
    capitalizeWords: function capitalize_words(word) {
        var words_ar = word.split(' ');
        for (var i = 0; i < words_ar.length; i++) {
            words_ar[i] = words_ar[i].charAt(0).toUpperCase() + words_ar[i].slice(1);
        }

        return words_ar.join(' ');
    } // capitalize_words(words) {

    , quote: function quote(words) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        if (!words) {
            return null;
        }
        var quotedWords = words.trim();
        var quoteChar = '"';

        if (options.quoteChar) {
            quoteChar = options.quoteChar;
        }

        if (quotedWords.indexOf(quoteChar) !== -1) {
            quoteChar = options.alternateQuoteChar || "'";
        }

        return '' + quoteChar + quotedWords + quoteChar;
    }
    // options is an object with keys:
    // mode - string, determines mapping mode to use, ex. 'rfc3986' or 'custom'
    //      - mode keys are overwritten if keys below are provided
    //      - default: 'pretty'
    // lower - boolean, determines whether or not to convert to lowercase
    //       - default: true
    // remove - regex, removes strings matched by regexp
    //        - default: /[.]/g
    // replacement - string, replacement for spaces
    //             - default: '-'
    // symbols - boolean, determines whether or not to KEEP symbols
    //         - default: true
    // charmap - object, maps special characters to strings
    // multicharmap - object, replaces multi-characters
    , slug: function slug(text, options) {

        // Handles issues with undefined/null text that slug module doesn't account for
        if (!text) {
            return text;
        }

        // use the RFC3986 standard by default
        _slug.defaults.mode = 'rfc3986';

        var defaultSlugOptions = {
            remove: /[.]/g
        };
        var slugOptions = _.assign({}, defaultSlugOptions, options);

        // if no options provided, stick with defaults
        var slugifiedText = _slug(text, slugOptions);

        return slugifiedText;
    },
    keyify: function keyify(text) {
        if (!text) {
            return text;
        }
        var keyifiedText = text.toString().toLowerCase().replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
        .replace(/-/g, '_'); // Replace all hyphens with underscores
        return keyifiedText;
    },

    ellipsify: function ellipsify(string) {
        var maxLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 50;
        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : { ellipsis: '...' };

        //# NOTE: only support browser for now
        if (!process.browser) {
            console.warn('[ruby-words.ellipsify] Only support browser for now');
            return string;
        }

        //# assume it's
        var dom_holder = document.createElement('div');
        dom_holder.innerHTML = string;

        var pureText = dom_holder.textContent;
        //# strip up to maxLength
        //# and prevent any partial word truncations

        var letterCount = 0;
        var truncArr = pureText.split(' ').reduce(function (collector, word) {
            letterCount += word.length;

            if (letterCount < maxLength) {
                collector.push(word);
            }

            return collector;
        }, []);

        return truncArr.join(' ') + (letterCount > maxLength ? options.ellipsis : '');
    },
    inflection: _.assign({}, inflection, {
        pluralize: function pluralize(text) {
            var plural = pluralMap[text];
            if (plural) {
                return inflection.pluralize(text, plural);
            }
            return inflection.pluralize.apply(null, arguments);
        },
        transform: function transform(str, arr) {
            //# overriding inflection.transform cause we need to call on the monkey patched methods
            var i = 0;
            var j = arr.length;
            var inflector = Words.inflection;

            for (; i < j; i++) {
                var method = arr[i];

                if (inflector.hasOwnProperty(method)) {
                    str = inflector[method](str);
                }
            }

            return str;
        }
    }),
    registerAdditionalPluralMap: function registerAdditionalPluralMap(additionalPluralMap) {
        _extends(pluralMap, additionalPluralMap);
    }
};

module.exports = Words;