'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var defaultState = {
    open: false,
    stateSnapshot: null,
    previewUrl: null,
    error: null //# error: {message: <string>}just like the nested fields
};

module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];
    var TYPES = this.getDependencies().selfAction.TYPES;
    var type = action.type,
        payload = action.payload;


    if (!state.hasOwnProperty('open')) {
        state = _extends({}, state, defaultState);
    }

    switch (type) {
        case TYPES.OPEN_DIALOG:
            return _extends({}, state, {
                open: true
            });
        case TYPES.CLOSE_DIALOG:
            return _extends({}, state, {
                open: false
            });
        case TYPES.RESET_DIALOG:
            return _extends({}, state, defaultState, {
                previewUrl: state.previewUrl
                //# keep previewUrl intact
            });

        case TYPES.SNAPSHOT_STATE:
            return _extends({}, state, {
                stateSnapshot: payload.state
            });

        case TYPES.SET_PREVIEW:
            return _extends({}, state, {
                previewUrl: payload.previewUrl
            });
        case TYPES.CLEAR_PREVIEW:
            return _extends({}, state, {
                previewUrl: null
            });

        default:
            return state;
    }
};