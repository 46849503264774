'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _fieldBase = require('@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule');

var _fieldBase2 = _interopRequireDefault(_fieldBase);

var _OpenGraph = require('./OpenGraph.cssModule');

var _OpenGraph2 = _interopRequireDefault(_OpenGraph);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var styles = _extends({}, _fieldBase2.default, _OpenGraph2.default);

var OpenGraph = _react2.default.createClass({
    displayName: 'OpenGraph',

    propTypes: {
        label: PropTypes.string,
        id: PropTypes.string,
        path: PropTypes.string,
        name: PropTypes.string,
        domain: PropTypes.string
    },
    render: function render() {
        var _React$createElement, _React$createElement2;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        return _react2.default.createElement(
            'div',
            (_React$createElement2 = { className: styles.wrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'OpenGraph'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'OpenGraph'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'OpenGraph'), _React$createElement2),
            _react2.default.createElement(
                'label',
                (_React$createElement = { className: styles.label, 'data-codecept-selector-node': 'label',
                    'data-codecept-selector-file': 'OpenGraph'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'OpenGraph'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'OpenGraph'), _React$createElement),
                this.props.label
            ),
            this.props.children
        );
    }
});

exports.default = OpenGraph;