'use strict';

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var ReactRouter = require('react-router');
var Route = ReactRouter.Route;
var RouteParser = require('route-parser');
var _ = require('lodash');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var RubyComponentHeader = require('@rubyapps/ruby-component-header');
var RubyComponentLoadingIndicator = require('@rubyapps/ruby-component-loading-indicator');
var RubyComponentNotifications = require('@rubyapps/ruby-component-notifications');

var RubyComponentCurrentUser = require('@rubyapps/ruby-component-current-user');
var RubyComponentRubyClientFK = require('@rubyapps/ruby-component-rubyclientfk');
var RubyComponentFrontendSettings = require('@rubyapps/ruby-component-frontend-settings');
var RubyComponentLoginPage = require('@rubyapps/ruby-component-login-page');
var RubyComponentForgotPasswordPage = require('@rubyapps/ruby-component-forgot-password-page');
var RubyComponentSecureLoginPage = require('@rubyapps/ruby-component-secure-login-page');
var RubyComponentConfirmDialog = require('@rubyapps/ruby-component-confirm-dialog');
var RubyComponentAlertDialog = require('@rubyapps/ruby-component-alert-dialog');

var CONSTANTS = require('../common/constants');
var routePath = CONSTANTS.ROUTE_PATH;
var componentName = CONSTANTS.COMPONENT_NAME;
var App = require('./reactComponents/App');

var editPageID = 'rubyComponentEditPage';
var RCRubyApp = RubyComponent.createClass({
    propTypes: {
        contentFormKeys: PropTypes.array,
        loginPageMode: PropTypes.bool
    },
    componentName: componentName,
    reducer: function reducer() {
        var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var action = arguments[1];

        return state;
    },
    getDefaultProps: function getDefaultProps() {
        return {
            path: '/',
            contentFormKeys: [],
            loginPageMode: false
        };
    },

    children: function children() {
        return [[//# Insert these RubyComponent at the front
        RubyComponent.createElement(RubyComponentLoadingIndicator), RubyComponent.createElement(RubyComponentCurrentUser), RubyComponent.createElement(RubyComponentRubyClientFK), RubyComponent.createElement(RubyComponentFrontendSettings), RubyComponent.createElement(RubyComponentLoginPage, {}, RubyComponent.createElement(require('@rubyapps/ruby-component-plugin-sso'))), RubyComponent.createElement(RubyComponentForgotPasswordPage), RubyComponent.createElement(RubyComponentSecureLoginPage)], [//# Insert these RubyComponents at the end
        RubyComponent.createElement(RubyComponentNotifications), RubyComponent.createElement(RubyComponentConfirmDialog), RubyComponent.createElement(RubyComponentAlertDialog)]];
    },
    getReactClass: function getReactClass() {
        return App;
    },
    getRouteElement: function getRouteElement() {
        var _React$createElement;

        var path = this.props.path;
        return React.createElement(Route, (_React$createElement = { name: 'app', component: this.getReactClass(), path: path, 'data-codecept-selector-node': 'Route',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Route'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Route'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement));
    },
    getUrlForComponent_fromModule_withParams: function getUrlForComponent_fromModule_withParams(compId, fromModule) {
        var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        var applicationState = this.getStore().getState();
        var globalRouteParams = applicationState.routeParams;

        var fromSelector = fromModule.getDefaultSelector();
        var fromState = fromSelector(applicationState);
        var fromRouteParams = _.get(fromState, 'routeParams');

        var forModule = this.findDescendentByID(compId);
        var forPath = forModule.getRoutePath();

        var routeParserForPath = new RouteParser(forPath);

        var reverseParams = _.assign({}, globalRouteParams, fromRouteParams, params);
        var cmsUrl = routeParserForPath.reverse(reverseParams);

        // routeParser.reverse will return false
        // when it fails to create the URL
        if (cmsUrl === false) {
            // Usually because it did not receive
            // all required variables for the URL
            throw "Failed to get URL for component.  " + "This usually happens when the path contains variables that " + "were not supplied via the params.\n\n" + "  Route path: " + forPath + "\n" + "  Params: " + JSON.stringify(reverseParams);
        }

        return cmsUrl;
    },
    CONSTANTS: CONSTANTS
});

module.exports = RCRubyApp;