"use strict";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

/* jshint -W138 */

module.exports = function (state, action) {
    var actionTypes = this.getAction().TYPES;
    var type = action.type,
        payload = action.payload;

    switch (type) {
        case actionTypes.SET_MOST_RECENTLY_MODIFIED_ITEM:
            return _extends({}, state, { mostRecentlyModifiedItem: payload.item ? payload.item : {} });
        default:
            return state;
    }
};