'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _rubyLogo = require('@rubyapps/ruby-assets/ruby-logo.svg');

var _rubyLogo2 = _interopRequireDefault(_rubyLogo);

var _announcement = require('material-ui/svg-icons/action/announcement');

var _announcement2 = _interopRequireDefault(_announcement);

var _NoResults = require('./NoResults.cssModule');

var _NoResults2 = _interopRequireDefault(_NoResults);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var NoResults = function (_React$Component) {
    _inherits(NoResults, _React$Component);

    function NoResults() {
        _classCallCheck(this, NoResults);

        return _possibleConstructorReturn(this, (NoResults.__proto__ || Object.getPrototypeOf(NoResults)).apply(this, arguments));
    }

    _createClass(NoResults, [{
        key: 'render',
        value: function render() {
            var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6;

            return _react2.default.createElement(
                'div',
                (_React$createElement6 = { className: _NoResults2.default.noResultsContent, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'NoResults'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'NoResults'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'NoResults'), _React$createElement6),
                _react2.default.createElement(
                    'div',
                    (_React$createElement3 = { className: _NoResults2.default.imageWrapper, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'NoResults'
                    }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'NoResults'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'NoResults'), _React$createElement3),
                    _react2.default.createElement(_announcement2.default, (_React$createElement = { className: _NoResults2.default.AnnouncementIcon, 'data-codecept-selector-node': 'AnnouncementIcon',
                        'data-codecept-selector-file': 'NoResults'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'AnnouncementIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'NoResults'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'AnnouncementIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'NoResults'), _React$createElement)),
                    _react2.default.createElement('img', (_React$createElement2 = {
                        className: _NoResults2.default.logo,
                        src: _rubyLogo2.default,
                        alt: 'Ruby',
                        'data-codecept-selector-node': 'img',
                        'data-codecept-selector-file': 'NoResults'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'img'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'NoResults'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'img'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'NoResults'), _React$createElement2))
                ),
                _react2.default.createElement(
                    'h4',
                    (_React$createElement4 = { className: _NoResults2.default.header, 'data-codecept-selector-node': 'h4',
                        'data-codecept-selector-file': 'NoResults'
                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'h4'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'NoResults'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'h4'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'NoResults'), _React$createElement4),
                    this.props.header
                ),
                _react2.default.createElement(
                    'p',
                    (_React$createElement5 = { className: _NoResults2.default.details, 'data-codecept-selector-node': 'p',
                        'data-codecept-selector-file': 'NoResults'
                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'NoResults'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'NoResults'), _React$createElement5),
                    this.props.details
                )
            );
        }
    }]);

    return NoResults;
}(_react2.default.Component);

exports.default = NoResults;