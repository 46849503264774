'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _RaisedButton = require('./RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _EnhancedButton = require('material-ui/internal/EnhancedButton');

var _EnhancedButton2 = _interopRequireDefault(_EnhancedButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

exports.default = _react2.default.createClass({
    displayName: 'RaisedButton',

    propTypes: _extends({
        htmlFor: _react2.default.PropTypes.string
    }, _EnhancedButton2.default.propTypes, _RaisedButton2.default.propTypes),
    render: function render() {
        var _extends2;

        return _react2.default.createElement(_RaisedButton2.default, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'RaisedButton',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    }
});