'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _Divider = require('material-ui/Divider');

var _Divider2 = _interopRequireDefault(_Divider);

var _selectedColumnsPropsHelper = require('../selectedColumnsPropsHelper');

var _selectedColumnsPropsHelper2 = _interopRequireDefault(_selectedColumnsPropsHelper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function partitionedContextMenuItemsFromUtilityBarActions(utilityBarActions) {
    var menuItems = utilityBarActions;

    var sectionNames = menuItems.reduce(function (result, menuItem) {
        var _menuItem$sectionName = menuItem.sectionName,
            sectionName = _menuItem$sectionName === undefined ? 'other' : _menuItem$sectionName;

        return _lodash2.default.includes(result, sectionName) ? result : [].concat(result, sectionName);
    }, []);

    var sectionedMenuItems = menuItems.reduce(function (result, menuItem) {
        var _menuItem$sectionName2 = menuItem.sectionName,
            sectionName = _menuItem$sectionName2 === undefined ? 'other' : _menuItem$sectionName2;

        return _extends(result, _defineProperty({}, sectionName, [].concat(result[sectionName] || [], menuItem)));
    }, {});

    return _lodash2.default.flatMap(sectionNames, function (sectionName) {
        var _React$createElement;

        var menuItemsInSection = sectionedMenuItems[sectionName];
        return [menuItemsInSection.map(function (item) {
            var _extends3;

            return _react2.default.createElement(_MenuItem2.default, _extends({}, item, (_extends3 = {
                'data-codecept-selector-node': 'MenuItem',
                'data-codecept-selector-file': 'indexConnector'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends3, 'data-codecept-selector-file', 'indexConnector'), _defineProperty(_extends3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends3, 'data-codecept-selector-file', 'indexConnector'), _extends3)));
        }), _react2.default.createElement(_Divider2.default, (_React$createElement = {
            'data-codecept-selector-node': 'Divider',
            'data-codecept-selector-file': 'indexConnector'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'indexConnector'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'indexConnector'), _React$createElement))];
    }).slice(0, -1);
}

function AdvancedSearchUtilityBarConnector(selfModule, getRubyComponentProps) {
    return function (UtilityBar) {

        var UtilityBar_propTypeKeys = Object.keys(UtilityBar.propTypes);
        return function render(griddleProps) {
            var _extends4;

            var rubyComponentProps = getRubyComponentProps();

            var griddleFilterProps = _lodash2.default.get(griddleProps, 'state.filter') || {};
            var griddleFilterTags = _lodash2.default.get(griddleFilterProps, 'filterTags', []);
            var griddleFilterQueryString = _lodash2.default.get(griddleFilterProps, 'queryString', '');

            var selectedColumnsProps = (0, _selectedColumnsPropsHelper2.default)(rubyComponentProps, griddleProps);

            var contextMenuItemsFromProps = function contextMenuItemsFromProps(props) {
                var _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6, _React$createElement7;

                var utilityBarActions = selfModule.getComposedUtilityBarActions_usingUtilityBarProps(props.griddleProps);

                var menuItems = partitionedContextMenuItemsFromUtilityBarActions(utilityBarActions);

                return [_react2.default.createElement(_MenuItem2.default, (_React$createElement2 = {
                    key: 'save-search',
                    primaryText: 'Save Search',
                    disabled: !props.hasSaveableSearchParams,
                    onTouchTap: props.delegateSaveSearch__dialogOpen,
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'indexConnector'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'indexConnector'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'indexConnector'), _React$createElement2)), _react2.default.createElement(_MenuItem2.default, (_React$createElement3 = {
                    key: 'reset-search',
                    primaryText: 'Reset Search',
                    disabled: !props.hasSaveableSearchParams,
                    onTouchTap: props.delegateResetSearch,
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'indexConnector'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'indexConnector'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'indexConnector'), _React$createElement3)), _react2.default.createElement(_MenuItem2.default, (_React$createElement4 = {
                    key: 'view-saved-searched',
                    primaryText: 'View Saved Searches',
                    onTouchTap: props.delegateViewSavedSearchLister,
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'indexConnector'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'indexConnector'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'indexConnector'), _React$createElement4)), _react2.default.createElement(_Divider2.default, (_React$createElement5 = { key: 'divider-0', 'data-codecept-selector-node': 'Divider',
                    'data-codecept-selector-file': 'indexConnector'
                }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'indexConnector'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'indexConnector'), _React$createElement5)), _react2.default.createElement(_MenuItem2.default, (_React$createElement6 = {
                    key: 'select-columns',
                    primaryText: 'Select Columns',
                    onTouchTap: props.delegateSelectColumnsButtonTap,
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'indexConnector'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'indexConnector'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'indexConnector'), _React$createElement6))].concat(menuItems.length ? _react2.default.createElement(_Divider2.default, (_React$createElement7 = { key: 'divider-1', 'data-codecept-selector-node': 'Divider',
                    'data-codecept-selector-file': 'indexConnector'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'indexConnector'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'indexConnector'), _React$createElement7)) : [], menuItems);
            };

            return _react2.default.createElement(UtilityBar, _extends({}, _lodash2.default.pick(selfModule.props, UtilityBar_propTypeKeys), _lodash2.default.pick(rubyComponentProps, UtilityBar_propTypeKeys), {
                savedSearchContext: selfModule.getSavedSearchContext(),
                filterTags: griddleFilterTags,
                queryString: griddleFilterQueryString,
                griddleProps: griddleProps,
                boundActions: rubyComponentProps.actions,
                boundDelegateActions: rubyComponentProps.delegateActions,
                export_context: selfModule.props.export_context,
                dialogFormJs: rubyComponentProps.dialogFormJs,
                selfModule: selfModule //# use sparingly. we do this only to get a ref to griddleProps
                , contextMenuItemsFromProps: contextMenuItemsFromProps
            }, selectedColumnsProps, (_extends4 = {
                'data-codecept-selector-node': 'UtilityBar',
                'data-codecept-selector-file': 'indexConnector'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'UtilityBar'), _defineProperty(_extends4, 'data-codecept-selector-file', 'indexConnector'), _defineProperty(_extends4, 'data-codecept-selector-node', 'UtilityBar'), _defineProperty(_extends4, 'data-codecept-selector-file', 'indexConnector'), _extends4)));
        };
    };
}

//# PROP TYPES
/*
rubyComponentProps
{
    searchableFields: PropTypes.arrayOf(PropTypes.object)
}
*/

exports.default = AdvancedSearchUtilityBarConnector;