'use strict';

var _reduxBatchedActions = require('redux-batched-actions');

//== The Middleware ========================================================//

var middlewareInitializer = function middlewareInitializer(store) {
    var selfModule = this;
    var selfAction = selfModule.getAction();
    var selfActionGenerators = selfAction.generators;

    var _selfModule$getDepend = selfModule.getDependencies(),
        currentUserSelector = _selfModule$getDepend.currentUserSelector;

    return function (next) {
        return function (action) {
            var nextRetVal = next(action); //# still allow @@router to continue propagation
            var subscribeToStore = selfModule.getStore().subscribe;

            switch (action.type) {
                case selfAction.TYPES.SET_ROUTE_PARAMS:
                    // NOTE: SET_ROUTE_PARAMS will only execute this middleware
                    //       if this component is currently active
                    _fetchLastModified_onActivate({
                        action: action,
                        currentUserSelector: currentUserSelector,
                        store: store,
                        selfActionGenerators: selfActionGenerators,
                        subscribeToStore: subscribeToStore
                    });
                    break;
                case _reduxBatchedActions.BATCH:
                    //# check if the batch action has SET_ROUTE_PARAMS
                    var foundSetRouteParamsAction = action.payload.filter(function (action) {
                        return action.type == selfAction.TYPES.SET_ROUTE_PARAMS;
                    })[0];

                    if (foundSetRouteParamsAction) {
                        _fetchLastModified_onActivate({
                            action: foundSetRouteParamsAction,
                            currentUserSelector: currentUserSelector,
                            store: store,
                            selfActionGenerators: selfActionGenerators,
                            subscribeToStore: subscribeToStore
                        });
                    }
                    break;
            }
            return nextRetVal;
        };
    };
};

module.exports = middlewareInitializer;

function _fetchLastModified_onActivate(params) {
    var action = params.action,
        currentUserSelector = params.currentUserSelector,
        store = params.store,
        selfActionGenerators = params.selfActionGenerators,
        subscribeToStore = params.subscribeToStore;

    // If we have a valid template keyword

    var templateKey = action.payload.template;
    if (!templateKey) {
        return;
    }

    store.dispatch(selfActionGenerators.retrieveAndSetMostRecentlyModifiedItem_forType(templateKey));

    return;
}