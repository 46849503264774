'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _SelectField = require('@rubyapps/ruby-material-ui/SelectField');

var _SelectField2 = _interopRequireDefault(_SelectField);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _Toggle = require('material-ui/Toggle');

var _Toggle2 = _interopRequireDefault(_Toggle);

var _PropTable = require('./PropTable');

var _PropTable2 = _interopRequireDefault(_PropTable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var _ = require('lodash');

var PropTypes = React.PropTypes;

var NAME_KEY = 'label';
var TYPE_KEY = 'componentName';

var rubyWords = require('@rubyapps/ruby-words');

var FieldCard = React.createClass({
    displayName: 'FieldCard',

    getDefaultProps: function getDefaultProps() {
        return {
            instance: { instanceProps: {} }
        };
    },
    _getInstanceProp: function _getInstanceProp(path, defaultValue) {
        if (path == undefined) {
            path = [];
        }

        var value = _.get(this, [].concat(['props', 'instance', 'instanceProps'], path), defaultValue);
        return value && value.toJSON ? value.toJSON() : value;
    },
    _properties_byType: function _properties_byType() {
        var permissions = _.get(this.props, ['all_permissions']) || [];
        var propertiesByType = this.props.selfModule.propertiesByType_withRubyPermissions(permissions);
        return propertiesByType;
    },
    _properties_byKey: function _properties_byKey() {
        var permissions = _.get(this.props, ['all_permissions']) || [];
        var propertiesByKey = this.props.selfModule.propertiesByKey_withRubyPermissions(permissions);

        return propertiesByKey;
    },
    _generateOnTextChange_forPropertyKey: function _generateOnTextChange_forPropertyKey(propertyKey) {
        var self = this;
        var constraints = _.get(this._properties_byKey()[propertyKey], 'constraints');

        return function (event) {
            self.props.set_property_toValue(propertyKey, event.target.value, constraints);
        };
    },
    _generateOnChange_forPropertySpec: function _generateOnChange_forPropertySpec(propertySpec) {
        var self = this;
        var constraints = _.get(propertySpec, 'constraints');

        return function (event, value) {
            var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
            var skipValidation = params.skipValidation;

            self.props.set_property_toValue(propertySpec.propKey, value, skipValidation ? null : constraints);
        };
    },
    _generateOnToggleChange_forPropertySpec: function _generateOnToggleChange_forPropertySpec(propertySpec) {
        var self = this;
        return function (event, toggled) {
            self.props.set_property_toValue(propertySpec.propKey, toggled);
        };
    },
    _generateOnToggleChange_forPropertyKey: function _generateOnToggleChange_forPropertyKey(propertyKey) {
        var self = this;
        return function (event, toggled) {
            self.props.set_property_toValue(propertyKey, toggled);
        };
    },
    _generateOnDropdownChange_forPropertyKey: function _generateOnDropdownChange_forPropertyKey(propertyKey) {
        var self = this;
        return function (event, index, value) {
            self.props.set_property_toValue(propertyKey, value);
        };
    },
    _generateOnRichTextChange_forPropertySpec: function _generateOnRichTextChange_forPropertySpec(propertySpec) {
        var self = this;
        var constraints = _.get(propertySpec, 'constraints');

        return function (event, editor) {
            var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
            var skipValidation = params.skipValidation;

            var updatedValue = editor.getContent();
            self.props.set_property_toValue(propertySpec.propKey, updatedValue, skipValidation ? null : constraints);
        };
    },
    resetProps_forComponentName: function resetProps_forComponentName(componentName) {
        var self = this;
        var currentProps = _.result(this, 'props.instance.instanceProps');
        var currentComponentName = _.get(currentProps, 'componentName.value');
        var currentPropKeys = Object.keys(currentProps);

        var propertiesByType = this.props.selfModule.propertiesWithoutPermissions_byType();

        var oldPropertySpecs = propertiesByType[currentComponentName];
        var newPropertySpecs = propertiesByType[componentName];
        var corePropertySpecs = _.values(this.props.selfModule.coreProperties_byInstanceType()["Field"]);

        var propertySpecsToKeep = [].concat(_.intersectionWith(newPropertySpecs, oldPropertySpecs, function (a, b) {
            return _.isEqual(_.omit(a, _.functions(a)), _.omit(b, _.functions(b)));
        }), corePropertySpecs);
        var propertyKeysToKeep = [].concat(propertySpecsToKeep.map(function (spec) {
            return spec.propKey;
        }), 'instanceType');

        var keysToClear = currentPropKeys.filter(function (key) {
            return !_.includes(propertyKeysToKeep, key);
        });

        keysToClear.forEach(function (propKey) {
            self.props.set_property_toValue(propKey, undefined);
        });
    },
    _generateOnDropdownChange_forPropertySpec: function _generateOnDropdownChange_forPropertySpec(propertySpec) {
        var self = this;
        var constraints = _.get(propertySpec, 'constraints');

        return function (event, value) {
            // set a copy of the value because sometimes the value is a modified verstion of the previous value,
            // and no difference is detected causing
            self.props.set_property_toValue(propertySpec.propKey, value, constraints);
        };
    },
    _generateOnMenuChange_forPropertySpec: function _generateOnMenuChange_forPropertySpec(propertySpec) {
        var self = this;
        return function (event, value) {
            // set a copy of the value because sometimes the value is a modified verstion of the previous value,
            // and no difference is detected causing
            self.props.set_property_toValue(propertySpec.propKey, value && value.slice(0) || []);
        };
    },
    _generateOnChange_ofType_forPropertySpec: function _generateOnChange_ofType_forPropertySpec(type, propertySpec) {
        var self = this;
        switch (type) {
            case 'number':
            case 'text':
            case 'textarea':
            case 'endpoint_builder':
                return self._generateOnChange_forPropertySpec(propertySpec);
            case 'richtext':
                return self._generateOnRichTextChange_forPropertySpec(propertySpec);
            case 'dropdown':
                return self._generateOnDropdownChange_forPropertySpec(propertySpec);
            case 'multiselect':
                return self._generateOnMenuChange_forPropertySpec(propertySpec);
            case 'toggle':
                return self._generateOnToggleChange_forPropertySpec(propertySpec);
            default:
                return function () {};
        }
    },
    render: function render() {
        var _extends2,
            _this = this,
            _React$createElement2,
            _React$createElement3,
            _React$createElement4,
            _React$createElement5,
            _React$createElement6,
            _React$createElement7,
            _React$createElement8,
            _React$createElement9,
            _React$createElement10;

        var self = this;
        var overrideLocked = this.props.overrideLocked;

        var isLocked = this._getInstanceProp(['_locked', 'value']) && !overrideLocked;
        var nameProps = this._getInstanceProp([NAME_KEY], {});
        if (_.isNil(nameProps.value)) {
            nameProps.value = '';
        }

        var fieldTypeDisplayText_byType_sorted = _.map(this.props.selfModule.fieldTypeDisplayText_byType(), function (label, componentName) {
            return { componentName: componentName, label: label };
        }).sort(function (a, b) {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });

        return React.createElement(
            'div',
            (_React$createElement10 = { style: { display: 'flex' }, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Field'
            }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'Field'), _React$createElement10),
            React.createElement(
                'div',
                (_React$createElement6 = {
                    style: { flex: 1 },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Field'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Field'), _React$createElement6),
                React.createElement(
                    'div',
                    (_React$createElement5 = {
                        style: { maxWidth: "300px" },
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'Field'
                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Field'), _React$createElement5),
                    React.createElement(_TextField2.default, _extends({
                        key: NAME_KEY,
                        name: NAME_KEY,
                        floatingLabelText: 'Field Label *',
                        fullWidth: true,
                        onChange: this._generateOnTextChange_forPropertyKey(NAME_KEY),
                        disabled: isLocked
                    }, nameProps, (_extends2 = {
                        'data-codecept-selector-node': 'TextField',
                        'data-codecept-selector-file': 'Field'
                    }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Field'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Field'), _extends2))),
                    React.createElement(
                        _SelectField2.default,
                        (_React$createElement2 = {
                            floatingLabelText: 'Type',
                            autoWidth: true,
                            fullWidth: true,
                            onChange: function onChange(event, index, value) {
                                self._generateOnDropdownChange_forPropertyKey(TYPE_KEY).apply(this, arguments);
                                self.resetProps_forComponentName(value);
                            },
                            disabled: isLocked,
                            value: this._getInstanceProp([TYPE_KEY, 'value']),
                            'data-codecept-selector-node': 'SelectField',
                            'data-codecept-selector-file': 'Field'
                        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'SelectField'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'SelectField'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Field'), _React$createElement2),
                        _.map(fieldTypeDisplayText_byType_sorted, function (spec, index) {
                            var _React$createElement;

                            return React.createElement(_MenuItem2.default, (_React$createElement = {
                                key: TYPE_KEY + '-' + index,
                                value: spec.componentName,
                                insetChildren: true,
                                checked: _.isEqual(spec.componentName, _this._getInstanceProp([TYPE_KEY, 'value'])),
                                primaryText: spec.label,
                                'data-codecept-selector-node': 'MenuItem',
                                'data-codecept-selector-file': 'Field'
                            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Field'), _React$createElement));
                        })
                    ),
                    React.createElement(_Toggle2.default, (_React$createElement3 = {
                        key: 'fieldRequiredToggle',
                        toggled: this._getInstanceProp(['constraint_required', 'value']) || false,
                        onToggle: this._generateOnToggleChange_forPropertyKey('constraint_required'),
                        label: 'Field Required?',
                        disabled: isLocked,
                        'data-codecept-selector-node': 'Toggle',
                        'data-codecept-selector-file': 'Field'
                    }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Field'), _React$createElement3)),
                    React.createElement(_Toggle2.default, (_React$createElement4 = {
                        key: 'fieldLockedToggle',
                        toggled: this._getInstanceProp(['_locked', 'value']) || false,
                        onToggle: this._generateOnToggleChange_forPropertyKey('_locked'),
                        label: 'Lock field from being edited?',
                        disabled: overrideLocked ? false : true,
                        'data-codecept-selector-node': 'Toggle',
                        'data-codecept-selector-file': 'Field'
                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Field'), _React$createElement4))
                )
            ),
            React.createElement(
                'div',
                (_React$createElement9 = { style: { flex: 1 }, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Field'
                }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Field'), _React$createElement9),
                React.createElement(
                    'div',
                    (_React$createElement8 = {
                        style: {
                            marginTop: 20,
                            height: '280px'
                        },
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'Field'
                    }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Field'), _React$createElement8),
                    React.createElement(_PropTable2.default, (_React$createElement7 = {
                        disabled: isLocked,
                        contentTemplateOptions: this.props.contentTemplateOptions,
                        rubyClientInfo: this.props.rubyClientInfo,
                        rowProps: (self._properties_byType()[this._getInstanceProp([TYPE_KEY, 'value'])] || []).map(function (propertySpec) {
                            var propKey = propertySpec.propKey;
                            var type = propertySpec.type;
                            var propValue = _this._getInstanceProp([propKey, 'value']);
                            if (propValue == null && propertySpec.hasOwnProperty('defaultValue')) {
                                propValue = propertySpec.defaultValue;
                            }

                            var isAutoPopulated = false;
                            if (propValue == null && propertySpec.defaultToProp) {
                                var defaultToProp = propertySpec.defaultToProp;
                                var referencedPropertySpec = self._properties_byKey()[defaultToProp];
                                propValue = _this._getInstanceProp([referencedPropertySpec.propKey, 'value']) || referencedPropertySpec.defaultValue;
                                propValue = rubyWords.keyify(propValue);
                                isAutoPopulated = true;
                            }

                            return _extends({}, propertySpec, {
                                fieldProps: {
                                    errorText: _this._getInstanceProp([propKey, 'errorText']),
                                    value: propValue,
                                    isAutoPopulated: isAutoPopulated,

                                    onChange: _this._generateOnChange_ofType_forPropertySpec(type, propertySpec)
                                }
                            });
                        }),
                        'data-codecept-selector-node': 'PropTable',
                        'data-codecept-selector-file': 'Field'
                    }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'PropTable'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Field'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'PropTable'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Field'), _React$createElement7))
                )
            )
        );
    }
});
exports.default = FieldCard;