'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

require('./HamburgerIcon.css');

var HamburgerIcon = function HamburgerIcon(props) {
    var _React$createElement, _React$createElement2, _React$createElement3;

    return _react2.default.createElement(
        'div',
        (_React$createElement3 = { className: 'hamburger hamburger--slider ' + (props.isActive ? 'is-active' : '') + ' ' + (props.className ? props.className : ''), 'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'HamburgerIcon'
        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'HamburgerIcon'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'HamburgerIcon'), _React$createElement3),
        _react2.default.createElement(
            'div',
            (_React$createElement2 = { className: 'hamburger-box', 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'HamburgerIcon'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'HamburgerIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'HamburgerIcon'), _React$createElement2),
            _react2.default.createElement('div', (_React$createElement = { className: 'hamburger-inner', 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'HamburgerIcon'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'HamburgerIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'HamburgerIcon'), _React$createElement))
        )
    );
};

exports.default = HamburgerIcon;