'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _Button = require('./Button');

var _Button2 = _interopRequireDefault(_Button);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector;

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state) || {};

        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps ? selfModule.fieldProps_fromState_andOwnProps(state, ownProps) : {};

        //# NOTE: checking for the existence of these methods because the FilePicker component also uses this Connector,
        //# but it doesn't have some of the mixins expected
        var formattedError = selfModule.formattedErrorFromState ? selfModule.formattedErrorFromState(state) : {};

        var fieldKey = mixinFieldProps.fieldKey;


        var props = selfModule.getProps(true);

        var _selfModule$getDepend2 = selfModule.getDependencies(),
            actionForButton = _selfModule$getDepend2.actionForButton; //# calling it here because the field-sitemap is doing a hacky thing
        //# to override the actionForButton
        //# which we need to honor for now


        return _extends({
            handler: selfModule.props.handler,
            delegateActions: (0, _redux.bindActionCreators)({ actionForButton: actionForButton }, selfModule.getStore().dispatch)
        }, ownProps, mixinFieldProps, props, _lodash2.default.pick(_lodash2.default.get(selfState, ['fields', fieldKey]), ['value']), formattedError, selfState);
    }

    function mapDispatchToProps(dispatch /*, ownProps*/) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Button2.default);
}