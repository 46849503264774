'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconMenu = require('material-ui/IconMenu');

var _IconMenu2 = _interopRequireDefault(_IconMenu);

var _IconButton = require('material-ui/IconButton/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _moreVert = require('material-ui/svg-icons/navigation/more-vert');

var _moreVert2 = _interopRequireDefault(_moreVert);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var ContextMenu = _react2.default.createClass({
    displayName: 'ContextMenu',

    propTypes: {
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        return _react2.default.createElement(
            _IconMenu2.default,
            (_React$createElement3 = {
                iconButtonElement: _react2.default.createElement(
                    _IconButton2.default,
                    (_React$createElement2 = { tooltip: 'More Actions', 'data-codecept-selector-node': 'IconButton',
                        'data-codecept-selector-file': 'ContextMenu'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ContextMenu'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ContextMenu'), _React$createElement2),
                    _react2.default.createElement(_moreVert2.default, (_React$createElement = {
                        'data-codecept-selector-node': 'MoreVertIcon',
                        'data-codecept-selector-file': 'ContextMenu'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ContextMenu'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ContextMenu'), _React$createElement))
                ),
                anchorOrigin: { horizontal: 'right', vertical: 'top' },
                targetOrigin: { horizontal: 'right', vertical: 'top' },
                style: {
                    marginRight: -20,
                    marginLeft: -20
                },
                'data-codecept-selector-node': 'IconMenu',
                'data-codecept-selector-file': 'ContextMenu'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ContextMenu'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ContextMenu'), _React$createElement3),
            this.props.children
        );
    }
});

exports.default = ContextMenu;