'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _DropdownConnector = require('./reactComponents/DropdownConnector');

var _DropdownConnector2 = _interopRequireDefault(_DropdownConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var fieldRemoteOptionsMixin = require('@rubyapps/ruby-component-mixin-field-remote-options');

var RubyComponentCurrentUser__CONSTANTS = require('@rubyapps/ruby-component-current-user/src/common/constants');

var RCDropdown = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin, fieldRemoteOptionsMixin],
    propTypes: {
        //, label: PropTypes.string
        //, key: PropTypes.string
        //, constraints: PropTypes.object
        autoCompleteThreshold: PropTypes.number,
        autoWidth: PropTypes.bool
        //# autoWidth: true == fullWidth: false
        //# autoWidth: false == fullWidth: true
        //# BUT you can override the width if necessary
        , count: PropTypes.number //# the count param for url
        , retainPropsOnReset: PropTypes.arrayOf(PropTypes.string),
        filterLocally: PropTypes.bool //# this assumes all options have been retrieved. This is to work around endpoints that don't support `search_input`
        //# data_type: 'object' //# NOTE: currently only works one-way. if you tried hydrating from object, it won't work

    },
    componentName: componentName,

    getDefaultProps: function getDefaultProps(props) {
        var count = props.count || 50;

        return {
            autoCompleteThreshold: 10,
            autoWidth: false,
            count: count //# set this to null or Infinity if you want all results
            , currentUserID: RubyComponentCurrentUser__CONSTANTS.COMPONENT_NAME,
            preloadOptions: true,
            retainPropsOnReset: ['requestedOptionsUrl', 'props', 'refreshRequestTimestamp', 'requestedTimestamp'],
            preloadOptionsQuery: {
                search_input: '',
                count: count
            }
        };
    },
    getInitialState: function getInitialState() {
        var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        return _extends({}, fieldValidationMixin.getInitialState.apply(this, arguments), _lodash2.default.pick(state, this.props.retainPropsOnReset));
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getSelfStateSelector();

        var currentUser = root.findDescendentByID(this.props.currentUserID);
        var currentUserSelector = currentUser.getSelfStateSelector();

        return {
            selfSelector: selfSelector,
            currentUserSelector: currentUserSelector
        };
    },
    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector,
            currentUserSelector = _getDependencies.currentUserSelector;

        return {
            self: selfSelector(state),
            user: currentUserSelector(state),
            fieldProps: this.fieldProps_fromState_andOwnProps(state, this.props),
            routing: state.routing //# need to update based on routing changes
        };
    },
    getReactClass: function getReactClass() {
        return _DropdownConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var DropdownComponent = this.getReactClass();

        return React.createElement(DropdownComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'DropdownComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'DropdownComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'DropdownComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

    //# mixin overrides
    , formValueFromFieldValue_forKey: function formValueFromFieldValue_forKey(value, key) {
        var data_type = this.props.data_type;


        var dataType_isObject = data_type == 'object';
        var dataType_isObjectArr = _lodash2.default.isArray(data_type) && _lodash2.default.first(data_type) == 'object';

        if (dataType_isObject || dataType_isObjectArr) {
            var optionsFromValue = this._getOptionsMatchingOptionValue_raw(value);
            if (dataType_isObject) {
                return _lodash2.default.first(optionsFromValue);
            }

            return optionsFromValue;
        }

        //# don't allow empty string
        if (_lodash2.default.trim(value) == '') {
            return null;
        } else {
            return baseFieldMixin.formValueFromFieldValue_forKey.apply(this, arguments);
        }
    }
});

module.exports = RCDropdown;