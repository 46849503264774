"use strict";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

module.exports = function (state, action) {
    var _getAction = this.getAction(),
        TYPES = _getAction.TYPES;

    var type = action.type,
        payload = action.payload;


    if (!state) {
        state = this.getInitialState();
    }

    switch (type) {
        case TYPES.SET_VISIBLE:
            return _extends({}, state, {
                isVisible: true //# use a more generic property so we can leverage mixin to handle children
                , isVisibleLatched: true
            });
        case TYPES.UNSET_VISIBLE:
            return _extends({}, state, {
                isVisible: false
            });
        case TYPES.RESET_STORE:
            var activeState = this.getState();
            var initialState = this.getInitialState(activeState);
            var resetState = _extends({}, state, initialState);

            return resetState;
        default:
            return state;
    }
};