'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

//import styles from './utility-bar.cssModule';

exports.default = function (UtilityBar) {
    return function (_React$Component) {
        _inherits(_class2, _React$Component);

        function _class2() {
            var _ref;

            var _temp, _this, _ret;

            _classCallCheck(this, _class2);

            for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
                args[_key] = arguments[_key];
            }

            return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = _class2.__proto__ || Object.getPrototypeOf(_class2)).call.apply(_ref, [this].concat(args))), _this), _this._setUtilityBarProperties = function (utilityBarProperties) {
                if (_this.props.updateGriddleUtilityBarProperties) {
                    _this.props.updateGriddleUtilityBarProperties(utilityBarProperties);
                }
            }, _temp), _possibleConstructorReturn(_this, _ret);
        }

        _createClass(_class2, [{
            key: 'render',
            value: function render() {
                var _extends2;

                var Utility = this.props.utilityRenderer;
                var _props = this.props,
                    plugins = _props.plugins,
                    pluginsSettings = _props.pluginsSettings,
                    dispatch = _props.dispatch;

                var otherProps = _lodash2.default.omit(this.props, ['plugins', 'pluginsSettings', 'dispatch']);
                var events = this.getEvents();

                pluginsSettings = pluginsSettings || {};

                //# NOTE: do not set style on div because we can't override it
                return _react2.default.createElement(Utility, _extends({}, pluginsSettings, otherProps, (_extends2 = {
                    state: this.props.state,
                    dispatch: dispatch,
                    plugins: plugins,
                    events: events,
                    utilityBarProperties: this.props.utilityBarProperties,
                    'data-codecept-selector-node': 'Utility',
                    'data-codecept-selector-file': 'utility-bar'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Utility'), _defineProperty(_extends2, 'data-codecept-selector-file', 'utility-bar'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Utility'), _defineProperty(_extends2, 'data-codecept-selector-file', 'utility-bar'), _extends2)));
            }
        }, {
            key: 'getEvents',
            value: function getEvents() {
                return _extends({}, this.props.events, { setUtilityBarProperties: this._setUtilityBarProperties
                });
            }
        }]);

        return _class2;
    }(_react2.default.Component);
};