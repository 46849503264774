'use strict';

module.exports = {
    COMPONENT_NAME: 'rubyComponentFieldRepeater',
    OMIT_OPTION_PROPS_FOR_FORM: ['value']
    //# when using options to generate the child form value
    //# we need to omit some props like `value` because it's not valid 
    //# for form generation as value
    //# this was only noticed after the use of mixin-field-dynamic
    //# because we defer to using value
};