'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var styles = process.browser ? require('./ScheduledStatus.cssModule').default : {};

var ScheduledStatus = _react2.default.createClass({
    displayName: 'ScheduledStatus',

    propTypes: {
        label: PropTypes.string,
        id: PropTypes.string,
        value: PropTypes.shape({
            status: PropTypes.string,
            start_timestamp: PropTypes.shape({
                datetime: PropTypes.instanceOf(Date),
                zoneName: PropTypes.string
            }),
            end_timestamp: PropTypes.shape({
                datetime: PropTypes.instanceOf(Date),
                zoneName: PropTypes.string
            })
        })
    },
    render: function render() {
        var _React$createElement;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        return _react2.default.createElement(
            'div',
            (_React$createElement = {
                id: this.props.id,
                className: styles.wrapper,
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'ScheduledStatus'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ScheduledStatus'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ScheduledStatus'), _React$createElement),
            this.props.children
        );
    }
});

exports.default = ScheduledStatus;