'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _language = require('material-ui/svg-icons/action/language');

var _language2 = _interopRequireDefault(_language);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

//# icons


var LanguageIndicator = _react2.default.createClass({
    displayName: 'LanguageIndicator',

    propTypes: {
        defaultNamespace: PropTypes.string,
        value: PropTypes.array
    },
    getDefaultProps: function getDefaultProps() {
        return {
            styles: {
                root: {},
                icon: {}
            }
        };
    },
    render: function render() {
        var _React$createElement, _React$createElement2;

        var isDefaultSelected = this.isDefaultSelected();

        var mergedRootStyle = _extends({
            padding: '0 10px',
            display: 'inline-block'

        }, this.props.styles.root);

        var mergedIconStyle = _extends({
            top: 5,
            position: 'relative'
        }, this.props.styles.icon);

        return _react2.default.createElement(
            'span',
            (_React$createElement2 = {
                style: mergedRootStyle,
                'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'LanguageIndicator'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'LanguageIndicator'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'LanguageIndicator'), _React$createElement2),
            !isDefaultSelected ? _react2.default.createElement(_language2.default, (_React$createElement = {
                color: '#f5b328',
                style: mergedIconStyle,
                'data-codecept-selector-node': 'LanguageIcon',
                'data-codecept-selector-file': 'LanguageIndicator'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'LanguageIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'LanguageIndicator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'LanguageIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'LanguageIndicator'), _React$createElement)) : null
        );
    }
    //# utilities
    , isDefaultSelected: function isDefaultSelected() {
        var _props = this.props,
            defaultNamespace = _props.defaultNamespace,
            value = _props.value;


        return value.filter(function (singleValue) {
            return singleValue == defaultNamespace;
        }).length > 0;
    }
});

exports.default = LanguageIndicator;