'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _customComponent_styles = require('./customComponent_styles.cssModule');

var _customComponent_styles2 = _interopRequireDefault(_customComponent_styles);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var _ = require('lodash');

//# IDEA: add actionsList support to all cells
module.exports = function RubyActionsGenerator(options) {

    var actionsList = _.get(options, 'actionsList');
    var style = _.get(options, 'style');

    var RubyActions = React.createClass({
        displayName: 'RubyActions',

        render: function render() {
            var _this = this,
                _React$createElement,
                _extends2;

            return React.createElement(
                'div',
                _extends({
                    className: 'visibleWhenRowHovered'
                }, { style: style }, (_extends2 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'RubyActionsGenerator'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'RubyActionsGenerator'), _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'RubyActionsGenerator'), _extends2)),
                React.createElement(
                    'div',
                    (_React$createElement = { className: _customComponent_styles2.default.RubyActions, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'RubyActionsGenerator'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'RubyActionsGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'RubyActionsGenerator'), _React$createElement),
                    _.map(actionsList, function (actionItem) {
                        return actionItem.render.call(_this);
                    })
                )
            );
        }
    });

    return RubyActions;
};