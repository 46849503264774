'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var action = require('./action');
var reducer = require('./reducer');
var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;
var AlterTemplateDialogConnector = require('./reactComponents/AlterTemplateDialogConnector');

var RubyComponentForms = require('@rubyapps/ruby-component-forms');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var RubyComponentFrontendSettings = require('@rubyapps/ruby-component-frontend-settings');
var RubyComponentRubyClientFK = require('@rubyapps/ruby-component-rubyclientfk');

var rubyMiddlewareFields = require('@rubyapps/ruby-middleware-fields');
var fieldValidationsMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var dialogMixin = require('@rubyapps/ruby-component-mixin-dialog');

var RCAlterTemplateDialog = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationsMixin, dialogMixin],
    propTypes: {
        delegateActions: PropTypes.object,
        frontendSettingsID: PropTypes.string,
        editPageID: PropTypes.string,
        reactProps: PropTypes.object,
        forms: PropTypes.object
    },
    componentName: componentName,
    CONSTANTS: CONSTANTS,
    action: action,
    reducer: reducer,
    selector: function selector() {
        var selfModule = this;
        var selfSelector = selfModule.getDefaultSelector();

        return RubyComponent.composeSelectors(selfSelector, rubyMiddlewareFields.selector);
    },
    dependencies: function dependencies() {
        //# declare dependencies here so it's easy to find
        var rootComponent = this.getRoot();

        var selfSelector = this.getSelfStateSelector();

        var frontendSettingsID = this.props.frontendSettingsID || RubyComponentFrontendSettings.CONSTANTS.COMPONENT_NAME;
        var frontendSettingsComponent = rootComponent.findDescendentByID(frontendSettingsID);
        var frontendSettingsSelector = frontendSettingsComponent.getDefaultSelector();

        var formsID = this.props.formsID || RubyComponentForms.CONSTANTS.COMPONENT_NAME;
        var forms = rootComponent.findDescendentByID(formsID);
        var formsSelector = forms.getDefaultSelector();

        var editPageID = this.props.editPageID || 'rubyComponentEditPage';
        var editPageComponent = rootComponent.findDescendentByID(editPageID);

        var rubyClientFKID = this.props.rubyClientFKID || RubyComponentRubyClientFK.CONSTANTS.COMPONENT_NAME;
        var rubyClientFKComponent = rootComponent.findDescendentByID(rubyClientFKID);

        return {
            selfSelector: selfSelector,
            frontendSettingsSelector: frontendSettingsSelector,
            editPageComponent: editPageComponent,
            rubyClientFKSelector: rubyClientFKComponent.getDefaultSelector(),
            formsSelector: formsSelector
        };
    },

    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector,
            frontendSettingsSelector = _getDependencies.frontendSettingsSelector,
            formsSelector = _getDependencies.formsSelector;

        return {
            self: selfSelector(state),
            frontendSettings: frontendSettingsSelector(state),
            forms: formsSelector(state)
        };
    },
    getReactClass: function getReactClass() {
        var AlterTemplateDialogComponent = AlterTemplateDialogConnector.apply(this);
        return AlterTemplateDialogComponent;
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var AlterTemplateDialogComponent = this.getReactClass();
        var reactProps = this.props.reactProps;
        //# perform connection
        //# need to access the ruby_client_fk
        //# action to open the compare dialog should give the templatekey
        return React.createElement(AlterTemplateDialogComponent, _extends({}, reactProps, (_extends2 = {
            'data-codecept-selector-node': 'AlterTemplateDialogComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'AlterTemplateDialogComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'AlterTemplateDialogComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    }
});

module.exports = RCAlterTemplateDialog;