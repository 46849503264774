"use strict";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

/* jshint -W138 */

var defaultState = {
    lastUpdatedTimestamp: new Date()
};

module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];

    var _getAction = this.getAction(),
        TYPES = _getAction.TYPES;

    switch (action.type) {
        case TYPES.UPDATE__LAST_UPDATED_TIMESTAMP:
            return _extends({}, state, action.payload);
        default:
            return state;
    }
};