'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function ancestorKeysForKey_inPermissionItemsByKey(key, permissionItemsByKey) {
    var ancestorKeys = [];
    var currentParentKey = permissionItemsByKey[key].parentKey;
    while (currentParentKey && currentParentKey != 'root') {
        //# root isn't toggle
        ancestorKeys.push(currentParentKey);
        currentParentKey = permissionItemsByKey[currentParentKey].parentKey;
    }

    return ancestorKeys;
}

function toggledStateForParentPermItem_usingToggledByPermId(parentPermItem, toggledByPermId) {
    var siblingKeys = parentPermItem.children;
    var parentToggledState = siblingKeys.reduce(function (collector, siblingKey) {
        return collector || toggledByPermId[siblingKey];
    }, false);

    return parentToggledState;
}

module.exports = function () {
    var id = this.getID();
    var TYPES = {
        SET_PERMISSION_ITEMS: '@@ruby-app/' + id + '/SET_PERMISSION_ITEMS',
        SET_ROOT_PERMISSION_ITEM_KEY: '@@ruby-app/' + id + '/SET_ROOT_PERMISSION_ITEM_KEY',
        CUSTOM_RESET_STATE: '@@ruby-app/' + id + '/CUSTOM_RESET_STATE'
    };

    var generators = {
        setPermissionItems: function setPermissionItems(items) {
            return {
                type: TYPES.SET_PERMISSION_ITEMS,
                payload: {
                    items: items
                }
            };
        },
        setRootPermissionItemKey: function setRootPermissionItemKey(key) {
            return {
                type: TYPES.SET_ROOT_PERMISSION_ITEM_KEY,
                payload: {
                    key: key
                }
            };
        },
        resetStore: function resetStore() {
            return {
                type: TYPES.CUSTOM_RESET_STATE
            };
        },

        toggleValues: function toggleValues(values, toggled) {
            var actions = this.getAction().generators;
            var selfState = this.getState();
            var permissionItemsByKey = selfState.permissionItemsByKey;

            var fieldValue = this.getFieldValue();

            var _ref = fieldValue || {},
                toggledByPermId = _ref.toggledByPermId;

            var toggled_toggledByPermId = values.reduce(function (collector, permId) {
                collector[permId] = toggled;
                return collector;
            }, _extends({}, toggledByPermId));

            //# determine if the ancestors need to be toggled as well by looking at the value's siblings
            //# assume that the first value in values is the root key being toggled
            var primaryKey = _lodash2.default.first(values);
            var primaryPermItem = permissionItemsByKey[primaryKey];

            if (primaryPermItem.parentKey) {
                var ancestorKeys = ancestorKeysForKey_inPermissionItemsByKey(primaryKey, permissionItemsByKey);

                ancestorKeys.forEach(function (ancestorKey) {
                    //# NOTE: this is not functional because the state of the ancestors 
                    //# progressively depends on the previous ancestor calculations
                    //# NOTE: order of ancestorKeys matter as we want to start from the lowest and walk up the tree
                    //# TODO: for each parent, need to mutate toggled_toggledByPermId
                    var ancestorPermItem = permissionItemsByKey[ancestorKey];
                    var ancestorToggledState = toggledStateForParentPermItem_usingToggledByPermId(ancestorPermItem, toggled_toggledByPermId);

                    toggled_toggledByPermId[ancestorKey] = ancestorToggledState;
                });
            }

            var newFieldValue = _extends({}, fieldValue, {
                toggledByPermId: toggled_toggledByPermId
            });

            return actions.setFieldValueByKey(newFieldValue, this.props.key);
        },

        setIdsForPermKey: function setIdsForPermKey(ids, permKey) {
            var actions = this.getAction().generators;
            var fieldValue = this.getFieldValue();
            var idsByPermId = fieldValue.idsByPermId;


            var newFieldValue = _extends({}, fieldValue, {
                idsByPermId: _extends({}, idsByPermId, _defineProperty({}, permKey, ids))
            });

            return actions.setFieldValueByKey(newFieldValue, this.props.key);
        }
    };

    return {
        TYPES: TYPES,
        generators: generators
    };
};