'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _expandMore = require('material-ui/svg-icons/navigation/expand-more');

var _expandMore2 = _interopRequireDefault(_expandMore);

var _expandLess = require('material-ui/svg-icons/navigation/expand-less');

var _expandLess2 = _interopRequireDefault(_expandLess);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Hammer = require('react-hammerjs');

function getStyles() {
  return {
    root: {
      top: 0,
      bottom: 0,
      right: 4,
      margin: 'auto',
      position: 'absolute'
    }
  };
}

var CardExpandable = (_temp = _class = function (_Component) {
  _inherits(CardExpandable, _Component);

  function CardExpandable() {
    _classCallCheck(this, CardExpandable);

    return _possibleConstructorReturn(this, (CardExpandable.__proto__ || Object.getPrototypeOf(CardExpandable)).apply(this, arguments));
  }

  _createClass(CardExpandable, [{
    key: 'render',
    value: function render() {
      var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4;

      var styles = getStyles(this.props, this.context);

      return _react2.default.createElement(
        Hammer,
        (_React$createElement4 = { onPress: this.props.onPress, 'data-codecept-selector-node': 'Hammer',
          'data-codecept-selector-file': 'CardExpandable'
        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'Hammer'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'CardExpandable'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'Hammer'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'CardExpandable'), _React$createElement4),
        _react2.default.createElement(
          _IconButton2.default,
          (_React$createElement3 = {
            style: _extends(styles.root, this.props.style),
            tooltip: 'Hold to collapse/expand all',
            tooltipPosition: 'top-left',
            tooltipStyles: {
              top: 6,
              right: -6
            },
            'data-codecept-selector-node': 'IconButton',
            'data-codecept-selector-file': 'CardExpandable'
          }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'CardExpandable'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'CardExpandable'), _React$createElement3),
          this.props.expanded ? _react2.default.createElement(_expandLess2.default, (_React$createElement = {
            'data-codecept-selector-node': 'ExpandLessIcon',
            'data-codecept-selector-file': 'CardExpandable'
          }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ExpandLessIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'CardExpandable'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ExpandLessIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'CardExpandable'), _React$createElement)) : _react2.default.createElement(_expandMore2.default, (_React$createElement2 = {
            'data-codecept-selector-node': 'ExpandMoreIcon',
            'data-codecept-selector-file': 'CardExpandable'
          }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'ExpandMoreIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'CardExpandable'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'ExpandMoreIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'CardExpandable'), _React$createElement2))
        )
      );
    }
  }]);

  return CardExpandable;
}(_react.Component), _class.propTypes = {
  expanded: _react.PropTypes.bool,
  onExpanding: _react.PropTypes.func.isRequired,
  onPress: _react.PropTypes.func,
  style: _react.PropTypes.object
}, _class.contextTypes = {
  muiTheme: _react.PropTypes.object.isRequired
}, _temp);
exports.default = CardExpandable;