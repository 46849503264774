'use strict';

module.exports = {
    COMPONENT_NAME: 'rubyComponentStructuredDataListPage',
    REDUCER_KEYS_TO_QUERY_PARAMS: {
        sortColumn: 'sname',
        sortDirection: 'sdir',
        currentPage: 'p',
        resultsPerPage: 'psize',
        searchTerm: 'search'
    },
    CMS_URL_PREFIX: '/cms' //# TODO: DEPRECATE
    , FILTER_TYPES: {
        NAV: 'NAV',
        CONTENT_FILTER: 'CONTENT.FILTER'
    },
    API_KEY: 'content'
};