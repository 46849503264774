'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rubyWords = require('@rubyapps/ruby-words');
var Route = require('route-parser');
var queryString = require('query-string');

var path = require('path');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

var optionsUrl_routeTemplate = '/ruby/api/:version/:model/(:template/):endpointMethod(?*queryString)';
//# made the routeTemplate more permissive to match the following:
//# '/ruby/api/v2/settings/timezones'
//# '/ruby/api/v2/templates/profileOptions?ruby_client_fk=3&templateType=profile_temp'
//# '/ruby/api/v2/content/office/options'

var route = new Route(optionsUrl_routeTemplate);

var queryPropsToConvertToNumber = ['ruby_client_fk', 'content_subsite_fk'];

function queryObjectFromQueryString(theQueryString) {

    var queryObject = queryString.parse(theQueryString);

    Object.keys(queryObject).forEach(function (queryKey) {
        var queryValue = queryObject[queryKey];
        //# NOTE: queryValue might be [(:globaldata_ancestor_fk)]
        //# which would be an invalid JSON string
        //# TODO: routeTemplate format doesn't work on _.isArray(queryValue)
        var firstChar = _lodash2.default.isString(queryValue) ? queryValue.charAt(0) : null;
        if (firstChar == '[' || firstChar == '{') {
            try {
                var parsedQueryValue = JSON.parse(queryValue);
                queryObject[queryKey] = parsedQueryValue;
            } catch (err) {
                //# test that the error is due to a route-parser templated string
                var routeParserTest = new Route(queryValue);
                var routeMatch = routeParserTest.match(queryValue);
                if (Object.keys(routeMatch).length) {
                    //# since we're dealing with a routeTemplate
                    //# map the queryKey to a special object with the key '_routeTemplate'
                    //# and store the original routeTemplate value
                    //# along with the params
                    queryObject[queryKey] = {
                        _routeTemplate: {
                            template: queryValue,
                            params: routeMatch
                        }
                    };
                } else {
                    logger.warn('queryValue: [' + queryValue + '] is an invalid JSON string.');
                }
            }
        }
    });

    return queryObject;
}

var PLURAL_TO_SINGULAR_MAP = {
    'media': 'media'
};

/**
 * @typedef {Object} OptionsUrlSpec
 * @property {string} version
 * @property {string} model
 * @property {Array} template
 * @property {string} endpointMethod
 * @property {Object} query - query object may include key-value pairs where the value is an array or object
 */

/**
 * @returns {OptionsUrlSpec}
 */
function extractParamsFromOptionsUrl(optionsUrl) {

    var routeMatchObject = route.match(optionsUrl) || {};

    var queryObject = routeMatchObject.queryString ? queryObjectFromQueryString(routeMatchObject.queryString) : {};

    queryPropsToConvertToNumber.forEach(function (queryPropKey) {
        if (!_lodash2.default.isNil(queryObject[queryPropKey])) {
            queryObject[queryPropKey] = Number(queryObject[queryPropKey]);
        }
    });

    routeMatchObject.query = queryObject;
    routeMatchObject.model = routeMatchObject.model ? PLURAL_TO_SINGULAR_MAP[routeMatchObject.model] || rubyWords.inflection.singularize(routeMatchObject.model) : undefined;
    routeMatchObject.template = routeMatchObject.template ? routeMatchObject.template.split(',') : undefined;

    return _lodash2.default.omit(routeMatchObject, ['queryString']);
}

exports.default = extractParamsFromOptionsUrl;