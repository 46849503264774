'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
function constructKeyURI(issuer, label, secret) {
    // TODO: allow configuration of type
    // alternative: hotp (counter based)
    var type = 'totp';

    var encodedLabel = encodeURIComponent(label);
    var encodedIssuer = encodeURIComponent(issuer);

    return 'otpauth://' + type + '/' + encodedLabel + '?secret=' + secret + '&issuer=' + encodedIssuer;
}

exports.default = constructKeyURI;