'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var action = require('./action');
var reducer = require('./reducer');
var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;
var NotificationConnector = require('./reactComponents/NotificationConnector');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var RubyComponentFrontendSettings = require('@rubyapps/ruby-component-frontend-settings');
var RubyComponentRubyClientFK = require('@rubyapps/ruby-component-rubyclientfk');

var accessControlMixin = require('@rubyapps/ruby-component-mixin-access-control');
var supportMixin = require('@rubyapps/ruby-component-mixin-support');

var RCNotifications = RubyComponent.createClass({
    mixins: [accessControlMixin, supportMixin],
    propTypes: {
        delegateActions: PropTypes.object,
        frontendSettingsID: PropTypes.string,
        reactProps: PropTypes.object
    },
    componentName: componentName,
    CONSTANTS: CONSTANTS,
    action: action,
    reducer: reducer,
    selector: function selector() {
        var selfModule = this;
        return selfModule.getDefaultSelector();
    },
    dependencies: function dependencies() {
        //# declare dependencies here so it's easy to find
        var rootComponent = this.getRoot();

        var selfSelector = this.getDefaultSelector();

        var frontendSettingsID = this.props.frontendSettingsID || RubyComponentFrontendSettings.CONSTANTS.COMPONENT_NAME;
        var frontendSettingsComponent = rootComponent.findDescendentByID(frontendSettingsID);
        var frontendSettingsSelector = frontendSettingsComponent.getDefaultSelector();

        var rubyClientFKID = this.props.rubyClientFKID || RubyComponentRubyClientFK.CONSTANTS.COMPONENT_NAME;
        var rubyClientFKComponent = rootComponent.findDescendentByID(rubyClientFKID);

        return {
            selfSelector: selfSelector,
            frontendSettingsSelector: frontendSettingsSelector,
            rubyClientFKSelector: rubyClientFKComponent.getDefaultSelector()
        };
    },

    getReactClass: function getReactClass() {
        var NotificationComponent = NotificationConnector.apply(this);
        return NotificationComponent;
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var NotificationComponent = this.getReactClass();
        var reactProps = this.props.reactProps;
        //# perform connection
        //# need to access the ruby_client_fk
        //# action to open the compare dialog should give the templatekey
        return React.createElement(NotificationComponent, _extends({}, reactProps, (_extends2 = {
            'data-codecept-selector-node': 'NotificationComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'NotificationComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'NotificationComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    }

    //# == Static Utilities ====
    , notification_savedChanges: function notification_savedChanges() {
        return {
            message: 'Your changes have been saved.',
            type: CONSTANTS.NOTIFICATION_TYPES.SUCCESS
        };
    }

    /*
        additionalOptions = {
            customAction: {
                label: "Edit"
                , onTouchTap: () => {
                    const route = editRouteObject.reverse(data)
                    dispatch(
                        selfModule.getAction().generators.navigateToPathWithOptions({path:route})
                    );
                }
            }
            , additionalActions = [
                //# Similar to form-dialog
                {
                    label: 'Cancel'
                    , primary: false
                    , disabled: props.loading
                    , onTouchTap: delegateClose
                }
            ]
        }
    */
    , notification_success: function notification_success() {
        var additionalOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        return _extends({}, {
            type: CONSTANTS.NOTIFICATION_TYPES.SUCCESS
        }, additionalOptions);
    },
    notification_info: function notification_info() {
        var additionalOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        return _extends({}, {
            type: CONSTANTS.NOTIFICATION_TYPES.INFO
        }, additionalOptions);
    },
    notification_pageAddedToProposal: function notification_pageAddedToProposal() {
        var additionalOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        return _extends({}, {
            message: 'Your page has been added to the proposal.',
            type: CONSTANTS.NOTIFICATION_TYPES.SUCCESS
        }, additionalOptions);
    }

    //# == Utility ====
    , notification_contentAllocated_forModelInfo: function notification_contentAllocated_forModelInfo(contentData, modelInfo) {
        var selfModule = this;
        var name = contentData.name;


        var editPageRoute = this._editPageRouteForContent_andModelInfo(contentData, modelInfo);
        return _extends({}, {
            message: _.isNil(name) ? 'You\'ve created new content.' : 'You\'ve created: ' + name,
            type: CONSTANTS.NOTIFICATION_TYPES.SUCCESS,
            customAction: {
                label: "Edit",
                onTouchTap: function onTouchTap() {
                    selfModule.getStore().dispatch(selfModule.getAction().generators.navigateToPathWithOptions({ path: editPageRoute }));
                }
            }
        });
    },

    _editPageRouteForContent_andModelInfo: function _editPageRouteForContent_andModelInfo(contentData, modelInfo) {
        var rootComponent = this.getRoot();
        var modelKey = modelInfo.model;

        var templateKey = _.isArray(modelInfo.template) ? modelInfo.template[0] : modelInfo.template;
        var formForTemplateKey = this.getFormForTemplateKey(templateKey);
        var modelKey__lowerCase = modelKey.toLowerCase();

        var contentId = contentData.id;

        var form__templateType = formForTemplateKey.templateType;
        var pathKeyForEditPage = form__templateType == 'lookup_template' ? 'lookups' : 'content';
        var targetEditPage = rootComponent.findDescendentsBy(function (node) {
            return node.componentName == 'rubyComponentEditPage';
        }).filter(function (node) {
            var apiKeyMatched = node.props.apiKey.toLowerCase() == modelKey__lowerCase;
            if (modelKey__lowerCase == 'content') {
                var node__routePath = node.getRoutePath();
                //# we need to differentiate lookups from content pages
                return apiKeyMatched && node__routePath.indexOf(pathKeyForEditPage) >= 0 && node__routePath.indexOf(':template') >= 0;
                //# not great but if we're selecting a content edit page we need the version that's generic
            } else {
                return apiKeyMatched;
            }
        })[0];

        var editPageRoute = rootComponent.getUrlForComponent_fromModule_withParams(targetEditPage, this, {
            template: templateKey,
            id: contentData.id,
            action: 'edit'
        });
        return editPageRoute;
    }
});

module.exports = RCNotifications;