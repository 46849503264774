'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _urlJoin = require('url-join');

var _urlJoin2 = _interopRequireDefault(_urlJoin);

var _action = require('./action');

var _action2 = _interopRequireDefault(_action);

var _ApiSecretConnector = require('./reactComponents/ApiSecretConnector');

var _ApiSecretConnector2 = _interopRequireDefault(_ApiSecretConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Promise = require('bluebird');


var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var FieldForm = require('@rubyapps/ruby-component-field-form');
var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var RubyComponentCurrentUser = require('@rubyapps/ruby-component-current-user');
var RubyComponentFrontendSettings = require('@rubyapps/ruby-component-frontend-settings');
var RubyComponentConfirmDialog = require('@rubyapps/ruby-component-confirm-dialog');

var RCApiSecret = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {
        key: PropTypes.string,
        label: PropTypes.string,
        secretEndpoint: PropTypes.string
    },
    componentName: componentName,
    getDefaultProps: function getDefaultProps() {
        return {
            "secretEndpoint": "${frontendSettings.restApiRoot}/users/${pageId}/secret",
            "mode": "info"
        };
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getSelfStateSelector();

        var currentUserID = this.props.currentUserID || RubyComponentCurrentUser.CONSTANTS.COMPONENT_NAME;
        var currentUser = root.findDescendentByID(currentUserID);
        var currentUserSelector = currentUser.getSelfStateSelector();

        var frontendSettingsID = this.props.frontendSettingsID || RubyComponentFrontendSettings.CONSTANTS.COMPONENT_NAME;
        var frontendSettingsComponent = root.findDescendentByID(frontendSettingsID);
        var frontendSettingsSelector = frontendSettingsComponent.getDefaultSelector();

        var editPage = this.findAncestorBy(function (ancestor) {
            return ancestor.componentName === 'rubyComponentEditPage';
        });
        var editPageSelector = editPage.getSelfStateSelector();

        var confirmDialogID = this.props.confirmDialogID || RubyComponentConfirmDialog.componentName;
        var confirmDialogComponent = root.findDescendentByID(confirmDialogID);

        return {
            selfSelector: selfSelector,
            currentUserSelector: currentUserSelector,
            editPageSelector: editPageSelector,
            frontendSettingsSelector: frontendSettingsSelector,
            confirmDialogComponent: confirmDialogComponent
        };
    },
    action: _action2.default,
    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector,
            currentUserSelector = _getDependencies.currentUserSelector,
            frontendSettingsSelector = _getDependencies.frontendSettingsSelector;

        return {
            self: selfSelector(state),
            user: currentUserSelector(state),
            frontendSettings: frontendSettingsSelector(state),
            routing: state.routing //# need to update based on routing changes
        };
    },
    getReactClass: function getReactClass() {
        return _ApiSecretConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var ApiSecretComponent = this.getReactClass();

        return React.createElement(ApiSecretComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'ApiSecretComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'ApiSecretComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'ApiSecretComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

    //# override _formValueToLocalState to getSecret() instead
    , _formValueToLocalState: function _formValueToLocalState(formValue, dispatchOrCollect, isError, entireFormValue, options) {
        if (!isError) {
            var store = this.getStore();
            var actions = this.getAction().generators;

            var selfKey = this.props.key;

            if (formValue.hasOwnProperty(selfKey)) {
                return baseFieldMixin._formValueToLocalState.call(this, formValue, dispatchOrCollect, isError, entireFormValue, options);
            } else {
                store.dispatch(actions.getSecret());
                return Promise.resolve();
            }
        }

        return baseFieldMixin._formValueToLocalState.call(this, formValue, dispatchOrCollect, isError, entireFormValue, options);
    }

    //== UTILITIES =================================================//

    , determineSecretEndpoint: function determineSecretEndpoint() {
        var secretEndpoint = this.props.secretEndpoint;

        var _getDependencies2 = this.getDependencies(),
            selfSelector = _getDependencies2.selfSelector,
            frontendSettingsSelector = _getDependencies2.frontendSettingsSelector,
            editPageSelector = _getDependencies2.editPageSelector;

        var root = this.getRoot();
        var appState = this.getStore().getState();
        var frontendSettings = frontendSettingsSelector(appState);
        var editPage = editPageSelector(appState);
        var pageId = editPage.routeParams.id;
        return secretEndpoint ? _lodash2.default.template(secretEndpoint)({
            pageId: pageId,
            frontendSettings: frontendSettings,
            editPage: editPage
        }) : (0, _urlJoin2.default)(editPage.instanceDataEndpoint, '/secret');
    }
});

module.exports = RCApiSecret;