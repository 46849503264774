'use strict';

var request = require('@rubyapps/ruby-superagent');
var _ = require('lodash');

var moment = require('moment');
var urljoin = require('url-join');

var _require = require('../common/constants'),
    ERROR_CODES = _require.ERROR_CODES;

function typesFromID(id) {
    var TYPES = {
        SET_ERROR: '@@ruby-app/' + id + '/SET_ERROR',
        SET_ERROR_PAYLOAD: '@@ruby-app/' + id + '/SET_ERROR_PAYLOAD',
        RESET: '@@ruby-app/' + id + '/RESET',
        SWITCH_TO_FORGOT_PASSWORD: '@@ruby-app/' + id + '/SWITCH_TO_FORGOT_PASSWORD',
        FORGOT_PASSWORD_SUCCESS: '@@ruby-app/' + id + '/FORGOT_PASSWORD_SUCCESS'
    };

    return TYPES;
}

var generators = {
    //# errorPayload = {error: { message: '' }}
    setError: function setError(errorPayload) {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        return {
            type: TYPES.SET_ERROR,
            payload: errorPayload
        };
    },
    setErrorPayload: function setErrorPayload(errorPayload) {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES;

        return {
            type: TYPES.SET_ERROR_PAYLOAD,
            payload: {
                errorPayload: errorPayload
            }
        };
    },
    switchToForgotPassword: function switchToForgotPassword() {
        var _getAction3 = this.getAction(),
            TYPES = _getAction3.TYPES;

        return {
            type: TYPES.SWITCH_TO_FORGOT_PASSWORD
        };
    },
    forgotPasswordSuccess: function forgotPasswordSuccess() {
        var _getAction4 = this.getAction(),
            TYPES = _getAction4.TYPES;

        return {
            type: TYPES.FORGOT_PASSWORD_SUCCESS
        };
    },
    reset: function reset() {
        var _getAction5 = this.getAction(),
            TYPES = _getAction5.TYPES;

        return {
            type: TYPES.RESET
        };
    },
    clearErrorsAndSetFieldValueByKey: function clearErrorsAndSetFieldValueByKey(value, key) {
        var generators = this.getAction().generators;

        return function (dispatch) {
            dispatch(generators.setError({ error: null }));
            dispatch(generators.setFieldValueByKey(value, key));
        };
    },
    validateBeforeSubmitting: function validateBeforeSubmitting(payload) /*{successCallback, errorCallback, additionalFieldKeys}*/{
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var selfModule = this;
        var selfSelectorObj = selfModule.getSelector();
        var generators = selfModule.getAction().generators;

        return function (dispatch, getState) {

            dispatch(generators.validateFields(['username', 'password'].concat(options.additionalFieldKeys || [])));
            //# since validateAllFields is synchronous, we have acces to the updated state at this point
            var applicationState = getState();

            var hasErrors = Object.keys(selfSelectorObj.getFieldsThatErroredByKey(applicationState)).length > 0;

            if (hasErrors) {
                //# TODO: show another error ?
                options.errorCallback && options.errorCallback(new Error('Invalid input'));
            } else {
                dispatch(generators.submit(payload, options));
            }
        };
    },

    submit: function submit() /*{successCallback, errorCallback}*/{
        var nextPage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '/';
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var selfModule = this;

        var _getDependencies = this.getDependencies(),
            currentUserActions = _getDependencies.currentUserActions;

        var selfSelectorObj = selfModule.getSelector();
        var generators = selfModule.getAction().generators;

        return function (dispatch, getState) {
            var applicationState = getState();
            var selfState = selfSelectorObj.default(applicationState);

            var fields = selfState.fields;
            var data = _.reduce(fields, function (collector, fieldObj, fieldKey) {
                collector[fieldKey] = fieldObj.value;
                return collector;
            }, {});
            var previousErrorPayload = selfState.errorPayload;

            //# dispatch(generators.setLoading());
            var thenableArguments = [function onResult(response) {
                //# dispatch(generators.clearLoading());

                //# if not local, we reject (don't even use the local route)
                if (nextPage.charAt(0) == '/') {
                    //# assume relative if slash
                    //# navigate to page
                    window.location.href = nextPage; //# we need to force a page reload to get the rest of the bundle
                } else {
                    window.location.href = '/'; //# redirect to the root page and ignore other urls
                }

                //dispatch(generators.navigateToPathWithOptions({path:nextPage}));
                setTimeout(function () {
                    dispatch(generators.reset());
                }, 1000); //# since we're doing a hard navigation to the nextPage, we don't need to reset the form
                //# but we'll leave this in here anyways, but on a delay so that we don't need the form content change
            }, function onError(err) {
                //# dispatch(generators.clearLoading());

                var errorResponseBody = _.get(err, 'response.body');
                dispatch(generators.setErrorPayload(errorResponseBody.error));
                var errorStatusCode = err.status;
                if (errorStatusCode == 449) {
                    dispatch(generators.setError({
                        error: null
                    }));
                } else if (errorStatusCode == 429) {
                    var nextValidRequestDate = errorResponseBody.error.nextValidRequestDate;
                    var timeToWaitString = void 0;
                    if (nextValidRequestDate) {
                        timeToWaitString = moment(nextValidRequestDate).diff(moment(), 'minutes') + 1 + ' minutes'; //# round up
                    } else {
                        timeToWaitString = '5 minutes';
                    }
                    dispatch(generators.setError({
                        error: {
                            message: 'Too many tries. Please try again in ' + timeToWaitString + '.'
                        }
                    }));
                } else {
                    var errorMessage = errorResponseBody.error.message;

                    dispatch(generators.setError({
                        error: {
                            message: errorMessage
                        }
                    }));
                }

                options.errorCallback && options.errorCallback(err);
            }];
            dispatch(currentUserActions.logIn(data, thenableArguments));
        };
    }

    //# NOT necessary since we're not validating the username input for this
    , validateBeforeSubmittingForgotPassword: function validateBeforeSubmittingForgotPassword() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var selfModule = this;
        var selfSelectorObj = selfModule.getSelector();
        var generators = selfModule.getAction().generators;

        return function (dispatch, getState) {
            dispatch(generators.validateFields(['fp_username']));

            var applicationState = getState();
            var hasErrors = Object.keys(selfSelectorObj.getFieldsThatErroredByKey(applicationState)).length > 0;

            if (hasErrors) {
                //# TODO: show another error ?

                options.errorCallback && options.errorCallback(new Error('Invalid input'));
            } else {
                dispatch(generators.submitForgotPassword(options));
            }
        };
    },

    submitForgotPassword: function submitForgotPassword() /*{successCallback, errorCallback}*/{
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var selfModule = this;

        var selfSelectorObj = selfModule.getSelector();
        var generators = selfModule.getAction().generators;

        return function (dispatch, getState) {
            var applicationState = getState();
            var selfState = selfSelectorObj.default(applicationState);

            var fields = selfState.fields;
            var data = _.reduce(fields, function (collector, fieldObj, fieldKey) {
                collector[fieldKey] = fieldObj.value;
                return collector;
            }, {});

            return request.post('/ruby/api/v2/users/forgotPassword').send(data).then(function () {
                dispatch(generators.forgotPasswordSuccess());

                options.successCallback && options.successCallback();
            }, function (error) {
                options.errorCallback && options.errorCallback(error);
            });
        };
    }

};

module.exports = function () {
    var TYPES = typesFromID(this.getID());
    return {
        TYPES: TYPES,
        generators: generators
    };
};