'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var actionTypes = require('./action').TYPES;

var defaultState = {
    menuIsPinned: false,
    menuDrawerOpen: false
};

module.exports = function (state, action) {
    var TYPES = this.getAction().TYPES;

    if (state === undefined) {
        state = defaultState;
    }

    switch (action.type) {
        case TYPES.PIN_MENU:
            return _extends({}, state, {
                menuIsPinned: true
            });

        case TYPES.UNPIN_MENU:
            return _extends({}, state, {
                menuIsPinned: false
            });

        case TYPES.REQUEST_MENU_OPEN_CHANGE:
            return _extends({}, state, {
                menuDrawerOpen: action.payload
            });

        default:
            return state;
    }
};