'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IS_DEVELOPMENT_ENV = process.NODE_ENV != 'production';

function formatRequestPropsForColumns(columns) {
    var fields = _lodash2.default.map(columns, function (col) {
        return col.key;
    }).filter(function (colKey) {
        return colKey;
    });

    //# exclude keys in listerConfig that has the 'references' prop defined
    var fieldsToExpand = _lodash2.default.reduce(columns, function (collector, col) {
        if (IS_DEVELOPMENT_ENV && col.hasOwnProperty('references')) {
            console.warn('The \'references\' prop in the listerConfig is deprecated.\nIf you need to expand data, please use: \n{\ntype: \'valueInObject\'\n, expand: true\n}\n');
        }
        if (col.references == undefined && col.shouldExpand) {
            collector.push(col.key);
        }
        return collector;
    }, []);

    return {
        fields: fields,
        expand: fieldsToExpand
    };
}

exports.default = formatRequestPropsForColumns;