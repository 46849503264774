'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var tokenizer = require('sbd');

module.exports = {

    getTextSummary: function getTextSummary(value) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var sbdOptions = _extends({
            sanitize: true
        }, options.sbdOptions);

        var sentences = tokenizer.sentences(value, sbdOptions);

        if (options.sentenceCount) {
            return sentences.slice(0, options.sentenceCount).join(' ');
        }
        return sentences[0] || '';
    }
};