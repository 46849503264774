'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _TokenTaggerConnector = require('./reactComponents/TokenTaggerConnector');

var _TokenTaggerConnector2 = _interopRequireDefault(_TokenTaggerConnector);

var _optionsUrl__extractParams = require('../../../ruby-form-js-to-json-schema/src/common/optionsUrl__extractParams.js');

var _optionsUrl__extractParams2 = _interopRequireDefault(_optionsUrl__extractParams);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var fieldRemoteOptionsMixin = require('@rubyapps/ruby-component-mixin-field-remote-options');
var accessControlMixin = require('@rubyapps/ruby-component-mixin-access-control');

var RCTokenTagger = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin, accessControlMixin, fieldRemoteOptionsMixin, require('./mixins/advancedSearch'), require('./mixins/tagSuggestions')],
    propTypes: {
        advancedSearch: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        limit: PropTypes.number,
        count: PropTypes.number //# the count param for url
        , requireSearchValueForDropdown: PropTypes.bool,
        showCreateEntryButton: PropTypes.bool,
        options: PropTypes.array,
        toHTML: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
    },
    getDefaultProps: function getDefaultProps(props) {
        var count = props.count || 50;
        return {
            count: count,
            currentUserID: 'rubyComponentCurrentUser',
            contentHelperID: 'rubyComponentContentHelper',
            notificationsID: 'rubyComponentNotifications',
            requireSearchValueForDropdown: false,
            preloadOptions: true,
            preloadOptionsQuery: {
                search_input: '',
                count: count
                //, showCreateEntryButton: true //# don't default
                //, "url" : "/ruby/api/v2/content/attorney/options?ruby_client_fk=3&content_subsite_fk=1"
            } };
    },
    componentName: componentName,
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();

        var currentUser = root.findDescendentByID(this.props.currentUserID);
        var currentUserSelector = currentUser.getSelector().default;

        var contentHelper = root.findDescendentByID(this.props.contentHelperID);

        var notificationsComponent = root.findDescendentByID(this.props.notificationsID);
        return {
            selfSelector: selfSelector,
            contentHelper: contentHelper,
            currentUserSelector: currentUserSelector,
            notificationsComponent: notificationsComponent
        };
    },
    getInitialState: function getInitialState() {
        return {
            searchValue: null
        };
    },

    getReactClass: function getReactClass() {
        return _TokenTaggerConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var TokenTaggerConnector = this.getReactClass();

        return React.createElement(TokenTaggerConnector, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'TokenTaggerConnector',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TokenTaggerConnector'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TokenTaggerConnector'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    }

    //# Mixin Overrides

    //# Utility methods
    , urlDependentFieldDataInModelInfo: function urlDependentFieldDataInModelInfo(modelInfo) {
        var modelInfo__query = modelInfo.query || {};
        var urlDependentFields = this.urlDependentFields();

        return _.pick(modelInfo__query, urlDependentFields);
    },
    canCreateEntry: function canCreateEntry() {
        var modelInfoOverride = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var selfModule = this;

        var _selfModule$getDepend = selfModule.getDependencies(),
            contentHelper = _selfModule$getDepend.contentHelper;

        var props__url = this.url();
        var modelInfo = _extends({}, (0, _optionsUrl__extractParams2.default)(props__url), modelInfoOverride);

        var urlDependentFieldData = this.urlDependentFieldDataInModelInfo(modelInfo);
        var validUrlDependentFieldData = _.reduce(urlDependentFieldData, function (collector, value, key) {
            if (!collector) {
                return collector;
            }

            var valueContainsValue = _.isArray(value) ? value.length > 0 : _.isPlainObject(value) ? Object.keys(value).length : value;

            return collector && valueContainsValue;
        }, true);

        return validUrlDependentFieldData && contentHelper.canUserAllocateSome({ modelInfo: modelInfo });
    },
    canCreateSomeEntry: function canCreateSomeEntry() {
        return this.canCreateEntry();
    },
    canCreateEntryWithKey: function canCreateEntryWithKey(templateKey) {
        return this.canCreateEntry({
            template: templateKey
        });
    },
    createEntryAsync: function createEntryAsync(entryName, templateKey) {
        var _this = this;

        var selfModule = this;

        var _selfModule$getDepend2 = selfModule.getDependencies(),
            contentHelper = _selfModule$getDepend2.contentHelper,
            notificationsComponent = _selfModule$getDepend2.notificationsComponent;

        var hydratedUrl = this.url();
        var modelInfo = (0, _optionsUrl__extractParams2.default)(hydratedUrl);

        var urlDependentFieldData = this.urlDependentFieldDataInModelInfo(modelInfo);

        /*
            {
                template
                version
                model
                endpointMethod
                query: {
                    content_subsite
                    ruby_client_fk
                }
            }
        */
        var allocationData = _extends({
            name: entryName,
            status: 'active'
        }, urlDependentFieldData);

        //# get the template keyword
        return contentHelper.allocateAsync(allocationData, {
            modelInfo: _extends({}, modelInfo, templateKey && { template: templateKey })
        }).then(function (response) {
            _this.pushNotification(notificationsComponent.notification_contentAllocated_forModelInfo(response.body, modelInfo));

            return response;
        });
    },
    getModelInfoFromOptionsUrl: function getModelInfoFromOptionsUrl(url) {
        return (0, _optionsUrl__extractParams2.default)(url);
    },
    getFormObjectsFromModelInfo: function getFormObjectsFromModelInfo(modelInfo) {
        var _this2 = this;

        var finalTemplateKeys = [];
        var model = modelInfo.model,
            template = modelInfo.template;


        if (model == 'content') {
            finalTemplateKeys = _.castArray(template);
        } else if (model == 'User') {
            finalTemplateKeys = ['user'];
        } else if (model == 'RubyRole') {
            finalTemplateKeys = ['RubyRole'];
        }

        return finalTemplateKeys.map(function (key) {
            return _this2.getFormForTemplateKey(key);
        });
    }
});

module.exports = RCTokenTagger;