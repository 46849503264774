'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GriddleRedux = exports.processPluginActions = exports.bindStoreToActions = exports.processPlugins = exports.combineComponents = exports.previousOrCombined = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.combinePlugins = combinePlugins;
exports.composer = composer;

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _griddleContainer = require('./griddleContainer');

var _redux = require('redux');

var _reduxBatchedActions = require('redux-batched-actions');

var _reactRedux = require('react-redux');

var _reduxThunk = require('redux-thunk');

var _reduxThunk2 = _interopRequireDefault(_reduxThunk);

var _griddleCore = require('../../griddle-core');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var previousOrCombined = exports.previousOrCombined = function previousOrCombined(previous, newValue) {
  return newValue ? [].concat(_toConsumableArray(previous), [newValue]) : previous;
};

function combinePlugins(plugins) {
  return plugins.reduce(function (previous, current) {
    return {
      actions: _extends({}, previous.actions, current.actions),
      middleware: previousOrCombined(previous.middleware, current.middleware),
      reducers: previousOrCombined(previous.reducers, current.reducers),
      states: previousOrCombined(previous.states, current.states),
      helpers: previousOrCombined(previous.helpers, current.helpers),
      components: previousOrCombined(previous.components, current.components)
    };
  }, { actions: _griddleCore.GriddleActions, middleware: [], reducers: [], states: [], helpers: [], components: [] });
}

function composer(functions) {
  return _lodash2.default.flowRight.apply(this, functions.reverse());
}

var combineComponents = exports.combineComponents = function combineComponents(_ref) {
  var _ref$plugins = _ref.plugins,
      plugins = _ref$plugins === undefined ? null : _ref$plugins,
      _ref$components = _ref.components,
      components = _ref$components === undefined ? null : _ref$components;

  if (!plugins || !components) {
    return;
  }

  var composedComponents = {};
  //for every plugin in griddleComponents compose the the matching plugins with the griddle component at the end
  //TODO: This is going to be really slow -- we need to clean this up
  for (var key in components) {
    if (plugins.some(function (p) {
      return p.components.hasOwnProperty(key);
    })) {
      composedComponents[key] = composer(plugins.filter(function (p) {
        return p.components.hasOwnProperty(key);
      }).map(function (p) {
        return p.components[key];
      }))(components[key]);
    }
  }

  return composedComponents;
};

//Should return GriddleReducer and the new components
var processPlugins = exports.processPlugins = function processPlugins(plugins, originalComponents) {
  if (!plugins) {
    return {
      actions: _griddleCore.GriddleActions,
      reducer: (0, _griddleCore.GriddleReducer)([_griddleCore.States.data, _griddleCore.States.local], [_griddleCore.Reducers.data, _griddleCore.Reducers.local], [_griddleCore.GriddleHelpers.data, _griddleCore.GriddleHelpers.local]),
      middleware: []
    };
  }

  var combinedPlugin = combinePlugins(plugins);
  var actions = combinedPlugin.actions,
      middleware = combinedPlugin.middleware;

  var reducer = (0, _griddleCore.GriddleReducer)([_griddleCore.States.data, _griddleCore.States.local].concat(_toConsumableArray(combinedPlugin.states)), [_griddleCore.Reducers.data, _griddleCore.Reducers.local].concat(_toConsumableArray(combinedPlugin.reducers)), [_griddleCore.GriddleHelpers.data, _griddleCore.GriddleHelpers.local].concat(_toConsumableArray(combinedPlugin.helpers)));

  var components = combineComponents({ plugins: plugins, components: originalComponents });
  if (components) {
    return { actions: actions, middleware: middleware, components: components, reducer: reducer };
  }

  return { actions: actions, middleware: middleware, reducer: reducer };
};

var bindStoreToActions = exports.bindStoreToActions = function bindStoreToActions(actions, actionsToBind, store) {
  return Object.keys(actions).reduce(function (actions, actionKey) {
    if (actionsToBind.indexOf(actions[actionKey]) > -1) {
      // Bind the store to the action if it's in the array.
      actions[actionKey] = actions[actionKey].bind(null, store);
    }
    return actions;
  }, actions);
};

var processPluginActions = exports.processPluginActions = function processPluginActions(actions, plugins, store) {
  if (!plugins) {
    return actions;
  }

  // Bind store to necessary actions.
  return plugins.reduce(function (previous, current) {
    var processActions = current.storeBoundActions && current.storeBoundActions.length > 0;
    return processActions ? bindStoreToActions(previous, current.storeBoundActions, store) : actions;
  }, actions);
};

var GriddleRedux = exports.GriddleRedux = function GriddleRedux(_ref2) {
  var _class, _temp;

  var Griddle = _ref2.Griddle,
      Components = _ref2.Components,
      Plugins = _ref2.Plugins;
  return _temp = _class = function (_Component) {
    _inherits(GriddleRedux, _Component);

    function GriddleRedux(props, context) {
      _classCallCheck(this, GriddleRedux);

      //TODO: Switch this around so that the states and the reducers come in as props.
      //      if nothing is specified, it should default to the local one maybe
      var _this = _possibleConstructorReturn(this, (GriddleRedux.__proto__ || Object.getPrototypeOf(GriddleRedux)).call(this, props, context));

      var _processPlugins = processPlugins(Plugins, Components),
          actions = _processPlugins.actions,
          reducer = _processPlugins.reducer,
          components = _processPlugins.components,
          middleware = _processPlugins.middleware;

      middleware = [_reduxThunk2.default].concat(middleware);

      // Use the thunk middleware to allow for multiple dispatches in a single action.
      var createStoreWithMiddleware = _redux.applyMiddleware.apply(null, middleware)(_redux.createStore);

      /* set up the redux store */
      var combinedReducer = (0, _redux.combineReducers)(reducer);
      _this.store = createStoreWithMiddleware((0, _reduxBatchedActions.enableBatching)(reducer));

      // Update the actions with the newly created store.
      actions = processPluginActions(actions, Plugins, _this.store);

      _this.components = _extends({}, components, props.components);
      _this.component = (0, _griddleContainer.GriddleContainer)(actions)(Griddle);
      return _this;
    }

    _createClass(GriddleRedux, [{
      key: 'componentWillReceiveProps',
      value: function componentWillReceiveProps(nextProps) {
        this.components = _extends({}, this.components, nextProps.components);
      }
    }, {
      key: 'render',
      value: function render() {
        return _react2.default.createElement(
          _reactRedux.Provider,
          { store: this.store, 'data-codecept-selector-node': 'Provider',
            'data-codecept-selector-file': 'griddle-redux'
          },
          _react2.default.createElement(
            this.component,
            _extends({}, this.props, { components: this.components, 'data-codecept-selector-file': 'griddle-redux'
            }),
            this.props.children
          )
        );
      }
    }, {
      key: 'componentDidMount',
      value: function componentDidMount() {
        var _this2 = this;

        //# reflect store changes to our parentStore
        if (this.props.reflectGriddleState) {

          this._griddleStateReflectionUnsubscribe = this.store.subscribe(function () {
            var newState = _this2.store.getState();
            var prevState = _this2._prevState;
            if (!prevState || !_immutable2.default.is(newState, prevState)) {
              _this2._prevState = newState;
              //# update state;
              _this2.props.reflectGriddleState(newState.toJS(), prevState ? prevState.toJS() : {});
            }
          });
        }
      }
    }, {
      key: 'componentWillUnmount',
      value: function componentWillUnmount() {
        this._griddleStateReflectionUnsubscribe && this._griddleStateReflectionUnsubscribe();
        this._griddleStateReflectionUnsubscribe = undefined;
      }
    }]);

    return GriddleRedux;
  }(_react.Component), _class.PropTypes = {
    data: _react2.default.PropTypes.array.isRequired
  }, _temp;
};