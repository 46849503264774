"use strict";

module.exports = {
   "Asset": {
      "AUDIO_CATEGORY": "audio",
      "DOCUMENT_CATEGORY": "document",
      "FLASH_CATEGORY": "flash",
      "IMAGE_CATEGORY": "image",
      "PDF_CATEGORY": "pdf",
      "SLIDESHOW_CATEGORY": "slideshow",
      "TEXT_CATEGORY": "text",
      "VIDEO_CATEGORY": "video",
      "asset_types": {
         "": {
            "ext": "",
            "largethumb": "/images/micon_doc_generic.jpg",
            "text": "file",
            "thumb": "/images/micon_doc_generic_80.jpg",
            "type": "generic"
         },
         "csv": {
            "ext": "csv",
            "largethumb": "/images/micon_doc_generic.jpg",
            "text": "comma separated values",
            "thumb": "/images/micon_doc_generic_80.jpg",
            "type": "text"
         },
         "doc": {
            "ext": "doc",
            "largethumb": "/images/micon_doc.jpg",
            "text": "ms word",
            "thumb": "/images/micon_doc_80.jpg",
            "type": "document"
         },
         "docx": {
            "ext": "docx",
            "largethumb": "/images/micon_doc.jpg",
            "text": "ms word",
            "thumb": "/images/micon_doc_80.jpg",
            "type": "document"
         },
         "flv": {
            "ext": "flv",
            "text": "flash video",
            "type": "video"
         },
         "gif": {
            "ext": "gif",
            "type": "image"
         },
         "html": {
            "ext": "html",
            "largethumb": "/images/micon_doc_generic.jpg",
            "text": "html",
            "thumb": "/images/micon_doc_generic_80.jpg",
            "type": "text"
         },
         "jpeg": {
            "ext": "jpeg",
            "type": "image"
         },
         "jpg": {
            "ext": "jpg",
            "text": "jpg",
            "type": "image"
         },
         "mov": {
            "ext": "mov",
            "text": "quicktime",
            "type": "video"
         },
         "mp3": {
            "ext": "mp3",
            "largethumb": "/images/micon_audio.jpg",
            "thumb": "/images/micon_audio_80.jpg",
            "type": "audio"
         },
         "mp4": {
            "ext": "mp4",
            "text": "mpeg4",
            "type": "video"
         },
         "ogv": {
            "ext": "ogv",
            "text": "ogg video",
            "type": "video"
         },
         "pdf": {
            "ext": "pdf",
            "text": "pdf",
            "type": "pdf"
         },
         "png": {
            "ext": "png",
            "type": "image"
         },
         "psd": {
            "ext": "psd",
            "text": "photoshop",
            "type": "document"
         },
         "show": {
            "ext": "show",
            "largethumb": "/images/micon_show.jpg",
            "text": "slideshow",
            "thumb": "/images/micon_show_80.jpg",
            "type": "slideshow"
         },
         "svg": {
            "ext": "svg",
            "thumb_ext": "png",
            "type": "image"
         },
         "swf": {
            "ext": "swf",
            "largethumb": "/images/micon_swf.jpg",
            "text": "flash",
            "thumb": "/images/micon_swf_80.jpg",
            "type": "flash"
         },
         "txt": {
            "ext": "txt",
            "largethumb": "/images/micon_doc_generic.jpg",
            "text": "text",
            "thumb": "/images/micon_doc_generic_80.jpg",
            "type": "text"
         },
         "vmo": {
            "ext": "vmo",
            "text": "vimeo",
            "type": "video"
         },
         "wma": {
            "ext": "wma",
            "largethumb": "/images/micon_audio.jpg",
            "text": "windows media audio",
            "thumb": "/images/micon_audio_80.jpg",
            "type": "audio"
         },
         "wmv": {
            "ext": "wmv",
            "text": "windows media video",
            "type": "video"
         },
         "yt": {
            "ext": "yt",
            "text": "youtube",
            "type": "video"
         }
      },
      "category_extensions": {
         "audio": {
            "mp3": 1,
            "wma": 1
         },
         "document": {
            "doc": 1,
            "docx": 1,
            "psd": 1
         },
         "flash": {
            "swf": 1
         },
         "image": {
            "gif": 1,
            "jpg": 1,
            "png": 1,
            "svg": 1
         },
         "pdf": {
            "pdf": 1
         },
         "slideshow": {
            "show": 1
         },
         "text": {
            "csv": 1,
            "html": 1,
            "txt": 1
         },
         "video": {
            "flv": 1,
            "mov": 1,
            "mp4": 1,
            "ogv": 1,
            "vmo": 1,
            "wmv": 1,
            "yt": 1
         }
      }
   },
   "DELETE_ACTION": "delete",
   "DISABLE_ACTION": "disable",
   "ENABLE_ACTION": "enable",
   "FAILED_STATUS": "failed",
   "FIREFOX_BROWSER": "firefox",
   "IE_BROWSER": "ie",
   "INSERT_ACTION": "add",
   "SAFARI_BROWSER": "safari",
   "UNPUBLISH_ACTION": "unpublish",
   "UPDATE_ACTION": "update",
   "WORKING_STATUS": "working"
};