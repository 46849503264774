'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

exports.default = function (TableBody) {
    return function (_Component) {
        _inherits(_class, _Component);

        function _class() {
            _classCallCheck(this, _class);

            return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
        }

        _createClass(_class, [{
            key: 'expandRow',
            value: function expandRow(row, expandedRows, context) {
                var _this2 = this;

                var rows = [row];

                //# 20230117 - DO NOT EXPOSE context... It doesn't seem  like this is used 
                //# oncluding context is an issue because parentRow causes data to be cyclical which is an issue when compare data in 
                //# griddle-render/src/table-body.js > shoudComponentUpdate
                //row.__metadata.context = context;
                row.__metadata.expandedFlag = expandedRows[row.id];
                var expanded = row.__metadata.expandedFlag;

                if (
                // has children and is expanded
                row.children && row.children.length > 0 && expanded) {
                    rows = rows.concat(row.children.reduce(function (prev, curr, idx, arr) {
                        var currContext = {
                            parentRow: row,
                            ancestors: context.ancestors.concat(row)
                        };
                        return prev.concat(_this2.expandRow(curr, expandedRows, currContext));
                    }, []));
                }

                return rows;
            }
        }, {
            key: 'render',
            value: function render() {
                var _this3 = this;

                var expandedRows = this.props.expandedRows;
                var expandedRowData = this.props.data.reduce(function (prev, curr, idx, arr) {
                    return prev.concat(_this3.expandRow(curr, expandedRows, {
                        parentRow: null,
                        ancestors: []
                    }));
                }, []);

                return _react2.default.createElement(TableBody, _extends({}, this.props, { data: expandedRowData, 'data-codecept-selector-node': 'TableBody',
                    'data-codecept-selector-file': 'table-body'
                }));
            }
        }]);

        return _class;
    }(_react.Component);
};