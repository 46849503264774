'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _TextConnector = require('./reactComponents/TextConnector');

var _TextConnector2 = _interopRequireDefault(_TextConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Promise = require('bluebird');

var React = require('react');

var rubyWords = require('@rubyapps/ruby-words');
var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var FieldForm = require('@rubyapps/ruby-component-field-form');
var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var RubyComponentCurrentUser = require('@rubyapps/ruby-component-current-user');

var mixinUtils = require('@rubyapps/ruby-component-mixin-utils');

var action = require('./action');
var reducer = require('./reducer');

var RCAutopopulatedText = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {
        //, label: PropTypes.string
        //, key: PropTypes.string
        //, constraints: PropTypes.object
        keyForReferenceComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf([PropTypes.string])]),
        referenceComponentValueMap: PropTypes.oneOfType([PropTypes.func //# (val) => _.first(val); //# the assumption is that the value is always normalized to an array
        , PropTypes.string]),
        displayValueMode: PropTypes.bool
    },
    getDefaultProps: function getDefaultProps() {
        return {
            keyForReferenceComponent: 'name',
            referenceComponentValueMap: function referenceComponentValueMap(val) {
                return _lodash2.default.isArray(val) ? val.filter(function (i) {
                    return i;
                }).join(', ') : val;
            },
            displayValueMode: false //# pretty expensive for text fields where the speed of value change is high
            //# this is only suitable for selector fields like dropdowns
        };
    },
    componentName: componentName,
    action: action,
    reducer: reducer,
    dependencies: function dependencies() {
        var _this = this;

        var root = this.getRoot();
        var selfSelector = this.getSelfStateSelector();

        var parentForm = this.findAncestorBy(function (module) {
            return module.componentName == FieldForm.componentName;
        });
        var referenceComponent = parentForm.findDescendentBy(function (module) {
            return module.props.key == _this.props.keyForReferenceComponent;
        });

        return {
            selfSelector: selfSelector,
            parentForm: parentForm,
            referenceComponent: referenceComponent //# TODO: DEPRECATED
            , referenceSelector: referenceComponent ? referenceComponent.getSelfStateSelector() : function () {
                return {};
            } //# TODO: DEPRECATED
        };
    },
    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector;

        return {
            self: selfSelector(state),
            reference: this.getReferencedStatesByKey(state),
            fieldProps: this.fieldProps_fromState_andOwnProps(state, this.props),
            routing: state.routing //# need to update based on routing changes
        };
    },
    getReactClass: function getReactClass() {
        return _TextConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var TextComponent = this.getReactClass();

        return React.createElement(TextComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'TextComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TextComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TextComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

    //# utility
    , getReferencedComponents: function getReferencedComponents() {
        var _this2 = this;

        //# TODO: if we want to support providing a path
        //# then we need to update this to support calling this.getRubyComponentAtPath('> .key1 > .key2');

        if (this._cachedReferencedComponents) {
            return this._cachedReferencedComponents;
        }

        var _getDependencies2 = this.getDependencies(),
            parentForm = _getDependencies2.parentForm;

        var keyForReferenceComponent = this.props.keyForReferenceComponent;


        var normalizedKeysForReferenceComponents = keyForReferenceComponent ? _lodash2.default.castArray(keyForReferenceComponent) : [];

        var referencedComponents = normalizedKeysForReferenceComponents.map(function (key) {
            return parentForm.findDescendentBy(function (module) {
                return module.props.key == key;
            });
        });

        var hasUndefinedComponents = referencedComponents.filter(_lodash2.default.isNil).length;

        //# need to spoof undefined components cause of deferred rendering
        var referencedComponents_wSpoofedMethodsForUndef = referencedComponents.map(function (refComponent, index) {
            if (_lodash2.default.isNil(refComponent)) {
                var refKey = normalizedKeysForReferenceComponents[index];

                var spoofedComponent = {
                    props: {
                        key: refKey
                    },
                    key: function key() {
                        return refKey;
                    },
                    getFieldValue: function getFieldValue(entireState) {
                        var parentFormState = _extends({}, parentForm.getSelfStateSelector()(entireState), parentForm.getChildrenStateSelector()(entireState));

                        var formValue = parentForm._formValueFromLocalState(parentFormState, false, undefined, entireState, {
                            omitFieldPicker: function omitFieldPicker(rubyComponent) {
                                //# omit self cause otherwise we get stuck in a loop since
                                //# formValue will call on this method
                                if (rubyComponent == _this2) {
                                    return true;
                                }

                                return false;
                            }
                        });

                        var referencedFormValue = _lodash2.default.get(formValue, refKey);

                        return referencedFormValue;
                    },
                    getState: function getState(entireState) {
                        //# TODO: don't know if we need to return something that resembles actual state
                        //# this is used to check if referenced components has error which
                        //# I don't think is necessary if the referenced component hasn't rendered yet
                    },
                    displayValue: function displayValue() {
                        //# get key and then humanize it
                        //# NOTE: we cannot humanize everything because 
                        //# only some fields have "display values" like dropdowns
                        var formValue = spoofedComponent.getFieldValue();

                        // return rubyWords.inflection.humanize(formValue);

                        return formValue;
                    }
                };

                return spoofedComponent;
            }

            return refComponent;
        });

        if (!hasUndefinedComponents) {
            //# only cache if all components are available
            //# it might be undefined becuase of deferred rendering
            this._cachedReferencedComponents = referencedComponents;
        }

        return referencedComponents_wSpoofedMethodsForUndef;
    },
    getReferencedStatesByKey: function getReferencedStatesByKey(entireState) {
        //# TODO: replace referenceSelector with this
        var referencedComponents = this.getReferencedComponents();
        var statesByKey = referencedComponents.reduce(function (collector, referencedComponent) {
            collector[referencedComponent.key()] = referencedComponent.getState(entireState);

            return collector;
        }, {});

        return statesByKey;
    },
    getReferencedValues: function getReferencedValues(entireState) {
        var referencedComponents = this.getReferencedComponents();

        var keyForReferenceComponent = this.props.keyForReferenceComponent;
        /*
        const referenceComponent = _.first(referencedComponents);
         const referenceState = referenceComponent? referenceComponent.getState() :{};
        return _.get(
            referenceState
            , ['fields', _.get(referenceComponent, 'props.key'), 'value']
            , null
        );
        */

        return referencedComponents.map(function (referencedComponent) {
            return referencedComponent.getFieldValue(entireState);
        });
    },
    getReferencedFormValue: function getReferencedFormValue(entireState) {
        var _this3 = this;

        var referencedStateValue = this.getReferencedValues(entireState);
        var referencedDisplayValue = (this.getState(entireState) || {}).referencedDisplayValues;

        var valueMapper = _lodash2.default.isString(this.props.referenceComponentValueMap) ? function (value) {
            var compiled = _lodash2.default.template(_this3.props.referenceComponentValueMap, { imports: { '_': _lodash2.default } });
            return compiled({ data: value });
        } : this.props.referenceComponentValueMap;

        return referencedDisplayValue ? valueMapper(referencedDisplayValue) : valueMapper(referencedStateValue);
    }

    //# overrides
    , _formValueFromLocalState: function _formValueFromLocalState(selfState, isError) {
        var predicateFormatter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (value) {
            return value;
        };
        var entireState = arguments[3];
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        if (selfState == undefined) {
            return undefined;
        }
        var isFieldActive = this.isFieldActiveForNamespace_fromState(entireState);
        if (!isFieldActive || _lodash2.default.isFunction(options.omitFieldPicker) && options.omitFieldPicker.apply(options, [this].concat(Array.prototype.slice.call(arguments)))) {
            return {};
        }
        /**
         * NOTE: Other comps that override this function will usually:
         *       - get any children values
         *       - figure out the form value based on children values or not
         *
         * We decided not to do that here because this comp doesn't have children
         * and it would mean copy/pasting a large chunk of code
         */

        // Get any value the user typed into this field and the referenced field's value
        var formValue = this._getValuesFromFieldsObject(selfState.fields, isError, predicateFormatter, options);

        // Choose between the typed in value or the referenced field's value
        var selfKey = this.props.key;

        var referencedFormValue = this.props.suggestedMode ? null : predicateFormatter(this.getReferencedFormValue(entireState));

        //const referencedFormValue = predicateFormatter(this.getReferencedFormValue(entireState));

        var finalValue = isError ? formValue : _extends({}, formValue, _defineProperty({}, selfKey, formValue[selfKey] != null ? formValue[selfKey] : referencedFormValue));

        if (options.excludeNull && _lodash2.default.isNil(finalValue[selfKey])) {
            delete finalValue[selfKey];
        }

        return finalValue;
    },

    _objectValueFromLocalState: function _objectValueFromLocalState(selfState, isError, limitToTabWithLabel) {
        //# Pretty hacky just to support returning the correct error for referenced values
        var last_mixin = mixinUtils.superMixinContaining(this, '_objectValueFromLocalState');

        var superValue = last_mixin._objectValueFromLocalState.apply(this, arguments);

        var selfStateValue = _lodash2.default.get(selfState, ['fields', _lodash2.default.get(this, 'props.key'), 'value']);
        var referencedStateValues = this.getReferencedValues();

        if (!isError && !selfStateValue && referencedStateValues.filter(function (i) {
            return i;
        }).length) {
            return undefined;
        }

        return superValue;
    },

    refreshReferencedDisplayValue: function refreshReferencedDisplayValue() {
        var _this4 = this;

        var _getAction = this.getAction(),
            generators = _getAction.generators;

        var referencedComponents = this.getReferencedComponents();

        var promisedDisplayValues = referencedComponents.map(function (referencedComponent) {
            return referencedComponent.displayValue().then(function (displayValue) {
                return displayValue[referencedComponent.key()];
            });
        });

        var promisedDisplayValue = Promise.all(promisedDisplayValues).then(function (displayValues) {
            _this4.getStore().dispatch(generators.updateReferencedDisplayValues(displayValues));
        });

        return promisedDisplayValue;
    },
    refreshReferencedDisplayValueIfFormValueIsDifferent: function refreshReferencedDisplayValueIfFormValueIsDifferent() {
        var previousReferencedValues = this._cached_referencedValues;
        var nextReferencedValues = this.getReferencedValues();
        var referencedValuesAreDifferent = !_lodash2.default.isEqual(previousReferencedValues, nextReferencedValues);

        this._cached_referencedValues = nextReferencedValues;

        if (referencedValuesAreDifferent) {
            this.refreshReferencedDisplayValue();
        }
    },

    onReduxInit: function onReduxInit(dispatch) {
        var _this5 = this;

        var selfModule = this;
        var store = selfModule.getStore();

        this.props.displayValueMode && this.refreshReferencedDisplayValueIfFormValueIsDifferent();
        return store.subscribe(function () {
            var prevReferencedValues = selfModule._prevReferencedValues;
            var currReferencedValues = selfModule.getReferencedValues();

            var currentValue = _lodash2.default.get(_this5.getState().fields, _this5.props.key);
            //# NOTE: don't call formValue() cause if value is null 
            //# it gets stuck in an infinite loop
            var isLinkedToReferencedValue = currentValue == null;
            var isReferencedValueChanged = prevReferencedValues != currReferencedValues;

            _this5.props.displayValueMode && _this5.refreshReferencedDisplayValueIfFormValueIsDifferent();
            if (isLinkedToReferencedValue && isReferencedValueChanged) {
                _this5._prevReferencedValues = currReferencedValues;
                dispatch(selfModule.getAction().generators.setFieldErrorMessageByKey(null, selfModule.props.key));
                selfModule.refreshParentErrors();
            }
        });
    }
});

module.exports = RCAutopopulatedText;