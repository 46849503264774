'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var ReactDOM = _interopRequireWildcard(_reactDom);

var _transitions = require('material-ui/styles/transitions');

var _transitions2 = _interopRequireDefault(_transitions);

var _Chip = require('@rubyapps/ruby-material-ui/Chip');

var _Chip2 = _interopRequireDefault(_Chip);

var _Avatar = require('material-ui/Avatar');

var _Avatar2 = _interopRequireDefault(_Avatar);

var _dragHandle = require('material-ui/svg-icons/editor/drag-handle');

var _dragHandle2 = _interopRequireDefault(_dragHandle);

var _reactGridLayout = require('@rubyapps/react-grid-layout');

var _reactGridLayout2 = _interopRequireDefault(_reactGridLayout);

var _TokenTaggerList_style = require('./TokenTaggerList_style');

var _TokenTaggerList_style2 = _interopRequireDefault(_TokenTaggerList_style);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PropTypes = _react2.default.PropTypes;

//# Material UI


//# React Grid Layout

var ReactGridLayout = (0, _reactGridLayout.WidthProvider)(_reactGridLayout2.default);

//# Custom Components
var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label;

//# Styles


var fieldBaseStyles = process.browser ? require('@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule').default : {};
var tokenTaggerListStyles = process.browser ? require('./TokenTaggerList.cssModule').default : {};
var styles = _extends({}, fieldBaseStyles, tokenTaggerListStyles);


var DEFAULT_BOX_SIZE = {
    height: 32
};

var getStyles = function getStyles(props, context, state) {
    var _context$muiTheme = context.muiTheme,
        baseTheme = _context$muiTheme.baseTheme,
        _context$muiTheme$tex = _context$muiTheme.textField,
        floatingLabelColor = _context$muiTheme$tex.floatingLabelColor,
        focusColor = _context$muiTheme$tex.focusColor,
        textColor = _context$muiTheme$tex.textColor,
        disabledTextColor = _context$muiTheme$tex.disabledTextColor,
        backgroundColor = _context$muiTheme$tex.backgroundColor,
        hintColor = _context$muiTheme$tex.hintColor,
        errorColor = _context$muiTheme$tex.errorColor,
        palette = _context$muiTheme.palette;


    var styles = {
        root: {
            fontSize: 16,
            lineHeight: '24px',
            width: props.fullWidth ? '100%' : 256,
            height: (props.rows - 1) * 24 + (props.floatingLabelText ? 72 : 48),
            display: 'inline-block',
            position: 'relative',
            fontFamily: baseTheme.fontFamily,
            transition: _transitions2.default.easeOut('200ms', 'height')
        },
        error: {
            position: 'relative',
            bottom: -6,
            fontSize: 12,
            lineHeight: '12px',
            color: errorColor,
            transition: _transitions2.default.easeOut()
        },
        icon_disabled: {
            color: palette.disabledColor_level2,
            fill: palette.disabledColor_level2,
            cursor: 'not-allowed'
        },
        deleteIcon_disabled: {
            color: palette.disabledColor_level3,
            fill: palette.disabledColor_level3,
            cursor: 'not-allowed'
        }
    };

    _extends(styles.error, props.errorStyle);

    return styles;
};

var ChipItem = function (_Component) {
    _inherits(ChipItem, _Component);

    function ChipItem() {
        _classCallCheck(this, ChipItem);

        return _possibleConstructorReturn(this, (ChipItem.__proto__ || Object.getPrototypeOf(ChipItem)).apply(this, arguments));
    }

    _createClass(ChipItem, [{
        key: 'render',
        value: function render() {
            var _React$createElement, _extends2, _extends3;

            var muiTheme = this.context.muiTheme;
            var prepareStyles = muiTheme.prepareStyles;

            var muiStyleObject = getStyles(this.props, this.context, this.state);

            var icon__style_disabled = prepareStyles(muiStyleObject.icon_disabled);
            var deleteIcon__style_disabled = prepareStyles(muiStyleObject.deleteIcon_disabled);

            var primary2Color = muiTheme.baseTheme.palette.primary2Color;

            var _props = this.props,
                id = _props.id,
                text = _props.text,
                sortable = _props.sortable,
                disabled = _props.disabled,
                rest = _objectWithoutProperties(_props, ['id', 'text', 'sortable', 'disabled']);

            var DragAvatar = !sortable ? null : _react2.default.createElement(_Avatar2.default, _extends({}, _TokenTaggerList_style2.default.Avatar, (_extends2 = {
                icon: _react2.default.createElement(_dragHandle2.default, (_React$createElement = { className: styles.DragHandleIcon,
                    style: disabled ? icon__style_disabled : {},
                    'data-codecept-selector-node': 'DragIcon',
                    'data-codecept-selector-file': 'TokenTaggerList'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'DragIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TokenTaggerList'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'DragIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TokenTaggerList'), _React$createElement)),
                'data-codecept-selector-node': 'Avatar',
                'data-codecept-selector-file': 'TokenTaggerList'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_extends2, 'data-codecept-selector-file', 'TokenTaggerList'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_extends2, 'data-codecept-selector-file', 'TokenTaggerList'), _extends2)));

            var Content = _react2.default.createElement(
                _Chip2.default,
                _extends({
                    backgroundColor: 'rgb(232, 232, 232)',
                    allowEventPropagation: true
                }, rest, (_extends3 = {
                    labelStyle: {
                        width: '100%'
                    },
                    style: {
                        width: '100%',
                        boxShadow: '0 1px 1px 1px rgba(0,0,0,0.2)'
                    },
                    deleteIconStyle: disabled ? deleteIcon__style_disabled : {},
                    'data-codecept-selector-node': 'Chip',
                    'data-codecept-selector-file': 'TokenTaggerList'
                }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Chip'), _defineProperty(_extends3, 'data-codecept-selector-file', 'TokenTaggerList'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Chip'), _defineProperty(_extends3, 'data-codecept-selector-file', 'TokenTaggerList'), _extends3)),
                DragAvatar,
                text
            );

            //return (<div>{text}</div>)
            return Content;
        }
    }]);

    return ChipItem;
}(_react.Component);

;
ChipItem.contextTypes = {
    muiTheme: PropTypes.object
};
ChipItem.propTypes = {
    type: PropTypes.string,
    text: PropTypes.string,
    sortable: PropTypes.bool,
    disabled: PropTypes.bool
};

function _getBoxSizeForElement(element) {
    var style = window.getComputedStyle(element, null);
    var width = parseInt(style.getPropertyValue('width'));
    var height = parseInt(style.getPropertyValue('height'));
    return {
        width: isNaN(width) ? 0 : width,
        height: isNaN(height) ? 0 : height
    };
}

var cachedClonedChildren = {};
//# Local cache of the cloned react component so we don't need to reclone the children
//# NOTE: not a good idea if the children component changes dynamically
//# The cache is to allow us to bind to the element and watch for resize, which
//# causes this component to rerender, but we don't want the child to rerender 
//# while it's being interacted with

var TokenTaggerList = _react2.default.createClass({
    displayName: 'TokenTaggerList',

    propTypes: {
        fieldKey: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string,
            text: PropTypes.string,
            type: PropTypes.string
        })),
        delegateValueChange: PropTypes.func,
        delegateDeleteGeneratorForType: PropTypes.func,
        disabled: PropTypes.bool,
        sortable: PropTypes.bool
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            values: [],
            columns: 1,
            layoutWidth: 1200
        };
    },
    getInitialState: function getInitialState() {
        return {
            itemBeingDragged: null,
            layoutWidth: 1200
        };
    },
    componentDidMount: function componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
    },
    _renderChildren: function _renderChildren() {
        var _this2 = this;

        var values = this.props.values;


        return values.map(function (valueObject) {
            var _extends4;

            return _react2.default.createElement(ChipItem, _extends({
                disabled: _this2.props.disabled,
                sortable: _this2.props.sortable,

                key: valueObject.type,
                onRequestDelete: _this2.props.delegateDeleteGeneratorForType(valueObject.type)
            }, valueObject, (_extends4 = {
                'data-codecept-selector-node': 'ChipItem',
                'data-codecept-selector-file': 'TokenTaggerList'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'ChipItem'), _defineProperty(_extends4, 'data-codecept-selector-file', 'TokenTaggerList'), _defineProperty(_extends4, 'data-codecept-selector-node', 'ChipItem'), _defineProperty(_extends4, 'data-codecept-selector-file', 'TokenTaggerList'), _extends4)));
        });
    },
    render: function render() {
        var _this3 = this,
            _React$createElement4;

        var prepareStyles = this.context.muiTheme.prepareStyles;

        var muiStyleObject = getStyles(this.props, this.context, this.state);

        var errorTextElement = null;
        if (this.props.error && this.props.error.message) {
            var _React$createElement2;

            errorTextElement = _react2.default.createElement(
                'div',
                (_React$createElement2 = { style: prepareStyles(muiStyleObject.error), 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'TokenTaggerList'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TokenTaggerList'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TokenTaggerList'), _React$createElement2),
                this.props.error.message
            );
        }

        var layout = [];
        var currentY = 0;
        var margin = [10, 10];

        var childrenElements = this._renderChildren().filter(function (child) {
            return !_lodash2.default.isNull(child);
        });
        var children = childrenElements.map(function (renderedChild, childIdx) {
            var _React$createElement3;

            var type = renderedChild.key;
            var boxSize = DEFAULT_BOX_SIZE;

            var dataGridProps = {
                key: type //# necessary to get ReactGridLayout playing nicely with new instances since the IDs would be completely different
                , i: type,
                x: 0,
                y: currentY,
                w: _this3.state.layoutWidth,
                h: boxSize.height,
                minW: 1 //# TODO: update based on layout for module
                , maxW: 1
                //, static: false //# TODO: no specific request for this
            };
            currentY += boxSize.height + margin[1];

            layout.push(dataGridProps);

            return _react2.default.createElement(
                'div',
                (_React$createElement3 = {
                    key: type,
                    'data-grid': dataGridProps,
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'TokenTaggerList'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TokenTaggerList'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TokenTaggerList'), _React$createElement3),
                renderedChild
            );
        });

        //draggableHandle={`.${styles.DragHandleIcon}`}
        return _react2.default.createElement(
            ReactGridLayout,
            (_React$createElement4 = { className: 'layout',
                placeholderClassName: styles.Placeholder,
                layout: layout,
                isDraggable: this.props.sortable && !this.props.disabled,
                cols: this.props.columns,
                rowHeight: 1,
                width: this.state.layoutWidth,
                isResizable: false,
                horizontalCompact: false,
                verticalCompact: false,
                linearCompact: true,
                margin: margin,
                containerPadding: [0, 0],
                onDragStart: this._handleDragStart,
                onDragStop: this._handleDragStop,
                onLayoutChange: this._handleLayoutChange,
                ref: function ref(theReactGridLayout) {
                    var layoutElement = theReactGridLayout ? ReactDOM.findDOMNode(theReactGridLayout) : undefined;
                    _this3._layoutElement = layoutElement;
                },
                'data-codecept-selector-node': 'ReactGridLayout',
                'data-codecept-selector-file': 'TokenTaggerList'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ReactGridLayout'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TokenTaggerList'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ReactGridLayout'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TokenTaggerList'), _React$createElement4),
            children
        );
    },
    componentWillUnmount: function componentWillUnmount() {

        //# stop listening to layout width changes
        window.removeEventListener('resize', this.onWindowResize);
        this._layoutElement = undefined;
    }

    //# Custom handlers
    , onWindowResize: function onWindowResize(_event) {
        if (!this._layoutElement) {
            return;
        };

        this.setState({ layoutWidth: this._layoutElement.offsetWidth });
    },
    setItemBeingDragged: function setItemBeingDragged(id) {
        return;
        this.setState({
            itemBeingDragged: id
        });
    },
    _handleDragStart: function _handleDragStart(layout, oldItem, newItem, placeholder, evt, htmlElement) {
        this.setItemBeingDragged(oldItem.i);
    },
    _handleDragStop: function _handleDragStop(layout, oldItem, newItem, placeholder, evt, htmlElement) {
        var sortedByXLayout = layout.sort(function (a, b) {
            return a.x - b.x;
        });
        var sortedByYLayout = sortedByXLayout.sort(function (a, b) {
            return a.y - b.y;
        });

        var indexOfNewPlacement = sortedByYLayout.indexOf(newItem);

        var leftSibling = sortedByYLayout[indexOfNewPlacement - 1];
        var rightSibling = sortedByYLayout[indexOfNewPlacement + 1];

        this.setItemBeingDragged(null);
        //# NOTE: due to how the reactGridLayout component internally calls on setState
        //# we need to call on a 'setImmediate' if we want to trigger the relayout last
        //# this would be to allow static fields to be pushed to the top
        //# however, note: the static fields would not be pushed down so we lose the opportunity to shove groups of fields around

        var itemOrder = sortedByYLayout.filter(function (item) {
            return !item.static;
        }).map(function (item) {
            return item.i;
        });

        this.props.delegateValueChange(evt, itemOrder);
    },
    _handleLayoutChange: function _handleLayoutChange(layout, oldItem, newItem, placeholder, evt, htmlElement) {},
    _updateBoxSizeForId: function _updateBoxSizeForId(boxSize, id) {
        var currentBoxSize = this.state.boxSizesByChildId[id];
        if (!_lodash2.default.isEqual(boxSize, currentBoxSize)) {
            this.setState({
                boxSizesByChildId: _extends({}, this.state.boxSizesByChildId, _defineProperty({}, id, boxSize))
            });
        }
    },
    _updateSizeChangeCallbackForId: function _updateSizeChangeCallbackForId(callback, id) {
        this.setState({
            sizeChangeCallbacksByChildId: _extends({}, this.state.sizeChangeCallbacksByChildId, _defineProperty({}, id, callback))
        });
    }
});

exports.default = TokenTaggerList;