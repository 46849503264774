'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _reactRedux = require('react-redux');

var _ConnectorMappersGenerator = require('@rubyapps/ruby-component-mixin-field-base/src/client/reactComponents/ConnectorMappersGenerator');

var _ConnectorMappersGenerator2 = _interopRequireDefault(_ConnectorMappersGenerator);

var _RubyComponentFieldArrayMap = require('./RubyComponentFieldArrayMap');

var _RubyComponentFieldArrayMap2 = _interopRequireDefault(_RubyComponentFieldArrayMap);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SELFMODULE_ACTION_KEYS = ['requestToAppendChildren', 'requestToDeleteChild', 'requestToUpdateExistingChild', 'requestToEditChild', 'requestToCancelEdit'];

//# NOTE: we're not using addInfoMode props HOC because this is a fairly complex widget
function generator() {
    var selfModule = this;
    //# NOTE: use this if you're writing a field component

    var _defaultMappersGenera = _ConnectorMappersGenerator2.default.call(this),
        defaultMapStateToProps = _defaultMappersGenera.mapStateToProps,
        defaultMapDispatchToProps = _defaultMappersGenera.mapDispatchToProps;

    var selfModuleActions = _lodash2.default.reduce(_lodash2.default.pick(selfModule, SELFMODULE_ACTION_KEYS), function (collector, func, key) {

        collector[key] = func.bind(selfModule);
        return collector;
    }, {});

    var cachedDisplayValue = void 0;

    function mapStateToProps(state, ownProps) {
        var mappedDefaultProps = defaultMapStateToProps.apply(null, arguments);
        var selfState = selfModule.getState(state);

        return _extends({}, mappedDefaultProps, selfModuleActions, {
            actionButtons: selfModule.hydratedActionButtons(mappedDefaultProps.actionButtons),
            selectedValue: selfState.selectedValue,
            formHasData: selfModule.childrenFormValueHasData(),
            displayValue: selfModule.unhydratedDisplayValueByHydratedItems()
        });
    }

    /*
    function mapDispatchToProps (dispatch, ownProps) {
        return Object.assign({
            delegateActions: {
                mediaGallery: bindActionCreators(mediaGalleryComponent.getAction().generators, dispatch)
            }
        }, defaultMapDispatchToProps.apply(null, arguments));
    }
    */

    //return connect(mapStateToProps, mapDispatchToProps)(RubyComponentFieldArrayMap);
    return (0, _reactRedux.connect)(mapStateToProps, defaultMapDispatchToProps)(_RubyComponentFieldArrayMap2.default);
}