'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp, _initialiseProps;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _shallowEqual = require('recompose/shallowEqual');

var _shallowEqual2 = _interopRequireDefault(_shallowEqual);

var _ClickAwayListener = require('material-ui/internal/ClickAwayListener');

var _ClickAwayListener2 = _interopRequireDefault(_ClickAwayListener);

var _keycode = require('keycode');

var _keycode2 = _interopRequireDefault(_keycode);

var _propTypes = require('material-ui/utils/propTypes');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _menuUtils = require('material-ui/Menu/menuUtils');

var _reactVirtualized = require('react-virtualized');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PADDING = 8;

function getStyles(props, context) {
  var desktop = props.desktop,
      maxHeight = props.maxHeight,
      virtualMenuMinWidth = props.virtualMenuMinWidth;
  var muiTheme = context.muiTheme;


  var styles = {
    root: {
      // Nested div bacause the List scales x faster than it scales y
      zIndex: muiTheme.zIndex.menu,
      //maxHeight: maxHeight, //# had to remove this because it was messing with the scrollbar in Edge
      overflowY: maxHeight ? 'auto' : null
    },
    divider: {
      marginTop: 7,
      marginBottom: 8
    },
    list: {
      display: 'table-cell',
      paddingBottom: desktop ? 16 : 8,
      paddingTop: desktop ? 16 : 8,
      userSelect: 'none',
      width: virtualMenuMinWidth
    },
    selectedMenuItem: {
      color: muiTheme.baseTheme.palette.accent1Color
    }
  };

  return styles;
}

var Menu = (_temp = _class = function (_Component) {
  _inherits(Menu, _Component);

  function Menu(props, context) {
    _classCallCheck(this, Menu);

    var _this = _possibleConstructorReturn(this, (Menu.__proto__ || Object.getPrototypeOf(Menu)).call(this, props, context));

    _initialiseProps.call(_this);

    var filteredChildren = _this.getFilteredChildren(props.children);
    var selectedIndex = _this.getSelectedIndex(props, filteredChildren);

    _this.state = {
      focusIndex: props.disableAutoFocus ? -1 : selectedIndex >= 0 ? selectedIndex : 0,
      isKeyboardFocused: props.initiallyKeyboardFocused,
      keyWidth: props.desktop ? 64 : 56,
      el__originalStyleWidth: '100%',
      listEl__originalStyleWidth: '100%'
    };

    _this.hotKeyHolder = new _menuUtils.HotKeyHolder();
    return _this;
  }

  _createClass(Menu, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var self = this;
      if (this.props.autoWidth) {
        this.storeWidth();
        this.setWidth();
      }
      var filteredChildren = this.getFilteredChildren(this.props.children);
      var selectedIndex = this.getSelectedIndex(this.props, filteredChildren);
      var offset = selectedIndex * self.getMenuItemHeight() + 16;
      var offsetOfBottomOfSelectedIndex = offset + self.getMenuItemHeight();
      window.setImmediate(function () {
        if (offsetOfBottomOfSelectedIndex > self.props.maxHeight) {
          self.refs.list.scrollToPosition(offset);
        }
      });
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      var filteredChildren = this.getFilteredChildren(nextProps.children);
      var selectedIndex = this.getSelectedIndex(nextProps, filteredChildren);

      this.setState({
        focusIndex: nextProps.disableAutoFocus ? -1 : selectedIndex >= 0 ? selectedIndex : 0,
        isKeyboardFocused: !nextProps.disabledAutoFocus && nextProps.initiallyKeyboardFocused,
        keyWidth: nextProps.desktop ? 64 : 56
      });
    }
  }, {
    key: 'shouldComponentUpdate',
    value: function shouldComponentUpdate(nextProps, nextState, nextContext) {
      return !(0, _shallowEqual2.default)(this.props, nextProps) || !(0, _shallowEqual2.default)(this.state, nextState) || !(0, _shallowEqual2.default)(this.context, nextContext);
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate() {
      if (this.props.autoWidth) this.setWidth();
    }
  }, {
    key: 'scrollToIndex',
    value: function scrollToIndex(index) {
      var _this2 = this;

      if (this.refs.list == undefined) {
        return;
      }

      var maxHeight = this.props.maxHeight;
      var _refs$list$props = this.refs.list.props,
          _refs$list$props$heig = _refs$list$props.height,
          listHeight = _refs$list$props$heig === undefined ? maxHeight : _refs$list$props$heig,
          listRowCount = _refs$list$props.rowCount;

      var currentScrollTop = this.refs.list.Grid.state.scrollTop;

      var menuItemHeight = this.getMenuItemHeight();
      var maxScrollTop = this.props.children.length * menuItemHeight - listHeight;

      var numberOfVisibleRows = Math.round(listHeight / menuItemHeight);
      var halfOfVisibleRows = numberOfVisibleRows / 2;

      var menuHeightAtIndex = index * menuItemHeight;

      var targetOffset = (index - halfOfVisibleRows) * menuItemHeight;
      targetOffset = targetOffset < 0 ? 0 : targetOffset > maxScrollTop ? maxScrollTop : targetOffset;

      if (index < halfOfVisibleRows) {
        //# part of the first x rows
        targetOffset = targetOffset < currentScrollTop ? targetOffset : undefined;
      } else if (index > listRowCount - halfOfVisibleRows) {
        //# handle figuring out whether we need to scroll or not
        targetOffset = targetOffset > currentScrollTop ? targetOffset : undefined;
      }

      window.setImmediate(function () {
        if (targetOffset != undefined) {
          _this2.refs.list && _this2.refs.list.scrollToPosition(targetOffset);
        }
      });

      this.setFocusIndex(index, true);
    }
  }, {
    key: 'getValueLink',


    // Do not use outside of this component, it will be removed once valueLink is deprecated
    value: function getValueLink(props) {
      return props.valueLink || {
        value: props.value,
        requestChange: props.onChange
      };
    }
  }, {
    key: 'setKeyboardFocused',
    value: function setKeyboardFocused(keyboardFocused) {
      this.setState({
        isKeyboardFocused: keyboardFocused
      });
    }
  }, {
    key: 'getFilteredChildren',
    value: function getFilteredChildren(children) {
      var filteredChildren = [];
      _react2.default.Children.forEach(children, function (child) {
        if (child) {
          filteredChildren.push(child);
        }
      });
      return filteredChildren;
    }
  }, {
    key: 'cloneMenuItem',
    value: function cloneMenuItem(child, childIndex, styles, index) {
      var _this3 = this;

      var _props = this.props,
          desktop = _props.desktop,
          selectedMenuItemStyle = _props.selectedMenuItemStyle;


      var selected = this.isChildSelected(child, this.props);
      var selectedChildrenStyles = {};

      if (selected) {
        selectedChildrenStyles = _extends(styles.selectedMenuItem, selectedMenuItemStyle);
      }

      var mergedChildrenStyles = _extends({}, child.props.style, selectedChildrenStyles);

      var isFocused = childIndex === this.state.focusIndex;
      var focusState = 'none';
      if (isFocused) {
        focusState = this.state.isKeyboardFocused ? 'keyboard-focused' : 'focused';
      }

      return _react2.default.cloneElement(child, _extends({
        desktop: desktop,
        focusState: focusState,
        onTouchTap: function onTouchTap(event) {
          _this3.handleMenuItemTouchTap(event, child, index);
          if (child.props.onTouchTap) child.props.onTouchTap(event);
        },
        style: mergedChildrenStyles
      }, child.ref ? {} : {
        ref: isFocused ? 'focusedMenuItem' : null //# NOTE: do not override if ref is already provided
      }));
    }
  }, {
    key: 'decrementKeyboardFocusIndex',
    value: function decrementKeyboardFocusIndex() {
      var index = this.state.focusIndex;

      index--;
      if (index < 0) index = 0;

      this.setFocusIndex(index, true);
    }
  }, {
    key: 'getMenuItemCount',
    value: function getMenuItemCount(filteredChildren) {
      var menuItemCount = 0;
      filteredChildren.forEach(function (child) {
        var childIsADivider = child.type && child.type.muiName === 'Divider';
        var childIsDisabled = child.props.disabled;
        if (!childIsADivider && !childIsDisabled) menuItemCount++;
      });
      return menuItemCount;
    }
  }, {
    key: 'getSelectedIndex',
    value: function getSelectedIndex(props, filteredChildren) {
      var _this4 = this;

      var selectedIndex = -1;
      var menuItemIndex = 0;

      filteredChildren.forEach(function (child) {
        var childIsADivider = child.type && child.type.muiName === 'Divider';

        if (_this4.isChildSelected(child, props)) selectedIndex = menuItemIndex;
        if (!childIsADivider) menuItemIndex++;
      });

      return selectedIndex;
    }
  }, {
    key: 'setFocusIndexStartsWith',
    value: function setFocusIndexStartsWith(keys) {
      var foundIndex = -1;
      _react2.default.Children.forEach(this.props.children, function (child, index) {
        if (foundIndex >= 0) {
          return;
        }
        var primaryText = child.props.primaryText;

        if (typeof primaryText === 'string' && new RegExp('^' + keys, 'i').test(primaryText)) {
          foundIndex = index;
        }
      });
      if (foundIndex >= 0) {
        this.setFocusIndex(foundIndex, true);
        return true;
      }
      return false;
    }
  }, {
    key: 'handleMenuItemTouchTap',
    value: function handleMenuItemTouchTap(event, item, index) {
      var children = this.props.children;
      var multiple = this.props.multiple;
      var valueLink = this.getValueLink(this.props);
      var menuValue = valueLink.value;
      var itemValue = item.props.value;
      var focusIndex = _react2.default.isValidElement(children) ? 0 : children.indexOf(item);

      this.setFocusIndex(focusIndex, false);

      if (multiple) {
        var itemIndex = menuValue.indexOf(itemValue);

        var _menuValue = _toArray(menuValue),
            newMenuValue = _menuValue.slice(0);

        if (itemIndex === -1) {
          newMenuValue.push(itemValue);
        } else {
          newMenuValue.splice(itemIndex, 1);
        }

        valueLink.requestChange(event, newMenuValue);
      } else if (!multiple && itemValue !== menuValue) {
        valueLink.requestChange(event, itemValue);
      }

      this.props.onItemTouchTap(event, item, index);
    }
  }, {
    key: 'incrementKeyboardFocusIndex',
    value: function incrementKeyboardFocusIndex(filteredChildren) {
      var index = this.state.focusIndex;
      var maxIndex = this.getMenuItemCount(filteredChildren) - 1;

      index++;
      if (index > maxIndex) index = maxIndex;

      this.setFocusIndex(index, true);
    }
  }, {
    key: 'isChildSelected',
    value: function isChildSelected(child, props) {
      var menuValue = this.getValueLink(props).value;
      var childValue = child.props.value;

      if (props.multiple) {
        return menuValue.length && menuValue.indexOf(childValue) !== -1;
      } else {
        return child.props.hasOwnProperty('value') && menuValue === childValue;
      }
    }
  }, {
    key: 'setFocusIndex',
    value: function setFocusIndex(newIndex, isKeyboardFocused) {
      this.setState({
        focusIndex: newIndex,
        isKeyboardFocused: isKeyboardFocused
      });
    }
  }, {
    key: 'getMenuItemHeight',
    value: function getMenuItemHeight() {
      var desktop = this.props.desktop;
      var menuItemHeight = desktop ? 32 : 48;
      return menuItemHeight;
    }
  }, {
    key: 'rowHeightForIndexWithChildren',
    value: function rowHeightForIndexWithChildren(_ref) {
      var index = _ref.index,
          children = _ref.children;

      var specifiedHeight = _.get(children, [index, 'props', 'virtualListHeight']);
      if (specifiedHeight) {
        return specifiedHeight;
      } else {
        return this.getMenuItemHeight();
      }
    }
  }, {
    key: 'storeWidth',
    value: function storeWidth() {
      var el = _reactDom2.default.findDOMNode(this);
      var listEl = _reactDom2.default.findDOMNode(this.refs.list);
      this.setState({
        el__originalStyleWidth: el.style.width,
        listEl__originalStyleWidth: listEl.style.width
      });
    }
  }, {
    key: 'getWidthToSet',
    value: function getWidthToSet() {
      var self = this;

      var elWidth = self.state.maxWidth;
      //# cache default width

      var keyWidth = this.state.keyWidth;
      var minWidth = keyWidth * 1.5;
      var keyIncrements = elWidth / keyWidth;
      var newWidth = void 0;

      keyIncrements = keyIncrements <= 1.5 ? 1.5 : Math.ceil(keyIncrements);
      newWidth = keyIncrements * keyWidth;

      if (newWidth < minWidth) newWidth = minWidth;

      // Add buffer to make sure element widths clear the scrollbar
      var widthBuffer = this.state.keyWidth * .5;
      return newWidth + widthBuffer;
    }
  }, {
    key: 'setWidth',
    value: function setWidth() {
      var el = _reactDom2.default.findDOMNode(this);
      var listEl = _reactDom2.default.findDOMNode(this.refs.list);

      var newWidth = this.getWidthToSet();

      el.style.width = newWidth + 'px';
      listEl.style.width = newWidth + 'px';
    }
  }, {
    key: 'render',
    value: function render() {
      var _this5 = this,
          _extends2,
          _extends3,
          _React$createElement;

      var self = this;

      var _props2 = this.props,
          autoWidth = _props2.autoWidth,
          children = _props2.children,
          desktop = _props2.desktop,
          disableAutoFocus = _props2.disableAutoFocus,
          initiallyKeyboardFocused = _props2.initiallyKeyboardFocused,
          listStyle = _props2.listStyle,
          maxHeight = _props2.maxHeight,
          multiple = _props2.multiple,
          onItemTouchTap = _props2.onItemTouchTap,
          onEscKeyDown = _props2.onEscKeyDown,
          selectedMenuItemStyle = _props2.selectedMenuItemStyle,
          style = _props2.style,
          value = _props2.value,
          valueLink = _props2.valueLink,
          virtualMenuMinWidth = _props2.virtualMenuMinWidth,
          onMouseEnter = _props2.onMouseEnter,
          onMouseLeave = _props2.onMouseLeave,
          other = _objectWithoutProperties(_props2, ['autoWidth', 'children', 'desktop', 'disableAutoFocus', 'initiallyKeyboardFocused', 'listStyle', 'maxHeight', 'multiple', 'onItemTouchTap', 'onEscKeyDown', 'selectedMenuItemStyle', 'style', 'value', 'valueLink', 'virtualMenuMinWidth', 'onMouseEnter', 'onMouseLeave']);

      var prepareStyles = this.context.muiTheme.prepareStyles;

      var styles = getStyles(this.props, this.context);

      var mergedRootStyles = _extends(styles.root, style);
      var mergedListStyles = _extends(styles.list, listStyle);

      var filteredChildren = this.getFilteredChildren(children);

      var menuItemIndex = 0;
      var newChildren = _react2.default.Children.map(filteredChildren, function (child, index) {
        var childIsADivider = child.type && child.type.muiName === 'Divider';
        var childIsDisabled = child.props.disabled;

        var clonedChild = childIsADivider ? _react2.default.cloneElement(child, { style: styles.divider }) : childIsDisabled ? _react2.default.cloneElement(child, { desktop: desktop }) : _this5.cloneMenuItem(child, menuItemIndex, styles, index);

        if (!childIsADivider && !childIsDisabled) {
          menuItemIndex++;
        }

        return clonedChild;
      });

      var selectedIndex = newChildren.findIndex(function (child) {
        return child.key === value;
      });
      return _react2.default.createElement(
        _ClickAwayListener2.default,
        (_React$createElement = { onClickAway: this.handleClickAway, 'data-codecept-selector-node': 'ClickAwayListener',
          'data-codecept-selector-file': 'Menu'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ClickAwayListener'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Menu'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ClickAwayListener'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Menu'), _React$createElement),
        _react2.default.createElement(
          'div',
          _extends({
            onKeyDown: this.handleKeyDown,
            style: prepareStyles(mergedRootStyles)
          }, {
            onMouseEnter: onMouseEnter,
            onMouseLeave: onMouseLeave
          }, (_extends3 = {
            'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'Menu'
          }, _defineProperty(_extends3, 'data-codecept-selector-node', 'div'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Menu'), _defineProperty(_extends3, 'data-codecept-selector-node', 'div'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Menu'), _extends3)),
          _react2.default.createElement(_reactVirtualized.List, _extends({
            width: Math.max(virtualMenuMinWidth, self.getWidthToSet() || 0),
            height: Math.min(Number.parseInt(maxHeight), PADDING * 2 + newChildren.reduce(function (accumulator, child, index) {
              return accumulator + _this5.rowHeightForIndexWithChildren({ index: index, children: newChildren });
            }, 0)),
            rowCount: newChildren.length,
            rowHeight: function rowHeight(_ref2) {
              var index = _ref2.index;

              return _this5.rowHeightForIndexWithChildren({
                index: index,
                children: newChildren
              });
            },
            rowRenderer: rowRenderer,
            ref: 'list'
          }, other, (_extends2 = {
            style: {
              paddingTop: PADDING,
              paddingBottom: PADDING,
              minWidth: virtualMenuMinWidth
            },
            'data-codecept-selector-node': 'List',
            'data-codecept-selector-file': 'Menu'
          }, _defineProperty(_extends2, 'data-codecept-selector-node', 'List'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Menu'), _defineProperty(_extends2, 'data-codecept-selector-node', 'List'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Menu'), _extends2)))
        )
      );
      function rowRenderer(_ref3) {
        var _React$createElement2;

        var index = _ref3.index,
            isScrolling = _ref3.isScrolling,
            isVisible = _ref3.isVisible,
            key = _ref3.key,
            parent = _ref3.parent,
            style = _ref3.style;

        var menuItemStyle = _.chain(style).omit(['width']).assign({
          minWidth: '100%'
        }).value();
        return _react2.default.createElement(
          'div',
          (_React$createElement2 = {
            style: menuItemStyle,
            key: index,
            ref: function ref(input) {
              if (!isVisible) return;
              var width = input && input.offsetWidth || 0;
              var parentWidth = input && input.parentElement.offsetWidth;
              var storedMaxWidth = self.state.maxWidth || 0;
              if (width > storedMaxWidth && width > parentWidth) {
                self.setState({
                  maxWidth: width
                });
              }
            },
            'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'Menu'
          }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Menu'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Menu'), _React$createElement2),
          newChildren[index]
        );
      }
    }
  }]);

  return Menu;
}(_react.Component), _class.propTypes = {
  /**
   * If true, the width of the menu will be set automatically
   * according to the widths of its children,
   * using proper keyline increments (64px for desktop,
   * 56px otherwise).
   */
  autoWidth: _react.PropTypes.bool,
  /**
   * The content of the menu. This is usually used to pass `MenuItem`
   * elements.
   */
  children: _react.PropTypes.node,
  /**
   * If true, the menu item will render with compact desktop styles.
   */
  desktop: _react.PropTypes.bool,
  /**
   * If true, the menu will not be auto-focused.
   */
  disableAutoFocus: _react.PropTypes.bool,
  /**
   * If true, the menu will be keyboard-focused initially.
   */
  initiallyKeyboardFocused: _react.PropTypes.bool,
  /**
   * Override the inline-styles of the underlying `List` element.
   */
  listStyle: _react.PropTypes.object,
  /**
   * The maximum height of the menu in pixels. If specified,
   * the menu will be scrollable if it is taller than the provided
   * height.
   */
  maxHeight: _react.PropTypes.number,
  /**
   * If true, `value` must be an array and the menu will support
   * multiple selections.
   */
  multiple: _react.PropTypes.bool,
  /**
   * Callback function fired when a menu item with `value` not
   * equal to the current `value` of the menu is touch-tapped.
   *
   * @param {object} event TouchTap event targeting the menu item.
   * @param {any}  value If `multiple` is true, the menu's `value`
   * array with either the menu item's `value` added (if
   * it wasn't already selected) or omitted (if it was already selected).
   * Otherwise, the `value` of the menu item.
   */
  onChange: _react.PropTypes.func,
  /**
   * Callback function fired when the menu is focused and the *Esc* key
   * is pressed.
   *
   * @param {object} event `keydown` event targeting the menu.
   */
  onEscKeyDown: _react.PropTypes.func,
  /**
   * Callback function fired when a menu item is touch-tapped.
   *
   * @param {object} event TouchTap event targeting the menu item.
   * @param {object} menuItem The menu item.
   * @param {number} index The index of the menu item.
   */
  onItemTouchTap: _react.PropTypes.func,
  /** @ignore */
  onKeyDown: _react.PropTypes.func,
  /**
   * Override the inline-styles of selected menu items.
   */
  selectedMenuItemStyle: _react.PropTypes.object,
  /**
   * Override the inline-styles of the root element.
   */
  style: _react.PropTypes.object,
  /**
   * If `multiple` is true, an array of the `value`s of the selected
   * menu items. Otherwise, the `value` of the selected menu item.
   * If provided, the menu will be a controlled component.
   * This component also supports valueLink.
   */
  value: _react.PropTypes.any,
  /**
   * ValueLink for the menu's `value`.
   */
  valueLink: _react.PropTypes.object,
  /**
   * The width of the menu. If not specified, the menu's width
   * will be set according to the widths of its children, using
   * proper keyline increments (64px for desktop, 56px otherwise).
   */
  virtualMenuMinWidth: _propTypes2.default.stringOrNumber
}, _class.defaultProps = {
  autoWidth: true,
  desktop: false,
  disableAutoFocus: false,
  initiallyKeyboardFocused: false,
  maxHeight: 500,
  multiple: false,
  onChange: function onChange() {},
  onEscKeyDown: function onEscKeyDown() {},
  onItemTouchTap: function onItemTouchTap() {},
  onKeyDown: function onKeyDown() {},
  virtualMenuMinWidth: 200
}, _class.contextTypes = {
  muiTheme: _react.PropTypes.object.isRequired
}, _initialiseProps = function _initialiseProps() {
  var _this6 = this;

  this.handleClickAway = function (event) {
    if (event.defaultPrevented) {
      return;
    }

    _this6.setFocusIndex(-1, false);
  };

  this.handleKeyDown = function (event) {
    var filteredChildren = _this6.getFilteredChildren(_this6.props.children);
    var key = (0, _keycode2.default)(event);
    switch (key) {
      case 'down':
        event.preventDefault();
        _this6.incrementKeyboardFocusIndex(filteredChildren);
        break;
      case 'esc':
        _this6.props.onEscKeyDown(event);
        break;
      case 'tab':
        event.preventDefault();
        if (event.shiftKey) {
          _this6.decrementKeyboardFocusIndex();
        } else {
          _this6.incrementKeyboardFocusIndex(filteredChildren);
        }
        break;
      case 'up':
        event.preventDefault();
        _this6.decrementKeyboardFocusIndex();
        break;
      default:
        if (key && key.length === 1) {
          var hotKeys = _this6.hotKeyHolder.append(key);
          if (_this6.setFocusIndexStartsWith(hotKeys)) {
            event.preventDefault();
          }
        }
    }
    _this6.props.onKeyDown(event);
  };
}, _temp);
exports.default = Menu;