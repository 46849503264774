'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SnackbarBody = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; //# https://raw.githubusercontent.com/mui/material-ui/v0.15.4/src/Snackbar/SnackbarBody.js

//import transitions from '../styles/transitions';
//import withWidth, {SMALL} from '../utils/withWidth';
//import FlatButton from '../FlatButton';


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _transitions = require('material-ui/styles/transitions');

var _transitions2 = _interopRequireDefault(_transitions);

var _withWidth = require('material-ui/utils/withWidth');

var _withWidth2 = _interopRequireDefault(_withWidth);

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function getStyles(props, context) {
  var open = props.open,
      width = props.width;
  var _context$muiTheme = context.muiTheme,
      _context$muiTheme$bas = _context$muiTheme.baseTheme,
      _context$muiTheme$bas2 = _context$muiTheme$bas.spacing,
      desktopGutter = _context$muiTheme$bas2.desktopGutter,
      desktopSubheaderHeight = _context$muiTheme$bas2.desktopSubheaderHeight,
      fontFamily = _context$muiTheme$bas.fontFamily,
      _context$muiTheme$sna = _context$muiTheme.snackbar,
      backgroundColor = _context$muiTheme$sna.backgroundColor,
      textColor = _context$muiTheme$sna.textColor,
      actionColor = _context$muiTheme$sna.actionColor;


  var isSmall = width === _withWidth.SMALL;

  var styles = {
    root: {
      fontFamily: fontFamily,
      backgroundColor: backgroundColor,
      padding: '0 ' + desktopGutter + 'px',
      height: desktopSubheaderHeight,
      lineHeight: desktopSubheaderHeight + 'px',
      borderRadius: isSmall ? 0 : 2,
      maxWidth: isSmall ? 'inherit' : 568,
      minWidth: isSmall ? 'inherit' : 288,
      flexGrow: isSmall ? 1 : 0,
      margin: 'auto'
    },
    content: {
      fontSize: 14,
      color: textColor,
      opacity: open ? 1 : 0,
      transition: open ? _transitions2.default.easeOut('500ms', 'opacity', '100ms') : _transitions2.default.easeOut('400ms', 'opacity')
    },
    action: _extends({
      color: actionColor,
      float: 'right',
      marginTop: 6,
      marginRight: -16,
      marginLeft: desktopGutter,
      backgroundColor: 'transparent'
    }, props.actionStyle)
  };

  return styles;
}

var SnackbarBody = function SnackbarBody(props, context) {
  var _React$createElement, _React$createElement3, _React$createElement4, _extends2;

  var open = props.open,
      action = props.action,
      actions = props.actions,
      message = props.message,
      onActionTouchTap = props.onActionTouchTap,
      style = props.style,
      actionStyle = props.actionStyle,
      other = _objectWithoutProperties(props, ['open', 'action', 'actions', 'message', 'onActionTouchTap', 'style', 'actionStyle']);

  var prepareStyles = context.muiTheme.prepareStyles;

  var styles = getStyles(props, context);

  var actionButton = action && _react2.default.createElement(_FlatButton2.default, (_React$createElement = {
    style: styles.action,
    label: action,
    onTouchTap: onActionTouchTap,
    'data-codecept-selector-node': 'FlatButton',
    'data-codecept-selector-file': 'SnackbarBody'
  }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'SnackbarBody'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'SnackbarBody'), _React$createElement));

  var actionButtons = actions && actions.map(function (action) {
    var _React$createElement2;

    return _react2.default.createElement(_FlatButton2.default, (_React$createElement2 = {
      key: action.key,
      style: styles.action,
      label: action.label,
      onTouchTap: action.onTouchTap,
      'data-codecept-selector-node': 'FlatButton',
      'data-codecept-selector-file': 'SnackbarBody'
    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'SnackbarBody'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'SnackbarBody'), _React$createElement2));
  });

  return _react2.default.createElement(
    'div',
    _extends({}, other, (_extends2 = { style: prepareStyles(_extends(styles.root, style)), 'data-codecept-selector-node': 'div',
      'data-codecept-selector-file': 'SnackbarBody'
    }, _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'SnackbarBody'), _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'SnackbarBody'), _extends2)),
    _react2.default.createElement(
      'div',
      (_React$createElement4 = { style: prepareStyles(styles.content), 'data-codecept-selector-node': 'div',
        'data-codecept-selector-file': 'SnackbarBody'
      }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'SnackbarBody'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'SnackbarBody'), _React$createElement4),
      _react2.default.createElement(
        'span',
        (_React$createElement3 = {
          'data-codecept-selector-node': 'span',
          'data-codecept-selector-file': 'SnackbarBody'
        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'SnackbarBody'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'SnackbarBody'), _React$createElement3),
        message
      ),
      actionButton,
      actionButtons
    )
  );
};

exports.SnackbarBody = SnackbarBody;
SnackbarBody.propTypes = {
  /**
   * Specs for multiple actions
   */
  actions: _react.PropTypes.arrayOf(_react.PropTypes.shape({
    key: _react.PropTypes.string.isRequired,
    label: _react.PropTypes.node,
    onTouchTap: _react.PropTypes.func
  })),
  /**
   * The label for the action on the snackbar.
   */
  action: _react.PropTypes.node,
  /**
   * The style for the action on the snackbar.
   */
  actionStyle: _react.PropTypes.object,
  /**
   * The message to be displayed.
   *
   * (Note: If the message is an element or array, and the `Snackbar` may re-render while it is still open,
   * ensure that the same object remains as the `message` property if you want to avoid the `Snackbar` hiding and
   * showing again)
   */
  message: _react.PropTypes.node.isRequired,
  /**
   * Fired when the action button is touchtapped.
   *
   * @param {object} event Action button event.
   */
  onActionTouchTap: _react.PropTypes.func,
  /**
   * @ignore
   * Controls whether the `Snackbar` is opened or not.
   */
  open: _react.PropTypes.bool.isRequired,
  /**
   * Override the inline-styles of the root element.
   */
  style: _react.PropTypes.object,
  /**
   * @ignore
   * Width of the screen.
   */
  width: _react.PropTypes.number.isRequired
};

SnackbarBody.contextTypes = {
  muiTheme: _react.PropTypes.object.isRequired
};

exports.default = (0, _withWidth2.default)()(SnackbarBody);