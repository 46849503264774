'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _RadioButton = require('material-ui/RadioButton');

var _fieldBase = require('@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule');

var _fieldBase2 = _interopRequireDefault(_fieldBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label;

function getStyles(props, context) {
    var radioButton = context.muiTheme.radioButton;

    return {
        icon: {
            height: radioButton.size,
            width: radioButton.size
        },
        label: {
            color: props.disabled ? radioButton.labelDisabledColor : radioButton.labelColor
        }
    };
}

var FieldRadioButtonGroup = _react2.default.createClass({
    displayName: 'FieldRadioButtonGroup',

    propTypes: {
        label: PropTypes.string,
        fieldKey: PropTypes.string,
        value: PropTypes.string,
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        id: PropTypes.string,
        actions: PropTypes.object,
        isActive: PropTypes.bool,
        locked: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string,
            text: PropTypes.string
        }))
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null
        };
    },
    _onValueChange: function _onValueChange(_event, value) {
        this.props.actions.setFieldValueByKey(value, this.props.fieldKey);
    },
    render: function render() {
        var _extends2,
            _React$createElement,
            _this = this,
            _React$createElement2,
            _React$createElement3;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var _props = this.props,
            _props$labelStyle = _props.labelStyle,
            labelStyle = _props$labelStyle === undefined ? {} : _props$labelStyle,
            _props$iconStyle = _props.iconStyle,
            iconStyle = _props$iconStyle === undefined ? {} : _props$iconStyle;


        var isInfoMode = this._isInfoMode();
        var radioButtonGroupStyles = getStyles(this.props, this.context);
        var infoModeStyles = isInfoMode ? {
            labelStyle: _extends({}, radioButtonGroupStyles.label, labelStyle),
            iconStyle: _extends({}, radioButtonGroupStyles.icon, iconStyle),
            inputStyle: {
                cursor: 'not-allowed'
            }
        } : {};

        var labelProps = _lodash2.default.pick(this.props, Object.keys(Label.propTypes));
        return _react2.default.createElement(
            'div',
            (_React$createElement3 = { className: _fieldBase2.default.wrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'RadioButtonGroup'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'RadioButtonGroup'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'RadioButtonGroup'), _React$createElement3),
            _react2.default.createElement(
                'label',
                (_React$createElement = { className: radioButtonGroupStyles.label, 'data-codecept-selector-node': 'label',
                    'data-codecept-selector-file': 'RadioButtonGroup'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'RadioButtonGroup'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'RadioButtonGroup'), _React$createElement),
                _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                    'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'RadioButtonGroup'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'RadioButtonGroup'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'RadioButtonGroup'), _extends2)))
            ),
            _react2.default.createElement(
                _RadioButton.RadioButtonGroup,
                (_React$createElement2 = {
                    valueSelected: this.props.value,
                    onChange: this._onValueChange,
                    name: this.props.fieldKey,
                    'data-codecept-selector-node': 'RadioButtonGroup',
                    'data-codecept-selector-file': 'RadioButtonGroup'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'RadioButtonGroup'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'RadioButtonGroup'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'RadioButtonGroup'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'RadioButtonGroup'), _React$createElement2),
                (this.props.options || []).map(function (option) {
                    var _extends3;

                    return _react2.default.createElement(_RadioButton.RadioButton, _extends({
                        key: option.value,
                        value: option.value,
                        label: option.text || option.value,
                        disabled: _this.props.locked || option.locked || isInfoMode
                    }, infoModeStyles, (_extends3 = {
                        'data-codecept-selector-node': 'RadioButton',
                        'data-codecept-selector-file': 'RadioButtonGroup'
                    }, _defineProperty(_extends3, 'data-codecept-selector-node', 'RadioButton'), _defineProperty(_extends3, 'data-codecept-selector-file', 'RadioButtonGroup'), _defineProperty(_extends3, 'data-codecept-selector-node', 'RadioButton'), _defineProperty(_extends3, 'data-codecept-selector-file', 'RadioButtonGroup'), _extends3)));
                })
            )
        );
    },

    _isInfoMode: function _isInfoMode() {
        var mode = this.props.mode;


        return mode === 'info';
    }
});

exports.default = FieldRadioButtonGroup;