'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _FontIcon = require('material-ui/FontIcon');

var _FontIcon2 = _interopRequireDefault(_FontIcon);

var _svgIcons = require('material-ui/svg-icons');

var svgIcons = _interopRequireWildcard(_svgIcons);

var _addToScheduleIcon = require('./svgs/addToScheduleIcon');

var _addToScheduleIcon2 = _interopRequireDefault(_addToScheduleIcon);

var _editScheduleIcon = require('./svgs/editScheduleIcon');

var _editScheduleIcon2 = _interopRequireDefault(_editScheduleIcon);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//# NOTE: don't know if we should gulp-require the icons
//# because that will increase the bundle size drastically
var iconsHash = {
    'material-ui': {
        'svgIcons': svgIcons
        //# DEPRECATED: 20160922 - include all of the svgIcons instead
        , 'svg-icons': {
            action: {
                code: require('material-ui/svg-icons/action/code').default,
                'swap-horiz': require('material-ui/svg-icons/action/swap-horiz').default
            },
            content: {
                'inbox': require('material-ui/svg-icons/content/inbox').default
            },
            image: {
                'photo-library': require('material-ui/svg-icons/image/photo-library').default
            },
            social: {
                'photo-library': require('material-ui/svg-icons/image/photo-library').default
            }
        }
    },
    'ruby': {
        'icons': {
            content: {
                sitemap: function sitemap(props) {
                    var _extends2;

                    return _react2.default.createElement(_FontIcon2.default, _extends({}, props, (_extends2 = { className: 'fa fa-sitemap', 'data-codecept-selector-node': 'FontIcon',
                        'data-codecept-selector-file': 'index'
                    }, _defineProperty(_extends2, 'data-codecept-selector-node', 'FontIcon'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'FontIcon'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
                },
                'global-data': function globalData(props) {
                    var _extends3;

                    return _react2.default.createElement(_FontIcon2.default, _extends({}, props, (_extends3 = { className: 'fa fa-globe', 'data-codecept-selector-node': 'FontIcon',
                        'data-codecept-selector-file': 'index'
                    }, _defineProperty(_extends3, 'data-codecept-selector-node', 'FontIcon'), _defineProperty(_extends3, 'data-codecept-selector-file', 'index'), _defineProperty(_extends3, 'data-codecept-selector-node', 'FontIcon'), _defineProperty(_extends3, 'data-codecept-selector-file', 'index'), _extends3)));
                },
                'add-to-schedule': _addToScheduleIcon2.default,
                'edit-schedule': _editScheduleIcon2.default
            },
            social: {
                'linkedin': function linkedin(props) {
                    var _extends4;

                    return _react2.default.createElement(_FontIcon2.default, _extends({}, props, (_extends4 = { className: 'fa fa-linkedin', 'data-codecept-selector-node': 'FontIcon',
                        'data-codecept-selector-file': 'index'
                    }, _defineProperty(_extends4, 'data-codecept-selector-node', 'FontIcon'), _defineProperty(_extends4, 'data-codecept-selector-file', 'index'), _defineProperty(_extends4, 'data-codecept-selector-node', 'FontIcon'), _defineProperty(_extends4, 'data-codecept-selector-file', 'index'), _extends4)));
                },
                'facebook': function facebook(props) {
                    var _extends5;

                    return _react2.default.createElement(_FontIcon2.default, _extends({}, props, (_extends5 = { className: 'fa fa-facebook', 'data-codecept-selector-node': 'FontIcon',
                        'data-codecept-selector-file': 'index'
                    }, _defineProperty(_extends5, 'data-codecept-selector-node', 'FontIcon'), _defineProperty(_extends5, 'data-codecept-selector-file', 'index'), _defineProperty(_extends5, 'data-codecept-selector-node', 'FontIcon'), _defineProperty(_extends5, 'data-codecept-selector-file', 'index'), _extends5)));
                },
                'twitter': function twitter(props) {
                    var _extends6;

                    return _react2.default.createElement(_FontIcon2.default, _extends({}, props, (_extends6 = { className: 'fa fa-twitter', 'data-codecept-selector-node': 'FontIcon',
                        'data-codecept-selector-file': 'index'
                    }, _defineProperty(_extends6, 'data-codecept-selector-node', 'FontIcon'), _defineProperty(_extends6, 'data-codecept-selector-file', 'index'), _defineProperty(_extends6, 'data-codecept-selector-node', 'FontIcon'), _defineProperty(_extends6, 'data-codecept-selector-file', 'index'), _extends6)));
                }
            }
        }
    }
};

var iconGenerators = {
    'font-awesome': function fontAwesome(name) {
        var additionalClasses = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
        return function (props) {
            var _extends7;

            return _react2.default.createElement(_FontIcon2.default, _extends({}, props, (_extends7 = { className: 'fa fa-' + name + ' ' + additionalClasses, 'data-codecept-selector-node': 'FontIcon',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends7, 'data-codecept-selector-node', 'FontIcon'), _defineProperty(_extends7, 'data-codecept-selector-file', 'index'), _defineProperty(_extends7, 'data-codecept-selector-node', 'FontIcon'), _defineProperty(_extends7, 'data-codecept-selector-file', 'index'), _extends7)));
        };
    }
};

var RubyIcons = {
    iconsHash: iconsHash,
    iconGenerators: iconGenerators,
    getIconClassForPath: function getIconClassForPath(path) {
        //# the path is the same require path as you would when you do
        //# import...
        var pathArr = path.split('/');
        var iconGenerator = _lodash2.default.get(iconGenerators, pathArr.slice(0, pathArr.length - 1));
        return _lodash2.default.isFunction(iconGenerator) ? iconGenerator(_lodash2.default.last(pathArr)) : _lodash2.default.get(iconsHash, pathArr);
    }
};

module.exports = RubyIcons;