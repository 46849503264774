'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _rubyMemoize = require('@rubyapps/ruby-memoize');

var _memoizee = require('memoizee');

var _memoizee2 = _interopRequireDefault(_memoizee);

var _reduxBatchedActions = require('redux-batched-actions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var mixinUtils = require('@rubyapps/ruby-component-mixin-utils');

//# methods in mixin so we can override
module.exports = {
    hasUnsavedChanges: function hasUnsavedChanges() {
        var editPageMixin = require('@rubyapps/ruby-component-mixin-edit-page/src/client/index');

        var formValue = this.formValue({ omitFieldPicker: this.omitInfoAndControlFieldsPicker });
        var editedFormData = formValue;
        var pristineFormData = this.getState().pristineFormData;
        var filteredPristineFormData = _lodash2.default.pick(pristineFormData, Object.keys(editedFormData));

        /*
        const pristineFormData = _.pick(
            (this.getState().pristineFormData || {})
            , Object.keys(editedFormData)
        );
        */

        var flattenedEditedFormData = this.flattenedObject(editedFormData);
        var flattenedPristineFormData = this.flattenedObject(filteredPristineFormData);

        //# Custom isEqual customizer to treat empty string values as null so that the isEqual is compatible
        //# This is because certain string fields in the UI will default to empty string but it's a null
        var formDataIsDifferent = !_lodash2.default.isEqual(flattenedPristineFormData, flattenedEditedFormData);

        //# get nearest loadingIndicator and use that as an indication of whether the form is still loading
        //# if the form is still loading, we want to report that it has no unsaved changes
        var parentEditPageComponent = this.findAncestorBy(function (node) {
            return mixinUtils.doesComponentHaveMixin(node, editPageMixin);
        });
        var loadingIndicator = parentEditPageComponent ? parentEditPageComponent.getRubyComponentAtPath('> .rubyComponentLoadingIndicator') : undefined;
        var isLoading = loadingIndicator && loadingIndicator.getState().visible;

        return !isLoading && formDataIsDifferent;
    }
    //# used by the saveButton in formJS
    , hasNoUnsavedChanges: function hasNoUnsavedChanges() {
        return !this.hasUnsavedChanges();
    }

    //# TODO: update drafts mixin to override this to check for user perms 
    , canEdit: (0, _memoizee2.default)(function () {
        //# TODO: ISSUE: the canEdit permission check is specific to the content model
        //# we *should* update this to work with other models
        if (this.props.key == 'content') {
            //# TODO: 20170301 - we'll have to check if user ruby_permissions array
            //#     has changed if we want to accomodate dynamically updating the 
            //#     form.canEdit value while the form is active
            //#     and the user's role is changing
            var currTimestamp = new Date();
            var canEditTimestamp = this._canEditTimestamp;
            //# just to be safe, we invalidate the cached canEdit value
            //# after a timeout
            if (canEditTimestamp && currTimestamp - canEditTimestamp < 5000) {
                this._canEditTimestamp = currTimestamp;
                return this._canEdit;
            }

            //# if form has id, check the 'edit' + 'id' permission
            //# if form doesn't have id, check the 'add' permission
            var parentEditPage = this.getParent();

            var pageId = _lodash2.default.get(parentEditPage.getState(), 'routeParams.id');

            var permission = pageId ? {
                template: _lodash2.default.get(this, 'props.hiddenValues.template_keyword'),
                ruby_client: true,
                subsite: true,
                model: this.props.key //# TODO: might want to change the <Form> component to store 'content' as model instead of key
                //# don't know what else is expecting key to be 'content'
                , action: 'edit',
                id: pageId
                //: TODO: need to actually pass in id if available
            } : {
                template: _lodash2.default.get(this, 'props.hiddenValues.template_keyword'),
                ruby_client: true,
                subsite: true,
                model: this.props.key,
                action: 'add'
            };
            var canEdit = this.doesUserHavePermission(permission);

            this._canEdit = canEdit;
            this._canEditTimestamp = currTimestamp;
            return canEdit;
        } else {
            return true;
        }
    }, _extends({}, _rubyMemoize.defaultMemoizeeOptions, { maxAge: 1000, preFetch: false })),
    formValueToLocalState: function formValueToLocalState(formValue, dispatchOrCollect, entireFormValue, options) {
        var _this = this;

        var shouldDispatchOurselves = false;
        var actionCollector = [];
        if (!dispatchOrCollect) {
            if (options.batch) {
                var collectAction = function collectAction(value) {
                    actionCollector.push(value);
                };
                dispatchOrCollect = collectAction;
                shouldDispatchOurselves = true;
            } else {
                dispatchOrCollect = this.getStore().dispatch;
            }
        }
        if (!entireFormValue) {
            //# entireFormValue is typically formValue, but still 
            //# allow users to override if they must provide some wholly different entireFormValue
            entireFormValue = formValue;
        }

        return this._formValueToLocalState(formValue, dispatchOrCollect, false, entireFormValue, options).then(function (res) {
            if (shouldDispatchOurselves) {
                _this.getStore().dispatch((0, _reduxBatchedActions.batchActions)(actionCollector));
            }
            return res;
        });
    }
};