'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDndProvider = getDndProvider;
function getDndProvider(store) {
  var state = store.getState();

  return state.get('dndProvider').toJS();
}