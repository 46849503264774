'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var defaultState = {
    childrenOrderById: [],
    childrenIdToTypeMap: {},
    userAddedChildrenIdMap: {}
    //# used to track any user-explicitly added modules during the editing session
    , rerenderTimestamp: null,
    error: null //# error: {message: <string>}just like the nested fields
    , options: [],
    optionsByType: {}
};

function _addOptionsToState_reducer(selfmodule, state, action) {
    var optionsByType = state.optionsByType;


    var optionsToAdd = action.payload.options;

    var newOptionsByType = optionsToAdd.reduce(function (collector, option) {

        collector[option.type] = option;

        return collector;
    }, _extends({}, optionsByType));

    return _extends({}, state, {
        options: _lodash2.default.map(newOptionsByType, function (option) {
            return option;
        }),
        optionsByType: newOptionsByType
    });
}

function _appendItemOfType_reducer(selfmodule, state, action) {
    var _action$payload = action.payload,
        type = _action$payload.type,
        newChildId = _action$payload.id;
    //const options = this.normalizedOptions();

    //# Generate a uuid, add it to the array of items (in the fields

    var childrenOrderById = state.childrenOrderById.slice();
    childrenOrderById.push(newChildId);

    //# Generate a map of uuids to type map
    var childrenIdToTypeMap = _extends({}, state.childrenIdToTypeMap, _defineProperty({}, newChildId, type));
    var userAddedChildrenIdMap = _extends({}, state.userAddedChildrenIdMap, _defineProperty({}, newChildId, new Date()));

    return _extends({}, state, {
        childrenOrderById: childrenOrderById,
        childrenIdToTypeMap: childrenIdToTypeMap,
        userAddedChildrenIdMap: userAddedChildrenIdMap
    });
    //# children child editing should just work
}

function _deleteItemWithId_reducer(selfmodule, state, action) {
    var childId = action.payload.id;

    //# removing the childId from the type map
    var childrenIdToTypeMap = _lodash2.default.omit(state.childrenIdToTypeMap, [childId]);
    var childrenOrderById = state.childrenOrderById.filter(function (id) {
        return id != childId;
    });

    return _extends({}, _lodash2.default.omit(state, childId), {
        childrenOrderById: childrenOrderById,
        childrenIdToTypeMap: childrenIdToTypeMap
    });
    //# children child editing should just work
}

function moveItem_reducer(selfModule, state, action) {
    var _action$payload2 = action.payload,
        childId = _action$payload2.id,
        betweenIDs = _action$payload2.betweenIDs;


    var childrenOrderById = state.childrenOrderById.filter(function (id) {
        return id != childId;
    });

    var updatedChildrenOrderById = void 0;
    if (betweenIDs[0]) {
        //# if we have a left value
        var insertIndex = childrenOrderById.indexOf(betweenIDs[0]);
        updatedChildrenOrderById = childrenOrderById.slice();
        updatedChildrenOrderById.splice(insertIndex + 1, 0, childId);
    } else {
        //# insert to the left of the right value
        var _insertIndex = childrenOrderById.indexOf(betweenIDs[1]);
        updatedChildrenOrderById = childrenOrderById.slice();
        updatedChildrenOrderById.splice(_insertIndex, 0, childId);
    }

    return _extends({}, state, {
        childrenOrderById: updatedChildrenOrderById
    });
}

function updateItemOrder_reducer(selfModule, state, action) {
    var itemOrder = action.payload.itemOrder;


    return _extends({}, state, {
        childrenOrderById: itemOrder
    });
}
function _replaceState_reducer(selfModule, state, action) {
    return _extends({}, state, action.payload);
}

/* 20170517 - DEPRECATED - howard
function _setFieldError_reducer(selfModule, state, action) {
    return Object.assign({}, state
        , {
            error: action.payload.error
        }
    );
}
*/

module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];

    var _getDependencies = this.getDependencies(),
        TYPES = _getDependencies.selfAction.TYPES;

    if (!state.hasOwnProperty('childrenOrderById')) {
        var normalizedOptions = this.normalizedOptions_withOptions(this.props.options);
        state = _extends({}, state, defaultState, {
            options: normalizedOptions,
            optionsByType: this.optionsByType_fromOptions(normalizedOptions)
        });
    }

    switch (action.type) {
        case TYPES.APPEND_ITEM_OF_TYPE:
            return _appendItemOfType_reducer(this, state, action);
        case TYPES.DELETE_ITEM_WITH_ID:
            return _deleteItemWithId_reducer(this, state, action);
        case TYPES.MOVE_ITEM:
            return moveItem_reducer(this, state, action);
        case TYPES.UPDATE_ITEM_ORDER:
            return updateItemOrder_reducer(this, state, action);
        case TYPES.REPLACE_STATE:
            return _replaceState_reducer(this, state, action);

        case TYPES.ADD_OPTIONS:
            return _addOptionsToState_reducer(this, state, action);

        /* 20170517 - DEPRECATED - howard
        case TYPES.SET_FIELD_ERROR_MESSAGE_BY_KEY:
            return _setFieldError_reducer(this, state, action);
        */
        case TYPES.RESET_STORE:
            return this.getInitialState();
        default:
            return state;
    }
};