'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggleRowSelection = toggleRowSelection;
exports.selectVisibleRows = selectVisibleRows;
exports.unselectVisibleRows = unselectVisibleRows;
exports.clearAllRowSelections = clearAllRowSelections;

var _constants = require('./constants');

var types = _interopRequireWildcard(_constants);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function toggleRowSelection(griddleKey) {
  return {
    type: types.GRIDDLE_ROW_SELECTION_TOGGLED,
    griddleKey: griddleKey
  };
}

function selectVisibleRows() {
  return {
    type: types.GRIDDLE_ROW_SELECT_VISIBLE_ROWS
  };
}
function unselectVisibleRows() {
  return {
    type: types.GRIDDLE_ROW_UNSELECT_VISIBLE_ROWS
  };
}

function clearAllRowSelections(griddleKey) {
  return {
    type: types.GRIDDLE_CLEAR_ALL_ROW_SELECTIONS
  };
}