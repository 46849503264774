'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; //# NOTE: this was initialy implemented for searchableFields, we can now genericize it

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _path = require('path');

var _path2 = _interopRequireDefault(_path);

var _queryString = require('query-string');

var _queryString2 = _interopRequireDefault(_queryString);

var _components = require('@rubyapps/ruby-component-builder/src/common/components');

var _components2 = _interopRequireDefault(_components);

var _optionsUrl__extractParams = require('@rubyapps/ruby-form-js-to-json-schema/src/common/optionsUrl__extractParams.js');

var _optionsUrl__extractParams2 = _interopRequireDefault(_optionsUrl__extractParams);

var _constants = require('@rubyapps/ruby-component-plugin-template-editor/src/common/constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

require('deepdash')(_lodash2.default);

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = _path2.default.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

var _require = require('@rubyapps/ruby-form-js-to-json-schema/src/common/utils'),
    mergedChildrenPropsByKeyFromForm_andDefaultProps = _require.mergedChildrenPropsByKeyFromForm_andDefaultProps;

//# NOTE: `DEFAULT_INCLUDE_ID_PATHS` and `DEFAULT_INCLUDE_KEY_PATHS` are
//# hacks that we are implementing to include fields that would otherwise
//# be excluded (like the `_score` Relevance field defined by the
//# `ruby-model-mixin-relevance` mixin) in the searchableFields spec.
//# Ideally, this mixin should not have to know anything about any particular
//# field


var DEFAULT_INCLUDE_ID_PATHS = ['__score', '__termFreq'];
var DEFAULT_INCLUDE_KEY_PATHS = ['status'];

//# TODO: we handle filterTags.include in two different ways:
//# calling on 'reshapedSpecsUsingIdPaths' at the <Form> component 
//# So this only works for the form component or <TokenTagger/> when we're expanding it with the nested fields
//# We currently also support inclusion of localFields but it's not the ideal way to do so since 
//# the config may create duplicate fields (since we don't remove the field from the origin)
//# We want to update so the two sets of logic is shared
//# Currently, since this is async, there's a limitation where it only works for referencing 
//# callingModule - so we can check if we should include namespace fields
var fieldsSpecsFromTemplate = function fieldsSpecsFromTemplate(selfModule, templateData, callingModule) {
    var idPathsFromListerConfig = _lodash2.default.get(templateData, 'form.listerConfig.expandedSearch.filterTags.include') || [];
    var wantedIdPaths = _lodash2.default.isEmpty(idPathsFromListerConfig) ? ['*'] //# Wildcard means include everything
    : _lodash2.default.uniq(idPathsFromListerConfig.concat(DEFAULT_INCLUDE_ID_PATHS));

    var specsFromTemplateData = specsFromTemplateData_usingCtx(selfModule, templateData);

    return reshapedSpecsUsingIdPaths(specsFromTemplateData, wantedIdPaths);
};

module.exports = {
    fieldsSpecsFromTemplate: fieldsSpecsFromTemplate,
    specsFromNodes_andCtx: specsFromNodes_andCtx
};

function reshapedSpecsUsingIdPaths(specs) {
    var idPaths = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    var reducedSpecs = !_lodash2.default.isEmpty(idPaths) ? _lodash2.default.flatMap(idPaths, function (idPath) {
        return reduceSpecsUsingIdPath_andCtx(specs, idPath);
    }) : specs;

    return prunedTreeUsingIdPaths(reducedSpecs, idPathsToPruneFromIdPaths(idPaths));
}

function reduceSpecsUsingIdPath_andCtx(specs, idPath) {
    var ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    return specs.reduce(function (acc, spec) {
        var specsMatchingIdPath = specsMatchingIdPath_usingCtx(spec, idPath, ctx);
        return acc.concat(specsMatchingIdPath);
    }, []);
}

function specsMatchingIdPath_usingCtx(node, wantedIdPath) {
    var ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var id = node.id,
        _node$children = node.children,
        children = _node$children === undefined ? [] : _node$children;
    var _ctx$idPathArray = ctx.idPathArray,
        idPathArray = _ctx$idPathArray === undefined ? [] : _ctx$idPathArray,
        _ctx$matchedIds = ctx.matchedIds,
        matchedIds = _ctx$matchedIds === undefined ? [] : _ctx$matchedIds;


    var wantedIdPathArray = wantedIdPath.split('.');
    var wantedIdPathIsWildcard = wantedIdPathArray[wantedIdPathArray.length - 1] === '*';

    var currentNodeIdPathArray = idPathArray.concat(id ? id : '*');
    var currentNodeIdPath = currentNodeIdPathArray.join('.');

    var parentPathOfWantedIdPath = _lodash2.default.initial(wantedIdPathArray).join('.');
    var parentPathOfCurrentNodeIdPath = _lodash2.default.initial(currentNodeIdPathArray).join('.');

    var currentNodeMatchesWantedIdPath = wantedIdPath === currentNodeIdPath || wantedIdPathIsWildcard && parentPathOfWantedIdPath === parentPathOfCurrentNodeIdPath;

    var descendantNodesMatchingWantedIdPath = !_lodash2.default.isEmpty(children) ? reduceSpecsUsingIdPath_andCtx(children, wantedIdPath, _extends({}, ctx, { idPathArray: currentNodeIdPathArray })) : [];

    var nodesMatchingWantedIdPath = currentNodeMatchesWantedIdPath ? [].concat(node) : descendantNodesMatchingWantedIdPath;

    return _lodash2.default.flatMapDeep(nodesMatchingWantedIdPath, _lodash2.default.identity);
}

function prunedTreeUsingIdPaths(tree) {
    var idPaths = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    var prunedTree = idPaths.reduce(function (acc, idPath) {
        return pruneTreeUsingIdPath_andCtx(acc, idPath);
    }, tree);

    return prunedTree;
}

function pruneTreeUsingIdPath_andCtx(tree, idPath) {
    var ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var _ctx$idPathArray2 = ctx.idPathArray,
        idPathArray = _ctx$idPathArray2 === undefined ? [] : _ctx$idPathArray2;

    var idPathToParentNode = _lodash2.default.initial(idPath.split('.')).join('.');
    var pruneId = _lodash2.default.last(idPath.split('.'));

    var prunedTree = tree.reduce(function (acc, treeNode) {
        var id = treeNode.id,
            _treeNode$children = treeNode.children,
            children = _treeNode$children === undefined ? [] : _treeNode$children;

        var currentIdPathArray = idPathArray.concat(id ? id : '*');
        var currentIdPath = currentIdPathArray.join('.');

        if (currentIdPath === idPathToParentNode) {
            return acc.concat(_extends({}, treeNode, { children: children.filter(function (child) {
                    return !child.id || child.id !== pruneId;
                }) }));
        }

        return _lodash2.default.isEmpty(children) ? acc.concat(treeNode) : acc.concat(_extends({}, treeNode, {
            children: pruneTreeUsingIdPath_andCtx(children, idPath, _extends({}, ctx, { idPathArray: currentIdPathArray }))
        }));
    }, []);

    return prunedTree;
}

function idPathsToPruneFromIdPaths() {
    var idPaths = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    return idPaths.reduce(function (acc, currentIdPath) {
        var isDescendantPath = idPaths.filter(function (idPath) {
            return idPath !== currentIdPath;
        }).find(function (idPath) {
            var currentIdPathPartials = currentIdPath.split('.');
            var idPathPartials = idPath.split('.').filter(function (partial) {
                return partial !== '*';
            });
            return currentIdPathPartials.reduce(function (acc, partial, idx) {
                return !!acc ? acc : currentIdPathPartials.length > idPathPartials.length && currentIdPathPartials[idx] === idPathPartials[idx];
            }, false);
        });

        return isDescendantPath ? acc.concat(currentIdPath) : acc;
    }, []);
}

/**
 *  @property ctx.callingModule
 *  @property ctx.breadcrumbArray
 *  @property ctx.templateKey
 *  @property ctx.dataPathArray
 *  @property ctx.nestingOf - 
 *  @property ctx.selfModule - 
 *
 **/
function specsFromTemplateData_usingCtx(selfModule, templateData) {
    var ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : { nestedSearch: true };

    if (!templateData) {
        logger.error('[specsFromTemplateData_usingCtx] templateData is undefined for template: [' + ctx.templateKey + ']');
        return [];
    }
    var _templateData$form = templateData.form,
        form = _templateData$form === undefined ? {} : _templateData$form,
        templateKey = templateData.key;
    var _form$children = form.children,
        children = _form$children === undefined ? [] : _form$children;


    var ctxForTemplate = _extends({}, ctx, {
        breadcrumbArray: [],
        templateKey: templateKey,
        dataPathArray: []
    });

    return specsFromNodes_andCtx(selfModule, children, ctxForTemplate, options);
}

//# NOTE: not great, but some getDefaultProps() accesses `this`
var polyfilledComponentInstance_withProps = function polyfilledComponentInstance_withProps(component, props) {
    return {
        getID: function getID() {
            props.id || component.componentName;
        }
    };
};

function _nodeWithMergedDefaultProps(node) {
    var componentName = node.componentName;


    var component = _components2.default[componentName];
    var polyfilledComponentInstance = polyfilledComponentInstance_withProps(component, node);
    var component__defaultProps = component.getDefaultProps ? component.getDefaultProps.call(polyfilledComponentInstance, node) : {};

    var nodeWithMergedDefaultProps = _extends({}, component__defaultProps, node, node.hasOwnProperty('childrenPropsByKey') ? {
        childrenPropsByKey: mergedChildrenPropsByKeyFromForm_andDefaultProps(node, component__defaultProps)
    } : {}); //# This is the node representing a field

    return nodeWithMergedDefaultProps;
}
function specsFromNodes_andCtx(selfModule) {
    var nodes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : { nestedSearch: true };

    var searchableFieldsSpec = nodes.reduce(function (acc, node) {
        var componentName = node.componentName,
            __alreadyFormattedByContentPropertyHelper__ = node.__alreadyFormattedByContentPropertyHelper__;
        var _ctx$dataPathArray = ctx.dataPathArray,
            dataPathArray = _ctx$dataPathArray === undefined ? [] : _ctx$dataPathArray;

        //# Previously, we relied on differentiating between children and _internal_children
        //# however, that doesn't work because some components like NamespaceSelector
        //# will lift up children fields as siblings
        //# so we need to mark them as having been processed

        if (__alreadyFormattedByContentPropertyHelper__) {
            acc.push(node);
            return acc;
        }

        var component = _components2.default[componentName];

        if (!component) {
            componentName && logger.warn('[specsFromNodes_andCtx()] No component found for ' + componentName + '] with formJS valued:', node);
            //# Only need to log this out if component isn't found even if componentName is available
            //# there are other cases (eg. LinkUrl) where it's children do not have componentNames defined because it's a managed component
            //# so we can ignore them for now
            //# if we need them to be searchable, we should update the complex components source and add the componentName, I think profileIdentity or internationAddress already does it
            return acc;
        }

        var nodeWithMergedDefaultProps = _nodeWithMergedDefaultProps(node);
        //const nodeWithMergedDefaultProps = mergedChildrenPropsByKeyFromForm_andDefaultProps(
        //


        var url = nodeWithMergedDefaultProps.url,
            _nodeWithMergedDefaul = nodeWithMergedDefaultProps.listerConfig,
            listerConfig = _nodeWithMergedDefaul === undefined ? {} : _nodeWithMergedDefaul;

        var nestedSearch = url && (_lodash2.default.get(listerConfig, 'expandedSearch.filterTags') || _lodash2.default.get(listerConfig, 'expandedSearch') === true) && options.nestedSearch;

        //# Need to update dataPathArray with current key
        //# the call to fieldSpecsForNode needs it so individual getFieldSpecFromProps can 
        //# have that info
        var parentDataPathArray = dataPathArray;
        //# NOTE: dataPathArray is the parent dataPathArray at this point
        //# the dataPath for nodeWithMergedDefaultProps will be determined in `specFromNode_andCtx` 
        var ctxWithSelfModule = _extends({}, ctx, { selfModule: selfModule, dataPathArray: parentDataPathArray });

        var fieldSpecs = fieldSpecsForNode(nodeWithMergedDefaultProps, ctxWithSelfModule);
        //# a field might map to multiple fieldSpec (eg. international address maps to multiple simple fields)

        //# NOTE: We are relying on a match between the `key` and `componentName`
        //# properties to find the field spec from `fieldSpecs` that matches the
        //# current `node`. The assumption being made here is that it would never
        //# be the case that a given node would have field specs where multiple
        //# items have the same key.
        var matchingFieldSpecForNode = fieldSpecs.find(_lodash2.default.matches({
            key: nodeWithMergedDefaultProps.key,
            componentName: nodeWithMergedDefaultProps.componentName
        })) || {};
        //# We look for the fieldSpec out of all fieldSpecs returned to find the one representing the node
        //# NOTE: that typically fieldSpecsForNode() returns one fieldSpec

        var nodeWithMergedFieldSpec = _extends({}, nodeWithMergedDefaultProps //# merge in default props first
        , matchingFieldSpecForNode ? matchingFieldSpecForNode : {}
        //, node //# need to finally include node because node includes custom overrides for the particular node whereas nodeWithMergedDefaultProps and matchingFieldSpecForNode
        );

        var flattenedFieldSpecs = _lodash2.default.flatMap(fieldSpecs, function (fieldSpec) {
            return _lodash2.default.isEqual(matchingFieldSpecForNode, fieldSpec) ? specFromNode_andCtx(selfModule, nodeWithMergedFieldSpec, ctxWithSelfModule, options) : specFromNode_andCtx(selfModule, _extends({}, fieldSpec, { parentSearchableFieldSpec: nodeWithMergedFieldSpec }), ctxWithSelfModule, options);
        });

        var retval = selectNodeForSpec(nodeWithMergedFieldSpec, ctxWithSelfModule) ?

        //# if include children, we recurse
        acc.concat(_lodash2.default.isEmpty(flattenedFieldSpecs) ? specFromNode_andCtx(selfModule, nodeWithMergedFieldSpec, ctxWithSelfModule, options) : [], flattenedFieldSpecs, nestedSearch ? nestedSpecsFromNode_andCtx(selfModule, nodeWithMergedFieldSpec, ctxWithSelfModule, options) : []) : acc;

        return retval;
    }, []);

    return searchableFieldsSpec.map(function (searchableFieldSpec) {
        return specWithHydratedListerConfig(searchableFieldSpec);
    });
}

//# determing if we need to include children
function selectNodeForSpec(node, ctx) {
    var _node$children2 = node.children,
        children = _node$children2 === undefined ? [] : _node$children2,
        componentName = node.componentName,
        key = node.key,
        id = node.id;
    //# find component and see if it has childrenPropsByKey

    var nodeWithMergedDefaultProps = _nodeWithMergedDefaultProps(node);

    return DEFAULT_INCLUDE_ID_PATHS.includes(id) || DEFAULT_INCLUDE_KEY_PATHS.includes(key) || key || !_lodash2.default.isEmpty(fieldSpecsForNode(node, ctx)) || children.find(function (childNode) {
        return selectNodeForSpec(childNode, ctx);
    }) || nodeWithMergedDefaultProps.hasOwnProperty('childrenPropsByKey') && _lodash2.default.size(nodeWithMergedDefaultProps.childrenPropsByKey);
}

/**
 *  Returns the final format of the fieldSpec (from fieldSpecsForNode() call), because we need 
 *  to recurse into the children
 **/
function specFromNode_andCtx(selfModule, node) {
    var ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var options = arguments[3];
    var children = node.children,
        componentName = node.componentName,
        dataPath = node.dataPath,
        label = node.label,
        url = node.url,
        key = node.key,
        _node$listerConfig = node.listerConfig,
        listerConfig = _node$listerConfig === undefined ? {} : _node$listerConfig;


    var filterTags__includes = _lodash2.default.get(listerConfig, 'expandedSearch.filterTags.include');
    //# NOTE: localSearch handles filterTag includes of local fields

    var _ctx$dataPathArray2 = ctx.dataPathArray,
        dataPathArray = _ctx$dataPathArray2 === undefined ? [] : _ctx$dataPathArray2,
        templateKey = ctx.templateKey,
        nestingOf = ctx.nestingOf,
        _ctx$nestingLabelArra = ctx.nestingLabelArray,
        nestingLabelArray = _ctx$nestingLabelArra === undefined ? [] : _ctx$nestingLabelArra,
        parentTemplateKey = ctx.parentTemplateKey;

    //# NOTE: currently it doesn't seem like this is an issue so we can ignore it for now
    //# TODO check ctx.callingModule and determine whether we include namespace or not
    /*
    if (componentName === 'NamespaceSelector') {
        //# TODO: update so we don't need this check, instead, we can rely on specsFromNodes_andCtx calling on 
        //# TODO: check context, if we want to include fieldSpecsForNode
        const retval = specsFromNodes_andCtx(selfModule, children, ctx);
         debugger;
        return retval;
    }
    */

    var formsState = formsStateFromSelfModule(selfModule);

    var templateData = formsState[templateKey] || {}; //# NOTE tempalteKey could be empty

    var templateName = templateData.name;

    var nestingLabel = nestingOf && nestingLabelArray.join(' › ');
    var displayLabel = _lodash2.default.get(listerConfig, 'expandedSearch.filterTags.labelAs') || label;

    var labelForSpec = componentName === 'Tab' || componentName === 'Header' ? _lodash2.default.compact([nestingLabel || templateName, displayLabel]).join(' › ') : displayLabel;

    var childrenNodes = children;
    if (filterTags__includes && !url) {
        //# only allow this if the field is a container field like fieldset
        //# Get nodes based on include ids

        var wantedIdPaths = filterTags__includes;
        childrenNodes = wantedIdPaths.reduce(function (collector, idPath) {
            var foundNodes = nodesInTemplate_atIdPath(templateData, idPath);

            return collector.concat(foundNodes);
        }, []);
    }

    //# if node.dataPath is available, then we use that for the dataPathArray
    //# otherwise, we concat key to the dataPathArray (which is the parent dataPathArray)
    var dataPathArrayForChildren = dataPath ? dataPath.split('.') : dataPathArray.concat(key ? key : []);
    var hydratedChildren = [].concat(childrenNodes && childrenNodes.length ? specsFromNodes_andCtx(selfModule, childrenNodes, _extends({}, ctx, { dataPathArray: dataPathArrayForChildren }), options) : []);

    return [].concat(componentName === 'Repeater' && url ? specFromProfileRepeaterNode_andCtx(selfModule, node, ctx) : _extends({}, node, {
        dataPath: node.dataPath || dataPathArrayForChildren.join('.'),
        breadcrumbPartial: labelForSpec,
        label: labelForSpec,
        foundIn__templateKey: templateKey,
        nestingOf: nestingOf,
        parentTemplateKey: parentTemplateKey,
        parentDataPath: dataPathArray.join('.'),
        __alreadyFormattedByContentPropertyHelper__: true
    }, hydratedChildren.length ? {
        children: hydratedChildren
    } : {}));
}

function specWithHydratedListerConfig(searchableFieldSpec) {
    var _searchableFieldSpec$ = searchableFieldSpec.excludeFromAdvancedLister,
        excludeFromAdvancedLister = _searchableFieldSpec$ === undefined ? false : _searchableFieldSpec$,
        _searchableFieldSpec$2 = searchableFieldSpec.excludeFromColumnSelection,
        excludeFromColumnSelection = _searchableFieldSpec$2 === undefined ? false : _searchableFieldSpec$2,
        _searchableFieldSpec$3 = searchableFieldSpec.excludeFromFilterSelection,
        excludeFromFilterSelection = _searchableFieldSpec$3 === undefined ? false : _searchableFieldSpec$3,
        _searchableFieldSpec$4 = searchableFieldSpec.listerConfig,
        listerConfig = _searchableFieldSpec$4 === undefined ? {
        excludeFromColumnSelection: false,
        excludeFromFilterSelection: false
    } : _searchableFieldSpec$4,
        _searchableFieldSpec$5 = searchableFieldSpec.parentSearchableFieldSpec,
        parentSearchableFieldSpec = _searchableFieldSpec$5 === undefined ? {} : _searchableFieldSpec$5;


    var hydratedListerConfig = _extends({}, listerConfig, {
        excludeFromColumnSelection: !!(excludeFromAdvancedLister || listerConfig.excludeFromColumnSelection || excludeFromColumnSelection || _lodash2.default.get(parentSearchableFieldSpec, 'listerConfig.excludeFromColumnSelection')),
        excludeFromFilterSelection: !!(excludeFromAdvancedLister || listerConfig.excludeFromFilterSelection || excludeFromFilterSelection || _lodash2.default.get(parentSearchableFieldSpec, 'listerConfig.excludeFromFilterSelection'))
    });

    return _extends({}, _lodash2.default.omit(searchableFieldSpec, ['excludeFromColumnSelection', 'excludeFromFilterSelection', 'excludeFromAdvancedLister']), { listerConfig: hydratedListerConfig });
}

function specFromProfileRepeaterNode_andCtx(selfModule, profileRepeaterNode) {
    var ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var options = arguments[3];

    var formsState = formsStateFromSelfModule(selfModule);

    var _ctx$dataPathArray3 = ctx.dataPathArray,
        dataPathArray = _ctx$dataPathArray3 === undefined ? [] : _ctx$dataPathArray3,
        nestingOf = ctx.nestingOf,
        parentTemplateKey = ctx.parentTemplateKey,
        templateKey = ctx.templateKey;
    var url = profileRepeaterNode.url,
        repeaterKey = profileRepeaterNode.key,
        label = profileRepeaterNode.label;

    var urlParamString = url.substring(url.indexOf('?'));
    var urlParams = _queryString2.default.parse(urlParamString);
    var _urlParams$templateTy = urlParams.templateType,
        targetTemplateType = _urlParams$templateTy === undefined ? _constants.PROFILE_TEMPLATE : _urlParams$templateTy;


    var profileTemplateSpecs = _lodash2.default.chain(formsState).filter(function (template, templateKey) {
        return template.templateType === targetTemplateType;
    }).sortBy('name').flatMap(function (template) {
        var _template$form = template.form,
            form = _template$form === undefined ? {} : _template$form,
            templateKey = template.key;
        var _form$children2 = form.children,
            formChildren = _form$children2 === undefined ? [] : _form$children2;

        return _lodash2.default.chain(formChildren).filter(function (node) {
            return selectNodeForSpec(node, _extends({}, ctx, { selfModule: selfModule }));
        }).map(function (node) {
            var _node$children3 = node.children,
                children = _node$children3 === undefined ? [] : _node$children3,
                key = node.key;

            var parentDataPath = dataPathArray.join('.');
            var updatedDataPathArray = dataPathArray.concat(repeaterKey, '*', key);

            return _extends({}, node, {
                dataPath: updatedDataPathArray.join('.'),
                breadcrumbPartial: template.name,
                label: 'Profile: ' + template.name,
                children: specsFromNodes_andCtx(selfModule, children, _extends({}, ctx, { dataPathArray: updatedDataPathArray }), options),
                foundIn__templateKey: templateKey,
                __alreadyFormattedByContentPropertyHelper__: true,
                nestingOf: nestingOf,
                parentTemplateKey: parentTemplateKey,
                parentDataPath: parentDataPath
            });
        }).value();
    }).value();

    var profileRepeaterTypeSpec = _extends({}, profileRepeaterNode, {
        componentName: 'Hidden',
        breadcrumbPartial: label + ' Type',
        key: 'type',
        dataPath: dataPathArray.concat([repeaterKey, '*', 'type']).join('.'),
        data_type: ['string'],
        label: label + ' Type',
        listerConfig: {
            excludeFromFilterSelection: false
        },
        foundIn__templateKey: templateKey,
        __alreadyFormattedByContentPropertyHelper__: true,
        parentTemplateKey: parentTemplateKey,
        nestingOf: nestingOf
    });

    //# NOTE: For Matter Profile Repeaters - we explicitly inject a
    //# searchable field spec for the profile type. The reason we do
    //# this specifically for Matter Profile Repeaters is because
    //# they don't have inline module options (and thefore currently,
    //# the Repeater component's getFieldSpecForProps method will not
    //# expose the repeater module type spec)
    return [].concat(profileRepeaterTypeSpec, profileTemplateSpecs);
}

function nestedSpecsFromNode_andCtx(selfModule, node) {
    var ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var options = arguments[3];
    var url = node.url,
        _node$listerConfig2 = node.listerConfig,
        listerConfig = _node$listerConfig2 === undefined ? {} : _node$listerConfig2,
        dataPath = node.dataPath,
        label = node.label;
    var _ctx$dataPathArray4 = ctx.dataPathArray,
        dataPathArray = _ctx$dataPathArray4 === undefined ? [] : _ctx$dataPathArray4,
        _ctx$nestingLabelArra2 = ctx.nestingLabelArray,
        nestingLabelArray = _ctx$nestingLabelArra2 === undefined ? [] : _ctx$nestingLabelArra2;

    var formsState = formsStateFromSelfModule(selfModule);

    var modelInfo = url && (0, _optionsUrl__extractParams2.default)(url);
    var templateKeys = modelInfo ? _lodash2.default.castArray(modelInfo.template) : [];
    var wantedIdPaths = _lodash2.default.get(listerConfig, 'expandedSearch.filterTags.include') || [];

    var nestedSpecs = _lodash2.default.flatMap(templateKeys, function (templateKey) {
        var templateData = formsState[templateKey];
        return specsFromTemplateData_usingCtx(selfModule, templateData, {
            dataPathArray: dataPathArray.concat(dataPath),
            nestingOf: dataPath,
            nestingLabelArray: nestingLabelArray.concat(label),
            parentTemplateKey: ctx.templateKey,
            templateKey: templateKey //# NOTE: used for debugging since templateData might be undefined
        }, options);
    });

    return reshapedSpecsUsingIdPaths(nestedSpecs, wantedIdPaths);
}

function nodesInTemplate_atIdPath(template, idPath) {
    var idPath_arr = idPath.split('.');

    var foundNodes = idPath_arr.reduce(function (collector, idPath_partial) {
        //# iteratie collector and search for the closest decendant with matching idPath_partial
        return collector.reduce(function (filteredNodes, node) {
            var foundNode = _lodash2.default.findDeep(node, function (value, key, parentValue, context) {
                return value.id == idPath_partial;
            }, {
                checkCircular: false,
                pathFormat: 'string',
                includeRoot: false,
                childrenPath: 'children'
            });

            if (foundNode) {
                filteredNodes.push(foundNode.value);
            }

            return filteredNodes;
        }, []);
    }, [template.form]);

    return foundNodes;
}

/**
 * @param {Object} ctx.selfModule - the caller's RubyComponent
 */
function fieldSpecsForNode(node, ctx) {
    var componentName = node.componentName;

    var component = _components2.default[componentName];

    if (!component) {
        return [];
    }

    var getFieldSpecFromProps = component.getFieldSpecFromProps
    //# find the first getFieldSpecFromProps based on the component mixin
    || [].concat(_toConsumableArray(component.mixins)).reverse().reduce(function (collector, mixin) {
        if (collector) {
            return collector;
        }

        return mixin.getFieldSpecFromProps;
    }, undefined) || baseFieldMixin.getFieldSpecFromProps;
    getFieldSpecFromProps = getFieldSpecFromProps.bind(component);

    return getFieldSpecFromProps(node, ctx);
}

function formsStateFromSelfModule(selfModule) {
    var rootModule = selfModule.getRoot();
    var formsID = selfModule.props.formsID || 'rubyComponentForms';
    var formsComponent = rootModule.findDescendentByID(formsID);
    var formsState = formsComponent.getState();

    return formsState;
}