'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _addInfoModeProps = require('@rubyapps/ruby-react-components/src/client/hoc/addInfoModeProps');

var _addInfoModeProps2 = _interopRequireDefault(_addInfoModeProps);

var _Text_style = require('@rubyapps/ruby-component-field-text/src/client/reactComponents/Text_style');

var _Text_style2 = _interopRequireDefault(_Text_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label;

var optionUtils = require('./optionUtils');

var PropTypes_value = PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]);
var DropdownInfoMode = _react2.default.createClass(_extends({
    propTypes: {
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes_value, PropTypes.arrayOf(PropTypes_value)]),
        preloadOptions: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes_value,
            text: PropTypes.string
        })),
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        id: PropTypes.string,
        hintText: PropTypes.string,
        actions: PropTypes.object,
        excludeWrapper: PropTypes.bool
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null
        };
    },
    render: function render() {
        var _extends2, _extends3;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var _props = this.props,
            options = _props.options,
            value = _props.value,
            toHTML = _props.toHTML;


        var selectedOptions = this.getSelectedOptions_fromValues(options, value ? _lodash2.default.castArray(value) : value);

        //# TODO: add toHTML support. Need to consider whether we want to call toHTML(selectedOptions) or selectedOptions.map(toHTML)
        var formattedValue = selectedOptions.map(function (option) {
            return option.text;
        }).join(', ');

        var labelProps = _lodash2.default.pick(this.props, Object.keys(Label.propTypes));
        var inputComponent = _react2.default.createElement(_TextField2.default, _extends({}, _Text_style2.default.TextField, {
            floatingLabelText: _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                'data-codecept-selector-node': 'Label',
                'data-codecept-selector-file': 'DropdownInfoMode'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'DropdownInfoMode'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'DropdownInfoMode'), _extends2))),
            id: this.props.id,
            errorText: this.props.error ? this.props.error.message : null
        }, _lodash2.default.pick(this.props, ['hintText']), _lodash2.default.get(this, 'props.infoModeProps.TextField'), _lodash2.default.get(this, 'props.infoModeProps.default'), (_extends3 = {
            value: formattedValue //# formattedValue trumps the infoMode value since we need to resolve to the options label
            , 'data-codecept-selector-node': 'TextField',
            'data-codecept-selector-file': 'DropdownInfoMode'
        }, _defineProperty(_extends3, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends3, 'data-codecept-selector-file', 'DropdownInfoMode'), _defineProperty(_extends3, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends3, 'data-codecept-selector-file', 'DropdownInfoMode'), _extends3)));

        return this.props.renderGridWrapper(_extends({}, this.props, { children: inputComponent }));
    },

    componentDidMount: function componentDidMount() {
        if (this.props.preloadOptions) {
            //# NOTE: this should't change as nothing else would trigger loading the remote options
            this.props.actions.preloadOptions();
        }

        this.props.actions.setComponentIsMounted && this.props.actions.setComponentIsMounted();
    }
}, optionUtils));

exports.default = (0, _addInfoModeProps2.default)(DropdownInfoMode);