'use strict';

var _ = require('lodash');
var config = require('./config').mergedConfig;

function _newConfigForClient(config) {
    if (typeof config == 'string') {
        config = {
            TAG: config
        };
    }
    var configStreams = config.streamsByName;
    if (_.isNil(configStreams)) {
        return _.assign({}, config);
    }

    var newConfigStreams = _.reduce(configStreams, function (collector, streamConfig, streamName) {
        var isBunyanFormatStream = false;
        if (streamConfig && streamConfig.stream && streamConfig.stream.length && streamConfig.stream[0] == 'bunyan-format') {
            isBunyanFormatStream = true;
        }

        if (isBunyanFormatStream) {
            streamConfig.stream.push({
                write: function write() {
                    //# map out.write() to console.log()
                    console.log.apply(console, arguments);
                }
            });
        }
        //# bunyan-format doens't work in browser unless we pass in an "out" param with {write: fn()}, which we would map to console.log();

        if (streamConfig && streamConfig.target != 'server' && _.isNil(streamConfig.path)) {
            collector[streamName] = streamConfig;
        }
        return collector;
    }, {});
    return _.assign({}, config, { streamsByName: newConfigStreams });
}

function _newConfigForServer(config) {
    if (typeof config == 'string') {
        config = {
            TAG: config
        };
    }
    var configStreams = config.streamsByName;
    if (configStreams === undefined) {
        return _.assign({}, config);
    }

    var newConfigStreams = _.reduce(configStreams, function (collector, streamConfig, streamName) {
        if (streamConfig && streamConfig.target != 'client') {
            collector[streamName] = streamConfig;
        }
        return collector;
    }, {});

    return _.assign({}, config, { streamsByName: newConfigStreams });
}

module.exports = {
    getFullConfig: function getFullConfig() {
        return config;
    },
    prepareConfig_forEnv: function prepareConfig_forEnv(config, envString) {
        if (envString == 'server') {
            return _newConfigForServer(config);
        } else {
            return _newConfigForClient(config);
        }
    },
    getConfig_forEnv: function getConfig_forEnv(envString) {
        var fullConfig = this.getFullConfig();
        return this.prepareConfig_forEnv(fullConfig, envString);
    }
};