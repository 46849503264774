'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

(function ($) {

    // Create a detached dom node from an html string

    $.from_html = function (html) {
        var div = document.createElement('div');
        div.innerHTML = html;
        var elem = div.childNodes[0];
        div.removeChild(elem);
        return elem;
    };

    // Get all attributes of an element as a hash.
    // The returned hash is suitable input for the $(...).attr.(...) setter.

    $.fn.attr_all = function () {
        var attr = {};

        for (var i = 0; i < this[0].attributes.length; i++) {
            var node = this[0].attributes[i];
            var key = node.nodeName;
            if (key == 'class') key = 'className';
            attr[key] = node.value;
        }

        return attr;
    };

    // Generate a query string from a hash.
    // Returns a blank string if the hash is empty
    // Returns a string that starts with "?" otherwise

    $.generate_query = function (query) {

        var query_string = '';

        // Loop through the hash and generate key/value pairs
        var pairs = [];
        for (var key in query) {
            var val = query[key];

            // Be sure to handle arrays
            if ($.isArray(val)) {
                for (var i = 0; i < val.length; i++) {
                    pairs.push([key, val[i]]);
                }
            } else {
                pairs.push([key, val]);
            }
        } // for each key in the query hash

        // Loop through each key/value pair
        var encPairs = [];
        for (var i = 0; i < pairs.length; i++) {
            // And encode the key/value and join with a "="
            encPairs.push(encodeURIComponent(pairs[i][0]) + '=' + encodeURIComponent(pairs[i][1]));
        }

        // Return the query string  (or an empty string)
        if (encPairs.length > 0) return '?' + encPairs.join('&');else return '';
    }; // generate_query


    // Parse a query string into a flat hash structure.
    // If the same key is encountered multiple times, the value
    // under that key will be an array.

    $.parse_query = function (str) {

        var i = str.indexOf('?');
        if (i >= 0) str = str.substr(i + 1);

        var pairs = str.split('&');
        var param = {};

        for (var i in pairs) {
            var kv = pairs[i].split('=');
            var k = decodeURIComponent(kv[0]);
            var v = decodeURIComponent(kv[1]);
            k = k.replace(/\+/g, ' ');
            v = v.replace(/\+/g, ' ');

            if (param[k] == null) {
                param[k] = v;
            } else {
                if (typeof param[k] == 'string') param[k] = [param[k]];
                param[k].push(v);
            }
        }

        return param;
    };

    /*
      Turn a nested structure into a flat hash suitable for
      constructing a query string. Example:
     
       { foo: { bar: 42, baz: [ 5, 6, 7 ] } }
     
      becomes
     
       {
         "foo.bar" : 42,
         "foo.baz.0" : 5,
         "foo.baz.1" : 6,
         "foo.baz.2" : 7
       }
    */

    $.flatten_param = function (param) {
        var newparam = {};

        for (var k1 in param) {
            var v1 = param[k1];

            if (v1 != null && (typeof v1 === 'undefined' ? 'undefined' : _typeof(v1)) == 'object') {

                var subparam = $.flatten_param(v1);
                for (var k2 in subparam) {
                    var v2 = subparam[k2];
                    newparam[k1 + '.' + k2] = v2;
                }
            } else {
                newparam[k1] = v1;
            }
        }

        return newparam;
    };

    /*
      Turn a flat hash of path specifications into a nested structure.
      This is the inverse operation of $.flatten_param. For example,
      the input:
     
       {
         "foo.bar" : 42,
         "foo.baz.0" : 5,
         "foo.baz.1" : 6,
         "foo.baz.2" : 7
       }
    
      becomes
    
       { foo: { bar: 42, baz: [ 5, 6, 7 ] } }
    
    */

    $.unflatten_param = function (param) {

        var newparam = { root: null };

        // For each pathspec, insert a value.

        $.each(param, function (pathspec, val) {

            var path = pathspec.split(".");
            path.unshift("root");

            // Descend into the structure via the path.

            var p = newparam;

            for (var i = 0; i < path.length - 1; i++) {

                var key1 = path[i];
                var key2 = path[i + 1];
                var want_array = key2.match(/^\d+$/);

                if (p[key1] == null) {

                    // Nothing currently occupying this slot,
                    // so create an array or hash there.

                    if (want_array) p[key1] = [];else p[key1] = {};
                } else if ($.isArray(p[key1]) && !want_array) {

                    // Slot already occupied by an array, but we have a non-numeric key.
                    // Upgrade the array to a hash.

                    var h = {};

                    for (var j = 0; j < p[key1].length; j++) {
                        h[j] = p[key1][j];
                    }p[key1] = h;
                } else if (_typeof(p[key1]) != 'object') {

                    // Slot already occupied by eg. a number or string,
                    // preventing us from indexing in.

                    var path_so_far = path.slice(1, i + 1).join(".");
                    throw "unflatten: nested data conflict at path " + path_so_far;
                }

                // If we're at the end of the path, insert the value.
                // Otherwise, descend deeper into the structure.

                if (i == path.length - 2) {

                    if (p[key1][key2] != null) throw "unflatten: nested data conflict at path " + pathspec;else p[key1][key2] = val;
                } else p = p[key1];
            }
        });

        return newparam.root ? newparam.root : {};
    };

    // Takes a date object and returns a DB suitable date string

    $.format_date = function (dateObj) {
        var monthStr = dateObj.getMonth() + 1;
        if (monthStr < 10) monthStr = '0' + '' + monthStr;

        var dayStr = dateObj.getDate();
        if (dayStr < 10) dayStr = '0' + '' + dayStr;

        return dateObj.getFullYear() + '-' + monthStr + '-' + dayStr;
    }; // format_date

    // Parse a date string "mm/dd/YYYY" and return a Date object
    // Will return null if parsing fails

    $.parse_date = function (dateStr) {

        // Validate date
        if (typeof dateStr === "undefined" || !dateStr) {
            return null;
        }

        var year, month, day;
        var hour, min, sec;
        if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.exec(dateStr)) {

            // Parse date
            var dateParts = dateStr.split('/');
            for (var i = 0; i < dateParts.length; i++) {
                dateParts[i] = dateParts[i].replace(/^0/, "");
                dateParts[i] = parseInt(dateParts[i]);
            }

            // And do some more validation
            if (dateParts[0] < 1 || dateParts[0] > 12 // validate month
            || dateParts[1] < 0 || dateParts[1] > 31 // validate day
            || dateParts[2] < 1900 || dateParts[2] > 3000) // validate year
                {
                    return null;
                }

            year = dateParts[2];
            month = dateParts[0];
            day = dateParts[1];
        } else if (match = /^(\d{4})-(\d{1,2})-(\d{1,2}) +(\d{1,2}):(\d{1,2}):(\d{1,2})(.[0-9+-]+)?$/.exec(dateStr)) {
            year = match[1];
            month = match[2];
            day = match[3];
            hour = match[4];
            min = match[5];
            sec = match[6];
        }

        if (year == null) return null;

        // Finally, enough validate, create our date object
        var date = new Date();
        date.setFullYear(year);
        date.setMonth(month - 1, day);

        if (hour != null) {
            date.setHours(hour);
            date.setMinutes(min);
            date.setSeconds(sec);
        }

        return date;
    };

    $.delta_days = function (date1, date2) {

        // Copy the dates
        var date1Norm = new Date(date1.getTime());
        var date2Norm = new Date(date2.getTime());

        // Set the times equal
        date1Norm.setHours(0);
        date1Norm.setMinutes(0);
        date1Norm.setSeconds(0);
        date1Norm.setMilliseconds(0);

        date2Norm.setHours(0);
        date2Norm.setMinutes(0);
        date2Norm.setSeconds(0);
        date2Norm.setMilliseconds(0);

        // Take the epoch which should only differ in multiples of a day
        var epoch1 = date1Norm.getTime();
        var epoch2 = date2Norm.getTime();

        var oneDay = 1000 * 60 * 60 * 24; // milli * secs * mins * hours

        var deltaSeconds = epoch2 - epoch1;

        return deltaSeconds / oneDay;
    }; // delta_days

    $.format_datetime = function (dateObj, fmt, opts) {

        if (typeof dateObj == "undefined" || dateObj == null) return null;

        if (typeof opts == "undefined" || opts == null) opts = {};

        if (fmt == 'friendly') {

            // get today
            var now = new Date();

            var dd = $.delta_days(dateObj, now);

            var datepart = null;
            if (dd == 0) // then Today
                {
                    var timefmt = opts['short'] ? '%I:%M%p' : '%I%p';
                    datepart = $.format_datetime(dateObj, timefmt);
                    datepart = datepart.replace(/^0+/g, ''); //eliminate leading zeros
                    if (opts['short']) datepart = datepart.toLowerCase();

                    if (!opts['short']) datepart = "Today " + datepart;
                } else if (!opts['short'] && dd == 1) // yesterday
                {
                    datepart = 'Yesterday';
                } else if (!opts['short'] && dd == -1) // tmw
                {
                    datepart = 'Tomorrow';
                } else if (opts['short'] && dd <= 365) // <= year
                {
                    datepart = $.format_datetime(dateObj, '%b %e');
                } else // > year
                {
                    if (dateObj.getFullYear() == now.getFullYear()) // this year
                        {
                            datepart = $.format_datetime(dateObj, '%b %e', { no_abbrev: opts['no_abbrev'] });
                        } else {
                        datepart = $.format_datetime(dateObj, '%b %e, %Y', { no_abbrev: opts['no_abbrev'] });
                    }
                }

            return datepart;
        } else if (/(%[IMpbeY])/.test(fmt)) {
            var monthsStrings = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

            var hour = dateObj.getHours();
            var hour12 = hour == 0 ? 12 : hour > 12 ? hour - 12 : hour;

            if (hour < 10) hour = '0' + hour;

            var minute = dateObj.getMinutes();
            if (minute == 0) minute = '00';else if (minute < 10) minute = '0' + minute;

            var amPM = hour >= 12 ? 'PM' : 'AM';

            var month = dateObj.getMonth();
            var month_str = opts['no_abbrev'] ? monthsStrings[month] : monthsStrings[month].substr(0, 3) + '.';
            var trimmedMonth = (month + 1 + "").replace(/^0/, '');

            var day = dateObj.getDate();
            var trimmedDay = (day + "").replace(/^0/, '');

            var fullYear = dateObj.getFullYear();

            var dateStr = fmt;

            dateStr = dateStr.replace(/%I/g, hour12);
            dateStr = dateStr.replace(/%M/g, minute);
            dateStr = dateStr.replace(/%p/g, amPM);
            dateStr = dateStr.replace(/%b/g, month_str);
            dateStr = dateStr.replace(/%m/g, trimmedMonth);
            dateStr = dateStr.replace(/%e/g, trimmedDay);
            dateStr = dateStr.replace(/%Y/g, fullYear);

            return dateStr;
        }

        return null;
    }; // format_datetime

    $.parse_number = function (number) {
        var match_number = /^[\n\f\r\s\t]*([0-9, ]+)[\n\f\r\s\t]*$/;
        var matches = match_number.exec(number);

        var parsed_number = null;

        if (matches) {
            parsed_number = matches[0].replace(',', '').replace(' ', '');
        }

        if (parsed_number != null) parsed_number = parseInt(parsed_number, 10);

        return parsed_number;
    }; // parse_number

    $.parse_money = function (money) {
        var is_negative = money.indexOf('-') >= 0;
        var match_money = /^[\n\f\r\s\t\$-]*([0-9, ]+(\.\d{1,2}|\.)?)[\n\f\r\s\t]*$/;
        var matches = match_money.exec(money);

        var parsed_money = null;

        if (matches) {
            parsed_money = matches[1].replace(/[ ,]/g, '');
        }

        if (parsed_money != null) parsed_money = parseFloat(parsed_money);

        return is_negative ? -1 * parsed_money : parsed_money;
    }; // parse_money

    $.format_money = function (money, trimZeroCents) {
        var formatted_money = money;

        var missing_zero = /^-?\d+\.\d$/;
        if (missing_zero.exec(money)) {
            formatted_money += '0';
        } else if (new String(formatted_money).indexOf('.') == -1) {
            formatted_money += '.00';
        }

        formatted_money = '$' + $.commify(formatted_money);

        formatted_money = formatted_money.replace(/\.00$/, '');

        return formatted_money;
    }; // format_money

    $.commify = function (number) {
        var commified_number = number;

        commified_number = $.reverse_string(commified_number);
        commified_number = commified_number.replace(/(\d{3})(?=\d)(?!\d*\.)/g, "$1,");
        commified_number = $.reverse_string(commified_number);

        return commified_number;
    }; // commify

    $.reverse_string = function (str) {
        str = new String(str);
        var reversed_string = null;

        if (str != null) {
            reversed_string = "";

            if (str.length > 0) {
                for (var i = str.length - 1; i >= 0; i--) {
                    reversed_string += str.charAt(i);
                }
            }
        }

        return reversed_string;
    }; // reverse_string

    $.max = function () {
        var max = null;

        $(arguments).each(function () {
            if (max == null || this > max) max = this;
        });

        return max;
    };

    $.min = function () {
        var min = null;

        $(arguments).each(function () {
            if (min == null || this < min) min = this;
        });

        return min;
    };

    $.trim = function (str) {
        var trimmed = str.replace(/^\s+/, '');
        trimmed = str.replace(/\s+$/, '');

        return trimmed;
    };

    $.ucfirst = function (str) {
        return str.substr(0, 1).toUpperCase() + str.substr(1);
    };

    $.lcfirst = function (str) {
        return str.substr(0, 1).toLowerCase() + str.substr(1);
    };

    $.rubyConfirm = function (opts) {

        var defaults = {
            title: "Confirm",
            msg: "Are you sure you want to proceed?",
            onconfirm: function onconfirm() {},
            onreject: function onreject() {},
            isyes: 0
        };

        var params = $.extend(true, defaults, opts);

        var dlg = $($.from_html('<div></div>')).dialog({
            modal: true,
            title: params.title,
            autoOpen: false,
            buttons: {
                Yes: function Yes() {
                    $(this).data('isyes', 1);
                    $(this).dialog('close');
                },
                No: function No() {
                    $(this).dialog('close');
                }
            },
            close: function close() {
                if ($(this).data('isyes')) {
                    $(this).data('onconfirm').call();
                } else {
                    $(this).data('onreject').call();
                }
            }
        });

        dlg.data('onconfirm', params.onconfirm);
        dlg.data('onreject', params.onreject);
        dlg.html(params.msg);
        dlg.dialog("open");
    }; // rubyConfirm


    $.rubyFormConfirm = function (opts) {
        var pageTouched = $(window).ruby('getPageTouched');
        if (!pageTouched && opts.action == "cancel") {
            var act = $('#action');
            act.val(opts.action);
            act.parents('form ')[0].submit();
            $(':piwik-track_edit_page_options').trigger('piwik_cancel_success');
            return false;
        }
        opts.onconfirm = function () {
            var act = $('#action');
            act.val(opts.action);
            act.parents('form')[0].submit();
            $(':piwik-track_edit_page_options').trigger('piwik_cancel_success');
        };

        opts.onreject = function () {
            $(':piwik-track_edit_page_options').trigger('piwik_cancel_fail');
        };

        $.rubyConfirm(opts);
    }; // rubyConfirmCancel

    $.rubyError = function (title, message, onClose, moreParams) {

        if (typeof title === "undefined" || title === null) title = 'Ruby System Error';

        if (typeof message === "undefined" || message === null) message = 'A systems error has occurred.  ' + 'Please try again in a few minutes';

        var params = {
            modal: true,
            title: title,
            autoOpen: false,
            buttons: {
                OK: function OK() {
                    $(this).dialog('close');
                }
            }
        };

        if (onClose) params.close = onClose;

        // DO NOT do a deep extend here,
        // that way the OK button can be removed by caller
        var finalParams = $.extend({}, params, moreParams || {});
        var dlg = $($.from_html('<div></div>')).dialog(finalParams);

        dlg.html(message);
        dlg.dialog("open");
    }; // rubyError


    // Ajax request error handler

    $.rubyAjaxError = function (req, textStatus, errorThrown) {
        if (req.status == 403) // Session expired
            window.location.reload();else if (req.status != 0) {
            //* req.status, req.statusText
            $.rubyError("Systems Error", "An error has occurred. Please try again in a few minutes.");
        }
    }; // rubyAjaxError

    // Enable or disable a jQuery ui widget

    $.fn.enableWidget = function (flag) {

        if (flag) {
            $(this).removeClass('ui-state-disabled');
            $(this).removeAttr('disabled');
        } else {
            $(this).addClass('ui-state-disabled');
            $(this).attr('disabled', 'disabled');
        }
    }; // $.fn.enableWidget

    $.selectmenuSwap = function () {
        // Loop through each select option with the rtg-swap class
        var select = this;
        var swaps = $('.rtg-swap', select);
        if (swaps.length > 0) {
            swaps.each(function () {

                // Parse out the id of the HTML element to toggle visibility
                var option = this;
                var swapClass = $.grep(option.className.split(/ /), function (n) {
                    return n.indexOf('rtg-swap-') == 0;
                });
                var swapId = swapClass[0].substr("rtg-swap-".length);

                // Show or hide that element based on if this option is selected
                if (option.selected) {
                    $('#' + swapId).show();
                } else {
                    $('#' + swapId).hide();
                }
            });
        }

        return;
    }; // selectmenuSwap

    $.parseDataFromElementClass = function (elm, strOrRegex) {
        var data = null;

        var isRegex = (typeof strOrRegex === 'undefined' ? 'undefined' : _typeof(strOrRegex)) == 'object' || typeof strOrRegex == 'function';

        var classes = $(elm).attr('class').split(/\s+/);
        for (var i = 0; i < classes.length; i++) {
            var classStr = classes[i];

            if (isRegex) {
                var matches = strOrRegex.exec(classStr);

                if (matches && matches[1]) {
                    data = matches[1];
                    break;
                }
            } else if (classStr.indexOf(strOrRegex) == 0) {
                data = classStr.substr(strOrRegex.length);
                break;
            }
        }

        return data;
    }; // parseClass

    $.rubyContentApprovalComment = function (elm) {
        var _elm$cluetip;

        elm.cluetip((_elm$cluetip = {
            onShow: function onShow(ct, inner) {
                // cluetip does not provide a way to update the title when using
                // ajax; this code will use the H1 in the HTML returned as the title
                var h1 = inner.find('h1').remove();
                var title = ct.find('#cluetip-title');
                title.html(h1.html());
            }, // onshow
            cluetipClass: 'jtip ruby-comment-popup',
            attribute: 'rel',
            dropShadow: true,
            arrows: true,
            clickThrough: false,
            apositionBy: 'auto'
        }, _defineProperty(_elm$cluetip, 'apositionBy', 'mouse'), _defineProperty(_elm$cluetip, 'positionBy', 'bottomTop'), _defineProperty(_elm$cluetip, 'sticky', false), _elm$cluetip));
    }; // rubyContentApprovalComment

    $.isDefined = function (variable) {
        return typeof variable !== "undefined" && variable != null;
    };

    $.supportsVideo = function () {
        //* checks if <video> tag is supported
        return !!document.createElement('video').canPlayType;
    };

    // adding a function to add the * for msie browsers
    //rtg-layout .rtg-widget.required .rtg-label label
    $.addAsterisks = function (selector) {
        if ($.browser.msie && $.browser.version == "6.0" || $.browser.version == "7.0") {
            $(selector).each(function (i, e) {
                $(e).html($(e).html() + "*");
            });
        }
    };

    // profileCode( label, code )
    //
    //     profileCode( 'a.sitemap', function () {
    //         var links = $( 'a.sitemap' );
    //         links.initSometing();
    //         return links.length;
    //     });
    //
    // Time how long it takes a block of code to run
    // Log out that time with the suplied label
    // If the block of code returns a number, the output that number in
    // the logs, also
    //
    $.profileCode = function (label, code) {
        var t1 = new Date().getTime();
        var count = code();
        var t2 = new Date().getTime();
        if (typeof count != 'undefined' && count != null) console.log(label + ': ' + count);else console.log(label);
        console.log('  ^ ' + (t2 - t1) / 1000 + ' seconds');
    };

    $.increasePageHeight = function (popup, fixTopPosition) {

        if (fixTopPosition == null || typeof fixTopPosition == 'undefined') fixTopPosition = true;

        var page = $('#page-holder');

        // If popup is taller than the page height
        var pageHeight = page.outerHeight(true);
        var popupHeight = popup.outerHeight(true) + popup.position().top;

        if (popupHeight > pageHeight) {

            // Then add padding to the bottom of the page
            var padding = popupHeight - pageHeight;

            // Add some padding between the browser and the popup
            padding += 40;

            page.css('padding-bottom', padding + "px");

            // Push the popup down a bit so that the top is not flush
            if (fixTopPosition) popup.css('top', '20px');

            // Adjust the overlay height
            pageHeight = page.outerHeight(true) + 40;
            $('.ui-widget-overlay').height(pageHeight);
        }

        return;
    }; // _increasePageHeight

    $.decreasePageHeight = function () {

        var page = $('#page-holder');
        page.css('padding-bottom', '');

        return;
    }; // _decreasePageHeight

    $.formatFileSize = function (bytes) {
        var sizes = ['b', 'kb', 'mb', 'gb']; // let's hope we aren't dealing with anything larger
        if (bytes == 0) return '0b';
        var k = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, k), 2) + sizes[k];
    };

    $.getValidImageExtensions = function () {
        return ['png', 'jpg', 'jpeg', 'gif'];
    };

    // Return an error object if invalid
    $.validateFileSize = function (file) {
        var error = null;

        var image_file_exts = $.getValidImageExtensions();
        var file_ext = file.name.split('.').pop().toLowerCase();

        // enforce file size limit
        if (image_file_exts.indexOf(file_ext) != -1) {
            // enforce image size limit!
            var image_size_limit_container = $('#media-image-file_size_limit');

            if (image_size_limit_container.length) {
                // if image size limit specified
                var image_size_limit = parseInt($('#media-image-file_size_limit').html());

                if (file.size > image_size_limit) {
                    error = {
                        title: 'Image Size Limit Exceeded',
                        msg: 'The image you chose (' + file.name + ') is too large.  Image size limit is ' + $.formatFileSize($('#media-image-file_size_limit').html())
                    };
                }
            }
        }

        if (file.size > parseInt($('#media-file_size_limit').html())) {
            error = {
                title: 'File Size Limit Exceeded',
                msg: 'The file you chose (' + file.name + ') is too large.  File size limit is ' + $.formatFileSize($('#media-file_size_limit').html())
            };
        }

        return { err: error };
    };

    $.rubyBranding = function () {
        return $('body').data('rubyBranding');
    };

    $.rubySetupMultiSelects = function (el) {
        $(el).multiselectable({
            selectableLabel: 'Available:'
        });
    };

    // Really, we want these functions to run on individual compnents ( container )
    // but to preserve ruby functionality, we are falling back to the hardcoded selectors
    $.rubySetupDropdowns = function (elem, dropdowns) {
        if (!dropdowns) {
            // Setup flyout dropdowns for single-selection <select>s.
            dropdowns = $('.rtg-widget select[multiple!=multiple]', elem).map(function () {
                return $(this).attr('size') > 1 ? null : this;
            });
        }
        dropdowns.each(function () {
            var selectbox = $(this);

            // If a select box it set to show more than one item at a time
            // Then we should not convert it to our pretty dropdown
            // This is the case for the selectbox on the modify parent page
            if (selectbox.attr('size') > 1) return;

            // If select box is hidden, then the dropdown will not look right
            var hiddenParent = selectbox.parents(':hidden:last');
            var parentDisplayVal = hiddenParent.css('display');

            // So, change its hidden parent to be hidden-accessible
            if (hiddenParent.length > 0) {
                hiddenParent.addClass('ui-helper-hidden-accessible').css('display', 'block');
            }

            // Then init the dropdown
            selectbox.selectmenu({
                style: 'popup',
                maxHeight: 300,
                handleWidth: 20, /* 20px of left/right padding */
                open: function open(e) {
                    /* move to below header if dropdown goes past it */
                    var selectId = this.id + "-menu",
                        menu = $('#' + selectId).parent(),
                        buffer = 15,
                        offset = buffer;
                    if (menu.length > 0 && menu.offset().top < offset) {
                        menu.offset({ top: offset });
                    }
                }
            }).unbind('selectmenuchange').bind('selectmenuchange', $.selectmenuSwap).each(function () {
                $.selectmenuSwap.call(this);
            });

            // Trigger an event change for ReactJs to listen to
            selectbox.on('selectmenuchange', function (e) {
                // Let the select menu finish its event loop before firing the react event
                window.setTimeout(emitReactEvent.bind(null, selectbox.get(0), 'change'), 0);
            });

            // And change the hidden parent back
            if (hiddenParent.length > 0) {
                hiddenParent.css('display', parentDisplayVal).removeClass('ui-helper-hidden-accessible');
            }

            // Set zIndex (for this to work, $.rubyReady must be called on open)
            var parentDlg = selectbox.parents('.ui-dialog');
            if (parentDlg.length > 0) {
                var dlgZIndex = parseInt(parentDlg.css('zIndex'), 10);
                // Add three because if the dlg is currently hidden, then it
                // will jump by two when it becomes visible
                selectbox.data('selectmenu').list.css('zIndex', dlgZIndex + 3);
            }
        });

        return;
    }; // rubySetupDropdowns

    $.rubySetFormErrors = function (elem, errs, prefix) {

        if (!prefix) prefix = '';

        for (var key in errs) {
            var err = errs[key];

            if ((typeof err === 'undefined' ? 'undefined' : _typeof(err)) === 'object' && typeof err.message !== 'undefined' && typeof err.code !== 'undefined') {
                err = err.message;
            }

            if ((typeof err === 'undefined' ? 'undefined' : _typeof(err)) === 'object') {
                $.rubySetFormErrors(elem, err, prefix + key + '.');
            } else {
                var fieldId = prefix + key + '-field';
                var field = elem.find('#' + fieldId.replace('.', '\\.'));
                var errBox = $('.ui-state-error', field);
                if (errBox.length <= 0) {
                    field.append('<dd class="ui-state-error"></dd>');

                    errBox = $('.ui-state-error', field);
                }

                errBox.html(err).show();

                // Find the tab that corresponds to this field
                var parentTabContainer = field.parents('.ui-tabs-panel');
                if (parentTabContainer.length && parentTabContainer.attr('id').indexOf('-box') > 0) {

                    var tabId = parentTabContainer.attr('id').replace('-box', '');
                    var tab = $('#' + tabId + '-tab');
                    if (tab.length > 0 && !tab.hasClass('ui-state-error')) {

                        // And highlight the tab as having an errror
                        tab.prepend('<span class="ui-icon ui-icon-alert"></span>');
                        tab.addClass('ui-state-error');
                    }
                }

                // Find the fieldset that corresponds to this field
                var parentFieldsetContainer = field.parents('.rtg-fieldset');
                if (parentFieldsetContainer.length) {
                    // And see if it is an expandoband
                    var parentFieldsetExpando = $(parentFieldsetContainer).find('a.rtg-expandoband');
                    // If it does expand
                    if (parentFieldsetExpando.length) {
                        // Then force it open
                        $(parentFieldsetExpando).expandCollapse('open');
                    }
                }
            } // if string
        } // foreach error

        return;
    }; // rubySetFormErrors

    $.rubyClearFormErrors = function (elem) {

        // Clear any errors in the tabs
        $('.ui-tabs-nav .ui-state-error').each(function (idx, tab) {
            $(tab).find('.ui-icon-alert').remove();
            $(tab).removeClass('ui-state-error');
        });

        $('.ui-state-error', elem).html('').hide();
    }; // rubyClearFormErrors
})(jQuery); // function ($)