'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Promise = require('bluebird');
var rubyLogger = require('@rubyapps/ruby-logger');
var logger = rubyLogger.getLogger('ruby-component-forms:client:index');

var React = require('react');
var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var memoize = require('memoizee');

var _require = require('@rubyapps/ruby-memoize'),
    defaultMemoizeeOptions = _require.defaultMemoizeeOptions;

var jsonselect = require('JSONSelect');
var headerFormSelector = jsonselect.compile('object:has(:root > .componentName:val("Header"))');
var tabFormSelector = jsonselect.compile('object:has(:root > .componentName:val("Tab"))');

require('deepdash')(_lodash2.default);

var rubyComponent = require('@rubyapps/ruby-component');

var RubyComponentPluginTemplateEditor__CONSTANTS = require('@rubyapps/ruby-component-plugin-template-editor/src/common/constants');
var RubyComponentConfirmDialog__CONSTANTS = require('@rubyapps/ruby-component-confirm-dialog/src/common/constants');
var RubyComponentFieldRepeater__CONSTANTS = require('@rubyapps/ruby-component-field-repeater/src/common/constants');
var currentUserMixin = require('@rubyapps/ruby-component-mixin-current-user');
var formsContentMixin = require('@rubyapps/ruby-component-mixin-forms-content');
var eventEmitterMixin = require('@rubyapps/ruby-component-mixin-event-emitter');
var fieldSpecMixin = require('./fieldSpecMixin');

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;
var action = require('./action');
var reducer = require('./reducer');
var middleware = require('./middleware');

var _require2 = require('@rubyapps/ruby-component-plugin-template-editor/src/common/constants'),
    ADMIN_TEMPLATE = _require2.ADMIN_TEMPLATE,
    TOP_LEVEL_TEMPLATE_TYPES = _require2.TOP_LEVEL_TEMPLATE_TYPES;

var templates = {};
var defaultState = _extends({}, templates);

function parseFieldKey(key) {
    var parts = key.match(/^(.+?)(\d+)$/);
    var fieldPrefix = key;
    var fieldNumber = 1;
    if (!/^top_column_\d+/.test(key) && parts) {
        fieldPrefix = parts[1];
        fieldNumber = parseInt(parts[2], 10);
    }
    return { fieldPrefix: fieldPrefix, fieldNumber: fieldNumber };
};
function provideRangeOfFields(name, numberOfFields) {
    return name + ' (up to ' + numberOfFields + ')';
};

function reduceTree(tree, reducer, getChildren, collection) {
    return _lodash2.default.reduce(tree, function (coll, value, keyOrIdx) {
        var newColl = reducer(coll, value, keyOrIdx);
        return reduceTree(getChildren(newColl, value, keyOrIdx), reducer, getChildren, newColl);
    }, collection);
}

var RCForms = rubyComponent.createClass({
    componentName: componentName,
    mixins: [currentUserMixin, formsContentMixin, eventEmitterMixin, fieldSpecMixin],
    action: action,
    reducer: reducer,
    middleware: middleware,
    propTypes: {
        confirmDialogID: PropTypes.string
    },
    getDefaultProps: function getDefaultProps() {
        return {
            pluginTemplateEditorId: RubyComponentPluginTemplateEditor__CONSTANTS.COMPONENT_NAME,
            confirmDialogID: RubyComponentConfirmDialog__CONSTANTS.COMPONENT_NAME
        };
    },
    getInitialState: function getInitialState() {
        return defaultState;
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();
        var selfAction = this.getAction();

        var pluginTemplateEditorComponent = root.findDescendentByID(this.props.pluginTemplateEditorId);

        return {
            selfSelector: selfSelector,
            selfAction: selfAction,
            pluginTemplateEditorComponent: pluginTemplateEditorComponent
        };
    },
    overrideProps_withConfig: function overrideProps_withConfig(config, dispatch) {
        dispatch(this.getAction().generators.overrideProps_withConfig(config));
    },
    onReduxInit: function onReduxInit(dispatch) {
        dispatch(this.getAction().generators.pollTemplates());
    },
    CONSTANTS: CONSTANTS

    //== UTILITIES =========================================================//
    , setTemplate_atKey: function setTemplate_atKey(template, key) {
        var _getAction$generators;

        return this.getStore().dispatch((_getAction$generators = this.getAction().generators).setTemplate_atKey.apply(_getAction$generators, arguments));
    },
    getTemplateForKey: function getTemplateForKey(key, templatesState) {
        templatesState = templatesState || this.getState();

        var selectedTemplate = _lodash2.default.get(templatesState, [key]);

        return selectedTemplate;
    },
    isValidTopLevelTemplate: function isValidTopLevelTemplate(template) {
        return _lodash2.default.includes(TOP_LEVEL_TEMPLATE_TYPES, template.templateType);
    },
    augmentedTemplate: function augmentedTemplate(template, callingModule) {
        return template;
    }

    //# NOTE: cannot just memoize this, we need to memoize each call to 'augmentedTemplate()' 
    //# ... each template augmentor cares about a different set of states
    //# we are however, allowing temporary memoization
    //# NOTE: templateState would change if the template has been updated
    , getAugmentedTemplateForKey: memoize(function (key, templateState, callingModule) {
        var _this = this;

        //return this.getTemplateForKey(key, templateState);
        var templateToAugment = this.getTemplateForKey(key, templateState);
        if (!templateToAugment) {
            return templateToAugment;
        }

        var mixins = this.getMixins();
        var augmentedTemplateFns = [this.augmentedTemplate].concat(mixins.filter(function (mixin) {
            return mixin.hasOwnProperty('augmentedTemplate');
        }).map(function (mixin) {
            return mixin.augmentedTemplate;
        }));

        var clonedTemplateToAugment = _lodash2.default.cloneDeep(templateToAugment);

        var augmentedTemplate = _lodash2.default.reduce(augmentedTemplateFns, function (result, augmentor) {
            return augmentor.call(_this, result, callingModule);
        }, clonedTemplateToAugment);

        //# add template key to the form component so we have access to that info in form props
        augmentedTemplate.form.template_keyword = key;

        logger.debug('[getAugmentedTemplateForKey()]', key, augmentedTemplate);
        return augmentedTemplate;
    }, _extends({}, defaultMemoizeeOptions, { profileName: 'getAugmentedTemplateForKey', maxAge: 1000 /*ms*/ * 60 /*sec*/ * 2 /*minutes*/ })),

    promisedAugmentedTemplateForKey: function promisedAugmentedTemplateForKey(key, templateState, callingModule) {
        var _this2 = this;

        if (templateState) {
            return Promise.resolve(this.getAugmentedTemplateForKey(key, templateState, callingModule));
        }

        return new Promise(function (resolve) {
            _this2.once('state:' + key, function () {
                resolve(_this2.getAugmentedTemplateForKey(key, templateState, callingModule));
            }, { preempt: true });
        });
    },

    getActiveTemplateKey: function getActiveTemplateKey() {
        var selfModule = this;
        var activeRouteComponent = selfModule.getActiveRouteComponent();
        var currentTemplateKey = _lodash2.default.result(activeRouteComponent, 'getTemplateKey');
        return currentTemplateKey;
    },
    activeTemplatesAreDifferent_fromPrevState: function activeTemplatesAreDifferent_fromPrevState(previousTemplates) {
        var selfModule = this;
        var newTemplates = selfModule.getState();

        var activeTemplateKey = selfModule.getActiveTemplateKey();
        var activeRouteComponent = selfModule.getActiveRouteComponent();

        if (!activeRouteComponent) {
            return false;
        }

        var profileFields = activeRouteComponent.findDescendentsBy(function (el) {
            return el.componentName === RubyComponentFieldRepeater__CONSTANTS.COMPONENT_NAME && _lodash2.default.result(el, 'hasRemoteOptions');
        });
        var activeProfileModuleTypes = _lodash2.default.flowRight(_lodash2.default.uniq, function (values) {
            return values.map(function (value) {
                return value.type;
            });
        }, _lodash2.default.flattenDeep, function (fields) {
            return fields.map(function (field) {
                return [].concat(_lodash2.default.flatten(_lodash2.default.values(field.formValue())) //# selected profiles
                , _lodash2.default.result(field, 'getState.options') //# retrieved profiles
                );
            });
        })(profileFields);

        var activeTemplateKeys = [].concat(activeTemplateKey || [], activeProfileModuleTypes);

        return activeTemplateKeys.length > 0 && _lodash2.default.some(activeTemplateKeys, function (templateKey) {
            return !_lodash2.default.isEqual(selfModule.preparedTemplateForComparison(newTemplates[templateKey]), selfModule.preparedTemplateForComparison(previousTemplates[templateKey]));
        });
    },
    openTemplateChangedDialog_forTemplate: function openTemplateChangedDialog_forTemplate(template) {
        var selfModule = this;
        var rootComponent = selfModule.getRoot();

        var confirmDialogComponent = rootComponent.findDescendentByID(selfModule.props.confirmDialogID);
        var confirmDialogActions = confirmDialogComponent.getAction().generators;
        var dispatch = selfModule.getStore().dispatch;

        template && dispatch(confirmDialogActions.openDialogWithOptions({
            title: 'New Version Of The Current Template Detected',
            content: 'The ' + template.name + ' template has been modified.' + ' To ensure the integrity of your data, please refresh the current page.' + ' Attempting to save your current work may fail if fields have been removed or added to this template.',
            submitLabel: 'Refresh',
            submitSuccessCallback: function submitSuccessCallback(response) {
                window.location.reload();
            }
        }));
    },
    preparedTemplateForComparison: function preparedTemplateForComparison(template) {
        return _lodash2.default.omit(template, ['created_timestamp', 'hiddenValues', '__expandedWithTemplatesMap']);
    },
    availableFieldsInTemplateForEditing: function availableFieldsInTemplateForEditing(template) {
        var headerAndFirstTab = headerFormSelector.match(template).concat(_lodash2.default.first(tabFormSelector.match(template)) || []);

        var fieldsByPrefix = reduceTree(
        //[ template.form ], // the tree
        headerAndFirstTab,

        // the reducer function
        function (map, field, fieldIdx) {
            // Only include fields that have a key and label
            // (and they are not an info field, hidden, or namespace)
            if (field.key && field.label && field.componentName != 'Hidden' && field.componentName != 'Info' && field.componentName != 'NamespaceSelector') {
                var _parseFieldKey = parseFieldKey(field.key),
                    fieldPrefix = _parseFieldKey.fieldPrefix,
                    fieldNumber = _parseFieldKey.fieldNumber;

                var mappedFields = map[fieldPrefix] ? map[fieldPrefix] : [];
                return _lodash2.default.assign({}, map, _defineProperty({}, fieldPrefix, mappedFields.concat({ fieldIdx: fieldIdx, field: field, fieldNumber: fieldNumber })));
            } else {
                return map;
            }
        },

        // the get children function
        function (map, field, fieldIdx) {
            // Only return children, grandchildren, etc
            // of the first tab
            var isArray = _lodash2.default.isArray(field.children);
            var hasChildren = isArray && _lodash2.default.size(field.children) > 0;

            return hasChildren ? field.children : [];
        }, {} // the initial value
        );

        var availableFields = Object.keys(fieldsByPrefix).reduce(function (list, prefix) {
            var fields = fieldsByPrefix[prefix];
            if (fields.length === 1) {
                list.push(fields[0].field.label);
            } else if (fields.length > 1) {
                var numberOfFields = fields.length;
                if (fields[0].fieldNumber == 1 && fields[numberOfFields - 1].fieldNumber == numberOfFields) {
                    list.push(provideRangeOfFields(fields[0].field.label, numberOfFields));
                } else {
                    fields.forEach(function (field) {
                        list.push(field.field.label);
                    });
                }
            }
            return list;
        }, []);

        return availableFields;
    },
    templatesForActiveRouteComponent: function templatesForActiveRouteComponent() {
        var templates = this.getState();
        return this.filteredTemplatesForActiveRouteComponent(_lodash2.default.map(templates, function (template) {
            return template;
        }));
    }

    //# Template filtering
    , filteredTemplatesForActiveRouteComponent: function filteredTemplatesForActiveRouteComponent(templates) {
        var activeRouteComponent = rubyApp.getActiveRouteComponent();
        if (activeRouteComponent == undefined) {
            return [];
        } else if (activeRouteComponent.getActiveRubyClientInfo == undefined) {
            console.warn(activeRouteComponent.getID(), 'does not have the accessMixin, so you cannot call on getActiveRubyClientInfo()');
            return [];
        }

        var _activeRouteComponent = activeRouteComponent.getState(),
            routeParams = _activeRouteComponent.routeParams;

        var rubyClientInfo = activeRouteComponent.getActiveRubyClientInfo();
        var currentSubsite = rubyClientInfo.subsite;
        var currentTemplate = routeParams.template;


        var filteredTemplates = _lodash2.default.reduce(templates, function (collector, template) {
            var templateKey = template.key,
                ruby_subsite_fk = template.ruby_subsite_fk;


            var isSameAsCurrentTemplate = templateKey === currentTemplate;
            var subsiteMatches = ruby_subsite_fk === currentSubsite || Array.isArray(ruby_subsite_fk) && _lodash2.default.includes(ruby_subsite_fk, currentSubsite) || ruby_subsite_fk == null;

            if (subsiteMatches && !isSameAsCurrentTemplate) {
                collector.push(template);
            }
            return collector;
        }, []);

        return filteredTemplates;
    }
    //# canMergeContent_withTemplateKey is being defined here
    //*  but we might want to include it using registerMixin from the proposal plugin
    , canMergeContent_withTemplateKey: function canMergeContent_withTemplateKey(templateKey) {
        var templates = this.getState();

        return _lodash2.default.get(templates, [templateKey, 'allowContentMerge'], false);
    },

    isResolved: function isResolved() {
        return !!this._retrievedTemplates;
    },

    pickFields_inTemplate_byPicker_withOptions: function pickFields_inTemplate_byPicker_withOptions(template, picker, suppliedOptions) {
        var defaultOptions = {
            eachDeepOptions: {
                checkCircular: true
            },
            baseFieldPicker: function baseFieldPicker(node) {
                return node && node.hasOwnProperty('componentName');
            }
        };
        var options = _lodash2.default.defaults(suppliedOptions, defaultOptions);
        var pickedFields = [];
        _lodash2.default.eachDeep(template, function (value) {
            if (value && rubyComponent.isComponent(value)) {
                //# don't try to iterate over children of a ruby-component; you'll get a stack overflow
                return false;
            }
            if (options.baseFieldPicker.apply(options, arguments) && picker.apply(undefined, arguments)) {
                pickedFields.push(value);
            } else {
                pickedFields;
            }
        }, [], options.eachDeepOptions);
        return pickedFields;
    }
});
module.exports = RCForms;