'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _typeToGridHeight;

var _reactDom = require('react-dom');

var ReactDOM = _interopRequireWildcard(_reactDom);

var _reactGridLayout = require('@rubyapps/react-grid-layout');

var _reactGridLayout2 = _interopRequireDefault(_reactGridLayout);

require('@rubyapps/react-grid-layout/css/styles.css');

require('react-resizable/css/styles.css');

var _FieldCard = require('./FieldCard');

var _FieldCard2 = _interopRequireDefault(_FieldCard);

var _Field = require('./Field');

var _Field2 = _interopRequireDefault(_Field);

var _Fieldset = require('./Fieldset');

var _Fieldset2 = _interopRequireDefault(_Fieldset);

var _Tab = require('./Tab');

var _Tab2 = _interopRequireDefault(_Tab);

var _SubTemplate = require('./SubTemplate');

var _SubTemplate2 = _interopRequireDefault(_SubTemplate);

var _FloatingPlusMenu = require('./FloatingPlusMenu');

var _FloatingPlusMenu2 = _interopRequireDefault(_FloatingPlusMenu);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var PropTypes = React.PropTypes;

var ReactGridLayout = (0, _reactGridLayout.WidthProvider)(_reactGridLayout2.default);

//import FieldCard from './FieldCard';


var FieldHOC = (0, _FieldCard2.default)(_Field2.default);
var FieldsetHOC = (0, _FieldCard2.default)(_Fieldset2.default);
var TabHOC = (0, _FieldCard2.default)(_Tab2.default);
var SubTemplateHOC = (0, _FieldCard2.default)(_SubTemplate2.default);

var TAB_KEY = 'Tab';
var FIELDSET_KEY = 'Fieldset';
var FIELD_KEY = 'Field';
var SUBTEMPLATE_KEY = 'SubTemplate';
var typeToGridHeight = (_typeToGridHeight = {}, _defineProperty(_typeToGridHeight, FIELD_KEY, 320), _defineProperty(_typeToGridHeight, FIELDSET_KEY, 162), _defineProperty(_typeToGridHeight, TAB_KEY, 100), _defineProperty(_typeToGridHeight, SUBTEMPLATE_KEY, 180), _typeToGridHeight);
var FIELD_PROP_KEYS = ['overrideLocked', 'all_permissions', 'subtemplates', 'contentTemplateOptions', 'rubyClientInfo', 'selfModule'];

var FieldEditor = React.createClass({
    displayName: 'FieldEditor',

    propTypes: {
        actions: PropTypes.object,
        contentTemplateOptions: PropTypes.arrayOf(PropTypes.object),
        rubyClientInfo: PropTypes.object
    },
    componentDidMount: function componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
        window.addEventListener('scroll', this.onWindowScroll);
        this.onWindowScroll();
    },
    componentWillUnmount: function componentWillUnmount() {

        //# stop listening to layout width changes
        window.removeEventListener('resize', this.onWindowResize);
        window.removeEventListener('scroll', this.onWindowScroll);
        this._layoutElement = undefined;
    },
    onWindowResize: function onWindowResize(_event) {
        if (!this._layoutElement) {
            return;
        };

        this.setState({ layoutWidth: this._layoutElement.offsetWidth });
    },
    onWindowScroll: _.throttle(function (event) {
        if (!this._layoutElement) {
            return;
        };
        var windowScrollerProps = {
            height: window.innerHeight,
            scrollTop: window.scrollY
        };
        if (!_.isEqual(windowScrollerProps, this.state.windowScrollerProps)) {
            this.setState({ windowScrollerProps: windowScrollerProps });
        }
    }, 100, {
        leading: true,
        trailing: true
    }),
    getDefaultProps: function getDefaultProps() {
        return {
            fieldState: {
                instances: {},
                childIds: []
            }
        };
    },
    getInitialState: function getInitialState() {
        return {
            itemBeingDragged: null,
            layoutWidth: 1200,
            windowScrollerProps: {
                height: window.innerHeight,
                scrollTop: window.scrollY
            }
        };
    },
    setItemBeingDragged: function setItemBeingDragged(id) {
        this.setState({
            itemBeingDragged: id
        });
    },
    _handleDragStart: function _handleDragStart(layout, oldItem, newItem, placeholder, evt, htmlElement) {
        this.setItemBeingDragged(oldItem.i);
    },
    _handleDragStop: function _handleDragStop(layout, oldItem, newItem, placeholder, evt, htmlElement) {
        var sortedByYLayout = layout.sort(function (a, b) {
            return a.y - b.y;
        });
        var indexOfNewPlacement = sortedByYLayout.indexOf(newItem);

        var leftSibling = sortedByYLayout[indexOfNewPlacement - 1];

        this.setItemBeingDragged(null);
        //# NOTE: due to how the reactGridLayout component internally calls on setState
        //# we need to call on a 'setImmediate' if we want to trigger the relayout last
        //# this would be to allow static fields to be pushed to the top
        //# however, note: the static fields would not be pushed down so we lose the opportunity to shove groups of fields around

        this.props.actions.moveCard_withTargetID_toAfterID(newItem.i, leftSibling ? leftSibling.i : null);
    }
    //# Don't use
    , _handleOrderChange: function _handleOrderChange(layout) {
        var sortedByYLayout = layout.sort(function (a, b) {
            return a.y - b.y;
        });
        //# dispatch action for handling order update
        var newOrder = sortedByYLayout.map(function (gridInfo) {
            return gridInfo.i;
        });
        this.props.actions.updateCardOrdering_withOrder && this.props.actions.updateCardOrdering_withOrder(newOrder);
    },
    _getChildren: function _getChildren() {
        var _this = this;

        var fieldState = this.props.fieldState;
        return fieldState.childIds.map(function (childId) {
            return fieldState.instances[childId];
        }).filter(function (child) {
            return !child.skip;
        }).filter(function (child) {
            return _.get(child, 'instanceProps.instanceType');
        }).reduce(function (result, child, idx) {
            var instanceType = _.get(child, 'instanceProps.instanceType');
            var heightForType = typeToGridHeight[instanceType];
            var isLocked = _.get(child, 'instanceProps._locked.value', false);
            var dataGridProps = {
                key: child.id //# necessary to get ReactGridLayout playing nicely with new instances since the IDs would be completely different
                , x: 0,
                y: _.get(_.last(result), 'dataGridProps.y', 0) + _.get(_.last(result), 'dataGridProps.h', 0),
                w: _this.state.layoutWidth,
                h: heightForType,
                static: false
            };
            var newChild = _extends({}, child, { dataGridProps: dataGridProps });
            return result.concat(newChild);
        }, []);
    },
    getLayout: function getLayout() {
        var children = this._getChildren();
        return children.map(function (child) {
            return child.dataGridProps;
        });
    },
    _renderVisibleCards_withWindowProps: function _renderVisibleCards_withWindowProps(_ref) {
        var _this2 = this;

        var height = _ref.height,
            scrollTop = _ref.scrollTop;

        var fieldState = this.props.fieldState;
        var children = this._getChildren();

        return children.map(function (child, index) {
            var _HOC_byInstanceType, _extends2;

            var instanceType = _.get(child, 'instanceProps.instanceType');
            var HOC_byInstanceType = (_HOC_byInstanceType = {}, _defineProperty(_HOC_byInstanceType, FIELD_KEY, FieldHOC), _defineProperty(_HOC_byInstanceType, FIELDSET_KEY, FieldsetHOC), _defineProperty(_HOC_byInstanceType, TAB_KEY, TabHOC), _defineProperty(_HOC_byInstanceType, SUBTEMPLATE_KEY, SubTemplateHOC), _HOC_byInstanceType);
            var HOC = HOC_byInstanceType[instanceType];

            var isBeingDragged = child.id == _this2.state.itemBeingDragged;
            var isInViewport = child.dataGridProps.y + index * 10 < scrollTop + height && child.dataGridProps.y + index * 10 + child.dataGridProps.h > scrollTop;
            var isVisible = isBeingDragged || isInViewport;

            var nextInstance = children[index + 1];
            var isNextLocked = _.get(nextInstance, 'instanceProps._locked.value', false);
            var setFieldCard_property_toValue = function setFieldCard_property_toValue(property, value, constraints) {
                return _this2.props.actions.set_property_forFieldCardKey_toValue(property, child.id.toString(), value);
            };
            var set_property_toValue = function set_property_toValue(propertyKey, value, constraints, propSpecKey) {
                return _this2.props.actions.set_property_forFieldKey_toValue_withConstraints(propertyKey, child.id.toString(), value, constraints, propSpecKey);
            };

            var fieldProps = _extends({
                key: child.id,
                instance: _.get(_this2, ['props', 'fieldState', 'instances', child.id]),
                setFieldCard_property_toValue: setFieldCard_property_toValue,
                set_property_toValue: set_property_toValue,
                isBeingDragged: isBeingDragged,
                isNextLocked: isNextLocked,
                disableDuplicate: instanceType === SUBTEMPLATE_KEY
                //, allowInsertSubtemplate: instanceType === SUBTEMPLATE_KEY
            }, _.pick(_this2.props, FIELD_PROP_KEYS));
            var delegateContextHandlers = {
                delegateInsertAfter: _this2.props.actions.addCard_ofType_afterTargetID,
                delegateDuplicateItem: _this2.props.actions.duplicateCard_withTargetID,
                delegateDeleteItem: _this2.props.actions.deleteCard_withTargetID,

                delegateStartCodeEditingForInstance: _this2.props.actions.startCodeEditingForInstance
            };

            return React.createElement(GridItem, _extends({
                key: child.id,
                'data-grid': child.dataGridProps
            }, {
                child: child,
                isVisible: isVisible,
                layoutWidth: _this2.state.layoutWidth,
                fieldProps: fieldProps,
                delegateContextHandlers: delegateContextHandlers,
                HOC: HOC
            }, (_extends2 = {
                'data-codecept-selector-node': 'GridItem',
                'data-codecept-selector-file': 'FieldEditor'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'GridItem'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FieldEditor'), _defineProperty(_extends2, 'data-codecept-selector-node', 'GridItem'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FieldEditor'), _extends2)));
        });
    },
    render: function render() {
        var _this3 = this,
            _React$createElement,
            _React$createElement2,
            _React$createElement3;

        return React.createElement(
            'div',
            (_React$createElement3 = { style: { position: 'relative', 'userSelect': 'none' }, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'FieldEditor'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'FieldEditor'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'FieldEditor'), _React$createElement3),
            React.createElement(
                ReactGridLayout,
                (_React$createElement = { className: 'layout',
                    layout: this.getLayout(),
                    cols: 1,
                    rowHeight: 1,
                    width: this.state.layoutWidth,
                    isResizable: false,
                    draggableHandle: '.fieldDragHandle',
                    verticalCompact: true,
                    margin: [0, 10],
                    containerPadding: [0, 0],
                    onDragStart: this._handleDragStart,
                    onDragStop: this._handleDragStop,
                    ref: function ref(theReactGridLayout) {
                        var layoutElement = theReactGridLayout ? ReactDOM.findDOMNode(theReactGridLayout) : undefined;
                        _this3._layoutElement = layoutElement;
                    },
                    'data-codecept-selector-node': 'ReactGridLayout',
                    'data-codecept-selector-file': 'FieldEditor'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ReactGridLayout'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FieldEditor'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ReactGridLayout'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FieldEditor'), _React$createElement),
                this._renderVisibleCards_withWindowProps(this.state.windowScrollerProps)
            ),
            React.createElement(_FloatingPlusMenu2.default, (_React$createElement2 = {
                style: {
                    position: 'absolute',
                    right: "20px",
                    bottom: "-28px"
                },
                delegateMenuItemSelected: this.props.actions.addCard_ofType_afterTargetID,
                'data-codecept-selector-node': 'FloatingPlusMenu',
                'data-codecept-selector-file': 'FieldEditor'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'FloatingPlusMenu'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'FieldEditor'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'FloatingPlusMenu'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'FieldEditor'), _React$createElement2))
        );
    }
});

var GridItem = React.createClass({
    displayName: 'GridItem',

    shouldComponentUpdate: function shouldComponentUpdate(nextProps) {
        return !!nextProps.isVisible;
    },
    render: function render() {
        var _extends3, _React$createElement4, _extends4, _extends5;

        var _props = this.props,
            isVisible = _props.isVisible,
            layoutWidth = _props.layoutWidth,
            fieldProps = _props.fieldProps,
            delegateContextHandlers = _props.delegateContextHandlers,
            HOC = _props.HOC;


        return React.createElement(
            'div',
            _extends({}, _extends({}, this.props, {
                style: _extends({}, this.props.style, {
                    opacity: isVisible ? 1 : 0,
                    maxWidth: layoutWidth // hack to override react-grid-layout width
                    , minWidth: layoutWidth // hack to override react-grid-layout width
                })
            }), (_extends4 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'FieldEditor'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'div'), _defineProperty(_extends4, 'data-codecept-selector-file', 'FieldEditor'), _defineProperty(_extends4, 'data-codecept-selector-node', 'div'), _defineProperty(_extends4, 'data-codecept-selector-file', 'FieldEditor'), _extends4)),
            isVisible ? React.createElement(HOC, _extends({}, fieldProps, delegateContextHandlers, (_extends3 = {
                'data-codecept-selector-node': 'HOC',
                'data-codecept-selector-file': 'FieldEditor'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'HOC'), _defineProperty(_extends3, 'data-codecept-selector-file', 'FieldEditor'), _defineProperty(_extends3, 'data-codecept-selector-node', 'HOC'), _defineProperty(_extends3, 'data-codecept-selector-file', 'FieldEditor'), _extends3))) : React.createElement(
                'div',
                (_React$createElement4 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'FieldEditor'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'FieldEditor'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'FieldEditor'), _React$createElement4),
                JSON.stringify(fieldProps.instance)
            ) //# limited support for searching in the page

        );
        return React.createElement('div', _extends({
            style: {
                opacity: isVisible ? 1 : 0,
                maxWidth: layoutWidth // hack to override react-grid-layout width
                , minWidth: layoutWidth // hack to override react-grid-layout width
            }
        }, this.props, (_extends5 = {
            'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'FieldEditor'
        }, _defineProperty(_extends5, 'data-codecept-selector-node', 'div'), _defineProperty(_extends5, 'data-codecept-selector-file', 'FieldEditor'), _defineProperty(_extends5, 'data-codecept-selector-node', 'div'), _defineProperty(_extends5, 'data-codecept-selector-file', 'FieldEditor'), _extends5)));
    }
});

exports.default = FieldEditor;