'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reactScroll = require('react-scroll');

var _Card = require('material-ui/Card');

var _Card2 = _interopRequireDefault(_Card);

var _IconMenu = require('material-ui/IconMenu');

var _IconMenu2 = _interopRequireDefault(_IconMenu);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _lockOutline = require('material-ui/svg-icons/action/lock-outline');

var _lockOutline2 = _interopRequireDefault(_lockOutline);

var _moreVert = require('material-ui/svg-icons/navigation/more-vert');

var _moreVert2 = _interopRequireDefault(_moreVert);

var _FieldCard_style = require('./FieldCard_style');

var _FieldCard_style2 = _interopRequireDefault(_FieldCard_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var PropTypes = React.PropTypes;

//# Icons
var DragHandleIcon = require('@rubyapps/ruby-material-ui/svg-icons/navigation/drag-handle').default;


var TAB_KEY = 'Tab';
var FIELDSET_KEY = 'Fieldset';
var FIELD_KEY = 'Field';
var SUBTEMPLATE_KEY = 'SubTemplate';

exports.default = function (WrappedComponent) {
    var FieldCard = React.createClass({
        displayName: 'FieldCard',

        propTypes: {
            delegateInsertAfter: PropTypes.func,
            delegateDeleteItem: PropTypes.func,
            delegateDuplicatItem: PropTypes.func,
            isBeingDragged: PropTypes.bool,
            instance: PropTypes.object,
            isNextLocked: PropTypes.bool,
            allowInsertSubtemplate: PropTypes.bool,
            disableDuplicate: PropTypes.bool,
            selfModule: PropTypes.object
        },
        getDefaultProps: function getDefaultProps() {
            return {
                allowInsertSubtemplate: true,
                isBeingDragged: false
            };
        },
        _handleInsertAfter: function _handleInsertAfter(targetID, type, event) {
            this.props.delegateInsertAfter && this.props.delegateInsertAfter(type, targetID);
        },
        _handleInsertTab: function _handleInsertTab(event) {
            var instanceID = _.get(this, 'props.instance.id');
            this._handleInsertAfter(instanceID, TAB_KEY, event);
        },
        _handleInsertFieldset: function _handleInsertFieldset(event) {
            var instanceID = _.get(this, 'props.instance.id');
            this._handleInsertAfter(instanceID, FIELDSET_KEY, event);
        },
        _handleInsertField: function _handleInsertField(event) {
            var instanceID = _.get(this, 'props.instance.id');
            this._handleInsertAfter(instanceID, FIELD_KEY, event);
        },
        _handleInsertSubtemplate: function _handleInsertSubtemplate(event) {
            var instanceID = _.get(this, 'props.instance.id');
            this._handleInsertAfter(instanceID, SUBTEMPLATE_KEY, event);
        },
        _handleDeleteItem: function _handleDeleteItem(event) {
            var instanceID = _.get(this, 'props.instance.id');
            this.props.delegateDeleteItem && this.props.delegateDeleteItem(instanceID);
        },
        _handleDuplicateItem: function _handleDuplicateItem(event) {
            var instanceID = _.get(this, 'props.instance.id');
            this.props.delegateDuplicateItem && this.props.delegateDuplicateItem(instanceID);
        },
        _handleStartCodeEditing: function _handleStartCodeEditing(event) {
            var instance = this.props.instance;
            this.props.delegateStartCodeEditingForInstance(instance);
        },
        _renderMenu: function _renderMenu() {
            var _React$createElement, _React$createElement2, _extends2, _extends3, _extends4, _extends5, _extends6, _extends7, _extends8, _extends9, _React$createElement3;

            var _props = this.props,
                overrideLocked = _props.overrideLocked,
                disableDuplicate = _props.disableDuplicate,
                allowInsertSubtemplate = _props.allowInsertSubtemplate;

            var isLocked = _.get(this, 'props.instance.instanceProps._locked.value', false) && !overrideLocked;
            var isNextLocked = this.props.isNextLocked && !overrideLocked;

            var propsToFixLongDelayOnTheBottomOfLargeTemplates = {
                //# on the bottom of large templates, the touch ripple causes a delay of
                //# approx. 4 seconds for the menu to show in chrome
                disableTouchRipple: true
            };

            return React.createElement(
                _IconMenu2.default,
                (_React$createElement3 = {
                    iconButtonElement: React.createElement(
                        _IconButton2.default,
                        (_React$createElement2 = {
                            'data-codecept-selector-node': 'IconButton',
                            'data-codecept-selector-file': 'FieldCard'
                        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'FieldCard'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'FieldCard'), _React$createElement2),
                        React.createElement(_moreVert2.default, (_React$createElement = {
                            'data-codecept-selector-node': 'MoreVertIcon',
                            'data-codecept-selector-file': 'FieldCard'
                        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FieldCard'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FieldCard'), _React$createElement))
                    ),
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                    targetOrigin: { horizontal: 'right', vertical: 'top' },
                    'data-codecept-selector-node': 'IconMenu',
                    'data-codecept-selector-file': 'FieldCard'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'FieldCard'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'FieldCard'), _React$createElement3),
                React.createElement(_MenuItem2.default, _extends({
                    primaryText: 'Duplicate',
                    value: 'duplicate',
                    disabled: disableDuplicate || isLocked,
                    onTouchTap: this._handleDuplicateItem
                }, propsToFixLongDelayOnTheBottomOfLargeTemplates, (_extends2 = {
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'FieldCard'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FieldCard'), _defineProperty(_extends2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FieldCard'), _extends2))),
                React.createElement(_MenuItem2.default, _extends({
                    primaryText: 'Insert After',
                    value: 'insertAfter',
                    disabled: isLocked && isNextLocked
                }, propsToFixLongDelayOnTheBottomOfLargeTemplates, (_extends7 = {
                    menuItems: [React.createElement(_MenuItem2.default, _extends({ primaryText: 'Tab',
                        value: TAB_KEY,
                        onTouchTap: this._handleInsertTab
                    }, propsToFixLongDelayOnTheBottomOfLargeTemplates, (_extends3 = {
                        'data-codecept-selector-node': 'MenuItem',
                        'data-codecept-selector-file': 'FieldCard'
                    }, _defineProperty(_extends3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends3, 'data-codecept-selector-file', 'FieldCard'), _defineProperty(_extends3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends3, 'data-codecept-selector-file', 'FieldCard'), _extends3))), React.createElement(_MenuItem2.default, _extends({ primaryText: 'Fieldset',
                        value: FIELDSET_KEY,
                        onTouchTap: this._handleInsertFieldset
                    }, propsToFixLongDelayOnTheBottomOfLargeTemplates, (_extends4 = {
                        'data-codecept-selector-node': 'MenuItem',
                        'data-codecept-selector-file': 'FieldCard'
                    }, _defineProperty(_extends4, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends4, 'data-codecept-selector-file', 'FieldCard'), _defineProperty(_extends4, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends4, 'data-codecept-selector-file', 'FieldCard'), _extends4))), React.createElement(_MenuItem2.default, _extends({ primaryText: 'Field',
                        value: FIELD_KEY,
                        onTouchTap: this._handleInsertField
                    }, propsToFixLongDelayOnTheBottomOfLargeTemplates, (_extends5 = {
                        'data-codecept-selector-node': 'MenuItem',
                        'data-codecept-selector-file': 'FieldCard'
                    }, _defineProperty(_extends5, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends5, 'data-codecept-selector-file', 'FieldCard'), _defineProperty(_extends5, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends5, 'data-codecept-selector-file', 'FieldCard'), _extends5))), React.createElement(_MenuItem2.default, _extends({ primaryText: 'Subtemplate',
                        value: 'test',
                        onTouchTap: this._handleInsertSubtemplate,
                        disabled: !allowInsertSubtemplate
                    }, propsToFixLongDelayOnTheBottomOfLargeTemplates, (_extends6 = {
                        'data-codecept-selector-node': 'MenuItem',
                        'data-codecept-selector-file': 'FieldCard'
                    }, _defineProperty(_extends6, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends6, 'data-codecept-selector-file', 'FieldCard'), _defineProperty(_extends6, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends6, 'data-codecept-selector-file', 'FieldCard'), _extends6)))],
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'FieldCard'
                }, _defineProperty(_extends7, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends7, 'data-codecept-selector-file', 'FieldCard'), _defineProperty(_extends7, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends7, 'data-codecept-selector-file', 'FieldCard'), _extends7))),
                React.createElement(_MenuItem2.default, _extends({
                    primaryText: 'Delete',
                    value: 'delete',
                    disabled: isLocked,
                    onTouchTap: this._handleDeleteItem
                }, propsToFixLongDelayOnTheBottomOfLargeTemplates, (_extends8 = {
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'FieldCard'
                }, _defineProperty(_extends8, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends8, 'data-codecept-selector-file', 'FieldCard'), _defineProperty(_extends8, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends8, 'data-codecept-selector-file', 'FieldCard'), _extends8))),
                React.createElement(_MenuItem2.default, _extends({
                    primaryText: 'Code',
                    value: 'code',
                    disabled: isLocked,
                    onTouchTap: this._handleStartCodeEditing
                }, propsToFixLongDelayOnTheBottomOfLargeTemplates, (_extends9 = {
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'FieldCard'
                }, _defineProperty(_extends9, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends9, 'data-codecept-selector-file', 'FieldCard'), _defineProperty(_extends9, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends9, 'data-codecept-selector-file', 'FieldCard'), _extends9)))
            );
        },
        componentDidMount: function componentDidMount() {
            var scrollToBottom = _.get(this, 'props.instance.scrollToBottomOnMount');
            if (scrollToBottom) {
                _reactScroll.animateScroll.scrollToBottom();
                this.props.setFieldCard_property_toValue('scrollToBottomOnMount', false);
            }
        },
        render: function render() {
            var _extends10, _React$createElement6, _React$createElement7, _React$createElement8;

            var instanceID = _.get(this, 'props.instance.id');
            var overrideLocked = this.props.overrideLocked;

            var isLocked = _.get(this, 'props.instance.instanceProps._locked.value', false) && !overrideLocked;

            var zDepth = this.props.isBeingDragged ? 4 : isLocked ? 0 : 1;

            return React.createElement(
                _Card2.default,
                (_React$createElement8 = { style: { height: '100%', 'userSelect': 'none' },
                    zDepth: zDepth,
                    ref: 'cardRoot',
                    'data-codecept-selector-node': 'Card',
                    'data-codecept-selector-file': 'FieldCard'
                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'FieldCard'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'FieldCard'), _React$createElement8),
                React.createElement(
                    'div',
                    (_React$createElement7 = { style: { display: 'flex' }, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'FieldCard'
                    }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'FieldCard'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'FieldCard'), _React$createElement7),
                    function () {
                        if (isLocked) {
                            var _React$createElement4;

                            return React.createElement(_lockOutline2.default, (_React$createElement4 = { color: 'gray',
                                style: _FieldCard_style2.default.locked,
                                'data-codecept-selector-node': 'LockIcon',
                                'data-codecept-selector-file': 'FieldCard'
                            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'LockIcon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'FieldCard'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'LockIcon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'FieldCard'), _React$createElement4));
                        } else {
                            var _React$createElement5;

                            return React.createElement(DragHandleIcon, (_React$createElement5 = { color: 'gray',
                                className: 'fieldDragHandle',
                                style: _FieldCard_style2.default.handle,
                                'data-codecept-selector-node': 'DragHandleIcon',
                                'data-codecept-selector-file': 'FieldCard'
                            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'DragHandleIcon'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'FieldCard'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'DragHandleIcon'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'FieldCard'), _React$createElement5));
                        }
                    }(),
                    React.createElement(
                        'div',
                        (_React$createElement6 = { style: { flex: 1 }, 'data-codecept-selector-node': 'div',
                            'data-codecept-selector-file': 'FieldCard'
                        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'FieldCard'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'FieldCard'), _React$createElement6),
                        React.createElement(WrappedComponent, _extends({}, this.props, (_extends10 = {
                            'data-codecept-selector-node': 'WrappedComponent',
                            'data-codecept-selector-file': 'FieldCard'
                        }, _defineProperty(_extends10, 'data-codecept-selector-node', 'WrappedComponent'), _defineProperty(_extends10, 'data-codecept-selector-file', 'FieldCard'), _defineProperty(_extends10, 'data-codecept-selector-node', 'WrappedComponent'), _defineProperty(_extends10, 'data-codecept-selector-file', 'FieldCard'), _extends10)))
                    ),
                    this._renderMenu()
                )
            );
        }
    });

    return FieldCard;
};