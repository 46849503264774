'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _DashboardAnalyticsConnector = require('./reactComponents/DashboardAnalyticsConnector');

var _DashboardAnalyticsConnector2 = _interopRequireDefault(_DashboardAnalyticsConnector);

var _action = require('./action');

var _action2 = _interopRequireDefault(_action);

var _reducer = require('./reducer');

var _reducer2 = _interopRequireDefault(_reducer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var Route = require('route-parser');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;
var FieldForm_CONSTANTS = require('@rubyapps/ruby-component-field-form/src/common/constants');
var RubyComponentRecentUpdates_CONSTANTS = require('@rubyapps/ruby-component-recent-updates/src/common/constants');

var RubyComponentFESettings = require('@rubyapps/ruby-component-frontend-settings');
var InfoField = require('@rubyapps/ruby-component-field-info');
var ButtonField = require('@rubyapps/ruby-component-field-button');

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldDynamicMixin = require('@rubyapps/ruby-component-mixin-field-dynamic');
var forcedLeafNodeMixin = require('@rubyapps/ruby-component-mixin-field-forced-leaf-node');

var RCDashboardAnalytics = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldDynamicMixin, forcedLeafNodeMixin],
    propTypes: {
        filterSpecs: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            gaFilter: PropTypes.string
        })),
        widgetSpecs: PropTypes.arrayOf(PropTypes.shape({
            dataEndpointTemplate: PropTypes.string,
            type: PropTypes.string
        })),
        frontendSettingsID: PropTypes.string,
        recentUpdatesID: PropTypes.string,
        convenienceLinkSpecs: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            rubyIconPath: PropTypes.string,
            url: PropTypes.string.isRequired
        })),
        domain: PropTypes.string,
        google_analytics_view_id: PropTypes.string
    },
    staticPropsByComponent: {
        'ruby-component-field-editor': {
            fieldInfo: {
                displayText: 'Dashboard Analytics'
            }
        }
    },
    action: _action2.default,
    reducer: _reducer2.default,
    componentName: componentName,
    getDefaultProps: function getDefaultProps(props) {
        var selfModule = this;
        return {
            frontendSettingsID: RubyComponentFESettings.CONSTANTS.COMPONENT_NAME,
            recentUpdatesID: RubyComponentRecentUpdates_CONSTANTS.COMPONENT_NAME,
            widgetSpecs: [{
                key: 'top_pages_history',
                label: 'Analytics',
                dataEndpointTemplate: '/private/dashboard_analytics/:id/filtered_top_pages.json',
                type: 'MultiLineChart',
                filterSpecs: props.filterSpecs,
                colProps: {
                    xs: 12
                }
            }, {
                key: 'mobile_visitors',
                label: '% mobile visitors',
                subtitle: 'Last 3 Months',
                dataEndpointTemplate: '/private/dashboard_analytics/:id/users_deviceCategory.json',
                type: 'SingleStat',
                colProps: {
                    xs: 6,
                    md: 3,
                    height: 400
                },
                dataPath: 'valuesByDimension.mobile.displayPercent'
                /*
                                , {
                                    key: 'session_duration'
                                    , label: 'Session duration'
                                    , dataEndpointTemplate: '/private/dashboard_analytics/:id/sessionDuration.json'
                                    , type: 'SingleStat'
                                    , colProps: {
                                        xs: 6
                                        , md: 3
                                        , height: 400
                                    }
                                }
                */
            }, {
                key: 'avg_session_duration',
                label: 'Session duration',
                subtitle: 'Last 3 Months',
                dataEndpointTemplate: '/private/dashboard_analytics/:id/avgSessionDuration.json',
                type: 'SingleStat',
                colProps: {
                    xs: 6,
                    md: 3,
                    height: 400
                }
            }, {
                key: 'updates_today',
                label: 'Content updates',
                subtitle: 'Today',
                dataEndpointTemplate: '/private/dashboard_analytics/space/:id/content_updates.json',
                type: 'SingleStat',
                colProps: {
                    xs: 6,
                    md: 3,
                    height: 400
                },
                onValueClick: function onValueClick() {
                    var _selfModule$getDepend = selfModule.getDependencies(),
                        recentUpdatesComponent = _selfModule$getDepend.recentUpdatesComponent;

                    selfModule.getStore().dispatch(recentUpdatesComponent.getAction().generators.openDisplay());
                }
            }, {
                key: 'goal_completions',
                label: 'Goal completions',
                subtitle: 'Last 3 Months',
                dataEndpointTemplate: '/private/dashboard_analytics/:id/goalCompletionsAll.json',
                type: 'SingleStat',
                colProps: {
                    xs: 6,
                    md: 3,
                    height: 400
                }
            }, {
                key: 'user_conversion_rate',
                label: 'User conversion rate',
                subtitle: 'Last 3 Months',
                dataEndpointTemplate: '/private/dashboard_analytics/:id/userConversionRate.json',
                type: 'SingleStat',
                colProps: {
                    xs: 6,
                    md: 3,
                    height: 400
                }
            }, {
                key: 'total_users',
                label: 'Website Visitors',
                subtitle: 'Last 3 Months',
                dataEndpointTemplate: '/private/dashboard_analytics/:id/total_users.json',
                type: 'LineChart',
                colProps: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    height: 400
                }
            }, {
                key: 'top_browsers',
                label: 'Visitors by Browser',
                subtitle: 'Last 3 Months',
                dataEndpointTemplate: '/private/dashboard_analytics/:id/top_browsers.json',
                type: 'PieChart',
                colProps: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    height: 400
                }
            }, {
                key: 'top_pages',
                label: 'Top Pageviews',
                subtitle: 'Last 3 Months',
                dataEndpointTemplate: '/private/dashboard_analytics/:id/top_viewed_pages.json',
                type: 'Table',
                colProps: {
                    xs: 12,
                    md: 4,
                    height: 400
                }
            }]
        };
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();
        var parentForm = this.findAncestorBy(function (ancestor) {
            return ancestor.componentName === FieldForm_CONSTANTS.COMPONENT_NAME;
        });

        var frontendSettingsComponent = root.findDescendentByID(this.props.frontendSettingsID);

        var recentUpdatesComponent = root.findDescendentByID(this.props.recentUpdatesID);

        return {
            selfSelector: selfSelector,
            parentForm: parentForm,
            frontendSettingsComponent: frontendSettingsComponent,
            recentUpdatesComponent: recentUpdatesComponent
        };
    },
    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector;

        var selectedState = {
            self: selfSelector(state)
        };
        return selectedState;
    },
    getReactClass: function getReactClass() {
        return _DashboardAnalyticsConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var DashboardAnalyticsComponent = this.getReactClass();

        return React.createElement(DashboardAnalyticsComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'DashboardAnalyticsComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'DashboardAnalyticsComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'DashboardAnalyticsComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS,
    children: function children() {
        var parentID = this.getID();

        var linkSpecs = this.getStore() !== undefined ? _.result(this, 'getState.props.convenienceLinkSpecs', []) : [];

        return RubyComponent.createElement.apply(RubyComponent, [InfoField, _extends({
            id: parentID + '.info'
        })].concat(_toConsumableArray(linkSpecs.map(function (spec, idx) {
            return RubyComponent.createElement(ButtonField, _extends({
                id: parentID + '.' + spec.label,
                key: '' + spec.label,
                style: { marginRight: 20 },
                action: {
                    action: ['openLink', spec.url],
                    path: '< .rubyComponentFieldDashboardAnalytics'
                }
            }, spec));
        }))));
    },
    retrieveAndSetData: function retrieveAndSetData(params) {
        var selfModule = this;
        var dispatch = selfModule.getStore().dispatch;

        var actions = selfModule.getAction().generators;

        var endpointsByKey = _.pickBy(_.reduce(selfModule.props.widgetSpecs, function (result, _ref) {
            var dataEndpointTemplate = _ref.dataEndpointTemplate,
                key = _ref.key;
            return _extends({}, result, _defineProperty({}, key, new Route(dataEndpointTemplate).reverse(params)));
        }, {}), _.identity);

        // goal completions is UA only and user conversion rate is GA4 only
        if (params.google_analytics_property_id) {
            delete endpointsByKey['goal_completions'];
        } else {
            delete endpointsByKey['user_conversion_rate'];
        }
        return dispatch(actions.retrieveAnalyticsData({ endpointsByKey: endpointsByKey }));
    },
    setDomain: function setDomain(domain) {
        var selfModule = this;
        var dispatch = selfModule.getStore().dispatch;
        var actions = selfModule.getAction().generators;

        return dispatch(actions.setDomain(domain));
    },
    onReduxInit: function onReduxInit(dispatch) {
        var selfModule = this;
        var store = selfModule.getStore();

        var _selfModule$getDepend2 = selfModule.getDependencies(),
            parentForm = _selfModule$getDepend2.parentForm;

        var actions = selfModule.getAction().generators;

        return store.subscribe(function () {
            var pickedSavedFormData = _.pick(_.result(parentForm, 'getState.pristineFormData'), ['settings.google_analytics_view_id', 'settings.google_analytics_property_id', 'settings.dashboard_convenience_links', 'domain', 'id']);

            if (!_.isEqual(pickedSavedFormData, selfModule._PICKED_SAVED_FORM_DATA)) {
                selfModule._PICKED_SAVED_FORM_DATA = pickedSavedFormData;
                var newViewId = _.get(pickedSavedFormData, 'settings.google_analytics_view_id');
                var oldViewId = selfModule.getProps(true)['google_analytics_view_id'];
                var newPropertyId = _.get(pickedSavedFormData, 'settings.google_analytics_property_id');
                var oldPropertyId = selfModule.getProps(true)['google_analytics_property_id'];

                if (!(newPropertyId === oldPropertyId && newViewId === oldViewId)) {

                    if (newPropertyId || newViewId) {
                        selfModule.retrieveAndSetData({ id: pickedSavedFormData.id, google_analytics_view_id: newViewId, google_analytics_property_id: newPropertyId });
                    } else {
                        selfModule.retrieveAndSetData({});
                    }
                }

                store.dispatch(selfModule.getAction().generators.setProps({
                    google_analytics_property_id: newPropertyId,
                    google_analytics_view_id: newViewId,
                    convenienceLinkSpecs: _.get(pickedSavedFormData, 'settings.dashboard_convenience_links', []).map(function (val) {
                        return _.pick(val, ['label', 'rubyIconPath', 'url']);
                    }),
                    domain: pickedSavedFormData.domain
                }));
                selfModule.updateChildren();
            }
        });
    }
});

module.exports = RCDashboardAnalytics;