"use strict";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

/* jshint -W138 */

/*
const defaultState = {
    onSelect: null
    , open: false
};
*/

module.exports = function (state, action) {
    var TYPES = this.getAction().TYPES;
    if (state == undefined) {
        state = this.getInitialState();
    }
    var type = action.type,
        payload = action.payload;


    switch (type) {
        case TYPES.OPEN_DIALOG:
            return _extends({}, state, {
                open: true
            });
        case TYPES.CLOSE_DIALOG:
            return _extends({}, state, {
                open: false
            });

        case TYPES.SET_OPTIONS:
            {
                return _extends({}, state, action.payload);
            }
        case TYPES.RESET_DIALOG:
            return _extends({}, state, this.getInitialState());
        default:
            return state;
    }
};