'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _search = require('material-ui/svg-icons/action/search');

var _search2 = _interopRequireDefault(_search);

var _Activity_style = require('../Activity_style');

var _Activity_style2 = _interopRequireDefault(_Activity_style);

var _constants = require('../../../common/constants');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; } /*
                                                                                                                                                                                                                   * unlike the title component, this component
                                                                                                                                                                                                                   * is meant to be used in places outside the page
                                                                                                                                                                                                                   * where it might be unclear as to what was updated
                                                                                                                                                                                                                   * i.e. Recent Updates
                                                                                                                                                                                                                  */


var styles = process.browser ? require('../Activity.cssModule').default : {};

var ACTIVITY_TYPES = _constants2.default.ACTIVITY_TYPES;


var ActivityTitle = _react2.default.createClass({
    displayName: 'ActivityTitle',

    customMixins: [],
    isCurrentUser: function isCurrentUser(dataKey) {
        var userId = _.get(this.props, [dataKey, 'id']);
        var currentUserId = _.get(this.props, 'rubyUser.id');
        return currentUserId && userId == currentUserId;
    },
    getRubyUserDisplayName: function getRubyUserDisplayName() {
        var _React$createElement;

        var dataKey = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'user';
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var displayString = this.isCurrentUser(dataKey) ? options.lowercasePronouns ? 'you' : 'You' : [_.get(this.props, [dataKey, 'first_name']), _.get(this.props, [dataKey, 'last_name'])].join(' ');
        return _react2.default.createElement(
            'span',
            (_React$createElement = { className: styles.username, 'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'descriptive-title'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement),
            displayString
        );
    },
    getActivityRepresentation: function getActivityRepresentation() {
        return {
            activityId: this.props.id,
            versionDataId: this.props.version_data_id,
            versionNumber: this.props.version_num,
            templateKey: this.props.entity_name,
            entityId: this.props.entity_id
        };
    },
    getRevisionLink: function getRevisionLink() {
        var _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5;

        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var _props = this.props,
            version_num = _props.version_num,
            actions = _props.actions;

        var activity = this.getActivityRepresentation();
        var hideIcon = options.hideIcon;


        return actions ? _react2.default.createElement(
            'a',
            (_React$createElement4 = { className: styles.revisionLink, onClick: function onClick(e) {
                    e.preventDefault();
                    actions.previewActivityVersion(activity);
                }, 'data-codecept-selector-node': 'a',
                'data-codecept-selector-file': 'descriptive-title'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement4),
            'Revision ',
            version_num,
            hideIcon ? null : _react2.default.createElement(
                'span',
                (_React$createElement3 = { className: styles.revisionLinkHoverIcon, 'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'descriptive-title'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement3),
                _react2.default.createElement(_search2.default, (_React$createElement2 = { style: _Activity_style2.default.iconStyles, 'data-codecept-selector-node': 'SearchIcon',
                    'data-codecept-selector-file': 'descriptive-title'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'SearchIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'SearchIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement2))
            )
        ) : _react2.default.createElement(
            'span',
            (_React$createElement5 = { className: styles.revisionText, 'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'descriptive-title'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement5),
            'Revision ',
            version_num
        );
    },
    getItemDisplayName: function getItemDisplayName() {
        var _React$createElement6;

        var _props2 = this.props,
            entity = _props2.entity,
            fallbackEntityName = _props2.entity_name;
        var entityInstanceName = entity.name,
            entityName = entity.entityName;

        return entityInstanceName ? _react2.default.createElement(
            'span',
            (_React$createElement6 = { className: styles.entityInstanceName, 'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'descriptive-title'
            }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement6),
            entityInstanceName
        ) : 'a ' + (entityName || fallbackEntityName) + ' item';
    },
    getAlteredTemplateName: function getAlteredTemplateName() {
        var _props3 = this.props,
            details = _props3.details,
            forms = _props3.forms;

        var formsState = forms.getState();

        var alteredTemplateKey = _.get(details, 'prior_template_keyword');
        var alteredTemplateName = _.get(formsState, [alteredTemplateKey, 'name']);
        return alteredTemplateName ? 'a ' + alteredTemplateName : 'an undefined item';
    },
    getRenderTypeInformation: function getRenderTypeInformation() {
        var details = this.props.details;

        return {
            renderTypeLabel: _.get(details, 'render_type_label') || '',
            originalMediaName: _.get(details, 'original_media_name') || ''
        };
    },
    styledKeywords: function styledKeywords(keywords) {
        var _React$createElement7;

        return _react2.default.createElement(
            'span',
            (_React$createElement7 = { className: styles.keywords, 'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'descriptive-title'
            }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement7),
            keywords
        );
    },
    renderTitle: function renderTitle() {
        var _this = this;

        return this.customMixins.reduce(function (collector, mixin) {
            if (collector) {
                return collector;
            }

            return mixin.renderTitle && mixin.renderTitle.apply(_this);
        }, null);
    },
    render: function render() {
        var _React$createElement8, _React$createElement9, _React$createElement10, _React$createElement11, _React$createElement12, _React$createElement13, _React$createElement14, _React$createElement15, _React$createElement16, _React$createElement17, _React$createElement18, _React$createElement19, _React$createElement24, _React$createElement25;

        var titleFromMixin = this.renderTitle();
        if (titleFromMixin) {
            return titleFromMixin;
        }

        var _props4 = this.props,
            activity_type = _props4.activity_type,
            version_num = _props4.version_num;

        var user = this.getRubyUserDisplayName('user');
        var itemDisplayName = this.getItemDisplayName();
        var recipient = this.getRubyUserDisplayName('recipient', { lowercasePronouns: true });

        switch (activity_type) {
            case ACTIVITY_TYPES.REVISION_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement8 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'descriptive-title'
                    }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement8),
                    user,
                    ' updated ',
                    itemDisplayName,
                    ' to ',
                    this.getRevisionLink()
                );
            case ACTIVITY_TYPES.ALTER_TEMPLATE_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement9 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'descriptive-title'
                    }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement9),
                    user,
                    ' altered the template of ',
                    this.getAlteredTemplateName(),
                    ' creating ',
                    this.getRevisionLink()
                );
            case ACTIVITY_TYPES.DELETION_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement10 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'descriptive-title'
                    }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement10),
                    user,
                    ' deleted ',
                    itemDisplayName
                );
            case ACTIVITY_TYPES.RERANK_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement11 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'descriptive-title'
                    }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement11),
                    user,
                    ' reordered ',
                    itemDisplayName
                );
            case ACTIVITY_TYPES.COMMENT_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement12 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'descriptive-title'
                    }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement12),
                    user,
                    ' added a comment on ',
                    itemDisplayName,
                    ' about ',
                    this.getRevisionLink()
                );
            case ACTIVITY_TYPES.WORDDOC_GENERATION_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement13 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'descriptive-title'
                    }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement13),
                    user,
                    ' exported document ',
                    this.getRevisionLink(),
                    ' of ',
                    itemDisplayName
                );
            case ACTIVITY_TYPES.APPROVAL_REQUEST_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement14 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'descriptive-title'
                    }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement14),
                    user,
                    ' submitted an update to ',
                    itemDisplayName,
                    ' for review to ',
                    recipient
                );
            case ACTIVITY_TYPES.APPROVAL_COMMENT_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement15 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'descriptive-title'
                    }, _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement15),
                    user,
                    ' added a comment on the update to ',
                    itemDisplayName,
                    ' submitted for review'
                );
            case ACTIVITY_TYPES.APPROVAL_APPROVE_ACTIVITY:
                return (// TODO: the notification language here needs work!
                    _react2.default.createElement(
                        'span',
                        (_React$createElement16 = {
                            'data-codecept-selector-node': 'span',
                            'data-codecept-selector-file': 'descriptive-title'
                        }, _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement16),
                        user,
                        ' approved the update to ',
                        itemDisplayName,
                        ' to ',
                        this.getRevisionLink()
                    )
                );
            case ACTIVITY_TYPES.APPROVAL_REJECT_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement17 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'descriptive-title'
                    }, _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement17),
                    user,
                    ' rejected the update to ',
                    itemDisplayName,
                    ' submitted by ',
                    recipient
                );
            case ACTIVITY_TYPES.APPROVAL_DISCARD_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement18 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'descriptive-title'
                    }, _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement18),
                    user,
                    ' discarded the update to ',
                    itemDisplayName,
                    ' submitted by ',
                    recipient
                );
            case ACTIVITY_TYPES.UPLOAD_MEDIA_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement19 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'descriptive-title'
                    }, _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement19),
                    user,
                    ' uploaded ',
                    itemDisplayName
                );
            case ACTIVITY_TYPES.REPLACE_MEDIA_ACTIVITY:
                {
                    var _React$createElement20, _React$createElement21;

                    var _getRenderTypeInforma = this.getRenderTypeInformation(),
                        renderTypeLabel = _getRenderTypeInforma.renderTypeLabel,
                        originalMediaName = _getRenderTypeInforma.originalMediaName;

                    return renderTypeLabel ? _react2.default.createElement(
                        'span',
                        (_React$createElement20 = {
                            'data-codecept-selector-node': 'span',
                            'data-codecept-selector-file': 'descriptive-title'
                        }, _defineProperty(_React$createElement20, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement20, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement20, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement20, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement20),
                        user,
                        ' replaced render type ',
                        this.styledKeywords(renderTypeLabel),
                        ' for ',
                        this.styledKeywords(originalMediaName)
                    ) : _react2.default.createElement(
                        'span',
                        (_React$createElement21 = {
                            'data-codecept-selector-node': 'span',
                            'data-codecept-selector-file': 'descriptive-title'
                        }, _defineProperty(_React$createElement21, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement21, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement21, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement21, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement21),
                        user,
                        ' replaced ',
                        itemDisplayName
                    );
                }
            case ACTIVITY_TYPES.DERIVE_RENDER_ACTIVITY:
                {
                    var _React$createElement22;

                    var _getRenderTypeInforma2 = this.getRenderTypeInformation(),
                        _renderTypeLabel = _getRenderTypeInforma2.renderTypeLabel,
                        _originalMediaName = _getRenderTypeInforma2.originalMediaName;

                    return _react2.default.createElement(
                        'span',
                        (_React$createElement22 = {
                            'data-codecept-selector-node': 'span',
                            'data-codecept-selector-file': 'descriptive-title'
                        }, _defineProperty(_React$createElement22, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement22, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement22, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement22, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement22),
                        'Render type ',
                        this.styledKeywords(_renderTypeLabel),
                        ' was created from ',
                        this.styledKeywords(_originalMediaName),
                        ' for ',
                        user
                    );
                }
            case ACTIVITY_TYPES.DELETE_DERIVED_RENDER_ACTIVITY:
                {
                    var _React$createElement23;

                    var _getRenderTypeInforma3 = this.getRenderTypeInformation(),
                        _renderTypeLabel2 = _getRenderTypeInforma3.renderTypeLabel,
                        _originalMediaName2 = _getRenderTypeInforma3.originalMediaName;

                    return _react2.default.createElement(
                        'span',
                        (_React$createElement23 = {
                            'data-codecept-selector-node': 'span',
                            'data-codecept-selector-file': 'descriptive-title'
                        }, _defineProperty(_React$createElement23, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement23, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement23, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement23, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement23),
                        user,
                        ' deleted render type ',
                        this.styledKeywords(_renderTypeLabel2),
                        ' for ',
                        this.styledKeywords(_originalMediaName2)
                    );
                }
            case ACTIVITY_TYPES.CONTENT_IMPORT_ACTIVITY:
                var isUpdate = version_num > 1;
                var actionWord = isUpdate ? 'updated' : 'created';
                var versionText = isUpdate ? ['to ', this.getRevisionLink({ hideIcon: true })] : null;
                return _react2.default.createElement(
                    'span',
                    (_React$createElement24 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'descriptive-title'
                    }, _defineProperty(_React$createElement24, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement24, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement24, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement24, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement24),
                    user,
                    ' ',
                    actionWord,
                    ' ',
                    itemDisplayName,
                    ' ',
                    versionText,
                    ' as part of a content import'
                );
            default:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement25 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'descriptive-title'
                    }, _defineProperty(_React$createElement25, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement25, 'data-codecept-selector-file', 'descriptive-title'), _defineProperty(_React$createElement25, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement25, 'data-codecept-selector-file', 'descriptive-title'), _React$createElement25),
                    user,
                    ' added a comment'
                );
        };
    }
});

exports.default = ActivityTitle;