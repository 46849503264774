'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    Card: {
        style: {
            margin: '20px 0'
        }
    },
    CardText: {
        style: {}
    }
};