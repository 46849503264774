"use strict";

module.exports = function testGenerator() {
    var _this = this;

    var selfModule = this;

    return {
        hydrate: function hydrate(formData) {
            _this.formValueToLocalState(formData, _this.getStore().dispatch);
        }
    };
};