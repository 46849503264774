'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _VirtualAutoComplete = require('@rubyapps/ruby-material-ui/VirtualAutoComplete');

var _VirtualAutoComplete2 = _interopRequireDefault(_VirtualAutoComplete);

var _index = require('./index.cssModule');

var _index2 = _interopRequireDefault(_index);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var request = require('@rubyapps/ruby-superagent');

var PropTypes = _react2.default.PropTypes;

var AutoComplete__propTypes = _VirtualAutoComplete2.default.propTypes;
var AutoComplete__propTypeKeys = Object.keys(AutoComplete__propTypes);

var AutoCompleteSelector = _react2.default.createClass({
    displayName: 'AutoCompleteSelector',

    propTypes: _extends({
        chosenRequest: PropTypes.object //# the actual chosenRequest
        , count: PropTypes.number //# the count param for url, needed to limit the number of results
        , disabed: PropTypes.bool,
        filter: PropTypes.func,
        onOptionSelected: PropTypes.func,
        onOptionCleared: PropTypes.func,
        requestToInvalidateCacheTimestamp: PropTypes.string,
        shouldClearUnfoundedSearch_onMenuClose: PropTypes.bool,
        url: PropTypes.string
        /*
            dataSource: PropTypes.arrayOf(PropTypes.shape({
                text: PropTypes.string
                , value: PropTypes.string
            })
         */
    }, AutoComplete__propTypes),
    getDefaultProps: function getDefaultProps() {
        return {
            count: 50,
            dataSource: [],
            dataSourceConfig: {
                text: 'text',
                value: 'value'
            },
            formattedOptions: function formattedOptions(options) {
                return options;
            },
            requestToInvalidateCacheTimestamp: new Date().toString(),
            shouldClearUnfoundedSearch_onMenuClose: true
        };
    },
    getInitialState: function getInitialState() {
        return {
            searchText: '',
            chosenRequest: this.props.chosenRequest,
            dataSource: []
        };
    }

    //== START: HANDLERS ===================================//

    , _onUpdateInput: function _onUpdateInput(searchText) {
        var newState = {
            searchText: searchText,
            chosenRequest: undefined //# clear out chosenRequest as long as onNewRequest isn't triggered
        };

        this.setState(newState);
        if (this.props.url) {
            this._queryForDataSource(searchText);
        }

        this.props.onUpdateInput && this.props.onUpdateInput.apply(this, arguments);
        this.props.onOptionCleared && this.props.onOptionCleared();
    },
    _onNewRequest: function _onNewRequest(chosenRequest, index) {
        //# NOTE: <AutoComplete/> implementation of searchText is stateful
        //# so you need to pass the searchText along to the AutoComplete's componentWillReceiveProps() method
        //# so force it to update it's prop record
        if (typeof chosenRequest === 'string') {
            return;
        }
        var chosenRequestText = this._chosenRequestText(chosenRequest);
        var dataSource = this._completeDataSource();
        this.setState({
            searchText: chosenRequestText,
            chosenRequest: chosenRequest
        });

        this.props.onUpdateInput && this.props.onUpdateInput(chosenRequestText);
        this.props.onNewRequest && this.props.onNewRequest.apply(this, arguments);
        this.props.onOptionSelected && this.props.onOptionSelected(chosenRequest);
    },
    _onFocus: function _onFocus(event) {
        //# select all text
        var searchTextField__input = _lodash2.default.get(this, ['_autocompleteComponent', 'refs', 'searchTextField', 'input'], event.target);

        searchTextField__input && searchTextField__input.setSelectionRange && searchTextField__input.setSelectionRange(0, searchTextField__input.value.length);
    }

    //== END: HANDLERS =====================================//

    , componentWillMount: function componentWillMount() {
        var shouldUpdateState = false;
        var newStateProps = {};

        if (this.props.searchText) {
            newStateProps.searchText = this.props.searchText;
            shouldUpdateState = true;
        }

        if (this.props.chosenRequest) {
            newStateProps.chosenRequest = this.props.chosenRequest;
            var searchText = this._chosenRequestText(this.props.chosenRequest);
            newStateProps.searchText = searchText;
            shouldUpdateState = true;
        }

        if (shouldUpdateState) {
            this.setState(newStateProps);
        }
    },
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        var shouldUpdateState = false;
        var newStateProps = {};

        if (this.props.searchText !== nextProps.searchText) {
            newStateProps.searchText = nextProps.searchText;
            shouldUpdateState = true;
        }

        if (this.props.chosenRequest !== nextProps.chosenRequest) {
            newStateProps.chosenRequest = nextProps.chosenRequest;
            if (!this.state.isMenuOpened) {
                var searchText = this._chosenRequestText(nextProps.chosenRequest);
                newStateProps.searchText = searchText;
            }
            shouldUpdateState = true;
        }

        var nextProps__requestToInvalidateCacheTimestamp = nextProps.requestToInvalidateCacheTimestamp;
        var props__requestToInvalidateCacheTimestamp = this.props.requestToInvalidateCacheTimestamp;

        if (nextProps__requestToInvalidateCacheTimestamp && nextProps__requestToInvalidateCacheTimestamp > props__requestToInvalidateCacheTimestamp) {
            //# trigger a request
            this._queryForDataSource(newStateProps.searchText ? newStateProps.searchText : this.state.searchText);
        }

        if (shouldUpdateState) {
            this.setState(newStateProps);
        }
    },

    render: function render() {
        var _this = this,
            _extends2;

        var AutoComplete__props = _lodash2.default.pick(this.props, AutoComplete__propTypeKeys);

        var hasChosenRequest = !!this.state.chosenRequest;
        return _react2.default.createElement(_VirtualAutoComplete2.default, _extends({}, AutoComplete__props, (_extends2 = {
            disabled: this.props.disabled,
            searchText: this.state.searchText,
            onUpdateInput: this._onUpdateInput,
            onNewRequest: this._onNewRequest,
            onFocus: this._onFocus,
            dataSource: this._completeDataSource(),
            errorText: this.props.errorText,
            className: _index2.default['textfield'] + ' ' + (hasChosenRequest ? _index2.default['textfield--active'] : _index2.default['textfield--inactive']),
            ref: function ref(autocompleteComponent) {
                if (autocompleteComponent) {
                    _this._autocompleteComponent = autocompleteComponent;
                }
            },
            onMenuClose: function onMenuClose() {
                var prevSearchText = _this.state.searchText;

                var searchText = _this._chosenRequestText(_this.state.chosenRequest);

                if (_this.props.shouldClearUnfoundedSearch_onMenuClose) {

                    _this.setState({
                        isMenuOpened: false,
                        searchText: searchText
                    });

                    if (searchText != prevSearchText) {
                        _this._onUpdateInput(searchText);
                    }
                } else {
                    _this.setState({
                        isMenuOpened: false
                    });
                }
            },
            onMenuOpen: function onMenuOpen() {
                _this.setState({
                    isMenuOpened: true
                });
            },
            'data-codecept-selector-node': 'AutoComplete',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'AutoComplete'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'AutoComplete'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },

    componentDidMount: function componentDidMount() {
        //# lets retrieve the initial batch of values for dataSource
        if (this.props.url) {
            this._queryForDataSource(this.state.searchText);
        }
    }
    //== START: UTILITY =====================================//
    , _completeDataSource: function _completeDataSource() {
        return this.state.dataSource.concat(this.props.dataSource);
    },
    _chosenRequestText: function _chosenRequestText(chosenRequest) {
        if (_lodash2.default.isNil(chosenRequest)) {
            return '';
        } else if (typeof chosenRequest === 'string') {
            return chosenRequest;
        } else {
            return chosenRequest[this.props.dataSourceConfig.text || 'text'];
        }
    },
    _queryForDataSource: function _queryForDataSource(query) {
        var _this2 = this;

        return request.get(this.props.url).query({
            search_input: query,
            count: this.props.count
        }).then(function (response) {
            var data = response.body.data;

            _this2.setState({
                dataSource: data
            });
        });
    }
});

exports.default = AutoCompleteSelector;