'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var styles = {
    Card: {
        margin: '20px 0'
    },
    FlatButton: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    RaisedButton: {
        marginLeft: '10px'
    },
    ViewMore: {
        width: '100%',
        marginTop: '15px'
    },
    newCommentHeader: {
        paddingTop: '23px'
    },
    newCommentAvatar: {
        marginLeft: '14px',
        marginRight: '14px',
        display: 'inline-block'
    },
    newCommentTitle: {
        paddingTop: '15px'
    }
};

exports.default = styles;