'use strict';

module.exports = function () {
    var selfModule = this;

    var id = this.getID();
    var TYPES = {
        CANCEL_EDITING: '@ruby-app/' + id + '/CANCEL_EDITING',
        SET_LISTER_NAME: '@ruby-app/' + id + '/SET_LISTER_NAME',
        SET_CHILDREN_PROPS_BY_CLASS: '@ruby-app/' + id + '/SET_CHILDREN_PROPS_BY_CLASS',
        RESET_STORE: '@ruby-app/' + id + '/RESET_STORE'
    };
    return {
        TYPES: TYPES,
        generators: {
            cancelEditing: function cancelEditing() {
                var actions = selfModule.getAction().generators;
                return function (dispatch, getState) {

                    var listRoute = selfModule.getListRoute();

                    dispatch(actions.navigateToPathWithOptions({
                        path: listRoute
                    }));
                };
            },
            setListerName: function setListerName(value) {
                var actions = selfModule.getAction().generators;
                return {
                    type: TYPES.SET_LISTER_NAME,
                    payload: { value: value }
                };
            },
            setChildrenPropsByClass: function setChildrenPropsByClass(childrenPropsByClass) {
                return {
                    type: TYPES.SET_CHILDREN_PROPS_BY_CLASS,
                    payload: childrenPropsByClass
                };
            },
            resetStore: function resetStore() {
                return {
                    type: TYPES.RESET_STORE
                };
            }
        }
    };
};