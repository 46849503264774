'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Toggle = require('material-ui/Toggle');

var _Toggle2 = _interopRequireDefault(_Toggle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var PropTypes_value = PropTypes.bool;

var Dropdown = _react2.default.createClass({
    displayName: 'Dropdown',

    propTypes: {
        label: PropTypes.string,
        value: PropTypes_value,
        id: PropTypes.string
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: false
        };
    },
    _onToggle: function _onToggle(event, toggled) {
        this.props.onToggle && this.props.onToggle(event, toggled);
    },
    render: function render() {
        var _React$createElement;

        var value = this.props.value || this.props.defaultValue;
        return _react2.default.createElement(_Toggle2.default, (_React$createElement = {
            toggled: value,
            onToggle: this._onToggle,
            style: { width: 'auto' },
            disabled: this.props.disabled,
            'data-codecept-selector-node': 'Toggle',
            'data-codecept-selector-file': 'Toggle'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Toggle'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Toggle'), _React$createElement));
    }
});

exports.default = Dropdown;