'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styleHelper = require('./utils/styleHelper');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SettingsToggle = function (_React$Component) {
  _inherits(SettingsToggle, _React$Component);

  function SettingsToggle() {
    _classCallCheck(this, SettingsToggle);

    var _this = _possibleConstructorReturn(this, (SettingsToggle.__proto__ || Object.getPrototypeOf(SettingsToggle)).call(this));

    _this.state = {};
    _this.state.toggled = false;

    _this._handleButton = _this._handleButton.bind(_this);
    return _this;
  }

  _createClass(SettingsToggle, [{
    key: 'shouldComponentUpdate',
    value: function shouldComponentUpdate() {
      return false;
    }
  }, {
    key: 'render',
    value: function render() {
      var _getStyleProperties = (0, _styleHelper.getStyleProperties)(this.props, 'settingsToggle'),
          style = _getStyleProperties.style,
          className = _getStyleProperties.className;

      var toggleClass = (0, _classnames2.default)(this.state.toggled ? 'toggled' : 'not-toggled', className);

      return _react2.default.createElement(
        'button',
        { className: toggleClass, style: style, onClick: this._handleButton, 'data-codecept-selector-node': 'button',
          'data-codecept-selector-file': 'settings-toggle'
        },
        'Settings'
      );
    }

    //this should keep track locally if it's toggled
    //and just send whether or not settings should be shown

  }, {
    key: '_handleButton',
    value: function _handleButton() {
      var toggled = !this.state.toggled;
      this.props.showSettings(toggled);
      this.setState({ toggled: toggled });
    }
  }]);

  return SettingsToggle;
}(_react2.default.Component);

exports.default = SettingsToggle;