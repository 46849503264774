'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Popover = require('material-ui/Popover');

var _Popover2 = _interopRequireDefault(_Popover);

var _IndicatingIconButton = require('../../../../ruby-react-components/src/client/input/IndicatingIconButton');

var _IndicatingIconButton2 = _interopRequireDefault(_IndicatingIconButton);

var _InfoComponent = require('./InfoComponent');

var _InfoComponent2 = _interopRequireDefault(_InfoComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

//# Material UI

//import IconButton from 'material-ui/IconButton/IconButton';


var RubyIcons = require('@rubyapps/ruby-icons');

var RCPopover = _react2.default.createClass({
    displayName: 'RCPopover',

    propTypes: {
        label: PropTypes.string,
        isOpen: PropTypes.bool
    },
    _handleOpenPopover: function _handleOpenPopover(event) {
        // This prevents ghost click.
        event.preventDefault();

        this.props.actions.open();
        this.setState({
            anchorEl: event.currentTarget
        });
    },
    _handleRequestClosePopover: function _handleRequestClosePopover() {
        this.props.actions.close();
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6, _React$createElement7;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var iconPath = this.props.descendentsHaveValues ? this.props.activeIconPath : this.props.inactiveIconPath;
        var Icon = RubyIcons.getIconClassForPath(iconPath);

        return _react2.default.createElement(
            'div',
            (_React$createElement7 = {
                style: _extends({
                    display: 'flex',
                    alignItems: 'center'
                }, this.props.styles),
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Popover'
            }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Popover'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Popover'), _React$createElement7),
            _react2.default.createElement(
                _IndicatingIconButton2.default,
                (_React$createElement2 = {
                    tooltip: this.props.label,
                    error: this.props.error,
                    isActive: this.props.descendentsHaveValues,
                    onClick: this._handleOpenPopover,
                    style: {
                        width: 33,
                        padding: '0px 0px 0px 0px',
                        height: 40
                    },
                    'data-codecept-selector-node': 'IndicatingIconButton',
                    'data-codecept-selector-file': 'Popover'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'IndicatingIconButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Popover'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'IndicatingIconButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Popover'), _React$createElement2),
                _react2.default.createElement(Icon, (_React$createElement = {
                    'data-codecept-selector-node': 'Icon',
                    'data-codecept-selector-file': 'Popover'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Icon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Popover'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Icon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Popover'), _React$createElement))
            ),
            _react2.default.createElement(
                'div',
                (_React$createElement4 = {
                    onClick: this._handleOpenPopover,
                    style: { cursor: 'pointer' },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Popover'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Popover'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Popover'), _React$createElement4),
                _.result(this, 'props.infoComponent') || _react2.default.createElement(_InfoComponent2.default, (_React$createElement3 = { displayValueSpecs: this.props.displayValueSpecs, 'data-codecept-selector-node': 'InfoComponent',
                    'data-codecept-selector-file': 'Popover'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'InfoComponent'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Popover'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'InfoComponent'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Popover'), _React$createElement3))
            ),
            _react2.default.createElement(
                _Popover2.default,
                (_React$createElement6 = {
                    open: this.props.isOpen,
                    anchorEl: _.get(this, 'state.anchorEl'),
                    onRequestClose: this._handleRequestClosePopover,
                    anchorOrigin: this.props.anchorOrigin,
                    targetOrigin: this.props.targetOrigin,
                    style: this.props.popoverStyles,
                    'data-codecept-selector-node': 'Popover',
                    'data-codecept-selector-file': 'Popover'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'Popover'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Popover'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'Popover'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Popover'), _React$createElement6),
                _react2.default.createElement(
                    'div',
                    (_React$createElement5 = {
                        style: {
                            marginBottom: 14,
                            marginLeft: 20,
                            marginRight: 20
                        },
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'Popover'
                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Popover'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Popover'), _React$createElement5),
                    this.props.children
                )
            )
        );
    }
});

exports.default = RCPopover;