'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _ = require('lodash');

var updateTogglesByIdUsingState_andToggleCtx = require('./updateTogglesById');
var setTogglesByIdUsingState_andFieldValue = require('./setTogglesById');
var initTogglesByIdUsingState_andHydratedToggleTree = require('./initTogglesById');

module.exports = function (state, action) {
    var _getAction = this.getAction(),
        TYPES = _getAction.TYPES;

    var type = action.type,
        payload = action.payload;
    var togglesById = state.togglesById,
        toggleTree = state.toggleTree;


    switch (type) {
        case TYPES.UPDATE_TOGGLES_BY_ID__WHEN_TOGGLED:
            var toggleCtx = payload.toggleCtx;

            return _extends({}, state, {
                togglesById: updateTogglesByIdUsingState_andToggleCtx(state, toggleCtx)
            });
        case TYPES.SET_TOGGLES_BY_ID__WHEN_SERVER_HYDRATES_WITH_FIELD_VALUE:
            var fieldValue = payload;
            return _extends({}, state, {
                togglesById: setTogglesByIdUsingState_andFieldValue(state, fieldValue)
            });
        case TYPES.INIT_TOGGLE_TREE_AND_TOGGLES_BY_ID__ON_REDUX_INIT:
            var hydratedToggleTree = payload.toggleTree;

            return _extends({}, state, {
                toggleTree: hydratedToggleTree,
                togglesById: initTogglesByIdUsingState_andHydratedToggleTree(state, hydratedToggleTree)
            });
        default:
            return state;
    }
};