'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _ToggleConnector = require('./reactComponents/ToggleConnector');

var _ToggleConnector2 = _interopRequireDefault(_ToggleConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');

var RCToggle = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {
        default: PropTypes.bool
    },
    getDefaultProps: function getDefaultProps() {
        return {
            default: false
        };
    },
    componentName: componentName,
    action: function action() {
        var id = this.getID();
        var TYPES = {
            SET_FIELD: '@@ruby-app/' + id + '/SET_FIELD'
        };
        return {
            TYPES: TYPES,
            generators: {
                setField_withValue: function setField_withValue(value) {
                    var _this = this;

                    var actions = this.getAction().generators;
                    return function (dispatch) {
                        dispatch(actions.setFieldValueByKey(value, _this.props.key));
                    };
                }
            }
        };
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();

        return {
            selfSelector: selfSelector
        };
    },
    getReactClass: function getReactClass() {
        return _ToggleConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var ToggleComponent = this.getReactClass();

        return React.createElement(ToggleComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'ToggleComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'ToggleComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'ToggleComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

    //# Mixin Overrides
    , formValueFromFieldValue_forKey: function formValueFromFieldValue_forKey(value, key) {
        if (value) {
            return true; //# force the type
        } else {
            return false;
        }
    }

});

module.exports = RCToggle;