'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _ApiSecret = require('./ApiSecret');

var _ApiSecret2 = _interopRequireDefault(_ApiSecret);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector,
        currentUserSelector = _selfModule$getDepend.currentUserSelector,
        frontendSettingsSelector = _selfModule$getDepend.frontendSettingsSelector,
        editPageSelector = _selfModule$getDepend.editPageSelector;

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);
        var editPageState = editPageSelector(state);
        var frontendSettings = frontendSettingsSelector(state);

        var idFromRoute = parseInt(editPageState.routeParams.id);
        var pageId = Number.isNaN(idFromRoute) ? undefined : idFromRoute;

        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);

        var fieldObject = selfModule.props.key ? _lodash2.default.pick(selfState.fields[selfModule.props.key], ['value']) : {};
        var formattedError = selfModule.formattedErrorFromState(state);

        var mappedProps = _extends({
            pageId: pageId,
            frontendSettings: frontendSettings
        }, ownProps, mixinFieldProps, fieldObject, formattedError);

        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        var _selfModule$getDepend2 = selfModule.getDependencies(),
            confirmDialogComponent = _selfModule$getDepend2.confirmDialogComponent;

        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch),
            delegateActions: {
                pushNotification: selfModule.pushNotification.bind(selfModule),
                confirmDialog: (0, _redux.bindActionCreators)(confirmDialogComponent.getAction().generators, dispatch)
            }
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_ApiSecret2.default);
}