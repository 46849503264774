'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = HtmlFormatterGenerator;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');
var React = require('react');

function HtmlFormatterGenerator(options) {

    var maxHeight = _.get(options, 'maxHeight') || 49;
    return function HtmlFormatter(props) {
        var _React$createElement;

        var data = _.get(props, ['data'], '');

        return React.createElement('div', (_React$createElement = {
            style: {
                maxHeight: maxHeight,
                overflow: 'hidden'
            },
            dangerouslySetInnerHTML: { __html: data },
            'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'HtmlFormatterGenerator'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'HtmlFormatterGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'HtmlFormatterGenerator'), _React$createElement));
    };
}