"use strict";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var defaultState = {
    isOpen: false,
    error: null
    //, error: null /# error: {message: <string>}just like the nested fields
};

module.exports = function (state, action) {
    var TYPES = this.getDependencies().selfAction.TYPES;
    var type = action.type,
        payload = action.payload;


    switch (type) {
        case TYPES.OPEN:
            return _extends({}, state, {
                isOpen: true
            });
        case TYPES.CLOSE:
            return _extends({}, state, {
                isOpen: false
            });
        case TYPES.RESET_STORE:
            return this.getInitialState();
        case TYPES.UPDATE_DISPLAY_VALUE_SPECS:
            return _extends({}, state, payload || {});
        default:
            return state;
    }
};