'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _Snackbar;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Dialog = require('material-ui/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _Snackbar2 = require('material-ui/Snackbar');

var _Snackbar3 = _interopRequireDefault(_Snackbar2);

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

var _constants = require('../../common/constants');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var rubyLogger = require('@rubyapps/ruby-logger');
var logger = rubyLogger.getLogger('ruby-component-notifications:reactComponents/Notification');

var PropTypes = _react2.default.PropTypes;

var DEFAULT_BODY_STYLE = {
    boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
    height: 'auto',
    lineHeight: '2.2em',
    padding: '0px 24px 6px 24px'
};
var DEFAULT_STYLE = {
    height: 0,
    alignItems: 'baseline'
};
var DEFAULT_MESSAGE_STYLE = {
    position: 'relative',
    top: 12
};

var styles = {
    Snackbar: (_Snackbar = {}, _defineProperty(_Snackbar, _constants2.default.NOTIFICATION_TYPES.INFO, {
        bodyStyle: _extends({}, DEFAULT_BODY_STYLE, {
            backgroundColor: '#BDE5F8'
        }),
        style: _extends({}, DEFAULT_STYLE, {
            color: '#00529B'
        }),
        messageStyle: _extends({}, DEFAULT_MESSAGE_STYLE, {
            color: '#00529B'
        })
    }), _defineProperty(_Snackbar, _constants2.default.NOTIFICATION_TYPES.SUCCESS, {
        bodyStyle: _extends({}, DEFAULT_BODY_STYLE, {
            backgroundColor: '#DFF2BF'
        }),
        style: _extends({}, DEFAULT_STYLE, {
            color: '#4F8A10'
        }),
        messageStyle: _extends({}, DEFAULT_MESSAGE_STYLE, {
            color: '#4F8A10'
        })
    }), _defineProperty(_Snackbar, _constants2.default.NOTIFICATION_TYPES.WARNING, {
        bodyStyle: _extends({}, DEFAULT_BODY_STYLE, {
            backgroundColor: '#FEEFB3'
        }),
        style: _extends({}, DEFAULT_STYLE, {
            color: '#9F6000'
        }),
        messageStyle: _extends({}, DEFAULT_MESSAGE_STYLE, {
            color: '#9F6000'
        })
    }), _defineProperty(_Snackbar, _constants2.default.NOTIFICATION_TYPES.ERROR, {
        bodyStyle: _extends({}, DEFAULT_BODY_STYLE, {
            backgroundColor: '#FFBABA'
        }),
        style: _extends({}, DEFAULT_STYLE, {
            color: '#D8000C'
        }),
        messageStyle: _extends({}, DEFAULT_MESSAGE_STYLE, {
            color: '#D8000C'
        })
    }), _Snackbar),
    Dialog: {}
};

function getStyleObjectFromMuiTheme_forNotificationType(muiTheme, notificationType) {
    var palette = muiTheme.palette;

    switch (notificationType) {
        case _constants2.default.NOTIFICATION_TYPES.SUCCESS:
            return {
                bodyStyle: {
                    backgroundColor: palette.sucessColor_background
                },
                style: {
                    color: palette.successColor_foreground
                },
                messageStyle: _extends({}, DEFAULT_MESSAGE_STYLE, {
                    color: '#4F8A10'
                })
            };
            break;
        case _constants2.default.NOTIFICATION_TYPES.WARNING:
            return {
                bodyStyle: {
                    backgroundColor: palette.warningColor_background
                },
                style: {
                    color: palette.warningColor_foreground
                },
                messageStyle: _extends({}, DEFAULT_MESSAGE_STYLE, {
                    color: '#9F6000'
                })
            };
            break;
        case _constants2.default.NOTIFICATION_TYPES.ERROR:
            return {
                bodyStyle: {
                    backgroundColor: palette.errorColor_background
                },
                style: {
                    color: palette.errorColor_foreground
                },
                messageStyle: _extends({}, DEFAULT_MESSAGE_STYLE, {
                    color: '#D8000C'
                })
            };
            break;
        default:
            return {
                bodyStyle: {
                    backgroundColor: palette.infoColor_background
                },
                style: {
                    color: palette.infoColor_foreground
                },
                messageStyle: _extends({}, DEFAULT_MESSAGE_STYLE, {
                    color: '#00529B'
                })
            };

    }
}

var DEFAULT_STYLE_OBJECT = {
    bodyStyle: {},
    style: {},
    messageStyle: {}
};

var Notification = _react2.default.createClass({
    displayName: 'Notification',

    propTypes: {
        //# state-like
        open: PropTypes.bool,
        modal: PropTypes.bool,
        formatted_mailto_link: PropTypes.string

        //# delegate Actions
        , delegateClose: PropTypes.func,

        actionLabel: PropTypes.string,
        onActionTouchTap: PropTypes.func

        //# other
        , styles: PropTypes.object

    },
    getDefaultProps: function getDefaultProps() {
        return {
            actionLabel: "Close"
        };
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },

    _handleSupportClick: function _handleSupportClick() {
        window.location.href = this.props.formatted_mailto_link;
    },

    renderNotificationMessage: function renderNotificationMessage() {
        var _React$createElement;

        var notificationType = this.props.type || _constants2.default.NOTIFICATION_TYPES.INFO;
        var styleProps = getStyleObjectFromMuiTheme_forNotificationType(this.context.muiTheme, notificationType);
        var message = _react2.default.createElement(
            'span',
            (_React$createElement = { style: styleProps.messageStyle, 'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'Notification'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Notification'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Notification'), _React$createElement),
            this.props.message
        );

        if (typeof message === 'string') {
            var _extends2;

            message = _react2.default.createElement(
                'span',
                _extends({}, _lodash2.default.omit(styleProps, ['bodyStyle']), (_extends2 = { className: notificationType, 'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'Notification'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'span'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Notification'), _defineProperty(_extends2, 'data-codecept-selector-node', 'span'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Notification'), _extends2)),
                message
            );
        }

        return message;
    },
    renderErrorMessage: function renderErrorMessage(message) {
        if (!message) return message;

        if (typeof message === 'string' && message.indexOf('Support') > 0 && this.props.formatted_mailto_link) {
            var _React$createElement2, _React$createElement3;

            var re = /^(.+)(Support)(.*)$/;
            var result = re.exec(message);

            var messageStart = result[1];
            var messageKeyword = result[2];
            var messageEnd = result[3];

            return _react2.default.createElement(
                'div',
                (_React$createElement3 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Notification'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Notification'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Notification'), _React$createElement3),
                messageStart,
                _react2.default.createElement(
                    'a',
                    (_React$createElement2 = { onTouchTap: this._handleSupportClick, 'data-codecept-selector-node': 'a',
                        'data-codecept-selector-file': 'Notification'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Notification'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Notification'), _React$createElement2),
                    messageKeyword
                ),
                messageEnd
            );
        } else {
            return message;
        }
    },
    render: function render() {
        var _React$createElement4,
            _this = this,
            _extends4,
            _extends5;

        var actions = [_react2.default.createElement(_FlatButton2.default, (_React$createElement4 = {
            label: this.props.actionLabel,
            primary: true,
            onTouchTap: this.props.onActionTouchTap,
            'data-codecept-selector-node': 'FlatButton',
            'data-codecept-selector-file': 'Notification'
        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Notification'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Notification'), _React$createElement4))].concat((this.props.additionalActions || []).map(function (actionSpec) {
            var _extends3;

            return _react2.default.createElement(_FlatButton2.default, _extends({}, actionSpec, (_extends3 = {
                onTouchTap: function onTouchTap(evt) {
                    return actionSpec.onTouchTap(evt, _this);
                },
                'data-codecept-selector-node': 'FlatButton',
                'data-codecept-selector-file': 'Notification'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Notification'), _defineProperty(_extends3, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Notification'), _extends3)));
        }));

        var componentName = this.props.modal ? 'Dialog' : 'Snackbar';
        var notificationType = this.props.type || _constants2.default.NOTIFICATION_TYPES.INFO;
        var styleProps = _lodash2.default.get(styles, [componentName, notificationType], DEFAULT_STYLE_OBJECT);
        var mergedStyleProps = _lodash2.default.omit(this.mergedStylesFromState(styleProps), ['messageStyle']);

        var message = this.renderNotificationMessage();
        var errorMessage = this.renderErrorMessage(this.props.message);

        // If caller specifically sets the duration to 0,
        // then use an empty string as the value so that the snackbar doesn't go away
        // We use this on the published/unbpublished notifications in the approval review popup
        var duration = this.props.duration === 0 ? "" : this.props.duration || _constants2.default.DEFAULT_LIGHTWEIGHT_NOTIFICATION_DURATION;

        return this.props.modal ? _react2.default.createElement(
            _Dialog2.default,
            _extends({}, mergedStyleProps, (_extends4 = {
                open: this.props.open,
                modal: true,
                title: this.props.title,
                actions: actions,
                onRequestClose: this.props.delegateClose,
                'data-codecept-selector-node': 'Dialog',
                'data-codecept-selector-file': 'Notification'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Notification'), _defineProperty(_extends4, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Notification'), _extends4)),
            errorMessage || this.props.body
        ) : _react2.default.createElement(_Snackbar3.default, _extends({}, mergedStyleProps, (_extends5 = {
            open: this.props.open,
            message: message,
            action: this.props.actionLabel,
            autoHideDuration: duration,
            onActionTouchTap: this.props.onActionTouchTap,
            onRequestClose: this.props.delegateClose,
            'data-codecept-selector-node': 'Snackbar',
            'data-codecept-selector-file': 'Notification'
        }, _defineProperty(_extends5, 'data-codecept-selector-node', 'Snackbar'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Notification'), _defineProperty(_extends5, 'data-codecept-selector-node', 'Snackbar'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Notification'), _extends5)));
    },
    mergedStylesFromState: function mergedStylesFromState(styleProps) {
        var desktopSubheaderHeight = this.context.muiTheme.baseTheme.spacing.desktopSubheaderHeight;


        var stylesFromState = {
            bottom: 'inherit',
            top: 46,
            transform: this.props.open ? 'translate(0, 0)' : 'translate(0, ' + -1 * desktopSubheaderHeight + 'px)'
        };

        var propStyles = this.props.styles || {};

        var mergedStyles = _extends({}, styleProps, {
            style: _extends({}, styleProps.style, stylesFromState, propStyles.style || {})
        });
        return mergedStyles;
    }
});

exports.default = Notification;