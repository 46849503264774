'use strict';

var _reduxBatchedActions = require('redux-batched-actions');

function typesWithID(id) {
    return {
        SET_IS_LOADING: '@@ruby-app/' + id + '/SET_IS_LOADING',
        SET_REQUEST_OBJECT: '@@ruby-app/' + id + '/SET_REQUEST_OBJECT',
        SET_REQUEST_RESPONSE: '@@ruby-app/' + id + '/SET_REQUEST_RESPONSE'
    };
}

var generators = {

    setIsLoading: function setIsLoading(isLoading) {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES,
            generators = _getAction.generators;

        return {
            type: TYPES.SET_IS_LOADING,
            payload: {
                isLoading: isLoading
            }
        };
    },
    setRequestObject: function setRequestObject(requestObject) {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES,
            generators = _getAction2.generators;

        return (0, _reduxBatchedActions.batchActions)([generators._setRequestObject(requestObject), generators.setIsLoading(true)]);
    },
    clearRequestObject: function clearRequestObject() {
        var _getAction3 = this.getAction(),
            TYPES = _getAction3.TYPES,
            generators = _getAction3.generators;

        return (0, _reduxBatchedActions.batchActions)([generators._clearRequestObject(), generators.setIsLoading(false)]);
    },

    _setRequestObject: function _setRequestObject(requestObject) {
        var _getAction4 = this.getAction(),
            TYPES = _getAction4.TYPES,
            generators = _getAction4.generators;

        return {
            type: TYPES.SET_REQUEST_OBJECT,
            payload: {
                requestObject: requestObject
            }
        };
    },
    _clearRequestObject: function _clearRequestObject() {
        var _getAction5 = this.getAction(),
            TYPES = _getAction5.TYPES,
            generators = _getAction5.generators;

        return {
            type: TYPES.SET_REQUEST_OBJECT,
            payload: {
                requestObject: null
            }
        };
    },

    setRequestResponse: function setRequestResponse(requestResponse) {
        var TYPES = this.getAction().TYPES;
        //response.constructor.name == "Error" if it's an error
        return {
            type: TYPES.SET_REQUEST_RESPONSE,
            payload: {
                requestResponse: requestResponse
            }
        };
    }

};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};