'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _constants = require('../common/constants');

var _constants2 = _interopRequireDefault(_constants);

var _permissions = require('../common/permissions');

var _clientInfo = require('../common/client-info');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _reduceMenuArr_withUserObject(menuArr, userObject) {
    var filteredMenuArr = menuArr.reduce(function (collector, menuItem, menuIndex) {
        var permissions = menuItem.permissions ? menuItem.permissions : [];
        var isPermitted = permissions ? (0, _permissions.doesUserMatchPermissionObject)(userObject, permissions) : true;
        if (isPermitted) {
            var reducedMenuItem = _lodash2.default.omit(menuItem, ['children', 'permissions']);
            if (menuItem.children) {
                var reducedChildren = _reduceMenuArr_withUserObject(menuItem.children, userObject);
                if (reducedChildren.length) {
                    reducedMenuItem.children = reducedChildren;
                }
            }
            if (reducedMenuItem.url || reducedMenuItem.route || Array.isArray(reducedMenuItem.children) && reducedMenuItem.children.length > 0) {
                collector.push(reducedMenuItem);
            }
        }
        return collector;
    }, []);

    return filteredMenuArr;
}

function _rubyClientInfoTreeTraversal_matchingFn(collector, currentNode, matchingFn) {
    if (currentNode == undefined) {
        return collector;
    }

    for (var key in currentNode) {
        var nodeForKey = currentNode[key];
        var mergedCollector = _extends({}, collector, _lodash2.default.omit(nodeForKey, ['children']));
        if (matchingFn(mergedCollector)) {
            return mergedCollector;
        } else if (nodeForKey.hasOwnProperty('children')) {
            collector = _lodash2.default.reduce(nodeForKey.children, function (collector, childNode, childKey) {
                return _rubyClientInfoTreeTraversal_matchingFn(collector, childNode, matchingFn);
            }, mergedCollector);
        }
    }

    return collector;
}

var accessManager = {

    CONSTANTS: _constants2.default,

    does_permissionList_includePermission: function does_permissionList_includePermission(permissionList, permission) {
        return (0, _permissions.boolean_fromPermissionObject_andUser)(permission, {
            rubyRole: {
                ruby_permissions: permissionList
            }
        });
    },
    idsInRubyPermissionsFromUser_forPerm: _permissions.idsInRubyPermissionsFromUser_forPerm,
    doesUserHavePermission: function doesUserHavePermission(userObject, permission) {
        return (0, _permissions.boolean_fromPermissionObject_andUser)(permission, userObject);
    },

    filteredMenuConfig_withUserObject: function filteredMenuConfig_withUserObject(menuConfig, userObject) {
        if (!userObject) {
            return {};
        }

        var filteredMenuConfig = _reduceMenuArr_withUserObject([menuConfig], userObject);

        return filteredMenuConfig.length ? filteredMenuConfig[0] : {};
    },

    getRubyClientInfo_fromSettings_forRoute: _clientInfo.getRubyClientInfo_fromSettings_forRoute,
    getRubyClientId_fromSettings_forRoute: function getRubyClientId_fromSettings_forRoute(settings, route) {
        return (0, _clientInfo.getRubyClientInfo_fromSettings_forRoute)(settings, route).client;
    },
    getSubsiteId_fromSettings_forRoute: function getSubsiteId_fromSettings_forRoute(settings, route) {
        return (0, _clientInfo.getRubyClientInfo_fromSettings_forRoute)(settings, route).subsite;
    },

    getSubsiteUrlIdentifier_fromSettings_forRubyClientId_andSubsiteId: function getSubsiteUrlIdentifier_fromSettings_forRubyClientId_andSubsiteId(feSettings, rubyClientId, subsiteId) {
        var _feSettings$rubyClien = feSettings.rubyClients_routeTree,
            rubyClients_routeTree = _feSettings$rubyClien === undefined ? {} : _feSettings$rubyClien;


        var seedInfo = _extends({}, _lodash2.default.omit(rubyClients_routeTree, ['children']));
        var rubyClientInfo = _rubyClientInfoTreeTraversal_matchingFn(seedInfo, rubyClients_routeTree.children, function (infoObject) {
            if (infoObject.client == rubyClientId && infoObject.subsite == subsiteId) {
                return true;
            }
            return false;
        });

        return rubyClientInfo.subsiteUrlIdentifier;
    },

    getDefaultSubsiteUrlIdentifier_fromSettings: function getDefaultSubsiteUrlIdentifier_fromSettings(feSettings) {
        var _feSettings$rubyClien2 = feSettings.rubyClients_routeTree,
            rubyClients_routeTree = _feSettings$rubyClien2 === undefined ? {} : _feSettings$rubyClien2;


        return rubyClients_routeTree.subsiteUrlIdentifier;
    }
};

module.exports = accessManager;