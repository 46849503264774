'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _palette;

var _colors = require('material-ui/styles/colors');

var Colors = _interopRequireWildcard(_colors);

var _colors2 = require('../styleObjects/colors');

var RubyColors = _interopRequireWildcard(_colors2);

var _colorManipulator = require('material-ui/utils/colorManipulator');

var ColorManipulator = _interopRequireWildcard(_colorManipulator);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; } //https://www.materialpalette.com/blue-grey/blue-grey

//import Spacing from 'material-ui/styles/spacing';
var Spacing = {
    iconSize: 24,

    desktopGutter: 24,
    desktopGutterMore: 32,
    desktopGutterLess: 16,
    desktopGutterMini: 8,
    desktopKeylineIncrement: 64,
    desktopDropDownMenuItemHeight: 32,
    desktopDropDownMenuFontSize: 15,
    desktopDrawerMenuItemHeight: 48,
    desktopSubheaderHeight: 48,
    desktopToolbarHeight: 56
};

var emphasisCoefficient = 0.2;
//'#4FC3F7';
var rubyRed = '#ed0c53';
var rubyAccent = '#455A64'; //# Dark Primary Color

var errorColor = Colors.red500;
exports.default = {
    spacing: Spacing,
    fontFamily: 'CartoGothicStd,Helvetica,Verdana,Arial,sans-serif',
    zIndex: {
        snackbar: 11
    },
    palette: (_palette = {
        primary1Color: RubyColors.rubyBlue, //_light,
        primary2Color: RubyColors.rubyBlue_light,
        primary3Color: Colors.grey400,
        primary4Color: RubyColors.headerGrey,

        accent1Color: rubyAccent, //rubyRed,
        accent2Color: Colors.grey100,
        accent3Color: Colors.grey400,

        textColor: Colors.darkBlack,
        alternateTextColor: Colors.white,
        canvasColor: Colors.white,
        borderColor: Colors.grey300,
        inactiveColor: (0, ColorManipulator.fade)(Colors.darkBlack, 0.5),
        disabledColor: (0, ColorManipulator.fade)(Colors.darkBlack, 0.26),
        disabledColor_level2: (0, ColorManipulator.fade)(Colors.darkBlack, 0.12),
        disabledColor_level3: (0, ColorManipulator.fade)(Colors.darkBlack, 0.06),
        pickerHeaderColor: RubyColors.rubyBlue_light,
        clockCircleColor: (0, ColorManipulator.fade)(Colors.darkBlack, 0.07),
        shadowColor: Colors.fullBlack,

        floatingLabelColor: (0, ColorManipulator.fade)(Colors.darkBlack, 0.5)

    }, _defineProperty(_palette, 'borderColor', RubyColors.black_25), _defineProperty(_palette, 'infoColor_background', '#BDE5F8'), _defineProperty(_palette, 'infoColor_foreground', '#00529B'), _defineProperty(_palette, 'succesColor_background', '#DFF2BF'), _defineProperty(_palette, 'successColor_foreground', '#4F8A10'), _defineProperty(_palette, 'warningColor_background', '#FEEFB3'), _defineProperty(_palette, 'warningColor_foreground', '#9F6000'), _defineProperty(_palette, 'errorColor_background', '#FFBABA'), _defineProperty(_palette, 'errorColor_foreground', '#D8000C'), _palette),
    alternates: {
        inkBar: {
            backgroundColor: '#FFFFFF'
        },
        tabError: {
            backgroundColor: errorColor
        }
        //# TODO: start adding in component by component overrides for the transitions
        //# and updating the components to check for some flag and determining whether we
        //# should pass these objects as inline-style overrides
    }, noAnimation: {
        drawer: {
            containerStyle: {
                overflow: 'visible',
                transition: 'transform 0ms'
            }
        }
    }
};