'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp2;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TimeDisplay = require('./TimeDisplay');

var _TimeDisplay2 = _interopRequireDefault(_TimeDisplay);

var _ClockHours = require('./ClockHours');

var _ClockHours2 = _interopRequireDefault(_ClockHours);

var _ClockMinutes = require('./ClockMinutes');

var _ClockMinutes2 = _interopRequireDefault(_ClockMinutes);

var _AutoComplete = require('material-ui/AutoComplete');

var _AutoComplete2 = _interopRequireDefault(_AutoComplete);

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var epochMoment = _momentTimezone2.default.unix(0).tz('UTC');
var guessedZoneName = _momentTimezone2.default.tz.guess();

var Clock = (_temp2 = _class = function (_Component) {
  _inherits(Clock, _Component);

  function Clock() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Clock);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Clock.__proto__ || Object.getPrototypeOf(Clock)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      selectedTime: null,
      mode: 'hour'
    }, _this.timeZones = (_this.props.customTimeZoneList || _momentTimezone2.default.tz.names()).map(function (value) {
      if (typeof value === 'string') {
        return {
          value: value,
          primaryText: value
        };
      } else {
        return value;
      }
    }), _this.setMode = function (mode) {
      setTimeout(function () {
        _this.setState({
          mode: mode
        });
      }, 100);
    }, _this.handleSelectAffix = function (affix) {
      if (affix === _this.getAffix()) return;

      var hours = _this.state.selectedTime.hours();

      if (affix === 'am') {
        _this.handleChangeHours(hours - 12, affix);
        return;
      }

      _this.handleChangeHours(hours + 12, affix);
    }, _this.handleChangeHours = function (hours, finished) {
      var time = (0, _momentTimezone2.default)(_this.state.selectedTime);
      var affix = void 0;

      if (typeof finished === 'string') {
        affix = finished;
        finished = undefined;
      }
      if (!affix) {
        affix = _this.getAffix();
      }
      if (affix === 'pm' && hours < 12) {
        hours += 12;
      }

      time.hour(hours);
      _this.setState({
        selectedTime: time
      });

      if (finished) {
        setTimeout(function () {
          _this.setState({
            mode: 'minute'
          });

          var onChangeHours = _this.props.onChangeHours;

          if (onChangeHours) {
            onChangeHours(time);
          }
        }, 100);
      }
    }, _this.handleChangeMinutes = function (minutes) {
      var time = (0, _momentTimezone2.default)(_this.state.selectedTime);
      time.minutes(minutes);
      _this.setState({
        selectedTime: time
      });

      var onChangeMinutes = _this.props.onChangeMinutes;

      if (onChangeMinutes) {
        setTimeout(function () {
          onChangeMinutes(time);
        }, 0);
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Clock, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var zoneName = this.props.initialZoneName;
      this.setState({
        selectedTime: this.props.initialTime.tz(zoneName),
        zoneName: zoneName
      });
    }
  }, {
    key: 'getAffix',
    value: function getAffix() {
      if (this.props.format !== 'ampm') return '';

      var hours = this.state.selectedTime.hours();
      if (hours < 12) {
        return 'am';
      }

      return 'pm';
    }
  }, {
    key: 'getSelectedTime',
    value: function getSelectedTime() {
      return this.state.selectedTime;
    }
  }, {
    key: 'getSelectedZoneName',
    value: function getSelectedZoneName() {
      return this.state.zoneName;
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this,
          _React$createElement7,
          _React$createElement8;

      var clock = null;

      var _context$muiTheme = this.context.muiTheme,
          prepareStyles = _context$muiTheme.prepareStyles,
          timePicker = _context$muiTheme.timePicker;


      var styles = {
        root: {
          userSelect: 'none'
        },
        container: {
          height: 280,
          padding: 10,
          position: 'relative',
          boxSizing: 'content-box'
        },
        circle: {
          position: 'absolute',
          top: 20,
          width: 260,
          height: 260,
          borderRadius: '100%',
          backgroundColor: timePicker.clockCircleColor
        }
      };

      if (this.state.mode === 'hour') {
        var _React$createElement;

        clock = _react2.default.createElement(_ClockHours2.default, (_React$createElement = {
          key: 'hours',
          format: this.props.format,
          onChange: this.handleChangeHours,
          initialHours: this.state.selectedTime.hours(),
          'data-codecept-selector-node': 'ClockHours',
          'data-codecept-selector-file': 'Clock'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ClockHours'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Clock'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ClockHours'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Clock'), _React$createElement));
      } else {
        var _React$createElement2;

        clock = _react2.default.createElement(_ClockMinutes2.default, (_React$createElement2 = {
          key: 'minutes',
          onChange: this.handleChangeMinutes,
          initialMinutes: this.state.selectedTime.minutes(),
          'data-codecept-selector-node': 'ClockMinutes',
          'data-codecept-selector-file': 'Clock'
        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'ClockMinutes'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Clock'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'ClockMinutes'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Clock'), _React$createElement2));
      }

      var content = null;
      if (this.state.mode === 'zoneName') {
        var _React$createElement3, _React$createElement4;

        content = _react2.default.createElement(
          'div',
          (_React$createElement4 = {
            style: {
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            },
            'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'Clock'
          }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Clock'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Clock'), _React$createElement4),
          _react2.default.createElement(_AutoComplete2.default, (_React$createElement3 = {
            autoFocus: true,
            floatingLabelText: 'Search Zones',
            filter: function filter(searchText, key) {
              if (searchText === '') {
                return;
              }

              var analyzedSearchText = analyzer(searchText);
              var analyzedText = analyzer(key);

              return analyzedText.indexOf(analyzedSearchText) !== -1;

              function analyzer(text) {
                return text.toLowerCase().replace('_', ' ');
              }
            },
            maxSearchResults: 5,
            openOnFocus: true,
            dataSource: this.timeZones.slice(0),
            dataSourceConfig: { text: 'primaryText', value: 'value' },
            onNewRequest: function onNewRequest(selection, dataSource) {
              var selectedHours = _this2.state.selectedTime.hours();

              _this2.setState({
                selectedTime: _this2.state.selectedTime.tz(selection.value).set({
                  hours: selectedHours
                }),
                zoneName: selection.value,
                mode: 'hour'
              });
            },
            listStyle: {
              width: 'auto',
              minWidth: 256
            },
            'data-codecept-selector-node': 'AutoComplete',
            'data-codecept-selector-file': 'Clock'
          }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'AutoComplete'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Clock'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'AutoComplete'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Clock'), _React$createElement3))
        );
      } else {
        var _React$createElement5, _React$createElement6;

        content = _react2.default.createElement(
          'div',
          (_React$createElement6 = { style: prepareStyles(styles.container), 'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'Clock'
          }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Clock'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Clock'), _React$createElement6),
          _react2.default.createElement('div', (_React$createElement5 = { style: prepareStyles(styles.circle), 'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'Clock'
          }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Clock'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Clock'), _React$createElement5)),
          clock
        );
      }

      return _react2.default.createElement(
        'div',
        (_React$createElement8 = { style: prepareStyles(styles.root), 'data-codecept-selector-node': 'div',
          'data-codecept-selector-file': 'Clock'
        }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Clock'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Clock'), _React$createElement8),
        _react2.default.createElement(_TimeDisplay2.default, (_React$createElement7 = {
          zoneName: this.state.zoneName,
          selectedTime: this.state.selectedTime,
          referenceDate: this.props.referenceDate,
          mode: this.state.mode,
          format: this.props.format,
          affix: this.getAffix(),
          onSelectAffix: this.handleSelectAffix,
          onSelectHour: this.setMode.bind(this, 'hour'),
          onSelectMin: this.setMode.bind(this, 'minute'),
          onSelectZoneName: this.setMode.bind(this, 'zoneName'),
          'data-codecept-selector-node': 'TimeDisplay',
          'data-codecept-selector-file': 'Clock'
        }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'TimeDisplay'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Clock'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'TimeDisplay'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Clock'), _React$createElement7)),
        content
      );
    }
  }]);

  return Clock;
}(_react.Component), _class.propTypes = {
  format: _react.PropTypes.oneOf(['ampm', '24hr']),
  initialTime: _react.PropTypes.object,
  initialZoneName: _react.PropTypes.string,
  onChangeHours: _react.PropTypes.func,
  onChangeMinutes: _react.PropTypes.func
}, _class.defaultProps = {
  initialTime: epochMoment.tz(guessedZoneName),
  initialZoneName: guessedZoneName
}, _class.contextTypes = {
  muiTheme: _react.PropTypes.object.isRequired
}, _temp2);
exports.default = Clock;