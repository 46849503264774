'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _urlJoin = require('url-join');

var _urlJoin2 = _interopRequireDefault(_urlJoin);

var _clipboardCopy = require('clipboard-copy');

var _clipboardCopy2 = _interopRequireDefault(_clipboardCopy);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

var _IconMenu = require('material-ui/IconMenu');

var _IconMenu2 = _interopRequireDefault(_IconMenu);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _share = require('material-ui/svg-icons/social/share');

var _share2 = _interopRequireDefault(_share);

var _url = require('url');

var _url2 = _interopRequireDefault(_url);

var _addInfoModeProps = require('@rubyapps/ruby-react-components/src/client/hoc/addInfoModeProps');

var _addInfoModeProps2 = _interopRequireDefault(_addInfoModeProps);

var _Url_style = require('./Url_style');

var _Url_style2 = _interopRequireDefault(_Url_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var _require = require('@rubyapps/react-flexbox-grid'),
    Grid = _require.Grid,
    Row = _require.Row,
    Col = _require.Col;

var rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');

var _require2 = require('@rubyapps/ruby-field-common'),
    Label = _require2.Label,
    RichErrorMessages = _require2.RichErrorMessages;

var Url = _react2.default.createClass({
    displayName: 'Url',

    propTypes: {
        label: PropTypes.string,
        isActive: PropTypes.bool,
        delegateActions: PropTypes.object,
        value: PropTypes.string,
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        id: PropTypes.string,
        actions: PropTypes.object,
        default_host: PropTypes.string,
        hintText: PropTypes.string,
        actionTypes: PropTypes.arrayOf(PropTypes.oneOf(['test', 'copy', 'share'])),
        domainSpecs: PropTypes.arrayOf(PropTypes.shape({
            origin: PropTypes.string,
            name: PropTypes.string
        }))
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null,
            hintText: 'https://',
            actionTypes: ['test', 'copy', 'share']
        };
    },
    _onTextChange: function _onTextChange(event) {
        this.props.actions.setField_withValue(event.target.value);
    },
    _onTestClick: function _onTestClick() {
        var url = this.props.value;

        if (!url || url == "") {
            alert('Please enter a valid link.');
            return false;
        }

        // URL must be fully qualified
        var re = new RegExp('^(https?://)');
        var results = re.exec(url);
        if (results == null) // then the pattern didn't match
            {
                var prefix = 'http://';
                if (url.substr(0, 1) == '/') {
                    prefix = this.props.default_host;
                }

                url = prefix + url;
            }

        // Pop URL in new window
        var x = (screen.availWidth - width) / 2 + 50;
        var y = (screen.availHeight - height) / 2 + 50;

        var width = 800;
        var height = 600;

        var new_wnd = window.open(url, '_blank', 'width=' + width + 'px,' + 'height=' + height + 'px,' + 'left=' + x + ',' + 'top=' + y + ',' + 'scrollbars=yes');
        if (new_wnd && new_wnd.blur) new_wnd.focus();
    },
    _copy_string_withOptions: function _copy_string_withOptions(value) {
        var _this = this;

        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        (0, _clipboardCopy2.default)(value || '').then(function () {
            options.successMessage && _this.props.delegateActions.pushNotification({
                message: options.successMessage || _this.props.label + ' was copied to the clipboard',
                type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.INFO
            });
        }).catch(function (err) {
            console.error(err);
            options.errorMessage && _this.props.delegateActions.pushNotification({
                message: options.errorMessage || 'Could not copy ' + _this.props.label + ' to the clipboard',
                type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR
            });
        });
    },
    _renderShare: function _renderShare() {
        var _this2 = this;

        var value = this.props.value;
        if (!value) {
            return null;
        }

        var isAbsolute = !!_url2.default.parse(value).hostname;

        var shareSpecs = isAbsolute ? [{
            shareUrl: value
        }] : (this.props.domainSpecs || []).map(function (_ref) {
            var _React$createElement, _React$createElement2;

            var origin = _ref.origin,
                name = _ref.name;
            return {
                shareUrl: (0, _urlJoin2.default)(origin, value),
                name: name,
                successMessage: _react2.default.createElement(
                    'span',
                    (_React$createElement2 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'Url'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Url'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Url'), _React$createElement2),
                    _this2.props.label,
                    ' for ',
                    _react2.default.createElement(
                        'strong',
                        (_React$createElement = {
                            'data-codecept-selector-node': 'strong',
                            'data-codecept-selector-file': 'Url'
                        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Url'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Url'), _React$createElement),
                        name
                    ),
                    ' was copied to the clipboard'
                )
            };
        });

        var buttonProps = {
            style: { top: 10 },
            tooltip: 'Share'
        };

        if (_lodash2.default.get(shareSpecs, 'length') === 1) {
            var _React$createElement3, _extends2;

            return _react2.default.createElement(
                _IconButton2.default,
                _extends({}, buttonProps, (_extends2 = {
                    onTouchTap: function onTouchTap() {
                        _this2._copy_string_withOptions(shareSpecs[0].shareUrl, {
                            successMessage: shareSpecs[0].successMessage
                        });
                    },
                    'data-codecept-selector-node': 'IconButton',
                    'data-codecept-selector-file': 'Url'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Url'), _defineProperty(_extends2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Url'), _extends2)),
                _react2.default.createElement(_share2.default, (_React$createElement3 = {
                    'data-codecept-selector-node': 'ShareIcon',
                    'data-codecept-selector-file': 'Url'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ShareIcon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Url'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ShareIcon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Url'), _React$createElement3))
            );
        } else if (_lodash2.default.get(shareSpecs, 'length') > 1) {
            var _React$createElement4, _extends3, _React$createElement6;

            return _react2.default.createElement(
                _IconMenu2.default,
                (_React$createElement6 = {
                    iconButtonElement: _react2.default.createElement(
                        _IconButton2.default,
                        _extends({}, buttonProps, (_extends3 = {
                            'data-codecept-selector-node': 'IconButton',
                            'data-codecept-selector-file': 'Url'
                        }, _defineProperty(_extends3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Url'), _defineProperty(_extends3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Url'), _extends3)),
                        _react2.default.createElement(_share2.default, (_React$createElement4 = {
                            'data-codecept-selector-node': 'ShareIcon',
                            'data-codecept-selector-file': 'Url'
                        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ShareIcon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Url'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ShareIcon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Url'), _React$createElement4))
                    ),
                    'data-codecept-selector-node': 'IconMenu',
                    'data-codecept-selector-file': 'Url'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Url'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Url'), _React$createElement6),
                shareSpecs.map(function (_ref2) {
                    var _React$createElement5;

                    var shareUrl = _ref2.shareUrl,
                        successMessage = _ref2.successMessage,
                        name = _ref2.name;
                    return _react2.default.createElement(_MenuItem2.default, (_React$createElement5 = {
                        key: shareUrl,
                        primaryText: name,
                        onTouchTap: function onTouchTap() {
                            _this2._copy_string_withOptions(shareUrl, { successMessage: successMessage });
                        },
                        'data-codecept-selector-node': 'MenuItem',
                        'data-codecept-selector-file': 'Url'
                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Url'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Url'), _React$createElement5));
                })
            );
        }
    },
    render: function render() {
        var _React$createElement7,
            _this3 = this,
            _React$createElement8,
            _React$createElement9,
            _extends4,
            _extends5,
            _React$createElement10,
            _React$createElement11;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var labelProps = _lodash2.default.pick(this.props, Object.keys(Label.propTypes));

        var actionsByType = {
            test: {
                component: !this.props.locked && _react2.default.createElement(_FlatButton2.default, (_React$createElement7 = {
                    label: 'Test',
                    primary: true,
                    onClick: this._onTestClick,
                    'data-codecept-selector-node': 'FlatButton',
                    'data-codecept-selector-file': 'Url'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Url'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Url'), _React$createElement7))
            },
            copy: {
                component: _react2.default.createElement(_FlatButton2.default, (_React$createElement8 = {
                    label: 'Copy',
                    primary: true,
                    onClick: function onClick() {
                        _this3._copy_string_withOptions(_this3.props.value || '');
                    },
                    'data-codecept-selector-node': 'FlatButton',
                    'data-codecept-selector-file': 'Url'
                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Url'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Url'), _React$createElement8))
            },
            share: {
                component: this._renderShare()
            }
        };

        var errorText = !_lodash2.default.isEmpty(this.props.richErrorMessages) ? _react2.default.createElement(RichErrorMessages, (_React$createElement9 = { messages: this.props.richErrorMessages, 'data-codecept-selector-node': 'RichErrorMessages',
            'data-codecept-selector-file': 'Url'
        }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Url'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Url'), _React$createElement9)) : null;
        return _react2.default.createElement(
            'div',
            (_React$createElement11 = {
                style: {
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'baseline',
                    justifyContent: 'flex-end'
                },
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Url'
            }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'Url'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'Url'), _React$createElement11),
            _react2.default.createElement(_TextField2.default, _extends({}, _Url_style2.default.TextField, {
                id: this.props.id,
                hintText: this.props.hintText,
                floatingLabelText: _react2.default.createElement(Label, _extends({}, labelProps, (_extends4 = {
                    'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'Url'
                }, _defineProperty(_extends4, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Url'), _defineProperty(_extends4, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Url'), _extends4))),
                onChange: this._onTextChange,
                value: this.props.value || '',
                errorText: errorText,
                disabled: this.props.locked
            }, _lodash2.default.get(this, 'props.infoModeProps.TextField'), _lodash2.default.get(this, 'props.infoModeProps.default'), (_extends5 = {
                errorStyle: {
                    color: _lodash2.default.get(this, 'props.errorColors.foreground')
                },
                'data-codecept-selector-node': 'TextField',
                'data-codecept-selector-file': 'Url'
            }, _defineProperty(_extends5, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Url'), _defineProperty(_extends5, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Url'), _extends5))),
            _react2.default.createElement(
                'div',
                (_React$createElement10 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Url'
                }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'Url'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'Url'), _React$createElement10),
                this.props.actionTypes.map(function (actionType) {
                    return _lodash2.default.get(actionsByType, [actionType, 'component']);
                })
            )
        );
    }
});

exports.default = (0, _addInfoModeProps2.default)(Url);