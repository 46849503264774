'use strict';

module.exports = {
    modelName: 'changelog',
    columns: [{
        key: 'timestamp',
        displayName: 'Timestamp',
        type: 'date',
        componentParams: {
            format: 'YYYY MMM DD hh:mma zz'
        },
        resizable: false,
        minWidth: 190,
        width: 190
    }, {
        key: 'username',
        displayName: 'User',
        resizable: false
    }, {
        key: 'verb',
        displayName: 'Action',
        resizable: false
    }, {
        key: 'entity_name',
        displayName: 'Entity',
        resizable: false
    }, {
        key: 'entity_id',
        displayName: 'ID',
        resizable: false
    }, {
        key: 'data_name',
        displayName: 'Name',
        resizable: false
    }],
    defaultSortColumn: 'timestamp',
    defaultSortDirection: 'DESC'
};