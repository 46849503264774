'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _Login = require('./Login');

var _Login2 = _interopRequireDefault(_Login);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ReactDOM = require('react-dom');

var routeParser = require('route-parser');

var PropTypes = _react2.default.PropTypes;

module.exports = function editGenerator() {
    var selfModule = this;
    var selfProps = this.props;

    //# Dependencies
    var dependencies = selfModule.getDependencies();
    var selfSelector = dependencies.selfSelector,
        selfActions = dependencies.selfActions,
        feSettingsSelector = dependencies.feSettingsSelector;

    //# Constants

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);
        var feSettings = feSettingsSelector(state);

        var location__query__next_page = _lodash2.default.get(ownProps, 'location.query.next_page') || '/';
        var location__hash__next_page = _lodash2.default.get(ownProps, 'location.hash');
        var nextPageArg = location__query__next_page ? decodeURIComponent(location__query__next_page) : location__query__next_page;
        // if next page is index, fall back to default
        var nextPage = nextPageArg && nextPageArg != '/index.html' ? _lodash2.default.compact([nextPageArg, location__hash__next_page]).join('') : undefined;

        var location__query__error = _lodash2.default.get(ownProps, 'location.query.error');
        var error = void 0;
        if (_lodash2.default.get(selfState, 'errorPayload.message')) {
            error = selfState.errorPayload.message;
        } else if (location__query__error) {
            error = decodeURIComponent(location__query__error);
        }

        var mappedProps = _extends({}, selfProps, selfState, {
            clientName: feSettings.CLIENT_NAME,
            rubyBranding: feSettings.RUBY_BRANDING,
            rubyContributors: feSettings.RUBY_CONTRIBUTORS,
            error: {
                message: error
            },
            nextPage: nextPage,
            feSettings: feSettings
        });
        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfActions, dispatch)
        };
    }

    function mergeProps(stateProps, dispatchProps, ownProps) {
        return _extends({}, ownProps, stateProps, dispatchProps, {
            //# setup the delegate callbacks here whcih calls on dispatchProps.actions...
            delegateSubmit: function delegateSubmit(options) {
                dispatchProps.actions.validateBeforeSubmitting(stateProps.nextPage, options);
                return;
            },
            delegateForgotPasswordSubmit: function delegateForgotPasswordSubmit(options) {
                //dispatchProps.actions.submitForgotPassword();
                dispatchProps.actions.validateBeforeSubmittingForgotPassword(options);
                return;
            },
            delegateForgotPasswordClick: function delegateForgotPasswordClick(evt) {
                dispatchProps.actions.reset();
                dispatchProps.actions.switchToForgotPassword();
                return;
            },
            delegateBack: function delegateBack(evt) {
                dispatchProps.actions.reset();
                return;
            }
        });
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(_Login2.default);
};