'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; /* jshint -W138 */


var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//# NOTE: default state is provided by the RubyComponent (via getInitialState())
//# because it has to account for the defaultState of the mixins
module.exports = function (state, action) {
    var _getAction = this.getAction(),
        TYPES = _getAction.TYPES;

    var type = action.type,
        payload = action.payload;


    switch (type) {
        case TYPES.UPDATE_DISPLAY_VALUE_BY_HASH:
        case TYPES.SELECT_ITEM:
            return _extends({}, state, payload || {});
        case TYPES.CLEAR_SELECTED_ITEM:
            return _extends({}, state, {
                selectedValue: null
            });
        case TYPES.REPLACE_ITEM:
            return replaceItemReducer.call(this, state, action);
        default:
            return state;
    }
};

function replaceItemReducer(state, action) {
    var currentFieldValue = this.getFieldValue();

    var selfKey = this.key();

    var _action$payload = action.payload,
        existingItem = _action$payload.existingItem,
        newItem = _action$payload.newItem;


    var existingItemsByHash = _lodash2.default.keyBy(this.hydratedItems(currentFieldValue), '_hash');
    var hydratedExistingItem = this.hydratedItem(existingItem);
    var foundExistingItem = existingItemsByHash[hydratedExistingItem._hash];

    if (!foundExistingItem) {
        console.warn('[replaceItemReducer()] Could not find existing item:', hydratedExistingItem);
        return state;
    }

    var newValue = currentFieldValue.slice(0);
    newValue[foundExistingItem._index] = newItem;

    return _extends({}, state, {
        fields: _extends({}, state.fields, _defineProperty({}, selfKey, _extends({}, state.fields[selfKey], {
            value: newValue
        })))
    });
}