'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _NoResults = require('@rubyapps/ruby-react-components/src/client/view/NoResults');

var _NoResults2 = _interopRequireDefault(_NoResults);

var _noResults = require('./no-results.cssModule');

var _noResults2 = _interopRequireDefault(_noResults);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var NoResults = function (_React$Component) {
    _inherits(NoResults, _React$Component);

    function NoResults() {
        _classCallCheck(this, NoResults);

        return _possibleConstructorReturn(this, (NoResults.__proto__ || Object.getPrototypeOf(NoResults)).apply(this, arguments));
    }

    _createClass(NoResults, [{
        key: 'render',
        value: function render() {
            return _react2.default.createElement(
                'div',
                { className: _noResults2.default.noResults, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'no-results'
                },
                _react2.default.createElement(
                    'div',
                    {
                        style: {
                            opacity: this.props.loading ? 0 : 1
                        },
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'no-results'
                    },
                    _react2.default.createElement(_NoResults2.default, {
                        header: this.props.header,
                        details: this.props.details,
                        'data-codecept-selector-node': 'NoResultsGraphic',
                        'data-codecept-selector-file': 'no-results'
                    })
                )
            );
        }
    }]);

    return NoResults;
}(_react2.default.Component);

NoResults.defaultProps = {
    header: "No results found",
    details: "Please update your search or add some items."
};

exports.default = NoResults;