'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styleHelper = require('./utils/styleHelper');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Table = function (_React$Component) {
  _inherits(Table, _React$Component);

  function Table(props, context) {
    _classCallCheck(this, Table);

    var _this = _possibleConstructorReturn(this, (Table.__proto__ || Object.getPrototypeOf(Table)).call(this, props, context));

    _this.state = {};
    return _this;
  }

  _createClass(Table, [{
    key: 'getTableBodySection',
    value: function getTableBodySection() {
      var additionalProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (this.props.data.length < 1) {
        return _react2.default.createElement(
          'tbody',
          {
            'data-codecept-selector-node': 'tbody',
            'data-codecept-selector-file': 'table'
          },
          _react2.default.createElement(
            'tr',
            {
              'data-codecept-selector-node': 'tr',
              'data-codecept-selector-file': 'table'
            },
            _react2.default.createElement(
              'td',
              {
                'data-codecept-selector-node': 'td',
                'data-codecept-selector-file': 'table'
              },
              _react2.default.createElement(this.props.components.NoResults, {
                components: this.props.components,
                styles: this.props.styles,
                settings: this.props.settings,
                events: this.props.events, 'data-codecept-selector-file': 'table'
              })
            )
          )
        );
      }

      return _react2.default.createElement(this.props.components.TableBody, _extends({}, additionalProps, this.props, {
        'data-codecept-selector-file': 'table'
      }));
    }
  }, {
    key: 'getColumns',
    value: function getColumns() {
      var columnProperties = this.props.renderProperties.columnProperties;

      if (this.props.data.length > 0) {
        //# NOTE: do not return data keys. the order is NOT guaranteed
        //return Object.keys(this.props.data[0]);
      }

      if (columnProperties) {
        var orderedColumns = Object.keys(this.props.renderProperties.columnProperties).sort(function (first, second) {
          var firstColumn = columnProperties[first];
          var secondColumn = columnProperties[second];

          //deal with columns without order properties
          if (!firstColumn.hasOwnProperty('order') && !secondColumn.hasOwnProperty('order')) {
            return 0;
          }
          if (firstColumn.hasOwnProperty('order') && !secondColumn.hasOwnProperty('order')) {
            return -1;
          }
          if (!firstColumn.hasOwnProperty('order') && secondColumn.hasOwnProperty('order')) {
            return 1;
          }

          //order the columns if they both have an order property
          return firstColumn["order"] - secondColumn["order"];
        });

        return orderedColumns;
      }

      return [];
    }
  }, {
    key: 'getTableHeaderSection',
    value: function getTableHeaderSection() {
      var additionalProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var _additionalProps$colu = additionalProps.columns,
          columns = _additionalProps$colu === undefined ? [] : _additionalProps$colu;


      if (columns.length > 0) {
        return _react2.default.createElement(this.props.components.TableHeading, _extends({}, additionalProps, this.props, {
          'data-codecept-selector-file': 'table'
        }));
      }

      return null;
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          settings = _props.settings,
          styles = _props.styles;

      var columns = this.getColumns();

      var style = styles.getStyle({
        styles: styles.inlineStyles,
        styleName: 'table',
        mergeStyles: settings.useFixedTable && styles.getStyle({
          styles: styles.inlineStyles,
          styleName: 'fixedTable'
        })
      });

      var _getStyleProperties = (0, _styleHelper.getStyleProperties)(this.props, 'table'),
          className = _getStyleProperties.className;
      //translate the definition object to props for Heading / Body


      return _react2.default.createElement(
        'div',
        {
          'data-codecept-selector-node': 'div',
          'data-codecept-selector-file': 'table'
        },
        _react2.default.createElement(
          'table',
          {
            className: className,
            style: style,
            'data-codecept-selector-node': 'table',
            'data-codecept-selector-file': 'table'
          },
          this.getTableHeaderSection({ columns: columns })
        ),
        _react2.default.createElement(
          'div',
          {
            'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'table'
          },
          this.getTableBodySection({ columns: columns })
        )
      );
    }
  }]);

  return Table;
}(_react2.default.Component);

//TODO: enabled the propTypes again
/*
Table.propTypes = {
  children: React.PropTypes.oneOfType([
    React.PropTypes.instanceOf(RowDefinition)
    // React.PropTypes.arrayOf(React.PropTypes.instanceOf(ColumnDefinition))
  ])
}; */

exports.default = Table;