'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = PublishAndDateFormatterGenerator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _friendlyRubyMoment = require('@rubyapps/friendly-ruby-moment');

var _friendlyRubyMoment2 = _interopRequireDefault(_friendlyRubyMoment);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Toggle = require('@rubyapps/ruby-material-ui/Toggle');

var _Toggle2 = _interopRequireDefault(_Toggle);

var _Tooltip = require('material-ui/internal/Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

var _DateFormatterGenerator = require('./DateFormatterGenerator');

var _DateFormatterGenerator2 = _interopRequireDefault(_DateFormatterGenerator);

var _customComponent_styles = require('./customComponent_styles');

var _customComponent_styles2 = _interopRequireDefault(_customComponent_styles);

var _customComponent_styles3 = require('./customComponent_styles.cssModule');

var _customComponent_styles4 = _interopRequireDefault(_customComponent_styles3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var RubyIcons = require('@rubyapps/ruby-icons');

function _getTemplateKeyAndIDFromRowProps(rowProps) {
    var templateKey = _lodash2.default.get(rowProps, ['rowData', 'template_keyword']) || _lodash2.default.get(rowProps, ['rowData', '__metadata', 'template_keyword']);
    var id = _lodash2.default.get(rowProps, ['rowData', 'id']) || _lodash2.default.get(rowProps, ['rowData', '__metadata', 'id']);
    return { templateKey: templateKey, id: id };
}

var darkThemeColor = 'rgb(96, 125, 139)';

/**
 *  options.renderLeft - render function for left icon (where toggle is)
 *  options.renderRight - render function for right icon (adjacent to the scheduled status calendar icon)
 *  options.hideToggle - hide toggle (used by lookups)
 */
function PublishAndDateFormatterGenerator() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var parentProps = arguments[1];

    var DateFormatter = (0, _DateFormatterGenerator2.default)(options);

    var _options$renderLeft = options.renderLeft,
        renderLeft = _options$renderLeft === undefined ? function () {} : _options$renderLeft,
        _options$renderRight = options.renderRight,
        renderRight = _options$renderRight === undefined ? function () {} : _options$renderRight,
        hideToggle = options.hideToggle;

    //return function PublishAndDateFormatter(props) {

    return _react2.default.createClass({
        getInitialState: function getInitialState() {
            return {
                isToggled: null
            };
        },
        propTypes: {
            calendarIconPath: PropTypes.string
        },
        getDefaultProps: function getDefaultProps() {
            return {
                calendarIconPath: 'ruby/icons/content/edit-schedule'
            };
        },
        contextTypes: {
            muiTheme: PropTypes.object
        },
        componentDidMount: function componentDidMount() {
            var _this = this;

            return;
            setTimeout(function () {
                _this.setState({
                    isToggled: true
                });
            }, 5000);
        },
        renderCalendar: function renderCalendar() {
            var _this2 = this;

            var flatRowData = _extends({}, _lodash2.default.omit(this.props.rowData, ['__metadata']), _lodash2.default.get(this, 'props.rowData.__metadata'));
            var start_timestamp = flatRowData.start_timestamp,
                end_timestamp = flatRowData.end_timestamp;


            var Icon = RubyIcons.getIconClassForPath(this.props.calendarIconPath);

            var hasStart = _lodash2.default.get(start_timestamp, 'datetime');
            var hasEnd = _lodash2.default.get(end_timestamp, 'datetime');

            if (hasStart || hasEnd) {
                var _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5;

                var ScheduledStatusTooltip = _react2.default.createElement(
                    'div',
                    (_React$createElement2 = {
                        style: {
                            fontSize: 12,
                            textAlign: 'right'
                        },
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'PublishAndDateFormatterGenerator'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _React$createElement2),
                    [{
                        label: 'Scheduled to Publish',
                        key: 'start_timestamp'
                    }, {
                        label: 'Scheduled to Unpublish',
                        key: 'end_timestamp'
                    }].map(function (_ref) {
                        var _React$createElement;

                        var label = _ref.label,
                            key = _ref.key;

                        var timestamp = _lodash2.default.get(flatRowData, key);
                        var timeZone = _lodash2.default.get(timestamp, 'timeZone');
                        var date = _lodash2.default.get(timestamp, 'datetime');
                        var formattedDate = date && timeZone && _friendlyRubyMoment2.default.tz(date, timeZone).format('MMM D, YYYY hh:mm a zz');
                        return formattedDate && _react2.default.createElement(
                            'div',
                            (_React$createElement = {
                                'data-codecept-selector-node': 'div',
                                'data-codecept-selector-file': 'PublishAndDateFormatterGenerator'
                            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _React$createElement),
                            label,
                            ': ',
                            formattedDate
                        );
                    })
                );

                return _react2.default.createElement(
                    'div',
                    (_React$createElement5 = {
                        style: {
                            position: 'relative',
                            display: 'flex',
                            marginLeft: 7,
                            paddingTop: 12,
                            paddingBottom: 12
                        },
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'PublishAndDateFormatterGenerator'
                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _React$createElement5),
                    _react2.default.createElement(Icon, (_React$createElement3 = {
                        style: {
                            color: darkThemeColor,
                            width: 22,
                            height: 22
                        },
                        onMouseEnter: function onMouseEnter() {
                            _this2.setState({ hoveredTooltip: true });
                        },
                        onMouseLeave: function onMouseLeave() {
                            _this2.setState({ hoveredTooltip: false });
                        },
                        'data-codecept-selector-node': 'Icon',
                        'data-codecept-selector-file': 'PublishAndDateFormatterGenerator'
                    }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Icon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Icon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _React$createElement3)),
                    _react2.default.createElement(_Tooltip2.default, (_React$createElement4 = {
                        show: this.state && this.state.hoveredTooltip,
                        label: ScheduledStatusTooltip,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                        style: {
                            top: hasStart && hasEnd ? -30 : -8,
                            pointerEvents: 'none'
                        },
                        'data-codecept-selector-node': 'Tooltip',
                        'data-codecept-selector-file': 'PublishAndDateFormatterGenerator'
                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'Tooltip'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'Tooltip'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _React$createElement4))
                );
            }
        },
        render: function render() {
            var _React$createElement6,
                _extends2,
                _this3 = this,
                _extends3,
                _React$createElement7;

            var props = this.props;

            var activeState = _lodash2.default.get(props.rowData, ['__metadata', 'status'], _lodash2.default.get(props.rowData, 'status', 'inactive'));

            var disabledForCMS = _lodash2.default.get(props, 'rowData.__metadata.__disabledForCMS');

            var item = _getTemplateKeyAndIDFromRowProps(props);
            var disabledUnpublish = _lodash2.default.get(options, ['disableUnpublish_byTemplateKey', item.templateKey]);
            var isActive = activeState == 'active' ? true : false;
            var labelColor = this.context.muiTheme.toggle.labelColor;


            var labelStyle = {
                color: disabledForCMS ? '#a9a7a7' : labelColor
            };

            var _renderedLeft = renderLeft(props);
            var renderedLeft = _renderedLeft || hideToggle ? _react2.default.createElement(
                'span',
                (_React$createElement6 = { className: _customComponent_styles4.default.LeftIconWrapper + ' ' + (hideToggle ? '' : _customComponent_styles4.default.LeftIconWrapper__toggleWidth), 'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'PublishAndDateFormatterGenerator'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _React$createElement6),
                _renderedLeft
            ) : null;
            var renderedRight = renderRight(props);

            var canEdit = parentProps.getUserHasPerm_forItem ? parentProps.getUserHasPerm_forItem('edit', item) : true; //# assume `true` for backwards compatibility with parentProps that don't define getUserHasPerm_forItem
            var canPublish = parentProps.canUserPublish ? parentProps.canUserPublish() : true; //# assume `true` for backwards compatibility with parentProps that don't define canUserPublish

            var DateFormatter__props = _extends({}, props, renderedLeft || hideToggle ? { className: _customComponent_styles4.default.DateFormatterLabel } : {});
            var renderedLabel = _react2.default.createElement(DateFormatter, _extends({}, DateFormatter__props, (_extends2 = {
                'data-codecept-selector-node': 'DateFormatter',
                'data-codecept-selector-file': 'PublishAndDateFormatterGenerator'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'DateFormatter'), _defineProperty(_extends2, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _defineProperty(_extends2, 'data-codecept-selector-node', 'DateFormatter'), _defineProperty(_extends2, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _extends2)));

            //# do not show toggle if there's left icon (typically a page lock)
            var renderedMain = renderedLeft || hideToggle ? renderedLabel : _react2.default.createElement(_Toggle2.default, _extends({
                defaultToggled: isActive //# make it uncontrolled
                , disabled: disabledForCMS || disabledUnpublish || options.disableToggles || !canEdit || !canPublish,
                onToggle: function onToggle(event, toggled) {
                    parentProps.actions.setItemStatus_withActive(item, toggled, [function (response) {
                        _this3.setState({
                            isToggled: toggled
                        }); //# keep localState updated until we can refresh
                        props.events.refreshCurrentPage(); //# we probably don't want to this to prevent flash
                        options && options.successCallback && options.successCallback();
                    }, function (err) {
                        //# failed
                        //# NOTE: don't know if we want to keep it uncontrolled
                        //# or rely on the list refreshing
                        _this3.theToggle.setToggled(!toggled);
                    }]);
                },
                label: renderedLabel,
                labelPosition: 'right',
                labelStyle: labelStyle,
                ref: function ref(theToggle) {
                    if (theToggle) {
                        _this3.theToggle = theToggle;
                    }
                }
            }, _customComponent_styles2.default.Toggle, (_extends3 = {
                'data-codecept-selector-node': 'Toggle',
                'data-codecept-selector-file': 'PublishAndDateFormatterGenerator'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_extends3, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_extends3, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _extends3)));
            return _react2.default.createElement(
                'div',
                (_React$createElement7 = {
                    style: {
                        display: 'flex',
                        alignItems: 'center'
                    },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'PublishAndDateFormatterGenerator'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'PublishAndDateFormatterGenerator'), _React$createElement7),
                renderedLeft,
                renderedMain,
                renderedRight,
                this.renderCalendar()
            );
        }
    });
}