'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _close = require('material-ui/svg-icons/navigation/close');

var _close2 = _interopRequireDefault(_close);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var PropTypes = React.PropTypes;

//# Icons


var ChildActions = React.createClass({
    displayName: 'ChildActions',

    propTypes: {
        id: PropTypes.string,
        actions: PropTypes.object,
        disabled: PropTypes.bool
    },
    _handleDelete: function _handleDelete(event) {
        event.stopPropagation();
        this.props.actions.deleteItemWithId(this.props.id);
        return false;
    },
    render: function render() {
        var _React$createElement, _React$createElement2;

        return React.createElement(
            _IconButton2.default,
            (_React$createElement2 = {
                disabled: this.props.disabled,
                tooltip: 'Delete',
                tooltipPosition: 'top-center',
                tooltipStyles: {
                    top: 6
                },
                onTouchTap: this._handleDelete,
                'data-codecept-selector-node': 'IconButton',
                'data-codecept-selector-file': 'ChildActions'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ChildActions'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ChildActions'), _React$createElement2),
            React.createElement(_close2.default, (_React$createElement = {
                'data-codecept-selector-node': 'CloseIcon',
                'data-codecept-selector-file': 'ChildActions'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'CloseIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ChildActions'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'CloseIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ChildActions'), _React$createElement))
        );
    }
});

exports.default = ChildActions;