'use strict';

var _matchConstants = require('../matchConstants');

var _matchConstants2 = _interopRequireDefault(_matchConstants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

/*
   {
       "id": 1,
       "status": "active",
       "context": "matter",
       "name": "Saved Search A",
       "query": "",
       "filterTags": [{
           "fieldSpec": {
               "key": "name",
               "label": "Name",
               "dataPath": "name",
               "refKey": "module_a" //# the same refKey as returned by RubySchema.pickProperties(),
               "foundIn__templateKey": "matter",
               "nestingOf": undefined,
               "parentDataPath": undefined
               "parentTemplateKey": undefined
           },
           "matchSpec": {
               "text": "contains",
               "value": "contains"
           },
           "searchSpec": {
               "text": "rubytest",
               "value": "rubytest"
           }
       }],
       "created_timestamp": "2017-07-12T00:00:00.000Z",
       "last_modified_timestamp": "2017-08-03T02:20:14.688Z"
   }
*/
/*
   {
       [f]: {
           simpleqs: searchText
       } : {
       [f]: {
           like: searchText
           , options: {
               operator: 'and'
               , fuzziness: 'AUTO'
           }
       }
   };
*/

//https://loopback.io/doc/en/lb2/Where-filter.html#inq
function _filterSpecForDataPath_matching_value(dataPath, matching, value, refKey) {
    var filter = void 0;

    switch (matching) {
        case _matchConstants2.default.IS: //# NOTE: keeping this here for backwards compatiblity
        //# but this has been DEPRECATED in favor of 'CONTAINS'
        case _matchConstants2.default.CONTAINS:
        case _matchConstants2.default.IS_EQUAL_TO:
            //# Number
            filter = _defineProperty({}, dataPath, value);
            break;
        case _matchConstants2.default.CONTAINS_ALL_OF:
            var andFilterArray = _.chain(value).castArray().map(function (val) {
                return _defineProperty({}, dataPath, val);
            }).value();

            filter = {
                and: andFilterArray
            };
            break;
        case _matchConstants2.default.IS_ONE_OF:
            var valueAsArray = value ? _.castArray(value) : [];
            if (valueAsArray.length == 1) {
                filter = _defineProperty({}, dataPath, valueAsArray[0]);
            } else {
                filter = _defineProperty({}, dataPath, {
                    inq: valueAsArray
                });
            }
            break;

        case _matchConstants2.default.IS_NOT:
        case _matchConstants2.default.DOES_NOT_CONTAIN:
        case _matchConstants2.default.IS_NOT_EQUAL_TO:
            //# Number
            filter = _defineProperty({}, dataPath, {
                neq: value
            });
            break;

        case _matchConstants2.default.IS_EMPTY:
            filter = _defineProperty({}, dataPath, null);
            break;
        case _matchConstants2.default.IS_NOT_EMPTY:
            filter = _defineProperty({}, dataPath, {
                neq: null
            });
            break;

        //# Array
        case _matchConstants2.default.IS_EMPTY__ARRAY:
            filter = {
                or: [_defineProperty({}, dataPath, null), _defineProperty({}, dataPath, [])]
            };
            break;
        case _matchConstants2.default.IS_NOT_EMPTY__ARRAY:
            filter = {
                and: [_defineProperty({}, dataPath, {
                    neq: null
                }), _defineProperty({}, dataPath, {
                    neq: []
                })]
            };
            break;

        //# Boolean
        case _matchConstants2.default.IS_TRUE:
            filter = _defineProperty({}, dataPath, true);
            break;
        case _matchConstants2.default.IS_FALSE:
            filter = {
                or: [_defineProperty({}, dataPath, false), _defineProperty({}, dataPath, null)]
            };
            break;

        //# Date
        case _matchConstants2.default.IS_EQUAL_TO__DATE:
            if (_.isObject(value)) {
                if (value.hasOwnProperty('datetime')) {
                    //# doesn't need to match both timeZone and datetime
                    //# because the datetime is normalized with respect to UTC

                    filter = _defineProperty({}, dataPath + '.datetime', value.datetime);
                } else if (value.hasOwnProperty('date')) {

                    filter = _defineProperty({}, dataPath + '.date', value.date);
                } else if (value.hasOwnProperty('time')) {
                    //# need to match both timeZone and time
                    //# this cannot be normalized because DST is different
                    //# based on time of year

                    filter = {
                        and: [_defineProperty({}, dataPath + '.time', value.time), _defineProperty({}, dataPath + '.timeZone', value.timeZone)]
                    };
                }
            } else {
                filter = _defineProperty({}, dataPath, value);
            }
            break;
        case _matchConstants2.default.IS_NOT_EQUAL_TO__DATE:
            if (_.isObject(value)) {
                if (value.hasOwnProperty('datetime')) {
                    filter = _defineProperty({}, dataPath + '.datetime', {
                        neq: value.datetime
                    });
                } else if (value.hasOwnProperty('date')) {
                    filter = _defineProperty({}, dataPath + '.date', {
                        neq: value.date
                    });
                } else if (value.hasOwnProperty('time')) {
                    filter = {
                        and: [_defineProperty({}, dataPath + '.time', {
                            neq: value.time
                        }), _defineProperty({}, dataPath + '.timeZone', {
                            neq: value.timeZone
                        })]
                    };
                }
            } else {
                filter = _defineProperty({}, dataPath, {
                    neq: value
                });
            }
            break;
        case _matchConstants2.default.IS_AFTER__DATE:
            if (_.isObject(value)) {
                if (value.hasOwnProperty('datetime')) {
                    filter = _defineProperty({}, dataPath + '.datetime', {
                        gt: value.datetime
                    });
                } else if (value.hasOwnProperty('date')) {
                    filter = _defineProperty({}, dataPath + '.date', {
                        gt: value.date
                    });
                } else if (value.hasOwnProperty('time')) {
                    filter = {
                        and: [_defineProperty({}, dataPath + '.time', {
                            gt: value.time
                        }), _defineProperty({}, dataPath + '.timeZone', value.timeZone)]
                    };
                }
            } else {
                filter = _defineProperty({}, dataPath, {
                    gt: value
                });
            }
            break;
        case _matchConstants2.default.IS_BEFORE__DATE:
            if (_.isObject(value)) {
                if (value.hasOwnProperty('datetime')) {
                    filter = _defineProperty({}, dataPath + '.datetime', {
                        lt: value.datetime
                    });
                } else if (value.hasOwnProperty('date')) {
                    filter = _defineProperty({}, dataPath + '.date', {
                        lt: value.date
                    });
                } else if (value.hasOwnProperty('time')) {
                    filter = {
                        and: [_defineProperty({}, dataPath + '.time', {
                            lt: value.time
                        }), _defineProperty({}, dataPath + '.timeZone', value.timeZone)]
                    };
                }
            } else {
                filter = _defineProperty({}, dataPath, {
                    lt: value
                });
            }
            break;

        //# Number
        case _matchConstants2.default.IS_GREATER_THAN:
            //# Number
            filter = _defineProperty({}, dataPath, {
                gt: value
            });
            break;
        case _matchConstants2.default.IS_LESS_THAN:
            //# Number
            filter = _defineProperty({}, dataPath, {
                lt: value
            });
            break;
        case _matchConstants2.default.IS_GREATER_THAN_OR_EQUAL_TO:
            //# Number
            filter = _defineProperty({}, dataPath, {
                gte: value
            });
            break;
        case _matchConstants2.default.IS_LESS_THAN_OR_EQUAL_TO:
            //# Number
            filter = _defineProperty({}, dataPath, {
                lte: value
            });
            break;

        case _matchConstants2.default.IS_EMPTY__NUMBER:
        case _matchConstants2.default.IS_EMPTY__STRING:
            //# String
            filter = {
                or: [_defineProperty({}, dataPath, null), _defineProperty({}, dataPath, '')]
            };
            break;
        case _matchConstants2.default.IS_NOT_EMPTY__NUMBER:
        case _matchConstants2.default.IS_NOT_EMPTY__STRING:
            //# String
            filter = {
                and: [_defineProperty({}, dataPath, {
                    neq: null
                }), _defineProperty({}, dataPath, {
                    neq: ''
                })]
            };
            break;

        //# String
        case _matchConstants2.default.CONTAINS__STRING:
            filter = _defineProperty({}, dataPath, {
                //like: value
                simpleqs: value
                /* //# allow connector-cb-es to handle defaulting the operator
                , options: {
                    operator: 'and'
                    //, fieldSuffix: '.search'
                }
                */
            });
            break;
        case _matchConstants2.default.DOES_NOT_CONTAIN__STRING:
            filter = _defineProperty({}, dataPath, {
                //nlike: value
                nsimpleqs: value
            });
            break;

    }

    return filter;
}

/*
 *  filterTags_byMatch = {
 *      "is_greater_than": [
 *          {... filter tag ...}
 *          , {... filter tag ...}
 *      ]
 *      , "is_less_than": [
 *          {... filter tag ...}
 *          , {... filter tag ...}
 *      ]
 *  }
 */
function _reduceFilterTagsByMatch_forDataPath(filterTags_byMatch, dataPath) {
    return _.reduce(filterTags_byMatch, function (collector, filterTagCollection, match) {
        var dataPathForLB = dataPath; //# return raw dataPath; do not strip out wildcard

        var filters = filterTagCollection.map(function (filterTag) {
            var matchSpec__value = filterTag.matchSpec.value;
            var searchSpec__value = filterTag.searchSpec.value;
            var fieldSpec__refKey = filterTag.fieldSpec.refKey;

            return _filterSpecForDataPath_matching_value(dataPathForLB, matchSpec__value, searchSpec__value, fieldSpec__refKey);
        });

        if (filters.length > 1) {
            //# TODO Currently `and` operator would only be used when
            //# `match === CONTAINS_ALL_OF`. In the future, this could
            //# change, so we'll need a better way to determine what
            //# value `filtersWrapperOperator` should be
            var filtersWrapperOperator = match === _matchConstants2.default.CONTAINS_ALL_OF ? 'and' : 'or';

            collector.push(_defineProperty({}, filtersWrapperOperator, filters));
        } else {
            collector.push(filters[0]);
        }

        return collector;
    }, []);
}

/*
 *  filterTags_byDataPath_byMatch = {
 *      "dataPath": {
 *          "is": [
 *              {... filter tag ...}
 *              , {... filter tag ...}
 *          ]
 *      }
 *  }
 */

function filterTagsToWhereFilter(filterTagsTree) {
    var nestedFilterTagNodes = [];
    var filterTags_byDataPath_byMatch = filterTagsTree.reduce(function (collector, filterTagTreeNode) {
        var _filterTagTreeNode$ch = filterTagTreeNode.children,
            children = _filterTagTreeNode$ch === undefined ? [] : _filterTagTreeNode$ch;

        if (!_.isEmpty(children)) {
            nestedFilterTagNodes.push(filterTagTreeNode);
            return collector;
        }
        var filterTag__dataPath = dataPathExcludingParentPathFromParentTemplateKey_fromFilterTag(filterTagTreeNode);
        var filterTag__match = filterTagTreeNode.matchSpec.value;
        var pathArr = [filterTag__dataPath, filterTag__match];
        _.set(collector, pathArr, _.get(collector, pathArr, []).concat(filterTagTreeNode));

        return collector;
    }, {});

    var whereFilterArr = _.reduce(filterTags_byDataPath_byMatch, function (collector, filterTags_byMatch, dataPath) {
        return collector.concat(_reduceFilterTagsByMatch_forDataPath(filterTags_byMatch, dataPath));
    }, []).concat(_.flatMap(nestedFilterTagNodes, function (nestedFilterTagNode) {
        var _nestedFilterTagNode$ = nestedFilterTagNode.children,
            children = _nestedFilterTagNode$ === undefined ? [] : _nestedFilterTagNode$;

        var childWithNestingOfProperty = children.find(function (child) {
            return _.get(child, 'fieldSpec.nestingOf');
        });
        var nestingField = _.get(childWithNestingOfProperty, 'fieldSpec.nestingOf');
        return _defineProperty({}, nestingField, {
            nested: { where: filterTagsToWhereFilter(children) }
        });
    }));

    var finalWhereFilter = whereFilterArr.length > 1 ? {
        and: whereFilterArr
    } : whereFilterArr[0];

    return finalWhereFilter;
}

function dataPathExcludingParentPathFromParentTemplateKey_fromFilterTag(filterTag) {
    var dataPath = _.get(filterTag, 'fieldSpec.dataPath');
    var dataPathPartials = dataPath.split('.');
    var parentTemplateKey = _.get(filterTag, 'fieldSpec.parentTemplateKey');

    if (!parentTemplateKey) {
        return dataPath;
    }
    var parentDataPath = _.get(filterTag, 'fieldSpec.parentDataPath');
    var parentDataPathPartials = parentDataPath ? parentDataPath.split('.') : [];

    var dataPathArrayWithoutParentPathPartials = dataPathPartials.reduce(function (acc, partial, idx) {
        return dataPathPartials[idx] !== parentDataPathPartials[idx] ? acc.concat(partial) : acc;
    }, []);

    return dataPathArrayWithoutParentPathPartials.join('.');
}

function filterTagsTreeFromFilterTagsAndTemplateKeyword(filterTags, templateKeyword) {
    var filterTagsByTemplate = _.groupBy(filterTags, function (filterTag) {
        return _.get(filterTag, 'fieldSpec.foundIn__templateKey');
    });

    var filterTagsForTemplate = filterTagsByTemplate[templateKeyword] || [];
    var filterTagsForUndefined = filterTagsByTemplate[undefined] || []; //# foundIn__templateKey might be undefined

    //# Only process nestedFilterTags if a templateKeyword is provided
    //# otherwise, we assume tags are not nested
    var nestedFilterTags = templateKeyword ? filterTags.filter(function (filterTag) {
        var parentTemplateKey = _.get(filterTag, 'fieldSpec.parentTemplateKey');
        return parentTemplateKey === templateKeyword;
    }) : [];

    var nestedTemplateKeywords = _.chain(nestedFilterTags).map(function (nestedFilterTag) {
        return _.get(nestedFilterTag, 'fieldSpec.foundIn__templateKey');
    }).uniq().value();

    var filterTagsTree = [].concat(filterTagsForTemplate, filterTagsForUndefined, nestedTemplateKeywords.reduce(function (acc, nestedTemplateKeyword) {
        return acc.concat({
            children: filterTagsTreeFromFilterTagsAndTemplateKeyword(filterTags, nestedTemplateKeyword)
        });
    }, []));

    return filterTagsTree;
}

function filterTagsToWhereFilterUsingTemplateKeyword(filterTags, templateKeyword) {
    //# NOTE: if templateKeyword is undefined, assume it's wildcard
    //# since only listers like quick-find would pass an undefined

    var filterTagsTree = templateKeyword ? filterTagsTreeFromFilterTagsAndTemplateKeyword(filterTags, templateKeyword) : filterTags;

    var whereFilter = filterTagsToWhereFilter(filterTagsTree);

    return whereFilter;
}

module.exports = {
    filterTagsToWhereFilter: filterTagsToWhereFilter,
    filterTagsToWhereFilterUsingTemplateKeyword: filterTagsToWhereFilterUsingTemplateKeyword,
    filterTagsTreeFromFilterTagsAndTemplateKeyword: filterTagsTreeFromFilterTagsAndTemplateKeyword
};