'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _SitemapConnector = require('./reactComponents/SitemapConnector');

var _SitemapConnector2 = _interopRequireDefault(_SitemapConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;
var rubyWords = require('@rubyapps/ruby-words');

var CONSTANTS = require('../common/constants');
var sitemapUtils = require('../common/utils');
var componentName = CONSTANTS.COMPONENT_NAME;

var action = require('./action');

var RubyComponentNewPageDialog__CONSTANTS = require('@rubyapps/ruby-component-new-page-dialog/src/common/constants');
var RubyComponentAlterTemplateDialog__CONSTANTS = require('@rubyapps/ruby-component-alter-template-dialog/src/common/constants');
var RubyComponentConfirmDialog__CONSTANTS = require('@rubyapps/ruby-component-confirm-dialog/src/common/constants');
var RubyComponentContentSitemapPage__CONSTANTS = require('@rubyapps/ruby-component-sitemap-page/src/common/constants');
var RubyComponentForms__CONSTANTS = require('@rubyapps/ruby-component-forms/src/common/constants');
var RubyComponentFESettings__CONSTANTS = require('@rubyapps/ruby-component-frontend-settings/src/common/constants');
var RubyComponentCurrentUser__CONSTANTS = require('@rubyapps/ruby-component-current-user/src/common/constants');
var FieldForm__CONSTANTS = require('@rubyapps/ruby-component-field-form/src/common/constants');

var _require = require('@rubyapps/griddle'),
    griddleComponentsByType = _require.customComponentsByType;

var accessControlMixin = require('@rubyapps/ruby-component-mixin-access-control');
var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var listPageMixin = require('@rubyapps/ruby-component-mixin-list-page');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');

var _require2 = require('../../../ruby-component-material-edit-page/src/common/constants'),
    EditPageName = _require2.COMPONENT_NAME;

var TEMPLATE_CONSTANTS = require('../../../ruby-component-plugin-template-editor/src/common/constants');

var structuredDataTemplateType = 'structured_data_template';

var RCSitemap = RubyComponent.createClass(_extends({
    mixins: [accessControlMixin, listPageMixin, baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {
        apiKey: PropTypes.string,
        confirmDialogID: PropTypes.string,
        currentUserID: PropTypes.string,
        editPageID: PropTypes.string,
        enableAddSitemapPage: PropTypes.bool,
        enableAddStructuredDataPage: PropTypes.bool,
        feSettingsID: PropTypes.string,
        formsID: PropTypes.string,
        listApiEndpoint: PropTypes.string,
        newPageDialogID: PropTypes.string,
        sitemapPageID: PropTypes.string,
        openNewPageDialog_options: PropTypes.object,
        sdTemplateOrder: PropTypes.arrayOf(PropTypes.string),
        allowColumnSelection: PropTypes.bool,
        templateTypes: PropTypes.arrayOf(PropTypes.oneOf(TEMPLATE_CONSTANTS.TEMPLATE_TYPE_VALUES)),
        listerConfig: PropTypes.shape({
            hierarchical: PropTypes.bool,
            optionsColumnProps: PropTypes.shape({
                width: PropTypes.number
            }),
            columns: PropTypes.arrayOf(PropTypes.shape({
                displayName: PropTypes.string,
                key: PropTypes.string,
                type: PropTypes.oneOf(Object.keys(griddleComponentsByType)),
                width: PropTypes.number,
                minWidth: PropTypes.number,
                resizable: PropTypes.bool,
                sortable: PropTypes.bool
            })),
            excludeFromColumnSelection: PropTypes.bool,
            excludeFromFilterSelection: PropTypes.bool
        })
    },
    action: action,
    getDefaultProps: function getDefaultProps() {
        return {
            apiKey: 'content',
            confirmDialogID: RubyComponentConfirmDialog__CONSTANTS.COMPONENT_NAME,
            currentUserID: RubyComponentCurrentUser__CONSTANTS.COMPONENT_NAME,
            editPageID: EditPageName,
            enableAddSitemapPage: true,
            enableAddStructuredDataPage: true,
            feSettingsID: RubyComponentFESettings__CONSTANTS.COMPONENT_NAME,
            formsID: RubyComponentForms__CONSTANTS.COMPONENT_NAME,
            listApiEndpoint: 'content/hydrate-sitemap',
            newPageDialogID: RubyComponentNewPageDialog__CONSTANTS.COMPONENT_NAME,
            sitemapPageID: RubyComponentContentSitemapPage__CONSTANTS.COMPONENT_NAME,
            alterTemplateDialogID: RubyComponentAlterTemplateDialog__CONSTANTS.COMPONENT_NAME,
            openNewPageDialog_options: {},
            sdTemplateOrder: ['attorney'],
            allowColumnSelection: true,
            listerConfig: {
                excludeFromColumnSelection: true,
                excludeFromFilterSelection: true
            },
            templateTypes: _.values(_.pick(TEMPLATE_CONSTANTS, ['SITEMAP_TEMPLATE'])),
            label: 'Outline'
        };
    },
    componentName: componentName,
    dependencies: function dependencies() {
        var _this = this;

        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();

        var currentUserID = this.props.currentUserID;

        var currentUser = root.findDescendentByID(currentUserID);
        var currentUserSelector = currentUser.getSelector().default;

        var alterTemplateDialogID = this.props.alterTemplateDialogID;
        var alterTemplateDialogComponent = root.findDescendentByID(alterTemplateDialogID);

        var confirmDialogID = this.props.confirmDialogID;
        var confirmDialogComponent = root.findDescendentByID(confirmDialogID);

        var formsID = this.props.formsID;
        var forms = root.findDescendentByID(formsID);
        var formsSelector = forms.getDefaultSelector();

        var feSettingsID = this.props.feSettingsID;
        var feSettingsComponent = root.findDescendentByID(feSettingsID);
        var feSettingsSelector = feSettingsComponent.getDefaultSelector();

        var parentForm = this.findAncestorBy(function (module) {
            return module.componentName == FieldForm__CONSTANTS.COMPONENT_NAME;
        });
        var saveStateToRemoteAction = parentForm.getAction().generators['saveStateToRemote'];

        var saveButtonComponent = parentForm.findDescendentBy(function (n) {
            return n.props.label == 'Save' && n.props.componentName == "Button";
        });

        var newPageDialogID = this.props.newPageDialogID;
        var newPageDialogComponent = root.findDescendentByID(newPageDialogID);

        var routeParent = this.findAncestorBy(function (module) {
            return module.getRouteElement;
        });

        var editPageID = this.props.editPageID;
        var sitemapField_content_editPageId = _.get(routeParent, 'props.editPageID');
        var getEditEntryUrl_forId_withTemplateKeyword = function getEditEntryUrl_forId_withTemplateKeyword(id, templateKeyword) {
            // TODO: When we implement linking, we want to be able to get the URL based on the subsite id and the template keyword
            return root.getUrlForComponent_fromModule_withParams(sitemapField_content_editPageId, root, {
                id: id,
                containerPageId: _.get(routeParent.getState(), 'routeParams.id'),
                template: templateKeyword,
                action: 'edit'
            });
        };

        var proposalEditPageId = routeParent.getID();
        var sdListerPageID = _.get(routeParent, 'props.sdListerPageID');
        var getContentListUrl_forTemplate = function getContentListUrl_forTemplate(template) {
            return sdListerPageID && root.getUrlForComponent_fromModule_withParams(sdListerPageID, _this, { template: template });
        };

        var sitemapPageID = _.get(this, 'props.sitemapPageID');
        var getSitemapUrl = function getSitemapUrl(template) {
            return root.getUrlForComponent_fromModule_withParams(sitemapPageID, _this);
        };

        var editPageTemplateKey = routeParent.getTemplateKey();
        var subsite = routeParent.getActiveSubsiteId();

        return {
            selfSelector: selfSelector,
            routeParent: routeParent,
            proposalEditPageId: proposalEditPageId,
            newPageDialogComponent: newPageDialogComponent,
            newPageDialogActions: newPageDialogComponent.getAction().generators,
            parentForm: parentForm,
            saveButtonComponent: saveButtonComponent,
            saveStateToRemoteAction: saveStateToRemoteAction,
            currentUser: currentUser,
            currentUserSelector: currentUserSelector,
            forms: forms,
            formsSelector: formsSelector,
            feSettingsSelector: feSettingsSelector,
            alterTemplateDialogComponent: alterTemplateDialogComponent,
            alterTemplateDialogActions: alterTemplateDialogComponent.getAction().generators,
            confirmDialogActions: confirmDialogComponent.getAction().generators,
            getEditEntryUrl_forId_withTemplateKeyword: getEditEntryUrl_forId_withTemplateKeyword,
            getContentListUrl_forTemplate: getContentListUrl_forTemplate,
            getSitemapUrl: getSitemapUrl,
            subsite: subsite,
            editPageTemplateKey: editPageTemplateKey
        };
    },
    getTemplateType_fromTemplateKeyword: function getTemplateType_fromTemplateKeyword(templateKeyword) {
        var _getDependencies = this.getDependencies(),
            forms = _getDependencies.forms;

        var templateForm = forms.getState()[templateKeyword];
        var templateType = _.get(templateForm, 'templateType');

        return templateType;
    },
    getReactClass: function getReactClass() {
        return _SitemapConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var SitemapConnector = this.getReactClass();

        return _react2.default.createElement(SitemapConnector, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'SitemapConnector',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'SitemapConnector'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'SitemapConnector'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

    //# == OVERRIDES ==
    , _formValueFromLocalState: function _formValueFromLocalState(selfState, isError, predicateFormatter, entireState) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        var fieldKey = this.props.key;

        var formValue = baseFieldMixin._formValueFromLocalState.apply(this, arguments);
        var cleanedFormValue = formValue;
        if (!isError) {
            cleanedFormValue = sitemapUtils.cleanSitemapFormValue_forFieldKeypathArr(formValue, [fieldKey]);
        }

        return cleanedFormValue;
    }

    //# Utility methods
    , canEditSelfSitemap: function canEditSelfSitemap() {
        var anyReasonsForDisabledSitemap = this.getAnyReasonsForDisabledModifySitemap() || [];
        var canEditSelfSitemap = anyReasonsForDisabledSitemap.length === 0;
        return canEditSelfSitemap;
    },
    getAnyReasonsForDisabledModifySitemap: function getAnyReasonsForDisabledModifySitemap() {
        var hasPermissionToEditTemplateInstance = this.getHasPermToEditTemplateInstance();
        var hasFieldSpecificPermisisons = this.getHasFieldSpecificPermissions();
        var currentPageExists = this.getCurrentPageExists();

        var templateName = this.getTemplateNameForCurrentPage();
        var pluralTemplateName = templateName && rubyWords.inflection.pluralize(templateName);

        return [].concat(!hasPermissionToEditTemplateInstance ? 'You do not have permission to edit ' + pluralTemplateName : [], !hasFieldSpecificPermisisons ? 'You do not have permission to edit the ' + this.props.label : [], !currentPageExists ? 'Please save this page before adding pages to the ' + this.props.label : []);
    },
    getAnyReasonsForDisabledAddEntry: function getAnyReasonsForDisabledAddEntry() {
        var addableTemplates = this.addableTemplateForms();
        return [].concat(this.getAnyReasonsForDisabledModifySitemap(), addableTemplates.length < 1 ? 'You do not have permission to create any pages that can be added to the ' + this.props.label + '.' : []);
    },
    getTemplateNameForCurrentPage: function getTemplateNameForCurrentPage() {
        var _getDependencies2 = this.getDependencies(),
            forms = _getDependencies2.forms,
            routeParent = _getDependencies2.routeParent;

        var templateKey = _.get(routeParent, ['props', 'templateKey']);
        var templateName = _.get(forms.getState(), [templateKey, 'name']);

        return templateName;
    },
    getCurrentPageExists: function getCurrentPageExists() {
        var _getDependencies3 = this.getDependencies(),
            routeParent = _getDependencies3.routeParent;

        var editPageId = _.get(routeParent.getState(), 'routeParams.id');
        var currentPageExists = editPageId != null;
        return currentPageExists;
    },
    getHasFieldSpecificPermissions: function getHasFieldSpecificPermissions() {
        var _this2 = this;

        var _getDependencies4 = this.getDependencies(),
            routeParent = _getDependencies4.routeParent,
            forms = _getDependencies4.forms;

        var fieldPerms = _.get(this, 'props.ruby_permissions', []);

        var hasAllPerms = _.every(fieldPerms, function (keyword) {
            return fieldPropsMixin._doesUserHaveFieldPermission_forComponent(keyword, _this2);
        });

        return hasAllPerms;
    },
    getHasPermToEditTemplateInstance: function getHasPermToEditTemplateInstance() {
        var _getDependencies5 = this.getDependencies(),
            routeParent = _getDependencies5.routeParent,
            forms = _getDependencies5.forms;

        var templateKey = _.get(routeParent, ['props', 'templateKey']);
        var templateId = _.get(forms.getState(), [templateKey, 'id']);
        var editPageId = _.get(routeParent.getState(), 'routeParams.id');

        var hasPermission = this.doesUserHavePermission({
            action: 'edit',
            subsite: true,
            ruby_client: true,
            model: 'content',
            template: templateId,
            id: editPageId
        });

        return hasPermission;
    },
    canAccessContentSitemap: function canAccessContentSitemap() {
        var canAccessContentSitemap = this.doesUserHavePermission({
            action: '*',
            ruby_client: true,
            keyword: 'content_sitemap_section'
        });
        return canAccessContentSitemap;
    },
    firstStructuredDataKeyThatIsAccessible: function firstStructuredDataKeyThatIsAccessible() {
        var _this3 = this;

        var _getDependencies6 = this.getDependencies(),
            forms = _getDependencies6.forms;

        var mainContentSubsite = this.getRubyClientInfo_forRoute('/app/content').subsite;

        return _.flowRight(function (template) {
            return template && template.key;
        }, _.first, function (definedTemplates) {
            return definedTemplates.filter(function (template) {
                return _this3.doesUserHavePermission({
                    action: 'get',
                    subsite: mainContentSubsite,
                    ruby_client: true,
                    model: 'content',
                    template: template.id
                });
            });
        }, function (sortedTemplates) {
            return sortedTemplates.filter(_.identity);
        }, function (filteredTemplates) {
            return [].concat(_this3.props.sdTemplateOrder.map(function (key) {
                return filteredTemplates.find(function (template) {
                    return template.key === key;
                });
            }), _.filter(filteredTemplates, function (template) {
                return _this3.props.sdTemplateOrder.indexOf(template.key) === -1;
            }).sort(function (a, b) {
                var aName = _.get(a, 'name', '');
                var bName = _.get(b, 'name', '');
                return aName.toUpperCase().localeCompare(bName.toUpperCase());
            }));
        }, function (templates) {
            return templates.filter(function (template) {
                return template.templateType === structuredDataTemplateType;
            });
        })(forms.templatesForActiveRouteComponent() || []);
    },
    possibleTemplateForms: function possibleTemplateForms() {
        var selfModule = this;

        var _selfModule$getDepend = selfModule.getDependencies(),
            forms = _selfModule$getDepend.forms;

        return forms.templatesForActiveRouteComponent().filter(function (template) {
            return selfModule.props.templateTypes.indexOf(template.templateType) > -1;
        });
    },
    addableTemplateForms: function addableTemplateForms() {
        var selfModule = this;

        return selfModule.possibleTemplateForms().filter(function (template) {
            return !_.get(template, 'unique_template');
        }).filter(function (template) {
            return selfModule.doesUserHavePermission({ action: 'add', template: template.key, subsite: true, ruby_client: true, model: 'content' });
        });
    },
    util__save: function util__save() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { successCallback: undefined, errorCallback: undefined };

        var _getDependencies7 = this.getDependencies(),
            saveButtonComponent = _getDependencies7.saveButtonComponent;

        if (saveButtonComponent) {
            this.getStore().dispatch(saveButtonComponent.getDependencies().actionForButton(options));
        } else {
            console.error('[util__save] was called, but [saveButtonComponent] not found');
        }
    }
}, require('./pollChanges')));

module.exports = RCSitemap;