'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Card = require('material-ui/Card');

var _Avatar = require('material-ui/Avatar');

var _Avatar2 = _interopRequireDefault(_Avatar);

var _person = require('material-ui/svg-icons/social/person');

var _person2 = _interopRequireDefault(_person);

var _RaisedButton = require('material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _ActivityList = require('./ActivityList');

var _ActivityList2 = _interopRequireDefault(_ActivityList);

var _ActivityList3 = require('./ActivityList.cssModule');

var _ActivityList4 = _interopRequireDefault(_ActivityList3);

var _ActivityList_style = require('./ActivityList_style');

var _ActivityList_style2 = _interopRequireDefault(_ActivityList_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;


var TinyMCE = require('@rubyapps/react-tinymce');

var ActivityLister = _react2.default.createClass({
    displayName: 'ActivityLister',

    propTypes: {
        actions: PropTypes.object,
        activities: PropTypes.array
        //, edits
        , rteConfig: PropTypes.object,
        rteMode: PropTypes.string,
        rubyUser: PropTypes.object,
        showAll: PropTypes.bool,
        canUserPublish: PropTypes.bool,
        canUserEdit: PropTypes.bool,
        canUserDownload: PropTypes.bool,
        getWordDocGenerationStatus_fromActivity: PropTypes.func.isRequired,
        STATUS_TYPES: PropTypes.object.isRequired,
        templateKey: PropTypes.string.isRequired,
        forms: PropTypes.object.isRequired
    },
    getDefaultProps: function getDefaultProps() {
        return {};
    },
    onNewCommentEdit: function onNewCommentEdit(event, editor) {
        if (!event.initial) {
            //# this check is only necessary for setContent
            var updateNewComment = this.props.actions.updateNewComment;

            updateNewComment(editor.getContent());
        }
    },
    onNewCommentSave: function onNewCommentSave() {
        var _props = this.props,
            newComment = _props.newComment,
            actions = _props.actions;
        var saveNewComment = actions.saveNewComment;

        saveNewComment(newComment);
    },
    onNewCommentSaveWithContent: function onNewCommentSaveWithContent() {
        var _props2 = this.props,
            newComment = _props2.newComment,
            actions = _props2.actions;
        var saveNewCommentAfterSavingContent = actions.saveNewCommentAfterSavingContent;

        saveNewCommentAfterSavingContent(newComment);
    },
    renderNewCommentOptions: function renderNewCommentOptions() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5;

        var _props3 = this.props,
            newComment = _props3.newComment,
            activities = _props3.activities,
            rteConfig = _props3.rteConfig,
            canUserPublish = _props3.canUserPublish,
            checkShouldDisableSave = _props3.checkShouldDisableSave;


        var shouldDisableSave = checkShouldDisableSave();
        var contentCanBeSaved = _lodash2.default.isNil(this.props.contentApprovalFlag);
        var userCanSave = canUserPublish && !shouldDisableSave;
        var commentIsSupplied = newComment;
        var isNewPage = activities.length === 0;

        return _react2.default.createElement(
            'div',
            (_React$createElement5 = { className: _ActivityList4.default.newComment, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'ActivityLister'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ActivityLister'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ActivityLister'), _React$createElement5),
            _react2.default.createElement(TinyMCE, (_React$createElement = {
                content: newComment,
                config: rteConfig
                //# onChange doesn't work as expected.
                // see: https://github.com/instructure-react/react-tinymce/issues/10
                // see: http://stackoverflow.com/questions/17802592/how-to-tinymce-change-detect
                , onKeyup: this.onNewCommentEdit,
                onSetContent: this.onNewCommentEdit,
                onChange: this.onNewCommentEdit,
                'data-codecept-selector-node': 'TinyMCE',
                'data-codecept-selector-file': 'ActivityLister'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'TinyMCE'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ActivityLister'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'TinyMCE'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ActivityLister'), _React$createElement)),
            _react2.default.createElement(
                'div',
                (_React$createElement4 = { className: _ActivityList4.default.newCommentOptions, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'ActivityLister'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ActivityLister'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ActivityLister'), _React$createElement4),
                _react2.default.createElement(_RaisedButton2.default, (_React$createElement2 = {
                    label: 'Save Content With Comment',
                    secondary: true,
                    style: _ActivityList_style2.default.RaisedButton,
                    onTouchTap: this.onNewCommentSaveWithContent,
                    disabled: !userCanSave || !contentCanBeSaved || !commentIsSupplied,
                    'data-codecept-selector-node': 'RaisedButton',
                    'data-codecept-selector-file': 'ActivityLister'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ActivityLister'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ActivityLister'), _React$createElement2)),
                _react2.default.createElement(_RaisedButton2.default, (_React$createElement3 = {
                    label: 'Save Comment Only',
                    primary: true,
                    style: _ActivityList_style2.default.RaisedButton,
                    onTouchTap: this.onNewCommentSave,
                    disabled: isNewPage || !commentIsSupplied,
                    'data-codecept-selector-node': 'RaisedButton',
                    'data-codecept-selector-file': 'ActivityLister'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ActivityLister'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ActivityLister'), _React$createElement3))
            )
        );
    },
    render: function render() {
        var _React$createElement6, _React$createElement7, _React$createElement8, _React$createElement9, _React$createElement10, _React$createElement11, _extends2, _React$createElement12, _React$createElement13, _React$createElement14;

        var _props4 = this.props,
            actions = _props4.actions,
            activities = _props4.activities,
            edits = _props4.edits,
            rteConfig = _props4.rteConfig,
            rteMode = _props4.rteMode,
            rubyUser = _props4.rubyUser,
            showAll = _props4.showAll,
            canUserDownload = _props4.canUserDownload,
            selfModule = _props4.selfModule,
            shouldHidePreviewLinks = _props4.shouldHidePreviewLinks,
            shouldHideRevertLinks = _props4.shouldHideRevertLinks,
            templateKey = _props4.templateKey,
            forms = _props4.forms;


        var userAvatar = _lodash2.default.get(rubyUser, ['avatarMedia']);
        var rubyUserAvatarUrl = _lodash2.default.get(userAvatar, 'thumbnailUrls.small_padded');

        return _react2.default.createElement(
            _Card.Card,
            (_React$createElement14 = { style: _ActivityList_style2.default.Card, 'data-codecept-selector-node': 'Card',
                'data-codecept-selector-file': 'ActivityLister'
            }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'ActivityLister'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'ActivityLister'), _React$createElement14),
            _react2.default.createElement(
                _Card.CardHeader,
                (_React$createElement11 = {
                    avatar: rubyUserAvatarUrl ? _react2.default.createElement(_Avatar2.default, (_React$createElement6 = { style: _ActivityList_style2.default.newCommentAvatar, src: rubyUserAvatarUrl, backgroundColor: 'transparent', 'data-codecept-selector-node': 'Avatar',
                        'data-codecept-selector-file': 'ActivityLister'
                    }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'ActivityLister'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'ActivityLister'), _React$createElement6)) : _react2.default.createElement(_Avatar2.default, (_React$createElement8 = { style: _ActivityList_style2.default.newCommentAvatar, icon: _react2.default.createElement(_person2.default, (_React$createElement7 = {
                            'data-codecept-selector-node': 'PersonIcon',
                            'data-codecept-selector-file': 'ActivityLister'
                        }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'PersonIcon'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'ActivityLister'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'PersonIcon'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'ActivityLister'), _React$createElement7)), 'data-codecept-selector-node': 'Avatar',
                        'data-codecept-selector-file': 'ActivityLister'
                    }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'ActivityLister'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'ActivityLister'), _React$createElement8)),
                    title: _react2.default.createElement(
                        'span',
                        (_React$createElement10 = {
                            'data-codecept-selector-node': 'span',
                            'data-codecept-selector-file': 'ActivityLister'
                        }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'ActivityLister'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'ActivityLister'), _React$createElement10),
                        _react2.default.createElement(
                            'strong',
                            (_React$createElement9 = {
                                'data-codecept-selector-node': 'strong',
                                'data-codecept-selector-file': 'ActivityLister'
                            }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'ActivityLister'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'ActivityLister'), _React$createElement9),
                            'Comment'
                        ),
                        ' on this revision:'
                    ),
                    titleStyle: _ActivityList_style2.default.newCommentTitle,
                    style: _ActivityList_style2.default.newCommentHeader,
                    'data-codecept-selector-node': 'CardHeader',
                    'data-codecept-selector-file': 'ActivityLister'
                }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'ActivityLister'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'ActivityLister'), _React$createElement11),
                this.renderNewCommentOptions()
            ),
            _react2.default.createElement(
                _Card.CardText,
                (_React$createElement13 = {
                    'data-codecept-selector-node': 'CardText',
                    'data-codecept-selector-file': 'ActivityLister'
                }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'ActivityLister'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'ActivityLister'), _React$createElement13),
                _react2.default.createElement(
                    'div',
                    (_React$createElement12 = { className: _ActivityList4.default.activityList, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'ActivityLister'
                    }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'ActivityLister'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'ActivityLister'), _React$createElement12),
                    _react2.default.createElement(_ActivityList2.default, _extends({
                        actions: actions,
                        activities: activities,
                        edits: edits,
                        rteConfig: rteConfig,
                        rteMode: rteMode,
                        rubyUser: rubyUser,
                        canUserDownload: canUserDownload,
                        selfModule: selfModule,
                        shouldHidePreviewLinks: shouldHidePreviewLinks,
                        shouldHideRevertLinks: shouldHideRevertLinks,
                        showAll: showAll,
                        templateKey: templateKey,
                        forms: forms
                    }, _lodash2.default.pick(this.props, ['getWordDocGenerationStatus_fromActivity', 'STATUS_TYPES']), (_extends2 = {
                        'data-codecept-selector-node': 'ActivityList',
                        'data-codecept-selector-file': 'ActivityLister'
                    }, _defineProperty(_extends2, 'data-codecept-selector-node', 'ActivityList'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ActivityLister'), _defineProperty(_extends2, 'data-codecept-selector-node', 'ActivityList'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ActivityLister'), _extends2)))
                )
            )
        );
    }
});

exports.default = ActivityLister;