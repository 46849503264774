'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _fieldBase = require('@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule');

var _fieldBase2 = _interopRequireDefault(_fieldBase);

var _transitions = require('material-ui/styles/transitions');

var _transitions2 = _interopRequireDefault(_transitions);

var _addInfoModeProps = require('@rubyapps/ruby-react-components/src/client/hoc/addInfoModeProps');

var _addInfoModeProps2 = _interopRequireDefault(_addInfoModeProps);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

var PropTypes = _react2.default.PropTypes;

//import TinyMCE from '@rubyapps/react-tinymce';
var TinyMCE = require('@rubyapps/react-tinymce');

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label,
    RichErrorMessages = _require.RichErrorMessages;

var svgPath_black_playCircle = require('font-awesome-svg-png/black/svg/play-circle-o.svg');
var svgPath_white_playCircle = require('font-awesome-svg-png/white/svg/play-circle.svg');

var getStyles = function getStyles(props, context, state) {
    var _context$muiTheme = context.muiTheme,
        baseTheme = _context$muiTheme.baseTheme,
        _context$muiTheme$tex = _context$muiTheme.textField,
        floatingLabelColor = _context$muiTheme$tex.floatingLabelColor,
        focusColor = _context$muiTheme$tex.focusColor,
        textColor = _context$muiTheme$tex.textColor,
        disabledTextColor = _context$muiTheme$tex.disabledTextColor,
        backgroundColor = _context$muiTheme$tex.backgroundColor,
        hintColor = _context$muiTheme$tex.hintColor,
        errorColor = _context$muiTheme$tex.errorColor;


    var styles = {
        root: {
            fontSize: 16,
            lineHeight: '24px',
            width: props.fullWidth ? '100%' : 256,
            height: (props.rows - 1) * 24 + (props.floatingLabelText ? 72 : 48),
            display: 'inline-block',
            position: 'relative',
            fontFamily: baseTheme.fontFamily,
            transition: _transitions2.default.easeOut('200ms', 'height')
        },
        error: {
            position: 'relative',
            bottom: 8
        }
    };

    _extends(styles.error, props.errorStyle);

    return styles;
};
var RichTextEditor = _react2.default.createClass({
    displayName: 'RichTextEditor',

    propTypes: {
        label: PropTypes.string,
        fieldKey: PropTypes.string,
        value: PropTypes.string,
        error: PropTypes.shape({
            message: PropTypes.string,
            wordsToHighlight: PropTypes.arrayOf(PropTypes.string)
        }),
        id: PropTypes.string,
        actions: PropTypes.object,
        config: PropTypes.object,
        hasWordErrors: PropTypes.bool,
        keepToolbarOpen: PropTypes.bool
    },
    contextTypes: {
        registerForDirtyForceUpdate: PropTypes.func,
        unregisterForDirtyForceUpdate: PropTypes.func,
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null,
            config: {
                plugins: 'link image code',
                menubar: false,
                toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
            },
            keepToolbarOpen: false
        };
    },
    getInitialState: function getInitialState() {
        return {
            shouldPerformSpellCheck: false,
            outgoingContent: null,
            didSpellCheck: false
        };
    },
    _onValueChange: function _onValueChange(event, editor) {
        var updatedContent = editor.getContent();
        var valueHasChanged = updatedContent != this.props.value;
        var valueIsCoercedNull = this.props.value === null && updatedContent === "";

        var shouldUpdateFieldValue = valueHasChanged

        //# Don't `setFieldValue` to `''` after `clearForm` sets the value to `null`
        //# `''` implies that the user removed the field and that it should be validated
        && !valueIsCoercedNull;

        if (!event.initial && this._reactTinyMCE && shouldUpdateFieldValue) {
            //# event.initial check is only necessary for setContent
            //# this._reactTinyMCE check so that we don't fire changes if we already unmounted
            //#     TinyMCE sometimes fires changes when it's being teared down
            //this._onValueChange_updatedContent = updatedContent;
            this.setState({
                outgoingContent: updatedContent
            });

            this.props.actions.setFieldValueByKey(updatedContent, this.props.fieldKey);
        }
    },
    _onPostProcess: function _onPostProcess(event, editor) {
        if (this._reactTinyMCE) {
            var $ = this._reactTinyMCE._editor.$;
            //# NOTE: hack. For some reason, the contenteditable prop is removed after a drop for iframes so we have to add it back in. Otherwise, you can't drag the iframe anymore
            /*
            const $iframes = $('.mce-object-iframe');
            $iframes.length && $iframes.attr('contenteditable', 'false');
            */

            //# NOTE: hack to forcefully use the poster url for <video> placeholders
            /*
            background: url() no-repeat center
                , url(/ruby/api/v2/media/53654/thumbnail/large/53654.jpg) no-repeat center / cover
             */
            var $videos = $('.mce-object-video');
            if ($videos.length) {
                $videos.each(function (index, video) {
                    var $video = $(video);
                    var posterUrl = $video.attr('data-mce-p-poster');
                    //posterUrl && $video.attr('src', posterUrl);
                    posterUrl && $video.css('background', 'url(' + svgPath_black_playCircle + ') no-repeat center / 50px' + (', url(' + svgPath_white_playCircle + ') no-repeat center / 50px') + (', url(' + posterUrl + ') no-repeat center / cover'));
                });
            }

            var $audios = $('.mce-object-audio');
            if ($audios.length) {
                $audios.each(function (index, audio) {
                    var $audio = $(audio);
                    var src = $audio.attr('src');
                    src && $audio.css('background', ' #D5D5D5 url(' + svgPath_black_playCircle + ') no-repeat center / 50px');
                });
            }

            var $iframes = $('.mce-object-iframe');
            if ($iframes.length) {
                $iframes.each(function (index, iframe) {
                    var $iframe = $(iframe);
                    var posterUrl = $iframe.attr('data-mce-p-data-poster');
                    //posterUrl && $iframe.attr('src', posterUrl);
                    posterUrl && $iframe.css('background', 'url(' + svgPath_black_playCircle + ') no-repeat center / 50px' + (', url(' + svgPath_white_playCircle + ') no-repeat center / 50px') + (', url(' + posterUrl + ') no-repeat center / cover'));
                });
            }
        }
    },
    componentDidMount: function componentDidMount() {
        //this.context.registerForDirtyForceUpdate(this, this.props.id);:w

        //# if component just mounted and already has data and wordErrors, then we need to trigger spellcheck
        if (this.props.value && this.props.hasWordErrors) {
            this._performSpellCheck();
        }
    },
    componentWillUnmount: function componentWillUnmount() {
        this.context.unregisterForDirtyForceUpdate && this.context.unregisterForDirtyForceUpdate(this, this.props.id);
        this._reactTinyMCE = undefined;
        clearInterval(this._spellcheckInterval);
        this._spellcheckInterval = undefined;
    },
    _setupCallback: function _setupCallback(editor) {
        var _this = this;

        this.props.config.setup && this.props.config.setup(editor);
        editor.on('init', function () {
            _this.context.registerForDirtyForceUpdate && _this.context.registerForDirtyForceUpdate(_this, _this.props.id);
        });
    },
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        //if (nextProps.hasWordErrors && should
        var _state = this.state,
            outgoingContent = _state.outgoingContent,
            didSpellCheck = _state.didSpellCheck;


        var setStateData = void 0;
        if (outgoingContent != nextProps.value) {
            setStateData = {
                outgoingContent: nextProps.value,
                shouldPerformSpellCheck: nextProps.hasWordErrors
                //# new content. Don't need to check if we've done spell check
            };
        } else {
            //# after content had already loaded, check if we have a spellCheck queued up
            var shouldPerformSpellCheck_deferred = !didSpellCheck && nextProps.hasWordErrors;
            setStateData = {
                outgoingContent: nextProps.value,
                shouldPerformSpellCheck: shouldPerformSpellCheck_deferred
            };
        }

        if (this.props.hasWordErrors && !nextProps.hasWordErrors) {
            //# clear the marked words
            this._clearMarkedWords();
        }
        if (!nextProps.hasWordErrors) {
            setStateData.didSpellCheck = false;
        }

        this.setState(setStateData);
    },
    render: function render() {
        var _extends2,
            _React$createElement,
            _React$createElement2,
            _this2 = this,
            _React$createElement3,
            _React$createElement4;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var config = _extends({}, this.props.config, {
            readonly: this.props.disabled || this.props.locked || _.get(this, 'props.infoModeProps.default.disabled'),
            setup: this._setupCallback
        });
        var labelProps = _.pick(this.props, Object.keys(Label.propTypes));

        var prepareStyles = this.context.muiTheme.prepareStyles;

        var styleObject = getStyles(this.props, this.context, this.state);
        return _react2.default.createElement(
            'div',
            (_React$createElement4 = { className: _fieldBase2.default.wrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'RichTextEditor'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'RichTextEditor'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'RichTextEditor'), _React$createElement4),
            _react2.default.createElement(
                'label',
                (_React$createElement = { className: _fieldBase2.default.label, 'data-codecept-selector-node': 'label',
                    'data-codecept-selector-file': 'RichTextEditor'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'RichTextEditor'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'RichTextEditor'), _React$createElement),
                _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                    'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'RichTextEditor'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'RichTextEditor'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'RichTextEditor'), _extends2)))
            ),
            _react2.default.createElement(RichErrorMessages, (_React$createElement2 = {
                style: prepareStyles(styleObject.error),
                messages: this.props.richErrorMessages,
                'data-codecept-selector-node': 'RichErrorMessages',
                'data-codecept-selector-file': 'RichTextEditor'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'RichTextEditor'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'RichTextEditor'), _React$createElement2)),
            _react2.default.createElement(TinyMCE, (_React$createElement3 = {
                key: this.props.id,
                id: this.props.id,
                content: _.get(this, 'props.infoModeProps.default.value', this.props.value),
                config: config,
                onKeyup: this._onValueChange,
                onChange: this._onValueChange,
                onSetContent: this._onValueChange,
                onPostProcess: this._onPostProcess,
                ref: function ref(reactTinyMCE) {
                    if (!reactTinyMCE) {
                        return;
                    }
                    _this2._reactTinyMCE = reactTinyMCE;
                },
                toolbarAsHiddenByDefault: !this.props.keepToolbarOpen,
                'data-codecept-selector-node': 'TinyMCE',
                'data-codecept-selector-file': 'RichTextEditor'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'TinyMCE'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'RichTextEditor'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'TinyMCE'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'RichTextEditor'), _React$createElement3)),
            this.props.children
        );
    },
    _clearMarkedWords: function _clearMarkedWords() {
        var _this3 = this;

        clearTimeout(this._clearMarkedWordsTimeout);

        if (this._spellcheckInterval) {
            return;
        } //# spellchecking will handle it

        var reactTinyMCEInstance = this._reactTinyMCE;
        var editor = reactTinyMCEInstance._editor;
        var editorIsAvailable = editor && editor.getBody();

        if (editorIsAvailable) {
            if (editor.plugins.spellchecker.active) {
                editor.plugins.spellchecker.removeWords();
            }
        } else {
            this._clearMarkedWordsTimeout = setTimeout(function () {
                _this3._clearMarkedWords();
            }, 1000);
        }
    },
    _performSpellCheck: function _performSpellCheck() {
        var _this4 = this;

        clearTimeout(this._spellcheckTimeout);
        clearTimeout(this._clearMarkedWordsTimeout);

        var reactTinyMCEInstance = this._reactTinyMCE;
        var editor = reactTinyMCEInstance._editor;

        var editorIsAvailable = editor && editor.initialized && editor.getBody();

        if (editorIsAvailable) {
            this.setState({
                didSpellCheck: true,
                shouldPerformSpellCheck: false
            });

            //# trigger editor to highlight words

            if (editor.plugins.spellchecker.active) {
                editor.plugins.spellchecker.removeWords();
            }

            var wordsToHighlight = _.get(this.props, 'error.wordsToHighlight', []);

            if (wordsToHighlight.length) {
                //# Doing what the spellchecker would do but without hitting the check_text.json
                //# spellchecker/editor_plugin_src.js:569
                editor.plugins.spellchecker.markWords(wordsToHighlight);
                /*
                editor.plugins.spellchecker.active = 1;
                editor.target.spellchecker_active = 1;
                editor.plugins.spellchecker._markWords(wordsToHighlight);
                editor.nodeChanged();
                */
            }

            this._spellcheckTimeout = undefined;
        } else {

            this._spellcheckTimeout = setInterval(function () {
                _this4._performSpellCheck();
            }, 1000);
        }
    },
    componentDidUpdate: function componentDidUpdate() {

        if (this.state.shouldPerformSpellCheck) {
            this._performSpellCheck();
        }
    },
    dirtyForceUpdate: function dirtyForceUpdate() {
        if (this._reactTinyMCE) {
            var tinyMCE__editor = _.get(this, '_reactTinyMCE._editor');
            if (tinyMCE__editor) {
                var iframeDocument = _.get(tinyMCE__editor, 'iframeElement.contentWindow.document');
                var raw_iframeBody = iframeDocument.getElementById('tinymce');
                var cached_iframeBody = tinyMCE__editor.getBody();
                if (_.isNil(raw_iframeBody) && raw_iframeBody != cached_iframeBody) {
                    //# raw iframe body isn't found. we loss sync probably due to a repeater resorting
                    this._reactTinyMCE.componentWillUnmount();
                    this._reactTinyMCE.componentDidMount();
                }
            }
        }
    }
});

exports.default = (0, _addInfoModeProps2.default)(RichTextEditor);