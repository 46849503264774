'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _DropdownConnector = require('./reactComponents/DropdownConnector');

var _DropdownConnector2 = _interopRequireDefault(_DropdownConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Promise = require('bluebird');

var React = require('react');

var _require = require('redux'),
    combineReducers = _require.combineReducers;

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var RubyComponentCurrentUser = require('@rubyapps/ruby-component-current-user');

var RubyClientFK = require('@rubyapps/ruby-component-rubyclientfk');

var _require2 = require('@rubyapps/ruby-content-property-helper/src/client/fieldsSpec.js'),
    specsFromNodes_andCtx = _require2.specsFromNodes_andCtx;

var mixinUtils = require('@rubyapps/ruby-component-mixin-utils');

var reducer = require('./reducer');
var action = require('./action');

var RCNamespaceSelector = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {
        //, label: PropTypes.string
        //, key: PropTypes.string
        //, constraints: PropTypes.object
        isControlHidden: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.string,
            isDefault: PropTypes.bool
        }))
    },
    getDefaultProps: function getDefaultProps() {
        return {
            key: '__value',
            verify: {
                required: true
            },
            isControlHidden: false
        };
    },
    componentName: componentName,
    action: action,
    reducer: reducer,
    getInitialState: function getInitialState() {
        return fieldValidationMixin.getInitialState.apply(this);
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getSelfStateSelector();

        var RubyClientFKSelector = root.findDescendentByID(RubyClientFK.componentName).getDefaultSelector();

        var currentUserID = this.props.currentUserID || RubyComponentCurrentUser.CONSTANTS.COMPONENT_NAME;
        var currentUser = root.findDescendentByID(currentUserID);
        var currentUserSelector = currentUser.getSelfStateSelector();

        return {
            selfSelector: selfSelector,
            currentUserSelector: currentUserSelector,
            RubyClientFKSelector: RubyClientFKSelector
        };
    },
    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector,
            currentUserSelector = _getDependencies.currentUserSelector;

        return {
            self: selfSelector(state),
            user: currentUserSelector(state),
            fieldProps: this.fieldProps_fromState_andOwnProps(state, this.props),
            routing: state.routing //# need to update based on routing changes
        };
    },
    getReactClass: function getReactClass() {
        return _DropdownConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var DropdownComponent = this.getReactClass();

        return React.createElement(DropdownComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'DropdownComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'DropdownComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'DropdownComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

    //# Utility methods
    , getDefaultNamespace: function getDefaultNamespace() {
        var options = this.props.options;


        var defaultOption = _lodash2.default.first(_lodash2.default.filter(options, ['isDefault', true]));
        return defaultOption ? defaultOption.value : null;
    },
    getFirstNamespace: function getFirstNamespace() {
        var options = this.props.options;


        var firstOption = _lodash2.default.first(options);
        return firstOption ? firstOption.value : null;
    }

    //# mixin overrides
    , _activeNamespacePathArray_fromState: function _activeNamespacePathArray_fromState(state) {
        if (!state) {
            state = this.getStore().getState();
        }

        var selfSelector = this.getDefaultSelector();
        var selfState = selfSelector(state);
        var selfKey = this.props.key;

        var value = _lodash2.default.get(selfState, ['fields', selfKey, 'value']);

        //return [this.props.keypath, value].join('.');
        return value ? [selfKey, value] : undefined;
    },
    resetStore: function resetStore(dispatchOrCollect, shouldResetStore) {
        var _arguments = arguments;

        var store = this.getStore();
        var actions = this.getAction().generators;
        var children = this.getChildren();

        //# NOTE: temporary measure to really immediately dispatch for thunks
        //# Since we may call on resetStore with a collector for dispatching thunks
        var shouldResetStoreResult = shouldResetStore ? shouldResetStore(this) : true;
        //# default is true;

        if (shouldResetStoreResult) {
            actions.resetStore && store.dispatch(actions.resetStore());
            children.forEach(function (child) {
                child.resetStore && child.resetStore.apply(child, _arguments);
            });
        }
    },

    deferredClearErrorsWithKeys: function deferredClearErrorsWithKeys(dispatchOrCollect, pickKeys, inState) {
        if (inState == undefined) {
            inState = this.getState();
        }
        if (inState == undefined) {
            return;
        }

        var actions = this.getAction().generators;
        var children = this.getChildren();

        children.forEach(function (child) {
            child.clearErrorsWithKeys_inState && child.clearErrorsWithKeys_inState.call(child, dispatchOrCollect, pickKeys, inState[child.getID()]);
        });
    },
    clearErrorsWithKeys_inState: function clearErrorsWithKeys_inState(dispatchOrCollect, pickKeys, inState) {
        if (inState == undefined) {
            return;
        }

        //#     - convert the default values into the nested version

        var _getAction = this.getAction(),
            actions = _getAction.generators;

        var selfID = this.getID();
        var selfState = this.getState();
        var savedLocalStateByNamespace = selfState.savedLocalStateByNamespace,
            savedLocalReducerByNamespace = selfState.savedLocalReducerByNamespace;

        var selfKey = this.props.key;
        var defaultNamespace = this.getDefaultNamespace();
        var activeNamespace = _lodash2.default.get(selfState, ['fields', selfKey, 'value']);
        var firstNamespace = this.getFirstNamespace();

        var targetNamespace = activeNamespace || defaultNamespace || firstNamespace;
        var children = this.getChildren();

        //# call on baseFieldMixin.clearErrorsWithKeys(...) for the active value (which is probably the defaultNamespace or the first available namespace)
        baseFieldMixin.clearErrorsWithKeys_inState.apply(this, arguments);

        //# manually run the cached localStates against the reducers to get the new state without the errors
        var savedLocalStateByNamespaceWithoutActive = _lodash2.default.omit(savedLocalStateByNamespace, [targetNamespace]);

        var deferredClearFormErrorByNamespace = _lodash2.default.reduce(savedLocalStateByNamespaceWithoutActive, function (collector, savedLocalState, namespace) {
            collector[namespace] = pickKeys;

            return collector;
        }, {});

        dispatchOrCollect(actions.replaceDeferredClearFormErrorByNamespace(deferredClearFormErrorByNamespace));
    },

    _objectValueFromLocalState: function _objectValueFromLocalState(selfState, isError, limitToTabWithLabel) {
        var selfID = this.getID();
        var savedObjectValuesByNamespace = selfState.savedObjectValuesByNamespace;


        var last_mixin = mixinUtils.superMixinContaining(this, '_objectValueFromLocalState');

        var baseFormValue = last_mixin._objectValueFromLocalState.apply(this, arguments);

        if (!isError) {
            var formValueWithSavedNamespace = _extends({}, baseFormValue, _defineProperty({}, selfID, _extends({}, baseFormValue[selfID], {
                savedObjectValuesByNamespace: savedObjectValuesByNamespace
            })));

            //console.log('=== namespace object value', JSON.stringify(formValueWithSavedNamespace, null, ' '));
            return formValueWithSavedNamespace;
        } else {
            return baseFormValue;
        }
    },
    _objectValueToLocalState: function _objectValueToLocalState(objectValue, dispatchOrCollect, isError) {
        var _getAction2 = this.getAction(),
            actions = _getAction2.generators;

        var selfID = this.getID();

        var last_mixin = mixinUtils.superMixinContaining(this, '_objectValueToLocalState');

        last_mixin._objectValueToLocalState.apply(this, arguments);

        if (!isError) {
            //# TODO: need to reconstitute the savedObjectValuesByNamespace
            var selfObjectValue = objectValue[selfID];
            dispatchOrCollect(actions.replaceSavedObjectValuesByNamespace(selfObjectValue.savedObjectValuesByNamespace));
        }
    },
    deferredFormValueToLocalState: function deferredFormValueToLocalState(formValue, dispatchOrCollect, isError, entireFormValue) {
        if (!entireFormValue) {
            var parentFormComponent = this.getParentFormComponent();
            entireFormValue = parentFormComponent ? parentFormComponent.formValue() : formValue;

            if (!parentFormComponent) {
                console.error('formValueToLocalState() was called for [' + this.key() + '] but parentFormComponent is missing');
            }
        }

        //# the formValue given would be the nested value without selfKey;
        var selfKey = this.props.key;
        var formValueForLocalState = _defineProperty({}, selfKey, formValue);

        //# call on baseFieldMixin._formValueToLocalState(...) for the active value (which is probably the defaultNamespace or the first available namespace)
        return baseFieldMixin._formValueToLocalState.call(this, formValueForLocalState, dispatchOrCollect, isError, entireFormValue);
    },
    _formValueToLocalState: function _formValueToLocalState(formValue, dispatchOrCollect, isError, entireFormValue) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        //#     - convert the default values into the nested version
        var _getAction3 = this.getAction(),
            actions = _getAction3.generators;

        var selfState = this.getState();
        var selfKey = this.props.key;
        var defaultNamespace = this.getDefaultNamespace();
        var activeNamespace = _lodash2.default.get(selfState, ['fields', selfKey, 'value']);
        var firstNamespace = this.getFirstNamespace();

        var targetNamespace = activeNamespace || defaultNamespace || firstNamespace;

        //# support omit field picker
        var shouldOmitField = _lodash2.default.isFunction(options.omitFieldPicker) && options.omitFieldPicker.apply(options, [this].concat(Array.prototype.slice.call(arguments)));

        var formValueWithNamespacedDefault = _defineProperty({}, selfKey, _extends({}, _lodash2.default.get(formValue, [selfKey], {}), defaultNamespace ? _defineProperty({}, defaultNamespace, _lodash2.default.omit(formValue, [selfKey])) : {}));
        //# NOTE: the entireFormValue we expect is the formValue that gets submitted to the server
        //# therefore, we don't need to hydrate the formValue with the default namespace because 
        //# from the consumers that need entireFormValue, it's non-existent

        //#     - find the firstNamespace and call on formValueToLocalState for that namespaced formValue
        var formValueForLocalState = _defineProperty({}, selfKey, _lodash2.default.get(formValueWithNamespacedDefault, [selfKey, targetNamespace]));

        //# call on baseFieldMixin._formValueToLocalState(...) for the active value (which is probably the defaultNamespace or the first available namespace)
        var promiseArr = [];
        promiseArr.push(baseFieldMixin._formValueToLocalState.call(this, formValueForLocalState, dispatchOrCollect, isError, entireFormValue, options));

        //#     - store the other formValues in another key in the local store so that when switching to another namespace, if it's not available in the savedLocalStateByNamespace, then we check if it's available in the deferredFormValues
        var deferredFormValue = _lodash2.default.get(formValueWithNamespacedDefault, [selfKey]);
        var deferredFormValueWithoutActiveNamespace = _lodash2.default.omit(deferredFormValue, activeNamespace);

        if (!shouldOmitField) {
            //# TODO: project/ruby-app/issues/167
            //# NOTE: not ideal but for now, the NamespaceSelector should never have 'mode: info'
            //# thus, these will always be omitted if options.omitFieldPicker is available

            if (isError) {
                promiseArr.push(dispatchOrCollect(actions.replaceDeferredFormErrorByNamespace(deferredFormValueWithoutActiveNamespace)));
            } else {
                promiseArr.push(dispatchOrCollect(actions.replaceDeferredFormValueByNamespace(deferredFormValueWithoutActiveNamespace)));
            }
        }

        return Promise.all(promiseArr);
    },
    getFormValueForActiveNamespace_withLocalState: function getFormValueForActiveNamespace_withLocalState(localState) {
        var selfKey = this.props.key;

        var baseFormValue = baseFieldMixin._formValueFromLocalState.apply(this, arguments);

        var activeNamespaceFormValue = baseFormValue[selfKey];
        return activeNamespaceFormValue;
    },
    _formValueFromLocalState: function _formValueFromLocalState(selfState, isError, predicateFormatter, entireState) {
        var _this = this;

        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        //# do not mixin the nested states with self value
        var selfKey = this.props.key;
        var selfKeypathArr = this.getKeypathArr();

        var baseFormValue = baseFieldMixin._formValueFromLocalState.apply(this, arguments);
        var defaultNamespace = this.getDefaultNamespace();
        var activeNamespace = _lodash2.default.get(selfState, ['fields', selfKey, 'value']);
        var savedLocalStateByNamespace = selfState.savedLocalStateByNamespace,
            savedFormValueByNamespace = selfState.savedFormValueByNamespace,
            deferredFormValueByNamespace = selfState.deferredFormValueByNamespace;


        var filteredSavedLocalStateByNamespace = _lodash2.default.omit(savedLocalStateByNamespace, [activeNamespace]);

        var activeNamespaceFormValue = baseFormValue[selfKey];

        var completeWithNamespacesFormValue = _defineProperty({}, selfKey, _lodash2.default.reduce(filteredSavedLocalStateByNamespace, function (collector, localState, namespace) {
            _lodash2.default.set(entireState, selfKeypathArr, localState); //# NOTE: not functionally friendly but this prevents us from needing to duplicate entireState.
            var formValue = namespace === activeNamespace ? baseFieldMixin._formValueFromLocalState.call(_this, localState, isError, predicateFormatter, entireState) : _defineProperty({}, selfKey, _lodash2.default.omit(savedFormValueByNamespace[namespace], [selfKey]));

            collector[namespace] = _lodash2.default.get(formValue, [selfKey]);
            return collector;
        }, _extends({}, deferredFormValueByNamespace //# we'll need to update the formValueFromLocal state to check the deferredFormValues in case the user didn't switch to that namespace for editing
        , _defineProperty({}, activeNamespace, activeNamespaceFormValue))));

        _lodash2.default.set(entireState, selfKeypathArr, selfState); //# NOTE: fixing mutation of object


        //# handle lifting a namespace object up if there's a default namespace
        if (defaultNamespace) {
            var defaultNamespaceFormValue = _lodash2.default.get(completeWithNamespacesFormValue, [selfKey, defaultNamespace]);
            var newFormValueWithLiftedDefaultNamespace = _extends(_defineProperty({}, selfKey, _lodash2.default.omit(completeWithNamespacesFormValue[selfKey], [defaultNamespace])), defaultNamespaceFormValue);

            return newFormValueWithLiftedDefaultNamespace;
        }

        return completeWithNamespacesFormValue;
    }

    //# utility
    , getActiveNamespace: function getActiveNamespace() {
        var state = this.getState();
        var activeNamespace = _lodash2.default.get(state, ['fields', this.props.key, 'value']);
        return activeNamespace;
    },
    getStateForNamespace: function getStateForNamespace(namespace) {
        var activeNamespace = this.getActiveNamespace();
        var store = this.getStore();

        var selfState = this.getState();
        if (activeNamespace == namespace) {
            return selfState;
        } else {
            return _lodash2.default.get(selfState, ['savedLocalStateByNamespace', namespace], {});
        }
    }

    /**
     *  Return fieldSpecs to be used by ruby-content-property-helper
     *  @property props
     *  @property ctx.dataPathArray - need to include this for nested components
     *  @params {Object} ctx.selfModule - the module of the root caller
     * */
    , getFieldSpecFromProps: function getFieldSpecFromProps(props, ctx) {
        var selfModule = ctx.selfModule;


        var baseFieldSpec = baseFieldMixin.getFieldSpecFromProps(props, ctx);
        var baseFieldSpecFirstItem = _lodash2.default.first(baseFieldSpec);

        var options = props.options;


        var nonDefaultOptions = options.filter(function (option) {
            return !option.isDefault;
        });

        var retval = [_extends({}, baseFieldSpecFirstItem, {
            //# Wrap children with the namespace keys
            children: nonDefaultOptions.map(function (option) {
                return {
                    componentName: 'Object',
                    label: option.display || option.text,
                    key: option.value,
                    children: props.children
                };
            })
        })].concat(_toConsumableArray(specsFromNodes_andCtx(selfModule, props.children, ctx)));

        return retval;
    },

    getFieldKeypathArr: function getFieldKeypathArr() {
        var keypathArr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];


        var activeNamespace = this.getActiveNamespace();
        var activeOption = this.props.options.filter(function (option) {
            return option.value == activeNamespace;
        })[0];

        if (activeOption && !activeOption.isDefault) {
            keypathArr.unshift(activeNamespace);
            this.props.key && keypathArr.unshift(this.props.key);
        }

        var parentComponent = this.getParent();

        return parentComponent.getFieldKeypathArr(keypathArr);
    }

    //== AUTO POPULATE OVERRIDES ===================//
    , _promisedFormValueFromFieldSpecs: function _promisedFormValueFromFieldSpecs() {
        var _this2 = this,
            _arguments2 = arguments;

        var _props = this.props,
            options = _props.options,
            selfKey = _props.key;


        var defaultNamespace = this.getDefaultNamespace();
        //# generate sample data for every namespace
        return Promise.map(options, function (option) {
            return baseFieldMixin._promisedFormValueFromFieldSpecs.apply(_this2, _arguments2).then(function (formValue) {
                return _defineProperty({}, option.value, formValue[selfKey]);
            });
        }).then(function (formValues) {
            return _defineProperty({}, selfKey, _extends.apply(undefined, _toConsumableArray(formValues)));
        }).then(function (formValue) {
            return _extends({}, formValue, _lodash2.default.get(formValue, [selfKey, defaultNamespace]));
        });
    }
});

module.exports = RCNamespaceSelector;