'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _LinkUrlConnector = require('./reactComponents/LinkUrlConnector');

var _LinkUrlConnector2 = _interopRequireDefault(_LinkUrlConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;
var defaultTextProps = CONSTANTS.DEFAULT_TEXT_PROPS;
var defaultLinkProps = CONSTANTS.DEFAULT_LINK_PROPS;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');

var FieldText = require('@rubyapps/ruby-component-field-text');
var FieldUrl = require('@rubyapps/ruby-component-field-url');

var RCLinkUrl = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin],
    propTypes: {} //# some are already included by the baseFieldMixin
    , componentName: componentName,
    getDefaultProps: function getDefaultProps(props) {
        var selfRequired = _.get(props, 'verify.required', false);
        var selfMode = props.mode;
        return {
            childrenPropsByKey: {
                text: _extends({}, defaultTextProps, {
                    ruby_permissions: props.ruby_permissions,
                    "verify": { "required": selfRequired },
                    mode: selfMode
                }),
                link: _extends({}, defaultLinkProps, {
                    ruby_permissions: props.ruby_permissions,
                    "verify": { "required": selfRequired },
                    mode: selfMode
                })
            }
        };
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();

        return {
            selfSelector: selfSelector
        };
    },
    children: function children() {
        var parentID = this.getID();

        return [RubyComponent.createElement(FieldText, _extends({
            id: parentID + '.text'
        }, this.props.childrenPropsByKey.text)), RubyComponent.createElement(FieldUrl, _extends({
            id: parentID + '.link'
        }, this.props.childrenPropsByKey.link))];
    },
    getReactClass: function getReactClass() {
        return _LinkUrlConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var LinkUrlComponent = this.getReactClass();

        return React.createElement(LinkUrlComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'LinkUrlComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'LinkUrlComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'LinkUrlComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS
});

module.exports = RCLinkUrl;