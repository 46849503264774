'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _Password = require('./Password');

var _Password2 = _interopRequireDefault(_Password);

var _zxcvbn = require('zxcvbn');

var _zxcvbn2 = _interopRequireDefault(_zxcvbn);

var _analyzer = require('../../common/analyzer');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function passwordStrengthIndicationAndSuggestions(passwordValue) {
    var analysis = (0, _zxcvbn2.default)(passwordValue || '');
    var score = analysis.score,
        feedback = analysis.feedback;
    var suggestions = feedback.suggestions;

    var passwordMeterResults = (0, _analyzer.analyze)(passwordValue);

    return _extends({
        suggestions: suggestions
    }, passwordMeterResults);
}

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector,
        currentUserSelector = _selfModule$getDepend.currentUserSelector,
        pairedComponentAction = _selfModule$getDepend.pairedComponentAction;

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);

        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);

        var fieldObject = selfModule.props.key ? _lodash2.default.pick(selfState.fields[selfModule.props.key], ['value', 'error']) : {};

        var passwordAnalysis = fieldObject.value && ownProps.showStrength ? passwordStrengthIndicationAndSuggestions(fieldObject.value) : {};

        var error = fieldObject.error && fieldObject.error.message ? fieldObject.error : passwordAnalysis.errors ? {
            message: {
                msg: [].concat(passwordAnalysis.score != -1 ? 'Password Strength: ' + passwordAnalysis.status : [], passwordAnalysis.errors),
                level: passwordAnalysis.score != -1 ? 'info' : 'error'
            }
        } : {};

        var mappedProps = _extends({
            showPassword: selfState.showPassword,
            pairedField: selfModule.props.pairedField
        }, ownProps, mixinFieldProps, _extends(fieldObject, {
            richErrorMessages: _lodash2.default.compact([error.message]).concat(ownProps.showStrength && passwordAnalysis.suggestions ? {
                msg: passwordAnalysis.suggestions,
                level: 'info'
            } : [])
        }));

        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch),
            delegateRefreshParentErrors: selfModule.refreshParentErrors.bind(selfModule),
            pairedComponentAction: pairedComponentAction && (0, _redux.bindActionCreators)(pairedComponentAction.generators, dispatch)
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Password2.default);
}