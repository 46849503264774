'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.hydrateDependenciesForRubyComponent = hydrateDependenciesForRubyComponent;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function hydrateDependenciesForRubyComponent(selfModule, dependencies) {
    var rootModule = selfModule.getRoot();

    return _lodash2.default.reduce(dependencies, function (collector, componentId, key) {
        collector[key] = rootModule.findDescendentByID(componentId);
        return collector;
    }, {});
} /*
      dependencies: {
          [key]: idOfComponent
      }
  */