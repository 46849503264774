'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTableState = getTableState;
exports.getUpdatedSortDirection = getUpdatedSortDirection;
exports.getPagesLoaded = getPagesLoaded;
exports.getRemoteProvider = getRemoteProvider;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getTableState(store) {
  var state = store.getState().toJS();

  return {
    page: _lodash2.default.get(state, ['pageProperties', 'currentPage']),
    pageSize: _lodash2.default.get(state, ['pageProperties', 'pageSize']),
    filter: _lodash2.default.get(state, 'filter'),
    sortColumn: _lodash2.default.get(state, ['pageProperties', 'sortColumns']),
    sortDirection: _lodash2.default.get(state, ['pageProperties', 'sortAscending']) ? 'asc' : 'desc'
  };
}

function getUpdatedSortDirection(store, sortColumn) {
  var state = store.getState();
  var sortColumns = state.getIn(['pageProperties', 'sortColumns']);

  // Default to ascending
  if (!sortColumns || !sortColumns.includes(sortColumn)) return 'asc';

  // Flip the sort direction
  return state.getIn(['pageProperties', 'sortAscending']) ? 'desc' : 'asc';
}

function getPagesLoaded(store) {
  var state = store.getState();

  return state.get('pagesLoaded');
}

function getRemoteProvider(store) {
  var state = store.getState();

  return state.get('remoteProvider').toJS();
}