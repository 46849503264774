'use strict';

var _reduxBatchedActions = require('redux-batched-actions');

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var Promise = require('bluebird');


var request = require('@rubyapps/ruby-superagent');

var fieldForm_CONSTANTS = require('@rubyapps/ruby-component-field-form/src/common/constants');

function typesWithID(id) {
    return {
        SET_PRISTINE_DATA: '@@ruby-app/' + id + '/SET_PRISTINE_DATA',
        SET_URL: '@@ruby-app/' + id + '/SET_URL',
        RESET_STORE: '@@ruby-app/' + id + '/RESET_STORE'
    };
}

var generators = {

    retrieveDataAtEndpoint: function retrieveDataAtEndpoint(endpoint, thenableArguments) {
        var selfModule = this;

        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES,
            generators = _getAction.generators;

        return function (dispatch) {

            var promisedRequest = endpoint && request.get(endpoint).then(function success(response) {
                //
                var data = response.body;
                //# set the response data

                dispatch((0, _reduxBatchedActions.batchActions)([generators.clearRequestObject(), generators.setPristineData(data), generators.setUrl(endpoint)]));

                //return Promise.resolve(data).then(...thenableArguments);

                return data;
            });

            dispatch(generators.setRequestObject(promisedRequest));

            if (thenableArguments) {
                return promisedRequest.then.apply(promisedRequest, _toConsumableArray(thenableArguments));
            }

            return promisedRequest;
        };
    },

    setPristineData: function setPristineData(data) {
        var TYPES = this.getAction().TYPES;
        return {
            type: TYPES.SET_PRISTINE_DATA,
            payload: {
                data: data
            }
        };
    },
    setUrl: function setUrl(data) {
        var TYPES = this.getAction().TYPES;
        return {
            type: TYPES.SET_URL,
            payload: {
                data: data
            }
        };
    }

    //# NOTE: maybe? not really an action that creates state but does it count if
    //# it's actually the children generating state changes?
    , formValueToChildrenLocalState: function formValueToChildrenLocalState(formValue) {
        var _this = this;

        return function (dispatch) {

            //# return a batch action
            var collector = [];
            var collectAction = function collectAction(value) {
                collector.push(value);
            };

            var promisedOnceResolved = {
                resolve: null,
                reject: null
            };
            var promiseTree = _this.formValueToChildrenLocalState(formValue, collectAction, {
                promisedOnceResolved: new Promise(function (resolve, reject) {
                    promisedOnceResolved.resolve = resolve;
                    promisedOnceResolved.reject = reject;
                })
            });

            promiseTree.then(function () {
                dispatch((0, _reduxBatchedActions.batchActions)(collector));

                //console.log('promisedOnceResolved.resolve for: ', this.getID())
                promisedOnceResolved.resolve && promisedOnceResolved.resolve();
            });
        };
    },

    resetChildrenLocalState: function resetChildrenLocalState() {
        var _this2 = this;

        return function (dispatch) {
            //# return a batch action
            var collector = [];
            var collectAction = function collectAction(value) {
                collector.push(value);
            };

            var promiseTree = _this2.resetChildrenLocalState(collectAction);

            promiseTree.then(function () {
                dispatch((0, _reduxBatchedActions.batchActions)(collector));
            });
        };
    },

    resetStore: function resetStore() {
        var TYPES = this.getAction().TYPES;
        return {
            type: TYPES.RESET_STORE
        };
    }
};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};