'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reduxBatchedActions = require('redux-batched-actions');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _index = require('@rubyapps/ruby-component-builder/src/common/index');

var _index2 = _interopRequireDefault(_index);

var _RepeaterConnector = require('./reactComponents/RepeaterConnector');

var _RepeaterConnector2 = _interopRequireDefault(_RepeaterConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Promise = require('bluebird');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var RubyWords = require('@rubyapps/ruby-words');


var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;
var COMPONENT_BUILDER__CONSTANTS = require('@rubyapps/ruby-component-builder/src/common/constants');

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationsMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var fieldSetMixin = require('@rubyapps/ruby-component-mixin-field-set');
var fieldDynamicMixin = require('@rubyapps/ruby-component-mixin-field-dynamic');

var _require = require('@rubyapps/ruby-content-property-helper/src/client/fieldsSpec.js'),
    specsFromNodes_andCtx = _require.specsFromNodes_andCtx;

var rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');

var mixinUtils = require('@rubyapps/ruby-component-mixin-utils');

var action = require('./action');
var reducer = require('./reducer');

var DEFAULT_CHILDREN_ERROR_MESSAGE = 'Please review. Some fields are invalid.';

var uuid = require('node-uuid');
var request = require('@rubyapps/ruby-superagent');

var jsonselect = require('JSONSelect');
var fieldWithKeySelector = jsonselect.compile('object:has(:root > .key)');

var ERROR_LEVEL_HIERARCHY = ['info', 'warning', 'error'];
function maxErrorLevel_fromLevels(levels) {
    return _lodash2.default.maxBy(levels, function (el) {
        return ERROR_LEVEL_HIERARCHY.indexOf(el);
    });
}

function newID() {
    return uuid.v1();
}

var DEFAULT_CHILD_SPEC = {
    componentName: 'Fieldset',
    styleObject: {
        Card: {
            style: {
                margin: '0'
            }
        }
    }
};
var RCRepeater = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationsMixin, fieldPropsMixin, fieldDynamicMixin],
    propTypes: {
        limit: PropTypes.number,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.object,
            text: PropTypes.string,
            type: PropTypes.string,
            interpolateLabel: PropTypes.oneOf([PropTypes.bool, PropTypes.oneOf(['mustache', 'lodash'])]),
            label: PropTypes.string
            //# NOTE: we do not want to use text as the dynamic label
            //# because text must be static
            //# label can be using mustache or lodash template literals
            //# eg. `My Module ${data.fieldKey} ${modules.rubyWords.ellipsify(data.fieldKey, 100)}`
            //# NOTE that the data object is relative to the repeater module instance. so Given ModuleA, 'data' is the data inside ModuleA
        }))
        //, key: PropTypes.string
        //, constraints: PropTypes.object
        , defaultItemSize: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number
        }),
        sortable: PropTypes.bool //#20170616 - TODO
        , showTokenTagger: PropTypes.bool,
        showMenu: PropTypes.bool,
        url: PropTypes.string
    },
    getDefaultProps: function getDefaultProps() {
        return {
            columns: 1,
            defaultItemSize: {
                height: 80
            },
            limit: Infinity,
            options: [],
            sortable: true
        };
    },
    action: action,
    reducer: reducer,
    componentName: componentName,
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();
        var selfAction = this.getAction();

        return {
            selfSelector: selfSelector,
            selfAction: selfAction
        };
    },
    getReactClass: function getReactClass() {
        var ConnectedRepeater = _RepeaterConnector2.default.apply(this);
        ConnectedRepeater.rubyDebug = true;
        return ConnectedRepeater;
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var RepeaterComponent = this.getReactClass();
        return _react2.default.createElement(RepeaterComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'RepeaterComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'RepeaterComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'RepeaterComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS,

    children: function children() {
        var _this = this;

        if (this.getStore() == undefined || !this.getState()) {
            return [];
        }
        var selfState = this.getState();
        var childrenIdToTypeMap = selfState.childrenIdToTypeMap,
            childrenOrderById = selfState.childrenOrderById,
            userAddedChildrenIdMap = selfState.userAddedChildrenIdMap,
            optionsByType = selfState.optionsByType;


        var defaultItemSize = this.props.defaultItemSize;
        if (Object.keys(defaultItemSize).length == 0) {
            defaultItemSize = this.getDefaultProps().defaultItemSize;
        }

        if (this._cachedChildrenByUUID == undefined) {
            this._cachedChildrenByUUID = {};
        }
        var cachedChildrenByUUID = this._cachedChildrenByUUID;
        var childrenComponentUUIDs = [];
        var childrenComponents = childrenOrderById.map(function (childID, index) {
            var cachedChild = cachedChildrenByUUID[childID];
            childrenComponentUUIDs.push(childID);
            if (cachedChild) {
                return cachedChild;
            }

            var optionForChildID = optionsByType[childrenIdToTypeMap[childID]];
            if (!optionForChildID) {
                return undefined;
            }

            var optionValue = _lodash2.default.isArray(optionForChildID.value) ? optionForChildID.value : [optionForChildID.value];

            var childFormJS = _extends({}, DEFAULT_CHILD_SPEC, defaultItemSize, _lodash2.default.omit(optionForChildID, CONSTANTS.OMIT_OPTION_PROPS_FOR_FORM) //# omit value because it's not a valid value for form
            , {
                children: optionValue,
                key: childID,
                label: optionForChildID.label || optionForChildID.text,
                parentKey: _this.props.key,
                expandedByDefault: index < COMPONENT_BUILDER__CONSTANTS.EXPAND_FIRST_NTH_CHILDREN || userAddedChildrenIdMap.hasOwnProperty(childID)
            });

            var builtChildComponent = (0, _index2.default)(childFormJS, undefined, _this.props.__depth + 1);

            cachedChildrenByUUID[childID] = builtChildComponent;

            return builtChildComponent;
        }).filter(function (child) {
            return child != undefined;
        });

        //# purge old cache
        this._cachedChildrenByUUID = _lodash2.default.pick(cachedChildrenByUUID, childrenComponentUUIDs);

        return [childrenComponents];
    }

    //== UTILITIES =============================================//
    , hasRemoteOptions: function hasRemoteOptions() {
        return !_lodash2.default.isNil(this.props.url);
    },
    normalizedOptions_withOptions: function normalizedOptions_withOptions(options) {
        var mode = this.props.mode;


        if (mode) {
            fieldWithKeySelector.forEach(options, function (match) {
                match.mode = mode;
            });
        }

        var normalizedOptions = options.map(function (option) {
            return _extends({}, option, {
                type: option.type ? option.type : RubyWords.slug(option.text)
            });
        });
        return normalizedOptions;
    },

    optionsByType_fromOptions: function optionsByType_fromOptions(options) {

        return options.reduce(function (collector, option) {
            collector[option.type] = option;
            return collector;
        }, {});
    },
    getIndexForChildId: function getIndexForChildId(id) {
        var _getState = this.getState(),
            childrenOrderById = _getState.childrenOrderById;

        return childrenOrderById.indexOf(id);
    },

    revalidateSelfValueAndReflectError: function revalidateSelfValueAndReflectError(value /*optional*/) {
        //# validate own value and set error
        var key = this.key();
        var targetValue = value != undefined ? value : this.formValue()[key];
        var errors = this.validateValue_forField(targetValue, key);
        var errorMessage = _lodash2.default.get(errors, [0, 'message'], null);

        //# TODO: confirm that this is ok:
        //# This only shows error for itself, not the error because of children errors
        this.getStore().dispatch(this.getAction().generators.setFieldErrorMessageByKey(errorMessage, key));

        return errorMessage; //# return errorMessage so we know if we have errors
        /*
        this.getStore().dispatch({
            type: this.getAction().TYPES.SET_FIELD_VALUE_BY_KEY
                , payload: {
                    key: this.key()
                    , errors
                    , error: errors[0]? errors[0] :{message: null}
                }
        });
        */
    }

    //== Overrides ================================================//
    , getChildren: function getChildren() {
        var _this2 = this;

        //# Return the children in the correct order
        var selfState = this.getState();
        if (_lodash2.default.isNil(selfState)) {
            return this._children;
        }

        var childrenOrderById = selfState.childrenOrderById;


        var allChildren = this._children;

        var sortedChildren = childrenOrderById.reduce(function (collector, childId) {
            var foundChild = _this2.findChildByID(childId);
            if (foundChild) {
                collector.push(foundChild);
            }
            return collector;
        }, []);

        var finalChildren = sortedChildren.concat(_lodash2.default.differenceBy(allChildren, sortedChildren, function (el) {
            return el.getID();
        }));
        //# return remainder children as well because we don't want to lose them

        return finalChildren;
    },
    getInitialState: function getInitialState() {
        //# NOTE: this type is a fake type just to get the reducer to return the default state
        var initialState = reducer.call(this, fieldValidationsMixin.getInitialState.apply(this), { type: '__GET_INITIAL_STATE' });

        var normalizedOptions = this.normalizedOptions_withOptions(this.props.options);

        var childrenOrderById = [];
        var childrenIdToTypeMap = {};
        var userAddedChildrenIdMap = {};

        var augmentedFormValueForHydration = {};
        var rerenderTimestamp = null;
        if (this.props.default) {
            rerenderTimestamp = new Date();
            childrenOrderById = this.props.default.map(function (repeaterChild) {
                //# Generate the uuids for each child and map it to the children

                var childId = newID();
                childrenIdToTypeMap[childId] = repeaterChild.type;
                augmentedFormValueForHydration[childId] = repeaterChild;
                return childId;
            });
        }

        //# TODO: figure out a way to seed with default value if available
        //# This doesn't handle augmentedFormValueForHydration nor does it account for hasRemoteOptions();
        //# we're ok using this for now since we're using this to inject default module instances
        //# and not data in each module
        return _extends({}, initialState, {
            options: normalizedOptions,
            optionsByType: this.optionsByType_fromOptions(normalizedOptions),
            childrenOrderById: childrenOrderById,
            childrenIdToTypeMap: childrenIdToTypeMap,
            userAddedChildrenIdMap: userAddedChildrenIdMap
            //# NOTE: This seed relies on onReduxInit to call on updateChildren()
        });
    },
    _formValueFromLocalState: function _formValueFromLocalState(selfState, isError) {
        var predicateFormatter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (value) {
            return value;
        };
        var entireState = arguments[3];
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        if (selfState == undefined) {
            return undefined;
        }
        var selfID = this.getID();
        var selfKey = this.props.key;
        var childrenOrderById = selfState.childrenOrderById,
            childrenIdToTypeMap = selfState.childrenIdToTypeMap,
            selectedOptionsRetrieved = selfState.selectedOptionsRetrieved;


        var children = this.getChildren();

        var arrOfReducedChildrenState = _lodash2.default.reduce(children, function (collector, child, index) {
            var childID = child.getID();
            var childFormValue = child.formValueFromLocalState ? child._formValueFromLocalState(selfState[childID], isError, predicateFormatter, entireState, options) : undefined;
            if (childFormValue != undefined) {
                collector.push(childFormValue);
            }
            return collector;
        }, []);

        var reducedChildrenState = _extends.apply(undefined, [{}].concat(_toConsumableArray(arrOfReducedChildrenState)));

        var selfValue = childrenOrderById.filter(function (id) {
            return reducedChildrenState && Object.keys(reducedChildrenState).indexOf(id) > -1;
        }).map(function (childId) {
            return _extends({ type: childrenIdToTypeMap[childId] }, reducedChildrenState[childId]);
        });

        var formattedSelfValue = predicateFormatter(selfValue);

        if (selfKey) {
            return options.excludeNull && formattedSelfValue.length == 0 ? {} : _defineProperty({}, selfKey, formattedSelfValue);
        } else {
            return undefined;
        }
    },
    deferredFormValueToLocalState: function deferredFormValueToLocalState(formValue) {
        console.warn('[DEPRECATED 20180905] - Should be deprecated. If you see this warning, please investigate what is calling it');
        //# the formValue given would be the nested value without selfKey;
        var store = this.getStore();
        var dispatch = store.dispatch;
        var selfKey = this.props.key;
        var formValueForLocalState = _defineProperty({}, selfKey, formValue);

        this._formValueToLocalState.call(this, formValueForLocalState, dispatch);
    },
    _formValueToLocalState: function _formValueToLocalState(formValue, dispatchOrCollect, isError, entireFormValue) {
        var _this3 = this;

        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        var selfID = this.getID();
        var selfKey = this.props.key;
        if (!selfKey) {
            return undefined;
        } //# cannot nest a rubyComponent unowned by the repeater field here, and the repeater field MUST have a key

        //# support omit field picker
        var shouldOmitField = _lodash2.default.isFunction(options.omitFieldPicker) && options.omitFieldPicker.apply(options, [this].concat(Array.prototype.slice.call(arguments)));

        //# TODO: figure out how we would be able to reseed the info-mode fields inside repeater modules
        //# for now we just blanket skip the module if repeater is not info-mode
        //# IDEA: - if we're omitting the fields, we should attempt to traverse the nested children values and see if they are omitting as well, but we shouldn't clear out the existing modules
        if (shouldOmitField) {
            return undefined;
        }

        var _getDependencies = this.getDependencies(),
            actions = _getDependencies.selfAction.generators;

        var this__store = this.getStore();
        var this__dispatch = this__store.dispatch;

        var previousRepeaterFormValue = this.formValue()[selfKey] || [];
        var repeaterFormValue = formValue[selfKey] || [];

        var augmentedFormValueForHydration = void 0;

        var promiseArr = [];
        var promiseForDataHydration = Promise.resolve(null);
        if (!isError) {
            //# STEP: Modify formValue since it's current
            //#     [this.props.key] : [ array of objects ]
            //#         when it needs to be like:
            //#     [this.props.key] : {
            //          [uuid_1] : {
            //              ...values
            //          }
            //          , [uuid_2] : {...}
            //#     }
            var childrenIdToTypeMap = {};
            augmentedFormValueForHydration = {};
            var childrenOrderById = repeaterFormValue.map(function (repeaterChild) {
                //# Generate the uuids for each child and map it to the children

                var childId = newID();
                childrenIdToTypeMap[childId] = repeaterChild.type;
                augmentedFormValueForHydration[childId] = repeaterChild;
                return childId;
            });

            //# need to short-circuit and force an update first because the dispatchOrCollect might be a collector
            //# if it's a collector, that means the updates are being batched
            //# and the children population won't happen because we're not regenerating the children
            var newChildrenState = {
                childrenOrderById: childrenOrderById,
                childrenIdToTypeMap: childrenIdToTypeMap
            };
            var actionInstance = actions.replaceState(newChildrenState);
            promiseArr.push(this__dispatch(actionInstance));
            //# We need to trigger this again because the resetStore getting called wipes out the childrenOrderById
            promiseArr.push(dispatchOrCollect(actionInstance));

            if (this.hasRemoteOptions()) {
                //# check if we need to retrieveSelectedOptions

                promiseForDataHydration = this.retrieveRemoteOptionsForState(newChildrenState).then(function (optionValues) {
                    if (optionValues && optionValues.length > 0) {
                        _this3.updateLocalState_forAvailableOptionValues(optionValues);
                    }

                    _this3.updateChildren(true, this__dispatch);
                    return optionValues;
                });
            } else {
                if (!_lodash2.default.isEqual(this.props.options, _lodash2.default.result(this, 'getState.options'))) {
                    this.updateLocalState_forAvailableOptionValues(this.props.options);
                }
                //# previously, we were relying on the Repeater's redux subscription
                //# to handle updating children during localState hydration from formValue
                //# we should explicitly update children here

                this.updateChildren(true, this__dispatch);
            }
        } else {
            //# isError == true
            var selfState = this.getState();
            var _childrenOrderById = selfState.childrenOrderById;

            if (_lodash2.default.isArray(repeaterFormValue)) {
                augmentedFormValueForHydration = repeaterFormValue.reduce(function (collector, repeaterChild, index) {
                    //# Get the uuids for each child based on the index
                    //# and augment the form with it
                    var childId = _childrenOrderById[index];
                    if (repeaterChild) {
                        //# only set errors if it exists
                        //# this is only a problem with the old repeater modules
                        //# where the format is 
                        /* 
                            {
                                type: moduleA
                                fieldA
                                fieldB
                            }
                            //# whereas with new repeaters it's: 
                            {
                                type: moduleA
                                moduleA: {
                                    fieldA
                                    fieldB
                                }
                            }
                        */
                        //# with the former structure, it may result in errors like [undefined, {fieldA:"error message"}]
                        collector[childId] = repeaterChild;
                    }

                    return collector;
                }, {});
            } else if (_lodash2.default.isString(repeaterFormValue)) {
                //# root-level error message
                augmentedFormValueForHydration = repeaterFormValue;
            }
        }

        //# STEP: rubyComponents should have been built to support all of the children

        //# STEP: continue populating children values
        promiseArr.push(promiseForDataHydration.then(function () {

            var children = _this3.getChildren();
            var deferredBatchActions = [];
            var deferredCollector = function deferredCollector(action) {
                deferredBatchActions.push(action);
            };

            //# TODO 20170804 - might need to remove stale formValues
            /*
                const deferredFormValue = (oldDeferredFormValue || []).filter((module) =>
                    availableTypes.indexOf(module.type) > -1
                );
            */
            var promiseArr = void 0;
            if (_lodash2.default.isString(augmentedFormValueForHydration)) {
                //# if it's a root-level object. Typically only for error messages
                promiseArr = [deferredCollector(actions.setFieldErrorMessageByKey(augmentedFormValueForHydration, selfKey))];
            } else {
                promiseArr = children.reduce(function (collector, child) {
                    if (child._formValueToLocalState) {

                        collector.push(child._formValueToLocalState(augmentedFormValueForHydration, deferredCollector, isError, entireFormValue, options));
                    }
                    return collector;
                }, []);
            }

            var promiseTree = Promise.all(promiseArr);

            return promiseTree.then(function () {
                this__dispatch((0, _reduxBatchedActions.batchActions)(deferredBatchActions));
            });
        }));

        return Promise.all(promiseArr);
    }
    /* //# NOTE can potentially remove since it looks like it's a copy of baseFieldMixin
    , _objectValueFromLocalState: function(selfState, isError, limitToTabWithLabel) {
        if (selfState == undefined) {return undefined;}
        const selfID = this.getID();
         const children = this.getChildren();
         let childrenHasValues = false;
        let maxChildErrorLevel = null;
        const arrOfReducedChildrenState = _.reduce(children, (collector, child, index) => {
            const childID = child.getID();
            if (
                child.componentName == 'rubyComponentFieldTab' 
                && limitToTabWithLabel 
                && child.props.label != limitToTabWithLabel
            ) {
                return collector;
            }
             const childObjectValue = child._objectValueFromLocalState ? 
                child._objectValueFromLocalState(selfState[childID], isError, limitToTabWithLabel) 
                : undefined;
            if (childObjectValue != undefined) {
                collector.push(childObjectValue);
                 const childObjectValueAtID = childObjectValue[childID];
                if (Object.keys(childObjectValueAtID).length && childObjectValueAtID._value !== null ) {
                    //# only has value if the leaf nodes has value
                    childrenHasValues = true;
                    maxChildErrorLevel = maxErrorLevel_fromLevels([
                        maxChildErrorLevel
                        , _.get(childObjectValueAtID, '_value.level', 'error')
                    ]);
                }
            }
            return collector;
        }, []);
         const reducedChildrenState = Object.assign({}, ...arrOfReducedChildrenState);
         const reducedChildrenAvail = Object.keys(reducedChildrenState).length > 0;
         let selfValue = selfState.fields? this._getValuesFromFieldsObject(selfState.fields, isError) :{};
         if (isError) { //# add in _value if necessary
            const doesChildrenHaveErrors = childrenHasValues && reducedChildrenState._value !== null;
             selfValue._value = doesChildrenHaveErrors?
                {
                    msg: DEFAULT_CHILDREN_ERROR_MESSAGE
                    , level: maxChildErrorLevel
                }
                :null;
        }
         return (reducedChildrenAvail) ?
                {
                    [selfID]: Object.assign(
                        reducedChildrenState
                    , selfValue || {})
                } :
            selfValue ?
                {
                    [selfID] : selfValue
                } :
                undefined;
    }
    */
    , _objectValueToLocalState: function _objectValueToLocalState(objectValue, dispatchOrCollect, isError) {
        var selfID = this.getID();
        var selfKey = this.props.key;

        var children = this.getChildren();

        var action = this.getAction();
        var _action$generators = action.generators,
            setFieldValueByKey = _action$generators.setFieldValueByKey,
            setFieldErrorMessageByKey = _action$generators.setFieldErrorMessageByKey,
            setFieldErrorObjectByKey = _action$generators.setFieldErrorObjectByKey;


        if (!_lodash2.default.isNil(objectValue) && objectValue.hasOwnProperty(selfID)) {
            var objectValueForID = objectValue[selfID];
            var hasObjectValueForKey = objectValueForID.hasOwnProperty(selfKey);
            var objectValueForKey = objectValueForID[selfKey]; //# the key is nested in the object
            //const objectHas_value = objectValueForID.hasOwnProperty('_value');
            if (!_lodash2.default.isNil(objectValueForID)) {
                children.forEach(function (child) {
                    if (child._objectValueToLocalState) {
                        child._objectValueToLocalState(objectValueForID, dispatchOrCollect, isError);
                    }
                });

                //# parent error
                if (isError) {
                    //# include message for self
                    if (setFieldErrorMessageByKey /*&& objectHas_value*/) {
                            //# NOTE: we shouldn't need to check for whether objectHas_value
                            //# if it doesn't have value, it'll get cleared
                            //# set the root-level `error.message`, not the `fields.${key}.error.message`
                            dispatchOrCollect(setFieldErrorMessageByKey(objectValueForID._value));
                        }
                }
            }

            //# this sets the field value. This needs to be outside of the if-else statement
            //# for children nodes because we might have field values in a parent field
            //# like the namespaceSelector has children and its own value
            if (hasObjectValueForKey) {
                if (isError) {
                    if (setFieldErrorMessageByKey) {
                        //# we can set `fields.${key}.error.message` here
                        dispatchOrCollect(setFieldErrorObjectByKey(objectValueForKey, selfKey));
                    }
                } else {
                    dispatchOrCollect(setFieldValueByKey(this.fieldValueFromFormValue_forKey(objectValueForKey, selfKey), selfKey, true));
                }
            }
        }
    },

    onReduxInit: function onReduxInit(dispatch) {
        //# updateChildren if this.props.default
        if (this.props.default) {
            //# need to trigger an initial update of children
            this.updateChildren(true, dispatch);
        }
    }

    // class-level Utility methods
    , updateLocalState_forAvailableOptionValues: function updateLocalState_forAvailableOptionValues(optionValues) {
        var store = this.getStore();

        var _getDependencies2 = this.getDependencies(),
            actions = _getDependencies2.selfAction.generators;

        var _getState2 = this.getState(),
            oldChildrenOrderById = _getState2.childrenOrderById,
            oldChildrenIdToTypeMap = _getState2.childrenIdToTypeMap,
            selectedOptionsRetrieved = _getState2.selectedOptionsRetrieved;

        var availableTypes = optionValues.map(function (value) {
            return value.type;
        });
        var childrenIdToTypeMap = _lodash2.default.pickBy(oldChildrenIdToTypeMap, function (type) {
            return availableTypes.indexOf(type) > -1;
        });
        var availableIds = Object.keys(childrenIdToTypeMap);
        var childrenOrderById = oldChildrenOrderById.filter(function (id) {
            return availableIds.indexOf(id) > -1;
        });

        //# TODO: might want to merge optionValues
        var actionInstance = actions.replaceState({
            childrenOrderById: childrenOrderById,
            childrenIdToTypeMap: childrenIdToTypeMap,
            options: optionValues,
            optionsByType: this.optionsByType_fromOptions(optionValues)
        });
        store.dispatch(actionInstance);
    },
    retrieveRemoteOptionsForState: function retrieveRemoteOptionsForState(state) {
        var selfModule = this;
        var url = this.props.url;
        var childrenIdToTypeMap = state.childrenIdToTypeMap,
            childrenOrderById = state.childrenOrderById;

        var selectedModuleTypes = childrenOrderById.map(function (id) {
            return childrenIdToTypeMap[id];
        });

        if (!url || selectedModuleTypes.length === 0) {
            return Promise.resolve(undefined);
        }
        return request.get(url).query({ id: selectedModuleTypes.join(',') }).then(function success(response) {
            return _lodash2.default.get(response, 'body.data');
        }).catch(function error(err) {
            var showNotificationModal = selfModule.showNotificationModal.bind(selfModule);
            showNotificationModal({
                type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR,
                title: 'Error Retrieving Profile Modules'
            });
        });
    }
    // This is used to filter against the repeater module types
    // ex. for Advanced Search Filters
    /**
     *  Return fieldSpecs to be used by ruby-content-property-helper
     *  @property props
     *  @property ctx.dataPathArray - need to include this for nested components. dataPathArray is for the parent
     *  @params {Object} ctx.selfModule - the module of the root caller
     * */
    ,
    getFieldSpecFromProps: function getFieldSpecFromProps(props, ctx) {
        var _props$options = props.options,
            options = _props$options === undefined ? [] : _props$options,
            label = props.label;


        var baseFieldSpec = baseFieldMixin.getFieldSpecFromProps(props, ctx);
        var baseFieldSpecFirstItem = _lodash2.default.first(baseFieldSpec);

        var repeaterKey = _lodash2.default.get(baseFieldSpecFirstItem, 'key');
        var updatedDataPathArray = ctx.dataPathArray.concat(repeaterKey || []);

        var repeaterModuleTypeFieldSpec = _extends({}, baseFieldSpecFirstItem, {
            componentName: 'Hidden',
            listerConfig: {
                excludeFromFilterSelection: false,
                matchSpec: {
                    //# Need to override default dataSourceConfig for advanced filter options
                    //# since the value is not primitive
                    dataSourceConfig: {
                        text: 'text',
                        value: 'type'
                    }
                }
            },
            label: label + ' Type',
            key: 'type'

            //# NOTE dataPath should be the absolute path
            , dataPath: updatedDataPathArray.concat(['*', 'type']).join('.')
        });

        var retval = [].concat(_extends({}, baseFieldSpecFirstItem, {
            dataPath: updatedDataPathArray.join('.'),
            children: [].concat(
            //# NOTE: We want to add the type spec for Repeaters that have more than
            //# one repeater module option.
            options.length > 1 ? repeaterModuleTypeFieldSpec : [], this._fieldSpecsFromRepeaterDataPathArray_andOptions(updatedDataPathArray, options, ctx))
        }));

        return retval;
    },

    _fieldSpecsFromRepeaterDataPathArray_andOptions: function _fieldSpecsFromRepeaterDataPathArray_andOptions() {
        var repeaterDataPathArray = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        var _this4 = this;

        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
        var ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        var selfModule = ctx.selfModule;

        var hydratedFieldSpecsFromOptions = _lodash2.default.flatMap(options, function (option) {
            var moduleType = option.type,
                moduleLabel = option.text,
                _option$value = option.value,
                moduleValue = _option$value === undefined ? [] : _option$value;


            var fieldReducerFromDataPathArray = _this4._fieldReducerFnGenerator(moduleType, ctx);

            return _lodash2.default.castArray(moduleValue).reduce(fieldReducerFromDataPathArray(repeaterDataPathArray.concat('*')), []);
        });

        return hydratedFieldSpecsFromOptions;
    },

    _fieldReducerFnGenerator: function _fieldReducerFnGenerator(moduleType, ctx) {
        var selfModule = ctx.selfModule;


        var fieldReducerFromDataPathArray = function fieldReducerFromDataPathArray() {
            var dataPathArray = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
            return function (acc, props, idx) {

                var specsFromNodes = specsFromNodes_andCtx(selfModule, [props], _extends({}, ctx, { dataPathArray: dataPathArray
                }));

                return acc.concat(specsFromNodes.map(function (specFromNode) {
                    return _extends({}, specFromNode, { refKey: moduleType });
                }));
            };
        };

        return fieldReducerFromDataPathArray;
    },

    getFieldKeypathArr: function getFieldKeypathArr() {
        var keypathArr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        //# replace keypath[0] with the appropriate index
        //# since it's [uuid, key, path]
        if (keypathArr.length) {
            keypathArr[0] = this.getIndexForChildId(keypathArr[0]);
        }

        this.props.key && keypathArr.unshift(this.props.key);

        var parentComponent = this.getParent();

        return parentComponent.getFieldKeypathArr(keypathArr);
    }

    //== AUTO POPULATE OVERRIDES ===================//
    , _promisedFormValueFromFieldSpecs: function _promisedFormValueFromFieldSpecs() {
        var _this5 = this;

        var _props = this.props,
            limit = _props.limit,
            _props$options2 = _props.options,
            options = _props$options2 === undefined ? [] : _props$options2;


        var normalizedOptions = this.normalizedOptions_withOptions(options);
        //# if there's a limit, we can only create up to the limit amount
        //# otherwise, create one of each module
        //# NOTE: if limit exceeds the number of available options, we'll use the smallest number
        var limitAsArray = Array.apply(null, { length: Math.min(limit || Infinity, normalizedOptions.length) }).map(Number.call, Number);

        return Promise.map(limitAsArray, function (n, index) {
            var targetOption = normalizedOptions[index];

            var childFormJS = _extends({}, DEFAULT_CHILD_SPEC, targetOption, {
                children: _lodash2.default.castArray(targetOption.value)
            });

            //# create Repeater module then call on it
            var builtChildComponent = (0, _index2.default)(childFormJS);
            //# patch the component with parent

            builtChildComponent._parent = _this5;

            return builtChildComponent._promisedFormValueFromFieldSpecs().then(function (childFormValue) {
                return _extends({ type: targetOption.type }, childFormValue);
            });
        }).then(function (childrenFormValues) {

            return _defineProperty({}, _this5.props.key, childrenFormValues);
        });
    }
});

module.exports = RCRepeater;