'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var FieldMenuItem = _react2.default.createClass({
    displayName: 'FieldMenuItem',

    propTypes: {
        label: PropTypes.string,
        link: PropTypes.string
    },
    render: function render() {
        var _this = this,
            _React$createElement;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        return _react2.default.createElement(_MenuItem2.default, (_React$createElement = {
            primaryText: this.props.label,
            disabled: this.props.disabled,
            onTouchTap: function onTouchTap(evt) {
                window.open(_this.props.link);
            },
            'data-codecept-selector-node': 'MenuItem',
            'data-codecept-selector-file': 'ViewLink'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ViewLink'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ViewLink'), _React$createElement));
    }
});
exports.default = FieldMenuItem;