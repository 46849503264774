'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

/* jshint -W138 */

var defaultState = {
    open: false,
    loading: false,
    mode: 'all',
    activities: []
};

module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];
    var type = action.type,
        payload = action.payload;

    var _getAction = this.getAction(),
        TYPES = _getAction.TYPES;

    switch (type) {
        case TYPES.SET_DISPLAY:
            return _extends({}, state, {
                open: payload.open
            });
        case TYPES.SET_LOADING:
            return _extends({}, state, {
                loading: payload.loading
            });
        case TYPES.SET_MODE:
            return _extends({}, state, {
                mode: payload.mode
            });
        case TYPES.SET_ACTIVITIES:
            return _extends({}, state, {
                activities: payload.activities
            });
        default:
            return state;
    }
};