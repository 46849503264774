'use strict';

var _reduxBatchedActions = require('redux-batched-actions');

module.exports = function () {
    var id = this.getID();

    var TYPES = {
        APPEND_ITEM: '@ruby-app/' + id + '/APPEND_ITEM',
        SELECT_ITEM: '@ruby-app/' + id + '/SELECT_ITEM',
        CLEAR_SELECTED_ITEM: '@ruby-app/' + id + '/CLEAR_SELECTED_ITEM',
        REPLACE_ITEM: '@ruby-app/' + id + '/REPLACE_ITEM',
        UPDATE_DISPLAY_VALUE_BY_HASH: '@ruby-app/' + id + '/UPDATE_DISPLAY_VALUE_BY_HASH'
    };

    return {
        TYPES: TYPES,
        generators: {
            appendItem: function appendItem(item) {
                var _getAction = this.getAction(),
                    generators = _getAction.generators;

                var selfFieldValue = this.getFieldValue() || [];

                return generators.setFieldValue(selfFieldValue.concat(item));
            },
            selectItem: function selectItem(item) {
                var _getAction2 = this.getAction(),
                    TYPES = _getAction2.TYPES;

                return {
                    type: TYPES.SELECT_ITEM,
                    payload: {
                        selectedValue: item
                    }
                };
            },
            clearSelectedItem: function clearSelectedItem() {
                var _getAction3 = this.getAction(),
                    TYPES = _getAction3.TYPES;

                return {
                    type: TYPES.CLEAR_SELECTED_ITEM
                };
            },
            replaceChildItem: function replaceChildItem(existingItem, newItem) {
                var _getAction4 = this.getAction(),
                    TYPES = _getAction4.TYPES;

                return {
                    type: TYPES.REPLACE_ITEM,
                    payload: {
                        existingItem: existingItem,
                        newItem: newItem
                    }
                };
            },
            updateDisplayValueByHash: function updateDisplayValueByHash(displayValueByHash) {
                var _getAction5 = this.getAction(),
                    TYPES = _getAction5.TYPES;

                return {
                    type: TYPES.UPDATE_DISPLAY_VALUE_BY_HASH,
                    payload: {
                        displayValueByHash: displayValueByHash
                    }
                };
            },
            validateChildrenFields: function validateChildrenFields() {
                var selfModule = this;
                var children = this.getChildren();

                var collector = [];
                var collectAction = function collectAction(value) {
                    collector.push(value);
                };

                children.forEach(function (child) {
                    child.iterativelyTraverseChildren_withCallback(function (rcElement) {
                        var actions = rcElement.getAction().generators;
                        if (actions.hasOwnProperty('validateAllFields')) {
                            collectAction(actions.validateAllFields());
                        }
                    });
                });

                return (0, _reduxBatchedActions.batchActions)(collector);

                return function (dispatch) {
                    formModule.iterativelyTraverseChildren_withCallback(function (rcElement) {
                        var actions = rcElement.getAction().generators;
                        actions.validateAllFields && dispatch(actions.validateAllFields());
                    });
                };
            }
        }
    };
};