'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Toolbar = require('material-ui/Toolbar');

var _zoomIn = require('material-ui/svg-icons/action/zoom-in');

var _zoomIn2 = _interopRequireDefault(_zoomIn);

var _Loading = require('@rubyapps/ruby-react-components/src/client/view/Loading');

var _Loading2 = _interopRequireDefault(_Loading);

var _RaisedButton = require('material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _AdvancedSearchUtilityBar = require('./AdvancedSearchUtilityBar.cssModule');

var _AdvancedSearchUtilityBar2 = _interopRequireDefault(_AdvancedSearchUtilityBar);

var _AdvancedSearchUtilityBar_style = require('./AdvancedSearchUtilityBar_style');

var _AdvancedSearchUtilityBar_style2 = _interopRequireDefault(_AdvancedSearchUtilityBar_style);

var _Dropdown = require('@rubyapps/ruby-react-components/src/client/input/Dropdown');

var _Dropdown2 = _interopRequireDefault(_Dropdown);

var _AutoCompleteSelector = require('@rubyapps/ruby-react-components/src/client/input/AutoCompleteSelector');

var _AutoCompleteSelector2 = _interopRequireDefault(_AutoCompleteSelector);

var _reactVirtualized = require('react-virtualized');

var _matchSpecForFieldSpec = require('./matchSpecForFieldSpec');

var _matchSpecForFieldSpec2 = _interopRequireDefault(_matchSpecForFieldSpec);

var _FilterTagsToolbarGroup = require('./FilterTagsToolbarGroup');

var _FilterTagsToolbarGroup2 = _interopRequireDefault(_FilterTagsToolbarGroup);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var request = require('@rubyapps/ruby-superagent');

var PropTypes = _react2.default.PropTypes;

// Material UI Icons


// Material UI Components

var AutoCompleteSelector__propTypeKeys = Object.keys(_AutoCompleteSelector2.default.propTypes);
//import AutoComplete from 'material-ui/AutoComplete';


//# Statically defined inputs for filter


var SAVED_SEARCHES_DATAPATH = 'saved_searches';

var AddFilterToolbarGroup = _react2.default.createClass({
    displayName: 'AddFilterToolbarGroup',

    propTypes: {
        filterTags: _FilterTagsToolbarGroup2.default.propTypes.filterTags,
        filterableFields: PropTypes.arrayOf(PropTypes.object),
        filterableFields__dataSourceConfig: PropTypes.object,
        filterableFieldsOptions: PropTypes.shape({
            isFlat: PropTypes.bool
        }),

        griddleProps: PropTypes.object,
        isAddFilterVisible: PropTypes.bool,
        requestToInvalidateCacheTimestamp: PropTypes.string,

        savedSearches_optionsUrl: PropTypes.string,
        savedSearches_instanceRouteParser: PropTypes.object,

        delegateOnSavedSearchLoad: PropTypes.func,
        delegateAddToFilter: PropTypes.func
    },
    getDefaultProps: function getDefaultProps() {
        return {
            savedSearches_optionsUrl: '',
            savedSearches_instanceRouteParser: { reverse: function reverse() {
                    return '';
                } },
            delegateOnSavedSearchLoad: function delegateOnSavedSearchLoad() {},
            delegateAddToFilter: function delegateAddToFilter(selectedField, selectedMatch, searchInputItem) {},
            filterableFieldsOptions: {
                isFlat: false
            }
        };
    },
    getInitialState: function getInitialState() {

        return {
            fieldSelectorQuery: '',
            searchInputQuery: '',

            selectedField: undefined,
            selectedMatch: undefined,
            fieldSpecForKeyDataPath: undefined,
            matchSpecForKeyDataPath: undefined,
            searchInputItem: undefined,

            savedSearchLoading: false
        };
    }

    //== START: HANDLERS ====================================//
    , _onFieldSelect: function _onFieldSelect(selectedField) {
        var selectedFieldDataPath = _lodash2.default.get(selectedField, 'dataPath');
        if (selectedFieldDataPath == SAVED_SEARCHES_DATAPATH) {
            this.setState({
                selectedField: selectedField
            });
        } else if (selectedField) {
            var fieldSpecForKeyDataPath = this._findFieldSpecForDataPath(selectedField.dataPath, this.props.filterableFields);
            var myMatchSpecForFieldSpec = (0, _matchSpecForFieldSpec2.default)(this, fieldSpecForKeyDataPath);

            this.setState({
                selectedField: selectedField,
                fieldSpecForKeyDataPath: fieldSpecForKeyDataPath,
                matchSpecForKeyDataPath: myMatchSpecForFieldSpec,
                selectedMatch: myMatchSpecForFieldSpec.matchOptions[0]
            });
        } else {
            this.setState({
                selectedField: selectedField,
                fieldSpecForKeyDataPath: undefined,
                matchSpecForKeyDataPath: undefined,
                selectedMatch: undefined
            });
        }
    },
    _findFieldSpecForDataPath: function _findFieldSpecForDataPath(wantedDataPath, fieldSpecs) {
        var _this = this;

        var foundFieldSpec = _lodash2.default.reduce(fieldSpecs, function (acc, fieldSpec) {
            var _fieldSpec$children = fieldSpec.children,
                children = _fieldSpec$children === undefined ? [] : _fieldSpec$children,
                dataPath = fieldSpec.dataPath;


            if (!_lodash2.default.isEmpty(acc)) {
                return acc;
            }
            return dataPath && dataPath === wantedDataPath ? fieldSpec : !_lodash2.default.isEmpty(children) ? _this._findFieldSpecForDataPath(wantedDataPath, children) : acc;
        }, {});

        return foundFieldSpec;
    },
    _onMatchSelect: function _onMatchSelect(event, index, selectedMatch) {
        this.setState({
            selectedMatch: selectedMatch
        });
    },

    _delegatedFieldSelectorAutoComplete__onUpdateInput: function _delegatedFieldSelectorAutoComplete__onUpdateInput(searchText) {
        this.setState({
            fieldSelectorQuery: searchText
        });
    },
    _fieldSelectorAutoComplete__onOptionSelected: function _fieldSelectorAutoComplete__onOptionSelected(chosenRequest) {
        //# NOTE: <AutoComplete/> implementation of searchText is stateful
        //# so you need to pass the searchText along to the AutoComplete's componentWillReceiveProps() method
        //# so force it to update it's prop record
        //# DEPRECATED
        /*
        this.setState({
            fieldSelectorQuery: chosenRequest.label
        });
        */

        this._onFieldSelect(chosenRequest);
        //# DEPRECATED
        /*
        this.setState({
            fieldSelectorQuery: ''
        });
        */
    },

    _delegatedSearchInput__onUpdateInput: function _delegatedSearchInput__onUpdateInput(searchInputQuery) {
        this.setState({
            searchInputQuery: searchInputQuery
        });
    }
    /**
     *  @param {Object[]} searchInputItem - we want to normalize all of the search inputs
     *      into an object so that the consumer knows how to pluck the needed values
     *  @param {Object[]} searchInputItem.text
     *  @param {Object[]} searchInputItem.value
     */
    , _delegatedSearchInput__onChange: function _delegatedSearchInput__onChange(searchInputItem) {
        if (searchInputItem && searchInputItem.value) {
            this.setState({
                searchInputItem: searchInputItem
            });
        } else {
            this.setState({
                searchInputItem: null
            });
        }
    },

    _retrieveSavedSearchUsingId_andLoad: function _retrieveSavedSearchUsingId_andLoad(id) {
        var _this2 = this;

        var targetSavedSearchUrl = this.props.savedSearches_instanceRouteParser.reverse({ id: id });

        return request.get(targetSavedSearchUrl).then(function (response) {
            var savedSearchObject = response.body;
            _this2.props.delegateOnSavedSearchLoad(savedSearchObject);

            return savedSearchObject;
        });
    }
    //== END: HANDLERS ====================================//

    , _renderLoader: function _renderLoader() {
        var _React$createElement, _React$createElement2;

        return _react2.default.createElement(
            'div',
            (_React$createElement2 = { className: _AdvancedSearchUtilityBar2.default.loaderWrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'AddFilterToolbarGroup'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _React$createElement2),
            _react2.default.createElement(_Loading2.default, (_React$createElement = { style: { width: 20, height: 20 }, status: 'loading', 'data-codecept-selector-node': 'RubyLoading',
                'data-codecept-selector-file': 'AddFilterToolbarGroup'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RubyLoading'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RubyLoading'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _React$createElement))
        );
    },
    _renderMatchQueryForSelectedField: function _renderMatchQueryForSelectedField(selectedField) {
        var _this3 = this;

        var selectedFieldDataPath = _lodash2.default.get(this.state, 'selectedField.dataPath');

        if (selectedFieldDataPath == SAVED_SEARCHES_DATAPATH) {
            var _extends2, _React$createElement3, _extends3;

            var loadSavedSearch_isActive = this._isLoadSavedSearchActive();
            var savedSearchLoading = this.state.savedSearchLoading;

            return [_react2.default.createElement(_AutoCompleteSelector2.default, _extends({}, _lodash2.default.pick(this.props, AutoCompleteSelector__propTypeKeys), _AdvancedSearchUtilityBar_style2.default.AutoComplete__rightMargin, (_extends2 = {
                hintText: 'Saved search name',
                menuCloseDelay: 0,
                canAutoPosition: false,
                url: this.props.savedSearches_optionsUrl,
                searchText: this.state.searchInputQuery,
                onUpdateInput: this._delegatedSearchInput__onUpdateInput,
                onOptionSelected: this._delegatedSearchInput__onChange,
                onOptionCleared: this._delegatedSearchInput__onChange,
                openOnFocus: true,
                filter: function filter(searchText, key) {
                    return key && key.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
                },
                'data-codecept-selector-node': 'AutoCompleteSelector',
                'data-codecept-selector-file': 'AddFilterToolbarGroup'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'AutoCompleteSelector'), _defineProperty(_extends2, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _defineProperty(_extends2, 'data-codecept-selector-node', 'AutoCompleteSelector'), _defineProperty(_extends2, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _extends2))), _react2.default.createElement(_RaisedButton2.default, _extends({}, _AdvancedSearchUtilityBar_style2.default.RaisedButton, (_extends3 = {
                key: 'load-saved-search',
                label: 'Load saved search',
                primary: true,
                icon: savedSearchLoading ? this._renderLoader() : _react2.default.createElement(_zoomIn2.default, (_React$createElement3 = {
                    'data-codecept-selector-node': 'ZoomInIcon',
                    'data-codecept-selector-file': 'AddFilterToolbarGroup'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ZoomInIcon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ZoomInIcon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _React$createElement3)),
                disabled: !loadSavedSearch_isActive || savedSearchLoading,
                onTouchTap: function onTouchTap() {
                    var searchInputItem = _this3.state.searchInputItem;


                    _this3.setState({ savedSearchLoading: true });
                    //# query for the full saved search data
                    _this3._retrieveSavedSearchUsingId_andLoad(searchInputItem.value).then(function (savedSearchObject) {

                        //# clear searchInput state
                        _this3._clearSearchInput();
                        _this3.setState({ savedSearchLoading: false });
                    });
                },
                'data-codecept-selector-node': 'RaisedButton',
                'data-codecept-selector-file': 'AddFilterToolbarGroup'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends3, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _defineProperty(_extends3, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends3, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _extends3)))];
        } else {
            var _extends4, _React$createElement4, _extends5;

            //# Retrieve the matchSpecForFieldSpec()
            var _state = this.state,
                fieldSpecForKeyDataPath = _state.fieldSpecForKeyDataPath,
                matchSpecForKeyDataPath = _state.matchSpecForKeyDataPath;

            if (matchSpecForKeyDataPath == undefined) {
                return [];
            }

            var matchOptions = matchSpecForKeyDataPath.matchOptions;

            var selectedMatchValue = _lodash2.default.get(this.state, 'selectedMatch.value');
            var selectedMatchOption = matchOptions.filter(function (option) {
                return option.value == selectedMatchValue;
            })[0];
            if (selectedMatchOption == undefined) {
                selectedMatchOption = matchOptions[0] || {};
            }

            var addToFilter_isActive = this._isAddToFilterActive();

            var searchInputItem = this.state.searchInputItem;

            return [_react2.default.createElement(_Dropdown2.default, _extends({}, _AdvancedSearchUtilityBar_style2.default.Dropdown__rightMargin, (_extends4 = {
                id: 'matchSelector',
                key: 'matchSelector',
                value: selectedMatchOption.value,
                options: matchOptions,
                onChange: function onChange(event, optionIndex, payload) {
                    //this.props.delegateOnMatchSelect(event, key, payload);
                    _this3._onMatchSelect(event, optionIndex, matchOptions[optionIndex]);
                },
                'data-codecept-selector-node': 'Dropdown',
                'data-codecept-selector-file': 'AddFilterToolbarGroup'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'Dropdown'), _defineProperty(_extends4, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _defineProperty(_extends4, 'data-codecept-selector-node', 'Dropdown'), _defineProperty(_extends4, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _extends4))), (selectedMatchOption.searchInputs || []).map(function (searchInput) {
                return _lodash2.default.isFunction(searchInput) ? searchInput(searchInputItem) : searchInput;
            }), _react2.default.createElement(_RaisedButton2.default, _extends({}, _AdvancedSearchUtilityBar_style2.default.RaisedButton, (_extends5 = {
                key: 'add-to-filter',
                label: 'Add to filter',
                primary: true,
                icon: _react2.default.createElement(_zoomIn2.default, (_React$createElement4 = {
                    'data-codecept-selector-node': 'ZoomInIcon',
                    'data-codecept-selector-file': 'AddFilterToolbarGroup'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ZoomInIcon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ZoomInIcon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _React$createElement4)),
                disabled: !addToFilter_isActive,
                onTouchTap: function onTouchTap() {
                    var _state2 = _this3.state,
                        selectedField = _state2.selectedField,
                        selectedMatch = _state2.selectedMatch,
                        searchInputItem = _state2.searchInputItem;


                    _this3.props.delegateAddToFilter(selectedField, selectedMatch, searchInputItem);
                    //# clear state
                    _this3._resetState();
                },
                'data-codecept-selector-node': 'RaisedButton',
                'data-codecept-selector-file': 'AddFilterToolbarGroup'
            }, _defineProperty(_extends5, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends5, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _defineProperty(_extends5, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends5, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _extends5)))];
        }
    },
    render: function render() {
        var _this4 = this,
            _React$createElement5,
            _extends7,
            _extends8,
            _extends9;

        var ToolbarGroupStyle = _extends({}, _AdvancedSearchUtilityBar_style2.default.ToolbarGroup__nested.style, {
            maxHeight: this.props.isAddFilterVisible ? 100 : 0
        });

        var fieldSelectorDataSource = this._preppedFieldSelectorDataSource();

        var selectedFieldDataPath = _lodash2.default.get(this.state, 'selectedField.dataPath');
        var fieldSelectorDataSourceItem__fromPropsKeyDataPath = fieldSelectorDataSource.filter(function (dataSourceItem) {
            return dataSourceItem.dataPath == selectedFieldDataPath;
        })[0];

        var fieldSelector__searchText = fieldSelectorDataSourceItem__fromPropsKeyDataPath ? fieldSelectorDataSourceItem__fromPropsKeyDataPath.label : undefined;

        var AutoCompleteSelectorProps = {
            /*
            styles: {
                list: {
                    border: "1px solid red"
                }
                , popover: {
                    border: "1px solid green"
                }
            }
            */
            id: "fieldSelector",
            hintText: "Filter by",
            menuCloseDelay: 0,
            menuProps: {
                className: _AdvancedSearchUtilityBar2.default.AutoCompleteSelector
            },
            canAutoPosition: false,
            searchText: this.state.fieldSelectorQuery,
            onUpdateInput: this._delegatedFieldSelectorAutoComplete__onUpdateInput,
            onOptionSelected: this._fieldSelectorAutoComplete__onOptionSelected,
            onOptionCleared: this._fieldSelectorAutoComplete__onOptionSelected,
            dataSource: fieldSelectorDataSource,
            dataSourceConfig: this.props.filterableFields__dataSourceConfig,
            openOnFocus: true,
            filter: function filter(searchText, menuItemText, menuItem) {
                return (menuItem.label + ' ' + (menuItem.breadcrumb || '')).toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
            }
        };

        return _react2.default.createElement(
            _Toolbar.ToolbarGroup,
            _extends({}, _AdvancedSearchUtilityBar_style2.default.ToolbarGroup__nested, (_extends9 = {
                className: _AdvancedSearchUtilityBar2.default.ToolbarGroup__nested,
                style: ToolbarGroupStyle,
                'data-codecept-selector-node': 'ToolbarGroup',
                'data-codecept-selector-file': 'AddFilterToolbarGroup'
            }, _defineProperty(_extends9, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_extends9, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _defineProperty(_extends9, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_extends9, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _extends9)),
            _react2.default.createElement(
                'div',
                _extends({}, _AdvancedSearchUtilityBar_style2.default.ToolbarGroupDiv, (_extends8 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'AddFilterToolbarGroup'
                }, _defineProperty(_extends8, 'data-codecept-selector-node', 'div'), _defineProperty(_extends8, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _defineProperty(_extends8, 'data-codecept-selector-node', 'div'), _defineProperty(_extends8, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _extends8)),
                this.props.filterableFieldsOptions.isFlat ? _react2.default.createElement(
                    _reactVirtualized.AutoSizer,
                    (_React$createElement5 = { disableHeight: true, style: { width: "100%", display: "flex", alignItems: "center" }, 'data-codecept-selector-node': 'AutoSizer',
                        'data-codecept-selector-file': 'AddFilterToolbarGroup'
                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'AutoSizer'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'AutoSizer'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _React$createElement5),
                    function (_ref) {
                        var _extends6;

                        var autoSizerWidth = _ref.width;

                        return [_react2.default.createElement(_AutoCompleteSelector2.default, _extends({}, _AdvancedSearchUtilityBar_style2.default.AutoComplete__rightMargin, AutoCompleteSelectorProps, (_extends6 = {
                            AutoCompleteMenu: { width: autoSizerWidth },
                            'data-codecept-selector-node': 'AutoCompleteSelector',
                            'data-codecept-selector-file': 'AddFilterToolbarGroup'
                        }, _defineProperty(_extends6, 'data-codecept-selector-node', 'AutoCompleteSelector'), _defineProperty(_extends6, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _defineProperty(_extends6, 'data-codecept-selector-node', 'AutoCompleteSelector'), _defineProperty(_extends6, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _extends6))), _this4._renderMatchQueryForSelectedField(_this4.state.selectedField)];
                    }
                ) : [_react2.default.createElement(_AutoCompleteSelector2.default, _extends({}, _AdvancedSearchUtilityBar_style2.default.AutoComplete__rightMargin, AutoCompleteSelectorProps, (_extends7 = {
                    'data-codecept-selector-node': 'AutoCompleteSelector',
                    'data-codecept-selector-file': 'AddFilterToolbarGroup'
                }, _defineProperty(_extends7, 'data-codecept-selector-node', 'AutoCompleteSelector'), _defineProperty(_extends7, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _defineProperty(_extends7, 'data-codecept-selector-node', 'AutoCompleteSelector'), _defineProperty(_extends7, 'data-codecept-selector-file', 'AddFilterToolbarGroup'), _extends7))), this._renderMatchQueryForSelectedField(this.state.selectedField)]
            )
        );
    }

    //== START: UTILITY ==================================================================//
    ,
    _resetState: function _resetState() {
        this.setState(this.getInitialState());
    },
    _clearSearchInput: function _clearSearchInput() {
        this.setState({
            searchInputQuery: '',
            searchInputItem: undefined
        });
    },
    _isLoadSavedSearchActive: function _isLoadSavedSearchActive() {
        var _state3 = this.state,
            selectedField = _state3.selectedField,
            searchInputItem = _state3.searchInputItem;

        return selectedField && selectedField.dataPath == SAVED_SEARCHES_DATAPATH && searchInputItem;
    },
    _isAddToFilterActive: function _isAddToFilterActive() {
        var _state4 = this.state,
            selectedField = _state4.selectedField,
            selectedMatch = _state4.selectedMatch,
            searchInputItem = _state4.searchInputItem;


        var haveCompleteSetOfFields = selectedField && selectedMatch && (selectedMatch.searchInputs && searchInputItem || selectedMatch.searchInputs == undefined);

        if (!haveCompleteSetOfFields) {
            return false;
        }

        var testTag = {
            fieldSpec: selectedField,
            matchSpec: selectedMatch,
            searchSpec: searchInputItem
        };

        var newFilterTags = _lodash2.default.differenceWith([testTag], this.props.filterTags, function (a, b) {
            return a.fieldSpec.dataPath == b.fieldSpec.dataPath && a.matchSpec.value == b.matchSpec.value && _lodash2.default.isEqual(a.searchSpec.value, b.searchSpec.value);
        });

        return haveCompleteSetOfFields && newFilterTags.length;
    },
    _preppedFieldSelectorDataSource: function _preppedFieldSelectorDataSource() {
        var savedSearch_fieldSelectorOption = {
            label: 'Saved Searches',
            menuItemProps: {
                primaryText: "Saved Searches",
                style: { borderBottom: '1px solid  black', paddingLeft: '7px' }
            },
            dataPath: SAVED_SEARCHES_DATAPATH,
            key: SAVED_SEARCHES_DATAPATH,
            reactKey: 'Saved Searches'
        };

        //# TODO: temporary until we can improve how to remove overlapping fields
        /*
        const filterableFieldsByKey = this._filterableFieldsByKey();
        const filterableFields = _.map(filterableFieldsByKey, filterableField => filterableField);
        */
        var filterableFields = this.props.filterableFields; //# NOTE don't remove overlapping field keys

        var fieldSelectorDataSource = [savedSearch_fieldSelectorOption].concat(filterableFields);

        return fieldSelectorDataSource;
    },

    _filterableFieldsByKey: function _filterableFieldsByKey() {
        var filterableFields = this.props.filterableFields;

        var dataSourceConfig = this.props.filterableFields__dataSourceConfig;

        return _lodash2.default.reduce(filterableFields, function (collector, filterableField) {
            var key = filterableField[dataSourceConfig.key];
            collector[key] = filterableField;
            return collector;
        }, {});
    }
    //== END: UTILITY ==================================================================//
});

exports.default = AddFilterToolbarGroup;