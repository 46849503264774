'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Selectize = require('@rubyapps/ruby-react-components/src/client/input/Selectize');

var _Selectize2 = _interopRequireDefault(_Selectize);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var RubyIcons = require('@rubyapps/ruby-icons');

var Selectize__propTypeKeys = Object.keys(_Selectize2.default.propTypes);

var RubyCodeUtils = require('@rubyapps/ruby-code-utils');

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label,
    RichErrorMessages = _require.RichErrorMessages;

var optionUtils = require('./optionUtils');

var PropTypes_value = _react.PropTypes.oneOfType([_react.PropTypes.string, _react.PropTypes.number, _react.PropTypes.bool]);
var Dropdown = _react2.default.createClass({
    displayName: 'Dropdown',

    propTypes: {
        label: _react.PropTypes.string,
        value: _react.PropTypes.oneOfType([PropTypes_value, _react.PropTypes.arrayOf(PropTypes_value)]),
        error: _react.PropTypes.shape({
            message: _react.PropTypes.string
        }),
        id: _react.PropTypes.string,
        isArray: _react.PropTypes.bool,
        isMounted: _react.PropTypes.bool,
        actions: _react.PropTypes.object,
        options: _react.PropTypes.arrayOf(_react.PropTypes.shape({
            value: PropTypes_value,
            text: _react.PropTypes.string
        })),
        preloadOptions: _react.PropTypes.bool,
        styles: _react.PropTypes.shape({
            Selectize: _react.PropTypes.object
        }),
        searchValue: _react.PropTypes.string,
        excludeWrapper: _react.PropTypes.bool,
        autoCompleteThreshold: _react.PropTypes.number //# TODO: DEPRECATED
        , filterLocally: _react.PropTypes.bool
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null,
            styles: {}
        };
    },
    isMultiSelect: function isMultiSelect() {
        return this.props.limit == null || Number.isInteger(this.props.limit) && this.props.limit > 1;
    },
    disambiguatedOptions: optionUtils.disambiguatedOptions,

    getPrimaryText: function getPrimaryText(option) {
        var _React$createElement2;

        var optionToHTML = this.props.optionToHTML;
        if (!optionToHTML || !_lodash2.default.isFunction(optionToHTML)) {
            var _React$createElement;

            return _react2.default.createElement(
                'span',
                (_React$createElement = {
                    'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'Dropdown'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Dropdown'), _React$createElement),
                option.text
            );
        }

        return _react2.default.createElement('span', (_React$createElement2 = {
            dangerouslySetInnerHTML: {
                __html: RubyCodeUtils.sanitizeHTML(optionToHTML(option))
            },
            'data-codecept-selector-node': 'span',
            'data-codecept-selector-file': 'Dropdown'
        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Dropdown'), _React$createElement2));
    },
    dataSourceItems: function dataSourceItems(options) {
        //# for Selectize
        var disambiguatedOptions = this.disambiguatedOptions(options || this.props.options);
        //# filter out null values
        //return disambiguatedOptions.filter(option => option.value != null);

        return disambiguatedOptions.reduce(function (collector, opt) {
            var _React$createElement3;

            if (opt.value == null) {
                collector.push(opt);
                return collector;
            }

            var IconClass = opt.rubyIconPath && RubyIcons.getIconClassForPath(opt.rubyIconPath);
            var Icon = IconClass && _react2.default.createElement(IconClass, (_React$createElement3 = {
                'data-codecept-selector-node': 'IconClass',
                'data-codecept-selector-file': 'Dropdown'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconClass'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconClass'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Dropdown'), _React$createElement3));
            collector.push(_extends({}, opt, { icon: Icon //# TODO update option render to render icon
                , text: opt.text
            }));

            return collector;
        }, []);
    },
    displayTextFromOption: optionUtils.displayTextFromOption,
    values: function values() {
        var dataIsArray = Array.isArray(this.props.value);

        //# the connect layer should have normalized the value
        return dataIsArray ? this.props.value : [this.props.value];
    },

    _renderSelect: function _renderSelect() {
        var _React$createElement4,
            _extends2,
            _this = this,
            _extends3,
            _React$createElement5;

        var self = this;
        var disabled = self.props.locked;

        var myValues = this.values();

        var labelProps = _lodash2.default.pick(this.props, Object.keys(Label.propTypes));
        var errorTextElement = !_lodash2.default.isEmpty(this.props.richErrorMessages) ? _react2.default.createElement(RichErrorMessages, (_React$createElement4 = { messages: this.props.richErrorMessages, 'data-codecept-selector-node': 'RichErrorMessages',
            'data-codecept-selector-file': 'Dropdown'
        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Dropdown'), _React$createElement4)) : null;

        /* //# TODO: move to renderOption
        const IconClass = _.get(selectedOption, 'rubyIconPath')
            && RubyIcons.getIconClassForPath(selectedOption.rubyIconPath);
        const Icon = IconClass
            && <IconClass style={{position: 'absolute', bottom: 11}}/>;
                {Icon}
        */

        var dataSource = self.dataSourceItems();
        var filteredDataSource = self.dataSourceItems(this.props.filteredOptions || []);
        //# NOTE: dataSource might be a deprecated prop
        //# Selectize doens't really use it 
        //# so we need to make sure to map it back to options
        //searchText={selectedOption ? selectedOption.text : ''}

        //# TODO: REVISIT THESE PROPS
        //openOnFocus={true}
        //fullWidth={true}
        /*
        inputStyle={{
            paddingLeft: Icon? 35 :undefined
        }}
        */
        return _react2.default.createElement(
            'div',
            (_React$createElement5 = { style: { position: 'relative' }, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Dropdown'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Dropdown'), _React$createElement5),
            _react2.default.createElement(_Selectize2.default, _extends({
                placeholder: _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                    'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'Dropdown'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Dropdown'), _extends2)))
            }, this.props.styles.Selectize, _lodash2.default.omit(_lodash2.default.pick(this.props, Selectize__propTypeKeys), ['url', 'options'] //# NOTE: because this is a Dropdown, we retrieve ALL options= and rely on local filtering
            ), {
                ref: function ref(selectizeRef) {
                    _this._selectizeRef = selectizeRef;
                },
                getPrimaryText: this.getPrimaryText,
                errorText: errorTextElement,
                dataSource: dataSource,
                disabled: disabled,
                value: myValues
            }, this.props.filterLocally ? { options: dataSource } : {
                options: filteredDataSource,
                search: this.props.searchValue,
                onSearchChange: function onSearchChange(search, callback) {
                    //# TODO: wait for options to finish before calling on callback
                    self.props.actions.setSearchValue(search, function () {
                        callback();

                        self._selectizeRef._selectizeRef.highlightFirstSelectableOption();
                    });
                }
            }, (_extends3 = {
                filterOptions: function filterOptions(options, values, search) {
                    if (self.props.filterLocally) {
                        if (arguments.length == 2) {
                            search = values;
                            values = myValues;
                        }

                        if (_lodash2.default.isNil(search)) {
                            return options;
                        }

                        return options.filter(function (option) {
                            return self.displayTextFromOption(option).toLowerCase().indexOf(search.toLowerCase()) > -1;
                        });
                    }

                    return options; //# don't need to actually filter
                },
                onOptionSelected: function onOptionSelected(options) {
                    var normalizedOptions = options ? _lodash2.default.castArray(options) : options;
                    //# handling the case that the option values are menu items. TODO: probably don't need to handle that anymore
                    var isMultiSelect = _this.isMultiSelect();

                    var option__value = normalizedOptions.map(function (option) {
                        return _lodash2.default.get(option, 'value.props.value', option.value);
                    });

                    _this.props.actions.setFieldValueByKey(isMultiSelect ? option__value : _lodash2.default.first(option__value), _this.props.fieldKey);
                },
                onOptionCleared: function onOptionCleared() {
                    _this.props.actions.setFieldValueByKey(null, _this.props.fieldKey);
                },
                'data-codecept-selector-node': 'Selectize',
                'data-codecept-selector-file': 'Dropdown'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Selectize'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Selectize'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Dropdown'), _extends3)))
        );
    },
    render: function render() {
        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var dropdownComponent = this._renderSelect();

        return this.props.renderGridWrapper(_extends({}, this.props, { children: dropdownComponent }));
    },
    componentDidUpdate: function componentDidUpdate(prevProps, prevState) {
        if (this._isMounted && !this.props.isMounted) {
            if (this.props.preloadOptions) {
                this.props.actions.preloadOptions();
            }

            this.props.actions.setComponentIsMounted && this.props.actions.setComponentIsMounted();
        }
    },
    componentDidMount: function componentDidMount() {
        this._isMounted = true; //# NOTE: cannot use setState because that happens asynchronously which causes issues with the async setState call
        if (this.props.preloadOptions) {
            //# NOTE: this should't change as nothing else would trigger loading the remote options
            this.props.actions.preloadOptions();
        }

        this.props.actions.setComponentIsMounted && this.props.actions.setComponentIsMounted();
    }
});

exports.default = Dropdown;