'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var PARENT_CONSTANTS = require('../../../../common/constants');

var TEMPLATE_FORM_TEMPLATE_TYPE = 'rubyComponentTemplateEditPage__templateForm';
var TEMPLATE_FORM_TEMPLATE_KEY = TEMPLATE_FORM_TEMPLATE_TYPE;

module.exports = _extends({}, PARENT_CONSTANTS, {
    COMPONENT_NAME: 'rubyComponentTemplateEditPage',
    TEMPLATE_FORM_TEMPLATE_TYPE: TEMPLATE_FORM_TEMPLATE_TYPE,
    TEMPLATE_FORM_TEMPLATE_KEY: TEMPLATE_FORM_TEMPLATE_KEY
});