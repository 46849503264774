'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _reactRedux = require('react-redux');

var _mustache = require('mustache');

var _mustache2 = _interopRequireDefault(_mustache);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Text = require('./Text');

var _Text2 = _interopRequireDefault(_Text);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var mustacheHelpers = {
    appendPeriodIfMissing: function appendPeriodIfMissing() {
        return function (text, render) {
            var newText = text.endsWith('.') ? text : text + '.';
            return render(newText);
        };
    }
};

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector,
        frontendSettingsComponent = _selfModule$getDepend.frontendSettingsComponent,
        parentForm = _selfModule$getDepend.parentForm;

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);
        var interpolateValue = ownProps.interpolateValue;


        var rawValue = _lodash2.default.get(ownProps, 'value');
        var config = frontendSettingsComponent.getState();

        var view = !interpolateValue ? {} : _extends({
            config: config,
            pageData: parentForm.formValue()
        }, mustacheHelpers);

        var outputValue = rawValue;

        if (rawValue && interpolateValue) {
            switch (interpolateValue) {
                case 'lodash':
                    outputValue = _lodash2.default.template(rawValue)(view);
                    break;
                case true:
                case 'mustache':
                default:
                    outputValue = _mustache2.default.render(rawValue, view);
                    break;
            }
        }

        var mappedProps = _extends({}, ownProps, {
            id: selfModule.getID(),
            value: outputValue
        });

        return mappedProps;
    }

    return (0, _reactRedux.connect)(mapStateToProps)(_Text2.default);
}