'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _RaisedButton = require('material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _ImagePickerWidget = require('./ImagePickerWidget.cssModule');

var _ImagePickerWidget2 = _interopRequireDefault(_ImagePickerWidget);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var PropTypes = React.PropTypes;

var ReactDOM = require('react-dom');
var RubyConfig = require('@rubyapps/ruby-config')('common');

var Field = require('./Field');

require('./ImagePickerWidget.css');


var hasMediaStyles = _ImagePickerWidget2.default;
var noMediaStyles = {
    'rtgPhoto': _ImagePickerWidget2.default.rtgPhoto_noMedia,
    'rtgPhotoImg': _ImagePickerWidget2.default.rtgPhotoImg_noMedia,
    'rtgIco': _ImagePickerWidget2.default.rtgIco_noMedia
};

function DEPRECATED_ERROR() {
    console.error('rubyImagePicker is no longer available. If you see this error, that means you are still using the old image picker somewhere');
}

var ImagePicker = React.createClass({
    displayName: 'ImagePicker',


    /*
    propTypes: {
        customValue ????
        value
        errorText
        name (used as id)
        simple
        hasUploadPermission
        additionalParentClasses
        constraints 
        onChange
    }
    */
    // Since we have weird things liek image pickers that depend on external sources modifying hte input value,
    // AND reactjs doens't really support onChange events that occur prograemmatically,
    // we need to handle these changes differently
    //# TODO: 20160330 revisit... ideally, we shouldn't do this at all
    componentWillReceiveProps: function componentWillReceiveProps(newProps) {
        if (this.props.customValue !== newProps.customValue) {
            this.setValue(newProps.customValue.get('data'));
        }
    },

    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8, _React$createElement9, _React$createElement10, _React$createElement11, _React$createElement12, _React$createElement13, _React$createElement14, _React$createElement15, _React$createElement16, _React$createElement17, _React$createElement18, _React$createElement19, _React$createElement20, _React$createElement21, _extends2, _React$createElement22;

        var categoryList = [];

        var hasUploadPermission = true;
        var styles = this.props.value ? hasMediaStyles : _extends({}, hasMediaStyles, noMediaStyles);

        return React.createElement(
            'div',
            (_React$createElement22 = { className: 'rtg-layout react-rtg-layout rubyImagePickerWidget', 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'ImagePickerWidget'
            }, _defineProperty(_React$createElement22, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement22, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement22, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement22, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement22),
            React.createElement(
                Field,
                _extends({}, this.props, (_extends2 = {
                    value: this.props.value ? parseInt(this.props.value) : undefined,
                    charCount: this.props.show_counter && this.props.value ? this.props.value.length : 0,
                    errorMessage: this.props.errorText,
                    parentDisplayName: this.constructor.displayName,
                    additionalParentClasses: this.getAdditionalParentClasses(),
                    'data-codecept-selector-node': 'Field',
                    'data-codecept-selector-file': 'ImagePickerWidget'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Field'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Field'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ImagePickerWidget'), _extends2)),
                React.createElement(
                    'dl',
                    (_React$createElement21 = {
                        'data-codecept-selector-node': 'dl',
                        'data-codecept-selector-file': 'ImagePickerWidget'
                    }, _defineProperty(_React$createElement21, 'data-codecept-selector-node', 'dl'), _defineProperty(_React$createElement21, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement21, 'data-codecept-selector-node', 'dl'), _defineProperty(_React$createElement21, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement21),
                    React.createElement(
                        'dd',
                        (_React$createElement6 = { className: 'rtg-media', 'data-codecept-selector-node': 'dd',
                            'data-codecept-selector-file': 'ImagePickerWidget'
                        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement6),
                        React.createElement(
                            'div',
                            (_React$createElement5 = { className: 'rtg-photo ' + styles.rtgPhoto, 'data-codecept-selector-node': 'div',
                                'data-codecept-selector-file': 'ImagePickerWidget'
                            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement5),
                            React.createElement('img', (_React$createElement = {
                                id: this.props.name,
                                src: '/images/spacer.gif',
                                alt: '',
                                className: styles.rtgPhotoImg,
                                'data-codecept-selector-node': 'img',
                                'data-codecept-selector-file': 'ImagePickerWidget'
                            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'img'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'img'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement)),
                            React.createElement(
                                'div',
                                (_React$createElement3 = { className: 'rtg-button ui-state-default rtg-ico ' + styles.rtgIco, 'data-codecept-selector-node': 'div',
                                    'data-codecept-selector-file': 'ImagePickerWidget'
                                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement3),
                                React.createElement('a', (_React$createElement2 = { href: '#', className: 'ui-icon ui-icon-zoomin rtg-ignore-dirty-form ', 'data-codecept-selector-node': 'a',
                                    'data-codecept-selector-file': 'ImagePickerWidget'
                                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement2))
                            ),
                            React.createElement('div', (_React$createElement4 = { className: 'rtg-video-overlay hidden', 'data-codecept-selector-node': 'div',
                                'data-codecept-selector-file': 'ImagePickerWidget'
                            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement4)),
                            this.renderCategoryMasks(),
                            this.renderUploadShim()
                        ),
                        this.renderControls()
                    ),
                    React.createElement(
                        'dd',
                        (_React$createElement20 = { className: 'rtg-image-info ' + styles.rtgImageInfo, 'data-codecept-selector-node': 'dd',
                            'data-codecept-selector-file': 'ImagePickerWidget'
                        }, _defineProperty(_React$createElement20, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement20, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement20, 'data-codecept-selector-node', 'dd'), _defineProperty(_React$createElement20, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement20),
                        React.createElement(
                            'h3',
                            (_React$createElement7 = { className: 'rtg-filename', title: 'No Media Selected', 'data-codecept-selector-node': 'h3',
                                'data-codecept-selector-file': 'ImagePickerWidget'
                            }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'h3'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'h3'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement7),
                            'No Media Selected'
                        ),
                        this.renderConstraints(),
                        React.createElement(
                            'ul',
                            (_React$createElement19 = { className: 'rtg-image-details', 'data-codecept-selector-node': 'ul',
                                'data-codecept-selector-file': 'ImagePickerWidget'
                            }, _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement19),
                            React.createElement(
                                'li',
                                (_React$createElement9 = {
                                    'data-codecept-selector-node': 'li',
                                    'data-codecept-selector-file': 'ImagePickerWidget'
                                }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement9),
                                React.createElement(
                                    'h2',
                                    (_React$createElement8 = {
                                        'data-codecept-selector-node': 'h2',
                                        'data-codecept-selector-file': 'ImagePickerWidget'
                                    }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'h2'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'h2'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement8),
                                    'Image Details'
                                )
                            ),
                            React.createElement(
                                'li',
                                (_React$createElement12 = { className: 'rtg-published', 'data-codecept-selector-node': 'li',
                                    'data-codecept-selector-file': 'ImagePickerWidget'
                                }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement12),
                                React.createElement(
                                    'em',
                                    (_React$createElement10 = {
                                        'data-codecept-selector-node': 'em',
                                        'data-codecept-selector-file': 'ImagePickerWidget'
                                    }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement10),
                                    'Published:'
                                ),
                                React.createElement(
                                    'span',
                                    (_React$createElement11 = {
                                        'data-codecept-selector-node': 'span',
                                        'data-codecept-selector-file': 'ImagePickerWidget'
                                    }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement11),
                                    '--'
                                )
                            ),
                            React.createElement(
                                'li',
                                (_React$createElement15 = { className: 'rtg-size', 'data-codecept-selector-node': 'li',
                                    'data-codecept-selector-file': 'ImagePickerWidget'
                                }, _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement15),
                                React.createElement(
                                    'em',
                                    (_React$createElement13 = {
                                        'data-codecept-selector-node': 'em',
                                        'data-codecept-selector-file': 'ImagePickerWidget'
                                    }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement13),
                                    'Size: '
                                ),
                                React.createElement(
                                    'span',
                                    (_React$createElement14 = {
                                        'data-codecept-selector-node': 'span',
                                        'data-codecept-selector-file': 'ImagePickerWidget'
                                    }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement14),
                                    '--'
                                )
                            ),
                            React.createElement(
                                'li',
                                (_React$createElement18 = { className: 'rtg-type', 'data-codecept-selector-node': 'li',
                                    'data-codecept-selector-file': 'ImagePickerWidget'
                                }, _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement18),
                                React.createElement(
                                    'em',
                                    (_React$createElement16 = {
                                        'data-codecept-selector-node': 'em',
                                        'data-codecept-selector-file': 'ImagePickerWidget'
                                    }, _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement16),
                                    'Media type: '
                                ),
                                React.createElement(
                                    'span',
                                    (_React$createElement17 = {
                                        'data-codecept-selector-node': 'span',
                                        'data-codecept-selector-file': 'ImagePickerWidget'
                                    }, _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement17),
                                    '--'
                                )
                            )
                        )
                    )
                )
            )
        );
    },

    renderUploadShim: function renderUploadShim() {
        var _React$createElement23;

        if (this.props.hasUploadPermission) return React.createElement('div', (_React$createElement23 = { className: 'rtg-mupload-queue-shim', 'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'ImagePickerWidget'
        }, _defineProperty(_React$createElement23, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement23, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement23, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement23, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement23));
    },

    renderControls: function renderControls() {
        var _React$createElement24, _React$createElement25, _React$createElement26, _React$createElement27, _React$createElement28, _React$createElement29, _React$createElement30, _React$createElement31, _React$createElement32, _React$createElement33;

        var buttons = [React.createElement(
            'li',
            (_React$createElement25 = { className: 'rtg-insert', key: 'rtg-insert', 'data-codecept-selector-node': 'li',
                'data-codecept-selector-file': 'ImagePickerWidget'
            }, _defineProperty(_React$createElement25, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement25, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement25, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement25, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement25),
            React.createElement(_RaisedButton2.default, (_React$createElement24 = {
                label: 'Choose Media',
                primary: true,
                disabled: this.props.disabled || (this.props.value ? true : false),
                className: 'ui-state-default rtg-button',
                'data-codecept-selector-node': 'RaisedButton',
                'data-codecept-selector-file': 'ImagePickerWidget'
            }, _defineProperty(_React$createElement24, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement24, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement24, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement24, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement24))
        ), React.createElement(
            'li',
            (_React$createElement27 = { className: 'rtg-replace', key: 'rtg-replace', 'data-codecept-selector-node': 'li',
                'data-codecept-selector-file': 'ImagePickerWidget'
            }, _defineProperty(_React$createElement27, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement27, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement27, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement27, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement27),
            React.createElement(_RaisedButton2.default, (_React$createElement26 = {
                label: 'Replace',
                primary: true,
                disabled: this.props.disabled || (this.props.value ? false : true),
                className: 'ui-state-default rtg-button',
                'data-codecept-selector-node': 'RaisedButton',
                'data-codecept-selector-file': 'ImagePickerWidget'
            }, _defineProperty(_React$createElement26, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement26, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement26, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement26, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement26))
        ), React.createElement(
            'li',
            (_React$createElement29 = { className: 'rtg-remove', key: 'rtg-remove', 'data-codecept-selector-node': 'li',
                'data-codecept-selector-file': 'ImagePickerWidget'
            }, _defineProperty(_React$createElement29, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement29, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement29, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement29, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement29),
            React.createElement(_RaisedButton2.default, (_React$createElement28 = {
                label: 'Remove',
                secondary: true,
                disabled: this.props.disabled || (this.props.value ? false : true),
                className: 'ui-state-default rtg-button',
                'data-codecept-selector-node': 'RaisedButton',
                'data-codecept-selector-file': 'ImagePickerWidget'
            }, _defineProperty(_React$createElement28, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement28, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement28, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement28, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement28))
        )];

        if (this.props.hasUploadPermission) buttons.push(React.createElement(
            'li',
            (_React$createElement31 = { className: 'rtg-cancel', key: 'rtg-cancel', 'data-codecept-selector-node': 'li',
                'data-codecept-selector-file': 'ImagePickerWidget'
            }, _defineProperty(_React$createElement31, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement31, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement31, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement31, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement31),
            React.createElement(
                'button',
                (_React$createElement30 = { type: 'button', className: 'ui-state-default rtg-button', 'data-codecept-selector-node': 'button',
                    'data-codecept-selector-file': 'ImagePickerWidget'
                }, _defineProperty(_React$createElement30, 'data-codecept-selector-node', 'button'), _defineProperty(_React$createElement30, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement30, 'data-codecept-selector-node', 'button'), _defineProperty(_React$createElement30, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement30),
                'Cancel'
            )
        ));

        var controlList = React.createElement(
            'ul',
            (_React$createElement32 = {
                'data-codecept-selector-node': 'ul',
                'data-codecept-selector-file': 'ImagePickerWidget'
            }, _defineProperty(_React$createElement32, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement32, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement32, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement32, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement32),
            buttons
        );

        if (this.props.simple && !this.props.hasUploadPermission) controlList = '';

        return React.createElement(
            'div',
            (_React$createElement33 = { className: 'rtg-control', 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'ImagePickerWidget'
            }, _defineProperty(_React$createElement33, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement33, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement33, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement33, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement33),
            controlList
        );
    },

    handleChangeWrapper: function handleChangeWrapper(evt) {

        // since asset fields are Numbers (Loopback types)
        // something in LB is turning '' (empty strings)
        // to 0 - let's set to null here to avoid the issue
        // (would trip on validation anyway)
        var value = evt.target.value;
        if (value === '') value = null;else {
            value = parseInt(value);
        }

        this.props.onChange(evt, value);
    },
    renderCategoryMasks: function renderCategoryMasks() {
        var _React$createElement35;

        if (this.props.simple) return;

        var allCategories = [RubyConfig.Constants.Asset.AUDIO_CATEGORY, RubyConfig.Constants.Asset.DOCUMENT_CATEGORY, RubyConfig.Constants.Asset.FLASH_CATEGORY, RubyConfig.Constants.Asset.IMAGE_CATEGORY, RubyConfig.Constants.Asset.PDF_CATEGORY, RubyConfig.Constants.Asset.SLIDESHOW_CATEGORY, RubyConfig.Constants.Asset.VIDEO_CATEGORY];
        var types = this.props.constraints && this.props.constraints.type ? this.props.constraints.type.split(/\s*,\s*/) : [RubyConfig.Constants.Asset.IMAGE_CATEGORY, RubyConfig.Constants.Asset.VIDEO_CATEGORY];
        var typeMap = types.reduce(function (map, t) {
            map[t] = true;return map;
        }, {});

        var masks = allCategories.reduce(function (divs, cat) {
            var extWithinCategory = Object.keys(RubyConfig.Constants.Asset.category_extensions[cat]).reduce(function (found, ext) {
                return typeMap[ext] ? true : found;
            }, false);

            if (!typeMap[cat] && !extWithinCategory) {
                var _React$createElement34;

                var mask = 'rtg-category-mask-' + cat;
                divs.push(React.createElement('div', (_React$createElement34 = { key: mask, className: "rtg-category-mask " + mask, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'ImagePickerWidget'
                }, _defineProperty(_React$createElement34, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement34, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement34, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement34, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement34)));
            }
            return divs;
        }, []);

        return React.createElement(
            'div',
            (_React$createElement35 = { className: 'rtg-category-masks', 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'ImagePickerWidget'
            }, _defineProperty(_React$createElement35, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement35, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement35, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement35, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement35),
            masks
        );
    },

    renderImageDescription: function renderImageDescription() {
        var _React$createElement36, _React$createElement37, _React$createElement38, _React$createElement39, _React$createElement40, _React$createElement41, _React$createElement42, _React$createElement43, _React$createElement44, _React$createElement45, _React$createElement46, _React$createElement47;

        return React.createElement(
            'ul',
            (_React$createElement47 = { className: 'rtg-image-details', 'data-codecept-selector-node': 'ul',
                'data-codecept-selector-file': 'ImagePickerWidget'
            }, _defineProperty(_React$createElement47, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement47, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement47, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement47, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement47),
            React.createElement(
                'li',
                (_React$createElement37 = {
                    'data-codecept-selector-node': 'li',
                    'data-codecept-selector-file': 'ImagePickerWidget'
                }, _defineProperty(_React$createElement37, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement37, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement37, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement37, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement37),
                React.createElement(
                    'h2',
                    (_React$createElement36 = {
                        'data-codecept-selector-node': 'h2',
                        'data-codecept-selector-file': 'ImagePickerWidget'
                    }, _defineProperty(_React$createElement36, 'data-codecept-selector-node', 'h2'), _defineProperty(_React$createElement36, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement36, 'data-codecept-selector-node', 'h2'), _defineProperty(_React$createElement36, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement36),
                    'Image Details'
                )
            ),
            React.createElement(
                'li',
                (_React$createElement40 = { className: 'rtg-published', 'data-codecept-selector-node': 'li',
                    'data-codecept-selector-file': 'ImagePickerWidget'
                }, _defineProperty(_React$createElement40, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement40, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement40, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement40, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement40),
                React.createElement(
                    'em',
                    (_React$createElement38 = {
                        'data-codecept-selector-node': 'em',
                        'data-codecept-selector-file': 'ImagePickerWidget'
                    }, _defineProperty(_React$createElement38, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement38, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement38, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement38, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement38),
                    'Published: '
                ),
                React.createElement(
                    'span',
                    (_React$createElement39 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'ImagePickerWidget'
                    }, _defineProperty(_React$createElement39, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement39, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement39, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement39, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement39),
                    '--'
                )
            ),
            React.createElement(
                'li',
                (_React$createElement43 = { className: 'rtg-size', 'data-codecept-selector-node': 'li',
                    'data-codecept-selector-file': 'ImagePickerWidget'
                }, _defineProperty(_React$createElement43, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement43, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement43, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement43, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement43),
                React.createElement(
                    'em',
                    (_React$createElement41 = {
                        'data-codecept-selector-node': 'em',
                        'data-codecept-selector-file': 'ImagePickerWidget'
                    }, _defineProperty(_React$createElement41, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement41, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement41, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement41, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement41),
                    'Size: '
                ),
                React.createElement(
                    'span',
                    (_React$createElement42 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'ImagePickerWidget'
                    }, _defineProperty(_React$createElement42, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement42, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement42, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement42, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement42),
                    '--'
                )
            ),
            React.createElement(
                'li',
                (_React$createElement46 = { className: 'rtg-type', 'data-codecept-selector-node': 'li',
                    'data-codecept-selector-file': 'ImagePickerWidget'
                }, _defineProperty(_React$createElement46, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement46, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement46, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement46, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement46),
                React.createElement(
                    'em',
                    (_React$createElement44 = {
                        'data-codecept-selector-node': 'em',
                        'data-codecept-selector-file': 'ImagePickerWidget'
                    }, _defineProperty(_React$createElement44, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement44, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement44, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement44, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement44),
                    'Media type: '
                ),
                React.createElement(
                    'span',
                    (_React$createElement45 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'ImagePickerWidget'
                    }, _defineProperty(_React$createElement45, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement45, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement45, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement45, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement45),
                    '--'
                )
            )
        );
    },

    componentDidUpdate: function componentDidUpdate(prevProps) {
        //# TODO: we could techinically update this component so that the image picker modal is only responsible for dispatching the selected value
        //# the non modal parts of the component can be completely React-ified
        if (prevProps.value != this.props.value) {
            var $imagePicker = $(ReactDOM.findDOMNode(this));
            if (this.props.value) {
                //$imagePicker.rubyImagePicker('insert', { id: this.props.value } );
            } else {
                    //$imagePicker.rubyImagePicker('reset');
                }
        }
    }

    // Do we have to account for image-uploader anymore?
    ,
    componentDidMount: function componentDidMount() {
        var self = this;

        /*
        $( ReactDOM.findDOMNode(this) ).rubyImagePicker({
            delegateChange: function(asset_id){
                let evt = {
                    target: {
                        value: asset_id
                    }
                };
                self.handleChangeWrapper(evt);
            }
        });
        */

        // If a component happens to have a value on load, then
        // we need to tell the image picker to load it in
        // ( this was an issue with ruby modules, since these will
        // most liekly know their values on first render )
        if (this.props.value) {
            //$( ReactDOM.findDOMNode(this) ).rubyImagePicker('insert', { id: this.props.value } );
        }

        DEPRECATED_ERROR();
    },
    componentWillUnmount: function componentWillUnmount() {
        //$( ReactDOM.findDOMNode(this)).rubyImagePicker('destroy');
        DEPRECATED_ERROR();
    },
    getPrettyLabel: function getPrettyLabel(label) {
        var prettyLabel = label.charAt(0).toUpperCase() + label.slice(1);
        prettyLabel = prettyLabel.replace(/_/g, ' ');
        return prettyLabel;
    },

    getCategoryForType: function getCategoryForType(ext) {
        var info = RubyConfig.Constants.Asset.asset_types[ext];
        return info ? info.type : RubyConfig.Constants.Asset.asset_types[''].type;
    },

    getExtensions: function getExtensions() {
        var types = this.props.constraints && this.props.constraints.type ? this.props.constraints.type.split(/\s*,\s*/) : [RubyConfig.Constants.Asset.IMAGE_CATEGORY, RubyConfig.Constants.Asset.VIDEO_CATEGORY];
        var extensions = [];

        for (var k = 0; k < types.length; k++) {
            var type = types[k];
            if (RubyConfig.Constants.Asset.category_extensions[type]) {
                extensions = extensions.concat(Object.keys(RubyConfig.Constants.Asset.category_extensions[type]));
            } else if (RubyConfig.Constants.Asset.asset_types[type]) {
                extensions.push(type);
            }
        }

        return extensions;
    }

    // copied exactly from Ruby::Core::Asset
    , prettyExtDisplay: function prettyExtDisplay(type) {
        var self = this;
        var exts = self.getExtensions();
        var type_groups = [],
            category_extensions = {};
        var display_order = [RubyConfig.Constants.Asset.VIDEO_CATEGORY, RubyConfig.Constants.Asset.SLIDESHOW_CATEGORY, RubyConfig.Constants.Asset.IMAGE_CATEGORY, RubyConfig.Constants.Asset.AUDIO_CATEGORY, RubyConfig.Constants.Asset.PDF_CATEGORY, RubyConfig.Constants.Asset.DOCUMENT_CATEGORY, RubyConfig.Constants.Asset.FLASH_CATEGORY, RubyConfig.Constants.Asset.TEXT_CATEGORY];
        var fake_filenames = ['vmo', 'yt', 'show'];
        var ucfirst = function ucfirst(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        };

        // make hash of categories to array of extensions
        for (var k = 0; k < exts.length; k++) {
            var ext = exts[k];
            var category = self.getCategoryForType(ext);

            if (fake_filenames.indexOf(ext) >= 0) ext = ucfirst(RubyConfig.Constants.Asset.asset_types[ext].text);

            if (!category_extensions[category]) category_extensions[category] = [];
            category_extensions[category].push(ext);
        }

        Object.keys(category_extensions).forEach(function (category) {
            if (category_extensions[category].length > 1) {
                var group = ucfirst(category) + ' ( ' + category_extensions[category].sort().join(', ') + ' )';
                category_extensions[category] = group;
            } else {
                category_extensions[category] = category_extensions[category][0];
            }
        });

        // add sorted groups from hash
        for (var k = 0; k < display_order.length; k++) {
            var cat = display_order[k];
            if (category_extensions[cat]) {
                type_groups.push(category_extensions[cat]);
                delete category_extensions[cat];
            }
        }

        // append any remaining groups from hash
        Object.keys(category_extensions).sort().forEach(function (cat) {
            type_groups.push(category_extensions[cat]);
        });

        return type_groups.join(', ');
    } // prettyExtDisplay

    , renderConstraints: function renderConstraints() {
        var _React$createElement54, _React$createElement55, _React$createElement56;

        var constraints = this.props.constraints || {};
        var pretty_constraints = [];
        var extensions = this.getExtensions().join(', ');

        constraints.type = constraints.type || 'image,video';

        for (var key in constraints) {
            var _React$createElement48, _React$createElement49, _React$createElement50, _React$createElement51, _React$createElement52, _React$createElement53;

            var value = constraints[key];

            var prettyValue = value;

            if (key.match(/_(width|height)$/)) prettyValue += 'px';
            if (key === 'type') {
                value = extensions;
                prettyValue = this.prettyExtDisplay(value);
            }

            // Uppercase first letter
            var label = this.getPrettyLabel(key);

            // React reacts to loose text in the components below by enclosing the loose text
            // and the variable each in a span, this broke the image picker code that determined
            // the constraints. The new class name solves this problem.
            var augmentedKey = "rtg-" + key;
            pretty_constraints.push(React.createElement(
                'li',
                (_React$createElement50 = { className: augmentedKey, key: augmentedKey, style: { display: 'none' }, 'data-codecept-selector-node': 'li',
                    'data-codecept-selector-file': 'ImagePickerWidget'
                }, _defineProperty(_React$createElement50, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement50, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement50, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement50, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement50),
                React.createElement(
                    'em',
                    (_React$createElement48 = {
                        'data-codecept-selector-node': 'em',
                        'data-codecept-selector-file': 'ImagePickerWidget'
                    }, _defineProperty(_React$createElement48, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement48, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement48, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement48, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement48),
                    label
                ),
                React.createElement(
                    'span',
                    (_React$createElement49 = { className: 'rtg-constraint-value', 'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'ImagePickerWidget'
                    }, _defineProperty(_React$createElement49, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement49, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement49, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement49, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement49),
                    value
                )
            ));

            pretty_constraints.push(React.createElement(
                'li',
                (_React$createElement53 = { className: "display-rtg-" + key, key: "display-rtg-" + key, 'data-codecept-selector-node': 'li',
                    'data-codecept-selector-file': 'ImagePickerWidget'
                }, _defineProperty(_React$createElement53, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement53, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement53, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement53, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement53),
                React.createElement(
                    'em',
                    (_React$createElement51 = {
                        'data-codecept-selector-node': 'em',
                        'data-codecept-selector-file': 'ImagePickerWidget'
                    }, _defineProperty(_React$createElement51, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement51, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement51, 'data-codecept-selector-node', 'em'), _defineProperty(_React$createElement51, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement51),
                    label,
                    ': '
                ),
                React.createElement(
                    'span',
                    (_React$createElement52 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'ImagePickerWidget'
                    }, _defineProperty(_React$createElement52, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement52, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement52, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement52, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement52),
                    prettyValue
                )
            ));
        }
        return React.createElement(
            'ul',
            (_React$createElement56 = { className: 'rtg-constraints', 'data-codecept-selector-node': 'ul',
                'data-codecept-selector-file': 'ImagePickerWidget'
            }, _defineProperty(_React$createElement56, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement56, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement56, 'data-codecept-selector-node', 'ul'), _defineProperty(_React$createElement56, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement56),
            React.createElement(
                'li',
                (_React$createElement55 = { key: 'rtg-constraints-header', 'data-codecept-selector-node': 'li',
                    'data-codecept-selector-file': 'ImagePickerWidget'
                }, _defineProperty(_React$createElement55, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement55, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement55, 'data-codecept-selector-node', 'li'), _defineProperty(_React$createElement55, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement55),
                React.createElement(
                    'h2',
                    (_React$createElement54 = {
                        'data-codecept-selector-node': 'h2',
                        'data-codecept-selector-file': 'ImagePickerWidget'
                    }, _defineProperty(_React$createElement54, 'data-codecept-selector-node', 'h2'), _defineProperty(_React$createElement54, 'data-codecept-selector-file', 'ImagePickerWidget'), _defineProperty(_React$createElement54, 'data-codecept-selector-node', 'h2'), _defineProperty(_React$createElement54, 'data-codecept-selector-file', 'ImagePickerWidget'), _React$createElement54),
                    'Media Constraints'
                )
            ),
            pretty_constraints
        );
    },
    getAdditionalParentClasses: function getAdditionalParentClasses() {
        var classes = [];
        if (this.props.additionalParentClasses) classes.push(this.props.additionalParentClasses);

        //# NOTE: the occurrence of 'rtf-no-media' class manipulation in the jQuery widget is minimal.
        //# I *believe* it'll be ok if we added logic to include/exclude the classes here

        if (!this.props.value) {
            classes.push('rtg-no-media');
        }

        return classes.join('  ');
    },
    getDefaultProps: function getDefaultProps() {
        return {
            constraints: {}
        };
    }
});

module.exports = ImagePicker;