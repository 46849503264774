'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AFTER_REDUCE = AFTER_REDUCE;
exports.GRIDDLE_ROW_TOGGLED = GRIDDLE_ROW_TOGGLED;
exports.GRIDDLE_LOADED_DATA_AFTER = GRIDDLE_LOADED_DATA_AFTER;
exports.getVisibleChildData = getVisibleChildData;
exports.setRowProperties = setRowProperties;

var _lodash = require('lodash.assign');

var _lodash2 = _interopRequireDefault(_lodash);

var _griddleCore = require('../../griddle-core');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var IS_DEV_MODE = "production" !== 'production';

var data = _griddleCore.GriddleHelpers.data;
var getVisibleDataColumns = data.getVisibleDataColumns;

/*
OVERALL TODO:
  fix column order
*/

function hasChildren(record) {
  var childrenPropertyName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'children';

  return record.get(childrenPropertyName) && record.get(childrenPropertyName).size > 0;
}

function transform(data, state) {
  var childrenPropertyName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'children';

  var currentData = data;
  /* //# we're being passed the data that we need, so don't use filter
  if(state.get('filter') && state.get('filter') !== '') {
    currentData = filterChildren(data, state.get('filter'), childrenPropertyName);
  }
  */

  return currentData;
}

function sortChildren(data, state, helpers) {
  var childrenPropertyName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'children';

  var sortColumns = state.getIn(['sortProperties', 'sortColumns']);
  var sortAscending = state.getIn(['sortProperties', 'sortAscending']);
  var idColumn = state.get('idColumn');
  var getSortedRows = function getSortedRows(data) {
    var sort = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    var mappedData = data.map(function (row, index) {
      var griddleKey = row.getIn(['__metadata', 'griddleKey']);
      var rowIdValue = getRowIdValue_fromGriddleKey(state.get('data'), griddleKey, idColumn, childrenPropertyName) + '';

      return hasChildren(row) && state.get('expandedRows').has(rowIdValue) === true ? row.set('children', getSortedRows(row.get('children'), true)) : row;
    });

    return sort ? helpers.getSortedData(mappedData, sortColumns, sortAscending) : mappedData;
  };

  if (!sortColumns || !helpers) {
    return data;
  }

  return getSortedRows(data);
}

/*
function nestedParentIdReducer(collector = [], object) {
    if (object && object.children) {
        collector.push(object.id);
        _.reduce(object.children, nestedParentIdReducer, collector);
    }
    return collector;
}
*/

function nestedParentIdReducer_generator(matcher) {
  return function nestedParentIdReducer() {
    var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var object = arguments[1];

    if (matcher(object)) {
      collector.push(object.id);
      _.reduce(object.children, nestedParentIdReducer, collector);
    }
    return collector;
  };
}

function AFTER_REDUCE(state, action, helpers) {
  //# ~200ms
  //IS_DEV_MODE && console.time('==== AFTER_REDUCE');
  var columns = helpers.getDataColumns(state, data);

  var properties = getProperties(columns);
  var data = transform(state.get('visibleData'), state, properties.childrenPropertyName);

  columns.push(properties.childrenPropertyName);

  //# NOTE: we dont' need to sortChildren or getSortedColumsn
  //# we don't rely on objects with ordered keys cause we get the jsonObject instead of using immutable directly
  //# we dont need to sortChildren because we get it already sorted from the server
  //IS_DEV_MODE && console.time('==== newState');
  var newState = state.set('visibleData', getVisibleChildData(data, columns)); //# maybe ~70 - 86ms
  //.set('visibleData', sortChildren(getVisibleChildData(data, columns), state, helpers, properties.childrenPropertyName), columns); //# maybe ~90ms
  //.set('visibleData', sortChildren(helpers.getSortedColumns(getVisibleChildData(data, columns), columns), state, helpers, properties.childrenPropertyName), columns); //# ~ 94ms
  //IS_DEV_MODE && console.timeEnd('==== newState');

  var filterState = state.get('filter');
  if (action.type == 'GRIDDLE_FILTERED' && filterState && filterState !== '') {
    //# expand columns that we need to expand
    var idsThatHaveChildren = _.reduce(state.get('data').toJS(), nestedParentIdReducer_generator(function (object) {
      return object && object.children;
    }), []);

    var expandedRows = newState.get('expandedRows');
    var newExpandedRows = idsThatHaveChildren.reduce(function (collector, id) {
      return collector.set(id, true);
    }, expandedRows);

    newState = newState.set('expandedRows', newExpandedRows);
    //# TODO: if we want to clear the expandedRows, then we want to watch for filterState == ''
    //# and then clear the expandedRows state
  } else if (action.type == 'GRIDDLE_LOADED_DATA') {
    var _idsThatHaveChildren = _.reduce(state.get('data').toJS(), nestedParentIdReducer_generator(function (object) {
      return object && object.__autoexpandForCMS;
    }), []);

    var _expandedRows = newState.get('expandedRows');
    var _newExpandedRows = _idsThatHaveChildren.reduce(function (collector, id) {
      return collector.set(id, true);
    }, _expandedRows);

    newState = newState.set('expandedRows', _newExpandedRows);
  }

  //IS_DEV_MODE && console.timeEnd('==== AFTER_REDUCE');
  //# with just getVisibleChildData: 68 - 151ms
  //# with everything: 69.6 - 153ms (although I think it averages higher)
  return newState;
}

function GRIDDLE_ROW_TOGGLED(state, action, helpers) {
  var griddleKey = action.griddleKey,
      direction = action.direction,
      includeDescendants = action.includeDescendants;


  var columns = helpers.getDataColumns(state, state.get('data'));

  var _getProperties = getProperties(columns),
      childrenPropertyName = _getProperties.childrenPropertyName;

  var idColumn = state.get('idColumn');
  var rowValues = griddleKey ? [getRowValue_fromGriddleKey(state.get('data'), griddleKey, idColumn, childrenPropertyName)] : getTopLevelRowValuesWithChildren(state.get('data'));

  // the reason we can't use the griddleKey is because
  // it will likely change as we use filter + sort actions

  var hydratedDirection = direction;

  if (direction == 'toggle') {
    //# if direction is toggle, there should be only one rowValue
    //# even if there are multiple, the direction should be the same for all rows
    //# so use the first rowValue to determine direction
    var rowValue = rowValues[0];
    var rowIdValue = rowValue.get(idColumn) + '';
    hydratedDirection = state.get('expandedRows').has(rowIdValue.toString()) || state.get('expandedRows').has(Number.parseInt(rowIdValue)) ? 'collapse' : 'expand';
  }

  var nodesToToggle = [].concat(_toConsumableArray(rowValues));
  var filteredGetChildren = helpers.filteredGetChildren(function (child) {
    //# if child has childrne
    return child.get('hasChildren');
  });

  if (includeDescendants) {
    //# walk rowValue and include all children
    rowValues.forEach(function (rowValue) {
      nodesToToggle = nodesToToggle.concat(helpers.flattenNestedDataBy(filteredGetChildren(rowValue), filteredGetChildren));
    });
  }

  var newState = state;
  if (hydratedDirection == 'collapse') {
    newState = nodesToToggle.reduce(function (stateAcc, node) {
      var rowIdValue = node.get(idColumn) + '';

      return stateAcc.set('expandedRows', stateAcc.get('expandedRows').remove(rowIdValue.toString()).remove(Number.parseInt(rowIdValue)));
    }, newState);
  } else {
    newState = nodesToToggle.reduce(function (stateAcc, node) {
      var rowIdValue = node.get(idColumn) + '';

      return stateAcc.set('expandedRows', stateAcc.get('expandedRows').set(rowIdValue, true));
    }, newState);
  }

  return newState;
}

function getTopLevelRowValuesWithChildren(data) {
  var childrenPropertyName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'children';

  var rowValues = [];
  data.forEach(function (row) {
    var children = row.get(childrenPropertyName);
    if (children && children.size > 0) {
      rowValues.push(row);
    }
  });
  return rowValues;
}
function getRowValue_fromGriddleKey(data, griddleKey) {
  var idColumn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'id';
  var childrenPropertyName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'children';

  var immutableListToSearch = [data];
  var currentNode = void 0;
  var foundNode = void 0;

  currentNode = immutableListToSearch.pop();
  while (foundNode === undefined) {
    currentNode.forEach(function (row) {
      if (foundNode === undefined) {
        if (row.get('griddleKey') === griddleKey) {
          foundNode = row;
          return;
        }

        var children = row.get(childrenPropertyName);
        if (children && children.size > 0) {
          immutableListToSearch.push(children);
        }
      }
    });

    currentNode = immutableListToSearch.pop();
  }

  return foundNode;
}
function getRowIdValue_fromGriddleKey(data, griddleKey) {
  var idColumn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'id';
  var childrenPropertyName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'children';

  var immutableListToSearch = [data];
  var foundNode = getRowValue_fromGriddleKey.apply(null, arguments);

  return foundNode ? foundNode.get(idColumn) : foundNode;
}

//TODO: This is almost the same as the filterChildrenData method but not applying the filter method :/
function filterChildren(rows, filter) {
  var childrenPropertyName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'children';

  return rows.map(function (row) {
    var children = row.get(childrenPropertyName);

    if (children && children.size > 0) {
      children = filterChildrenData(row.get(childrenPropertyName), filter, childrenPropertyName);
    }

    return row.set(childrenPropertyName, children);
  });
}

function filterChildrenData(rows, filter) {
  var childrenPropertyName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'children';

  var values = rows.filter(function (row) {
    var children = row.get(childrenPropertyName);

    if (children && children.size > 0) {
      children = filterChildrenData(row.get(childrenPropertyName), filter, childrenPropertyName);
    }

    var hasMatch = children && children.length > 0 || Object.keys(row.toJSON()).some(function (key) {
      return row.get(key) && row.get(key).toString().toLowerCase().indexOf(filter.toLowerCase()) > -1;
    });

    return hasMatch;
  });
  return values;
}

function GRIDDLE_LOADED_DATA_AFTER(state, action, helpers) {
  var data = state.get('data');
  var columns = helpers.getDataColumns(state, data);
  var newData = setRowProperties(state, data, getProperties(columns));

  return state.set('data', newData);
}

function getProperties(columns) {
  return (0, _lodash2.default)({
    childrenPropertyName: 'children',
    columns: []
  }, columns);
}

function hasMetadata(data) {
  var metadata = data.get(0).get('__metadata');
  return metadata && metadata.size > 0;
}

//# TODO: for this to work, we need to memoize `getVisibleDataColumns`
//# which is a challenge itself because we're using rubyLodash.get
//# and it's also mergeDeep, so the data it returns is completely new
/*
const memoize = require('memoizee')
const memProfile = require('memoizee/profile');
const { defaultMemoizeeOptions } = require('@rubyapps/ruby-memoize');
const memoize__normalizers__getFixed = require("memoizee/normalizers/get-fixed");
const getVisibleChildData__normalizers__getFixed = memoize__normalizers__getFixed(3);
const getVisibleChildData = memoize(
    ...
, {
    ...defaultMemoizeeOptions
    , profileName: 'getVisibleChildData'
    , normalizer: {
        get: (args) => {
            const [data, columns, childrenPropertyName] = args;

            return getVisibleChildData__normalizers__getFixed.get([data, JSON.stringify(columns), childrenPropertyName]);
        }
        , set: (args) => {
            const [data, columns, childrenPropertyName] = args;

            return getVisibleChildData__normalizers__getFixed.set([data, JSON.stringify(columns), childrenPropertyName]);
        }
        , delete: getVisibleChildData__normalizers__getFixed.delete
        , clear: getVisibleChildData__normalizers__getFixed.clear
    }
});
*/
function getVisibleChildData(data, columns) {
  var childrenPropertyName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'children';

  if (data.size === 0) {
    return data;
  }
  //get the data and make sure metadata is applied
  var dataWithMetadata = hasMetadata(data) ? data : getVisibleDataColumns(data, columns);

  //go through each visible child row and set it to use the correct column settings

  return dataWithMetadata.map(function (row, index) {
    var children = row.get(childrenPropertyName);

    if (children && children.size > 0) {
      children = getVisibleChildData(children, columns, childrenPropertyName);
    }

    return row.set('children', children);
  });
}

function setRowProperties(state, data, properties) {
  var depth = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  var parentId = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;

  var key = 0;
  var getKey = function getKey() {
    return key += 1;
  };

  return data.map(function (row, index) {
    var children = row.get(properties.childrenPropertyName);
    var currentKey = parentId !== null ? parentId + '.' + getKey() : '' + row.get('griddleKey');

    if (children && children.size > 0) {
      children = setRowProperties(state, children, { childrenPropertyName: properties.childrenPropertyName, columns: properties.columns }, depth + 1, currentKey);
    }

    return row.sortBy(function (val, key) {
      return properties.columns.indexOf(key);
    }).set('children', children).set('depth', depth).set('expandedFlag', state.get('expandedRows').get(row.get('id'))).set('griddleKey', currentKey).set('parentId', parentId).set('hasChildren', children && children.size > 0);
  });
}