'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.registerComponentForKey = exports.Components = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = createRubyComponentsFromFormJS;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _components = require('./components');

var _components2 = _interopRequireDefault(_components);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RubyComponent = require('@rubyapps/ruby-component');
/*
   {
        "componentName": "Form"
        , "modelType": "content"
        , "children": [
            {
                "componentName": "Text"
                , "label": "Name"
                , "key": "name"
            }
        ]
    }
*/

var CONSTANTS = require('./constants');

//# traverses
var OMIT_PROPS = ['component', 'children'];
//# idPrefix: key[${i}].key[${j}]
function createRubyComponentNodeWithSpec(spec, idPrefix, depth, index, ctx) {
    var componentKey = spec.componentName;
    var theComponent = _components2.default[componentKey];
    if (!theComponent) {
        var originalSpec = spec;
        spec = _extends({}, spec, {
            value: 'Component not found for spec: <br/> <pre>' + JSON.stringify(originalSpec, null, 4) + '</pre>'
        });
        theComponent = _components2.default.Info;
    }

    var theComponentName = spec.key || theComponent.componentName;
    var compoundID = spec.id ? spec.id : idPrefix ? idPrefix + '.' + theComponentName : theComponentName;

    var additionalPropsBasedOnComponent = _additionalPropsForAllFields(spec, depth, index);
    if (spec.componentName == 'Fieldset') {
        additionalPropsBasedOnComponent = _extends({}, additionalPropsBasedOnComponent, _additionalPropsForFieldset(spec, depth, index));
    }
    var props = _extends(additionalPropsBasedOnComponent, _lodash2.default.omit(spec, OMIT_PROPS), {
        id: compoundID
    });

    var children = spec.children ? spec.children : [];

    var formattedChildren = _lodash2.default.reduce(children, function (collector, child, index) {
        if (child == undefined) {
            return collector;
        }
        if (child.componentName == 'Tab' && spec.componentName != 'Tabs') {
            if (collector.currentTabs == undefined) {
                collector.currentTabs = { componentName: 'Tabs', children: [] };
                collector.children.push(collector.currentTabs);
            }
            collector.currentTabs.children.push(child);
        } else {
            collector.children.push(child);
        }
        return collector;
    }, {
        currentTabs: undefined,
        children: []
    }).children;

    //# TODO: instead of calling on children like this, we should expect component to have 
    //# .children() method
    //# that way, it's the responsibility of each component to determine whether it should generate teh children
    //# 
    var childrenDepth = depth + 1;
    var childrenComponents = [];

    //# TODO: remove
    /*
    const childrenComponents = _.map(formattedChildren, (childSpec, index) =>
        createRubyComponentNodeWithSpec(childSpec, `${compoundID}[${index}]`, childrenDepth, index)
    ).filter((rubyComponentChild) => rubyComponentChild != undefined);
    */

    return RubyComponent.createElement.apply(RubyComponent, [theComponent, _extends({}, props, _lodash2.default.pick(ctx, ['_parent']), { __originalSpec: spec,
        __formattedChildren: formattedChildren
        //# NOTE: `children` is a reserved key, eg. header uses it
        //# we blindly spread the rubyComponent props to the react component, so children cannot be used
        //# while we can handle this key specifically, I think it's better to use a different key
        //# for the purposes of handling the RubyComponent children interally by each individual component
        //# we should use `__formattedChildren` instead
    })].concat(childrenComponents));
}

function _additionalPropsForAllFields(spec, depth, index) {
    return {
        __depth: depth,
        __index: index
    };
}
function _additionalPropsForFieldset(spec, depth, index) {
    return {
        expandedByDefault: depth <= 4 && index < CONSTANTS.EXPAND_FIRST_NTH_CHILDREN
    };
}

function createRubyComponentsFromFormJS(formJS, idPrefix) {
    var depth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var index = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    var ctx = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

    //# const {_parent} = ctx;
    //#     normally set by `newMergedExplicitAndImplicitChildren` 
    //#     but some `children()` expects to retrieve state, which relies on this._parent

    //# walk the tree
    return createRubyComponentNodeWithSpec(formJS, idPrefix, depth, index, ctx);
}

exports.Components = _components2.default;
exports.registerComponentForKey = _components.registerComponentForKey;