'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _Text = require('./Text');

var _Text2 = _interopRequireDefault(_Text);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector,
        referenceSelector = _selfModule$getDepend.referenceSelector;

    //# figure out the toHTML ahead of time


    var hydratedToHTML = this.hydratedToHTMLFromProps(this.props);

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);

        var anyOfReferencedComponentsHasError = _lodash2.default.reduce(selfModule.getReferencedStatesByKey(state), function (collector, referencedState, key) {
            if (collector) {
                return collector;
            }

            var referencedError = _lodash2.default.get(referencedState, ['fields', key, 'error', 'message'], null);

            return !!referencedError;
        }, false);

        var mappedReferenceComponentValue = selfModule.getReferencedFormValue(state);

        var stateProps = _lodash2.default.pick(_lodash2.default.get(selfState, ['fields', _lodash2.default.get(selfModule, 'props.key')]), ['value', 'error']);
        var stateError = selfModule.formattedErrorFromState(state);

        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);

        var statePropsValue = stateProps.value;
        var valueAvailable = statePropsValue != null && statePropsValue != '';
        //# NOTE:, _formValueFromLocalState for this component checks if value is null and defaults to the referenced formValue
        //# the _getValuesFromFieldsObject > predicateFormatter returns null for ''
        var errorAvailable = stateError.richErrorMessages.length;
        //# 20170518 - NOTE: we are showing sweeper errors for this field if it's set in its local state
        //# BUT we're not handling the case where the referenced state has sweeper errors 
        //# It should never get to that point where we need to show sweeper errors for
        //# the referenced component since the sweeper error is only relevant for the 
        //# referenced component

        var errorForReferenceComponentValue = !valueAvailable && (mappedReferenceComponentValue || anyOfReferencedComponentsHasError) ? selfModule.validateValue_forField(mappedReferenceComponentValue, mixinFieldProps.fieldKey) : [{ message: null }];
        //# if self value isn't available, find errors for referencedValue
        //# we don't care if the referencedValue is available or not (because we'll want to required check)
        //# However, we dont' want to generate the error message on a fresh state, so we should see if the referenced component has an error and follow suit
        //const errorForReferenceComponentValue = !valueAvailable? selfModule.validateValue_forField(mappedReferenceComponentValue, mixinFieldProps.fieldKey) :[{message:null}];

        //# Hacky, but used to clear the parent errors for now
        if (!valueAvailable) {
            //# if we don't have a local value
            //# we should refresh the parent errors in case we need to set or clear them
            selfModule.refreshParentErrors();
        }

        //# TODO: suggestedMode: true/false
        //# if suggestedMode, we should show the button instead
        var mappedProps = _extends({}, ownProps, mixinFieldProps, _extends({}, stateProps, {
            value: valueAvailable ? statePropsValue : !ownProps.suggestedMode ? mappedReferenceComponentValue : statePropsValue,
            suggestedValue: ownProps.suggestedMode ? mappedReferenceComponentValue : null,
            isAutoPopulated: !valueAvailable
        }), hydratedToHTML ? { toHTML: hydratedToHTML } : {}, errorAvailable ? stateError : {
            error: {
                message: errorForReferenceComponentValue.length ? errorForReferenceComponentValue[0].message : null
            }
        });

        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Text2.default);
}