'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = LogLevelFormatterGenerator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _bunyan = require('bunyan');

var _bunyan2 = _interopRequireDefault(_bunyan);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function LogLevelFormatterGenerator(options, parentProps) {

    return _react2.default.createClass({
        render: function render() {
            var _React$createElement;

            return _react2.default.createElement(
                'div',
                (_React$createElement = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'LogLevelFormatterGenerator'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'LogLevelFormatterGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'LogLevelFormatterGenerator'), _React$createElement),
                _lodash2.default.get(_bunyan2.default, ['nameFromLevel', this.props.data], "").toUpperCase()
            );
        }
    });
}