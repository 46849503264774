'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = generator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _reactRedux = require('react-redux');

var _ViewLink = require('./ViewLink');

var _ViewLink2 = _interopRequireDefault(_ViewLink);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        parentForm = _selfModule$getDepend.parentForm;

    function mapStateToProps() /*state*/{

        var status = _lodash2.default.result(parentForm, 'getState.pristineFormData.status');

        var link = status && status == 'active' && (_lodash2.default.get(selfModule.getProps(true), 'link') || _lodash2.default.result(parentForm, 'getState.pristineFormData.url'));

        return {
            label: selfModule.props.label || 'View',
            link: link,
            disabled: !link || _lodash2.default.get(selfModule, 'props.disabled')
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps)(_ViewLink2.default);
}