'use strict';

var path = require('path');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

var EventEmitter = require('events');
var _ = require('lodash');

var _require = require('../common/constants'),
    NAMESPACES = _require.NAMESPACES;

var STATE_EVENT_SPECS_KEY = 'stateEventSpecs';

module.exports = {
    mixinName: 'rubyComponentMixinEventEmitter',
    doIfShouldEmitForEventName: function doIfShouldEmitForEventName(eventName) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var eventEmitter = this.eventEmitter();
        var selfState = this.getState();
        var selfID = this.getID();
        var onAny = options.onAny;

        //console.debug(`[${selfID}] checking for eventName: [${eventName}]`, selfState);

        var selectedStateValue = this.getStateForEventName(eventName);
        var cachedStateValue = this._getCachedStateForEventName(eventName);

        if (selectedStateValue !== undefined) {
            if (onAny || !_.isEqual(selectedStateValue, cachedStateValue)) {
                logger.debug('[' + selfID + '] emitting eventName: [' + eventName + '] with selected state: ', selectedStateValue);

                this._updateCachedStateForEventName(eventName, selectedStateValue);
                eventEmitter.emit(eventName, selectedStateValue, selfState, cachedStateValue //# prevWatchedState
                );
            }
        }
    },
    isEventNameValidNamespace: function isEventNameValidNamespace(eventName) {
        var eventPair = eventName.split(':');

        if (eventPair[0] == NAMESPACES.STATE) {
            return true;
        }

        return false;
    },
    getStateForEventName: function getStateForEventName(eventName, selfState) {
        var eventPair = eventName.split(':');

        if (eventPair[0] != NAMESPACES.STATE) {
            throw new Error('Event: [' + eventName + '] is not compatible with ' + this.mixinName);
        }

        selfState = selfState || this.getState();

        var keypath = eventPair[1];
        var selectedStateValue = _.get(selfState, keypath);

        return selectedStateValue;
    },

    onReduxInit: function onReduxInit() {
        var _this = this;

        return this.getStore().subscribe(function () {
            var eventEmitter = _this.eventEmitter();

            //# iterate through the events and select the keypaths based on the registered events
            //# emit if the value is not undefined
            var eventNames = Object.keys(_this.getStatefulCacheForKey(STATE_EVENT_SPECS_KEY) || {});

            //Object.keys(eventEmitter._events).forEach(eventName => {
            eventNames.forEach(function (eventName) {
                var options = _this._getCachedOptionsForEventName(eventName);
                _this.doIfShouldEmitForEventName(eventName, options);
            });
        });
    },
    onUnmount: function onUnmount() {
        var eventEmitter = this.eventEmitter();

        eventEmitter.removeAllListeners();
    },

    eventEmitter: function eventEmitter() {
        if (this._eventEmitter) {
            return this._eventEmitter;
        }

        //# set up the EventEmitter instance
        var eventEmitter = new EventEmitter();
        this._eventEmitter = eventEmitter;

        //# list for event registrations so we can keep track
        /*
        eventEmitter.on('newListener', (event, listener) => {
            
            debugger;
        });
        */
        //# NOTE: for now, we can just do Object.keys(eventEmitter._events)
        //# but caution that _events is an internal property

        return eventEmitter;
    }
    /**
     * Register an event handle
     * @param {string} eventName - formatted to be a local keypath to this module's state (eg. 'state:forms')
     * @param {Function} eventHandler - (selectedState, moduleState) => {}
     * @param {Object} options
     * @param {boolean} options.preempt - call on emit if the state we're expecting is available
     * @param {boolean} options.onAny - emit regardless of whether state has changed. By default, it only emits on state changes
     **/
    , on: function on(eventName, eventHandler) {
        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        var eventEmitter = this.eventEmitter();

        //# TODO: check if the eventName is a valid 'state:keypath' format
        //# if it's not, we just do a regular eventEmitter bind

        eventEmitter.on.apply(eventEmitter, arguments);
        if (this.isEventNameValidNamespace(eventName)) {
            this._setInitialCachedStateForEventName(eventName, options);

            if (options.preempt) {
                //# if we're preempting, then we need to allow any through
                this.doIfShouldEmitForEventName(eventName, { onAny: true });
            }
        }
    },
    off: function off() {
        var eventEmitter = this.eventEmitter();

        return eventEmitter.removeListener.apply(eventEmitter, arguments);
    },
    once: function once(eventName, eventHandler) {
        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        var eventEmitter = this.eventEmitter();

        eventEmitter.once.apply(eventEmitter, arguments);

        if (this.isEventNameValidNamespace(eventName)) {
            this._setInitialCachedStateForEventName(eventName, options);
            if (options.preempt) {
                this.doIfShouldEmitForEventName(eventName, { onAny: true });
            }
        }
    },
    emit: function emit() {
        var eventEmitter = this.eventEmitter();

        eventEmitter.emit.apply(eventEmitter, arguments);
    }

    //# == CACHE HANDLING =====================================//
    , _getCachedStateForEventName: function _getCachedStateForEventName(eventName) {
        return this.getStatefulCacheForKey([STATE_EVENT_SPECS_KEY, eventName, 'state']);
    },
    _getCachedOptionsForEventName: function _getCachedOptionsForEventName(eventName) {
        return this.getStatefulCacheForKey([STATE_EVENT_SPECS_KEY, eventName, 'options']);
    },
    _updateCachedStateForEventName: function _updateCachedStateForEventName(eventName, state) {
        this.setStatefulCacheForKey([STATE_EVENT_SPECS_KEY, eventName, 'state'], state);
    },
    _setInitialCachedStateForEventName: function _setInitialCachedStateForEventName(eventName, options) {
        this.setStatefulCacheForKey([STATE_EVENT_SPECS_KEY, eventName], {
            state: this.getStateForEventName(eventName),
            options: options
        });
    },
    _clearCachedStateForEventName: function _clearCachedStateForEventName(eventName) {
        this.clearStatefulCacheForKey([STATE_EVENT_SPECS_KEY, eventName]);
    }
};