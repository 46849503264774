'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

var _IconMenu = require('material-ui/IconMenu');

var _IconMenu2 = _interopRequireDefault(_IconMenu);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _IconButton = require('material-ui/IconButton/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _moreVert = require('material-ui/svg-icons/navigation/more-vert');

var _moreVert2 = _interopRequireDefault(_moreVert);

var _modeEdit = require('material-ui/svg-icons/editor/mode-edit');

var _modeEdit2 = _interopRequireDefault(_modeEdit);

var _launch = require('material-ui/svg-icons/action/launch');

var _launch2 = _interopRequireDefault(_launch);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var CONSTANTS = require('../common/constants');
var path = require('path');
var Route = require('route-parser');

var _require = require('@rubyapps/griddle'),
    customComponents = _require.customComponents,
    customComponentsByType = _require.customComponentsByType;

var rubyWords = require('@rubyapps/ruby-words');
var rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');

//# Material Components


//# Icons

require('deepdash')(_lodash2.default);
function findStatusField_inForm(form) {
    return _lodash2.default.findValueDeep(form, _lodash2.default.matches({ componentName: "ScheduledStatus" }), {
        childrenPath: ['children']
    });
}

var COMPONENTS_BY_TYPE = customComponentsByType;

module.exports = function (parentProps) {
    var selfModule = this;
    var selfDependencies = selfModule.getDependencies();

    var apiUrlPrefix = parentProps.apiUrlPrefix,
        actions = parentProps.actions;


    var columnDefinitionsFromParentProps = getColumnDefintions_fromParentProps(parentProps, selfDependencies);

    var columnDefinitions = [].concat(columnDefinitionsFromParentProps || [], getOptionsColumnDefinition_fromParentProps_andSelfModule(parentProps, selfModule) || [], getChildrenColumnDefinition() || []);

    return columnDefinitions;
};

var BASE_PERM = {
    subsite: true,
    ruby_client: true,
    model: 'content'
};

function getPerm_withProps(props) {
    return _extends({}, props, BASE_PERM);
}

var COLUMN_KEYS_FOR_LAST = ["template_keyword", "last_modified_timestamp"];
function getColumnDefintions_fromParentProps(parentProps, selfDependencies) {
    var columnDefinitionsFromParent = parentProps.columns || [];
    var formsComponent = selfDependencies.formsComponent;

    var templatesForActiveRoute = formsComponent.templatesForActiveRouteComponent();
    var disableUnpublish_byTemplateKey = templatesForActiveRoute.reduce(function (result, curr) {
        var key = curr.key;

        var editableStatusField = findStatusField_inForm(curr.form);
        return _extends({}, result, _defineProperty({}, key, !editableStatusField));
    }, {});
    var orderedColumns = columnDefinitionsFromParent.reduce(function (collector, column) {
        var type = column.type,
            componentOverride = column.componentOverride,
            componentParams = column.componentParams;

        var customComponentType = componentOverride || type;

        var customComponentGenerator = COMPONENTS_BY_TYPE[customComponentType];
        var customComponent = _lodash2.default.isFunction(customComponentGenerator) && customComponentGenerator(_extends({}, componentParams, column, { disableUnpublish_byTemplateKey: disableUnpublish_byTemplateKey }), parentProps);

        var preppedColumn = _extends({
            id: column.key
        }, customComponent ? { customComponent: customComponent } : {}, column);

        if (COLUMN_KEYS_FOR_LAST.indexOf(column.key) >= 0) {
            collector.last.push(preppedColumn);
        } else {
            collector.first.push(preppedColumn);
        }

        return collector;
    }, {
        first: [],
        last: []
    });
    return orderedColumns.first.concat(orderedColumns.last);
}

function getOptionsColumnDefinition_fromParentProps_andSelfModule(parentProps, selfModule) {
    return _extends({
        id: 'id',
        sortable: false,
        displayName: '',
        width: 175,
        resizable: false,
        customComponent: customComponents.RubyActionsGenerator({
            actionsList: [getRowViewButton_usingParentProps_andSelfModule(parentProps, selfModule), getRowEditButton_usingParentProps_andSelfModule(parentProps, selfModule), getRowContextMenu_usingParentProps_andSelfModule(parentProps, selfModule)]
        })
    }, _lodash2.default.get(selfModule, 'props.listerConfig.optionsColumnProps'));
}

function getRowViewButton_usingParentProps_andSelfModule(parentProps, selfModule) {
    return {
        render: function viewButton() {
            var _React$createElement, _React$createElement2, _React$createElement3;

            var url = _lodash2.default.get(this, 'props.originalData.url');

            return url && React.createElement(
                'a',
                (_React$createElement3 = { target: '_blank', href: url, key: 'view', 'data-codecept-selector-node': 'a',
                    'data-codecept-selector-file': 'columnDefinitionsGenerator'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement3),
                React.createElement(_FlatButton2.default, (_React$createElement2 = {
                    label: 'View',
                    icon: React.createElement(_launch2.default, (_React$createElement = {
                        'data-codecept-selector-node': 'LaunchIcon',
                        'data-codecept-selector-file': 'columnDefinitionsGenerator'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'LaunchIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'LaunchIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement)),
                    'data-codecept-selector-node': 'FlatButton',
                    'data-codecept-selector-file': 'columnDefinitionsGenerator'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement2))
            );
        }
    };
}

function getRowEditButton_usingParentProps_andSelfModule(parentProps, selfModule) {
    return {
        render: function editButton() {
            var _React$createElement4, _React$createElement5;

            var id = _lodash2.default.get(this, 'props.originalData.id');
            var template_keyword = _lodash2.default.get(this, 'props.originalData.template_keyword');

            var _selfModule$getDepend = selfModule.getDependencies(),
                getEditEntryUrl_forId_withTemplateKeyword = _selfModule$getDepend.getEditEntryUrl_forId_withTemplateKeyword;

            var getPerm = getPerm_withProps({ action: 'get', template: template_keyword, id: id });
            var canGet = selfModule.doesUserHavePermission(getPerm);

            var editLink = id != null && template_keyword != null && getEditEntryUrl_forId_withTemplateKeyword(id, template_keyword);

            return React.createElement(_FlatButton2.default, (_React$createElement5 = {
                key: 'edit',
                label: 'Edit',
                icon: React.createElement(_modeEdit2.default, (_React$createElement4 = {
                    'data-codecept-selector-node': 'PenIcon',
                    'data-codecept-selector-file': 'columnDefinitionsGenerator'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'PenIcon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'PenIcon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement4)),
                onTouchTap: function onTouchTap(e) {
                    if (editLink) {
                        parentProps.actions.navigateToPathWithOptions({ path: editLink });
                    }
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                },
                href: editLink,
                disabled: !editLink || !canGet,
                'data-codecept-selector-node': 'FlatButton',
                'data-codecept-selector-file': 'columnDefinitionsGenerator'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement5));
        }
    };
}

function getRowContextMenu_usingParentProps_andSelfModule(parentProps, selfModule) {

    return {
        render: function contextMenu() {
            var _React$createElement6, _React$createElement7, _React$createElement8;

            var griddleInstance = this;
            var id = _lodash2.default.get(this, 'props.data');
            var name = _lodash2.default.get(this, 'props.originalData.name');

            var menuItems = [getAddChildMenuItem_forParentId_usingParentProps_andSelfModule.call(griddleInstance, id, parentProps, selfModule), getAddSDMenuItem_forParentId_usingParentProps_andSelfModule.call(griddleInstance, id, parentProps, selfModule), getAddSitemapPageMenuItem_forParentId_usingParentProps_andSelfModule.call(griddleInstance, id, parentProps, selfModule), getDuplicateMenuItem_forId_withName_usingParentProps.call(griddleInstance, id, name, parentProps, selfModule), getAlterTemplateMenuItem_forId_withName_usingParentProps.call(griddleInstance, id, name, parentProps, selfModule), getDeleteMenuItem_forId_usingParentProps.call(griddleInstance, id, parentProps, selfModule)];

            return menuItems.length ? React.createElement(
                _IconMenu2.default,
                (_React$createElement8 = {
                    key: 'ruby-actions_context-menu',
                    iconButtonElement: React.createElement(
                        _IconButton2.default,
                        (_React$createElement7 = {
                            'data-codecept-selector-node': 'IconButton',
                            'data-codecept-selector-file': 'columnDefinitionsGenerator'
                        }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement7),
                        React.createElement(_moreVert2.default, (_React$createElement6 = {
                            'data-codecept-selector-node': 'MoreVertIcon',
                            'data-codecept-selector-file': 'columnDefinitionsGenerator'
                        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement6))
                    ),
                    anchorOrigin: { horizontal: 'right', vertical: 'top' },
                    targetOrigin: { horizontal: 'right', vertical: 'top' },
                    'data-codecept-selector-node': 'IconMenu',
                    'data-codecept-selector-file': 'columnDefinitionsGenerator'
                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement8),
                menuItems
            ) : undefined;
        }
    };
}

function getAddChildMenuItem_forParentId_usingParentProps_andSelfModule(parentId, parentProps, selfModule) {
    var _React$createElement9;

    var canEditSelfSitemap = selfModule.canEditSelfSitemap();

    var newPageDialog = parentProps.delegateActions.newPageDialog;

    var _selfModule$getDepend2 = selfModule.getDependencies(),
        getEditEntryUrl_forId_withTemplateKeyword = _selfModule$getDepend2.getEditEntryUrl_forId_withTemplateKeyword;

    var rowData = this.props.rowData;
    var disable_adding_children = rowData.__metadata.disable_adding_children;


    var addableTemplateForms = selfModule.addableTemplateForms();
    var canAdd = addableTemplateForms.length > 0;

    return React.createElement(_MenuItem2.default, (_React$createElement9 = {
        key: 'add-child',
        primaryText: 'Add Child Page',
        onTouchTap: function onTouchTap(e) {
            parentProps.actions.addPageDialog_withParentId(parentId);
        },
        disabled: !canEditSelfSitemap || disable_adding_children || !canAdd,
        'data-codecept-selector-node': 'MenuItem',
        'data-codecept-selector-file': 'columnDefinitionsGenerator'
    }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement9));
}

function getAddSDMenuItem_forParentId_usingParentProps_andSelfModule(parentId, parentProps, selfModule) {
    var _React$createElement10;

    var canEditSelfSitemap = selfModule.canEditSelfSitemap();
    var firstAccessibleSDKey = selfModule.firstStructuredDataKeyThatIsAccessible();

    var _selfModule$getDepend3 = selfModule.getDependencies(),
        getContentListUrl_forTemplate = _selfModule$getDepend3.getContentListUrl_forTemplate;

    var sdListerLink = firstAccessibleSDKey ? getContentListUrl_forTemplate(firstAccessibleSDKey) : undefined;

    var rowData = this.props.rowData;
    var disable_adding_children = rowData.__metadata.disable_adding_children;


    return sdListerLink && parentProps.enableAddStructuredDataPage && React.createElement(_MenuItem2.default, (_React$createElement10 = {
        key: 'add-sd',
        primaryText: 'Add Structured Data',
        onTouchTap: function onTouchTap(e) {
            parentProps.actions.setActiveParentPageId(parentId);
            parentProps.actions.navigateToPathWithOptions({ path: sdListerLink });
            e.preventDefault();
            e.stopPropagation();
            return false;
        },
        disabled: !canEditSelfSitemap || !firstAccessibleSDKey || disable_adding_children,
        'data-codecept-selector-node': 'MenuItem',
        'data-codecept-selector-file': 'columnDefinitionsGenerator'
    }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement10));
}

function getAddSitemapPageMenuItem_forParentId_usingParentProps_andSelfModule(parentId, parentProps, selfModule) {
    var _React$createElement11;

    var canEditSelfSitemap = selfModule.canEditSelfSitemap();
    var canAccessContentSitemap = selfModule.canAccessContentSitemap();

    var _selfModule$getDepend4 = selfModule.getDependencies(),
        getSitemapUrl = _selfModule$getDepend4.getSitemapUrl;

    var rowData = this.props.rowData;
    var disable_adding_children = rowData.__metadata.disable_adding_children;


    return parentProps.enableAddSitemapPage && React.createElement(_MenuItem2.default, (_React$createElement11 = {
        key: 'add-sitemap-page',
        primaryText: 'Add Sitemap Page',
        onTouchTap: function onTouchTap(e) {
            parentProps.actions.setActiveParentPageId(parentId);
            parentProps.actions.navigateToPathWithOptions({ path: getSitemapUrl() });
            e.preventDefault();
            e.stopPropagation();
            return false;
        },
        disabled: !canEditSelfSitemap || !canAccessContentSitemap || disable_adding_children,
        'data-codecept-selector-node': 'MenuItem',
        'data-codecept-selector-file': 'columnDefinitionsGenerator'
    }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement11));
}

function getDeleteMenuItem_forId_usingParentProps(idToDelete, parentProps, selfModule) {
    var _React$createElement12;

    var canEditSelfSitemap = selfModule.canEditSelfSitemap();

    var confirmDialog = parentProps.delegateActions.confirmDialog;


    var griddleInstance = this;
    var refreshCurrentPage = _lodash2.default.get(griddleInstance, 'props.events.refreshCurrentPage');

    var rowData = this.props.rowData;
    var unique_template = rowData.__metadata.unique_template;


    return React.createElement(_MenuItem2.default, (_React$createElement12 = {
        key: 'delete',
        primaryText: 'Delete',
        onTouchTap: function onTouchTap(e) {
            confirmDialog.openDialogWithOptions({
                title: 'Delete',
                submitLabel: 'Delete',
                content: 'Are you sure you want to delete this item?',
                submitSuccessCallback: function submitSuccessCallback(response) {
                    parentProps.actions.removePage_withId(idToDelete, function () {
                        _lodash2.default.isFunction(refreshCurrentPage) && refreshCurrentPage();
                    });
                }
            });
        },
        disabled: !canEditSelfSitemap || unique_template,
        'data-codecept-selector-node': 'MenuItem',
        'data-codecept-selector-file': 'columnDefinitionsGenerator'
    }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement12));
}

function getDuplicateMenuItem_forId_withName_usingParentProps(idToDuplicate, name, parentProps, selfModule) {
    var _React$createElement13;

    var canEditSelfSitemap = selfModule.canEditSelfSitemap();

    var griddleInstance = this;
    var refreshCurrentPage = _lodash2.default.get(griddleInstance, 'props.events.refreshCurrentPage');

    var rowData = griddleInstance.props.rowData;
    var unique_template = rowData.__metadata.unique_template,
        template_keyword = rowData.template_keyword;

    var addPerm = getPerm_withProps({ action: 'add', template: template_keyword });
    var canAdd = selfModule.doesUserHavePermission(addPerm);

    return React.createElement(_MenuItem2.default, (_React$createElement13 = {
        key: 'duplicate',
        primaryText: 'Duplicate',
        onTouchTap: function onTouchTap(e) {
            parentProps.actions.copyPage_withId_andName_andOnSuccess(idToDuplicate, name, function () {
                _lodash2.default.isFunction(refreshCurrentPage) && refreshCurrentPage();
            });
        },
        disabled: !canEditSelfSitemap || unique_template || !canAdd,
        'data-codecept-selector-node': 'MenuItem',
        'data-codecept-selector-file': 'columnDefinitionsGenerator'
    }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement13));
}

function getAlterTemplateMenuItem_forId_withName_usingParentProps(idToDuplicate, name, parentProps, selfModule) {
    var _React$createElement14;

    var canEditSelfSitemap = selfModule.canEditSelfSitemap();

    var griddleInstance = this;
    var refreshCurrentPage = _lodash2.default.get(griddleInstance, 'props.events.refreshCurrentPage');

    var rowData = griddleInstance.props.rowData;
    var unique_template = rowData.__metadata.unique_template,
        template_keyword = rowData.template_keyword,
        id = rowData.id;


    var editPerm = getPerm_withProps({ action: 'edit', template: template_keyword, id: id });
    var canEdit = selfModule.doesUserHavePermission(editPerm);

    return React.createElement(_MenuItem2.default, (_React$createElement14 = {
        key: 'alter-template',
        primaryText: 'Alter Template',
        disabled: !canEditSelfSitemap || unique_template || !canEdit,
        onTouchTap: function onTouchTap(e) {
            var alterTemplateRoute = new Route(path.join(parentProps.apiUrlPrefix, _lodash2.default.result(selfModule, 'getDependencies.alterTemplateDialogComponent.CONSTANTS.ALTER_TEMPLATE_ROUTE')));
            var alterTemplateEndpoint = alterTemplateRoute.reverse({ id: id });

            parentProps.delegateActions.alterTemplateDialog.openAlterTemplateDialog({
                fields: { templateKey: { value: template_keyword } },
                supportedTemplateTypes: selfModule.props.templateTypes,
                endpoint: alterTemplateEndpoint,
                submitSuccessCallback: function submitSuccessCallback(response) {
                    var entryName = rubyWords.quote(_lodash2.default.get(response, 'body.name'));
                    var griddleProps = griddleInstance.props;

                    griddleProps.events.refreshCurrentPage();
                    selfModule.pushNotification({
                        message: 'Altered the template of ' + entryName + '.',
                        type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS
                    });
                },
                submitFailCallback: function submitFailCallback(error) {
                    selfModule.showErrorNotification({ error: error });
                }
            });
        },
        'data-codecept-selector-node': 'MenuItem',
        'data-codecept-selector-file': 'columnDefinitionsGenerator'
    }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'columnDefinitionsGenerator'), _React$createElement14));
}

function getChildrenColumnDefinition() {
    return {
        id: 'children',
        displayName: ''
    };
}