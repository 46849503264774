'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Card = require('material-ui/Card');

var _Paper = require('material-ui/Paper');

var _Paper2 = _interopRequireDefault(_Paper);

var _colors = require('material-ui/styles/colors');

var _Activity_style = require('../Activity_style');

var _Activity_style2 = _interopRequireDefault(_Activity_style);

var _constants = require('../../../common/constants');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = process.browser ? require('../Activity.cssModule').default : {};

var ACTIVITY_TYPES = _constants2.default.ACTIVITY_TYPES,
    ACTIVITY_NAMES = _constants2.default.ACTIVITY_NAMES;

var rubyWords = require('@rubyapps/ruby-words');

var ActivityFooter = _react2.default.createClass({
    displayName: 'ActivityFooter',

    customMixins: [],
    color: function color() {
        var _this = this;

        var color = this.customMixins.reduce(function (collector, mixin) {
            if (collector) {
                return collector;
            }

            return mixin.color && mixin.color.apply(_this);
        }, null);

        if (!color) {
            var _colorMap;

            var _props$activity_type = this.props.activity_type,
                activityTypeName = _props$activity_type === undefined ? 'Comment' : _props$activity_type;


            var colorMap = (_colorMap = {}, _defineProperty(_colorMap, ACTIVITY_TYPES.REVISION_ACTIVITY, {
                bg: '#607D8B',
                fg: '#FFFFFF'
            }), _defineProperty(_colorMap, ACTIVITY_TYPES.ALTER_TEMPLATE_ACTIVITY, {
                bg: '#292929',
                fg: '#FFFFFF'
            }), _defineProperty(_colorMap, ACTIVITY_TYPES.DELETION_ACTIVITY, {
                bg: '#292929',
                fg: '#FFFFFF'
            }), _defineProperty(_colorMap, ACTIVITY_TYPES.RERANK_ACTIVITY, {
                bg: '#CFD8DC',
                fg: '#000000'
            }), _defineProperty(_colorMap, ACTIVITY_TYPES.APPROVAL_REQUEST_ACTIVITY, {
                bg: '#A5DAD2',
                fg: '#000000'
            }), _defineProperty(_colorMap, ACTIVITY_TYPES.APPROVAL_APPROVE_ACTIVITY, {
                bg: '#B1CC9F',
                fg: '#000000'
            }), _defineProperty(_colorMap, ACTIVITY_TYPES.APPROVAL_REJECT_ACTIVITY, {
                bg: '#F9B5B2',
                fg: '#000000'
            }), _defineProperty(_colorMap, ACTIVITY_TYPES.APPROVAL_DISCARD_ACTIVITY, {
                bg: '#F36E4B',
                fg: '#000000'
            }), _defineProperty(_colorMap, ACTIVITY_TYPES.COMMENT_ACTIVITY, {
                bg: '#BDE5F8',
                fg: '#000000'
            }), _defineProperty(_colorMap, ACTIVITY_TYPES.APPROVAL_COMMENT_ACTIVITY, {
                bg: '#BDE5F8',
                fg: '#000000'
            }), _defineProperty(_colorMap, ACTIVITY_TYPES.WORDDOC_GENERATION_ACTIVITY, {
                bg: '#2B579A',
                fg: '#FFFFFF'
            }), _defineProperty(_colorMap, ACTIVITY_TYPES.CONTENT_IMPORT_ACTIVITY, {
                bg: _colors.deepPurple200,
                fg: '#000000'
            }), _defineProperty(_colorMap, ACTIVITY_TYPES.SYSTEM_MAINTENANCE_ACTIVITY, {
                bg: '#5ab3bf',
                fg: '#FFFFFF'
            }), _colorMap);

            color = colorMap[activityTypeName] || colorMap[ACTIVITY_TYPES.COMMENT_ACTIVITY];
        }

        return color;
    },
    displayText: function displayText() {
        var _this2 = this;

        var displayText = this.customMixins.reduce(function (collector, mixin) {
            if (collector) {
                return collector;
            }

            return mixin.displayText && mixin.displayText.apply(_this2);
        }, null);

        if (!displayText) {
            var _props$activity_type2 = this.props.activity_type,
                activityTypeName = _props$activity_type2 === undefined ? 'Comment' : _props$activity_type2;

            var nameMap = _defineProperty({}, ACTIVITY_TYPES.WORDDOC_GENERATION_ACTIVITY, 'Document Export');

            displayText = activityTypeName ? (nameMap[activityTypeName] || rubyWords.inflection.humanize(activityTypeName)).toUpperCase() : '';
        }

        return displayText;
    },
    render: function render() {
        var _React$createElement, _React$createElement2;

        var _props$activity_type3 = this.props.activity_type,
            activityTypeName = _props$activity_type3 === undefined ? 'Comment' : _props$activity_type3;


        var color = this.color();
        var displayText = this.displayText();

        var typeStyle = _extends({}, _Activity_style2.default.activityTypePaper, {
            color: color.fg,
            backgroundColor: color.bg
        });

        return _react2.default.createElement(
            _Card.CardText,
            (_React$createElement2 = { style: _Activity_style2.default.activityFooterCardText, 'data-codecept-selector-node': 'CardText',
                'data-codecept-selector-file': 'footer'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'footer'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'footer'), _React$createElement2),
            _react2.default.createElement(
                _Paper2.default,
                (_React$createElement = {
                    style: typeStyle,
                    rounded: false,
                    zDepth: 1,
                    'data-codecept-selector-node': 'Paper',
                    'data-codecept-selector-file': 'footer'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Paper'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'footer'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Paper'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'footer'), _React$createElement),
                displayText
            )
        );
    }
});

exports.default = ActivityFooter;