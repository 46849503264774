'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _MissingComponentPlaceholder = require('./reactComponents/MissingComponentPlaceholder');

var _MissingComponentPlaceholder2 = _interopRequireDefault(_MissingComponentPlaceholder);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var cachedComponentInstance = undefined;
function componentGenerator(rubyComponent) {
    if (cachedComponentInstance) {
        return cachedComponentInstance;
    }

    var RCMissingComponentPlaceholder = rubyComponent.createClass({
        componentName: 'rubyComponentMissingComponentPlaceholder',

        getReactClass: function getReactClass() {
            return _MissingComponentPlaceholder2.default;
        },
        getReactElement: function getReactElement() {
            var _extends2;

            var MissingComponentPlaceholder = this.getReactClass();

            return React.createElement(MissingComponentPlaceholder, _extends({}, this.props, (_extends2 = {
                'data-codecept-selector-node': 'MissingComponentPlaceholder',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'MissingComponentPlaceholder'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'MissingComponentPlaceholder'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
        }
    });

    cachedComponentInstance = RCMissingComponentPlaceholder;
    return cachedComponentInstance;
}

exports.default = componentGenerator;