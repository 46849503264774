'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _components = require('../../../ruby-component-builder/src/common/components');

var _components2 = _interopRequireDefault(_components);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');


function fieldSpecsTree_fromFormChildren(formChildren, ctx) {
    var fieldSpecsTree = selectableNodesFromFormChildren(formChildren).reduce(function (acc, node) {
        var componentName = node.componentName,
            _node$children = node.children,
            children = _node$children === undefined ? [] : _node$children;

        if (componentName === 'NamespaceSelector') {
            return acc.concat(fieldSpecsTree_fromFormChildren(children, ctx));
        }

        var Component = _components2.default[componentName];
        var nodeWithMergedDefaultProps = _extends({}, Component && Component.getDefaultProps ? Component.getDefaultProps(node) : {}, node);

        var fieldSpecs = fieldSpecsForNode(nodeWithMergedDefaultProps, ctx);
        //# NOTE: We are relying on a match between the `key` and `componentName`
        //# properties to find the field spec from `fieldSpecs` that matches the
        //# current `node`. The assumption being made here is that it would never
        //# be the case that a given node would have field specs where multiple
        //# items have the same key.
        var matchingFieldSpecForNode = fieldSpecs.find(_lodash2.default.matches({
            key: nodeWithMergedDefaultProps.key,
            componentName: nodeWithMergedDefaultProps.componentName
        })) || {};

        var nodeWithMergedFieldSpec = _extends({}, nodeWithMergedDefaultProps, matchingFieldSpecForNode ? _extends({}, matchingFieldSpecForNode
        //# NOTE: For components like the `ProfileIdentity` widget that have `childrenPropsByKey`
        //# , we are considering each property of `childrenPropsByKey` as a child of the component
        , matchingFieldSpecForNode.hasOwnProperty('childrenPropsByKey') ? {
            children: [].concat(children, Object.keys(matchingFieldSpecForNode.childrenPropsByKey).map(function (childPropKey) {
                return fieldSpecs.find(function (spec) {
                    return spec.key === childPropKey;
                });
            }).filter(_lodash2.default.identity))
        } : {}) : {});

        return acc.concat(_extends({}, nodeWithMergedFieldSpec, !_lodash2.default.isEmpty(children) ? { children: fieldSpecsTree_fromFormChildren(children, ctx) } : {}));
    }, []);

    return fieldSpecsTree;
}

function selectableNodesFromFormChildren() {
    var formChildren = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    var selectableNodes = _lodash2.default.reduce(formChildren, function (acc, node) {
        var _node$children2 = node.children,
            children = _node$children2 === undefined ? [] : _node$children2;


        return nodePicker(node) ? acc.concat(_extends({}, node, { children: selectableNodesFromFormChildren(children) })) : acc;
    }, []);

    return selectableNodes;
}

function nodePicker(node) {
    var _node$children3 = node.children,
        children = _node$children3 === undefined ? [] : _node$children3,
        key = node.key,
        _node$componentName = node.componentName,
        componentName = _node$componentName === undefined ? "" : _node$componentName;

    var fieldPickerPropsFromNode = _lodash2.default.get(node, 'forComponent.FieldsPicker') || {};
    var _fieldPickerPropsFrom = fieldPickerPropsFromNode.exclude,
        exclude = _fieldPickerPropsFrom === undefined ? false : _fieldPickerPropsFrom;


    var shouldSelect = exclude ? false : !_lodash2.default.isEmpty(children) ? children.find(nodePicker) : componentName !== 'Hidden' && componentName !== 'Info' && key && _lodash2.default.isEmpty(children) && !exclude;

    return shouldSelect;
}

function fieldSpecsForNode(node, ctx) {
    var componentName = node.componentName;

    var component = _components2.default[componentName];
    ctx.dataPathArray = ctx.dataPathArray || [];

    if (!component) {
        return [];
    }

    var getFieldSpecFromProps = component.getFieldSpecFromProps
    //# find the first getFieldSpecFromProps based on the component mixin
    || [].concat(_toConsumableArray(component.mixins)).reverse().reduce(function (collector, mixin) {
        if (collector) {
            return collector;
        }

        return mixin.getFieldSpecFromProps;
    }, undefined) || baseFieldMixin.getFieldSpecFromProps;

    getFieldSpecFromProps = getFieldSpecFromProps.bind(component);

    return getFieldSpecFromProps(node, ctx);
}

module.exports = fieldSpecsTree_fromFormChildren;