'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _TextConnector = require('./reactComponents/TextConnector');

var _TextConnector2 = _interopRequireDefault(_TextConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var _ = require('lodash');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var RubyComponentCurrentUser = require('@rubyapps/ruby-component-current-user');

var RCText = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {
        //, label: PropTypes.string
        //, key: PropTypes.string
        //, constraints: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            data_type: 'string'
        };
    },
    componentName: componentName,
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getSelfStateSelector();

        var currentUserID = this.props.currentUserID || RubyComponentCurrentUser.CONSTANTS.COMPONENT_NAME;
        var currentUser = root.findDescendentByID(currentUserID);
        var currentUserSelector = currentUser.getSelector().default;

        return {
            selfSelector: selfSelector,
            currentUserSelector: currentUserSelector
        };
    },
    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector,
            currentUserSelector = _getDependencies.currentUserSelector;

        return {
            self: selfSelector(state),
            user: currentUserSelector(state),
            fieldProps: this.fieldProps_fromState_andOwnProps(state, this.props)
            //# TODO: add this as part of the default states selector for the mixin-field-base
            , routing: state.routing //# need to update based on routing changes
        };
    },
    getReactClass: function getReactClass() {
        return _TextConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var TextComponent = this.getReactClass();

        return React.createElement(TextComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'TextComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TextComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TextComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

    /*
    , formValueFromFieldValue_forKey: function(textValue, key) {
        return _.isNil(textValue)? null
            :baseFieldMixin.formValueFromFieldValue_forKey.apply(this, arguments)
    }
    */
    //# NOTE: not needed anymore since the baseFieldMixin should account for returning empty string or null appropriately

});

module.exports = RCText;