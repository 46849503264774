'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _colors = require('@rubyapps/ruby-styles/src/client/styleObjects/colors');

var Colors = _interopRequireWildcard(_colors);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

exports.default = {
    Avatar: {
        color: Colors.black_25,
        style: {
            backgroundColor: 'transparent'
        }
    },
    IconButton: {
        style: {
            padding: 0,
            marginRight: 4,
            width: 24,
            height: 24
        },
        iconStyle: {
            width: 20,
            height: 20
        },
        tooltipPosition: 'top-center'
    }
};