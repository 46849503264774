'use strict';

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

module.exports = {
    COMPONENT_NAME: 'rubyComponentFieldTFA',

    GET_DEFAULT_PROPS: function GET_DEFAULT_PROPS(props) {
        var _childrenPropsByKey;

        var mode = props.mode,
            locked = props.locked,
            disabled = props.disabled;


        var enabledKey = qualifyTFAComponentKey(props.keyPrefix, 'enabled');
        var registeredKey = qualifyTFAComponentKey(props.keyPrefix, 'registered');
        var secretKey = qualifyTFAComponentKey(props.keyPrefix, 'secret');
        var tokenKey = qualifyTFAComponentKey(props.keyPrefix, 'token');

        return {
            //# NOTE: this is currently not used to actually render the children components
            //# nor is it used to generate the json-schema
            //# this is specifically used by browser-side content-property-helper 
            //# in order to 
            childrenPropsByKey: (_childrenPropsByKey = {}, _defineProperty(_childrenPropsByKey, enabledKey, {
                label: "Enable MFA",
                key: enabledKey,
                componentName: 'Toggle' //# NOTE: for now, this doesn't do anything
            }), _defineProperty(_childrenPropsByKey, registeredKey, {
                label: "Registered MFA",
                key: registeredKey,
                componentName: 'Hidden'
            }), _defineProperty(_childrenPropsByKey, secretKey, {
                label: "MFA Secret",
                key: secretKey,
                componentName: 'Hidden'
            }), _defineProperty(_childrenPropsByKey, tokenKey, {
                label: "MFA Token",
                key: tokenKey,
                componentName: 'Text'
            }), _childrenPropsByKey)
        };
    }

};

function qualifyTFAComponentKey(qualifier, key) {
    return qualifier + '_' + key;
}