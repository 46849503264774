'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = DateFormatterGenerator;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');
var moment = require('@rubyapps/friendly-ruby-moment');
var React = require('react');

var default_timeZone = moment.tz.guess();

function DateFormatterGenerator(options) {

    return function DateFormatter(props) {
        var _React$createElement;

        var friendlyDate = _.get(options, 'friendlyDate');
        var formattedDateString = void 0;

        var date = '';
        var timeZone = '';
        if (typeof props.data === 'string') {
            date = props.data;
            //# guess timeZone
            timeZone = default_timeZone;
        } else if (typeof _.get(props, ['data', 'datetime']) === 'string') {
            date = _.get(props, ['data', 'datetime']);
            timeZone = _.get(props, ['data', 'timeZone']);
        }
        var defaultDateFormat = timeZone ? "MMM D, YYYY hh:mm a zz" : "MMM D, YYYY";
        var dateFormat = _.get(options, 'format') || defaultDateFormat;

        if (date) {
            var momentInstance = moment.tz(date, timeZone || 'UTC');

            if (friendlyDate) {
                switch (friendlyDate) {
                    case 'long':
                        formattedDateString = momentInstance.longButFriendly();
                        break;
                    case 'short':
                    default:
                        formattedDateString = momentInstance.shortButFriendly();
                }
            } else {
                formattedDateString = momentInstance.format(dateFormat);
            }
        } else {
            formattedDateString = '';
        }

        return React.createElement(
            'span',
            (_React$createElement = {
                'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'DatetimeFormatterGenerator'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'DatetimeFormatterGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'DatetimeFormatterGenerator'), _React$createElement),
            formattedDateString
        );
    };
}