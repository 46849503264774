'use strict';

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

module.exports = function (selfModule) {
    var filteredTemplates = selfModule.possibleTemplateForms();

    var options = _lodash2.default.sortBy(filteredTemplates.map(function (template) {
        return {
            text: template.name,
            value: template.key
        };
    }), ['text']);

    var templateOptions = {
        options: options,
        key: 'template_keyword',
        header: 'All Templates',
        headerValue: null
    };

    return [templateOptions];
};