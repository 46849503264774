"use strict";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var defaultState = {
    pristineData: null,
    url: null
};

module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];

    var _getAction = this.getAction(),
        TYPES = _getAction.TYPES;

    var type = action.type,
        payload = action.payload;

    switch (type) {
        case TYPES.SET_PRISTINE_DATA:
            return _extends({}, state, {
                pristineData: payload.data
            });
        case TYPES.SET_URL:
            return _extends({}, state, {
                url: payload.data
            });
        case TYPES.RESET_STORE:
            this.clearStatefulCache();
            //# NOTE: not sure if we should call it here
            //# or in the rubyComponent.resetStore() method
            //# NOTE that we should clear the cache
            //# AFTER the redux state gets cleared or close to it
            //# meaning we can't clear it during the action generator call

            return _extends({}, state, defaultState);
        default:
            return state;
    }
};