'use strict';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _index = require('../local_modules/ruby-model-mixin-saml/src/client/index.js');

var _index2 = _interopRequireDefault(_index);

var _constants = require('../common/constants');

var CONSTANTS = _interopRequireWildcard(_constants);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RubyComponent = require('@rubyapps/ruby-component');

var RCPluginSso = RubyComponent.createClass({
    componentName: CONSTANTS.COMPONENT_NAME,
    CONSTANTS: CONSTANTS,
    getReactClass: function getReactClass() {
        return _react2.default.createClass({
            render: function render() {
                return this.props.children;
            }
        });
    },
    children: function children() {
        return [RubyComponent.createElement(_index2.default)];
    }
});

module.exports = RCPluginSso;