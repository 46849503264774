'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _TFA = require('./TFA');

var _TFA2 = _interopRequireDefault(_TFA);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector,
        currentUserSelector = _selfModule$getDepend.currentUserSelector,
        frontendSettingsSelector = _selfModule$getDepend.frontendSettingsSelector,
        usernameSelector = _selfModule$getDepend.usernameSelector;

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);
        var frontendSettings = frontendSettingsSelector(state);
        var usernameState = usernameSelector(state);

        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);

        var mappedProps = _extends({
            frontendSettings: frontendSettings,
            tfaLabel: _lodash2.default.get(usernameState, ['fields', 'username', 'value'], null)
        }, ownProps, mixinFieldProps, ['enabled', 'registered', 'secret', 'token'].reduce(function (fields, key) {
            fields[key] = _lodash2.default.pick(selfState.fields[key], ['value', 'error']);
            return fields;
        }, {}));

        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_TFA2.default);
}