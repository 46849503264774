'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _RichTextEditorConnector = require('./reactComponents/RichTextEditorConnector');

var _RichTextEditorConnector2 = _interopRequireDefault(_RichTextEditorConnector);

var _coreConfig = require('./coreConfig');

var _coreConfig2 = _interopRequireDefault(_coreConfig);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var tokenizer = require('sbd');

var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var COMMON_UTILS = require('../common/index');
var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME,
    META_SUFFIX = CONSTANTS.META_SUFFIX;


var propsMixin = require('./mixinProps');
var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var forcedLeafNodeMixin = require('@rubyapps/ruby-component-mixin-field-forced-leaf-node');

require('./corePlugins');

var defaultConfig = _extends({ menubar: false, elementpath: false }, _coreConfig2.default
//ruby_tinymce_default_options, //# NOTE: DEPRECATED 20190514 - in favor of coreConfig
);

var global_client_tinymce_options = window.client_tinymce_default_options || {};
var backwardsCompatGlobalConfig = _extends({}, defaultConfig, global_client_tinymce_options);
//# override client_tinymce_default_options.content_css cause we need to include our urls
backwardsCompatGlobalConfig.content_css = [].concat(_toConsumableArray(defaultConfig.content_css), _toConsumableArray(global_client_tinymce_options.content_css ? _lodash2.default.isArray(global_client_tinymce_options.content_css) ? global_client_tinymce_options.content_css : global_client_tinymce_options.content_css.split(',').map(_lodash2.default.trim) : []));

window.backwardsCompatGlobalConfig = backwardsCompatGlobalConfig;

var modesByKey = window.ruby_tinymce_modes || _coreConfig.ruby_tinymce_modes;

//# glob all plugins under ../local_modules
//const bulk = require('bulk-require');
//const RTEPlugins = bulk(__dirname+'../local_modules/', [ 'ruby-component-rte-plugin-*/src/client/index.js']);
//# NOTE: loading these happen in the coreConfig now

var RCRichTextEditor = RubyComponent.createClass({
    mixins: [propsMixin, baseFieldMixin, fieldValidationMixin, fieldPropsMixin, forcedLeafNodeMixin],
    propTypes: {
        config: PropTypes.object
        //# NOTE: config[pluginName]: {} will contain props
        //# for the pluginComponent
    },
    getDefaultProps: function getDefaultProps() {
        return {
            data_type: 'string'
        };
    }
    //# TODO: figure out best method to pass in config prop for the RTE
    , componentName: componentName,
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();

        return {
            selfSelector: selfSelector
        };
    }

    //# NOTE: might not need these
    //, action: require('./action') //# TODO
    //, reducer: require('./reducer') //# TODO


    , children: function children() {
        var _this = this;

        var hydratedConfig = this.hydratedConfig();
        var _hydratedConfig$plugi = hydratedConfig.plugins,
            plugins = _hydratedConfig$plugi === undefined ? "" : _hydratedConfig$plugi;

        var pluginsArray = plugins.split(/[ ,]/).filter(_lodash2.default.identity);

        var tinymcePluginsByKey = tinymce.PluginManager.lookup;

        //# iterate pluginsArray, and look up tinymce.PluginManager.lookup[]
        //# if it has RubyComponent, then we should initialize it as a child
        var pluginChildren = pluginsArray.reduce(function (collector, inputPluginName) {
            var pluginName = inputPluginName;

            var isOutdatedPluginName = false;

            if (pluginName.charAt(0) == '-') {
                isOutdatedPluginName = true;
                pluginName = pluginName.substring(1);
            }

            var tinymcePluginSpec = tinymcePluginsByKey[pluginName];

            var _ref = tinymcePluginSpec || {},
                tinymcePlugin__RubyComponent = _ref.RubyComponent;

            if (tinymcePlugin__RubyComponent) {
                isOutdatedPluginName && console.warn('Plugin entry: [' + inputPluginName + '] is using the old form with the \'-\' prefix. Please update to use the RubyComponentName (without the dash)');

                collector.push(RubyComponent.createElement(tinymcePlugin__RubyComponent, _extends({
                    id: _this.getID() + '.' + pluginName,
                    config: hydratedConfig
                }, hydratedConfig[pluginName] || {})));
            }

            return collector;
        }, []);

        return pluginChildren;
    },

    getReactClass: function getReactClass() {
        return _RichTextEditorConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var RichTextEditorComponent = this.getReactClass();

        return React.createElement(RichTextEditorComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'RichTextEditorComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'RichTextEditorComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'RichTextEditorComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    }
    //# == Utils ==================================================//
    , hydratedConfig: function hydratedConfig() {
        var cachedHydratedConfig = this.getStatefulCacheForKey('_cachedHydratedConfig');
        if (cachedHydratedConfig) {
            return cachedHydratedConfig;
        }

        var _props = this.props,
            config = _props.config,
            mode = _props.mode;


        var hydratedConfig = void 0;
        if (config == undefined) {
            hydratedConfig = _extends({}, backwardsCompatGlobalConfig);
        } else {
            hydratedConfig = _extends({}, defaultConfig, config);
            //# NOTE: the default config is always merged in
            //# You will need to override if you want to null it out
        }

        if (mode) {
            //# TODO:
            //# NOTE: if we're defining a local config, most likely we shouldn't expose a
            //# mode
            hydratedConfig = _extends({}, hydratedConfig, modesByKey[mode] || {});
        }

        //# if plugins is an array, conver it to a string
        var plugins = hydratedConfig.plugins;
        if (_lodash2.default.isArray(plugins)) {
            hydratedConfig.plugins = plugins.join(' ');
        }

        this.setStatefulCacheForKey('_cachedHydratedConfig', hydratedConfig);

        return hydratedConfig;
    },

    hydratedConfigWithChildrenReferences: function hydratedConfigWithChildrenReferences() {
        var hydratedConfig = this.hydratedConfig();
        var children = this.getChildren();
        var hydratedConfigWithChildren = _extends({
            rubyApp: {
                childrenByKey: _lodash2.default.keyBy(children, 'componentName')
            }
        }, hydratedConfig);

        return hydratedConfigWithChildren;
    },

    getTextSummary: function getTextSummary() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        return COMMON_UTILS.getTextSummary(this.getFieldValue(), options);
    }

    //# Mixin overrides
    , _formValueToLocalState: function _formValueToLocalState(formValue, dispatchOrCollect, isError, entireFormValue, options) {
        var selfKey = this.props.key;
        var selfMetaKey = '' + this.props.key + META_SUFFIX;

        if (isError) {
            var ownError = formValue[selfKey];
            var ownErrorObject = _lodash2.default.isString(ownError) ? { message: ownError } : ownError;

            var metaValue = formValue[selfMetaKey];
            var metaErrorMessage = _lodash2.default.compact(_lodash2.default.map(metaValue)).join(' ') || null;

            var modifiedValue = _extends({}, ownErrorObject, {
                metaErrorMessage: metaErrorMessage
            });

            var modifiedFormValue = _lodash2.default.cloneDeep(formValue);
            var modifiedEntireFormValue = _lodash2.default.cloneDeep(entireFormValue);

            _lodash2.default.set(modifiedFormValue, [selfKey], modifiedValue);
            _lodash2.default.set(modifiedEntireFormValue, this.getFieldKeypathArr(), modifiedValue);

            return baseFieldMixin._formValueToLocalState.call(this, modifiedFormValue, dispatchOrCollect, isError, modifiedEntireFormValue, _extends({ ignoreChildren: true }, options));
        }

        return baseFieldMixin._formValueToLocalState.call(this, formValue, dispatchOrCollect, isError, entireFormValue, _extends({ ignoreChildren: true }, options));
    },

    formValueFromFieldValue_forKey: function formValueFromFieldValue_forKey__RTEoverride(value) {
        if (!value) {
            return baseFieldMixin.formValueFromFieldValue_forKey.apply(this, arguments);
        }

        //# NOTE: need to sanitize the data so it matches what we do on the backend
        //console.time(`${this.props.key}:jquery`);
        //# NOTE we were referencing: https://stackoverflow.com/questions/1667868/prevent-images-from-loading
        //# the issue with <noscript> is that it seems to prevent all javascript from running (expected)
        //# but it seems like it prevents any DOM formatting from happening as well (eg. <img /> should get converted to <img>). This is unexpected
        //# my guess is that at least Chrome is leveraging JavaScript to handle the DOM element creation which <noscript> prevents
        //# So we need to instead rename the src attr and then rename it back
        //
        //# const $targetBody = $(`<noscript>${value}</noscript>`);
        var valuWithRenamedSrcs = value.replaceAll(' src=', ' _src=').replaceAll(' poster=', ' _poster=').replaceAll(' url(', ' _url(');
        var $targetBody = $('<div>' + valuWithRenamedSrcs + '</div>');

        var value_sanitized = $targetBody.html().replaceAll(' _src=', ' src=').replaceAll(' _poster=', ' poster=').replaceAll(' _url(', ' url(');
        //console.timeEnd(`${this.props.key}:jquery`);

        return value_sanitized;
    }
});

module.exports = RCRichTextEditor;