'use strict';

module.exports = {
    PLUGIN_NAME: 'rubyComponentActivities',
    COMPONENT_NAME: 'rubyComponentActivities',
    ACTIVITY_TYPES: {
        REVISION_ACTIVITY: 'Revision',
        ALTER_TEMPLATE_ACTIVITY: 'Alter Template',
        DELETION_ACTIVITY: 'Deletion',
        RERANK_ACTIVITY: 'Rerank',
        COMMENT_ACTIVITY: 'Comment',
        SYSTEM_MAINTENANCE_ACTIVITY: 'Maintenance',
        WORDDOC_GENERATION_ACTIVITY: 'Word Doc Generation',
        APPROVAL_COMMENT_ACTIVITY: 'Workflow Comment',
        APPROVAL_REQUEST_ACTIVITY: 'Workflow Request',
        APPROVAL_APPROVE_ACTIVITY: 'Workflow Approval',
        APPROVAL_REJECT_ACTIVITY: 'Workflow Rejection',
        APPROVAL_DISCARD_ACTIVITY: 'Workflow Discarded',
        UPLOAD_MEDIA_ACTIVITY: 'Upload Media',
        REPLACE_MEDIA_ACTIVITY: 'Replace Media',
        DERIVE_RENDER_ACTIVITY: 'Derive Render',
        DELETE_DERIVED_RENDER_ACTIVITY: 'Delete Derived Render',
        CONTENT_IMPORT_ACTIVITY: 'Content Import'
        //# TODO: consider refactoring CONTENT_IMPORT_ACTIVITY to be managed by the ruby-component-plugin-content-import
        //# Reference: ruby-component-plugin-content-drafts/src/local_modules/ruby-component-mixin-activities
    },
    STATUS_TYPES: {
        PROCESSING: 'PROCESSING',
        ERROR: 'ERROR',
        COMPLETE: 'COMPLETE'
    },
    PROPOSAL_TEMPLATE_KEY: 'proposal'
    // LATEST_REVISION_ACTIVITY is a placeholder
    // id that FE can pass to BE and BE will swap out
    // with the intended activity id
    , LATEST_REVISION_ACTIVITY: 'latestRevisionActivity'
};