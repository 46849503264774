'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _MenuItemConnector = require('./reactComponents/MenuItemConnector');

var _MenuItemConnector2 = _interopRequireDefault(_MenuItemConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var FieldForm = require('@rubyapps/ruby-component-field-form');

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var RubyIcons = require('@rubyapps/ruby-icons');

/*
 *  props: {
 *      action: PropTypes.string - the name of the action on the closest ruby-component-field-form that the button should trigger
 *      action: PropTypes.shape({
 *          path: PropTypes.string - selector path for selection to node that should call on the action
 *          , action: PropTypes.string - the name of the action to trigger
 *      })
 *  }
 * */
var RCMenuItem = RubyComponent.createClass({
    mixins: [baseFieldMixin],
    propTypes: {
        //# action: baseFieldMixin.propTypes.action
        icon: PropTypes.string
    },
    componentName: componentName,
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();

        var props = this.getProps(true);
        var props__action = props.action;

        //# TODO: copy what we're doing in the field-button to set loading

        var iconClass = props.icon ? RubyIcons.getIconClassForPath(props.icon) : undefined;

        var dependencies = {
            selfSelector: selfSelector,
            actionForMenuItem: props__action
        };

        if (iconClass) {
            dependencies.iconClass = iconClass;
        }

        return dependencies;
    },
    getReactClass: function getReactClass() {
        return _MenuItemConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var MenuItemComponent = this.getReactClass();

        return _react2.default.createElement(MenuItemComponent, _extends({}, this.props.reactProps, (_extends2 = {
            'data-codecept-selector-node': 'MenuItemComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'MenuItemComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'MenuItemComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    }

});

module.exports = RCMenuItem;