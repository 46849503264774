'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _RaisedButton = require('material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;


var LoginWithSaml = _react2.default.createClass({
    displayName: 'LoginWithSaml',

    propTypes: {
        href: PropTypes.string,
        shouldRender: PropTypes.bool
    },
    render: function render() {
        var _React$createElement;

        var buttonStyle = {
            width: '100%',
            marginTop: 10,
            marginBottom: 10
        };
        return this.props.shouldRender ? _react2.default.createElement(_RaisedButton2.default, (_React$createElement = {
            label: 'Log In with SSO',
            fullWidth: true,
            href: this.props.href,
            style: buttonStyle,
            'data-codecept-selector-node': 'RaisedButton',
            'data-codecept-selector-file': 'LoginWithSaml'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'LoginWithSaml'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'LoginWithSaml'), _React$createElement)) : null;
    }
});

exports.default = LoginWithSaml;