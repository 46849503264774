'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _immutable2.default.fromJS({
  loading: true,
  data: [],
  visibleData: [],
  columnTitles: [],
  allColumns: [],
  renderProperties: {},
  resizeProperties: {}, //# for columnResizing
  renderedSizeProperties: {}, //# for columnResizing
  hasNext: false,
  hasPrevious: false,
  pageProperties: {
    currentPage: 0,
    maxPage: 0,
    pageSize: 0
  }
});