'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

//# Material UI


var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _Menu = require('material-ui/Menu');

var _Menu2 = _interopRequireDefault(_Menu);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _Card = require('material-ui/Card');

var _keycode = require('keycode');

var _keycode2 = _interopRequireDefault(_keycode);

var _TokenTagger = require('./TokenTagger.cssModule');

var _TokenTagger2 = _interopRequireDefault(_TokenTagger);

var _TokenTagger_style = require('./TokenTagger_style');

var _TokenTagger_style2 = _interopRequireDefault(_TokenTagger_style);

var _TokenTaggerList = require('./TokenTaggerList');

var _TokenTaggerList2 = _interopRequireDefault(_TokenTaggerList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var Popover = require('@rubyapps/ruby-material-ui/Popover').default;

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label;

var PropTypes = _react2.default.PropTypes;

var request = require('@rubyapps/ruby-superagent');

var TokenTaggerList__propTypeKeys = Object.keys(_TokenTaggerList2.default.propTypes);

var TokenTagger = _react2.default.createClass({
    displayName: 'TokenTagger',

    propTypes: {
        count: PropTypes.number //# the count param for url
        , delegateSearchValueChange: PropTypes.func //# (event, newValue)
        , delegateValueChange: PropTypes.func //# (event, newValue)
        , draggable: PropTypes.bool,
        hintText: PropTypes.string,
        label: PropTypes.string,
        limit: PropTypes.number //# the number of items you can select
        , disabled: PropTypes.bool,
        searchValue: PropTypes.string,
        sortable: PropTypes.bool,
        url: PropTypes.string,
        value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
        requireSearchValueForDropdown: PropTypes.bool //# (event, newValue)
    },
    getDefaultProps: function getDefaultProps() {
        return {
            style: {},
            requireSearchValueForDropdown: false
        };
    },
    getInitialState: function getInitialState() {
        return {
            shouldAutoFocusPopoverMenu: false,
            searchValue: '',
            showResults: false,
            primaryTextByValue: {},
            options: this.props.options,
            moduleDefByType: this.getModuleDefByType_fromOptions(this.props.options || [])
        };
    },
    componentDidMount: function componentDidMount() {
        this._isMounted = true;
        this._populateOptions_forSearchValue_andShowResults(this.props.searchValue, false);
    },
    componentWillUnmount: function componentWillUnmount() {
        this._isMounted = false;
    },
    _listItemSource: function _listItemSource() {
        var self = this;
        return {
            beginDrag: function beginDrag(props, monitor) {
                return {
                    index: props.index,
                    rubyId: props.rubyId,
                    disabled: self.props.disabled
                };
            },
            endDrag: function endDrag(props, monitor) {}
        };
    },
    _listItemTarget: {
        hover: function hover(props, monitor, component) {

            var dragIndex = monitor.getItem().index;
            var hoverIndex = props.index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            var hoverBoundingRect = _reactDom2.default.findDOMNode(component).getBoundingClientRect();

            // Get vertical middle
            var hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            var clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            var hoverClientY = clientOffset.y - hoverBoundingRect.top;

            props.delegateOnMove(dragIndex, hoverIndex);

            monitor.getItem().index = hoverIndex;
        }
    },
    _onSearchValueChange: function _onSearchValueChange(event, value) {
        var self = this;
        this.setState({
            searchValue: value
        });

        this._populateOptions_forSearchValue_andShowResults(value, true);
    },
    _populateOptions_forSearchValue_andShowResults: function _populateOptions_forSearchValue_andShowResults(value, showResults) {
        var self = this;
        var url = this.props.url;
        var shouldDoQuery = this.props.requireSearchValueForDropdown ? !!value : true;

        shouldDoQuery && request.get(url).query({
            search_input: value || '',
            count: this.props.count
        }).then(function success(response) {
            var options = response.body.data;

            if (self._isMounted) {
                self.setState({
                    options: options,
                    showResults: showResults,
                    moduleDefByType: _extends({}, self.state.moduleDefByType, self.getModuleDefByType_fromOptions(options))
                });
            }
        }, function error(err) {
            //# show error notification?
        });
    },
    _handleKeyDown: function _handleKeyDown(event) {
        switch ((0, _keycode2.default)(event)) {
            case 'down':
                event.preventDefault();
                this.setState({
                    shouldAutoFocusPopoverMenu: true
                });
                break;
            case 'tab':
            case 'esc':
                this._onBlur_fromField(event);
                break;
            default:
                break;
        }
    },
    getModuleDefByType_fromOptions: function getModuleDefByType_fromOptions(options) {
        var moduleDefByType = options.reduce(function (result, option) {
            result[option.type] = option;
            return result;
        }, {});
        return moduleDefByType;
    },
    _onValueChange_withMenuItems: function _onValueChange_withMenuItems(menuItems) {
        var _this = this;

        var self = this;
        return function (event, selectedModuleDefs) {
            var uniqueSelectedModuleDefs = _lodash2.default.uniqBy(selectedModuleDefs, 'type');

            _this.props.delegateValueChange && _this.props.delegateValueChange(event, uniqueSelectedModuleDefs);

            if (Array.isArray(menuItems) && menuItems.length === 1 || _lodash2.default.get(_this, 'props.limit') == 1) {
                _this.setState({
                    showResults: false
                });
            }
        };
    },
    _onValueChangeFromList: function _onValueChangeFromList(event, alteredTypesValue) {
        if (alteredTypesValue.length > this.props.limit) {
            return false;
        }
        var moduleDefByType = this.state.moduleDefByType;


        var uniqueTypes = _lodash2.default.uniq(alteredTypesValue);

        var uniqueModuleDefs = uniqueTypes.map(function (type) {
            return moduleDefByType[type];
        });

        this.props.delegateValueChange && this.props.delegateValueChange(event, uniqueModuleDefs);
    },
    _onBlur_fromField: function _onBlur_fromField(event) {
        //# clear textfield
        this._onSearchValueChange(event, '');
        this._anchorEl.getElementsByTagName('input')[0].blur();

        this.setState({
            shouldAutoFocusPopoverMenu: false,
            showResults: false
        });
    },
    _onEscKeyDown_fromMenu: function _onEscKeyDown_fromMenu() {

        //# focus text field
        this._anchorEl.getElementsByTagName('input')[0].focus();
    },
    _onClosePopover: function _onClosePopover(reason) {
        this.setState({
            shouldAutoFocusPopoverMenu: false,
            showResults: false
        });
    },
    _onTextFieldFocus: function _onTextFieldFocus() {
        this.setState({
            shouldAutoFocusPopoverMenu: false,
            showResults: true
            //, anchorEl: ReactDOM.findDOMNode(this.refs.searchTextField)
        });
    },
    _removeSelectionGeneratorForType: function _removeSelectionGeneratorForType(type) {
        var _this2 = this;

        if (this.props.disabled) {
            return function () {};
        } else {
            return function () {
                var currentValues = _this2.hydratedValues();
                var newValue = currentValues.filter(function (currentValue) {
                    return currentValue.type != type;
                });

                _this2.props.delegateValueChange && _this2.props.delegateValueChange(event, newValue);
            };
        }
    },
    _handleOnMove: function _handleOnMove(startIdx, endIdx) {

        if (this.props.disabled) {
            return;
        }

        // Get the sorted values
        var newValue = this.props.value;

        var dataToMove = newValue[startIdx];
        newValue = [].concat(_toConsumableArray(newValue.slice(0, startIdx)), _toConsumableArray(newValue.slice(startIdx + 1)));

        newValue = [].concat(_toConsumableArray(newValue.slice(0, endIdx)), [dataToMove], _toConsumableArray(newValue.slice(endIdx)));

        this.props.delegateValueChange && this.props.delegateValueChange(event, newValue);
    },

    values: function values() {
        var dataIsArray = Array.isArray(this.props.value);
        var dataIsDefined = this.props.value ? true : false;

        //# the connect layer should have normalized the value
        return dataIsArray ? this.props.value : dataIsDefined ? [this.props.value] : [];
    },
    hydratedValues: function hydratedValues() {
        var _this3 = this;

        var hydratedValues = this.values().reduce(function (collector, moduleType) {

            var moduleDef = _this3.state.moduleDefByType[moduleType];
            return _this3.state.moduleDefByType[moduleType] ? collector.concat([moduleDef]) : collector;
        }, []);

        return hydratedValues;
    },
    _getCanShowResults: function _getCanShowResults() {
        return this.props.requireSearchValueForDropdown ? !!this.props.searchValue : true;
    },
    render: function render() {
        var _React$createElement,
            _this4 = this,
            _extends3,
            _extends4,
            _extends5,
            _React$createElement2,
            _extends6,
            _extends7,
            _extends8;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }
        var popoverIsOpen = this._getCanShowResults() && this.state.showResults;

        var self = this;

        var menuWidth = this._anchorEl ? this._anchorEl.offsetWidth : null;

        var menuItems = self.state.options.length ? self.state.options.map(function (option, idx) {
            var _extends2;

            var isSelected = self.values().indexOf(option.type) > -1;
            return _react2.default.createElement(_MenuItem2.default, _extends({
                key: idx,
                checked: isSelected,
                insetChildren: true
            }, _TokenTagger_style2.default.MenuItem, (_extends2 = {
                style: { width: menuWidth },
                value: option,
                primaryText: option.text,
                'data-codecept-selector-node': 'MenuItem',
                'data-codecept-selector-file': 'TokenTagger'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends2, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends2, 'data-codecept-selector-file', 'TokenTagger'), _extends2)));
        }) : _react2.default.createElement(_MenuItem2.default, (_React$createElement = {
            key: 'noResults',
            disabled: true,
            primaryText: 'No Results Found',
            'data-codecept-selector-node': 'MenuItem',
            'data-codecept-selector-file': 'TokenTagger'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement));

        var labelProps = _lodash2.default.pick(this.props, Object.keys(Label.propTypes));

        var tokenTaggerListProps = _lodash2.default.pick(this.props, TokenTaggerList__propTypeKeys);

        var Card_style = this.props.style.Card || _TokenTagger_style2.default.Card || {};
        var hintText = this.props.hintText || 'Search to add ' + _lodash2.default.upperFirst(this.props.label);
        return _react2.default.createElement(
            _Card.Card,
            _extends({
                className: _TokenTagger2.default.wrapper,
                id: this.props.id
            }, Card_style, (_extends8 = {
                'data-codecept-selector-node': 'Card',
                'data-codecept-selector-file': 'TokenTagger'
            }, _defineProperty(_extends8, 'data-codecept-selector-node', 'Card'), _defineProperty(_extends8, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends8, 'data-codecept-selector-node', 'Card'), _defineProperty(_extends8, 'data-codecept-selector-file', 'TokenTagger'), _extends8)),
            _react2.default.createElement(
                _Card.CardText,
                _extends({}, _TokenTagger_style2.default.CardText, (_extends7 = {
                    'data-codecept-selector-node': 'CardText',
                    'data-codecept-selector-file': 'TokenTagger'
                }, _defineProperty(_extends7, 'data-codecept-selector-node', 'CardText'), _defineProperty(_extends7, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends7, 'data-codecept-selector-node', 'CardText'), _defineProperty(_extends7, 'data-codecept-selector-file', 'TokenTagger'), _extends7)),
                _react2.default.createElement(_TextField2.default, _extends({}, _TokenTagger_style2.default.TextField, (_extends4 = {
                    ref: function ref(searchTextField) {
                        if (searchTextField) {
                            _this4._anchorEl = _reactDom2.default.findDOMNode(searchTextField);
                        }
                    },
                    floatingLabelText: _react2.default.createElement(Label, _extends({}, labelProps, (_extends3 = {
                        'data-codecept-selector-node': 'Label',
                        'data-codecept-selector-file': 'TokenTagger'
                    }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends3, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends3, 'data-codecept-selector-file', 'TokenTagger'), _extends3))),
                    floatingLabelFixed: true,
                    hintText: hintText,
                    onChange: this._onSearchValueChange,
                    value: this.state.searchValue,
                    onKeyDown: this._handleKeyDown,
                    onFocus: this._onTextFieldFocus,
                    disabled: this.props.disabled,
                    errorText: this.props.error ? this.props.error.message : null,
                    'data-codecept-selector-node': 'TextField',
                    'data-codecept-selector-file': 'TokenTagger'
                }, _defineProperty(_extends4, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends4, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends4, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends4, 'data-codecept-selector-file', 'TokenTagger'), _extends4))),
                _react2.default.createElement(
                    Popover,
                    (_React$createElement2 = {
                        open: popoverIsOpen,
                        useLayerForClickAway: true,
                        onRequestClose: this._onClosePopover,
                        anchorEl: this._anchorEl,
                        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                        targetOrigin: { vertical: 'top', horizontal: 'left' },
                        height: menuItems.length,
                        'data-codecept-selector-node': 'Popover',
                        'data-codecept-selector-file': 'TokenTagger'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Popover'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Popover'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TokenTagger'), _React$createElement2),
                    _react2.default.createElement(
                        _Menu2.default,
                        _extends({}, _TokenTagger_style2.default.Menu, (_extends5 = {
                            value: this.hydratedValues().slice(0),
                            ref: 'popoverMenu',
                            disableAutoFocus: !this.state.shouldAutoFocusPopoverMenu,
                            initiallyKeyboardFocused: true,
                            onEscKeyDown: this._onEscKeyDown_fromMenu,
                            onChange: this._onValueChange_withMenuItems(menuItems),
                            multiple: true,
                            maxHeight: 400,
                            autoWidth: false,
                            width: menuWidth,
                            'data-codecept-selector-node': 'Menu',
                            'data-codecept-selector-file': 'TokenTagger'
                        }, _defineProperty(_extends5, 'data-codecept-selector-node', 'Menu'), _defineProperty(_extends5, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends5, 'data-codecept-selector-node', 'Menu'), _defineProperty(_extends5, 'data-codecept-selector-file', 'TokenTagger'), _extends5)),
                        menuItems
                    )
                ),
                _react2.default.createElement(_TokenTaggerList2.default, _extends({}, tokenTaggerListProps, (_extends6 = {
                    disabled: this.props.disabled,
                    delegateValueChange: this._onValueChangeFromList,
                    delegateDeleteGeneratorForType: this._removeSelectionGeneratorForType,
                    values: this.hydratedValues(),
                    'data-codecept-selector-node': 'TokenTaggerList',
                    'data-codecept-selector-file': 'TokenTagger'
                }, _defineProperty(_extends6, 'data-codecept-selector-node', 'TokenTaggerList'), _defineProperty(_extends6, 'data-codecept-selector-file', 'TokenTagger'), _defineProperty(_extends6, 'data-codecept-selector-node', 'TokenTaggerList'), _defineProperty(_extends6, 'data-codecept-selector-file', 'TokenTagger'), _extends6)))
            )
        );
    }

});

exports.default = TokenTagger;