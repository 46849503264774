'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _normalizr = require('normalizr');

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function getFlattenedNestedData_fromNestedData_withChildrenKey(nestedData) {
    var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'children';


    var wrappedNestedData = Array.isArray(nestedData) ? _defineProperty({
        id: 'root'
    }, key, nestedData) : nestedData;

    //let nextId = 0;
    function generateId(entity, id) {
        var newId = _.get(entity, 'id');
        //|| (nextId++).toString();
        //# previously there's an obscure scenario where if the griddleData received an empty row 
        //# where the id was missing (which previously happened during the denormalize stage of the hydrate-sitemap endpoint)
        //# and you modified the sitemap,
        //# then nextId would have been used, which is invalid
        //# thus causing the system to think you 
        return newId;
    }

    var rowSchema = new _normalizr.Schema('rows', { idAttribute: generateId });
    rowSchema.define({
        children: (0, _normalizr.arrayOf)(rowSchema)
    });

    var normalizedData = (0, _normalizr.normalize)(wrappedNestedData, rowSchema);

    var rowsByKey = _.get(normalizedData, 'entities.rows');

    return _.pickBy(rowsByKey, function (val, key) {
        return key !== 'root';
    });
}

function getNormalizedDataWithoutDuplicates_fromNormalizedData() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var rootIds = data.entities.rows[data.result].children;
    var rowById = _.get(data, 'entities.rows');

    var newRootIds = _.uniq(rootIds);
    var rowIds = Object.keys(rowById);
    var newRows = rowIds.map(function (id, index) {
        var _ref2;

        var row = rowById[id];
        var encounteredRows = rowIds.slice(0, index).map(function (id) {
            return rowById[id];
        });
        var encounteredChildrenIds = (_ref2 = []).concat.apply(_ref2, _toConsumableArray(encounteredRows.map(function (row) {
            return row.children || [];
        })));
        var currentChildren = row.children || [];
        var childrenWithoutRootItems = currentChildren.filter(function (childId) {
            return newRootIds.findIndex(function (rootId) {
                return rootId == childId;
            }) == -1;
        });
        var childrenWithoutEncounteredIds = childrenWithoutRootItems.filter(function (childId) {
            return encounteredChildrenIds.findIndex(function (rootId) {
                return rootId == childId;
            }) === -1;
        });

        return _extends({}, row, {
            children: childrenWithoutEncounteredIds //: childrenWithoutRootItems
        });
    });

    var newRowById = newRows.reduce(function (result, curr) {
        result[curr.id] = curr;
        return result;
    }, {});

    return {
        result: newRootIds,
        entities: {
            rows: newRowById
        }
    };
}

exports.default = {
    getNormalizedDataWithoutDuplicates_fromNormalizedData: getNormalizedDataWithoutDuplicates_fromNormalizedData,
    getFlattenedNestedData_fromNestedData_withChildrenKey: getFlattenedNestedData_fromNestedData_withChildrenKey
};