'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _urlJoin = require('url-join');

var _urlJoin2 = _interopRequireDefault(_urlJoin);

var _querystring = require('querystring');

var _querystring2 = _interopRequireDefault(_querystring);

var _LoginWithSaml = require('./LoginWithSaml');

var _LoginWithSaml2 = _interopRequireDefault(_LoginWithSaml);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

module.exports = function LoginWithSamlConnector() {
    var selfModule = this;
    var selfProps = this.props;

    var _getDependencies = this.getDependencies(),
        feSettingsComponent = _getDependencies.feSettingsComponent;

    function mapStateToProps(state, ownProps) {
        var selfState = selfModule.getState(state);
        var feSettingsSelector = feSettingsComponent.getDefaultSelector();
        var feSettingsState = feSettingsSelector(state);
        var apiUrlPrefix = _lodash2.default.get(feSettingsState, 'restApiRoot');
        var ssoEnabled = _lodash2.default.get(feSettingsState, 'RUBYAPPS_SETTINGS.saml.sso_enabled');

        var window__location__search = window.location.search;
        // Remove the beginning ? if present (it usually is), since querystring.parse doesn't handle it.
        if (window__location__search.indexOf('?') === 0) {
            window__location__search = window__location__search.slice(1);
        }
        var searchQuery = _querystring2.default.parse(window__location__search);
        var next_page = _lodash2.default.get(searchQuery, 'next_page');
        var query = {};
        if (next_page) {
            query.next_page = next_page;
        }

        var stringifiedQuery = '';
        if (query && !_lodash2.default.isEmpty(query)) {
            stringifiedQuery = '?' + _querystring2.default.stringify(query);
        }
        var initiateLoginEndpoint = (0, _urlJoin2.default)(apiUrlPrefix, 'users/login/saml', stringifiedQuery);

        return _extends({
            selfProps: selfProps,
            selfState: selfState,
            href: initiateLoginEndpoint,
            shouldRender: ssoEnabled
        });
    }

    function mapDispatchToProps(dispatch, ownProps) {
        var selfActions = selfModule.getAction();
        return {
            actions: (0, _redux.bindActionCreators)(selfActions, dispatch)
        };
    }

    function mergeProps(stateProps, dispatchProps, ownProps) {
        return _extends({}, ownProps, stateProps, dispatchProps);
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(_LoginWithSaml2.default);
};