'use strict';

var _ = require('lodash');

function typesFromID(id) {
    var TYPES = {};

    return TYPES;
}

var generators = {};

module.exports = function () {
    var TYPES = typesFromID(this.getID());
    return {
        TYPES: TYPES,
        generators: generators
    };
};