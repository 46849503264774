'use strict';

var _constants = require('./constants');

var constants = _interopRequireWildcard(_constants);

var _initialState = require('./initial-state');

var _initialState2 = _interopRequireDefault(_initialState);

var _reducer = require('./reducer');

var reducers = _interopRequireWildcard(_reducer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

/**
 * Function description
 * @param {object} options.initialState - inistial state object used to override the saved preferences.
 **/
module.exports = function (preferencesStorageKeyIdentifier, preferencesToSave) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    return {
        name: 'GriddlePreferences',
        constants: constants,
        states: (0, _initialState2.default)(preferencesStorageKeyIdentifier, preferencesToSave, options),
        components: {},
        reducers: reducers
    };
};