'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeRequest = makeRequest;

var _superagent = require('superagent');

var _superagent2 = _interopRequireDefault(_superagent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//# NOTE: the provider is responsible for 
//# noting that we're loading by dispatching startLoadingActionGenerator
function makeRequest(remoteConfig, tableState, _ref) {
  var startLoadingActionGenerator = _ref.startLoadingActionGenerator,
      successActionGenerator = _ref.successActionGenerator,
      remoteErrorActionGenerator = _ref.remoteErrorActionGenerator,
      griddleState = _ref.griddleState;

  return function (dispatch) {
    var url = remoteConfig.url,
        requestHeaders = remoteConfig.requestHeaders,
        formatRequest = remoteConfig.formatRequest,
        formatSuccess = remoteConfig.formatSuccess,
        formatError = remoteConfig.formatError;

    // Set up the request.

    var loadRequest = _superagent2.default.get(url);

    // If the   request needs to be constructed differently, pass to the override function.
    if (formatRequest) {
      loadRequest = formatRequest(loadRequest, tableState, griddleState);
    } else {
      var page = tableState.page,
          pageSize = tableState.pageSize,
          filter = tableState.filter,
          sortColumn = tableState.sortColumn,
          sortDirection = tableState.sortDirection;

      // Add parameters

      loadRequest = loadRequest.query({ page: page,
        pageSize: pageSize,
        filter: filter,
        sortColumn: sortColumn && sortColumn.length > 0 ? sortColumn[0] : '',
        sortDirection: sortDirection });

      // Add any headers added to the remote config
      if (requestHeaders) {
        Object.keys(requestHeaders).forEach(function (key) {
          loadRequest = loadRequest.set(key, requestHeaders[key]);
        });
      }
    }

    if (loadRequest) {
      // Indicate that our AJAX request is starting.
      dispatch(startLoadingActionGenerator(loadRequest));

      loadRequest.end(function (err, res) {
        if (res.ok) {
          var successResponse = res.body;

          // Format if necessary.
          if (formatSuccess) {
            successResponse = formatSuccess(successResponse, tableState, griddleState);
          }

          // Dispatch the success
          dispatch(successActionGenerator(successResponse));
        } else {
          var errorResponse = res.body;

          // Format if necessary
          if (formatError) {
            errorResponse = formatError(err, errorResponse);
          }

          // Dispatch the error
          dispatch(remoteErrorActionGenerator(err, errorResponse));
        }
      });
    } else {
      dispatch(successActionGenerator({
        data: [],
        total: 0
      }));
    }
  };
}