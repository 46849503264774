'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _rubyHierarchyUtils = require('@rubyapps/ruby-hierarchy-utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var request = require('@rubyapps/ruby-superagent');
var path = require('path');
var urljoin = require('url-join');

var rubyWords = require('@rubyapps/ruby-words');
var rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');

module.exports = function () {
    var selfModule = this;
    var formKey = this.props.key;
    var id = selfModule.getID();

    var TYPES = {
        SET_ACTIVE_PARENT_PAGE_ID: '@ruby-app/' + id + '/SET_ACTIVE_PARENT_PAGE_ID'
    };

    var pushNotification = selfModule.pushNotification.bind(selfModule);
    var showNotificationModal = selfModule.showNotificationModal.bind(selfModule);

    return {
        TYPES: TYPES,
        generators: {
            copyPage_withId_andName_andOnSuccess: function copyPage_withId_andName_andOnSuccess(idToDuplicate, name, onSuccess) {
                var skipValidation = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

                var selfActions = selfModule.getAction().generators;

                return function (dispatch, getState) {
                    var currentFormValue = selfModule.formValue();
                    var currentFieldValue = _lodash2.default.get(currentFormValue, formKey);
                    var applicationState = getState();

                    var _selfModule$getDepend = selfModule.getDependencies(),
                        feSettingsSelector = _selfModule$getDepend.feSettingsSelector;

                    var apiUrlPrefix = _lodash2.default.get(feSettingsSelector(applicationState), 'restApiRoot');
                    var copyApiEndpoint = path.join(apiUrlPrefix, selfModule.props.apiKey, '' + idToDuplicate, 'copy');

                    request.post(copyApiEndpoint + (skipValidation ? '?skipValidation=true' : '')).then(function (response) {
                        var newName = rubyWords.quote(_lodash2.default.get(response, 'body.name'));
                        var newId = _lodash2.default.get(response, 'body.id');
                        var parentPage = getParentOfPage_withId_inSitemap(idToDuplicate, currentFieldValue);
                        var parentId = _lodash2.default.get(parentPage, 'id');
                        var siblings = parentPage ? _lodash2.default.get(parentPage, 'children', []) : _lodash2.default.get(currentFieldValue, 'result', []);
                        var copiedPageIndex = siblings.findIndex(function (childId) {
                            return childId == idToDuplicate;
                        });
                        var newPageIndex = Number.isInteger(copiedPageIndex) && copiedPageIndex !== -1 && copiedPageIndex + 1;
                        dispatch(selfActions.addPage_withId_andParentId_andIndex_andOnSuccess(newId, parentId, newPageIndex));
                        pushNotification({
                            message: 'Copied ' + name + ' to ' + newName + '.',
                            type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS
                        });
                        _lodash2.default.isFunction(onSuccess) && onSuccess();
                    }).catch(function (err) {
                        var notificationMethod = pushNotification;

                        var notificationOptions = {
                            title: null,
                            message: null,
                            additionalActions: null,
                            type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR
                        };

                        if (err.status === 403) {
                            // should not see this ultimately, since the lister will constrained to valid entries
                            notificationOptions.message = 'You do not have the permission to copy this content.';
                        } else if (err.status === 422) {
                            var _React$createElement2, _React$createElement3, _React$createElement4;

                            var fieldErrors = err.response.body.error.errors.map(function (fieldError) {
                                return fieldError.message;
                            }).reduce(function (collector, fieldError) {
                                var _React$createElement;

                                collector.push(fieldError);
                                collector.push(React.createElement('br', (_React$createElement = {
                                    'data-codecept-selector-node': 'br',
                                    'data-codecept-selector-file': 'action'
                                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'action'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'action'), _React$createElement)));

                                return collector;
                            }, []);

                            notificationOptions.title = 'Content Copy Validation Error';
                            notificationOptions.message = React.createElement(
                                'p',
                                (_React$createElement4 = {
                                    'data-codecept-selector-node': 'p',
                                    'data-codecept-selector-file': 'action'
                                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'action'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'action'), _React$createElement4),
                                'Validation error while copying content:',
                                React.createElement('br', (_React$createElement2 = {
                                    'data-codecept-selector-node': 'br',
                                    'data-codecept-selector-file': 'action'
                                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'action'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'action'), _React$createElement2)),
                                React.createElement('br', (_React$createElement3 = {
                                    'data-codecept-selector-node': 'br',
                                    'data-codecept-selector-file': 'action'
                                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'action'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'action'), _React$createElement3)),
                                ' ',
                                fieldErrors
                            );

                            notificationOptions.additionalActions = [{
                                label: 'Copy without Validating',
                                onTouchTap: function onTouchTap(evt, callerReactComponent) {
                                    callerReactComponent.props.delegateClose();
                                    dispatch(selfActions.copyPage_withId_andName_andOnSuccess(idToDuplicate, name, onSuccess, true));
                                }
                            }];

                            notificationMethod = showNotificationModal;
                        } else {
                            notificationOptions.title = 'Content Copy Error';
                            notificationOptions.message = 'An error was encountered while copying content. ' + 'Please try again later or contact an administrator.';
                            notificationMethod = showNotificationModal;
                        }

                        notificationMethod(notificationOptions);
                    });
                };
            },
            setItemStatus_withActive: function setItemStatus_withActive(item, isActive, thenableArguments) {
                var templateKey = item.templateKey,
                    id = item.id;

                var selfModule = this;

                var _selfModule$getDepend2 = selfModule.getDependencies(),
                    feSettingsSelector = _selfModule$getDepend2.feSettingsSelector;

                var pushNotification = selfModule.pushNotification.bind(selfModule);
                var showNotificationModal = selfModule.showNotificationModal.bind(selfModule);

                return function (dispatch, getState) {
                    var applicationState = getState();
                    var apiUrlPrefix = _lodash2.default.get(feSettingsSelector(applicationState), 'restApiRoot');
                    var endpoint = urljoin(apiUrlPrefix, selfModule.props.apiKey, '' + id, isActive ? 'publish' : 'unpublish');
                    var requestPromise = request.post(endpoint).then(function (response) {
                        var displayStatusMap = {
                            active: 'published',
                            inactive: 'unpublished'
                        };
                        var status = _lodash2.default.get(response, 'body.status');
                        var contentDisplayString = rubyWords.quote(_lodash2.default.get(response, 'body.name')) || 'This content';
                        var displayedStatus = displayStatusMap[status];

                        pushNotification({
                            message: contentDisplayString + ' is now ' + displayedStatus + '.',
                            type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS
                        });
                        return response;
                    }, function (err) {
                        var notificationMethod = pushNotification,
                            title = void 0,
                            message = void 0,
                            type = rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR;
                        if (err.status === 409) {
                            message = 'The entry could not be updated due to a pending content approval request. ' + 'Please try again once the request is resolved.';
                        } else if (err.status === 403) {
                            message = _lodash2.default.get(err, 'response.body.error.message') || 'You do not have the permission to update content. ' + 'Please edit the page and submit a content approval request.';
                        } else if (err.status === 422) {
                            message = 'The entry could not be updated due to invalid content. ' + 'Please edit the page to fix.';
                        } else {
                            title = 'Content Update Error';
                            message = _lodash2.default.get(err, 'response.body.error.message') || 'An error was encountered while updating content. ' + 'Please try again later.';
                            notificationMethod = showNotificationModal;
                        }

                        notificationMethod({ type: type, title: title, message: message });

                        throw err;
                    }).catch(function (err) {
                        console.error(err);
                    });
                    if (thenableArguments) {
                        requestPromise.then.apply(requestPromise, thenableArguments);
                    }
                    return requestPromise;
                };
            },
            setActiveParentPageId: function setActiveParentPageId(parentId) {
                return function (dispatch, getState) {
                    dispatch({
                        type: TYPES.SET_ACTIVE_PARENT_PAGE_ID,
                        payload: {
                            id: parentId
                        }
                    });
                };
            },
            addPageDialog_withParentId: function addPageDialog_withParentId(parentId) {
                var selfModule = this;

                var _getDependencies = this.getDependencies(),
                    editPageTemplateKey = _getDependencies.editPageTemplateKey,
                    newPageDialogActions = _getDependencies.newPageDialogActions,
                    getEditEntryUrl_forId_withTemplateKeyword = _getDependencies.getEditEntryUrl_forId_withTemplateKeyword;

                var selfActions = selfModule.getAction().generators;
                var openNewPageDialog_options = this.props.listerConfig.openNewPageDialog_options;


                return function (dispatch, getState) {
                    dispatch(newPageDialogActions.openNewPageDialog(_extends({
                        shouldAllocateNewPage: true,
                        addableTemplates: selfModule.addableTemplateForms(),
                        parent_id: parentId,
                        submitSuccessCallback: function submitSuccessCallback(response) {
                            var _$get = _lodash2.default.get(response, 'body'),
                                id = _$get.id,
                                template_keyword = _$get.template_keyword;

                            selfModule.setStatefulCacheForKey('pendingAddPage', true);

                            dispatch(selfActions.addPage_withId_andParentId_andOnSuccess(id, parentId, function () {
                                var editLink = getEditEntryUrl_forId_withTemplateKeyword(id, template_keyword);

                                //# ignore changes because we know we just saved
                                //# and the diffing algo is async, so during a navigation, it thinks there are changes
                                dispatch(selfActions.navigateToPathWithOptions({ path: editLink }, null, { ignoreChanges: true }));
                                selfModule.clearStatefulCacheForKey('pendingAddPage');
                            }));
                        },
                        submitFailCallback: function submitFailCallback() {}
                    }, openNewPageDialog_options)));
                };
            },
            addPage_withId_andOnSuccess: function addPage_withId_andOnSuccess(id, onSuccess) {
                var selfActions = selfModule.getAction().generators;

                return selfActions.addPage_withId_andIndex_andOnSuccess(id, null, onSuccess);
            },
            addPage_withId_andIndex_andOnSuccess: function addPage_withId_andIndex_andOnSuccess(id, index, onSuccess) {
                return function (dispatch, getState) {
                    var currentFormValue = selfModule.formValue();
                    var currentFieldValue = _lodash2.default.get(currentFormValue, formKey);

                    var currRootIdArr = _lodash2.default.get(currentFieldValue, 'result', []);
                    var newPageIndex = index != null ? index : currRootIdArr.length;
                    var newRootIdArr = [].concat(currRootIdArr.slice(0, newPageIndex), id, currRootIdArr.slice(newPageIndex));

                    var currSitemapItemById = _lodash2.default.get(currentFieldValue, 'entities.sitemapItem');
                    var newSitemapItemById = _extends({}, currSitemapItemById, _defineProperty({}, id, { id: id }));

                    var newFieldValue = _extends({}, currentFieldValue, {
                        entities: {
                            sitemapItem: newSitemapItemById
                        },
                        result: newRootIdArr
                    });
                    dispatch(selfModule.getAction().generators.setFieldValue((0, _rubyHierarchyUtils.getPrunedSitemap_fromSitemap)(newFieldValue)));
                    selfModule.util__save({
                        successCallback: onSuccess
                    });
                };
            },
            addPage_withId_andParentId_andOnSuccess: function addPage_withId_andParentId_andOnSuccess(id, parentId, onSuccess) {
                var selfActions = selfModule.getAction().generators;

                return selfActions.addPage_withId_andParentId_andIndex_andOnSuccess(id, parentId, null, onSuccess);
            },
            addPage_withId_andParentId_andIndex_andOnSuccess: function addPage_withId_andParentId_andIndex_andOnSuccess(id, parentId, index, onSuccess) {
                var selfActions = selfModule.getAction().generators;

                if (parentId == null) {
                    return selfActions.addPage_withId_andIndex_andOnSuccess(id, index, onSuccess);
                }

                return function (dispatch, getState) {
                    var _extends3;

                    var currentFormValue = selfModule.formValue();
                    var currentFieldValue = _lodash2.default.get(currentFormValue, formKey);

                    var currRootIdArr = _lodash2.default.get(currentFieldValue, 'result', []);

                    var currSitemapItemById = _lodash2.default.get(currentFieldValue, 'entities.sitemapItem', {});
                    var currParentItem = _lodash2.default.get(currSitemapItemById, parentId);
                    var currChildren = _lodash2.default.get(currParentItem, 'children', []);
                    var indexOfAddedItem = index != null ? index : currChildren.length;
                    var newParentItem = _extends({}, currParentItem, {
                        children: [].concat(currChildren.slice(0, indexOfAddedItem), id, currChildren.slice(indexOfAddedItem))
                    });

                    var newSitemapItemById = _extends({}, currSitemapItemById, (_extends3 = {}, _defineProperty(_extends3, id, { id: id }), _defineProperty(_extends3, parentId, newParentItem), _extends3));

                    var newFieldValue = _extends({}, currentFieldValue, {
                        entities: {
                            sitemapItem: newSitemapItemById
                        }
                    });
                    dispatch(selfModule.getAction().generators.setFieldValue((0, _rubyHierarchyUtils.getPrunedSitemap_fromSitemap)(newFieldValue)));
                    selfModule.util__save({
                        successCallback: onSuccess
                    });
                };
            },
            removePage_withId: function removePage_withId(idToRemove, onSuccess) {
                var formKey = this.props.key;

                return function (dispatch, getState) {
                    var currentFormValue = selfModule.formValue();
                    var currentFieldValue = _lodash2.default.get(currentFormValue, formKey);

                    var currRootIdArr = _lodash2.default.get(currentFieldValue, 'result', []);
                    var newRootIdArr = _lodash2.default.filter(currRootIdArr, function (id) {
                        return id != idToRemove;
                    });

                    var currSitemapItemById = _lodash2.default.get(currentFieldValue, 'entities.sitemapItem');
                    var newSitemapItemById = _lodash2.default.mapValues(currSitemapItemById, function (sitemapItem) {
                        var children = _lodash2.default.get(sitemapItem, 'children', []);
                        var newChildren = children.filter(function (id) {
                            return id != idToRemove;
                        });
                        return _extends({}, sitemapItem, {
                            children: newChildren
                        });
                    });

                    var newFieldValue = _extends({}, currentFieldValue, {
                        entities: {
                            sitemapItem: newSitemapItemById
                        },
                        result: newRootIdArr
                    });

                    dispatch(selfModule.getAction().generators.setFieldValue((0, _rubyHierarchyUtils.getPrunedSitemap_fromSitemap)(newFieldValue)));

                    selfModule.util__save({
                        successCallback: onSuccess
                    });
                };
            }
        }
    };
};

function getParentOfPage_withId_inSitemap(id, sitemap) {
    var rootIds = _lodash2.default.get(sitemap, 'result');
    var pageById = _lodash2.default.get(sitemap, 'entities.sitemapItem');
    if (rootIds.find(function (rootId) {
        return rootId == id;
    })) {
        return null;
    } else {
        var pages = _lodash2.default.mapValues(pageById, function (page) {
            return page;
        });
        var parentPage = _lodash2.default.find(pages, function (page) {
            var children = _lodash2.default.get(page, 'children', []);
            return children.find(function (childId) {
                return childId == id;
            });
        });
        return parentPage;
    }
}