'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _keycode = require('keycode');

var _keycode2 = _interopRequireDefault(_keycode);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _Divider = require('material-ui/Divider');

var _Divider2 = _interopRequireDefault(_Divider);

var _propTypes = require('material-ui/utils/propTypes');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _AutoCompleteMenu = require('./AutoCompleteMenu');

var _AutoCompleteMenu2 = _interopRequireDefault(_AutoCompleteMenu);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TextField__propTypes = _TextField2.default.propTypes;
var AutoCompleteMenu__propTypes = _AutoCompleteMenu2.default.propTypes;
var AutoCompleteMenu__propTypes__keys = Object.keys(AutoCompleteMenu__propTypes);

function getStyles(props, context, state) {
  var anchorEl = state.anchorEl;
  var fullWidth = props.fullWidth,
      _props$styles = props.styles,
      overrideStyles = _props$styles === undefined ? {} : _props$styles;


  var styles = {
    root: _extends({
      display: 'inline-block',
      position: 'relative',
      width: fullWidth ? '100%' : 256
    }, overrideStyles.root || {}),
    menu: _extends({
      width: '100%'
    }, overrideStyles.menu || {}),
    list: _extends({
      display: 'block',
      width: fullWidth ? '100%' : 256
    }, overrideStyles.list || {}),
    innerDiv: _extends({
      overflow: 'hidden'
    }, overrideStyles.innerDiv || {})
  };

  if (anchorEl && fullWidth) {
    styles.popover = _extends({
      minWidth: anchorEl.clientWidth
    }, overrideStyles.popover || {});
  } else {
    styles.popover = overrideStyles.popover || {};
  }

  return styles;
}

var AutoComplete = (_temp = _class = function (_Component) {
  _inherits(AutoComplete, _Component);

  function AutoComplete(props) {
    _classCallCheck(this, AutoComplete);

    var _this = _possibleConstructorReturn(this, (AutoComplete.__proto__ || Object.getPrototypeOf(AutoComplete)).call(this, props));

    _this.handleRequestClose = function () {
      // Only take into account the Popover clickAway when we are
      // not focusing the TextField.
      if (!_lodash2.default.get(_this, 'refs.searchTextField.state.isFocused') && !_lodash2.default.get(_this, 'state.menuFocused')) {
        _this.close();
      }
    };

    _this.handleItemTouchTap = function (event, child) {
      var dataSource = _this.state.normalizedDataSource;
      var requestsByReactKey = _this.requestsByReactKeyFromDataSourceList(dataSource);
      var reactKey = child.key;


      var chosenRequest = requestsByReactKey[reactKey] || {};

      if (_lodash2.default.get(chosenRequest, 'menuItemProps.disabledSelection')) {
        return;
      }

      var searchText = _this.chosenRequestText(chosenRequest);

      _this.timerTouchTapCloseId = setTimeout(function () {
        _this.timerTouchTapCloseId = null;
        var filteredDataSource = _this.filterDataSourceUsingSearchText(_this.props.dataSource, searchText);

        _this.setState({
          searchText: searchText,
          filteredDataSource: filteredDataSource,
          openMenuItems: _this.openMenuItemKeysFromFilteredDataSource(filteredDataSource)
        });
        _this.close();
        _this.props.onNewRequest(chosenRequest);
      }, _this.props.menuCloseDelay);
    };

    _this.chosenRequestText = function (chosenRequest) {
      if (typeof chosenRequest === 'string') {
        return chosenRequest;
      } else {
        return chosenRequest[_this.props.dataSourceConfig.text];
      }
    };

    _this._onEscKeyDown_fromTextField = function () {
      _reactDom2.default.findDOMNode(_this.refs.searchTextField).getElementsByTagName('input')[0].blur();
      _this.close();
    };

    _this._onEscKeyDown_fromMenu = function () {
      _reactDom2.default.findDOMNode(_this.refs.searchTextField).getElementsByTagName('input')[0].focus();
      //this.close();
    };

    _this.handleKeyDown = function (event) {
      if (_this.props.onKeyDown) _this.props.onKeyDown(event);

      switch ((0, _keycode2.default)(event)) {
        case 'enter':
          var searchText = _this.state.searchText;
          if (searchText !== '') {
            _this.props.onNewRequest(searchText, -1);
          }
          break;

        case 'esc':
          _this._onEscKeyDown_fromTextField(event);
          break;

        case 'down':
          event.preventDefault();

          _this._onFocus_menu();
          break;

        default:
          break;
      }
    };

    _this.handleChange = function (event) {
      var searchText = event.target.value;

      // Make sure that we have a new searchText.
      // Fix an issue with a Cordova Webview
      if (searchText === _this.state.searchText) {
        return;
      }

      var normalizedDataSource = _this.state.normalizedDataSource;

      var filteredDataSource = _this.filterDataSourceUsingSearchText(normalizedDataSource, searchText);
      _this.setState({
        searchText: searchText,
        filteredDataSource: filteredDataSource,
        open: true,
        openMenuItems: _this.openMenuItemKeysFromFilteredDataSource(filteredDataSource),
        anchorEl: _reactDom2.default.findDOMNode(_this.refs.searchTextField)
      }, function () {
        _this.props.onUpdateInput(searchText, normalizedDataSource);
      });
    };

    _this._onFocus_menu = function (event) {
      _this.setState({
        open: true,
        focusTextField: false,
        anchorEl: _reactDom2.default.findDOMNode(_this.refs.searchTextField)
      });
    };

    _this._onBlur_fromTextFieldBlur = function (event) {

      if (_this.props.onBlur) {
        _this.props.onBlur(event);
      }
    };

    _this.handleFocus = function (event) {
      if (!_this.state.open && _this.props.openOnFocus) {
        _this.setState({
          anchorEl: _reactDom2.default.findDOMNode(_this.refs.searchTextField),
          open: true
        });
        _this.props.onMenuOpen && _this.props.onMenuOpen();
      }

      _this.setState({
        focusTextField: true
      });

      if (_this.props.onFocus) {
        _this.props.onFocus(event);
      }
    };

    var initialNormalizedDataSource = _this.normalizedDataSource(_this.props.dataSource || []);
    _this.state = {
      anchorEl: null,
      focusTextField: true,
      searchText: undefined,
      openMenuItems: [],
      normalizedDataSource: initialNormalizedDataSource,
      filteredDataSource: initialNormalizedDataSource
    };
    return _this;
  }

  _createClass(AutoComplete, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var normalizedDataSource = this.normalizedDataSource(this.props.dataSource || []);

      var filteredDataSource = this.filterDataSourceUsingSearchText(normalizedDataSource, this.props.searchText);

      this.setState({
        open: this.props.open,
        searchText: this.props.searchText,
        normalizedDataSource: normalizedDataSource,
        filteredDataSource: filteredDataSource,
        openMenuItems: this.openMenuItemKeysFromFilteredDataSource(filteredDataSource)
      });
      this.timerTouchTapCloseId = null;
    }
  }, {
    key: 'requestsByReactKeyFromDataSourceList',
    value: function requestsByReactKeyFromDataSourceList() {
      var _this2 = this;

      var list = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      return _lodash2.default.reduce(list, function (acc, item) {
        if (item == undefined) {
          //# TODO:
          //# defensively prevent this for now... need to figure out what the problem is
          //# NOTE: This might be fixed in the version with content drafts so we should check
          console.warn("Encountered undefined children entries. Most likely from a Repeater. This issue is not consistent");
          return acc;
        }
        var _item$children = item.children,
            children = _item$children === undefined ? [] : _item$children,
            key = item.key,
            reactKey = item.reactKey;

        //# default reactKey to value

        return key && reactKey || !_lodash2.default.isEmpty(children) ? _extends(acc, _defineProperty({}, reactKey, item), !_lodash2.default.isEmpty(children) ? _this2.requestsByReactKeyFromDataSourceList(children) : {}) : acc;
      }, {});
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      var next_normalizedDataSource = this.normalizedDataSource(nextProps.dataSource || []);
      var prev_normalizedDataSource = this.state.normalizedDataSource;

      if (this.props.searchText !== nextProps.searchText || !_lodash2.default.isEmpty(_lodash2.default.xorBy(prev_normalizedDataSource, next_normalizedDataSource, 'reactKey'))) {

        var filteredDataSource = this.filterDataSourceUsingSearchText(next_normalizedDataSource, nextProps.searchText);

        this.setState({
          searchText: nextProps.searchText,
          normalizedDataSource: next_normalizedDataSource,
          filteredDataSource: filteredDataSource,
          openMenuItems: this.openMenuItemKeysFromFilteredDataSource(filteredDataSource)
        });
      }
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      this._mounted = true;
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      clearTimeout(this.timerTouchTapCloseId);
      this._mounted = false;
    }
  }, {
    key: 'close',
    value: function close() {
      if (!this._mounted) {
        return false;
      }
      this.setState({
        anchorEl: null,
        open: false,
        openMenuItems: []
      });
      this.props.onMenuClose && this.props.onMenuClose();
    }
  }, {
    key: 'handleMenuItemFocusStateChange',
    value: function handleMenuItemFocusStateChange(key, depth, nextFocusState, prevFocusState) {
      if (nextFocusState == 'none') {
        //# unset 
        console.log('handleMenuItemFocusStateChange (UNSET) for key:', key, nextFocusState, prevFocusState);
        //this.handleMenuItemMouseLeave(key, depth);
      } else {
        console.log('handleMenuItemFocusStateChange for key:', key, nextFocusState, prevFocusState);
        //this.handleMenuItemMouseEnter(key, depth);
      }
    }
  }, {
    key: 'handleMenuItemMouseEnter',
    value: function handleMenuItemMouseEnter(key, depth, ev) {
      this.setState(function (state, props) {
        var updatedOpenMenuItems = [].concat(state.openMenuItems);
        updatedOpenMenuItems[depth] = key;

        return {
          openMenuItems: updatedOpenMenuItems
        };
      });
    }
  }, {
    key: 'handleMenuItemMouseLeave',
    value: function handleMenuItemMouseLeave(key, depth, ev) {
      this.setState(function (state, props) {
        //# exclude all items under the menuItem that's being left
        var updatedOpenMenuItems = state.openMenuItems.slice(0, depth + 1);

        if (updatedOpenMenuItems[depth] == key) {
          //updatedOpenMenuItems[depth] = null;
          //# also unset the menuItem at depth if the key matches
          //# usually it won't match because we enter another menuItem
        }

        return {
          openMenuItems: updatedOpenMenuItems
        };
      });
    }
  }, {
    key: 'blur',
    value: function blur() {
      this.refs.searchTextField.blur();
    }
  }, {
    key: 'focus',
    value: function focus() {
      this.refs.searchTextField.focus();
    }
  }, {
    key: 'filterDataSourceUsingSearchText',
    value: function filterDataSourceUsingSearchText(dataSource, textToMatch) {
      var _this3 = this;

      var dataSourceConfig = this.props.dataSourceConfig;
      var filterFunction = this.props.filter ? this.props.filter : function () {
        return true;
      };

      return dataSource.reduce(function (acc, item) {
        if (item == undefined) {
          //# TODO:
          //# defensively prevent this for now... need to figure out what the problem is
          //# NOTE: This might be fixed in the version with content drafts so we should check
          console.warn("Encountered undefined children entries. Most likely from a Repeater. This issue is not consistent");
          return acc;
        }
        var _item$children2 = item.children,
            children = _item$children2 === undefined ? [] : _item$children2,
            key = item.key;

        var itemText = item[dataSourceConfig.text];

        var itemMatchesText = filterFunction(textToMatch, itemText, item);
        var childrenMatchingText = _this3.filterDataSourceUsingSearchText(children, textToMatch);

        return itemMatchesText ? acc.concat(item) : !_lodash2.default.isEmpty(childrenMatchingText) ? acc.concat(_extends({}, item, { children: childrenMatchingText })) : acc;
      }, []);
    }
  }, {
    key: 'normalizedDataSource',
    value: function normalizedDataSource() {
      var _this4 = this;

      var dataSource = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      var normalizedDataSource = dataSource.map(function (item, index) {
        var _ref;

        switch (typeof item === 'undefined' ? 'undefined' : _typeof(item)) {
          case 'string':
            var reactKey = index + '.' + item;
            return _ref = {
              key: reactKey,
              reactKey: reactKey
            }, _defineProperty(_ref, _this4.props.dataSourceConfig.text, item), _defineProperty(_ref, _this4.props.dataSourceConfig.value, item), _ref;
            break;
          case 'object':
            if (item && typeof item[_this4.props.dataSourceConfig.text] === 'string') {
              var itemValue = item[_this4.props.dataSourceConfig.value];

              var _item$children3 = item.children,
                  children = _item$children3 === undefined ? [] : _item$children3,
                  _item$reactKey = item.reactKey,
                  _reactKey = _item$reactKey === undefined ? index + '.' + itemValue : _item$reactKey;

              return _extends({ reactKey: _reactKey, key: _reactKey }, item, !_lodash2.default.isEmpty(children) ? {
                children: _this4.normalizedDataSource(children)
              } : {});
            }
            break;
          default:
          // Do nothing
        }
      });

      return normalizedDataSource;
    }
  }, {
    key: 'prepDataSourceForRender',
    value: function prepDataSourceForRender() {
      var _this5 = this;

      var dataSource = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var _props = this.props,
          filter = _props.filter,
          disableFocusRipple = _props.disableFocusRipple,
          maxSearchResults = _props.maxSearchResults;
      var searchText = this.state.searchText;

      var requestsList = [];
      var styles = getStyles(this.props, this.context, this.state);

      //# the assumption is that the dataSource has been normalized
      //# and filtered
      dataSource.every(function (item, index) {
        if (item && typeof item[_this5.props.dataSourceConfig.text] === 'string') {
          var itemText = item[_this5.props.dataSourceConfig.text];
          var _item$children4 = item.children,
              children = _item$children4 === undefined ? [] : _item$children4,
              reactKey = item.reactKey,
              _item$menuItemProps = item.menuItemProps,
              menuItemProps = _item$menuItemProps === undefined ? {} : _item$menuItemProps;


          requestsList.push({
            text: itemText,
            menuItemProps: _extends({
              primaryText: itemText
            }, menuItemProps, { disableFocusRipple: disableFocusRipple }, !menuItemProps.innerDivStyle ? { innerDivStyle: styles.innerDiv } : {}),
            menuItems: !_lodash2.default.isEmpty(children) ? _this5.prepDataSourceForRender(children) : [],
            reactKey: reactKey
          });
        }

        return !(maxSearchResults && maxSearchResults > 0 && requestsList.length === maxSearchResults);
      });

      return requestsList;
    }
  }, {
    key: 'openMenuItemKeysFromFilteredDataSource',
    value: function openMenuItemKeysFromFilteredDataSource() {
      var _this6 = this;

      var filteredDataSource = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      return filteredDataSource.reduce(function (acc, item, idx) {
        var reactKey = item.reactKey,
            _item$children5 = item.children,
            filteredChildren = _item$children5 === undefined ? [] : _item$children5;

        return idx === 0 ? acc.concat(reactKey, _this6.openMenuItemKeysFromFilteredDataSource(filteredChildren)) : acc;
      }, []);
    }
  }, {
    key: 'render',
    value: function render() {
      var _extends3,
          _this7 = this,
          _extends4,
          _React$createElement;

      var _props2 = this.props,
          anchorOrigin = _props2.anchorOrigin,
          animated = _props2.animated,
          animation = _props2.animation,
          canAutoPosition = _props2.canAutoPosition,
          disableFocusRipple = _props2.disableFocusRipple,
          dataSource = _props2.dataSource,
          dataSourceConfig = _props2.dataSourceConfig,
          errorText = _props2.errorText,
          errorStyle = _props2.errorStyle,
          floatingLabelText = _props2.floatingLabelText,
          filter = _props2.filter,
          fullWidth = _props2.fullWidth,
          hintText = _props2.hintText,
          id = _props2.id,
          listStyle = _props2.listStyle,
          maxSearchResults = _props2.maxSearchResults,
          menuCloseDelay = _props2.menuCloseDelay,
          menuStyle = _props2.menuStyle,
          menuProps = _props2.menuProps,
          onBlur = _props2.onBlur,
          onFocus = _props2.onFocus,
          onMenuClose = _props2.onMenuClose,
          onMenuOpen = _props2.onMenuOpen,
          onNewRequest = _props2.onNewRequest,
          onUpdateInput = _props2.onUpdateInput,
          openOnFocus = _props2.openOnFocus,
          popoverProps = _props2.popoverProps,
          searchTextProp = _props2.searchText,
          style = _props2.style,
          targetOrigin = _props2.targetOrigin,
          textFieldStyle = _props2.textFieldStyle,
          other = _objectWithoutProperties(_props2, ['anchorOrigin', 'animated', 'animation', 'canAutoPosition', 'disableFocusRipple', 'dataSource', 'dataSourceConfig', 'errorText', 'errorStyle', 'floatingLabelText', 'filter', 'fullWidth', 'hintText', 'id', 'listStyle', 'maxSearchResults', 'menuCloseDelay', 'menuStyle', 'menuProps', 'onBlur', 'onFocus', 'onMenuClose', 'onMenuOpen', 'onNewRequest', 'onUpdateInput', 'openOnFocus', 'popoverProps', 'searchText', 'style', 'targetOrigin', 'textFieldStyle']);

      var _state = this.state,
          anchorEl = _state.anchorEl,
          focusTextField = _state.focusTextField,
          open = _state.open,
          openMenuItems = _state.openMenuItems,
          searchText = _state.searchText;
      var prepareStyles = this.context.muiTheme.prepareStyles;

      var styles = getStyles(this.props, this.context, this.state);

      var filteredDataSource = this.state.filteredDataSource;
      var menuItems = this.prepDataSourceForRender(filteredDataSource);

      return _react2.default.createElement(
        'div',
        (_React$createElement = { style: prepareStyles(_extends(styles.root, style)), 'data-codecept-selector-node': 'div',
          'data-codecept-selector-file': 'AutoComplete'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'AutoComplete'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'AutoComplete'), _React$createElement),
        _react2.default.createElement(_TextField2.default, _extends({
          ref: 'searchTextField',
          autoComplete: 'off',
          onChange: this.handleChange,
          onBlur: this._onBlur_fromTextFieldBlur,
          onFocus: this.handleFocus,
          onKeyDown: this.handleKeyDown,
          floatingLabelText: floatingLabelText,
          hintText: hintText,
          id: id,
          fullWidth: fullWidth,
          multiLine: false,
          errorText: errorText,
          errorStyle: errorStyle,
          style: textFieldStyle
        }, other, (_extends3 = {
          value: searchText,
          'data-codecept-selector-node': 'TextField',
          'data-codecept-selector-file': 'AutoComplete'
        }, _defineProperty(_extends3, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends3, 'data-codecept-selector-file', 'AutoComplete'), _defineProperty(_extends3, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends3, 'data-codecept-selector-file', 'AutoComplete'), _extends3))),
        _react2.default.createElement(_AutoCompleteMenu2.default, _extends({
          animated: animated,
          animation: animation,
          anchorOrigin: anchorOrigin,
          anchorEl: anchorEl,
          canAutoPosition: canAutoPosition,
          disableAutoFocus: focusTextField,
          initiallyKeyboardFocused: !focusTextField,
          listStyle: _extends({}, styles.list, listStyle),
          menuItems: menuItems,
          menuProps: _extends({}, menuProps, { style: menuStyle }),
          onEscKeyDown: this._onEscKeyDown_fromMenu,
          onItemTouchTap: this.handleItemTouchTap,
          onMenuItemMouseEnter: this.handleMenuItemMouseEnter.bind(this),
          onMenuItemMouseLeave: this.handleMenuItemMouseLeave.bind(this),
          onMenuItemFocusStateChange: this.handleMenuItemFocusStateChange.bind(this),
          open: open,
          openMenuItems: openMenuItems,
          onRequestClose: this.handleRequestClose,
          popoverProps: _extends({}, popoverProps, { style: styles.popover }),
          targetOrigin: targetOrigin,
          useLayerForClickAway: false,
          onMouseEnter: function onMouseEnter() {
            _this7.setState({
              menuFocused: true
            });
          },
          onMouseLeave: function onMouseLeave() {
            _this7.setState({
              menuFocused: false
            });
          }
        }, this.props.AutoCompleteMenu ? _lodash2.default.pick(this.props.AutoCompleteMenu, AutoCompleteMenu__propTypes__keys) : {}, (_extends4 = {
          'data-codecept-selector-node': 'AutoCompleteMenu',
          'data-codecept-selector-file': 'AutoComplete'
        }, _defineProperty(_extends4, 'data-codecept-selector-node', 'AutoCompleteMenu'), _defineProperty(_extends4, 'data-codecept-selector-file', 'AutoComplete'), _defineProperty(_extends4, 'data-codecept-selector-node', 'AutoCompleteMenu'), _defineProperty(_extends4, 'data-codecept-selector-file', 'AutoComplete'), _extends4)))
      );
    }
  }]);

  return AutoComplete;
}(_react.Component), _class.propTypes = _extends({}, TextField__propTypes, {
  /**
   * AutoComplete prop overrides
   */
  AutoCompleteMenu: _propTypes2.default.object,
  AutoComplete: _propTypes2.default.object,
  /**
   * Location of the anchor for the auto complete.
   */
  anchorOrigin: _propTypes2.default.origin,
  /**
   * If true, the auto complete is animated as it is toggled.
   */
  animated: _react.PropTypes.bool,
  /**
   * If true, the auto complete repositions to fit in the screen.
   */
  canAutoPosition: _react.PropTypes.bool,
  /**
   * Override the default animation component used.
   */
  animation: _react.PropTypes.func,
  /**
   * Array of strings or nodes used to populate the list.
   */
  dataSource: _react.PropTypes.array.isRequired,
  /**
   * Config for objects list dataSource.
   *
   * @typedef {Object} dataSourceConfig
   *
   * @property {string} text `dataSource` element key used to find a string to be matched for search
   * and shown as a `TextField` input value after choosing the result.
   * @property {string} value `dataSource` element key used to find a string to be shown in search results.
   */
  dataSourceConfig: _react.PropTypes.object,
  /**
   * Disables focus ripple when true.
   */
  disableFocusRipple: _react.PropTypes.bool,
  /**
   * Override style prop for error.
   */
  errorStyle: _react.PropTypes.object,
  /**
   * The error content to display.
   */
  errorText: _react.PropTypes.node,
  /**
   * Callback function used to filter the auto complete.
   *
   * @param {string} searchText The text to search for within `dataSource`.
   * @param {string} key `dataSource` element, or `text` property on that element if it's not a string.
   * @returns {boolean} `true` indicates the auto complete list will include `key` when the input is `searchText`.
   */
  filter: _react.PropTypes.func,
  /**
   * The content to use for adding floating label element.
   */
  floatingLabelText: _react.PropTypes.node,
  /**
   * If true, the field receives the property `width: 100%`.
   */
  fullWidth: _react.PropTypes.bool,
  /**
   * The hint content to display.
   */
  hintText: _react.PropTypes.node,
  /**
   * Override style for list.
   */
  listStyle: _react.PropTypes.object,
  /**
   * The max number of search results to be shown.
   * By default it shows all the items which matches filter.
   */
  maxSearchResults: _react.PropTypes.number,
  /**
   * Delay for closing time of the menu.
   */
  menuCloseDelay: _react.PropTypes.number,
  /**
   * Props to be passed to menu.
   */
  menuProps: _react.PropTypes.object,
  /**
   * Override style for menu.
   */
  menuStyle: _react.PropTypes.object,
  /** @ignore */
  onBlur: _react.PropTypes.func,
  /** @ignore */
  onFocus: _react.PropTypes.func,
  /** @ignore */
  onKeyDown: _react.PropTypes.func,
  /**
   * Callback function that is fired when a list item is selected, or enter is pressed in the `TextField`.
   *
   * @param {string} chosenRequest Either the `TextField` input value, if enter is pressed in the `TextField`,
   * or the text value of the corresponding list item that was selected.
   * @param {number} index The index in `dataSource` of the list item selected, or `-1` if enter is pressed in the
   * `TextField`.
   */
  onNewRequest: _react.PropTypes.func,
  /**
   * Callback function that is fired when the user updates the `TextField`.
   *
   * @param {string} searchText The auto-complete's `searchText` value.
   * @param {array} dataSource The auto-complete's `dataSource` array.
   */
  onUpdateInput: _react.PropTypes.func,
  /**
   * Auto complete menu is open if true.
   */
  open: _react.PropTypes.bool,
  /**
   * If true, the list item is showed when a focus event triggers.
   */
  openOnFocus: _react.PropTypes.bool,
  /**
   * Props to be passed to popover.
   */
  popoverProps: _react.PropTypes.object,
  /**
   * Text being input to auto complete.
   */
  searchText: _react.PropTypes.string,
  /**
   * Override the inline-styles of the root element.
   */
  style: _react.PropTypes.object,
  /**
   * Override all getStyles() 
   */
  styles: _react.PropTypes.object,
  /**
   * Origin for location of target.
   */
  targetOrigin: _propTypes2.default.origin,
  /**
   * Override the inline-styles of AutoComplete's TextField element.
   */
  textFieldStyle: _react.PropTypes.object
}), _class.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  animated: true,
  canAutoPosition: true,
  dataSourceConfig: {
    text: 'text',
    value: 'value'
  },
  disableFocusRipple: true,
  filter: function filter(searchText, key) {
    return searchText !== '' && key.indexOf(searchText) !== -1;
  },
  fullWidth: false,
  open: false,
  openOnFocus: false,
  onUpdateInput: function onUpdateInput() {},
  onNewRequest: function onNewRequest() {},
  searchText: '',
  menuCloseDelay: 300,
  targetOrigin: {
    vertical: 'top',
    horizontal: 'left'
  }
}, _class.contextTypes = {
  muiTheme: _react.PropTypes.object.isRequired
}, _temp);


AutoComplete.levenshteinDistance = function (searchText, key) {
  var current = [];
  var prev = void 0;
  var value = void 0;

  for (var i = 0; i <= key.length; i++) {
    for (var j = 0; j <= searchText.length; j++) {
      if (i && j) {
        if (searchText.charAt(j - 1) === key.charAt(i - 1)) value = prev;else value = Math.min(current[j], current[j - 1], prev) + 1;
      } else {
        value = i + j;
      }
      prev = current[j];
      current[j] = value;
    }
  }
  return current.pop();
};

AutoComplete.noFilter = function () {
  return true;
};

AutoComplete.defaultFilter = AutoComplete.caseSensitiveFilter = function (searchText, key) {
  return searchText !== '' && key.indexOf(searchText) !== -1;
};

AutoComplete.caseInsensitiveFilter = function (searchText, key) {
  return key.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
};

AutoComplete.levenshteinDistanceFilter = function (distanceLessThan) {
  if (distanceLessThan === undefined) {
    return AutoComplete.levenshteinDistance;
  } else if (typeof distanceLessThan !== 'number') {
    throw 'Error: AutoComplete.levenshteinDistanceFilter is a filter generator, not a filter!';
  }

  return function (s, k) {
    return AutoComplete.levenshteinDistance(s, k) < distanceLessThan;
  };
};

AutoComplete.fuzzyFilter = function (searchText, key) {
  var compareString = key.toLowerCase();
  searchText = searchText.toLowerCase();

  var searchTextIndex = 0;
  for (var index = 0; index < key.length; index++) {
    if (compareString[index] === searchText[searchTextIndex]) {
      searchTextIndex += 1;
    }
  }

  return searchTextIndex === searchText.length;
};

AutoComplete.Item = _MenuItem2.default;
AutoComplete.Divider = _Divider2.default;

exports.default = AutoComplete;