'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _reduxBatchedActions = require('redux-batched-actions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var thunkMiddleware = require('redux-thunk');

var _require = require('redux'),
    createStore = _require.createStore,
    combineReducers = _require.combineReducers,
    applyMiddleware = _require.applyMiddleware;

var CACHE_CLEAR_TIMEOUT = 500;
var reduxHelperMixin = {
    mixinName: 'rubyComponentMixinReduxHelper'
    //# NOTE: cachedHelperReduxStore probably shouldn't be used
    //# we want to inject the helperReduxStore instance into the cloned nodes instead
    , cachedHelperReduxStore: function cachedHelperReduxStore(options) {
        if (this._helperReduxStore) {
            return this._helperReduxStore;
        }
        return this._helperReduxStore = this.helperReduxStore(options);
    },
    helperReduxStore: function helperReduxStore(options) {
        var _this = this;

        var selfComponentReducers = this.getReducerByKey(options);

        var middlewares = [thunkMiddleware];
        var createStoreWithMiddleware = applyMiddleware.apply(null, middlewares)(createStore);

        var reduxStore = createStoreWithMiddleware((0, _reduxBatchedActions.enableBatching)(combineReducers(selfComponentReducers)));

        var selfID = this.getID();
        var getEntireState = function getEntireState() {
            var entireState = void 0;
            //# caching entireState to speed up helper methods
            //# we don't really care too much about the entire state, we just need one instace
            //# to provide the expected redux state to the rest of our helper children
            //# since we need the redux state to mimic the real data shape
            if (_this._cachedEntireState) {
                entireState = _this._cachedEntireState;
            } else {
                //console.time('entireStateClone:'+selfID);
                _this._cachedEntireState = _lodash2.default.cloneDeep(_this.getStore().getState());
                //console.timeEnd('entireStateClone:'+selfID);
                entireState = _this._cachedEntireState;
            }

            clearTimeout(_this._cachedEntireState_clearTimeoutId);
            _this._cachedEntireState_clearTimeoutId = setTimeout(function () {
                _this._cachedEntireState;
            }, CACHE_CLEAR_TIMEOUT);

            return entireState;
        };
        var patchedReduxStore = _extends({}, reduxStore, {
            getState: function getState() {
                var entireState = getEntireState();

                _lodash2.default.set(entireState, _this.getKeypathArr(), reduxStore.getState()[selfID]);
                return entireState;
            },
            getLocalState: reduxStore.getState
        });

        return patchedReduxStore;
    },
    cachedHelperSelf: function cachedHelperSelf() {
        if (this._helperSelf) {
            return this._helperSelf;
        }
        return this._helperSelf = this.helperSelf();
    },
    helperSelf: function helperSelf() {
        var helperReduxStore = void 0;
        var getStore = function getStore() {
            return helperReduxStore;
        };

        //# need to deeply copy children
        var helperSelf = this.cloneSelf(undefined, { getStore: getStore }); //depthFirstCloneNode(this, undefined, {getStore});

        //# create reduxStore with helper children
        helperReduxStore = this.helperReduxStore({ children: helperSelf.getChildren() });

        return helperSelf;
    },
    newActionCollectorSpec: function newActionCollectorSpec() {
        var collectedActions = [];

        var collectAction = function collectAction(value) {
            collectedActions.push(value);
        };

        return {
            collectAction: collectAction,
            collectedActions: collectedActions
        };
    }
};

module.exports = reduxHelperMixin;