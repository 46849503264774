'use strict';

var _constants = require('./constants');

var superagent = require('superagent');
var superagentCache = require('superagent-cache');
var superagentCache__utils = require('superagent-cache/utils');
var queryString = require('query-string');
var _ = require('lodash');
var Promise = require('bluebird');

var AgentBase = require('./agent-base');
var methods = require('methods');


superagentCache(superagent, null, {
    preventDuplicateCalls: true,
    forceUpdate: true
    //# NOTE: we don't want to cache anything because right now there are a lot of modules that uses
    //# superagent, where there's an expectation that it's not cached
    //# so if we want to actually cache responses, we'll need to 
    //# update the consumers to explicitly call on .forceUpdate(true) for the calls that we don't want cached
    //, expiration: 30 
});

//# patch abort to clear out superagent-cache's pendingCache
var parentAbort = superagent.Request.prototype.abort;
superagent.Request.prototype.abort = function () {
    //const key = superagentCache__utils.keygen(superagent, this, superagent.defaults);

    //console.log('aborting:', key);
    //delete superagent.pendingRequests[key];

    //# unfortunately the key generated doesn't match the pendingRequests key, we'll just have to wipe out everything

    superagent.pendingRequests = {};

    return parentAbort.apply(this, arguments);
};

//# Monkey patch superagent-cache's method of parsing query parameters
//# Its arrayToObj and stringToObj methods roll their own query string parsing
//# that does not handle multiple values for the same parameter key.
//# Instead of parsing this query parameter as an array with multiple values, it parses it as a single value
//# for the duplicated parameter key, whichever value for that key appears last in the string.
//#
//# This causes `getQueryParams` to calculate incorrect query parameters and
//# therefore generate incorrect cache keys that may cause false-positive cache collisions.
superagentCache__utils.getQueryParams = function (req) {
    if (req && req.qs && !superagentCache__utils.isEmpty(req.qs)) {
        return req.qs;
    } else if (req && req.qsRaw) {
        return arrayToObj(req.qsRaw);
    } else if (req && req.req) {
        return stringToObj(req.req.path);
    } else if (req && req._query) {
        return stringToObj(req._query.join('&'));
    }
    return null;

    function arrayToObj(arr) {
        if (!arr || !arr.length) {
            return null;
        }

        var str = arr.join('&');
        return stringToObj(str);
    }

    function stringToObj(str) {
        if (!str) {
            return null;
        }

        return queryString.parse(str);
    }
};

// reference: https://github.com/ladjs/superagent/blob/master/src/node/agent.js
function Agent(options) {
    if (!(this instanceof Agent)) {
        return new Agent(options);
    }

    AgentBase.call(this);

    if (options) {
        if (options.ca) {
            this.ca(options.ca);
        }

        if (options.key) {
            this.key(options.key);
        }

        if (options.pfx) {
            this.pfx(options.pfx);
        }

        if (options.cert) {
            this.cert(options.cert);
        }

        if (options.rejectUnauthorized === false) {
            this.disableTLSCerts();
        }
    }

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
        for (var _iterator = methods[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var name = _step.value;
            this[name] = this[name].bind(this);
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally {
        try {
            if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
            }
        } finally {
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }

    ;
}

Agent.prototype = Object.create(AgentBase.prototype);

var _iteratorNormalCompletion2 = true;
var _didIteratorError2 = false;
var _iteratorError2 = undefined;

try {
    var _loop = function _loop() {
        var name = _step2.value;

        var methodName = name.toUpperCase();
        Agent.prototype[name] = function (url, fn) {
            var request_ = new superagent.Request(methodName, url);
            this._setDefaults(request_);
            if (fn) {
                request_.end(fn);
            }

            return request_;
        };
    };

    for (var _iterator2 = methods[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        _loop();
    }
} catch (err) {
    _didIteratorError2 = true;
    _iteratorError2 = err;
} finally {
    try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
        }
    } finally {
        if (_didIteratorError2) {
            throw _iteratorError2;
        }
    }
}

Agent.prototype.del = Agent.prototype.delete;

var isOnline = false;

// check if the window object is available 
if (!(typeof window === 'undefined')) {
    window.addEventListener("load", function () {
        isOnline = navigator.onLine ? true : false;
    });

    window.addEventListener("offline", function () {
        isOnline = false;
    });

    window.addEventListener("online", function () {
        isOnline = true;
    });
} else {
    isOnline = true;
}

function superagentFactory() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var retryOptions = options.retryOptions;


    var newSuperagent = Agent();

    if (_.isNil(retryOptions)) {
        return newSuperagent;
    } else {
        newSuperagent.get = new Proxy(newSuperagent.get, {
            apply: function apply(target, thisArg, argumentsList) {
                var requestGetObj = Reflect.apply(target, thisArg, argumentsList);
                // debugger;

                requestGetObj._retry = new Proxy(requestGetObj._retry, {
                    apply: function apply(target, thisArg, argumentsList) {
                        var _retryOptions$readine = retryOptions.readinessProbe,
                            readinessProbe = _retryOptions$readine === undefined ? _constants.READINESS_PROBE : _retryOptions$readine,
                            _retryOptions$delay = retryOptions.delay,
                            delay = _retryOptions$delay === undefined ? _constants.RETRY_DELAY : _retryOptions$delay;

                        // assume readinessProbe is fully qualified 

                        function probeReadiness() {
                            // debugger;
                            if (!isOnline) {
                                return Promise.delay(delay).then(function () {
                                    return probeReadiness();
                                });
                            } else {
                                return superagent.options(readinessProbe).then(function (res) {
                                    // debugger;
                                    return superagent.get(readinessProbe).then(function (res) {
                                        // debugger;
                                        return Promise.resolve();
                                    }).catch(function (err) {
                                        // debugger;
                                        if (err.status === 405 && err.name === 'NotReadyError') {
                                            return Promise.delay(delay).then(function () {
                                                return probeReadiness();
                                            });
                                        } else {
                                            return Promise.resolve();
                                        }
                                    });
                                }).catch(function (err) {
                                    // debugger;
                                    if (err.message && err.message.indexOf("the network is offline") >= 0) {
                                        // debugger;
                                        return Promise.delay(delay).then(function () {
                                            return probeReadiness();
                                        });
                                    } else {
                                        return Promise.resolve();
                                    }
                                });
                            }
                        }

                        return probeReadiness().then(function () {
                            return Reflect.apply(target, thisArg, argumentsList);
                        });
                    }
                });

                return requestGetObj.retry(_constants.MAX_RETRIES, function (err, res) {
                    // debugger;
                    // by default, superagent retries 429 but we don't want that
                    if (res && res.status && res.status === 429) return false;
                    // https://github.com/ladjs/superagent/blob/master/src/request-base.js#L191
                    // return undefined so it falls back to superagent defaults
                    return;
                }, retryOptions);
            }
        });

        return newSuperagent;
    }
};

module.exports = superagentFactory;