'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.errorColor = exports.categoryColors = exports.rubyBlue_light = exports.rubyBlue = exports.borderGrey = exports.black_50 = exports.black_40 = exports.black_25 = exports.headerGrey = undefined;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _colors = require('material-ui/styles/colors');

var Colors = _interopRequireWildcard(_colors);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var headerGrey = exports.headerGrey = 'rgb(232, 232, 232)';
var black_25 = exports.black_25 = 'rgba(0,0,0,0.25)';
var black_40 = exports.black_40 = 'rgba(0,0,0,0.40)';
var black_50 = exports.black_50 = 'rgba(0,0,0,0.50)';
var borderGrey = exports.borderGrey = 'rgba(0,0,0,0.2)';

var rubyBlue = '#607D8B'; //'#005b95'; //# Primary Color
var rubyBlue_light = '#CFD8DC'; //ColorManipulator.lighten(rubyBlue, emphasisCoefficient); //# light Primary Color

exports.rubyBlue = rubyBlue;
exports.rubyBlue_light = rubyBlue_light;
var categoryColors = exports.categoryColors = _lodash2.default.reverse(['#f7fbff', '#deebf7', '#c6dbef', '#9ecae1', '#6baed6', '#4292c6', '#2171b5', '#08519c', '#08306b']);

var errorColor = exports.errorColor = Colors.red500;