'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _ = require('lodash');


var PluginTemplateEditor__CONSTANTS = require('@rubyapps/ruby-component-plugin-template-editor/src/common/constants');

var STRUCTURED_DATA_TEMPLATE = PluginTemplateEditor__CONSTANTS.STRUCTURED_DATA_TEMPLATE,
    LOOKUP_TEMPLATE = PluginTemplateEditor__CONSTANTS.LOOKUP_TEMPLATE;


var listerConfigMixin = {
    augmentedListerConfig: function augmentedListerConfig(listerConfig, key, rubyComponent) {
        var augmentedListerConfig = augmentListerConfigWithLastModified.apply(this, arguments);

        return augmentedListerConfig;
    }
};

module.exports = listerConfigMixin;

function augmentListerConfigWithLastModified(listerConfig, key, rubyComponent, ctx) {
    if (!(rubyComponent.componentName == 'rubyComponentFieldSitemap' || rubyComponent.componentName == 'rubyComponentStructuredDataListPage')) {

        return listerConfig;
    }

    var template = ctx.template;


    var templateType = _.get(template, 'templateType');

    if (!_.includes([STRUCTURED_DATA_TEMPLATE, LOOKUP_TEMPLATE], templateType)) {
        return listerConfig;
    }

    var listerConfig__columns = listerConfig.columns;

    var existingColumnIndex = _.findIndex(listerConfig__columns, ['key', 'last_modified_timestamp']);

    if (existingColumnIndex >= 0) {
        //# already exists, we don't need to do anything
        return listerConfig;
    } else {
        var isLookupTemplate = templateType == LOOKUP_TEMPLATE;

        var lastModifiedColumn = {
            key: 'last_modified_timestamp',
            sortKeys: ['status', 'last_modified_timestamp'],
            sortDirsFromDir_andKeys: function sortDirsFromDir_andKeys(dir, keys) {
                return [dir, 'desc'];
            },
            type: 'publishAndDate',
            displayName: isLookupTemplate ? 'Last Modified' : 'Published / Last Modified',
            sortable: true,
            width: 180,
            resizable: false,
            alwaysShow: true,
            componentParams: {
                hideToggle: isLookupTemplate
            }
        };

        return _extends({}, listerConfig, {
            columns: [].concat(listerConfig.columns, lastModifiedColumn)
        });
    }
}