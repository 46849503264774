'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var defaultChildrenPropsByKeyFromProps = function defaultChildrenPropsByKeyFromProps(props) {
    var mode = props.mode,
        disabled = props.disabled,
        locked = props.locked;


    return {
        person_prefix_fk: {
            label: 'Prefix',
            key: 'person_prefix_fk',
            data_type: 'number'
            //, verify: {required: false}
            , ruby_permissions: props.ruby_permissions

            // TODO: Do not hard code client/subsite
            , url: '/ruby/api/v2/content/person_prefix/options?ruby_client_fk=3&content_subsite_fk=1',
            mode: mode,
            disabled: disabled,
            locked: locked,
            componentName: 'Dropdown'
        },
        first_name: {
            label: 'First Name',
            key: 'first_name',
            data_type: 'string',
            ruby_permissions: props.ruby_permissions
            //, verify: {required: true}
            , mode: mode,
            disabled: disabled,
            locked: locked,
            componentName: 'Text'
        },
        middle_name: {
            label: 'Middle Name',
            key: 'middle_name',
            data_type: 'string',
            ruby_permissions: props.ruby_permissions
            //, verify: {required: false}
            , mode: mode,
            disabled: disabled,
            locked: locked,
            componentName: 'Text'
        },
        last_name: {
            label: 'Last Name',
            key: 'last_name',
            data_type: 'string',
            ruby_permissions: props.ruby_permissions
            //, verify: {required: true}
            , mode: mode,
            disabled: disabled,
            locked: locked,
            componentName: 'Text'
        },
        person_suffix_fk: {
            label: 'Suffix',
            key: 'person_suffix_fk',
            data_type: 'number',
            ruby_permissions: props.ruby_permissions

            // TODO: Do not hard code client/subsite
            , url: '/ruby/api/v2/content/person_suffix/options?ruby_client_fk=3&content_subsite_fk=1',
            mode: mode,
            disabled: disabled,
            locked: locked,
            componentName: 'Dropdown'
        },
        asset_fk: {
            label: 'Image',
            componentName: "ImagePicker",
            key: 'asset_fk',
            data_type: 'number',
            ruby_permissions: props.ruby_permissions,
            constraints: {
                type: 'image'
            },
            mode: mode,
            disabled: disabled,
            locked: locked
        }
    };
};

//# take the opportunity here to set whether children values are required
var childrenFormFromProps = function childrenFormFromProps(_ref) {
    var childrenPropsByKey = _ref.childrenPropsByKey,
        _ref$verify = _ref.verify,
        verify = _ref$verify === undefined ? {} : _ref$verify;
    var person_prefix_fk = childrenPropsByKey.person_prefix_fk,
        first_name = childrenPropsByKey.first_name,
        middle_name = childrenPropsByKey.middle_name,
        last_name = childrenPropsByKey.last_name,
        person_suffix_fk = childrenPropsByKey.person_suffix_fk,
        asset_fk = childrenPropsByKey.asset_fk;
    var required = verify.required;


    return [_extends({
        componentName: "Dropdown"
    }, person_prefix_fk), _extends({
        componentName: "Text",
        verify: {
            required: required
        }
    }, first_name), _extends({
        componentName: "Text"
    }, middle_name), _extends({
        componentName: "Text",
        verify: {
            required: required
        }
    }, last_name), _extends({
        componentName: "Dropdown"
    }, person_suffix_fk), _extends({
        componentName: "ImagePicker"
    }, asset_fk)];
};

module.exports = {
    defaultChildrenPropsByKeyFromProps: defaultChildrenPropsByKeyFromProps,
    childrenFormFromProps: childrenFormFromProps
};