'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Dialog = require('material-ui/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _AppBar = require('material-ui/AppBar');

var _AppBar2 = _interopRequireDefault(_AppBar);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _close = require('material-ui/svg-icons/navigation/close');

var _close2 = _interopRequireDefault(_close);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;
var ReactDOM = require('react-dom');

var Dialog__propTypeKeys = Object.keys(_Dialog2.default.propTypes);

var ResizeMixin = require('@rubyapps/ruby-component-field-dialog/src/client/reactComponents/mixin-resize');

var styles = process.browser ? require('./styles.cssModule').default : {};

var styleObject = {
    Dialog: {
        contentStyle: {
            width: '100%',
            maxWidth: 1216
        },
        bodyStyle: {
            padding: '0',
            overflowX: 'hidden',
            overflowY: 'auto'
        },
        style: {
            zIndex: 1499 //# one less than the default zIndex for Dialogs
        }
    }
};

var DialogHeader = _react2.default.createClass({
    displayName: 'DialogHeader',

    propTypes: {
        title: PropTypes.string,
        delegateRequestClose: PropTypes.func
    },
    getDefaultProps: function getDefaultProps() {
        return {
            title: 'Advanced Search' //# TODO: change to show label
        };
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4;

        return _react2.default.createElement(_AppBar2.default, (_React$createElement4 = {
            id: 'ListerHeader',
            title: _react2.default.createElement(
                'span',
                (_React$createElement = {
                    'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'ListerDialog'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ListerDialog'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ListerDialog'), _React$createElement),
                this.props.title
            ),
            iconElementLeft: _react2.default.createElement(
                _IconButton2.default,
                (_React$createElement3 = { onClick: this.props.delegateRequestClose, 'data-codecept-selector-node': 'IconButton',
                    'data-codecept-selector-file': 'ListerDialog'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ListerDialog'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ListerDialog'), _React$createElement3),
                _react2.default.createElement(_close2.default, (_React$createElement2 = {
                    'data-codecept-selector-node': 'NavigationCloseIcon',
                    'data-codecept-selector-file': 'ListerDialog'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'NavigationCloseIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ListerDialog'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'NavigationCloseIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ListerDialog'), _React$createElement2))
            ),
            'data-codecept-selector-node': 'AppBar',
            'data-codecept-selector-file': 'ListerDialog'
        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ListerDialog'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ListerDialog'), _React$createElement4));
    }
});
var DialogHeader__propTypeKeys = Object.keys(DialogHeader.propTypes);

//Dialog.positionDialog
var ListerDialog = _react2.default.createClass(_extends({}, ResizeMixin, {
    propTypes: _extends({
        delegateRequestClose: PropTypes.func,
        id: PropTypes.string,
        listerComponent: PropTypes.node,
        dialog_open: PropTypes.bool,
        value: PropTypes.string
    }, DialogHeader.propTypes),
    getDefaultProps: function getDefaultProps() {
        return {
            sourceKey: 'url',
            value: null

        };
    },
    getInitialState: function getInitialState() {
        return {
            lastLoadedTimestamp: null
        };
    },
    _renderLister: function _renderLister() {
        return this.props.listerComponent ? this.props.listerComponent : null;
    },
    render: function render() {
        var _extends2,
            _this = this,
            _React$createElement5,
            _extends3;

        var dialogHeaderProps = _lodash2.default.pick(this.props, DialogHeader__propTypeKeys);
        return _react2.default.createElement(
            _Dialog2.default,
            _extends({
                bodyClassName: styles.lister__dialogBody
            }, styleObject.Dialog, _lodash2.default.pick(this.props, Dialog__propTypeKeys), (_extends3 = {
                title: _react2.default.createElement(DialogHeader, _extends({}, dialogHeaderProps, (_extends2 = {
                    'data-codecept-selector-node': 'DialogHeader',
                    'data-codecept-selector-file': 'ListerDialog'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'DialogHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ListerDialog'), _defineProperty(_extends2, 'data-codecept-selector-node', 'DialogHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ListerDialog'), _extends2))),
                modal: true,
                open: this.props.dialog_open,
                onRequestClose: this.props.delegateRequestClose,
                'data-codecept-selector-node': 'Dialog',
                'data-codecept-selector-file': 'ListerDialog'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ListerDialog'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_extends3, 'data-codecept-selector-file', 'ListerDialog'), _extends3)),
            _react2.default.createElement(
                'div',
                (_React$createElement5 = {
                    ref: function ref(componentRef) {
                        if (!componentRef) {
                            return;
                        }
                        _this._bindElementResize(ReactDOM.findDOMNode(componentRef));
                    },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'ListerDialog'
                }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ListerDialog'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'ListerDialog'), _React$createElement5),
                this._renderLister()
            )
        );
    }

    //# == Utilities =============================================//
}));

exports.default = ListerDialog;