'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _transitions = require('material-ui/styles/transitions');

var _transitions2 = _interopRequireDefault(_transitions);

var _IconButton = require('material-ui/IconButton/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

//# Material UI

var IconButton__propTypeKeys = Object.keys(_IconButton2.default.propTypes).concat(['onClick', 'onTouchTap']);

var getStyles = function getStyles(props, context, state) {
    var errorColor = context.muiTheme.textField.errorColor;


    var styles = {
        error: _extends({}, {
            positior: 'relative',
            bottom: -6,
            fontSize: 12,
            lineHeight: '12px',
            color: errorColor,
            transition: _transitions2.default.easeOut()
        }, props.errorStyle),
        Icon: {
            color: iconColorFromProps(props),
            transition: _transitions2.default.easeOut()
        }
    };

    function iconColorFromProps(props) {
        var hasError = _.get(props, 'error.message');
        if (hasError) {
            return errorColor;
        } else if (props.isActive) {
            var darkThemeColor = 'rgb(96, 125, 139)';
            return darkThemeColor;
        } else {
            return 'rgba(0, 0, 0, 0.7)';
        }
    }

    return styles;
};

var IndicatingIconButton = _react2.default.createClass({
    displayName: 'IndicatingIconButton',

    propTypes: _extends({
        isActive: PropTypes.bool,
        error: PropTypes.object
    }, _IconButton2.default.propTypes),
    contextTypes: {
        muiTheme: PropTypes.object
    },
    render: function render() {
        var _extends2;

        var muiStyleObject = getStyles(this.props, this.context, this.state);

        var IconButtonProps = _.pick(this.props, IconButton__propTypeKeys);

        return _react2.default.createElement(
            _IconButton2.default,
            _extends({}, IconButtonProps, (_extends2 = {
                iconStyle: muiStyleObject.Icon,
                'data-codecept-selector-node': 'IconButton',
                'data-codecept-selector-file': 'IndicatingIconButton'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'IndicatingIconButton'), _defineProperty(_extends2, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'IndicatingIconButton'), _extends2)),
            this.props.children
        );
    }
});

exports.default = IndicatingIconButton;