'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

/* jshint -W138 */
var actionTypes = require('./action').TYPES;

//# NOTE: should not need to have a default state

var defaultState = {
    isOpen: false,
    title: 'Preview',
    pageName: '',
    previewPage: {},
    result: {},
    basis: {},
    diff: {},
    tableDiff: {},
    id: null,
    templateKey: null,
    editorName: null,
    showContentApprovalActions: false,
    showEditAction: false,
    selectedLanguage: 'en',
    isComparingMode: false //# true if we're comparing different versions
    , options: null //# cached options
    , isLoading: false
};

module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];

    switch (action.type) {
        case actionTypes.OPEN_CONTENT_COMPARE_MODAL:
            return _extends({}, state, {
                isOpen: true
            });
        case actionTypes.CLOSE_CONTENT_COMPARE_MODAL:
            return _extends({}, state, {
                isOpen: false,
                result: {},
                basis: {},
                diff: {},
                tableDiff: {},
                showContentApprovalActions: false,
                title: 'Preview' //# reset
            });
        case actionTypes.RESET_CONTENT_COMPARE_MODAL:
            return _extends({}, state, defaultState);
        case actionTypes.SET_COMPARE_DATA:
            var urls = action.payload.urls;
            var docs = action.payload.docs || {};
            var labels = action.payload.labels || {};;

            var compareSpec = {
                previewPage: {
                    url: urls.previewPage,
                    doc: docs.previewPage,
                    label: labels.previewPage || 'Preview Page'
                },
                result: {
                    url: urls.result,
                    doc: docs.result,
                    label: labels.result || 'New Page'
                },
                basis: {
                    url: urls.basis,
                    doc: docs.basis,
                    label: labels.basis || 'Existing Page'
                },
                diff: {
                    url: urls.diff,
                    doc: docs.diff,
                    label: labels.diff || 'Page Changes'
                },
                tableDiff: {
                    url: urls.tableDiff,
                    doc: docs.tableDiff,
                    label: labels.tableDiff || 'Data Changes'
                }
            };
            return _extends({}, state, compareSpec);
        case actionTypes.SET_CONFIG:
            return _extends({}, state, action.payload);
        case actionTypes.SET_IS_LOADING:
            return _extends({}, state, {
                isLoading: true
            });
        case actionTypes.CLEAR_IS_LOADING:
            return _extends({}, state, {
                isLoading: false
            });
        case actionTypes.CLEAR_URLS:
            return _extends({}, state, {
                previewPage: {},
                result: {},
                basis: {},
                diff: {},
                tableDiff: {}
            });
        case actionTypes.SET_SELECTED_LANGUAGE:
            return _extends({}, state, action.payload);

        case actionTypes.SHOW_CONTENT_APPROVAL_ACTIONS:
            return _extends({}, state, { showContentApprovalActions: true });
        default:
            return state;
    }
};