'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Paper = require('material-ui/Paper');

var _Paper2 = _interopRequireDefault(_Paper);

var _RaisedButton = require('@rubyapps/ruby-material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _ScriptjsLoader = require('react-google-maps/lib/async/ScriptjsLoader');

var _ScriptjsLoader2 = _interopRequireDefault(_ScriptjsLoader);

var _reactGoogleMaps = require('react-google-maps');

var _googleMaps = require('@rubyapps/ruby-assets/google-maps.svg');

var _googleMaps2 = _interopRequireDefault(_googleMaps);

var _fieldBase = require('@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule');

var _fieldBase2 = _interopRequireDefault(_fieldBase);

var _InternationalAddress = require('./InternationalAddress.cssModule');

var _InternationalAddress2 = _interopRequireDefault(_InternationalAddress);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var request = require('@rubyapps/ruby-superagent');

var styles = _extends({}, _fieldBase2.default, _InternationalAddress2.default);

var geoCodeFields = ['address_line_1', 'address_line_2', 'city', 'us_state', 'ca_state', 'other_state', 'country', 'postal_code'];

var countriesByExclusiveFields = {
    'us_state': ['US'],
    'ca_state': ['CA'],
    'gb_state': ['UK']
};
var excludedFieldsByCountry = {
    US: ['other_state'],
    CA: ['other_state'],
    UK: ['other_state']
};

function childrenBeforeStates(children) {
    return children.slice(0, 6);
}
function childrenForStates(children) {
    return children.slice(6, children.length - 1);
}
function childrenAfterStates(children) {
    return children.slice(children.length - 1);
}
var InternationalAddress = _react2.default.createClass({
    displayName: 'InternationalAddress',

    propTypes: {
        label: PropTypes.string,
        id: PropTypes.string,
        value: PropTypes.shape({
            latitude: PropTypes.string,
            longitude: PropTypes.string,
            address_line_1: PropTypes.string,
            address_line_2: PropTypes.string,
            city: PropTypes.string,
            country: PropTypes.string,
            us_state: PropTypes.string,
            ca_state: PropTypes.string,
            gb_state: PropTypes.string,
            other_state: PropTypes.string,
            postal_code: PropTypes.string
        }),
        delegateAction: PropTypes.func
    },
    handleMapClick: function handleMapClick(event) {
        if (this.props.locked) {
            return;
        }
        this.props.delegateActions.longitudeField.setFieldValue(event.latLng.lng().toString());
        this.props.delegateActions.latitudeField.setFieldValue(event.latLng.lat().toString());
    },
    _shouldShowField: function _shouldShowField(fieldKey) {
        var self = this;
        var countryCode = Array.isArray(self.currentValue.country) ? self.currentValue.country[0] : self.currentValue.country;
        var excludedFields = excludedFieldsByCountry[countryCode] || [];
        var countriesWithExclusiveField = countriesByExclusiveFields[fieldKey];
        if (countriesWithExclusiveField && countriesWithExclusiveField.indexOf(countryCode) > -1) {
            return true;
        } else if (countriesWithExclusiveField && countriesWithExclusiveField.indexOf(countryCode) == -1) {
            return false;
        } else if (excludedFields.indexOf(fieldKey) > -1) {
            return false;
        } else {
            return true;
        }
    },
    _geoCode: function _geoCode(currentValue) {
        var _this = this;

        if (!this.props.GOOGLE_API_KEY) {
            return;
        }

        var self = this;
        var url = 'https://maps.googleapis.com/maps/api/geocode/json';

        if (self.debouncedGeoCode != null) {
            self.debouncedGeoCode(currentValue);
        } else {

            self.debouncedGeoCode = _lodash2.default.debounce(function (currentValue) {
                var components = [{
                    key: 'locality',
                    value: currentValue.city || ''
                }, {
                    key: 'country',
                    value: currentValue.country || ''
                }, {
                    key: 'administrative_area',
                    value: ['us_state', 'ca_state', 'gb_state', 'other_state'].filter(function (field) {
                        return self._shouldShowField(field);
                    }).map(function (key) {
                        return currentValue[key];
                    })
                }, {
                    key: 'postal_code',
                    value: currentValue.postal_code
                }];

                var componentsArg = components.filter(function (component) {
                    return component.value != null && component.value != '';
                }).map(function (component) {
                    return component.key + ':' + component.value;
                }).join('|');

                var addressArr = ['address_line_1', 'address_line_2'].map(function (field) {
                    return currentValue[field];
                }).filter(function (value) {
                    return value != null && value != '';
                });

                request.get(url).query({
                    address: addressArr.join(' '),
                    key: _this.props.GOOGLE_API_KEY,
                    components: componentsArg
                }).then(function success(response) {
                    var location = _lodash2.default.get(response, 'body.results[0].geometry.location');
                    if (!location) {
                        return;
                    }
                    if (location != null) {
                        self.props.delegateActions.longitudeField.setFieldValue(location.lng);
                        self.props.delegateActions.latitudeField.setFieldValue(location.lat);
                    }
                    var viewport = _lodash2.default.get(response, 'body.results[0].geometry.viewport');

                    var panned = false;
                    var zoomed = false;

                    self._panToMarkerMap_withRef = function (mapRef) {
                        if (panned) {
                            return;
                        } else {
                            panned = true;
                            mapRef.panTo(location);
                        }
                    };
                    self._fitToBoundsMap_withRef = function (mapRef) {
                        if (zoomed) {
                            return;
                        } else {
                            zoomed = true;
                            mapRef.fitBounds({
                                east: viewport.northeast.lng,
                                north: viewport.northeast.lat,
                                west: viewport.southwest.lng,
                                south: viewport.southwest.lat
                            });
                        }
                    };
                    if (self.googleMapRef != null) {
                        self._panToMarkerMap_withRef(self.googleMapRef);
                        self._fitToBoundsMap_withRef(self.googleMapRef);
                    }
                }, function error(err) {
                    self.props.showErrorNotification({ error: err.response.error });
                });
            }, 500);
            self.debouncedGeoCode(currentValue);
        }
    },
    renderMap: function renderMap() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4;

        var self = this;
        self.currentValue = _lodash2.default.get(this, ['props', 'value'], {});
        var latitude = parseFloat(_lodash2.default.get(this.currentValue, 'latitude')) || 0;
        var longitude = parseFloat(_lodash2.default.get(this.currentValue, 'longitude')) || 0;
        var markers = [];

        if (latitude != null && !isNaN(latitude) && longitude != null && !isNaN(longitude)) {
            markers.push({
                position: { lat: latitude, lng: longitude },
                defaultAnimation: 0
            });
        }
        return _react2.default.createElement(_ScriptjsLoader2.default, (_React$createElement4 = {
            hostname: "maps.googleapis.com",
            pathname: "/maps/api/js",
            query: {
                key: self.props.GOOGLE_API_KEY,
                libraries: "geometry,drawing,places"
            },
            loadingElement: _react2.default.createElement('div', (_React$createElement = { style: { height: '100%' }, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'InternationalAddress'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'InternationalAddress'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'InternationalAddress'), _React$createElement)),
            containerElement: _react2.default.createElement('div', (_React$createElement2 = { style: { height: '100%', width: '100%' }, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'InternationalAddress'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'InternationalAddress'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'InternationalAddress'), _React$createElement2)),
            googleMapElement: _react2.default.createElement(
                _reactGoogleMaps.GoogleMap,
                (_React$createElement3 = {
                    ref: function ref(googleMap) {
                        if (!googleMap) {
                            return;
                        }
                        self.googleMapRef = googleMap;
                        if (self._panToMarkerMap_withRef) {
                            self._panToMarkerMap_withRef(self.googleMapRef);
                        }
                        if (self._fitToBoundsMap_withRef) {
                            self._fitToBoundsMap_withRef(self.googleMapRef);
                        }
                    },
                    defaultZoom: 3,
                    defaultCenter: { lat: latitude, lng: longitude },
                    onClick: self.handleMapClick,
                    'data-codecept-selector-node': 'GoogleMap',
                    'data-codecept-selector-file': 'InternationalAddress'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'GoogleMap'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'InternationalAddress'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'GoogleMap'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'InternationalAddress'), _React$createElement3),
                markers.map(function (markerProps) {
                    var _extends2;

                    return _react2.default.createElement(_reactGoogleMaps.Marker, _extends({}, markerProps, (_extends2 = {
                        defaultAnimation: 0,
                        'data-codecept-selector-node': 'Marker',
                        'data-codecept-selector-file': 'InternationalAddress'
                    }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Marker'), _defineProperty(_extends2, 'data-codecept-selector-file', 'InternationalAddress'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Marker'), _defineProperty(_extends2, 'data-codecept-selector-file', 'InternationalAddress'), _extends2)));
                })
            ),
            'data-codecept-selector-node': 'ScriptjsLoader',
            'data-codecept-selector-file': 'InternationalAddress'
        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ScriptjsLoader'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'InternationalAddress'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ScriptjsLoader'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'InternationalAddress'), _React$createElement4));
    },
    renderMapPlaceholder: function renderMapPlaceholder() {
        var _this2 = this,
            _React$createElement5,
            _React$createElement6,
            _React$createElement7,
            _React$createElement8,
            _React$createElement9,
            _React$createElement10;

        var settingsEditMenuSpec = this.props.settingsEditMenuSpec;


        var enableButton = _react2.default.createElement(_RaisedButton2.default, (_React$createElement5 = {
            label: settingsEditMenuSpec ? "Configure Google API Key to enable" : "Contact your administrator to enable",
            secondary: true,
            disabled: !settingsEditMenuSpec,
            href: settingsEditMenuSpec.route,
            onTouchTap: function onTouchTap() {
                _this2.props.delegateRouteChange(settingsEditMenuSpec.route, settingsEditMenuSpec);
            },
            'data-codecept-selector-node': 'RaisedButton',
            'data-codecept-selector-file': 'InternationalAddress'
        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'InternationalAddress'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'InternationalAddress'), _React$createElement5));

        return _react2.default.createElement(_Paper2.default, (_React$createElement10 = { className: styles.placeholderWrapper, zDepth: 1,
            children: _react2.default.createElement(
                'div',
                (_React$createElement9 = { className: styles.placeholderContent, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'InternationalAddress'
                }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'InternationalAddress'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'InternationalAddress'), _React$createElement9),
                _react2.default.createElement(
                    'span',
                    (_React$createElement6 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'InternationalAddress'
                    }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'InternationalAddress'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'InternationalAddress'), _React$createElement6),
                    ' powered by '
                ),
                _react2.default.createElement('br', (_React$createElement7 = {
                    'data-codecept-selector-node': 'br',
                    'data-codecept-selector-file': 'InternationalAddress'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'InternationalAddress'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'InternationalAddress'), _React$createElement7)),
                _react2.default.createElement('img', (_React$createElement8 = { src: _googleMaps2.default, alt: '', style: { margin: '10px' }, 'data-codecept-selector-node': 'img',
                    'data-codecept-selector-file': 'InternationalAddress'
                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'img'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'InternationalAddress'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'img'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'InternationalAddress'), _React$createElement8)),
                enableButton
            ),
            'data-codecept-selector-node': 'Paper',
            'data-codecept-selector-file': 'InternationalAddress'
        }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'Paper'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'InternationalAddress'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'Paper'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'InternationalAddress'), _React$createElement10));
    },
    render: function render() {
        var _React$createElement11, _React$createElement13, _React$createElement14, _React$createElement15, _React$createElement16;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var self = this;
        self.currentValue = _lodash2.default.get(this, ['props', 'value'], {});

        if (
        // don't search if values are empty
        _lodash2.default.map(_lodash2.default.pick(this.currentValue, geoCodeFields), function (val, key) {
            return val;
        }).filter(function (val) {
            return val != null && val != '';
        }).length > 0
        // and if they haven't changed
        && !_lodash2.default.isEqual(_lodash2.default.pick(this.currentValue, geoCodeFields), _lodash2.default.pick(this.previousValue, geoCodeFields))) {
            // search for current address
            self._geoCode(this.currentValue); //# should explicitly pass currentValue through to prevent relying on state
            self.previousValue = self.currentValue;
        }

        var children = this.props.children;
        var filteredChildren = [].concat(childrenBeforeStates(children), childrenForStates(children).filter(function (child) {
            return self._shouldShowField(child.key);
        }), childrenAfterStates(children));

        return _react2.default.createElement(
            'div',
            (_React$createElement16 = {
                id: this.props.id,
                className: styles.wrapper,
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'InternationalAddress'
            }, _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'InternationalAddress'), _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'InternationalAddress'), _React$createElement16),
            _react2.default.createElement(
                'label',
                (_React$createElement11 = { className: styles.label, 'data-codecept-selector-node': 'label',
                    'data-codecept-selector-file': 'InternationalAddress'
                }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'InternationalAddress'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'InternationalAddress'), _React$createElement11),
                this.props.label
            ),
            _react2.default.createElement(
                'div',
                (_React$createElement15 = {
                    className: styles.columnWrapper,
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'InternationalAddress'
                }, _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'InternationalAddress'), _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'InternationalAddress'), _React$createElement15),
                _react2.default.createElement(
                    'div',
                    (_React$createElement13 = {
                        className: styles.leftColumn,
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'InternationalAddress'
                    }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'InternationalAddress'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'InternationalAddress'), _React$createElement13),
                    filteredChildren.map(function (child) {
                        var _React$createElement12;

                        return _react2.default.createElement(
                            'div',
                            (_React$createElement12 = {
                                'data-codecept-selector-node': 'div',
                                'data-codecept-selector-file': 'InternationalAddress'
                            }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'InternationalAddress'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'InternationalAddress'), _React$createElement12),
                            child
                        );
                    })
                ),
                _react2.default.createElement(
                    'div',
                    (_React$createElement14 = {
                        className: styles.rightColumn,
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'InternationalAddress'
                    }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'InternationalAddress'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'InternationalAddress'), _React$createElement14),
                    this.props.GOOGLE_API_KEY ? this.renderMap() : this.renderMapPlaceholder()
                )
            )
        );
    }
});

exports.default = InternationalAddress;