'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Toggle = require('@rubyapps/ruby-react-components/src/client/input/Toggle');

var _Toggle2 = _interopRequireDefault(_Toggle);

var _Toolbar = require('material-ui/Toolbar');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var CustomToggle = _react2.default.createClass({
    displayName: 'CustomToggle',
    render: function render() {
        if (this.props.name) {
            var _React$createElement, _React$createElement2, _React$createElement3;

            return _react2.default.createElement(
                'div',
                (_React$createElement3 = { style: { display: 'flex', alignItems: 'center' }, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Toggle'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Toggle'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Toggle'), _React$createElement3),
                this.props.label && _react2.default.createElement(_Toolbar.ToolbarTitle, (_React$createElement = { text: this.props.label, style: { opacity: 0.4 }, 'data-codecept-selector-node': 'ToolbarTitle',
                    'data-codecept-selector-file': 'Toggle'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ToolbarTitle'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Toggle'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ToolbarTitle'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Toggle'), _React$createElement)),
                _react2.default.createElement(_Toggle2.default, (_React$createElement2 = {
                    key: this.props.name,
                    name: this.props.name,

                    value: this.props.value || this.props.defaultValue,
                    defaultToggled: this.props.defaultValue,

                    onToggle: this.props.delegateOnToggle,
                    disabled: _lodash2.default.isFunction(this.props.disabled) ? this.props.disabled() : this.props.disabled,
                    'data-codecept-selector-node': 'Toggle',
                    'data-codecept-selector-file': 'Toggle'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Toggle'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Toggle'), _React$createElement2))
            );
        } else {
            return null;
        }
    }
});

exports.default = CustomToggle;