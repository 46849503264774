'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var path = require('path');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

module.exports = {
    getProps: function getProps(shouldHydrateProps) {
        var mergedProps = _extends({}, this.props, this.getState().props);
        var finalProps = mergedProps;

        if (shouldHydrateProps) {
            logger.warn('shouldHydrateProps is not supported in this mixin');
        }

        return finalProps;
    },
    action: function action() {
        var TYPES = function (id) {
            return {
                SET_PROPS: '@@ruby-app/' + id + '/SET_PROPS'
            };
        }(this.getID());

        return {
            TYPES: TYPES,
            generators: {
                setProps: function setProps(props) {
                    var TYPES = this.getAction().TYPES;
                    return {
                        type: TYPES.SET_PROPS,
                        payload: {
                            props: props
                        }
                    };
                }
            }
        };
    },
    reducer: function reducer() {
        var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { props: {} };
        var action = arguments[1];

        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        var type = action.type,
            payload = action.payload;


        switch (type) {
            case TYPES.SET_PROPS:
                return _extends({}, state, {
                    props: _extends({}, state.props, payload.props)
                });
            default:
                return state;
        }
    }
};