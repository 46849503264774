'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = ObjectGenerator;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');
var React = require('react');

var RubyCodeUtils = require('@rubyapps/ruby-code-utils');
var fieldBaseMixin = require('@rubyapps/ruby-component-mixin-field-base');

/**
 *  @param {object} options - the options from the column definition
 */
var DEFAULT_OPTIONS = {
    toHTML: '${data.name || data.full_name || (data.first_name + ", " + data.last_name) || data.first_name || data.username || data.text}'
};
function ObjectGenerator(options) {
    var parentProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var toHTML = options.toHTML;


    var hydratedToHTML = fieldBaseMixin.hydratedToHTMLFromProps(toHTML ? options : DEFAULT_OPTIONS);
    /*
    const options__key = options.key;
    const keyPathArr = options__key.split('.');
    */

    /**
     *  @param {props} the props for the specific cell instance (contains data)
     */
    return function ObjectComponent() {
        var _React$createElement;

        var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var data = _.get(props, ['data'], '');

        //# NOTE: originalRowData doesn't work 100% because originalRowData isn't defined at all when toHTML is initiall called.
        //# Is sommething calling on toHTML from not here?
        //# Not sure why so we can't rely on this reliably until we can figure out a way to ensure it's defined
        //#
        var formattedValue = hydratedToHTML(data || {}, { originalRowData: props.originalRowData });

        return React.createElement('span', (_React$createElement = {
            dangerouslySetInnerHTML: {
                __html: RubyCodeUtils.sanitizeHTML(formattedValue)
            },
            'data-codecept-selector-node': 'span',
            'data-codecept-selector-file': 'ObjectGenerator'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ObjectGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ObjectGenerator'), _React$createElement));
    };
}