'use strict';

var _reactTapEventPlugin = require('react-tap-event-plugin');

var _reactTapEventPlugin2 = _interopRequireDefault(_reactTapEventPlugin);

var _MuiThemeProvider = require('material-ui/styles/MuiThemeProvider');

var _MuiThemeProvider2 = _interopRequireDefault(_MuiThemeProvider);

var _getMuiTheme = require('material-ui/styles/getMuiTheme');

var _getMuiTheme2 = _interopRequireDefault(_getMuiTheme);

var _reactRouter = require('react-router');

var _reactRouterScroll = require('react-router-scroll');

var _reactRouterScroll2 = _interopRequireDefault(_reactRouterScroll);

var _store = require('store');

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');
var Promise = require('bluebird');

//# loading in core components
var rubyLogger = require('@rubyapps/ruby-logger');
var logger = rubyLogger.getLogger('Client::App');

var React = require('react');
var PropTypes = React.PropTypes;
var ReactDOM = require('react-dom');

var _require = require('react-redux'),
    Provider = _require.Provider;

var request = require('@rubyapps/ruby-superagent');

var ReactRouterRedux = require('react-router-redux');
var syncHistoryWithStore = ReactRouterRedux.syncHistoryWithStore;

var rubyStyles = require('@rubyapps/ruby-styles');

//== DEFAULTS ==================================//

// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
(0, _reactTapEventPlugin2.default)();

var muiThemeStoreKey = 'rubyMuiThemeKey';
var WrappedMuiThemeProvider = React.createClass({
    displayName: 'WrappedMuiThemeProvider',

    childContextTypes: {
        updateTheme: PropTypes.func.isRequired
    },
    getInitialState: function getInitialState() {
        var muiThemeKey = _store2.default.get(muiThemeStoreKey);

        var defaultMuiTheme = rubyStyles.materialTheme.default;
        var muiTheme = muiThemeKey ? rubyStyles.materialTheme[muiThemeKey] : undefined;

        return {
            muiTheme: (0, _getMuiTheme2.default)(muiTheme || defaultMuiTheme)
        };
    },
    updateTheme: function updateTheme(themeKey) {
        var newTheme = rubyStyles.materialTheme[themeKey];
        if (newTheme) {
            _store2.default.set(muiThemeStoreKey, themeKey);
            this.setState({
                muiTheme: (0, _getMuiTheme2.default)(newTheme)
            });
        }
    },
    getChildContext: function getChildContext() {
        return {
            updateTheme: this.updateTheme
        };
    },
    render: function render() {
        var _React$createElement;

        return React.createElement(
            _MuiThemeProvider2.default,
            (_React$createElement = { muiTheme: this.state.muiTheme, 'data-codecept-selector-node': 'MuiThemeProvider',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MuiThemeProvider'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'MuiThemeProvider'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement),
            this.props.children
        );
    }
});

module.exports = {
    _rootComponent: undefined,
    _store: undefined,
    _history: undefined,
    getCoreComponents: function getCoreComponents() {
        return this._coreComponents;
    }

    /*
     *  config: {
     *      overrides: {
     *          coreComponents: {
     *              //# any components that you want to replace
     *          }
     *      }
     *      , actionsByNamespace: //required
     *      , store:
     *      , rubyPluginManager:
     *  }
     * */
    , init: function init(config) {
        var self = this;
        if (config.overrides && config.overrides.coreComponents) {
            this._coreComponents = _.assign({}, this._coreComponents, config.overrides.coreComponents);
        }
        this._rootComponent = config.rootComponent;

        this._store = config.store;
        this._history = syncHistoryWithStore(_reactRouter.browserHistory, this._store);

        return new Promise(function (resolve, reject) {
            resolve(self);
        });
    },
    render: function render() {
        var _React$createElement2, _React$createElement3, _React$createElement4;

        this._reactComponent = ReactDOM.render(React.createElement(
            WrappedMuiThemeProvider,
            (_React$createElement4 = {
                'data-codecept-selector-node': 'WrappedMuiThemeProvider',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'WrappedMuiThemeProvider'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'WrappedMuiThemeProvider'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'index'), _React$createElement4),
            React.createElement(
                Provider,
                (_React$createElement3 = { store: this._store, 'data-codecept-selector-node': 'Provider',
                    'data-codecept-selector-file': 'index'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Provider'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Provider'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'index'), _React$createElement3),
                React.createElement(
                    _reactRouter.Router,
                    (_React$createElement2 = {
                        history: this._history,
                        render: (0, _reactRouter.applyRouterMiddleware)((0, _reactRouterScroll2.default)(function (prevRouterProps, _ref) {
                            var location = _ref.location;

                            //# only update scroll when pathname is different
                            //# do not do so if only hash changes
                            return prevRouterProps && location.pathname !== prevRouterProps.location.pathname;
                        })),
                        'data-codecept-selector-node': 'Router',
                        'data-codecept-selector-file': 'index'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Router'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Router'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'index'), _React$createElement2),
                    this._rootComponent.getRouteElement()
                )
            )
        ), document.getElementById('react-content'));
    }
};