'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _ImagePicker = require('./ImagePicker.cssModule');

var _ImagePicker2 = _interopRequireDefault(_ImagePicker);

var _transitions = require('material-ui/styles/transitions');

var _transitions2 = _interopRequireDefault(_transitions);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

var PropTypes = _react2.default.PropTypes;

var ImagePickerWidget = require('./ImagePickerWidget');

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label;

var getStyles = function getStyles(props, context, state) {
    var _context$muiTheme = context.muiTheme,
        baseTheme = _context$muiTheme.baseTheme,
        _context$muiTheme$tex = _context$muiTheme.textField,
        floatingLabelColor = _context$muiTheme$tex.floatingLabelColor,
        focusColor = _context$muiTheme$tex.focusColor,
        textColor = _context$muiTheme$tex.textColor,
        disabledTextColor = _context$muiTheme$tex.disabledTextColor,
        backgroundColor = _context$muiTheme$tex.backgroundColor,
        hintColor = _context$muiTheme$tex.hintColor,
        errorColor = _context$muiTheme$tex.errorColor;


    var styles = {
        root: {
            fontSize: 16,
            lineHeight: '24px',
            width: props.fullWidth ? '100%' : 256,
            height: (props.rows - 1) * 24 + (props.floatingLabelText ? 72 : 48),
            display: 'inline-block',
            position: 'relative',
            fontFamily: baseTheme.fontFamily,
            transition: _transitions2.default.easeOut('200ms', 'height')
        },
        error: {
            position: 'relative',
            bottom: 8,
            fontSize: 12,
            lineHeight: '12px',
            color: errorColor,
            transition: _transitions2.default.easeOut()
        }
    };

    _extends(styles.error, props.errorStyle);

    return styles;
};

var KEYPATHS_FOR_COMPONENT_UPDATE = ['value', 'error.message', 'locked', 'disabled', 'mode'];
var ImagePicker = _react2.default.createClass({
    displayName: 'ImagePicker',

    propTypes: {
        label: PropTypes.string,
        fieldKey: PropTypes.string,
        value: PropTypes.number,
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        id: PropTypes.string,
        actions: PropTypes.object,
        constraints: PropTypes.object

        //# kludgy prop support for old image picker
        , hasUploadPermission: PropTypes.bool,
        simple: PropTypes.bool
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null
        };
    },
    _onValueChange: function _onValueChange(event, value) {
        if (value == this.props.value) {
            return;
        }
        this.props.actions.setFieldValueByKey(value, this.props.fieldKey);
    },

    shouldComponentUpdate: function shouldComponentUpdate(nextProps, nextState) {
        var selectedCurrentProps = _.pick(this.props, KEYPATHS_FOR_COMPONENT_UPDATE);
        var selectedNextProps = _.pick(nextProps, KEYPATHS_FOR_COMPONENT_UPDATE);

        return !_.isEqual(selectedCurrentProps, selectedNextProps);
    },
    render: function render() {
        var _extends2, _React$createElement2, _React$createElement3, _React$createElement4;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var labelProps = _.pick(this.props, Object.keys(Label.propTypes));

        var prepareStyles = this.context.muiTheme.prepareStyles;

        var styleObject = getStyles(this.props, this.context, this.state);
        var errorTextElement = null;
        if (this.props.error && this.props.error.message) {
            var _React$createElement;

            errorTextElement = _react2.default.createElement(
                'div',
                (_React$createElement = { style: prepareStyles(styleObject.error), 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'ImagePicker'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ImagePicker'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ImagePicker'), _React$createElement),
                this.props.error.message
            );
        }

        //errorText={this.props.error ? this.props.error.message : null}
        //constraints={_.pickBy(this.props.verify, (value, key) => /^media_/.test(key) )}
        return _react2.default.createElement(
            'div',
            (_React$createElement4 = { className: _ImagePicker2.default.wrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'ImagePicker'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ImagePicker'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ImagePicker'), _React$createElement4),
            _react2.default.createElement(
                'label',
                (_React$createElement2 = { className: _ImagePicker2.default.label, 'data-codecept-selector-node': 'label',
                    'data-codecept-selector-file': 'ImagePicker'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ImagePicker'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ImagePicker'), _React$createElement2),
                _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                    'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'ImagePicker'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ImagePicker'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ImagePicker'), _extends2)))
            ),
            errorTextElement,
            _react2.default.createElement(ImagePickerWidget, (_React$createElement3 = {
                name: this.props.id,
                value: this.props.value,
                onChange: this._onValueChange,
                constraints: this.props.constraints,
                disabled: this._isLocked() || this._isInfoMode(),
                'data-codecept-selector-node': 'ImagePickerWidget',
                'data-codecept-selector-file': 'ImagePicker'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ImagePickerWidget'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ImagePicker'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ImagePickerWidget'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ImagePicker'), _React$createElement3))
        );
    },

    _isInfoMode: function _isInfoMode() {
        var mode = this.props.mode;


        return mode === 'info';
    },
    _isLocked: function _isLocked() {
        return this.props.locked || this.props.disabled;
    }
});

exports.default = ImagePicker;