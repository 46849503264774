"use strict";

var defaultState = {};

module.exports = function (state, action) {
    var actionTypes = this.getAction().TYPES;

    switch (action.type) {
        default:
            return state;
    }
};