'use strict';

var _store = require('store');

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RCPersistenceHelper = {
    set: function set(key, value) {
        _store2.default.set(key, value);
    },
    get: function get(key) {
        return _store2.default.get(key);
    },
    clearAll: function clearAll() {
        _store2.default.clearAll();
    }

};

module.exports = RCPersistenceHelper;