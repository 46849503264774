'use strict';

//# builtin plugins
require('tinymce/plugins/autoresize');
require('tinymce/plugins/advlist');
require('tinymce/plugins/autolink');
require('tinymce/plugins/link');
require('tinymce/plugins/lists');
require('tinymce/plugins/image');
require('tinymce/plugins/charmap');
require('tinymce/plugins/print');
require('tinymce/plugins/preview');
require('tinymce/plugins/anchor');
require('tinymce/plugins/searchreplace');
require('tinymce/plugins/visualblocks');
require('tinymce/plugins/code');
require('tinymce/plugins/fullscreen');
require('tinymce/plugins/hr');
require('tinymce/plugins/insertdatetime');
require('tinymce/plugins/media');
require('tinymce/plugins/table');
require('tinymce/plugins/contextmenu');
require('tinymce/plugins/paste');
require('tinymce/plugins/textcolor');
require('tinymce/plugins/colorpicker');
require('tinymce/plugins/wordcount');

/* Import plugins - include the relevant plugin in the 'plugins' option. */
require('tinymce/plugins/emoticons'); //# from tiny5
require('tinymce/plugins/emoticons/js/emojis');

//# Custom plugins

var pluginCacheDate = '20231221024600';

/*
tinymce.PluginManager.load(
    'rubyimagepicker',
    `/public/${pluginCacheDate}/javascript/tiny_mce-custom-plugins/rubyimagepicker/editor_plugin_src.js`
);
*/

require('../local_modules/ruby-component-rte-plugin-spellchecker/src/client/index.js');

tinymce.PluginManager.load('condense', '/public/' + pluginCacheDate + '/javascript/tiny_mce-custom-plugins/condense/editor_plugin_src.js');
require('../../../../../assets/public/javascript/tiny_mce-custom-plugins/condense/condense.js');
require('../../../../../assets/public/javascript/tiny_mce-custom-plugins/condense/condense.css');

tinymce.PluginManager.load('imageanchor', '/public/' + pluginCacheDate + '/javascript/tiny_mce-custom-plugins/imageanchor/editor_plugin_src.js');

/*
tinymce.PluginManager.load(
    'rubycontentpagepicker',
    `/public/${pluginCacheDate}/javascript/tiny_mce-custom-plugins/rubycontentpagepicker/editor_plugin_src.js`
);
*/
require('../local_modules/ruby-component-rte-plugin-rubycontentpagepicker/src/client/index.js');

/*
tinymce.PluginManager.load(
    'rubyemaillink',
    `/public/${pluginCacheDate}/javascript/tiny_mce-custom-plugins/rubyemaillink/editor_plugin_src.js`
);
*/
require('../local_modules/ruby-component-rte-plugin-rubyemaillink/src/client/index.js');

tinymce.PluginManager.load('typekit', '/public/' + pluginCacheDate + '/javascript/tiny_mce-custom-plugins/typekit/editor_plugin_src.js');

tinymce.PluginManager.load('charwordcount', '/public/' + pluginCacheDate + '/javascript/tiny_mce-custom-plugins/charwordcount/editor_plugin_src.js');

tinymce.PluginManager.load('codemirror', '/public/' + pluginCacheDate + '/javascript/tiny_mce-custom-plugins/codemirror/plugin.js'
//# number to allow for browser cache
//# but also to be able to cache bust
);

/* //# DEPRECATED, we should the new way to load custom plugins if it's going to have a hook into RubyComponents
tinymce.PluginManager.load(
    'rubyappmediapicker',
    `/public/${pluginCacheDate}/javascript/tiny_mce-custom-plugins/rubyappmediapicker/editor_plugin_src.js`
    //# number to allow for browser cache
    //# but also to be able to cache bust
);
*/