'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.local = exports.data = undefined;

var _dataHelpers = require('./data-helpers');

var data = _interopRequireWildcard(_dataHelpers);

var _localHelpers = require('./local-helpers');

var local = _interopRequireWildcard(_localHelpers);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

exports.data = data;
exports.local = local;