'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _baseField = require('./baseField');

var _baseField2 = _interopRequireDefault(_baseField);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    style: _extends({}, _baseField2.default.style, {
        minWidth: 60,
        verticalAlign: 'baseline'
    })
    /*
    , underlineStyle: {
        borderColor: Colors.black_25
    }
    */
};