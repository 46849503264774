"use strict";

module.exports = {
    "ruby_client_fk": 3,
    "content_subsite_fk": 1,
    "template_keyword": "office",
    "parent_id": null,
    "status": "active",
    "start_timestamp": null,
    "end_timestamp": null,
    "contentForLanguages": {
        "de": {
            "name": "fdsfsa ",
            "office_addresses": [],
            "office_abbreviation": null,
            "fax_alternate": null,
            "excerpt": null,
            "main_content": null,
            "aux_content": null,
            "career_header": null,
            "career_content": null,
            "career_link_name_override": null,
            "career_link": null,
            "featured_news_override": null,
            "media": null,
            "supporting_link_1": {
                "text": null,
                "link": null
            },
            "supporting_link_2": {
                "text": null,
                "link": null
            },
            "supporting_link_3": {
                "text": null,
                "link": null
            },
            "office_name": null,
            "phone": null,
            "fax": null,
            "address": {
                "state": null,
                "latitude": null,
                "longitude": null,
                "address_line_1": null,
                "address_line_2": null,
                "city": null,
                "country": null,
                "postal_code": null
            },
            "address_url": null,
            "attorneys": null,
            "practices": null,
            "regions": null,
            "industries": null,
            "browser_page_title": null,
            "meta_description": null,
            "meta_keywords": null,
            "url_identifier": null,
            "open_graph": {
                "open_graph_title": null,
                "open_graph_description": null,
                "open_graph_image": null
            },
            "external_id": null,
            "nav_title": null,
            "show_in_client_sitemap": false
        }
    },
    "name": "New Yorks",
    "office_addresses": [{
        "type": "office_address",
        "name": "New York  sdfdsfd sdfds",
        "office_name": "Test Office Name",
        "address": {
            "state": "NY",
            "latitude": 40.7078315,
            "longitude": -74.0087358,
            "address_line_1": "55 Broad",
            "address_line_2": null,
            "city": "New York",
            "country": "US",
            "postal_code": "10005-1413"
        },
        "phone": "+1-212-530-5000",
        "fax": "+1-212-530-5219",
        "address_url": "https://www.google.com/maps/place/40%C2%B042'28.1%22N+74%C2%B000'30.2%22W/@…4.0105776,16z/data=!4m5!3m4!1s0x0:0x0!8m2!3d40.7078056!4d-74.0083889?hl=en"
    }],
    "office_abbreviation": null,
    "fax_alternate": null,
    "excerpt": "<p>The New York office--headquarters for Test&rsquo;s leading edge global practice--takes advantage of the city&rsquo;s dominant role in business and finance. The office serves as the focal point for the biggest deals, the most complex litigation and most consequential bankruptcies in the country. law</p>",
    "main_content": "<p>Headquartered in the heart of New York City's financial district, we have been at the forefront of major corporate and financial legal trends since our founding on Wall Street in 1866.</p>\r\n<p>Our more than 400 attorneys in New York manage complex and diverse legal matters, providing innovative legal solutions for both US and international clients. </p>",
    "aux_content": "<p>Our New York office represents top companies and financial institutions, who benefit from our comprehensive expertise in a full range of legal areas, including: <a href=\"/practices/areas/corporate/index.html\">Corporate</a>, <a href=\"/practices/areas/finance/index.html\">Finance</a>, <a href=\"/practices/areas/financial-restructuring/index.html\">Financial Restructuring</a>, <a href=\"/practices/areas/litigation-and-arbitration/index.html\">Litigation &amp; Arbitration</a>, <a href=\"/practices/areas/finance/project-finance.html\">Project, Energy and Infrastructure Finance</a>, <a href=\"/practices/areas/securities/index.html\">Capital Markets</a>, <a href=\"/practices/areas/tax/index.html\">Tax</a>, and <a href=\"/practices/areas/trusts-estates-and-exempt-organizations/trusts-estates.html\">Trust &amp; Estates</a>.</p>\r\n<p>The attorneys in our New York head office work closely with not only the attorneys in our two other US offices, but also those in our nine offices located outside of the US.</p>",
    "career_header": null,
    "career_content": "<p><strong>With more than 350 lawyers in 10 practice areas,</strong> including Corporate/M&amp;A, Technology, Corporate Finance, Project Finance, Transportation and Space, Real Estate, Litigation &amp; Arbitration, Financial Restructuring, Tax and Trusts and Estates, Test's New York office provides an exceptional level of service to meet the needs of our clients.</p>\r\n<p>We are a big office, in a big city, yet we maintain a small office culture in which associates and partners from different practice areas often consult each other and work together. The Firm has a relaxed business casual environment.</p>\r\n<h3>Recruiting/Summer Program</h3><p>Test NY recruits on campus at national and local law schools and from high quality candidates completing judicial clerkships for our summer program and incoming classes. Our recruiting process focuses on a two-way street where we get to know you, and you get to know us. Our call-back interviews include two partners and two associates and generally take a half day. Offer decisions are usually made within one week of a call-back interview.</p>\r\n<p>We pride ourselves on running an extremely personal and comprehensive summer program. Summer associates rotate through three practice areas. Each summer associate is assigned two associate advisors to assist with the transition between law school and law firm life. The summer program is managed by the Recruiting Committee, along with a partner from each practice area, to ensure that each summer associate is getting a broad experience throughout each rotation. Informal and formal social events are scheduled throughout the summer to complete the summer associate experience. Offers are typically extended on each summer associate's last day.</p>\r\n<p>Once associates join our firm, they are assigned directly to a practice area. An intensive training program is an important aspect of our entry level associates' introduction to their career at Test.</p>",
    "career_link_name_override": null,
    "career_link": null,
    "featured_news_override": null,
    "media": null,
    "supporting_link_1": {
        "text": "",
        "link": ""
    },
    "supporting_link_2": {
        "text": "",
        "link": ""
    },
    "supporting_link_3": {
        "text": "",
        "link": ""
    },
    "office_name": "Test Office Name",
    "phone": "+1-212-530-5000",
    "fax": "+1-212-530-5219",
    "address": {
        "state": "NY",
        "latitude": "40.7078161",
        "longitude": "-74.00872420000002",
        "address_line_1": "55 Broad",
        "address_line_2": null,
        "city": "New York",
        "country": "US",
        "postal_code": "10005-1413"
    },
    "address_url": "https://www.google.com/maps/place/40%C2%B042'28.1%22N+74%C2%B000'30.2%22W/@40.707811,-74.0083796,17z/data=!3m1!4b1!4m2!3m1!1s0x0:0x0?hl=en",
    "attorneys": [6331, 4289, 127, 5620],
    "practices": [823, 1945, 842, 831, 6276, 827],
    "regions": [1951, 266],
    "industries": [260, 250],
    "browser_page_title": null,
    "url_identifier": "new-york",
    "redirect_from_url": "/en/Offices/NewYork.htm",
    "meta_description": null,
    "meta_keywords": null,
    "open_graph": {
        "open_graph_title": null,
        "open_graph_description": null,
        "open_graph_image": null
    },
    "external_id": null,
    "nav_title": null,
    "show_in_client_sitemap": true
};