'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _ProfileIdentityConnector = require('./reactComponents/ProfileIdentityConnector');

var _ProfileIdentityConnector2 = _interopRequireDefault(_ProfileIdentityConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');
var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');

var FieldDropdown = require('@rubyapps/ruby-component-field-dropdown');
var FieldText = require('@rubyapps/ruby-component-field-text');

var childrenFormModule = require('../common/childrenForm');

var RCProfileIdentity = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {} //# some are already included by the baseFieldMixin
    , componentName: componentName,
    reducer: function reducer() {
        var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        //# need to remove if we don't need it
        //# but for now, some instance of this widget is not including the correct mixins
        return state;
    },
    getDefaultProps: function getDefaultProps(props) {
        return {
            excludeWrapper: false,
            gridWidth: 'medium',
            childrenPropsByKey: childrenFormModule.defaultChildrenPropsByKeyFromProps(props)
        };
    },
    dependencies: function dependencies() {
        var selfSelector = this.getDefaultSelector();

        return {
            selfSelector: selfSelector
        };
    },
    children: function children() {
        var childrenForm = childrenFormModule.childrenFormFromProps(this.props);
        var parentID = this.getID();
        //const Components = require('@rubyapps/ruby-component-builder').Components;
        var createRubyComponentsFromFormJS = require('@rubyapps/ruby-component-builder').default;

        return childrenForm.map(function (childForm) {
            return createRubyComponentsFromFormJS(childForm, parentID);
        });
    },
    getReactClass: function getReactClass() {
        return _ProfileIdentityConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var ProfileIdentityComponent = this.getReactClass();

        return React.createElement(ProfileIdentityComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'ProfileIdentityComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'ProfileIdentityComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'ProfileIdentityComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

});

module.exports = RCProfileIdentity;