'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var path = require('path');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

var _ = require('lodash');
var rubyLodash = require('@rubyapps/ruby-lodash');

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');

module.exports = {
    //# Mimic the naming scheme for content property helper
    //# NOTE: this might not support retrieving children components of complex fields for now
    //# this won't handle overloaded fields for now (like DynamicForm)
    //# TODO: support keypaths and wildcards
    //# TODO: support referenced templates (like profile widget)
    //# TODO: support DynamicForm
    //#     NOTE: to support the custom children, we just need to customize the childrenSelector
    //#     to return custom nodes, which may be exterior to the root tree node
    fieldSpecsForTemplate_atKeypath: function fieldSpecsForTemplate_atKeypath(template, keypath, callingModule) {
        var Components = require('../../../ruby-component-builder/src/common/components').default;
        template = _.isString(template) ? this.getAugmentedTemplateForKey(template, undefined, callingModule || this) : template;

        var keypathArr = keypath.split('.');
        var lastKeypathKey = _.last(keypathArr);

        var childrenSelector = rubyLodash.childrenSelectors.childrenSelectorMatchingKeypath_byKey_forChildrenKey(keypathArr);

        var selectedFieldSpecs = rubyLodash.depthFirstReduce(template.form, function (collector, node, nodepathArr) {
            var lastNodeKey = (_.findLast(nodepathArr, function (n) {
                return n.key;
            }) || {}).key;
            if (lastNodeKey == lastKeypathKey) {
                collector.push(node);
            }
            return collector;
        }, [], childrenSelector);

        var hydratedFieldSpecs = selectedFieldSpecs.map(function (fieldSpec) {
            var componentForChild = Components[fieldSpec.componentName];
            if (!componentForChild) {
                logger.warn('No component found for [' + fieldSpec.componentName + '] with formJS valued:', fieldSpec);
                return fieldSpec;
            }

            var getFieldSpecFromProps = componentForChild.getFieldSpecFromProps || baseFieldMixin.getFieldSpecFromProps;

            var defaultProps = componentForChild.getDefaultProps ? componentForChild.getDefaultProps(fieldSpec) : {};

            return _extends({}, defaultProps, fieldSpec);
        });

        return hydratedFieldSpecs;
    }
};