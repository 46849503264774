'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var defaultState = {
    error: null,
    isLoading: false
};

module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];

    var actionTypes = this.getAction().TYPES;

    if (!state.hasOwnProperty('error')) {
        state = _extends({}, state, defaultState);
    }
    switch (action.type) {
        case actionTypes.SET_ERROR:
            return _extends({}, state, {
                error: action.payload.error
            });
        case actionTypes.SHOW_LOADING:
            return _extends({}, state, {
                isLoading: true
            });
        case actionTypes.CLEAR_LOADING:
            return _extends({}, state, {
                isLoading: false
            });
        default:
            return state;
    }
};