'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _FloatingActionButton = require('material-ui/FloatingActionButton');

var _FloatingActionButton2 = _interopRequireDefault(_FloatingActionButton);

var _Tooltip = require('material-ui/internal/Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

var _transitions = require('material-ui/styles/transitions');

var _transitions2 = _interopRequireDefault(_transitions);

var _reactTextareaAutosize = require('react-textarea-autosize');

var _reactTextareaAutosize2 = _interopRequireDefault(_reactTextareaAutosize);

var _addInfoModeProps = require('@rubyapps/ruby-react-components/src/client/hoc/addInfoModeProps');

var _addInfoModeProps2 = _interopRequireDefault(_addInfoModeProps);

var _Textarea_style = require('./Textarea_style');

var _Textarea_style2 = _interopRequireDefault(_Textarea_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var RubyIcons = require('@rubyapps/ruby-icons');
var WandIcon = RubyIcons.iconGenerators['font-awesome']('wand-magic-sparkles');

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label;

//import fieldBaseStyles from '@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule';
//import textareaStyles from './Textarea.cssModule';
var fieldBaseStyles = process.browser ? require('@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule').default : {};
var textareaStyles = process.browser ? require('./Textarea.cssModule').default : {};
var styles = _extends({}, fieldBaseStyles, textareaStyles);


var getStyles = function getStyles(props, context, state) {
    var _context$muiTheme = context.muiTheme,
        baseTheme = _context$muiTheme.baseTheme,
        _context$muiTheme$tex = _context$muiTheme.textField,
        floatingLabelColor = _context$muiTheme$tex.floatingLabelColor,
        focusColor = _context$muiTheme$tex.focusColor,
        textColor = _context$muiTheme$tex.textColor,
        disabledTextColor = _context$muiTheme$tex.disabledTextColor,
        backgroundColor = _context$muiTheme$tex.backgroundColor,
        hintColor = _context$muiTheme$tex.hintColor,
        errorColor = _context$muiTheme$tex.errorColor;


    var styles = {
        root: {
            fontSize: 16,
            lineHeight: '24px',
            width: props.fullWidth ? '100%' : 256,
            height: (props.rows - 1) * 24 + (props.floatingLabelText ? 72 : 48),
            display: 'inline-block',
            position: 'relative',
            fontFamily: baseTheme.fontFamily,
            transition: _transitions2.default.easeOut('200ms', 'height')
        },
        error: {
            position: 'relative',
            bottom: -6,
            fontSize: 12,
            lineHeight: '12px',
            color: errorColor,
            transition: _transitions2.default.easeOut()
        },
        infoMode: {
            //border: 'none'
        }
    };

    _extends(styles.error, props.errorStyle);

    return styles;
};
var Textarea = _react2.default.createClass({
    displayName: 'Textarea',

    propTypes: _extends({
        label: PropTypes.string,
        value: PropTypes.string,
        suggestedMode: PropTypes.bool,
        isAutoPopulated: PropTypes.bool,
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        id: PropTypes.string,
        actions: PropTypes.object
    }, Label.propTypes),
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null
        };
    },
    _onTextareaChange: function _onTextareaChange(event) {
        this.props.actions.setField_withValue(event.target.value);
    },
    _conditionalStyles: function _conditionalStyles() {

        var muiTheme = this.context.muiTheme;

        var inactiveColor = muiTheme.palette.inactiveColor;


        return this.props.isAutoPopulated ? { color: inactiveColor } : {};
    }
    /*
            <TextareaField
                id={this.props.id}
                floatingLabelText={this.props.label}
                onChange={this._onTextareaChange}
                value={this.props.value}
                multiLine={true}
                fullWidth={true}
                rows={3}
                errorText={this.props.error ? this.props.error.message : null}
                disabled={this.props.locked}
            />
    */
    , _renderUseSuggestedButton: function _renderUseSuggestedButton() {
        var _React$createElement,
            _this = this,
            _React$createElement2,
            _React$createElement3,
            _React$createElement4;

        return this.props.suggestedValue && !this.props.value ? _react2.default.createElement(
            'div',
            (_React$createElement4 = { className: styles.toolbarButtonWrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Textarea'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Textarea'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Textarea'), _React$createElement4),
            _react2.default.createElement(_Tooltip2.default, (_React$createElement = {
                show: this.state && this.state.hoveredTooltip,
                label: "Use Suggested Text",
                horizontalPosition: 'right',
                verticalPosition: 'top',
                style: {
                    top: -6,
                    left: 0
                },
                'data-codecept-selector-node': 'Tooltip',
                'data-codecept-selector-file': 'Textarea'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Tooltip'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Textarea'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Tooltip'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Textarea'), _React$createElement)),
            _react2.default.createElement(
                _FloatingActionButton2.default,
                (_React$createElement3 = {
                    className: styles.toolbarButton,
                    mini: true,
                    onClick: function onClick() {
                        _this.setState({ hoveredTooltip: false });
                        _this.props.actions.setField_withValue(_this.props.suggestedValue);
                    },
                    onMouseEnter: function onMouseEnter() {
                        _this.setState({ hoveredTooltip: true });
                    },
                    onMouseLeave: function onMouseLeave() {
                        _this.setState({ hoveredTooltip: false });
                    },
                    'data-codecept-selector-node': 'FloatingActionButton',
                    'data-codecept-selector-file': 'Textarea'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'FloatingActionButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Textarea'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'FloatingActionButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Textarea'), _React$createElement3),
                _react2.default.createElement(WandIcon, (_React$createElement2 = { style: { fontSize: 20 }, 'data-codecept-selector-node': 'WandIcon',
                    'data-codecept-selector-file': 'Textarea'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'WandIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Textarea'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'WandIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Textarea'), _React$createElement2))
            )
        ) : null;
    },
    _renderToolbar: function _renderToolbar() {
        var _React$createElement5;

        return _react2.default.createElement(
            'div',
            (_React$createElement5 = { className: styles.toolbar, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Textarea'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Textarea'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Textarea'), _React$createElement5),
            this._renderUseSuggestedButton()
        );
    },
    render: function render() {
        var _extends2, _React$createElement7, _extends3, _React$createElement8, _React$createElement9;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var prepareStyles = this.context.muiTheme.prepareStyles;

        var styleObjectFromTheme = getStyles(this.props, this.context, this.state);

        var errorTextElement = null;
        if (this.props.error && this.props.error.message) {
            var _React$createElement6;

            errorTextElement = _react2.default.createElement(
                'div',
                (_React$createElement6 = { style: prepareStyles(styleObjectFromTheme.error), 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Textarea'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Textarea'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Textarea'), _React$createElement6),
                this.props.error.message
            );
        }

        var labelProps = _.pick(this.props, Object.keys(Label.propTypes));

        var textAreaStyle = _extends({}, _Textarea_style2.default.style || {}, styleObjectFromTheme.infoMode, _extends({}, this._conditionalStyles()));

        return _react2.default.createElement(
            'div',
            (_React$createElement9 = { className: styles.wrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Textarea'
            }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Textarea'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Textarea'), _React$createElement9),
            _react2.default.createElement(
                'label',
                (_React$createElement7 = { className: styles.label, 'data-codecept-selector-node': 'label',
                    'data-codecept-selector-file': 'Textarea'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Textarea'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Textarea'), _React$createElement7),
                _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                    'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'Textarea'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Textarea'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Textarea'), _extends2)))
            ),
            _react2.default.createElement(
                'div',
                (_React$createElement8 = { style: { position: 'relative' }, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Textarea'
                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Textarea'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Textarea'), _React$createElement8),
                _react2.default.createElement(_reactTextareaAutosize2.default, _extends({}, _Textarea_style2.default.textarea, {
                    style: textAreaStyle,
                    className: styles.textarea,
                    onChange: this.props.onChange || this._onTextareaChange,
                    disabled: this.props.locked,
                    value: this.props.value || '',
                    placeholder: this.props.suggestedValue || this.props.hintText
                }, _.get(this, 'props.infoModeProps.default'), (_extends3 = {
                    'data-codecept-selector-node': 'TextareaAutosize',
                    'data-codecept-selector-file': 'Textarea'
                }, _defineProperty(_extends3, 'data-codecept-selector-node', 'TextareaAutosize'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Textarea'), _defineProperty(_extends3, 'data-codecept-selector-node', 'TextareaAutosize'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Textarea'), _extends3))),
                this._renderToolbar()
            ),
            errorTextElement
        );
    }
});

exports.default = (0, _addInfoModeProps2.default)(Textarea);