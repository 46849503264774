'use strict';

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _reduxBatchedActions = require('redux-batched-actions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _require = require('redux'),
    combineReducers = _require.combineReducers;

var request = require('@rubyapps/ruby-superagent');
var urljoin = require('url-join');

function typesWithID(id) {
    return {
        CUSTOM_RESET_STATE: '@@ruby-app/' + id + '/CUSTOM_RESET_STATE',
        SET_LOCAL_STATE_CACHE_FOR_NAMESPACE: '@@ruby-app/' + id + '/SET_LOCAL_STATE_CACHE_FOR_NAMESPACE',
        SAVE_FORM_VALUE: '@@ruby-app/' + id + '/SAVE_FORM_VALUE',
        SAVE_LOCAL_STATE: '@@ruby-app/' + id + '/SAVE_LOCAL_STATE',
        SET_LOCAL_STATE: '@@ruby-app/' + id + '/SET_LOCAL_STATE' //# DEPRECATED 20170106, use mixin "replaceLocalState" instead
        , REPLACE_SAVED_LOCAL_STATE_BY_NAMESPACE: '@@ruby-app/' + id + '/REPLACE_SAVED_LOCAL_STATE_BY_NAMESPACE',
        REPLACE_DEFERRED_FORM_VALUE_BY_NAMESPACE: '@@ruby-app/' + id + '/REPLACE_DEFERRED_FORM_VALUE_BY_NAMESPACE',
        REPLACE_DEFERRED_FORM_ERROR_BY_NAMESPACE: '@@ruby-app/' + id + '/REPLACE_DEFERRED_FORM_ERROR_BY_NAMESPACE',
        REPLACE_DEFERRED_CLEAR_FORM_ERROR_BY_NAMESPACE: '@@ruby-app/' + id + '/REPLACE_DEFERRED_CLEAR_FORM_ERROR_BY_NAMESPACE',

        CLEAR_DEFERRED_FORM_VALUE_FOR_NAMESPACE: '@@ruby-app/' + id + '/CLEAR_DEFERRED_FORM_VALUE_FOR_NAMESPACE',
        CLEAR_DEFERRED_FORM_ERROR_FOR_NAMESPACE: '@@ruby-app/' + id + '/CLEAR_DEFERRED_FORM_ERROR_FOR_NAMESPACE',
        CLEAR_DEFERRED_CLEAR_FORM_ERROR_FOR_NAMESPACE: '@@ruby-app/' + id + '/CLEAR_DEFERRED_CLEAR_FORM_ERROR_FOR_NAMESPACE'
    };
}

var KEYS_TO_OMIT_FROM_LOCAL_STATE_CACHE = ['savedLocalStateByNamespace', 'savedLocalReducerByNamespace', 'savedFormValueByNamespace', 'deferredFormValueByNamespace', 'deferredFormErrorByNamespace', 'deferredClearFormErrorByNamespace'];

var generators = {
    //# NOTE: we're batching resetStore, and THUNKS don't work
    //# so you MUST override the rubyComponent.resetStore method and call on store.dispatch immediately 
    //# Hopefully, in the future, we can update how resetStore works and/or the batch plugin to 
    //# allow for batching thunks
    resetStore: function resetStore() {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES,
            generators = _getAction.generators;

        var selfSelector = this.getDefaultSelector();
        return function (dispatch, getState) {
            var applicationState = getState();
            var selfState = selfSelector(applicationState);
            var options = _lodash2.default.get(selfState, 'props.options', []);
            dispatch({
                type: TYPES.CUSTOM_RESET_STATE
            });
        };
    },

    setLocalStateCacheForNamespace: function setLocalStateCacheForNamespace(localState, namespace) {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES,
            generators = _getAction2.generators;

        return {
            type: TYPES.SET_LOCAL_STATE_CACHE_FOR_NAMESPACE,
            payload: {
                namespace: namespace,
                localState: localState
            }
        };
    }
    //# for namespace reconstitution
    , saveLocalStateForActiveNamespace: function saveLocalStateForActiveNamespace() {
        var _getAction3 = this.getAction(),
            TYPES = _getAction3.TYPES,
            generators = _getAction3.generators;

        var selfSelector = this.getDefaultSelector();
        var selfID = this.getID();
        var selfKey = this.props.key;

        var children = this.getChildren();
        var state = this.getState();
        var selfObjectValue = this.objectValueFromLocalState(state);
        var childrenIDs = children.map(function (child) {
            return child.getID();
        });
        //const localState = _.pick(selfObjectValue[selfID], childrenIDs);
        var localState = _lodash2.default.omit(state, KEYS_TO_OMIT_FROM_LOCAL_STATE_CACHE);

        var activeNamespace = _lodash2.default.get(state, ['fields', this.props.key, 'value']);

        return {
            type: TYPES.SAVE_LOCAL_STATE,
            payload: {
                namespace: activeNamespace,
                localState: localState
            }
        };
    },

    saveFormValueForActiveNamespace: function saveFormValueForActiveNamespace() {
        var _getAction4 = this.getAction(),
            TYPES = _getAction4.TYPES,
            generators = _getAction4.generators;

        var state = this.getState();
        var activeNamespace = _lodash2.default.get(state, ['fields', this.props.key, 'value']);
        var formValueForActiveNamespace = this.getFormValueForActiveNamespace_withLocalState(state);

        return {
            type: TYPES.SAVE_FORM_VALUE,
            payload: {
                namespace: activeNamespace,
                formValue: formValueForActiveNamespace
            }
        };
    },
    replaceSavedLocalStateByNamespace: function replaceSavedLocalStateByNamespace(savedLocalStateByNamespace) {
        var _getAction5 = this.getAction(),
            TYPES = _getAction5.TYPES,
            generators = _getAction5.generators;

        return {
            type: TYPES.REPLACE_SAVED_LOCAL_STATE_BY_NAMESPACE,
            payload: {
                savedLocalStateByNamespace: savedLocalStateByNamespace
            }
        };
    },
    replaceDeferredFormValueByNamespace: function replaceDeferredFormValueByNamespace(deferredFormValueByNamespace) {
        var _getAction6 = this.getAction(),
            TYPES = _getAction6.TYPES,
            generators = _getAction6.generators;

        return {
            type: TYPES.REPLACE_DEFERRED_FORM_VALUE_BY_NAMESPACE,
            payload: {
                deferredFormValueByNamespace: deferredFormValueByNamespace
            }
        };
    },
    replaceDeferredFormErrorByNamespace: function replaceDeferredFormErrorByNamespace(deferredFormErrorByNamespace) {
        var _getAction7 = this.getAction(),
            TYPES = _getAction7.TYPES,
            generators = _getAction7.generators;

        return {
            type: TYPES.REPLACE_DEFERRED_FORM_ERROR_BY_NAMESPACE,
            payload: {
                deferredFormErrorByNamespace: deferredFormErrorByNamespace
            }
        };
    },
    replaceDeferredClearFormErrorByNamespace: function replaceDeferredClearFormErrorByNamespace(deferredClearFormErrorByNamespace) {
        var _getAction8 = this.getAction(),
            TYPES = _getAction8.TYPES,
            generators = _getAction8.generators;

        return {
            type: TYPES.REPLACE_DEFERRED_CLEAR_FORM_ERROR_BY_NAMESPACE,
            payload: {
                deferredClearFormErrorByNamespace: deferredClearFormErrorByNamespace
            }
        };
    },
    setLocalStateForActiveNamespace: function setLocalStateForActiveNamespace() {
        var _this = this;

        var _getAction9 = this.getAction(),
            TYPES = _getAction9.TYPES,
            actions = _getAction9.generators;

        var selfSelector = this.getDefaultSelector();
        var selfID = this.getID();
        var state = this.getState();
        var children = this.getChildren();
        var activeNamespace = _lodash2.default.get(state, ['fields', this.props.key, 'value']);

        var localState = _lodash2.default.get(state, ['savedLocalStateByNamespace', activeNamespace]);
        var deferredFormValue = _lodash2.default.get(state, ['deferredFormValueByNamespace', activeNamespace]);
        var deferredFormError = _lodash2.default.get(state, ['deferredFormErrorByNamespace', activeNamespace]);
        var deferredClearFormError = _lodash2.default.get(state, ['deferredClearFormErrorByNamespace', activeNamespace]);

        //# NOTE: if received deferredFormError, we want to dispatch formValueToLocalState() for the errors AFTER recovering the localState
        //# NOTE: if we received deferreredFormValue, then we want to ignore the localState
        //# NOTE: the assumption could be that any namespaced value that has a localState cached should be preferred because the formValue being loaded should only happen on route load before localStates have been cached for other namespaces (thus the deferred formValues are preferred)

        var arrayOfActions_forReset = [];
        var collector_forReset = function collector_forReset(action) {
            arrayOfActions_forReset.push(action);
        };

        var arrayOfActions_forHydration = [];
        var collector_forHydration = function collector_forHydration(action) {
            arrayOfActions_forHydration.push(action);
        };
        if (localState) {
            var preppedLocalState = _lodash2.default.omit(localState, KEYS_TO_OMIT_FROM_LOCAL_STATE_CACHE);
            /* //# 20171130 - deprecated call. It's being handled below
            this.replaceLocalState(preppedLocalState, collector_forReset, (el) => {
                if (el.props.excludeFromNamespaces) { return false; }
                return true;
            });
            */
        } else {
            //# reset children values
            children.forEach(function (child) {
                child.resetStore && child.resetStore(collector_forReset, function (el) {
                    if (el.props.excludeFromNamespaces) {
                        return false;
                    }
                    return true;
                });
            });
        }

        var promiseTree = Promise.resolve(null);

        return function (dispatch) {

            //# dispatch the swap first
            dispatch((0, _reduxBatchedActions.batchActions)(arrayOfActions_forReset));

            setImmediate(function () {

                if (deferredFormValue) {
                    //deferredFormValue.subservice_extended_content = [];
                    promiseTree = _this.deferredFormValueToLocalState(deferredFormValue, collector_forHydration); //# TODO: the problematic thing is here
                    //# remove the deferredFormValue for the sepcific namespace
                    collector_forHydration(actions.clearDeferredFormValueForNamespace(activeNamespace));
                } else if (localState) {
                    var _preppedLocalState = _lodash2.default.omit(localState, KEYS_TO_OMIT_FROM_LOCAL_STATE_CACHE);
                    promiseTree = _this.replaceLocalStateAsync(_preppedLocalState, function (el) {
                        if (el.props.excludeFromNamespaces) {
                            return false;
                        }
                        return true;
                    });
                }

                //# Need to wait for promise to resolve
                promiseTree.then(function () {

                    arrayOfActions_forHydration.length && dispatch((0, _reduxBatchedActions.batchActions)(arrayOfActions_forHydration));

                    setImmediate(function () {
                        var delayedArrayOfActions_forClear = [];
                        var delayedCollector_forClear = function delayedCollector_forClear(action) {
                            delayedArrayOfActions_forClear.push(action);
                        };

                        var delayedArrayOfActions_forError = [];
                        var delayedCollector_forError = function delayedCollector_forError(action) {
                            delayedArrayOfActions_forError.push(action);
                        };

                        if (deferredClearFormError) {
                            _this.deferredClearErrorsWithKeys(delayedCollector_forClear, deferredClearFormError);
                            delayedCollector_forClear(actions.clearDeferredClearFormErrorForNamespace(activeNamespace));
                            dispatch((0, _reduxBatchedActions.batchActions)(delayedArrayOfActions_forClear));
                        }

                        if (deferredFormError) {
                            _this.deferredFormValueToLocalState(deferredFormError, delayedCollector_forError, true);
                            //# remove the deferredFormError for the sepcific namespace
                            delayedCollector_forError(actions.clearDeferredFormErrorForNamespace(activeNamespace));
                            dispatch((0, _reduxBatchedActions.batchActions)(delayedArrayOfActions_forError));
                        }

                        //# NOTE: a handleful of other components calls on refreshParentErrors()
                        //# when switching namespaces, and because it happens asynchronously
                        //# before we finish hydrating the errors, it clears out the saved
                        //# error for the namespace selector
                        //# we need to refresh the parent errors
                        _this.refreshParentErrors();
                    });
                });
            });
        };
    },
    clearDeferredFormValueForNamespace: function clearDeferredFormValueForNamespace(namespace) {
        var _getAction10 = this.getAction(),
            TYPES = _getAction10.TYPES,
            generators = _getAction10.generators;

        return {
            type: TYPES.CLEAR_DEFERRED_FORM_VALUE_FOR_NAMESPACE,
            payload: {
                namespace: namespace
            }
        };
    },
    clearDeferredFormErrorForNamespace: function clearDeferredFormErrorForNamespace(namespace) {
        var _getAction11 = this.getAction(),
            TYPES = _getAction11.TYPES,
            generators = _getAction11.generators;

        return {
            type: TYPES.CLEAR_DEFERRED_FORM_ERROR_FOR_NAMESPACE,
            payload: {
                namespace: namespace
            }
        };
    },
    clearDeferredClearFormErrorForNamespace: function clearDeferredClearFormErrorForNamespace(namespace) {
        var _getAction12 = this.getAction(),
            TYPES = _getAction12.TYPES,
            generators = _getAction12.generators;

        return {
            type: TYPES.CLEAR_DEFERRED_CLEAR_FORM_ERROR_FOR_NAMESPACE,
            payload: {
                namespace: namespace
            }
        };
    },

    requestNamespaceChangeToNamespace: function requestNamespaceChangeToNamespace(namespace) {
        var _this2 = this;

        var _getAction13 = this.getAction(),
            TYPES = _getAction13.TYPES,
            actions = _getAction13.generators;

        var selfKey = this.key();

        return function (dispatch, getState) {
            var selfState = _this2.getState();
            var defaultNamespace = _this2.getDefaultNamespace();
            var activeNamespace = _lodash2.default.get(selfState, ['fields', selfKey, 'value'], defaultNamespace);

            if (namespace != activeNamespace) {
                // Clearing cache for memoized_propertyExistsForKeypath to fix: https://gitlab.intranet.rubensteintech.com/project/jenner.com/-/issues/477
                var parentForm = _this2.getParentFormComponent && _this2.getParentFormComponent();
                var memoized_propertyExistsForKeypath = _lodash2.default.get(parentForm, 'getStatefulCacheForKey') && parentForm.getStatefulCacheForKey('memoized_propertyExistsForKeypath');
                memoized_propertyExistsForKeypath && memoized_propertyExistsForKeypath.clear();

                dispatch(actions.saveFormValueForActiveNamespace());
                dispatch(actions.saveLocalStateForActiveNamespace());
                dispatch(actions.setFieldValueByKey(namespace, selfKey));
                dispatch(actions.setLocalStateForActiveNamespace());
            }
        };
    }
};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};