'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _React$createClass;

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Table = require('material-ui/Table');

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _Toggle = require('material-ui/Toggle');

var _Toggle2 = _interopRequireDefault(_Toggle);

var _reactTinymce = require('@rubyapps/react-tinymce');

var _reactTinymce2 = _interopRequireDefault(_reactTinymce);

var _routeParser = require('route-parser');

var _routeParser2 = _interopRequireDefault(_routeParser);

var _Multiselect = require('./Multiselect');

var _Multiselect2 = _interopRequireDefault(_Multiselect);

var _PropTable_style = require('./PropTable_style');

var _PropTable_style2 = _interopRequireDefault(_PropTable_style);

var _PropTable = require('./PropTable.cssModule');

var _PropTable2 = _interopRequireDefault(_PropTable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var ReactDOM = require('react-dom');

var PropTypes = React.PropTypes;

var TextField__propTypeKeys = Object.keys(_TextField2.default.propTypes);


var rowHeight = 30;
var underlineShow = false;
var textFieldProps = {
    underlineShow: underlineShow,
    //lineHeight: rowHeight, //# NOTE: invalid prop
    fullWidth: true,
    inputStyle: {
        textOverflow: 'ellipsis'
    },
    style: {
        height: 'auto',
        width: '100%'
    }
};

var onChangeDataTypeGenerator = function onChangeDataTypeGenerator(visibleRowProps, value) {
    var onChangeDataType = function onChangeDataType(e, val) {
        var dataTypeProp = visibleRowProps.filter(function (prop) {
            return prop.propKey == 'data_type';
        })[0];
        if (dataTypeProp) {
            dataTypeProp.fieldProps.onChange(e, value);
        }
    };

    return onChangeDataType;
};

var PropTable = React.createClass((_React$createClass = {
    displayName: 'PropTable',

    propTypes: {
        disabled: PropTypes.bool,
        contentTemplateOptions: PropTypes.arrayOf(PropTypes.object),
        rubyClientInfo: PropTypes.object,
        rowProps: PropTypes.arrayOf(PropTypes.shape(_extends({
            label: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            required: PropTypes.bool,
            fieldProps: PropTypes.shape({
                errorText: PropTypes.string,
                value: PropTypes.any,
                isAutoPopulated: PropTypes.bool,
                onChange: PropTypes.func.isRequired
            })
        }, _Multiselect2.default.propTypes)))
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            contentTemplateOptions: [],
            rubyClientInfo: {}
        };
    },
    _renderTextField: function _renderTextField(prop) {
        var _extends2, _React$createElement;

        var TextField__propsFromFieldProps = _lodash2.default.pick(prop.fieldProps, TextField__propTypeKeys);
        if (_lodash2.default.isNil(TextField__propsFromFieldProps.value)) {
            TextField__propsFromFieldProps.value = '';
        }

        var muiTheme = this.context.muiTheme;
        var inactiveColor = muiTheme.palette.inactiveColor;


        var conditionalProps = prop.fieldProps.isAutoPopulated ? {
            inputStyle: _extends({}, textFieldProps.inputStyle, {
                color: inactiveColor
            })
        } : {};

        return React.createElement(
            'div',
            (_React$createElement = {
                style: _extends({}, _PropTable_style2.default.CellContentWrapper.style, prop.style),
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'PropTable'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'PropTable'), _React$createElement),
            React.createElement(_TextField2.default, _extends({}, textFieldProps, {
                key: prop.propKey,
                name: prop.propKey,
                disabled: this.props.disabled
            }, conditionalProps, TextField__propsFromFieldProps, (_extends2 = {
                'data-codecept-selector-node': 'TextField',
                'data-codecept-selector-file': 'PropTable'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends2, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends2, 'data-codecept-selector-file', 'PropTable'), _extends2)))
        );
    },
    _renderToggle: function _renderToggle(prop) {
        var _extends3, _extends4;

        var ToggleProps = _lodash2.default.omit(prop.fieldProps, ['onChange', 'value']);
        ToggleProps.onToggle = prop.fieldProps.onChange;
        ToggleProps.toggled = prop.fieldProps.value;

        var valueMatchesDataTypes = checkIfValueMatchesDataTypes(prop.fieldProps.value, ['boolean', 'undefined']);

        return React.createElement(
            'div',
            _extends({}, _PropTable_style2.default.CellContentWrapper, (_extends4 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'PropTable'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'div'), _defineProperty(_extends4, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_extends4, 'data-codecept-selector-node', 'div'), _defineProperty(_extends4, 'data-codecept-selector-file', 'PropTable'), _extends4)),
            React.createElement(_Toggle2.default, _extends({}, ToggleProps, {
                disabled: !valueMatchesDataTypes
            }, _lodash2.default.merge({}, _PropTable_style2.default.Value, {
                style: {
                    paddingLeft: 4,
                    width: 'auto'
                }
            }), (_extends3 = {
                'data-codecept-selector-node': 'Toggle',
                'data-codecept-selector-file': 'PropTable'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_extends3, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_extends3, 'data-codecept-selector-file', 'PropTable'), _extends3)))
        );
    },
    getStyles: function getStyles(props, context) {
        var accentColor = context.muiTheme.dropDownMenu.accentColor;
        return {
            icon: {
                fill: accentColor
            }
        };
    }
}, _defineProperty(_React$createClass, 'contextTypes', {
    muiTheme: PropTypes.object.isRequired
}), _defineProperty(_React$createClass, '_setDropdownWidth', function _setDropdownWidth() {
    var _this = this;

    var dropdownNode = ReactDOM.findDOMNode(this.refs.valueColumn);
    dropdownNode && setTimeout(function () {
        _this.setState({ dropdownWidth: dropdownNode.clientWidth });
    });
}), _defineProperty(_React$createClass, 'componentDidMount', function componentDidMount() {
    this._setDropdownWidth();
    var debouncedHandleResize = _lodash2.default.debounce(this.handleResize, 500);
    this._debouncedHandleResize = debouncedHandleResize;

    window.addEventListener('resize', debouncedHandleResize);
}), _defineProperty(_React$createClass, 'componentWillUnmount', function componentWillUnmount() {
    window.removeEventListener('resize', this._debouncedHandleResize);
}), _defineProperty(_React$createClass, 'handleResize', function handleResize() {
    this._setDropdownWidth();
}), _defineProperty(_React$createClass, '_renderMultiselect', function _renderMultiselect(prop) {
    var _extends5;

    var styles = this.getStyles(this.props, this.context);

    return React.createElement(_Multiselect2.default, _extends({}, prop, (_extends5 = {
        disabled: this.props.disabled,
        styles: styles,
        dropdownWidth: _lodash2.default.get(this, 'state.dropdownWidth'),
        'data-codecept-selector-node': 'Multiselect',
        'data-codecept-selector-file': 'PropTable'
    }, _defineProperty(_extends5, 'data-codecept-selector-node', 'Multiselect'), _defineProperty(_extends5, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_extends5, 'data-codecept-selector-node', 'Multiselect'), _defineProperty(_extends5, 'data-codecept-selector-file', 'PropTable'), _extends5)));
}), _defineProperty(_React$createClass, '_renderEndpointBuilder', function _renderEndpointBuilder(prop, visibleRowProps) {
    var _this2 = this,
        _React$createElement2;

    var styles = this.getStyles(this.props, this.context);
    var url = prop.fieldProps.value;

    var _props$rubyClientInfo = this.props.rubyClientInfo,
        client = _props$rubyClientInfo.client,
        subsite = _props$rubyClientInfo.subsite;

    var contentRoute = new _routeParser2.default('/ruby/api/v2/content/(:contentTypes/)options?ruby_client_fk=' + client + '&content_subsite_fk=' + subsite);
    var otherRoute = new _routeParser2.default('/ruby/api/v2/:model/options?ruby_client_fk=' + client);

    var _ref = contentRoute.match(url) || {},
        contentTypes = _ref.contentTypes;

    var contentTypesArr = contentTypes && contentTypes.split(',');

    var onChangeDataType_toNumber = onChangeDataTypeGenerator(visibleRowProps, 'number');
    var onChangeDataType_toString = onChangeDataTypeGenerator(visibleRowProps, 'string');

    var builderTypeOptions = [{
        primaryText: '- Select a remote source -',
        onSelectValue: null,
        onChange: onChangeDataType_toString
    }, {
        primaryText: 'Content',
        isSelected_fromUrl: function isSelected_fromUrl(url) {
            var match = contentRoute.match(url);
            var contentTypes = match.contentTypes;

            var contentTypesArr = contentTypes && contentTypes.split(',') || [];
            var allContentTypesAreKnown = _lodash2.default.every(contentTypesArr.map(function (contentType) {
                return _lodash2.default.find(_this2.props.contentTemplateOptions, { value: contentType });
            }));
            return match && allContentTypesAreKnown;
        },
        onSelectValue: contentRoute.reverse({}),
        onChange: onChangeDataType_toNumber,
        secondaryField: this._renderMultiselect({
            options: this.props.contentTemplateOptions,
            propKey: prop.propKey + '_multiselect',
            multiple: true,
            fieldProps: {
                value: contentTypesArr,
                style: _PropTable_style2.default.additionalField.style,
                errorText: _lodash2.default.get(prop, 'fieldProps.errorText'),
                onChange: function onChange(e, value) {
                    var newURL = contentRoute.reverse({ contentTypes: value.toString() });
                    prop.fieldProps.onChange(e, newURL);
                }
            }
        })
    }, {
        primaryText: 'User',
        isSelected_fromUrl: function isSelected_fromUrl(url) {
            return otherRoute.reverse({ model: 'Users' }) === url;
        },
        onSelectValue: otherRoute.reverse({ model: 'Users' }),
        onChange: onChangeDataType_toNumber
    }, {
        primaryText: 'Role',
        isSelected_fromUrl: function isSelected_fromUrl(url) {
            return otherRoute.reverse({ model: 'RubyRoles' }) === url;
        },
        onSelectValue: otherRoute.reverse({ model: 'RubyRoles' }),
        onChange: onChangeDataType_toNumber
    }, {
        primaryText: 'Custom URL',
        fallback: true,
        onSelectValue: '/ruby/api/v2/',
        onChange: onChangeDataType_toString,
        secondaryField: this._renderTextField({
            style: _PropTable_style2.default.additionalField.style,
            propKey: prop.propKey + '_custom',
            fieldProps: {
                value: url,
                errorText: _lodash2.default.get(prop, 'fieldProps.errorText'),
                onChange: function onChange(e) {
                    prop.fieldProps.onChange(e, e.target.value);
                }
            }
        })
    }].map(function (option, idx) {
        return _extends({ value: idx.toString() }, option);
    });

    var builderTypeSelectedOption = _lodash2.default.isNil(url) ? builderTypeOptions[0] : _lodash2.default.find(builderTypeOptions, function (option) {
        return option.isSelected_fromUrl && option.isSelected_fromUrl(url);
    }) || _lodash2.default.find(builderTypeOptions, { fallback: true });

    var builderTypeValue = builderTypeSelectedOption.value;

    var builderTypeSelect = this._renderMultiselect({
        options: builderTypeOptions,
        fieldProps: {
            value: builderTypeValue,
            onChange: function onChange(e, value) {
                var selectedOption = _lodash2.default.find(builderTypeOptions, { value: value });
                var newURL = selectedOption.onSelectValue;
                prop.fieldProps.onChange(e, newURL, { skipValidation: true });

                selectedOption.onChange && selectedOption.onChange(e, value);
            }
        }
    });

    var secondaryField = _lodash2.default.find(builderTypeOptions, { value: builderTypeValue }).secondaryField;

    return React.createElement(
        'div',
        (_React$createElement2 = {
            style: {
                display: 'flex',
                flexWrap: 'wrap'
            },
            'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'PropTable'
        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'PropTable'), _React$createElement2),
        builderTypeSelect,
        secondaryField
    );
}), _defineProperty(_React$createClass, 'render', function render() {
    var _extends6,
        _extends7,
        _extends8,
        _extends9,
        _extends10,
        _React$createElement3,
        _this3 = this,
        _React$createElement5,
        _extends18;

    var visibleRowProps = _lodash2.default.get(this, 'props.rowProps', []).filter(function (prop) {
        return !prop.hidden;
    });
    return React.createElement(
        _Table.Table,
        _extends({
            selectable: false,
            displayBorder: false,
            fixedHeader: true
        }, _PropTable_style2.default.Table, (_extends18 = {
            'data-codecept-selector-node': 'Table',
            'data-codecept-selector-file': 'PropTable'
        }, _defineProperty(_extends18, 'data-codecept-selector-node', 'Table'), _defineProperty(_extends18, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_extends18, 'data-codecept-selector-node', 'Table'), _defineProperty(_extends18, 'data-codecept-selector-file', 'PropTable'), _extends18)),
        React.createElement(
            _Table.TableHeader,
            (_React$createElement3 = {
                key: 1,
                displaySelectAll: false,
                adjustForCheckbox: false,
                'data-codecept-selector-node': 'TableHeader',
                'data-codecept-selector-file': 'PropTable'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'TableHeader'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'TableHeader'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'PropTable'), _React$createElement3),
            React.createElement(
                _Table.TableRow,
                _extends({
                    key: 1
                }, _PropTable_style2.default.TableRow, (_extends10 = {
                    'data-codecept-selector-node': 'TableRow',
                    'data-codecept-selector-file': 'PropTable'
                }, _defineProperty(_extends10, 'data-codecept-selector-node', 'TableRow'), _defineProperty(_extends10, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_extends10, 'data-codecept-selector-node', 'TableRow'), _defineProperty(_extends10, 'data-codecept-selector-file', 'PropTable'), _extends10)),
                React.createElement(
                    _Table.TableHeaderColumn,
                    _extends({
                        key: 1
                    }, _PropTable_style2.default.TableHeaderColumnLeft, (_extends7 = {
                        'data-codecept-selector-node': 'TableHeaderColumn',
                        'data-codecept-selector-file': 'PropTable'
                    }, _defineProperty(_extends7, 'data-codecept-selector-node', 'TableHeaderColumn'), _defineProperty(_extends7, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_extends7, 'data-codecept-selector-node', 'TableHeaderColumn'), _defineProperty(_extends7, 'data-codecept-selector-file', 'PropTable'), _extends7)),
                    React.createElement(
                        'div',
                        _extends({}, _PropTable_style2.default.HeaderContentWrapper, (_extends6 = {
                            'data-codecept-selector-node': 'div',
                            'data-codecept-selector-file': 'PropTable'
                        }, _defineProperty(_extends6, 'data-codecept-selector-node', 'div'), _defineProperty(_extends6, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_extends6, 'data-codecept-selector-node', 'div'), _defineProperty(_extends6, 'data-codecept-selector-file', 'PropTable'), _extends6)),
                        'Property'
                    )
                ),
                React.createElement(
                    _Table.TableHeaderColumn,
                    _extends({
                        key: 2,
                        ref: 'valueColumn'
                    }, _PropTable_style2.default.TableHeaderColumnRight, (_extends9 = {
                        'data-codecept-selector-node': 'TableHeaderColumn',
                        'data-codecept-selector-file': 'PropTable'
                    }, _defineProperty(_extends9, 'data-codecept-selector-node', 'TableHeaderColumn'), _defineProperty(_extends9, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_extends9, 'data-codecept-selector-node', 'TableHeaderColumn'), _defineProperty(_extends9, 'data-codecept-selector-file', 'PropTable'), _extends9)),
                    React.createElement(
                        'div',
                        _extends({}, _PropTable_style2.default.HeaderContentWrapper, (_extends8 = {
                            'data-codecept-selector-node': 'div',
                            'data-codecept-selector-file': 'PropTable'
                        }, _defineProperty(_extends8, 'data-codecept-selector-node', 'div'), _defineProperty(_extends8, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_extends8, 'data-codecept-selector-node', 'div'), _defineProperty(_extends8, 'data-codecept-selector-file', 'PropTable'), _extends8)),
                        'Setting'
                    )
                )
            )
        ),
        React.createElement(
            _Table.TableBody,
            (_React$createElement5 = {
                key: 1,
                displayRowCheckbox: false,
                stripedRows: false,
                'data-codecept-selector-node': 'TableBody',
                'data-codecept-selector-file': 'PropTable'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'TableBody'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'TableBody'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'PropTable'), _React$createElement5),
            visibleRowProps.map(function (prop, idx) {
                var _extends11, _extends12, _React$createElement4, _extends13, _extends14, _extends15, _extends16, _extends17;

                var isLastRow = visibleRowProps.length === idx + 1;
                var valueColumn = void 0;

                var value = _lodash2.default.get(prop, ['fieldProps', 'value']);

                switch (prop.type) {
                    case 'number':
                    case 'text':
                        valueColumn = _this3._renderTextField(prop);
                        break;
                    case 'textarea':
                        var textValue = void 0;
                        if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object') {
                            textValue = value.map(function (option) {
                                return option.value;
                            }).join("\n");
                        } else {
                            textValue = value || '';
                        }
                        var lineCount = textValue.split(/\r\n|\r|\n/).length;
                        var TextField__propsFromFieldProps = _lodash2.default.pick(prop.fieldProps, TextField__propTypeKeys);

                        valueColumn = React.createElement(
                            'div',
                            _extends({}, _PropTable_style2.default.CellContentWrapper, (_extends12 = {
                                'data-codecept-selector-node': 'div',
                                'data-codecept-selector-file': 'PropTable'
                            }, _defineProperty(_extends12, 'data-codecept-selector-node', 'div'), _defineProperty(_extends12, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_extends12, 'data-codecept-selector-node', 'div'), _defineProperty(_extends12, 'data-codecept-selector-file', 'PropTable'), _extends12)),
                            React.createElement(_TextField2.default, _extends({
                                key: prop.propKey,
                                name: prop.propKey,
                                underlineShow: underlineShow,
                                disabled: _this3.props.disabled,
                                rows: lineCount,
                                textareaStyle: { height: rowHeight * lineCount },
                                multiLine: prop.type === 'textarea'
                            }, TextField__propsFromFieldProps, {
                                value: textValue
                            }, (_extends11 = {
                                'data-codecept-selector-node': 'TextField',
                                'data-codecept-selector-file': 'PropTable'
                            }, _defineProperty(_extends11, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends11, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_extends11, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends11, 'data-codecept-selector-file', 'PropTable'), _extends11)))
                        );
                        break;
                    case 'richtext':
                        valueColumn = React.createElement(
                            'div',
                            _extends({}, _PropTable_style2.default.RichTextCellContentWrapper, (_extends13 = {
                                'data-codecept-selector-node': 'div',
                                'data-codecept-selector-file': 'PropTable'
                            }, _defineProperty(_extends13, 'data-codecept-selector-node', 'div'), _defineProperty(_extends13, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_extends13, 'data-codecept-selector-node', 'div'), _defineProperty(_extends13, 'data-codecept-selector-file', 'PropTable'), _extends13)),
                            React.createElement(_reactTinymce2.default, (_React$createElement4 = {
                                className: _PropTable2.default.TinyMCE,
                                key: prop.propKey,
                                id: prop.propKey,
                                content: value,
                                config: {
                                    plugins: 'lists advlist code fullscreen',
                                    menubar: false,
                                    branding: false,
                                    toolbar: 'removeformat | bold italic underline' + ' | alignleft aligncenter alignright' + ' | numlist bullist' + ' | indent outdent' + ' | code | fullscreen'
                                },
                                onKeyup: prop.fieldProps.onChange,
                                onChange: prop.fieldProps.onChange,
                                onSetContent: prop.fieldProps.onChange,
                                'data-codecept-selector-node': 'TinyMCE',
                                'data-codecept-selector-file': 'PropTable'
                            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'TinyMCE'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'TinyMCE'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'PropTable'), _React$createElement4))
                        );
                        break;
                    case 'dropdown':
                        valueColumn = _this3._renderMultiselect(_extends({ multiple: false }, prop));
                        break;
                    case 'multiselect':
                        valueColumn = _this3._renderMultiselect(_extends({ multiple: true }, prop));
                        break;
                    case 'endpoint_builder':
                        valueColumn = _this3._renderEndpointBuilder(prop, visibleRowProps);
                        break;
                    case 'toggle':
                        valueColumn = _this3._renderToggle(prop);
                        break;
                }

                return React.createElement(
                    _Table.TableRow,
                    _extends({
                        key: 'Row:' + idx,
                        displayBorder: true
                    }, isLastRow ? _PropTable_style2.default.TableRowLast : _PropTable_style2.default.TableRow, (_extends17 = {
                        'data-codecept-selector-node': 'TableRow',
                        'data-codecept-selector-file': 'PropTable'
                    }, _defineProperty(_extends17, 'data-codecept-selector-node', 'TableRow'), _defineProperty(_extends17, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_extends17, 'data-codecept-selector-node', 'TableRow'), _defineProperty(_extends17, 'data-codecept-selector-file', 'PropTable'), _extends17)),
                    React.createElement(
                        _Table.TableRowColumn,
                        _extends({
                            key: 'Col:0'
                        }, _PropTable_style2.default.TableRowColumnLeft_fromRequired(_lodash2.default.get(prop, 'constraints.required')), (_extends15 = {
                            'data-codecept-selector-node': 'TableRowColumn',
                            'data-codecept-selector-file': 'PropTable'
                        }, _defineProperty(_extends15, 'data-codecept-selector-node', 'TableRowColumn'), _defineProperty(_extends15, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_extends15, 'data-codecept-selector-node', 'TableRowColumn'), _defineProperty(_extends15, 'data-codecept-selector-file', 'PropTable'), _extends15)),
                        React.createElement(
                            'div',
                            _extends({}, _PropTable_style2.default.CellContentWrapper, (_extends14 = {
                                'data-codecept-selector-node': 'div',
                                'data-codecept-selector-file': 'PropTable'
                            }, _defineProperty(_extends14, 'data-codecept-selector-node', 'div'), _defineProperty(_extends14, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_extends14, 'data-codecept-selector-node', 'div'), _defineProperty(_extends14, 'data-codecept-selector-file', 'PropTable'), _extends14)),
                            prop.label
                        )
                    ),
                    React.createElement(
                        _Table.TableRowColumn,
                        _extends({
                            key: 'Col:1'
                        }, _PropTable_style2.default.TableRowColumnRight, (_extends16 = {
                            'data-codecept-selector-node': 'TableRowColumn',
                            'data-codecept-selector-file': 'PropTable'
                        }, _defineProperty(_extends16, 'data-codecept-selector-node', 'TableRowColumn'), _defineProperty(_extends16, 'data-codecept-selector-file', 'PropTable'), _defineProperty(_extends16, 'data-codecept-selector-node', 'TableRowColumn'), _defineProperty(_extends16, 'data-codecept-selector-file', 'PropTable'), _extends16)),
                        valueColumn
                    )
                );
            })
        )
    );
}), _React$createClass));

function checkIfValueMatchesDataTypes(value, dataTypes) {
    var valueDataType = typeof value === 'undefined' ? 'undefined' : _typeof(value);
    var valueMatchesDataTypes = _lodash2.default.castArray(dataTypes).includes(valueDataType);

    return valueMatchesDataTypes;
}

exports.default = PropTable;