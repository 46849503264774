'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _action = require('./action');

var _action2 = _interopRequireDefault(_action);

var _reducer = require('./reducer');

var _reducer2 = _interopRequireDefault(_reducer);

var _TFAConnector = require('./reactComponents/TFAConnector');

var _TFAConnector2 = _interopRequireDefault(_TFAConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Promise = require('bluebird');
var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var FieldForm = require('@rubyapps/ruby-component-field-form');

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var RubyComponentCurrentUser = require('@rubyapps/ruby-component-current-user');
var RubyComponentFrontendSettings = require('@rubyapps/ruby-component-frontend-settings');

var RCtfa = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {
        //, label: PropTypes.string
        keyPrefix: PropTypes.string
        //, constraints: PropTypes.object
    },
    componentName: componentName,
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getSelfStateSelector();

        var currentUserID = this.props.currentUserID || RubyComponentCurrentUser.CONSTANTS.COMPONENT_NAME;
        var currentUser = root.findDescendentByID(currentUserID);
        var currentUserSelector = currentUser.getSelfStateSelector();

        var frontendSettingsID = this.props.frontendSettingsID || RubyComponentFrontendSettings.CONSTANTS.COMPONENT_NAME;
        var frontendSettingsComponent = root.findDescendentByID(frontendSettingsID);
        var frontendSettingsSelector = frontendSettingsComponent.getDefaultSelector();

        var parentForm = this.findAncestorBy(function (module) {
            return module.componentName == FieldForm.componentName;
        });
        var usernameComponent = parentForm.findDescendentBy(function (module) {
            return module.props.key == 'username';
        });
        var usernameSelector = usernameComponent.getDefaultSelector();

        return {
            selfSelector: selfSelector,
            currentUserSelector: currentUserSelector,
            frontendSettingsSelector: frontendSettingsSelector,
            usernameSelector: usernameSelector
        };
    },
    getDefaultProps: function getDefaultProps(props) {
        return CONSTANTS.GET_DEFAULT_PROPS(props);
    },
    getInitialState: function getInitialState() {
        var value = void 0;

        var frontendSettings = this.getDependencies().frontendSettingsSelector(this.getStore().getState());

        return {
            fields: {
                enabled: {
                    value: frontendSettings["feat:MFA:required"] ? true : null,
                    error: null,
                    label: null
                },
                registered: {
                    value: null,
                    error: null,
                    label: null
                },
                secret: {
                    value: null,
                    error: null,
                    label: null
                },
                token: {
                    value: null,
                    error: null,
                    label: null
                }
            }
        };
    },
    action: _action2.default,
    reducer: _reducer2.default,
    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector,
            currentUserSelector = _getDependencies.currentUserSelector,
            frontendSettingsSelector = _getDependencies.frontendSettingsSelector,
            usernameSelector = _getDependencies.usernameSelector;

        return {
            self: selfSelector(state),
            user: currentUserSelector(state),
            frontendSettings: frontendSettingsSelector(state),
            routing: state.routing //# need to update based on routing changes
            , fieldProps: this.fieldProps_fromState_andOwnProps(state, this.props),
            tfaKeyLabelInfo: usernameSelector(state)
        };
    },
    getReactClass: function getReactClass() {
        return _TFAConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var TFAComponent = this.getReactClass();

        return React.createElement(TFAComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'TFAComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TFAComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TFAComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    _formValueToLocalState: function _formValueToLocalState(formValue, dispatch, isError, entireFormValue) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        var selfID = this.getID();
        var selfKey = this.props.keyPrefix;

        //# support omit field picker
        var shouldOmitField = _.isFunction(options.omitFieldPicker) && options.omitFieldPicker.apply(options, [this].concat(Array.prototype.slice.call(arguments)));
        if (shouldOmitField) {
            return undefined;
        }

        var action = this.getAction();
        var _action$generators = action.generators,
            setFieldValueByKey = _action$generators.setFieldValueByKey,
            setFieldErrorMessageByKey = _action$generators.setFieldErrorMessageByKey;


        var enabled = _.get(formValue, qualifyTFAComponentKey(selfKey, 'enabled'), false);
        var registered = _.get(formValue, qualifyTFAComponentKey(selfKey, 'registered'), false);
        var secret = _.get(formValue, qualifyTFAComponentKey(selfKey, 'secret'), null); //# NOTE: we should not expect secret anymore
        var token = _.get(formValue, qualifyTFAComponentKey(selfKey, 'token'), null);

        var promiseArr = [];

        if (enabled) {
            // not expecting validation errors for this
            promiseArr.push(dispatch(setFieldValueByKey(enabled, 'enabled', true)));
        }

        if (registered != null) {
            // not expecting validation errors for this
            promiseArr.push(dispatch(setFieldValueByKey(registered, 'registered', true)));
        }

        if (secret) {
            // not expecting validation errors for this
            console.error("Server should not be returning mfa secret anymore");
            promiseArr.push(dispatch(setFieldValueByKey(secret, 'secret', true)));
        }

        if (isError && token) {
            promiseArr.push(dispatch(setFieldErrorMessageByKey(token, 'token')));
        }

        return Promise.all(promiseArr);
    },
    _formValueFromLocalState: function _formValueFromLocalState(selfState, isError) {
        var predicateFormatter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (value) {
            return value;
        };

        var _extends3;

        var entireState = arguments[3];
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        if (selfState == undefined) {
            return undefined;
        }
        var selfID = this.getID(); //# TODO: change cause ID isn't unique enough
        var selfKey = this.props.keyPrefix;

        var registered = predicateFormatter(_.get(selfState, 'fields.registered.value', null));
        return _extends((_extends3 = {}, _defineProperty(_extends3, qualifyTFAComponentKey(selfKey, 'enabled'), predicateFormatter(_.get(selfState, 'fields.enabled.value', false))), _defineProperty(_extends3, qualifyTFAComponentKey(selfKey, 'secret'), predicateFormatter(_.get(selfState, 'fields.secret.value', null))), _defineProperty(_extends3, qualifyTFAComponentKey(selfKey, 'token'), predicateFormatter(_.get(selfState, 'fields.token.value', null))), _extends3), registered ? {} //# never allow registered to be set to true
        : _defineProperty({}, qualifyTFAComponentKey(selfKey, 'registered'), registered));
    },
    CONSTANTS: CONSTANTS
});

module.exports = RCtfa;

function qualifyTFAComponentKey(qualifier, key) {
    return qualifier + '_' + key;
}