'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _OpenGraphConnector = require('./reactComponents/OpenGraphConnector');

var _OpenGraphConnector2 = _interopRequireDefault(_OpenGraphConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');
var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');

var FieldText = require('@rubyapps/ruby-component-field-text');
var FieldTextarea = require('@rubyapps/ruby-component-field-textarea');

var childrenFormModule = require('../common/childrenForm');

var RCOpenGraph = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {} //# some are already included by the baseFieldMixin
    , componentName: componentName,
    getDefaultProps: function getDefaultProps(props) {

        return {
            childrenPropsByKey: childrenFormModule.defaultChildrenPropsByKeyFromProps(props)
        };
    },
    dependencies: function dependencies() {
        var selfSelector = this.getDefaultSelector();

        return {
            selfSelector: selfSelector
        };
    },
    children: function children() {
        var createRubyComponentsFromFormJS = require('@rubyapps/ruby-component-builder').default;

        var parentID = this.getID();
        var childrenForm = childrenFormModule.childrenFormFromProps(this.props);
        return childrenForm.map(function (childForm) {
            return createRubyComponentsFromFormJS(childForm, parentID);
        });
    },
    getReactClass: function getReactClass() {
        return _OpenGraphConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var OpenGraphComponent = this.getReactClass();

        return React.createElement(OpenGraphComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'OpenGraphComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'OpenGraphComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'OpenGraphComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS
});

module.exports = RCOpenGraph;