'use strict';

var formValue__repeater__office = require('./testData/formValue__repeater__office');
var sweeperError__repeater__office = require('./testData/sweeperError__repeater__office');

var tests = {
    data: {
        formValue__repeater__office: formValue__repeater__office,
        sweeperError__repeater__office: sweeperError__repeater__office
    },
    hydrateFormValue: function hydrateFormValue(data) {
        var contentForm = window.rubyApp.findDescendentsBy(function (el) {
            return el.props.key == 'content';
        })[0];
        store.dispatch(contentForm.getAction().generators.seedWithFormData(data, true));
    },
    hydrateSweeperError: function hydrateSweeperError(errorMessagesTree) {
        var editPage = window.rubyApp.findDescendentsBy(function (el) {
            return el.props.key == 'content';
        })[0].getParent();
        editPage.seedFormWithSweeperErrors(errorMessagesTree);
    }
};

module.exports = tests;