'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = generator;

var _reactRedux = require('react-redux');

var _ConnectorMappersGenerator = require('@rubyapps/ruby-component-mixin-field-base/src/client/reactComponents/ConnectorMappersGenerator');

var _ConnectorMappersGenerator2 = _interopRequireDefault(_ConnectorMappersGenerator);

var _Number = require('./Number');

var _Number2 = _interopRequireDefault(_Number);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {
    var selfModule = this;

    var _defaultMappersGenera = _ConnectorMappersGenerator2.default.call(this),
        defaultMapStateToProps = _defaultMappersGenera.mapStateToProps,
        defaultMapDispatchToProps = _defaultMappersGenera.mapDispatchToProps;

    return (0, _reactRedux.connect)(defaultMapStateToProps, defaultMapDispatchToProps)(_Number2.default);
}