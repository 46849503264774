'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

var _ActivityList_style = require('./ActivityList_style');

var _ActivityList_style2 = _interopRequireDefault(_ActivityList_style);

var _Activity = require('./Activity');

var _Activity2 = _interopRequireDefault(_Activity);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;


var NUMBER_OF_LEADING_ACTIVITIES = 5;
var NUMBER_OF_TRAILING_ACTIVITIES = 2;

var ActivityList = _react2.default.createClass({
    displayName: 'ActivityList',

    propTypes: {
        menu: PropTypes.object,
        showAdditionalHeaderButtons: PropTypes.bool,
        canUserDownload: PropTypes.bool,
        getWordDocGenerationStatus_fromActivity: PropTypes.func,
        templateKey: PropTypes.string.isRequired,
        forms: PropTypes.object.isRequired
        //# TODO: fill in the others
    },
    getDefaultProps: function getDefaultProps() {
        return {};
    },
    renderActivity: function renderActivity(activity) {
        var _extends2;

        var _props = this.props,
            edits = _props.edits,
            actions = _props.actions,
            menu = _props.menu,
            showAdditionalHeaderButtons = _props.showAdditionalHeaderButtons,
            rteMode = _props.rteMode,
            rteConfig = _props.rteConfig,
            rubyUser = _props.rubyUser,
            showViewEditButtons = _props.showViewEditButtons,
            verbose = _props.verbose,
            viewEditActions = _props.viewEditActions,
            www_server = _props.www_server,
            canUserDownload = _props.canUserDownload,
            selfModule = _props.selfModule,
            callerModule = _props.callerModule,
            shouldHidePreviewLinks = _props.shouldHidePreviewLinks,
            shouldHideRevertLinks = _props.shouldHideRevertLinks,
            templateKey = _props.templateKey,
            forms = _props.forms;

        var editMode = activity.id === _lodash2.default.get(edits, 'activityId');
        var comment = editMode ? _lodash2.default.get(edits, 'comment') : activity.comment;

        return _react2.default.createElement(_Activity2.default, _extends({}, activity, {
            actions: actions,
            comment: comment,
            editMode: editMode,
            key: activity.id,
            menu: menu,
            showAdditionalHeaderButtons: showAdditionalHeaderButtons,
            rteMode: rteMode,
            rteConfig: rteConfig,
            rubyUser: rubyUser,
            selfModule: selfModule,
            callerModule: callerModule,
            showViewEditButtons: showViewEditButtons,
            verbose: verbose,
            viewEditActions: viewEditActions,
            www_server: www_server,
            canUserDownload: canUserDownload,
            templateKey: templateKey,
            forms: forms
        }, _lodash2.default.pick(this.props, ['getWordDocGenerationStatus_fromActivity', 'STATUS_TYPES']), (_extends2 = {
            shouldHidePreviewLinks: shouldHidePreviewLinks,
            shouldHideRevertLinks: shouldHideRevertLinks,
            'data-codecept-selector-node': 'Activity',
            'data-codecept-selector-file': 'ActivityList'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Activity'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ActivityList'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Activity'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ActivityList'), _extends2)));
    },
    renderShowMore: function renderShowMore() {
        var _React$createElement, _React$createElement2;

        var activities = this.props.activities;

        var remaining = activities.length > 10 ? activities.length - NUMBER_OF_LEADING_ACTIVITIES - NUMBER_OF_TRAILING_ACTIVITIES : 0;
        var toggleShowAll = this.props.actions.toggleShowAll;

        return remaining ? _react2.default.createElement(
            'div',
            (_React$createElement2 = { key: 'showMore', className: 'activityList__viewMore', style: _ActivityList_style2.default.ViewMore, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'ActivityList'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ActivityList'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ActivityList'), _React$createElement2),
            _react2.default.createElement(_FlatButton2.default, (_React$createElement = {
                label: 'View ' + remaining + ' more activities...',
                style: _ActivityList_style2.default.FlatButton,
                onClick: function onClick(e) {
                    e.preventDefault();
                    toggleShowAll();
                },
                'data-codecept-selector-node': 'FlatButton',
                'data-codecept-selector-file': 'ActivityList'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ActivityList'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ActivityList'), _React$createElement))
        ) : null;
    },
    render: function render() {
        var _this = this,
            _React$createElement3;

        var _props2 = this.props,
            activities = _props2.activities,
            showAll = _props2.showAll;

        var activityList = [];

        if (activities.length > 10 && !showAll) {
            activityList = activityList.concat(activities.slice(0, NUMBER_OF_LEADING_ACTIVITIES).map(function (activity) {
                return _this.renderActivity(activity);
            })).concat(this.renderShowMore()).concat(activities.slice(-NUMBER_OF_TRAILING_ACTIVITIES).map(function (activity) {
                return _this.renderActivity(activity);
            }));
        } else {
            activityList = activities.map(function (activity) {
                return _this.renderActivity(activity);
            });
        }

        return _react2.default.createElement(
            'div',
            (_React$createElement3 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'ActivityList'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ActivityList'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ActivityList'), _React$createElement3),
            activityList
        );
    }
});

exports.default = ActivityList;