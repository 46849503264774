'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lock = require('material-ui/svg-icons/action/lock');

var _lock2 = _interopRequireDefault(_lock);

var _Label_style = require('./Label_style');

var _Label_style2 = _interopRequireDefault(_Label_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var Label = _react2.default.createClass({
    displayName: 'Label',

    propTypes: {
        label: PropTypes.string,
        locked: PropTypes.bool,
        required: PropTypes.bool,
        limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        labelProps: PropTypes.object //# additional label properties
    },
    getDefaultProps: function getDefaultProps() {
        return {
            label: null,
            locked: false,
            required: false,
            labelProps: {}
        };
    },
    render: function render() {
        var _extends2, _React$createElement, _React$createElement2;

        var mergedProps = _extends({}, this.props, this.props.labelProps);
        mergedProps.children = (this.props.children || []).concat(this.props.labelProps.children || []);

        var _mergedProps$classNam = mergedProps.className,
            className = _mergedProps$classNam === undefined ? "" : _mergedProps$classNam,
            children = mergedProps.children,
            _mergedProps$style = mergedProps.style,
            style = _mergedProps$style === undefined ? {} : _mergedProps$style;


        var badge = mergedProps.locked ? _react2.default.createElement(_lock2.default, _extends({}, _Label_style2.default.LockIcon, (_extends2 = {
            'data-codecept-selector-node': 'LockIcon',
            'data-codecept-selector-file': 'Label'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'LockIcon'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-node', 'LockIcon'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Label'), _extends2))) : undefined;
        var required = mergedProps.required ? _react2.default.createElement(
            'sup',
            (_React$createElement = {
                'data-codecept-selector-node': 'sup',
                'data-codecept-selector-file': 'Label'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'sup'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Label'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'sup'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Label'), _React$createElement),
            '*'
        ) : undefined;

        var limitString = mergedProps.limit && isFinite(mergedProps.limit) ? ' (limit: ' + mergedProps.limit + ')' : null;
        return _react2.default.createElement(
            'div',
            (_React$createElement2 = { className: className, style: style, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Label'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Label'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Label'), _React$createElement2),
            mergedProps.label,
            required,
            limitString,
            badge,
            children
        );
    }
});

exports.default = Label;