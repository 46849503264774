'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PasswordInfo = _react2.default.createClass({
    displayName: 'PasswordInfo',

    render: function render() {
        var _React$createElement;

        return _react2.default.createElement(
            'p',
            (_React$createElement = { style: { marginTop: '20px' }, 'data-codecept-selector-node': 'p',
                'data-codecept-selector-file': 'PasswordInfo'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'PasswordInfo'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'PasswordInfo'), _React$createElement),
            'Passwords must have at least 8 characters and include three of the following: uppercase letters, lowercase letters, numbers, non-alphanumeric characters.'
        );
    }
});

exports.default = PasswordInfo;