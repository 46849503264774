'use strict';

var _constants = require('./constants');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PROPOSAL_APP_ROUTE = _constants2.default.PROPOSAL_APP_ROUTE;


var rubyAccessManager = require('@rubyapps/ruby-access-manager');

function getProposalSubsiteId_withSettings(settings) {

    var proposalSubsiteId = rubyAccessManager.getSubsiteId_fromSettings_forRoute(settings, PROPOSAL_APP_ROUTE);

    return proposalSubsiteId;
}

module.exports = {
    getProposalSubsiteId_withSettings: getProposalSubsiteId_withSettings
};