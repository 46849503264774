'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

//import DragHandleIcon from 'material-ui/svg-icons/editor/drag-handle';

var styles = {
  base: {
    position: 'absolute',
    zIndex: 1
  },
  top: {
    width: '100%',
    height: '10px',
    top: '-5px',
    left: '0px',
    cursor: 'row-resize'

  },
  right: {
    width: '10px',
    height: '100%',
    top: '0px',
    right: '-5px',
    cursor: 'col-resize',
    display: 'flex',
    'alignItems': 'center'
  },
  bottom: {
    width: '100%',
    height: '10px',
    bottom: '-5px',
    left: '0px',
    cursor: 'row-resize'
  },
  left: {
    width: '10px',
    height: '100%',
    top: '0px',
    left: '0px',
    cursor: 'col-resize',
    display: 'flex',
    'alignItems': 'center'
  },
  topRight: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    right: '-10px',
    top: '-10px',
    cursor: 'sw-resize'
  },
  bottomRight: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    right: '-10px',
    bottom: '-10px',
    cursor: 'nw-resize'
  },
  bottomLeft: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    left: '-10px',
    bottom: '-10px',
    cursor: 'ne-resize'
  },
  topLeft: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    left: '-10px',
    top: '-10px',
    cursor: 'se-resize'
  }
};

var DragHandleIcon = function DragHandleIcon(props) {
  return _react2.default.createElement('div', { style: props.style, 'data-codecept-selector-node': 'div',
    'data-codecept-selector-file': 'resizer'
  });
};

var additionalComponentByType = {
  right: {
    Component: DragHandleIcon,
    style: {
      width: '0px',
      height: '13px',
      flexShrink: 0,
      marginLeft: '4px',
      borderRight: '1px solid rgba(0, 0, 0, 0.2)'
    }
  },
  left: {
    Component: DragHandleIcon,
    style: {
      width: '0px',
      height: '13px',
      flexShrink: 0,
      marginRight: '4px',
      borderRight: '1px solid rgba(0, 0, 0, 0.2)'
    }
  }
};

var Resizer = (_temp = _class = function (_Component) {
  _inherits(Resizer, _Component);

  function Resizer(props) {
    _classCallCheck(this, Resizer);

    var _this = _possibleConstructorReturn(this, (Resizer.__proto__ || Object.getPrototypeOf(Resizer)).call(this, props));

    _this.onTouchStart = _this.onTouchStart.bind(_this);
    return _this;
  }

  _createClass(Resizer, [{
    key: 'shouldComponentUpdate',
    value: function shouldComponentUpdate(nextProps) {
      return !_lodash2.default.isEqual(this.props, nextProps);
    }
  }, {
    key: 'onTouchStart',
    value: function onTouchStart(event) {
      this.props.onResizeStart(event.touches[0]);
    }
  }, {
    key: 'getStyle',
    value: function getStyle() {
      if (this.props.replaceStyles) return this.props.replaceStyles;
      return _extends({}, styles.base, styles[this.props.type]);
    }
  }, {
    key: 'render',
    value: function render() {
      var additionalComponentSpec = additionalComponentByType[this.props.type];

      var renderProps = {
        className: this.props.className,
        style: this.getStyle(),
        onMouseDown: this.props.onResizeStart,
        onTouchStart: this.onTouchStart
      };
      if (additionalComponentSpec) {
        return _react2.default.createElement(
          'div',
          _extends({}, renderProps, {
            'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'resizer'
          }),
          _react2.default.createElement(additionalComponentSpec.Component, {
            style: additionalComponentSpec.style, 'data-codecept-selector-file': 'resizer'
          })
        );
      } else {
        return _react2.default.createElement('div', _extends({}, renderProps, {
          'data-codecept-selector-node': 'div',
          'data-codecept-selector-file': 'resizer'
        }));
      }
    }
  }]);

  return Resizer;
}(_react.Component), _class.propTypes = {
  onResizeStart: _react.PropTypes.func,
  type: _react.PropTypes.oneOf(['top', 'right', 'bottom', 'left', 'topRight', 'bottomRight', 'bottomLeft', 'topLeft']).isRequired,
  replaceStyles: _react.PropTypes.object,
  className: _react.PropTypes.string
}, _temp);
exports.default = Resizer;