'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _action = require('./action');

var _action2 = _interopRequireDefault(_action);

var _reducer = require('./reducer');

var _reducer2 = _interopRequireDefault(_reducer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Promise = require('bluebird');


var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var routeParser = require('@rubyapps/route-parser');

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldOptionsMixin = require('@rubyapps/ruby-component-mixin-field-options');
var fieldUrlMixin = require('@rubyapps/ruby-component-mixin-field-url');
var RubyClientFK__CONSTANTS = require('@rubyapps/ruby-component-rubyclientfk/src/common/constants');

var _require = require('../common/constants'),
    REQUEST_TYPE_FORM_HYDRATION = _require.REQUEST_TYPE_FORM_HYDRATION,
    REQUEST_TYPE_OPTIONS_RETRIEVAL = _require.REQUEST_TYPE_OPTIONS_RETRIEVAL;

var fieldRemoteOptionsMixin = {
    mixinName: 'rubyComponentMixinFieldRemoteOptions',
    propTypes: {
        options: PropTypes.array,
        preloadOptions: PropTypes.bool,
        preloadOptionsQuery: PropTypes.object
    },
    mixins: [fieldOptionsMixin, fieldUrlMixin],
    action: _action2.default,
    reducer: _reducer2.default,
    getInitialState: function getInitialState() {
        return {
            props: {
                options: null,
                filteredOptions: null
            },
            requestedOptionsUrlByType: null,
            refreshRequestTimestamp: null,
            requestedTimestamp: null,
            requestedQuery: null,
            searchValue: ''
        };
    },
    dependencies: function dependencies() {
        var RubyClientFKSelector = this.getRoot().findDescendentByID(RubyClientFK__CONSTANTS.COMPONENT_NAME).getDefaultSelector();

        return {
            RubyClientFKSelector: RubyClientFKSelector
        };
    }

    //== UTILITIES =============================//

    , url: function url() {
        var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
            props = _ref.props,
            state = _ref.state,
            formValue = _ref.formValue,
            params = _ref.params;

        props = props || this.props;
        state = state || this.getState();

        var hydratedUrl = this.hydratedUrl({ props: props, state: state, formValue: formValue, params: params });

        //# This was added to fix a bug where the Proposal Field in the Add To Proposal Dialog
        //# didn't default to a just created proposal. ref commit 8f400372
        //# I tested this and it appears to no longer be necessary.
        //# Removing it because it returns a potentially stale url that may no longer be valid.
        //# In particular, it prevents the renderType field in the Media Info Pane from properly
        //# clearing cached options when the Info Pane is closed. That, in turn, prevented the
        //# field from updating if the Info Pane was reopened for the same record.
        //      || _.get(state, ['requestedOptionsUrlByType', REQUEST_TYPE_OPTIONS_RETRIEVAL]);
        return hydratedUrl;
    },
    cacheRequestObject: function cacheRequestObject(requestObject, requestType) {
        this._requestObjectByType = _extends({}, this._requestObjectByType, _defineProperty({}, requestType, requestObject));;
    }

    //# NOTE: do not perform value coercion. Used by methods like `formValueFromFieldValue_forKey` which is used by coercedValue
    , _getOptionsMatchingOptionValue_raw: function _getOptionsMatchingOptionValue_raw(optionValue) {
        if (_lodash2.default.isNil(optionValue) || optionValue.length == 0) {
            return [];
        }

        var optionValue_normalized = _lodash2.default.flow(_lodash2.default.castArray, function (arrValue) {
            return _lodash2.default.flatMap(arrValue, function (item) {
                return item + "";
            });
        } //# forcing it to a string for the purposes of finding it
        )(optionValue);

        var options = _lodash2.default.get(this.getState(), 'props.options') || this.props.options || [];
        var foundOptions = options.filter(function (option) {
            return option && optionValue_normalized.indexOf((option.value || option.id) + "") >= 0;
        });

        return foundOptions;
    }
    //# TODO: override to to the server to get updated data
    //# Assumes options are loaded
    , getOptionsMatchingOptionValue: function getOptionsMatchingOptionValue(optionValue) {
        var _this = this;

        if (_lodash2.default.isNil(optionValue) || optionValue.length == 0) {
            return [];
        }

        var optionValue_normalized = _lodash2.default.flow(_lodash2.default.castArray, function (arrValue) {
            return _lodash2.default.flatMap(arrValue, function (item) {
                return _this.coercedValue(item);
            });
        })(optionValue);

        var options = _lodash2.default.get(this.getState(), 'props.options') || this.props.options || [];
        var foundOptions = options.filter(function (option) {
            return option && optionValue_normalized.indexOf(_this.coercedValue(option.value || option.id, true)) >= 0;
        });

        return foundOptions;
    },
    getOptionsMatchingOptionValueAsync: function getOptionsMatchingOptionValueAsync(optionValue) {
        var _this2 = this;

        if (_lodash2.default.isNil(optionValue) || optionValue.length == 0) {
            return Promise.resolve([]);
        }

        var optionValue_normalized = _lodash2.default.flow(_lodash2.default.castArray, function (arrValue) {
            return _lodash2.default.flatMap(arrValue, function (item) {
                return _this2.coercedValue(item);
            });
        })(optionValue);

        var options = _lodash2.default.get(this.getState(), 'props.options') || this.props.options || [];
        var foundOptions = options.filter(function (option) {
            return option && optionValue_normalized.indexOf(_this2.coercedValue(option.value || option.id, true)) >= 0;
        });

        if (foundOptions.length) {
            return Promise.resolve(foundOptions);
        } else {
            return this.retrieveOptionsFromUrl_withQueryAsync(this.url(), { id: optionValue_normalized }, undefined, REQUEST_TYPE_FORM_HYDRATION).then(function (data) {

                //# NOTE: This is not necessary but the User options endpoint currently doesn't filter by id
                var foundOptions = data.filter(function (option) {
                    return option && optionValue_normalized.indexOf(_this2.coercedValue(option.value || option.id, true)) >= 0;
                });

                //# might as well cache the options here
                _this2.getStore().dispatch(_this2.getAction().generators.updateCachedOptions_withAdditional(data));

                return foundOptions;
            });
        }
    },

    normalizedOptions: function normalizedOptions(options) {
        var _this3 = this;

        return options.map(function (option) {
            return _extends({}, option, {
                value: option.value || option.id,
                text: _this3.getDisplayTextFromOption(option)
            });
        });
    },
    getDisambiguatedOptionsFromHardcodedOptions: function getDisambiguatedOptionsFromHardcodedOptions(options) {
        var _this4 = this;

        var optionsWithUniqueValues = _lodash2.default.uniqBy(options, 'value') || [];

        var disambiguatedOptions = optionsWithUniqueValues.map(function (thisOption, index) {
            var otherOptions = options.slice(0, index).concat(options.slice(index + 1));
            var originalDisplayText = _this4.getDisplayTextFromOption(thisOption);

            var optionsWithMatchingDisplayText = otherOptions.filter(function (otherOption) {
                return originalDisplayText === _this4.getDisplayTextFromOption(otherOption);
            });
            var finalDisplayText = optionsWithMatchingDisplayText.length > 0 ? originalDisplayText + ' (' + thisOption.value + ')' : originalDisplayText;
            return _extends({}, thisOption, {
                text: finalDisplayText
            });
        });
        return disambiguatedOptions;
    },
    retrieveOptionsFromUrl_withQueryAsync: function retrieveOptionsFromUrl_withQueryAsync(url, query) {
        var allowAbort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var requestType = arguments[3];

        var store = this.getStore();
        var actions = this.getAction().generators;

        return actions.retrieveOptionsFromUrl_withQuery(url, query, undefined, allowAbort, requestType)(store.dispatch, store.getState);
    },

    onReduxInit: function onReduxInit(dispatch) {
        var _this5 = this;

        //if (this.props.options) { return; }
        if (!this.props.url) {
            return;
        } //# TODO: figure out why we changed the check to options: 53685d0e99d8ef53d3c65c2e767f6a4831736dcb
        var store = this.getStore();
        var actions = this.getAction().generators;

        /*
            //# NOTE: don't need this UNLESS we want to preload options
            //# before the reactComponent is mounted
            if (this.props.preloadOptions) {
                dispatch(actions.cacheOptionsFromUrl_withQuery(this.url()));
            }
        */

        //# NOTE: This is used for caching all options
        //# this won't work with the tokentagger since we expect to have filtered data
        return store.subscribe(function () {
            if (_lodash2.default.isNil(_this5.getState())) {
                return;
            }

            var _getState = _this5.getState(),
                requestedOptionsUrlByType = _getState.requestedOptionsUrlByType,
                refreshRequestTimestamp = _getState.refreshRequestTimestamp,
                requestedTimestamp = _getState.requestedTimestamp,
                requestedQuery = _getState.requestedQuery,
                isMounted = _getState.isMounted;

            var props = _this5.getProps();
            var defaultToFirstOption = props.defaultToFirstOption;


            var urlDependentFields = _this5.urlDependentFields();
            var isUrlDependent = urlDependentFields.length;
            if (isUrlDependent) {
                //# check if dependent fields updated
                var didUrlDependentFieldsUpdate = _this5.didUrlDependentFieldsUpdate(urlDependentFields);

                if (!didUrlDependentFieldsUpdate) {
                    //console.log('== dependent fields is same for: ',  this.props.key);
                    return;
                }
            }

            var props__url = _this5.url({ props: props });

            //# Instead of preventing updating/retrieving the options if the requestedOptionsUrl is null 
            //# (we previously did this because we relied on preloadOptions() being called)
            //# we instead check if the component is mounted before we allow for reretrieval
            //# so for the case of the Add to Proposal Dialog, it doens't have a url to preload
            //# so it waits for it to be mounted before it seeds its options
            var requestedOptionsUrl = _lodash2.default.get(requestedOptionsUrlByType, [REQUEST_TYPE_OPTIONS_RETRIEVAL]);
            var isMounted_orPreloaded_orDefaultToFirst = isMounted || requestedOptionsUrl || defaultToFirstOption;
            if (isMounted_orPreloaded_orDefaultToFirst && requestedOptionsUrl != props__url) {
                //console.log(`[${this.key()}] requestedOptionsUrl: ${requestedOptionsUrl} vs props__url: ${props__url}`)
                dispatch(actions.retrieveOptionsWithQuery(

                //# `requestedQuery` is not guaranteed to be defined yet.
                //# For TokenTaggers, this may cause a request without a `count` param, which may timeout.
                //# When it's not defined, using `preloadOptionsQuery` since this subscription is essentially
                //# pre-loading options for the new `props__url`.
                //# Otherwise, use `requestedQuery` so that `search_input` is used if it has been set.
                requestedQuery || _this5.props.preloadOptionsQuery, {
                    url: props__url, clearCache: true,
                    thenableArguments: [function (data) {
                        if (isUrlDependent) {
                            //# re-seed the form value to see if current value is still valid
                            var currentFormValue = _this5.formValue();
                            if (_lodash2.default.isNil(currentFormValue[_this5.key()])) {
                                return;
                            }
                            //# no current value so we don't need to check for validity

                            _this5.formValueToLocalState(currentFormValue);
                        }
                    }]
                }));
            } else if (refreshRequestTimestamp > requestedTimestamp) {
                //console.log(`[${this.key()}] refreshRequestTimestamp: ${refreshRequestTimestamp} vs ${requestedTimestamp}`)
                //# re-request the data
                dispatch(actions.retrieveOptionsFromUrl_withQuery(requestedQuery, { url: requestedOptionsUrl, clearCache: true }));
            }
        });
    },

    getPrunedValue_fromRawValue_withOptions: function getPrunedValue_fromRawValue_withOptions(value, options) {
        var _this6 = this;

        var validOptionValues = (options || []).map(function (option) {
            return _this6.coercedValue(option.value || option.id, true);
        });

        if (_lodash2.default.isArray(value)) {
            var filteredValue = value.filter(function (optionValue) {
                return validOptionValues.includes(optionValue);
            });
            return _lodash2.default.isEmpty(filteredValue) ? null : filteredValue;
        } else {
            return validOptionValues.includes(value) ? value : null;
        }
    }

    //== OVERRIDES =============================//
    , _formValueFromLocalState: function _formValueFromLocalState(selfState, isError, predicateFormatter, entireState) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        var selfKey = this.props.key;

        var formValue = baseFieldMixin._formValueFromLocalState.apply(this, arguments);

        if (formValue.hasOwnProperty(selfKey)) {
            if (formValue[selfKey] == undefined && this.props.defaultToFirstOption) {
                formValue[selfKey] = _lodash2.default.get(selfState, 'props.options.0.value');
            }
        }

        return formValue;
    },
    _formValueToLocalState: function _formValueToLocalState(formValue, dispatchOrCollect, isError, entireFormValue) {
        var _this7 = this;

        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        var dispatch = this.getStore().dispatch;
        var actions = this.getAction().generators;

        var selfID = this.getID();
        var selfKey = this.props.key;

        if (!formValue.hasOwnProperty(selfKey)) {
            //# only short-circuit if property is missing
            //# otherwise, continue populating (even if null)
            return Promise.resolve(null);
        }
        if (isError) {
            return baseFieldMixin._formValueToLocalState.apply(this, arguments);
        }

        //# support omit field picker
        var shouldOmitField = _lodash2.default.isFunction(options.omitFieldPicker) && options.omitFieldPicker.apply(options, [this].concat(Array.prototype.slice.call(arguments)));
        if (shouldOmitField) {
            return undefined;
        } //# NOTE: this will get checked later because we call on baseFieldMixin, but we want to preemptively call on it
        //# so we don't superfluously call on the options request


        //# This is used to filter out entries with the wrong data-type, e.g. omit strings if the field's data-type is
        //# `number`. This change was applied to avoid a `number_format_exception` from ES when attempting to find
        //# records by a string id, when the template uses number values for ids.
        //# Ideally, we should be able to search by both strings and numbers and the connector should handle that
        //# gracefully. Once that is done, this can be replaced with:
        // const formValueForKey = formValue[selfKey]
        var formValueForKey = this.formValueFromFieldValue_forKey(this.fieldValueFromFormValue_forKey(formValue[selfKey], selfKey), selfKey);

        var props__options = (_lodash2.default.get(this.getState(), 'props.options') || []).concat(this.props.options || []);
        var formValueForKey_prunedByPropsOptions = this.getPrunedValue_fromRawValue_withOptions(formValueForKey, props__options);
        var props__url = this.url({ formValue: entireFormValue });

        var propsOptionsAreComplete = _lodash2.default.isEqual(formValueForKey_prunedByPropsOptions, formValueForKey);
        if (props__url && !propsOptionsAreComplete) {
            return new Promise(function (resolve, reject) {
                dispatch(actions.retrieveOptionsFromUrl_withQuery(props__url, { id: formValueForKey }, [function (data) {
                    var allOptions = data.concat(props__options);
                    var formValueForKey_prunedByAllOptions = _this7.getPrunedValue_fromRawValue_withOptions(formValueForKey, allOptions);

                    if (!_lodash2.default.isEqual(formValueForKey_prunedByAllOptions, formValueForKey)) {
                        //# some options aren't found, we're filtering them out
                        console.warn('Some options are no longer available, we are filtering them out on hydration' + ('. Field key: \'' + _this7.props.key + '\'. Found Options:'), allOptions);
                    }

                    //# NOTE: previously, we're mutating the object here
                    //# This wasn't the greatest
                    formValue = _extends({}, formValue, _defineProperty({}, selfKey, formValueForKey_prunedByAllOptions));

                    //# might as well cache the options here
                    dispatch(actions.updateCachedOptions_withAdditional(data));

                    resolve(baseFieldMixin._formValueToLocalState.call(_this7, formValue, dispatchOrCollect, false, entireFormValue, options));
                }, function (err) {
                    reject(err);
                }], undefined, false //# clearCache
                , REQUEST_TYPE_FORM_HYDRATION));
            }).catch(function (err) {
                console.error('Error retrieving options for form key ' + selfKey + ' with value ' + formValueForKey + ': ', err);
            });
        } else {
            if (!propsOptionsAreComplete) {
                //# some options aren't found, we're filtering them out
                console.warn('Some options are no longer available, we are filtering them out on hydration. Field key:' + (' \'' + this.props.key + '\'. Found Options:'), props__options);
            }
            formValue = _extends({}, formValue, _defineProperty({}, selfKey, formValueForKey_prunedByPropsOptions));
            return baseFieldMixin._formValueToLocalState.call(this, formValue, dispatchOrCollect, false, entireFormValue, options);
        }
    },
    resetStore: function resetStore(dispatchOrCollect, shouldResetStore) {
        var _arguments = arguments;

        //# immediately call on resetStore instead of dispatchOrCollect
        var store = this.getStore();
        var actions = this.getAction().generators;
        var children = this.getChildren();

        //# NOTE: temporary measure to really immediately dispatch for thunks
        //# Since we may call on resetStore with a collector for dispatching thunks
        var shouldResetStoreResult = shouldResetStore ? shouldResetStore(this) : true;
        //# default is true;

        if (shouldResetStoreResult) {
            actions.resetStore && store.dispatch(actions.resetStore());

            children.forEach(function (child) {
                child.resetStore && child.resetStore.apply(child, _arguments);
            });
        }
    },
    clearForm: function clearForm(dispatchOrCollect) {
        if (!dispatchOrCollect) {
            dispatchOrCollect = this.getStore().dispatch;
        }

        var children = this.getChildren();

        var _getAction = this.getAction(),
            actions = _getAction.generators;

        //# getInitialState() and replace the field


        var initialState = this.getInitialState ? this.getInitialState() : {};
        if (initialState.hasOwnProperty('fields') || initialState.hasOwnProperty('error')) {
            //# set initialState differently if this.props.defaultToFirstOption;
            var newLocalState = _lodash2.default.pick(initialState, ['fields', 'error']);

            if (this.props.defaultToFirstOption) {
                //# NOTE: cannot defaultToFirstOption on form clear because of the async nature of retrieving props
                /*
                const options = _.get(this.getState(), ['props', 'options'], []) || [];
                if (options.length) {
                    const firstOptionValue = this.coercedValue(options[0].value);
                    _.set(newLocalState, ['fields', this.props.key, 'value'], firstOptionValue);
                }*/
                //# try setting it to be undefined so that we can dynamically select the first option
                //# since undefined is treated differently than null
                _lodash2.default.set(newLocalState, ['fields', this.props.key, 'value'], undefined);
            }

            dispatchOrCollect(actions.replaceLocalState(newLocalState));
        }

        children.forEach(function (child) {
            if (child.clearForm) {
                child.clearForm(dispatchOrCollect);
            }
        });
    }

    //== OVERRIDES ======================//
    , sampleValueFromFieldSpecs: function sampleValueFromFieldSpecs() {
        //# first check if getState().props.options has any
        //# hit options endpoint and rando select options
        var selfState = this.getStore() ? this.getState() : {};
        var options = _lodash2.default.get(selfState, 'props.options') || this.props.options;

        var faker = require('@rubyapps/ruby-faker');

        if (options && options.length) {
            return faker.helpers.randomize(options).value;
        }
        var selfUrl = this.url();

        if (!selfUrl) {
            console.error('[' + this.getID() + '] this.url() was called but no url prop');
        }
        var actions = this.getAction().generators;
        var dispatch = this.getStore().dispatch;
        return new Promise(function (resolve, reject) {
            //# TODO: replace with retrieveOptionsWithQuery() in v11
            dispatch(actions.retrieveOptionsFromUrl_withQuery(selfUrl + '&count=10' //# arbitrary limit for count
            , undefined, [function (data) {
                //dispatch(actions.updateCachedOptions_withAdditional(data));
                //# NOTE: cannot update cache because this field might be in a repeater (which isn't created yet), this it has no real state

                resolve(faker.helpers.randomize(data).value);
            }]));
        });
    }
};

module.exports = fieldRemoteOptionsMixin;