'use strict';

var _constants = require('../common/constants');

var queryString = require('query-string');
var query = location.search ? queryString.parse(location.search) : {};

var superagentQuery = query.superagent;


//# for now only support superagent: false/0 to disable cache
//# in the future, we want to support `?superagent={...options}`

if (superagentQuery) {
    var eff_query = {};

    if (superagentQuery == 'false' || superagentQuery == '0') {
        eff_query.disable_cache = true;
    }

    if (eff_query.disable_cache) {
        var superagent = require('superagent');

        console.log('Disabling superagent cache plugin');
        module.exports = superagent;
    } else {
        module.exports = require('../common/index.js')({ retryOptions: _constants.DEFAULT_RETRY_OPTIONS });
    }
} else {

    module.exports = require('../common/index.js')({ retryOptions: _constants.DEFAULT_RETRY_OPTIONS });
}