'use strict';

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var persistenceHelper = require('@rubyapps/ruby-persistence-helper');

var action = require('./action');
var reducer = require('./reducer');

var persistenceMixin = {
    mixinName: 'rubyComponentMixinPersistence',
    propTypes: {
        currentUserId: PropTypes.string,
        keysToPersist: PropTypes.arrayOf(PropTypes.string)
        //# NOTE: if null, persist everything
    },
    getDefaultProps: function getDefaultProps() {
        return {
            currentUserId: 'rubyComponentCurrentUser',
            keysToPesist: []
        };
    },
    action: action,
    reducer: reducer

    //# _cachedPersistenceKey
    , onReduxInit: function onReduxInit() {
        var _this = this;

        var id = this.getID();
        var store = this.getStore();
        var actions = this.getAction().generators;

        var keysToPersist = this.props.keysToPersist;

        if (keysToPersist == []) {
            return;
        }

        var currentUser = this.getRoot().findDescendentByID(this.props.currentUserId);
        return store.subscribe(function () {
            var currentUserState = currentUser.getState() || {};
            var username = currentUserState.username;

            if (username) {
                //# generate the persistenceKey
                var persistenceKey = username + '::' + id;
                var previousPersistedData = persistenceHelper.get(persistenceKey);
                if (_this._cachedPersistenceKey != persistenceKey) {
                    //# retrieve preference and replace localState
                    _this._cachedPersistenceKey = persistenceKey;

                    store.dispatch(actions.replaceReduxState(previousPersistedData));
                } else {
                    //# store preference if different
                    var selfState = _this.getState();
                    var potentialDataToPersist = keysToPersist ? _lodash2.default.pick(selfState, keysToPersist) : selfState;
                    if (!_lodash2.default.isEqual(previousPersistedData, potentialDataToPersist)) {
                        persistenceHelper.set(persistenceKey, potentialDataToPersist);
                    }
                }
            }
        });
    }
};

module.exports = persistenceMixin;