'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = SweeperErrorValueGenerator;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _error = require('material-ui/svg-icons/alert/error');

var _error2 = _interopRequireDefault(_error);

var _checkCircle = require('material-ui/svg-icons/action/check-circle');

var _checkCircle2 = _interopRequireDefault(_checkCircle);

var _addCircle = require('material-ui/svg-icons/content/add-circle');

var _addCircle2 = _interopRequireDefault(_addCircle);

var _notInterested = require('material-ui/svg-icons/av/not-interested');

var _notInterested2 = _interopRequireDefault(_notInterested);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

var PropTypes = _react2.default.PropTypes;

// Material UI Icons
function SweeperErrorValueGenerator(options, parentProps) {

    return _react2.default.createClass({
        contextTypes: {
            muiTheme: PropTypes.object
        },
        render: function render() {
            var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6;

            var props = this.props;

            var _Object$assign = _extends({}, _.get(props, 'rowData', {}), _.get(props, 'rowData.__metadata', {})),
                error_value = _Object$assign.error_value,
                status = _Object$assign.status,
                error_type = _Object$assign.error_type;

            var _context$muiTheme$raw = this.context.muiTheme.rawTheme.palette,
                errorColor_foreground = _context$muiTheme$raw.errorColor_foreground,
                successColor_foreground = _context$muiTheme$raw.successColor_foreground;


            var iconStyle = {
                marginRight: 10,
                minWidth: 24
            };

            var iconByStatus = {
                open: _react2.default.createElement(_error2.default, (_React$createElement = { style: iconStyle, color: errorColor_foreground, 'data-codecept-selector-node': 'ErrorIcon',
                    'data-codecept-selector-file': 'SweeperErrorValueGenerator'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ErrorIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'SweeperErrorValueGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ErrorIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'SweeperErrorValueGenerator'), _React$createElement)),
                fixed: _react2.default.createElement(_checkCircle2.default, (_React$createElement2 = { style: iconStyle, color: successColor_foreground, 'data-codecept-selector-node': 'CheckIcon',
                    'data-codecept-selector-file': 'SweeperErrorValueGenerator'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'CheckIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'SweeperErrorValueGenerator'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'CheckIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'SweeperErrorValueGenerator'), _React$createElement2)),
                ignored: _react2.default.createElement(_notInterested2.default, (_React$createElement3 = { style: iconStyle, color: successColor_foreground, 'data-codecept-selector-node': 'IgnoredIcon',
                    'data-codecept-selector-file': 'SweeperErrorValueGenerator'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IgnoredIcon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'SweeperErrorValueGenerator'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IgnoredIcon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'SweeperErrorValueGenerator'), _React$createElement3)),
                added_to_dictionary: _react2.default.createElement(_addCircle2.default, (_React$createElement4 = { style: iconStyle, color: successColor_foreground, 'data-codecept-selector-node': 'PlusIcon',
                    'data-codecept-selector-file': 'SweeperErrorValueGenerator'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'PlusIcon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'SweeperErrorValueGenerator'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'PlusIcon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'SweeperErrorValueGenerator'), _React$createElement4))
            };

            var StatusIcon = iconByStatus[status];

            return _react2.default.createElement(
                'div',
                (_React$createElement6 = { style: { display: 'flex', alignItems: 'center' }, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'SweeperErrorValueGenerator'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'SweeperErrorValueGenerator'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'SweeperErrorValueGenerator'), _React$createElement6),
                StatusIcon,
                _react2.default.createElement(ErrorValue, (_React$createElement5 = {
                    error_type: error_type,
                    error_value: error_value,
                    'data-codecept-selector-node': 'ErrorValue',
                    'data-codecept-selector-file': 'SweeperErrorValueGenerator'
                }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'ErrorValue'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'SweeperErrorValueGenerator'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'ErrorValue'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'SweeperErrorValueGenerator'), _React$createElement5))
            );
        }
    });
}

var linkStyle = {
    whiteSpace: 'nowrap'
};

var ErrorValue = function ErrorValue(props) {
    var _React$createElement7, _React$createElement8;

    return _react2.default.createElement(
        'div',
        (_React$createElement8 = {
            style: {
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            },
            'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'SweeperErrorValueGenerator'
        }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'SweeperErrorValueGenerator'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'SweeperErrorValueGenerator'), _React$createElement8),
        props.error_type === 'link' || props.error_type === 'image' ? _react2.default.createElement(
            'a',
            (_React$createElement7 = { style: linkStyle, href: props.error_value, target: '_blank', 'data-codecept-selector-node': 'a',
                'data-codecept-selector-file': 'SweeperErrorValueGenerator'
            }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'SweeperErrorValueGenerator'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'SweeperErrorValueGenerator'), _React$createElement7),
            props.error_value
        ) : props.error_value
    );
};