'use strict';

module.exports = {
    columns: [{
        key: 'name',
        displayName: 'Name'
    }, {
        key: 'status',
        type: 'publish',
        displayName: 'Status',
        width: 100
    }],
    filters: [],
    searchPlaceholder: 'Search Proposal Stages',
    hierarchical: true
};