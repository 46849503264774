'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var update = require('react-addons-update');

var defaultState = {}; //# Note: actual default state is defined by index.js


module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];

    var _getAction = this.getAction(),
        TYPES = _getAction.TYPES;

    var type = action.type,
        payload = action.payload;

    switch (type) {
        case TYPES.CLEAR_CACHED_OPTIONS:
            var clearedOptionsState = _extends({}, state, {
                props: _extends({}, state.props, {
                    options: null
                })
            });
            return clearedOptionsState;
        case TYPES.SET_OPTIONS:
            if (typeof payload.options == 'undefined') {
                //|| payload.options.length == 0) {
                return state;
            }
            var updatedState = _extends({}, state, {
                props: _extends({}, state.props, {
                    options: payload.options,
                    propOptions: payload.propOptions
                })
            });
            return updatedState;
        case TYPES.SET_FILTERED_OPTIONS:
            return _extends({}, state, {
                props: _extends({}, state.props, {
                    filteredOptions: payload.filteredOptions
                })
            });
        case TYPES.SET_REQUESTED_OPTIONS_URL:
            return _extends({}, state, {
                requestedOptionsUrlByType: _extends({}, state.requestedOptionsUrlByType, _defineProperty({}, payload.type, payload.url))
            });
        case TYPES.SET_SEARCH_VALUE:
            if (typeof payload.value !== 'string') {
                return state;
            }
            return _extends({}, state, {
                searchValue: payload.value
            });
        case TYPES.SET_REFRESH_REQUEST_TIMESTAMP:
            return _extends({}, state, {
                refreshRequestTimestamp: payload.timestamp
            });
        case TYPES.SET_REQUESTED_TIMESTAMP:
            return _extends({}, state, {
                requestedTimestamp: payload.timestamp
            });
        case TYPES.SET_REQUESTED_QUERY:
            return _extends({}, state, {
                requestedQuery: payload.query
            });
        case TYPES.CUSTOM_RESET_STATE:
            return _extends({}, state, this.getInitialState(state));
        default:
            return state;
    }
};