'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EXCLUDED_COMPONENT_NAMES = ['NamespaceSelector'];

function selectedColumnsPropsFromRubyComponentProps_andGriddleProps(rubyComponentProps, griddleProps) {
    var _rubyComponentProps$s = rubyComponentProps.searchableFields,
        searchableFields = _rubyComponentProps$s === undefined ? [] : _rubyComponentProps$s,
        listerConfig = rubyComponentProps.listerConfig;


    var filteredSearchableFields = searchableFields.filter(function (searchableField) {
        return EXCLUDED_COMPONENT_NAMES.indexOf(searchableField.componentName) < 0;
    });

    var columnSpecsArrFromGriddleColumnProps = _lodash2.default.map(_lodash2.default.omit(_lodash2.default.get(griddleProps, 'state.renderProperties.columnProperties', {}), ['id']), function (columnSpec) {
        return _extends({}, columnSpec, { key: columnSpec.id });
    });
    var griddleSelectedColumnSpecsArr = _lodash2.default.get(griddleProps, 'state.utilityBarProperties.selectedColumnSpecsArr', columnSpecsArrFromGriddleColumnProps);

    var selectedColumnsProps = {
        selectedColumnSpecsArr: griddleSelectedColumnSpecsArr,
        searchableFields: filteredSearchableFields
    };

    if (listerConfig) {
        selectedColumnsProps.listerConfig = listerConfig;
    }
    return selectedColumnsProps;
}

exports.default = selectedColumnsPropsFromRubyComponentProps_andGriddleProps;