'use strict';

module.exports = {
    fieldSpec: {
        displayText: 'Dropdown - Multiselect Tagger',
        propertyKeys: ['key', 'endpoint_builder', 'options', 'limit', 'token_tagger_data_type', 'token_tagger_advanced_search', 'token_tagger_hydrate_suggested_values', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden', 'count_hidden']
    },
    propertySpecs: {
        count_hidden: {
            label: 'Count',
            type: 'number',
            propKey: 'count',
            hidden: true
            //# description: "The number of options to retrieve"
        }
    }
};