'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var path = require('path');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

var mixinResize = require('@rubyapps/ruby-react-components/src/client/mixins/resize');

module.exports = _extends({}, mixinResize, {
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        //# Handle recentering the dialog from async rerendering of children
        if (nextProps.open == false && this.props.open == true) {
            //console.log('Unbinding on close:', this._componentEl);
            this._unbindBoundElementResize();
        } else if (nextProps.open == true && this.props.open == false) {
            //# rebind cached element
            //console.log('Rebinding on open:', this._componentEl);
            //this._bindElementResize(this._componentEl)
            //# Do not need to rebind
        }
    },
    componentDidUpdate: function componentDidUpdate(prevProps) {
        var _this = this;

        var nextProps = this.props;

        if (nextProps.open == true && !prevProps.open) {
            setTimeout(function () {
                //# Manually rerender on open
                _this._updateLastRenderedTimestamp();
            }, 500);
            //# NOTE: the timeout is hacky 
            //# but it seems that even though the element is already bound
            //# it's not picking up on the initial resizing when the lister data
            //# renders with data
        }
    },
    componentDidMount: function componentDidMount() {
        this._isMounted = true;
    },
    componentWillUnmount: function componentWillUnmount() {
        this._isMounted = false;
        this._unbindBoundElementResize();
    }

    //# == Utilities =============================================//
    , _bindElementResize: function _bindElementResize(componentEl, callback) {
        var _this2 = this;

        mixinResize._bindElementResize.call(this, componentEl, function () {
            logger.debug('_bindElementResize', _this2);
            _this2._updateLastRenderedTimestamp();
        }) && this._updateLastRenderedTimestamp();
    },
    _updateLastRenderedTimestamp: function _updateLastRenderedTimestamp() {
        if (this._isMounted) {
            this.setState({
                delayedLastRenderedTimestamp: new Date()
            });
        }
    }
});