'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var routeParser = require('@rubyapps/route-parser');
var jsonselect = require('JSONSelect');
var paramSelector = jsonselect.compile('object:has(:root > .displayName:val("Param"))');

//# This is a helper mixin for utility methods to help parse the url prop
//# This mixin doesn't handle override the _formValueToLocalState() methods
var fieldUrlMixin = {
    mixinName: 'rubyComponentMixinFieldUrl',
    propTypes: {
        url: PropTypes.string
    },
    getInitialState: function getInitialState() {
        return {
            props: {
                options: null,
                filteredOptions: null
            },
            requestedOptionsUrlByType: null,
            refreshRequestTimestamp: null,
            requestedTimestamp: null,
            requestedQuery: null
        };
    }

    //== UTILITIES =============================//
    , urlRouteParser: function urlRouteParser(url) {
        url = url || this.props.url;

        var urlRouteParser = this._urlRouteParser;
        if (url != (urlRouteParser || {}).spec) {
            urlRouteParser = this._urlRouteParser = new routeParser(url);
        }

        return urlRouteParser;
    },
    didUrlDependentFieldsUpdate: function didUrlDependentFieldsUpdate(urlDependentFields) {
        urlDependentFields = urlDependentFields || this.urlDependentFields();
        var cachedUrlDependentFieldValues = this.getStatefulCacheForKey('_cachedUrlDependentFieldValues');
        var formValue = this.getParentFormComponent().formValue(); //# {cacheTemporarily: true} DEPRECATED 20190502 - reference the base field mixin for more info

        var editPageComponent = this.findAncestorBy(function (element) {
            return element.componentName === 'rubyComponentEditPage';
        });
        var formValueWithId = _extends({}, formValue, { id: formValue.id ? formValue.id : _lodash2.default.result(editPageComponent, 'getTypedPageId') });

        var newUrlDependentFieldValues = _lodash2.default.pick(formValueWithId, urlDependentFields);

        this.setStatefulCacheForKey('_cachedUrlDependentFieldValues', newUrlDependentFieldValues);
        //this.props.key == '__renderType_expanded' && console.log(cachedUrlDependentFieldValues, newUrlDependentFieldValues)

        return !_lodash2.default.isEqual(cachedUrlDependentFieldValues, newUrlDependentFieldValues);
    },
    urlDependentFields: function urlDependentFields(url) {
        if (!url) {
            var props = this.props;
            var state = this.getState();

            url = props.url || _lodash2.default.get(state, 'props.url');
        }

        var cachedUrlDependentFields = _lodash2.default.get(this._urlDependentFieldsByUrl, [url]);

        if (cachedUrlDependentFields) {
            return cachedUrlDependentFields;
        }

        if (!url) {
            return false;
        }

        var routeParser = this.urlRouteParser(url);

        if (!this._urlDependentFieldsByUrl) {
            this._urlDependentFieldsByUrl = {};
        }

        var routeParser__params = routeParser ? paramSelector.match(routeParser.ast) : [];

        var paramKeys = routeParser__params.map(function (param) {
            return _lodash2.default.get(param, 'props.name');
        });

        this._urlDependentFieldsByUrl[url] = paramKeys;

        return this._urlDependentFieldsByUrl[url];
    },
    isUrlDependent: function isUrlDependent(url) {
        return this.urlDependentFields.apply(this, arguments).length;
    }

    /**
     * @param {Object=} props - override the props
     * @param {Object=} rootFormValue - override the form's formValue
     * @param {Object=} additionalParams - any additional params to include on the call to routeParser.reverse()
     */
    , hydratedUrl: function hydratedUrl() {
        var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
            props = _ref.props,
            state = _ref.state,
            formValue = _ref.formValue,
            _ref$params = _ref.params,
            params = _ref$params === undefined ? {} : _ref$params;

        //# NOTE: TODO: if you need to support JSONPointers, then
        //# this method needs to be updated to also parse the url prop as a template string.
        //# The expectation is that you need to specify the url like '/var/url/${JSONPointer('1/path/to/value')}'

        props = props || this.props;

        if (!state.props) {
            console.warn('state.props should never be null for this component:', this.props, ". It is very likely that the id got munged (and replaced with a number). Please retrace steps to try and reproduce this issue");
        }
        var props__url = props.url || _lodash2.default.get(state, 'props.url');
        var isUrlDependent = this.isUrlDependent();
        if (props__url == undefined) {
            return undefined;
        }
        if (!isUrlDependent) {
            return props__url;
        }

        formValue = formValue || this.getParentFormComponent().formValue(); //# {cacheTemporarily: true} DEPRECATED 20190502 - reference the base field mixin for more info

        var urlRouteParser = this.urlRouteParser(props__url);

        var routeParent = this.findAncestorBy(function (module) {
            return module.getRouteElement;
        });
        var pageId = _lodash2.default.get(formValue, 'id') || _lodash2.default.result(routeParent, 'getTypedPageId');

        //# "url" : "/ruby/api/v2/content/office/options?ruby_client_fk=3&content_subsite_fk=1&where=%7B%22rank%22%3A:dependent_rank_tokentagger%7D"
        //# NOTE: '%3A' is not valid, needs to be ':', but it cannot be ':' when using Route Parser
        var hydratedUrl = urlRouteParser.reverse(_extends({}, pageId && { id: pageId }, formValue, params));

        if (!hydratedUrl) {
            return false;
        }

        return decodeURIComponent( //# NOTE: calling this only for '%3A' => ':' if we have an encodedUriComponent for JSON
        //# this is not ideal but will have to do for now
        hydratedUrl);
    },
    url: function url() {
        var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
            props = _ref2.props,
            state = _ref2.state,
            formValue = _ref2.formValue,
            params = _ref2.params;

        props = props || this.props;
        state = state || this.getState();

        var hydratedUrl = this.hydratedUrl({ props: props, state: state, formValue: formValue, params: params });
        return hydratedUrl;
    }

};

module.exports = fieldUrlMixin;