'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _RaisedButton = require('material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _Card = require('material-ui/Card');

var _muiHelper = require('../../../../ruby-component-mixin-field-base/src/client/reactComponents/muiHelper');

var muiHelper = _interopRequireWildcard(_muiHelper);

var _ArrayList = require('./ArrayList');

var _ArrayList2 = _interopRequireDefault(_ArrayList);

var _RubyComponentFieldArrayMap = require('./RubyComponentFieldArrayMap.cssModule');

var _RubyComponentFieldArrayMap2 = _interopRequireDefault(_RubyComponentFieldArrayMap);

var _RubyComponentFieldArrayMap_style = require('./RubyComponentFieldArrayMap_style');

var _RubyComponentFieldArrayMap_style2 = _interopRequireDefault(_RubyComponentFieldArrayMap_style);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var md5 = require('crypto-js/md5');

var ArrayList_propTypeKeys = Object.keys(_ArrayList2.default.propTypes);

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label,
    RichErrorMessages = _require.RichErrorMessages;

var Label_propTypeKeys = Object.keys(Label.propTypes);

var fieldBaseStyles = process.browser ? require('@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule').default : {};

var styles = _extends({}, fieldBaseStyles, _RubyComponentFieldArrayMap2.default);


var DebugValue = function DebugValue(props) {
    var _React$createElement, _React$createElement2;

    var renderedValue = (props.value || []).map(function (value) {
        return props.toHTML(value);
    });

    return _react2.default.createElement(
        'div',
        (_React$createElement2 = {
            'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'RubyComponentFieldArrayMap'
        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _React$createElement2),
        'MyValue: ',
        _react2.default.createElement('pre', (_React$createElement = { dangerouslySetInnerHTML: { __html: JSON.stringify(props.value, null, '    ') }, 'data-codecept-selector-node': 'pre',
            'data-codecept-selector-file': 'RubyComponentFieldArrayMap'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'pre'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'pre'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _React$createElement)),
        'Rendered Value: ',
        renderedValue
    );
};

var valuePropType = PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.array]);

var RubyComponentFieldArrayMap = _react2.default.createClass({
    displayName: 'RubyComponentFieldArrayMap',

    propTypes: _extends({
        actions: PropTypes.object,
        formHasData: PropTypes.bool,
        'requestToAppendChildren': PropTypes.func,
        'requestToDeleteChild': PropTypes.func,
        'requestToUpdateExistingChild': PropTypes.func,
        'requestToEditChild': PropTypes.func,
        'requestToCancelEdit': PropTypes.func,
        selectedValue: valuePropType,
        toHTML: PropTypes.func,
        value: PropTypes.arrayOf(valuePropType),
        displayValue: PropTypes.arrayOf(valuePropType),
        childrenProps: PropTypes.shape({
            renderGridWrapper: PropTypes.func
        }),
        emptyLabel: PropTypes.string
    }, _ArrayList2.default.propTypes),
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            style: {},
            childrenProps: {
                renderGridWrapper: function renderGridWrapper(reactProps) {
                    var _React$createElement3;

                    //# reactProps need to have siblingCount
                    /*
                        <Grid fluid={true}>
                            <Row>
                                <Col xs={12} md={gridWidth_inInt}>
                                    {reactProps.children}
                                </Col>
                            </Row>
                        </Grid>
                    */

                    return _react2.default.createElement(
                        'div',
                        (_React$createElement3 = { className: styles.childrenWrapper, 'data-codecept-selector-node': 'div',
                            'data-codecept-selector-file': 'RubyComponentFieldArrayMap'
                        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _React$createElement3),
                        reactProps.children
                    );
                }
            }
        };
    },
    _onValueChange: function _onValueChange(event, value) {
        if (value.length > this.props.limit) {
            return false;
        }

        var unhydratedValue = this.unhydratedValues(value);

        //# TODO: need to update the order of the displayValue (OR, we should save the display value by hash)
        this.props.actions.setFieldValueByKey(unhydratedValue, this.props.fieldKey);
    },
    _removeSelectionForId: function _removeSelectionForId(id) {
        if (this._isDisabled()) {
            return undefined;
        } else {
            /*
            const hydratedCurrentValue = this.hydratedValues(this.props.value);
             const hydratedNewValue = hydratedCurrentValue.filter(value => value._hash != id);
            this.props.actions.setFieldValueByKey(this.unhydratedValues(hydratedNewValue), this.props.fieldKey)
            */
            this.props.requestToDeleteChild(id);
        }
    },
    _selectForId: function _selectForId(id) {
        if (this._isDisabled()) {
            return undefined;
        } else {
            /*
            const hydratedCurrentValueByHash = _.keyBy(this.hydratedValues(this.props.value), '_hash');
            const hydratedSelectedValue = hydratedCurrentValueByHash[id];
             this.props.requestToEditChild(this.unhydratedValue(hydratedSelectedValue));
            */

            this.props.requestToEditChild(id);
        }
    },
    _renderButtons: function _renderButtons() {
        var _this = this,
            _React$createElement4,
            _React$createElement5,
            _React$createElement6;

        if (this.props.mode == 'info') {
            return undefined;
        }

        var _props = this.props,
            formHasData = _props.formHasData,
            selectedValue = _props.selectedValue;


        return [selectedValue ? _react2.default.createElement(_RaisedButton2.default, (_React$createElement4 = {
            className: styles.RaisedButton + ' ' + styles.RaisedButton_primary + ' ' + styles.childrenWrapper,
            label: 'Update',
            labelPosition: 'before',
            primary: true,
            onClick: function onClick(event) {
                _this.props.requestToUpdateExistingChild(_this.props.selectedValue);
            },
            'data-codecept-selector-node': 'RaisedButton',
            'data-codecept-selector-file': 'RubyComponentFieldArrayMap'
        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _React$createElement4)) : _react2.default.createElement(_RaisedButton2.default, (_React$createElement5 = {
            className: styles.RaisedButton + ' ' + styles.RaisedButton_primary + ' ' + styles.childrenWrapper,
            label: 'Create',
            labelPosition: 'before',
            primary: true,
            disabled: !formHasData,
            onClick: function onClick(event) {
                _this.props.requestToAppendChildren();
            },
            'data-codecept-selector-node': 'RaisedButton',
            'data-codecept-selector-file': 'RubyComponentFieldArrayMap'
        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _React$createElement5)), _react2.default.createElement(_RaisedButton2.default, (_React$createElement6 = {
            className: styles.RaisedButton + ' ' + styles.childrenWrapper,
            label: 'Cancel',
            labelPosition: 'before',
            primary: false,
            disabled: !formHasData,
            onClick: function onClick(event) {
                _this.props.requestToCancelEdit();
            },
            'data-codecept-selector-node': 'RaisedButton',
            'data-codecept-selector-file': 'RubyComponentFieldArrayMap'
        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _React$createElement6))];
    },
    _renderChildren: function _renderChildren() {
        var _this2 = this;

        var castedChildren = this.props.children ? _.castArray(this.props.children) : [];
        return castedChildren.map(function (child) {
            return _react2.default.cloneElement(child, _this2.props.childrenProps || {});
        });
    },
    render: function render() {
        var _React$createElement7, _extends2, _React$createElement8, _extends3, _React$createElement9, _extends4, _extends5;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var prepareStyles = this.context.muiTheme.prepareStyles;

        var styleObjectFromTheme = muiHelper.getStyles(this.props, this.context, this.state);

        var errorTextElement = !_.isEmpty(this.props.richErrorMessages) ? _react2.default.createElement(RichErrorMessages, (_React$createElement7 = { messages: this.props.richErrorMessages, 'data-codecept-selector-node': 'RichErrorMessages',
            'data-codecept-selector-file': 'RubyComponentFieldArrayMap'
        }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _React$createElement7)) : null;

        var arrayListProps = _.pick(this.props, ArrayList_propTypeKeys);

        var labelProps = _.pick(this.props, Label_propTypeKeys);

        var Card_style = this.props.style.Card || _RubyComponentFieldArrayMap_style2.default.Card || {};
        //<DebugValue {...this.props}/>
        var hasChildrenValues = (this.props.value || []).length > 0;
        return _react2.default.createElement(
            _Card.Card,
            _extends({
                className: styles.wrapper,
                id: this.props.id
            }, Card_style, (_extends5 = {
                'data-codecept-selector-node': 'Card',
                'data-codecept-selector-file': 'RubyComponentFieldArrayMap'
            }, _defineProperty(_extends5, 'data-codecept-selector-node', 'Card'), _defineProperty(_extends5, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _defineProperty(_extends5, 'data-codecept-selector-node', 'Card'), _defineProperty(_extends5, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _extends5)),
            _react2.default.createElement(
                _Card.CardText,
                _extends({}, _RubyComponentFieldArrayMap_style2.default.CardText, (_extends4 = {
                    'data-codecept-selector-node': 'CardText',
                    'data-codecept-selector-file': 'RubyComponentFieldArrayMap'
                }, _defineProperty(_extends4, 'data-codecept-selector-node', 'CardText'), _defineProperty(_extends4, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _defineProperty(_extends4, 'data-codecept-selector-node', 'CardText'), _defineProperty(_extends4, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _extends4)),
                _react2.default.createElement(
                    'label',
                    (_React$createElement8 = { className: styles.label, 'data-codecept-selector-node': 'label',
                        'data-codecept-selector-file': 'RubyComponentFieldArrayMap'
                    }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _React$createElement8),
                    _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                        'data-codecept-selector-node': 'Label',
                        'data-codecept-selector-file': 'RubyComponentFieldArrayMap'
                    }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _extends2)))
                ),
                hasChildrenValues ? _react2.default.createElement(_ArrayList2.default, _extends({}, arrayListProps, (_extends3 = {
                    draggable: this.isDraggable(),
                    delegateValueChange: this._onValueChange,
                    delegateDeleteForId: this._removeSelectionForId,
                    delegateSelectForId: this._selectForId,
                    value: this.hydratedValues(this.props.value),
                    displayValue: this.props.displayValue,
                    selectedValue: this.hydratedValue(this.props.selectedValue),
                    'data-codecept-selector-node': 'ArrayList',
                    'data-codecept-selector-file': 'RubyComponentFieldArrayMap'
                }, _defineProperty(_extends3, 'data-codecept-selector-node', 'ArrayList'), _defineProperty(_extends3, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _defineProperty(_extends3, 'data-codecept-selector-node', 'ArrayList'), _defineProperty(_extends3, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _extends3))) : this.props.emptyLabel,
                _react2.default.createElement(
                    'div',
                    (_React$createElement9 = { className: styles.childrenContainer, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'RubyComponentFieldArrayMap'
                    }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'RubyComponentFieldArrayMap'), _React$createElement9),
                    this._renderChildren(),
                    this._renderButtons()
                ),
                errorTextElement
            )
        );
    }

    //# hashes the values and nest then in object = {_hash: _value:}
    , hydratedValue: function hydratedValue(value) {
        return {
            _hash: md5(JSON.stringify(value)).toString(),
            _value: value
        };
    },
    unhydratedValue: function unhydratedValue(value) {
        return value._value;
    },

    hydratedValues: function hydratedValues(values) {
        var _this3 = this;

        return (values || []).map(function (value, index) {
            return _extends({ _index: index }, _this3.hydratedValue(value));
        });
    },
    unhydratedValues: function unhydratedValues(values) {
        return (values || []).map(this.unhydratedValue);
    },

    _isInfoMode: function _isInfoMode() {
        var mode = this.props.mode;


        return mode === 'info';
    },
    _isDisabled: function _isDisabled() {
        return this._isInfoMode() || this.props.locked || this.props.disabled;
    },
    isDraggable: function isDraggable() {
        return !this._isDisabled() && this.props.limit != 1;
    }
});

exports.default = RubyComponentFieldArrayMap;