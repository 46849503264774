'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Avatar = require('material-ui/Avatar');

var _Avatar2 = _interopRequireDefault(_Avatar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function getStyles(props, context) {
  var card = context.muiTheme.card;


  return {
    root: {
      padding: 16,
      fontWeight: card.fontWeight,
      boxSizing: 'border-box',
      position: 'relative',
      whiteSpace: 'nowrap'
    },
    text: {
      display: 'inline-block',
      verticalAlign: 'top',
      whiteSpace: 'normal',
      paddingRight: '90px'
    },
    avatar: {
      marginRight: 16
    },
    title: {
      color: props.titleColor || card.titleColor,
      display: 'block',
      fontSize: 15
    },
    subtitle: {
      color: props.subtitleColor || card.subtitleColor,
      display: 'block',
      fontSize: 14
    },
    actions: {
      top: 0,
      bottom: 0,
      right: 4,
      margin: 'auto',
      position: 'absolute'
    }
  };
}

var CardHeader = (_temp = _class = function (_Component) {
  _inherits(CardHeader, _Component);

  function CardHeader() {
    _classCallCheck(this, CardHeader);

    return _possibleConstructorReturn(this, (CardHeader.__proto__ || Object.getPrototypeOf(CardHeader)).apply(this, arguments));
  }

  _createClass(CardHeader, [{
    key: 'render',
    value: function render() {
      var _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _extends2;

      var _props = this.props,
          actAsExpander = _props.actAsExpander,
          avatarProp = _props.avatar,
          children = _props.children,
          expandable = _props.expandable,
          showExpandableButton = _props.showExpandableButton,
          style = _props.style,
          subtitle = _props.subtitle,
          subtitleStyle = _props.subtitleStyle,
          textStyle = _props.textStyle,
          title = _props.title,
          titleColor = _props.titleColor,
          titleStyle = _props.titleStyle,
          actionButtons = _props.actionButtons,
          expandButton = _props.expandButton,
          actionsStyle = _props.actionsStyle,
          other = _objectWithoutProperties(_props, ['actAsExpander', 'avatar', 'children', 'expandable', 'showExpandableButton', 'style', 'subtitle', 'subtitleStyle', 'textStyle', 'title', 'titleColor', 'titleStyle', 'actionButtons', 'expandButton', 'actionsStyle']);

      var prepareStyles = this.context.muiTheme.prepareStyles;

      var styles = getStyles(this.props, this.context);

      var avatar = avatarProp;

      if ((0, _react.isValidElement)(avatarProp)) {
        avatar = _react2.default.cloneElement(avatar, {
          style: _extends(styles.avatar, avatar.props.style)
        });
      } else if (avatar !== null) {
        var _React$createElement;

        avatar = _react2.default.createElement(_Avatar2.default, (_React$createElement = { src: avatarProp, style: styles.avatar, 'data-codecept-selector-node': 'Avatar',
          'data-codecept-selector-file': 'CardHeader'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'CardHeader'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'CardHeader'), _React$createElement));
      }

      return _react2.default.createElement(
        'div',
        _extends({}, other, (_extends2 = { style: prepareStyles(_extends(styles.root, style)), 'data-codecept-selector-node': 'div',
          'data-codecept-selector-file': 'CardHeader'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'CardHeader'), _defineProperty(_extends2, 'data-codecept-selector-node', 'div'), _defineProperty(_extends2, 'data-codecept-selector-file', 'CardHeader'), _extends2)),
        avatar,
        _react2.default.createElement(
          'div',
          (_React$createElement4 = { style: prepareStyles(_extends(styles.text, textStyle)), 'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'CardHeader'
          }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'CardHeader'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'CardHeader'), _React$createElement4),
          _react2.default.createElement(
            'span',
            (_React$createElement2 = { style: prepareStyles(_extends(styles.title, titleStyle)), 'data-codecept-selector-node': 'span',
              'data-codecept-selector-file': 'CardHeader'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'CardHeader'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'CardHeader'), _React$createElement2),
            title
          ),
          _react2.default.createElement(
            'span',
            (_React$createElement3 = { style: prepareStyles(_extends(styles.subtitle, subtitleStyle)), 'data-codecept-selector-node': 'span',
              'data-codecept-selector-file': 'CardHeader'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'CardHeader'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'CardHeader'), _React$createElement3),
            subtitle
          )
        ),
        children,
        _react2.default.createElement(
          'div',
          (_React$createElement5 = {
            style: _extends(styles.actions, actionsStyle),
            'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'CardHeader'
          }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'CardHeader'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'CardHeader'), _React$createElement5),
          expandButton,
          actionButtons
        )
      );
    }
  }]);

  return CardHeader;
}(_react.Component), _class.muiName = 'CardHeader', _class.propTypes = {
  /**
   * If true, a click on this card component expands the card.
   */
  actAsExpander: _react.PropTypes.bool,
  /**
   * This is the [Avatar](/#/components/avatar) element to be displayed on the Card Header.
   * If `avatar` is an `Avatar` or other element, it will be rendered.
   * If `avatar` is a string, it will be used as the image `src` for an `Avatar`.
   */
  avatar: _react.PropTypes.node,
  /**
   * Can be used to render elements inside the Card Header.
   */
  children: _react.PropTypes.node,
  /**
   * If true, this card component is expandable.
   */
  expandable: _react.PropTypes.bool,
  /**
   * If true, this card component will include a button to expand the card.
   */
  showExpandableButton: _react.PropTypes.bool,
  /**
   * Override the inline-styles of the root element.
   */
  style: _react.PropTypes.object,
  /**
   * Can be used to render a subtitle in Card Header.
   */
  subtitle: _react.PropTypes.node,
  /**
   * Override the subtitle color.
   */
  subtitleColor: _react.PropTypes.string,
  /**
   * Override the inline-styles of the subtitle.
   */
  subtitleStyle: _react.PropTypes.object,
  /**
   * Override the inline-styles of the text.
   */
  textStyle: _react.PropTypes.object,
  /**
   * Can be used to render a title in Card Header.
   */
  title: _react.PropTypes.node,
  /**
   * Override the title color.
   */
  titleColor: _react.PropTypes.string,
  /**
   * Override the inline-styles of the title.
   */
  titleStyle: _react.PropTypes.object,
  /**
   * Expand button
   */
  expandButton: _react.PropTypes.node,
  /**
   * Additional action buttons
   */
  actionButtons: _react.PropTypes.node,
  /**
   * Override the inline-styles of the actions wrapper.
   */
  actionsStyle: _react.PropTypes.object
}, _class.defaultProps = {
  avatar: null
}, _class.contextTypes = {
  muiTheme: _react.PropTypes.object.isRequired
}, _temp);
exports.default = CardHeader;