'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _TextareaConnector = require('./reactComponents/TextareaConnector');

var _TextareaConnector2 = _interopRequireDefault(_TextareaConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');

var RCTextarea = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {
        reactProps: PropTypes.object,
        suggestedValue: PropTypes.string
        /*
            , suggestedValue: {
                path: '< .rubyComponentFieldForm > [props.key=main_content]'
                , method: ['getTextSummary', {sentenceCount: 3}]
            }
        */
        , autopopulate: PropTypes.bool
        //# if autopopulate: true, automatically populate with suggestedValue
        //, label: PropTypes.string
        //, key: PropTypes.string
        //, constraints: PropTypes.object
        , referenceComponentValueMap: PropTypes.oneOfType([PropTypes.func //# (val) => _.first(val); //# the assumption is that the value is always normalized to an array
        , PropTypes.string])
    },
    componentName: componentName,
    getDefaultProps: function getDefaultProps() {
        return {
            data_type: 'string',
            referenceComponentValueMap: function referenceComponentValueMap(val) {
                return _.isArray(val) ? val.filter(function (i) {
                    return i;
                }).join(', ') : val;
            }
        };
    },
    reducer: function reducer() {
        var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var action = arguments[1];

        //# need to remove if we don't need it
        //# but for now, some instance of this widget is not including the correct mixins
        return state;
    },
    action: function action() {
        var id = this.getID();
        var TYPES = {
            SET_FIELD: '@@ruby-app/' + id + '/SET_FIELD'
        };
        return {
            TYPES: TYPES,
            generators: {
                setField_withValue: function setField_withValue(value) {
                    var _this = this;

                    var actions = this.getAction().generators;
                    return function (dispatch) {
                        dispatch(actions.setFieldValueByKey(value, _this.props.key));
                    };

                    return {
                        type: TYPES.SET_FIELD,
                        payload: {
                            value: value
                        }
                    };
                }
            }
        };
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();

        return {
            selfSelector: selfSelector
        };
    },
    getReactClass: function getReactClass() {
        return _TextareaConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var TextareaComponent = this.getReactClass();

        return React.createElement(TextareaComponent, _extends({}, this.props.reactProps, _.omit(this.props, ['reactProps']), (_extends2 = {
            'data-codecept-selector-node': 'TextareaComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TextareaComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TextareaComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS,

    statesSelector: function statesSelector(state) {
        var selfSelector = this.getSelfStateSelector();

        return _extends({
            self: selfSelector(state)
        }, this.statesSelector_fromState_andOwnProps ? { selectedState: this.statesSelector_fromState_andOwnProps(state, this.props) } : { isActive: this.isFieldActiveForNamespace_fromState(state) }, _.pick(this.getProps(true), ['suggestedValue']));
    }

    //# based on ruby-component-field-auto-populated-text for autopopulate utility
    , getReferencedValues: function getReferencedValues(entireState) {
        return _.get(this.getProps(true), ['suggestedValue']);

        //# NOTE: the difference between the textarea and autopopulated text
        //# is that we don't support getReferencedComponents
        //const referencedComponents = this.getReferencedComponents();
    },
    getReferencedFormValue: function getReferencedFormValue(entireState) {
        var _this2 = this;

        var referencedStateValue = this.getReferencedValues(entireState);

        var valueMapper = _.isString(this.props.referenceComponentValueMap) ? function (value) {
            var compiled = _.template(_this2.props.referenceComponentValueMap, { imports: { '_': _ } });
            return compiled({ data: value });
        } : this.props.referenceComponentValueMap;

        return valueMapper(referencedStateValue);
    },
    _formValueFromLocalState: function _formValueFromLocalState(selfState, isError) {
        var predicateFormatter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (value) {
            return value;
        };
        var entireState = arguments[3];
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        if (selfState == undefined) {
            return undefined;
        }

        /**
         * NOTE: Other comps that override this function will usually:
         *       - get any children values
         *       - figure out the form value based on children values or not
         *
         * We decided not to do that here because this comp doesn't have children
         * and it would mean copy/pasting a large chunk of code
         */

        // Get any value the user typed into this field and the referenced field's value
        var formValue = this._getValuesFromFieldsObject(selfState.fields, isError, predicateFormatter, options);

        // Choose between the typed in value or the referenced field's value
        var selfKey = this.props.key;

        var referencedFormValue = this.props.autopopulate ? predicateFormatter(this.getReferencedFormValue(entireState)) : null;

        var finalValue = isError ? formValue : _extends({}, formValue, _defineProperty({}, selfKey, formValue[selfKey] != null ? formValue[selfKey] : referencedFormValue));

        if (options.excludeNull && _.isNil(finalValue[selfKey])) {
            delete finalValue[selfKey];
        }

        return finalValue;
    }

});

module.exports = RCTextarea;