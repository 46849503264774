'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _rubyComponent = require('@rubyapps/ruby-component');

var _rubyComponent2 = _interopRequireDefault(_rubyComponent);

var _index = require('@rubyapps/ruby-component/src/client/utils/index');

var _rubyComponentForms = require('@rubyapps/ruby-component-forms');

var _rubyComponentForms2 = _interopRequireDefault(_rubyComponentForms);

var _client = require('../local_modules/ruby-component-mixin-user-saml/src/client');

var _client2 = _interopRequireDefault(_client);

var _LoginWithSamlConnector = require('./reactComponents/LoginWithSamlConnector');

var _LoginWithSamlConnector2 = _interopRequireDefault(_LoginWithSamlConnector);

var _constants = require('../common/constants');

var CONSTANTS = _interopRequireWildcard(_constants);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _rubyComponent2.default.PropTypes;


var RCComponentLoginWithSaml = _rubyComponent2.default.createClass({
    propTypes: {
        feSettingsID: PropTypes.string
    },
    CONSTANTS: CONSTANTS,
    getDefaultProps: function getDefaultProps() {
        return {
            feSettingsID: 'rubyComponentFrontendSettings'
        };
    },
    dependencies: function dependencies() {
        return (0, _index.hydrateDependenciesForRubyComponent)(this, {
            feSettingsComponent: this.props.feSettingsID
        });
    },
    componentName: CONSTANTS.COMPONENT_NAME,
    getReactClass: function getReactClass() {
        var LoginWithSamlComponent = _LoginWithSamlConnector2.default.apply(this);
        return LoginWithSamlComponent;
    },
    getReactElement: function getReactElement() {
        var _React$createElement;

        var LoginWithSamlComponent = this.getReactClass();
        return _react2.default.createElement(LoginWithSamlComponent, (_React$createElement = {
            'data-codecept-selector-node': 'LoginWithSamlComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'LoginWithSamlComponent'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'LoginWithSamlComponent'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement));
    }
});

_rubyComponentForms2.default.registerMixin(_client2.default);

exports.default = RCComponentLoginWithSaml;