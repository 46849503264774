'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var styles = {
    MainCardText: {
        style: {
            position: 'relative',
            minHeight: 45,
            paddingLeft: 32,
            paddingRight: 32
        }
    },
    FloatingPlusMenu: {
        style: {
            position: 'absolute',
            bottom: 10,
            right: 15
        }
    },
    cardWrapper: {
        style: {}
    },
    placeholderWrapper: {
        style: {}
    },
    ReactGridLayout: {
        style: {}
    }
};

exports.default = styles;