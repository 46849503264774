'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reduxBatchedActions = require('redux-batched-actions');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _rubyMemoize = require('@rubyapps/ruby-memoize');

var _memoizee = require('memoizee');

var _memoizee2 = _interopRequireDefault(_memoizee);

var _FormConnector = require('./reactComponents/FormConnector');

var _FormConnector2 = _interopRequireDefault(_FormConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Promise = require('bluebird');


var inflection = require('inflection');
var urljoin = require('url-join');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var accessControlMixin = require('@rubyapps/ruby-component-mixin-access-control');
var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var diffHelperMixin = require('@rubyapps/ruby-component-mixin-field-diff-helper');
var fieldSelflessMixin = require('@rubyapps/ruby-component-mixin-field-selfless');
var eventEmitterMixin = require('@rubyapps/ruby-component-mixin-event-emitter');

var mixinUtils = require('@rubyapps/ruby-component-mixin-utils');

var RubyComponentFESettings__CONSTANTS = require('@rubyapps/ruby-component-frontend-settings/src/common/constants');

function _isJsonSchemaError(errorResponseObject) {
    var errors = _lodash2.default.get(errorResponseObject, 'response.body.error.errors');

    return errors && (errors[0].hasOwnProperty('dataPath') || errors[0].hasOwnProperty('schemaPath'));
}
function _convertJsonSchemaError_toErrorPath(jsonSchemaError) {
    var schemaPath = jsonSchemaError.schemaPath,
        dataPath = jsonSchemaError.dataPath;

    // Variations:
    //  SP: #/required                                       DP: .name
    //  SP: #/properties/name/minLength                      DP: .name
    //  SP: #/properties/date/required                       DP: .timeZone
    //  SP: #/properties/date/properties/timeZone/minLength  DP: .date.timeZone

    // So, we only need to handle the special case of required error for nested field

    var nestedRequiredMatch = /^#\/properties\/(.+?)\/required$/.exec(schemaPath);
    if (nestedRequiredMatch) {
        return [nestedRequiredMatch[1], dataPath].join('');
    } else {
        //# if the last node in the datapath targets an array directly.
        //# we need to nest it in an object
        if (dataPath.charAt(dataPath.length - 1) == ']') {
            return dataPath.substr(1) + '._message';
        }
        return dataPath.substr(1);
    }
}

function _convertJsonSchemaErrors_toLoopbackErrors(errors) {
    //# see if we can convert errors
    return errors.reduce(function (lbErrors, jsonSchemaError) {
        var errorPath = _convertJsonSchemaError_toErrorPath(jsonSchemaError);

        var existingErrorMessage = _lodash2.default.get(lbErrors, errorPath);
        var error = _lodash2.default.set({}, errorPath, existingErrorMessage ? existingErrorMessage + ' ' + jsonSchemaError.message : jsonSchemaError.message);
        return _lodash2.default.merge({}, lbErrors, error); //# merge instead of assign because the error might be an array of objects
    }, {});
}

var RCForm = RubyComponent.createClass({
    mixins: [baseFieldMixin, accessControlMixin, diffHelperMixin, fieldSelflessMixin, eventEmitterMixin, require('./mixin') //# mixins here should take precendencei
    //, require('@rubyapps/ruby-component-mixin-field-form-diff/src/client/index')
    ] //# we override the formValueToLocalState and fromValueFromLocalState
    , propTypes: {
        reactProps: PropTypes.object,
        thenable: PropTypes.arrayOf(PropTypes.func)
        //# , endpoints: PropTypes.shape({save: PropTypes.string}) //# NOTE: this should be deprecated
    },
    staticPropsByComponent: {
        'ruby-component-field-editor': {
            fieldInfo: {
                displayText: 'Form',
                propertyKeys: [, 'key', 'help_text', 'permissions', 'namespace', 'children_hidden']
            }
        }
    },
    componentName: componentName,
    reducer: require('./reducer'),
    action: require('./action'),
    getDefaultProps: function getDefaultProps() {
        return {
            feSettingsID: RubyComponentFESettings__CONSTANTS.COMPONENT_NAME
        };
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();
        var selfAction = this.getAction();

        var feSettingsID = this.props.feSettingsID;
        var feSettingsComponent = root.findDescendentByID(feSettingsID);
        var feSettingsSelector = feSettingsComponent.getDefaultSelector();

        return {
            selfSelector: selfSelector,
            selfAction: selfAction,
            feSettingsSelector: feSettingsSelector
        };
    }
    //# NOTE: form should not have any non-field component children ... so don't provide a children() method
    , getInitialState: function getInitialState() {
        var selfState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var childrenState = arguments[1];


        // const formValue = this._formValueFromLocalState(Object.assign({}, childrenState, selfState), false, undefined, {});
        //# NOTE: this doesn't really work probably because of dynamic fields like the DynamicForm component. So we'll have to stick with setting pristineFormData as null
        return {
            pristineFormData: null,
            pristineErrors: null //# might not need
            , forceUpdateTimestamp: null
        };
    },
    getReactClass: function getReactClass() {
        return _FormConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var FormComponent = this.getReactClass();

        return _react2.default.createElement(FormComponent, _extends({}, this.props.reactProps, (_extends2 = {
            'data-codecept-selector-node': 'FormComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'FormComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'FormComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS,

    formValueForRemote: function formValueForRemote() {
        return this.formValue({ omitFieldPicker: this.omitInfoAndControlFieldsPicker });
    }
    //# == OVERRIDES ===============================================//
    , _displayValueFromLocalState: function _displayValueFromLocalState(selfState, entireState) {
        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        if (selfState == undefined) {
            return undefined;
        }
        var selfID = this.getID();
        var selfKey = this.props.key;

        var children = this.getChildren();

        var arrOfReducedChildrenPromises = _lodash2.default.reduce(children, function (collector, child, index) {
            var childID = child.getID();
            var childDisplayValue = child._displayValueFromLocalState ? child._displayValueFromLocalState(selfState[childID], entireState, options) : undefined;
            if (childDisplayValue != undefined) {
                collector.push(childDisplayValue);
            }
            return collector;
        }, []);

        var formHiddenValues = this.props.hiddenValues || {};

        var reducedChildrenPromise = Promise.all(arrOfReducedChildrenPromises).then(function (arrOfReducedChildrenState) {
            return _extends.apply(undefined, [{}, formHiddenValues].concat(_toConsumableArray(arrOfReducedChildrenState)));
        });

        return Promise.props(reducedChildrenPromise);
    },
    _formValueToLocalState: function _formValueToLocalState(formValue, dispatchOrCollect, isError, entireFormValue, options) {
        var _arguments = arguments;

        var selfID = this.getID();
        var selfKey = this.props.key;

        var children = this.getChildren();

        //# automatically defer to it's children, don't honor the key
        //let collector = [];
        var promiseArr = children.reduce(function (collector, child) {
            if (child._formValueToLocalState) {
                //# pass in function to collect the actions instead of dispatch
                //child._formValueToLocalState(formValue, (value) => {collector.push(value)}, isError);
                collector.push(child._formValueToLocalState.apply(child, _arguments));
            }

            return collector;
        }, []);

        //dispatch(batchActions(collector));
        return Promise.all(promiseArr);
    },
    _promisedFormValueFromFieldSpecs: function _promisedFormValueFromFieldSpecs() {
        var selfKey = this.props.key;

        return baseFieldMixin._promisedFormValueFromFieldSpecs.apply(this, arguments).then(function (formValue) {
            return selfKey ? formValue[selfKey] : formValue;
        });
    },
    _objectValueToLocalState: function _objectValueToLocalState(objectValue, dispatch, isError) {
        var selfID = this.getID();
        var selfKey = this.props.key;
        var objectValueForID = objectValue[selfID];

        var children = this.getChildren();

        //# automatically defer to it's children, don't honor the key
        var collector = [];
        children.forEach(function (child) {
            if (child._formValueToLocalState) {
                //# pass in function to collect the actions instead of dispatch
                child._objectValueToLocalState(objectValueForID, function (value) {
                    collector.push(value);
                }, isError);
            }
        });
        dispatch((0, _reduxBatchedActions.batchActions)(collector));
    }

    //# Utilities
    /**
     * If any of the canEdit is false, then it's false
     */
    , canEdit: (0, _memoizee2.default)(function () {
        var _this = this,
            _arguments2 = arguments;

        //# find all canEdit functions minus myself
        var canEdit = this._flattenedMixins.reduce(function (collector, mixin) {
            if (collector === false) {
                return collector;
            }

            if (mixin.hasOwnProperty('canEdit')) {
                collector = mixin.canEdit.apply(_this, _arguments2);
            }

            return collector;
        }, true);

        return canEdit;
    }, _extends({}, _rubyMemoize.defaultMemoizeeOptions, { maxAge: 1000, preFetch: false })),
    omitInfoAndControlFieldsPicker: function omitInfoAndControlFieldsPicker(node) {
        var mode = _lodash2.default.get(node, ['props', 'mode']);

        return _lodash2.default.includes(['info', 'control'], mode);
    }

    //# used by the saveButton in formJS
    , shouldDisableSave: function shouldDisableSave() {
        var hasUnsavedChanges = this.hasUnsavedChanges();
        var canEdit = this.canEdit();

        return !(hasUnsavedChanges && canEdit);
    },
    _convertJsonSchemaErrors_toLoopbackErrors: _convertJsonSchemaErrors_toLoopbackErrors,
    delegateReceivedErrors: function delegateReceivedErrors(error) {
        var store = this.getStore();

        var _getAction = this.getAction(),
            actions = _getAction.generators;

        var errorMessagesTree = _lodash2.default.get(error, 'response.body.error.details.messages', {});
        var errors = _lodash2.default.get(error, 'response.body.error.errors');

        if (errors && (errors[0].hasOwnProperty('dataPath') || errors[0].hasOwnProperty('schemaPath'))) {
            errorMessagesTree = this._convertJsonSchemaErrors_toLoopbackErrors(errors);
        }
        store.dispatch(actions.seedWithFormError(errorMessagesTree));
    },

    handleErrorObject: function handleErrorObject(err) {
        var selfModule = this;
        var dispatch = this.getStore().dispatch;

        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES,
            actions = _getAction2.generators;

        if (err.status == 422 || _isJsonSchemaError(err)) {
            //# TODO: update to check if there's ajv errors
            var errorMessagesTree = _lodash2.default.get(err, 'response.body.error.details.messages', {});
            var errors = _lodash2.default.get(err, 'response.body.error.errors');

            if (errors && (errors[0].hasOwnProperty('dataPath') || errors[0].hasOwnProperty('schemaPath'))) {
                errorMessagesTree = selfModule._convertJsonSchemaErrors_toLoopbackErrors(errors);
            }
            dispatch(actions.seedWithFormError(errorMessagesTree));
        } else if (err.status == 403) {
            selfModule.showNotificationModal({
                title: 'Forbidden Update',
                message: 'You are not permitted to make this update.'
            });
        } else if (err.status == 409) {
            selfModule.showNotificationModal({
                title: 'Outstanding Approval',
                message: 'There is an outstanding content approval request for this item. ' + 'If you\'re a publisher and you are unable to approve this request, please try refreshing this page.'
            });
        } else {
            console.error('Error from submitToEndpoint: ', err);
            selfModule.showErrorNotification({ error: err });
        }
    },

    getSubmitTemplate_fromState: function getSubmitTemplate_fromState(applicationState) {
        var selfModule = this;

        var _selfModule$getDepend = selfModule.getDependencies(),
            feSettingsSelector = _selfModule$getDepend.feSettingsSelector;

        var feSettingsState = feSettingsSelector(applicationState);

        var modelKey = selfModule.props.key;
        if (!modelKey) {
            return undefined;
        }
        var pluralizedModelKey = modelKey == 'content' ? 'content' : inflection.pluralize(modelKey);
        var customEndpointComponent = selfModule.props.endpoints && selfModule.props.endpoints.save ? '/' + selfModule.props.endpoints.save : '';
        //# customEndpointComponent doesn't appear to be used anywhere but i'm leaving it for backwards compatibility

        //# NOTE: hacky, but we can grab the parent editPage and get props.additionalRouteParams
        var parentEditPage = this.getParent();

        var _ref = parentEditPage ? parentEditPage.props : {},
            _ref$additionalRouteP = _ref.additionalRouteParams,
            additionalRouteParams = _ref$additionalRouteP === undefined ? {} : _ref$additionalRouteP;

        return feSettingsState.restApiRoot + '/' + pluralizedModelKey + customEndpointComponent + '(/:id)' + (additionalRouteParams.query ? '?' + additionalRouteParams.query : '');
    },

    onReduxInit: function onReduxInit() {
        var formValueOnReduxInit = this.formValue();
        this.getStore().dispatch(this.getAction().generators.setPristineFormData(formValueOnReduxInit));
    },

    getParentEditPageComponent: function getParentEditPageComponent() {
        var editPageMixin = require('@rubyapps/ruby-component-mixin-edit-page');

        var parentEditPageComponent = this.findAncestorBy(function (node) {
            return mixinUtils.doesComponentHaveMixin(node, editPageMixin);
        });

        return parentEditPageComponent;
    }

});

module.exports = RCForm;