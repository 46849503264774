'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;
var _ = require('lodash');
var styles = process.browser ? require('./DatetimePicker.cssModule').default : {};

var DatetimePicker = _react2.default.createClass({
    displayName: 'DatetimePicker',

    propTypes: {
        label: PropTypes.string,
        id: PropTypes.string
        //        , value: PropTypes.
    },
    render: function render() {
        var _React$createElement;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        //# TODO: we don't show label here so we can't show the delta icon for the diff table
        return _react2.default.createElement(
            'div',
            (_React$createElement = {
                id: this.props.id,
                className: styles.wrapper,
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'DatetimePicker'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'DatetimePicker'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'DatetimePicker'), _React$createElement),
            this.props.children
        );
    }
});

exports.default = DatetimePicker;