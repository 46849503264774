'use strict';

var eventEmitterMixin = require('@rubyapps/ruby-component-mixin-event-emitter');

module.exports = {
    mixinName: 'rubyComponentMixinEditPage',
    mixins: [eventEmitterMixin],
    saveChanges_usingOptions: function saveChanges_usingOptions(options) {
        var formComponent = this.findDescendentBy(function (element) {
            return element.componentName == 'rubyComponentFieldForm';
        });
        if (formComponent == undefined) {
            console.warn("Form component not found");
            return;
        }

        var _formComponent$getAct = formComponent.getAction(),
            formActions = _formComponent$getAct.generators;

        var dispatch = this.getStore().dispatch;

        return dispatch(formActions.saveStateToRemote(options));
    },
    canEdit: function canEdit() {
        var formComponent = this.findDescendentBy(function (element) {
            return element.componentName == 'rubyComponentFieldForm';
        });

        return typeof _.get(formComponent, 'canEdit') === 'function' && formComponent.canEdit();
    }

    //# NOTE: This checks for all forms (nested) to see if any of them have unsaved changes
    , hasUnsavedChanges: function hasUnsavedChanges() {
        var formComponents = this.findDescendentsBy(function (element) {
            return element.componentName == 'rubyComponentFieldForm';
        });

        return formComponents.reduce(function (collector, formComponent) {
            if (collector) {
                return collector;
            }

            return typeof _.get(formComponent, 'hasUnsavedChanges') === 'function' && formComponent.hasUnsavedChanges();
        }, false);
    },
    getTypedPageId: function getTypedPageId() {
        var routeParams = _.result(this, 'getState.routeParams');
        return _.get(routeParams, 'routeActive') ? parseIfIntegerString(_.get(routeParams, 'id')) : undefined;
    }
};

function parseIfIntegerString(val) {
    var parsedVal = parseInt(val);
    var isNumber = !isNaN(parsedVal);
    return isNumber && parsedVal.toString().length === val.length ? parsedVal : val;
}