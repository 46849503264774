'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _ConnectorMappersGenerator = require('@rubyapps/ruby-component-mixin-field-base/src/client/reactComponents/ConnectorMappersGenerator');

var _ConnectorMappersGenerator2 = _interopRequireDefault(_ConnectorMappersGenerator);

var _TokenTagger = require('./TokenTagger');

var _TokenTagger2 = _interopRequireDefault(_TokenTagger);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {
    var selfModule = this;

    var _defaultMappersGenera = _ConnectorMappersGenerator2.default.call(this, _TokenTagger2.default),
        defaultMapStateToProps = _defaultMappersGenera.mapStateToProps;

    var optionsUrl = this.url();
    var modelInfo = selfModule.getModelInfoFromOptionsUrl(optionsUrl);

    function mapStateToProps(state, ownProps) {
        var _selfModule$getDepend = selfModule.getDependencies(),
            selfSelector = _selfModule$getDepend.selfSelector,
            listerElement = _selfModule$getDepend.listerElement;

        var selfState = selfSelector(state);

        var formObjects = _lodash2.default.filter(selfModule.getFormObjectsFromModelInfo(modelInfo), 'allowDynamicCreation');

        var hydratedProps = selfModule.getProps(true);
        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
        var selfStateProps_withoutNullValues = _lodash2.default.omitBy(hydratedProps, _lodash2.default.isNil);
        var formattedError = selfModule.formattedErrorFromState(state);

        var mappedDefaultProps = defaultMapStateToProps.apply(null, arguments);
        var mappedProps = _extends({}, ownProps, mixinFieldProps, mappedDefaultProps, {
            id: selfModule.getID(),
            fieldKey: hydratedProps.key
        }, selfStateProps_withoutNullValues, { searchValue: selfState.searchValue || '' }, { listerComponent: listerElement }, hydratedProps.key ? _lodash2.default.pick(selfState.fields[hydratedProps.key], ['value']) : {}, hydratedProps.optionToHTML ? {
            optionToHTML: selfModule.hydratedToHTMLFromProps({
                toHTML: hydratedProps.optionToHTML
            })
        } : { optionToHTML: selfModule.hydratedToHTMLFromProps({ toHTML: "${data.status === 'inactive' ? `<span class='unpublishedChipContainer'><span>` + data.text + `</span><span class='unpublishedChip'>unpublished</span></span>` : data.text}" }) }, formattedError, {
            showCreateEntryButton: ownProps.hasOwnProperty('showCreateEntryButton') ? ownProps.showCreateEntryButton : formObjects.length > 0,
            canCreateEntryWithKey: selfModule.canCreateEntryWithKey.bind(selfModule),
            canCreateSomeEntry: selfModule.canCreateSomeEntry(),
            templateOptions: formObjects.map(function (form) {
                return {
                    value: form.key,
                    text: form.name
                };
            })
        });

        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch),
            utilities: {
                createEntryAsync: selfModule.createEntryAsync.bind(selfModule),
                pushNotification: selfModule.pushNotification.bind(selfModule),
                showErrorNotification: selfModule.showErrorNotification.bind(selfModule)
            }
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_TokenTagger2.default);
}