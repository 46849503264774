'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp, _initialiseProps;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _defaultModules = require('./defaultModules');

var defaultModules = _interopRequireWildcard(_defaultModules);

var _defaultStyles = require('./defaultStyles');

var _defaultSettings = require('./defaultSettings');

var _defaultSettings2 = _interopRequireDefault(_defaultSettings);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

require('./griddle.css');

var Griddle = (_temp = _class = function (_React$Component) {
  _inherits(Griddle, _React$Component);

  function Griddle(props, context) {
    _classCallCheck(this, Griddle);

    var _this = _possibleConstructorReturn(this, (Griddle.__proto__ || Object.getPrototypeOf(Griddle)).call(this, props, context));

    _initialiseProps.call(_this);

    _this.wireUpSettings(props);
    _this.state = { showSettings: false };
    return _this;
  }

  _createClass(Griddle, [{
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      this.wireUpSettings(nextProps);
    }

    //TODO: This is okay-ish for the defaults but lets do something to override for plugins... there is stuff here for subgrid and selection and there shouldn't be.

  }, {
    key: 'getEvents',
    value: function getEvents() {
      var _this2 = this;

      return {
        onBeginDrag: this._onBeginDrag,
        makeRerankRequest: this._makeRerankRequest,
        moveRow_withIdToFirstChildOfRow_withId: this._moveRow_withIdToFirstChildOfRow_withId,
        moveRow_fromIdxPath_toIdxPath: this._moveRow_fromIdxPath_toIdxPath,
        moveRow_withId_toIdxPath: this._moveRow_withId_toIdxPath,
        getNextPage: this._nextPage,
        getPreviousPage: this._previousPage,
        getPage: this._getPage,
        refreshCurrentPage: this._refreshCurrentPage,
        setFilter: this._filter,
        setFilterByColumn: this._filterByColumn,
        setPageSize: this._setPageSize,
        rowHover: this._rowHover,
        rowClick: this._rowClick,
        rowSelect: this._rowSelect,
        columnHover: this._columnHover,
        columnClick: this._columnClick,
        headingHover: this._columnHeadingHover,
        headingClick: this._columnHeadingClick,
        toggleColumn: this._toggleColumn,
        collapseRow_withIdxPath: this._collapseRow_withIdxPath,
        expandRow_withIdxPath: this._expandRow_withIdxPath,
        expandRow: this._expandRow,
        setScrollPosition: this._setScrollPosition,
        toggleRowSelection: this._toggleRowSelection,
        selectVisibleRows: this._selectVisibleRows,
        unselectVisibleRows: this._unselectVisibleRows,
        clearAllRowSelections: this._clearAllRowSelections,
        setColumnProperties: this._setColumnProperties,
        setColumnResizeProperties: this._setColumnResizeProperties,
        setColumnRenderedSizeProperties: this._setColumnRenderedSizeProperties,
        setLoading: this._setLoading,

        //# NOTE: although we have access to this via this.props, we want to explicitly expose it as events
        setStaticData: function setStaticData(data) {
          return _this2.props.setStaticData && _this2.props.setStaticData(data);
        },
        prependStaticData: function prependStaticData(data) {
          return _this2.props.prependStaticData && _this2.props.prependStaticData(data);
        }
      };
    }
  }, {
    key: 'render',
    value: function render() {
      var events = this.getEvents();
      var components = this.getComponents();
      var componentProps = this.getComponentProps();
      var styles = this.styles,
          settings = this.settings;
      var loading = this.props.loading;


      var filterElement = _react2.default.createElement(this.components.Filter, _extends({}, this.props, { components: components, styles: styles, settings: settings, events: events, 'data-codecept-selector-file': 'griddle'
      }));

      //# exposing filterElement to utilityBar
      var utilityBarElement = _react2.default.createElement(this.components.UtilityBar, _extends({}, this.props, {
        components: components, styles: styles, settings: settings, events: events,
        filterElement: filterElement,
        'data-codecept-selector-file': 'griddle'
      }));
      var settingsToggleElement = _react2.default.createElement(this.components.SettingsToggle, { components: components, styles: styles, events: events, settings: settings, showSettings: this._showSettings, 'data-codecept-selector-file': 'griddle'
      });
      var settingsElement = this.state.showSettings ? _react2.default.createElement(this.components.Settings, _extends({}, this.props, { components: components, styles: styles, settings: settings, events: events, 'data-codecept-selector-file': 'griddle'
      })) : null;

      var hasStaticData = this.props.visibleStaticData && this.props.visibleStaticData.length > 0;
      var hasColumnFilters = this.props.columnFilters && this.props.columnFilters.length > 0;
      var hasFilter = this.props.filter && this.props.filter.length > 0;

      var mergedData = (this.props.visibleStaticData || []).concat(this.props.data || []);
      var hasData = mergedData.length > 0;

      //# NOTE: it seems like data becomes "visibleData" and data is replaced with something else sometime later
      //# this statement is true by the time we're rendering the row

      return _react2.default.createElement(
        'div',
        {
          'data-codecept-selector-node': 'div',
          'data-codecept-selector-file': 'griddle'
        },
        _react2.default.createElement(
          this.components.Header,
          _extends({}, this.props, componentProps.Header, { components: components, styles: styles,
            filterElement: filterElement,
            utilityBarElement: utilityBarElement,
            settingsToggleElement: settingsToggleElement,
            settingsElement: settingsElement,
            'data-codecept-selector-file': 'griddle'
          }),
          _react2.default.createElement(components.Loading, _extends({}, componentProps.Loading, { components: components, styles: styles, settings: settings, events: events, loading: loading, 'data-codecept-selector-file': 'griddle'
          }))
        ),
        hasData || hasColumnFilters ? _react2.default.createElement(this.components.Table, _extends({}, this.props, componentProps.Table, { components: components, styles: styles, settings: settings, events: events, data: mergedData, 'data-codecept-selector-file': 'griddle'
        })) : hasFilter ? _react2.default.createElement(this.components.NoResults, _extends({}, componentProps.NoResults, { components: components, styles: styles, settings: settings, events: events, loading: loading, 'data-codecept-selector-file': 'griddle'
        })) : _react2.default.createElement(this.components.EmptyList, _extends({}, componentProps.EmptyList, { components: components, styles: styles, settings: settings, events: events, loading: loading, 'data-codecept-selector-file': 'griddle'
        })),
        _react2.default.createElement(this.components.Pagination, _extends({}, this.props, componentProps.Pagination, { components: components, styles: styles, settings: settings, events: events, 'data-codecept-selector-file': 'griddle'
        }))
      );
    }

    /*TODO: Move to store */

  }]);

  return Griddle;
}(_react2.default.Component), _initialiseProps = function _initialiseProps() {
  var _this3 = this;

  this.wireUpSettings = function (props) {

    _this3.settings = _extends({}, _defaultSettings2.default, props.settings);
    _this3.components = _extends({}, defaultModules, props.components);

    _this3.styles = (0, _defaultStyles.getAssignedStyles)(props.style, _this3.settings.useGriddleStyles, _this3.props);
  };

  this.getComponents = function () {
    return _this3.components;
  };

  this.getComponentProps = function () {
    var props = _this3.props;
    return _extends(Object.keys(_this3.components).reduce(function (collector, key) {
      collector[key] = props.componentProps[key] || {};
      return collector;
    }, {}), props.componentProps);
    return _this3.componentProps;
  };

  this._showSettings = function (shouldShow) {
    _this3.setState({ showSettings: shouldShow });
  };

  this._onBeginDrag = function () {
    if (_this3.props.onBeginDrag) {
      _this3.props.onBeginDrag();
    }
  };

  this._makeRerankRequest = function (movedRow, events) {
    for (var _len = arguments.length, rest = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      rest[_key - 2] = arguments[_key];
    }

    if (_this3.props.makeRerankRequest) {
      var _props;

      //# NOTE: this is onDrop for the sitemap field
      (_props = _this3.props).makeRerankRequest.apply(_props, [movedRow, events].concat(rest));
    }
  };

  this._moveRow_withIdToFirstChildOfRow_withId = function (moveId, id) {
    if (_this3.props.moveRow_withIdToFirstChildOfRow_withId) {
      _this3.props.moveRow_withIdToFirstChildOfRow_withId(moveId, id);
    }
  };

  this._moveRow_withId_toIdxPath = function (id, toIdxPath) {
    if (_this3.props.moveRow_withId_toIdxPath) {
      _this3.props.moveRow_withId_toIdxPath(id, toIdxPath);
    }
  };

  this._moveRow_fromIdxPath_toIdxPath = function (fromIdxPath, toIdxPath) {
    if (_this3.props.moveRow_fromIdxPath_toIdxPath) {
      _this3.props.moveRow_fromIdxPath_toIdxPath(fromIdxPath, toIdxPath);
    }
  };

  this._nextPage = function () {
    if (_this3.props.loadNext) {
      _this3.props.loadNext();
    }
  };

  this._previousPage = function () {
    if (_this3.props.loadPrevious) {
      _this3.props.loadPrevious();
    }
  };

  this._getPage = function (pageNumber, ignoreCache) {
    if (_this3.props.loadPage) {
      _this3.props.loadPage(pageNumber, ignoreCache);
    }
  };

  this._refreshCurrentPage = function () {
    if (_this3.props.refreshCurrentPage) {
      var _props2;

      (_props2 = _this3.props).refreshCurrentPage.apply(_props2, arguments);
    }
  };

  this._filter = function (query) {
    if (_this3.props.filterData) {
      _this3.props.filterData(query);
    }
  };

  this._filterByColumn = function (filter, column) {
    if (_this3.props.filterDataByColumn) {
      _this3.props.filterDataByColumn(filter, column);
    }
  };

  this._setPageSize = function (size) {
    if (_this3.props.setPageSize) {
      _this3.props.setPageSize(size);
    }
  };

  this._toggleRowSelection = function (columnId) {
    if (_this3.props.toggleRowSelection) {
      _this3.props.toggleRowSelection(columnId);
    }
  };

  this._selectVisibleRows = function () {
    if (_this3.props.selectVisibleRows) {
      _this3.props.selectVisibleRows();
    }
  };

  this._unselectVisibleRows = function () {
    if (_this3.props.unselectVisibleRows) {
      _this3.props.unselectVisibleRows();
    }
  };

  this._clearAllRowSelections = function () {
    if (_this3.props.clearAllRowSelections) {
      _this3.props.clearAllRowSelections();
    }
  };

  this._toggleColumn = function (columnId) {
    if (_this3.props.toggleColumn) {
      _this3.props.toggleColumn(columnId);
    }
  };

  this._collapseRow_withIdxPath = function (idxPath) {
    if (_this3.props.collapseRow_withIdxPath) {
      _this3.props.collapseRow_withIdxPath(idxPath);
    }
  };

  this._expandRow_withIdxPath = function (idxPath) {
    if (_this3.props.expandRow_withIdxPath) {
      _this3.props.expandRow_withIdxPath(idxPath);
    }
  };

  this._expandRow = function () {
    if (_this3.props.expandRow) {
      var _props3;

      (_props3 = _this3.props).expandRow.apply(_props3, arguments);
    }
  };

  this._rowClick = function (rowData, originalRowData) {
    //TODO: lets make a function for getting these chains of 'does this property exist?'
    if (_this3.props.renderProperties && _this3.props.renderProperties.rowProperties && _this3.props.renderProperties.rowProperties.onClick) {
      _this3.props.renderProperties.rowProperties.onClick(rowData, originalRowData);
    }
  };

  this._rowHover = function (rowData) {
    //TODO:
    //# NOTE: DO NOT rely on rowIndex. 
    //# there's no expectation that rowIndex is up-to-date. Search for the index from the rowData if you must get the rowIndex
    //# This is because we decided to prevent the rowIndex from updating in DnD griddle. 
    //# the reason for this is because DnD is used in conjunction with subgrid and whenever we expand/collapse rows
    //# ALL rows need to rerender just because the rowIndex has changed. This is a costly operation for something
    //# that isn't currently used. So lets try to not do that
  };

  this._rowSelect = function (rowData) {
    //TODO:
    //# NOTE: DO NOT rely on rowIndex. 
    //# there's no expectation that rowIndex is up-to-date. Search for the index from the rowData if you must get the rowIndex
    //# This is because we decided to prevent the rowIndex from updating in DnD griddle. 
    //# the reason for this is because DnD is used in conjunction with subgrid and whenever we expand/collapse rows
    //# ALL rows need to rerender just because the rowIndex has changed. This is a costly operation for something
    //# that isn't currently used. So lets try to not do that
  };

  this._columnHover = function (columnId, columnValue, rowIndex, rowData) {
    //TODO:
    //# NOTE: DO NOT rely on rowIndex. 
    //# there's no expectation that rowIndex is up-to-date. Search for the index from the rowData if you must get the rowIndex
    //# This is because we decided to prevent the rowIndex from updating in DnD griddle. 
    //# the reason for this is because DnD is used in conjunction with subgrid and whenever we expand/collapse rows
    //# ALL rows need to rerender just because the rowIndex has changed. This is a costly operation for something
    //# that isn't currently used. So lets try to not do that
  };

  this._columnClick = function (columnId, columnValue, rowIndex, rowData) {
    //TODO:
    //# NOTE: DO NOT rely on rowIndex. 
    //# there's no expectation that rowIndex is up-to-date. Search for the index from the rowData if you must get the rowIndex
    //# This is because we decided to prevent the rowIndex from updating in DnD griddle. 
    //# the reason for this is because DnD is used in conjunction with subgrid and whenever we expand/collapse rows
    //# ALL rows need to rerender just because the rowIndex has changed. This is a costly operation for something
    //# that isn't currently used. So lets try to not do that
  };

  this._columnHeadingClick = function (columnId) {
    if (_this3.props.sort) {
      _this3.props.sort(columnId);
    }
  };

  this._columnHeadingHover = function (columnId) {};

  this._setScrollPosition = function (scrollLeft, scrollWidth, visibleWidth, scrollTop, scrollHeight, visibleHeight) {
    var _props4 = _this3.props,
        setScrollPosition = _props4.setScrollPosition,
        positionConfig = _props4.positionConfig,
        loadNext = _props4.loadNext;

    if (setScrollPosition) {
      setScrollPosition(scrollLeft, scrollWidth, visibleWidth, scrollTop, scrollHeight, visibleHeight);
    }
  };

  this._setColumnProperties = function (columnId, properties) {
    _this3.props.setColumnProperties(columnId, properties);
  };

  this._setColumnResizeProperties = function (propertiesByID) {
    _this3.props.setColumnResizeProperties(propertiesByID);
  };

  this._setColumnRenderedSizeProperties = function (propertiesByID) {
    _this3.props.setColumnRenderedSizeProperties(propertiesByID);
  };

  this._setLoading = function (loading) {
    if (_this3.props.setLoading) {
      _this3.props.setLoading(loading);
    }
  };
}, _temp);

// Configure the default props.

exports.default = Griddle;
Griddle.defaultProps = {
  //currentPage: 0, //# all of these are in pageProperties now
  //resultsPerPage: 10,
  //maxPage: 0,
  componentProps: {}
};

Griddle.propTypes = {
  events: _react2.default.PropTypes.object,
  data: _react2.default.PropTypes.array,
  components: _react2.default.PropTypes.object,
  componentProps: _react2.default.PropTypes.object
};