'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = getLookupMenuItemForSelfModule;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RubyWords = require('@rubyapps/ruby-words');
var inflection = RubyWords.inflection;

var lookupTemplateType = 'lookup_template';
var templateKeysToOmit = ['page_redirect'];

function getLookupMenuItemForSelfModule(selfModule) {

    var forms = selfModule.getForms();

    var lookupForms = _lodash2.default.flowRight(function (templates) {
        return _lodash2.default.sortBy(templates, function (template) {
            return template.name.toUpperCase();
        });
    }, function (templates) {
        return _lodash2.default.filter(templates, function (template) {
            var templateSubsiteFk = _lodash2.default.get(template, 'ruby_subsite_fk');
            var templateSubsites = templateSubsiteFk ? _lodash2.default.castArray(templateSubsiteFk) : [];
            var baseNeedlePerm = {
                action: 'get',
                model: 'content',
                ruby_client: template.ruby_client_fk,
                subsite: true
                //# default to true if ruby_subsite_fk is null because that's implicitly a wildcard
                //# which means the active subsite will be valid
                , template: template.id
            };

            return templateSubsites.length ? _lodash2.default.reduce(templateSubsites, function (collector, templateSubsite) {
                if (collector) {
                    return collector;
                }

                return selfModule.doesUserHavePermission(_extends({}, baseNeedlePerm, { subsite: templateSubsite }));
            }, false) : selfModule.doesUserHavePermission(baseNeedlePerm);
        });
    }, function (templates) {
        return _lodash2.default.filter(templates, function (template) {
            return !_lodash2.default.includes(templateKeysToOmit, template.key);
        });
    }, function (templates) {
        return _lodash2.default.filter(templates, { templateType: lookupTemplateType });
    }, function (keyedForms) {
        return _lodash2.default.values(keyedForms);
    })(forms);

    var lookupForm = lookupForms[0];

    var lookupMenuItem = lookupForm && {
        name: 'Lookups',
        icon: 'material-ui/svgIcons/ActionLabel',
        route: '/app/lookups/en/' + lookupForm.key + '/list'
    };

    return lookupMenuItem;
}