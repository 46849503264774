'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Popover = require('material-ui/Popover');

var _Popover2 = _interopRequireDefault(_Popover);

var _Menu = require('material-ui/Menu');

var _Menu2 = _interopRequireDefault(_Menu);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _tableHeading = require('./table-heading.cssModule');

var _tableHeading2 = _interopRequireDefault(_tableHeading);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

//# ExpandColumn is a close copy from ./row.js
//# changes were kept minimal in case we want to consolidate later on
//# isDraggable props were kept even though they aren't relevant atm
//#   - this might be used in the future if we want a UI to re-order columns
var ExpandColumn = (_temp = _class = function (_Component) {
    _inherits(ExpandColumn, _Component);

    function ExpandColumn(props, context) {
        _classCallCheck(this, ExpandColumn);

        var _this = _possibleConstructorReturn(this, (ExpandColumn.__proto__ || Object.getPrototypeOf(ExpandColumn)).call(this, props, context));

        _this.state = {
            contextIsOpen: false,
            contextAnchorEl: null
        };

        _this._handleContextClose = _this._handleContextClose.bind(_this);
        _this._handleCollapseAll = _this._handleCollapseAll.bind(_this);
        _this._handleExpandAll = _this._handleExpandAll.bind(_this);
        return _this;
    }

    _createClass(ExpandColumn, [{
        key: '_handleContextClose',
        value: function _handleContextClose() {
            this.setState({ contextIsOpen: false });
        }
    }, {
        key: '_handleCollapseAll',
        value: function _handleCollapseAll(event) {
            this._handleContextClose();
            this.props.delegateExpand(event, 'collapse', true);
        }
    }, {
        key: '_handleExpandAll',
        value: function _handleExpandAll(event) {
            this._handleContextClose();
            this.props.delegateExpand(event, 'expand', true);
        }
    }, {
        key: 'render',
        value: function render() {
            var _this2 = this;

            var _props = this.props,
                expanded = _props.expanded,
                hasChildren = _props.hasChildren,
                icons = _props.icons,
                isDraggable = _props.isDraggable,
                delegateExpand = _props.delegateExpand;


            var ExpandColumn = !hasChildren ? null : expanded ? icons.parentRowExpanded : icons.parentRowCollapsed;

            var clickableExpandColumn = _react2.default.isValidElement(ExpandColumn) ? _react2.default.createElement(
                'div',
                {
                    onClick: function onClick(event) {
                        event.preventDefault();

                        var direction = expanded ? 'collapse' : 'expand';
                        delegateExpand(event, direction);
                    },
                    onMouseDown: function onMouseDown(event) {
                        _this2.setState({ contextAnchorEl: event.currentTarget });

                        _this2._pressHoldTimeout = setTimeout(function () {
                            event.preventDefault();

                            _this2.setState({
                                contextIsOpen: true
                            });
                        }, _this2.props.contextMenuTimeout);
                    },
                    onMouseUp: function onMouseUp() {
                        clearTimeout(_this2._pressHoldTimeout);

                        _this2._pressHoldTimeout = null;
                    },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'table-heading'
                },
                ExpandColumn
            ) : null;

            var contextMenu = _react2.default.createElement(
                _Popover2.default,
                {
                    open: this.state.contextIsOpen,
                    anchorEl: this.state.contextAnchorEl,
                    anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
                    targetOrigin: { horizontal: 'left', vertical: 'top' },
                    onRequestClose: this._handleContextClose,
                    'data-codecept-selector-node': 'Popover',
                    'data-codecept-selector-file': 'table-heading'
                },
                _react2.default.createElement(
                    _Menu2.default,
                    {
                        'data-codecept-selector-node': 'Menu',
                        'data-codecept-selector-file': 'table-heading'
                    },
                    _react2.default.createElement(_MenuItem2.default, {
                        leftIcon: icons.parentRowCollapsed,
                        primaryText: 'Collapse All',
                        onTouchTap: this._handleCollapseAll,
                        'data-codecept-selector-node': 'MenuItem',
                        'data-codecept-selector-file': 'table-heading'
                    }),
                    _react2.default.createElement(_MenuItem2.default, {
                        leftIcon: icons.parentRowExpanded,
                        primaryText: 'Expand All',
                        onTouchTap: this._handleExpandAll,
                        'data-codecept-selector-node': 'MenuItem',
                        'data-codecept-selector-file': 'table-heading'
                    })
                )
            );

            var wrappedExpandColumn = isDraggable ? _react2.default.createElement(
                'div',
                { className: _tableHeading2.default.wrappedExpandColumn, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'table-heading'
                },
                icons.dragHandleIcon,
                clickableExpandColumn,
                contextMenu
            ) : _react2.default.createElement(
                'div',
                {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'table-heading'
                },
                clickableExpandColumn,
                contextMenu
            );

            return wrappedExpandColumn;
        }
    }]);

    return ExpandColumn;
}(_react.Component), _class.propTypes = {
    contextMenuTimeout: _react.PropTypes.number,
    delegateExpand: _react.PropTypes.func,
    expanded: _react.PropTypes.bool,
    hasChildren: _react.PropTypes.bool,
    icons: _react.PropTypes.shape({
        parentRowCollapsed: _react.PropTypes.node,
        parentRowExpanded: _react.PropTypes.node
    }),
    isDraggable: _react.PropTypes.bool
}, _class.defaultProps = {
    contextMenuTimeout: 600
}, _temp);

function getExpandColumn(expanded, hasChildren, icons, isDraggable, delegateExpand) {

    return {
        expandColumn: _react2.default.createElement(ExpandColumn, {
            expanded: expanded,
            hasChildren: hasChildren,
            icons: icons,
            isDraggable: isDraggable,
            delegateExpand: delegateExpand,
            'data-codecept-selector-node': 'ExpandColumn',
            'data-codecept-selector-file': 'table-heading'
        })
    };
}

exports.default = function (TableHeading) {
    var _class2, _temp2;

    return _temp2 = _class2 = function (_Component2) {
        _inherits(TableHeading_Subgrid, _Component2);

        function TableHeading_Subgrid() {
            _classCallCheck(this, TableHeading_Subgrid);

            return _possibleConstructorReturn(this, (TableHeading_Subgrid.__proto__ || Object.getPrototypeOf(TableHeading_Subgrid)).apply(this, arguments));
        }

        _createClass(TableHeading_Subgrid, [{
            key: 'render',
            value: function render() {
                var _this4 = this;

                //# ./griddle-subgrid-plugin/table.js should have already added the column so we shouldn't need to do this
                //const columns = ['expandColumn', ...this.props.columns];

                var renderProperties = this.props.renderProperties;
                var columnProperties = renderProperties.columnProperties;

                var data = this.props.data || [];
                var firstItemWithChildren = data.find(function (el) {
                    return el.children && el.children.length;
                });
                var firstChildIsExpanded = firstItemWithChildren && this.props.expandedRows[firstItemWithChildren[this.props.idColumn]];

                var expanded = firstChildIsExpanded;
                var hasChildren = !!firstItemWithChildren;
                var isDraggable = false;

                var expandColumn = getExpandColumn(expanded, hasChildren, this.props.styles.icons, isDraggable, function (event, direction, includeDescendants) {
                    _this4.props.events.expandRow(null, direction, includeDescendants);
                });

                var updatedColumnProperties = _extends({}, columnProperties, { expandColumn: _extends({}, columnProperties.expandColumn || {}, { displayName: expandColumn.expandColumn
                    })
                });

                return _react2.default.createElement(TableHeading, _extends({}, this.props, {
                    columns: this.props.columns,
                    renderProperties: _extends({}, renderProperties, { columnProperties: updatedColumnProperties }),
                    'data-codecept-selector-node': 'TableHeading',
                    'data-codecept-selector-file': 'table-heading'
                }));
            }
        }]);

        return TableHeading_Subgrid;
    }(_react.Component), _class2.propTypes = {
        columns: _react.PropTypes.array.isRequired,
        data: _react.PropTypes.array,
        expandedRows: _react.PropTypes.objectOf(_react.PropTypes.bool),
        idColumn: _react.PropTypes.string,
        styles: _react.PropTypes.shape({
            icons: _react.PropTypes.object
        }),
        events: _react.PropTypes.shape({
            expandRow: _react.PropTypes.function
        }),
        renderProperties: _react.PropTypes.shape({
            columnProperties: _react.PropTypes.object
        })
    }, _temp2;
};