'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _reduxBatchedActions = require('redux-batched-actions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var Promise = require('bluebird');
var request = require('@rubyapps/ruby-superagent');
var urljoin = require('url-join');
var Route = require('route-parser');
var rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');

var editPageMixin__CONSTANTS = require('@rubyapps/ruby-component-mixin-edit-page/src/common/constants');

function typesWithID(id) {
    return {
        SUBMIT: '@@ruby-app/' + id + '/SUBMIT',
        SET_PRISTINE_FORM_DATA: '@@ruby-app/' + id + '/SET_PRISTINE_FORM_DATA',
        SEED_CHILDREN: '@@ruby-app/' + id + '/SEED_CHILDREN',
        RETRIEVE_FORM: '@@ruby-app/' + id + '/RETRIEVE_FORM',
        RESET_STORE: '@@ruby-app/' + id + '/RESET_STORE',
        SET_FORCE_UPDATE_TIMESTAMP: '@@ruby-app/' + id + '/SET_FORCE_UPDATE_TIMESTAMP',
        SAVE_SUCCESSFUL: '@@ruby-app/' + id + '/FORM_SAVE_SUCCESSFUL',
        SAVE_ERROR: '@@ruby-app/' + id + '/FORM_SAVE_ERROR'
    };
}

var generators = {
    resetStore: function resetStore() {
        var TYPES = this.getAction().TYPES;

        return {
            type: TYPES.RESET_STORE
        };
    }
    //# expects selfModule.props.endpoint to be available
    , submitToRemote: function submitToRemote() {
        var selfModule = this;
        var selfModuleEndpoint = selfModule.props.endpoint;
        var thenable = selfModule.props.thenable;

        var _getDependencies = this.getDependencies(),
            feSettingsSelector = _getDependencies.feSettingsSelector;

        return function (_dispatch, getState) {
            var applicationState = getState();
            var feSettingsState = feSettingsSelector(applicationState);

            var endpointArgs = [feSettingsState.restApiRoot, selfModuleEndpoint];

            var endpoint = urljoin.apply(urljoin, endpointArgs);
            var formValue = selfModule.formValue({ omitFieldPicker: selfModule.omitInfoAndControlFieldsPicker });

            var requestMethod = request.post;
            var requestPromise = requestMethod(endpoint).send(formValue);

            if (thenable) {
                requestPromise.then.apply(requestPromise, thenable);
            }

            requestPromise.catch(function (err) {
                selfModule.handleErrorObject(err);
                return err;
            });

            return requestPromise;
        };
    }
    //# NOTE: specific for editing model instances
    , saveStateToRemote: function saveStateToRemote() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {/*successCallback, errorCallback*/};

        var selfModule = this;
        var successCallback = options.successCallback,
            errorCallback = options.errorCallback;

        if (successCallback) {
            successCallback.__registered = true;
        }
        if (errorCallback) {
            errorCallback.__registered = true;
        }

        var rootModule = selfModule.getRoot();

        var _getDependencies2 = this.getDependencies(),
            selfAction = _getDependencies2.selfAction;

        var TYPES = selfAction.TYPES,
            actions = selfAction.generators;


        var routeParent = selfModule.findAncestorBy(function (module) {
            return module.getRouteElement;
        });

        return function (dispatch, getState) {
            var route = new Route(selfModule.props.url || selfModule.getSubmitTemplate_fromState(getState()));

            var formValue = selfModule.formValueForRemote();
            var typedPageId = formValue.id ? formValue.id : _lodash2.default.result(routeParent, 'getTypedPageId');
            var modelKey = selfModule.props.key;

            dispatch(actions.submitToRoute_withID_andFormValue(route, typedPageId, formValue, [function (response) {
                var responseBody = response.body;
                var newID = responseBody.id;
                if (typedPageId == undefined) {
                    var newPath = rootModule.getUrlForComponent_fromModule_withParams(routeParent.getID(), routeParent, { id: newID, action: 'edit' });
                    //# replace instead of navigate because we don't want to allow
                    //# users to go back to the create endpoint
                    dispatch(actions.replacePathWithOptions({ path: newPath }));
                }

                successCallback && successCallback(null, responseBody);

                dispatch({
                    type: TYPES.SAVE_SUCCESSFUL,
                    payload: {
                        responseBody: responseBody,
                        route: route,
                        modelKey: modelKey,
                        pageId: newID
                    }
                });

                return responseBody;
            }, function (error) {
                errorCallback && errorCallback(error);
                throw error; //# rethrow error
            }]));
        };
    },
    submitToEndpoint_withID_andFormValue: function submitToEndpoint_withID_andFormValue(endpoint, id, formValue, thenableArgs) {
        console.warn('[DEPRECATED 20190404 - use `submitToRoute_withID_andFormValue` instead.');
        return this.getAction().generators.submitToRoute_withID_andFormValue(new Route(endpoint + '(/:id)'), id, formValue, thenableArgs);
    },
    submitToRoute_withID_andFormValue: function submitToRoute_withID_andFormValue(route, id, formValue, thenableArgs) {
        var _this = this;

        var selfModule = this;
        if (formValue == undefined) {
            formValue = selfModule.formValue({ omitFieldPicker: selfModule.omitInfoAndControlFieldsPicker });
        }

        var _selfModule$getDepend = selfModule.getDependencies(),
            selfAction = _selfModule$getDepend.selfAction;

        var actions = selfAction.generators;
        //# QUESTION: how to traverse the children and collect the data?
        //# can we use rubyComponent.iterativelyTraverse...
        return function (dispatch) {

            var formValueForServer = _extends({}, formValue, id ? { id: id } : {});

            var endpoint = route.reverse({ id: id });

            var endpointWithId = id && id !== 'create' ? // id should never be set to 'create'... but just in case
            urljoin(endpoint, '?url=1') : endpoint;

            var requestMethod = id ? request.put : request.post;

            var requestPromise = requestMethod(endpointWithId).send(formValueForServer).then(function (response) {
                _this.clearStatefulCacheForKey('submitToRoute_withID_andFormValue__requestPromise');

                selfModule.pushNotification(rubyNotificationsComponent.notification_savedChanges());

                //# reseed info mode components
                //# NOTE: cannot batch because it's a thunk
                var response__formValue = response.body;

                if (response__formValue) {
                    return Promise.fromCallback(function (callback) {
                        dispatch(selfAction.generators.refreshInfoModeFieldsWithFormData(response__formValue, callback));
                    }).then(function () {
                        var newPristineFormData = _extends({}
                        //# NOTE: we previously preferred formValue rather than the data from the response.body
                        //# now we're allowing the newer data to overwrite formValue
                        //# should be ok since the formValue is the recent data anyways that was used to submit to server
                        , formValue
                        //# for additional data returned from server, e.g. url
                        , response__formValue);

                        dispatch(selfAction.generators.seedWithFormData(newPristineFormData, newPristineFormData, false, undefined, {
                            omitFieldPicker: function omitFieldPicker(component, formValue, dispatchOrCollect, entireFormValue, options) {
                                var componentFieldKey = component.props.key;

                                //# formValue is actually {undefined: {...}}  because of leafnode ... we need to extract that
                                if (componentFieldKey == undefined && formValue[undefined]) {
                                    formValue = formValue[undefined];
                                }
                                //# diff component.formValue()  and formValue //# but filtered by the kys in component.formValue()
                                //
                                //# eg. drafts includes a meta property __selectedDraft that won't appear in formValu
                                //# so we should exclude that
                                //__selectedDraft: {…}, __drafts: Array(1)
                                //# NOTE: currentFormValue  might contain more fields than the ingest formValue
                                //# so we need to prune it too

                                var componentFormValue = component.formValue();

                                //# NOTE: this was the previous way we limited currentForm
                                //# which relied on component.formValue()
                                //# this doesn't work well with components that are dynamically replaced 
                                //# (eg. DynamicForm children, Matter Profile) because it relies 
                                //# on active local state that might not be available
                                /*
                                const currentFormValue = _.pick(componentFormValue
                                    , Object.keys(formValue)
                                    //# formValue, even though it's intended for component
                                    //# might have additional keys that the component does not want
                                    //# so we get the 
                                );
                                const fallbackFormKeys = Object.keys(currentFormValue);
                                    //# only form keys for selfKey
                                const inputFormValue_ingest__fallback = _.pick(formValue
                                    , fallbackFormKeys
                                );
                                const DEBUG_INGEST_V_FALLBACK_SAME = _.isEqual(inputFormValue_ingest, inputFormValue_ingest__fallback);
                                 */

                                var limitedSelfKeypaths = component.limitedSelfKeypaths();
                                var limitedKeypaths = limitedSelfKeypaths.length ? limitedSelfKeypaths : component.limitedChildrenKeypaths();
                                //# limitedKeypaths might be empty because it's a DynamicForm
                                //# for Buttons

                                var currentFromValueFromState = _lodash2.default.pick(componentFormValue, limitedKeypaths);

                                var inputFormValue_ingest = _lodash2.default.pick(formValue, limitedKeypaths);

                                var isSame = _lodash2.default.isEqual(currentFromValueFromState, inputFormValue_ingest);

                                /*
                                const SHOULD_DEBUG = false
                                
                                if (SHOULD_DEBUG){
                                    //# NOTE: For matter_profile
                                    //# for some reason, the module state already changed  here
                                    //# so componentFormValue is not correct
                                    //# This because of the __lockStatus field
                                    console.log(`[DEBUG][${component.props.label},${component.componentName}]`,
                                        {
                                            isSame
                                            , formValue
                                            , componentFormValue
                                            , inputFormValue_ingest
                                            , limitedKeypaths
                                            , component_props: component.props
                                        }
                                    )
                                }
                                */

                                //# NOTE: I think the issue is here with the matter profile

                                return isSame;
                            },
                            additionalBatchActions: [actions.setForceUpdateTimestamp(new Date())]
                        }));

                        return response;
                    });
                } else {
                    //# response.body is empty in the case of a 204
                    return response;
                }
            });

            if (thenableArgs) {
                requestPromise = requestPromise.then.apply(requestPromise, thenableArgs);
            }

            _this.setStatefulCacheForKey('submitToRoute_withID_andFormValue__requestPromise', requestPromise);

            return requestPromise.catch(function (err) {
                _this.clearStatefulCacheForKey('submitToRoute_withID_andFormValue__requestPromise');
                selfModule.showErrorNotification({ error: err });
                selfModule.handleErrorObject(err);
                return err;
            });
        };
    },
    setPristineFormData: function setPristineFormData(formData) {
        var _getDependencies3 = this.getDependencies(),
            TYPES = _getDependencies3.selfAction.TYPES;

        return {
            type: TYPES.SET_PRISTINE_FORM_DATA,
            payload: {
                formData: formData
            }
        };
    }

    /**
     * Just a way to set a pending promisedOnceResolve since seedWithFormData may be called async
     * But dependants need to know it's pending
     **/
    , before_seedWithFormData: function before_seedWithFormData() {
        var _this2 = this;

        return function (dispatch) {
            var promisedOnceResolvedExfil = {
                resolve: null,
                reject: null
            };

            var promisedOnceResolvedPromise = new Promise(function (resolve, reject) {
                promisedOnceResolvedExfil.resolve = function (res) {
                    resolve(res);

                    setTimeout(function () {
                        //# need to delay reenabling form diff
                        _this2.props.enabledFormDiff = true;
                    }, 1000);
                };
                promisedOnceResolvedExfil.reject = reject;
            });

            //# hacky but need to disable form diffing while loading
            _this2.props.enabledFormDiff = false;

            //# set cache
            _this2.setStatefulCacheForKey('pendingPromisedOnceResolved', promisedOnceResolvedPromise);
            _this2.setStatefulCacheForKey('promisedOnceResolved', promisedOnceResolvedPromise); //# expose this so dependants can chain to it
            _this2.setStatefulCacheForKey('pendingPromisedOnceResolvedExfil', promisedOnceResolvedExfil);
        };
    }
    /**
     * @param {Boolean|Object} shouldSetPristineFormData - if object, this would actually be the pristineFormData
     * @param {Object} options - options used to pass into formValueToLocalState.
     * @param {Boolean} options.additionalBatchActions - since save is now using this to update both the edit page field states and pristineFormData, we want to include additional actions as part of batch, includeing setForceUpdateTimestam.
     **/
    , seedWithFormData: function seedWithFormData(formData) {
        var shouldSetPristineFormData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        var shouldClearForm = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

        var _this3 = this;

        var callback = arguments[3];
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        var selfModule = this;

        var _selfModule$getDepend2 = selfModule.getDependencies(),
            selfAction = _selfModule$getDepend2.selfAction;

        var explicitPristineFormData = _lodash2.default.isPlainObject(shouldSetPristineFormData) ? shouldSetPristineFormData : false;

        return function (dispatch) {
            if (shouldClearForm) {
                var clearActionCollector = [];
                var collectClearAction = function collectClearAction(value) {
                    clearActionCollector.push(value);
                };

                //# clear the form
                selfModule.clearForm(collectClearAction);
                dispatch((0, _reduxBatchedActions.batchActions)(clearActionCollector));
            }

            //# return a batch action
            var collector = [];
            var collectAction = function collectAction(value) {
                for (var _len = arguments.length, rest = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
                    rest[_key - 1] = arguments[_key];
                }

                collector.push.apply(collector, [value].concat(rest));
            };

            //# passing a promisedOnceResolved promise through formValueToLocalState
            //# as a way to let all children know when the entire promiseTree resolves
            //# this is useful for dynamic components like DynamicForm that may rerender
            //# on form change, BUT it should probably defer any dynamic rerenders/replace children
            //# until AFTER the promiseTree has resolved
            //# OTHERWISE, it interferes with calculating pristineFormData
            //# eg. if the replaceChildren happens mid-pristineFormData calculation, some fields
            //# have null values (since they were unmounted), which is not valid
            var promisedOnceResolvedExfil = _this3.getStatefulCacheForKey('pendingPromisedOnceResolvedExfil') || {
                resolve: null,
                reject: null
            };

            var promisedOnceResolvedPromise = _this3.getStatefulCacheForKey('pendingPromisedOnceResolved') || new Promise(function (resolve, reject) {
                promisedOnceResolvedExfil.resolve = resolve;
                promisedOnceResolvedExfil.reject = reject;
            });
            _this3.clearStatefulCacheForKey('pendingPromisedOnceResolvedExfil');
            _this3.clearStatefulCacheForKey('pendingPromisedOnceResolved');

            if (shouldSetPristineFormData) {
                //# preemptively set pristineFormData so that components have access to it
                dispatch(selfAction.generators.setPristineFormData(explicitPristineFormData || formData));
            }

            //# set cache
            _this3.setStatefulCacheForKey('promisedOnceResolved', promisedOnceResolvedPromise);
            //# clear cache
            promisedOnceResolvedPromise.then(function () {
                _this3.clearStatefulCacheForKey('promisedOnceResolved');
            }, function () {
                _this3.clearStatefulCacheForKey('promisedOnceResolved');
            });

            var promiseTree = selfModule.formValueToLocalState(formData, collectAction, formData, _extends({
                promisedOnceResolved: promisedOnceResolvedPromise
            }, options));

            if (options.additionalBatchActions) {
                collectAction.apply(undefined, _toConsumableArray(options.additionalBatchActions));
            }

            //# set pristine data AFTER seeding so that we can call on formValue()
            //# which will give us the coerced types
            //# we should only do this if the pristineFormData isn't explicit
            //# that is, we expect the pristineFormData to be formData
            //# otherwise, we do not want to coerce the data because we assume it's correct
            //# NOTE: the data coercion has always been hacky due to poorly migrated data
            if (shouldSetPristineFormData && !explicitPristineFormData) {
                promiseTree.then(function () {
                    dispatch((0, _reduxBatchedActions.batchActions)(collector)); //# NOTE: need to dispatch AFTER the promiseTree resolves
                    selfModule.clearCachedFormValue();
                    //# because some sub-promises' resolutions dispatches through the collector()
                    //# so we can't actually dispatch the batched actions until all promises resolve

                    setImmediate(function () {
                        //# allow fields that depend on other fields to resolve their formValue
                        var coercedFormValue = _extends({}, formData, selfModule.formValue());

                        //# need to ObjectAssign in the passed in formData because the coerced formValue is missing
                        //# some meta values

                        dispatch(selfAction.generators.setPristineFormData(coercedFormValue));
                        callback && callback();
                        promisedOnceResolvedExfil.resolve && promisedOnceResolvedExfil.resolve();

                        selfModule.updateJsonDiff && selfModule.updateJsonDiff();
                    });
                });
            } else {

                promiseTree.then(function () {
                    dispatch((0, _reduxBatchedActions.batchActions)(collector)); //# NOTE: need to dispatch AFTER the promiseTree resolves
                    selfModule.clearCachedFormValue();
                    callback && callback();
                    promisedOnceResolvedExfil.resolve && promisedOnceResolvedExfil.resolve();
                });
            }
        };
    },
    seedWithFormError: function seedWithFormError(formError) {
        var selfModule = this;

        return function (dispatch) {
            selfModule.formErrorToLocalState(formError, dispatch);
            //# TODO 20170623: we can probably use module.clearErrorsWithKeys(['message']) now

            //# but right now we need to set all of the field component errors first
            //# then we need to retrieve the errors via the objectValue* methods
            //# then reset the errors
            setImmediate(function () {
                //# setImmediate to make it unblocking
                selfModule.objectErrorToLocalState(selfModule.objectErrorFromLocalState(selfModule.getState()));
            });
        };
    }
    //# After saving, we want to refresh any components whose values are readonly
    //# as they're most likely derived from the server

    /**
     * NOTE: there is an issue with selfModule.formValueToLocalState for fields that call on updateChildren() or replaceChildren() like in the Repeater field
     * @param {Object} options
     * @param {Object} options.omitFieldPicker - (Boolean) function(RubyComponent){} - a function that returns true if
     *     we want to omit the field from being seeded
     */
    , refreshInfoModeFieldsWithFormData: function refreshInfoModeFieldsWithFormData(formData, callback) {
        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        var selfModule = this;
        var parentEditPageComponent = this.getParentEditPageComponent();

        var omitFieldPicker = options.omitFieldPicker || function (node) {
            var isInfoMode = node.props.mode == 'info';

            return !isInfoMode;
        };

        //const infoModeComponents = selfModule.findDescendentsBy(node => node.props.mode == 'info');

        return function (dispatch) {
            //# return a batch action
            var collector = [];
            var collectAction = function collectAction(value) {
                collector.push(value);
            };

            var promiseTree = selfModule.formValueToLocalState(formData, collectAction, formData, {
                omitFieldPicker: omitFieldPicker
            });

            promiseTree.then(function () {
                dispatch((0, _reduxBatchedActions.batchActions)(collector)); //# NOTE: need to dispatch AFTER the promiseTree resolves
                selfModule.clearCachedFormValue();

                //# let parent edit page know that the form has been updated
                parentEditPageComponent.emit(editPageMixin__CONSTANTS.EVENTS.AFTER_REFRESH_FORM, formData);
                callback && callback();
            });
        };
    },
    setForceUpdateTimestamp: function setForceUpdateTimestamp(timestamp) {
        var TYPES = this.getAction().TYPES;
        return {
            type: TYPES.SET_FORCE_UPDATE_TIMESTAMP,
            payload: {
                timestamp: timestamp
            }
        };
    }

};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};