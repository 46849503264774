'use strict';

var _ = require('lodash');
var createVisitor = require('./create_visitor');
var escapeRegExp = /[\-{}\[\]+?.,\\\^$|#\s]/g;

/**
 * @class
 * @private
 */
function Matcher(options) {
  this.captures = options.captures;
  this.re = options.re;
}

/**
 * Try matching a path against the generated regular expression
 * @param {String} path The path to try to match
 * @param {Object=} options - additional options for matching
 * @param {Boolean} [options.deep=false] - by default, we return match with flattened keypaths.
 *                   If true. we use lodash to hydrate the nested object instead
 * @return {Object|false}      matched parameters or false
 */
Matcher.prototype.match = function (path, options) {
  var match = this.re.exec(path);
  var matchParams = {};

  if (!match) {
    return false;
  }

  var isDeep = _.get(options, 'deep', false);
  this.captures.forEach(function (capture, i) {
    if (typeof match[i + 1] === 'undefined') {
      if (isDeep) {
        _.set(matchParams, capture, undefined);
      } else {
        matchParams[capture] = undefined;
      }
    } else {
      var capturedValue = decodeURIComponent(match[i + 1]);
      if (isDeep) {
        _.set(matchParams, capture, capturedValue);
      } else {
        matchParams[capture] = capturedValue;
      }
    }
  });

  return matchParams;
};

/**
 * Visitor for the AST to create a regular expression matcher
 * @class RegexpVisitor
 * @borrows Visitor-visit
 */
var RegexpVisitor = createVisitor({
  Concat: function Concat(node) {
    return node.children.reduce(function (memo, child) {
      var childResult = this.visit(child);
      return {
        re: memo.re + childResult.re,
        captures: memo.captures.concat(childResult.captures)
      };
    }.bind(this), { re: '', captures: [] });
  },
  Literal: function Literal(node) {
    return {
      re: node.props.value.replace(escapeRegExp, '\\$&'),
      captures: []
    };
  },

  Splat: function Splat(node) {
    return {
      re: '([^?]*?)',
      captures: [node.props.name]
    };
  },

  Param: function Param(node) {
    return {
      re: '([^\\/\\?]+)',
      captures: [node.props.name]
    };
  },

  Optional: function Optional(node) {
    var child = this.visit(node.children[0]);
    return {
      re: '(?:' + child.re + ')?',
      captures: child.captures
    };
  },

  Root: function Root(node) {
    var childResult = this.visit(node.children[0]);
    return new Matcher({
      re: new RegExp('^' + childResult.re + '(?=\\?|$)'),
      captures: childResult.captures
    });
  }
});

module.exports = RegexpVisitor;