'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _AppBar = require('material-ui/AppBar');

var _AppBar2 = _interopRequireDefault(_AppBar);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _close = require('material-ui/svg-icons/navigation/close');

var _close2 = _interopRequireDefault(_close);

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

var _AutoCompleteSelector = require('@rubyapps/ruby-react-components/src/client/input/AutoCompleteSelector');

var _AutoCompleteSelector2 = _interopRequireDefault(_AutoCompleteSelector);

var _Dialog = require('material-ui/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _dialog = require('@rubyapps/ruby-styles/src/client/styleObjects/dialog');

var _dialog2 = _interopRequireDefault(_dialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Promise = require('bluebird');
var request = require('@rubyapps/ruby-superagent');

var PropTypes = _react2.default.PropTypes;

var styleObject = {
    Dialog: _dialog2.default
};

var SaveSearchDialogHeader = _react2.default.createClass({
    displayName: 'SaveSearchDialogHeader',

    propTypes: {
        title: PropTypes.string,
        delegateClose: PropTypes.func,
        onSave__promiseArgs: PropTypes.arrayOf(PropTypes.func)
    },
    getDefaultProps: function getDefaultProps() {
        return {
            title: 'Save Search'
        };
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4;

        var myContext = this;
        var styleColor = {
            color: '#ffffff'
        };

        return _react2.default.createElement(_AppBar2.default, (_React$createElement4 = {
            title: _react2.default.createElement(
                'span',
                (_React$createElement = {
                    'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'SaveSearchDialog'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'SaveSearchDialog'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'SaveSearchDialog'), _React$createElement),
                this.props.title
            ),
            iconElementLeft: _react2.default.createElement(
                _IconButton2.default,
                (_React$createElement3 = { onClick: this.props.delegateClose, 'data-codecept-selector-node': 'IconButton',
                    'data-codecept-selector-file': 'SaveSearchDialog'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'SaveSearchDialog'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'SaveSearchDialog'), _React$createElement3),
                _react2.default.createElement(_close2.default, (_React$createElement2 = {
                    'data-codecept-selector-node': 'NavigationClose',
                    'data-codecept-selector-file': 'SaveSearchDialog'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'NavigationClose'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'SaveSearchDialog'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'NavigationClose'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'SaveSearchDialog'), _React$createElement2))
            ),
            'data-codecept-selector-node': 'AppBar',
            'data-codecept-selector-file': 'SaveSearchDialog'
        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'SaveSearchDialog'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'SaveSearchDialog'), _React$createElement4));
    }
});

var SaveSearchDialog = _react2.default.createClass({
    displayName: 'SaveSearchDialog',

    propTypes: {
        open: PropTypes.bool,
        delegateClose: PropTypes.func,
        requestToInvalidateCacheTimestamp: PropTypes.string,

        savedSearches_optionsUrl: PropTypes.string,
        savedSearches_putEndpoint: PropTypes.string,
        savedSearches_baseParams: PropTypes.object
    },

    getDefaultProps: function getDefaultProps() {
        return {
            delegateClose: function delegateClose() {},

            savedSearches_optionsUrl: '',
            savedSearches_putEndpoint: '',
            savedSearches_baseParams: {}
        };
    },
    getInitialState: function getInitialState() {
        return {
            savedSearchName: '',
            savedSearchItem: undefined,
            pendingSave: false
        };
    }
    //== START: HANDLERS ==================================================================//
    , _delegatedSavedSearch__onUpdateInput: function _delegatedSavedSearch__onUpdateInput(savedSearchName) {
        this.setState({
            savedSearchName: savedSearchName
        });
    },
    _delegatedSavedSearch__onOptionCleared: function _delegatedSavedSearch__onOptionCleared() {
        this.setState({
            savedSearchItem: undefined
        });
    },
    _delegatedSavedSearch__onOptionSelected: function _delegatedSavedSearch__onOptionSelected(savedSearchItem) {
        this.setState({
            savedSearchItem: savedSearchItem
        });
    },
    _onSaveRequest: function _onSaveRequest() {
        var _this = this;

        if (this.state.pendingSave) {
            return Promise.resolve(false);
        }

        this.setState({
            pendingSave: true
        });
        var finalParams = _extends({}, this.props.savedSearches_baseParams, this.state.savedSearchItem ? {
            id: this.state.savedSearchItem.value,
            name: this.state.savedSearchItem.text
        } : {
            name: this.state.savedSearchName
        });

        var requestPromise = request.put(this.props.savedSearches_putEndpoint).send(finalParams).then(function (response) {
            //# successfully saved
            _this.setState({
                pendingSave: false,
                savedSearchName: ''
            });
            _this.props.delegateClose();

            return response;
        }, function (error) {
            _this.setState({
                pendingSave: false
            });

            //# TODO: show failure notification?
            return error;
        });

        if (this.props.onSave__promiseArgs) {
            requestPromise = requestPromise.then.apply(requestPromise, this.props.onSave__promiseArgs);
        }

        return requestPromise;
    }

    //== END: HANDLERS ====================================================================//

    , render: function render() {
        var _React$createElement5, _React$createElement6, _extends2, _React$createElement7, _React$createElement8, _extends3;

        var isSaveButtonDisabled = !(this.state.savedSearchItem || this.state.savedSearchName.length);

        var actions = [_react2.default.createElement(_FlatButton2.default, (_React$createElement5 = {
            label: 'Cancel',
            primary: false,
            onTouchTap: this.props.delegateClose,
            'data-codecept-selector-node': 'FlatButton',
            'data-codecept-selector-file': 'SaveSearchDialog'
        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'SaveSearchDialog'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'SaveSearchDialog'), _React$createElement5)), _react2.default.createElement(_FlatButton2.default, (_React$createElement6 = {
            label: this.state.savedSearchItem ? "Update" : "Save",
            primary: true,
            onTouchTap: this._onSaveRequest,
            disabled: isSaveButtonDisabled,
            'data-codecept-selector-node': 'FlatButton',
            'data-codecept-selector-file': 'SaveSearchDialog'
        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'SaveSearchDialog'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'SaveSearchDialog'), _React$createElement6))];

        var saveSearchDialogHeaderProps = _lodash2.default.pick(this.props, Object.keys(SaveSearchDialogHeader.propTypes));

        return _react2.default.createElement(
            _Dialog2.default,
            _extends({}, styleObject.Dialog, (_extends3 = {
                title: _react2.default.createElement(SaveSearchDialogHeader, _extends({}, saveSearchDialogHeaderProps, (_extends2 = {
                    'data-codecept-selector-node': 'SaveSearchDialogHeader',
                    'data-codecept-selector-file': 'SaveSearchDialog'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'SaveSearchDialogHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'SaveSearchDialog'), _defineProperty(_extends2, 'data-codecept-selector-node', 'SaveSearchDialogHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'SaveSearchDialog'), _extends2))),
                modal: true,
                actions: actions,
                open: this.props.open,
                onRequestClose: this.props.delegateClose,
                'data-codecept-selector-node': 'Dialog',
                'data-codecept-selector-file': 'SaveSearchDialog'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_extends3, 'data-codecept-selector-file', 'SaveSearchDialog'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_extends3, 'data-codecept-selector-file', 'SaveSearchDialog'), _extends3)),
            _react2.default.createElement(
                'div',
                (_React$createElement8 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'SaveSearchDialog'
                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'SaveSearchDialog'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'SaveSearchDialog'), _React$createElement8),
                _react2.default.createElement(_AutoCompleteSelector2.default, (_React$createElement7 = {
                    requestToInvalidateCacheTimestamp: this.props.requestToInvalidateCacheTimestamp,
                    id: 'savedSearchSelector',
                    hintText: 'Saved search name',
                    menuCloseDelay: 0,
                    canAutoPosition: false,
                    url: this.props.savedSearches_optionsUrl,
                    searchText: this.state.savedSearchName,
                    shouldClearUnfoundedSearch_onMenuClose: false,
                    onUpdateInput: this._delegatedSavedSearch__onUpdateInput,
                    onOptionSelected: this._delegatedSavedSearch__onOptionSelected,
                    onOptionCleared: this._delegatedSavedSearch__onOptionCleared,
                    openOnFocus: true,
                    filter: function filter(searchText, key) {
                        return key.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
                    },
                    fullWidth: true,
                    'data-codecept-selector-node': 'AutoCompleteSelector',
                    'data-codecept-selector-file': 'SaveSearchDialog'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'AutoCompleteSelector'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'SaveSearchDialog'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'AutoCompleteSelector'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'SaveSearchDialog'), _React$createElement7))
            )
        );
    }
    //== START: UTILITY ==================================================================//
    , _clearSavedSearchName: function _clearSavedSearchName() {
        this.setState({
            savedSearchName: ''
        });
    }

    //== END: UTILITY ==================================================================//
});

exports.default = SaveSearchDialog;