'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _ConnectorMappersGenerator = require('@rubyapps/ruby-component-mixin-field-base/src/client/reactComponents/ConnectorMappersGenerator');

var _ConnectorMappersGenerator2 = _interopRequireDefault(_ConnectorMappersGenerator);

var _Toggles = require('./Toggles');

var _Toggles2 = _interopRequireDefault(_Toggles);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector;

    var _defaultMappersGenera = _ConnectorMappersGenerator2.default.call(this, _Toggles2.default),
        defaultMapStateToProps = _defaultMappersGenera.mapStateToProps;

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);

        var hydratedProps = selfModule.getProps(true);
        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
        var selfStateProps_withoutNullValues = _.omitBy(hydratedProps, _.isNil);
        var formattedError = selfModule.formattedErrorFromState(state);

        var mappedDefaultProps = defaultMapStateToProps.apply(null, arguments);

        var mappedProps = _extends({}, ownProps, mixinFieldProps, mappedDefaultProps, {
            id: selfModule.getID(),
            fieldKey: selfModule.props.key //key is not exposed to the react component
            , isMounted: selfState.isMounted
        }, selfStateProps_withoutNullValues, hydratedProps.key ? _.pick(selfState.fields[hydratedProps.key], ['value']) : {}, hydratedProps.optionToHTML ? {
            optionToHTML: selfModule.hydratedToHTMLFromProps({
                toHTML: hydratedProps.optionToHTML
            })
        } : {}, formattedError);

        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Toggles2.default);
}