'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _baseField = require('@rubyapps/ruby-styles/src/client/styleObjects/baseField');

var _baseField2 = _interopRequireDefault(_baseField);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    CardText: {
        style: {
            paddingTop: 0,
            paddingBottom: 0,
            position: 'relative'
        }
    },
    TextField: _extends({}, _baseField2.default, {
        style: _extends({}, _baseField2.default.style, {
            width: '100%'
        })
    }),
    TextField_hasError: _extends({}, _baseField2.default, {
        style: _extends({}, _baseField2.default.style, {
            width: '100%',
            marginBottom: '22px'
        })
    }),
    Menu: {
        style: {
            overflowX: 'hidden'
        }
    },
    MenuItem: {
        disableFocusRipple: true,
        innerDivStyle: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            minHeight: '24px',
            lineHeight: '16px',
            paddingTop: '12px',
            paddingBottom: '12px'
        }
    },
    menuItem_secondaryText: {
        style: {
            fontSize: '10px',
            lineHeight: '16px',
            marginRight: '115px' // to make sure that the secondaryText won't overlap with .unpublishedChip
            , paddingLeft: '10px',
            float: 'none',
            flexGrow: 1,
            order: 2
        }
    },
    FlatButton__createEntry: {
        /*
        style: {
            position: 'absolute'
            , right: '16px'
            , top: '13px'
        }
        */
    }
};