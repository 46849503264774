'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp2;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TimePickerDialog = require('./TimePickerDialog');

var _TimePickerDialog2 = _interopRequireDefault(_TimePickerDialog);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var moment = require('@rubyapps/friendly-ruby-moment');
var guessedTimeZone = moment.tz.guess() || 'UTC';
var nowMoment = moment();
var epochTimeMoment = moment.unix(0).tz(guessedTimeZone).set({
  hour: nowMoment.hour(),
  minute: nowMoment.minute()
}).tz('UTC');

var TimePicker = (_temp2 = _class = function (_Component) {
  _inherits(TimePicker, _Component);

  function TimePicker() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, TimePicker);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = TimePicker.__proto__ || Object.getPrototypeOf(TimePicker)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      fieldValueTimeMoment: null,
      dialogTimeMoment: null
    }, _this.handleAcceptDialog = function (_ref2) {
      var time = _ref2.time,
          zoneName = _ref2.zoneName;

      _this.setState({
        fieldValueTimeMoment: time,
        fieldValueZoneName: zoneName
      });
      if (_this.props.onChange) _this.props.onChange(null, { timeMoment: time, zoneName: zoneName });
    }, _this.handleFocusInput = function (event) {
      event.target.blur();
      if (_this.props.onFocus) {
        _this.props.onFocus(event);
      }
    }, _this.handleTouchTapInput = function (event) {
      event.preventDefault();

      if (!_this.props.disabled) {
        _this.openDialog();
      }

      if (_this.props.onTouchTap) {
        _this.props.onTouchTap(event);
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(TimePicker, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var controlledTime = this.getControlledTime();
      var zoneName = _lodash2.default.get(this, 'props.value.zoneName');
      this.setState({
        fieldValueTimeMoment: controlledTime,
        fieldValueZoneName: zoneName
      });
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      if (nextProps.value !== this.props.value) {
        this.setState({
          fieldValueTimeMoment: this.getControlledTime(nextProps),
          fieldValueZoneName: _lodash2.default.get(nextProps, 'value.zoneName')
        });
      }
    }

    /**
     * Alias for `openDialog()` for an api consistent with TextField.
     */

  }, {
    key: 'focus',
    value: function focus() {
      this.openDialog();
    }
  }, {
    key: 'openDialog',
    value: function openDialog() {
      this.setState({
        dialogTimeMoment: this.state.fieldValueTimeMoment || this.getEpochTimeMoment(),
        dialogZoneName: this.state.fieldValueZoneName
      });
      this.refs.dialogWindow.show();
    }
  }, {
    key: 'getControlledTime',
    value: function getControlledTime() {
      var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.props;

      var result = null;
      var timeMoment = _lodash2.default.get(props, 'value.timeMoment');
      var isMomentInstance = moment.isMoment(timeMoment);
      return isMomentInstance ? timeMoment.tz(props.value.zoneName) : undefined;
    }
  }, {
    key: 'getEpochTimeMoment',
    value: function getEpochTimeMoment() {
      var guessedTimeZone = moment.tz.guess() || 'UTC';
      var nowMoment = moment();
      var epochTimeMoment = moment.unix(0).tz(guessedTimeZone).set({
        hour: nowMoment.hour(),
        minute: nowMoment.minute()
      });
      return epochTimeMoment;
    }
  }, {
    key: 'render',
    value: function render() {
      var _extends2, _React$createElement, _React$createElement2;

      var _props = this.props,
          autoOk = _props.autoOk,
          cancelLabel = _props.cancelLabel,
          container = _props.container,
          dialogBodyStyle = _props.dialogBodyStyle,
          dialogStyle = _props.dialogStyle,
          format = _props.format,
          okLabel = _props.okLabel,
          onFocus = _props.onFocus,
          onTouchTap = _props.onTouchTap,
          onShow = _props.onShow,
          onDismiss = _props.onDismiss,
          style = _props.style,
          textFieldStyle = _props.textFieldStyle,
          customTimeZoneList = _props.customTimeZoneList,
          other = _objectWithoutProperties(_props, ['autoOk', 'cancelLabel', 'container', 'dialogBodyStyle', 'dialogStyle', 'format', 'okLabel', 'onFocus', 'onTouchTap', 'onShow', 'onDismiss', 'style', 'textFieldStyle', 'customTimeZoneList']);

      var prepareStyles = this.context.muiTheme.prepareStyles;
      var _state = this.state,
          fieldValueTimeMoment = _state.fieldValueTimeMoment,
          fieldValueZoneName = _state.fieldValueZoneName;


      var isMomentInstance = moment.isMoment(fieldValueTimeMoment);
      var timeString = isMomentInstance ? fieldValueTimeMoment.tz(fieldValueZoneName).format('hh:mm a zz') : '';

      return _react2.default.createElement(
        'div',
        (_React$createElement2 = { style: prepareStyles(_extends({}, style)), 'data-codecept-selector-node': 'div',
          'data-codecept-selector-file': 'TimePicker'
        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TimePicker'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TimePicker'), _React$createElement2),
        _react2.default.createElement(_TextField2.default, _extends({}, other, (_extends2 = {
          style: textFieldStyle,
          ref: 'input',
          value: timeString,
          onFocus: this.handleFocusInput,
          onTouchTap: this.handleTouchTapInput,
          'data-codecept-selector-node': 'TextField',
          'data-codecept-selector-file': 'TimePicker'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends2, 'data-codecept-selector-file', 'TimePicker'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends2, 'data-codecept-selector-file', 'TimePicker'), _extends2))),
        _react2.default.createElement(_TimePickerDialog2.default, (_React$createElement = {
          ref: 'dialogWindow',
          bodyStyle: dialogBodyStyle,
          container: container,
          initialZoneName: this.state.dialogZoneName,
          initialTime: this.state.dialogTimeMoment,
          onAccept: this.handleAcceptDialog,
          onShow: onShow,
          onDismiss: onDismiss,
          format: format,
          okLabel: okLabel,
          cancelLabel: cancelLabel,
          autoOk: autoOk,
          style: dialogStyle,
          customTimeZoneList: customTimeZoneList,
          'data-codecept-selector-node': 'TimePickerDialog',
          'data-codecept-selector-file': 'TimePicker'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'TimePickerDialog'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TimePicker'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'TimePickerDialog'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TimePicker'), _React$createElement))
      );
    }
  }]);

  return TimePicker;
}(_react.Component), _class.propTypes = {
  /**
   * If true, automatically accept and close the picker on set minutes.
   */
  autoOk: _react.PropTypes.bool,
  /**
   * Override the label of the 'Cancel' button.
   */
  cancelLabel: _react.PropTypes.node,
  /**
   * The initial time value of the TimePicker.
   */
  defaultTime: _react.PropTypes.object,
  /**
   * Override the inline-styles of TimePickerDialog's body element.
   */
  dialogBodyStyle: _react.PropTypes.object,
  /**
   * Override the inline-styles of TimePickerDialog's root element.
   */
  dialogStyle: _react.PropTypes.object,
  /**
   * If true, the TimePicker is disabled.
   */
  disabled: _react.PropTypes.bool,
  /**
   * Tells the component to display the picker in `ampm` (12hr) format or `24hr` format.
   */
  format: _react.PropTypes.oneOf(['ampm', '24hr']),
  /**
   * Override the label of the 'OK' button.
   */
  okLabel: _react.PropTypes.node,
  /**
   * Callback function that is fired when the time value changes. The time value is passed in a Date Object.
   * Since there is no particular event associated with the change the first argument will always be null
   * and the second argument will be the new Date instance.
   */
  onChange: _react.PropTypes.func,
  /**
   * Callback function fired when the TimePicker dialog is dismissed.
   */
  onDismiss: _react.PropTypes.func,
  /**
   * Callback function fired when the TimePicker `TextField` gains focus.
   */
  onFocus: _react.PropTypes.func,
  /**
   * Callback function fired when the TimePicker dialog is shown.
   */
  onShow: _react.PropTypes.func,
  /**
   * Callback function fired when the TimePicker is tapped or clicked.
   */
  onTouchTap: _react.PropTypes.func,
  /**
   * Override the inline-styles of the root element.
   */
  style: _react.PropTypes.object,
  /**
   * Override the inline-styles of TimePicker's TextField element.
   */
  textFieldStyle: _react.PropTypes.object,
  /**
   * Sets the time for the Time Picker programmatically.
   */
  value: _react.PropTypes.object
}, _class.defaultProps = {
  autoOk: false,
  cancelLabel: 'Cancel',
  disabled: false,
  format: 'ampm',
  okLabel: 'OK',
  style: {},
  value: null
}, _class.contextTypes = {
  muiTheme: _react.PropTypes.object.isRequired
}, _temp2);
exports.default = TimePicker;