'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');

var forcedLeafNodeMixin = {
    mixinName: 'rubyComponentMixinFieldForcedLeafNode',
    _formValueToLocalState: function _formValueToLocalState(formValue, dispatchOrCollect, isError, entireFormValue) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        return baseFieldMixin._formValueToLocalState.call(this, formValue, dispatchOrCollect, isError, entireFormValue, _extends({ ignoreChildren: true }, options));
    },
    _formValueFromLocalState: function _formValueFromLocalState(selfState, isError, predicateFormatter, entireState) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        return baseFieldMixin._formValueFromLocalState.call(this, selfState, isError, predicateFormatter, entireState, _extends({ ignoreChildren: true }, options));
    },

    _objectValueToLocalState: function _objectValueToLocalState(objectValue, dispatchOrCollect, isError) {
        var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

        return baseFieldMixin._objectValueToLocalState.call(this, objectValue, dispatchOrCollect, isError, _extends({ ignoreChildren: true }, options));
    },
    _objectValueFromLocalState: function _objectValueFromLocalState(selfState, isError, limitToTabWithLabel) {
        var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

        return baseFieldMixin._objectValueFromLocalState.call(this, selfState, isError, limitToTabWithLabel, _extends({ ignoreChildren: true }, options));
    }
};

module.exports = forcedLeafNodeMixin;