'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Toolbar = require('material-ui/Toolbar');

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _IconButton = require('material-ui/IconButton/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _IconMenu = require('material-ui/IconMenu');

var _IconMenu2 = _interopRequireDefault(_IconMenu);

var _moreVert = require('material-ui/svg-icons/navigation/more-vert');

var _moreVert2 = _interopRequireDefault(_moreVert);

var _Divider = require('material-ui/Divider');

var _Divider2 = _interopRequireDefault(_Divider);

var _additionalContextMenuItems = require('./additionalContextMenuItems');

var _additionalContextMenuItems2 = _interopRequireDefault(_additionalContextMenuItems);

var _styles = require('./styles.cssModule');

var _styles2 = _interopRequireDefault(_styles);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

//# styles


var UtilityToolbarGroup = _react2.default.createClass({
    displayName: 'UtilityToolbarGroup',

    propTypes: {
        additionalHeaderToolbarItems: PropTypes.arrayOf(PropTypes.node),
        toggleProps: PropTypes.object,
        utilityBarActions: PropTypes.array

    },
    getDefaultProps: function getDefaultProps() {
        return {
            utilityBarActions: []
        };
    },

    partitionedContextMenuItems: function partitionedContextMenuItems() {
        var menuItems = this.props.utilityBarActions;

        var sectionNames = menuItems.reduce(function (result, menuItem) {
            var _menuItem$sectionName = menuItem.sectionName,
                sectionName = _menuItem$sectionName === undefined ? 'other' : _menuItem$sectionName;

            return _lodash2.default.includes(result, sectionName) ? result : [].concat(result, sectionName);
        }, []);

        var sectionedMenuItems = menuItems.reduce(function (result, menuItem) {
            var _menuItem$sectionName2 = menuItem.sectionName,
                sectionName = _menuItem$sectionName2 === undefined ? 'other' : _menuItem$sectionName2;

            return _extends(result, _defineProperty({}, sectionName, [].concat(result[sectionName] || [], menuItem)));
        }, {});

        return _lodash2.default.flatMap(sectionNames, function (sectionName) {
            var _React$createElement;

            var menuItemsInSection = sectionedMenuItems[sectionName];
            return [menuItemsInSection.map(function (item) {
                var _extends3;

                return _react2.default.createElement(_MenuItem2.default, _extends({}, item, (_extends3 = {
                    'data-codecept-selector-node': 'MenuItem',
                    'data-codecept-selector-file': 'UtilityToolbarGroup'
                }, _defineProperty(_extends3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends3, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _defineProperty(_extends3, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends3, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _extends3)));
            }), _react2.default.createElement(_Divider2.default, (_React$createElement = {
                'data-codecept-selector-node': 'Divider',
                'data-codecept-selector-file': 'UtilityToolbarGroup'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Divider'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _React$createElement))];
        }).slice(0, -1);
    },
    render: function render() {
        var _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5;

        var additionalHeaderToolbarItems = this.props.additionalHeaderToolbarItems;


        var griddleInstance = this;

        var menuItems = this.props.utilityBarActions;
        var additionalMenuItems = (0, _additionalContextMenuItems2.default)(menuItems, this.props);
        var shouldShowToolbarGroup = additionalHeaderToolbarItems && additionalHeaderToolbarItems.length || menuItems.length || additionalMenuItems.length;

        return _react2.default.createElement(
            _Toolbar.ToolbarGroup,
            (_React$createElement5 = { className: shouldShowToolbarGroup ? _styles2.default.ToolbarGroup : '', 'data-codecept-selector-node': 'ToolbarGroup',
                'data-codecept-selector-file': 'UtilityToolbarGroup'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _React$createElement5),
            this.props.additionalHeaderToolbarItems,
            (menuItems.length > 0 || additionalMenuItems.length > 0) && _react2.default.createElement(
                _IconMenu2.default,
                (_React$createElement4 = {
                    key: 'ruby-actions_context-menu',
                    iconButtonElement: _react2.default.createElement(
                        _IconButton2.default,
                        (_React$createElement3 = { style: { marginRight: -24 }, 'data-codecept-selector-node': 'IconButton',
                            'data-codecept-selector-file': 'UtilityToolbarGroup'
                        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _React$createElement3),
                        _react2.default.createElement(_moreVert2.default, (_React$createElement2 = {
                            'data-codecept-selector-node': 'MoreVertIcon',
                            'data-codecept-selector-file': 'UtilityToolbarGroup'
                        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'MoreVertIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _React$createElement2))
                    ),
                    anchorOrigin: { horizontal: 'right', vertical: 'top' },
                    targetOrigin: { horizontal: 'right', vertical: 'top' },
                    'data-codecept-selector-node': 'IconMenu',
                    'data-codecept-selector-file': 'UtilityToolbarGroup'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'UtilityToolbarGroup'), _React$createElement4),
                this.partitionedContextMenuItems(),
                additionalMenuItems
            )
        );
    }
});

exports.default = UtilityToolbarGroup;