'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ToolbarTitle = undefined;

var _ToolbarTitle2 = require('./ToolbarTitle');

var _ToolbarTitle3 = _interopRequireDefault(_ToolbarTitle2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.ToolbarTitle = _ToolbarTitle3.default;