"use strict";

var typesFromID = function typesFromID(id) {
    return {
        SHOW_LOADING_INDICATOR: "@@ruby-app/" + id + "/SHOW_LOADING_INDICATOR",
        HIDE_LOADING_INDICATOR: "@@ruby-app/" + id + "/HIDE_LOADING_INDICATOR"
    };
};

var generators = {
    show: function show(options) {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        return {
            type: TYPES.SHOW_LOADING_INDICATOR
        };
    },
    hide: function hide() {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES;

        return {
            type: TYPES.HIDE_LOADING_INDICATOR
        };
    }
};

module.exports = function () {
    var TYPES = typesFromID(this.getID());
    return {
        TYPES: TYPES,
        generators: generators
    };
};