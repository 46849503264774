'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var defaultChildrenPropsByKeyFromProps = function defaultChildrenPropsByKeyFromProps(props) {
    var mode = props.mode,
        disabled = props.disabled,
        locked = props.locked;


    return {
        open_graph_title: {
            label: 'Open Graph Title',
            componentName: "Text",
            key: 'open_graph_title',
            data_type: 'string',
            defaultValue: '',
            ruby_permissions: props.ruby_permissions,
            mode: mode,
            disabled: disabled,
            locked: locked
        },
        open_graph_description: {
            label: 'Open Graph Description',
            componentName: "Textarea",
            key: 'open_graph_description',
            data_type: 'string',
            defaultValue: '',
            ruby_permissions: props.ruby_permissions,
            mode: mode,
            disabled: disabled,
            locked: locked
        },
        open_graph_image: {
            label: 'Open Graph Image',
            componentName: "ImagePicker",
            key: 'open_graph_image',
            data_type: 'number',
            defaultValue: '',
            ruby_permissions: props.ruby_permission,
            constraints: {
                type: 'jpg,png,gif',
                max_width: '1200',
                max_height: '1200',
                rec_max_file_size: '1000KB'
            },
            mode: mode,
            disabled: disabled,
            locked: locked
        }
    };
};

var childrenFormFromProps = function childrenFormFromProps(_ref) {
    var childrenPropsByKey = _ref.childrenPropsByKey;
    var open_graph_title = childrenPropsByKey.open_graph_title,
        open_graph_description = childrenPropsByKey.open_graph_description,
        open_graph_image = childrenPropsByKey.open_graph_image;


    return [_extends({
        componentName: "Text"
    }, open_graph_title), _extends({
        componentName: "Textarea"
    }, open_graph_description), _extends({
        componentName: "ImagePicker"
    }, open_graph_image)];
};

module.exports = {
    defaultChildrenPropsByKeyFromProps: defaultChildrenPropsByKeyFromProps,
    childrenFormFromProps: childrenFormFromProps
};