'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//const rubyLogger = require('@rubyapps/ruby-logger');
//const logger = rubyLogger.getLogger('ruby-middleware-fields');

var _ = require('lodash');
var Validator = require('@rubyapps/ruby-validator-wrapper');
var CONSTANTS = {
    SET_FIELD_SUFFIX: 'SET_FIELD_VALUE_BY_KEY',
    SET_FIELD_ERROR_MESSAGE_SUFFIX: 'SET_FIELD_ERROR_MESSAGE_BY_KEY',
    SET_FIELD_ERROR_OBJECT_SUFFIX: 'SET_FIELD_ERROR_OBJECT_BY_KEY',
    MERGE_FIELD_ERROR_OBJECT_SUFFIX: 'MERGE_FIELD_ERROR_OBJECT_BY_KEY',
    VALIDATE_ALL_FIELDS_SUFFIX: 'VALIDATE_FIELDS',
    SET_ALL_FIELDS_SUFFIX: 'SET_ALL_FIELDS',
    RESET_STORE_SUFFIX: 'RESET_STORE',
    UPDATE_USER_MODIFIED_TIMESTAMP: 'UPDATE_USER_MODIFIED_TIMESTAMP'
};

/*
    //# this middleware expects an action of the following type:
    {
        type: '.../SET_FIELD_VALUE_BY_KEY'
        , payload: {
            key: ''
            , value: ''
        }
    }
    //# if an error is detected, it will insert an object into the payload:
    {
        payload: {
            error: {
                message:
            }
        }
    }
*/

/*
    constraints = {
        <constraint>: {
            params: [...] //# additional params that should be passed into the validator
            , message: ''
        }
    }
*/

var defaultConstraintParamsByKey = {
    required: [true]
};

var defaultConstraintMessage = {
    required: 'Field is required'
};

function _validateField_withConstraints(fieldLabel, fieldKey, fieldValue) {
    var constraints = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    var errorMessagesByKeyword = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

    var ruleKeys = Object.keys(constraints);

    var errorObjects = ruleKeys.reduce(function (collector, ruleKey) {
        // Check for validator function
        if (!Validator[ruleKey]) {
            //throw( "Invalid validator function: " + ruleKey );
            return collector;
        } else if (_.isNil(constraints[ruleKey])) {
            return collector;
        }

        var stateParams = !_.isNil(constraints[ruleKey]) ? constraints[ruleKey].params || constraints[ruleKey] : undefined;
        var defaultParams = !_.isNil(defaultConstraintParamsByKey[ruleKey]) ? defaultConstraintParamsByKey[ruleKey] : [];
        var constraintParams = [fieldValue].concat(!_.isNil(stateParams) ? stateParams : defaultParams);

        var isOK = Validator[ruleKey].apply(null, constraintParams);
        if (!isOK) {
            //# TODO: rely on validator to get the error message instead
            var errorMessage = _.get(errorMessagesByKeyword, ruleKey) || Validator.getErrorMessage({
                label: fieldLabel,
                verify: constraints
            }, ruleKey);
            collector.push({
                message: errorMessage
            });
        }
        return collector;
    }, []);

    return errorObjects;
}

function _validateFieldsInStateByKeys(state, keys) {
    var _this = this;

    var formValue = this.formValueFromLocalState(state);
    var fields = state.fields ? state.fields : {};

    return _.reduce(keys, function (collector, fieldKey) {
        var fieldObj = fields[fieldKey];

        //# NOTE: probably doesn't need to call on selfModule.getConstraints_fromState
        //# since this isn't called by the field components
        var errors = _validateField_withConstraints(fieldObj.label, fieldKey, formValue[fieldKey] || fieldObj.value //# prefer the formValue
        , fieldObj.constraints, _this.props.errorMessagesByKeyword);

        collector[fieldKey] = _extends({}, fieldObj, {
            errors: errors //# include all errors in case we need it in the future
            , error: errors[0]
        });
        return collector;
    }, {});
}

function _validateAllFieldsInStateByKey(state) {
    var fields = state.fields ? state.fields : {};
    var fieldKeys = Object.keys(fields);

    return _validateFieldsInStateByKeys.call(this, state, fieldKeys);
}

//# 20160908 DEPRECATED. Don't want to use middleware because
//# fields gets loaded in dynamically
var fieldMiddleware = function fieldMiddleware(store) {
    var _this2 = this;

    var selfModule = this;
    var localStateSelector = selfModule.getDefaultSelector();
    var selfAction = selfModule.getAction();
    return function (next) {
        return function (action) {
            //# check if we need to validate
            if (action.type === selfAction.TYPES.SET_FIELD_VALUE_BY_KEY && action.payload && action.payload.ignoreValidation != true) {
                var localState = localStateSelector(store.getState());
                var fields = localState.fields;
                var fieldKey = action.payload.key;
                var fieldValue = action.payload.value;
                var fieldSpec = fields[fieldKey];
                var fieldLabel = fieldSpec ? fieldSpec.label : '';

                var errors = _validateField_withConstraints(fieldLabel, fieldKey, fieldValue, fieldSpec ? fieldSpec.constraints : {}, selfModule.props.errorMessagesByKeyword);
                var newActionPayload = _extends({}, action.payload, {
                    errors: errors,
                    error: errors[0]
                });
                var result = next(_extends({}, action, {
                    payload: newActionPayload
                }));

                return result;
            } else if (action.type === selfAction.TYPES.VALIDATE_ALL_FIELDS) {
                //# validate and set all fields
                var _localState = localStateSelector(store.getState());
                var validatedFieldsObject = _validateAllFieldsInStateByKey.call(_this2, _localState);
                var _result = store.dispatch({
                    type: selfAction.TYPES.SET_ALL_FIELDS,
                    payload: { fields: validatedFieldsObject }
                });
                return _result;
            } else {
                return next(action);
            }
        };
    };
};

function _reduceFieldState_withAction() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];
    var payload = action.payload;


    return _extends({}, state, payload.hasOwnProperty('value') ? { value: action.payload.value, userModifiedTimestamp: new Date() } : {}, payload.hasOwnProperty('error') ? {
        error: _extends({}, state.error, action.payload.error)
    } : {}, payload.hasOwnProperty('userModifiedTimestamp') ? {
        userModifiedTimestamp: action.payload.userModifiedTimestamp
    } : {});
}

function _reduceFieldState_withErrorObjectAction(state, action) {
    var payload = action.payload;


    return _extends({}, state, payload.hasOwnProperty('error') ? {
        error: action.payload.error
    } : {});
}
//# assume state is fields
//# returns
//  {
//      fields: {
//          [key]: {
//              label: 'Field Label'
//              value:
//              error: {
//                  message: <string>
//              }
//              constraints: {
//                  [constraint key]: <param>
//              }
//          }
//      }
//  }
function _reducer(state, action) {
    var selfModule = this;

    var _selfModule$getAction = selfModule.getAction(),
        TYPES = _selfModule$getAction.TYPES;

    var payload__key = _.get(action, 'payload.key');
    var payload__error = _.get(action, 'payload.error', {});

    switch (action.type) {
        case TYPES.UPDATE_USER_MODIFIED_TIMESTAMP:
        case TYPES.SET_FIELD_VALUE_BY_KEY:
            var updatedFields = _extends({}, state.fields, _defineProperty({}, payload__key, _reduceFieldState_withAction(state.fields[payload__key], action)));

            return _extends({}, state, {
                fields: updatedFields
            });

        case TYPES.SET_FIELD_ERROR_OBJECT_BY_KEY:
            if (payload__key) {
                //# NOTE: if we don't have a payload__key, we set the error in the root-level
                //# of the field state
                //# this fallback is really used for field-set like fields that have keys
                //# like repeaters
                var updatedFields_withReplacedError = _extends({}, state.fields, _defineProperty({}, payload__key, _reduceFieldState_withAction(state.fields[payload__key], action)));

                return _extends({}, state, {
                    fields: updatedFields_withReplacedError
                });
            } else {
                return _extends({}, state, { error: payload_error });
            }

        case TYPES.SET_FIELD_ERROR_MESSAGE_BY_KEY:
        case TYPES.MERGE_FIELD_ERROR_OBJECT_BY_KEY:
            if (payload__key) {
                var updatedFields_withMergedError = _extends({}, state.fields, _defineProperty({}, payload__key, _reduceFieldState_withAction(state.fields[payload__key], action)));

                return _extends({}, state, {
                    fields: updatedFields_withMergedError
                });
            } else {
                var mergedErrorObject = _extends({}, state.error, payload__error);
                return _extends({}, state, { error: mergedErrorObject });
            }

        case TYPES.SET_ALL_FIELDS:
            var payloadFields = action.payload.fields;
            var allUpdatedFields = _.reduce(payloadFields, function (collector, fieldObj, fieldKey) {
                var payload__fieldObj = fieldObj;
                var state__fieldObj = state.fields[fieldKey] || {};

                collector[fieldKey] = _extends({}, state__fieldObj, payload__fieldObj);
                return collector;
            }, _extends({}, state.fields)); //# make sure we start with a shallow copy

            return _extends({}, state, {
                fields: allUpdatedFields
            });
        case TYPES.RESET_STORE:
            //# NOTE: all reducers gets called so 
            //# we need to make sure we set the initial state correct
            return _extends({}, state, selfModule.getInitialState(state));
        default:
            return state;
    }
}

function getAction_usingTypePrefix(typePrefix) {
    var TYPES = {
        SET_FIELD_VALUE_BY_KEY: typePrefix + CONSTANTS.SET_FIELD_SUFFIX,
        SET_FIELD_ERROR_MESSAGE_BY_KEY: typePrefix + CONSTANTS.SET_FIELD_ERROR_MESSAGE_SUFFIX,
        SET_FIELD_ERROR_OBJECT_BY_KEY: typePrefix + CONSTANTS.SET_FIELD_ERROR_OBJECT_SUFFIX,
        MERGE_FIELD_ERROR_OBJECT_BY_KEY: typePrefix + CONSTANTS.MERGE_FIELD_ERROR_OBJECT_SUFFIX,
        VALIDATE_ALL_FIELDS: typePrefix + CONSTANTS.VALIDATE_ALL_FIELDS_SUFFIX,
        SET_ALL_FIELDS: typePrefix + CONSTANTS.SET_ALL_FIELDS_SUFFIX,
        RESET_STORE: typePrefix + CONSTANTS.RESET_STORE_SUFFIX,
        UPDATE_USER_MODIFIED_TIMESTAMP: typePrefix + CONSTANTS.UPDATE_USER_MODIFIED_TIMESTAMP
    };

    return {
        TYPES: TYPES,
        generators: {
            resetStore: function resetStore() {
                return {
                    type: TYPES.RESET_STORE
                };
            }
            /**
             * should only be used by components that does not user setFieldValueByKey to update state (eg. Repeater)
             */
            , updateUserModifiedTimestamp: function updateUserModifiedTimestamp(key) {
                key = key ? key : this.key ? this.key() : this.props.key;

                return {
                    type: TYPES.UPDATE_USER_MODIFIED_TIMESTAMP,
                    payload: {
                        key: key,
                        userModifiedTimestamp: new Date()
                    }
                };
            },
            validateFields: function validateFields(fieldKeys) {
                var localState = this.getState();
                var validatedFieldsObject = _validateFieldsInStateByKeys.call(this, localState, fieldKeys);

                return {
                    type: TYPES.SET_ALL_FIELDS,
                    payload: { fields: validatedFieldsObject }
                };
            },
            validateAllFields: function validateAllFields() {
                var localState = this.getState();
                var validatedFieldsObject = _validateAllFieldsInStateByKey.call(this, localState);

                return {
                    type: TYPES.SET_ALL_FIELDS,
                    payload: { fields: validatedFieldsObject }
                };

                return {
                    type: TYPES.VALIDATE_ALL_FIELDS
                };
            },
            setAllFieldsWithObj: function setAllFieldsWithObj(obj) {
                return {
                    type: TYPES.SET_ALL_FIELDS,
                    payload: {
                        fields: obj
                    }
                };
            },
            setFieldValue: function setFieldValue(value) {
                var key = this.key ? this.key() : this.props.key;
                var actions = this.getAction().generators;
                return actions.setFieldValueByKey(value, key);
            },
            setFieldValueByKey: function setFieldValueByKey(value, key, ignoreValidation) {
                var selfModule = this;

                var localState = selfModule.getState();
                var prevErrorMessage = _.get(localState, ['fields', key, 'error', 'message']);

                var fields = localState.fields;
                var fieldKey = key;
                var fieldValue = value;
                var fieldSpec = fields[fieldKey];
                var fieldLabel = fieldSpec ? fieldSpec.label : '';
                var fieldConstraints = this.getConstraints_forKey_fromState(fieldKey);

                var action = {
                    type: TYPES.SET_FIELD_VALUE_BY_KEY,
                    payload: {
                        value: fieldValue,
                        key: key,
                        ignoreValidation: ignoreValidation
                    }
                };
                if (!ignoreValidation) {
                    var errors = _validateField_withConstraints(fieldLabel, fieldKey, fieldValue, fieldConstraints, selfModule.props.errorMessagesByKeyword);
                    var newActionPayload = _extends({}, action.payload, {
                        errors: errors,
                        error: errors[0] ? errors[0] : {
                            message: null //# explicitly say message is null
                        }
                    });
                    var newErrorMessage = _.get(errors[0], 'message', errors[0]);
                    var newAction = _extends({}, action, {
                        payload: newActionPayload
                    });

                    return function (dispatch, getState) {
                        dispatch(newAction);
                        if (newErrorMessage != prevErrorMessage) {
                            selfModule.refreshParentErrors && selfModule.refreshParentErrors();
                        }
                    };
                }

                return action;
            },
            setFieldErrorMessageByKey: function setFieldErrorMessageByKey(message, key) {

                return {
                    type: TYPES.SET_FIELD_ERROR_MESSAGE_BY_KEY,
                    payload: {
                        error: {
                            message: _.isString(message) ? {
                                msg: message,
                                level: 'error'
                            } : message
                        },
                        key: key
                    }
                };
            },
            setFieldErrorObjectByKey: function setFieldErrorObjectByKey(error, key) {

                return {
                    type: TYPES.SET_FIELD_ERROR_OBJECT_BY_KEY,
                    payload: {
                        error: error,
                        key: key
                    }
                };
            },
            mergeFieldErrorObjectByKey: function mergeFieldErrorObjectByKey(error, key) {
                return {
                    type: TYPES.MERGE_FIELD_ERROR_OBJECT_BY_KEY,
                    payload: {
                        error: error,
                        key: key
                    }
                };
            },
            clearErrorsWithKeys_inState: function clearErrorsWithKeys_inState() {
                var errorKeys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
                var inState = arguments[1];

                var _getAction = this.getAction(),
                    TYPES = _getAction.TYPES,
                    generators = _getAction.generators;

                var selfKey = this.props.key;
                var selfState = inState;
                var error = _.get(selfState, ['fields', selfKey, 'error']) || {};

                var preppedError = _.reduce(errorKeys, function (collector, errorKey, errorKeyIndex) {
                    if (error.hasOwnProperty(errorKey)) {
                        collector[errorKey] = null;
                    }
                    return collector;
                }, {});

                return generators.mergeFieldErrorObjectByKey(preppedError, selfKey);
            }
        }
    };
}
module.exports = {
    CONSTANTS: CONSTANTS
    //, middleware: fieldMiddleware
    , reducer: _reducer,
    action: function action() {
        var id = this.getID();
        return getAction_usingTypePrefix('@@ruby-app/' + id + '/');
    },
    getAction_usingTypePrefix: getAction_usingTypePrefix,
    selector: {
        getFieldsThatErroredByKey: function getFieldsThatErroredByKey(localState) {
            var fields = localState.fields;
            var errorsByKey = _.reduce(fields, function (collector, fieldObj, fieldKey) {
                if (!_.isNil(fieldObj.error)) {
                    collector[fieldKey] = fieldObj;
                }
                return collector;
            }, {});

            return errorsByKey;
        }
    },
    validateField_withConstraints: _validateField_withConstraints
};