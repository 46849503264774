'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var styles = process.browser ? require('./styles.cssModule').default : {};

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label;

var ExpandedData = _react2.default.createClass({
    displayName: 'ExpandedData',

    render: function render() {
        var _this = this,
            _React$createElement2;

        var labelProps = _.pick(this.props, Object.keys(Label.propTypes));

        return _react2.default.createElement(
            'div',
            (_React$createElement2 = { className: styles.wrapper, style: this.props.style, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'ExpandedData'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ExpandedData'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ExpandedData'), _React$createElement2),
            function () {
                if (_this.props.label) {
                    var _extends2, _React$createElement;

                    return _react2.default.createElement(
                        'label',
                        (_React$createElement = { className: styles.label, 'data-codecept-selector-node': 'label',
                            'data-codecept-selector-file': 'ExpandedData'
                        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ExpandedData'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ExpandedData'), _React$createElement),
                        _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                            'data-codecept-selector-node': 'Label',
                            'data-codecept-selector-file': 'ExpandedData'
                        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ExpandedData'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'ExpandedData'), _extends2)))
                    );
                }
            }(),
            this.props.children
        );
    }
});

exports.default = ExpandedData;