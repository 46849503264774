'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    bodyStyle: {
        padding: '24px'
    }
};


var DialogHeader = {
    iconElementRight: {
        color: '#ffffff',
        display: 'flex',
        'align-items': 'center',
        marginTop: '3px'
    }
};

exports.DialogHeader = DialogHeader;