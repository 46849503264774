"use strict";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var defaultState = {
    referencedDisplayValues: null
};

module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];

    var _getAction = this.getAction(),
        TYPES = _getAction.TYPES;

    var type = action.type,
        payload = action.payload;


    switch (type) {
        case TYPES.UPDATE_REFERENCED_DISPLAY_VALUES:
            return _extends({}, state, payload || {});
        default:
            return state;
    }
};