'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var GRIDDLE_START_LOADING = exports.GRIDDLE_START_LOADING = 'GRIDDLE_START_LOADING';
var GRIDDLE_STOP_LOADING = exports.GRIDDLE_STOP_LOADING = 'GRIDDLE_STOP_LOADING';
var GRIDDLE_REMOTE_REPLACE_DATA = exports.GRIDDLE_REMOTE_REPLACE_DATA = 'GRIDDLE_REMOTE_REPLACE_DATA';
var GRIDDLE_REMOTE_APPEND_DATA = exports.GRIDDLE_REMOTE_APPEND_DATA = 'GRIDDLE_REMOTE_APPEND_DATA';
var GRIDDLE_REMOTE_PREPEND_DATA = exports.GRIDDLE_REMOTE_PREPEND_DATA = 'GRIDDLE_REMOTE_PREPEND_DATA';
var GRIDDLE_REMOTE_ERROR = exports.GRIDDLE_REMOTE_ERROR = 'GRIDDLE_REMOTE_ERROR';
var GRIDDLE_LOADED_DATA = exports.GRIDDLE_LOADED_DATA = 'GRIDDLE_LOADED_DATA';