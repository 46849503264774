"use strict";

var rubyWords = require('@rubyapps/ruby-words');

var URL_IDENTIFIER_DESCRIPTION = "<p>Unique identifying final piece of the path to the page</p>" + "<p>(url identifiers must only contain a-z, 0-9, and hyphens '-')</p>" + "<p>(url identifiers must be unique across all resources of the same type)</p>";

var REDIRECT_FROM_URL_DESCRIPTION = "<p>URL paths of the past URLs for this content</p>" + "<p>If paths are provided, users (and search engine crawlers) will be redirected from the old paths to this page and SEO information is preserved.</p>";

var META_KEYWORDS_DESCRIPTION = "Keywords describing this content for search engines";

var META_DESCRIPTION_DESCRIPTION = "Description of the page for search engines";

var CONSTANTS = {
    COMPONENT_NAME: 'rubyComponentFieldGooglePreview',
    BASIS_KEY_FOR_URL_IDENTIFIER: 'name', // TODO: inflexible
    VALUE_MAP_FN_FOR_URL_IDENTIFIER: rubyWords.slug,
    URL_IDENTIFIER_DESCRIPTION: URL_IDENTIFIER_DESCRIPTION,
    REDIRECT_FROM_URL_DESCRIPTION: REDIRECT_FROM_URL_DESCRIPTION,
    META_KEYWORDS_DESCRIPTION: META_KEYWORDS_DESCRIPTION,
    META_DESCRIPTION_DESCRIPTION: META_DESCRIPTION_DESCRIPTION,

    GET_DEFAULT_PROPS: function GET_DEFAULT_PROPS(props) {
        var mode = props.mode,
            locked = props.locked,
            disabled = props.disabled;


        return {
            previewUrl: {
                method: ['getStateAtKeypath', 'pristineData.url'],
                path: "#__url_poller"
            },
            keyForNameComponent: 'name',
            childrenPropsByKey: {
                browser_page_title: {
                    label: "Browser Page Title",
                    key: 'browser_page_title',
                    data_type: "string"
                    //, default: ""
                    , ruby_permissions: props.ruby_permissions,
                    componentName: 'AutoPopulatedText' //# NOTE: for now, this doesn't do anything
                    //# since we call on RubyComponent.createElement directly
                    , mode: mode,
                    suggestedMode: true,
                    locked: locked,
                    disabled: disabled
                },
                url_identifier: {
                    label: "URL Identifier",
                    key: 'url_identifier',
                    data_type: "string",
                    ruby_permissions: props.ruby_permissions,
                    keyForReferenceComponent: CONSTANTS.BASIS_KEY_FOR_URL_IDENTIFIER,
                    referenceComponentValueMap: CONSTANTS.VALUE_MAP_FN_FOR_URL_IDENTIFIER,
                    "verify": {
                        "required": true,
                        "max": 200 // also needs to be updated in ruby-component-field-google-preview/src/client/index.js
                    },
                    "namespace": "en",
                    componentName: 'AutoPopulatedText',
                    mode: mode,
                    locked: locked,
                    disabled: disabled
                },
                redirect_from_url: {
                    label: "Redirect From Path",
                    key: 'redirect_from_url',
                    data_type: "string"
                    //, default: ""
                    , verify: { pattern: "^$|^\\/" },
                    ruby_permissions: props.ruby_permissions,
                    "namespace": "en",
                    description: REDIRECT_FROM_URL_DESCRIPTION,
                    componentName: 'Textarea',
                    mode: mode,
                    locked: locked,
                    disabled: disabled
                },
                meta_description: {
                    label: "Meta Description",
                    key: 'meta_description',
                    data_type: "string"
                    //, default: ""
                    , ruby_permissions: props.ruby_permissions,
                    description: META_DESCRIPTION_DESCRIPTION,
                    componentName: 'Textarea',
                    mode: mode,
                    locked: locked,
                    disabled: disabled,
                    suggestedValue: {
                        path: '< .rubyComponentFieldForm > [props.key=main_content]',
                        method: ['getTextSummary', { sentenceCount: 3 }]
                    }
                },
                meta_keywords: {
                    label: "Meta Keywords",
                    key: 'meta_keywords',
                    data_type: "string"
                    //, default: ""
                    , ruby_permissions: props.ruby_permissions,
                    "verify": {
                        "max": 5000
                    },
                    description: META_KEYWORDS_DESCRIPTION,
                    componentName: 'Text',
                    mode: mode,
                    locked: locked,
                    disabled: disabled
                }
            }
        };
    }
};

module.exports = CONSTANTS;