'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _materialUi = require('material-ui');

var _DropDownMenu = require('@rubyapps/ruby-material-ui/DropDownMenu');

var _DropDownMenu2 = _interopRequireDefault(_DropDownMenu);

var _Toggle = require('@rubyapps/ruby-material-ui/Toggle');

var _Toggle2 = _interopRequireDefault(_Toggle);

var _colors = require('@rubyapps/ruby-styles/src/client/styleObjects/colors');

var _reactChartjs = require('react-chartjs-2');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;
var moment = require('@rubyapps/friendly-ruby-moment');

//# Note: the metric values are hardcoded in the metrics 
//# array in the get_filtered_top_pages.js scripts
var metricMap = {
    'screenPageViews': ['ga:uniquePageviews', 'screenPageViews'],
    'userEngagementDuration': ['ga:timeOnPage', 'userEngagementDuration']
};

var WeeklyStats = _react2.default.createClass({
    displayName: 'WeeklyStats',

    propTypes: {
        label: PropTypes.string,
        id: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.shape({
            filterSpecsHydratedWithTopPages: PropTypes.array,
            pageHistoryData: PropTypes.array
        })),
        filterSpecs: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string
        }))
    },
    getInitialState: function getInitialState() {
        return {
            selectedMonthRange: 3,
            selectedMetric: 'screenPageViews',
            selectedContentType: this.props.filterSpecs[0].label,
            selectedToggles: [0, 1, 2, 3, 4]
        };
    },
    addToggle_idx: function addToggle_idx(idx) {
        this.setState({
            selectedToggles: [].concat(this.state.selectedToggles, idx)
        });
    },
    removeToggle_idx: function removeToggle_idx(idx) {
        this.setState({
            selectedToggles: this.state.selectedToggles.filter(function (el) {
                return el != idx;
            })
        });
    },
    _renderDropDowns: function _renderDropDowns() {
        var _React$createElement2;

        var self = this;
        var dropdowns = [{
            value: this.state.selectedContentType,
            key: 'selectedContentType',
            options: self.props.filterSpecs.map(function (spec) {
                return {
                    primaryText: 'Top 15 ' + spec.label,
                    value: spec.label
                };
            })
        }, {
            value: this.state.selectedMetric,
            key: 'selectedMetric',
            options: [{
                primaryText: 'Number of Unique Visitors',
                value: 'screenPageViews'
            }, {
                primaryText: 'Time on Page',
                value: 'userEngagementDuration'
            }]
        }, {
            value: this.state.selectedMonthRange,
            key: 'selectedMonthRange',
            options: [{
                primaryText: 'Last 3 Months',
                value: 3
            }, {
                primaryText: 'Last 6 Months',
                value: 6
            }, {
                primaryText: 'Last year',
                value: 12
            }]
        }];

        return _react2.default.createElement(
            'div',
            (_React$createElement2 = { style: { display: 'flex', flexWrap: 'wrap' }, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'FilteredTopPagesStats'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _React$createElement2),
            dropdowns.map(function (_ref) {
                var _React$createElement;

                var value = _ref.value,
                    dropDownKey = _ref.key,
                    options = _ref.options;
                return _react2.default.createElement(
                    _DropDownMenu2.default,
                    (_React$createElement = {
                        value: value,
                        style: { flexGrow: 1 },
                        onChange: function onChange(event, menuItemIdx, menuItemValue) {
                            self.setState(_defineProperty({}, dropDownKey, menuItemValue));
                        },
                        'data-codecept-selector-node': 'DropDownMenu',
                        'data-codecept-selector-file': 'FilteredTopPagesStats'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'DropDownMenu'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'DropDownMenu'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _React$createElement),
                    options.map(function (option) {
                        var _extends2;

                        return _react2.default.createElement(_materialUi.MenuItem, _extends({}, option, (_extends2 = {
                            'data-codecept-selector-node': 'MenuItem',
                            'data-codecept-selector-file': 'FilteredTopPagesStats'
                        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _defineProperty(_extends2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _extends2)));
                    })
                );
            })
        );
    },
    render: function render() {
        var _this = this,
            _React$createElement4,
            _React$createElement5,
            _React$createElement6,
            _React$createElement7,
            _React$createElement8,
            _React$createElement9;

        var self = this;

        var filterSpec = _.find(_.get(this, 'props.data.filterSpecsHydratedWithTopPages'), function (spec) {
            return metricMap[_this.state.selectedMetric].includes(spec.metric) && spec.label === _this.state.selectedContentType && spec.dateRange.count === _this.state.selectedMonthRange;
        });
        if (!filterSpec) {
            return null;
        }
        var scatterData = {
            datasets: (this.state.selectedToggles || []).map(function (dataIdx, colorIdx) {
                var _ref2 = filterSpec.topPages[dataIdx] || {},
                    path = _ref2.path;

                var pageData = _.find(_this.props.data.pageHistoryData, { path: path }) || {};
                var pageHistory = pageData.history || [];
                var data = processData_forRecharts({ data: pageHistory, metric: _this.state.selectedMetric }).reverse();
                return _extends({}, { data: data }, {
                    borderColor: _colors.categoryColors[colorIdx],
                    pointBackgroundColor: _colors.categoryColors[colorIdx],
                    key: colorIdx,
                    showLine: true,
                    fill: false,
                    lineTension: 0,
                    dataIdx: dataIdx
                });
            })
        };

        var allDataPoints = scatterData.datasets.reduce(function (result, dataset) {
            var points = dataset.data;
            return result.concat(points);
        }, []);
        var shownTopPages = (this.state.selectedToggles || []).reduce(function (result, dataIdx) {
            var pageData = _.get(filterSpec, ['topPages', dataIdx]);
            result.push(pageData);
            return result;
        }, []);
        var startDate = moment().subtract(this.state.selectedMonthRange, 'months');
        var visibleDataPoints = allDataPoints.filter(function (point) {
            var _point$meta = point.meta,
                week = _point$meta.week,
                year = _point$meta.year;

            if (!week || !year) {
                return false;
            }
            var pointDate = moment().year(year).week(week).startOf('week');
            return pointDate > startDate;
        });
        var maxY = _.max(visibleDataPoints.map(function (point) {
            return point.y;
        }));
        var digits = Math.round(maxY).toString().length;
        var roundBy = Math.pow(10, digits - 1);
        var upRoundedMaxY = Math.ceil(maxY / roundBy) * roundBy;
        return _react2.default.createElement(
            _materialUi.Card,
            (_React$createElement9 = {
                'data-codecept-selector-node': 'Card',
                'data-codecept-selector-file': 'FilteredTopPagesStats'
            }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _React$createElement9),
            _react2.default.createElement(
                _materialUi.CardText,
                (_React$createElement8 = {
                    'data-codecept-selector-node': 'CardText',
                    'data-codecept-selector-file': 'FilteredTopPagesStats'
                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _React$createElement8),
                self._renderDropDowns(),
                _react2.default.createElement(
                    'div',
                    (_React$createElement7 = {
                        style: {
                            display: 'flex',
                            flexWrap: 'wrap'
                        },
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'FilteredTopPagesStats'
                    }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _React$createElement7),
                    _react2.default.createElement(
                        'div',
                        (_React$createElement4 = {
                            style: {
                                padding: 20,
                                flexGrow: 1,
                                width: '40%',
                                maxWidth: '100%',
                                minWidth: 250,
                                boxSizing: 'border-box',
                                height: 400
                            },
                            'data-codecept-selector-node': 'div',
                            'data-codecept-selector-file': 'FilteredTopPagesStats'
                        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _React$createElement4),
                        filterSpec.topPages.map(function (_ref3, idx) {
                            var _React$createElement3;

                            var path = _ref3.path,
                                title = _ref3.title;
                            return _react2.default.createElement(_Toggle2.default, (_React$createElement3 = {
                                disabled: _this.state.selectedToggles.indexOf(idx) === -1 && _this.state.selectedToggles.length >= _colors.categoryColors.length,
                                label: title,
                                labelPosition: 'right',
                                style: { width: 'auto', display: 'block' },
                                labelStyle: {
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                },
                                toggled: _this.state.selectedToggles.indexOf(idx) > -1,
                                thumbSwitchedStyle: {
                                    backgroundColor: _colors.categoryColors[_this.state.selectedToggles.indexOf(idx)]
                                },
                                trackSwitchedStyle: {
                                    backgroundColor: _colors.categoryColors[_this.state.selectedToggles.indexOf(idx)]
                                },
                                onToggle: function onToggle(event, toggled) {
                                    toggled ? self.addToggle_idx(idx) : self.removeToggle_idx(idx);
                                },
                                'data-codecept-selector-node': 'Toggle',
                                'data-codecept-selector-file': 'FilteredTopPagesStats'
                            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _React$createElement3));
                        })
                    ),
                    _react2.default.createElement(
                        'div',
                        (_React$createElement6 = {
                            style: {
                                flexGrow: 2,
                                width: '50%',
                                padding: 20
                            },
                            'data-codecept-selector-node': 'div',
                            'data-codecept-selector-file': 'FilteredTopPagesStats'
                        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _React$createElement6),
                        _react2.default.createElement(_reactChartjs.Scatter, (_React$createElement5 = {
                            datasetKeyProvider: function datasetKeyProvider(dataset) {
                                return dataset.key;
                            },
                            data: scatterData,
                            options: {
                                legend: {
                                    display: false
                                },
                                tooltips: {
                                    callbacks: {
                                        title: function title(tooltipItems, data) {
                                            var tooltipItem = tooltipItems[0];
                                            var dataset = _.get(data, ['datasets', _.get(tooltipItem, 'datasetIndex')]);
                                            var dataPoint = _.get(dataset, ['data', tooltipItem.index]);
                                            var weekNumber = dataPoint.meta.week;
                                            var year = dataPoint.meta.year;

                                            var weekStart = moment().year(year).week(weekNumber).startOf('week').format('MMM D');
                                            var weekEnd = moment().year(year).week(weekNumber).endOf('week').format('MMM D');
                                            return weekStart + ' - ' + weekEnd;
                                        },
                                        afterTitle: function afterTitle(tooltipItems, data) {
                                            var tooltipItem = tooltipItems[0];
                                            return _.get(shownTopPages, [tooltipItem.datasetIndex, 'title']);
                                        },
                                        label: function label(tooltipItem, data) {
                                            return self.state.selectedMetric === 'userEngagementDuration' ? moment.duration(tooltipItem.yLabel, 'seconds').friendlyDuration() : tooltipItem.yLabel.toLocaleString();
                                        }
                                    }
                                },
                                maintainAspectRatio: false,
                                scales: {
                                    xAxes: [{
                                        type: 'time',
                                        time: {
                                            unit: 'week',
                                            displayFormats: {
                                                week: 'MMM D'
                                            },
                                            min: moment().subtract(this.state.selectedMonthRange, 'months')
                                        }
                                    }],
                                    yAxes: [{
                                        ticks: {
                                            min: 0,
                                            max: upRoundedMaxY,
                                            callback: function callback(value, index, values) {
                                                return self.state.selectedMetric === 'userEngagementDuration' ? moment.duration(value, 'seconds').friendlyDuration() : value.toLocaleString();
                                            }
                                        }
                                    }]
                                }
                            },
                            'data-codecept-selector-node': 'Scatter',
                            'data-codecept-selector-file': 'FilteredTopPagesStats'
                        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Scatter'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Scatter'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'FilteredTopPagesStats'), _React$createElement5))
                    )
                )
            )
        );
    }
});

function processData_forRecharts(_ref4) {
    var _ref4$data = _ref4.data,
        data = _ref4$data === undefined ? [] : _ref4$data,
        metric = _ref4.metric;

    if (data.length === 0) {
        return [];
    }
    return data.map(function (dataPoint) {
        var dataPointYear = _.get(dataPoint, 'year');
        var dataPointWeek = _.get(dataPoint, 'week');
        return {
            x: moment().year(dataPointYear).week(dataPointWeek).startOf('week'),
            y: _.get(dataPoint, metricMap[metric][0]) || _.get(dataPoint, metricMap[metric][1]) || 0,
            meta: dataPoint
        };
    }).reduce(function (dataAcc, dataPoint, idx) {
        if (idx === 0) {
            return [dataPoint];
        }

        var prevDataPoint = _.last(dataAcc);
        var prevDate = _.get(prevDataPoint, 'x');
        var currDate = _.get(dataPoint, 'x');

        var dataAccClone = dataAcc.slice(0);
        if (prevDate.isSame(currDate, 'day')) {
            dataAccClone.pop();
            dataAccClone.push({
                x: currDate,
                y: _.get(prevDataPoint, 'y') + _.get(dataPoint, 'y'),
                meta: dataPoint
            });
        } else {
            dataAccClone.push(dataPoint);
        }

        return dataAccClone;
    }, []);
}

exports.default = WeeklyStats;