'use strict';

module.exports = {
    COMPONENT_NAME: 'rubyComponentTest',
    ROUTE_ACTION_TYPES: {
        ACTION_TYPE_PREFIX: '@@ruby-app',
        SET_ROUTE_PARAMS_SUFFIX: 'SET_ROUTE_PARAMS',
        SET_HASH_SUFFIX: 'SET_HASH',
        LEAVING_ROUTE_SUFFIX: 'LEAVING_ROUTE'
    }
};