'use strict';

var request = require('@rubyapps/ruby-superagent');

var rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');

function typesWithID(id) {
    return {};
}

var generators = {
    getSecret: function getSecret() {
        var _this = this;

        var _getAction = this.getAction(),
            generators = _getAction.generators;

        return function (dispatch, getState) {
            var endpoint = _this.determineSecretEndpoint();

            request.get(endpoint).then(function (res) {
                var secret = res.body;
                dispatch(generators.setSecret(secret));
            }, function (err) {
                // TODO: show error modal
            });
        };
    },
    generateSecret: function generateSecret() {
        var _this2 = this;

        var self = this;

        var _getAction2 = this.getAction(),
            generators = _getAction2.generators;

        return function (dispatch, getState) {
            var endpoint = _this2.determineSecretEndpoint();

            request.post(endpoint).then(function (res) {
                var secret = res.body;
                dispatch(generators.setSecret(secret));
            }, function (err) {
                var notificationMethod = self.showNotificationModal.bind(self);
                var type = rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR;
                var title = 'API Secret Generation Error';
                var message = 'An unexpected error was encountered while generating a secret. ' + 'Please try again later or contact Support.';

                if (err.status === 403) {
                    message = 'You do not have the permission to generate a secret.';
                    notificationMethod = self.pushNotification.bind(self);
                }

                notificationMethod({
                    title: title,
                    message: message,
                    type: type
                });
            });
        };
    },
    removeSecret: function removeSecret() {
        var _this3 = this;

        var self = this;

        var _getAction3 = this.getAction(),
            generators = _getAction3.generators;

        return function (dispatch, getState) {
            var endpoint = _this3.determineSecretEndpoint();

            request.del(endpoint).then(function (res) {
                dispatch(generators.setSecret(null));
            }, function (err) {
                var notificationMethod = self.showNotificationModal.bind(self);
                var type = rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR;
                var title = 'API Secret Invalidation Error';
                var message = 'An unexpected error was encountered while invalidating the secret. ' + 'Please try again later or contact Support.';

                if (true || err.status === 403) {
                    message = 'You do not have the permission to invalidate the secret.';
                    notificationMethod = self.pushNotification.bind(self);
                }

                notificationMethod({
                    title: title,
                    message: message,
                    type: type
                });
            });
        };
    },
    setSecret: function setSecret(value) {
        var _getAction4 = this.getAction(),
            generators = _getAction4.generators;

        var fieldKey = this.props.key;


        return function (dispatch, getState) {
            dispatch(generators.setFieldValueByKey(value, fieldKey));
        };
    }
};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};