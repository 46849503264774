'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* jshint -W138 */
var actionTypes = require('./action').TYPES;

//# NOTE: should not need to have a default state

module.exports = function (state, action) {
    switch (action.type) {
        case actionTypes.SET_FRONTEND_SETTINGS:
            return _extends({}, state, action.payload['frontend-settings']);
        case actionTypes.SET_FRONTEND_SETTING:
            return _extends({}, state, _defineProperty({}, action.payload.key, action.payload.value));
        default:
            return state;
    }
};