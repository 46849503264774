'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = getWebsitesMenuItemsForSelfModule_bySection;
//# TODO: see if we can pass the icon component instead of the string
var _ = require('lodash');
var RubyWords = require('@rubyapps/ruby-words');
var pluginWebsitesComponentId = 'rubyComponentPluginWebsites';
var WEBSITES_PLUGIN__CONSTANTS = require('@rubyapps/ruby-component-plugin-websites/src/common/constants');

function getWebsitesMenuItemsForSelfModule_bySection(selfModule) {
    var pluginWebsitesComponent = selfModule.getRoot().findDescendentByID(pluginWebsitesComponentId);

    var websiteForm = selfModule.getForms()[WEBSITES_PLUGIN__CONSTANTS.TEMPLATE_KEYWORD];

    var formFirstSubsite = _.first(_.castArray(_.get(websiteForm, 'ruby_subsite_fk')));

    //# return old menu items
    if (!(websiteForm && pluginWebsitesComponent)) {
        return {
            web_content: [{
                name: 'Dashboard',
                icon: 'material-ui/svgIcons/ActionDashboard',
                route: '/app/dashboard'
            }, {
                name: 'Sitemap',
                icon: 'ruby/icons/content/sitemap',
                permissions: [{ keyword: 'content_sitemap_section', ruby_client: 3, action: 'edit' }],
                route: '/app/content/en/sitemap'
            }]
        };
    }

    var mainEntryId = _.result(pluginWebsitesComponent, 'getState.frontendSpacesState.config.mainEntryId');

    var permissionSpec = {
        model: 'content',
        template: websiteForm.id,
        ruby_client: websiteForm.ruby_client_fk,
        subsite: formFirstSubsite || 1,
        action: 'get'
    };

    return {
        admin: [{
            name: WEBSITES_PLUGIN__CONSTANTS.LABEL_PLURAL,
            icon: WEBSITES_PLUGIN__CONSTANTS.ICON_PATH,
            permissions: [permissionSpec],
            route: '/app/' + WEBSITES_PLUGIN__CONSTANTS.ROUTE_KEY + '/list'
        }],
        web_content: mainEntryId ? [{
            name: 'Dashboard',
            icon: 'material-ui/svgIcons/ActionDashboard',
            permissions: [_extends({ id: mainEntryId }, permissionSpec)],
            route: '/app/' + WEBSITES_PLUGIN__CONSTANTS.ROUTE_KEY + '/' + mainEntryId + '/edit#tab=analytics'
        }, {
            name: 'Sitemap',
            icon: 'ruby/icons/content/sitemap',
            permissions: [_extends({ id: mainEntryId }, permissionSpec)],
            route: '/app/' + WEBSITES_PLUGIN__CONSTANTS.ROUTE_KEY + '/' + mainEntryId + '/edit'
        }] : []
    };
}