'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _fieldBase = require('@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule');

var _fieldBase2 = _interopRequireDefault(_fieldBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var LinkUrl = _react2.default.createClass({
    displayName: 'LinkUrl',

    propTypes: {
        label: PropTypes.string,
        id: PropTypes.string,
        value: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string
        })
    },
    render: function render() {
        var _React$createElement, _React$createElement3;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var self = this;

        return _react2.default.createElement(
            'div',
            (_React$createElement3 = {
                id: this.props.id,
                className: _fieldBase2.default.wrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'LinkUrl'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'LinkUrl'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'LinkUrl'), _React$createElement3),
            _react2.default.createElement(
                'label',
                (_React$createElement = { className: _fieldBase2.default.label, 'data-codecept-selector-node': 'label',
                    'data-codecept-selector-file': 'LinkUrl'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'LinkUrl'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'LinkUrl'), _React$createElement),
                this.props.label
            ),
            this.props.children.map(function (child) {
                var _React$createElement2;

                return _react2.default.createElement(
                    'div',
                    (_React$createElement2 = {
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'LinkUrl'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'LinkUrl'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'LinkUrl'), _React$createElement2),
                    child
                );
            })
        );
    }
});

exports.default = LinkUrl;