'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Card = require('material-ui/Card');

var _Table = require('material-ui/Table');

var _styleObject = require('./styleObject');

var _styleObject2 = _interopRequireDefault(_styleObject);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var tableRowHeight = 34;
var tableColStyle = {
    height: tableRowHeight,
    verticalAlign: 'middle',
    borderBottom: 0,
    borderTop: '1px solid rgba(200,200,200,1)',
    paddingLeft: 16,
    paddingRight: 16
};

var DataTable = _react2.default.createClass({
    displayName: 'DataTable',

    propTypes: {
        label: PropTypes.string,
        id: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.shape({})),
        host: PropTypes.string
    },
    render: function render() {
        var _extends2,
            _this = this,
            _React$createElement7,
            _React$createElement8,
            _React$createElement9;

        var data = _lodash2.default.get(this, 'props.data.rows', []);

        return _react2.default.createElement(
            _Card.Card,
            (_React$createElement9 = {
                'data-codecept-selector-node': 'Card',
                'data-codecept-selector-file': 'Table'
            }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Table'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Table'), _React$createElement9),
            _react2.default.createElement(_Card.CardHeader, _extends({
                title: this.props.label,
                subtitle: this.props.subtitle
            }, _styleObject2.default.CardHeader, (_extends2 = {
                'data-codecept-selector-node': 'CardHeader',
                'data-codecept-selector-file': 'Table'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Table'), _defineProperty(_extends2, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Table'), _extends2))),
            _react2.default.createElement(
                _Table.Table,
                (_React$createElement8 = { selectable: false, wrapperStyle: { padding: 7 }, style: { tableLayout: 'auto' }, 'data-codecept-selector-node': 'Table',
                    'data-codecept-selector-file': 'Table'
                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Table'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Table'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Table'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Table'), _React$createElement8),
                _react2.default.createElement(
                    _Table.TableBody,
                    (_React$createElement7 = { displayRowCheckbox: false, 'data-codecept-selector-node': 'TableBody',
                        'data-codecept-selector-file': 'Table'
                    }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'TableBody'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Table'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'TableBody'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Table'), _React$createElement7),
                    data && data.map(function (rowData) {
                        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6;

                        return _react2.default.createElement(
                            _Table.TableRow,
                            (_React$createElement6 = {
                                style: {
                                    height: tableRowHeight,
                                    border: 0
                                },
                                'data-codecept-selector-node': 'TableRow',
                                'data-codecept-selector-file': 'Table'
                            }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'TableRow'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Table'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'TableRow'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Table'), _React$createElement6),
                            _react2.default.createElement(
                                _Table.TableRowColumn,
                                (_React$createElement3 = {
                                    style: _extends({ textAlign: 'left', position: 'relative' }, tableColStyle),
                                    'data-codecept-selector-node': 'TableRowColumn',
                                    'data-codecept-selector-file': 'Table'
                                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'TableRowColumn'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Table'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'TableRowColumn'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Table'), _React$createElement3),
                                _react2.default.createElement(
                                    'a',
                                    (_React$createElement = {
                                        target: '_blank',
                                        href: _this.props.host + rowData.link,
                                        style: { width: 200, display: 'block' },
                                        'data-codecept-selector-node': 'a',
                                        'data-codecept-selector-file': 'Table'
                                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Table'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Table'), _React$createElement),
                                    rowData.label
                                ),
                                _react2.default.createElement('div', (_React$createElement2 = {
                                    style: {
                                        position: 'absolute',
                                        height: '100%',
                                        width: '2em',
                                        right: 0,
                                        top: 0,
                                        background: 'linear-gradient(to right, transparent, white)',
                                        pointerEvents: 'none'
                                    },
                                    'data-codecept-selector-node': 'div',
                                    'data-codecept-selector-file': 'Table'
                                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Table'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Table'), _React$createElement2))
                            ),
                            _react2.default.createElement(
                                _Table.TableRowColumn,
                                (_React$createElement5 = {
                                    style: _extends({ textAlign: 'right', width: '1%', whiteSpace: 'nowrap' }, tableColStyle),
                                    'data-codecept-selector-node': 'TableRowColumn',
                                    'data-codecept-selector-file': 'Table'
                                }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'TableRowColumn'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Table'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'TableRowColumn'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Table'), _React$createElement5),
                                _react2.default.createElement(
                                    'strong',
                                    (_React$createElement4 = {
                                        'data-codecept-selector-node': 'strong',
                                        'data-codecept-selector-file': 'Table'
                                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Table'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Table'), _React$createElement4),
                                    rowData.value.toLocaleString()
                                )
                            )
                        );
                    })
                )
            )
        );
    }
});

exports.default = DataTable;