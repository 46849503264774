'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _require = require('../../common/utils/filterTagsToWhereFilter'),
    filterTagsToWhereFilterUsingTemplateKeyword = _require.filterTagsToWhereFilterUsingTemplateKeyword;

/*
 *  filterTags_byDataPath_byMatch = {
 *      "dataPath": {
 *          "is": [
 *              {... filter tag ...}
 *              , {... filter tag ...}
 *          ]
 *      }
 *  }
 */


function savedSearchToWhereFilter(savedSearch, templateKey) {
    var filterTags = _lodash2.default.get(savedSearch, 'filterTags', []);

    return filterTagsToWhereFilterUsingTemplateKeyword(filterTags, templateKey);
}

exports.default = savedSearchToWhereFilter;