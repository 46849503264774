'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styleHelper = require('./utils/styleHelper');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CheckItem = function (_React$Component) {
  _inherits(CheckItem, _React$Component);

  function CheckItem() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, CheckItem);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = CheckItem.__proto__ || Object.getPrototypeOf(CheckItem)).call.apply(_ref, [this].concat(args))), _this), _this._handleClick = function () {
      _this.props.toggleColumn(_this.props.name);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(CheckItem, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'label',
        { onClick: this._handleClick, 'data-codecept-selector-node': 'label',
          'data-codecept-selector-file': 'settings'
        },
        _react2.default.createElement('input', { type: 'checkbox', checked: this.props.checked, name: this.props.name, 'data-codecept-selector-node': 'input',
          'data-codecept-selector-file': 'settings'
        }),
        this.props.text
      );
    }
  }]);

  return CheckItem;
}(_react2.default.Component);

CheckItem.propTypes = {
  toggleColumn: _react2.default.PropTypes.func.isRequired,
  name: _react2.default.PropTypes.string.isRequired,
  checked: _react2.default.PropTypes.bool,
  value: _react2.default.PropTypes.oneOfType([_react2.default.PropTypes.string, _react2.default.PropTypes.Number]),
  text: _react2.default.PropTypes.string
};

var PageSize = function (_React$Component2) {
  _inherits(PageSize, _React$Component2);

  function PageSize() {
    var _ref2;

    var _temp2, _this2, _ret2;

    _classCallCheck(this, PageSize);

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return _ret2 = (_temp2 = (_this2 = _possibleConstructorReturn(this, (_ref2 = PageSize.__proto__ || Object.getPrototypeOf(PageSize)).call.apply(_ref2, [this].concat(args))), _this2), _this2._handleChange = function (e) {
      _this2.props.setPageSize(parseInt(e.target.value));
    }, _temp2), _possibleConstructorReturn(_this2, _ret2);
  }

  _createClass(PageSize, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'select',
        { name: 'pageSize', onChange: this._handleChange, 'data-codecept-selector-node': 'select',
          'data-codecept-selector-file': 'settings'
        },
        this.props.sizes.map(function (size) {
          return _react2.default.createElement(
            'option',
            { value: size, 'data-codecept-selector-node': 'option',
              'data-codecept-selector-file': 'settings'
            },
            size
          );
        })
      );
    }
  }]);

  return PageSize;
}(_react2.default.Component);

PageSize.defaultProps = {
  sizes: [5, 10, 20, 30, 50, 100]
};

var Settings = function (_React$Component3) {
  _inherits(Settings, _React$Component3);

  function Settings() {
    var _ref3;

    var _temp3, _this3, _ret3;

    _classCallCheck(this, Settings);

    for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    return _ret3 = (_temp3 = (_this3 = _possibleConstructorReturn(this, (_ref3 = Settings.__proto__ || Object.getPrototypeOf(Settings)).call.apply(_ref3, [this].concat(args))), _this3), _this3._getDisplayName = function (column) {
      var renderProperties = _this3.props.renderProperties;

      if (renderProperties.columnProperties.hasOwnProperty(column)) {
        return renderProperties.columnProperties[column].hasOwnProperty('displayName') ? renderProperties.columnProperties[column].displayName : column;
      } else if (renderProperties.hiddenColumnProperties.hasOwnProperty(column)) {
        return renderProperties.hiddenColumnProperties[column].hasOwnProperty('displayName') ? renderProperties.hiddenColumnProperties[column].displayName : column;
      }

      return column;
    }, _temp3), _possibleConstructorReturn(_this3, _ret3);
  }

  _createClass(Settings, [{
    key: 'render',
    value: function render() {
      var _this4 = this;

      var keys = Object.keys(this.props.renderProperties.columnProperties);

      var _getStyleProperties = (0, _styleHelper.getStyleProperties)(this.props, 'settings'),
          style = _getStyleProperties.style,
          className = _getStyleProperties.className;

      var columns = this.props.allColumns.map(function (column) {
        return _react2.default.createElement(CheckItem, {
          toggleColumn: _this4.props.events.toggleColumn,
          name: column,
          text: _this4._getDisplayName(column),
          checked: keys.indexOf(column) > -1, 'data-codecept-selector-node': 'CheckItem',
          'data-codecept-selector-file': 'settings'
        });
      });

      return _react2.default.createElement(
        'div',
        { style: style, className: className, 'data-codecept-selector-node': 'div',
          'data-codecept-selector-file': 'settings'
        },
        columns,
        _react2.default.createElement(PageSize, { setPageSize: this.props.events.setPageSize, 'data-codecept-selector-node': 'PageSize',
          'data-codecept-selector-file': 'settings'
        })
      );
    }
  }]);

  return Settings;
}(_react2.default.Component);

Settings.propTypes = {
  allColumns: _react2.default.PropTypes.arrayOf(_react2.default.PropTypes.string),
  visibleColumns: _react2.default.PropTypes.arrayOf(_react2.default.PropTypes.node)
};

exports.default = Settings;