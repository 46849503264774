'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _FloatingActionButton = require('material-ui/FloatingActionButton');

var _FloatingActionButton2 = _interopRequireDefault(_FloatingActionButton);

var _Tooltip = require('material-ui/internal/Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _addInfoModeProps = require('@rubyapps/ruby-react-components/src/client/hoc/addInfoModeProps');

var _addInfoModeProps2 = _interopRequireDefault(_addInfoModeProps);

var _Text_style = require('./Text_style');

var _Text_style2 = _interopRequireDefault(_Text_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var RubyIcons = require('@rubyapps/ruby-icons');
var WandIcon = RubyIcons.iconGenerators['font-awesome']('wand-magic-sparkles');

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label,
    RichErrorMessages = _require.RichErrorMessages;

var fieldBaseStyles = process.browser ? require('@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule').default : {};
var textStyles = process.browser ? require('./Text.cssModule').default : {};
var styles = _extends({}, fieldBaseStyles, textStyles);

var Text = _react2.default.createClass({
    displayName: 'Text',

    propTypes: {
        label: PropTypes.string,
        value: PropTypes.string,
        suggestedMode: PropTypes.bool,
        isAutoPopulated: PropTypes.bool,
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        id: PropTypes.string,
        actions: PropTypes.object,
        excludeWrapper: PropTypes.bool
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null
        };
    },
    _onTextChange: function _onTextChange(event) {
        this.props.actions.setFieldValueByKey(event.target.value, this.props.fieldKey);
    },
    _conditionalProps: function _conditionalProps() {

        var muiTheme = this.context.muiTheme;

        var inactiveColor = muiTheme.palette.inactiveColor;


        return this.props.isAutoPopulated ? {
            inputStyle: {
                color: inactiveColor
            }
        } : {};
    },
    _renderUseSuggestedButton: function _renderUseSuggestedButton() {
        var _React$createElement,
            _this = this,
            _React$createElement2,
            _React$createElement3,
            _React$createElement4;

        return this.props.suggestedValue && !this.props.value ? _react2.default.createElement(
            'div',
            (_React$createElement4 = { className: styles.toolbarButtonWrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Text'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Text'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Text'), _React$createElement4),
            _react2.default.createElement(_Tooltip2.default, (_React$createElement = {
                show: this.state && this.state.hoveredTooltip,
                label: "Use Suggested Text",
                horizontalPosition: 'right',
                verticalPosition: 'top',
                style: {
                    top: -2,
                    left: 0
                },
                'data-codecept-selector-node': 'Tooltip',
                'data-codecept-selector-file': 'Text'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Tooltip'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Text'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Tooltip'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Text'), _React$createElement)),
            _react2.default.createElement(
                _FloatingActionButton2.default,
                (_React$createElement3 = {
                    className: styles.toolbarButton,
                    mini: true,
                    onClick: function onClick() {
                        _this.setState({ hoveredTooltip: false });
                        _this.props.actions.setFieldValue(_this.props.suggestedValue);
                    },
                    onMouseEnter: function onMouseEnter() {
                        _this.setState({ hoveredTooltip: true });
                    },
                    onMouseLeave: function onMouseLeave() {
                        _this.setState({ hoveredTooltip: false });
                    },
                    'data-codecept-selector-node': 'FloatingActionButton',
                    'data-codecept-selector-file': 'Text'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'FloatingActionButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Text'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'FloatingActionButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Text'), _React$createElement3),
                _react2.default.createElement(WandIcon, (_React$createElement2 = {
                    'data-codecept-selector-node': 'WandIcon',
                    'data-codecept-selector-file': 'Text'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'WandIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Text'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'WandIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Text'), _React$createElement2))
            )
        ) : null;
    },
    _renderToolbar: function _renderToolbar() {
        var _React$createElement5;

        return _react2.default.createElement(
            'div',
            (_React$createElement5 = { className: styles.toolbar, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Text'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Text'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Text'), _React$createElement5),
            this._renderUseSuggestedButton()
        );
    },
    render: function render() {
        var _React$createElement6, _extends2, _extends3, _React$createElement7;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var labelProps = _.pick(this.props, Object.keys(Label.propTypes));

        var errorText = !_.isEmpty(this.props.richErrorMessages) ? _react2.default.createElement(RichErrorMessages, (_React$createElement6 = { messages: this.props.richErrorMessages, 'data-codecept-selector-node': 'RichErrorMessages',
            'data-codecept-selector-file': 'Text'
        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Text'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Text'), _React$createElement6)) : null;
        var inputComponent = _react2.default.createElement(
            'div',
            (_React$createElement7 = { className: styles.wrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Text'
            }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Text'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Text'), _React$createElement7),
            _react2.default.createElement(_TextField2.default, _extends({}, _Text_style2.default.TextField, {
                floatingLabelFixed: !!this.props.suggestedValue,
                floatingLabelText: _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                    'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'Text'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Text'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Text'), _extends2))),
                placeholder: this.props.suggestedValue,
                id: this.props.id,
                onChange: this._onTextChange,
                value: this.props.value || '',
                errorText: errorText,
                disabled: this.props.locked
            }, this._conditionalProps(), _.get(this, 'props.infoModeProps.TextField'), _.get(this, 'props.infoModeProps.default'), (_extends3 = {
                'data-codecept-selector-node': 'TextField',
                'data-codecept-selector-file': 'Text'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Text'), _defineProperty(_extends3, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Text'), _extends3))),
            this._renderToolbar()
        );

        return this.props.renderGridWrapper(_extends({}, this.props, { children: inputComponent }));
    }
});

exports.default = (0, _addInfoModeProps2.default)(Text);