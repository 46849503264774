'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.GRIDDLE_ROW_SELECTION_TOGGLED = GRIDDLE_ROW_SELECTION_TOGGLED;
exports.GRIDDLE_ROW_SELECT_VISIBLE_ROWS = GRIDDLE_ROW_SELECT_VISIBLE_ROWS;
exports.GRIDDLE_ROW_UNSELECT_VISIBLE_ROWS = GRIDDLE_ROW_UNSELECT_VISIBLE_ROWS;
exports.GRIDDLE_CLEAR_ALL_ROW_SELECTIONS = GRIDDLE_CLEAR_ALL_ROW_SELECTIONS;
exports.GRIDDLE_LOADED_DATA_AFTER = GRIDDLE_LOADED_DATA_AFTER;

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

var _helpers = require('../../griddle-subgrid-plugin/src/helpers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// These functions are doing most of the work:

function griddleKeysForVisibleData(state, returnCollapsed) {
    var visibleData = state.get('visibleData');
    var visibleStaticData = state.get('visibleStaticData');

    var flatVisibleData = (0, _helpers.flattenNestedDataBy)(visibleData, _helpers.getChildren);
    var flatVisibleStaticData = (0, _helpers.flattenNestedDataBy)(visibleStaticData, _helpers.getChildren);

    var visibleGriddleKeys = flatVisibleData.concat(flatVisibleStaticData).map(function (row) {
        return row.getIn(['__metadata', 'griddleKey'], row.get('griddleKey'));
    });

    return visibleGriddleKeys;
}

function newStateFromSelection(state, griddleKey, selectMode) {
    var idColumn = state.get('idColumn');

    //# NOTE: we might want to update setting staticData to include in regular data
    var isStaticGriddleKey = griddleKey.indexOf('static-') == 0;

    var flatData = (0, _helpers.flattenNestedDataBy)(isStaticGriddleKey ? state.get('staticData') : state.get('data'), _helpers.getChildren);

    var rowData = flatData.find(function (row) {
        return row.get('griddleKey') === griddleKey;
    });

    var rowIdValue = rowData.get(idColumn) + '';
    var isSelected = state.get('selectedRecords').has(rowIdValue);
    var shouldSelect = selectMode == -1 ? !isSelected : selectMode; // -1 == toggle


    var selectedRecords = state.get('selectedRecords');
    //# need to clean up the selected records from selection order
    //# this is a problem for merge lookups cause after merge, thed selected records gets cleared but the selection order doesn't
    var selectionOrder = state.get('selectionOrder').filter(function (id) {
        return selectedRecords.has(id);
    });

    if (shouldSelect) {
        state = state.set('selectedRecords', selectedRecords.set(rowIdValue, rowData));
        state = state.set('selectionOrder', selectionOrder.concat(rowIdValue));
    } else {
        state = state.set('selectedRecords', selectedRecords.remove(rowIdValue));
        state = state.set('selectionOrder', selectionOrder.filter(function (id) {
            return id !== rowIdValue;
        }));
    }

    return setRowProperties_inState(state);
}

// These functions are helper functions for working with nested data:


// These are the actual reducer functions:

function GRIDDLE_ROW_SELECTION_TOGGLED(state, action, helpers) {
    var griddleKey = action.griddleKey;

    return newStateFromSelection(state, griddleKey, -1);
}

function GRIDDLE_ROW_SELECT_VISIBLE_ROWS(state, action, helpers) {
    var visibleGriddleKeys = griddleKeysForVisibleData(state);
    return visibleGriddleKeys.reduce(function (collectorState, visibleGriddleKey) {
        return newStateFromSelection(collectorState, visibleGriddleKey, 1);
    }, state);
}

function GRIDDLE_ROW_UNSELECT_VISIBLE_ROWS(state, action, helpers) {
    var visibleGriddleKeys = griddleKeysForVisibleData(state, true);
    return visibleGriddleKeys.reduce(function (collectorState, visibleGriddleKey) {
        return newStateFromSelection(collectorState, visibleGriddleKey, 0);
    }, state);
}

function GRIDDLE_CLEAR_ALL_ROW_SELECTIONS(state) {
    return setRowProperties_inState(state.set('selectedRecords', _immutable2.default.fromJS({})));
}

function GRIDDLE_LOADED_DATA_AFTER(state) {
    return setRowProperties_inState(state);
}

function setRowProperties_inState(state) {
    var data = state.get('data');

    var idColumn = state.get('idColumn');
    var selectedRecords = state.get('selectedRecords');

    return state.set('data', getHydratedRows_fromRows_andParams(data, {
        idColumn: idColumn,
        selectedRecords: selectedRecords
    }));
}

function getHydratedRows_fromRows_andParams(data) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (!data) {
        return data;
    }

    var idColumn = params.idColumn,
        selectedRecords = params.selectedRecords,
        ancestorIsSelected = params.ancestorIsSelected;


    return data.map(function (row) {
        var rowIdValue = row.get(idColumn);
        var isSelected = selectedRecords.has(rowIdValue + '');

        var children = row.get('children');
        if (children && children.size > 0) {
            children = getHydratedRows_fromRows_andParams(children, _extends({}, params, {
                ancestorIsSelected: isSelected || ancestorIsSelected
            }));
        }

        return row.set('isSelected', isSelected).set('ancestorIsSelected', ancestorIsSelected).set('children', children);
    });
}