'use strict';

var RubyComponentForms = require('@rubyapps/ruby-component-forms');

//== The Middleware ========================================================//

var middlewareInitializer = function middlewareInitializer(store) {
    var _this = this;

    var selfModule = this;
    var selfSelector = this.getDefaultSelector();
    var selfAction = selfModule.getAction();

    return function (next) {
        return function (action) {
            var nextRetVal = next(action); //# still allow @@router to continue propagation
            var hiddenComponent = selfModule._hiddenComponent;
            var _props = _this.props,
                forgotPasswordTokenKey = _props.forgotPasswordTokenKey,
                forgotPasswordTokenParam = _props.forgotPasswordTokenParam;

            var forgotPasswordUUID = void 0;
            var mySetRouteAction = void 0;
            switch (action.type) {
                case 'BATCHING_REDUCER.BATCH':
                    var batchPayload = action.payload;
                    mySetRouteAction = _.find(batchPayload, { 'type': selfAction.TYPES.SET_ROUTE_PARAMS });
                    if (mySetRouteAction) {
                        forgotPasswordUUID = mySetRouteAction.payload.query[forgotPasswordTokenParam];
                        store.dispatch(hiddenComponent.getAction().generators.setFieldValueByKey(forgotPasswordUUID, forgotPasswordTokenKey));
                    }
                    break;
                case selfAction.TYPES.SET_ROUTE_PARAMS:
                    mySetRouteAction = action;
                    forgotPasswordUUID = mySetRouteAction.payload.query[forgotPasswordTokenParam];
                    store.dispatch(hiddenComponent.getAction().generators.setFieldValueByKey(forgotPasswordUUID, forgotPasswordTokenKey));
                    break;

            }
            return nextRetVal;
        };
    };
};

module.exports = middlewareInitializer;