'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.rubyComponentPatcherForPopover = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function rubyComponentPatcherForPopover(popoverComponent) {
    var selfModule = this;

    var popoverStatesSelector = popoverComponent.statesSelector;

    popoverComponent.statesSelector = function (state) {
        return _extends({}, popoverStatesSelector.bind(popoverComponent)(state), selfModule.statesSelector.bind(selfModule)(state));
    };

    popoverComponent._formValueFromLocalState = function (selfState, isError, predicateFormatter, entireState) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        if (selfState == undefined) {
            return undefined;
        }

        var children = this.getChildren();

        var arrOfReducedChildrenState = _lodash2.default.reduce(children, function (collector, child, index) {
            var childID = child.getID();
            var childFormValue = child.formValueFromLocalState ? child._formValueFromLocalState(selfState[childID], isError, predicateFormatter, entireState) : undefined;
            if (childFormValue != undefined) {
                collector.push(childFormValue);
            }
            return collector;
        }, []);

        var reducedChildrenState = _extends.apply(undefined, [{}].concat(_toConsumableArray(arrOfReducedChildrenState)));
        var reducedChildrenAvail = Object.keys(reducedChildrenState).length > 0;

        var formValue = reducedChildrenAvail ? reducedChildrenState : undefined;
        var statusFormValue = selfModule.findDescendentBy(function (el) {
            return el.key() === 'status';
        }).formValue();
        var parentRawFormValue = _extends({}, statusFormValue, formValue);
        var modifiedFormValue = _extends({}, formValue, {
            start_timestamp: selfModule.getStartTimeStampIsVisible_fromRawFormValue(parentRawFormValue) ? formValue.start_timestamp : null,
            end_timestamp: selfModule.getEndTimeStampIsVisible_fromRawFormValue(parentRawFormValue) ? formValue.end_timestamp : null
        });

        if (options.excludeNull) {
            if (_lodash2.default.isNil(modifiedFormValue.start_timestamp)) {
                delete modifiedFormValue.start_timestamp;
            }

            if (_lodash2.default.isNil(modifiedFormValue.end_timestamp)) {
                delete modifiedFormValue.end_timestamp;
            }
        }
        return modifiedFormValue;
    };

    return popoverComponent;
}

exports.rubyComponentPatcherForPopover = rubyComponentPatcherForPopover;