'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _serverTransforms = require('./serverTransforms');

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var uuid = require('node-uuid');

var NAME_KEY = 'label';
var TYPE_KEY = 'componentName';

var defaultFormState = {
    instances: {
        /*
        '1': {
            id: '1',
            instanceProps: {
                instanceType: 'Tab',
                _locked: {
                    value: false
                },
                label: {
                    value: null,
                },
            }
        },
        '2': {
            id: '2',
            instanceProps: {
                instanceType: 'Fieldset',
                _locked: {
                    value: false
                },
                label: {
                    value: null,
                },
            },
        },
        '3': {
            id: '3',
            instanceProps: {
                instanceType: 'Field',
                _locked: {
                    value: true
                },
                label: {
                    value: 'Case Cite',
                    errorText: 'Label is a required field.',
                },
                componentName: {
                    value: 'Text',
                },
                constraint_required: {
                    value: true,
                },
                key: {
                    value: 'case_cite',
                },
            },
        },
        '4': {
            id: '4',
            instanceProps: {
                instanceType: 'Field',
                label: {
                    value: 'Reported Case?',
                },
                componentName: {
                    value: 'Checkbox',
                },
                constraint_required: {
                    value: false,
                },
                key: {
                    value: 'reported',
                }
            }
        },
        */
    },
    childIds: [/*'1','2', '3','4'*/]
};

function newSpecForInstanceType(instanceType) {
    switch (instanceType) {
        case 'Tab':
            return {
                instanceType: 'Tab',
                label: {
                    value: null
                }
            };
        case 'Fieldset':
            return {
                instanceType: 'Fieldset',
                label: {
                    value: null
                }
            };
        case 'Field':
            return {
                instanceType: 'Field',
                label: {
                    value: null
                },
                componentName: {
                    value: 'Text'
                },
                constraint_required: {
                    value: null
                },
                key: {
                    value: null
                }
            };
        case 'SubTemplate':
            return {
                instanceType: 'SubTemplate',
                label: {
                    value: ''
                }
            };
    }
}

function newID() {
    return uuid.v1();
}
function flattenTree(tree) {
    function recurse(nodes, path) {
        return _.map(nodes, function (node, index) {
            if (node) {
                return [_.map(_.omit(node, ['children']), function (nodeValue, nodeKey) {
                    var newPath = path.concat([index, nodeKey]);
                    return { patharr: newPath, key: nodeKey, value: nodeValue };
                }), recurse(node.children, path.concat([index, 'children']))];
            } else {
                return [];
            }
        });
    }

    return _.flattenDeep(recurse(tree, []));
}

function setStateWithFieldKey(state, fieldKey, value) {
    var isUserModified = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

    var originalFields = state.fields || {};

    var updatedValue = { value: value };
    var updatedField = _extends({}, originalFields[fieldKey] || {}, updatedValue);
    var updatedFields = _extends({}, originalFields, _defineProperty({}, fieldKey, updatedField));

    if (isUserModified) {
        updatedFields[fieldKey].userModifiedTimestamp = new Date();
    }

    return _extends({}, state, { fields: updatedFields });
}

function setFieldCardProperty_reducer(selfModule, state, action) {
    var formKey = selfModule.props.key;
    var type = action.type,
        payload = action.payload;


    var fieldState = _.get(state, ['fields', formKey, 'value']);

    var newState = _.merge({}, fieldState, {
        instances: _defineProperty({}, payload.key, _defineProperty({}, payload.property, payload.value))
    });

    return setStateWithFieldKey(state, formKey, newState);
}

function setFieldProperty_reducer(selfModule, state, action) {
    var formKey = selfModule.props.key;
    var type = action.type,
        payload = action.payload;
    var error = payload.error,
        errors = payload.errors,
        key = payload.key;


    var fieldState = _.get(state, ['fields', formKey, 'value']);

    var newState = _extends({}, fieldState, {
        instances: _extends({}, fieldState.instances, _defineProperty({}, payload.key, _extends({}, fieldState.instances[payload.key], {
            instanceProps: _extends({}, fieldState.instances[payload.key].instanceProps, _defineProperty({}, payload.property, {
                value: payload.value,
                errorText: error ? error.message : null
            }))
        })))
    });

    return setStateWithFieldKey(state, formKey, newState);
}

/*
 *  Given a FormJS like tree of errors, we need to merge that into the full FormJS tree
 * */
function setFieldErrors_reducer(selfModule, state, action) {
    var formKey = selfModule.props.key;
    var type = action.type,
        payload = action.payload;


    var errorTree = payload.errors;

    var formJS_forInstance = (0, _serverTransforms.convertFieldEditorState_toFormJS)(_.get(state, ['fields', formKey, 'value']));

    //# walk the errorTree, generating a keypath and setting the keypath on formJS_forInstance
    var flattenedErrorTree = flattenTree(errorTree);

    flattenedErrorTree.forEach(function (errorObject) {
        var patharr = errorObject.patharr,
            key = errorObject.key,
            value = errorObject.value;

        //# WARNING:
        //# This is hacky. We shouldn't be nesting the values when formJS_forInstance
        //# should represent the actual formJS

        var formJSValue = _.get(formJS_forInstance, patharr);
        _.set(formJS_forInstance, patharr, {
            _doLift: true,
            value: formJSValue,
            errorText: value
        });
        //# We rely on the conversion functions we already have
        //# and then we patch up the values so that we effectively insert 'errorText'
        //# adjacent to the 'value' for the instanceProps.${property}
    });

    var augmentedFieldEditorState = (0, _serverTransforms.convertFormJS_toFieldEditorState)(formJS_forInstance);
    //# patch the augmentedFieldEditorState up by moving the values out
    _.each(augmentedFieldEditorState.instances, function (instance) {
        _.each(instance.instanceProps, function (instanceProp) {
            if (instanceProp.value && instanceProp.value._doLift) {
                instanceProp = _extends({}, instanceProp, _.omit(instanceProp.value, ['_doLift']));
            }
        });
    });

    return setStateWithFieldKey(state, formKey, augmentedFieldEditorState);
}

function addCard_reducer(selfModule, state, action) {
    var formKey = selfModule.props.key;
    var type = action.type,
        payload = action.payload;


    var instanceType = payload.type;

    var targetID = payload.targetID;
    var scrollToBottomOnMount = payload.scrollToBottomOnMount;
    var fieldState = _.get(state, ['fields', formKey, 'value']);
    var childIds = _.get(fieldState, 'childIds');
    var newChildID = newID();
    var newSpec = {
        id: newChildID,
        instanceProps: newSpecForInstanceType(instanceType),
        scrollToBottomOnMount: scrollToBottomOnMount
    };

    var indexForNewChild = targetID ? childIds.indexOf(targetID) : childIds.length;

    var newChildIds = [].concat(childIds.slice(0, indexForNewChild + 1), newChildID, childIds.slice(indexForNewChild + 1));

    var newState = _extends({}, fieldState, {
        childIds: newChildIds,
        instances: _extends({}, fieldState.instances, _defineProperty({}, newChildID, newSpec))
    });
    return setStateWithFieldKey(state, formKey, newState);
}

function duplicateCard_reducer(selfModule, state, action) {
    var _instanceProps;

    var formKey = selfModule.props.key;
    var type = action.type,
        payload = action.payload;


    var targetID = payload.targetID;
    var fieldState = _.get(state, ['fields', formKey, 'value']);
    var childIds = _.get(fieldState, 'childIds');
    var referenceInstance = _.get(fieldState, ['instances', targetID]);
    var newChildID = newID();

    var name = _.get(referenceInstance, ['instanceProps', NAME_KEY, 'value']);
    var key = _.get(referenceInstance, ['instanceProps', 'key', 'value']);

    var newSpecState = _.merge({}, referenceInstance, {
        id: newChildID,
        instanceProps: (_instanceProps = {}, _defineProperty(_instanceProps, NAME_KEY, { value: name + ' Copy' }), _defineProperty(_instanceProps, 'key', { value: key + '_copy' }), _instanceProps)
    });

    var indexForNewChild = childIds.indexOf(targetID);

    var newChildIds = [].concat(childIds.slice(0, indexForNewChild + 1), newChildID, childIds.slice(indexForNewChild + 1));

    var newState = _extends({}, fieldState, {
        childIds: newChildIds,
        instances: _extends({}, fieldState.instances, _defineProperty({}, newChildID, newSpecState))
    });

    return setStateWithFieldKey(state, formKey, newState);
}

function deleteCard_reducer(selfModule, state, action) {
    var formKey = selfModule.props.key;
    var type = action.type,
        payload = action.payload;


    var targetID = payload.targetID;
    var fieldState = _.get(state, ['fields', formKey, 'value']);
    var childIds = _.get(fieldState, 'childIds');
    var referenceInstance = _.get(fieldState, ['instances', targetID]);

    var deleteIndex = childIds.indexOf(targetID);
    var updatedChildIds = [].concat(childIds.slice(0, deleteIndex), childIds.slice(deleteIndex + 1));

    var newState = _extends({}, fieldState, {
        childIds: updatedChildIds,
        instances: _.omit(fieldState.instances, targetID)
    });

    return setStateWithFieldKey(state, formKey, newState);
}

function setEditCardCode_reducer(selfModule, state, action) {
    var formKey = selfModule.props.key;
    var type = action.type,
        payload = action.payload;
    var instanceID = payload.instanceID,
        value = payload.value;


    var fieldState = _.get(state, ['fields', formKey, 'value']);

    if (value) {
        var fieldEditorState = (0, _serverTransforms.convertFormJS_toFieldEditorState)([value]);

        var newState_withUpdatedInstance = _extends({}, fieldState, {
            instances: _extends({}, fieldState.instances, _defineProperty({}, instanceID, _extends({}, _.get(fieldState, ['instances', instanceID]), {
                instanceProps: _.get(fieldEditorState, ['instances', fieldEditorState.childIds[0], 'instanceProps'])
            })))
        });

        return setStateWithFieldKey(state, formKey, newState_withUpdatedInstance);
    }
}

function updateCardOrder_reducer(selfModule, state, action) {
    var formKey = selfModule.props.key;
    var type = action.type,
        payload = action.payload;


    var newOrder = payload.order;
    var fieldState = _.get(state, ['fields', formKey, 'value']);

    var newState = _extends({}, fieldState, {
        childIds: newOrder
    });

    return setStateWithFieldKey(state, formKey, newState);
}

function moveCard_reducer(selfModule, state, action) {
    var formKey = selfModule.props.key;
    var type = action.type,
        payload = action.payload;
    var targetID = payload.targetID,
        afterID = payload.afterID;


    var fieldState = _.get(state, ['fields', formKey, 'value']);
    var childIds = _.get(fieldState, 'childIds');

    var currentIndex = childIds.indexOf(targetID);

    var childIdsWithoutMovedID = [].concat(childIds.slice(0, currentIndex), childIds.slice(currentIndex + 1));

    var newIndex = childIdsWithoutMovedID.indexOf(afterID) + 1;

    var newChildIds = [].concat(childIdsWithoutMovedID.slice(0, newIndex), targetID, childIdsWithoutMovedID.slice(newIndex));

    var newState = _extends({}, fieldState, {
        childIds: newChildIds
    });

    return setStateWithFieldKey(state, formKey, newState);
}

module.exports = function (state, action) {
    var selfModule = this;
    var formKey = selfModule.props.key;

    if (state == undefined) {
        state = {
            fields: _defineProperty({}, formKey, {
                value: defaultFormState
            })
        };
    } else if (!state.fields[formKey].value) {
        return setStateWithFieldKey(state, formKey, defaultFormState);
    }

    var _getAction = this.getAction(),
        TYPES = _getAction.TYPES,
        generators = _getAction.generators;

    var type = action.type,
        payload = action.payload;


    switch (type) {

        case TYPES.SET_FIELD_CARD_PROPERTY:
            return setFieldCardProperty_reducer(selfModule, state, action);

        case TYPES.SET_FIELD_PROPERTY:
            return setFieldProperty_reducer(selfModule, state, action);

        case TYPES.SET_FIELD_ERROR_TREE:
            return setFieldErrors_reducer(selfModule, state, action);

        case TYPES.ADD_CARD:
            return addCard_reducer(selfModule, state, action);

        case TYPES.DUPLICATE_CARD:
            return duplicateCard_reducer(selfModule, state, action);

        case TYPES.DELETE_CARD:
            return deleteCard_reducer(selfModule, state, action);

        case TYPES.MOVE_CARD:
            return moveCard_reducer(selfModule, state, action);

        case TYPES.UPDATE_CARD_ORDER:
            return updateCardOrder_reducer(selfModule, state, action);

        case TYPES.SET_EDIT_CARD_CODE:
            return setEditCardCode_reducer(selfModule, state, action);

        case TYPES.SET_FIELD_VALUE_BY_KEY:
            var fieldEditorState = (0, _serverTransforms.convertFormJS_toFieldEditorState)(action.payload.value);
            return setStateWithFieldKey(state, formKey, fieldEditorState, false);
        default:
            return state;
    }
};