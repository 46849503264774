'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

//const styles = process.browser ? require('./ProfileIdentity.cssModule').default : {};


var ProfileIdentity = _react2.default.createClass({
    displayName: 'ProfileIdentity',

    propTypes: {
        label: PropTypes.string,
        id: PropTypes.string,
        value: PropTypes.shape({
            status: PropTypes.string,
            start_timestamp: PropTypes.instanceOf(Date),
            end_timestamp: PropTypes.instanceOf(Date)
        })
    },
    render: function render() {
        var _React$createElement;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var childrenWrapperComponent = _react2.default.createElement(
            'div',
            (_React$createElement = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'ProfileIdentity'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ProfileIdentity'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ProfileIdentity'), _React$createElement),
            this.props.children
        );

        return this.props.renderGridWrapper(_extends({}, this.props, { children: childrenWrapperComponent }));
    }
});

exports.default = ProfileIdentity;