'use strict';

//== The Middleware ========================================================//

var middlewareInitializer = function middlewareInitializer(store) {
    var selfModule = this;
    return function (next) {
        return function (action) {
            var nextRetVal = next(action); //# still allow @@router to continue propagation
            //# but also need to immediately call on next;

            //if (action.type.indexOf('LEAVING_ROUTE') >= 0) {
            if (action.type === '@@router/LOCATION_CHANGE') {
                var _selfModule$getState = selfModule.getState(),
                    open = _selfModule$getState.open;

                var _selfModule$getAction = selfModule.getAction(),
                    actions = _selfModule$getAction.generators;

                if (open) {
                    actions.closeAndReset && store.dispatch(actions.closeAndReset());
                }
            }

            return nextRetVal;
        };
    };
};

module.exports = middlewareInitializer;