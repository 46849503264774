'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = BooleanFormatterGenerator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function renderBooleanString(dataExists, options) {
    var _React$createElement;

    var trueString = _lodash2.default.get(options, 'trueString') || 'Yes';
    var falseString = _lodash2.default.get(options, 'falseString') || 'No';
    var renderString = dataExists ? trueString : falseString;
    return _react2.default.createElement(
        'span',
        (_React$createElement = {
            'data-codecept-selector-node': 'span',
            'data-codecept-selector-file': 'BooleanFormatterGenerator'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'BooleanFormatterGenerator'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'BooleanFormatterGenerator'), _React$createElement),
        renderString
    );
}

function BooleanFormatterGenerator(options) {
    function BooleanString(props) {
        var data = _lodash2.default.get(props, 'data', '');

        return renderBooleanString(data, options);
    };

    BooleanString.reduce = function (data) {
        var dataExists = data.reduce(function (collector, value, index) {
            return value || collector;
        }, false);

        return renderBooleanString(dataExists, options);
    };

    return BooleanString;
};