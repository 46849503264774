'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

//# Icons


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _SelectField = require('@rubyapps/ruby-material-ui/SelectField');

var _SelectField2 = _interopRequireDefault(_SelectField);

var _IconMenu = require('material-ui/IconMenu');

var _IconMenu2 = _interopRequireDefault(_IconMenu);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _error = require('material-ui/svg-icons/alert/error');

var _error2 = _interopRequireDefault(_error);

var _warning = require('material-ui/svg-icons/alert/warning');

var _warning2 = _interopRequireDefault(_warning);

var _info = require('material-ui/svg-icons/action/info');

var _info2 = _interopRequireDefault(_info);

var _Dropdown_style = require('./Dropdown_style');

var _Dropdown_style2 = _interopRequireDefault(_Dropdown_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label;

var PropTypes_value = _react.PropTypes.oneOfType([_react.PropTypes.string, _react.PropTypes.number, _react.PropTypes.bool]);
var Dropdown = _react2.default.createClass({
    displayName: 'Dropdown',

    propTypes: {
        isControlHidden: _react.PropTypes.bool,
        label: _react.PropTypes.string,
        value: _react.PropTypes.oneOfType([PropTypes_value, _react.PropTypes.arrayOf(PropTypes_value)]),
        error: _react.PropTypes.shape({
            message: _react.PropTypes.shape({
                level: _react.PropTypes.oneOf(['error', 'warning', 'info']),
                msg: _react.PropTypes.string
            })
        }),
        id: _react.PropTypes.string,
        isArray: _react.PropTypes.bool,
        actions: _react.PropTypes.object,
        options: _react.PropTypes.arrayOf(_react.PropTypes.shape({
            value: PropTypes_value,
            text: _react.PropTypes.string
        })),
        styles: _react.PropTypes.shape({
            SelectField: _react.PropTypes.object,
            TextField: _react.PropTypes.object
        }),
        excludeWrapper: _react.PropTypes.bool,
        leftIcon: _react.PropTypes.node
    },
    contextTypes: {
        muiTheme: _react.PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null,
            isControlHidden: false,
            styles: {}
        };
    },
    _onDropdownChange: function _onDropdownChange(event, value) {
        var selectedValue = this._transformUserSelection_intoExpectedType(value, this.props.isArray);

        this.props.actions.requestNamespaceChangeToNamespace(selectedValue);
        /*
        this.props.actions.saveLocalStateForActiveNamespace();
        this.props.actions.setFieldValueByKey(
            selectedValue,
            this.props.fieldKey
        );
        this.props.actions.setLocalStateForActiveNamespace();
        */
    },
    iconButtonElement: function iconButtonElement() {
        var _this = this,
            _extends2,
            _extends3;

        var disabled = this.props.locked;
        var isMultiSelect = Number.isInteger(this.props.limit) && this.props.limit > 1;

        var selectedValues = (this.props.options || []).filter(function (option) {
            return _this.values().indexOf(option.value) > -1;
        });
        var labelProps = _.pick(this.props, Object.keys(Label.propTypes));
        return _react2.default.createElement(_TextField2.default, _extends({}, this.props.styles.TextField, (_extends3 = {
            floatingLabelText: _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                'data-codecept-selector-node': 'Label',
                'data-codecept-selector-file': 'Dropdown'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Dropdown'), _extends2))),
            multiLine: isMultiSelect,
            disabled: disabled,
            value: selectedValues.map(function (val) {
                return val.label || val.text || val.primaryText || val.value;
            }).join(",\n"),
            'data-codecept-selector-node': 'TextField',
            'data-codecept-selector-file': 'Dropdown'
        }, _defineProperty(_extends3, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_extends3, 'data-codecept-selector-node', 'TextField'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Dropdown'), _extends3)));
    },
    menuItems: function menuItems() {
        var _props = this.props,
            errorsByNamespace = _props.errorsByNamespace,
            _props$options = _props.options,
            options = _props$options === undefined ? [] : _props$options;

        var errorColor = this.context.muiTheme.textField.errorColor;
        var warningColor = this.context.muiTheme.palette.warningColor_foreground;
        var infoColor = this.context.muiTheme.palette.infoColor_foreground;
        return options.map(function (opt) {
            var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4;

            var _$get = _.get(errorsByNamespace, [opt.value, 'message'], {}),
                errorLevel = _$get.level;

            var iconsByLevel = {
                error: _react2.default.createElement(_error2.default, (_React$createElement = { style: { fill: errorColor }, 'data-codecept-selector-node': 'Error',
                    'data-codecept-selector-file': 'Dropdown'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Error'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Error'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Dropdown'), _React$createElement)),
                warning: _react2.default.createElement(_warning2.default, (_React$createElement2 = { style: { fill: warningColor }, 'data-codecept-selector-node': 'Warning',
                    'data-codecept-selector-file': 'Dropdown'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Warning'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Warning'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Dropdown'), _React$createElement2)),
                info: _react2.default.createElement(_info2.default, (_React$createElement3 = { style: { fill: infoColor }, 'data-codecept-selector-node': 'Info',
                    'data-codecept-selector-file': 'Dropdown'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Info'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Info'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Dropdown'), _React$createElement3))
            };
            return _react2.default.createElement(_MenuItem2.default, (_React$createElement4 = {
                key: opt.value,
                value: opt.value,
                primaryText: opt.text || opt.primaryText || opt.label || opt.value,
                label: opt.label || opt.text || opt.primaryText || opt.value,
                rightIcon: iconsByLevel[errorLevel],
                'data-codecept-selector-node': 'MenuItem',
                'data-codecept-selector-file': 'Dropdown'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Dropdown'), _React$createElement4));
        });
    },
    values: function values() {
        var dataIsArray = Array.isArray(this.props.value);

        //# the connect layer should have normalized the value
        return dataIsArray ? this.props.value : [this.props.value];
    },
    _renderMultiSelect: function _renderMultiSelect() {
        var _extends4;

        var self = this;
        var isMultiSelect = Number.isInteger(this.props.limit) && this.props.limit > 1;
        var disabled = self.props.locked;

        var _$get2 = _.get(this, 'props.error.message', {}),
            errorLevel = _$get2.level,
            errorText = _$get2.msg;

        return _react2.default.createElement(
            _IconMenu2.default,
            _extends({
                errorText: errorText,
                iconButtonElement: this.iconButtonElement(),
                id: self.props.id,
                onChange: self._onDropdownChange,
                multiple: isMultiSelect,
                useLayerForClickAway: isMultiSelect,
                touchTapCloseDelay: isMultiSelect ? 0 : 200,
                value: this.values(),
                disabled: disabled
            }, disabled ? { open: false } : {}, (_extends4 = {
                'data-codecept-selector-node': 'IconMenu',
                'data-codecept-selector-file': 'Dropdown'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_extends4, 'data-codecept-selector-node', 'IconMenu'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Dropdown'), _extends4)),
            self.menuItems()
        );
    },
    _isFullWidth: function _isFullWidth() {
        return !this.props.excludeWrapper;
    },
    _transformUserSelection_intoExpectedType: function _transformUserSelection_intoExpectedType(value, expectsArray) {
        if (!value) return null;

        var dataIsArray = Array.isArray(value);

        return expectsArray && !dataIsArray ? [value] : !expectsArray && dataIsArray ? value[0] : value;
    },
    _selectFieldStyle: function _selectFieldStyle() {
        if (this._isFullWidth()) {
            return _Dropdown_style2.default.SelectField_fullWidth;
        } else {
            return _Dropdown_style2.default.SelectField_autoWidth;
        }
    },
    _renderSingleSelect: function _renderSingleSelect() {
        var _extends5,
            _this2 = this,
            _extends6;

        var self = this;
        var disabled = self.props.locked;

        var values = this.values();
        var value = values[0];

        this.props.styles.SelectField;
        var errorColor = this.context.muiTheme.textField.errorColor;
        var warningColor = this.context.muiTheme.palette.warningColor_foreground;
        var infoColor = this.context.muiTheme.palette.infoColor_foreground;

        var labelProps = _.pick(this.props, Object.keys(Label.propTypes));

        var _$get3 = _.get(this, 'props.error.message', {}),
            errorLevel = _$get3.level,
            errorText = _$get3.msg;

        var errorColors = {
            error: errorColor,
            warning: warningColor,
            info: infoColor
        };
        return _react2.default.createElement(
            _SelectField2.default,
            _extends({
                autoWidth: true
            }, this._selectFieldStyle(), this.props.styles.SelectField, {
                floatingLabelText: _react2.default.createElement(Label, _extends({}, labelProps, (_extends5 = {
                    'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'Dropdown'
                }, _defineProperty(_extends5, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_extends5, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Dropdown'), _extends5))),
                errorText: errorText,
                id: self.props.id,
                errorStyle: {
                    color: errorColors[errorLevel]
                },
                onChange: function onChange(event, index, value) {
                    var selectedValue = _this2._transformUserSelection_intoExpectedType(value, _this2.props.isArray);

                    _this2.props.actions.requestNamespaceChangeToNamespace(selectedValue);
                    /*
                    this.props.actions.saveLocalStateForActiveNamespace();
                    this.props.actions.setFieldValueByKey(
                        this._transformUserSelection_intoExpectedType(value, this.props.isArray),
                        this.props.fieldKey
                    );
                    this.props.actions.setLocalStateForActiveNamespace();
                    */
                },
                value: value,
                disabled: disabled
            }, disabled ? { open: false } : {}, (_extends6 = {
                'data-codecept-selector-node': 'SelectField',
                'data-codecept-selector-file': 'Dropdown'
            }, _defineProperty(_extends6, 'data-codecept-selector-node', 'SelectField'), _defineProperty(_extends6, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_extends6, 'data-codecept-selector-node', 'SelectField'), _defineProperty(_extends6, 'data-codecept-selector-file', 'Dropdown'), _extends6)),
            self.menuItems()
        );
    },
    render: function render() {
        var _React$createElement5;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var leftIcon = this.props.leftIcon ? _react2.default.cloneElement(this.props.leftIcon, {
            defaultNamespace: this.props.defaultNamespace,
            value: this.props.value
        }) : null;

        var isMultiSelect = Number.isInteger(this.props.limit) && this.props.limit > 1;

        var dropdownComponent = isMultiSelect ? this._renderMultiSelect() : this._renderSingleSelect();

        var wrappedDropdownComponent = _react2.default.createElement(
            'div',
            (_React$createElement5 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Dropdown'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Dropdown'), _React$createElement5),
            leftIcon,
            dropdownComponent
        );
        if (this.props.isControlHidden) {
            var _React$createElement6;

            return _react2.default.createElement(
                'div',
                (_React$createElement6 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Dropdown'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Dropdown'), _React$createElement6),
                this.props.children
            );
        } else if (this.props.excludeWrapper) {
            var _React$createElement7;

            return _react2.default.createElement(
                'div',
                (_React$createElement7 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Dropdown'
                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Dropdown'), _React$createElement7),
                wrappedDropdownComponent,
                this.props.children
            );
        } else {
            var _React$createElement8;

            return _react2.default.createElement(
                'div',
                (_React$createElement8 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Dropdown'
                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Dropdown'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Dropdown'), _React$createElement8),
                this.props.renderGridWrapper(_extends({}, this.props, { children: wrappedDropdownComponent })),
                this.props.children
            );
        }
    }
});

exports.default = Dropdown;