'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _ForgotPassword = require('./ForgotPassword');

var _ForgotPassword2 = _interopRequireDefault(_ForgotPassword);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ReactDOM = require('react-dom');

var routeParser = require('route-parser');

var PropTypes = _react2.default.PropTypes;

module.exports = function editGenerator() {
    var selfModule = this;
    var selfProps = this.props;

    //# Dependencies
    var dependencies = selfModule.getDependencies();
    var selfSelector = dependencies.selfSelector,
        selfActions = dependencies.selfActions,
        feSettingsSelector = dependencies.feSettingsSelector;

    //# Constants

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);
        var feSettings = feSettingsSelector(state);

        // if next page is index, fall back to default
        var nextPage = selfModule.props.nextPage;

        var mappedProps = _extends({}, selfProps, selfState, {
            clientName: feSettings.CLIENT_NAME,
            rubyBranding: feSettings.RUBY_BRANDING,
            rubyContributors: feSettings.RUBY_CONTRIBUTORS,
            nextPage: nextPage,
            feSettings: feSettings
        });
        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfActions, dispatch)
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_ForgotPassword2.default);
};