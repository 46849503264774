'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var defaultPadding = 10;
var paddingFactor = 20;

function _indentPaddingFromProps(props) {

    var initialPadding = defaultPadding;

    var columnProperty = props.columnProperties[props.id];
    var shouldIndent = props.id == 'name' || columnProperty.shouldIndent;
    //# migt want to phase out the check for id

    var offset = shouldIndent ? (props.rowData.__metadata.depth || 0) * paddingFactor + initialPadding : defaultPadding;
    return {
        marginLeft: offset,
        marginRight: -offset,
        paddingRight: props.id == 'name' ? offset : undefined,
        boxSizing: 'border-box'
    };
}

exports.default = function (ColumnComponent) {
    return function (_Component) {
        _inherits(_class, _Component);

        function _class() {
            _classCallCheck(this, _class);

            return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
        }

        _createClass(_class, [{
            key: 'render',
            value: function render() {
                var indentPadding = _indentPaddingFromProps(this.props);
                var columnStyle = _extends({}, this.props.styles.inlineStyles.column, indentPadding);
                var inlineStyles = _extends({}, this.props.styles.inlineStyles, { column: columnStyle });
                var styles = _extends({}, this.props.styles, { inlineStyles: inlineStyles });

                return _react2.default.createElement(ColumnComponent, _extends({}, this.props, { styles: styles, 'data-codecept-selector-node': 'ColumnComponent',
                    'data-codecept-selector-file': 'column'
                }));
            }
        }]);

        return _class;
    }(_react.Component);
};