'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.registerComponentForKey = registerComponentForKey;
var rubyLogger = require('@rubyapps/ruby-logger');
var logger = rubyLogger.getLogger('ruby-component-builder:common:components');

var ActivityList = require('@rubyapps/ruby-component-activities');
var ApiSecret = require('@rubyapps/ruby-component-field-api-secret');
var ArrayMap = require('@rubyapps/ruby-component-field-array-map');
var AutoPopulatedText = require('@rubyapps/ruby-component-field-auto-populated-text');
var Button = require('@rubyapps/ruby-component-field-button');
var CodeEditor = require('@rubyapps/ruby-component-field-code-editor');
var JsonEditor = require('@rubyapps/ruby-component-field-json-editor');
var ContextMenu = require('@rubyapps/ruby-component-field-context-menu');
var ColorPicker = require('@rubyapps/ruby-component-field-color-picker');
var ComponentizedDatePicker = require('@rubyapps/ruby-component-field-componentized-date-picker');
var DashboardAnalytics = require('@rubyapps/ruby-component-plugin-websites/src/local_modules/ruby-component-field-dashboard-analytics');
var DatePicker = require('@rubyapps/ruby-component-field-date-picker');
var DatetimePicker = require('@rubyapps/ruby-component-field-datetime-picker');
var Dialog = require('@rubyapps/ruby-component-field-dialog');
var Dropdown = require('@rubyapps/ruby-component-field-dropdown');
var DynamicForm = require('@rubyapps/ruby-component-field-dynamic-form');
var FieldObject = require('@rubyapps/ruby-component-field-object');
var FieldEditor = require('@rubyapps/ruby-component-field-editor');
var Fieldset = require('@rubyapps/ruby-component-field-fieldset');
var FieldsPicker = require('@rubyapps/ruby-component-field-fields-picker');
var Form = require('@rubyapps/ruby-component-field-form');
var GooglePreview = require('@rubyapps/ruby-component-field-google-preview');
var Header = require('@rubyapps/ruby-component-field-header');
var Hidden = require('@rubyapps/ruby-component-field-hidden');
var ImagePicker = require('@rubyapps/ruby-component-field-image-picker');
var InternationalAddress = require('@rubyapps/ruby-component-field-international-address');
var Info = require('@rubyapps/ruby-component-field-info');
var LinkUrl = require('@rubyapps/ruby-component-field-link-url');
var ExpandedData = require('@rubyapps/ruby-component-field-expanded-data');
var MenuItem = require('@rubyapps/ruby-component-field-menu-item');
var ManipulatorNamespace = require('@rubyapps/ruby-component-field-manipulator-namespace');
var Module = require('@rubyapps/ruby-component-field-module');
var NamespaceSelector = require('@rubyapps/ruby-component-field-namespace-selector');
var FieldNumber = require('@rubyapps/ruby-component-field-number');
var Password = require('@rubyapps/ruby-component-field-password');
var Permissions = require('@rubyapps/ruby-component-field-permissions');
var ProfileIdentity = require('@rubyapps/ruby-component-field-profile-identity');
//const QueryBuilder = require('@rubyapps/ruby-component-field-query-builder');
var RadioButtonGroup = require('@rubyapps/ruby-component-field-radio-button-group');
var Repeater = require('@rubyapps/ruby-component-field-repeater');
var RichTextEditor = require('@rubyapps/ruby-component-field-rich-text-editor');
var ScheduledStatus = require('@rubyapps/ruby-component-field-scheduled-status');
var Sitemap = require('@rubyapps/ruby-component-field-sitemap');
var Tab = require('@rubyapps/ruby-component-field-tab');
var Tabs = require('@rubyapps/ruby-component-field-tabs');
var Text = require('@rubyapps/ruby-component-field-text');
var Textarea = require('@rubyapps/ruby-component-field-textarea');
var TFA = require('@rubyapps/ruby-component-field-TFA');
var TimePicker = require('@rubyapps/ruby-component-field-time-picker');
var Toggle = require('@rubyapps/ruby-component-field-toggle');
var Toggles = require('@rubyapps/ruby-component-field-toggles');
var TokenTagger = require('@rubyapps/ruby-component-field-token-tagger');
var Url = require('@rubyapps/ruby-component-field-url');
var OpenGraph = require('@rubyapps/ruby-component-field-open-graph');
var Popover = require('@rubyapps/ruby-component-field-popover');
var ViewLink = require('@rubyapps/ruby-component-field-view-link');

var Components = {
    ActivityList: ActivityList,
    ApiSecret: ApiSecret,
    ArrayMap: ArrayMap,
    AutoPopulatedText: AutoPopulatedText,
    Button: Button,
    CodeEditor: CodeEditor,
    JsonEditor: JsonEditor,
    ContextMenu: ContextMenu,
    ColorPicker: ColorPicker,
    ComponentizedDatePicker: ComponentizedDatePicker,
    DashboardAnalytics: DashboardAnalytics,
    DatePicker: DatePicker,
    DatetimePicker: DatetimePicker,
    Dialog: Dialog,
    Dropdown: Dropdown,
    DynamicForm: DynamicForm,
    ExpandedData: ExpandedData,
    FieldEditor: FieldEditor,
    Fieldset: Fieldset,
    FieldsPicker: FieldsPicker,
    Form: Form,
    GooglePreview: GooglePreview,
    Header: Header,
    Hidden: Hidden,
    ImagePicker: ImagePicker,
    InternationalAddress: InternationalAddress,
    Info: Info,
    LinkUrl: LinkUrl,
    ManipulatorNamespace: ManipulatorNamespace,
    MenuItem: MenuItem,
    Module: Module,
    NamespaceSelector: NamespaceSelector,
    Number: FieldNumber,
    Object: FieldObject,
    Password: Password,
    Permissions: Permissions,
    ProfileIdentity: ProfileIdentity
    //, QueryBuilder
    , RadioButtonGroup: RadioButtonGroup,
    Repeater: Repeater,
    RichTextEditor: RichTextEditor,
    ScheduledStatus: ScheduledStatus,
    Sitemap: Sitemap,
    Tab: Tab,
    Tabs: Tabs,
    Text: Text,
    Textarea: Textarea,
    TFA: TFA,
    TimePicker: TimePicker,
    Toggle: Toggle,
    Toggles: Toggles,
    TokenTagger: TokenTagger,
    Url: Url,
    OpenGraph: OpenGraph,
    Popover: Popover,
    ViewLink: ViewLink
};

var baseComponents = _extends({}, Components);

exports.default = Components;
function registerComponentForKey(Component, ComponentKey) {
    if (baseComponents.hasOwnProperty(ComponentKey)) {
        logger.info('Overriding ' + ComponentKey + ' with custom Component');
    }

    Components[ComponentKey] = Component;
}