'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Paper = require('material-ui/Paper');

var _Paper2 = _interopRequireDefault(_Paper);

var _Toggle = require('@rubyapps/ruby-material-ui/Toggle');

var _Toggle2 = _interopRequireDefault(_Toggle);

var _colors = require('@rubyapps/ruby-styles/src/client/styleObjects/colors');

var Colors = _interopRequireWildcard(_colors);

var _addInfoModeProps = require('@rubyapps/ruby-react-components/src/client/hoc/addInfoModeProps');

var _addInfoModeProps2 = _interopRequireDefault(_addInfoModeProps);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var fieldBaseStyles = process.browser ? require('@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule').default : {};
var styles = require('./FieldsPicker.cssModule').default;

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label;

var PropTypes = _react2.default.PropTypes;

var FieldsPicker = function (_React$Component) {
    _inherits(FieldsPicker, _React$Component);

    function FieldsPicker(props) {
        _classCallCheck(this, FieldsPicker);

        var _this = _possibleConstructorReturn(this, (FieldsPicker.__proto__ || Object.getPrototypeOf(FieldsPicker)).call(this, props));

        _this.renderNodes = _this.renderNodes.bind(_this);
        _this.onToggle = _this.onToggle.bind(_this);
        return _this;
    }

    _createClass(FieldsPicker, [{
        key: 'renderNodes',
        value: function renderNodes(nodesData) {
            var _this2 = this;

            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            var _props$togglesById = this.props.togglesById,
                togglesById = _props$togglesById === undefined ? {} : _props$togglesById;
            var _options$newSection = options.newSection,
                newSection = _options$newSection === undefined ? false : _options$newSection;


            var Nodes = nodesData.map(function (nodeData) {
                var _extends2, _React$createElement;

                var _nodeData$children = nodeData.children,
                    children = _nodeData$children === undefined ? [] : _nodeData$children,
                    _nodeData$expand = nodeData.expand,
                    expand = _nodeData$expand === undefined ? false : _nodeData$expand;


                var Wrapper = newSection ? SectionWrapper : 'div';

                return _react2.default.createElement(
                    Wrapper,
                    (_React$createElement = { className: styles.nodeWrapper, 'data-codecept-selector-node': 'Wrapper',
                        'data-codecept-selector-file': 'FieldsPicker'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Wrapper'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FieldsPicker'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Wrapper'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FieldsPicker'), _React$createElement),
                    _react2.default.createElement(
                        Node,
                        _extends({}, nodeData, (_extends2 = { togglesById: togglesById, onToggle: _this2.onToggle, 'data-codecept-selector-node': 'Node',
                            'data-codecept-selector-file': 'FieldsPicker'
                        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Node'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FieldsPicker'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Node'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FieldsPicker'), _extends2)),
                        !_.isEmpty(children) ? _this2.renderNodes(children, { newSection: expand }) : null
                    )
                );
            });

            return Nodes;
        }
    }, {
        key: 'onToggle',
        value: function onToggle() {
            var toggleCtx = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            this.props.actions.setTogglesByIdUsingToggleCtx(toggleCtx);
        }
    }, {
        key: 'render',
        value: function render() {
            var _extends3, _React$createElement2, _React$createElement3, _React$createElement4;

            var props = this.props;
            var _props$toggleTree = props.toggleTree,
                toggleTree = _props$toggleTree === undefined ? [] : _props$toggleTree;

            var labelProps = _.pick(this.props, Object.keys(Label.propTypes));

            return !_.isEmpty(toggleTree) && _react2.default.createElement(
                'div',
                (_React$createElement4 = {
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'FieldsPicker'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'FieldsPicker'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'FieldsPicker'), _React$createElement4),
                _react2.default.createElement(
                    'label',
                    (_React$createElement2 = { className: fieldBaseStyles.label, 'data-codecept-selector-node': 'label',
                        'data-codecept-selector-file': 'FieldsPicker'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'FieldsPicker'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'FieldsPicker'), _React$createElement2),
                    _react2.default.createElement(Label, _extends({}, labelProps, (_extends3 = {
                        'data-codecept-selector-node': 'Label',
                        'data-codecept-selector-file': 'FieldsPicker'
                    }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends3, 'data-codecept-selector-file', 'FieldsPicker'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends3, 'data-codecept-selector-file', 'FieldsPicker'), _extends3)))
                ),
                _react2.default.createElement(
                    SectionWrapper,
                    (_React$createElement3 = {
                        'data-codecept-selector-node': 'SectionWrapper',
                        'data-codecept-selector-file': 'FieldsPicker'
                    }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'SectionWrapper'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'FieldsPicker'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'SectionWrapper'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'FieldsPicker'), _React$createElement3),
                    this.renderNodes(toggleTree)
                )
            );
        }
    }]);

    return FieldsPicker;
}(_react2.default.Component);

;

var Node = function Node(props) {
    var _React$createElement5, _React$createElement6, _React$createElement7;

    var children = props.children,
        id = props.id,
        fieldKey = props.fieldKey,
        _onToggle = props.onToggle,
        parentId = props.parentId,
        title = props.title,
        togglesById = props.togglesById;


    var toggleData = togglesById[id];

    return _react2.default.createElement(
        'div',
        (_React$createElement7 = { className: styles.toggleWrapper, 'data-codecept-selector-node': 'div',
            'data-codecept-selector-file': 'FieldsPicker'
        }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'FieldsPicker'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'FieldsPicker'), _React$createElement7),
        _react2.default.createElement(_Toggle2.default, (_React$createElement6 = {
            id: id,
            style: { width: 'auto' },
            labelPosition: 'right',
            labelStyle: !fieldKey && { color: Colors.rubyBlue, fontWeight: 'bold' },
            toggled: toggleData.toggled ? true : false,
            label: _react2.default.createElement(Label, (_React$createElement5 = { label: title, 'data-codecept-selector-node': 'Label',
                'data-codecept-selector-file': 'FieldsPicker'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'FieldsPicker'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Label'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'FieldsPicker'), _React$createElement5)),
            onToggle: function onToggle(ev, isToggled) {
                return _onToggle({ toggledId: id, isToggled: isToggled, parentId: parentId });
            },
            'data-codecept-selector-node': 'Toggle',
            'data-codecept-selector-file': 'FieldsPicker'
        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'FieldsPicker'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'FieldsPicker'), _React$createElement6)),
        children
    );
};

var SectionWrapper = function SectionWrapper(_ref) {
    var _React$createElement8;

    var children = _ref.children,
        className = _ref.className;
    return _react2.default.createElement(
        _Paper2.default,
        (_React$createElement8 = { className: (0, _classnames2.default)(className, styles.paper), 'data-codecept-selector-node': 'Paper',
            'data-codecept-selector-file': 'FieldsPicker'
        }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Paper'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'FieldsPicker'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Paper'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'FieldsPicker'), _React$createElement8),
        children
    );
};

exports.default = (0, _addInfoModeProps2.default)(FieldsPicker);