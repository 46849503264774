'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var request = require('@rubyapps/ruby-superagent');

var urljoin = require('url-join');

var TYPES = {
    RETRIEVE_LOGGED_IN_USER: '@@ruby-app/currentUser/RETRIEVE_LOGGED_IN_USER',
    SET_LOGGED_IN_USER: '@@ruby-app/currentUser/SET_LOGGED_IN_USER',
    CLEAR_LOGGED_IN_USER: '@@ruby-app/currentUser/CLEAR_LOGGED_IN_USER'
};

var CURRENT_USER_INFO_URL = '/ruby/api/v2/users/me';
var CURRENT_USER_INFO_ENDPOINT = '/users/me';
var CURRENT_USER_LOGIN_ENDPOINT = '/users/login';
var CURRENT_USER_LOGOUT_ENDPOINT = '/users/logout';

function _userInfoIsEqual() {
    var userInfoA = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var userInfoB = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var preppedUserInfoA = _extends({}, _lodash2.default.omit(userInfoA, ['created_timestamp']), {
        rubyRole: _lodash2.default.omit(_lodash2.default.get(userInfoA, 'rubyRole', {}), ['created_timestamp'])
    });

    var preppedUserInfoB = _extends({}, _lodash2.default.omit(userInfoB, ['created_timestamp']), {
        rubyRole: _lodash2.default.omit(_lodash2.default.get(userInfoB, 'rubyRole', {}), ['created_timestamp'])
    });

    return _lodash2.default.isEqual(preppedUserInfoA, preppedUserInfoB);
}

var generators = {

    pollLoggedInUser: function pollLoggedInUser() {
        var selfModule = this;
        var selfActions = selfModule.getAction().generators;
        var PING_INTERVAL = 1000 * 60 * 1; //# ms * sec * min; //# every 1 minutes

        // since we have retry logic in ruby-superagent, we would only attempt to 
        // retrieve the logged-in user again once the previous request had returned.
        return function (dispatch, getState) {
            dispatch(selfActions.retrieveLoggedInUser()).then(function () {
                setTimeout(function () {
                    dispatch(selfActions.pollLoggedInUser());
                }, PING_INTERVAL);
            });
        };
    },
    retrieveLoggedInUser: function retrieveLoggedInUser() {
        var self = this;

        var _getDependencies = this.getDependencies(),
            selfActions = _getDependencies.selfActions,
            selfSelector = _getDependencies.selfSelector,
            feSettingsSelector = _getDependencies.feSettingsSelector;

        return function (dispatch, getState) {
            var applicationState = getState();
            var selfState = selfSelector(applicationState);
            var apiUrlPrefix = _lodash2.default.get(feSettingsSelector(applicationState), 'restApiRoot');

            var currentPath = _lodash2.default.get(applicationState, ['routing', 'locationBeforeTransitions', 'pathname']);

            var endpoint = urljoin(apiUrlPrefix, CURRENT_USER_INFO_ENDPOINT);
            return request.get(endpoint).then(function success(response) {
                var responseData = _lodash2.default.get(response, 'body.data');

                if (responseData && !_userInfoIsEqual(responseData, selfState)) {
                    dispatch(generators.setLoggedInUser(responseData));
                }
            }, function error(err) {
                var responseStatus = _lodash2.default.get(err, 'response.status');
                //# don't show error, just redirect if necessary
                if (responseStatus && responseStatus != 401) {
                    //# only show the error for non-auth related errors
                    //self.showErrorNotification({ error: err });
                } else if (currentPath != '/' && currentPath.indexOf('login') < 0 && currentPath.indexOf('reset-password') < 0) {
                    //# navigate to /login
                    var targetPath = '/login.html?next_page=' + encodeURIComponent(currentPath);
                    window.location = targetPath;
                }
            });
        };
    },
    setLoggedInUser: function setLoggedInUser(userInfo) {
        return {
            type: TYPES.SET_LOGGED_IN_USER,
            payload: {
                userInfo: userInfo

            }
        };
    },
    clearLoggedInUser: function clearLoggedInUser() {
        return {
            type: TYPES.CLEAR_LOGGED_IN_USER
        };
    },
    logIn: function logIn(credentials, thenableArguments) {
        var self = this;

        var _getDependencies2 = this.getDependencies(),
            selfActions = _getDependencies2.selfActions,
            feSettingsSelector = _getDependencies2.feSettingsSelector;

        return function (dispatch, getState) {
            var applicationState = getState();
            var apiUrlPrefix = _lodash2.default.get(feSettingsSelector(applicationState), 'restApiRoot');

            var endpoint = urljoin(apiUrlPrefix, CURRENT_USER_LOGIN_ENDPOINT);

            var loginRequestPromise = request.post(endpoint).send(credentials).then(function success(response) {
                //# response contains the accessToken
                dispatch(selfActions.retrieveLoggedInUser());
            });

            if (thenableArguments) {
                loginRequestPromise.then.apply(loginRequestPromise, thenableArguments);
            }

            return loginRequestPromise;
        };
    },
    logOut: function logOut() {
        var self = this;

        var _getDependencies3 = this.getDependencies(),
            selfActions = _getDependencies3.selfActions,
            feSettingsSelector = _getDependencies3.feSettingsSelector;

        var rootModule = this.getRoot();
        var loginPageComponent = rootModule.findDescendentBy(function (element) {
            return element.componentName == 'rubyComponentLoginPage';
        });
        var loginRoute = loginPageComponent.getRoutePath();

        return function (dispatch, getState) {
            var applicationState = getState();
            var apiUrlPrefix = _lodash2.default.get(feSettingsSelector(applicationState), 'restApiRoot');

            var endpoint = urljoin(apiUrlPrefix, CURRENT_USER_LOGOUT_ENDPOINT);

            return request.post(endpoint).then(function success(response) {
                window.location.href = loginRoute; //# a hard reroute
                /*
                dispatch(generators.clearLoggedInUser()); //# don't need to clear user unless we dynamically reroute the user
                dispatch(selfActions.navigateToPathWithOptions({
                    path: loginRoute
                }));
                */
            }, function error(err) {
                //# we can still clear out logged in user and go to login page 
                window.location.href = loginRoute; //# a hard reroute
                /*
                dispatch(generators.clearLoggedInUser()); //# don't need to clear user unless we dynamically reroute the user
                dispatch(selfActions.navigateToPathWithOptions({
                    path: loginRoute
                }));
                */
            });
        };
    },
    clearLocalPreferences: function clearLocalPreferences() {
        return function (dispatch) {
            localStorage.clear();
        };
    }
};

module.exports = {
    TYPES: TYPES,
    generators: generators
};