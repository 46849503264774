'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Sticky = require('@rubyapps/ruby-react-components/src/client/layout/Sticky');

var _Sticky2 = _interopRequireDefault(_Sticky);

var _Toolbar = require('material-ui/Toolbar');

var _header = require('@rubyapps/griddle/local_modules/griddle-render/src/header.cssModule');

var _header2 = _interopRequireDefault(_header);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

//# styles


var GriddleHeader = function (_React$Component) {
    _inherits(GriddleHeader, _React$Component);

    function GriddleHeader() {
        _classCallCheck(this, GriddleHeader);

        return _possibleConstructorReturn(this, (GriddleHeader.__proto__ || Object.getPrototypeOf(GriddleHeader)).call(this));
    }

    _createClass(GriddleHeader, [{
        key: 'render',
        value: function render() {
            var _React$createElement,
                _this2 = this,
                _React$createElement3;

            var inlineStyle_Toolbar = _lodash2.default.get(this.props, 'inlineStyles.Toolbar', {});

            return _react2.default.createElement(
                _Sticky2.default,
                (_React$createElement3 = {
                    'data-codecept-selector-node': 'Sticky',
                    'data-codecept-selector-file': 'header'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Sticky'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'header'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Sticky'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'header'), _React$createElement3),
                _react2.default.createElement(
                    _Toolbar.Toolbar,
                    (_React$createElement = { className: _header2.default.Toolbar, style: inlineStyle_Toolbar, 'data-codecept-selector-node': 'Toolbar',
                        'data-codecept-selector-file': 'header'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Toolbar'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'header'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Toolbar'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'header'), _React$createElement),
                    this.props.utilityBarElement
                ),
                function () {
                    if (_this2.props.settingsElement) {
                        var _React$createElement2;

                        return _react2.default.createElement(
                            _Toolbar.Toolbar,
                            (_React$createElement2 = { className: _header2.default.Toolbar, style: inlineStyle_Toolbar, 'data-codecept-selector-node': 'Toolbar',
                                'data-codecept-selector-file': 'header'
                            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Toolbar'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'header'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Toolbar'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'header'), _React$createElement2),
                            _this2.props.settingsToggleElement,
                            _this2.props.settingsElement
                        );
                    }
                }(),
                this.props.children
            );
        }
    }]);

    return GriddleHeader;
}(_react2.default.Component);

exports.default = GriddleHeader;