'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var path = require('path');
var CONSTANTS = require('../../common/constants');
var TYPES = CONSTANTS.ROUTE_ACTION_TYPES;

function routeParamsReducer(state, newRouteParams) {
    return _extends({}, state, {
        routeParams: _extends({}, state.routeParams, newRouteParams)
    });
}

//# TODO: need to update to nest all state mutations to be under 
//# 'routeParams' since we're no longer returning the fake
//# reducerByKey, which used to return this reduce at 'routeParams'
var defaultState = { routeActive: false };
module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];

    var selfModule = this;
    var selfID = selfModule.getID();
    var TYPES = selfModule.getAction().TYPES;

    switch (action.type) {
        case TYPES.SET_ROUTE_PARAMS:
            return routeParamsReducer(state, _extends({}, action.payload, { routeActive: true }));

        case TYPES.SET_HASH:
            return routeParamsReducer(state, {
                hash: action.payload.hash
            });

        case TYPES.LEAVING_ROUTE:
            return routeParamsReducer(state, {
                routeActive: false,
                hash: null
            });

        default:
            return state;
    }
};