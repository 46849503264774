'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _reduxBatchedActions = require('redux-batched-actions');

var _constants = require('../../common/constants');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');

var request = require('@rubyapps/ruby-superagent');
var urljoin = require('url-join');
var url = require('url');

var POLL_INTERVAL = 5000;

function typesWithID(id) {
    return {
        SET_ACTIVITIES_ENDPOINT: '@@ruby-app/' + id + '/SET_ACTIVITIES_ENDPOINT',
        SET_ACTIVITIES_LIST_ENDPOINT: '@@ruby-app/' + id + '/SET_ACTIVITIES_LIST_ENDPOINT',
        RETRIEVE_ACTIVITY_LIST: '@@ruby-app/' + id + '/RETRIEVE_ACTIVITY_LIST',
        SET_ACTIVITY_LIST: '@@ruby-app/' + id + '/SET_ACTIVITY_LIST',

        SET_ACTIVITIES_BY_ID_TO_POLL: '@@ruby-app/' + id + '/SET_ACTIVITIES_BY_ID_TO_POLL',
        CLEAR_ACTIVITY_ID_FROM_POLL: '@@ruby-app/' + id + '/CLEAR_ACTIVITY_ID_FROM_POLL',
        SET_POLL_INTERVAL_ID: '@@ruby-app/' + id + '/SET_POLL_INTERVAL_ID',

        SET_ACTIVITY_OBJECT: '@@ruby-app/' + id + '/SET_ACTIVITY_OBJECT',
        UPDATE_ACTIVITY_OBJECT: '@@ruby-app/' + id + '/UPDATE_ACTIVITY_OBJECT',
        TOGGLE_SHOW_ALL: '@@ruby-app/' + id + '/TOGGLE_SHOW_ALL',
        EDIT_NEW_COMMENT: '@@ruby-app/' + id + '/EDIT_NEW_COMMENT',
        EDIT_ACTIVITY_COMMENT: '@@ruby-app/' + id + '/EDIT_ACTIVITY_COMMENT',
        SAVE_ACTIVITY_COMMENT: '@@ruby-app/' + id + '/SAVE_ACTIVITY_COMMENT',
        DELETE_ACTIVITY_COMMENT: '@@ruby-app/' + id + '/DELETE_ACTIVITY_COMMENT',
        CLEAR_EDITS: '@@ruby-app/' + id + '/CLEAR_EDITS',
        RESET_SHOW_ALL: '@@ruby-app/' + id + '/RESET_SHOW_ALL',
        RESET_STORE: '@@ruby-app/' + id + '/RESET_STORE'
    };
}

var generators = {
    setActivitiesEndpoint: function setActivitiesEndpoint(endpoint) {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        return {
            type: TYPES.SET_ACTIVITIES_ENDPOINT,
            payload: { endpoint: endpoint }
        };
    },
    setActivitiesListEndpoint: function setActivitiesListEndpoint(endpoint) {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES;

        return {
            type: TYPES.SET_ACTIVITIES_LIST_ENDPOINT,
            payload: { endpoint: endpoint }
        };
    },
    refreshActivitiesList: function refreshActivitiesList() {
        var _getAction3 = this.getAction(),
            TYPES = _getAction3.TYPES,
            generators = _getAction3.generators;

        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector;

        return function (dispatch, getState) {
            var applicationState = getState();
            var selfState = selfSelector(applicationState);
            var activitiesListEndpoint = selfState.activitiesListEndpoint;

            if (activitiesListEndpoint) {
                dispatch(generators.retrieveActivities_withEndpoint(activitiesListEndpoint));
            }
        };
    },
    retrieveActivities_withEndpoint: function retrieveActivities_withEndpoint(endpoint) {
        var self = this;
        var url = this.props.url;

        var _getAction4 = this.getAction(),
            TYPES = _getAction4.TYPES,
            generators = _getAction4.generators;

        var _getDependencies2 = this.getDependencies(),
            selfSelector = _getDependencies2.selfSelector,
            frontendSettingsSelector = _getDependencies2.frontendSettingsSelector;

        return function (dispatch, getState) {
            var applicationState = getState();
            var selfState = selfSelector(applicationState);

            var _frontendSettingsSele = frontendSettingsSelector(applicationState),
                restApiRoot = _frontendSettingsSele.restApiRoot;

            var pollIntervalId = selfState.pollIntervalId;


            return request.get(endpoint).query({
                filter: JSON.stringify({
                    "order": ["created_timestamp DESC"]
                })
            }).preventDuplicateCalls(false).then(function success(response) {
                var data = response.body.data;
                //# check if any of the ids need to be polled

                //# convert data.details to object if needed
                var hydratedData = data.map(function (datum) {
                    if (_lodash2.default.isString(datum.details)) {
                        var parsedJSON = {};
                        try {
                            parsedJSON = JSON.parse(datum.details);
                        } catch (err) {}

                        return _extends({}, datum, {
                            details: parsedJSON
                        });
                    }
                    return datum;
                });

                var activitiesByIdToPoll = self.getActivitiesByIdToPoll(hydratedData);
                var activityIdsToPoll = Object.keys(activitiesByIdToPoll);

                var actionsToBatch = [generators.setActivityList(hydratedData)];
                if (activityIdsToPoll.length) {
                    actionsToBatch.push(generators.setActivitiesByIdToPoll(activitiesByIdToPoll));

                    //# cancel previous poll invertal
                    if (_pollIntervalId) {
                        clearInterval(_pollIntervalId);
                    }

                    //# start polling
                    var _pollIntervalId = setInterval(function () {
                        //# hit the individual endpoints
                        dispatch(generators.pollActivities());
                    }, POLL_INTERVAL);
                    actionsToBatch.push(generators.setPollIntervalId(_pollIntervalId));
                }

                var batchedActions = (0, _reduxBatchedActions.batchActions)(actionsToBatch);
                dispatch(batchedActions);
            }, function error(err) {
                self.showErrorNotification({ error: err });
            });
        };
    },
    setActivityList: function setActivityList(activities) {
        var _getAction5 = this.getAction(),
            TYPES = _getAction5.TYPES;

        return {
            type: TYPES.SET_ACTIVITY_LIST,
            payload: {
                activities: activities
            }
        };
    },
    setActivitiesByIdToPoll: function setActivitiesByIdToPoll(activitiesByIdToPoll) {
        var _getAction6 = this.getAction(),
            TYPES = _getAction6.TYPES;

        return {
            type: TYPES.SET_ACTIVITIES_BY_ID_TO_POLL,
            payload: {
                activitiesByIdToPoll: activitiesByIdToPoll
            }
        };
    },
    clearActivityIdFromPoll: function clearActivityIdFromPoll(activityId) {
        var _getAction7 = this.getAction(),
            TYPES = _getAction7.TYPES;

        return {
            type: TYPES.CLEAR_ACTIVITY_ID_FROM_POLL,
            payload: {
                activityId: activityId
            }
        };
    },
    setPollIntervalId: function setPollIntervalId(pollIntervalId) {
        var _getAction8 = this.getAction(),
            TYPES = _getAction8.TYPES;

        return {
            type: TYPES.SET_POLL_INTERVAL_ID,
            payload: {
                pollIntervalId: pollIntervalId
            }
        };
    },

    pollActivity: function pollActivity(activityId) {
        var selfModule = this;

        var _getAction9 = this.getAction(),
            TYPES = _getAction9.TYPES,
            actions = _getAction9.generators;

        var activityEndpointRoute = this.props.activityEndpointRoute;

        var _getDependencies3 = this.getDependencies(),
            frontendSettingsSelector = _getDependencies3.frontendSettingsSelector;

        return function (dispatch, getState) {
            var applicationState = getState();

            var _frontendSettingsSele2 = frontendSettingsSelector(applicationState),
                restApiRoot = _frontendSettingsSele2.restApiRoot;

            var endpoint = urljoin(restApiRoot, activityEndpointRoute.reverse({ id: activityId }));

            //# hit endpoint for activityId
            return request.get(endpoint).then(function success(response) {
                var data = response.body;
                //# hydrate the activity response.body.data.detail
                var hydratedData = void 0;
                var dataDetails = _lodash2.default.get(data, 'details');
                if (_lodash2.default.isString(dataDetails)) {
                    var parsedJSON = {};
                    try {
                        parsedJSON = JSON.parse(dataDetails);
                    } catch (err) {}

                    hydratedData = _extends({}, datum, {
                        details: parsedJSON
                    });
                } else {
                    hydratedData = data;
                }

                if (!selfModule.shouldPollActivity(hydratedData)) {
                    var actionsToBatch = [actions.updateActivityObject(hydratedData), actions.clearActivityIdFromPoll(activityId)];

                    var batchedActions = (0, _reduxBatchedActions.batchActions)(actionsToBatch);
                    dispatch(batchedActions);
                }
            });
        };
    },
    pollActivities: function pollActivities() {
        var _getAction10 = this.getAction(),
            TYPES = _getAction10.TYPES,
            actions = _getAction10.generators;

        var selfState = this.getState();
        var activitiesByIdToPoll = selfState.activitiesByIdToPoll,
            pollIntervalId = selfState.pollIntervalId;


        var activityIdsToPoll = Object.keys(activitiesByIdToPoll);

        return function (dispatch, getState) {
            if (!activityIdsToPoll.length) {
                //# if there are no more activitiesByIdToPoll, clear the pollIntervalId
                clearInterval(pollIntervalId);
                return dispatch(actions.setPollIntervalId(null));
            } else {
                //# else
                //#     hit endpoints for each of the activities we still need to poll
                //#     on setActivityObject(), we should also clear it from the activitiesByIdToPoll
                _lodash2.default.each(activitiesByIdToPoll, function (_value, activityId) {
                    dispatch(actions.pollActivity(activityId));
                });
            }
        };
    },
    setActivityObject: function setActivityObject(activity) {
        var _getAction11 = this.getAction(),
            TYPES = _getAction11.TYPES;

        return {
            type: TYPES.SET_ACTIVITY_OBJECT,
            payload: {
                activity: activity
            }
        };
    }
    // as opposed setActivityObject
    // updateActivityObject will merge the data
    // received from polling (user data is not included
    // in this response - keep the user info we
    // already have)
    //
    // avoid creation of another remote
    // avoid using filters in the query string
    , updateActivityObject: function updateActivityObject(activity) {
        var _getAction12 = this.getAction(),
            TYPES = _getAction12.TYPES;

        return {
            type: TYPES.UPDATE_ACTIVITY_OBJECT,
            payload: {
                activity: activity
            }
        };
    },
    resetShowAll: function resetShowAll() {
        var _getAction13 = this.getAction(),
            TYPES = _getAction13.TYPES;

        return {
            type: TYPES.RESET_SHOW_ALL
        };
    },
    toggleShowAll: function toggleShowAll() {
        var _getAction14 = this.getAction(),
            TYPES = _getAction14.TYPES;

        return {
            type: TYPES.TOGGLE_SHOW_ALL
        };
    },
    updateNewComment: function updateNewComment(comment) {
        var _getAction15 = this.getAction(),
            TYPES = _getAction15.TYPES;

        return {
            type: TYPES.EDIT_NEW_COMMENT,
            payload: {
                comment: comment
            }
        };
    },
    saveNewComment: function saveNewComment(newComment) {
        var _this = this;

        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var _getAction16 = this.getAction(),
            TYPES = _getAction16.TYPES,
            generators = _getAction16.generators;

        var _getDependencies4 = this.getDependencies(),
            selfSelector = _getDependencies4.selfSelector,
            editPageSelector = _getDependencies4.editPageSelector;

        var successNotifier = options.successNotifier,
            _options$additionalPa = options.additionalPayload,
            additionalPayload = _options$additionalPa === undefined ? {} : _options$additionalPa;


        return function (dispatch, getState) {
            var applicationState = getState();
            var selfState = selfSelector(applicationState);
            var editPageState = editPageSelector(applicationState);
            var activitiesEndpoint = selfState.activitiesEndpoint;

            var activities = _this.utils.getActivitiesFromSelfState(selfState);
            var approvalId = _lodash2.default.get(editPageState, 'contentApprovalData.id'); //# NOTE: Deprecating 20200911 in favor of drafts
            //# however, for now, we'll need to keep it around
            //# IDEA: add an autopopulated textfield that grabs the draft id from the edit page data (the draft id will exist)

            //const basisVersion = this.utils.getBasisVersionFromActivityList(activities);
            //const versionNumber = basisVersion.versionNumber;

            // TODO: dispatch something before everything
            // to indicate to the user that their action
            // was registered... loader?

            return request.post(activitiesEndpoint).send(_extends({ comment: newComment, approval_id: approvalId }, additionalPayload)) //# for backwards compat
            .then(function (response) {
                var versionNumber = _lodash2.default.get(response, 'body.version_num');

                dispatch(generators.refreshActivitiesList());
                dispatch(generators.updateNewComment(''));

                if (successNotifier) {
                    successNotifier(response);
                } else {
                    _this.pushNotification({
                        type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS,
                        message: versionNumber ? 'Your comment on Revision ' + versionNumber + ' was added' : 'Your comment was added'
                    });
                }
            }, function (err) {
                _this.showErrorNotification({ err: err });
            });
        };
    },
    saveNewCommentAfterSavingContent: function saveNewCommentAfterSavingContent(newComment) {
        var self = this;

        var _getAction17 = this.getAction(),
            TYPES = _getAction17.TYPES,
            generators = _getAction17.generators;

        var _getDependencies5 = this.getDependencies(),
            selfSelector = _getDependencies5.selfSelector,
            parentForm = _getDependencies5.parentForm;

        return function (dispatch, getState) {
            var successCallback = function successCallback(formSaveResponse) {
                var successNotifier = function successNotifier(response) {
                    var versionNumber = _lodash2.default.get(response, 'body.version_num');
                    self.pushNotification({
                        type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS,
                        message: 'Your changes were saved with your comment'
                    });
                };

                // TODO: dispatch something before everything
                // to indicate to the user that their action
                // was registered... loader?

                // an op hook after page save creates the revision activity
                // postgres - we are guaranteed it has persisted and is available
                // let's update it
                dispatch(generators.saveActivityComment(_constants2.default.LATEST_REVISION_ACTIVITY, newComment, { successNotifier: successNotifier }));

                // clear the new comment
                dispatch(generators.updateNewComment(''));
            };
            dispatch(parentForm.getAction().generators.saveStateToRemote({ successCallback: successCallback }));
        };
    },
    editActivityComment: function editActivityComment(activityId, comment) {
        var _getAction18 = this.getAction(),
            TYPES = _getAction18.TYPES;

        return {
            type: TYPES.EDIT_ACTIVITY_COMMENT,
            payload: {
                activityId: activityId,
                comment: comment
            }
        };
    },
    clearEdits: function clearEdits() {
        var _getAction19 = this.getAction(),
            TYPES = _getAction19.TYPES;

        return {
            type: TYPES.CLEAR_EDITS
        };
    },
    resetStore: function resetStore() {
        var _getAction20 = this.getAction(),
            TYPES = _getAction20.TYPES;

        return {
            type: TYPES.RESET_STORE
        };
    },
    saveActivityComment: function saveActivityComment(id, comment) {
        var _this2 = this;

        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        var _getAction21 = this.getAction(),
            TYPES = _getAction21.TYPES,
            generators = _getAction21.generators;

        var _getDependencies6 = this.getDependencies(),
            selfSelector = _getDependencies6.selfSelector;

        var successNotifier = options.successNotifier;


        return function (dispatch, getState) {
            var applicationState = getState();
            var selfState = selfSelector(applicationState);
            var activities = selfState.activities,
                activitiesEndpoint = selfState.activitiesEndpoint;

            // TODO: dispatch something before everything
            // to indicate to the user that their action
            // was registered... loader?

            return request.post(activitiesEndpoint).send({ id: id, comment: comment }).then(function (response) {
                var updatedActivity = response.body;
                var versionNumber = updatedActivity.version_num;

                dispatch(generators.refreshActivitiesList());

                dispatch(generators.clearEdits());

                if (successNotifier) {
                    successNotifier(response);
                } else {
                    _this2.pushNotification({
                        type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS,
                        message: versionNumber ? 'Your comment on Revision ' + versionNumber + ' was updated' : 'Your comment was updated'
                    });
                }
            }, function (err) {
                if (err.status === 403) {
                    var author = _lodash2.default.get(err, 'response.body.error.details.author', 'another user');
                    _this2.showNotificationModal({
                        title: 'Forbidden',
                        message: 'You may not modify the comments of ' + author + '.'
                    });
                } else {
                    _this2.showErrorNotification({ err: err });
                }
            });
        };
    },
    deleteCommentActivity: function deleteCommentActivity(id) {
        var _this3 = this;

        var _getAction22 = this.getAction(),
            TYPES = _getAction22.TYPES,
            generators = _getAction22.generators;

        var _getDependencies7 = this.getDependencies(),
            selfSelector = _getDependencies7.selfSelector,
            editPageSelector = _getDependencies7.editPageSelector;

        return function (dispatch, getState) {
            var applicationState = getState();
            var selfState = selfSelector(applicationState);
            var activitiesEndpoint = selfState.activitiesEndpoint;

            var activities = _this3.utils.getActivitiesFromSelfState(selfState);

            var status = 'deleted';

            // TODO: dispatch something before everything
            // to indicate to the user that their action
            // was registered... loader?

            return request.post(activitiesEndpoint).send({ id: id, status: status }).then(function (response) {
                var updatedActivity = response.body;
                var versionNumber = updatedActivity.version_num;

                // filter out the activity in the list of activities
                var updatedActivities = activities.filter(function (activity) {
                    return activity.id !== id;
                });

                dispatch(generators.setActivityList(updatedActivities));
                dispatch(generators.clearEdits());

                _this3.pushNotification({
                    type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS,
                    message: versionNumber ? 'Your comment on Revision ' + versionNumber + ' was deleted' : 'Your comment was deleted'
                });
            }, function (err) {
                if (err.status === 403) {
                    var author = _lodash2.default.get(err, 'response.body.error.details.author', 'another user');
                    _this3.showNotificationModal({
                        title: 'Forbidden',
                        message: 'You may not delete the comments of ' + author + '.'
                    });
                } else {
                    _this3.showErrorNotification({ err: err });
                }
            });
        };
    },
    previewActivityVersion: function previewActivityVersion(activity) {
        var _this4 = this;

        var _getDependencies8 = this.getDependencies(),
            selfSelector = _getDependencies8.selfSelector,
            editPageSelector = _getDependencies8.editPageSelector,
            contentCompare = _getDependencies8.contentCompare;

        var versionNumber = activity.versionNumber,
            versionDataId = activity.versionDataId;


        return function (dispatch, getState) {
            var applicationState = getState();

            var editPageState = editPageSelector(applicationState);
            var editPageEndpoint = editPageState.instanceDataEndpoint;
            var _editPageState$routeP = editPageState.routeParams,
                id = _editPageState$routeP.id,
                templateKey = _editPageState$routeP.template;


            var selfState = selfSelector(applicationState);
            var activities = _this4.utils.getActivitiesFromSelfState(selfState);

            var basisVersion = _this4.utils.getBasisVersionFromActivityList(activities);
            var basisVersionDataId = basisVersion.versionDataId;

            var parsedEditPageEndpoint = url.parse(editPageEndpoint);

            var compareURL = urljoin(parsedEditPageEndpoint.pathname, '/compare-versions', '?versionDataId=' + versionDataId + '&basisVersionDataId=' + basisVersionDataId + '&' + parsedEditPageEndpoint.query);

            dispatch(contentCompare.getAction().generators.retrieveReviewData_thenOpen_usingOptions({
                compareURL: compareURL,
                id: id,
                templateKey: templateKey,
                labels: {
                    result: 'Revision ' + versionNumber
                }
            }));
        };
    },
    revertToVersion: function revertToVersion(versionNumber, versionDataId) {
        var _this5 = this;

        var _getDependencies9 = this.getDependencies(),
            selfSelector = _getDependencies9.selfSelector,
            editPage = _getDependencies9.editPage,
            frontendSettingsSelector = _getDependencies9.frontendSettingsSelector,
            editPageSelector = _getDependencies9.editPageSelector;

        return function (dispatch, getState) {
            var applicationState = getState();

            var frontendSettings = frontendSettingsSelector(applicationState);
            var editPageState = editPageSelector(applicationState);

            var retrieveFormDataAtEndpoint_thenHydrate = editPage.getAction().generators.retrieveFormDataAtEndpoint_thenHydrate;


            var revertEndpoint = urljoin(frontendSettings.restApiRoot, 'version_data', versionDataId);
            var revertOptions = {
                responseDataExtractor: function responseDataExtractor(response) {
                    return JSON.parse(response.body.raw_data);
                },
                successHandler: function successHandler() {
                    _this5.pushNotification({
                        type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.INFO,
                        message: 'Reverted content to Revision ' + versionNumber
                    });
                },
                errorHandler: function errorHandler(error) {
                    _this5.showErrorNotification({ error: error });
                },
                shouldSetPristineFormData: false
            };

            // TODO: dispatch something before everything
            // to indicate to the user that their action
            // was registered... loader?

            dispatch(retrieveFormDataAtEndpoint_thenHydrate(revertEndpoint, revertOptions));
        };
    }
};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};