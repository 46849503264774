'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

//# TODO: asyncify

var FieldMenuItem = _react2.default.createClass({
    displayName: 'FieldMenuItem',

    propTypes: _extends({
        actions: PropTypes.object,
        delegateActions: PropTypes.object,
        label: PropTypes.string,
        iconClass: PropTypes.func,
        disabled: PropTypes.bool
    }, _MenuItem2.default.propTypes),
    render: function render() {
        var _React$createElement,
            _this = this,
            _extends2;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var menuItemProps = _lodash2.default.pick(this.props, Object.keys(_MenuItem2.default.propTypes));
        return _react2.default.createElement(_MenuItem2.default, _extends({}, menuItemProps, (_extends2 = {
            primaryText: this.props.label,
            leftIcon: this.props.iconClass ? _react2.default.createElement(this.props.iconClass, (_React$createElement = {
                'data-codecept-selector-file': 'MenuItem'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-file', 'MenuItem'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'MenuItem'), _React$createElement)) : null,
            onTouchTap: function onTouchTap(evt) {
                _this.props.delegateActions.actionForMenuItem(evt);
                menuItemProps.onTouchTap && menuItemProps.onTouchTap(evt);
            },
            'data-codecept-selector-node': 'MenuItem',
            'data-codecept-selector-file': 'MenuItem'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends2, 'data-codecept-selector-file', 'MenuItem'), _defineProperty(_extends2, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_extends2, 'data-codecept-selector-file', 'MenuItem'), _extends2)));
    }
});
exports.default = FieldMenuItem;