'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GRIDDLE_BATCH_MOVE_START = GRIDDLE_BATCH_MOVE_START;
exports.GRIDDLE_BATCH_MOVE_END = GRIDDLE_BATCH_MOVE_END;
exports.GRIDDLE_BATCH_MOVE_SELECTED_ROWS_RELATIVE_TO_ROW_WITH_ID = GRIDDLE_BATCH_MOVE_SELECTED_ROWS_RELATIVE_TO_ROW_WITH_ID;

var _reducer = require('../../griddle-dnd-plugin/src/reducer');

/*
import {
    GRIDDLE_CLEAR_ALL_ROW_SELECTIONS
} from '../../griddle-selection-plugin/src/reducer';
*/

function GRIDDLE_BATCH_MOVE_START(state) {
    return state.set('batchMoveActive', true);
}

function GRIDDLE_BATCH_MOVE_END(state) {
    return state.set('batchMoveActive', false);
}

//# 20230112 - DEPRECATED should only use GRIDDLE_BATCH_MOVE_SELECTED_ROWS_RELATIVE_TO_ROW_WITH_ID
/*
export function GRIDDLE_BATCH_MOVE_SELECTED_INSIDE_ID(state, action) {
    const selectedRecords = state.get('selectedRecords');
    const selectionOrder = state.get('selectionOrder');

    let newState = state;
    for (let selectionId of selectionOrder.reverse()) {
        const selectedRecord = selectedRecords.get(selectionId);
        newState = GRIDDLE_MOVE_ROW_WITH_ID_TO_CHILD_OF_ROW_WITH_ID(newState, {
            moveId: selectedRecord.get('id')
            , targetId: action.targetId
        })
    }
    return newState;
}
*/

function GRIDDLE_BATCH_MOVE_SELECTED_ROWS_RELATIVE_TO_ROW_WITH_ID(state, action) {
    var selectedRecords = state.get('selectedRecords');
    var selectionOrder = state.get('selectionOrder');

    var referenceId = action.referenceId,
        relativePosition = action.relativePosition;


    var newState = state;
    var insertOrder = relativePosition === 'after' ? selectionOrder.reverse() : selectionOrder;

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
        for (var _iterator = insertOrder[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var selectionId = _step.value;

            var selectedRecord = selectedRecords.get(selectionId);
            newState = (0, _reducer.GRIDDLE_MOVE_ROW_WITH_ID_TO_RELATIVE_POSITION_BY_ID)(newState, {
                moveId: selectedRecord.get('id'),
                referenceId: referenceId,
                relativePosition: relativePosition
            });
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally {
        try {
            if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
            }
        } finally {
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }

    return newState;
}