'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TabsConnector = require('./reactComponents/TabsConnector');

var _TabsConnector2 = _interopRequireDefault(_TabsConnector);

var _Tabs = require('./reactComponents/Tabs');

var _Tabs2 = _interopRequireDefault(_Tabs);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Promise = require('bluebird');


var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var FieldForm = require('@rubyapps/ruby-component-field-form');

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldSetMixin = require('@rubyapps/ruby-component-mixin-field-set');

var RCTabs = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldSetMixin],
    componentName: componentName,
    getInitialState: function getInitialState() {
        return {
            //# NOTE: selectedIndex is a uni-directional data item
            //# it does not reflect the actual select index for now
            //# it only propagates a requested index change to the react component
            selectedIndex: 0
        };
    },
    reducer: require('./reducer'),
    action: require('./action'),
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();

        return {
            selfSelector: selfSelector
        };
    },
    getReactClass: function getReactClass() {
        return _TabsConnector2.default.apply(this);
        return _Tabs2.default;
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var TabsComponent = this.getReactClass();

        return _react2.default.createElement(TabsComponent, _extends({}, this.props.reactProps, (_extends2 = {
            'data-codecept-selector-node': 'TabsComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TabsComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TabsComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    clearForm: function clearForm(dispatchOrCollect) {
        //# reset the selectedIndex if the tab count does not match
        //# NOTE: we don't want to update to resetStrore instead of clearForm
        //# because there might be reasons why some components retain their redux state 
        //# during a route change

        var _getState = this.getState(),
            selectedIndex = _getState.selectedIndex;

        var childrenLength = this.getChildren().length;
        if (selectedIndex + 1 >= childrenLength) {
            this.getStore().dispatch(this.getAction().generators.setSelectedIndex(0));
        }

        return baseFieldMixin.clearForm.apply(this, arguments);
    },

    onReduxInit: function onReduxInit() {
        var _this = this;

        var routeParent = this.findAncestorBy(function (el) {
            return el.getRouteElement;
        });
        var parentFormComponent = this.findAncestorBy(function (node) {
            return node.componentName == 'rubyComponentFieldForm';
        });
        return store.subscribe(function () {
            //# if selectedId's hashRef doesn't match the url's hash ref, then we need to set the selectedId
            var nextRouteParams = routeParent.getState().routeParams;

            //# check if we should navigate to a hash ref
            var prevHashRef = _this.getStatefulCacheForKey('hashRef');

            var hash = nextRouteParams.hash,
                routeActive = nextRouteParams.routeActive;

            var nextHash = hash || {};

            var targetHashRef = nextHash.tab;

            if (targetHashRef != prevHashRef && routeActive) {
                _this.setStatefulCacheForKey('hashRef', targetHashRef);
                //# cache the hashRef locally to prevent tripping the reduxInit

                var tabIndex = _this.getChildren().findIndex(function (tab) {
                    return tab.props.hashRef == targetHashRef;
                });

                if (tabIndex >= 0 && _this.getState().selectedIndex != tabIndex) {

                    //# need to see if parent form hash promisedOnceResolved()
                    //# because we would need to wait for that before switching
                    var promisedOnceResolved = parentFormComponent.getStatefulCacheForKey('promisedOnceResolved') || Promise.resolve();
                    promisedOnceResolved.then(function () {
                        _this.getStore().dispatch(_this.getAction().generators.setSelectedIndex(tabIndex));
                    });
                }
            }
        });
    }
});

module.exports = RCTabs;