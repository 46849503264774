'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _DropdownWrapper = require('./DropdownWrapper');

var _DropdownWrapper2 = _interopRequireDefault(_DropdownWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DropdownWrapper__propTypeKeys = Object.keys(_DropdownWrapper2.default.propTypes);

var UNKNOWN = 10;
var NUMBER = 11;
var STRING = 12;

function optionValuesDataType(options) {
    var firstNonNullOptionValue = _lodash2.default.get(options.filter(function (option) {
        return option.value !== null;
    }), '0.value');

    if (!_lodash2.default.isNil(firstNonNullOptionValue)) {
        if (_lodash2.default.isString(firstNonNullOptionValue)) {
            return STRING;
        } else if (_lodash2.default.isNumber(firstNonNullOptionValue)) {
            return NUMBER;
        }
    } else {
        return UNKNOWN;
    }
}

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector;

    //# figure out the toHTML ahead of time


    var hydratedToHTML = this.hydratedToHTMLFromProps(this.props);
    //# currently not used

    //# optionToHTML because toHTML is used by mode:info
    var hydratedOptionToHTML = this.hydratedToHTMLFromProps({
        toHTML: this.props.optionToHTML
    });

    var defaultHydratedOptionToHTML = this.hydratedToHTMLFromProps({
        toHTML: "${data.status === 'inactive' ? `<span class='unpublishedChipContainer'><span>` + data.text + `</span><span class='unpublishedChip'>unpublished</span></span>` : data.text}"
    });

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);
        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);

        var options = _lodash2.default.get(selfState, 'props.options') || ownProps.options || [];
        var availableOptionsAsValues = options.map(function (option) {
            return option.value;
        });

        var _ref = selfModule.props.key ? _lodash2.default.pick(selfState.fields[selfModule.props.key], ['value']) : {},
            value = _ref.value;

        if (value == undefined && selfModule.props.defaultToFirstOption) {
            //# NOTE: hacky selecting defaultToFirstOption since we cannot preemptively set the value instate
            value = availableOptionsAsValues[0];
        }

        var valueNormalizedAsArray = Array.isArray(value) ? value : [value];

        var isArray = _lodash2.default.isArray(_lodash2.default.get(ownProps, 'data_type'));

        var formattedError = selfModule.formattedErrorFromState(state);

        //# coercing the input values
        var optionValueDataType = optionValuesDataType(options);
        var coercedValues = void 0;
        if (optionValueDataType == STRING) {
            coercedValues = valueNormalizedAsArray.map(function (value) {
                return value == null ? value : value + '';
            });
        } else if (optionValueDataType == NUMBER) {
            coercedValues = valueNormalizedAsArray.map(function (value) {
                return value == null ? value : parseInt(value);
            });
        } else {
            coercedValues = valueNormalizedAsArray;
        }

        //# some values cannot be found in the set of options, we we filter them out;
        var filteredValues = coercedValues.filter(function (value) {
            return availableOptionsAsValues.indexOf(value) != -1;
        });

        var mergedProps = _extends({}, ownProps, {
            isArray: isArray
        }, mixinFieldProps, _lodash2.default.pick(selfState, DropdownWrapper__propTypeKeys), _extends({}, selfState.props), {
            value: filteredValues
        }, formattedError, hydratedToHTML ? { toHTML: hydratedToHTML } : {}, hydratedOptionToHTML ? { optionToHTML: hydratedOptionToHTML } : { optionToHTML: defaultHydratedOptionToHTML });

        var isMultiSelect = mergedProps.limit == null || Number.isInteger(mergedProps.limit) && mergedProps.limit > 1;

        if (mergedProps.required || mergedProps.mode === 'info' || mergedProps.mode === 'control' || isMultiSelect) {
            mergedProps.options = options;
        } else {
            mergedProps.options = [{ value: null, text: '\xA0' }].concat(options);
        }

        return mergedProps;
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_DropdownWrapper2.default);
}