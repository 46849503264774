'use strict';

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _flattenObjectReducer() {
    var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var objectA = arguments[1];
    var pathArr = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

    if (_lodash2.default.isObject(objectA) && Object.keys(objectA).length == 0) {
        collector[pathArr.join('.')] = null;
        //# when the leaf object is null, we still need to have it appear
        //# in the flattened root object;
        return collector;
    }

    return _lodash2.default.reduce(objectA, function (collector, valueA, keyA) {
        var keyAPathArr = pathArr.concat(keyA);
        if (_lodash2.default.isObject(valueA) || _lodash2.default.isArray(valueA)) {
            return _flattenObjectReducer(collector, valueA, keyAPathArr);
        }

        collector[keyAPathArr.join('.')] = valueA;
        return collector;
    }, collector);
}

function _coerceDataTypes(objectA) {
    return _lodash2.default.reduce(objectA, function (collector, valueA, keyA) {
        collector[keyA] = valueA == undefined ? null : valueA;
        return collector;
    }, {});
}

var diffHelperMixin = {
    mixinName: 'rubyComponentMixinFieldDiffHelper',
    flattenedObject: function flattenedObject(objectToFlatten) {
        return _coerceDataTypes(_flattenObjectReducer({}, objectToFlatten));
    }
};

module.exports = diffHelperMixin;