"use strict";

/* jshint -W138 */

var defaultState = {
    pristineFields: null,
    pristineErrors: null //# might not need
    , error: null
};

module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];
    var TYPES = this.getDependencies().selfAction.TYPES;
    var type = action.type,
        payload = action.payload;

    switch (type) {
        case TYPES.SEED_CHILDREN:
            return state;
        default:
            return state;
    }
};