'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var ReactDOM = _interopRequireWildcard(_reactDom);

var _AppBar = require('material-ui/AppBar');

var _AppBar2 = _interopRequireDefault(_AppBar);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _close = require('material-ui/svg-icons/navigation/close');

var _close2 = _interopRequireDefault(_close);

var _Dialog = require('material-ui/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _dialog = require('@rubyapps/ruby-styles/src/client/styleObjects/dialog');

var _dialog2 = _interopRequireDefault(_dialog);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

var PropTypes = _react2.default.PropTypes;


var ResizeMixin = require('./mixin-resize.js');

var Dialog__propTypeKeys = Object.keys(_Dialog2.default.propTypes);

var styles = {
    title: {}
};

var styleObject = {
    Dialog: _extends({
        contentStyle: {
            width: '90%',
            maxWidth: 1216
        }
    }, _dialog2.default)
};
var FieldDialogHeader = _react2.default.createClass({
    displayName: 'FieldDialogHeader',

    propTypes: {
        actions: PropTypes.shape({
            close: PropTypes.func
        }),
        label: PropTypes.string,
        actionButtons: PropTypes.array
    },
    getDefaultProps: function getDefaultProps() {
        return {
            label: 'Dialog'
        };
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5;

        var myContext = this;
        var styleColor = {
            color: '#ffffff'
        };

        var actionButtonsContainer = this.props.actionButtons ? _react2.default.createElement(
            'div',
            (_React$createElement = { style: _dialog.DialogHeader.iconElementRight, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Dialog'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Dialog'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Dialog'), _React$createElement),
            this.props.actionButtons
        ) : null;
        return _react2.default.createElement(_AppBar2.default, (_React$createElement5 = {
            title: _react2.default.createElement(
                'span',
                (_React$createElement2 = { style: styles.title, 'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'Dialog'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Dialog'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Dialog'), _React$createElement2),
                this.props.label
            ),
            iconElementLeft: _react2.default.createElement(
                _IconButton2.default,
                (_React$createElement4 = { onClick: this.props.actions.close, 'data-codecept-selector-node': 'IconButton',
                    'data-codecept-selector-file': 'Dialog'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Dialog'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Dialog'), _React$createElement4),
                _react2.default.createElement(_close2.default, (_React$createElement3 = {
                    'data-codecept-selector-node': 'NavigationClose',
                    'data-codecept-selector-file': 'Dialog'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'NavigationClose'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Dialog'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'NavigationClose'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Dialog'), _React$createElement3))
            ),
            iconElementRight: actionButtonsContainer,
            'data-codecept-selector-node': 'AppBar',
            'data-codecept-selector-file': 'Dialog'
        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Dialog'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'AppBar'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Dialog'), _React$createElement5));
    }
});
var FieldDialogHeader__propTypeKeys = Object.keys(FieldDialogHeader.propTypes);

var FieldDialog = _react2.default.createClass(_extends({}, ResizeMixin, {
    propTypes: _extends({
        //# state-like
        actions: PropTypes.shape({
            close: PropTypes.func
        }),
        open: PropTypes.bool
    }, _Dialog2.default.propTypes),
    getDefaultProps: function getDefaultProps() {
        return {
            close: function close() {},
            modal: true
        };
    },
    getInitialState: function getInitialState() {
        return {
            delayedLastRenderedTimestamp: null
        };
    },
    render: function render() {
        var _extends2,
            _this = this,
            _React$createElement6,
            _extends3;

        var fieldDialogHeaderProps = _.pick(this.props, FieldDialogHeader__propTypeKeys);
        var DialogProps = _.omit(_.pick(this.props, Dialog__propTypeKeys), ['actions']);
        return _react2.default.createElement(
            _Dialog2.default,
            _extends({}, styleObject.Dialog, {
                title: _react2.default.createElement(FieldDialogHeader, _extends({}, fieldDialogHeaderProps, (_extends2 = {
                    'data-codecept-selector-node': 'FieldDialogHeader',
                    'data-codecept-selector-file': 'Dialog'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'FieldDialogHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Dialog'), _defineProperty(_extends2, 'data-codecept-selector-node', 'FieldDialogHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Dialog'), _extends2))),
                modal: true,
                onRequestClose: this.props.actions.close
            }, DialogProps, (_extends3 = {
                'data-codecept-selector-node': 'Dialog',
                'data-codecept-selector-file': 'Dialog'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Dialog'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Dialog'), _extends3)),
            _react2.default.createElement(
                'div',
                (_React$createElement6 = {
                    ref: function ref(componentRef) {
                        if (!componentRef) {
                            return;
                        }
                        _this._bindElementResize(ReactDOM.findDOMNode(componentRef));
                    },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Dialog'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Dialog'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Dialog'), _React$createElement6),
                this.props.children
            )
        );
    }

}));
exports.default = FieldDialog;