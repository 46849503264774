'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initialState;

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function initialState() {
  var idCol = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'id';
  var initialSelectedRecords = arguments[1];
  var initialSelectionOrder = arguments[2];

  return _immutable2.default.fromJS({
    selectedRecords: initialSelectedRecords ? (0, _immutable.fromJS)(initialSelectedRecords) : _immutable2.default.Map(),
    selectionOrder: initialSelectionOrder ? (0, _immutable.fromJS)(initialSelectionOrder) : _immutable2.default.List(),
    idColumn: idCol
  });
}