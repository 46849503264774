'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _optionsUrl__extractParams = require('@rubyapps/ruby-form-js-to-json-schema/src/common/optionsUrl__extractParams.js');

var _optionsUrl__extractParams2 = _interopRequireDefault(_optionsUrl__extractParams);

var _addToPhotos = require('material-ui/svg-icons/image/add-to-photos');

var _addToPhotos2 = _interopRequireDefault(_addToPhotos);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var RubyComponentStructuredDataListPage = require('@rubyapps/ruby-component-structuredDataList-page/src/client/index');

var styles = process.browser ? //# TODO:
require('../reactComponents/styles.cssModule').default : {};

var tokenTaggerListerMixin = require('../../local_modules/ruby-component-mixin-lister-tokentagger/src/client/index');

var AugmentedRubyComponentStructuredDataListPage = _extends({}, RubyComponentStructuredDataListPage, {
    mixins: RubyComponentStructuredDataListPage.mixins.concat(tokenTaggerListerMixin)
});
module.exports = {

    propTypes: {
        childrenPropsByClass: PropTypes.object
    },
    action: require('./action'),
    reducer: require('./reducer'),
    listerId: function listerId() {
        return this.props.id + '__lister';
    },
    getInitialState: function getInitialState() {
        return {
            dialog_open: false,
            dialog_opening: false
        };
    },
    dependencies: function dependencies() {
        var _React$createElement;

        var listerComponent = this.getChildren()[0];
        var Lister = listerComponent ? listerComponent.getReactClass() : null;
        var listerElement = Lister ? React.createElement(Lister, (_React$createElement = {
            'data-codecept-selector-node': 'Lister',
            'data-codecept-selector-file': 'advancedSearch'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Lister'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'advancedSearch'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Lister'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'advancedSearch'), _React$createElement)) : null;

        return {
            listerComponent: listerComponent,
            listerElement: listerElement
        };
    },
    children: function children() {
        var _this = this;

        if (!this.props.advancedSearch) {
            return [];
        }

        if (this.getStore() == undefined || !this.getState()) {

            return [];
        }

        var _getState = this.getState(),
            dialog_open = _getState.dialog_open,
            dialog_opening = _getState.dialog_opening,
            stateProps = _getState.props;

        //# Defer generating children to save resources


        if (dialog_open || dialog_opening) {
            var cachedChildren = this.getStatefulCacheForKey('_cachedChildren');

            //# TODO: determine proper template key
            //
            if (!cachedChildren) {
                var listerId = this.listerId();

                var url = this.props.url;


                var modelInfo = url && (0, _optionsUrl__extractParams2.default)(url);
                var templateKeys = modelInfo ? _.castArray(modelInfo.template) : [];
                var additionalFields = modelInfo.query.fields || [];
                var text_field_key = modelInfo.query.text_field_key || 'name';
                var value_field_key = modelInfo.query.value_field_key || 'id';
                var optionFromRowData = function optionFromRowData(rowData) {
                    return _extends({}, _.pick(rowData, additionalFields), { text: rowData[text_field_key] || rowData.text,
                        value: _this.coercedValue(rowData[value_field_key], true) || rowData.value
                    });
                };

                if (templateKeys.length > 1) {
                    console.warn('Only urls with a single template is supported by Advanced Search for now since the Lister only supports that. Selecting the first available template out of: '.templateKeys);
                }

                //figure out best list url for searchContext
                var targetSubsite = this.getSubsiteUrlIdentifier_forRubyClientId_andSubsiteId(modelInfo.ruby_client_fk || 3, modelInfo.content_subsite_fk || 1);
                var targetTemplate = _.first(templateKeys);

                var bestUrls = this.getRoot().getBestUrls_fromModule_withParams(this, {
                    template: targetTemplate,
                    subsite: targetSubsite
                }, { matchedParamsValidator: 'template' });

                var targetUrl = bestUrls.filter(function (url) {
                    return url.indexOf('\/list') > 0;
                })[0];

                var newChildren = [RubyComponent.createElement(AugmentedRubyComponentStructuredDataListPage, _extends({}, stateProps, _.get(this.props, 'childrenPropsByClass.rubyComponentStructuredDataLister'), { id: listerId,
                    mode: 'select',
                    'templateKey': targetTemplate,
                    savedSearchContext: targetUrl
                    //# listApiEndpoint: if we want to override this too
                    , disallow_alter_template: true,

                    additionalQueryParams: {
                        content_subsite_fk: modelInfo.query.content_subsite_fk
                    },
                    routeParams: {
                        template: targetTemplate
                    },
                    internalFieldsQuery: { //# need to include any additional fields
                        //# so we can hydrate the options
                        fields: modelInfo.query.fields || []
                    },
                    modeActionSpec: function modeActionSpec(griddleProps_row) {
                        var _React$createElement2;

                        var id = _this.coercedValue(_.get(griddleProps_row, 'originalData.id'), true);

                        var fieldValue = _.castArray(_this.getFieldValue() || []).map(function (val) {
                            return _this.coercedValue(val, true);
                        });
                        var isLimitHit = fieldValue.length >= _this.props.limit;

                        var alreadyInserted = fieldValue.indexOf(id) >= 0;

                        return {
                            label: 'Insert',
                            icon: React.createElement(_addToPhotos2.default, (_React$createElement2 = {
                                'data-codecept-selector-node': 'AddToPhotosIcon',
                                'data-codecept-selector-file': 'advancedSearch'
                            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'AddToPhotosIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'advancedSearch'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'AddToPhotosIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'advancedSearch'), _React$createElement2)),
                            disabled: isLimitHit || alreadyInserted,
                            handler: function handler(event, selectedIds, rowDatas) {
                                //# normalize selectedIds and rowDatas to array to support batch insert

                                //# need to normalize the selectedId
                                selectedIds = selectedIds ? _.castArray(selectedIds) : selectedIds;
                                rowDatas = rowDatas ? _.castArray(rowDatas) : [];

                                var targetSelectedIds = selectedIds && selectedIds.length ? selectedIds : event.target.value ? _.castArray(event.target.value) : [];
                                var normalizedSelectedIds = targetSelectedIds.map(function (id) {
                                    return id.toString();
                                }); //# normalize ids to string for internal storage
                                var fieldValue_normalized = (_this.getFieldValue() || []).map(function (id) {
                                    return id.toString();
                                });

                                //# insert value
                                var dispatch = _this.getStore().dispatch;

                                var _getAction = _this.getAction(),
                                    actions = _getAction.generators;

                                dispatch(actions.closeDialog());

                                var newValue = _.uniq(fieldValue_normalized.concat(normalizedSelectedIds));

                                //# truncate newValue based on limit
                                if (newValue.length >= _this.props.limit) {
                                    newValue = newValue.slice(0, _this.props.limit);
                                }

                                var newOptions = rowDatas.map(optionFromRowData);

                                dispatch(actions.updateCachedOptions_withAdditional(newOptions));

                                dispatch(actions.setFieldValueByKey(newValue, _this.props.key));
                            }
                        };
                    },

                    reactProps: {
                        childrenPropsByClass: {
                            Root: {
                                className: null //# clear out the class responsible for the margin bottom
                            },
                            Grid: {
                                style: {
                                    position: 'relative',
                                    zIndex: 1,
                                    width: '100%' //# override the flex widths
                                }
                            },
                            Lister: {
                                griddleProps: {
                                    componentProps: {
                                        Table: {
                                            scrollElementSelector: '.' + styles.lister__dialogBody
                                        },
                                        Header: {
                                            inlineStyles: {
                                                Toolbar: {
                                                    display: 'block'
                                                }
                                            },
                                            stickyProps: {
                                                //# TODO: REVIEW
                                                "scrollElement": '.' + styles.lister__dialogBody,
                                                stickyClassName: styles.sticky,
                                                disabled: false,
                                                topOffset: 3 //# to account for the dialogBody's -3px margin
                                                , style: {
                                                    zIndex: 10,
                                                    position: 'relative' //# Necessary to fix z-index of LoadingIndicator
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        //, staticFilterTags: hydratedStaticFilterTags
                    } }))];

                this.setStatefulCacheForKey('_cachedChildren', newChildren);
                //console.log(`== [${this.props.key}] returning new children `);
                return newChildren;
            } else {
                //console.log(`== [${this.props.key}] returning cached children `);
                return cachedChildren;
            }
        } else {
            //console.log('==== clearing media gallery for picker: ', this.props.key);
            return [];
        }
    }
};