'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var action = require('./action');
var reducer = require('./reducer');
var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;
var AlertDialogConnector = require('./reactComponents/AlertDialogConnector');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var RCAlertDialog = RubyComponent.createClass({
    propTypes: {
        delegateActions: PropTypes.object,
        reactProps: PropTypes.object
    },
    componentName: componentName,
    CONSTANTS: CONSTANTS,
    action: action,
    reducer: reducer,
    dependencies: function dependencies() {
        //# declare dependencies here so it's easy to find
        var rootComponent = this.getRoot();

        var selfSelector = this.getDefaultSelector();

        return {
            selfSelector: selfSelector
        };
    },

    getReactClass: function getReactClass() {
        var AlertDialogComponent = AlertDialogConnector.apply(this);
        return AlertDialogComponent;
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var AlertDialogComponent = this.getReactClass();
        var reactProps = this.props.reactProps;
        //# perform connection
        //# need to access the ruby_client_fk
        //# action to open the compare dialog should give the templatekey
        return React.createElement(AlertDialogComponent, _extends({}, reactProps, (_extends2 = {
            'data-codecept-selector-node': 'AlertDialogComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'AlertDialogComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'AlertDialogComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    }

    //# Utility
    , openWithOptions: function openWithOptions(options) {
        var _getAction = this.getAction(),
            actions = _getAction.generators;

        this.getStore().dispatch(actions.openDialogWithOptions(options));
    }
});

module.exports = RCAlertDialog;