"use strict";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

module.exports = function (state, action) {
    var _getAction = this.getAction(),
        TYPES = _getAction.TYPES;

    var type = action.type,
        payload = action.payload;

    switch (type) {
        case TYPES.REPLACE_REDUX_STATE:
            return _extends({
                reduxStateReplaced: new Date()
            }, state, payload.reduxState);
        default:
            return state;
    }
};