'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var _ = require('lodash');
var queryString = require('query-string');

var CONSTANTS = require('../../common/constants');

var COMPONENT_NAME = CONSTANTS.COMPONENT_NAME;


var mceCommand = 'mce' + COMPONENT_NAME;

//# module will self register

// Load plugin specific language pack
tinymce.PluginManager.requireLangPack(COMPONENT_NAME);

//# TOOD: figure out how to load the languages

var $ = require('jquery');
var REFERENCE_MODEL_KEY = "emaillink";

tinymce.create('tinymce.plugins.' + COMPONENT_NAME, {

    _editor: null,
    _rubyApp: null //{childrenByKey: {}}
    , _selfModule: null,
    _selectedNode: null,
    _selectedNodeSpec: null // the mailto: data parsed, to be used as formData for dialog
    , _augment_text: null //# TODO: what is augment text used for?

    /* ************************************************************
       ** BEGIN: FRAMEWORK METHODS
       ********************************************************** */

    /**
      * Initializes the plugin, this will be executed after the plugin has
      * been created.  This call is done before the editor instance has
      * finished it's initialization so use the onInit event of the editor
      * instance to intercept that event.
      * @param {tinymce.Editor} ed Editor instance that the plugin is
      *                                   initialized in.
      * @param {string} url Absolute URL to where the plugin is located.
      */
    , init: function init(ed, url) {
        var self = this;

        //https://webpack.js.org/concepts/loaders/#inline
        //# !! - ignore configured loaders
        //const envelopeSVG = require('!!svg-inline-loader?classPrefix!font-awesome-svg-png/black/svg/envelope-o.svg')
        var envelopeSVG = require('font-awesome-svg-png/black/svg/envelope-o.svg?raw');
        //# fix width and height
        envelopeSVG = envelopeSVG.replace('width="1792"', 'width="20"');
        envelopeSVG = envelopeSVG.replace('height="1792"', 'height="20"');

        ed.ui.registry.addIcon('email', envelopeSVG);

        this._editor = ed;
        this._rubyApp = ed.settings.rubyApp;
        this._selfModule = this._rubyApp.childrenByKey[COMPONENT_NAME];

        // Register the command so that it can be invoked by using
        // tinyMCE.activeEditor.execCommand('mceRubyAppMediaManager');
        ed.addCommand(mceCommand, function () {
            self._handlePickerButtonClick();
        });

        // Register the image gallery button
        ed.ui.registry.addButton(COMPONENT_NAME, {
            //title: 'Insert Email Link',
            tooltip: 'Insert Email Link',
            icon: 'email',
            onAction: function onAction() {
                return ed.execCommand(mceCommand);
            },
            onSetup: function onSetup(api) {
                var controlManager = api;

                var editorNodeChange = function editorNodeChange(e) {
                    return self._handleNodeChange(ed, controlManager, e.element);
                };

                ed.on('NodeChange', editorNodeChange);
                return function (api) {
                    ed.off('NodeChange', editorNodeChange);
                };
            }
        });

        //ed.settings.rubyComponent.actions to get the rubyComponent actions

        //# set options on the rubyComponent
        this._selfModule.getStore().dispatch(this._selfModule.getAction().generators.setOptions({
            onSelect: this._onSelect.bind(this)
        }));
    } // init

    /**
      * Creates control instances based in the incomming name. This method is
      * normally not needed since the addButton method of the tinymce.Editor
      * class is a more easy way of adding buttons but you sometimes need to
      * create more complex controls like listboxes, split buttons etc then
      * this method can be used to create those.
      *
      * @param {String} n Name of the control to create.
      * @param {tinymce.ControlManager} cm Control manager to use inorder to
      *                                    create new control.
      * @return {tinymce.ui.Control} New control instance or null if no
      *                              control was created.
      */
    , createControl: function createControl(n, cm) {
        return null;
    }

    /**
      * Returns information about the plugin as a name/value array.
      * The current keys are longname, author, authorurl, infourl and version.
      *
      * @return {Object} Name/value array containing info about the plugin.
      */
    , getInfo: function getInfo() {
        return {
            longname: 'RubyApp Email Link Manager plugin',
            author: 'Howard Yee (Rubenstein Technology Group)',
            authorurl: 'http://www.rubensteintech.com',
            version: "1.0"
        };
    } // getInfo


    /* ************************************************************
       ** BEGIN: INITIALIZATION METHODS
       ********************************************************** */

    /* ************************************************************
       ** BEGIN: EVENT HANDLER METHODS
       ********************************************************** */

    , _handleNodeChange: function _handleNodeChange(editor, controlManager, node) {
        // Reset some global data
        this._augment_text = '';
        this._selectedNode = node;
        this._selectedNodeSpec = null;

        var $node = $(node);

        //<a href="mailto:howard@email.com?subject=SUBJECT&amp;body=BODY%20TEST"></a>

        var href = $node.attr('href');
        var isMailTo = /^mailto:/.test(href);
        var isHighlighted = editor.selection.getContent().length;

        if (isHighlighted) {
            controlManager.setDisabled(false);
        } else {
            controlManager.setDisabled(true);
        }

        if (isMailTo && isHighlighted) {
            var match = /^mailto:(.+?@[^?]+)(\?.*)?$/.exec(href);

            var mailtoSpec = _extends({
                email: match[1]
            }, _.mapValues(match[2] ? queryString.parse(match[2]) : {}, decodeURIComponent));

            this._selectedNodeSpec = mailtoSpec;

            //# don't set active because this is only used by toggleButton
            //controlManager.setActive( true );

            return;
        }
        //controlManager.setActive( false );

    } // _handleNodeChange

    , _handlePickerButtonClick: function _handlePickerButtonClick() {
        var self = this;
        self._storeSelection();
        self._openDialog();

        return true;
    } // _handleRubyAppMediaManagerButtonClick

    , _onSelect: function _onSelect(data) {
        var editor = this._editor;
        var selectedNode = editor.selection.getNode();

        var selfModule = this._selfModule;

        var email = data.email,
            mailtoParams = _objectWithoutProperties(data, ['email']);

        //# TODO: why are we selecting the entire node?
        //# NOTE: the old image picker did this
        // Select the entire link


        if (editor.selection.getNode().nodeName == 'A') {
            editor.selection.select(editor.selection.getNode());
        }
        this._restoreSelection();

        var $embeddable = void 0;
        var selectedMarkup = editor.selection.getContent() || '';

        // If text has been selected, link to the Media.
        // Otherwise, embed the Media directly in the text.
        if (selectedMarkup) {
            // NOTE: the <div> tags are a hack. Without them, the text
            // of HTML strings such as "hello <em>world</em>" return an
            // empty string instead of the expected "hello world".
            // The <div> guarantees that there is always a valid wrapping
            // element to retrieve text from.
        } else {
            $embeddable = $(embeddableMarkup);
        }
        var embeddableText = $('<div>' + selectedMarkup + '</div>').text();

        var mailto_queryString = queryString.stringify(_.omitBy(mailtoParams, _.isNil));
        var href = 'mailto:' + email + (mailto_queryString ? '?' + mailto_queryString : '');
        $embeddable = $('<a href="' + href + '">' + embeddableText + '</a>');

        editor.execCommand('mceInsertContent', false, $('<div>').append($embeddable).html(), { skip_undo: 1 });

        editor.undoManager.add();
        editor.execCommand('mceRepaint');

        this._closeDialog();
    }

    /* ************************************************************
       ** BEGIN: HELPER METHODS
       ********************************************************** */

    , _closeDialog: function _closeDialog() {
        var selfModule = this._selfModule;

        var _selfModule$getAction = selfModule.getAction(),
            actions = _selfModule$getAction.generators;

        selfModule.getStore().dispatch(actions.closeDialog());
        /*
        selfModule.getStore().dispatch(
            this._selectedNodeSpec?
                actions.openAndUpdate_withOptions(this._selectedNodeSpec)
                :actions.openWithOptions()
        );
        */

        return;
    },
    _openDialog: function _openDialog() {
        var _this = this;

        var selfModule = this._selfModule;

        var _selfModule$getAction2 = selfModule.getAction(),
            actions = _selfModule$getAction2.generators;

        selfModule.getStore().dispatch(actions.openDialog({
            defaultFormData: this._selectedNodeSpec,
            onSubmit: function onSubmit(formData, successCb, errorCb) {
                _this._onSelect(formData);
            }
        }));
        /*
        selfModule.getStore().dispatch(
            this._selectedNodeSpec?
                actions.openAndUpdate_withOptions(this._selectedNodeSpec)
                :actions.openWithOptions()
        );
        */

        return;
    } // _openDialog

    /* ************************************************************
       ** BEGIN: tinyMCE HELPER METHODS
       **   (copied from tinyMCE's Popup.js)
       ********************************************************** */

    , _getLang: function _getLang(n, dv) {
        return tinymce.activeEditor.getLang(n, dv);
    },

    _getTinyMCEParam: function _getTinyMCEParam(n, dv) {
        return tinymce.activeEditor.getParam(n, dv);
    },

    _storeSelection: function _storeSelection() {
        tinymce.activeEditor.windowManager.bookmark = tinymce.activeEditor.selection.getBookmark(1);
    },

    _restoreSelection: function _restoreSelection() {
        if (tinymce.isIE) tinymce.activeEditor.selection.moveToBookmark(tinymce.activeEditor.windowManager.bookmark);
    }

}); // tinymce.create

// Register plugin
tinymce.PluginManager.add(COMPONENT_NAME, tinymce.plugins[COMPONENT_NAME]);

module.exports = {
    pluginName: COMPONENT_NAME
};