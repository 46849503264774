'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var fieldSelflessMixin = {
    mixinName: 'rubyComponentMixinFieldSelfless',
    _formValueFromLocalState: function _formValueFromLocalState(selfState, isError, predicateFormatter, entireState) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        if (selfState == undefined) {
            return undefined;
        }
        var selfID = this.getID();
        var selfKey = this.props.key;

        var children = this.getChildren();

        var arrOfReducedChildrenState = _.reduce(children, function (collector, child, index) {
            var childID = child.getID(); //# TODO: change caused ID isn't unique enough
            var childFormValue = child.formValueFromLocalState ? child._formValueFromLocalState(selfState[childID], isError, predicateFormatter, entireState, options) : undefined;
            if (childFormValue != undefined) {
                collector.push(childFormValue);
            }
            return collector;
        }, []);

        var hiddenValues = this.props.hiddenValues || {};
        var formHiddenValues = isError ? {} : hiddenValues;

        var reducedChildrenState = _extends.apply(undefined, [{}, formHiddenValues].concat(_toConsumableArray(arrOfReducedChildrenState)));

        return reducedChildrenState;
    }
};

module.exports = fieldSelflessMixin;