'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Card = require('material-ui/Card');

var _CopyrightFooter = require('@rubyapps/ruby-react-components/src/client/layout/CopyrightFooter');

var _CopyrightFooter2 = _interopRequireDefault(_CopyrightFooter);

var _RefreshIndicator = require('@rubyapps/ruby-material-ui/RefreshIndicator');

var _RefreshIndicator2 = _interopRequireDefault(_RefreshIndicator);

var _SecureLogin = require('./SecureLogin.cssModule');

var _SecureLogin2 = _interopRequireDefault(_SecureLogin);

var _SecureLogin_style = require('./SecureLogin_style');

var _SecureLogin_style2 = _interopRequireDefault(_SecureLogin_style);

var _rubyLogo = require('@rubyapps/ruby-logo');

var _rubyLogo2 = _interopRequireDefault(_rubyLogo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

var PropTypes = _react2.default.PropTypes;

var _require = require('@rubyapps/react-flexbox-grid'),
    Grid = _require.Grid,
    Row = _require.Row,
    Col = _require.Col;
//# icons


//# Local modules
var CONSTANTS = require('../../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

//# Styles


var errorPropType = PropTypes.shape({
    message: PropTypes.string
});

var SecureLogin = _react2.default.createClass({
    displayName: 'SecureLogin',

    propTypes: {
        clientName: PropTypes.string,
        rubyBranding: PropTypes.string,
        rubyContributors: PropTypes.array
    },

    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8, _React$createElement9, _React$createElement12, _extends2, _extends3, _extends4, _React$createElement13, _React$createElement14, _React$createElement15, _React$createElement16;

        console.log('props: ', this.props);

        console.log('error: ', this.props.error);

        var errorMessage = this.props.error && this.props.error.message ? _react2.default.createElement(
            'p',
            (_React$createElement3 = { className: _SecureLogin2.default.errorLabel, 'data-codecept-selector-node': 'p',
                'data-codecept-selector-file': 'SecureLogin'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'SecureLogin'), _React$createElement3),
            _react2.default.createElement(
                'strong',
                (_React$createElement = {
                    'data-codecept-selector-node': 'strong',
                    'data-codecept-selector-file': 'SecureLogin'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'SecureLogin'), _React$createElement),
                this.props.error.messageHeader
            ),
            _react2.default.createElement('br', (_React$createElement2 = {
                'data-codecept-selector-node': 'br',
                'data-codecept-selector-file': 'SecureLogin'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'SecureLogin'), _React$createElement2)),
            this.props.error.message
        ) : null;
        var showLoader = this.props.isLoading;
        return _react2.default.createElement(
            'div',
            (_React$createElement16 = { className: _SecureLogin2.default.secureLoginGridWrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'SecureLogin'
            }, _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'SecureLogin'), _React$createElement16),
            _react2.default.createElement(
                Grid,
                (_React$createElement15 = {
                    'data-codecept-selector-node': 'Grid',
                    'data-codecept-selector-file': 'SecureLogin'
                }, _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'Grid'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'Grid'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'SecureLogin'), _React$createElement15),
                _react2.default.createElement(
                    Row,
                    (_React$createElement14 = { center: 'xs', 'data-codecept-selector-node': 'Row',
                        'data-codecept-selector-file': 'SecureLogin'
                    }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'Row'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'Row'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'SecureLogin'), _React$createElement14),
                    _react2.default.createElement(
                        Col,
                        (_React$createElement13 = {
                            xs: 10,
                            sm: 6,
                            md: 4,
                            className: _SecureLogin2.default.secureLoginBlock,
                            'data-codecept-selector-node': 'Col',
                            'data-codecept-selector-file': 'SecureLogin'
                        }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'Col'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'Col'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'SecureLogin'), _React$createElement13),
                        _react2.default.createElement(
                            'div',
                            (_React$createElement5 = {
                                className: _SecureLogin2.default.logo,
                                'data-codecept-selector-node': 'div',
                                'data-codecept-selector-file': 'SecureLogin'
                            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'SecureLogin'), _React$createElement5),
                            _react2.default.createElement(_rubyLogo2.default, (_React$createElement4 = {
                                frontendSettings: this.props.feSettings,
                                'data-codecept-selector-node': 'RubyLogo',
                                'data-codecept-selector-file': 'SecureLogin'
                            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'RubyLogo'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'RubyLogo'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'SecureLogin'), _React$createElement4))
                        ),
                        _react2.default.createElement(
                            'h1',
                            (_React$createElement6 = { className: _SecureLogin2.default.h1, 'data-codecept-selector-node': 'h1',
                                'data-codecept-selector-file': 'SecureLogin'
                            }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'h1'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'h1'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'SecureLogin'), _React$createElement6),
                            this.props.clientName
                        ),
                        _react2.default.createElement(
                            _Card.Card,
                            _extends({ className: _SecureLogin2.default.secureLoginCard }, _SecureLogin_style2.default.Card, (_extends3 = {
                                'data-codecept-selector-node': 'Card',
                                'data-codecept-selector-file': 'SecureLogin'
                            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Card'), _defineProperty(_extends3, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Card'), _defineProperty(_extends3, 'data-codecept-selector-file', 'SecureLogin'), _extends3)),
                            _react2.default.createElement(
                                _Card.CardText,
                                _extends({}, _SecureLogin_style2.default.CardText, (_extends2 = {
                                    'data-codecept-selector-node': 'CardText',
                                    'data-codecept-selector-file': 'SecureLogin'
                                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'CardText'), _defineProperty(_extends2, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_extends2, 'data-codecept-selector-node', 'CardText'), _defineProperty(_extends2, 'data-codecept-selector-file', 'SecureLogin'), _extends2)),
                                _react2.default.createElement(
                                    'p',
                                    (_React$createElement9 = { className: _SecureLogin2.default.secureLoginHeader, 'data-codecept-selector-node': 'p',
                                        'data-codecept-selector-file': 'SecureLogin'
                                    }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'SecureLogin'), _React$createElement9),
                                    _react2.default.createElement(
                                        'strong',
                                        (_React$createElement7 = {
                                            'data-codecept-selector-node': 'strong',
                                            'data-codecept-selector-file': 'SecureLogin'
                                        }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'SecureLogin'), _React$createElement7),
                                        'SECURE AUTHENTICATION'
                                    ),
                                    _react2.default.createElement('br', (_React$createElement8 = {
                                        'data-codecept-selector-node': 'br',
                                        'data-codecept-selector-file': 'SecureLogin'
                                    }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'br'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'SecureLogin'), _React$createElement8))
                                ),
                                function () {
                                    if (showLoader) {
                                        var _React$createElement10, _React$createElement11;

                                        _react2.default.createElement(
                                            'div',
                                            (_React$createElement11 = { className: _SecureLogin2.default.loadingIndicatorWrapper, 'data-codecept-selector-node': 'div',
                                                'data-codecept-selector-file': 'SecureLogin'
                                            }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'SecureLogin'), _React$createElement11),
                                            _react2.default.createElement(_RefreshIndicator2.default, (_React$createElement10 = {
                                                key: 'LoadingIndicator',
                                                left: 0,
                                                top: 12,
                                                status: showLoader ? "loading" : "hide",
                                                className: _SecureLogin2.default.refreshIndicator,
                                                'data-codecept-selector-node': 'RefreshIndicator',
                                                'data-codecept-selector-file': 'SecureLogin'
                                            }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'RefreshIndicator'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'RefreshIndicator'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'SecureLogin'), _React$createElement10))
                                        );
                                    }
                                }(),
                                errorMessage ? errorMessage : _react2.default.createElement(
                                    'p',
                                    (_React$createElement12 = {
                                        'data-codecept-selector-node': 'p',
                                        'data-codecept-selector-file': 'SecureLogin'
                                    }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'SecureLogin'), _React$createElement12),
                                    'Automatic login in progress. Please wait.'
                                ),
                                this.props.children
                            )
                        ),
                        _react2.default.createElement(_CopyrightFooter2.default, _extends({}, this.props, (_extends4 = {
                            'data-codecept-selector-node': 'CopyrightFooter',
                            'data-codecept-selector-file': 'SecureLogin'
                        }, _defineProperty(_extends4, 'data-codecept-selector-node', 'CopyrightFooter'), _defineProperty(_extends4, 'data-codecept-selector-file', 'SecureLogin'), _defineProperty(_extends4, 'data-codecept-selector-node', 'CopyrightFooter'), _defineProperty(_extends4, 'data-codecept-selector-file', 'SecureLogin'), _extends4)))
                    )
                )
            )
        );
    } //# TODO: remove children
});
exports.default = SecureLogin;