'use strict';

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var SAVED_SEARCH_LISTER_CONSTANTS = require('../../../ruby-component-saved-search-lister/src/common/constants');

var savedSearchMixin = {
    mixinName: 'rubyComponentMixinListerSavedSearch',
    propTypes: {
        savedSearchListerID: PropTypes.string.isRequired,
        savedSearchContext: PropTypes.string
    },
    getDefaultProps: function getDefaultProps(props, componentClass) {
        return {
            savedSearchListerID: SAVED_SEARCH_LISTER_CONSTANTS.COMPONENT_NAME,
            savedSearchContext: null //props.id || componentClass.componentName
        };
    },
    getSavedSearchContext: function getSavedSearchContext() {
        //# TODO: support overriding context?
        //# we should use the same context used for the lister pages 

        if (!this.getState().routeParams.routeActive) {
            console.warn('getSavedSearchContext() was called for ' + this.getID() + ' when it is not active');
        }

        return this.props.savedSearchContext || window.location.pathname;
    },
    getSavedSearchListerDependencies: function getSavedSearchListerDependencies() {
        var rootModule = this.getRoot();

        var savedSearchLister = rootModule.findDescendentByID(this.props.savedSearchListerID);
        var savedSearchListerUrl = rootModule.getUrlForComponent_fromModule_withParams(savedSearchLister, this);

        return {
            savedSearchLister: savedSearchLister,
            savedSearchListerUrl: savedSearchListerUrl //# DEPRECATED 
            , savedSearchListerRoute: savedSearchListerUrl
        };
    }
};

module.exports = savedSearchMixin;