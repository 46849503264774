'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TabConnector = require('./reactComponents/TabConnector');

var _TabConnector2 = _interopRequireDefault(_TabConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var RubyWords = require('@rubyapps/ruby-words');

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldSetMixin = require('@rubyapps/ruby-component-mixin-field-set');
var anchorNavigationMixin = require('@rubyapps/ruby-component-mixin-anchor-navigation');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldDynamicMixin = require('@rubyapps/ruby-component-mixin-field-dynamic');

//import Tab from './reactComponents/Tab';
var RCTab = RubyComponent.createClass({
    getReactClassFix: true //# needed because of the way Material Tabs interact with this
    , mixins: [baseFieldMixin, fieldValidationMixin, fieldSetMixin, anchorNavigationMixin, fieldDynamicMixin],
    propTypes: {
        reactProps: PropTypes.object,
        hashRef: PropTypes.string,
        __index: PropTypes.number
    },
    componentName: componentName,
    getDefaultProps: function getDefaultProps(props) {
        return _extends({
            visibleByDefault: props.__index == 0
        }, props.label ? { hashRef: RubyWords.slug(props.label) } : {});
    },
    reducer: require('./reducer'),
    action: require('./action'),
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();
        var selfAction = this.getAction();

        return {
            selfSelector: selfSelector,
            selfAction: selfAction
        };
    },
    getReactClass: function getReactClass() {
        //# TODO: if we need the tabs to be connected, we will need to figure out how to fix it
        return _TabConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var TabComponent = this.getReactClass();

        return _react2.default.createElement(TabComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'TabComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TabComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TabComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    getInitialState: function getInitialState() {
        return {
            pristineFields: null,
            pristineErrors: null //# might not need
            , error: null
        };
    },
    CONSTANTS: CONSTANTS,

    getSelfIndex: function getSelfIndex() {
        var parentTabsComponent = this.getParent();
        var siblings = parentTabsComponent.getChildren();

        var selfIndex = _lodash2.default.indexOf(siblings, this);

        return selfIndex;
    }

    //# Overrides
    , navigateToSelf: function navigateToSelf() {
        var store = this.getStore();
        var parentTabsComponent = this.getParent();
        var parentActions = parentTabsComponent.getAction().generators;

        var selfIndex = this.getSelfIndex();

        store.dispatch(parentActions.setSelectedIndex(selfIndex));
    }

    //# UTILS
    //# returns true if this tab is the active tab
    , isVisible: function isVisible() {
        var parentTabsComponent = this.getParent();
        var selfIndex = this.getSelfIndex();

        var _parentTabsComponent$ = parentTabsComponent.getState(),
            selectedIndex = _parentTabsComponent$.selectedIndex;

        return selectedIndex == selfIndex;
    }
});

module.exports = RCTab;