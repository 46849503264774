'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var RubyComponentForms__CONSTANTS = require('@rubyapps/ruby-component-forms/src/common/constants');

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;
var action = require('./action');
var reducer = require('./reducer');
var middleware = require('./middleware');


var path = require('path');
var coreListerConfigs = ({"api_log":require("../../../../common/listers/api_log.js"),"changelog":require("../../../../common/listers/changelog.js"),"proposal_stage":require("../../../../common/listers/proposal_stage.js"),"proposal":require("../../../../common/listers/proposal.js"),"user":require("../../../../common/listers/user.js")});

//# Mixins
var accessControlMixin = require('@rubyapps/ruby-component-mixin-access-control');

var RCListerConfigs = RubyComponent.createClass({
    mixins: [accessControlMixin, require('./mixin')],

    propTypes: {
        listerConfigs: PropTypes.object,
        formsId: PropTypes.string,
        augmentors: PropTypes.shape({
            listerConfig: PropTypes.arrayOf(PropTypes.func)
        }),
        defaultListerConfig: PropTypes.object
    },
    componentName: componentName,
    action: action,
    reducer: reducer,
    middleware: middleware,

    getDefaultProps: function getDefaultProps() {
        return {
            formsId: RubyComponentForms__CONSTANTS.COMPONENT_NAME,
            defaultListerConfig: CONSTANTS.DEFAULT_CONFIG
        };
    },
    dependencies: function dependencies() {
        var root = this.getRoot();

        var formsComponent = root.findDescendentByID(this.props.formsId);

        return {
            formsComponent: formsComponent
        };
    }

    //# == Utility Methods =========================================//
    , listerConfigsByKey: function listerConfigsByKey() {
        //# get listerConfigs from template
        var _getDependencies = this.getDependencies(),
            formsComponent = _getDependencies.formsComponent;

        var templatesByKey = formsComponent.getState();
        var templateListerConfigs = _lodash2.default.reduce(templatesByKey, function (collector, template, templateKey) {
            var templateListerConfig = template.listerConfig;

            if (templateListerConfig) {
                collector[templateKey] = templateListerConfig;
            }

            return collector;
        }, {});

        return _extends({}, coreListerConfigs, this.props.listerConfigs, templateListerConfigs);
    },
    listerConfigForKey: function listerConfigForKey(key, callingListerModule) {
        var listerConfigsByKey = this.listerConfigsByKey();
        var callingListerModule__listerConfig = _lodash2.default.get(callingListerModule, 'props.listerConfig');

        var rawConfig = listerConfigsByKey[key] || callingListerModule__listerConfig || this.props.defaultListerConfig;

        var augmentedConfig = this.getAugmentedListerConfig(rawConfig, key, callingListerModule);

        return augmentedConfig;
    },

    getAugmentedListerConfig: function getAugmentedListerConfig(listerConfig, key, callingListerModule) {
        var _this = this;

        var _getDependencies2 = this.getDependencies(),
            formsComponent = _getDependencies2.formsComponent;

        var template = formsComponent.getAugmentedTemplateForKey(key, undefined, callingListerModule);
        var ctx = {
            template: template
        };

        var mixins = this.getMixins();
        var augmentedListerConfigFns = [].concat(mixins.filter(function (mixin) {
            return mixin.hasOwnProperty('augmentedListerConfig');
        }).map(function (mixin) {
            return mixin.augmentedListerConfig;
        }));

        var clonedListerConfigToAugment = _lodash2.default.cloneDeep(listerConfig);

        var augmentedListerConfig = _lodash2.default.reduce(augmentedListerConfigFns, function (result, augmentor) {
            return augmentor.call(_this, result, key, callingListerModule, ctx);
        }, clonedListerConfigToAugment);

        return augmentedListerConfig;
    }

});
module.exports = RCListerConfigs;