'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.unflatten = unflatten;
exports.flatten = flatten;
exports.pathCalculator = pathCalculator;
exports.getPrunedSitemap_fromSitemap = getPrunedSitemap_fromSitemap;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//var rubyLogger = require('@rubyapps/ruby-logger');
//var logger = rubyLogger.getLogger('ruby-sitemap:sitemapData');

var DEFAULT_ID_KEY = 'id';
var DEFAULT_PARENT_KEY = 'parent_id';
var DEFAULT_CHILDREN_KEY = 'children';

var MAX_PATH_SIZE = 10; // cap hierarchy size off @ 10 - prevent cycles from breaking stuff

/* Implementation of unflatten
 * is based on http://stackoverflow.com/a/22072374/3326617
 *
 * modifications:
 * - options: parentKey
 * - sort by display_order
 */
function unflatten(list, parent) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    var idKey = options.idKey || DEFAULT_ID_KEY;
    var parentKey = options.parentKey || DEFAULT_PARENT_KEY;
    var childrenKey = options.childrenKey || DEFAULT_CHILDREN_KEY;

    // not children of parent but descendants
    var indirectDescendants = _lodash2.default.filter(list, function (page) {
        return !parent(page);
    });

    //return _unflatten(_.cloneDeep(list), parent, options);
    var children = _lodash2.default.reduce(list, function (children, page) {
        var isChildPage = parent(page);
        if (!isChildPage) {
            return children;
        }

        // children of `page`
        var grandChildren = unflatten(indirectDescendants, function (potentialGrandChild) {
            return potentialGrandChild[parentKey] == page[idKey];
        }, options);

        return children.concat([grandChildren.length ? _lodash2.default.assign({
            children: grandChildren
        }, page) : page]);
    }, []);

    return children.sort(pageRankComparison);

    function pageRankComparison(a, b) {
        return getPageRank(a) - getPageRank(b);
    }

    function getPageRank(page) {
        var rankKey = options.rankKey || 'rank';
        return page[rankKey] || Infinity;
    }
}

function flatten(hierarchy) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var idKey = options.idKey || DEFAULT_ID_KEY;
    var parentKey = options.parentKey || DEFAULT_PARENT_KEY;
    var childrenKey = options.childrenKey || DEFAULT_CHILDREN_KEY;

    if (!hierarchy || !hierarchy.length) {
        return [];
    }

    return hierarchy.reduce(function (flatArray, hElem) {
        var flattenedChildren = flatten(hElem.children); // depth first
        var flatSegment = [_lodash2.default.pickBy(hElem, function (val, key) {
            return key !== childrenKey;
        })];

        if (flattenedChildren.length) {
            flatSegment = flatSegment.concat(flattenedChildren);
        }

        return flatArray.concat(flatSegment);
    }, []);
}

function pathCalculator(array, options) {
    if (!Array.isArray(array)) {
        return undefined;
    }
    options = options || {};

    var idKey = options.idKey || DEFAULT_ID_KEY;
    var parentKey = options.parentKey || DEFAULT_PARENT_KEY;

    var idMap = array.reduce(function (mapObj, item) {
        mapObj[item[idKey]] = item;
        return mapObj;
    }, {});

    var pathMap = array.reduce(function (mapObj, item) {
        var path = [];
        var parent = item[parentKey];
        while (!isNaN(parent) && parent !== null && path.length <= MAX_PATH_SIZE) {
            // while parent id is a valid #
            var parentRec = idMap[parent];
            path.push(parent);
            if (parentRec) {
                parent = parentRec[parentKey];
            } else {
                parent = undefined;
            }
        }
        mapObj[item[idKey]] = path; // TODO: don't need to reverse? or create path string?
        return mapObj;
    }, {});

    return function calculator(id) {
        return pathMap[id];
    };
}

function getPrunedSitemap_fromSitemap(sitemap) {

    var rootIds = _lodash2.default.get(sitemap, 'result') || [];
    var sitemapItems = _lodash2.default.get(sitemap, 'entities.sitemapItem') || {};

    var sitemapWithPrunedChildren = _lodash2.default.mapValues(sitemapItems, function (item) {
        return _extends({}, item, {
            children: (item.children || []).filter(function (childId) {
                return sitemapItems[childId];
            })
        });
    });

    return {
        result: rootIds,
        entities: {
            sitemapItem: sitemapWithPrunedChildren
        }
    };
}