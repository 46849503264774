'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    CardHeader: {
        textStyle: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: 0
        }
    }
};