"use strict";

/* jshint -W138 */
var defaultState = 3;

module.exports = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
    var action = arguments[1];

    switch (action.type) {
        default:
            return state;
    }
};