'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _Header = require('./Header');

var _Header2 = _interopRequireDefault(_Header);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector,
        nameSelector = _selfModule$getDepend.nameSelector;

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);
        var nameState = nameSelector && nameSelector(state);
        //# get formValue as backup if name state hasn't rendered yet
        var formValue = selfModule.getParentFormComponent().formValue();

        var listName = _lodash2.default.get(selfState, 'props.breadcrumbLabel') || selfModule.getStaticListName();

        return _extends({}, ownProps, selfState, {
            listRoute: selfModule.getListRoute(),
            listName: listName,
            name: _lodash2.default.get(nameState, ['fields', ownProps.keyForNameComponent, 'value'], _lodash2.default.get(formValue, [ownProps.keyForNameComponent], null))
        });
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Header2.default);
}