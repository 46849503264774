'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initialState;

var _immutable = require('immutable');

function initialState(batchMoveProvider) {
  return (0, _immutable.fromJS)({
    batchMoveActive: false,
    batchMoveProvider: batchMoveProvider
  });
}