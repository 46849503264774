'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _reduxBatchedActions = require('redux-batched-actions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var Promise = require('bluebird');
var request = require('@rubyapps/ruby-superagent');
var urljoin = require('url-join');

var _require = require('../common/constants'),
    REQUEST_TYPE_OPTIONS_RETRIEVAL = _require.REQUEST_TYPE_OPTIONS_RETRIEVAL;

function typesWithID(id) {
    return {
        RETRIEVE_OPTIONS: '@@ruby-app/' + id + '/RETRIEVE_OPTIONS',
        CLEAR_CACHED_OPTIONS: '@@ruby-app/' + id + '/CLEAR_CACHED_OPTIONS',
        CUSTOM_RESET_STATE: '@@ruby-app/' + id + '/CUSTOM_RESET_STATE',
        SET_OPTIONS: '@@ruby-app/' + id + '/SET_OPTIONS',
        SET_FILTERED_OPTIONS: '@@ruby-app/' + id + '/SET_FILTERED_OPTIONS',
        SET_REQUESTED_OPTIONS_URL: '@@ruby-app/' + id + '/SET_REQUESTED_OPTIONS_URL',
        SET_REFRESH_REQUEST_TIMESTAMP: '@@ruby-app/' + id + '/SET_REFRESH_REQUEST_TIMESTAMP',
        SET_REQUESTED_TIMESTAMP: '@@ruby-app/' + id + '/SET_REQUESTED_TIMESTAMP',
        SET_REQUESTED_QUERY: '@@ruby-app/' + id + '/SET_REQUESTED_QUERY',
        SET_SEARCH_VALUE: '@@ruby-app/' + id + '/SET_SEARCH_VALUE',
        DEFAULT_TO_FIRST_SUCCESSFUL: '@@ruby-app/' + id + '/DEFAULT_TO_FIRST_SUCCESSFUL'
    };
}

var generators = {
    preloadOptions: function preloadOptions() {
        var _this = this;

        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES,
            generators = _getAction.generators;

        var count = this.props.count;


        return function (dispatch, getState) {
            dispatch(generators.retrieveOptionsWithQuery(_this.props.preloadOptionsQuery));
        };
    },
    setSearchValue: function setSearchValue(value, callback) {
        var _this2 = this;

        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES,
            generators = _getAction2.generators;

        return function (dispatch, getState) {
            //# get filtered options
            dispatch(generators.setSearchValue_pure(value));
            dispatch(generators.retrieveOptionsWithQuery({
                search_input: value || '',
                count: _this2.props.count
            }, _extends({
                allowAbort: true

            }, callback ? {
                thenableArguments: [function (res) {
                    callback();
                }, function (err) {
                    callback(err);
                }]
            } : {}) //allowAbort when hitting URLs
            ));
        };
    },
    setSearchValue_pure: function setSearchValue_pure(value) {
        var _getAction3 = this.getAction(),
            TYPES = _getAction3.TYPES,
            generators = _getAction3.generators;

        return {
            type: TYPES.SET_SEARCH_VALUE,
            payload: {
                value: value
            }
        };
    }
    /*
     * @param {Object} query - query object for filtering
     * @param {Object} options
     * @param {String} options.url - url override
     * @param {Object[]} options.options - hardcoded options override
     * @param {Boolean} options.allowAbort - If we're making an asyn call (like a request), we want to allow abortions so that we honor the most recent request
     * @param {Boolean} options.clearCache - if true, we clear out the options cache
     */
    , retrieveOptionsWithQuery: function retrieveOptionsWithQuery(query) {
        var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
            url = _ref.url,
            options = _ref.options,
            _ref$allowAbort = _ref.allowAbort,
            allowAbort = _ref$allowAbort === undefined ? false : _ref$allowAbort,
            _ref$clearCache = _ref.clearCache,
            clearCache = _ref$clearCache === undefined ? false : _ref$clearCache,
            _ref$thenableArgument = _ref.thenableArguments,
            thenableArguments = _ref$thenableArgument === undefined ? undefined : _ref$thenableArgument;

        var _getAction4 = this.getAction(),
            TYPES = _getAction4.TYPES,
            generators = _getAction4.generators;

        url = url || this.url();
        var hardcodedOptions = options || this.props.options;

        return function (dispatch, getState) {
            Promise.props({
                hardcodedData: Promise.fromCallback(function (cb) {
                    hardcodedOptions ? dispatch(generators.retrieveOptionsFromHardcodedOptions_withQuery(hardcodedOptions, query, [function (data) {
                        return cb(null, data);
                    }, function (err) {
                        return cb(err);
                    }])) : cb(null, []);
                }),
                remoteData: Promise.fromCallback(function (cb) {
                    //# call retrieveOptionsFromUrl_withQuery even if !url to handle aborting
                    //# requests and clearing state associated with the previous url
                    url ? dispatch(generators.retrieveOptionsFromUrl_withQuery(url, query, [function (data) {
                        return cb(null, data);
                    }, function (err) {
                        return cb(err);
                    }], allowAbort, clearCache)) : cb(null, []);
                })
            }).then(function (_ref2) {
                var hardcodedData = _ref2.hardcodedData,
                    remoteData = _ref2.remoteData;

                var normalizedRemoteData = remoteData;

                var dataUnion = hardcodedData.concat(normalizedRemoteData);
                var dataUnion_withoutHiddens = dataUnion.filter(function (option) {
                    return !option.isHidden;
                });

                dispatch(generators.setFilteredOptions(dataUnion_withoutHiddens));

                dispatch(generators.setRequestedQuery(query));

                dispatch(generators.updateCachedOptions_withAdditional(normalizedRemoteData));
                //# do not need to add hardcoded options to cache

                if (thenableArguments) {
                    var _Promise$resolve;

                    return (_Promise$resolve = Promise.resolve(dataUnion)).then.apply(_Promise$resolve, _toConsumableArray(thenableArguments));
                }
            });
        };
    },

    retrieveOptionsFromHardcodedOptions_withQuery: function retrieveOptionsFromHardcodedOptions_withQuery(options) {
        var _this3 = this;

        var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var thenableArguments = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;

        if (!query) {
            query = {};
        }

        var _getAction5 = this.getAction(),
            TYPES = _getAction5.TYPES,
            generators = _getAction5.generators;

        var completeOptions = this.getDisambiguatedOptionsFromHardcodedOptions(options);
        var data = query.search_input ? _lodash2.default.filter(completeOptions, function (option) {
            var optionText = _this3.getDisplayTextFromOption(option).toLowerCase();
            var searchText = query.search_input.toLowerCase();
            return optionText.indexOf(searchText) !== -1;
        }) : completeOptions;

        var dataPromise = Promise.resolve(data);

        return function (dispatch, getState) {
            if (thenableArguments) {
                return dataPromise.then.apply(dataPromise, thenableArguments);
            }

            return dataPromise;
        };
    },

    retrieveOptionsFromUrl_withQuery: function retrieveOptionsFromUrl_withQuery(url, query, thenableArguments) {
        var allowAbort = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
        var clearCache = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
        var requestType = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : REQUEST_TYPE_OPTIONS_RETRIEVAL;

        var selfModule = this;
        var selfState = selfModule.getState();

        var _getAction6 = this.getAction(),
            TYPES = _getAction6.TYPES,
            generators = _getAction6.generators;

        var _getDependencies = this.getDependencies(),
            RubyClientFKSelector = _getDependencies.RubyClientFKSelector;

        //# kill previous request


        if (allowAbort) {
            _lodash2.default.get(selfModule, ['_requestObjectByType', requestType]) && selfModule._requestObjectByType[requestType].abort();
            selfModule.cacheRequestObject(null, requestType);
        }

        return function (dispatch, getState) {
            var applicationState = getState();
            var ruby_client_fk = RubyClientFKSelector(applicationState);
            var additionalQuery = /ruby_client_fk/.test(url) ? {} : { ruby_client_fk: ruby_client_fk };

            var finalQuery = query ? _extends({}, additionalQuery, query) : additionalQuery;

            dispatch((0, _reduxBatchedActions.batchActions)([generators.setRequestedOptionsUrl(url, requestType), generators.setRequestedTimestamp(new Date())]));

            if (!url) {
                //# clear cached options bc previous url is no longer valid
                clearCache && _lodash2.default.get(selfState, 'props.options') && dispatch(generators.clearCachedOptions());
                return Promise.resolve([]);
            }

            var requestObject = request.get(url).query(finalQuery);
            selfModule.cacheRequestObject(requestObject, requestType);

            var requestPromise = requestObject.then(function success(response) {
                selfModule.cacheRequestObject(null, requestType);
                var selfState = selfModule.getState();

                var data = selfModule.normalizedOptions(response.body.data || []);
                if (_lodash2.default.result(selfModule, ['getState', 'requestedOptionsUrlByType', requestType]) !== url) {
                    // if the options url has changed, don't set this value
                    // the response for this request might come after the
                    // response for a more recent request and overwrite the data

                    console.warn('Url option has changed from [' + url + '] to [' + _lodash2.default.result(selfModule, ['getState', 'requestedOptionsUrlByType', requestType]) + ']. This should be ok and we should allow the data through since if we didn\'t want old data, the caller should have passed the allowAbort flag');

                    return data;
                }

                clearCache && _lodash2.default.get(selfState, 'props.options') && dispatch(generators.clearCachedOptions());

                return data;
            }, function (error) {
                selfModule.cacheRequestObject(null, requestType);

                throw error;
            });

            if (thenableArguments) {
                return requestPromise.then.apply(requestPromise, thenableArguments);
            }

            return requestPromise;
        };
    }

    //# DEPRECATED 20180823
    //# should only be called when caching options
    /*
    , cacheOptionsFromUrl_withQuery: function(url, query, shouldClear=false) {
        const selfModule = this;
        const selfState = selfModule.getState();
         const { TYPES, generators } = this.getAction();
        const {
            RubyClientFKSelector
        } = this.getDependencies();
         return generators.retrieveOptionsFromUrl_withQuery(url, query, [(data) => {
            const {dispatch} = this.getStore();
            const selfState = this.getState();
             shouldClear && _.get(selfState, 'props.options') && dispatch(generators.clearCachedOptions());
            dispatch(generators.updateCachedOptions_withAdditional(data));
        }])
    }
    */

    , updateCachedOptions_withAdditional: function updateCachedOptions_withAdditional(options) {
        var _this4 = this;

        var _getAction7 = this.getAction(),
            TYPES = _getAction7.TYPES,
            generators = _getAction7.generators;

        var selfState = this.getState();
        var propOptions = this.props.options ? this.props.options : [];
        var cachedOptions = selfState.props.options || [];
        var remoteOptions = this.normalizedOptions(options ? options : []);

        var mergedOptions = _lodash2.default.uniqBy(propOptions.concat(cachedOptions, remoteOptions), 'value');

        var isRequired = _lodash2.default.get(this.props, ['verify', 'required']);
        var expandedOptions = isRequired ? mergedOptions : [].concat(mergedOptions);

        var selfSelector = this.getDefaultSelector();

        return function (dispatch, getState) {
            //# merge with prop options
            var applicationState = getState();
            var selfState = selfSelector(applicationState); //# TODO: BUG: this is undefined if you have a DynamicForm + TokenTagger nested under Popover

            var currentPropOptions = _this4.props.options ? _this4.props.options : [];
            var cachedOptions = selfState.props.options || [];
            var cachedPropOptions = selfState.props.propOptions || [];
            var remoteOptions = (options ? options : []).map(function (option) {
                return _extends({}, option, {
                    value: option.value || option.id,
                    text: option.text || option.name
                });
            });

            var propOptionsToRemoveFromCache = _lodash2.default.differenceBy(cachedPropOptions, currentPropOptions);

            var mergedOptions = _lodash2.default.differenceBy(_lodash2.default.uniqBy(currentPropOptions.concat(cachedOptions, remoteOptions), 'value'), propOptionsToRemoveFromCache);

            var isRequired = _lodash2.default.get(_this4.props, ['verify', 'required']);
            var expandedOptions = isRequired ? mergedOptions : [].concat(mergedOptions);
            var fieldValue = _lodash2.default.get(selfState, ['fields', _this4.props.key, 'value']);
            if (!fieldValue && _this4.props.defaultToFirstOption && expandedOptions.length) {
                var firstOptionValue = expandedOptions[0].value;
                dispatch(generators.setFieldValueByKey(firstOptionValue, _this4.props.key));
                dispatch({
                    type: TYPES.DEFAULT_TO_FIRST_SUCCESSFUL
                });
            }

            dispatch({
                type: TYPES.SET_OPTIONS,
                payload: {
                    options: expandedOptions
                    // we need to track prop options in redux state because we cache the
                    // full set of options (prop + remote), and the previous cached full
                    // options set is used when constructing the merged next set. without
                    // keeping track of the old prop options, we'd have no way to diff
                    // against current prop options in case some prop options need to be removed
                    , propOptions: currentPropOptions
                }
            });
        };
    },

    clearCachedOptions: function clearCachedOptions(filteredOptions) {
        //# merge with prop options
        var _getAction8 = this.getAction(),
            TYPES = _getAction8.TYPES,
            generators = _getAction8.generators;

        return {
            type: TYPES.CLEAR_CACHED_OPTIONS
        };
    },
    setFilteredOptions: function setFilteredOptions(filteredOptions) {
        //# merge with prop options
        var _getAction9 = this.getAction(),
            TYPES = _getAction9.TYPES,
            generators = _getAction9.generators;

        return {
            type: TYPES.SET_FILTERED_OPTIONS,
            payload: {
                filteredOptions: filteredOptions
            }
        };
    },

    setRequestedOptionsUrl: function setRequestedOptionsUrl(url, type) {
        //# merge with prop options
        var _getAction10 = this.getAction(),
            TYPES = _getAction10.TYPES,
            generators = _getAction10.generators;

        return {
            type: TYPES.SET_REQUESTED_OPTIONS_URL,
            payload: {
                url: url,
                type: type
            }
        };
    },

    setRequestedTimestamp: function setRequestedTimestamp(timestamp) {
        //# merge with prop options
        var _getAction11 = this.getAction(),
            TYPES = _getAction11.TYPES,
            generators = _getAction11.generators;

        return {
            type: TYPES.SET_REQUESTED_TIMESTAMP,
            payload: {
                timestamp: timestamp
            }
        };
    },
    setRefreshRequestTimestamp: function setRefreshRequestTimestamp(timestamp) {
        //# merge with prop options
        var _getAction12 = this.getAction(),
            TYPES = _getAction12.TYPES,
            generators = _getAction12.generators;

        return {
            type: TYPES.SET_REFRESH_REQUEST_TIMESTAMP,
            payload: {
                timestamp: timestamp
            }
        };
    },
    setRequestedQuery: function setRequestedQuery(query) {
        var _getAction13 = this.getAction(),
            TYPES = _getAction13.TYPES,
            generators = _getAction13.generators;

        return {
            type: TYPES.SET_REQUESTED_QUERY,
            payload: {
                query: query
            }
        };
    }

    //# NOTE: we're batching resetStore, and THUNKS don't work
    //# so you MUST override the rubyComponent.resetStore method and call on store.dispatch immediately 
    //# Hopefully, in the future, we can update how resetStore works and/or the batch plugin to 
    //# allow for batching thunks
    , resetStore: function resetStore() {
        var _this5 = this;

        var _getAction14 = this.getAction(),
            TYPES = _getAction14.TYPES,
            generators = _getAction14.generators;

        var selfSelector = this.getDefaultSelector();
        return function (dispatch, getState) {
            var applicationState = getState();
            var selfState = selfSelector(applicationState);
            var options = _lodash2.default.get(selfState, 'props.options', []) || [];

            _this5._cachedUrlDependentFieldValues = null; //# NOTE: probably not needed, but we should clear it

            dispatch({
                type: TYPES.CUSTOM_RESET_STATE
            });

            if (!_this5.props.hasOwnProperty('default') && _this5.props.defaultToFirstOption && options.length) {
                var firstOptionValue = _this5.coercedValue(options[0].value);
                dispatch(generators.setFieldValueByKey(firstOptionValue, _this5.props.key));
            }
        };
    }

};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};