'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.superagentProvider = undefined;
exports.RemotePlugin = RemotePlugin;

var _actions = require('./actions');

var actions = _interopRequireWildcard(_actions);

var _constants = require('./constants');

var constants = _interopRequireWildcard(_constants);

var _helpers = require('./helpers');

var helpers = _interopRequireWildcard(_helpers);

var _reducer = require('./reducer');

var reducers = _interopRequireWildcard(_reducer);

var _initialState = require('./initial-state');

var _initialState2 = _interopRequireDefault(_initialState);

var _bindRemoteProvider = require('./util/bind-remote-provider');

var _bindRemoteProvider2 = _interopRequireDefault(_bindRemoteProvider);

var _superagentProvider2 = require('./providers/superagent-provider');

var _superagentProvider = _interopRequireWildcard(_superagentProvider2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

exports.superagentProvider = _superagentProvider;
//import combineRemoteActions from './util/combine-remote-actions';

// Remote providers

function RemotePlugin(provider, remoteConfig) {
  var remoteProvider = (0, _bindRemoteProvider2.default)(provider, remoteConfig);
  return {
    name: 'GriddleRemote',
    actions: actions,
    storeBoundActions: [actions.requestToLoadData, actions.filterData, actions.setPageSize, actions.sort, actions.addSortColumn, actions.loadNext, actions.loadPrevious, actions.loadPage, actions.refreshCurrentPage],
    constants: constants,
    helpers: helpers,
    states: (0, _initialState2.default)(remoteProvider),
    reducers: reducers,
    components: {}
  };
};