'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reactRouterRedux = require('react-router-redux');

var CONSTANTS = require('../../common/constants');
var path = require('path');
var TYPES = CONSTANTS.ROUTE_ACTION_TYPES;

var routeHelperActionGenerator = require('../routeHelperMixin/action');
module.exports = function () {
    var selfModule = this;
    var selfID = selfModule.getID();
    var localTypes = {
        SET_ROUTE_PARAMS: path.join(TYPES.ACTION_TYPE_PREFIX, selfID, TYPES.SET_ROUTE_PARAMS_SUFFIX),
        SET_HASH: path.join(TYPES.ACTION_TYPE_PREFIX, selfID, TYPES.SET_HASH_SUFFIX),
        LEAVING_ROUTE: path.join(TYPES.ACTION_TYPE_PREFIX, selfID, TYPES.LEAVING_ROUTE_SUFFIX)
    };

    var routeHelperActions = routeHelperActionGenerator.apply(this, arguments);

    return {
        TYPES: _extends(localTypes, routeHelperActions.TYPES),
        generators: _extends({
            setRouteParams: function setRouteParams(payload) {
                return {
                    type: localTypes.SET_ROUTE_PARAMS,
                    payload: payload
                };
            },
            leavingRoute: function leavingRoute(payload) {
                return {
                    type: localTypes.LEAVING_ROUTE,
                    payload: payload
                };
            }

            //# DEPRECATING 20201027 - should always use the 'react-router-redux' actions. Let our middleware handle converting the url to routeParams appropriately
            /**
             *  @param {Object} payload.hash - hash object where key-value pairs mirror query params. So the formatted hash will look like: #param=value&param=value
             */
            , setHash: function setHash(hash) {
                //# NOTE: hash should always be an object now, but we're not preventing strings
                return {
                    type: localTypes.SET_HASH,
                    payload: {
                        hash: hash
                    }
                };
            },
            push: _reactRouterRedux.push,
            replace: _reactRouterRedux.replace
        }, routeHelperActions.generators)
    };
};