'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reduxBatchedActions = require('redux-batched-actions');

var Promise = require('bluebird');


function typesWithID(id) {
    return {
        SET_VISIBLE: '@@ruby-app/' + id + '/SET_VISIBLE',
        UNSET_VISIBLE: '@@ruby-app/' + id + '/UNSET_VISIBLE'
    };
}

var generators = {
    _set_visible: function _set_visible() {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        return {
            type: TYPES.SET_VISIBLE
        };
    },
    set_visible: function set_visible() {
        var _this = this;

        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES;

        return function (dispatch, getState) {
            var selfState = _this.getState(getState());

            var shouldUpdateChildren = !selfState.isVisibleLatched;
            var parentFormComponent = _this.getParentFormComponent();

            if (!shouldUpdateChildren) {
                dispatch(_this.getAction().generators._set_visible());

                return;
            }

            //# When being called by field-tabs setSelectedIndex
            //# it might have been triggeed by the #hash which references the tab 
            //# this happens before we retrieve data so there's contention between the two
            //# we check whether there's a pending promise before we set visibility 
            //# just in case 
            var promisedOnceResolved = parentFormComponent.getStatefulCacheForKey('promisedOnceResolved');

            return (promisedOnceResolved || Promise.resolve()).then(_.identity, _.identity).then(function () {

                var parentFormValue = parentFormComponent.formValue();
                var selfFormValue = _this.formValue();
                var selfErrorFormValue = _this.getState().error;

                dispatch(_this.getAction().generators._set_visible());

                //# update children
                var updateChildren__actionCollector = [];
                var updateChildren__collectAction = function updateChildren__collectAction(value) {
                    updateChildren__actionCollector.push(value);
                };
                var existingChildren = _this.getChildren();
                if (existingChildren && existingChildren.length == 0) {
                    _this.updateChildren(true, updateChildren__collectAction);
                }

                var formValueToLocalState__promises = [];

                if (selfFormValue) {
                    var actionCollector = [];
                    var collectAction = function collectAction(value) {
                        actionCollector.push(value);
                    };

                    //# Because of pristine form data, we need  to somehow update it for the following set of data

                    var promiseTree = _this._formValueToLocalState(selfFormValue, collectAction, false, parentFormValue);

                    promiseTree.then(function () {
                        dispatch((0, _reduxBatchedActions.batchActions)(actionCollector));
                        dispatch((0, _reduxBatchedActions.batchActions)(updateChildren__actionCollector)); //# request rerender of children

                        //# get updated formValue and then update the pristineFormData

                        var formValue_newSelf = _this.formValue();
                        //# NOTE: the selfFormValue above is effectively the pristineFormData partial
                        //# and formValue_newSelf is the rendere variant of pristineFormData partial
                        //# so we need to update the parentFormComponent's pristineFormData to account for the browser-side defaults
                        var fieldKey = _this.props.key;

                        var formValue_newChildren = fieldKey ? formValue_newSelf[fieldKey] : formValue_newSelf;

                        var fieldKeypathArr = _this.getFieldKeypathArr();

                        var parentFormComponent = _this.getParentFormComponent();
                        var pristineFormData_existing = parentFormComponent.getState().pristineFormData;

                        var pristineFormData_updated = _.cloneDeep(pristineFormData_existing);

                        var targetObjectToMerge = fieldKeypathArr.length ? _.get(pristineFormData_updated, fieldKeypathArr, {}) : pristineFormData_updated;
                        //# NOTE: targetObjectToMerge might be null (eg. for namespace cause the namespace value would be null in the pristine form if it wasn't previously saved)

                        _extends(targetObjectToMerge, formValue_newChildren);

                        if (fieldKeypathArr.length) {
                            //# set updated object at keypath
                            //# otherwise, we assume the pristineFormData was updated
                            _.set(pristineFormData_updated, fieldKeypathArr, targetObjectToMerge);
                        }

                        dispatch(parentFormComponent.getAction().generators.setPristineFormData(pristineFormData_updated));
                    });

                    formValueToLocalState__promises.push(promiseTree);
                }

                if (selfErrorFormValue) {
                    var _actionCollector = [];
                    var _collectAction = function _collectAction(value) {
                        _actionCollector.push(value);
                    };

                    //# Because of pristine form data, we need  to somehow update it for the following set of data
                    var _promiseTree = _this._formValueToLocalState(selfErrorFormValue, _collectAction, true, {});

                    _promiseTree.then(function () {
                        dispatch((0, _reduxBatchedActions.batchActions)(_actionCollector));

                        //# update parent errors
                        _this.refreshParentErrors();
                    });

                    formValueToLocalState__promises.push(_promiseTree);
                }

                return Promise.all(formValueToLocalState__promises);
            });
        };
    },
    unset_visible: function unset_visible() {
        var _getAction3 = this.getAction(),
            TYPES = _getAction3.TYPES;

        return {
            type: TYPES.UNSET_VISIBLE
        };
    }
};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};