'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Toolbar = require('material-ui/Toolbar');

var _Chip = require('@rubyapps/ruby-material-ui/Chip');

var _Chip2 = _interopRequireDefault(_Chip);

var _AdvancedSearchUtilityBar = require('./AdvancedSearchUtilityBar.cssModule');

var _AdvancedSearchUtilityBar2 = _interopRequireDefault(_AdvancedSearchUtilityBar);

var _AdvancedSearchUtilityBar_style = require('./AdvancedSearchUtilityBar_style');

var _AdvancedSearchUtilityBar_style2 = _interopRequireDefault(_AdvancedSearchUtilityBar_style);

var _matchConstants = require('../../../common/matchConstants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

require('deepdash')(_lodash2.default);

// Material UI Components

var _require = require('../../constants'),
    FILTER_TAGS_PROPTYPE = _require.REACT__FILTER_TAGS_PROPTYPE;

var FilterTagsToolbarGroup = _react2.default.createClass({
    displayName: 'FilterTagsToolbarGroup',

    propTypes: {
        delegateFilterTagDelete: PropTypes.func,
        isAddFilterVisible: PropTypes.bool,
        filterTags: FILTER_TAGS_PROPTYPE,
        isFlat: PropTypes.bool,
        filterableFields: PropTypes.arrayOf(PropTypes.object),
        filterableFields__dataSourceConfig: PropTypes.object,
        staticFilterTags: FILTER_TAGS_PROPTYPE
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            delegateFilterTagDelete: function delegateFilterTagDelete() {},
            filterTags: [],
            isFlat: false,
            staticFilterTags: []
        };
    },

    renderChip: function renderChip(data) {
        var _this = this,
            _React$createElement,
            _React$createElement7,
            _React$createElement8,
            _extends2;

        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var disabledDelete = options.disabledDelete;

        //# get background color from muiTheme

        var baseTheme = this.context.muiTheme.baseTheme;

        var primary1Color = baseTheme.palette.primary1Color;
        var primary2Color = baseTheme.palette.primary2Color; //# Gray

        var fieldDataPath = data.fieldSpec.dataPath;

        //const fieldSpec_fromSearchableFields = jsonselect.match(`object:has(:root > .dataPath:val("${fieldDataPath}"))`, this.props.filterableFields);

        var fieldSpec_fromSearchableFields = _lodash2.default.findDeep(this.props.filterableFields, function (value, key, parentValue, context) {
            return value.dataPath == fieldDataPath;
        }, {
            childrenPath: 'children'
        });

        var fieldBreadcrumb = _lodash2.default.get(fieldSpec_fromSearchableFields, 'value.breadcrumb', null);
        var fieldLabel = _lodash2.default.get(fieldSpec_fromSearchableFields, 'value.label', data.fieldSpec.label);
        var matchText = data.matchSpec.text;
        var searchText = data.searchSpec.text;

        var dataKey = data.fieldSpec.dataPath + '-' + data.matchSpec.value;

        //# TODO We might want to have a better way of associating `matchSpec.value`
        //# with AND or OR operator. Currently - only CONTAINS_ALL_OF will map to
        //# to AND ... but this could change in the future
        var delimitingLogicalOperator = _lodash2.default.get(data, ['matchSpec', 'value']) === _matchConstants.matchConstants.CONTAINS_ALL_OF ? 'AND' : 'OR';

        return _react2.default.createElement(
            _Chip2.default,
            _extends({}, _AdvancedSearchUtilityBar_style2.default.Chip, (_extends2 = {
                onRequestDelete: disabledDelete ? null : function () {
                    return _this.props.delegateFilterTagDelete(dataKey);
                },
                key: dataKey,
                backgroundColor: primary1Color,
                'data-codecept-selector-node': 'Chip',
                'data-codecept-selector-file': 'FilterTagsToolbarGroup'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Chip'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Chip'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _extends2)),
            _react2.default.createElement(
                'div',
                (_React$createElement8 = { className: _AdvancedSearchUtilityBar2.default.chipContent, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'FilterTagsToolbarGroup'
                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _React$createElement8),
                fieldBreadcrumb ? _react2.default.createElement(
                    'span',
                    (_React$createElement = { className: _AdvancedSearchUtilityBar2.default.breadcrumb, 'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'FilterTagsToolbarGroup'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _React$createElement),
                    _lodash2.default.isArray(fieldBreadcrumb) ? fieldBreadcrumb.join("; ") : fieldBreadcrumb
                ) : null,
                _react2.default.createElement(
                    'span',
                    (_React$createElement7 = { className: _AdvancedSearchUtilityBar2.default.chipText, 'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'FilterTagsToolbarGroup'
                    }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _React$createElement7),
                    fieldLabel,
                    ' ',
                    matchText,
                    ' ',
                    _lodash2.default.map(_lodash2.default.castArray(searchText), function (text, idx) {
                        var _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6;

                        return idx !== 0 ? _react2.default.createElement(
                            'span',
                            (_React$createElement4 = {
                                'data-codecept-selector-node': 'span',
                                'data-codecept-selector-file': 'FilterTagsToolbarGroup'
                            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _React$createElement4),
                            _react2.default.createElement(
                                'span',
                                (_React$createElement2 = { style: { color: primary2Color }, 'data-codecept-selector-node': 'span',
                                    'data-codecept-selector-file': 'FilterTagsToolbarGroup'
                                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _React$createElement2),
                                ' ',
                                delimitingLogicalOperator,
                                ' '
                            ),
                            _react2.default.createElement(
                                'strong',
                                (_React$createElement3 = {
                                    'data-codecept-selector-node': 'strong',
                                    'data-codecept-selector-file': 'FilterTagsToolbarGroup'
                                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _React$createElement3),
                                text
                            )
                        ) : _react2.default.createElement(
                            'span',
                            (_React$createElement6 = {
                                'data-codecept-selector-node': 'span',
                                'data-codecept-selector-file': 'FilterTagsToolbarGroup'
                            }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _React$createElement6),
                            _react2.default.createElement(
                                'strong',
                                (_React$createElement5 = {
                                    'data-codecept-selector-node': 'strong',
                                    'data-codecept-selector-file': 'FilterTagsToolbarGroup'
                                }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _React$createElement5),
                                text
                            )
                        );
                    })
                )
            )
        );
    },
    renderTags: function renderTags(tags) {
        var _this2 = this;

        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        return tags.map(function (tag, idx) {
            var _React$createElement9, _React$createElement10;

            return idx ? _react2.default.createElement(
                'div',
                (_React$createElement10 = { style: { display: 'flex' }, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'FilterTagsToolbarGroup'
                }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _React$createElement10),
                _react2.default.createElement(
                    OperatorChip,
                    (_React$createElement9 = { labelStyle: _AdvancedSearchUtilityBar_style2.default.LogicalOperator.labelStyle, 'data-codecept-selector-node': 'OperatorChip',
                        'data-codecept-selector-file': 'FilterTagsToolbarGroup'
                    }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'OperatorChip'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'OperatorChip'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _React$createElement9),
                    'AND'
                ),
                _this2.renderChip(tag, options)
            ) : _this2.renderChip(tag, options);
        });
    },
    render: function render() {
        var _React$createElement11, _React$createElement12, _React$createElement13, _extends3;

        var _props = this.props,
            _props$staticFilterTa = _props.staticFilterTags,
            staticFilterTags = _props$staticFilterTa === undefined ? [] : _props$staticFilterTa,
            _props$filterTags = _props.filterTags,
            filterTags = _props$filterTags === undefined ? [] : _props$filterTags;

        var shouldRenderEqualOperator = !_lodash2.default.isEmpty([].concat(staticFilterTags, filterTags));

        return _react2.default.createElement(
            _Toolbar.ToolbarGroup,
            _extends({}, _AdvancedSearchUtilityBar_style2.default.ToolbarGroup__filterTags, (_extends3 = {
                className: _AdvancedSearchUtilityBar2.default.ToolbarGroup__filterTags,
                'data-codecept-selector-node': 'ToolbarGroup',
                'data-codecept-selector-file': 'FilterTagsToolbarGroup'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_extends3, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _defineProperty(_extends3, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_extends3, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _extends3)),
            _react2.default.createElement(
                'div',
                (_React$createElement13 = { style: { display: 'flex' }, 'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'FilterTagsToolbarGroup'
                }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _React$createElement13),
                shouldRenderEqualOperator ? _react2.default.createElement(
                    OperatorChip,
                    (_React$createElement11 = { labelStyle: _AdvancedSearchUtilityBar_style2.default.EqualOperator.labelStyle, 'data-codecept-selector-node': 'OperatorChip',
                        'data-codecept-selector-file': 'FilterTagsToolbarGroup'
                    }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'OperatorChip'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'OperatorChip'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _React$createElement11),
                    '='
                ) : null,
                _react2.default.createElement(
                    'div',
                    (_React$createElement12 = { className: _AdvancedSearchUtilityBar2.default.filterTagsWrapper, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'FilterTagsToolbarGroup'
                    }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _React$createElement12),
                    this.renderTags(this.prepFilterTagsForRender(staticFilterTags), { disabledDelete: true }),
                    this.renderTags(this.prepFilterTagsForRender(filterTags))
                )
            )
        );
    },
    prepFilterTagsForRender: function prepFilterTagsForRender(filterTags) {
        var _this3 = this;

        var filterTags_byDataPath_byMatch = this.filterTags_byDataPath_byMatch(filterTags);

        //# Now let's map thru filterTags and combine the tags that
        //# occur more than once for a given field AND match condition
        var mergedFilterTagsMap = {};

        var finalFilterTags = _lodash2.default.reduce(filterTags, function (acc, filterTag) {
            var dataPath = filterTag.fieldSpec.dataPath;
            var matchSpecValue = filterTag.matchSpec.value;

            var filterTagsMatching_dataPath_andMatchValue = filterTags_byDataPath_byMatch[dataPath][matchSpecValue];

            if (_lodash2.default.get(mergedFilterTagsMap, [dataPath, matchSpecValue])) {
                return acc;
            }

            _lodash2.default.set(mergedFilterTagsMap, [dataPath, matchSpecValue], true);

            return filterTagsMatching_dataPath_andMatchValue.length === 1 ? acc.concat(filterTag) : acc.concat(_this3.singleFilterTag_fromFilterTags(filterTagsMatching_dataPath_andMatchValue));
        }, []);

        return finalFilterTags;
    },
    filterTags_byDataPath_byMatch: function filterTags_byDataPath_byMatch(filterTags) {
        var filterTags_byDataPath_byMatch = _lodash2.default.reduce(filterTags, function (acc, filterTag) {
            var dataPath = filterTag.fieldSpec.dataPath;
            var matchSpecValue = filterTag.matchSpec.value;

            var path = [dataPath, matchSpecValue];

            var mappedFilterTag = _lodash2.default.get(acc, path);

            return !mappedFilterTag ? _lodash2.default.set(acc, path, [].concat(filterTag)) : _lodash2.default.set(acc, path, mappedFilterTag.concat(filterTag));
        }, {});

        return filterTags_byDataPath_byMatch;
    },
    singleFilterTag_fromFilterTags: function singleFilterTag_fromFilterTags(filterTagsMatching_dataPath_andMatchValue) {
        var reducedFilterTag = _lodash2.default.reduce(filterTagsMatching_dataPath_andMatchValue, function (acc, filterTag) {
            return _lodash2.default.mergeWith(acc, filterTag, function (objValue, srcValue, key) {
                return key === 'searchSpec' ? _extends({}, srcValue, {
                    text: _lodash2.default.chain([]).concat(_lodash2.default.get(objValue, 'text'), _lodash2.default.get(srcValue, 'text')).compact().value(),
                    value: _lodash2.default.chain([]).concat(_lodash2.default.get(objValue, 'value'), _lodash2.default.get(srcValue, 'value')).compact().value(),
                    rawValue: _lodash2.default.chain([]).concat(_lodash2.default.get(objValue, 'rawValue'), _lodash2.default.get(srcValue, 'rawValue')).compact().value()
                }) : srcValue;
            });
        }, {});

        return reducedFilterTag;
    }
});

var OperatorChip = function OperatorChip(props) {
    var _React$createElement14, _extends4;

    var children = props.children,
        _props$style = props.style,
        style = _props$style === undefined ? {} : _props$style,
        otherProps = _objectWithoutProperties(props, ['children', 'style']);

    return _react2.default.createElement(
        _Chip2.default,
        _extends({ style: _extends({}, _AdvancedSearchUtilityBar_style2.default.OperatorChip.style, style) }, otherProps, (_extends4 = {
            'data-codecept-selector-node': 'Chip',
            'data-codecept-selector-file': 'FilterTagsToolbarGroup'
        }, _defineProperty(_extends4, 'data-codecept-selector-node', 'Chip'), _defineProperty(_extends4, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _defineProperty(_extends4, 'data-codecept-selector-node', 'Chip'), _defineProperty(_extends4, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _extends4)),
        _react2.default.createElement(
            'strong',
            (_React$createElement14 = {
                'data-codecept-selector-node': 'strong',
                'data-codecept-selector-file': 'FilterTagsToolbarGroup'
            }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'strong'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'FilterTagsToolbarGroup'), _React$createElement14),
            children
        )
    );
};

exports.default = FilterTagsToolbarGroup;