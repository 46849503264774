'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _PermissionTokenTagger = require('./PermissionTokenTagger');

var _PermissionTokenTagger2 = _interopRequireDefault(_PermissionTokenTagger);

var _Toggle = require('@rubyapps/ruby-material-ui/Toggle');

var _Toggle2 = _interopRequireDefault(_Toggle);

var _Paper = require('material-ui/Paper');

var _Paper2 = _interopRequireDefault(_Paper);

var _Permissions_style = require('./Permissions_style');

var _Permissions_style2 = _interopRequireDefault(_Permissions_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label;

var Permissions = _react2.default.createClass({
    displayName: 'Permissions',

    propTypes: {
        label: PropTypes.string,
        fieldKey: PropTypes.string,
        idsByPermId: PropTypes.object.isRequired,
        permissionItemsByKey: PropTypes.object.isRequired,
        toggledByPermId: PropTypes.object.isRequired,
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        id: PropTypes.string,
        actions: PropTypes.object,
        getOptionsEndpoint: PropTypes.func.isRequired
    },
    _itemToInitialState: function _itemToInitialState(item) {
        return _extends({}, item, {});
    },
    _onToggle_ofKey: function _onToggle_ofKey(key) {
        var self = this;

        return function (event, toggled) {
            self.props.actions.toggleValues([key], toggled);
        };
    },
    _descendentsOfKeys: function _descendentsOfKeys(keys) {
        var permissionItemsByKey = this.props.permissionItemsByKey;


        var childrenIterator = function childrenIterator(key) {
            var permissionItemForKey = permissionItemsByKey[key];

            var permissionItemChildren = permissionItemForKey.children || [];
            if (permissionItemForKey.children.length) {
                //debugger;
            }

            return permissionItemChildren.concat.apply(permissionItemChildren, _lodash2.default.flatMap(permissionItemChildren, function (childKey) {
                return childrenIterator(childKey);
            }));
        };

        var descendentKeys = _lodash2.default.flatMap(keys, function (childKey) {
            return childrenIterator(childKey);
        });

        return descendentKeys;
    },
    _onToggle_ofChildren_andKey: function _onToggle_ofChildren_andKey(children, key) {
        var self = this;
        //# expand children's descendents;

        var toggledValues = [key].concat(children, this._descendentsOfKeys(children));

        return function (event, toggled) {
            self.props.actions.toggleValues(toggledValues, toggled);
        };
    },
    _flattenItems: function _flattenItems(children) {
        var _this = this;

        var self = this;

        return children.map(function (id) {
            return (self.props.permissionItemsByKey || {})[id];
        }).reduce(function (prev, curr) {
            return prev.concat([curr].concat(_this._flattenItems(curr.children || [])));
        }, []);
    },
    render: function render() {
        var _this2 = this,
            _React$createElement3;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var self = this;
        var labelProps = _lodash2.default.pick(this.props, Object.keys(Label.propTypes));

        var rootItem = self.props.permissionItemsByKey[this.props.rootPermissionKey];

        if (rootItem == null) {
            return null;
        }

        return _react2.default.createElement(
            'div',
            (_React$createElement3 = {
                style: {
                    columnCount: 2
                },
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Permissions'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Permissions'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Permissions'), _React$createElement3),
            rootItem.children.map(function (childId) {
                return _extends({}, _lodash2.default.get(self, ['props', 'permissionItemsByKey', childId]));
            }).map(function (child, id) {
                if (Array.isArray(child.children) && child.children.length > 0) {
                    var _extends2, _React$createElement;

                    return _react2.default.createElement(
                        SectionWrapper,
                        (_React$createElement = {
                            'data-codecept-selector-node': 'SectionWrapper',
                            'data-codecept-selector-file': 'Permissions'
                        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'SectionWrapper'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Permissions'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'SectionWrapper'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Permissions'), _React$createElement),
                        _react2.default.createElement(PermissionContainer, _extends({
                            key: id
                        }, child, (_extends2 = {
                            onToggle_ofKey: _this2._onToggle_ofKey,
                            onToggle_ofChildren_andKey: _this2._onToggle_ofChildren_andKey,
                            permissionItemsByKey: _this2.props.permissionItemsByKey,
                            getOptionsEndpoint: _this2.props.getOptionsEndpoint,
                            actions: _this2.props.actions,
                            idsByPermId: _this2.props.idsByPermId,
                            toggledByPermId: _this2.props.toggledByPermId,
                            'data-codecept-selector-node': 'PermissionContainer',
                            'data-codecept-selector-file': 'Permissions'
                        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'PermissionContainer'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Permissions'), _defineProperty(_extends2, 'data-codecept-selector-node', 'PermissionContainer'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Permissions'), _extends2)))
                    );
                } else {
                    var _extends3, _extends4, _React$createElement2;

                    return _react2.default.createElement(
                        SectionWrapper,
                        (_React$createElement2 = {
                            'data-codecept-selector-node': 'SectionWrapper',
                            'data-codecept-selector-file': 'Permissions'
                        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'SectionWrapper'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Permissions'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'SectionWrapper'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Permissions'), _React$createElement2),
                        _react2.default.createElement(
                            _Paper2.default,
                            _extends({}, _Permissions_style2.default.Paper, (_extends4 = {
                                'data-codecept-selector-node': 'Paper',
                                'data-codecept-selector-file': 'Permissions'
                            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'Paper'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Permissions'), _defineProperty(_extends4, 'data-codecept-selector-node', 'Paper'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Permissions'), _extends4)),
                            _react2.default.createElement(PermissionItem, _extends({
                                key: id
                            }, child, (_extends3 = {
                                onToggle_ofKey: _this2._onToggle_ofKey,
                                onToggle_ofChildren_andKey: _this2._onToggle_ofChildren_andKey,
                                getOptionsEndpoint: _this2.props.getOptionsEndpoint,
                                actions: _this2.props.actions,
                                idsByPermId: _this2.props.idsByPermId,
                                toggledByPermId: _this2.props.toggledByPermId,
                                'data-codecept-selector-node': 'PermissionItem',
                                'data-codecept-selector-file': 'Permissions'
                            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'PermissionItem'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Permissions'), _defineProperty(_extends3, 'data-codecept-selector-node', 'PermissionItem'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Permissions'), _extends3)))
                        )
                    );
                }
            })
        );
    }
});

exports.default = Permissions;


var SectionWrapper = _react2.default.createClass({
    displayName: 'SectionWrapper',

    propTypes: {
        children: PropTypes.node
    },
    render: function render() {
        var _React$createElement4;

        return _react2.default.createElement(
            'div',
            (_React$createElement4 = {
                style: {
                    padding: 12,
                    breakInside: 'avoid-column'
                },
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Permissions'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Permissions'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Permissions'), _React$createElement4),
            this.props.children
        );
    }
});

var PermissionItem = _react2.default.createClass({
    displayName: 'PermissionItem',

    propTypes: {
        actions: PropTypes.object,
        getOptionsEndpoint: PropTypes.func.isRequired,
        idsByPermId: PropTypes.object.isRequired,
        label: PropTypes.string.isRequired,
        onToggle_ofKey: PropTypes.func.isRequired,
        permKey: PropTypes.string,
        shouldEnablePageSpecificity: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape(_PermissionTokenTagger2.default.propTypes)]),
        templateKeyword: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        toggledByPermId: PropTypes.object.isRequired
    },
    render: function render() {
        var _extends5, _React$createElement5, _React$createElement6;

        var idsByPermId = _lodash2.default.get(this, 'props.idsByPermId.sitemap', {});
        var sitemapHasPageIds = idsByPermId ? idsByPermId.length > 0 : false;
        var isModifySitemap = this.props.permKey == 'modifySitemap';
        var disabled = isModifySitemap && sitemapHasPageIds;
        var toggled = disabled ? false : this.props.toggledByPermId[this.props.permKey];
        return _react2.default.createElement(
            'div',
            (_React$createElement6 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Permissions'
            }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Permissions'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Permissions'), _React$createElement6),
            _react2.default.createElement(_Toggle2.default, _extends({
                label: this.props.label,
                labelPosition: 'right',
                onToggle: this.props.onToggle_ofKey(this.props.permKey),
                toggled: toggled,
                disabled: disabled
            }, _Permissions_style2.default.Toggle, (_extends5 = {
                'data-codecept-selector-node': 'Toggle',
                'data-codecept-selector-file': 'Permissions'
            }, _defineProperty(_extends5, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Permissions'), _defineProperty(_extends5, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Permissions'), _extends5))),
            this.props.shouldEnablePageSpecificity && toggled ? _react2.default.createElement(_PermissionTokenTagger2.default, (_React$createElement5 = {
                permKey: this.props.permKey,
                templateKeyword: this.props.templateKeyword,
                templateName: this.props.label,
                getOptionsEndpoint: this.props.getOptionsEndpoint,
                actions: this.props.actions,
                idsByPermId: this.props.idsByPermId,
                'data-codecept-selector-node': 'PermissionTokenTagger',
                'data-codecept-selector-file': 'Permissions'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'PermissionTokenTagger'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Permissions'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'PermissionTokenTagger'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Permissions'), _React$createElement5)) : null
        );
    }
});

var PermissionContainer = _react2.default.createClass({
    displayName: 'PermissionContainer',

    propTypes: {
        actions: PropTypes.object,
        children: PropTypes.array,
        idsByPermId: PropTypes.object.isRequired,
        getOptionsEndpoint: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
        onToggle_ofChildren_andKey: PropTypes.func.isRequired,
        permKey: PropTypes.string,
        permissionItemsByKey: PropTypes.object.isRequired,
        shouldEnablePageSpecificity: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape(_PermissionTokenTagger2.default.propTypes)]),
        toggledByPermId: PropTypes.object.isRequired,
        showWrapper: PropTypes.bool
    },
    getDefaultProps: function getDefaultProps() {
        return { showWrapper: true };
    },
    render: function render() {
        var _extends6,
            _extends7,
            _this3 = this,
            _React$createElement7,
            _extends10,
            _React$createElement8;

        var toggled = this.props.toggledByPermId[this.props.permKey];
        var permissionTokenTaggerProps = _lodash2.default.isPlainObject(this.props.shouldEnablePageSpecificity) ? this.props.shouldEnablePageSpecificity : {};

        var childrenElements = [_react2.default.createElement(_Toggle2.default, _extends({
            label: this.props.label,
            labelPosition: 'right',
            onToggle: this.props.onToggle_ofChildren_andKey(this.props.children, this.props.permKey)
        }, _Permissions_style2.default.Toggle, (_extends6 = {
            toggled: toggled,
            'data-codecept-selector-node': 'Toggle',
            'data-codecept-selector-file': 'Permissions'
        }, _defineProperty(_extends6, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_extends6, 'data-codecept-selector-file', 'Permissions'), _defineProperty(_extends6, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_extends6, 'data-codecept-selector-file', 'Permissions'), _extends6))), _react2.default.createElement(
            'div',
            (_React$createElement7 = { style: { marginLeft: 20, marginRight: 20 }, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Permissions'
            }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Permissions'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Permissions'), _React$createElement7),
            this.props.shouldEnablePageSpecificity && toggled ? _react2.default.createElement(_PermissionTokenTagger2.default, _extends({
                permKey: this.props.permKey,
                templateKeyword: this.props.templateKeyword,
                templateName: this.props.label,
                getOptionsEndpoint: this.props.getOptionsEndpoint,
                actions: this.props.actions,
                idsByPermId: this.props.idsByPermId
            }, permissionTokenTaggerProps, (_extends7 = {
                'data-codecept-selector-node': 'PermissionTokenTagger',
                'data-codecept-selector-file': 'Permissions'
            }, _defineProperty(_extends7, 'data-codecept-selector-node', 'PermissionTokenTagger'), _defineProperty(_extends7, 'data-codecept-selector-file', 'Permissions'), _defineProperty(_extends7, 'data-codecept-selector-node', 'PermissionTokenTagger'), _defineProperty(_extends7, 'data-codecept-selector-file', 'Permissions'), _extends7))) : null,
            this.props.children.map(function (id) {
                return (_this3.props.permissionItemsByKey || {})[id];
            }).map(function (child, id) {
                if (Array.isArray(child.children) && child.children.length > 0) {
                    var _extends8;

                    return _react2.default.createElement(PermissionContainer, _extends({
                        key: id
                    }, child, (_extends8 = {
                        onToggle_ofKey: _this3.props.onToggle_ofKey,
                        onToggle_ofChildren_andKey: _this3.props.onToggle_ofChildren_andKey,
                        idsByPermId: _this3.props.idsByPermId,
                        toggledByPermId: _this3.props.toggledByPermId,
                        permissionItemsByKey: _this3.props.permissionItemsByKey,
                        getOptionsEndpoint: _this3.props.getOptionsEndpoint,
                        actions: _this3.props.actions,
                        showWrapper: false,
                        'data-codecept-selector-node': 'PermissionContainer',
                        'data-codecept-selector-file': 'Permissions'
                    }, _defineProperty(_extends8, 'data-codecept-selector-node', 'PermissionContainer'), _defineProperty(_extends8, 'data-codecept-selector-file', 'Permissions'), _defineProperty(_extends8, 'data-codecept-selector-node', 'PermissionContainer'), _defineProperty(_extends8, 'data-codecept-selector-file', 'Permissions'), _extends8)));
                } else {
                    var _extends9;

                    return _react2.default.createElement(PermissionItem, _extends({
                        key: id
                    }, child, (_extends9 = {
                        onToggle_ofKey: _this3.props.onToggle_ofKey,
                        getOptionsEndpoint: _this3.props.getOptionsEndpoint,
                        actions: _this3.props.actions,
                        idsByPermId: _this3.props.idsByPermId,
                        toggledByPermId: _this3.props.toggledByPermId,
                        'data-codecept-selector-node': 'PermissionItem',
                        'data-codecept-selector-file': 'Permissions'
                    }, _defineProperty(_extends9, 'data-codecept-selector-node', 'PermissionItem'), _defineProperty(_extends9, 'data-codecept-selector-file', 'Permissions'), _defineProperty(_extends9, 'data-codecept-selector-node', 'PermissionItem'), _defineProperty(_extends9, 'data-codecept-selector-file', 'Permissions'), _extends9)));
                }
            })
        )];
        return this.props.showWrapper ? _react2.default.createElement(
            _Paper2.default,
            _extends({}, _Permissions_style2.default.Paper, (_extends10 = {
                'data-codecept-selector-node': 'Paper',
                'data-codecept-selector-file': 'Permissions'
            }, _defineProperty(_extends10, 'data-codecept-selector-node', 'Paper'), _defineProperty(_extends10, 'data-codecept-selector-file', 'Permissions'), _defineProperty(_extends10, 'data-codecept-selector-node', 'Paper'), _defineProperty(_extends10, 'data-codecept-selector-file', 'Permissions'), _extends10)),
            childrenElements
        ) : _react2.default.createElement(
            'div',
            (_React$createElement8 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Permissions'
            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Permissions'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Permissions'), _React$createElement8),
            childrenElements
        );
    }
});