'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _ScheduledStatus_style = require('./reactComponents/ScheduledStatus_style');

var _ScheduledStatus_style2 = _interopRequireDefault(_ScheduledStatus_style);

var _ScheduledStatusConnector = require('./reactComponents/ScheduledStatusConnector');

var _ScheduledStatusConnector2 = _interopRequireDefault(_ScheduledStatusConnector);

var _childrenPatcher = require('./childrenPatcher');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var moment = require('@rubyapps/friendly-ruby-moment');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

//# Mixins
var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');

//# RubyComponentFields
var FieldDropdown = require('@rubyapps/ruby-component-field-dropdown');
var FieldPopover = require('@rubyapps/ruby-component-field-popover');
var FieldDatetimePicker = require('@rubyapps/ruby-component-field-datetime-picker');
var InfoComponent = require('@rubyapps/ruby-component-field-popover/src/client/reactComponents/InfoComponent').default;

//# Styles


var RCScheduledStatus = RubyComponent.createClass({
    mixins: [baseFieldMixin],
    propTypes: {} //# some are already included by the baseFieldMixin
    , componentName: componentName,
    getDefaultProps: function getDefaultProps(props) {
        return {
            childrenPropsByKey: {
                status: _extends({
                    label: "Status",
                    key: "status",
                    data_type: "string",
                    options: [{ text: "Unpublished", value: "inactive" }, { text: "Published", value: "active" }],
                    ruby_permissions: props.ruby_permissions,
                    "verify": { "required": true },
                    excludeWrapper: true,
                    autoWidth: true,
                    styles: _ScheduledStatus_style2.default.Dropdown,
                    componentName: 'Dropdown'
                }, _.get(CONSTANTS, 'commonChildrenPropsByKey.status'), _.get(props, 'childrenPropsByKey.status', {})),
                popover: {
                    label: 'Scheduled Status',
                    ruby_permissions: props.ruby_permissions,
                    activeIconPath: 'ruby/icons/content/edit-schedule',
                    inactiveIconPath: 'ruby/icons/content/add-to-schedule',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                    targetOrigin: { vertical: 'top', horizontal: 'right' },
                    styles: { direction: 'ltr', marginRight: 15 },
                    componentName: 'Popover',
                    listerConfig: {
                        excludeFromColumnSelection: true,
                        excludeFromFilterSelection: true
                    }
                },
                start_timestamp: _extends({
                    label: "Publish Date",
                    key: "start_timestamp",
                    ruby_permissions: props.ruby_permissions,
                    styles: _ScheduledStatus_style2.default.StartDatetimePicker,
                    componentName: 'DatetimePicker',
                    listerConfig: {
                        excludeFromColumnSelection: false,
                        excludeFromFilterSelection: false
                    }
                }, _.get(props, 'childrenPropsByKey.start_timestamp', {})),
                end_timestamp: _extends({
                    label: "Unpublish Date",
                    key: "end_timestamp",
                    ruby_permissions: props.ruby_permissions,
                    styles: _ScheduledStatus_style2.default.EndDatetimePicker,
                    componentName: 'DatetimePicker',
                    listerConfig: {
                        excludeFromColumnSelection: false,
                        excludeFromFilterSelection: false
                    }
                }, _.get(props, 'childrenPropsByKey.end_timestamp', {}))
            }
        };
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();

        return {
            selfSelector: selfSelector
        };
    },
    _formValueToLocalState: function _formValueToLocalState(formValue, dispatchOrCollect, isError, entireFormValue) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        var selfModule = this;

        //# support omit field picker
        var shouldOmitField = _.isFunction(options.omitFieldPicker) && options.omitFieldPicker.apply(options, [this].concat(Array.prototype.slice.call(arguments)));
        if (shouldOmitField) {
            return undefined;
        }

        if (isError) {
            return baseFieldMixin._formValueToLocalState.apply(this, [formValue, dispatchOrCollect, isError, entireFormValue, options]);
        } else {
            var status = formValue.status;

            var modifiedFormValue = _extends({}, formValue, !selfModule.getStartTimeStampIsVisible_fromRawFormValue(formValue) ? { 'start_timestamp': null } : undefined, !selfModule.getEndTimeStampIsVisible_fromRawFormValue(formValue) ? { 'end_timestamp': null } : undefined);
            return baseFieldMixin._formValueToLocalState.apply(this, [modifiedFormValue, dispatchOrCollect, isError, entireFormValue, options]);
        }
    },
    getStartTimeStampIsVisible_fromRawFormValue: function getStartTimeStampIsVisible_fromRawFormValue() {
        var formValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        return formValue.status === 'inactive';
    },
    getEndTimeStampIsVisible_fromRawFormValue: function getEndTimeStampIsVisible_fromRawFormValue() {
        var formValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        if (formValue.status === 'active') {
            return true;
        } else {
            var start_timestamp = formValue.start_timestamp;

            var _ref = start_timestamp || {},
                startDatetime = _ref.datetime,
                startTimeZone = _ref.timeZone;

            return startDatetime || startTimeZone;
        }
    },
    rubyComponentPatcherForPopover: _childrenPatcher.rubyComponentPatcherForPopover,
    children: function children() {
        var selfModule = this;
        var parentID = this.getID();

        var children = [selfModule.rubyComponentPatcherForPopover(RubyComponent.createElement(FieldPopover, _extends({
            id: parentID + '.popover',
            infoComponent: function infoComponent() {
                var _React$createElement;

                var formValue = _.result(selfModule, 'formValue', {});

                var displayValueSpecs = ['start_timestamp', 'end_timestamp'].map(function (key) {
                    return {
                        label: _.get(selfModule, 'props.childrenPropsByKey.' + key + '.label'),
                        timestamp: _.get(formValue, key)
                    };
                }).map(function (_ref2) {
                    var label = _ref2.label,
                        timestamp = _ref2.timestamp;

                    var timeZone = _.get(timestamp, 'timeZone');
                    var date = _.get(timestamp, 'datetime');
                    var formattedDate = date && timeZone && moment.tz(date, timeZone).format('MMM D, YYYY hh:mm a zz');
                    return { label: label, value: formattedDate };
                });

                return React.createElement(InfoComponent, (_React$createElement = { displayValueSpecs: displayValueSpecs, 'data-codecept-selector-node': 'InfoComponent',
                    'data-codecept-selector-file': 'index'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'InfoComponent'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'InfoComponent'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement));
            }
        }, selfModule.props.childrenPropsByKey.popover), RubyComponent.createElement(FieldDatetimePicker, _extends({
            id: parentID + '.start_timestamp',
            hidden: function hidden() {
                return !selfModule.getStartTimeStampIsVisible_fromRawFormValue(_.result(selfModule, 'formValue', {}));
            }
        }, selfModule.props.childrenPropsByKey.start_timestamp)), RubyComponent.createElement(FieldDatetimePicker, _extends({
            id: parentID + '.end_timestamp',
            hidden: function hidden() {
                return !selfModule.getEndTimeStampIsVisible_fromRawFormValue(_.result(selfModule, 'formValue', {}));
            }
        }, selfModule.props.childrenPropsByKey.end_timestamp)))), RubyComponent.createElement(FieldDropdown, _extends({
            id: parentID + '.status'
        }, selfModule.props.childrenPropsByKey.status))];

        return children;
    },

    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector;

        var selectedState = {
            self: selfSelector(state),
            isActive: this.isFieldActiveForNamespace_fromState(state)
        };
        return selectedState;
    },
    getReactClass: function getReactClass() {
        return _ScheduledStatusConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var ScheduledStatusComponent = this.getReactClass();

        return React.createElement(ScheduledStatusComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'ScheduledStatusComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'ScheduledStatusComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'ScheduledStatusComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

    //== OVERRIDES ======================//
    , _promisedFormValueFromFieldSpecs: function _promisedFormValueFromFieldSpecs() {
        var selfKey = this.props.key;

        return baseFieldMixin._promisedFormValueFromFieldSpecs.apply(this, arguments).then(function (formValue) {
            var childrenFormValue = selfKey ? formValue[selfKey] : formValue;
            var cleanedChildrenFormValue = _.pick(childrenFormValue, ['status']);

            return selfKey ? _defineProperty({}, selfKey, cleanedChildrenFormValue) : cleanedChildrenFormValue;
        });
    }

    //# TODO: NOTE: because this field often doesn't come with a key, we ignore this
    //# so we need to have it mimic the tab/fieldsets
    //# IDEA: update fieldbase to support another prop to include the parent component regardless of whether it has a fieldKey
    //# OR override `_getFieldSpecFromProps__withoutInternalChildren` here
    //# to return the fieldSpec regardless (like fieldset or tabs)
});

module.exports = RCScheduledStatus;