'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };
//# Add Entry Button


var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Toolbar = require('material-ui/Toolbar');

var _griddlePlugins = require('./griddle-plugins');

var _griddlePlugins2 = _interopRequireDefault(_griddlePlugins);

var _FilterDropdown = require('@rubyapps/ruby-component-list-page/src/client/reactComponents/FilterDropdown');

var _FilterDropdown2 = _interopRequireDefault(_FilterDropdown);

var _AddEntryButton = require('@rubyapps/ruby-component-list-page/src/client/reactComponents/AddEntryButton');

var _AddEntryButton2 = _interopRequireDefault(_AddEntryButton);

var _utilityBar = require('@rubyapps/ruby-component-list-page/src/client/reactComponents/utility-bar.cssModule');

var _utilityBar2 = _interopRequireDefault(_utilityBar);

var _styles = require('./reactComponents/UtilityBar/styles.cssModule');

var _styles2 = _interopRequireDefault(_styles);

var _rubyMemoize = require('@rubyapps/ruby-memoize');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var routeParser = require('@rubyapps/route-parser');

var memoize = require('memoizee');
function raw_routeParserForUrl(url) {
    return new routeParser(url);
}
var memoized_routeParserForUrl = memoize(raw_routeParserForUrl, _extends({}, _rubyMemoize.defaultMemoizeeOptions, length));

function hydrateUrlWithData(url, data) {
    var urlRouteParser = memoized_routeParserForUrl(url);

    return urlRouteParser.reverse(data);
}

var contentDefaultsListPageMixin = {
    mixinName: 'rubyComponentMixinListerContentDefaults'
    //, mixins: [require('@rubyapps/ruby-component-mixin-list-page/src/client/index')]
    , getDefaultProps: function getDefaultProps() {
        return {
            searchMode: 'simple' //# 'advanced' | 'simple'
            //# NOTE: if you use 'advanced' searchMode, if you have any filters or "Add Entry" button
            //# that typically appears along side the headerLabel
            //# you need to inject it into the headerLabel directly
            //# this is alimitation of how the advancedSearch utilitybar can lay out components
        };
    },
    getGriddlePluginsAugmentor_duringMergeProps: function getGriddlePluginsAugmentor_duringMergeProps(stateProps, dispatchProps, ownProps) {
        var _arguments = arguments;

        var selfModule = this;
        return function () {
            var parentPlugins = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

            var defaultPlugins = _griddlePlugins2.default.apply(selfModule, _arguments);
            return [].concat(parentPlugins, defaultPlugins);
        };
    },
    _getCustomFilterProps_withConfig_withUtilityBarProps: function _getCustomFilterProps_withConfig_withUtilityBarProps() {
        var filterConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var griddleProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var utilityBarState = griddleProps.utilityBarProperties || {};
        var utilityBarEvents = griddleProps.events;

        var customFilterName = filterConfig.name;
        var customFilterData = utilityBarState.customFilters || {};
        var customFilterValue = customFilterData[customFilterName];
        var customFilterHeader = filterConfig.header;
        var customFilterOptions = filterConfig.options;
        var customFilterOptionsUrl = filterConfig.optionsUrl || filterConfig.optionsURL;
        if (filterConfig.optionsURL) {
            console.warn('[ruby-component-mixin-lister-content-defaults/UtilityBar/indexConnector] [filterConfig] provided "optionsURL". This has been deprecated in favor of "optionsUrl"', filterConfig);
        }
        var customFilterDelegateOnChange = function customFilterDelegateOnChange(event, index, value, valueObj) {

            var prevFilterValue = _lodash2.default.get(griddleProps, ['utilityBarProperties', 'customFilters', customFilterName]);
            if (value === prevFilterValue) {
                //# Don't update the utilityBarProperties if the value hasn't changed
                return;
            }

            var newUtilityBarProperties = _extends({}, griddleProps.utilityBarProperties || {}, {
                customFilters: _extends({}, _lodash2.default.get(griddleProps, 'utilityBarProperties.customFilters'), _defineProperty({}, customFilterName, value))
            });
            utilityBarEvents.setUtilityBarProperties(newUtilityBarProperties);
            utilityBarEvents.getPage(1, true); //# jump back to the first page
            //utilityBarEvents.refreshCurrentPage();
        };

        return _extends({}, filterConfig, {
            name: customFilterName,
            value: customFilterValue,
            header: customFilterHeader,
            options: customFilterOptions,
            optionsUrl: customFilterOptionsUrl ? hydrateUrlWithData(customFilterOptionsUrl, customFilterData) : undefined,
            delegateOnChange: customFilterDelegateOnChange
        });
    },
    _additionalHeaderToolbarItem__templateFilter: function _additionalHeaderToolbarItem__templateFilter(griddleProps, rubyComponentProps) {
        var _React$createElement, _extends3;

        var boundActions = rubyComponentProps.actions,
            sdTemplateOptions = rubyComponentProps.listerNav_options,
            sdTemplateDefaultOption = rubyComponentProps.listerNav_defaultOption,
            customFilterConfigs = rubyComponentProps.customFilters;
        var listerNavLabel = this.props.listerNavLabel;

        //# template options

        var sdTemplateName = 'switch_template';
        var sdTemplateOnChange = function sdTemplateOnChange(event, index, value) {
            var path = value;
            boundActions.navigateToPathWithOptions({ path: path });
        };
        var sdTemplate = {
            name: sdTemplateName,
            options: sdTemplateOptions,
            defaultValue: _lodash2.default.get(sdTemplateDefaultOption, 'value'),
            delegateOnChange: sdTemplateOnChange,
            label: listerNavLabel
        };

        return [_react2.default.createElement(_Toolbar.ToolbarTitle, (_React$createElement = { text: sdTemplate.label + ':', className: _styles2.default.ToolbarTitle, 'data-codecept-selector-node': 'ToolbarTitle',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ToolbarTitle'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ToolbarTitle'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement)), _react2.default.createElement(_FilterDropdown2.default, _extends({}, sdTemplate, (_extends3 = { label: null, 'data-codecept-selector-node': 'CustomFilterDropdown',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends3, 'data-codecept-selector-node', 'CustomFilterDropdown'), _defineProperty(_extends3, 'data-codecept-selector-file', 'index'), _defineProperty(_extends3, 'data-codecept-selector-node', 'CustomFilterDropdown'), _defineProperty(_extends3, 'data-codecept-selector-file', 'index'), _extends3)))];
    },
    _additionalHeaderToolbarItem__filters: function _additionalHeaderToolbarItem__filters(griddleProps, rubyComponentProps) {
        var _this = this,
            _React$createElement2;

        var customFilterConfigs = rubyComponentProps.customFilters,
            filtersProps__override = rubyComponentProps.filtersProps;

        // Setup props for custom SD (config come from the client side lister file)

        var filtersProps = filtersProps__override || customFilterConfigs.map(function (customFilterConfig) {
            return _this._getCustomFilterProps_withConfig_withUtilityBarProps(customFilterConfig, griddleProps);
        });
        var filterDropdowns = filtersProps.map(function (customFilter) {
            var _extends4;

            return _react2.default.createElement(_FilterDropdown2.default, _extends({}, customFilter, (_extends4 = {
                'data-codecept-selector-node': 'CustomFilterDropdown',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'CustomFilterDropdown'), _defineProperty(_extends4, 'data-codecept-selector-file', 'index'), _defineProperty(_extends4, 'data-codecept-selector-node', 'CustomFilterDropdown'), _defineProperty(_extends4, 'data-codecept-selector-file', 'index'), _extends4)));
        });

        return filterDropdowns.length ? [].concat(_react2.default.createElement(_Toolbar.ToolbarTitle, (_React$createElement2 = { text: 'Filters:', className: _styles2.default.ToolbarTitle, 'data-codecept-selector-node': 'ToolbarTitle',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'ToolbarTitle'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'ToolbarTitle'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'index'), _React$createElement2)), _lodash2.default.flatMap(filterDropdowns, function (filterDropdown, index) {
            var _React$createElement3;

            return [filterDropdown].concat(index != filterDropdowns.length - 1 ? _react2.default.createElement(_Toolbar.ToolbarSeparator, (_React$createElement3 = { className: _styles2.default.ToolbarSeparator, 'data-codecept-selector-node': 'ToolbarSeparator',
                'data-codecept-selector-file': 'index'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ToolbarSeparator'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ToolbarSeparator'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'index'), _React$createElement3)) : []);
        })) : [];
    }
    //# TODO: utility method to help inject the filters and addEntry button
    /**
     *  rubyComponentProps.hasAddEntryPermission - used to determine if we need to disable the addEntry button
     *  rubyComponentProps.isUniqueTemplate - used to determine if we need to disable the addEntry button
     *  rubyComponentProps.addEntryOnClick - add entry override. By default, we generate this function and route to this.getDependencies().getAddEntryUrl()
     *  rubyComponentProps.actions - boundActions from the UtilityBarConnector
     *  rubyComponentProps.listerNav_options - Deprecating param, only used to decide whether we need to show the template dropdown (for lookup lister)
     *  rubyComponentProps.listerNav_defaultOption - Default option object for the above
     *  rubyComponentProps.customFilters - array of custom filters. 
     *  rubyComponentProps.customFilters.*.name - field name 
     *  rubyComponentProps.customFilters.*.header - filter's label
     *  rubyComponentProps.customFilters.*.options - statically defined options
     *  rubyComponentProps.customFilters.*.optionsUrl - options url (if options are not static)
     *  rubyComponentProps.filtersProps - override the customFilters param. This is only used if you've defined your own delegateOnChange for the filter dropdown. You shouldn't need to use this property
     *
     */
    , additionalHeaderToolbarItems: function additionalHeaderToolbarItems(griddleProps, rubyComponentProps) {
        var _this2 = this,
            _React$createElement5,
            _React$createElement6;

        var hasAddEntryPermission = rubyComponentProps.hasAddEntryPermission,
            isUniqueTemplate = rubyComponentProps.isUniqueTemplate,
            addEntryOnClick__override = rubyComponentProps.addEntryOnClick;
        var showTemplateFilter = this.props.showTemplateFilter;

        var _getState = this.getState(),
            griddleState = _getState.griddleState;

        var _getDependencies = this.getDependencies(),
            editPageComponent = _getDependencies.editPageComponent,
            getAddEntryUrl = _getDependencies.getAddEntryUrl;

        var ToolbarItems = [];

        var filterToolbarItems = this._additionalHeaderToolbarItem__filters(griddleProps, rubyComponentProps);

        if (showTemplateFilter) {
            ToolbarItems.push(this._additionalHeaderToolbarItem__templateFilter(griddleProps, rubyComponentProps));
            if (filterToolbarItems.length) {
                var _React$createElement4;

                ToolbarItems.push(_react2.default.createElement(_Toolbar.ToolbarSeparator, (_React$createElement4 = { className: _styles2.default.ToolbarSeparator, 'data-codecept-selector-node': 'ToolbarSeparator',
                    'data-codecept-selector-file': 'index'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ToolbarSeparator'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ToolbarSeparator'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'index'), _React$createElement4)));
            }
        }
        ToolbarItems.push(filterToolbarItems);

        var isSelectMode = this.props.mode == 'select';

        var addEntryOnClick = rubyComponentProps.hasOwnProperty('addEntryOnClick') ? addEntryOnClick__override : function () {
            var customFilterData = griddleState.utilityBarProperties.customFilters;

            var addEntryUrl = getAddEntryUrl();

            var _getStore = _this2.getStore(),
                dispatch = _getStore.dispatch;

            if (isSelectMode) {
                window.open(addEntryUrl);
                return;
            }

            dispatch(_this2.getAction().generators.navigateToPathWithOptions({
                path: addEntryUrl,
                hash: _this2.props.addEntryHash
            }, function () {
                //# NOTE: for now we select the dispatch method
                //# but if we have reason to use the editPage actions elsewhere, we should consider
                //# binding the action creators in the list page connector
                if (!customFilterData || Object.keys(customFilterData).length == 0) {
                    return;
                }

                var editPageActions = editPageComponent.getAction().generators;
                dispatch(editPageActions.hydrateFormWithData(customFilterData, true));
            }));
        };
        return ToolbarItems.concat(ToolbarItems.length && addEntryOnClick ? _react2.default.createElement(_Toolbar.ToolbarSeparator, (_React$createElement5 = { className: _styles2.default.ToolbarSeparator, 'data-codecept-selector-node': 'ToolbarSeparator',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'ToolbarSeparator'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'ToolbarSeparator'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'index'), _React$createElement5)) : [], addEntryOnClick ? _react2.default.createElement(_AddEntryButton2.default, (_React$createElement6 = {
            delegateOnClick: addEntryOnClick,
            disabled: !hasAddEntryPermission || isUniqueTemplate,
            className: _utilityBar2.default.AddEntryButton,
            'data-codecept-selector-node': 'AddEntryButton',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'AddEntryButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'AddEntryButton'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'index'), _React$createElement6)) : []);
    }
};

module.exports = contentDefaultsListPageMixin;