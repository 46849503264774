"use strict";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var defaultState = {
    isExpanded: false,
    error: null
    //, error: null /# error: {message: <string>}just like the nested fields
};

module.exports = function (state, action) {
    var TYPES = this.getDependencies().selfAction.TYPES;
    var type = action.type,
        payload = action.payload;


    if (!state) {
        state = this.getInitialState();
    }

    switch (type) {
        case TYPES.EXPAND:
            return _extends({}, state, {
                isExpanded: true,
                isVisible: true //# use a more generic property so we can leverage mixin to handle children
            });
        case TYPES.COLLAPSE:
            return _extends({}, state, {
                isExpanded: false,
                isVisible: false
            });
        default:
            return state;
    }
};