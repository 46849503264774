'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _UrlConnector = require('./reactComponents/UrlConnector');

var _UrlConnector2 = _interopRequireDefault(_UrlConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var RubyComponentFESettings = require('@rubyapps/ruby-component-frontend-settings');
var RubyComponentCurrentUser = require('@rubyapps/ruby-component-current-user');

var RCUrl = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {
        reactProps: PropTypes.object
    },
    componentName: componentName,
    reducer: function reducer() {
        var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var action = arguments[1];

        return state;
    },
    action: function action() {
        var id = this.getID();
        var TYPES = {
            SET_FIELD: '@@ruby-app/' + id + '/SET_FIELD'
        };
        return {
            TYPES: TYPES,
            generators: {
                setField_withValue: function setField_withValue(value) {
                    var _this = this;

                    var actions = this.getAction().generators;
                    return function (dispatch) {
                        dispatch(actions.setFieldValueByKey(value, _this.props.key));
                    };

                    return {
                        type: TYPES.SET_FIELD,
                        payload: {
                            value: value
                        }
                    };
                }
            }
        };
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getSelfStateSelector();
        var rootModule = this.getRoot();

        var feSettingsID = this.props.feSettingsID || RubyComponentFESettings.componentName;
        var feSettingsComponent = rootModule.findDescendentByID(feSettingsID);
        var feSettingsSelector = feSettingsComponent.getDefaultSelector();

        var currentUserID = this.props.currentUserID || RubyComponentCurrentUser.CONSTANTS.COMPONENT_NAME;
        var currentUser = root.findDescendentByID(currentUserID);
        var currentUserSelector = currentUser.getSelfStateSelector();

        return {
            selfSelector: selfSelector,
            currentUserSelector: currentUserSelector,
            feSettingsSelector: feSettingsSelector,
            formComponent: this.getParentFormComponent()
        };
    },
    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector,
            currentUserSelector = _getDependencies.currentUserSelector;

        return {
            self: selfSelector(state),
            user: currentUserSelector(state),
            fieldProps: this.fieldProps_fromState_andOwnProps(state, this.props),
            routing: state.routing //# need to update based on routing changes
        };
    },
    getReactClass: function getReactClass() {
        return _UrlConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var UrlComponent = this.getReactClass();

        return React.createElement(UrlComponent, _extends({}, this.props.reactProps, _.omit(this.props, ['reactProps']), (_extends2 = {
            'data-codecept-selector-node': 'UrlComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'UrlComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'UrlComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

    //== OVERRIDES ======================//
    , sampleValueFromFieldSpecs: function sampleValueFromFieldSpecs() {
        return require('@rubyapps/ruby-faker').internet.url();
    }

});

module.exports = RCUrl;