'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _OptionsFetcher = require('@rubyapps/ruby-react-components/src/client/hoc/OptionsFetcher');

var _OptionsFetcher2 = _interopRequireDefault(_OptionsFetcher);

var _Dropdown = require('@rubyapps/ruby-react-components/src/client/input/Dropdown');

var _Dropdown2 = _interopRequireDefault(_Dropdown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var WrappedDropdown = (0, _OptionsFetcher2.default)(_Dropdown2.default);

var CustomFilterDropdown = _react2.default.createClass({
    displayName: 'CustomFilterDropdown',
    render: function render() {
        var TargetDropdown = this.props.optionsUrl ? WrappedDropdown : _Dropdown2.default;
        var dropdownProps = _lodash2.default.pick(this.props, Object.keys(TargetDropdown.propTypes));
        //value={_.isUndefined(this.props.value)? this.props.defaultValue :this.props.value}

        var styleProps = _lodash2.default.pick(this.props, ['style']);
        if (this.props.options || this.props.optionsUrl) {
            var _extends2;

            var options = this.props.options || [];
            return _react2.default.createElement(TargetDropdown, _extends({}, styleProps, dropdownProps, (_extends2 = {
                key: this.props.name,
                name: this.props.name,
                placeholder: this.props.header,
                placeholderValue: this.props.headerValue,

                value: this.props.value,
                defaultValue: this.props.defaultValue,

                url: this.props.optionsUrl,
                options: options,

                onChange: this.props.delegateOnChange,
                'data-codecept-selector-node': 'TargetDropdown',
                'data-codecept-selector-file': 'FilterDropdown'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'TargetDropdown'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FilterDropdown'), _defineProperty(_extends2, 'data-codecept-selector-node', 'TargetDropdown'), _defineProperty(_extends2, 'data-codecept-selector-file', 'FilterDropdown'), _extends2)));
        } else {
            var _React$createElement;

            return _react2.default.createElement('span', (_React$createElement = {
                'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'FilterDropdown'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FilterDropdown'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'FilterDropdown'), _React$createElement));
        }
    }
});

exports.default = CustomFilterDropdown;