'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Card = require('material-ui/Card');

var _fieldBase = require('@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule');

var _fieldBase2 = _interopRequireDefault(_fieldBase);

var _GooglePreview = require('./GooglePreview.cssModule');

var _GooglePreview2 = _interopRequireDefault(_GooglePreview);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var styles = _extends({}, _fieldBase2.default, _GooglePreview2.default);

var GooglePreview = _react2.default.createClass({
    displayName: 'GooglePreview',

    propTypes: {
        label: PropTypes.string,
        id: PropTypes.string,
        isActive: PropTypes.bool,
        pageName: PropTypes.string,
        browserPageTitle: PropTypes.string,
        value: PropTypes.object,
        previewUrl: PropTypes.string,
        generatedUrl: PropTypes.string
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8, _React$createElement9;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var children = this.props.children;

        var url = this.props.previewUrl;

        var browserPageTitle = this.props.browserPageTitle || this.props.pageName;

        return _react2.default.createElement(
            'div',
            (_React$createElement9 = { className: styles.wrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'GooglePreview'
            }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'GooglePreview'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'GooglePreview'), _React$createElement9),
            _react2.default.createElement(
                'label',
                (_React$createElement = { className: styles.label, 'data-codecept-selector-node': 'label',
                    'data-codecept-selector-file': 'GooglePreview'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'GooglePreview'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'GooglePreview'), _React$createElement),
                this.props.label
            ),
            _react2.default.createElement(
                _Card.Card,
                (_React$createElement8 = {
                    id: this.props.id,
                    'data-codecept-selector-node': 'Card',
                    'data-codecept-selector-file': 'GooglePreview'
                }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'GooglePreview'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'GooglePreview'), _React$createElement8),
                _react2.default.createElement(
                    _Card.CardText,
                    (_React$createElement7 = {
                        'data-codecept-selector-node': 'CardText',
                        'data-codecept-selector-file': 'GooglePreview'
                    }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'GooglePreview'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'GooglePreview'), _React$createElement7),
                    _react2.default.createElement(
                        'div',
                        (_React$createElement3 = {
                            'data-codecept-selector-node': 'div',
                            'data-codecept-selector-file': 'GooglePreview'
                        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'GooglePreview'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'GooglePreview'), _React$createElement3),
                        _react2.default.createElement(
                            'a',
                            (_React$createElement2 = {
                                target: '_blank',
                                className: styles.seoTitle,
                                href: this.props.previewUrl,
                                'data-codecept-selector-node': 'a',
                                'data-codecept-selector-file': 'GooglePreview'
                            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'GooglePreview'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'GooglePreview'), _React$createElement2),
                            browserPageTitle
                        )
                    ),
                    _react2.default.createElement(
                        'div',
                        (_React$createElement5 = {
                            'data-codecept-selector-node': 'div',
                            'data-codecept-selector-file': 'GooglePreview'
                        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'GooglePreview'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'GooglePreview'), _React$createElement5),
                        _react2.default.createElement(
                            'a',
                            (_React$createElement4 = {
                                target: '_blank',
                                className: styles.seoLink,
                                href: this.props.previewUrl,
                                'data-codecept-selector-node': 'a',
                                'data-codecept-selector-file': 'GooglePreview'
                            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'GooglePreview'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'GooglePreview'), _React$createElement4),
                            this.props.generatedUrl
                        )
                    ),
                    _react2.default.createElement(
                        'div',
                        (_React$createElement6 = { className: styles.seoDesc, 'data-codecept-selector-node': 'div',
                            'data-codecept-selector-file': 'GooglePreview'
                        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'GooglePreview'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'GooglePreview'), _React$createElement6),
                        this.props.value.meta_description
                    )
                )
            ),
            children
        );
    }
});

exports.default = GooglePreview;