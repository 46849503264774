'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _check = require('material-ui/svg-icons/navigation/check');

var _check2 = _interopRequireDefault(_check);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var path = require('path');
var request = require('@rubyapps/ruby-superagent');
var rubyWords = require('@rubyapps/ruby-words');

var CONSTANTS = require('../common/constants');

var _require = require('@rubyapps/griddle'),
    _require$GriddleDndPl = _require.GriddleDndPlugin,
    DndPlugin = _require$GriddleDndPl.DndPlugin,
    superagentProviderGenerator = _require$GriddleDndPl.superagentProviderGenerator,
    GriddleSubgridPlugin = _require.GriddleSubgridPlugin;

var rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');
var rubyAccessManager = require('@rubyapps/ruby-access-manager');
var moment = require('@rubyapps/friendly-ruby-moment');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;
var RubyComponentForms__CONSTANTS = require('@rubyapps/ruby-component-forms/src/common/constants');

var hierarchyListerMixin = {
    mixinName: 'rubyComponentMixinListerHierarchy',
    getChildrenAugmentor: function getChildrenAugmentor() {
        return function () {
            var children = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

            return children;
        };
    },
    getDndDependencies: function getDndDependencies() {
        var selfModule = this;
        var currentUserID = selfModule.props.currentUserID;
        var rootModule = selfModule.getRoot();
        var currentUser = rootModule.findDescendentByID(currentUserID);

        var formsID = RubyComponentForms__CONSTANTS.COMPONENT_NAME;
        var formsComponent = rootModule.findDescendentByID(formsID);

        return {
            currentUser: currentUser,
            formsComponent: formsComponent
        };
    },
    isHierarchical: function isHierarchical() {
        var selfModule = this;

        var _selfModule$getDepend = selfModule.getDependencies(),
            listerConfigsComponent = _selfModule$getDepend.listerConfigsComponent;

        var currentTemplateKey = this.props.templateKey || _lodash2.default.get(selfModule.getState(), 'routeParams.template');
        var listerConfig = listerConfigsComponent.listerConfigForKey(currentTemplateKey, selfModule);

        if (currentTemplateKey) {
            return listerConfig.hierarchical;
        } else {
            return true; //# default to true because this is the hierarchy mixin
        }
    },
    isDndEnabled: function isDndEnabled() {
        return _lodash2.default.every([this.isHierarchical(), this.getShouldRestrictDnd ? !this.getShouldRestrictDnd() : true]);
    },
    getGriddlePluginsAugmentor_duringMergeProps: function getGriddlePluginsAugmentor_duringMergeProps(stateProps, dispatchProps, ownProps) {
        var _this = this,
            _arguments = arguments;

        var selfModule = this;
        return function () {
            var parentPlugins = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];


            var dndPlugin = getDndPlugin_duringMergeProps.apply(_this, _arguments);

            return [].concat(parentPlugins, _this.isDndEnabled() ? dndPlugin : [], _this.isHierarchical() ? GriddleSubgridPlugin() : []);
        };
    },
    getUtilityBarActionsAugmentor_usingUtilityBarProps: function getUtilityBarActionsAugmentor_usingUtilityBarProps(griddleInstance) {
        var _this2 = this,
            _arguments2 = arguments;

        var selfModule = this;
        return function () {
            var parentActions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

            if (selfModule.props.mode == 'select') {
                //# Don't allow sitemap modification in TokenTagger AdvancedSearch dialog
                return parentActions;
            }

            var ModifySitemapAction = getModifySitemapAction_usingUtilityBarProps.apply(_this2, _arguments2);
            return [].concat(parentActions, _this2.isHierarchical() ? ModifySitemapAction : []);
        };
    }
};

function getModifySitemapAction_usingUtilityBarProps(utilityBarProps) {
    var _React$createElement;

    var selfModule = this;

    var utilityBarState = utilityBarProps.utilityBarProperties || {};
    var sortableStateKey = 'sortable_enabled';
    var sortableEnabled = utilityBarState[sortableStateKey];
    var shouldRestrictDnd = selfModule.getShouldRestrictDnd ? selfModule.getShouldRestrictDnd() : false;
    var textFilter = _lodash2.default.get(utilityBarProps, 'state.filter');

    var checked = !shouldRestrictDnd && !textFilter && sortableEnabled;

    return {
        key: 'modify-sitemap',
        primaryText: 'Modify Sitemap '
        //, checked: !shouldRestrictDnd && !textFilter && sortableEnabled
        //, insetChildren: true
        , rightIcon: checked ? React.createElement(_check2.default, (_React$createElement = {
            'data-codecept-selector-node': 'CheckIcon',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'CheckIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'CheckIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement)) : null,
        sectionName: 'checkable',
        disabled: shouldRestrictDnd,
        style: { minWidth: checked ? 170 : undefined },
        onTouchTap: function onTouchTap() {
            var _extends2;

            var utilityBarEvents = utilityBarProps.events;
            var filterValues = _lodash2.default.get(utilityBarState, 'customFilters') || {};
            var hadFilterValues = _lodash2.default.map(filterValues, function (val) {
                return val;
            }).filter(_lodash2.default.identity).length > 0;
            var hadFilterText = !_lodash2.default.isEmpty(_lodash2.default.get(utilityBarProps, 'state.filter'));
            utilityBarEvents.setUtilityBarProperties(_extends({}, utilityBarState, (_extends2 = {}, _defineProperty(_extends2, sortableStateKey, !sortableEnabled), _defineProperty(_extends2, 'customFilters', {}), _extends2)));
            if (!sortableEnabled && (hadFilterValues || hadFilterText)) {
                utilityBarEvents.setFilter(''); //# triggers a refresh
            }
        }
    };
}

function getDndPlugin_duringMergeProps(stateProps, dispatchProps, ownProps) {
    var selfModule = this;
    var refreshPageTimeout = void 0;
    return DndPlugin({
        onBeginDrag: function onBeginDrag() {
            clearTimeout(refreshPageTimeout);
        },
        onDrop: function onDrop(store, movedPageId, after, before, parentId, events) {
            var url = '/ruby/api/v2/content/' + movedPageId + '/rerank';

            if (!(after == null && before == null && parentId == null)) {
                request.post(url).query({ after: after, before: before, parentId: parentId }).end(function (err, res) {
                    if (err) {
                        var errMessage = _lodash2.default.get(res, 'body.error.message');
                        var errDetails = _lodash2.default.map(_lodash2.default.get(res, 'body.error.details.messages'), function (value, key) {
                            return value;
                        }).join(', ');

                        var displayMessage = [errMessage, errDetails].filter(function (string) {
                            return string != null && string != '';
                        }).join(': ');

                        selfModule.showNotificationModal({
                            title: 'Page Move Error',
                            message: displayMessage || 'An error was encountered while updating the page order. ' + 'Please try again later.'
                        });
                        if (typeof events.refreshCurrentPage === 'function') {
                            events.refreshCurrentPage();
                        }
                    } else {
                        selfModule.pushNotification({
                            type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS,
                            message: 'Page Move Successful.'
                        });
                        refreshPageTimeout = setTimeout(function () {
                            selfModule.pushNotification({
                                type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.INFO,
                                message: 'Refreshing Data.'
                            });
                            events.refreshCurrentPage();
                        }, 4000);
                    }
                });
            }
        }
    });
}

module.exports = hierarchyListerMixin;