'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _colors = require('@rubyapps/ruby-styles/src/client/styleObjects/colors');

var Colors = _interopRequireWildcard(_colors);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

exports.default = {
    Dropdown: {
        style: {
            width: 54,
            height: 46,
            marginTop: -3
        },
        labelStyle: {
            fontSize: 14
        },
        iconStyle: {
            top: 12,
            fill: Colors.black_25
        }
    }
};