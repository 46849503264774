'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var headerHeight = 46; //# add 2px from 44 because the effective height of the header is actually 46

var styles = {
    Drawer: {
        containerStyle: {
            overflow: 'visible'
        }
    },
    Paper: {
        style: {
            position: 'relative'
        }
    },
    Toolbar: {
        style: {
            height: headerHeight
        }
    },
    ToolbarGroup: {
        style: {
            display: 'block',
            marginTop: -4
        }
    },
    LoadingIndicator: {
        position: 'relative',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
};

exports.default = styles;