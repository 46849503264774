'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _FieldEditor = require('./FieldEditor');

var _FieldEditor2 = _interopRequireDefault(_FieldEditor);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rubyAccessManager = require('@rubyapps/ruby-access-manager');

var fieldHelperMixin = require('../fieldHelperMixin/index');
var fieldHelperMixin__KEYS = Object.keys(fieldHelperMixin);

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector,
        codeDialogActions = _selfModule$getDepend.codeDialogActions,
        frontendSettingsSelector = _selfModule$getDepend.frontendSettingsSelector,
        rubyClientFKSelector = _selfModule$getDepend.rubyClientFKSelector,
        currentUserSelector = _selfModule$getDepend.currentUserSelector,
        formsSelector = _selfModule$getDepend.formsSelector,
        getActiveRubyClientInfo = _selfModule$getDepend.getActiveRubyClientInfo;

    var boundSelfModule = _lodash2.default.mapValues(_lodash2.default.pick(selfModule, fieldHelperMixin__KEYS), function (fn) {
        return fn.bind(selfModule);
    });

    var previousTemplateProps = void 0;
    var previousAllForms = void 0;

    function getTemplatePropsFromState(state) {
        var allForms = formsSelector(state);
        var isAllFormsSame = allForms == previousAllForms;
        if (isAllFormsSame) {
            return previousTemplateProps;
        }

        previousAllForms = allForms;

        var subtemplates = _lodash2.default.pickBy(allForms, function (form) {
            return form.templateType == 'subtemplate';
        });

        var sitemapTemplates = _lodash2.default.pickBy(allForms, { templateType: 'sitemap_template' });
        var lookupTemplates = _lodash2.default.pickBy(allForms, { templateType: 'lookup_template' });
        var sdTemplates = _lodash2.default.chain(allForms).pickBy({ templateType: 'structured_data_template' }).omitBy({ key: 'global_data' }).omitBy({ key: 'rubyapps_settings' }).value();

        var contentTemplateOptions = _lodash2.default.chain({}).assign(sitemapTemplates, lookupTemplates, sdTemplates).values().map(function (form) {
            var key = form.key,
                name = form.name;

            return { value: key, primaryText: name };
        }).sortBy(['primaryText']).value();

        var newTemplateProps = {
            subtemplates: subtemplates,
            contentTemplateOptions: contentTemplateOptions
        };

        previousTemplateProps = newTemplateProps;
        return newTemplateProps;
    }

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);
        var rubyClientFK = rubyClientFKSelector(state);
        var userObject = currentUserSelector(state);
        var frontendSettings = frontendSettingsSelector(state);

        var allAccessPerm = {
            keyword: 'admin_templates_section.locked_items',
            ruby_client: rubyClientFK,
            action: 'edit'
        };

        return _extends({
            fieldState: _lodash2.default.get(selfState, ['fields', selfModule.props.key, 'value']),
            overrideLocked: rubyAccessManager.doesUserHavePermission(userObject, allAccessPerm),
            all_permissions: _lodash2.default.get(frontendSettings, ['all_permissions']),
            rubyClientInfo: getActiveRubyClientInfo()
        }, getTemplatePropsFromState(state));
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch),
            delegateActions: {
                codeDialog: (0, _redux.bindActionCreators)(codeDialogActions, dispatch)
                //# TODO: map selfModule fieldProperties methods
            }, selfModule: boundSelfModule
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_FieldEditor2.default);
}