'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AFTER_REDUCE = AFTER_REDUCE;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _store = require('store');

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function AFTER_REDUCE(griddleState) {
  var preferencesStorageKey = griddleState.get('preferencesStorageKey');
  var preferencesToSave = griddleState.get('preferencesToSave') || [];
  var stateToSave = preferencesToSave.reduce(function (map, key) {
    map[key] = griddleState.get(key);
    return map;
  }, {});

  if (preferencesStorageKey) {
    debounchedLocalStorageUpdate(preferencesStorageKey, stateToSave);
  }

  return griddleState;
}

function debounchedLocalStorageUpdate(key, value) {
  var debouncedUpdate = _lodash2.default.debounce(function (key, value) {

    var version = RUBYAPP_VERSION || '0.1';

    _store2.default.set(key, _defineProperty({}, version, value));
  }, 1000);
  debouncedUpdate(key, value);
}