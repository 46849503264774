'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _MenuItem = require('./MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector,
        actionForMenuItem = _selfModule$getDepend.actionForMenuItem,
        iconClass = _selfModule$getDepend.iconClass;

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);

        var props = selfModule.getProps(true);
        var mappedProps = _extends({
            iconClass: iconClass
        }, ownProps, props, selfState);

        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch),
            delegateActions: (0, _redux.bindActionCreators)({ actionForMenuItem: actionForMenuItem }, dispatch)
        };
    }

    var ConnectedMenuItem = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_MenuItem2.default);

    _extends(ConnectedMenuItem.propTypes, _MenuItem2.default.propTypes);
    return ConnectedMenuItem;
}