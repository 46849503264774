'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactAce = require('react-ace');

var _reactAce2 = _interopRequireDefault(_reactAce);

var _addInfoModeProps = require('@rubyapps/ruby-react-components/src/client/hoc/addInfoModeProps');

var _addInfoModeProps2 = _interopRequireDefault(_addInfoModeProps);

var _transitions = require('material-ui/styles/transitions');

var _transitions2 = _interopRequireDefault(_transitions);

var _colors = require('../../../../ruby-styles/src/client/styleObjects/colors.js');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

var PropTypes = _react2.default.PropTypes;

var AceEditor__propTypeKeys = Object.keys(_reactAce2.default.propTypes);

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label,
    RichErrorMessages = _require.RichErrorMessages;

var modeOptions = ['edit', 'read'];

var fieldBaseStyles = process.browser ? require('@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule').default : {};
var styles = fieldBaseStyles;

var styleObject = {
    AceEditor: {
        style: {
            border: '1px solid ' + _colors.borderGrey
        }
    }

    //# TODO: if this is the same as Textarea's getStyles, consolidate to ruby-styles/src/reactComponents
};var getStyles = function getStyles(props, context, state) {
    var _context$muiTheme = context.muiTheme,
        baseTheme = _context$muiTheme.baseTheme,
        _context$muiTheme$tex = _context$muiTheme.textField,
        floatingLabelColor = _context$muiTheme$tex.floatingLabelColor,
        focusColor = _context$muiTheme$tex.focusColor,
        textColor = _context$muiTheme$tex.textColor,
        disabledTextColor = _context$muiTheme$tex.disabledTextColor,
        backgroundColor = _context$muiTheme$tex.backgroundColor,
        hintColor = _context$muiTheme$tex.hintColor,
        errorColor = _context$muiTheme$tex.errorColor;


    var styles = {
        root: {
            fontSize: 16,
            lineHeight: '24px',
            width: props.fullWidth ? '100%' : 256,
            height: (props.rows - 1) * 24 + (props.floatingLabelText ? 72 : 48),
            display: 'inline-block',
            position: 'relative',
            fontFamily: baseTheme.fontFamily,
            transition: _transitions2.default.easeOut('200ms', 'height')
        },
        error: {
            position: 'relative',
            marginBottom: 2,
            fontSize: 12,
            lineHeight: '12px',
            color: errorColor,
            transition: _transitions2.default.easeOut()
        }
    };

    _extends(styles.error, props.errorStyle);

    return styles;
};

var CodeEditor = _react2.default.createClass({
    displayName: 'CodeEditor',

    propTypes: {
        code: PropTypes.string,
        title: PropTypes.string,
        open: PropTypes.bool,
        fieldKey: PropTypes.string,
        value: PropTypes.string,
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        disabled: PropTypes.bool,
        disableSyntaxChecker: PropTypes.bool,
        delegateClose: PropTypes.func,

        delegateChange: PropTypes.func,
        onSubmit: PropTypes.func,
        autoPopulateData: PropTypes.object,
        mode: PropTypes.oneOf(modeOptions)
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getInitialState: function getInitialState() {
        return {
            value: null,
            aceErrors: []
        };
    }
    /*
    , componentWillReceiveProps(nextProps) {
    
    }
    , shouldComponentUpdate: function() {
        return false;
    }
    */
    , _handleChange: function _handleChange(newValue) {
        this.props.actions.setFieldValueByKey(newValue, this.props.fieldKey);
    }

    /* //# Not needed for now. But uncomment if we need to know when ace detects errors
    , _aceEditorAnnotationCallback: function() {
        //http://stackoverflow.com/questions/25739759/how-to-get-syntax-error-on-ace-editor-and-alert-it
         const aceEditorInstance = this._aceEditorInstance;
        const aceEditorInstance_editor = aceEditorInstance.editor;
         const annotations = aceEditorInstance_editor.getSession().getAnnotations();
        const errorAnnotations = annotations.filter((annotation) => annotation.type == 'error');
         this.setState({
            aceErrors: errorAnnotations
        });
    }
    , _renderErrorMessage: function() {
        return null;
         if (this.state.aceErrors.length) {
            return <span>Error!</span>;
        }
    }
    */

    , render: function render() {
        var _React$createElement,
            _extends2,
            _React$createElement2,
            _this = this,
            _extends3,
            _React$createElement3;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var prepareStyles = this.context.muiTheme.prepareStyles;

        var styleObjectFromTheme = getStyles(this.props, this.context, this.state);

        var labelProps = _.pick(this.props, Object.keys(Label.propTypes));
        var errorTextElement = !_.isEmpty(this.props.richErrorMessages) ? _react2.default.createElement(RichErrorMessages, (_React$createElement = { messages: this.props.richErrorMessages, 'data-codecept-selector-node': 'RichErrorMessages',
            'data-codecept-selector-file': 'CodeEditor'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'CodeEditor'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'CodeEditor'), _React$createElement)) : null;

        var value = _.get(this, 'props.infoModeProps.default.value', this.props.value || '');

        var formattedValue = this.props.data_type == 'object' && _.isPlainObject(value) ? JSON.stringify(value, null, '  ') : value;

        return _react2.default.createElement(
            'div',
            (_React$createElement3 = { className: styles.wrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'CodeEditor'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'CodeEditor'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'CodeEditor'), _React$createElement3),
            _react2.default.createElement(
                'label',
                (_React$createElement2 = { className: styles.label, 'data-codecept-selector-node': 'label',
                    'data-codecept-selector-file': 'CodeEditor'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'CodeEditor'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'label'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'CodeEditor'), _React$createElement2),
                _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                    'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'CodeEditor'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'CodeEditor'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'CodeEditor'), _extends2)))
            ),
            errorTextElement,
            _react2.default.createElement(_reactAce2.default, _extends({
                mode: 'json',
                theme: 'github',
                width: '100%',
                showPrintMargin: false
            }, styleObject.AceEditor, _.pick(this.props, AceEditor__propTypeKeys), (_extends3 = {

                value: formattedValue,
                onChange: this._handleChange,
                readOnly: this.props.disabled || this.props.locked || _.get(this, 'props.infoModeProps.default.disabled'),
                onLoad: function onLoad(editor) {
                    if (_this.props.disableSyntaxChecker) {
                        editor.getSession().setUseWorker(false);
                    }
                }
                /*
                ref={(aceEditorInstance) => {
                    if (!aceEditorInstance) { return; }
                     this._aceEditorInstance = aceEditorInstance;
                    const aceEditorInstance_editor = aceEditorInstance.editor;
                     //# need to unbind and rebind because we might be getting different editor instances;
                    aceEditorInstance.editor.getSession().off("changeAnnotation", this._aceEditorAnnotationCallback);
                    aceEditorInstance.editor.getSession().on("changeAnnotation", this._aceEditorAnnotationCallback);
                }}
                */
                , 'data-codecept-selector-node': 'AceEditor',
                'data-codecept-selector-file': 'CodeEditor'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'AceEditor'), _defineProperty(_extends3, 'data-codecept-selector-file', 'CodeEditor'), _defineProperty(_extends3, 'data-codecept-selector-node', 'AceEditor'), _defineProperty(_extends3, 'data-codecept-selector-file', 'CodeEditor'), _extends3)))
        );
    }
});

//# if we need to show code without editing, add this HOC:
// import addInfoModeProps from '@rubyapps/ruby-react-components/src/client/hoc/addInfoModeProps';
exports.default = (0, _addInfoModeProps2.default)(CodeEditor);