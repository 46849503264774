'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Dialog = require('material-ui/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _reactPdfobject = require('react-pdfobject');

var _reactPlayer = require('react-player');

var _reactPlayer2 = _interopRequireDefault(_reactPlayer);

var _reactImages = require('react-images');

var _reactImages2 = _interopRequireDefault(_reactImages);

var _Icon = require('react-images/lib/components/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var mime = require('@rubyapps/ruby-mime-types');


var DialogHeader = require('@rubyapps/ruby-react-components/src/client/layout/DialogHeader').default;
var WatchWindowResizeMixin = require('@rubyapps/ruby-component-field-dialog/src/client/reactComponents/mixin-watch-window-resize');

var styles = process.browser ? require('./styles.cssModule').default : {};

var _require = require('@rubyapps/ruby-component-plugin-media-manager/src/common/constants'),
    BROWSER_SUPPORTED_IMG_TYPES = _require.BROWSER_SUPPORTED_IMG_TYPES;

var BROWSER_UNSUPPORTED_VIDEO_TYPES = ["video/quicktime"];

var modernizr = require("modernizr");
var hasWebpSupport = false;
modernizr.on('webp', function (result) {
    //# trigger async webp detection immediately
    hasWebpSupport = _lodash2.default.reduce(result, function (collector, value, key) {
        if (collector) {
            return collector;
        }

        return value;
    }, false);
});

var Modal__propTypes = {
    url: _react2.default.PropTypes.string,
    open: _react2.default.PropTypes.bool,
    delegateClose: _react2.default.PropTypes.func
};

var PDFModal = _react2.default.createClass(_extends({
    displayName: 'PDFModal',

    propTypes: Modal__propTypes

    //# trigger rerender on window resize
}, WatchWindowResizeMixin, { render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4;

        var spacingFromMaterialUI = 64 * 2;
        var adjustedWindowHeight = window.innerHeight - spacingFromMaterialUI;
        var adjustedWindowWidth = window.innerWidth * .9;

        return _react2.default.createElement(
            _Dialog2.default,
            (_React$createElement4 = {
                open: this.props.open,
                onRequestClose: this.props.delegateClose,
                autoDetectWindowHeight:
                //# we are setting height manually so that we can pass it to PDFObject
                false,
                autoScrollBodyContent:
                //# PDFObject has it's own scrollbar so `autoScrollBodyContent` adds an unnecessary scrollbar
                false,
                contentStyle: {
                    width: adjustedWindowWidth,
                    maxWidth: 'none'
                },
                bodyStyle: {
                    //# remove white-space to provide a larger view of pdf
                    padding: 0
                },
                'data-codecept-selector-node': 'Dialog',
                'data-codecept-selector-file': 'MediaModal'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'MediaModal'), _React$createElement4),
            _react2.default.createElement(
                'button',
                (_React$createElement2 = {
                    className: styles.closeButton,
                    onClick: this.props.delegateClose,
                    'data-codecept-selector-node': 'button',
                    'data-codecept-selector-file': 'MediaModal'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'button'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'button'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'MediaModal'), _React$createElement2),
                _react2.default.createElement(_Icon2.default, (_React$createElement = { type: 'close', 'data-codecept-selector-node': 'Lightbox_Icon',
                    'data-codecept-selector-file': 'MediaModal'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Lightbox_Icon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Lightbox_Icon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'MediaModal'), _React$createElement))
            ),
            _react2.default.createElement(_reactPdfobject.PDFObject, (_React$createElement3 = {
                url: this.props.url,
                height:
                //# Use height '100%' to keep this responsive. Changing the height prop doesn't rerender the
                //# <embed>. Instead, update the height of the parent to resize the PDF.
                '100%',
                containerProps: {
                    style: {
                        //# `display: 'flex'` to avoid additional whitespace below <embed> (caused by line-height)
                        display: 'flex',

                        height: adjustedWindowHeight
                    }
                },
                'data-codecept-selector-node': 'PDFObject',
                'data-codecept-selector-file': 'MediaModal'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'PDFObject'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'PDFObject'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'MediaModal'), _React$createElement3))
        );
    }
}));

var VideoModal = _react2.default.createClass({
    displayName: 'VideoModal',

    propTypes: Modal__propTypes,
    render: function render() {
        var _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8;

        return _react2.default.createElement(
            _Dialog2.default,
            (_React$createElement8 = {
                open: this.props.open,
                onRequestClose: this.props.delegateClose,
                contentStyle: {
                    maxWidth: '100%',
                    width: 640,
                    transform: 'none' //#'none' //# fix bug with video context menu placement in chrome
                },
                bodyStyle: { padding: 0 },
                'data-codecept-selector-node': 'Dialog',
                'data-codecept-selector-file': 'MediaModal'
            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'MediaModal'), _React$createElement8),
            _react2.default.createElement(
                'button',
                (_React$createElement6 = {
                    className: styles.closeButton,
                    onClick: this.props.delegateClose,
                    'data-codecept-selector-node': 'button',
                    'data-codecept-selector-file': 'MediaModal'
                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'button'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'button'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'MediaModal'), _React$createElement6),
                _react2.default.createElement(_Icon2.default, (_React$createElement5 = { type: 'close', 'data-codecept-selector-node': 'Lightbox_Icon',
                    'data-codecept-selector-file': 'MediaModal'
                }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Lightbox_Icon'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Lightbox_Icon'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'MediaModal'), _React$createElement5))
            ),
            _react2.default.createElement(_reactPlayer2.default, (_React$createElement7 = {
                url: this.props.url,
                controls: true,
                'data-codecept-selector-node': 'ReactPlayer',
                'data-codecept-selector-file': 'MediaModal'
            }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'ReactPlayer'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'ReactPlayer'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'MediaModal'), _React$createElement7))
        );
    }
});

var ImgModal = _react2.default.createClass({
    displayName: 'ImgModal',

    propTypes: Modal__propTypes,
    render: function render() {
        var _React$createElement9;

        return _react2.default.createElement(_reactImages2.default, (_React$createElement9 = {
            isOpen: this.props.open,
            images: [{
                src: this.props.url
            }],
            onClose: this.props.delegateClose,
            backdropClosesModal: true,
            showCloseButton: true,
            showImageCount: false,
            'data-codecept-selector-node': 'Lightbox',
            'data-codecept-selector-file': 'MediaModal'
        }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'Lightbox'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'Lightbox'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'MediaModal'), _React$createElement9));
    }
});

var DownloadModal = _react2.default.createClass({
    displayName: 'DownloadModal',

    propTypes: Modal__propTypes,
    render: function render() {
        var _React$createElement10, _React$createElement11, _React$createElement12, _React$createElement13, _React$createElement14;

        return _react2.default.createElement(
            _Dialog2.default,
            (_React$createElement14 = {
                open: this.props.open,
                modal: false,
                onRequestClose: this.props.delegateClose,
                bodyStyle: { padding: 24 },
                title: _react2.default.createElement(DialogHeader, (_React$createElement10 = {
                    title: 'Download',
                    delegateClose: this.props.delegateClose,
                    'data-codecept-selector-node': 'DialogHeader',
                    'data-codecept-selector-file': 'MediaModal'
                }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'DialogHeader'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'DialogHeader'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'MediaModal'), _React$createElement10)),
                actions: [_react2.default.createElement(_FlatButton2.default, (_React$createElement11 = {
                    label: 'Cancel',
                    primary: false,
                    onTouchTap: this.props.delegateClose,
                    'data-codecept-selector-node': 'FlatButton',
                    'data-codecept-selector-file': 'MediaModal'
                }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'MediaModal'), _React$createElement11)), _react2.default.createElement(
                    'a',
                    (_React$createElement13 = { href: this.props.url, download: true, 'data-codecept-selector-node': 'a',
                        'data-codecept-selector-file': 'MediaModal'
                    }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'MediaModal'), _React$createElement13),
                    _react2.default.createElement(_FlatButton2.default, (_React$createElement12 = {
                        label: 'Download',
                        primary: true,
                        onTouchTap: this.props.delegateClose,
                        'data-codecept-selector-node': 'FlatButton',
                        'data-codecept-selector-file': 'MediaModal'
                    }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'MediaModal'), _React$createElement12))
                )],
                'data-codecept-selector-node': 'Dialog',
                'data-codecept-selector-file': 'MediaModal'
            }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'Dialog'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'MediaModal'), _React$createElement14),
            'This file cannot be previewed in the browser. Download it to open the file on your computer.'
        );
    }
});

exports.default = _react2.default.createClass({
    displayName: 'MediaModal',

    propTypes: Modal__propTypes,
    render: function render() {
        var url = this.props.url;


        var urlWithoutQuery = url ? url.split('?')[0] : url;
        var mimeType = mime.lookup(urlWithoutQuery);

        if (mimeType === 'application/pdf') {
            var _extends2;

            return _react2.default.createElement(PDFModal, _extends({}, this.props, (_extends2 = {
                'data-codecept-selector-node': 'PDFModal',
                'data-codecept-selector-file': 'MediaModal'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'PDFModal'), _defineProperty(_extends2, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_extends2, 'data-codecept-selector-node', 'PDFModal'), _defineProperty(_extends2, 'data-codecept-selector-file', 'MediaModal'), _extends2)));
        } else if (!BROWSER_UNSUPPORTED_VIDEO_TYPES.includes(mimeType) && _reactPlayer2.default.canPlay(this.props.url)) {
            var _extends3;

            return _react2.default.createElement(VideoModal, _extends({}, this.props, (_extends3 = {
                'data-codecept-selector-node': 'VideoModal',
                'data-codecept-selector-file': 'MediaModal'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'VideoModal'), _defineProperty(_extends3, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_extends3, 'data-codecept-selector-node', 'VideoModal'), _defineProperty(_extends3, 'data-codecept-selector-file', 'MediaModal'), _extends3)));
        } else if (BROWSER_SUPPORTED_IMG_TYPES.includes(mimeType) || hasWebpSupport && mimeType == 'image/webp') {
            var _extends4;

            return _react2.default.createElement(ImgModal, _extends({}, this.props, (_extends4 = {
                'data-codecept-selector-node': 'ImgModal',
                'data-codecept-selector-file': 'MediaModal'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'ImgModal'), _defineProperty(_extends4, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_extends4, 'data-codecept-selector-node', 'ImgModal'), _defineProperty(_extends4, 'data-codecept-selector-file', 'MediaModal'), _extends4)));
        } else {
            var _extends5;

            return _react2.default.createElement(DownloadModal, _extends({}, this.props, (_extends5 = {
                'data-codecept-selector-node': 'DownloadModal',
                'data-codecept-selector-file': 'MediaModal'
            }, _defineProperty(_extends5, 'data-codecept-selector-node', 'DownloadModal'), _defineProperty(_extends5, 'data-codecept-selector-file', 'MediaModal'), _defineProperty(_extends5, 'data-codecept-selector-node', 'DownloadModal'), _defineProperty(_extends5, 'data-codecept-selector-file', 'MediaModal'), _extends5)));
        }
    }
});