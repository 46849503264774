'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Avatar = require('material-ui/Avatar');

var _Avatar2 = _interopRequireDefault(_Avatar);

var _Card = require('material-ui/Card');

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

var _person = require('material-ui/svg-icons/social/person');

var _person2 = _interopRequireDefault(_person);

var _fileDownload = require('material-ui/svg-icons/file/file-download');

var _fileDownload2 = _interopRequireDefault(_fileDownload);

var _error = require('material-ui/svg-icons/alert/error');

var _error2 = _interopRequireDefault(_error);

var _Loading = require('@rubyapps/ruby-react-components/src/client/view/Loading');

var _Loading2 = _interopRequireDefault(_Loading);

var _menu = require('./menu');

var _menu2 = _interopRequireDefault(_menu);

var _title = require('./title');

var _title2 = _interopRequireDefault(_title);

var _descriptiveTitle = require('./descriptive-title');

var _descriptiveTitle2 = _interopRequireDefault(_descriptiveTitle);

var _propTypes = require('./propTypes');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _constants = require('../../../common/constants');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

//# icons


var moment = require('@rubyapps/friendly-ruby-moment');

var styles = process.browser ? require('../Activity.cssModule').default : {};

var ACTIVITY_TYPES = _constants2.default.ACTIVITY_TYPES;


var ActivityHeader = _react2.default.createClass({
    displayName: 'ActivityHeader',

    propTypes: _extends({
        showAdditionalHeaderButtons: PropTypes.bool,
        getWordDocGenerationStatus_fromActivity: PropTypes.func.isRequired,
        STATUS_TYPES: PropTypes.object.isRequired
    }, _propTypes2.default),
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            showAdditionalHeaderButtons: true,
            details: {}
        };
    },
    getSubtitle: function getSubtitle() {
        return moment(this.props.created_timestamp).longButFriendly();
    },
    _shouldShowAdditionalHeaderButtons: function _shouldShowAdditionalHeaderButtons() {
        return this.props.showAdditionalHeaderButtons;
    },
    _isWordDocActivity: function _isWordDocActivity() {
        var activityType = this.props.activity_type;

        return activityType == ACTIVITY_TYPES.WORDDOC_GENERATION_ACTIVITY;
    },
    _wordDocUrl: function _wordDocUrl() {
        return _lodash2.default.get(this.props, 'details.word_download_url');
    },
    _shouldShowLoading: function _shouldShowLoading() {
        return this._isWordDocActivity() && !this._wordDocUrl();
    },
    _renderLoader: function _renderLoader() {
        var _React$createElement, _React$createElement2;

        return _react2.default.createElement(
            'div',
            (_React$createElement2 = { className: styles.loaderWrapper, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'header'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'header'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'header'), _React$createElement2),
            _react2.default.createElement(_Loading2.default, (_React$createElement = { style: { width: 20, height: 20 }, status: 'loading', 'data-codecept-selector-node': 'RubyLoading',
                'data-codecept-selector-file': 'header'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RubyLoading'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'header'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RubyLoading'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'header'), _React$createElement))
        );
    },
    _wordDownloadIcon: function _wordDownloadIcon() {
        var _React$createElement3;

        var wordDocStatus = this.props.getWordDocGenerationStatus_fromActivity(this.props);
        switch (wordDocStatus) {
            case this.props.STATUS_TYPES.COMPLETE:
                return _react2.default.createElement(_fileDownload2.default, (_React$createElement3 = {
                    'data-codecept-selector-node': 'FileDownloadIcon',
                    'data-codecept-selector-file': 'header'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'FileDownloadIcon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'header'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'FileDownloadIcon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'header'), _React$createElement3));
            case this.props.STATUS_TYPES.PROCESSING:
                return this._renderLoader();
            case this.props.STATUS_TYPES.ERROR:
                {
                    var _React$createElement4;

                    var errorColor = this.context.muiTheme.textField.errorColor;
                    return _react2.default.createElement(_error2.default, (_React$createElement4 = { style: { fill: errorColor }, 'data-codecept-selector-node': 'ErrorIcon',
                        'data-codecept-selector-file': 'header'
                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ErrorIcon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'header'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ErrorIcon'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'header'), _React$createElement4));
                }
        }
    },
    _wordDownloadLabel: function _wordDownloadLabel() {

        var wordDocStatus = this.props.getWordDocGenerationStatus_fromActivity(this.props);
        switch (wordDocStatus) {
            case this.props.STATUS_TYPES.COMPLETE:
                return "Download";
            case this.props.STATUS_TYPES.PROCESSING:
                return "Exporting";
            case this.props.STATUS_TYPES.ERROR:
                return "Export Failed";
        }
    },
    render: function render() {
        var _extends2, _extends3, _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8, _React$createElement9, _React$createElement10, _React$createElement11;

        var user = this.props.user;

        var userAvatar = _lodash2.default.get(user, ['avatarMedia']);
        var rubyUserAvatarUrl = _lodash2.default.get(userAvatar, 'thumbnailUrls.small_padded');

        var activityMenu = this.props.menu || _menu2.default;

        return _react2.default.createElement(
            'div',
            (_React$createElement11 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'header'
            }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'header'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'header'), _React$createElement11),
            _react2.default.createElement(
                _Card.CardHeader,
                (_React$createElement10 = {
                    title: this.props.verbose ? _react2.default.createElement(_descriptiveTitle2.default, _extends({}, this.props, (_extends2 = {
                        'data-codecept-selector-node': 'ActivityDescriptiveTitle',
                        'data-codecept-selector-file': 'header'
                    }, _defineProperty(_extends2, 'data-codecept-selector-node', 'ActivityDescriptiveTitle'), _defineProperty(_extends2, 'data-codecept-selector-file', 'header'), _defineProperty(_extends2, 'data-codecept-selector-node', 'ActivityDescriptiveTitle'), _defineProperty(_extends2, 'data-codecept-selector-file', 'header'), _extends2))) : _react2.default.createElement(_title2.default, _extends({}, this.props, (_extends3 = {
                        'data-codecept-selector-node': 'ActivityTitle',
                        'data-codecept-selector-file': 'header'
                    }, _defineProperty(_extends3, 'data-codecept-selector-node', 'ActivityTitle'), _defineProperty(_extends3, 'data-codecept-selector-file', 'header'), _defineProperty(_extends3, 'data-codecept-selector-node', 'ActivityTitle'), _defineProperty(_extends3, 'data-codecept-selector-file', 'header'), _extends3))),
                    subtitle: this.getSubtitle(),
                    avatar: rubyUserAvatarUrl ? _react2.default.createElement(_Avatar2.default, (_React$createElement5 = {
                        src: rubyUserAvatarUrl,
                        backgroundColor: 'transparent',
                        style: {
                            display: 'inline-block'
                        },
                        'data-codecept-selector-node': 'Avatar',
                        'data-codecept-selector-file': 'header'
                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'header'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'header'), _React$createElement5)) : _react2.default.createElement(_Avatar2.default, (_React$createElement7 = { icon: _react2.default.createElement(_person2.default, (_React$createElement6 = {
                            'data-codecept-selector-node': 'PersonIcon',
                            'data-codecept-selector-file': 'header'
                        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'PersonIcon'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'header'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'PersonIcon'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'header'), _React$createElement6)), 'data-codecept-selector-node': 'Avatar',
                        'data-codecept-selector-file': 'header'
                    }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'header'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'header'), _React$createElement7)),
                    'data-codecept-selector-node': 'CardHeader',
                    'data-codecept-selector-file': 'header'
                }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'header'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'header'), _React$createElement10),
                _react2.default.createElement(
                    'div',
                    (_React$createElement9 = { className: styles.headerButtonsWrapper, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'header'
                    }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'header'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'header'), _React$createElement9),
                    this._shouldShowAdditionalHeaderButtons() && this._isWordDocActivity() ? _react2.default.createElement(_FlatButton2.default, (_React$createElement8 = {
                        icon: this._wordDownloadIcon(),
                        label: this._wordDownloadLabel(),
                        disabled: !this.props.canUserDownload,
                        style: !this.props.canUserDownload ? { cursor: 'not-allowed' } : {},
                        href: this._wordDocUrl(),
                        target: '_blank',
                        'data-codecept-selector-node': 'FlatButton',
                        'data-codecept-selector-file': 'header'
                    }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'header'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'header'), _React$createElement8)) : null,
                    _react2.default.createElement(activityMenu, this.props)
                )
            )
        );
    }
});

exports.default = ActivityHeader;