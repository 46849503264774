'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _index = require('./reactComponents/UtilityBar/index');

var _index2 = _interopRequireDefault(_index);

var _indexConnector = require('./reactComponents/UtilityBar/indexConnector');

var _indexConnector2 = _interopRequireDefault(_indexConnector);

var _indexConnector3 = require('@rubyapps/ruby-component-mixin-list-page/src/client/reactComponents/AdvancedSearchUtilityBar/indexConnector.js');

var _indexConnector4 = _interopRequireDefault(_indexConnector3);

var _index3 = require('@rubyapps/ruby-component-mixin-list-page/src/client/reactComponents/AdvancedSearchUtilityBar/index.js');

var _index4 = _interopRequireDefault(_index3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RubyComponentAlterTemplateDialog = require('@rubyapps/ruby-component-alter-template-dialog');
var RubyComponentConfirmDialog = require('@rubyapps/ruby-component-confirm-dialog');

var ListPage = require('@rubyapps/ruby-component-list-page');

var rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');

var GriddleModule = require('@rubyapps/griddle');
var GriddleUtilityBarPlugin = GriddleModule.GriddleUtilityBarPlugin,
    GriddleRemotePlugin = GriddleModule.GriddleRemotePlugin,
    GriddleDefaultsPlugin = GriddleModule.GriddleDefaultsPlugin;

var RemotePlugin = GriddleRemotePlugin.RemotePlugin;
var superagentProvider = GriddleRemotePlugin.superagentProvider;

var ListerConnectorHelper = require('@rubyapps/ruby-component-mixin-list-page/src/client/reactComponents/ListerConnectorHelper');

function getGriddlePlugins_duringMergeProps(stateProps, dispatchProps, ownProps) {
    var utilityBarPlugin = getUtilityBarPlugin_duringMergeProps.apply(this, arguments);
    var remotePlugin = getRemotePlugin_duringMergeProps.apply(this, arguments);
    var defaultsPlugin = getDefaultsPlugin_duringMergeProps.apply(this, arguments);

    return [].concat(utilityBarPlugin, remotePlugin, defaultsPlugin).filter(_lodash2.default.identity);
}

module.exports = getGriddlePlugins_duringMergeProps;

function _getLister_fromTemplateKey(selfModule, templateKey) {
    var _selfModule$getDepend = selfModule.getDependencies(),
        listerConfigsComponent = _selfModule$getDepend.listerConfigsComponent;

    return listerConfigsComponent.listerConfigForKey(templateKey, selfModule);
}

function getDefaultsPlugin_duringMergeProps(stateProps, dispatchProps, ownProps) {
    var selfModule = this;
    var hierarchical = stateProps.hierarchical,
        columns = stateProps.columns;

    var listerConfig = _getLister_fromTemplateKey(selfModule, stateProps.template_key);

    var defaultSortColumn = selfModule.utils.determineDefaultSortColumn_withColumns_andListerConfig(columns, listerConfig);
    var defaultSortDirection = selfModule.utils.determineDefaultSortDirection_withColumns_andListerConfig(columns, listerConfig);

    return GriddleDefaultsPlugin.DefaultsPlugin(selfModule.getInitialGriddleState_withDefault({
        pageProperties: {
            pageSize: ListPage.CONSTANTS.DEFAULT_PAGE_SIZE,
            sortColumns: !hierarchical ? [defaultSortColumn] : [],
            sortAscending: defaultSortDirection == 'asc'
        }
    }));
}

function getUtilityBarPlugin_duringMergeProps(stateProps, dispatchProps, ownProps) {
    var selfModule = this;
    var showTemplateFilter = selfModule.props.showTemplateFilter;
    var customFilterName = 'filter1';

    var listerNav_defaultOption = stateProps.listerNav_defaultOption,
        listerNav_options = stateProps.listerNav_options,
        hasAddEntryPermission = stateProps.hasAddEntryPermission,
        hierarchical = stateProps.hierarchical,
        template_key = stateProps.template_key,
        customFilters = stateProps.customFilters,
        allForms = stateProps.allForms;


    var listerConfig = _getLister_fromTemplateKey(selfModule, template_key);

    var getUtilityBarActions_usingUtilityBarProps = selfModule.getComposedUtilityBarActions_usingUtilityBarProps;

    var mergedPropsFromArgument = _extends({
        listerConfig: listerConfig,
        searchableFields: selfModule.searchableFieldsForKey(template_key)
    }, ownProps, stateProps, dispatchProps);
    //# temporary cache of the props for access by Griddle
    selfModule._cachedPropsForConnector = mergedPropsFromArgument;

    if (selfModule.props.searchMode == 'advanced') {
        var _selfModule$getSavedS = selfModule.getSavedSearchListerDependencies(),
            savedSearchListerRoute = _selfModule$getSavedS.savedSearchListerRoute;

        _extends(selfModule._cachedPropsForConnector, { savedSearchListerRoute: savedSearchListerRoute });

        return GriddleUtilityBarPlugin((0, _indexConnector4.default)(selfModule, function () {
            return selfModule._cachedPropsForConnector;
        })(_index4.default));
    }

    return GriddleUtilityBarPlugin((0, _indexConnector2.default)(selfModule, function () {
        return selfModule._cachedPropsForConnector;
    })(_index2.default));
}

function getRemotePlugin_duringMergeProps(stateProps, dispatchProps, ownProps) {
    var selfModule = this;
    var listApiEndpoint = stateProps.listApiEndpoint,
        hierarchical = stateProps.hierarchical,
        filters = stateProps.filters,
        template_key = stateProps.template_key,
        customFilters = stateProps.customFilters;


    var additionalQueryParams = _lodash2.default.get(selfModule, 'props.additionalQueryParams') || {};

    var listerConfig = _getLister_fromTemplateKey(selfModule, template_key);

    var remoteConfig = {
        url: listApiEndpoint,
        formatRequest: ListerConnectorHelper.curriedSelfModule_forFormatRequest(selfModule, { listerConfig: listerConfig, additionalQueryParams: _extends(_extends({}, hierarchical ? { template_keyword: template_key } : {}), additionalQueryParams) }),
        formatSuccess: ListerConnectorHelper.formatSuccess
    };

    return RemotePlugin(superagentProvider, remoteConfig);
}