'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp2;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Checkbox = require('material-ui/Checkbox');

var _Checkbox2 = _interopRequireDefault(_Checkbox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SelectColumn = (_temp2 = _class = function (_Component) {
  _inherits(SelectColumn, _Component);

  function SelectColumn() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, SelectColumn);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SelectColumn.__proto__ || Object.getPrototypeOf(SelectColumn)).call.apply(_ref, [this].concat(args))), _this), _this._toggleSelection = function () {
      var _this$props = _this.props,
          events = _this$props.events,
          griddleKey = _this$props.griddleKey;

      events.toggleRowSelection(griddleKey);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(SelectColumn, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          griddleKey = _props.griddleKey,
          selected = _props.selected,
          isLimitHit = _props.isLimitHit;

      //# disable only if we hit the limit. If it's selected, we need to allow users to unselect

      return _react2.default.createElement(_Checkbox2.default, { checked: selected, onClick: this._toggleSelection, disabled: isLimitHit && !selected, 'data-codecept-selector-node': 'Checkbox',
        'data-codecept-selector-file': 'row'
      });
    }
  }]);

  return SelectColumn;
}(_react.Component), _class.propTypes = {
  griddleKey: _react.PropTypes.oneOfType([_react.PropTypes.string, _react.PropTypes.number]),
  selected: _react.PropTypes.bool,
  events: _react.PropTypes.object
}, _temp2);


function getSelectColumn(selected, griddleKey, events, isLimitHit) {

  var checkbox = _react2.default.createElement(SelectColumn, {
    griddleKey: griddleKey,
    key: 'select-column',
    selected: selected,
    isLimitHit: isLimitHit,
    events: events, 'data-codecept-selector-node': 'SelectColumn',
    'data-codecept-selector-file': 'row'
  });

  return { selectColumn: checkbox };
}

exports.default = function (RowComponent) {
  var _class2, _temp3;

  return _temp3 = _class2 = function (_Component2) {
    _inherits(RowComponent_Selection, _Component2);

    function RowComponent_Selection() {
      _classCallCheck(this, RowComponent_Selection);

      return _possibleConstructorReturn(this, (RowComponent_Selection.__proto__ || Object.getPrototypeOf(RowComponent_Selection)).apply(this, arguments));
    }

    _createClass(RowComponent_Selection, [{
      key: 'shouldComponentUpdate',
      value: function shouldComponentUpdate(nextProps, nextState) {

        var shouldComponentUpdateByComponent = RowComponent_Selection.staticShouldComponentUpdateByComponent(this.props, nextProps, this.state, nextState);

        this._shouldComponentUpdateByComponent = shouldComponentUpdateByComponent; //# cached for erender

        return _lodash2.default.reduce(shouldComponentUpdateByComponent, function (collector, value, key) {
          return collector || value;
        }, false);
      }
    }, {
      key: 'render',
      value: function render() {
        var _props2 = this.props,
            rowData = _props2.rowData,
            idColumn = _props2.idColumn,
            selectedRecords = _props2.selectedRecords,
            plugins = _props2.plugins;


        var SelectionPlugin = _lodash2.default.find(plugins, ['name', 'GriddleSelection']);

        var idValue = _lodash2.default.get(rowData, idColumn) || _lodash2.default.get(rowData, ['__metadata', idColumn]);
        var selected = selectedRecords[idValue] ? true : false;

        var limit = SelectionPlugin.options.limit;


        var isLimitHit = limit && _lodash2.default.size(selectedRecords) >= limit;

        var selectColumn = getSelectColumn(selected, rowData.__metadata.griddleKey, this.props.events, isLimitHit);

        //TODO: this should probably have a css class associated with it.
        var selectColumnProperties = {
          selectColumn: {
            id: "selectColumn"
          }
        };

        var newRowData = _extends({}, selectColumn, rowData);
        var columnProperties = _extends({}, selectColumnProperties, this.props.columnProperties);

        return _react2.default.createElement(RowComponent, _extends({}, this.props, {
          depth: rowData.depth || 0,
          rowData: newRowData,
          columnProperties: columnProperties,
          shouldComponentUpdateByComponent: this._shouldComponentUpdateByComponent,
          'data-codecept-selector-node': 'RowComponent',
          'data-codecept-selector-file': 'row'
        }));
      }
    }], [{
      key: 'staticShouldComponentUpdateByComponent',


      //static defaultProps = {...Component.defaultProps};
      //constructor(props, context) {super(props, context);}

      value: function staticShouldComponentUpdateByComponent(props, nextProps, state, nextState) {
        var shouldComponentUpdateByComponent_next = nextProps.shouldComponentUpdateByComponent || {};

        if (shouldComponentUpdateByComponent_next.hasOwnProperty(RowComponent_Selection.name) || shouldComponentUpdateByComponent_next.hasOwnProperty(RowComponent.name)) {
          return shouldComponentUpdateByComponent_next;
        }

        var rowData = props.rowData,
            idColumn = props.idColumn,
            selectedRecords_curr = props.selectedRecords;
        var selectedRecords_next = nextProps.selectedRecords;


        var idValue = _lodash2.default.get(rowData, idColumn) || _lodash2.default.get(rowData, ['__metadata', idColumn]);
        var selected_curr = selectedRecords_curr[idValue] ? true : false;
        var selected_next = selectedRecords_next[idValue] ? true : false;

        var shouldComponentUpdate_self = selected_curr != selected_next;
        var shouldComponentUpdate_curriedByComponent = RowComponent.staticShouldComponentUpdateByComponent ? RowComponent.staticShouldComponentUpdateByComponent.apply(null, arguments) : {};

        //console.log(`[${RowComponent_Selection.name} > ${RowComponent.name}, : ${props.rowData.name}] shouldComponentUpdate_self: ${shouldComponentUpdate_self} ... shouldComponentUpdate_curriedByComponent:`, shouldComponentUpdate_curriedByComponent);


        return _extends(_defineProperty({}, RowComponent_Selection.name, shouldComponentUpdate_self), shouldComponentUpdate_curriedByComponent);
      }
    }]);

    return RowComponent_Selection;
  }(_react.Component), _class2.PropTypes = _extends({}, RowComponent.PropTypes, {
    idColumn: _react.PropTypes.object.isRequired,
    selectedRecords: _react.PropTypes.object.isRequired }), _temp3;
};