'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _RecentUpdatesConnector = require('./reactComponents/RecentUpdatesConnector');

var _RecentUpdatesConnector2 = _interopRequireDefault(_RecentUpdatesConnector);

var _constants = require('../common/constants');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var componentName = _constants2.default.COMPONENT_NAME;

var rubyComponent = require('@rubyapps/ruby-component');

var action = require('./action');
var reducer = require('./reducer');

var RubyComponentFESettings = require('@rubyapps/ruby-component-frontend-settings');
var RubyComponentCurrentUser = require('@rubyapps/ruby-component-current-user');

var accessControlMixin = require('@rubyapps/ruby-component-mixin-access-control');

var RCRecentUpdates = rubyComponent.createClass({
    componentName: componentName,
    mixins: [accessControlMixin],
    getDefaultProps: function getDefaultProps() {
        return {
            formsID: 'rubyComponentForms'
            //# Not all the data is formatted the same way
            //# This allows us to search the results for
            //# where the data should live
            //# from the lister ajax request
        };
    },
    CONSTANTS: _constants2.default,
    action: action,
    reducer: reducer,
    dependencies: function dependencies() {
        var selfModule = this;
        var selfSelector = selfModule.getDefaultSelector();
        var selfActions = selfModule.getAction().generators;
        var rootModule = selfModule.getRoot();

        var feSettingsID = this.props.feSettingsID || RubyComponentFESettings.componentName;
        var feSettingsComponent = rootModule.findDescendentByID(feSettingsID);
        var feSettingsSelector = feSettingsComponent.getDefaultSelector();

        var rubyClientFKSelector = rootModule.findDescendentByID('rubyComponentRubyClientFK').getDefaultSelector();

        var currentUserID = this.props.currentUserID || RubyComponentCurrentUser.CONSTANTS.COMPONENT_NAME;
        var currentUser = rootModule.findDescendentByID(currentUserID);
        var currentUserSelector = currentUser ? currentUser.getDefaultSelector() : function () {
            return null;
        };

        var formsID = this.props.formsID || RubyComponentForms.CONSTANTS.COMPONENT_NAME;
        var forms = rootModule.findDescendentByID(formsID);

        return {
            selfSelector: selfSelector,
            selfActions: selfActions,
            feSettingsSelector: feSettingsSelector,
            forms: forms,
            rubyClientFKSelector: rubyClientFKSelector,
            currentUserSelector: currentUserSelector
        };
    },
    getReactClass: function getReactClass() {
        var RecentUpdatesComponent = _RecentUpdatesConnector2.default.apply(this);
        return RecentUpdatesComponent;
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var RecentUpdatesComponent = this.getReactClass();
        var reactProps = this.props.reactProps;
        //# perform connection
        //# need to access the ruby_client_fk
        //# action to open the compare dialog should give the templatekey
        return _react2.default.createElement(RecentUpdatesComponent, _extends({}, reactProps, (_extends2 = {
            'data-codecept-selector-node': 'RecentUpdatesComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'RecentUpdatesComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'RecentUpdatesComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    onReduxInit: function onReduxInit(dispatch) {
        var _getDependencies = this.getDependencies(),
            selfActions = _getDependencies.selfActions;
    }
});

module.exports = RCRecentUpdates;