'use strict';

var _reduxBatchedActions = require('redux-batched-actions');

function typesWithID(id) {
    return {
        UPDATE_TOGGLES_BY_ID__WHEN_TOGGLED: '@@ruby-app/' + id + '/UPDATE_TOGGLES_BY_ID__WHEN_TOGGLED',
        SET_TOGGLES_BY_ID__WHEN_SERVER_HYDRATES_WITH_FIELD_VALUE: '@@ruby-app/' + id + '/SET_TOGGLES_BY_ID__WHEN_SERVER_HYDRATES_WITH_FIELD_VALUE',
        INIT_TOGGLE_TREE_AND_TOGGLES_BY_ID__ON_REDUX_INIT: '@@ruby-app/' + id + '/INIT_TOGGLE_TREE_AND_TOGGLES_BY_ID__ON_REDUX_INIT'
    };
}

var generators = {
    setTogglesByIdUsingToggleCtx: function setTogglesByIdUsingToggleCtx(toggleCtx) {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES,
            generators = _getAction.generators;

        //updateUserModifiedTimestamp


        return (0, _reduxBatchedActions.batchActions)([{
            type: TYPES.UPDATE_TOGGLES_BY_ID__WHEN_TOGGLED,
            payload: { toggleCtx: toggleCtx }
        }, generators.updateUserModifiedTimestamp()]);
    },
    setTogglesByIdUsingFieldValue: function setTogglesByIdUsingFieldValue(fieldValue) {
        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES;

        return {
            type: TYPES.SET_TOGGLES_BY_ID__WHEN_SERVER_HYDRATES_WITH_FIELD_VALUE,
            payload: fieldValue
        };
    },
    initTogglesByIdUsingHydratedToggleTree: function initTogglesByIdUsingHydratedToggleTree(toggleTree) {
        var _getAction3 = this.getAction(),
            TYPES = _getAction3.TYPES;

        return {
            type: TYPES.INIT_TOGGLE_TREE_AND_TOGGLES_BY_ID__ON_REDUX_INIT,
            payload: { toggleTree: toggleTree }
        };
    }
};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};