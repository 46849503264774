'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reduxBatchedActions = require('redux-batched-actions');

var _RubyComponentFieldArrayMapConnector = require('./reactComponents/RubyComponentFieldArrayMapConnector');

var _RubyComponentFieldArrayMapConnector2 = _interopRequireDefault(_RubyComponentFieldArrayMapConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var IS_DEV_MODE = "production" !== 'production';

var React = require('react');
var _ = require('lodash');


var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

//# NOTE: helps with hydrating dependencies if you need the RubyComponent instances given an id
//import { hydrateDependenciesForRubyComponent } from '@rubyapps/ruby-component/src/client/utils/index';

//# mixins
//# TODO: support in the future
var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var fieldSetMixin = require('@rubyapps/ruby-component-mixin-field-set');
var guidHelperMixin = require('@rubyapps/ruby-component-mixin-field-guid-helper');
var forcedLeafNodeMixin = require('@rubyapps/ruby-component-mixin-field-forced-leaf-node');
var reduxHelperMixin = require('@rubyapps/ruby-component-mixin-redux-helper');

var action = require('./action');
var reducer = require('./reducer');
var displayValue = require('./displayValue');

var RCRubyComponentFieldArrayMap = RubyComponent.createClass(_extends({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin, guidHelperMixin, reduxHelperMixin, forcedLeafNodeMixin],
    propTypes: {
        actionButtons: fieldSetMixin.propTypes.actionButtons
        //# when calling on the hydrated action for each button, we will pass along the data object
        //# for the entry in the ArrayMap
        // dependentComponentID: PropTypes.string
        , toHTML: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
    },
    staticPropsByComponent: {
        'ruby-component-field-editor': {
            fieldInfo: {
                displayText: 'Array Map',
                propertyKeys: ['key', 'help_text', 'permissions', 'namespace', 'children_hidden']
            }
        }
    },
    componentName: componentName,
    action: action,
    reducer: reducer,
    getDefaultProps: function getDefaultProps() {
        return {
            // data_type: //# it's implicitly either ['string'|'number'] or ['object']
            // dependentComponentID: 'rubyComponentMediaGallery'
            //toHTML: (selfModule, data) => `data: ${data},  state: ${selfModule.getState}, props: ${selfModule.props}`
            //# NOTE: don't know if we need state and props yet, but data is for each child
            //toHTML: 'data: ${JSON.stringify(data, null, " ")},  state: ${JSON.stringify(selfModule.getState(), null, " ")}, props: ${selfModule.props}'
            toHTML: 'data: <strong>${data.myText}</strong>',
            emptyLabel: 'None'
        };
    },
    getInitialState: function getInitialState() {
        return {
            selectedValue: null,
            displayValueByHash: {}
        };
    }
    /*
    , dependencies: function() {
        //const root = this.getRoot();
        //const dependentComponent = root.findDescendentByID(this.props.dependentComponentID);
        
        const hydratedDependencies = hydrateDependenciesForRubyComponent(this, {
            dependentComponent: this.props.dependentComponentID
        });
         return {
            //, dependentComponent
            ...hydratedDependencies
        };
    }
    */
    , getReactClass: function getReactClass() {
        return _RubyComponentFieldArrayMapConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var RubyComponentFieldArrayMapComponent = this.getReactClass();

        return React.createElement(RubyComponentFieldArrayMapComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'RubyComponentFieldArrayMapComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'RubyComponentFieldArrayMapComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'RubyComponentFieldArrayMapComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    }

    //# == UTILITIES =============================================================//
    , childrenFormValueHasData: function childrenFormValueHasData() {
        var childrenFormValue = this.childrenFormValue();

        if (_.isPlainObject(childrenFormValue) || _.isArray(childrenFormValue)) {
            return _.reduce(childrenFormValue, function (collector, value) {
                if (collector) {
                    return collector;
                }

                if (!_.isNil(value)) {
                    return true;
                }

                return collector;
            }, false);
        } else {
            return !_.isNil(childrenFormValue);
        }
    },
    resetChildrenStore: function resetChildrenStore(shouldClearSelectedItem, dispatchOrCollect) {
        var _getAction = this.getAction(),
            generators = _getAction.generators;

        var collectorSpec = void 0;
        if (!dispatchOrCollect) {
            collectorSpec = this.newActionCollectorSpec();
            dispatchOrCollect = collectorSpec.collectAction;
        }

        if (shouldClearSelectedItem) {
            dispatchOrCollect(generators.clearSelectedItem());
        }

        var children = this.getChildren();

        children.forEach(function (child) {
            child.resetStore && child.resetStore(dispatchOrCollect);
        });

        collectorSpec && this.getStore().dispatch((0, _reduxBatchedActions.batchActions)(collectorSpec.collectedActions));
    }

    //# options {children} //# to allow children override for helper
    , formValueToChildrenLocalState: function formValueToChildrenLocalState(formValue, dispatchOrCollect) {
        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        var _getAction2 = this.getAction(),
            generators = _getAction2.generators;

        var formattedFormValueForHydration = _.isPlainObject(formValue) ? formValue : { undefined: formValue };

        var children = options.children || this.getChildren();

        var promiseArr = children.reduce(function (collector, child) {
            if (child._formValueToLocalState) {
                var retval = child._formValueToLocalState(formattedFormValueForHydration, dispatchOrCollect);
                collector.push(retval);

                return collector;
            }
        }, []);

        return promiseArr;
    },
    requestToAppendChildren: function requestToAppendChildren() {
        var selfModule = this;

        var _getAction3 = this.getAction(),
            generators = _getAction3.generators;

        var _getStore = this.getStore(),
            dispatch = _getStore.dispatch;

        dispatch(generators.validateChildrenFields());
        var errorObject = selfModule.formError({ ignoreChildren: false });

        var hasErrors = Object.keys(errorObject).length > 0;
        if (!hasErrors) {
            dispatch(generators.appendItem(this.childrenFormValue()));

            this.refreshDisplayValue();

            //# clear at the end
            this.resetChildrenStore();
        }
    },
    requestToDeleteChild: function requestToDeleteChild(hash) {
        var selfModule = this;

        var _getAction4 = this.getAction(),
            generators = _getAction4.generators;

        var _getStore2 = this.getStore(),
            dispatch = _getStore2.dispatch;

        var hydratedCurrentValue = this.hydratedItems(this.getFieldValue());
        var hydratedNewValue = hydratedCurrentValue.filter(function (value) {
            return value._hash != hash;
        });
        var selectedItem = this.getState().selectedValue;
        var hashForSelectedItem = selectedItem ? this.hydratedItem(selectedItem)._hash : null;

        dispatch(generators.setFieldValueByKey(this.unhydratedItems(hydratedNewValue), this.key()));

        this.refreshDisplayValue();

        if (hashForSelectedItem == hash) {
            //# clear children IFF the item being deleted is being edited
            this.resetChildrenStore(true);
        }
    },
    requestToUpdateExistingChild: function requestToUpdateExistingChild(existingChildFormValue) {
        var selfModule = this;

        var _getAction5 = this.getAction(),
            generators = _getAction5.generators;

        var _getStore3 = this.getStore(),
            dispatch = _getStore3.dispatch;

        dispatch(generators.validateChildrenFields());
        var errorObject = selfModule.formError({ ignoreChildren: false });

        var hasErrors = Object.keys(errorObject).length > 0;
        if (!hasErrors) {
            dispatch(generators.replaceChildItem(existingChildFormValue, this.childrenFormValue()));

            this.refreshDisplayValue();

            //# clear at the end
            this.resetChildrenStore(true);
        }
    },
    requestToEditChild: function requestToEditChild(hash, dispatchOrCollect) {
        var _getAction6 = this.getAction(),
            generators = _getAction6.generators;

        var _getStore4 = this.getStore(),
            dispatch = _getStore4.dispatch;

        var childFormValue = this.getChildValueForHash(hash);
        var formattedChildFormValueForHydration = _.isPlainObject(childFormValue) ? childFormValue : { undefined: childFormValue };

        var collectorSpec = void 0;
        if (!dispatchOrCollect) {
            collectorSpec = this.newActionCollectorSpec();
            dispatchOrCollect = collectorSpec.collectAction;
        }

        //# reset children store first
        this.resetChildrenStore(false, dispatchOrCollect);

        dispatchOrCollect(generators.selectItem(childFormValue));

        //# seed children
        this.formValueToChildrenLocalState(formattedChildFormValueForHydration, dispatchOrCollect);

        collectorSpec && dispatch((0, _reduxBatchedActions.batchActions)(collectorSpec.collectedActions));
    },
    requestToCancelEdit: function requestToCancelEdit() {
        //# unselect value
        this.resetChildrenStore(true);
    },

    hydratedItem: function hydratedItem(item) {
        return this.hashedItem(item);
    },
    unhydratedItem: function unhydratedItem(item) {
        return this.unhashedItem(item);
    },
    hydratedItems: function hydratedItems(items) {
        var _this = this;

        return (items || []).map(function (item, index) {
            return _extends({ _index: index }, _this.hashedItem(item));
        });
    },
    unhydratedItems: function unhydratedItems(items) {
        return (items || []).map(this.unhashedItem);
    },

    getChildValueForHash: function getChildValueForHash(hash) {
        return this.itemFromItemsMatchingHash(this.getFieldValue(), hash);
    },
    refreshDisplayValue: function refreshDisplayValue() {
        var _this2 = this;

        var _getAction7 = this.getAction(),
            generators = _getAction7.generators;

        var hydratedCurrentValue = this.hydratedItems(this.getFieldValue());

        var promisedDisplayValue = this.displayValue().then(function (displayValue) {
            var displayValueByHash = hydratedCurrentValue.reduce(function (collector, hydratedItem, index) {
                collector[hydratedItem._hash] = displayValue[index];

                return collector;
            }, {});

            _this2.getStore().dispatch(generators.updateDisplayValueByHash(displayValueByHash));
        });

        return promisedDisplayValue;
    },
    unhydratedDisplayValueByHydratedItems: function unhydratedDisplayValueByHydratedItems(displayValueByHash, hydratedItems) {
        if (!displayValueByHash) {
            displayValueByHash = this.getState().displayValueByHash;
        }
        if (!hydratedItems) {
            hydratedItems = this.hydratedItems(this.getFieldValue());
        }

        return hydratedItems.map(function (hydratedItem) {
            return displayValueByHash[hydratedItem._hash];
        });
    }

    //# == OVERRIDES =============================================================//
    /*
        Form input might look like: 
        {
            myKey: [
                {foo: 'bar'}
            ]
        }
         //# we actually don't want to allow the children to get it
    */
    , _formValueToLocalState: function _formValueToLocalState(formValue, dispatchOrCollect, isError, entireFormValue) {
        var _this3 = this;

        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        return forcedLeafNodeMixin._formValueToLocalState.apply(this, arguments).then(function (res) {
            setImmediate(function () {
                _this3.refreshDisplayValue();
            });
            //# since the root caller is waiting for promises to resolve before
            //# calling on dispatch, we need to allow that to happen
            return res;
        });
    },

    hydratedActionButtons: function hydratedActionButtons(actionButtons) {
        var _this4 = this;

        if (!actionButtons) {
            actionButtons = this.props.actionButtons || [];
        }
        var hydratedActionButtons = actionButtons.map(function (actionButton) {
            if (_.isPlainObject(actionButton) && actionButton.hasOwnProperty('action')) {
                var isRawMethod = _.get(actionButton, ['action', 'method']);
                var hydratedActionButtonSpecs = _this4._hydrateReferenceProps_withProps(actionButton);
                var hydratedAction = hydratedActionButtonSpecs.action;
                //console.log('hydratedActionButtonSpecs', actionButton, hydratedActionButtonSpecs);

                var boundAction = hydratedAction ? isRawMethod ? hydratedAction : bindActionCreators(hydratedAction, _this4.getStore().dispatch) : function () {};

                return _extends({}, actionButton, {
                    action: boundAction,
                    icon: _this4._getIconFromSpec(actionButton)
                });
            } else {
                return actionButton;
            }
        });

        return hydratedActionButtons;
    }

    //# == TESTS ====================================//
    , test: IS_DEV_MODE ? require('./browser-test') : null
}, displayValue, _.pick(fieldSetMixin, ['statesSelector', '_getIconFromSpec'])));

module.exports = RCRubyComponentFieldArrayMap;

//rubyApp.findDescendentBy(node => node.props.key == 'arrayMap_simple').getChildren()[0]._formValueToLocalState({undefined: 'test'}, store.dispatch)
//
//rubyApp.findDescendentBy(node => node.props.key == 'arrayMap_object').test().hydrate({arrayMap_object: [{myText: "test"}]})
//