'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _colors = require('@rubyapps/ruby-styles/src/client/styleObjects/colors');

var Colors = _interopRequireWildcard(_colors);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

exports.default = {
    TextField: {
        style: {
            height: 48,
            width: '100%',
            minWidth: 256
        },
        underlineStyle: {
            borderColor: Colors.black_25
        },
        inputStyle: {
            marginTop: 0,
            marginBottom: 0
        },
        floatingLabelStyle: {
            top: 13
        },
        floatingLabelFocusStyle: {
            transform: 'scale(1) translate(-22px, 0px)'
        }
    }
};