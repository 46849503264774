'use strict';

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _DatetimePickerConnector = require('./reactComponents/DatetimePickerConnector');

var _DatetimePickerConnector2 = _interopRequireDefault(_DatetimePickerConnector);

var _childrenPatcher = require('./childrenPatcher');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Promise = require('bluebird');
var React = require('react');

var moment = require('moment-timezone');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');

var FieldDatePicker = require('@rubyapps/ruby-component-field-date-picker');
var FieldTimePicker = require('@rubyapps/ruby-component-field-time-picker');

var RCDatetimePicker = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {} //# some are already included by the baseFieldMixin
    , componentName: componentName,
    getDefaultProps: CONSTANTS.GET_DEFAULT_PROPS,
    reducer: function reducer() {
        var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var action = arguments[1];

        return state;
    },
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();

        return {
            selfSelector: selfSelector
        };
    },
    rubyComponentPatcherForDate: _childrenPatcher.rubyComponentPatcherForDate,
    rubyComponentPatcherForTime: _childrenPatcher.rubyComponentPatcherForTime,
    children: function children() {
        var parentID = this.getID();
        return [this.rubyComponentPatcherForDate(RubyComponent.createElement(FieldDatePicker, _extends({
            id: parentID + '.date'
        }, this.props.childrenPropsByKey.date))), this.rubyComponentPatcherForTime(RubyComponent.createElement(FieldTimePicker, _extends({
            id: parentID + '.time'
        }, this.props.childrenPropsByKey.time)))];
    },
    getReactClass: function getReactClass() {
        return _DatetimePickerConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var DatetimePickerComponent = this.getReactClass();

        return React.createElement(DatetimePickerComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'DatetimePickerComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'DatetimePickerComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'DatetimePickerComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS,

    childrenFormValueFromFormValueForLocalState: function childrenFormValueFromFormValueForLocalState(formValue, isError) {
        var formValueForParent = formValue || {};

        if (isError) {
            // If error, then formValue might be:
            //  - a string: "Date is required"
            //  - an object: { datetime: "Invalid date", timeZone: "Time is required" }
            // We need to tranform it to an object with keys: date & time
            //  - { date: "Invalid date", time: "Time is required" }
            if (!_lodash2.default.isObject(formValueForParent)) {
                formValueForParent = { datetime: formValueForParent };
            }

            if (formValueForParent.timeZone) formValueForParent.time = formValueForParent.timeZone;

            if (formValueForParent.datetime) formValueForParent.date = formValueForParent.datetime;
        } else if (this.props.data_type == 'string') {
            formValueForParent = { datetime: formValueForParent };
        }

        var childrenFormValue = void 0;
        if (isError) {
            childrenFormValue = formValueForParent;
        } else {
            var dt = formValueForParent.datetime;
            var tz = formValueForParent.timeZone;

            // If no time and timezone selected, then we should show date as is without any TZ conversion
            // If we have a timezone, then make sure to use that in the conversion
            var date = !dt ? null : !tz ? moment(dt).utc().format('YYYY-MM-DD') : moment(dt).tz(tz).format('YYYY-MM-DD');

            var utcHourMinute = null;
            if (dt) {
                if (tz) {
                    var localMoment = moment(dt).tz(tz);
                    var localHour = localMoment.hour();
                    var localMinute = localMoment.minute();
                    var epochTimeMoment = moment.unix(0).tz(tz).set({
                        hour: localHour,
                        minute: localMinute
                    });

                    utcHourMinute = epochTimeMoment.tz('UTC').format('HH:mm');

                    // Check if the data_type is not an object; if it is one, then the timezone being undefined
                    // is an indication that the user left the Time field blank, so don't assign time a value
                } else if (this.props.data_type !== 'object') {
                    var gmtMoment = moment(dt);
                    var gmtHour = gmtMoment.hour();
                    var gmtMinute = gmtMoment.minute();
                    var gmt_epochTimeMoment = moment.unix(0).set({
                        hour: gmtHour,
                        minute: gmtMinute
                    });

                    tz = 'UTC';
                    utcHourMinute = gmt_epochTimeMoment.tz('UTC').format('HH:mm');
                }
            }
            var timeZone = tz;

            childrenFormValue = {
                date: date,
                time: {
                    time: utcHourMinute,
                    timeZone: timeZone
                }
            };
        };

        return childrenFormValue;
    }

    //# TODO: now that we have childrenFormValueFromFormValueForLocalState
    //# we can consider removing this override
    , _formValueToLocalState: function _formValueToLocalState(formValue, dispatch, isError, entireFormValue) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        var selfKey = this.props.key;
        var children = this.getChildren();

        //# support omit field picker
        var shouldOmitField = _lodash2.default.isFunction(options.omitFieldPicker) && options.omitFieldPicker.apply(options, [this].concat(Array.prototype.slice.call(arguments)));
        if (shouldOmitField) {
            return undefined;
        }

        var action = this.getAction();
        var _action$generators = action.generators,
            setFieldValueByKey = _action$generators.setFieldValueByKey,
            setFieldErrorMessageByKey = _action$generators.setFieldErrorMessageByKey;


        if (formValue && formValue.hasOwnProperty(selfKey)) {
            var formValueForParent = formValue[selfKey] || {};

            var formValueForKey = this.childrenFormValueFromFormValueForLocalState(formValueForParent, isError);

            // Datetime picker has two children: DatePicker, TimePicker
            var promiseArr = children.reduce(function (collector, child) {
                if (child._formValueToLocalState) {
                    collector.push(child._formValueToLocalState(formValueForKey, dispatch, isError, entireFormValue, options));
                }

                return collector;
            }, []);

            return Promise.all(promiseArr);
        }
    },
    _formValueFromLocalState: function _formValueFromLocalState(selfState, isError, predicateFormatter, entireState) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        if (selfState == undefined) {
            return undefined;
        }

        var selfID = this.getID();
        var selfKey = this.props.key;
        var children = this.getChildren();

        var arrOfReducedChildrenState = _lodash2.default.reduce(children, function (collector, child, index) {
            var childID = child.getID();
            var childFormValue = child.formValueFromLocalState ? child._formValueFromLocalState(selfState[childID], isError, predicateFormatter, entireState, options) : undefined;
            if (childFormValue != undefined) {
                collector.push(childFormValue);
            }
            return collector;
        }, []);

        var reducedChildrenState = _extends.apply(undefined, [{}].concat(_toConsumableArray(arrOfReducedChildrenState)));
        var reducedChildrenAvail = Object.keys(reducedChildrenState).length > 0;

        //# Note, if selfModule is an object, we would format the reducedChildrenState and return
        //# like for the case of the datePicker;
        //# prefer self value over the internal value
        var formValue = reducedChildrenAvail && selfKey ? _defineProperty({}, selfKey, reducedChildrenState) : reducedChildrenAvail ? reducedChildrenState : undefined;

        if (!formValue) {
            if (options.excludeNull) {
                return {};
            } else {
                return _defineProperty({}, selfKey, null);
            }
        }

        return _defineProperty({}, selfKey, this.datetimeFormValueFromDateAndTimeFormValue(formValue[selfKey]));
    }

    /**
     *  Return fieldSpecs to be used by ruby-content-property-helper
     *  @property props
     *  @property ctx.dataPathArray - need to include this for nested components
     *  @params {Object} ctx.selfModule - the module of the root caller
     * */
    , getFieldSpecFromProps: function getFieldSpecFromProps(props, ctx) {
        return baseFieldMixin._getFieldSpecFromProps__withoutInternalChildren(props, ctx);
    }
    //== Utility Methods ========================================================================//

    /**
     *
     *  overrideProps - because this is a utility method, it might be called statically, so we wouldn't have 'this.props'
     */
    , datetimeFormValueFromDateAndTimeFormValue: function datetimeFormValueFromDateAndTimeFormValue(formValue) {
        var overrideProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var props = this.props ? this.props : overrideProps;

        var date = formValue.date;
        var hourMinute = this.getLocalHourMinute_fromTime(formValue.time);
        var timeZone = (formValue.time || {}).timeZone; // || moment.tz.guess();
        //# NOTE: do not pass a timeZone by default
        //# if it's null, it's null

        //# 2017-08-09T21:30:00Z
        var dateFormat = 'YYYY-MM-DD HH:mm';
        var datetime = date ? moment.tz(date + ' ' + hourMinute, dateFormat, timeZone) : null;
        var datetimeStr = datetime ? datetime.clone().tz('UTC').format() : null;

        var value = datetime || timeZone ? {
            datetime: datetimeStr || null,
            timeZone: timeZone || null
        } : null;

        //# NOTE: always return UTC format for data_type string
        //# we don't store timezones in the format because of timezone translation issues
        if (props.data_type == 'string') {
            return datetimeStr;
        }

        return value;
    },
    prettyDatetimeFromDateAndTimeFormValue: function prettyDatetimeFromDateAndTimeFormValue(formValue) {
        var date = formValue.date;
        var time = this.getLocalHourMinute_fromTime(formValue.time);
        var timeZone = (formValue.time || {}).timeZone;

        var parseDateFormat = 'YYYY-MM-DD hh:mm a';
        var outputDateFormat = timeZone ? 'MM/DD/YYYY hh:mm a zz' : 'MM/DD/YYYY';
        var datetimeMoment = date ? moment.tz(date + ' ' + time, parseDateFormat, timeZone || 'UTC') : null;

        return datetimeMoment ? datetimeMoment.format(outputDateFormat) : '';
    },
    getLocalHourMinute_fromTime: function getLocalHourMinute_fromTime(time) {
        var _ref4 = time || {},
            timeString = _ref4.time,
            timeZone = _ref4.timeZone;

        if (timeString == undefined || timeZone == undefined) {
            return undefined;
        }

        if (timeString.length == 5) {
            var _timeString$split = timeString.split(':'),
                _timeString$split2 = _slicedToArray(_timeString$split, 2),
                hours = _timeString$split2[0],
                minutes = _timeString$split2[1];

            var epochMoment = moment.unix(0).tz('UTC').set({
                hour: hours,
                minute: minutes
            });
            return epochMoment.tz(timeZone).format('HH:mm');
        } else {
            var _epochMoment = moment(timeString);
            return _epochMoment.tz(timeZone).format('HH:mm');
        }
    }

    //== OVERRIDES ======================//
    ,
    _promisedFormValueFromFieldSpecs: function _promisedFormValueFromFieldSpecs() {
        var _this = this;

        var selfKey = this.props.key;

        return baseFieldMixin._promisedFormValueFromFieldSpecs.apply(this, arguments).then(function (childrenFormValue) {
            return _defineProperty({}, selfKey, _this.datetimeFormValueFromDateAndTimeFormValue(childrenFormValue[selfKey]));
        });
    }
});

module.exports = RCDatetimePicker;