'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    Dialog: {
        style: {}
    },
    CircularProgress: {
        style: {}
    },
    ModuleButton: {
        style: {
            display: 'block',
            width: '100%',
            height: 'auto',
            borderRadius: 0
        }
    },
    EditButton: {
        style: {}
    }
};