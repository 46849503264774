'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _Tab = require('./Tab');

var _Tab2 = _interopRequireDefault(_Tab);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//import {Tab} from 'material-ui/Tabs';

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector;

    var tabProps = Object.keys(_Tab2.default.propTypes).concat(['index', 'selected']);
    //# NOTE: material-ui is missing 'index' and 'selected' in props

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);

        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
        var formattedError = selfModule.formattedErrorFromState(state);

        var children = ownProps.getMergedChildrenReactElements ? ownProps.getMergedChildrenReactElements() : ownProps.children;

        return _extends({}, selfState, ownProps, mixinFieldProps, formattedError, {
            children: children
        });
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
        };
    }

    function mergeProps(stateProps, dispatchProps, ownProps) {
        return _extends({}, dispatchProps, stateProps);
    }

    //# TODO: if we need the tabs to be connected, we will need to figure out how to fix it
    var connectedTab = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(_Tab2.default);
    connectedTab.propagatedAttributes = _Tab2.default.propagatedAttributes;
    connectedTab.muiName = _Tab2.default.muiName;

    return connectedTab;

    //# WARNING: the way the material-ui Tabs component works
    //# it gets the "ownProps" for the children before it calls on this connetor
    //# this means that any alterd props does not propagate to the parent component
    //# and it's overriding the children directly expecting ownProps.children to be
    //# available
    //# which won't be if we're using the mixin-field-dynamic
}