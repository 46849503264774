'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.columnSpecsArrForSelf_andFieldSpecs = exports.columnSpecForSelfAndFieldSpec = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//const rubyWords = require('@rubyapps/ruby-words');

var BASE_COLUMN_SPEC = {
    key: '',
    displayName: ''
    //, type: ''
    , minWidth: 100
    // , width: 
    , resizable: true
    //, sortable: false
};

var DEFAULT_DATASOURCE_CONFIG = { key: 'dataPath', text: 'displayLabel' };
var TABLE_WIDTH_MAX = 1215;
var TABLE_WIDTH_MIN = 600; //# NOTE: subject to change
var DEFAULT_ACTION_COLUMN_WIDTH = 240;
var DEFAULT_MIN_WIDTH = 50;

var COMPONENT_TYPES = {
    DATE: 'date',
    DATETIME: 'datetime',
    TIME: 'time',
    HTML: 'html',
    NUMBER: 'number',
    VALUE_IN_OBJECT: 'valueInObject',
    BOOLEAN: 'booleanString'
};
function columnSpecForSelfAndFieldSpec(self) {
    var fieldSpec = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var dataSourceConfig = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DEFAULT_DATASOURCE_CONFIG;
    var listerConfig = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

    var listerConfigColumnsByKey = _lodash2.default.keyBy(_lodash2.default.get(listerConfig, 'columns', []), 'key');

    var componentName = fieldSpec.componentName,
        dataType = fieldSpec.data_type,
        urlProp = fieldSpec.url,
        toHTML = fieldSpec.toHTML,
        fieldSpec__listerConfig = fieldSpec.listerConfig;
    //# TODO: consider just include all of the fieldSpec so the griddle cells can be flexible instead of 
    //# needing to add additional props?

    var isDataTypeArray = _lodash2.default.isArray(dataType);
    var primitiveDataType = isDataTypeArray ? dataType[0] : dataType;

    var fieldSpec__key = fieldSpec[dataSourceConfig.key];

    var allowSort = isDataTypeArray ? false : true;
    var columnSpec = _extends({}, BASE_COLUMN_SPEC, {
        breadcrumb: fieldSpec.breadcrumb,
        displayName: fieldSpec[dataSourceConfig.text],
        key: fieldSpec__key,
        toHTML: toHTML
    }, {
        sortable: 1 || allowSort //# TODO
    }, listerConfigColumnsByKey[fieldSpec__key] || {}, fieldSpec__listerConfig);
    //# handle special cases first

    if (urlProp) {
        //# TODO: support Dropdown options by using humanize?
        columnSpec.type = COMPONENT_TYPES.VALUE_IN_OBJECT;
        columnSpec.shouldExpand = true;
    } else if (componentName == 'DatePicker') {
        columnSpec.type = COMPONENT_TYPES.DATE;
    } else if (componentName == 'ComponentizedDatePicker') {
        columnSpec.type = COMPONENT_TYPES.DATE;
        columnSpec.sortKey = [columnSpec.key, 'date'].join('.');
    } else if (componentName == 'DatetimePicker') {
        columnSpec.type = COMPONENT_TYPES.DATETIME;
        if (dataType != 'string') {
            columnSpec.sortKey = [columnSpec.key, 'datetime'].join('.');
        }
    } else if (componentName == 'TimePicker') {
        columnSpec.type = COMPONENT_TYPES.TIME;
        columnSpec.sortKey = [columnSpec.key, 'time'].join('.');
    } else if (primitiveDataType == 'number') {
        columnSpec.type = COMPONENT_TYPES.NUMBER;
    } else if (componentName == 'RichTextEditor') {
        columnSpec.type = COMPONENT_TYPES.HTML;
    } else if (primitiveDataType == 'boolean') {
        columnSpec.type = COMPONENT_TYPES.BOOLEAN;
    }

    //# NOTE: do this if we need to expose the spec to the griddle custom cell component
    //columnSpec.componentParams = Object.assign({}, columnSpec);

    return columnSpec;
}

var defaultOptions = {
    listerConfig: {},
    reservedColumnWidth: DEFAULT_ACTION_COLUMN_WIDTH
};
function columnSpecsArrForSelf_andFieldSpecs(self) {
    var fieldSpecs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var dataSourceConfig = arguments[2];
    var options = arguments[3];

    options = _extends({}, defaultOptions, options);
    var _options = options,
        listerConfig = _options.listerConfig;


    var columnSpecs = fieldSpecs.map(function (fieldSpec) {
        return columnSpecForSelfAndFieldSpec(self, fieldSpec, dataSourceConfig, listerConfig);
    });

    var averageWidth = (TABLE_WIDTH_MAX - options.reservedColumnWidth) / columnSpecs.length;
    var averageMinWidth = (TABLE_WIDTH_MIN - options.reservedColumnWidth) / columnSpecs.length;

    var minWidth = Math.max(DEFAULT_MIN_WIDTH, averageMinWidth);

    //const columnSpecs_normalized = columnSpecs.map(columnSpec => Object.assign({}, columnSpec, {width: averageWidth, minWidth: minWidth}));
    var columnSpecs_normalized = columnSpecs.map(function (columnSpec) {
        return _extends({}, columnSpec);
    });

    return columnSpecs_normalized;
}

exports.default = columnSpecForSelfAndFieldSpec;
exports.columnSpecForSelfAndFieldSpec = columnSpecForSelfAndFieldSpec;
exports.columnSpecsArrForSelf_andFieldSpecs = columnSpecsArrForSelf_andFieldSpecs;