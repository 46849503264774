'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var lockIconWidth = 16;

exports.default = {
    LockIcon: {
        style: {
            width: 17,
            height: 16,
            paddingLeft: 6,
            position: 'relative',
            top: 1
        }
    }
};