'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.rubyComponentPatcherForTime = exports.rubyComponentPatcherForDate = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _reduxBatchedActions = require('redux-batched-actions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function rubyComponentPatcherForTime(childComponent) {
    var selfModule = this;
    var timeComponent = childComponent;
    var timeKey = childComponent.props.key;
    //# override the actions of the child component
    var childGetAction = childComponent.getAction;
    childComponent.getAction = function () {
        var childAction = childGetAction.apply(childComponent);
        var siblings = selfModule.getChildren();
        var dateComponent = siblings[0];
        var dateKey = dateComponent.props.key;

        var hydratedAction = {
            TYPES: childAction.TYPES,
            generators: _extends({}, childAction.generators, {
                setFieldValueByKey: function setFieldValueByKey(value, key, ignoreValidation, bubblePayload) {
                    var _dateComponent$getAct = dateComponent.getAction(),
                        dateActions = _dateComponent$getAct.generators;

                    var _timeComponent$getAct = timeComponent.getAction(),
                        timeActions = _timeComponent$getAct.generators;

                    var childActionObject = childAction.generators.setFieldValueByKey(value, key, true); //# handle validation here

                    if (ignoreValidation && !bubblePayload) {
                        return childActionObject;
                    }

                    var dateValue = dateComponent.formValue()[dateKey];
                    var dateError = dateComponent.formError()[dateKey];
                    var prevDateErrorMessage = dateError;
                    var prevTimeErrorMessage = timeComponent.formError()[timeKey];

                    var collectedActions = [childActionObject];
                    var valueHasData = somethingHasData(value);

                    var dateConstraints = {
                        //required: _.get(dateComponent.props, 'verify.required') || valueHasData ? true : false
                        required: _lodash2.default.get(dateComponent.props, 'verify.required')
                    };

                    var timeConstraints = {
                        required: _lodash2.default.get(timeComponent.props, 'verify.required')
                    };

                    var dateErrors = dateComponent.validateValue_forField_againstConstraints(dateValue, dateKey, dateConstraints);
                    var timeErrors = timeComponent.validateValue_forField_againstConstraints(value, timeKey, timeConstraints);
                    var newDateErrorMessage = _lodash2.default.get(dateErrors, '0.message');
                    var newTimeErrorMessage = _lodash2.default.get(timeErrors, '0.message');
                    collectedActions.push(dateActions.setFieldErrorMessageByKey(newDateErrorMessage, dateKey));
                    collectedActions.push(timeActions.setFieldErrorMessageByKey(newTimeErrorMessage, timeKey));

                    var batchedActions = (0, _reduxBatchedActions.batchActions)(collectedActions);
                    if (bubblePayload) {
                        return batchedActions;
                    }
                    return function (dispatch) {
                        dispatch(batchedActions);
                        if (newDateErrorMessage != prevDateErrorMessage || newTimeErrorMessage != prevTimeErrorMessage) {
                            selfModule.refreshParentErrors();
                            selfModule.refreshParentErrors(); // call twice as temporary patch while RT#53866 isn't fixed, TODO: remove after fixing
                        }
                    };
                }
            })
            //# polyfill the parents prop (NOTE: not great)
        };hydratedAction.generators.setFieldValueByKey._parents = childAction.generators.setFieldValueByKey._parents;

        return hydratedAction;
    };
    return childComponent;
}

function rubyComponentPatcherForDate(childComponent) {
    var selfModule = this;
    var dateComponent = childComponent;
    var dateKey = dateComponent.props.key;
    //# override the actions of the child component
    var childGetAction = childComponent.getAction;
    childComponent.getAction = function () {
        var childAction = childGetAction.apply(childComponent);
        var siblings = selfModule.getChildren();
        var timeComponent = siblings[1];
        var timeKey = timeComponent.props.key;

        var hydratedAction = {
            TYPES: childAction.TYPES,
            generators: _extends({}, childAction.generators, {
                setFieldValueByKey: function setFieldValueByKey(value, key, ignoreValidation, bubblePayload) {
                    var _dateComponent$getAct2 = dateComponent.getAction(),
                        dateActions = _dateComponent$getAct2.generators;

                    var _timeComponent$getAct2 = timeComponent.getAction(),
                        timeActions = _timeComponent$getAct2.generators;

                    var childActionObject = childAction.generators.setFieldValueByKey(value, key, true); //# handle validation here

                    if (ignoreValidation && !bubblePayload) {
                        return childActionObject;
                    }

                    var timeValue = timeComponent.formValue()[timeKey];
                    var timeValueHasData = somethingHasData(timeValue);
                    var dateError = dateComponent.formError()[dateKey];
                    var prevDateErrorMessage = dateError;
                    var prevTimeErrorMessage = timeComponent.formError()[timeKey];

                    var collectedActions = [childActionObject];

                    var dateConstraints = {
                        //required: _.get(dateComponent.props, 'verify.required') || timeValueHasData ? true : false
                        required: _lodash2.default.get(dateComponent.props, 'verify.required')
                    };
                    var timeConstraints = {
                        required: _lodash2.default.get(timeComponent.props, 'verify.required')
                    };

                    var dateErrors = dateComponent.validateValue_forField_againstConstraints(value, dateKey, dateConstraints);
                    var timeErrors = timeComponent.validateValue_forField_againstConstraints(timeValue, timeKey, timeConstraints);
                    var newDateErrorMessage = _lodash2.default.get(dateErrors, '0.message');
                    var newTimeErrorMessage = _lodash2.default.get(timeErrors, '0.message');
                    collectedActions.push(dateActions.setFieldErrorMessageByKey(newDateErrorMessage, dateKey));
                    collectedActions.push(timeActions.setFieldErrorMessageByKey(newTimeErrorMessage, timeKey));

                    var batchedActions = (0, _reduxBatchedActions.batchActions)(collectedActions);
                    if (bubblePayload) {
                        return batchedActions;
                    }
                    return function (dispatch) {
                        dispatch(batchedActions);
                        if (newDateErrorMessage != prevDateErrorMessage || newTimeErrorMessage != prevTimeErrorMessage) {
                            selfModule.refreshParentErrors();
                            selfModule.refreshParentErrors(); // call twice as temporary patch while RT#53866 isn't fixed, TODO: remove after fixing
                        }
                    };
                }
            })

            //# polyfill the parents prop (NOTE: not great)
        };hydratedAction.generators.setFieldValueByKey._parents = childAction.generators.setFieldValueByKey._parents;

        return hydratedAction;
    };
    return childComponent;
}

function somethingHasData(something) {
    var somethingHasData = something ? true : false;
    if (_lodash2.default.isObject(something)) {
        somethingHasData = _lodash2.default.filter(something, function (something) {
            return something;
        }).length ? true : false;
    }

    return somethingHasData;
}

exports.rubyComponentPatcherForDate = rubyComponentPatcherForDate;
exports.rubyComponentPatcherForTime = rubyComponentPatcherForTime;