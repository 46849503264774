'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _TextField = require('material-ui/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _Toggle = require('material-ui/Toggle');

var _Toggle2 = _interopRequireDefault(_Toggle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var PropTypes = React.PropTypes;

var NAME_KEY = 'tab_label';

var Tab = React.createClass({
    displayName: 'Tab',

    getDefaultProps: function getDefaultProps() {
        return {
            instance: { instanceProps: {} }
        };
    },
    _getInstanceProp: function _getInstanceProp(path, defaultValue) {
        var value = _.get(this, [].concat(['props', 'instance', 'instanceProps'], path), defaultValue);
        return value && value.toJSON ? value.toJSON() : value;
    },
    _onNameChange: function _onNameChange(event) {
        var propertiesWithoutPermissions_byKey = this.props.selfModule.propertiesWithoutPermissions_byKey();
        var propsForName = propertiesWithoutPermissions_byKey[NAME_KEY];
        var constraintsForName = _.get(propsForName, 'constraints');
        this.props.set_property_toValue('label', event.target.value, constraintsForName, NAME_KEY);
    },
    _onToggleChange_forProperty: function _onToggleChange_forProperty(property) {
        var self = this;
        return function (event, toggled) {
            self.props.set_property_toValue(property, toggled);
        };
    },
    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5;

        var overrideLocked = this.props.overrideLocked;

        var isLocked = this._getInstanceProp(['_locked', 'value']) && !overrideLocked;

        return React.createElement(
            'div',
            (_React$createElement5 = { style: { display: 'flex' }, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Tab'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Tab'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Tab'), _React$createElement5),
            React.createElement(
                'div',
                (_React$createElement4 = {
                    style: { flex: 1 },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'Tab'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Tab'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Tab'), _React$createElement4),
                React.createElement(
                    'div',
                    (_React$createElement3 = {
                        style: { maxWidth: "256px" },
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'Tab'
                    }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Tab'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Tab'), _React$createElement3),
                    React.createElement(_TextField2.default, (_React$createElement = {
                        key: NAME_KEY,
                        name: NAME_KEY,
                        onChange: this._onNameChange,
                        floatingLabelText: 'Tab Label *',
                        disabled: isLocked,
                        value: this._getInstanceProp(['label', 'value']) || '',
                        errorText: this._getInstanceProp(['label', 'errorText']),
                        'data-codecept-selector-node': 'TextField',
                        'data-codecept-selector-file': 'Tab'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'TextField'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Tab'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'TextField'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Tab'), _React$createElement)),
                    React.createElement(_Toggle2.default, (_React$createElement2 = {
                        toggled: this._getInstanceProp(['_locked', 'value']) || false,
                        onToggle: this._onToggleChange_forProperty('_locked'),
                        label: 'Lock tab from being edited?',
                        disabled: overrideLocked ? false : true,
                        'data-codecept-selector-node': 'Toggle',
                        'data-codecept-selector-file': 'Tab'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Tab'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Tab'), _React$createElement2))
                )
            )
        );
    }
});
exports.default = Tab;