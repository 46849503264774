'use strict';

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Promise = require('bluebird');
var request = require('@rubyapps/ruby-superagent');

function typesWithID(id) {
    return {
        RETRIEVE_ANALYTICS_DATA: '@@ruby-app/' + id + '/RETRIEVE_ANALYTICS_DATA',
        SET_ANALYTICS_DATA: '@@ruby-app/' + id + '/SET_ANALYTICS_DATA',
        SET_DOMAIN: '@@ruby-app/' + id + '/SET_DOMAIN'
    };
}

var generators = {
    retrieveAnalyticsData: function retrieveAnalyticsData(_ref) {
        var endpointsByKey = _ref.endpointsByKey;

        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES,
            generators = _getAction.generators;

        return function (dispatch, getState) {
            Promise.props(_lodash2.default.mapValues(endpointsByKey, function (endpoint) {
                return request.get(endpoint).then(function (res) {
                    return res.body;
                });
            })).then(function (data) {
                dispatch(generators.setAnalyticsData({ data: data }));
            });
        };
    },
    setAnalyticsData: function setAnalyticsData(_ref2) {
        var data = _ref2.data;

        var _getAction2 = this.getAction(),
            TYPES = _getAction2.TYPES,
            generators = _getAction2.generators;

        return {
            type: TYPES.SET_ANALYTICS_DATA,
            payload: { data: data }
        };
    },
    openLink: function openLink(urlValue) {
        var protocolRegex = new RegExp('^(https?://)');
        var isAbsolute = protocolRegex.exec(urlValue) !== null;
        var isRootPath = urlValue.startsWith('/');

        var url = isAbsolute || isRootPath ? urlValue : '//' + urlValue;

        var newWindow = window.open();
        newWindow.opener = null;
        newWindow.location = url;
    }
};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};