'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _memoizee = require('memoizee');

var _memoizee2 = _interopRequireDefault(_memoizee);

var _mixins = require('../../../ruby-component/src/client/decorators/mixins');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RubyComponent = require('@rubyapps/ruby-component');

var rubyAccessManager = require('@rubyapps/ruby-access-manager');
var RubyComponentFeSettings = require('@rubyapps/ruby-component-frontend-settings');
var RubyComponentForms__CONSTANTS = require('@rubyapps/ruby-component-forms/src/common/constants');
var currentUserMixin = require('@rubyapps/ruby-component-mixin-current-user');

var ACCESS_CONSTANTS = rubyAccessManager.CONSTANTS;
var PERMISSION_STRINGS = ACCESS_CONSTANTS.PERMISSION_STRINGS;
var PERMISSION_ACTIONS = ACCESS_CONSTANTS.PERMISSION_ACTIONS;

var accessControlMixin = RubyComponent.createMixin({
    mixins: [currentUserMixin],
    mixinName: 'rubyComponentMixinAccessControl',
    getDefaultProps: function getDefaultProps() {
        return {
            frontendSettingsID: RubyComponentFeSettings.CONSTANTS.COMPONENT_NAME
        };
    },
    dependencies: function dependencies() {
        var rootComponent = this.getRoot();

        var feSettingsComponent = rootComponent.findDescendentByID(this.props.frontendSettingsID);

        return {
            feSettingsComponent: feSettingsComponent
        };
    },
    canUserPublish: function canUserPublish() {
        var rubyComponent = this;

        // Given a ruby client id, subsite and user object
        var rubyClientId = rubyComponent.getActiveRubyClientId();
        var subsiteId = rubyComponent.getActiveSubsiteId();
        var userObject = rubyComponent.getCurrentUserState();

        var publishPerm = _createPublishPermission(PERMISSION_STRINGS.PUBLISH, rubyClientId, subsiteId, PERMISSION_ACTIONS.EDIT);

        // Check to see if the user has publish permission
        // for the client/subsite
        return rubyAccessManager.doesUserHavePermission(userObject, publishPerm);
    },

    doesUserHavePermission: (0, _mixins.decoratedFunctionReduce_booleanAnd)(function (permInQuestion, options) {
        var rubyComponent = this;

        var userObject = rubyComponent.getCurrentUserState();

        var perm = _extends({}, permInQuestion);
        var forms = this.getForms();
        if (perm.template && forms[perm.template]) {
            //# if template prop is key instead of the id
            //# look up id from key
            var formId = _lodash2.default.get(forms, [perm.template, 'id']);
            perm.template = formId;
        }
        if (perm.ruby_client === true) {
            perm.ruby_client = rubyComponent.getActiveRubyClientId();
        }
        if (perm.subsite === true) {
            perm.subsite = rubyComponent.getActiveSubsiteId();
        }

        return rubyAccessManager.doesUserHavePermission(userObject, perm);
    }, 'doesUserHavePermission'),

    getForms: function getForms(applicationState) {
        var rubyComponent = this;

        // Get the full application state
        var store = rubyComponent.getStore();
        if (store == undefined) {
            return undefined;
        }

        applicationState = applicationState || store.getState();

        var formsSelector = this._formsSelector;
        if (!formsSelector) {
            var rootModule = rubyComponent.getRoot();
            var formsID = rubyComponent.props.formsID || RubyComponentForms__CONSTANTS.COMPONENT_NAME;
            var formsComponent = rootModule.findDescendentByID(formsID);
            formsSelector = formsComponent.getDefaultSelector();

            this._formsSelector = formsSelector;
        }

        // Return the forms state
        return formsSelector(applicationState);
    },
    getTemplateForKey: function getTemplateForKey(key) {
        return this.getForms()[key];
    },
    getFormForTemplateKey: function getFormForTemplateKey(key) {
        //# this returns the template, so use the better-named `getTemplateForKey` instead
        return this.getTemplateForKey(key);
    },
    getFrontendSettings: function getFrontendSettings() {
        var rubyComponent = this;

        // Get the full application state
        var store = rubyComponent.getStore();
        if (store == undefined) {
            return undefined;
        }

        var applicationState = store.getState();

        var feSettingsSelector = this._feSettingsSelector;
        if (!feSettingsSelector) {
            var _getDependencies = this.getDependencies(),
                feSettingsComponent = _getDependencies.feSettingsComponent;

            feSettingsSelector = feSettingsComponent.getDefaultSelector();

            this._feSettingsSelector = feSettingsSelector;
        }

        // Return the frontendSettings state
        return feSettingsSelector(applicationState);
    },

    getRubyClientInfo_forRoute: function getRubyClientInfo_forRoute(route) {
        var rubyComponent = this;

        var feSettings = this.getFrontendSettings();

        return rubyAccessManager.getRubyClientInfo_fromSettings_forRoute(feSettings, route);
    },
    getActiveRubyClientInfo: function getActiveRubyClientInfo() {
        var rubyComponent = this;

        var feSettings = this.getFrontendSettings();
        var route = window.location.pathname;

        return rubyAccessManager.getRubyClientInfo_fromSettings_forRoute(feSettings, route);
    },
    getActiveRubyClientId: function getActiveRubyClientId() {
        var rubyComponent = this;

        var feSettings = this.getFrontendSettings();
        var route = window.location.pathname;

        // Return a client id
        return rubyAccessManager.getRubyClientId_fromSettings_forRoute(feSettings, route);
    },
    getActiveSubsiteId: function getActiveSubsiteId() {
        var rubyComponent = this;

        var feSettings = this.getFrontendSettings();
        var route = window.location.pathname;

        var defaultSubsiteId = 1; // TODO: we need to remove this hard coded ID once we complete the subsite task for propgen

        // Return a subsite id
        return rubyAccessManager.getSubsiteId_fromSettings_forRoute(feSettings, route) || defaultSubsiteId;
    }

    //# url identifiers
    , getActiveRubyClientUrlIdentifier: function getActiveRubyClientUrlIdentifier() {
        var routeComponent = this.getActiveRouteComponent();

        if (!routeComponent) return;

        // Given this rubyComponent's state from the store
        var selfState = routeComponent.getState();

        // Return the part of the URL that represents the ruby client
        // For this to work, the rubyComponent should be a child of
        // the route-cms rubyComponent, which has the path:
        //   { path: "cms(/client/:rubyClient)" }
        return _lodash2.default.get(selfState, ['routeParams', 'rubyClient']);
    },
    getActiveSubsiteUrlIdentifier: function getActiveSubsiteUrlIdentifier() {
        var routeComponent = this.getActiveRouteComponent();

        var selfState = void 0;
        if (!routeComponent) {
            selfState = { routeParams: {} };
        } else {
            // Given this rubyComponent's state from the store
            selfState = routeComponent.getState();
        }
        var applicationState = this.getStore().getState();

        var routeParams = _extends({}, _lodash2.default.get(applicationState, 'routeParams', {}), _lodash2.default.get(selfState, 'routeParams', {}));
        // Return the part of the URL that represents the subsite
        // For this to work, the :subsite route should be part of this
        // rubyComponent's path prop:  { path: "/content/:subsite/quick-find" }
        return _lodash2.default.get(routeParams, 'subsite');
    },
    getSubsiteUrlIdentifier_forRubyClientId_andSubsiteId: function getSubsiteUrlIdentifier_forRubyClientId_andSubsiteId(rubyClientId, subsiteId) {
        var feSettings = this.getFrontendSettings();

        return rubyAccessManager.getSubsiteUrlIdentifier_fromSettings_forRubyClientId_andSubsiteId(feSettings, rubyClientId, subsiteId);
    },
    getDefaultSubsiteUrlIdentifier: function getDefaultSubsiteUrlIdentifier() {
        var feSettings = this.getFrontendSettings();

        // Return default subsiteUrl
        return rubyAccessManager.getDefaultSubsiteUrlIdentifier_fromSettings(feSettings);
    }

});

module.exports = accessControlMixin;

// Memoize here, so that doesUserHavePermission memoize works
var _createPublishPermission = (0, _memoizee2.default)(_raw_createPublishPermission, { length: 4 });
function _raw_createPublishPermission(keyword, ruby_client, subsite, action) {
    return {
        keyword: keyword,
        ruby_client: ruby_client,
        subsite: subsite,
        action: action
    };
}