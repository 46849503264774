'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var MenuItemStyle = {
    innerDivStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    }
};

var WHERE_FILTER = {
    "templateType": {
        "inq": ["lookup_template", "sitemap_template", "structured_data_template", "proposal_page_template", "proposal_template"]
    }
};
var hiddenSearchableFields = [{
    componentName: "Hidden",
    dataPath: 'template_keyword',
    menuItemProps: _extends({
        primaryText: "Template"
    }, MenuItemStyle),
    key: 'template_keyword',
    label: 'Template',
    url: '/ruby/api/v2/templates/options?value_field_key=key&where=' + JSON.stringify(WHERE_FILTER)
}];

exports.default = hiddenSearchableFields;