'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = function (preferencesStorageKeyIdentifier, preferencesToSave) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  // figure out the key where the preferences are saved

  var version = RUBYAPP_VERSION || '0.0';

  var preferencesStorageKey = [constants.LOCAL_STORAGE_KEY];

  if (preferencesStorageKeyIdentifier) {
    preferencesStorageKey.push(preferencesStorageKeyIdentifier);
  }
  preferencesStorageKey = preferencesStorageKey.join('::') || '';

  // fetch the preferences
  var savedGriddleState = _lodash2.default.get(_store2.default.get(preferencesStorageKey), [version], {});

  //# incorporate any initialState defined by options
  preferencesToSave.forEach(function (preferenceKey) {
    var overrideState = _lodash2.default.get(options, ['initialState', preferenceKey]);
    if (overrideState) {
      if (_lodash2.default.isPlainObject) {
        savedGriddleState[preferenceKey] = _extends({}, savedGriddleState[preferenceKey], overrideState);
      } else {
        savedGriddleState[preferenceKey] = overrideState;
      }
    }
  });

  return _immutable2.default.fromJS(_extends({}, savedGriddleState, { preferencesStorageKey: preferencesStorageKey,
    preferencesToSave: preferencesToSave,
    filterHintText: options.filterHintText || 'Search'
  }));
};

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _store = require('store');

var _store2 = _interopRequireDefault(_store);

var _preferencesPluginConstants = require('./preferences-plugin-constants');

var constants = _interopRequireWildcard(_preferencesPluginConstants);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

;