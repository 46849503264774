'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.idLimitedPermissionsFromUser_forPerm = exports.idsInRubyPermissionsFromUser_forPerm = exports.idsInRubyPermissionsFromUser_forPermJSON = exports.boolean_fromPermissionObject_andUser = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.doesUserMatchPermissionObject = doesUserMatchPermissionObject;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _rubyMemoize = require('../../../ruby-memoize');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var memoize = require('memoizee');


var propsForNormalization = ['id', 'last_modified_timestamp'];
function getNormalizedUserObject(userObject) {
    //# return userObject.toMemoizableObject();
    //# NOTE: would be better if we can use 'toMemoizableObject()' but this module is common
    //# so client-side code wouldn't have that kind of user object


    var rubyRole = typeof userObject.rubyRole == 'function' ? userObject.rubyRole().toJSON() : userObject.rubyRole;
    var normalizedUserObject = _extends({}, _lodash2.default.pick(userObject, propsForNormalization), {
        rubyRole: _lodash2.default.pick(rubyRole, propsForNormalization)
    });

    return normalizedUserObject;
};

var boolean_fromPermissionObject_andUser = exports.boolean_fromPermissionObject_andUser = memoize(raw_boolean_fromPermissionObject_andUser, _extends({}, _rubyMemoize.defaultMemoizeeOptions, {
    length: 2,
    normalizer: function normalizer(args) {
        var checkForPerm = args[0];
        var userObject = args[1];

        var normalizedUserObject = userObject ? getNormalizedUserObject(userObject) : undefined;
        var normalizedArgs = JSON.stringify([checkForPerm, normalizedUserObject]);
        return normalizedArgs;
    }
}));
var idsInRubyPermissionsFromUser_forPermJSON = exports.idsInRubyPermissionsFromUser_forPermJSON = memoize(raw_idsInRubyPermissionsFromUser_forPermJSON, _extends({}, _rubyMemoize.defaultMemoizeeOptions, {
    length: 2,
    normalizer: function normalizer(args) {
        var userObject = args[0];
        var permJSON = args[1];

        var normalizedUserObject = userObject ? getNormalizedUserObject(userObject) : undefined;

        var normalizedArgs = JSON.stringify([getNormalizedUserObject(userObject), permJSON]);
        return normalizedArgs;
    }
}));

var idLimitedPermissionsFromUser_forPermJSON = memoize(raw_idLimitedPermissionsFromUser_forPermJSON, _extends({}, _rubyMemoize.defaultMemoizeeOptions, {
    length: 2,
    normalizer: function normalizer(args) {
        var userObject = args[0];
        var permJSON = args[1];

        var normalizedUserObject = userObject ? getNormalizedUserObject(userObject) : undefined;

        var normalizedArgs = JSON.stringify([getNormalizedUserObject(userObject), permJSON]);
        return normalizedArgs;
    }
}));

var idsInRubyPermissionsFromUser_forPerm = exports.idsInRubyPermissionsFromUser_forPerm = function idsInRubyPermissionsFromUser_forPerm(user, perm) {
    return idsInRubyPermissionsFromUser_forPermJSON(user, JSON.stringify(perm));
};
var idLimitedPermissionsFromUser_forPerm = exports.idLimitedPermissionsFromUser_forPerm = function idLimitedPermissionsFromUser_forPerm(user, perm) {
    return idLimitedPermissionsFromUser_forPermJSON(user, JSON.stringify(perm));
};

var ID_KEY = 'id';
var TEMPLATE_KEY = 'template';
var MODEL_KEY = 'model';
var ACTION_KEY = 'action';
var checkThesePermsIndividually = [ID_KEY];

//# normalize perm to include id and template if necessary
//# by default, perms that contain "model" key should have an "id" and "template" key
//# which is normalized to "*"
function normalizedPerm(perm) {
    var normalizedPerm = void 0;
    if (perm.hasOwnProperty('model')) {
        normalizedPerm = _extends({
            id: '*',
            template: '*'
        }, perm);
    } else {
        normalizedPerm = _extends({}, perm);
    }

    //# get priority
    normalizedPerm._priority = priorityForPerm(normalizedPerm);

    return normalizedPerm;
}

var priorityKeys = [ID_KEY, TEMPLATE_KEY, MODEL_KEY, ACTION_KEY];
var priorityEnumByKey = {
    id: 1000,
    template: 100,
    model: 10,
    action: 1
};
function priorityForPerm(perm) {
    var priority = priorityKeys.reduce(function (collector, key) {
        if (perm.hasOwnProperty(key) && perm[key] != '*') {
            return collector + priorityEnumByKey[key];
        }
        return collector;
    }, 0);

    return priority;
}

function raw_boolean_fromPermissionObject_andUser(checkForPerm, userObject) {
    /* The userObject should look like:
     *     userObject = {
     *         username: "rubytest",
     *         rubyRole: {
     *             name: "Administrator",
     *             ruby_permissions: [ ... ]
     *         }
     *     }
     */

    if (!userObject || !userObject.rubyRole || !userObject.rubyRole.ruby_permissions) {
        // If it doesn't, then they DO NOT have permission
        return false;
    }

    var additional_ruby_permissions = _lodash2.default.get(userObject, 'additional_ruby_permissions', []);
    var ruby_permissions = userObject.rubyRole.ruby_permissions;

    var finalRubyPermissions = ruby_permissions.concat(additional_ruby_permissions);

    /* Permissions look like:
     *      ruby_permissions: [
     *         { model: 'content', template: 12, ruby_client: 3, subsite: 1, action: 'add' },
     *         { model: 'content', template: 5,  ruby_client: 3, subsite: 1, action: 'add' }
     *         { keyword: 'sitemap',             ruby_client: 3, subsite: 1, action: 'nav' }
     *      ]
     *
     * Every permission object, must have a ruby_client and action.
     * Permissions that map to an API model, must have the model property.
     * Permissions that are not for an API model, must have the keyword property.
     * Permissions for the content model must also have a template & subsite property.
     * Any of the values can be a single value, an array or a wildcard "*"
     *
     * Actions:
     * - add lets user add entries to this model
     * - edit lets the user update entries for this model
     * - delete lets the user delete entries for this model
     * - get lets the user retrieve/list all entries from this model
     * - wildcard "*" lets the user do all of the above
     * - restricted lets the user add pages, and edit/get/delete pages based on the restricted values
     *
     * We support id specific permissions. The way it works is as follows:
     * - IF any perm has the 'id' key, then id specific permission is enabled for that permission
     * - IF the value for the 'id' is [], then NOTHING is permitted
     * - IF the value for the 'id' is [...], then only items with an id contained inside is permitted
     * - IF there's a ruby_permission object with {template: '*'}, this means it applies to all permission with a template key
     *       NOTE: specificity for permissions goes like this:
     *       [id, template, action, model]
     *       So
     *          {id: [], template: '*'} > {id:'*', template: '101'}
     *          BUT
     *          {id: [], template: '101'} > {id: [], template: '*'}
     * For keyword permissions, that might not have a specific action, it is
     * best practice to set the action to the wildcard.  When checking in the
     * to see if a user has the permission, the action shoud be "edit".
     *
     */

    var permsMatchingKeys = _lodash2.default.reduce(finalRubyPermissions, function (collector, perm) {
        // Permission from user/role should be an object
        if (!_lodash2.default.isObject(perm) || perm == null) {
            return collector;
        }

        var newPerm = normalizedPerm(perm);

        var permKeys = Object.keys(newPerm);
        var checkForPermKeys = Object.keys(checkForPerm);
        //# Must have the same set of base keys to match

        var diffedKeys = _lodash2.default.difference(checkForPermKeys, permKeys);

        if (diffedKeys.length == 0) {
            //# checkForPerm has all the keys that perm has (ie. checkForPermKeys is a subset of permKeys)
            collector.push(newPerm);
        }
        /*
        const keysAreEquivalent = _.isEqual(_.difference(permKeys, ['_priority']), checkForPermKeys);
        if (keysAreEquivalent) {
            collector.push(newPerm);
        }
        */

        return collector;
    }, []);

    var permsMatchingKeys_sortedByPriority = permsMatchingKeys.sort(function (a, b) {
        return b._priority - a._priority;
    });
    var permsMatchingKeyValues_ignoringArrays = _lodash2.default.filter(permsMatchingKeys_sortedByPriority, function (perm) {
        var noMatchKeys = _lodash2.default.reduce(_lodash2.default.omit(perm, ['_priority']), function (diff, value, key) {
            if (value == '*' ||
            //# only need to check if checkForPerm has the property if the permission isn't '*'
            checkForPerm.hasOwnProperty(key) && (_lodash2.default.isArray(value) || value == checkForPerm[key])) {
                return diff;
            }
            return _lodash2.default.assign({}, diff, _defineProperty({}, key, value));
        }, {});

        return _lodash2.default.isEmpty(noMatchKeys) ? true : false;
    });

    //# since permsMatchingKeyValues_ignoringArrays is sorted by priority order, the first group of highest priority
    //# permissions MUST match
    //# EXAMPLE:
    //#     [{action: '*', model:[], _priority:10}, {action:'get', model:'*', _priority:1}]
    //#     the first permission overrides the second, that means for NO models do we allow ANY actions

    if (permsMatchingKeyValues_ignoringArrays.length == 0) {
        return false;
    }

    var priorityToCheck = permsMatchingKeyValues_ignoringArrays[0]._priority;
    var matchingPerms = _lodash2.default.filter(permsMatchingKeyValues_ignoringArrays, function (perm) {
        var noMatchKeys = _lodash2.default.reduce(_lodash2.default.omit(perm, ['_priority']), function (diff, value, key) {
            var checkForPerm_atKey_asArr = [].concat(checkForPerm[key]);
            var value_asArr = [].concat(value);
            if (value == '*' || checkForPerm.hasOwnProperty(key) &&
            /*
            (
                //_.isArray(value)
                //&& _.find(value, testVal => testVal == checkForPerm_atKey) != undefined
            )
            || value == checkForPerm[key]
            */
            _lodash2.default.intersectionWith(value_asArr, checkForPerm_atKey_asArr, function (valA, valB) {
                return valA == valB;
            }).length) {
                return diff;
            }

            return _lodash2.default.assign({}, diff, _defineProperty({}, key, value));
        }, {});

        return _lodash2.default.isEmpty(noMatchKeys) && priorityToCheck == perm._priority ? true : false;
    });

    //# NOTE: matchingPerms WILL return an array of perms that match IF we have
    //# {id:[], template: '*'} AND {id:[], template:''}
    //# we need to sort them by priority order then CHECK for best match and see if it's applicable

    return _lodash2.default.isEmpty(matchingPerms) ? false : true;
}

function raw_idsInRubyPermissionsFromUser_forPermJSON(user, permJSON) {
    var needlePerm = JSON.parse(permJSON);
    var needlePermKeys = Object.keys(needlePerm);
    if (needlePermKeys.length == 0) {
        //# if the needlePerm is empty, don't allow anything to return
        return [];
    }

    //# only get ids for action:* or action:'get' or action:['get']
    var foundPerms = raw_idLimitedPermissionsFromUser_forPermJSON(user, permJSON);

    var idsFromFoundPerms = _lodash2.default.reduce(foundPerms, function (collector, perm) {
        var newCollector = collector;
        if (perm.id) {
            if (!_lodash2.default.isArray(newCollector)) {
                newCollector = [];
            }

            newCollector = newCollector.concat(perm.id);
        }
        return newCollector;
    }, undefined);

    return idsFromFoundPerms;
}

function raw_idLimitedPermissionsFromUser_forPermJSON(user, permJSON) {
    var needlePerm = JSON.parse(permJSON);
    var needlePermKeys = Object.keys(needlePerm);
    if (needlePermKeys.length == 0) {
        //# if the needlePerm is empty, don't allow anything to return
        return [];
    }

    var rubyRole = typeof user.rubyRole == 'function' ? user.rubyRole().toJSON() : user.rubyRole;
    var rubyPermissions = rubyRole.ruby_permissions;
    var additionalRubyPermissions = _lodash2.default.get(user, 'additional_ruby_permissions', []);
    var allRubyPermissions = rubyPermissions.concat(additionalRubyPermissions);

    //# only get ids for action:* or action:'get' or action:['get']
    var foundPerms = _lodash2.default.filter(allRubyPermissions, function (perm) {
        var permMatches = _lodash2.default.reduce(needlePerm, function (isMatch, needlePermValue, needlePermKey) {
            if (isMatch == false) {
                return isMatch;
            }
            var needlePermValue_asArr = [].concat(needlePermValue);

            var permValue = _lodash2.default.get(perm, needlePermKey);
            var permValue_asArr = [].concat(permValue);
            var additionalMatch = permValue == '*' || _lodash2.default.intersectionWith(permValue_asArr, needlePermValue_asArr, function (valA, valB) {
                return valA == valB;
            }).length;

            return isMatch && additionalMatch;
        }, true);

        return permMatches;
    });

    var filteredFoundPerms = foundPerms.filter(function (perm) {
        return perm.hasOwnProperty('id');
    });

    return filteredFoundPerms;
}

function doesUserMatchPermissionObject(userObject, permissionsObjectArray) {
    return _lodash2.default.every(permissionsObjectArray.map(function (perm) {
        return boolean_fromPermissionObject_andUser(perm, userObject);
    }));
}