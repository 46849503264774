'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reduxBatchedActions = require('redux-batched-actions');

var _ExpandedDataConnector = require('./reactComponents/ExpandedDataConnector');

var _ExpandedDataConnector2 = _interopRequireDefault(_ExpandedDataConnector);

var _action = require('./action');

var _action2 = _interopRequireDefault(_action);

var _reducer = require('./reducer');

var _reducer2 = _interopRequireDefault(_reducer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var path = require('path');
var Promise = require('bluebird');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

var React = require('react');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

//# mixins
var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationsMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var fieldUrlMixin = require('@rubyapps/ruby-component-mixin-field-url');
var fieldUrlMixin__CONSTANTS = require('@rubyapps/ruby-component-mixin-field-url/src/common/constants');
var requestTrackerMixin = require('@rubyapps/ruby-component-mixin-request-tracker');

var _require = require('@rubyapps/advanced-loopback-filters'),
    createLoopbackFilterer = _require.createLoopbackFilterer;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

//# NOTE: this is a Fieldset-class component. It doesn't typically have a key assigned to it
var ExpandedData = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationsMixin, fieldUrlMixin, requestTrackerMixin],
    action: _action2.default,
    reducer: _reducer2.default,
    propTypes: {
        watch: PropTypes.shape({
            interval: PropTypes.number,
            retries: PropTypes.number //# NOTE: not a great solution for waiting for async processes that ExpandedData has no access to (eg. triggering a regenerateAllMedia and waiting for regenerate_all_media_progress)
        }),
        unwatch: PropTypes.shape({
            stopOnMatch: PropTypes.object,
            stopOnMatchLBFilter: PropTypes.object
        }),
        mode: PropTypes.oneOf(['info', 'control', 'edit']),
        returnRaw: PropTypes.bool //# always force the value to be returned
        //# mode: 'edit' will also work

    },
    staticPropsByComponent: {
        'ruby-component-field-editor': {
            fieldInfo: {
                displayText: 'Expanded Data',
                propertyKeys: ['key', 'help_text', 'permissions', 'namespace', 'children_hidden']
            }
        }
    },
    getDefaultProps: function getDefaultProps(props) {
        return {
            listerConfig: {
                excludeFromColumnSelection: true,
                excludeFromFilterSelection: true
                //# TODO: bug: need to figure out how to skip fields like for media
            },
            mode: 'info' //# info mode by default since ExpandedData currently isn't used to edit data, just displaying data
            /*
            , watch: {
                interval: 5000
                , on: 'save'
            }
            */
        };
    },
    getInitialState: function getInitialState() {
        return {
            pristineData: null,
            url: null
        };
    },
    componentName: componentName

    //# NOTE: no need to customize children when we can use the regular children prop
    , getReactClass: function getReactClass() {
        return _ExpandedDataConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var Component = this.getReactClass();

        return React.createElement(Component, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'Component',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Component'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Component'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },

    shouldRefresh: function shouldRefresh() {
        var didUrlDependentFieldsUpdate = this.didUrlDependentFieldsUpdate();
        //const hasPendingRefresh = !!this._promisedRefreshData && !this._promisedRefreshData.isFulfilled();

        return didUrlDependentFieldsUpdate;
    },
    onReduxInit: function onReduxInit(dispatch) {
        var _this = this;

        var store = this.getStore();

        var _props = this.props,
            watchProps = _props.watch,
            unwatchProps = _props.unwatch;


        var stopOnMatch = _.get(unwatchProps, ['stopOnMatch']);
        var stopOnMatchLBFilter = _.get(unwatchProps, ['stopOnMatchLBFilter']);

        var refreshDataForUrl = function refreshDataForUrl(url, options) {
            var promisedRefreshData = _this.getStatefulCacheForKey('_promisedRefreshData');

            var finalPromisedRefreshData = void 0;
            if (promisedRefreshData && promisedRefreshData.isPending()) {
                finalPromisedRefreshData = promisedRefreshData.then(function () {
                    return _this.refreshDataForUrl(url, options);
                }, function () {
                    //# refresh data regardless
                    return _this.refreshDataForUrl(url, options);
                });
            } else {
                finalPromisedRefreshData = _this.refreshDataForUrl(url, options);
            }

            return finalPromisedRefreshData ? finalPromisedRefreshData.catch(function (error) {
                if (error != fieldUrlMixin__CONSTANTS.ERRORS.INVALID_URL) {
                    throw error;
                }
            }) : undefined;
        };

        var unwatch = void 0;
        var watch = void 0;
        var watch__handler = void 0;
        var watchIntervalId = void 0;

        if (watchProps && (watchProps.interval || watchProps.on)) {
            var retryCount = watchProps.retries;

            watch__handler = function watch__handler(newValue, state, prevValue) {
                if (watchProps.on == 'save' && prevValue == null) {
                    //# return prematurely since it's the page load
                    return;
                }
                var promisedRefreshDataForUrl = refreshDataForUrl(_this.url(), { ignoreUrlCache: true });

                if (promisedRefreshDataForUrl) {
                    promisedRefreshDataForUrl.then(function (data) {
                        if (retryCount) {
                            retryCount--;return;
                        }
                        //# Do not allow stop as long as there are retries

                        if (stopOnMatch) {
                            if (_.isMatch(data, stopOnMatch)) {
                                unwatch && unwatch();
                            }
                        }

                        if (stopOnMatchLBFilter) {
                            var lbFilterer = createLoopbackFilterer();
                            var filteredData = lbFilterer([data], stopOnMatchLBFilter);

                            var dataMatchesFilter = filteredData && filteredData.length;

                            if (!_.isEmpty(data) && dataMatchesFilter) {
                                unwatch && unwatch();
                            }
                        }
                    }).catch(function (error) {
                        // the sysevent record is not found. Assume the status is success.
                        if (error.status === 404) {
                            unwatch && unwatch();
                        }
                    });
                }
            };

            watch = function watch() {
                logger.trace('Start watching url for:', _this.props);

                if (watchProps.interval) {
                    clearInterval(watchIntervalId);
                    watchIntervalId = setInterval(function () {
                        logger.trace('setInterval triggered for Watching url for:', _this.props);
                        watch__handler();
                    }, watchProps.interval);
                }

                if (watchProps.on && watchProps.on == 'save') {
                    //# TODO: when watching on, this seems to exponentially grow
                    //# but `once` doesn't resolve the issue
                    _this.getParentFormComponent().on('state:pristineFormData.last_modified_timestamp', watch__handler);
                }
            };

            unwatch && unwatch(); //# unwatch before replacing the unwatch method
            unwatch = function unwatch() {
                watchIntervalId && clearInterval(watchIntervalId);
                _this.getParentFormComponent().off('state:pristineFormData.last_modified_timestamp', watch__handler);
                watchIntervalId = null;
            };
        }

        var unsubscribe = store.subscribe(function () {
            //const shouldRefresh = this.didUrlDependentFieldsUpdate();
            var shouldRefresh = _this.shouldRefresh();
            if (!shouldRefresh) {
                return;
            }

            var url = _this.url();

            if (url) {
                //console.log(`== [${this.getID()}] shouldRefresh: ${shouldRefresh} with url: ${url}`, this.getStatefulCacheForKey('_cachedUrlDependentFieldValues'))
                refreshDataForUrl(url);
                watch && watch();
            } else {
                refreshDataForUrl(url); //# to clear the children forms
                unwatch && unwatch();
            }
        });

        refreshDataForUrl(this.url());

        return function () {
            unwatch && unwatch();
            unsubscribe();

            //# reset store: to clear out pristineData and url used for comparision
            //# this is necessary to allow for this field to rerender properly
            //# This was an issue if ExpandedData is nested under DynamicForm since DynamicForm might remove this field
            //# but the state would not be cleared, therefore, if the DynamicForm reinserts the ExpandedData component
            //# it won't reload it's children with the same data
            var collectionOfActions = [];
            var includeAction = function includeAction(action) {
                collectionOfActions.push(action);
            };
            _this.resetStore(includeAction);

            var _getStore = _this.getStore(),
                dispatch = _getStore.dispatch;

            dispatch((0, _reduxBatchedActions.batchActions)(collectionOfActions));
        };
    },

    refreshDataForUrl: function refreshDataForUrl(url) {
        var _this2 = this;

        var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
            ignoreUrlCache = _ref.ignoreUrlCache;

        var _getAction = this.getAction(),
            generators = _getAction.generators;

        var _getStore2 = this.getStore(),
            dispatch = _getStore2.dispatch;

        var cachedUrl = this.getStatefulCacheForKey('_refreshDataUrl') || false;
        url = url != undefined ? url : this.url();
        //# only do something if the url is different
        if (!ignoreUrlCache && cachedUrl == url) {
            //console.log('=== skipping refreshDataForUrl because url is same:', url);
            return false;
        }
        this.setStatefulCacheForKey('_refreshDataUrl', url);

        if (!url) {
            //# reset children store
            dispatch(generators.resetChildrenLocalState());

            var promisedRefreshDataERR = Promise.reject(fieldUrlMixin__CONSTANTS.ERRORS.INVALID_URL);
            this.setStatefulCacheForKey('_promisedRefreshData', null);
            //# don't need to cache it

            return promisedRefreshDataERR;
        }

        var _getState = this.getState(),
            prevPristineData = _getState.pristineData,
            prevUrl = _getState.url;

        //# NOTE: the caching of _promisedRefreshData is not great


        var promisedRefreshData = new Promise(function (resolve, reject) {
            dispatch(generators.retrieveDataAtEndpoint(url, [function (data) {
                _this2.setStatefulCacheForKey('_promisedRefreshData', null);

                if (!_.isEqual(prevPristineData, data) || prevUrl != url //# NOTE: sometimes the data might be the same but the url is different
                ) {
                        //# need to defensively check if the component is still mounted
                        //# otherwise it'll trigger a never resolved promiseTree
                        if (_this2._onReduxInit_returnValues) {
                            dispatch(generators.formValueToChildrenLocalState(data));
                        }
                    }

                resolve(data);
            }, function (error) {
                return reject(error);
            }]));
        });

        this.setStatefulCacheForKey('_promisedRefreshData', promisedRefreshData);

        return promisedRefreshData;
    }
    //# options {children} //# to allow children override for helper
    , formValueToChildrenLocalState: function formValueToChildrenLocalState(formValue, dispatchOrCollect) {
        var _this3 = this;

        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        var _getStore3 = this.getStore(),
            dispatch = _getStore3.dispatch;

        var key = this.props.key;


        var formattedFormValueForHydration = void 0;

        var existingFormValue = this._seedFormValue;
        //this.clearStatefulCacheForKey('seedFormValue');

        if (_.isPlainObject(formValue)) {
            //# only allow this once because formValueToChildrenLocalState is async
            formattedFormValueForHydration = _extends({}, formValue, existingFormValue ? _.omitBy((key ? existingFormValue[key] : existingFormValue) || {}, _.isNil) : {});
        } else {
            formattedFormValueForHydration = { undefined: existingFormValue || formValue };
        }

        var children = options.children || this.getChildren();

        var resetStoreActionCollector = [];
        var collectResetStoreAction = function collectResetStoreAction(value) {
            resetStoreActionCollector.push(value);
        };

        //# NOTE: don't clearForm, resetState instead because we need to clear nested ExpandedData internal states
        //# NOTE: resetting child not self
        return this.resetChildrenLocalState(collectResetStoreAction).then(function () {
            //# Dispatch resetStore actions before calling `_formValueToLocalState` to avoid reseting store after
            //# hydration.
            //# In DynamicForm fields, for example, `_formValueToLocalState` dispatches some actions directly
            //# instead of using the provided `dispatchOrCollect`. The state changes from those actions are lost if
            //# we wait to dispatch the resetStore actions.
            dispatch((0, _reduxBatchedActions.batchActions)(resetStoreActionCollector));

            var promiseArr = children.reduce(function (collector, child) {
                if (_.get(child.getAction(), 'generators.seedWithFormData')) {
                    //# NOTE: dispatchOrCollect won't work here
                    collector.push(Promise.fromCallback(function (cb) {
                        return dispatch(child.getAction().generators.seedWithFormData(formattedFormValueForHydration, true, false //# dont' clear form, we're handling the clear ourselves
                        , cb, _.omit(options, ['children'])));
                    }));
                } else if (child._formValueToLocalState) {
                    //# using `dispatch(batchActions(resetStoreActionCollector))` above instead bc this resets
                    //# synchronous state changes made by `_formValueToLocalState`
                    //# in particular, this was undoing hydration of child DynamicForm fields
                    //child.resetStore(dispatchOrCollect);

                    var retval = child._formValueToLocalState(formattedFormValueForHydration, dispatchOrCollect, false, undefined, _.omit(options, ['children']));
                    collector.push(retval);
                }
                return collector;
            }, []);

            return Promise.all(promiseArr);
        }).then(function (res) {
            _this3._seedFormValue = undefined;
            return res;
        });
    },
    resetChildrenLocalState: function resetChildrenLocalState(dispatchOrCollect) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var children = options.children || this.getChildren();

        var promiseArr = children.reduce(function (collector, child) {
            if (child._formValueToLocalState) {
                var retval = child.resetStore(dispatchOrCollect);
                collector.push(retval);

                return collector;
            }
        }, []); //# NOTE: resetStore currently doesn't actually return a promise

        return Promise.all(promiseArr);
    }

    //# == Overrides ===========================//
    , _formValueToLocalState: function _formValueToLocalState(formValue, dispatchOrCollect) {
        if (this.props.mode == 'edit') {
            this._seedFormValue = formValue; //# we need to save it here because
            //# statefulCache is cleared before we can use it because
            //# we trigger resetStore
            return baseFieldMixin._formValueToLocalState.apply(this, arguments);
        }

        return Promise.resolve(); //# nothing to seed;
        //# All of the children under this field uses external data
        //# so we don't want to conflate the children data with the parent form
    },
    _formValueFromLocalState: function _formValueFromLocalState(selfState, isError, predicateFormatter, entireState) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
        var _options$returnRaw = options.returnRaw,
            returnRaw = _options$returnRaw === undefined ? this.props.returnRaw || this.props.mode == 'edit' : _options$returnRaw;


        if (returnRaw) {
            var rawFormValue = baseFieldMixin._formValueFromLocalState.apply(this, arguments);

            return rawFormValue;
        } else {
            return undefined; //# nothing to return
        }
        //# All of the children under this field uses external data
        //# so we don't want to conflate the children data with the parent form
    },
    clearForm: function clearForm(dispatchOrCollect) {
        //# clears both value and errors for form
        if (!dispatchOrCollect) {
            dispatchOrCollect = this.getStore().dispatch;
        }
        var children = this.getChildren();

        var _getAction2 = this.getAction(),
            actions = _getAction2.generators;
        //# getInitialState() and replace the field


        var initialState = this.getInitialState ? this.getInitialState() : {};
        if (initialState.hasOwnProperty('fields') || initialState.hasOwnProperty('error')) {
            dispatchOrCollect(actions.replaceLocalState(_.pick(initialState, ['fields', 'error'])));
        }

        //# NOTE: for expanded-data, since we're expanding the data ourselves, we shouldn't allow for form to be cleared from a parent call
        /*
        children.forEach((child) => {
            if (child.clearForm) {
                child.clearForm(dispatchOrCollect);
            }
        });
        */
    }
});

module.exports = ExpandedData;