'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Avatar = require('material-ui/Avatar');

var _Avatar2 = _interopRequireDefault(_Avatar);

var _FlatButton = require('material-ui/FlatButton');

var _FlatButton2 = _interopRequireDefault(_FlatButton);

var _Menu = require('material-ui/Menu');

var _Menu2 = _interopRequireDefault(_Menu);

var _MenuItem = require('material-ui/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _Popover = require('material-ui/Popover');

var _Popover2 = _interopRequireDefault(_Popover);

var _person = require('material-ui/svg-icons/social/person');

var _person2 = _interopRequireDefault(_person);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var UserStatusTool = _react2.default.createClass({
    displayName: 'UserStatusTool',

    propTypes: {
        userInfo: PropTypes.shape({
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            avatar: PropTypes.number
        }),
        open: PropTypes.bool //# not necessary for now,
        //# but might want it if we want to control the state of this component in the future
        , delegateLogOut: PropTypes.func,
        delegateUpdateAccount: PropTypes.func,
        delegateActions: PropTypes.object

        //# TODO: change this to redux store instead
    }, getInitialState: function getInitialState() {
        return {
            open: false
        };
    },

    _handleRequestClose: function _handleRequestClose() {
        this.setState({
            open: false
        });
    },
    _handleUserNameTouchTap: function _handleUserNameTouchTap(event) {
        event.preventDefault();

        this.setState({
            open: true,
            anchorEl: event.currentTarget
        });
    },
    _handleUpdateAccountTouchTap: function _handleUpdateAccountTouchTap(event) {
        event.preventDefault();

        this.props.delegateUpdateAccount && this.props.delegateUpdateAccount();
    },
    _handleLogOutTouchTap: function _handleLogOutTouchTap(event) {
        event.preventDefault();
        this.props.delegateActions.currentUser.logOut();
    },

    render: function render() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6, _React$createElement7, _extends2, _React$createElement8;

        var userPopoverProps = {
            open: this.state.open,
            anchorEl: this.state.anchorEl
        };

        var userFirstName = _lodash2.default.get(this.props, ['userInfo', 'first_name'], '');
        var userLastName = _lodash2.default.get(this.props, ['userInfo', 'last_name'], '');
        var userAvatar = _lodash2.default.get(this.props, ['userInfo', 'avatarMedia']);
        var rubyUserAvatarUrl = _lodash2.default.get(userAvatar, 'thumbnailUrls.small_padded');

        var userFullName = userFirstName + ' ' + userLastName;

        return _react2.default.createElement(
            'div',
            (_React$createElement8 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'UserStatusTool'
            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'UserStatusTool'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'UserStatusTool'), _React$createElement8),
            _react2.default.createElement(_FlatButton2.default, (_React$createElement4 = {
                label: userFullName,
                onTouchTap: this._handleUserNameTouchTap,
                icon: rubyUserAvatarUrl ? _react2.default.createElement(_Avatar2.default, (_React$createElement = {
                    src: rubyUserAvatarUrl,
                    size: 30,
                    backgroundColor: 'transparent',
                    style: { display: 'inline-block' },
                    'data-codecept-selector-node': 'Avatar',
                    'data-codecept-selector-file': 'UserStatusTool'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'UserStatusTool'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'UserStatusTool'), _React$createElement)) : _react2.default.createElement(_Avatar2.default, (_React$createElement3 = { icon: _react2.default.createElement(_person2.default, (_React$createElement2 = {
                        'data-codecept-selector-node': 'PersonIcon',
                        'data-codecept-selector-file': 'UserStatusTool'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'PersonIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'UserStatusTool'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'PersonIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'UserStatusTool'), _React$createElement2)), size: 30, color: '#fff', 'data-codecept-selector-node': 'Avatar',
                    'data-codecept-selector-file': 'UserStatusTool'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'UserStatusTool'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Avatar'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'UserStatusTool'), _React$createElement3)),
                'data-codecept-selector-node': 'FlatButton',
                'data-codecept-selector-file': 'UserStatusTool'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'UserStatusTool'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'FlatButton'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'UserStatusTool'), _React$createElement4)),
            _react2.default.createElement(
                _Popover2.default,
                _extends({
                    onRequestClose: this._handleRequestClose
                }, userPopoverProps, (_extends2 = {
                    'data-codecept-selector-node': 'Popover',
                    'data-codecept-selector-file': 'UserStatusTool'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Popover'), _defineProperty(_extends2, 'data-codecept-selector-file', 'UserStatusTool'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Popover'), _defineProperty(_extends2, 'data-codecept-selector-file', 'UserStatusTool'), _extends2)),
                _react2.default.createElement(
                    _Menu2.default,
                    (_React$createElement7 = {
                        'data-codecept-selector-node': 'Menu',
                        'data-codecept-selector-file': 'UserStatusTool'
                    }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'Menu'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'UserStatusTool'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'Menu'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'UserStatusTool'), _React$createElement7),
                    !_lodash2.default.get(this, 'props.userInfo.isTemporaryUser') && _react2.default.createElement(_MenuItem2.default, (_React$createElement5 = { primaryText: 'Update Account', onTouchTap: this._handleUpdateAccountTouchTap, 'data-codecept-selector-node': 'MenuItem',
                        'data-codecept-selector-file': 'UserStatusTool'
                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'UserStatusTool'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'UserStatusTool'), _React$createElement5)),
                    _react2.default.createElement(_MenuItem2.default, (_React$createElement6 = { primaryText: 'Log Out', onTouchTap: this._handleLogOutTouchTap, 'data-codecept-selector-node': 'MenuItem',
                        'data-codecept-selector-file': 'UserStatusTool'
                    }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'UserStatusTool'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'MenuItem'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'UserStatusTool'), _React$createElement6))
                )
            )
        );
    }
});

exports.default = UserStatusTool;