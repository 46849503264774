'use strict';

var _ = require('lodash');

//# TODO: move to ruby-lodash
//# childrenIterator = (children) => { returns matching children; }
var DEFAULT_CHILDREN_SELECTOR = function DEFAULT_CHILDREN_SELECTOR(node, nodepathArr) {
    return node.children;
};
//# return all children by default
//# meaning that we'll always walk the entire tree
//# override this if we can hint which nodes to skip
//# reducer = (collector, value, nodepathArr) => {}
//# NOTE: keypathArr here doesn't account for the key used to select children
//# since the knowledge of the key is encapsulated in the childrenSelector
//# NOTE: nodepathArr: is array of nodes encountered
function depthFirstReduce(node, reducer, initialValue) {
    var childrenSelector = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : DEFAULT_CHILDREN_SELECTOR;
    var nodepathArr = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];

    var childrenNodes = childrenSelector(node, nodepathArr) || [];

    return childrenNodes.reduce(function (collector, childNode, index) {
        var childNodepathArr = nodepathArr.concat(childNode);
        //# first call on reducer
        var updatedCollector = reducer(collector, childNode, childNodepathArr);

        //# then recurse
        return depthFirstReduce(childNode, reducer, updatedCollector, childrenSelector, childNodepathArr);
    }, initialValue);
}

function childrenSelectorMatchingKeypath_byKey_forChildrenKey(keypath) {
    var propertyKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'key';
    var childrenKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'children';

    var targetKeypathArr = _.isArray(keypath) ? keypath : keypath.split('.');

    return function (node, nodepathArr) {
        var keypathArr = nodepathArr.map(function (n) {
            return n.key;
        }).filter(_.identity);
        var nextKey = targetKeypathArr[keypathArr.length];

        var selectedChildren = (node[childrenKey] || []).reduce(function (collector, childNode, index) {
            if (childNode.hasOwnProperty(propertyKey)) {
                if (childNode.key == nextKey) {
                    collector.push(childNode);
                }
            } else {
                collector.push(childNode);
            }

            return collector;
        }, []);

        return selectedChildren;
    };
}

module.exports = {
    depthFirstReduce: depthFirstReduce,
    childrenSelectors: {
        childrenSelectorMatchingKeypath_byKey_forChildrenKey: childrenSelectorMatchingKeypath_byKey_forChildrenKey
    }
};