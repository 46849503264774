'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

var _require = require('./utils'),
    ancestorIdsForId_usingTogglesById = _require.ancestorIdsForId_usingTogglesById;

module.exports = function (state, toggleCtx) {
    var togglesById = state.togglesById,
        toggleTree = state.toggleTree;


    var toggleIds = idsToUpdateFromToggleCtx_usingToggleTree_andTogglesById(toggleCtx, toggleTree, togglesById);

    var mutableTogglesById = _.cloneDeep(togglesById); //# Don't modify original togglesById - instead modify a copy
    var updatedTogglesById = setTogglesById_forToggleIds_usingToggleCtx(mutableTogglesById, toggleIds, toggleCtx);

    return updatedTogglesById;
};

function idsToUpdateFromToggleCtx_usingToggleTree_andTogglesById(toggleCtx, toggleTree, togglesById) {
    var toggledId = toggleCtx.toggledId,
        isToggled = toggleCtx.isToggled;


    var collectedIds = _.reduce(toggleTree, function (acc, treeNode) {
        var id = treeNode.id,
            _treeNode$children = treeNode.children,
            children = _treeNode$children === undefined ? [] : _treeNode$children,
            fieldKey = treeNode.fieldKey;

        var collectedIds = id === toggledId ? acc.concat(id, ancestorToggleIdsInheritingToggleCtx_usingTogglesById(toggleCtx, togglesById), descendantToggleIdsFromToggleTree(children)) : idsToUpdateFromToggleCtx_usingToggleTree_andTogglesById(toggleCtx, children, togglesById);

        return acc.concat(collectedIds);
    }, []);

    return collectedIds;
}

function ancestorToggleIdsInheritingToggleCtx_usingTogglesById(toggleCtx, togglesById) {
    var toggledId = toggleCtx.toggledId,
        isToggled = toggleCtx.isToggled;

    return isToggled ? ancestorIdsForId_usingTogglesById(toggledId, togglesById)
    //# If a node is toggled OFF then we must check if any siblings are toggled ON
    //# If no siblings are toggled ON then we can collect the parent id and recurse up the tree
    : ancestorIdsForId_untilNoSiblingsMatchToggleCondition_usingTogglesById(toggledId, isToggled, togglesById);
}

function ancestorIdsForId_untilNoSiblingsMatchToggleCondition_usingTogglesById(toggleId, toggleCondition, togglesById) {
    var collectedAncestorIds = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];

    var parentId = togglesById[toggleId].parentId;
    if (!parentId) {
        return collectedAncestorIds;
    }
    var siblingIds = Object.keys(togglesById).filter(function (id) {
        return togglesById[id].parentId === parentId && id !== toggleId;
    });
    var siblingsNotMatchingToggleCondition = siblingIds.filter(function (siblingId) {
        return togglesById[siblingId].toggled === !toggleCondition;
    });

    return _.isEmpty(siblingsNotMatchingToggleCondition) ? ancestorIdsForId_untilNoSiblingsMatchToggleCondition_usingTogglesById(parentId, toggleCondition, togglesById, collectedAncestorIds.concat(parentId)) : collectedAncestorIds;
}

function descendantToggleIdsFromToggleTree(toggleTree) {
    var toggleIds = _.reduce(toggleTree, function (acc, treeNode) {
        var id = treeNode.id,
            _treeNode$children2 = treeNode.children,
            children = _treeNode$children2 === undefined ? [] : _treeNode$children2;

        return _.isEmpty(children) ? acc.concat(id) : acc.concat(id, descendantToggleIdsFromToggleTree(children));
    }, []);

    return toggleIds;
}

function setTogglesById_forToggleIds_usingToggleCtx(togglesById, toggleIds, toggleCtx) {
    var isToggled = toggleCtx.isToggled;

    var mutatedTogglesById = _.reduce(toggleIds, function (acc, id) {
        var toggleData = acc[id];
        var updatedToggleData = _extends({}, toggleData, { toggled: isToggled });

        return _extends(acc, _defineProperty({}, id, updatedToggleData));
    }, togglesById);

    return mutatedTogglesById;
}