'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var styles = process.browser ? require('./TFA.cssModule').default : {};

var TFAInfo = _react2.default.createClass({
    displayName: 'TFAInfo',

    // TODO: update branding
    propTypes: {
        styles: PropTypes.object,
        rubyBranding: PropTypes.string,
        introNodes: PropTypes.arrayOf(PropTypes.node) //# override the intro
        , mode: PropTypes.oneOf(['default', 'required', 'login'])
    },
    getDefaultProps: function getDefaultProps() {
        return {
            styles: styles,
            rubyBranding: 'RubyContent',
            mode: 'default'
        };
    },
    renderIntro: function renderIntro() {
        var _React$createElement, _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5, _React$createElement6, _React$createElement7, _React$createElement8, _React$createElement9;

        var _props = this.props,
            styles = _props.styles,
            rubyBranding = _props.rubyBranding,
            mode = _props.mode;


        var infoArray = [_react2.default.createElement(
            'p',
            (_React$createElement = { className: styles.infoParagraph, 'data-codecept-selector-node': 'p',
                'data-codecept-selector-file': 'TFAInfo'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TFAInfo'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TFAInfo'), _React$createElement),
            rubyBranding,
            ' Multi-Factor Authentication (MFA) adds an extra layer of protection on top of your username and password. With MFA enabled, you will be prompted for your username and password, as well as for an authentication code from an MFA device. By requiring both, these multiple factors provide increased security for your ',
            rubyBranding,
            ' account. If your organization does not provide you with a compatible MFA device, we recommend you download and install the \u201CGoogle Authenticator\u201D mobile application for use as your virtual MFA\xA0device.'
        )];

        switch (mode) {
            case 'login':
                return [_react2.default.createElement(
                    'p',
                    (_React$createElement2 = { className: styles.infoParagraph, 'data-codecept-selector-node': 'p',
                        'data-codecept-selector-file': 'TFAInfo'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TFAInfo'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TFAInfo'), _React$createElement2),
                    rubyBranding,
                    ' Multi-Factor Authentication (MFA) is required.'
                ), _react2.default.createElement(
                    'p',
                    (_React$createElement3 = { className: styles.infoParagraph, 'data-codecept-selector-node': 'p',
                        'data-codecept-selector-file': 'TFAInfo'
                    }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TFAInfo'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TFAInfo'), _React$createElement3),
                    rubyBranding,
                    ' MFA adds an extra layer of protection on top of your username and password. With MFA enabled, you will be prompted for a second form of authentication via a unique code from an MFA device in addition to your username and password. If your organization does not provide you with a compatible MFA device, we recommend you download and install the \u201CGoogle Authenticator\u201D mobile application for use as your virtual MFA\xA0device.'
                ), _react2.default.createElement(
                    'p',
                    (_React$createElement4 = { className: styles.infoParagraph, 'data-codecept-selector-node': 'p',
                        'data-codecept-selector-file': 'TFAInfo'
                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TFAInfo'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TFAInfo'), _React$createElement4),
                    'Click "Begin Setup" below. Then, using your MFA device, scan the QR Code or enter the registration key manually. Then, enter the 6-digit token provided by your device into the "MFA Token"\xA0field.'
                ), _react2.default.createElement(
                    'p',
                    (_React$createElement5 = { className: styles.infoParagraph, 'data-codecept-selector-node': 'p',
                        'data-codecept-selector-file': 'TFAInfo'
                    }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'TFAInfo'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'TFAInfo'), _React$createElement5),
                    'Going forward, you will need your MFA device with you every time you log in to\xA0',
                    rubyBranding,
                    '.'
                )];

            case 'required':
                infoArray.push(_react2.default.createElement(
                    'p',
                    (_React$createElement6 = { className: styles.infoParagraph, 'data-codecept-selector-node': 'p',
                        'data-codecept-selector-file': 'TFAInfo'
                    }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'TFAInfo'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'TFAInfo'), _React$createElement6),
                    'If you need to reset your MFA secret, or transfer it to another device, press the "Invalidate Multi-Factor Secret" button below and save. You will be prompted to setup MFA the next time you log\xA0in.'
                ));
                infoArray.push(_react2.default.createElement(
                    'p',
                    (_React$createElement7 = { className: styles.infoParagraph, 'data-codecept-selector-node': 'p',
                        'data-codecept-selector-file': 'TFAInfo'
                    }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'TFAInfo'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'TFAInfo'), _React$createElement7),
                    'You may also "Begin Setup" below. Then, using an MFA device, scan the QR Code or enter the registration key manually. Then, enter the 6-digit token into the "MFA Token" field below and\xA0save.'
                ));
                break;
            default:
                infoArray.push(_react2.default.createElement(
                    'p',
                    (_React$createElement8 = { className: styles.infoParagraph, 'data-codecept-selector-node': 'p',
                        'data-codecept-selector-file': 'TFAInfo'
                    }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'TFAInfo'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'TFAInfo'), _React$createElement8),
                    'To enable MFA, toggle the "Enable Multi-Factor Authentication (MFA)" field below. Using an MFA device, scan the QR Code or enter the registration key manually. Then, enter the 6-digit token into the "MFA Token" field below and\xA0save.'
                ));
        }

        infoArray.push(_react2.default.createElement(
            'p',
            (_React$createElement9 = { className: styles.infoParagraph, 'data-codecept-selector-node': 'p',
                'data-codecept-selector-file': 'TFAInfo'
            }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'TFAInfo'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'p'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'TFAInfo'), _React$createElement9),
            'Going forward, you will need your MFA device with you every time you log in to RubyLaw.'
        ));

        return infoArray;
    },
    render: function render() {
        var _React$createElement10;

        var _props2 = this.props,
            styles = _props2.styles,
            rubyBranding = _props2.rubyBranding,
            introNodes = _props2.introNodes;


        return _react2.default.createElement(
            'div',
            (_React$createElement10 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'TFAInfo'
            }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'TFAInfo'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'TFAInfo'), _React$createElement10),
            introNodes || this.renderIntro()
        );
    }
});

exports.default = TFAInfo;