'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var action = require('./action');

//# Note: not great but we can make certain assumptions based on the params
function hydratedParams() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    params = params || {};
    var hydratedParams = _extends({}, params);

    if (params.hasOwnProperty('template_keyword')) {
        hydratedParams.template = hydratedParams.template || params.template_keyword;
    }

    if (params.hasOwnProperty('id')) {
        hydratedParams.action = hydratedParams.action || 'edit';
    }

    return hydratedParams;
}

var routeHelperMixin = {
    action: action //# provide some actions to help with navigation to all components

    , parentRouteComponent: function parentRouteComponent() {
        var parentRouteComponent = this.findAncestorBy(function (node) {
            return node.hasOwnProperty('getRouteElement');
        }) || this;

        return parentRouteComponent;
    },
    _getBestUrlForComponentName_withparams: function _getBestUrlForComponentName_withparams(componentName, params) {
        //# get nearest route component
        var parentRouteComponent = this.parentRouteComponent();

        var rootModule = this.getRoot();

        var bestUrl = rootModule.getBestUrlForComponentName_fromModule_withParams(componentName, parentRouteComponent, hydratedParams(params));

        return bestUrl;
    },
    openBestUrlForComponentName_withParams: function openBestUrlForComponentName_withParams(componentName, params) {
        var bestUrl = this._getBestUrlForComponentName_withparams.apply(this, arguments);

        window.open(window.location.protocol + '//' + window.location.host + bestUrl, '_blank');
    },
    navigateToBestUrlForComponentName_withParams: function navigateToBestUrlForComponentName_withParams(componentName, params) {
        var bestUrl = this._getBestUrlForComponentName_withparams.apply(this, arguments);

        this.getStore().dispatch(this.getAction().generators.navigateToPathWithOptions({ path: bestUrl }));
    }
};

module.exports = routeHelperMixin;