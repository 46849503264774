'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');

var isDevMode = "production" !== 'production';

var devModeMessage = isDevMode ? 'WARNING: you\'re calling RubyComponent.createElement() on an undefined RubyComponent. Please doublecheck your project\'s client/index.js file' : null;
var MissingComponentPlaceholder = React.createClass({
    displayName: 'MissingComponentPlaceholder',

    render: function render() {
        var _React$createElement;

        return React.createElement(
            'div',
            (_React$createElement = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'MissingComponentPlaceholder'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'MissingComponentPlaceholder'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'MissingComponentPlaceholder'), _React$createElement),
            devModeMessage,
            this.props.children
        );
    }
});

exports.default = MissingComponentPlaceholder;