'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Card = require('material-ui/Card');

var _MediaModal = require('./MediaModal');

var _MediaModal2 = _interopRequireDefault(_MediaModal);

var _zoomIn = require('material-ui/svg-icons/action/zoom-in');

var _zoomIn2 = _interopRequireDefault(_zoomIn);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;
exports.default = _react2.default.createClass({
    displayName: 'MediaModalThumbnail',

    propTypes: {
        url: PropTypes.string,
        thumbnailUrl: PropTypes.string,
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    },
    getDefaultProps: function getDefaultProps() {
        return {};
    },
    getInitialState: function getInitialState() {
        return {
            dialogOpen: false
        };
    },
    _openDialog: function _openDialog() {
        this.setState({ dialogOpen: true });
    },
    _closeDialog: function _closeDialog() {
        this.setState({ dialogOpen: false });
    },
    render: function render() {
        var _this = this,
            _React$createElement,
            _React$createElement2,
            _React$createElement3,
            _React$createElement4,
            _React$createElement5;

        return _react2.default.createElement(
            _Card.Card,
            (_React$createElement5 = {
                'data-codecept-selector-node': 'Card',
                'data-codecept-selector-file': 'MediaModalThumbnail'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'MediaModalThumbnail'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'MediaModalThumbnail'), _React$createElement5),
            _react2.default.createElement(
                _Card.CardMedia,
                (_React$createElement3 = {
                    onMouseEnter: function onMouseEnter() {
                        _this.setState({ hovered: true });
                    },
                    onMouseLeave: function onMouseLeave() {
                        _this.setState({ hovered: false });
                    },
                    overlay: this.state.hovered ? _react2.default.createElement(_zoomIn2.default, (_React$createElement = {
                        style: {
                            width: '50%',
                            height: '50%',
                            maxWidth: '75px',
                            maxHeight: '75px'
                        },
                        color: 'white',
                        'data-codecept-selector-node': 'ZoomInIcon',
                        'data-codecept-selector-file': 'MediaModalThumbnail'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ZoomInIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'MediaModalThumbnail'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'ZoomInIcon'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'MediaModalThumbnail'), _React$createElement)) : null,
                    overlayContainerStyle: {
                        pointerEvents: 'none'
                    },
                    overlayStyle: {
                        width: '100%',
                        height: '100%'
                    },
                    overlayContentStyle: {
                        width: '100%',
                        height: '100%',
                        padding: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    },
                    'data-codecept-selector-node': 'CardMedia',
                    'data-codecept-selector-file': 'MediaModalThumbnail'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'CardMedia'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'MediaModalThumbnail'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'CardMedia'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'MediaModalThumbnail'), _React$createElement3),
                _react2.default.createElement('img', (_React$createElement2 = {
                    src: this.props.thumbnailUrl,
                    onTouchTap: this._openDialog,
                    style: {
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'scale-down',
                        cursor: 'pointer'
                    },
                    'data-codecept-selector-node': 'img',
                    'data-codecept-selector-file': 'MediaModalThumbnail'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'img'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'MediaModalThumbnail'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'img'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'MediaModalThumbnail'), _React$createElement2))
            ),
            _react2.default.createElement(_MediaModal2.default, (_React$createElement4 = {
                url: this.props.url,
                open: _lodash2.default.get(this, 'state.dialogOpen'),
                delegateClose: this._closeDialog,
                'data-codecept-selector-node': 'MediaModal',
                'data-codecept-selector-file': 'MediaModalThumbnail'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'MediaModal'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'MediaModalThumbnail'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'MediaModal'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'MediaModalThumbnail'), _React$createElement4))
        );
    }
});