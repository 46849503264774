'use strict';

var Faker = require('faker/lib/index');
//const Faker = require('./faker'); //# Faker class
//const faker = new Faker({ locales: require('faker/lib/locales') });
var faker = new Faker({ locales: {
        //https://github.com/Marak/faker.js/blob/master/lib/locales.js
        en: require('faker/lib/locales/en')
        //, en_US: require('faker/lib/locales/en_US')
    } });

if (window.RUBYAPP_DEBUG) {
    window.faker = faker;
}

//# NOTES:
//  with only en locale, ~300KB
//  with everything, ~1MB
module['exports'] = faker;