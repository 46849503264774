'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _ = require('lodash');
var constants = require('../common/constants');
var errorHelper = require('../common/errors');

var typesFromID = function typesFromID(id) {
    return {
        OPEN_NOTIFICATION: '@@ruby-app/' + id + '/error-feedback/OPEN_NOTIFICATION',
        CLOSE_NOTIFICATION: '@@ruby-app/' + id + '/error-feedback/CLOSE_NOTIFICATION',
        RESET_NOTIFICATION: '@@ruby-app/' + id + '/error-feedback/RESET_NOTIFICATION'
    };
};

var generators = {
    //# options.parent_id
    showNotification: function showNotification(options) {
        var selfModule = this;
        var TYPES = selfModule.getAction().TYPES;

        return {
            type: TYPES.OPEN_NOTIFICATION,
            payload: options
        };
    },
    showErrorNotification: function showErrorNotification(options) {
        var selfModule = this;
        var TYPES = selfModule.getAction().TYPES;

        options = options || {};

        var error = options.error;
        var defaultErrorOptions = errorHelper.getErrorNotificationOptions(error, options);

        console.error(error);
        return {
            type: TYPES.OPEN_NOTIFICATION,
            payload: _extends({}, defaultErrorOptions, options)
        };
    },
    closeNotification: function closeNotification() {
        var selfModule = this;
        var TYPES = selfModule.getAction().TYPES;

        return {
            type: TYPES.CLOSE_NOTIFICATION
        };
    },
    closeAndResetNotification: function closeAndResetNotification() {
        var generators = this.getAction().generators;

        return function (dispatch) {
            dispatch(generators.closeNotification());

            setTimeout(function () {
                dispatch(generators.resetNotification());
            }, 500);
        };
    },
    resetNotification: function resetNotification() {
        var selfModule = this;
        var TYPES = selfModule.getAction().TYPES;

        return {
            type: TYPES.RESET_NOTIFICATION
        };
    }

};

module.exports = function () {
    var TYPES = typesFromID(this.getID());
    return {
        TYPES: TYPES,
        generators: generators
    };
};