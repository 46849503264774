'use strict';

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var middleware = require('./middleware');

var dialogMixin = {
    mixinName: 'rubyComponentMixinDialog',
    middleware: middleware,
    action: require('./action'),
    reducer: require('./reducer')
};

module.exports = dialogMixin;