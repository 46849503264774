'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Card = require('material-ui/Card');

var _colors = require('@rubyapps/ruby-styles/src/client/styleObjects/colors');

var _reactChartjs = require('react-chartjs-2');

var _styleObject = require('./styleObject');

var _styleObject2 = _interopRequireDefault(_styleObject);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;
var moment = require('moment');

var WeeklyStats = _react2.default.createClass({
    displayName: 'WeeklyStats',

    propTypes: {
        label: PropTypes.string,
        id: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.shape({
            weekNumber: PropTypes.number,
            value: PropTypes.number
        }))
    },
    render: function render() {
        var _extends2, _React$createElement, _React$createElement2, _React$createElement3;

        var data = processData_forRecharts(this.props.data);
        return _react2.default.createElement(
            _Card.Card,
            (_React$createElement3 = {
                'data-codecept-selector-node': 'Card',
                'data-codecept-selector-file': 'WeeklyStats'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'WeeklyStats'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'Card'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'WeeklyStats'), _React$createElement3),
            _react2.default.createElement(_Card.CardHeader, _extends({
                title: this.props.label,
                subtitle: this.props.subtitle
            }, _styleObject2.default.CardHeader, (_extends2 = {
                'data-codecept-selector-node': 'CardHeader',
                'data-codecept-selector-file': 'WeeklyStats'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'WeeklyStats'), _defineProperty(_extends2, 'data-codecept-selector-node', 'CardHeader'), _defineProperty(_extends2, 'data-codecept-selector-file', 'WeeklyStats'), _extends2))),
            _react2.default.createElement(
                _Card.CardText,
                (_React$createElement2 = {
                    'data-codecept-selector-node': 'CardText',
                    'data-codecept-selector-file': 'WeeklyStats'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'WeeklyStats'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'CardText'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'WeeklyStats'), _React$createElement2),
                _react2.default.createElement(_reactChartjs.Scatter, (_React$createElement = {
                    height: 325,
                    data: !data ? {} : {
                        datasets: [{
                            data: data,
                            showLine: true,
                            fill: false,
                            lineTension: 0,
                            borderColor: _colors.categoryColors[0],
                            pointBackgroundColor: _colors.categoryColors[0]

                        }]
                    },
                    options: {
                        legend: {
                            display: false
                        },
                        tooltips: {
                            callbacks: {
                                title: function title(tooltipItems, data) {
                                    var tooltipItem = tooltipItems[0];
                                    var dataset = _.get(data, ['datasets', tooltipItem.datasetIndex]);
                                    var dataPoint = _.get(dataset, ['data', tooltipItem.index]);
                                    var weekNumber = dataPoint.meta.weekNumber;
                                    var weekStart = moment().week(weekNumber).startOf('week').format('MMM D');
                                    var weekEnd = moment().week(weekNumber).endOf('week').format('MMM D');
                                    return weekStart + ' - ' + weekEnd;
                                },
                                label: function label(tooltipItem, data) {
                                    var dataset = _.get(data, ['datasets', tooltipItem.datasetIndex]);
                                    var meta = _.get(dataset, ['data', tooltipItem.index, 'meta']);
                                    return tooltipItem.yLabel.toLocaleString();
                                }
                            }
                        },
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                type: 'time',
                                time: {
                                    unit: 'week',
                                    displayFormats: {
                                        week: 'MMM D'
                                    }
                                }
                            }],
                            yAxes: [{
                                ticks: {
                                    min: 0,
                                    callback: function callback(value, index, values) {
                                        return value.toLocaleString();
                                    }
                                }
                            }]
                        }
                    },
                    'data-codecept-selector-node': 'Scatter',
                    'data-codecept-selector-file': 'WeeklyStats'
                }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Scatter'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'WeeklyStats'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Scatter'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'WeeklyStats'), _React$createElement))
            )
        );
    }
});

function processData_forRecharts() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    return data.map(function (dataPoint) {
        return {
            x: moment().year(dataPoint.year).week(dataPoint.weekNumber).startOf('week'),
            y: dataPoint.value,
            meta: dataPoint
        };
    }).reduce(function (dataAcc, dataPoint) {
        if (dataAcc.length === 0) {
            return [dataPoint];
        }

        var prevDataPoint = _.last(dataAcc);
        var prevDate = _.get(prevDataPoint, 'x');
        var currDate = _.get(dataPoint, 'x');
        if (currDate.isSame(prevDate, 'day')) {
            dataAcc.pop();
            dataAcc.push({
                x: currDate,
                y: _.get(prevDataPoint, 'y') + _.get(dataPoint, 'y'),
                meta: dataPoint
            });
        } else {
            dataAcc.push(dataPoint);
        }

        return dataAcc;
    }, []);
}

exports.default = WeeklyStats;