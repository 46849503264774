'use strict';

function typesWithID(id) {
    return {};
}

var generators = {};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};