'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.expandRow = expandRow;

var _constants = require('./constants');

var types = _interopRequireWildcard(_constants);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

/*
 * @param {string} direction - (default: 'toggle') ['toggle', 'collapse', 'expand'] directions other than 'toggle' is typically used only for 'includeDescendants'
 * */
function expandRow(griddleKey) {
  var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'toggle';
  var includeDescendants = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;


  return {
    type: types.GRIDDLE_ROW_TOGGLED,
    griddleKey: griddleKey,
    direction: direction,
    includeDescendants: includeDescendants
  };
}