'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.local = exports.data = undefined;

var _dataState = require('./data-state');

var _dataState2 = _interopRequireDefault(_dataState);

var _localState = require('./local-state');

var _localState2 = _interopRequireDefault(_localState);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.data = _dataState2.default;
exports.local = _localState2.default;