"use strict";

function typesWithID(id) {
    return {
        SEED_CHILDREN: "@@ruby-app/" + id + "/SEED_CHILDREN",
        RETRIEVE_FORM: "@@ruby-app/" + id + "/RETRIEVE_FORM"
    };
}

function getQueryForSave_usingFieldsState(fieldsState) {
    return {
        templateName: fieldsSate.templateName
    };
}

var generators = {
    seedChildrenWithFormData: function seedChildrenWithFormData(formData) {
        var selfModule = this;

        var _selfModule$getDepend = selfModule.getDependencies(),
            selfAction = _selfModule$getDepend.selfAction;

        return {
            type: selfAction.TYPES.SEED_CHILDREN
        };
    }

    //# TODO:
    //# NOTE: this does not fire if tab is active through the url hash
    , selectSelf: function selectSelf() {
        var _getAction = this.getAction(),
            actions = _getAction.generators;

        var parentTabsComponent = this.getParent();

        var _parentTabsComponent$ = parentTabsComponent.getAction(),
            parentActions = _parentTabsComponent$.generators;

        var siblings = parentTabsComponent.getChildren();

        var selfIndex = _.indexOf(siblings, this);

        return function (dispatch, getState) {
            dispatch(parentActions.setSelectedIndex(selfIndex));
        };
    },
    onSelected: function onSelected() {
        var _getAction2 = this.getAction(),
            actions = _getAction2.generators;

        return actions.set_visible();
    }
};

module.exports = function () {
    return {
        TYPES: typesWithID(this.getID()),
        generators: generators
    };
};