'use strict';

var request = require('@rubyapps/ruby-superagent');


var typesForId = function typesForId(id) {
    return {
        UPDATE__LAST_UPDATED_TIMESTAMP: '@@ruby-app/' + id + '/UPDATE__LAST_UPDATED_TIMESTAMP'
    };
};

var generators = {
    update_lastUpdatedTimestamp: function update_lastUpdatedTimestamp() {
        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        return {
            type: TYPES.UPDATE__LAST_UPDATED_TIMESTAMP,
            payload: {
                lastUpdatedTimestamp: new Date()
            }
        };
    }
};

module.exports = function () {
    return {
        TYPES: typesForId(this.getID()),
        generators: generators
    };
};