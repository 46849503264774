'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactStickyEl = require('react-sticky-el');

var _reactStickyEl2 = _interopRequireDefault(_reactStickyEl);

var _Paper = require('material-ui/Paper');

var _Paper2 = _interopRequireDefault(_Paper);

var _Toolbar = require('material-ui/Toolbar');

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _history = require('material-ui/svg-icons/action/history');

var _history2 = _interopRequireDefault(_history);

var _HamburgerIcon = require('@rubyapps/ruby-react-components/src/client/element/HamburgerIcon');

var _HamburgerIcon2 = _interopRequireDefault(_HamburgerIcon);

var _UserStatusTool = require('./UserStatusTool');

var _UserStatusTool2 = _interopRequireDefault(_UserStatusTool);

var _MenuDrawer = require('./MenuDrawer');

var _MenuDrawer2 = _interopRequireDefault(_MenuDrawer);

var _Header = require('./Header.cssModule');

var _Header2 = _interopRequireDefault(_Header);

var _Header_style = require('./Header_style');

var _Header_style2 = _interopRequireDefault(_Header_style);

var _rubyLogo = require('@rubyapps/ruby-logo');

var _rubyLogo2 = _interopRequireDefault(_rubyLogo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

var PropTypes = _react2.default.PropTypes;

/*
import Container from 'muicss/lib/react/container';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
*/

var _require = require('@rubyapps/react-flexbox-grid'),
    Grid = _require.Grid,
    Row = _require.Row,
    Col = _require.Col;
//const Sticky = require('react-stickynode');


//# icons


//# assets


var menuDrawerWidth = 300;

var Header = _react2.default.createClass({
    displayName: 'Header',

    propTypes: {
        actions: PropTypes.object,
        activeRoute: PropTypes.string,
        delegateLogOut: _UserStatusTool2.default.propTypes.delegateLogOut,
        delegateRequestMenuOpenChange: PropTypes.func,
        delegateRouteChange: _MenuDrawer2.default.propTypes.delegateRouteChange,
        delegateUpdateAccount: _UserStatusTool2.default.propTypes.delegateUpdateAccount,
        frontendSettings: PropTypes.object,
        menuConfig: PropTypes.shape({
            children: PropTypes.array
        }),
        menuDrawerOpen: PropTypes.bool
        //open: PropTypes.bool //# not necessary for now,
        //# but might want it if we want to control the state of this component in the future
        , userInfo: _UserStatusTool2.default.propTypes.userInfo
    },

    getInitialState: function getInitialState() {
        return {
            notificationDrawerOpen: false,
            userPopoverOpen: false,
            stickyFixed: false
        };
    },
    _onHeaderLogoTap: function _onHeaderLogoTap() {
        var route = this.props.menuConfig.route || this.props.menuConfig.url;
        this.props.delegateRouteChange && this.props.delegateRouteChange(route, this.props.menuConfig);
    },
    _handleStickyStateChange: function _handleStickyStateChange(state) {
        if (state.status === _reactStickyEl2.default.STATUS_FIXED) {
            this.setState({ stickyFixed: true });
        } else {
            this.setState({ stickyFixed: false });
        }
    },
    render: function render() {
        var _React$createElement2,
            _this = this,
            _React$createElement3,
            _React$createElement4,
            _React$createElement5,
            _React$createElement6,
            _React$createElement7,
            _extends2,
            _React$createElement8,
            _React$createElement9,
            _React$createElement10,
            _React$createElement11,
            _React$createElement12,
            _React$createElement13,
            _React$createElement14,
            _React$createElement15,
            _extends3,
            _React$createElement16;

        var _props = this.props,
            activeRoute = _props.activeRoute,
            userInfo = _props.userInfo,
            delegateRouteChange = _props.delegateRouteChange,
            delegateActions = _props.delegateActions,
            delegateToggleMenuPin = _props.delegateToggleMenuPin,
            menuIsPinned = _props.menuIsPinned;


        if (activeRoute == "/" || activeRoute.indexOf('login') >= 0 || activeRoute.indexOf('forgot-password') >= 0) {
            var _React$createElement;

            return _react2.default.createElement('div', (_React$createElement = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Header'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Header'), _React$createElement));
        }

        var menuDrawerProps = {
            menuConfig: this.props.menuConfig.children ? this.props.menuConfig.children : [],
            activeRoute: activeRoute,
            open: this.props.menuDrawerOpen,
            userInfo: userInfo,
            delegateRequestMenuOpenChange: this.props.actions.requestMenuOpenChange,
            delegateRouteChange: delegateRouteChange,
            delegateToggleMenuPin: delegateToggleMenuPin,
            menuIsPinned: menuIsPinned,
            frontendSettings: this.props.frontendSettings,
            width: menuDrawerWidth
        };

        var userStatusToolProps = _.pick(this.props, Object.keys(_UserStatusTool2.default.propTypes));

        var zDepth = this.state.stickyFixed ? 2 : 1;

        var isTemporaryUser = _.get(userInfo, 'isTemporaryUser', false);

        /*
                                <Grid>
                                    <Row>
                                        <Col xs={12}>
                                        </Col>
                                    </Row>
                                </Grid>
        */
        //# NOTE: the empty sticky is so we can support autoscrolling when dragging items in the sitemap
        return _react2.default.createElement(
            'div',
            (_React$createElement16 = { className: _Header2.default.header, id: this.props.id, style: _Header_style2.default.Header, 'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Header'
            }, _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'Header'), _React$createElement16),
            _react2.default.createElement('div', (_React$createElement2 = {
                style: {
                    pointerEvents: 'none',
                    width: '100%',
                    height: 2,
                    backgroundColor: '#e8e8e8',
                    position: 'fixed',
                    top: 0,
                    zIndex: 1
                },
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Header'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Header'), _React$createElement2)),
            _react2.default.createElement(
                _reactStickyEl2.default,
                (_React$createElement15 = { topOffset: -9, style: { marginTop: 9 }, 'data-codecept-selector-node': 'Sticky',
                    'data-codecept-selector-file': 'Header'
                }, _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'Sticky'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'Sticky'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'Header'), _React$createElement15),
                _react2.default.createElement(
                    'div',
                    (_React$createElement14 = { style: { overflow: 'visible' }, 'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'Header'
                    }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'Header'), _React$createElement14),
                    _react2.default.createElement(
                        'div',
                        (_React$createElement13 = { style: { position: 'relative', top: -7 }, 'data-codecept-selector-node': 'div',
                            'data-codecept-selector-file': 'Header'
                        }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'Header'), _React$createElement13),
                        _react2.default.createElement(
                            _Paper2.default,
                            (_React$createElement12 = { zDepth: zDepth, style: _Header_style2.default.Paper, className: _Header2.default.Paper, 'data-codecept-selector-node': 'Paper',
                                'data-codecept-selector-file': 'Header'
                            }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'Paper'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'Paper'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'Header'), _React$createElement12),
                            _react2.default.createElement(
                                _Toolbar.Toolbar,
                                (_React$createElement11 = {
                                    noGutter: true,
                                    style: _Header_style2.default.Toolbar,
                                    className: _Header2.default.Toolbar,
                                    'data-codecept-selector-node': 'Toolbar',
                                    'data-codecept-selector-file': 'Header'
                                }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'Toolbar'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'Toolbar'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'Header'), _React$createElement11),
                                _react2.default.createElement(
                                    _Toolbar.ToolbarGroup,
                                    (_React$createElement7 = {
                                        style: {
                                            justifyContent: 'flex-start'
                                        },
                                        'data-codecept-selector-node': 'ToolbarGroup',
                                        'data-codecept-selector-file': 'Header'
                                    }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Header'), _React$createElement7),
                                    _react2.default.createElement(
                                        _IconButton2.default,
                                        (_React$createElement4 = {
                                            tooltip: 'Menu',
                                            tooltipPosition: 'bottom-right',
                                            tooltipStyles: {
                                                top: 26
                                            },
                                            disabled: isTemporaryUser,
                                            onTouchTap: function onTouchTap() {
                                                _this.props.actions.requestMenuOpenChange(true);
                                            },
                                            style: _Header_style2.default.MenuButton,
                                            'data-codecept-selector-node': 'IconButton',
                                            'data-codecept-selector-file': 'Header'
                                        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Header'), _React$createElement4),
                                        _react2.default.createElement(_HamburgerIcon2.default, (_React$createElement3 = {
                                            isActive: this.state.menuDrawerOpen,
                                            className: _Header2.default.HamburgerIcon,
                                            'data-codecept-selector-node': 'HamburgerIcon',
                                            'data-codecept-selector-file': 'Header'
                                        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'HamburgerIcon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'HamburgerIcon'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Header'), _React$createElement3))
                                    ),
                                    _react2.default.createElement(
                                        'div',
                                        (_React$createElement6 = {
                                            style: {
                                                height: '100%'
                                            },
                                            'data-codecept-selector-node': 'div',
                                            'data-codecept-selector-file': 'Header'
                                        }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Header'), _React$createElement6),
                                        _react2.default.createElement(_rubyLogo2.default, (_React$createElement5 = {
                                            frontendSettings: this.props.frontendSettings,
                                            onClick: function onClick(event) {
                                                if (!isTemporaryUser) {
                                                    _this._onHeaderLogoTap();
                                                }
                                                event.preventDefault();
                                                event.stopPropagation();
                                                return false;
                                            },
                                            'data-codecept-selector-node': 'RubyLogo',
                                            'data-codecept-selector-file': 'Header'
                                        }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'RubyLogo'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'RubyLogo'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Header'), _React$createElement5))
                                    )
                                ),
                                _react2.default.createElement(
                                    _Toolbar.ToolbarGroup,
                                    (_React$createElement10 = { style: _Header_style2.default.ToolbarGroup, 'data-codecept-selector-node': 'ToolbarGroup',
                                        'data-codecept-selector-file': 'Header'
                                    }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'Header'), _React$createElement10),
                                    _react2.default.createElement(_UserStatusTool2.default, _extends({}, userStatusToolProps, (_extends2 = {
                                        'data-codecept-selector-node': 'UserStatusTool',
                                        'data-codecept-selector-file': 'Header'
                                    }, _defineProperty(_extends2, 'data-codecept-selector-node', 'UserStatusTool'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Header'), _defineProperty(_extends2, 'data-codecept-selector-node', 'UserStatusTool'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Header'), _extends2))),
                                    _react2.default.createElement(
                                        _IconButton2.default,
                                        (_React$createElement9 = {
                                            tooltip: 'Recent Updates',
                                            tooltipPosition: 'bottom-left',
                                            tooltipStyles: {
                                                top: 26
                                            },
                                            disabled: isTemporaryUser,
                                            onTouchTap: delegateActions.recentUpdates.openDisplay,
                                            'data-codecept-selector-node': 'IconButton',
                                            'data-codecept-selector-file': 'Header'
                                        }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Header'), _React$createElement9),
                                        _react2.default.createElement(_history2.default, (_React$createElement8 = {
                                            'data-codecept-selector-node': 'HistoryIcon',
                                            'data-codecept-selector-file': 'Header'
                                        }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'HistoryIcon'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'HistoryIcon'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Header'), _React$createElement8))
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            _react2.default.createElement(_MenuDrawer2.default, _extends({}, menuDrawerProps, (_extends3 = {
                'data-codecept-selector-node': 'MenuDrawer',
                'data-codecept-selector-file': 'Header'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'MenuDrawer'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Header'), _defineProperty(_extends3, 'data-codecept-selector-node', 'MenuDrawer'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Header'), _extends3)))
        );
    }
    /* //# set this up if we want to auto-hide pinned menus if the width is too narrow
    , componentDidMount: function() {
        this.mounted = true;
         window.addEventListener('resize', this._onWindowResize);
        this._onWindowResize();
    }
     , componentWillUnmount: function() {
        this.mounted = false;
        window.removeEventListener('resize', this._onWindowResize);
    }
     , _onWindowResize: function(event) {
    }
    */
});
exports.default = Header;