'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    handle: {
        margin: '10px 10px',
        cursor: 'move'
    },
    locked: {
        margin: '10px 10px',
        cursor: 'not-allowed'
    },
    title: {
        cursor: 'pointer'
    }

};