'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _ComponentizedDatePickerConnector = require('./reactComponents/ComponentizedDatePickerConnector');

var _ComponentizedDatePickerConnector2 = _interopRequireDefault(_ComponentizedDatePickerConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var React = require('react');
var moment = require('@rubyapps/friendly-ruby-moment');

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;

var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');

var RCDatePicker = RubyComponent.createClass({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin],
    propTypes: {
        styles: PropTypes.shape({
            DatePickerWrapper: PropTypes.object,
            DatePicker: PropTypes.object
        }),
        fullWidth: PropTypes.bool
    },
    componentName: componentName,
    dependencies: function dependencies() {
        var root = this.getRoot();
        var selfSelector = this.getDefaultSelector();

        return {
            selfSelector: selfSelector
        };
    },
    getDefaultProps: function getDefaultProps() {
        return {
            toHTML: '${data.formattedDateString? data.formattedDateString :"Year: "+(data.year||"")+", Month: "+ ((typeof data.month == "number")? modules.moment().month(data.month).format("MMM") :"") +", Day: "+ (data.day||"")}'
        };
    },
    getReactClass: function getReactClass() {
        return _ComponentizedDatePickerConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var DatePickerComponent = this.getReactClass();

        return React.createElement(DatePickerComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'DatePickerComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'DatePickerComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'DatePickerComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    },
    CONSTANTS: CONSTANTS

    //# Mixin Overrides

    , fieldValueFromFormValue_forKey: function fieldValueFromFormValue_forKey(formValue, key) {
        var _ref = formValue || {},
            year = _ref.year,
            month = _ref.month,
            day = _ref.day,
            date = _ref.date;

        //# NOTE: date might be provided, but if year, month, day are not available
        //# that means the date was erroneously included


        return {
            year: year,
            month: month,
            day: day
        };
    },
    formValueFromFieldValue_forKey: function formValueFromFieldValue_forKey(fieldValue, key) {
        var _ref2 = fieldValue || {},
            year = _ref2.year,
            month = _ref2.month,
            day = _ref2.day;

        return {
            year: year ? Number(year) : year,
            month: month ? Number(month) : month,
            day: day ? Number(day) : day
        };
    }
});

module.exports = RCDatePicker;