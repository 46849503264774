'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

module.exports = function () {
    var id = this.getID();

    var TYPES = {
        OPEN_DIALOG: '@@ruby-app/' + id + '/OPEN_DIALOG',
        CLOSE_DIALOG: '@@ruby-app/' + id + '/CLOSE_DIALOG',
        SET_OPTIONS: '@ruby-app/' + id + '/SET_OPTIONS',

        RESET_DIALOG: '@@ruby-app/' + id + '/RESET_DIALOG'
    };

    return {
        TYPES: TYPES,
        generators: {
            openDialog: function openDialog(options) {
                var selfModule = this;

                var _selfModule$getDepend = selfModule.getDependencies(),
                    formDialogComponent = _selfModule$getDepend.formDialogComponent;

                var dispatch = selfModule.getStore().dispatch;

                var formJs = selfModule.getAugmentedDialogTemplate().form;

                var formDialogActions = formDialogComponent.getAction().generators;

                return formDialogActions.open(_extends({
                    title: 'Content Page Picker',
                    formJs: formJs,
                    dialogActions: [{
                        label: 'Cancel',
                        primary: false,
                        onTouchTap: function onTouchTap() {
                            dispatch(formDialogActions.closeAndReset());
                        }
                    }, {
                        label: 'Add Link',
                        primary: true,
                        onTouchTap: function onTouchTap(evt) {
                            dispatch(formDialogActions.validateBeforeSubmitting());
                            return;
                        }
                    }],
                    onSubmit: function onSubmit(formData, successCB, errorCB) {
                        var generators = selfModule.getAction().generators;

                        //# is overwritten by the tinymce plugin
                        debugger;
                    }
                }, options));
            },

            closeDialog: function closeDialog(options) {
                var _getDependencies = this.getDependencies(),
                    formDialogComponent = _getDependencies.formDialogComponent;

                var formDialogActions = formDialogComponent.getAction().generators;

                return formDialogActions.closeAndReset();
            }
            /**
             *
             *  options: {
             *      title: the title of the dialog
             *      defaultFormData: {
             *          //# key-value pair as if it's form data
             *      }
             *  }
             *
             **/
            //# options.parent_id
            , open: function open(options) {
                var _getAction = this.getAction(),
                    TYPES = _getAction.TYPES,
                    generators = _getAction.generators;

                var openAction = {
                    type: TYPES.OPEN_DIALOG
                };

                if (options) {
                    return function (dispatch) {
                        dispatch(generators.setOptions(options));
                        dispatch(openAction);
                    };
                } else {
                    return openAction;
                }
            },
            close: function close() {
                var _getAction2 = this.getAction(),
                    TYPES = _getAction2.TYPES;

                return {
                    type: TYPES.CLOSE_DIALOG
                };
            },
            closeAndReset: function closeAndReset() {
                var generators = this.getAction().generators;
                return function (dispatch) {
                    dispatch(generators.close());
                    setTimeout(function () {
                        dispatch(generators.reset());
                    }, 500);
                };
            },

            setOptions: function setOptions(options) {
                var _getAction3 = this.getAction(),
                    TYPES = _getAction3.TYPES,
                    generators = _getAction3.generators;

                var selfModule = this;

                return {
                    type: TYPES.SET_OPTIONS,
                    payload: options
                };
            },

            reset: function reset() {
                var selfModule = this;
                var generators = selfModule.getAction().generators;

                var _selfModule$getAction = selfModule.getAction(),
                    TYPES = _selfModule$getAction.TYPES;

                return { type: TYPES.RESET_DIALOG };
            }

        }
    };
};