'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.makeRerankRequest = makeRerankRequest;
exports.onBeginDrag = onBeginDrag;
exports.expandRow_withIdxPath = expandRow_withIdxPath;
exports.collapseRow_withIdxPath = collapseRow_withIdxPath;
exports.moveRow_withIdToFirstChildOfRow_withId = moveRow_withIdToFirstChildOfRow_withId;
exports.moveRow_withId_toIdxPath = moveRow_withId_toIdxPath;
exports.moveRow_fromIdxPath_toIdxPath = moveRow_fromIdxPath_toIdxPath;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _constants = require('./constants');

var types = _interopRequireWildcard(_constants);

var _storeUtil = require('./util/store-util');

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function makeRerankRequest(store, movedRow, events, monitoredItem) {
    var movedRowId = movedRow.rowData.id;
    var sitemapData = store.getState().get('data').toJS();

    var originalIndexPath = monitoredItem.originalIndexPath;

    //# NOTE: movedRow.rowData is stateful and the indexPath isn't guaranteed to have been updated by the time makeRerankRequest gets called

    var newIndexPath = monitoredItem.indexPath || movedRow.rowData.__metadata.indexPath;
    var movedRowNewIdx = newIndexPath.slice(-1)[0];

    var parentPath = newIndexPath.slice(0, -1);
    var pathToSiblingsInSitemapData = parentPath.reduce(function (res, curr) {
        return res.concat([curr, 'children']);
    }, []);

    var siblings = _lodash2.default.get(sitemapData, pathToSiblingsInSitemapData, sitemapData);

    var keyPath = _lodash2.default.flatMap(parentPath.map(function (parentPathComponent, idx) {
        return idx === 0 ? parentPathComponent : ['children', parentPathComponent];
    }));

    var parentObject = _lodash2.default.get(sitemapData, keyPath);

    //# NOTE: movedRow.rowData is stateful and the parent_id isn't guaranteed to have been updated by the time makeRerankRequest gets called
    //# instead, we should look at newIndexPath and then select the row based on newIndexPath.slice(0, -1);
    var parentId = parentObject ? parentObject.id : null;

    var precedingRowId = _lodash2.default.get(siblings, [movedRowNewIdx - 1, 'id']);
    var followingRowId = _lodash2.default.get(siblings, [movedRowNewIdx + 1, 'id']);

    return function (dispatch) {
        (0, _storeUtil.getDndProvider)(store).onDrop(store, movedRowId, precedingRowId, followingRowId, parentId, events);
    };
}

function onBeginDrag(store) {
    return function (dispatch) {
        (0, _storeUtil.getDndProvider)(store).onBeginDrag();
    };
}

function expandRow_withIdxPath(idxPath) {
    return {
        type: types.GRIDDLE_EXPAND_ROW,
        indexPath: idxPath
    };
}

function collapseRow_withIdxPath(idxPath) {
    return {
        type: types.GRIDDLE_COLLAPSE_ROW,
        indexPath: idxPath
    };
}

function moveRow_withIdToFirstChildOfRow_withId(moveId, id) {
    return {
        type: types.GRIDDLE_MOVE_ROW_WITH_ID_TO_CHILD_OF_ROW_WITH_ID,
        moveId: moveId,
        targetId: id
    };
}

function moveRow_withId_toIdxPath(id, toIdxPath) {
    return {
        type: types.GRIDDLE_MOVE_ROW_WITH_ID,
        id: id,
        toIdxPath: toIdxPath
    };
}

function moveRow_fromIdxPath_toIdxPath(fromIdxPath, toIdxPath) {
    return {
        type: types.GRIDDLE_MOVE_ROW,
        fromIdxPath: fromIdxPath,
        toIdxPath: toIdxPath
    };
}