'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Dropdown = require('./Dropdown');

var _Dropdown2 = _interopRequireDefault(_Dropdown);

var _DropdownInfoMode = require('./DropdownInfoMode');

var _DropdownInfoMode2 = _interopRequireDefault(_DropdownInfoMode);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var DropdownWrapper = _react2.default.createClass({
    displayName: 'DropdownWrapper',

    propTypes: _extends({}, _Dropdown2.default.propTypes, _DropdownInfoMode2.default.propTypes),
    render: function render() {
        var _extends3;

        if (this.props.mode == 'info') {
            var _extends2;

            return _react2.default.createElement(_DropdownInfoMode2.default, _extends({}, this.props, (_extends2 = {
                'data-codecept-selector-node': 'DropdownInfoMode',
                'data-codecept-selector-file': 'DropdownWrapper'
            }, _defineProperty(_extends2, 'data-codecept-selector-node', 'DropdownInfoMode'), _defineProperty(_extends2, 'data-codecept-selector-file', 'DropdownWrapper'), _defineProperty(_extends2, 'data-codecept-selector-node', 'DropdownInfoMode'), _defineProperty(_extends2, 'data-codecept-selector-file', 'DropdownWrapper'), _extends2)));
        }

        return _react2.default.createElement(_Dropdown2.default, _extends({}, this.props, (_extends3 = {
            'data-codecept-selector-node': 'Dropdown',
            'data-codecept-selector-file': 'DropdownWrapper'
        }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Dropdown'), _defineProperty(_extends3, 'data-codecept-selector-file', 'DropdownWrapper'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Dropdown'), _defineProperty(_extends3, 'data-codecept-selector-file', 'DropdownWrapper'), _extends3)));
    }
});

exports.default = DropdownWrapper;