'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _reactRedux = require('react-redux');

var _ProfileIdentity = require('./ProfileIdentity');

var _ProfileIdentity2 = _interopRequireDefault(_ProfileIdentity);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector;

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);

        var formValue = selfModule.formValueFromLocalState(selfState);

        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);

        var mappedProps = _extends({}, ownProps, mixinFieldProps, {
            id: selfModule.getID(),
            value: formValue
        });

        return mappedProps;
    }

    return (0, _reactRedux.connect)(mapStateToProps)(_ProfileIdentity2.default);
}