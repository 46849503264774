'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _require = require('./fieldProperties'),
    base_fieldInfo_byComponentName = _require.base_fieldInfo_byComponentName,
    propertySpecsByKey_withRubyPermissions = _require.propertySpecsByKey_withRubyPermissions;

//# field Props stuff that requires RubyApp context goes here
//# eg. moving 'fieldInfo_byComponentName' to here


module.exports = {
    fieldInfo_byComponentName: function fieldInfo_byComponentName() {
        var cached = this.getStatefulCacheForKey('fieldInfo_byComponentName');
        if (cached) {
            return cached;
        }

        var Components = require('@rubyapps/ruby-component-builder/src/common/components').default;

        var fieldInfo_byComponentName = _extends({}, base_fieldInfo_byComponentName, _.reduce(Components, function (collector, component, componentKey) {
            var fieldInfo = _.get(component, ['staticPropsByComponent', 'ruby-component-field-editor', 'fieldInfo']);
            if (fieldInfo) {
                collector[componentKey] = fieldInfo;
            }

            return collector;
        }, {}));

        this.setStatefulCacheForKey('fieldInfo_byComponentName', fieldInfo_byComponentName);

        return fieldInfo_byComponentName;
    },
    fieldTypeDisplayText_byType: function fieldTypeDisplayText_byType() {
        var cached = this.getStatefulCacheForKey('fieldTypeDisplayText_byType');
        if (cached) {
            return cached;
        }

        var fieldInfo_byComponentName = this.fieldInfo_byComponentName();

        var fieldTypeDisplayText_byType = _.reduce(fieldInfo_byComponentName, function (result, typeArray, componentName) {
            result[componentName] = fieldInfo_byComponentName[componentName].displayText;
            return result;
        }, {});

        this.setStatefulCacheForKey('fieldTypeDisplayText_byType', fieldTypeDisplayText_byType);

        return fieldTypeDisplayText_byType;
    },

    propertiesWithoutPermissions_byType: function propertiesWithoutPermissions_byType() {
        var cached = this.getStatefulCacheForKey('propertiesWithoutPermissions_byType');
        if (cached) {
            return cached;
        }
        var propertiesWithoutPermissions_byType = this.propertiesByType_withRubyPermissions([]);

        this.setStatefulCacheForKey('propertiesWithoutPermissions_byType', propertiesWithoutPermissions_byType);

        return propertiesWithoutPermissions_byType;
    },

    propertiesWithoutPermissions_byKey: function propertiesWithoutPermissions_byKey() {
        var cached = this.getStatefulCacheForKey('propertiesWithoutPermissions_byKey');
        if (cached) {
            return cached;
        }
        var propertiesWithoutPermissions_byKey = propertySpecsByKey_withRubyPermissions([]);

        this.setStatefulCacheForKey('propertiesWithoutPermissions_byKey', propertiesWithoutPermissions_byKey);

        return propertiesWithoutPermissions_byKey;
    },

    propertiesByKey_withRubyPermissions: function propertiesByKey_withRubyPermissions(ruby_permissions) {
        return propertySpecsByKey_withRubyPermissions(ruby_permissions);
    },

    propertiesByType_withRubyPermissions: function propertiesByType_withRubyPermissions(ruby_permissions) {
        var properties_byKey = propertySpecsByKey_withRubyPermissions(ruby_permissions);

        var fieldInfo_byComponentName = this.fieldInfo_byComponentName();
        return _.reduce(fieldInfo_byComponentName, function (properties_byType, typeArray, componentName) {
            properties_byType[componentName] = (fieldInfo_byComponentName[componentName].propertyKeys || []).map(function (propertyKey) {
                return properties_byKey[propertyKey];
            });
            return properties_byType;
        }, {});
    },

    coreProperties_byInstanceType: function coreProperties_byInstanceType() {
        var cached = this.getStatefulCacheForKey('coreProperties_byInstanceType');
        if (cached) {
            return cached;
        }

        var propertiesWithoutPermissions_byKey = this.propertiesWithoutPermissions_byKey();

        var coreProperties_byInstanceType = {
            'Tab': {
                label: propertiesWithoutPermissions_byKey.label,
                field_meta_locked: propertiesWithoutPermissions_byKey.field_meta_locked
            },
            'Fieldset': {
                label: propertiesWithoutPermissions_byKey.label,
                help_text: propertiesWithoutPermissions_byKey.help_text,
                field_meta_locked: propertiesWithoutPermissions_byKey.field_meta_locked
            },
            'Field': {
                label: propertiesWithoutPermissions_byKey.label,
                constraint_required: propertiesWithoutPermissions_byKey.constraint_required,
                field_meta_locked: propertiesWithoutPermissions_byKey.field_meta_locked,
                componentName: propertiesWithoutPermissions_byKey.componentName
            },
            'SubTemplate': {
                subtemplate: propertiesWithoutPermissions_byKey.subtemplate,
                field_meta_locked: propertiesWithoutPermissions_byKey.field_meta_locked
            }
        };

        this.setStatefulCacheForKey('coreProperties_byInstanceType', coreProperties_byInstanceType);
        return coreProperties_byInstanceType;
    }
};