'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getSubsiteOptions_fromSettings = exports.getRubyClientInfo_fromSettings_forRoute = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _memoizee = require('memoizee');

var _memoizee2 = _interopRequireDefault(_memoizee);

var _rubyMemoize = require('../../../ruby-memoize');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Cache the permission check when given the same permission/user reference
var getRubyClientInfo_fromSettings_forRoute = exports.getRubyClientInfo_fromSettings_forRoute = (0, _memoizee2.default)(raw_getRubyClientInfo_fromSettings_forRoute, _extends({}, _rubyMemoize.defaultMemoizeeOptions, { length: 2 }));
var getSubsiteOptions_fromSettings = exports.getSubsiteOptions_fromSettings = (0, _memoizee2.default)(raw_getSubsiteOptions_fromSettings, _extends({}, _rubyMemoize.defaultMemoizeeOptions, { length: 1 }));

//# Walks this tree:
/*

    rubyClients_routeTree = {
        //# top-level are defaults
        "subsite":1
        , "subsiteUrlIdentifier": "en"
        , "client": 3

        "children": {
            "app": {
                "children": {
                    "content": {
                        "children": {
                            "cn": {
                                "subsite": 3
                                "subsiteUrlIdentifier": "cn"
                            }
                        }
                    }
                    , "proposal": {
                        "subsite": 2
                    }
                }
            }
         }
    }


   
   currentNode = {
        "app": {
            "subsite": 1
            , "client": 3
            , "children": {
                "content": {
                    "subsite": 1
                    "children": {
                        "cn": {
                            "subsite": 2
                        }
                    }
                }
                , "proposal": {
                    "subsite": 2
                }
            }
        }
    }

    currentKeypathArray = ['app', 'proposal'];
*/

function _rubyClientInfoReducer(collector, currentNode, currentKeypathArray) {
    if (currentNode == undefined) {
        return collector;
    }

    var currentKey = currentKeypathArray[0];
    var childKeypathArray = currentKeypathArray.slice(1);

    var nodeForKey = currentNode[currentKey];
    if (nodeForKey) {
        collector = _extends({}, collector, _lodash2.default.omit(nodeForKey, ['children']));

        //# recursively call
        if (childKeypathArray.length && nodeForKey.hasOwnProperty('children')) {
            collector = _rubyClientInfoReducer(collector, nodeForKey.children, childKeypathArray);
        }
    }

    return collector;
}

//# returns {subsite, client}
function raw_getRubyClientInfo_fromSettings_forRoute(settings, route) {
    var _settings$rubyClients = settings.rubyClients_routeTree,
        rubyClients_routeTree = _settings$rubyClients === undefined ? {} : _settings$rubyClients;


    var routeArray = route.split('/').filter(function (value) {
        return !!value;
    });

    var rubyClientInfo = _rubyClientInfoReducer(_extends({}, _lodash2.default.omit(rubyClients_routeTree, ['children'])), rubyClients_routeTree.children, routeArray);

    return rubyClientInfo;
}

function getSubsiteOptions_fromNode(node) {
    var thisOption = _extends({}, _lodash2.default.pick(node, ['subsite', 'subsiteLabel']));
    var childOptions = node.children ? _lodash2.default.map(node.children, function (childNode) {
        return getSubsiteOptions_fromNode(childNode);
    }) : [];
    return _lodash2.default.flatMap([].concat(thisOption, childOptions)).filter(function (el) {
        return !_lodash2.default.isEmpty(el);
    });
}

//# NOTE: some subsite values repeat (eg. Websites and Content share the same subsite value)
function raw_getSubsiteOptions_fromSettings(settings) {
    var _settings$rubyClients2 = settings.rubyClients_routeTree,
        rubyClients_routeTree = _settings$rubyClients2 === undefined ? {} : _settings$rubyClients2;


    var rubySubsiteOptions = getSubsiteOptions_fromNode(rubyClients_routeTree);
    var rubySubsiteOptionsBySubsite = rubySubsiteOptions.reduce(function (collector, subsiteSpec) {
        var subsite = subsiteSpec.subsite,
            subsiteLabel = subsiteSpec.subsiteLabel;

        if (!collector[subsite]) {
            collector[subsite] = { subsiteLabel: [] };
        }

        collector[subsite].subsiteLabel = collector[subsite].subsiteLabel.concat(subsiteLabel);

        return collector;
    }, {});

    var mergedRubySubsiteOptions = _lodash2.default.map(rubySubsiteOptionsBySubsite, function (value, key) {
        return {
            subsiteLabel: value.subsiteLabel.join(' / '),
            subsite: key
        };
    });

    return mergedRubySubsiteOptions;
}