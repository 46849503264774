'use strict';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require('react-router');

var _SecureLoginConnector = require('./reactComponents/SecureLoginConnector');

var _SecureLoginConnector2 = _interopRequireDefault(_SecureLoginConnector);

var _constants = require('../common/constants');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var componentName = _constants2.default.COMPONENT_NAME;

var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var action = require('./action');
var reducer = require('./reducer');
var middleware = require('./middleware');

var RubyComponentFESettings = require('@rubyapps/ruby-component-frontend-settings');
var RubyComponentCurrentUser = require('@rubyapps/ruby-component-current-user');

var RCSecureLoginPage = RubyComponent.createClass({
    propTypes: {
        nextPage: PropTypes.string
    },
    getDefaultProps: function getDefaultProps() {
        return {
            nextPage: '/',
            clientName: 'Ruby',
            path: '/app/secure-login'
        };
    },
    componentName: componentName,
    CONSTANTS: _constants2.default,
    action: action,
    reducer: reducer,
    middleware: middleware,
    dependencies: function dependencies() {
        var selfModule = this;
        var selfSelector = selfModule.getSelfStateSelector();
        var selfActions = selfModule.getAction().generators;
        var rootModule = selfModule.getRoot();

        var feSettingsID = this.props.feSettingsID || RubyComponentFESettings.componentName;
        var feSettingsComponent = rootModule.findDescendentByID(feSettingsID);
        var feSettingsSelector = feSettingsComponent.getDefaultSelector();

        var rubyClientFKSelector = rootModule.findDescendentByID('rubyComponentRubyClientFK').getDefaultSelector();

        var currentUserID = this.props.currentUserID || RubyComponentCurrentUser.componentName;
        var currentUser = rootModule.findDescendentByID(currentUserID);
        var currentUserActions = currentUser.getAction().generators;
        var currentUserSelector = currentUser.getSelfStateSelector();

        var alertDialogID = this.props.alertDialogID || 'rubyComponentAlertDialog';
        var alertDialog = rootModule.findDescendentByID(alertDialogID);

        return {
            selfSelector: selfSelector,
            selfActions: selfActions,
            feSettingsSelector: feSettingsSelector,
            rubyClientFKSelector: rubyClientFKSelector,
            currentUserActions: currentUserActions,
            currentUserSelector: currentUserSelector,
            alertDialog: alertDialog
        };
    },
    getReactClass: function getReactClass() {
        var SecureLoginComponent = _SecureLoginConnector2.default.apply(this);
        return SecureLoginComponent;
    },
    getRouteElement: function getRouteElement() {
        var _React$createElement;

        var SecureLogin = this.getReactClass();

        return _react2.default.createElement(_reactRouter.Route, (_React$createElement = { name: '', component: SecureLogin, path: this.props.path, key: componentName, 'data-codecept-selector-node': 'Route',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Route'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Route'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'index'), _React$createElement));
    },
    statesSelector: function statesSelector(state) {
        var _getDependencies = this.getDependencies(),
            selfSelector = _getDependencies.selfSelector,
            feSettingsSelector = _getDependencies.feSettingsSelector,
            rubyClientFKSelector = _getDependencies.rubyClientFKSelector,
            currentUserSelector = _getDependencies.currentUserSelector;

        return {
            selfState: selfSelector(state),
            feSettingsState: feSettingsSelector(state),
            rubyClientFKState: rubyClientFKSelector(state),
            currentUserState: currentUserSelector(state)
        };
    }

});

module.exports = RCSecureLoginPage;