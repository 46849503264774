'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.TimePicker = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TimePicker = require('@rubyapps/ruby-material-ui/TimePicker');

var _TimePicker2 = _interopRequireDefault(_TimePicker);

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _IconButton = require('material-ui/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _clear = require('material-ui/svg-icons/content/clear');

var _clear2 = _interopRequireDefault(_clear);

var _addInfoModeProps = require('@rubyapps/ruby-react-components/src/client/hoc/addInfoModeProps');

var _addInfoModeProps2 = _interopRequireDefault(_addInfoModeProps);

var _TimePicker_style = require('./TimePicker_style');

var _TimePicker_style2 = _interopRequireDefault(_TimePicker_style);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label,
    RichErrorMessages = _require.RichErrorMessages;

var rubyTimeZoneList = require('@rubyapps/ruby-timezone-list');

var augmentedTimeZones = rubyTimeZoneList.getTimeZones();

function compareTimeZone(a, b) {

    // - comes after + alphabethically so handle this case to show - times first
    if (a.offset > b.offset) {
        return -1;
    } else if (a.offset < b.offset) {
        return +1;
    }

    if (a.primaryText < b.primaryText) {
        return -1;
    } else if (a.primaryText > b.primaryText) {
        return 1;
    } else {
        return 0;
    }
}

var sortedCustomTimeZoneList = augmentedTimeZones.sort(compareTimeZone);

function isMomentValidator(props, propName, componentName) {
    componentName = componentName || 'ANONYMOUS';

    if (props[propName]) {
        var value = props[propName];
        if (!_momentTimezone2.default.isMoment(value)) {
            return new Error(propName + ' in ' + componentName + ' should be a moment instance');
        }
    }
    return null;
}

var RawFieldTimePicker = _react2.default.createClass({
    displayName: 'RawFieldTimePicker',

    propTypes: {
        label: PropTypes.string,
        labelProps: PropTypes.object,
        value: PropTypes.shape({
            zoneName: PropTypes.string,
            timeMoment: isMomentValidator
        }),
        clearValue: PropTypes.func,
        onValueChange: PropTypes.func,
        disabled: PropTypes.bool,
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        richErrorMessages: PropTypes.arrayOf(PropTypes.shape({
            level: PropTypes.oneOf(['error', 'warning', 'info']),
            msg: PropTypes.string
        })),
        id: PropTypes.string,
        styles: PropTypes.shape({
            TimePickerWrapper: PropTypes.object,
            TimePicker: PropTypes.object
        })
    },
    render: function render() {
        var _React$createElement,
            _extends2,
            _this = this,
            _extends3,
            _React$createElement2,
            _React$createElement3,
            _React$createElement4,
            _React$createElement5,
            _extends4;

        var labelProps = _lodash2.default.pick(this.props, Object.keys(Label.propTypes));
        var errorTextElement = !_lodash2.default.isEmpty(this.props.richErrorMessages) ? _react2.default.createElement(RichErrorMessages, (_React$createElement = { messages: this.props.richErrorMessages, 'data-codecept-selector-node': 'RichErrorMessages',
            'data-codecept-selector-file': 'TimePicker'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TimePicker'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'TimePicker'), _React$createElement)) : null;
        var fieldValueTimeMoment = _lodash2.default.get(this, 'props.value.timeMoment');
        var dateValueIsValid = _momentTimezone2.default.isMoment(fieldValueTimeMoment);

        var isDisabled = this.props.disabled;

        var timePickerComponent = _react2.default.createElement(
            'div',
            _extends({
                style: {
                    width: '100%',
                    position: 'relative'
                }
            }, _lodash2.default.get(this.props, 'styles.TimePickerWrapper', {}), (_extends4 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'TimePicker'
            }, _defineProperty(_extends4, 'data-codecept-selector-node', 'div'), _defineProperty(_extends4, 'data-codecept-selector-file', 'TimePicker'), _defineProperty(_extends4, 'data-codecept-selector-node', 'div'), _defineProperty(_extends4, 'data-codecept-selector-file', 'TimePicker'), _extends4)),
            _react2.default.createElement(_TimePicker2.default, _extends({
                fullWidth: true

            }, _TimePicker_style2.default.TimePicker, _lodash2.default.get(this.props, 'styles.TimePicker', {}), {
                id: this.props.id,
                floatingLabelText: _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                    'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'TimePicker'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'TimePicker'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'TimePicker'), _extends2))),
                onFocus: function onFocus(event) {
                    _this._timePicker;
                    //# NOTE: with the diff mixin, we allow for 'pointer-events' which prevents touchTap from getting fired, but focus events still work
                    //# so we should open dialog here

                    _this._timePicker.handleTouchTapInput(event);
                },
                onChange: this.props.onValueChange,
                value: dateValueIsValid ? _lodash2.default.get(this, 'props.value') : null,
                errorText: errorTextElement,
                container: 'inline',
                disabled: isDisabled,
                customTimeZoneList: sortedCustomTimeZoneList
            }, _lodash2.default.get(this, 'props.infoModeProps.TextField'), _lodash2.default.get(this, 'props.infoModeProps.default'), (_extends3 = {
                ref: function ref(timePicker) {
                    _this._timePicker = timePicker;
                },
                'data-codecept-selector-node': 'TimePicker',
                'data-codecept-selector-file': 'TimePicker'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'TimePicker'), _defineProperty(_extends3, 'data-codecept-selector-file', 'TimePicker'), _defineProperty(_extends3, 'data-codecept-selector-node', 'TimePicker'), _defineProperty(_extends3, 'data-codecept-selector-file', 'TimePicker'), _extends3))),
            !isDisabled ? _react2.default.createElement(
                'div',
                (_React$createElement5 = {
                    style: {
                        display: 'flex',
                        marginRight: 3,
                        visibility: dateValueIsValid ? 'visible' : 'hidden',
                        position: 'absolute',
                        right: -19,
                        top: -3
                    },
                    'data-codecept-selector-node': 'div',
                    'data-codecept-selector-file': 'TimePicker'
                }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'TimePicker'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'TimePicker'), _React$createElement5),
                _react2.default.createElement(
                    'div',
                    (_React$createElement4 = {
                        style: {
                            marginTop: 30
                        },
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'TimePicker'
                    }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TimePicker'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'TimePicker'), _React$createElement4),
                    _react2.default.createElement(
                        _IconButton2.default,
                        (_React$createElement3 = {
                            onTouchTap: this.props.clearValue,
                            'data-codecept-selector-node': 'IconButton',
                            'data-codecept-selector-file': 'TimePicker'
                        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TimePicker'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'IconButton'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'TimePicker'), _React$createElement3),
                        _react2.default.createElement(_clear2.default, (_React$createElement2 = {
                            'data-codecept-selector-node': 'ContentClear',
                            'data-codecept-selector-file': 'TimePicker'
                        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'ContentClear'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TimePicker'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'ContentClear'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'TimePicker'), _React$createElement2))
                    )
                )
            ) : null
        );

        return timePickerComponent;
    }
});

var FieldTimePicker = _react2.default.createClass({
    displayName: 'FieldTimePicker',

    propTypes: _extends({
        fieldKey: PropTypes.string,
        actions: PropTypes.object
    }, RawFieldTimePicker.propTypes),
    contextTypes: {
        muiTheme: PropTypes.object.isRequired
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null,
            styles: {},
            renderGridWrapper: function renderGridWrapper(props) {
                return props.children;
            }
        };
    },
    _onValueChange: function _onValueChange(event, _ref) {
        var timeMoment = _ref.timeMoment,
            zoneName = _ref.zoneName;

        this.props.actions.setFieldValueByKey({ timeMoment: timeMoment, zoneName: zoneName }, this.props.fieldKey);
    },
    _clearValue: function _clearValue() {
        this.props.actions.setFieldValueByKey(null, this.props.fieldKey);
    },
    render: function render() {
        var _extends5;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var isDisabled = this._isDisabled();

        var timePickerComponent = _react2.default.createElement(RawFieldTimePicker, _extends({
            disabled: isDisabled,
            clearValue: this._clearValue,
            onValueChange: this._onValueChange
        }, _lodash2.default.pick(this.props, Object.keys(RawFieldTimePicker.propTypes)), (_extends5 = {
            'data-codecept-selector-node': 'RawFieldTimePicker',
            'data-codecept-selector-file': 'TimePicker'
        }, _defineProperty(_extends5, 'data-codecept-selector-node', 'RawFieldTimePicker'), _defineProperty(_extends5, 'data-codecept-selector-file', 'TimePicker'), _defineProperty(_extends5, 'data-codecept-selector-node', 'RawFieldTimePicker'), _defineProperty(_extends5, 'data-codecept-selector-file', 'TimePicker'), _extends5)));

        return this.props.renderGridWrapper(_extends({}, this.props, { children: timePickerComponent }));
    },

    _isDisabled: function _isDisabled() {
        return this.props.locked || this.props.disabled || this.props.isInfoMode;
    }
});

exports.default = (0, _addInfoModeProps2.default)(FieldTimePicker);
exports.TimePicker = RawFieldTimePicker;