'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp, _initialiseProps;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash.isequal');

var _lodash2 = _interopRequireDefault(_lodash);

var _utils = require('./utils');

var _GridItem = require('./GridItem');

var _GridItem2 = _interopRequireDefault(_GridItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var path = require('path');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

var noop = function noop() {};

// Types

// End Types

/**
 * A reactive, fluid grid layout with draggable, resizable components.
 */

var ReactGridLayout = (_temp = _class = function (_React$Component) {
  _inherits(ReactGridLayout, _React$Component);

  // TODO publish internal ReactClass displayName transform
  function ReactGridLayout(props, context) {
    _classCallCheck(this, ReactGridLayout);

    //autoBindHandlers(this, ['onDragStart', 'onDrag', 'onDragStop', 'onResizeStart', 'onResize', 'onResizeStop']);
    var _this = _possibleConstructorReturn(this, (ReactGridLayout.__proto__ || Object.getPrototypeOf(ReactGridLayout)).call(this, props, context));

    _initialiseProps.call(_this);

    (0, _utils.autoBindHandlers)(_this, ['onDragStart', 'onDrag', 'onDragStop']);
    return _this;
  }

  _createClass(ReactGridLayout, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.setState({ mounted: true });
      // Possibly call back with layout on mount. This should be done after correcting the layout width
      // to ensure we don't rerender with the wrong width.
      this.onLayoutMaybeChanged(this.state.layout, this.props.layout);
    }

    /*    //# NOTE: we can't use this because we rely too much on this.state
          //# but also, because this component is pretty stateful, most likely it'll need to 
          //# always be rerendered
    shouldComponentUpdate(nextProps, nextState) {
      let newLayoutBase;
      if (!isEqual(nextState, this.state)) {
          return true
      }
       if (!isEqual(nextProps.layout, this.props.layout)) {
          newLayoutBase = nextProps.layout;
      } else if (!childrenEqual(this.props.children, nextProps.children)) {
          newLayoutBase = this.state.layout;
      }
      if (!newLayoutBase) {
          logger.debug('[shouldComponentUpdate()] false');
          return false;
      }
       //# NOTE: not great but useable since our use of this component is limited
      //# so we can craft shouldComponentUpdate to however we need it
      return true;
    }
    */

  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      var newLayoutBase = void 0;
      // Allow parent to set layout directly.
      if (!(0, _lodash2.default)(nextProps.layout, this.props.layout)) {
        newLayoutBase = nextProps.layout;
      }

      // If children change, also regenerate the layout. Use our state
      // as the base in case because it may be more up to date than
      // what is in props.
      else if (!(0, _utils.childrenEqual)(this.props.children, nextProps.children)) {
          logger.debug('[componentWillReceiveProps] new children', _react2.default.Children.map(this.props.children, function (c) {
            return c.key;
          }), _react2.default.Children.map(nextProps.children, function (c) {
            return c.key;
          }));
          newLayoutBase = this.state.layout;
        }

      // We need to regenerate the layout.
      if (newLayoutBase) {
        var newLayout = (0, _utils.synchronizeLayoutWithChildren)(newLayoutBase, nextProps.children, { cols: nextProps.cols, margin: nextProps.margin }, { vertical: nextProps.verticalCompact,
          horizontal: nextProps.horizontalCompact,
          linear: nextProps.linearCompact
        });
        var _oldLayout = this.state.layout;
        this.setState({ layout: newLayout });
        this.onLayoutMaybeChanged(newLayout, _oldLayout);
        logger.debug('[componentWillReceiveProps()] regenerating layout', newLayout, _oldLayout);
      }
    }

    /**
     * Calculates a pixel value for the container.
     * @return {String} Container height in pixels.
     */

  }, {
    key: 'containerHeight',
    value: function containerHeight() {
      if (!this.props.autoSize) return;
      var nbRow = (0, _utils.bottom)(this.state.layout);
      var containerPaddingY = this.props.containerPadding ? this.props.containerPadding[1] : this.props.margin[1];

      if (this.props.rowHeight == 1) {
        return nbRow + this.props.margin[1] + containerPaddingY * 2 + 'px';
      } else {
        return nbRow * this.props.rowHeight + (nbRow - 1) * this.props.margin[1] + containerPaddingY * 2 + 'px';
      }
    }

    /**
     * When dragging starts
     * @param {String} i Id of the child
     * @param {Number} x X position of the move
     * @param {Number} y Y position of the move
     * @param {Event} e The mousedown event
     * @param {Element} node The current dragging DOM element
     */

  }, {
    key: 'onDragStart',
    value: function onDragStart(i, x, y, _ref) {
      var e = _ref.e,
          node = _ref.node;
      var layout = this.state.layout;

      var l = (0, _utils.getLayoutItem)(layout, i);
      if (!l) return;

      this.setState({ oldDragItem: (0, _utils.cloneLayoutItem)(l), oldLayout: this.state.layout });

      this.props.onDragStart(layout, l, l, null, e, node);
    }

    /**
     * Each drag movement create a new dragelement and move the element to the dragged location
     * @param {String} i Id of the child
     * @param {Number} x X position of the move
     * @param {Number} y Y position of the move
     * @param {Event} e The mousedown event
     * @param {Element} node The current dragging DOM element
     */

  }, {
    key: 'onDrag',
    value: function onDrag(i, x, y, _ref2) {
      var e = _ref2.e,
          node = _ref2.node;
      var oldDragItem = this.state.oldDragItem;
      var layout = this.state.layout;
      //console.log('===', JSON.stringify(layout));

      var l = (0, _utils.getLayoutItem)(layout, i);
      if (!l) return;
      // Create placeholder (display only)
      var placeholder = {
        w: l.w, h: l.h, x: l.x, y: l.y, placeholder: true, i: i
      };

      //console.log('BEFORE MOVE', JSON.stringify(placeholder), JSON.stringify(l));
      // Move the element to the dragged location.
      layout = (0, _utils.moveElement)(layout, l, x, y, true /* isUserAction */);

      this.props.onDrag(layout, oldDragItem, l, placeholder, e, node);

      var compactProps = {
        vertical: this.props.verticalCompact,
        horizontal: this.props.horizontalCompact,
        linear: this.props.linearCompact
      };
      this.setState({
        layout: (0, _utils.compact)(layout, compactProps, { cols: this.props.cols, margin: this.props.margin }),
        activeDrag: placeholder
      });
    }

    /**
     * When dragging stops, figure out which position the element is closest to and update its x and y.
     * @param  {String} i Index of the child.
     * @param {Number} x X position of the move
     * @param {Number} y Y position of the move
     * @param {Event} e The mousedown event
     * @param {Element} node The current dragging DOM element
     */

  }, {
    key: 'onDragStop',
    value: function onDragStop(i, x, y, _ref3) {
      var e = _ref3.e,
          node = _ref3.node;
      var oldDragItem = this.state.oldDragItem;
      var layout = this.state.layout;

      var l = (0, _utils.getLayoutItem)(layout, i);
      if (!l) return;

      // Move the element here
      layout = (0, _utils.moveElement)(layout, l, x, y, true /* isUserAction */);

      this.props.onDragStop(layout, oldDragItem, l, null, e, node);

      // Set state
      var compactProps = {
        vertical: this.props.verticalCompact,
        horizontal: this.props.horizontalCompact,
        linear: this.props.linearCompact
      };
      var newLayout = (0, _utils.compact)(layout, compactProps, { cols: this.props.cols, margin: this.props.margin });
      var oldLayout = this.state.oldLayout;

      this.setState({
        activeDrag: null,
        layout: newLayout,
        oldDragItem: null,
        oldLayout: null
      });

      this.onLayoutMaybeChanged(newLayout, oldLayout);
    }
  }, {
    key: 'onLayoutMaybeChanged',
    value: function onLayoutMaybeChanged(newLayout, oldLayout) {
      if (!oldLayout) oldLayout = this.state.layout;
      if (!(0, _lodash2.default)(oldLayout, newLayout)) {
        this.props.onLayoutChange(newLayout);
      }
    }

    /*
    onResizeStart(i:string, w:number, h:number, {e, node}: ResizeEvent) {
      const {layout} = this.state;
      var l = getLayoutItem(layout, i);
      if (!l) return;
       this.setState({
        oldResizeItem: cloneLayoutItem(l),
        oldLayout: this.state.layout
      });
       this.props.onResizeStart(layout, l, l, null, e, node);
    }
     onResize(i:string, w:number, h:number, {e, node}: ResizeEvent) {
      const {layout, oldResizeItem} = this.state;
      var l = getLayoutItem(layout, i);
      if (!l) return;
       // Set new width and height.
      l.w = w;
      l.h = h;
       // Create placeholder element (display only)
      var placeholder = {
        w: w, h: h, x: l.x, y: l.y, static: true, i: i
      };
       this.props.onResize(layout, oldResizeItem, l, placeholder, e, node);
       // Re-compact the layout and set the drag placeholder.
      const compactProps = {
        vertical: this.props.verticalCompact
        , horizontal: this.props.horizontalCompact
        , linear: this.props.linearCompact
      };
      this.setState({
          layout: compact(layout, compactProps, {cols:this.props.cols, margin: this.props.margin}),
        activeDrag: placeholder
      });
    }
     onResizeStop(i:string, w:number, h:number, {e, node}: ResizeEvent) {
      const {layout, oldResizeItem} = this.state;
      var l = getLayoutItem(layout, i);
       this.props.onResizeStop(layout, oldResizeItem, l, null, e, node);
       // Set state
      const compactProps = {
        vertical: this.props.verticalCompact
        , horizontal: this.props.horizontalCompact
        , linear: this.props.linearCompact
      };
      const newLayout = compact(layout, compactProps, {cols:this.props.cols, margin: this.props.margin});
      const {oldLayout} = this.state;
      this.setState({
        activeDrag: null,
        layout: newLayout,
        oldResizeItem: null,
        oldLayout: null
      });
       this.onLayoutMaybeChanged(newLayout, oldLayout);
    }
    */

    /**
     * Create a placeholder object.
     * @return {Element} Placeholder div.
     */

  }, {
    key: 'placeholder',
    value: function placeholder() {
      var _React$createElement, _React$createElement2;

      var activeDrag = this.state.activeDrag;

      if (!activeDrag) return null;
      var _props = this.props,
          width = _props.width,
          cols = _props.cols,
          margin = _props.margin,
          containerPadding = _props.containerPadding,
          rowHeight = _props.rowHeight,
          maxRows = _props.maxRows,
          useCSSTransforms = _props.useCSSTransforms;

      // {...this.state.activeDrag} is pretty slow, actually

      return _react2.default.createElement(
        _GridItem2.default,
        (_React$createElement2 = {
          w: activeDrag.w,
          h: activeDrag.h,
          x: activeDrag.x,
          y: activeDrag.y,
          i: activeDrag.i,
          className: 'react-grid-placeholder ' + this.props.placeholderClassName,
          containerWidth: width,
          cols: cols,
          margin: margin,
          containerPadding: containerPadding || margin,
          maxRows: maxRows,
          rowHeight: rowHeight,
          isDraggable: false,
          isResizable: false,
          useCSSTransforms: useCSSTransforms, 'data-codecept-selector-node': 'GridItem',
          'data-codecept-selector-file': 'ReactGridLayout'
        }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'GridItem'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ReactGridLayout'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'GridItem'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'ReactGridLayout'), _React$createElement2),
        _react2.default.createElement('div', (_React$createElement = {
          'data-codecept-selector-node': 'div',
          'data-codecept-selector-file': 'ReactGridLayout'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ReactGridLayout'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'ReactGridLayout'), _React$createElement))
      );
    }

    /**
     * Given a grid item, set its style attributes & surround in a <Draggable>.
     * @param  {Element} child React element.
     * @return {Element}       Element wrapped in draggable and properly placed.
     */

  }, {
    key: 'processGridItem',
    value: function processGridItem(child) {
      var _React$createElement3;

      if (!child.key) return;
      var l = (0, _utils.getLayoutItem)(this.state.layout, child.key);

      if (!l) return null;
      var _props2 = this.props,
          width = _props2.width,
          cols = _props2.cols,
          margin = _props2.margin,
          containerPadding = _props2.containerPadding,
          rowHeight = _props2.rowHeight,
          maxRows = _props2.maxRows,
          isDraggable = _props2.isDraggable,
          isResizable = _props2.isResizable,
          useCSSTransforms = _props2.useCSSTransforms,
          draggableCancel = _props2.draggableCancel,
          draggableHandle = _props2.draggableHandle;
      var mounted = this.state.mounted;

      // Parse 'static'. Any properties defined directly on the grid item will take precedence.

      var draggable = Boolean(!l.static && isDraggable && (l.isDraggable || l.isDraggable == null));
      var resizable = Boolean(!l.static && isResizable && (l.isResizable || l.isResizable == null));

      return _react2.default.createElement(
        _GridItem2.default,
        (_React$createElement3 = {
          containerWidth: width,
          cols: cols,
          margin: margin,
          containerPadding: containerPadding || margin,
          maxRows: maxRows,
          rowHeight: rowHeight,
          cancel: draggableCancel,
          handle: draggableHandle,
          onDragStop: this.onDragStop,
          onDragStart: this.onDragStart,
          onDrag: this.onDrag,
          onResizeStart: this.onResizeStart,
          onResize: this.onResize,
          onResizeStop: this.onResizeStop,
          isDraggable: draggable,
          isResizable: resizable,
          useCSSTransforms: useCSSTransforms && mounted,
          usePercentages: !mounted,

          w: l.w,
          h: l.h,
          x: l.x,
          y: l.y,
          i: l.i,
          minH: l.minH,
          minW: l.minW,
          maxH: l.maxH,
          maxW: l.maxW,
          'static': l.static,
          'data-codecept-selector-node': 'GridItem',
          'data-codecept-selector-file': 'ReactGridLayout'
        }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'GridItem'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ReactGridLayout'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'GridItem'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'ReactGridLayout'), _React$createElement3),
        child
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this,
          _React$createElement4;

      var _props3 = this.props,
          className = _props3.className,
          style = _props3.style;


      var mergedClassName = 'react-grid-layout ' + className;
      var mergedStyle = _extends({
        height: this.containerHeight()
      }, style);

      return _react2.default.createElement(
        'div',
        (_React$createElement4 = { className: mergedClassName, style: mergedStyle, 'data-codecept-selector-node': 'div',
          'data-codecept-selector-file': 'ReactGridLayout'
        }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ReactGridLayout'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'ReactGridLayout'), _React$createElement4),
        _react2.default.Children.map(this.props.children, function (child) {
          return _this2.processGridItem(child);
        }),
        this.placeholder()
      );
    }
  }]);

  return ReactGridLayout;
}(_react2.default.Component), _class.displayName = "ReactGridLayout", _class.propTypes = {
  //
  // Basic props
  //
  className: _react.PropTypes.string,
  style: _react.PropTypes.object,
  //# for additional classes for the placeholder
  placeholderClassName: _react.PropTypes.string,

  // This can be set explicitly. If it is not set, it will automatically
  // be set to the container width. Note that resizes will *not* cause this to adjust.
  // If you need that behavior, use WidthProvider.
  width: _react.PropTypes.number,

  // If true, the container height swells and contracts to fit contents
  autoSize: _react.PropTypes.bool,
  // # of cols.
  cols: _react.PropTypes.number,

  // A selector that will not be draggable.
  draggableCancel: _react.PropTypes.string,
  // A selector for the draggable handler
  draggableHandle: _react.PropTypes.string,

  // If true, the layout will compact vertically
  verticalCompact: _react.PropTypes.bool,

  // If true, the layout will compact horizontally
  horizontalCompact: _react.PropTypes.bool,

  // If true, the layout will compact sequentially
  // this requires that the item heights are fixed in size
  linearCompact: _react.PropTypes.bool,

  // layout is an array of object with the format:
  // {x: Number, y: Number, w: Number, h: Number, i: String}
  layout: function layout(props) {
    var layout = props.layout;
    // I hope you're setting the data-grid property on the grid items
    if (layout === undefined) return;
    //validateLayout(layout, 'layout'); //# NOTE: ignore for now since we're modifying this library
  },

  //
  // Grid Dimensions
  //

  // Margin between items [x, y] in px
  margin: _react.PropTypes.arrayOf(_react.PropTypes.number),
  // Padding inside the container [x, y] in px
  containerPadding: _react.PropTypes.arrayOf(_react.PropTypes.number),
  // Rows have a static height, but you can change this based on breakpoints if you like
  rowHeight: _react.PropTypes.number,
  // Default Infinity, but you can specify a max here if you like.
  // Note that this isn't fully fleshed out and won't error if you specify a layout that
  // extends beyond the row capacity. It will, however, not allow users to drag/resize
  // an item past the barrier. They can push items beyond the barrier, though.
  // Intentionally not documented for this reason.
  maxRows: _react.PropTypes.number,

  //
  // Flags
  //
  isDraggable: _react.PropTypes.bool,
  isResizable: _react.PropTypes.bool,
  // Use CSS transforms instead of top/left
  useCSSTransforms: _react.PropTypes.bool,

  //
  // Callbacks
  //

  // Callback so you can save the layout. Calls after each drag & resize stops.
  onLayoutChange: _react.PropTypes.func,

  // Calls when drag starts. Callback is of the signature (layout, oldItem, newItem, placeholder, e).
  // All callbacks below have the same signature. 'start' and 'stop' callbacks omit the 'placeholder'.
  onDragStart: _react.PropTypes.func,
  // Calls on each drag movement.
  onDrag: _react.PropTypes.func,
  // Calls when drag is complete.
  onDragStop: _react.PropTypes.func,
  /*
  //Calls when resize starts.
  onResizeStart: PropTypes.func,
  // Calls when resize movement happens.
  onResize: PropTypes.func,
  // Calls when resize is complete.
  onResizeStop: PropTypes.func,
  */
  //
  // Other validations
  //

  // Children must not have duplicate keys.
  children: function children(props, propName, _componentName) {
    var children = props[propName];

    // Check children keys for duplicates. Throw if found.
    var keys = {};
    _react2.default.Children.forEach(children, function (child) {
      if (keys[child.key]) {
        throw new Error("Duplicate child key found! This will cause problems in ReactGridLayout.");
      }
      keys[child.key] = true;
    });
  }
}, _class.defaultProps = {
  placeholderClassName: '',
  autoSize: true,
  cols: 12,
  className: '',
  rowHeight: 150,
  maxRows: Infinity, // infinite vertical growth
  layout: [],
  margin: [10, 10],
  isDraggable: true,
  isResizable: true,
  useCSSTransforms: true,
  verticalCompact: false,
  horizontalCompact: false,
  linearCompact: true,
  onLayoutChange: noop,
  onDragStart: noop,
  onDrag: noop,
  onDragStop: noop,
  onResizeStart: noop,
  onResize: noop,
  onResizeStop: noop
}, _initialiseProps = function _initialiseProps() {
  this.state = {
    activeDrag: null,
    layout: (0, _utils.synchronizeLayoutWithChildren)(this.props.layout, this.props.children, { cols: this.props.cols, margin: this.props.margin }, { vertical: this.props.verticalCompact,
      horizontal: this.props.horizontalCompact,
      linear: this.props.linearCompact
    }),
    mounted: false,
    oldDragItem: null,
    oldLayout: null,
    oldResizeItem: null
  };
}, _temp);
exports.default = ReactGridLayout;