'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _datepicker = require('@rubyapps/ruby-styles/src/client/styleObjects/datepicker');

var _datepicker2 = _interopRequireDefault(_datepicker);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    DatePicker: _datepicker2.default
};