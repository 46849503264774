'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _search = require('material-ui/svg-icons/action/search');

var _search2 = _interopRequireDefault(_search);

var _Activity_style = require('../Activity_style');

var _Activity_style2 = _interopRequireDefault(_Activity_style);

var _constants = require('../../../common/constants');

var _constants2 = _interopRequireDefault(_constants);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var styles = process.browser ? require('../Activity.cssModule').default : {};

var ACTIVITY_TYPES = _constants2.default.ACTIVITY_TYPES;


var ActivityTitle = _react2.default.createClass({
    displayName: 'ActivityTitle',

    propTypes: {},
    customMixins: [] //# non-reserved key to handle special mixins where we want to handle calling them ourselves
    , isCurrentUser: function isCurrentUser(dataKey) {
        var userId = _lodash2.default.get(this.props, [dataKey, 'id']);
        var currentUserId = _lodash2.default.get(this.props, 'rubyUser.id');
        return currentUserId && userId == currentUserId;
    },
    getRubyUserDisplayName: function getRubyUserDisplayName() {
        var _React$createElement;

        var dataKey = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'user';
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var displayString = this.isCurrentUser(dataKey) ? options.lowercasePronouns ? 'you' : 'You' : [_lodash2.default.get(this.props, [dataKey, 'first_name']), _lodash2.default.get(this.props, [dataKey, 'last_name'])].join(' ');
        return _react2.default.createElement(
            'span',
            (_React$createElement = { className: styles.username, 'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'title'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'title'), _React$createElement),
            displayString
        );
    },
    getActivityRepresentation: function getActivityRepresentation() {
        return {
            activityId: this.props.id,
            versionDataId: this.props.version_data_id,
            versionNumber: this.props.version_num,
            templateKey: this.props.entity_name,
            entityId: this.props.entity_id
        };
    },
    getRevisionLink: function getRevisionLink() {
        var _React$createElement2, _React$createElement3, _React$createElement4, _React$createElement5;

        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var _props = this.props,
            version_num = _props.version_num,
            actions = _props.actions,
            shouldHidePreviewLinks = _props.shouldHidePreviewLinks;

        var activity = this.getActivityRepresentation();
        var hideIcon = options.hideIcon;


        var enablePreview = actions && !shouldHidePreviewLinks;

        return enablePreview ? _react2.default.createElement(
            'a',
            (_React$createElement4 = { className: styles.revisionLink, onClick: function onClick(e) {
                    e.preventDefault();
                    actions.previewActivityVersion(activity);
                }, 'data-codecept-selector-node': 'a',
                'data-codecept-selector-file': 'title'
            }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'title'), _React$createElement4),
            'Revision ',
            version_num,
            hideIcon ? null : _react2.default.createElement(
                'span',
                (_React$createElement3 = { className: styles.revisionLinkHoverIcon, 'data-codecept-selector-node': 'span',
                    'data-codecept-selector-file': 'title'
                }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'title'), _React$createElement3),
                _react2.default.createElement(_search2.default, (_React$createElement2 = { style: _Activity_style2.default.iconStyles, 'data-codecept-selector-node': 'SearchIcon',
                    'data-codecept-selector-file': 'title'
                }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'SearchIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'SearchIcon'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'title'), _React$createElement2))
            )
        ) : _react2.default.createElement(
            'span',
            (_React$createElement5 = { className: styles.revisionText, 'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'title'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'title'), _React$createElement5),
            'Revision ',
            version_num
        );
    },
    getAlterTemplateDesc: function getAlterTemplateDesc() {
        var _props2 = this.props,
            details = _props2.details,
            forms = _props2.forms;


        var priorTemplateKey = details && details.prior_template_keyword;
        var templateKey = details && details.template_keyword;

        var formsState = forms.getState();

        var priorForm = priorTemplateKey && formsState[priorTemplateKey];
        var form = templateKey && formsState[templateKey];

        var priorTemplateName = priorForm && priorForm.name;
        var templateName = form && form.name;

        return priorTemplateName && templateName && priorTemplateName !== templateName ? 'from ' + priorTemplateName + ' to ' + templateName : '';
    },
    renderTitle: function renderTitle() {
        var _this = this;

        return this.customMixins.reduce(function (collector, mixin) {
            if (collector) {
                return collector;
            }

            return mixin.renderTitle && mixin.renderTitle.apply(_this);
        }, null);
    },
    getRevisionMaintenanceDesc: function getRevisionMaintenanceDesc() {
        var _React$createElement6, _React$createElement7, _React$createElement8, _React$createElement9;

        var _props$details = this.props.details,
            details = _props$details === undefined ? {} : _props$details;
        var _details$affected_rev = details.affected_revisions,
            affected_revisions = _details$affected_rev === undefined ? {} : _details$affected_rev;
        var start = affected_revisions.start,
            end = affected_revisions.end;


        var hasRange = start && end;

        var revisionsRange = _lodash2.default.find([hasRange && start === end && _react2.default.createElement(
            'span',
            (_React$createElement6 = { className: styles.revisionsRange, 'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'title'
            }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'title'), _React$createElement6),
            'Revision ',
            start
        ), hasRange && start !== end && _react2.default.createElement(
            'span',
            (_React$createElement7 = { className: styles.revisionsRange, 'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'title'
            }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'title'), _React$createElement7),
            'Revisions ',
            start,
            ' \u2013 ',
            end
        ), _react2.default.createElement(
            'span',
            (_React$createElement8 = {
                'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'title'
            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'title'), _React$createElement8),
            'Revisions'
        )], _lodash2.default.identity);

        return _react2.default.createElement(
            'span',
            (_React$createElement9 = {
                'data-codecept-selector-node': 'span',
                'data-codecept-selector-file': 'title'
            }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'title'), _React$createElement9),
            revisionsRange,
            ' removed as part of routine maintenance'
        );
    },
    render: function render() {
        var _React$createElement10, _React$createElement11, _React$createElement12, _React$createElement13, _React$createElement14, _React$createElement15, _React$createElement16, _React$createElement17, _React$createElement18, _React$createElement19, _React$createElement20, _React$createElement21, _React$createElement22;

        var titleFromMixin = this.renderTitle();
        if (titleFromMixin) {
            return titleFromMixin;
        }

        var user = this.getRubyUserDisplayName('user');
        var recipient = this.getRubyUserDisplayName('recipient', { lowercasePronouns: true });
        var _props3 = this.props,
            activity_type = _props3.activity_type,
            version_num = _props3.version_num,
            _props3$details = _props3.details,
            details = _props3$details === undefined ? {} : _props3$details;


        switch (activity_type) {
            case ACTIVITY_TYPES.REVISION_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement10 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'title'
                    }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'title'), _React$createElement10),
                    user,
                    ' updated content to ',
                    this.getRevisionLink()
                );
            case ACTIVITY_TYPES.ALTER_TEMPLATE_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement11 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'title'
                    }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'title'), _React$createElement11),
                    user,
                    ' altered the template ',
                    this.getAlterTemplateDesc(),
                    ' creating ',
                    this.getRevisionLink()
                );
            case ACTIVITY_TYPES.DELETION_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement12 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'title'
                    }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'title'), _React$createElement12),
                    user,
                    ' deleted the content'
                );
            case ACTIVITY_TYPES.RERANK_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement13 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'title'
                    }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'title'), _React$createElement13),
                    user,
                    ' reordered the content'
                );
            case ACTIVITY_TYPES.COMMENT_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement14 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'title'
                    }, _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement14, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement14, 'data-codecept-selector-file', 'title'), _React$createElement14),
                    user,
                    ' added a comment about ',
                    this.getRevisionLink()
                );
            case ACTIVITY_TYPES.WORDDOC_GENERATION_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement15 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'title'
                    }, _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement15, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement15, 'data-codecept-selector-file', 'title'), _React$createElement15),
                    user,
                    ' exported document ',
                    this.getRevisionLink()
                );
            case ACTIVITY_TYPES.APPROVAL_REQUEST_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement16 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'title'
                    }, _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement16, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement16, 'data-codecept-selector-file', 'title'), _React$createElement16),
                    user,
                    ' submitted an update for review to ',
                    recipient
                );
            case ACTIVITY_TYPES.APPROVAL_COMMENT_ACTIVITY:
                //# DEPRECATED 20201203
                return _react2.default.createElement(
                    'span',
                    (_React$createElement17 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'title'
                    }, _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement17, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement17, 'data-codecept-selector-file', 'title'), _React$createElement17),
                    user,
                    ' added a comment on the update submitted for review'
                );
            case ACTIVITY_TYPES.APPROVAL_APPROVE_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement18 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'title'
                    }, _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement18, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement18, 'data-codecept-selector-file', 'title'), _React$createElement18),
                    user,
                    ' approved the update to ',
                    this.getRevisionLink()
                );
            case ACTIVITY_TYPES.APPROVAL_REJECT_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement19 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'title'
                    }, _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement19, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement19, 'data-codecept-selector-file', 'title'), _React$createElement19),
                    user,
                    ' rejected the update submitted by ',
                    recipient
                );
            case ACTIVITY_TYPES.APPROVAL_DISCARD_ACTIVITY:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement20 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'title'
                    }, _defineProperty(_React$createElement20, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement20, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement20, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement20, 'data-codecept-selector-file', 'title'), _React$createElement20),
                    user,
                    ' discarded the update submitted by ',
                    recipient
                );
            case ACTIVITY_TYPES.CONTENT_IMPORT_ACTIVITY:
                var isUpdate = version_num > 1;
                var actionWord = isUpdate ? 'updated' : 'created';
                var versionText = isUpdate ? ['to ', this.getRevisionLink({ hideIcon: true })] : null;
                return _react2.default.createElement(
                    'span',
                    (_React$createElement21 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'title'
                    }, _defineProperty(_React$createElement21, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement21, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement21, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement21, 'data-codecept-selector-file', 'title'), _React$createElement21),
                    user,
                    ' ',
                    actionWord,
                    ' this page ',
                    versionText,
                    ' as part of a content import'
                );
            case ACTIVITY_TYPES.SYSTEM_MAINTENANCE_ACTIVITY:
                return this.getRevisionMaintenanceDesc();
            default:
                return _react2.default.createElement(
                    'span',
                    (_React$createElement22 = {
                        'data-codecept-selector-node': 'span',
                        'data-codecept-selector-file': 'title'
                    }, _defineProperty(_React$createElement22, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement22, 'data-codecept-selector-file', 'title'), _defineProperty(_React$createElement22, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement22, 'data-codecept-selector-file', 'title'), _React$createElement22),
                    user,
                    ' added a comment'
                );
        };
    }
});

exports.default = ActivityTitle;