'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');

module.exports = function (state, hydratedToggleTree) {
    var togglesById = togglesById_fromToggleTree(hydratedToggleTree);

    return togglesById;
};

function togglesById_fromToggleTree() {
    var toggleTree = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    var togglesById = _.reduce(toggleTree, function (acc, treeNode) {
        var id = treeNode.id,
            _treeNode$children = treeNode.children,
            children = _treeNode$children === undefined ? [] : _treeNode$children;

        var value = _extends(_defineProperty({}, id, _extends({}, _.omit(treeNode, ['children']), { toggled: false })), !_.isEmpty(children) ? togglesById_fromToggleTree(children) : {});

        return _extends(acc, value);
    }, {});

    return togglesById;
}

function updateToggleTree(toggledId, toggleCtx, toggleTree) {
    var updatedToggleTree = _.reduce(toggleTree, function (acc, toggleData) {
        var isToggled = toggleCtx.isToggled;
        var id = toggleData.id,
            fieldKey = toggleData.fieldKey,
            _toggleData$children = toggleData.children,
            children = _toggleData$children === undefined ? [] : _toggleData$children;


        var updatedData = id === toggledId ? _extends({}, toggleData, { toggled: isToggled }, !fieldKey && !_.isEmpty(children) && { children: updateChildToggleStates(children, isToggled) }) : _.isEmpty(children) ? toggleData : _extends({}, toggleData, { children: updateToggleTree(toggledId, toggleCtx, children) });

        return acc.concat(updatedData);
    }, []);

    return updatedToggleTree;
}

function updateChildToggleStates(toggleTree, toggleState) {
    return _.reduce(toggleTree, function (acc, treeNode) {
        var _treeNode$children2 = treeNode.children,
            children = _treeNode$children2 === undefined ? [] : _treeNode$children2;

        var updatedChildState = _extends({}, treeNode, !_.isEmpty(children) && { children: updateChildToggleStates(children, toggleState) }, { toggled: toggleState });

        return acc.concat(updatedChildState);
    }, []);
}