'use strict';

//import {batchActions} from 'redux-batched-actions';
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');

module.exports = function () {
    var id = this.getID();

    var TYPES = {
        OPEN: '@ruby-app/' + id + '/OPEN',
        OPENING: '@ruby-app/' + id + '/OPENING',
        CLOSE: '@ruby-app/' + id + '/CLOSE'
    };

    return {
        TYPES: TYPES,
        generators: {
            _open: function _open() {
                return {
                    type: TYPES.OPEN,
                    payload: {
                        dialog_opening: false,
                        dialog_open: true
                    }
                };
            },
            _opening: function _opening() {
                return {
                    type: TYPES.OPENING,
                    payload: {
                        dialog_opening: true
                    }
                };
            },
            openDialog: function openDialog() {
                var _this = this;

                var _getAction = this.getAction(),
                    actions = _getAction.generators;

                return function (dispatch) {
                    dispatch(actions._opening());
                    _this.replaceChildren();
                    dispatch(actions._open());
                };
            },
            _close: function _close() {
                return {
                    type: TYPES.CLOSE,
                    payload: {
                        dialog_open: false,
                        dialog_opening: false
                    }
                };
            },
            closeDialog: function closeDialog() {
                var _this2 = this;

                var _getAction2 = this.getAction(),
                    actions = _getAction2.generators;

                //# close info pane


                return function (dispatch) {
                    dispatch(actions._close());
                    _this2.replaceChildren();
                };
            }
        }
    };
};