'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.remoteError = remoteError;
exports.makeRequest = makeRequest;
exports.setLoading = setLoading;
exports.startLoading = startLoading;
exports.dataWasLoaded = dataWasLoaded;
exports.loadData = loadData;
exports.requestToLoadData = requestToLoadData;
exports.filterData = filterData;
exports.filterDataRemoteHandler = filterDataRemoteHandler;
exports.setPageSize = setPageSize;
exports.setPageSizeRemoteHandler = setPageSizeRemoteHandler;
exports.sort = sort;
exports.sortRemoteHandler = sortRemoteHandler;
exports.addSortColumn = addSortColumn;
exports.addSortColumnRemoteHandler = addSortColumnRemoteHandler;
exports.loadNext = loadNext;
exports.loadNextRemoteHandler = loadNextRemoteHandler;
exports.loadPrevious = loadPrevious;
exports.loadPreviousRemoteHandler = loadPreviousRemoteHandler;
exports.loadPage = loadPage;
exports.loadPageRemoteHandler = loadPageRemoteHandler;
exports.refreshCurrentPage = refreshCurrentPage;
exports.refreshCurrentPageRemoteHandler = refreshCurrentPageRemoteHandler;

var _constants = require('./constants');

var types = _interopRequireWildcard(_constants);

var _storeUtil = require('./util/store-util');

var _griddleCore = require('../../griddle-core');

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

var _reduxBatchedActions = require('redux-batched-actions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

/**
 *  @param {array[Object]} additionalBatchActions - additional action payloads to concat to batch action
 *  @param {array[Object]} additionalPostBatchActions - additional actions to dispatch after batch
 */
function remoteAction(actionType, response) {
  var additionalBatchActions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var additionalPostBatchActions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];

  /*
  return batchActions([
      {
          type: actionType,
          currentPage: response.page,
          maxPage: response.maxPage,
          totalCount: response.totalCount,
          data: Immutable.fromJS(response.data)
      }
      , dataWasLoaded()
  ]);
  */

  return function (dispatch) {
    var batchedActionsPayload = (0, _reduxBatchedActions.batchActions)([{
      type: actionType,
      currentPage: response.page,
      maxPage: response.maxPage,
      totalCount: response.totalCount,
      data: _immutable2.default.fromJS(response.data)
    }].concat(_toConsumableArray(additionalBatchActions)));

    dispatch(batchedActionsPayload);

    dispatch(dataWasLoaded());
    additionalPostBatchActions.forEach(dispatch);
  };
}

function remoteError(err, response) {
  return {
    type: types.GRIDDLE_REMOTE_ERROR
  };
}

function makeRequest(remoteProvider, tableState, successActionGenerator) {
  return function (dispatch, getState) {

    if (!remoteProvider || !remoteProvider.makeRequest) {
      console.error('No provider has been configured. Please ensure that a provider has been passed in to the griddle-remote-plugin.');
    }

    // Make the request.
    dispatch(remoteProvider.makeRequest(tableState, {
      startLoadingActionGenerator: startLoading,
      successActionGenerator: successActionGenerator,
      remoteErrorActionGenerator: remoteError,
      griddleState: getState().toJSON()
    }));
  };
}

function setLoading(loading) {
  return {
    type: loading ? types.GRIDDLE_START_LOADING : types.GRIDDLE_STOP_LOADING
  };
}

function startLoading(req) {
  return (0, _reduxBatchedActions.batchActions)([_startLoading(req), _griddleCore.GriddleActions.clearStaticData() //# clear the static data on remote request
  ]);
}

function _startLoading(req) {
  return {
    type: types.GRIDDLE_START_LOADING,
    req: req
  };
}

function dataWasLoaded() {
  return {
    type: types.GRIDDLE_LOADED_DATA
  };
}

function loadData(data) {
  return {
    type: types.GRIDDLE_LOADED_DATA,
    data: data
  };
}

function requestToLoadData(store, data, properties, ignoreCache) {
  var currentPage = store.getState().getIn(['pageProperties', 'currentPage']) || 1;

  //# types.GRIDDLE_LOAD_DATA
  return function (dispatch) {
    // Load the first page of results.
    dispatch(loadPage(store, currentPage, ignoreCache));
  };
}

/*
export function initializeGrid(store, properties) {
  let currentPage = store.getState().getIn(['pageProperties', 'currentPage']) || 1;
  return dispatch => {
    // Initialize the grid.
    dispatch(GriddleActions.initializeGrid(properties));

    // Load the first page of results.
    dispatch(loadPage(store, currentPage));
  }
}
*/

function filterData(store, filter) {
  var tableState = _extends({}, (0, _storeUtil.getTableState)(store), {
    page: 1,
    filter: filter
  });
  var remoteProvider = (0, _storeUtil.getRemoteProvider)(store);

  return function (dispatch) {
    // Execute the filter.
    dispatch(_griddleCore.GriddleActions.filterData(filter));
    dispatch(makeRequest(remoteProvider, tableState, function (response) {
      return filterDataRemoteHandler(response, filter);
    }));
  };
}

function filterDataRemoteHandler(response, filter) {
  /*
  return batchActions([
  // Append the data.
  remoteAction(types.GRIDDLE_REMOTE_REPLACE_DATA, response)
  // Execute the filter.
  , GriddleActions.filterData(filter)
  // Indicate that we're done loading.
  , { type: types.GRIDDLE_STOP_LOADING }
  ]);
  */

  return function (dispatch) {
    // Append the data.
    dispatch(remoteAction(types.GRIDDLE_REMOTE_REPLACE_DATA, response));

    // Execute the filter.
    dispatch(_griddleCore.GriddleActions.filterData(filter));
    // Indicate that we're done loading.
    dispatch({ type: types.GRIDDLE_STOP_LOADING });
  };
}

function setPageSize(store, pageSize) {
  var tableState = _extends({}, (0, _storeUtil.getTableState)(store), {
    page: 1,
    pageSize: pageSize
  });
  var remoteProvider = (0, _storeUtil.getRemoteProvider)(store);

  return makeRequest(remoteProvider, tableState, function (response) {
    return setPageSizeRemoteHandler(response, pageSize);
  });
}

function setPageSizeRemoteHandler(response, pageSize) {
  // Append the data.
  var batchedRemoteAction = remoteAction(types.GRIDDLE_REMOTE_REPLACE_DATA, response
  // Set the page size.
  , [_griddleCore.GriddleActions.setPageSize(pageSize)]
  // Indicate that we're done loading.
  , [{ type: types.GRIDDLE_STOP_LOADING }]);

  return batchedRemoteAction;
}

function sort(store, column) {
  var tableState = _extends({}, (0, _storeUtil.getTableState)(store), {
    page: 1, // Reset the page
    sortColumn: [column],
    sortDirection: (0, _storeUtil.getUpdatedSortDirection)(store, column)
  });
  var remoteProvider = (0, _storeUtil.getRemoteProvider)(store);

  return makeRequest(remoteProvider, tableState, function (response) {
    return sortRemoteHandler(response, column);
  });
}

function sortRemoteHandler(response, column) {
  return function (dispatch) {
    // Append the data.
    dispatch(remoteAction(types.GRIDDLE_REMOTE_REPLACE_DATA, response));

    // Finish the sort.
    dispatch(_griddleCore.GriddleActions.sort(column));

    // Indicate that we're done loading.
    dispatch({ type: types.GRIDDLE_STOP_LOADING });
  };
}

function addSortColumn(store, column) {
  var tableState = (0, _storeUtil.getTableState)(store);
  tableState.column = tableState.column.concat(column);
  var remoteProvider = (0, _storeUtil.getRemoteProvider)(store);

  return makeRequest(remoteProvider, tableState, function (response) {
    return addSortColumnRemoteHandler(response, column);
  });
}

function addSortColumnRemoteHandler(response, column) {
  return function (dispatch) {
    // Append the data.
    dispatch(remoteAction(types.GRIDDLE_REMOTE_REPLACE_DATA, response));

    // Finish the sort.
    dispatch(_griddleCore.GriddleActions.addSortColumn(column));

    // Indicate that we're done loading.
    dispatch({ type: types.GRIDDLE_STOP_LOADING });
  };
}

function loadNext(store) {
  var tableState = (0, _storeUtil.getTableState)(store);
  tableState.page++;
  var remoteProvider = (0, _storeUtil.getRemoteProvider)(store);

  var storeState = store.getState();
  var staticData_immutable = storeState.get('staticData');
  var staticData = staticData_immutable ? staticData_immutable.toJS() : staticData_immutable;
  var currentPage = storeState.getIn(['pageProperties', 'currentPage']);
  var maxPage = storeState.getIn(['pageProperties', 'maxPage']);

  var shouldClearPagesLoaded = staticData && staticData.length;

  if (shouldClearPagesLoaded) {
    //# force using loadPage, which inherently clears the data and loadedPages cache
    var nextPageNumber = currentPage < maxPage ? currentPage + 1 : currentPage;
    return loadPage(store, nextPageNumber, true);
  }

  // If this is a page that has already been loaded, simply load the page.
  var loadedPages = (0, _storeUtil.getPagesLoaded)(store);
  if (loadedPages.includes(tableState.page)) {
    return _griddleCore.GriddleActions.loadNext();
  }

  return makeRequest(remoteProvider, tableState, function (response) {
    return loadNextRemoteHandler(response);
  });
}

function loadNextRemoteHandler(response) {
  return function (dispatch) {
    // Append the data.
    dispatch(remoteAction(types.GRIDDLE_REMOTE_APPEND_DATA, response));

    // Load the next page, now that we have the data.
    dispatch(_griddleCore.GriddleActions.loadNext());

    // Indicate that we're done loading.
    dispatch({ type: types.GRIDDLE_STOP_LOADING });
  };
}

function loadPrevious(store) {
  var tableState = (0, _storeUtil.getTableState)(store);
  tableState.page--;
  var remoteProvider = (0, _storeUtil.getRemoteProvider)(store);

  var storeState = store.getState();
  var staticData_immutable = storeState.get('staticData');
  var staticData = staticData_immutable ? staticData_immutable.toJS() : staticData_immutable;
  var currentPage = storeState.getIn(['pageProperties', 'currentPage']);
  var shouldClearPagesLoaded = staticData && staticData.length;

  if (shouldClearPagesLoaded) {
    //# force using loadPage, which inherently clears the data and loadedPages cache
    var previousPageNumber = currentPage > 0 ? currentPage - 1 : 0;
    return loadPage(store, previousPageNumber, true);
  }

  // If this is a page that has already been loaded, simply load the page.
  var loadedPages = (0, _storeUtil.getPagesLoaded)(store);
  if (loadedPages.includes(tableState.page)) {
    return _griddleCore.GriddleActions.loadPrevious();
  }

  return makeRequest(remoteProvider, tableState, function (response) {
    return loadPreviousRemoteHandler(response);
  });
}

function loadPreviousRemoteHandler(response) {
  return function (dispatch) {
    // Append the data.
    dispatch(remoteAction(types.GRIDDLE_REMOTE_PREPEND_DATA, response));

    // Load the previous page, now that we have the data.
    dispatch(_griddleCore.GriddleActions.loadPrevious());

    // Indicate that we're done loading.
    dispatch({ type: types.GRIDDLE_STOP_LOADING });
  };
}

function loadPage(store, number, ignoreCache) {
  var tableState = _extends({}, (0, _storeUtil.getTableState)(store), {
    page: number
  });
  var remoteProvider = (0, _storeUtil.getRemoteProvider)(store);

  // If this is a page that has already been loaded, simply load the page.
  if (!ignoreCache) {
    var loadedPages = (0, _storeUtil.getPagesLoaded)(store);
    if (loadedPages.includes(tableState.page)) {
      return _griddleCore.GriddleActions.loadPage(number);
    }
  }
  // Make a remote request.
  return makeRequest(remoteProvider, tableState, function (response) {
    return loadPageRemoteHandler(response, number);
  });
}

function loadPageRemoteHandler(response, number) {
  return function (dispatch) {
    // Replace the data.
    dispatch(remoteAction(types.GRIDDLE_REMOTE_REPLACE_DATA, response));

    // Load the specified page, now that we have the data.
    dispatch(_griddleCore.GriddleActions.loadPage(number));

    // Indicate that we're done loading.
    dispatch({ type: types.GRIDDLE_STOP_LOADING });
  };
}

function refreshCurrentPage(store, callback) {
  var tableState = (0, _storeUtil.getTableState)(store);
  var remoteProvider = (0, _storeUtil.getRemoteProvider)(store);

  // Bypass loaded pages during refresh

  // Make a remote request.
  return makeRequest(remoteProvider, tableState, function (response) {
    var retVal = refreshCurrentPageRemoteHandler(response);

    callback && callback(response);
    return retVal;
  });
}

function refreshCurrentPageRemoteHandler(response) {
  return function (dispatch) {
    // Replace the data.
    dispatch(remoteAction(types.GRIDDLE_REMOTE_REPLACE_DATA, response));

    // Load the specified page, now that we have the data.
    dispatch(_griddleCore.GriddleActions.refreshCurrentPage());

    // Indicate that we're done loading.
    dispatch({ type: types.GRIDDLE_STOP_LOADING });
  };
}