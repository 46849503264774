'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _RaisedButton = require('material-ui/RaisedButton');

var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _Tooltip = require('material-ui/internal/Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var AddEntryButton = _react2.default.createClass({
    displayName: 'AddEntryButton',
    getDefaultProps: function getDefaultProps() {
        return {
            label: "Add Entry",
            delegateOnClick: function delegateOnClick() {}
        };
    },
    propTypes: _extends({}, _RaisedButton2.default.propTypes, {
        delegateOnClick: _propTypes2.default.func.isRequired,
        disabledMessage: _propTypes2.default.string
    }),
    render: function render() {
        var _this = this,
            _extends2,
            _React$createElement,
            _React$createElement2;

        var disabledMessage = this.props.disabled && this.props.disabledMessage;
        var tooltipMessage = disabledMessage;

        return _react2.default.createElement(
            'div',
            (_React$createElement2 = {
                style: { flexShrink: 0 },
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'AddEntryButton'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'AddEntryButton'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'AddEntryButton'), _React$createElement2),
            _react2.default.createElement(_RaisedButton2.default, _extends({}, _lodash2.default.pick(this.props, Object.keys(_RaisedButton2.default.propTypes)), (_extends2 = {
                label: this.props.label
            }, _defineProperty(_extends2, 'label', this.props.label), _defineProperty(_extends2, 'primary', true), _defineProperty(_extends2, 'onTouchTap', this.props.delegateOnClick), _defineProperty(_extends2, 'onMouseEnter', function onMouseEnter() {
                _this.setState({ hoveredTooltip: true });
            }), _defineProperty(_extends2, 'onMouseLeave', function onMouseLeave() {
                _this.setState({ hoveredTooltip: false });
            }), _defineProperty(_extends2, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'AddEntryButton'), _defineProperty(_extends2, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'AddEntryButton'), _defineProperty(_extends2, 'data-codecept-selector-node', 'RaisedButton'), _defineProperty(_extends2, 'data-codecept-selector-file', 'AddEntryButton'), _extends2))),
            tooltipMessage && _react2.default.createElement(_Tooltip2.default, (_React$createElement = {
                show: this.state && this.state.hoveredTooltip,
                label: tooltipMessage,
                horizontalPosition: 'left',
                verticalPosition: 'bottom',
                style: {
                    top: 36,
                    right: 0
                },
                'data-codecept-selector-node': 'Tooltip',
                'data-codecept-selector-file': 'AddEntryButton'
            }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Tooltip'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'AddEntryButton'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'Tooltip'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'AddEntryButton'), _React$createElement))
        );
    }
});

exports.default = AddEntryButton;