'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _require = require('../../griddle-render'),
    Griddle = _require.Griddle,
    DefaultModules = _require.DefaultModules;

var React = require('react');
var extend = require('lodash.assign');
var _ = require('lodash');

var _require2 = require('../../griddle-connector'),
    GriddleRedux = _require2.GriddleRedux;

var _default = function (_React$Component) {
  _inherits(_default, _React$Component);

  function _default(props) {
    _classCallCheck(this, _default);

    var _this = _possibleConstructorReturn(this, (_default.__proto__ || Object.getPrototypeOf(_default)).call(this, props));

    _this.initializeComponent_usingProps(_this.props);
    return _this;
  }

  _createClass(_default, [{
    key: 'initializeComponent_usingProps',
    value: function initializeComponent_usingProps(props) {
      this.component = GriddleRedux({
        Griddle: Griddle,
        Components: extend({}, DefaultModules, props.components),
        Plugins: props.plugins
      });
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      var listOfPluginsHasChanged = getHasListOfPluginsChanged_usingPrevPlugins_andNextPlugins(this.props.plugins, nextProps.plugins);
      if (listOfPluginsHasChanged) {
        this.initializeComponent_usingProps(nextProps);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      return React.createElement(
        'div',
        {
          'data-codecept-selector-node': 'div',
          'data-codecept-selector-file': 'index'
        },
        React.createElement(
          this.component,
          _extends({}, this.props, {
            'data-codecept-selector-file': 'index'
          }),
          this.props.children
        )
      );
    }
  }]);

  return _default;
}(React.Component);

exports.default = _default;
;

// checks if plugins have been added, removed or reordered
// does NOT check if plugins have been modified, because that returns too many false positives
function getHasListOfPluginsChanged_usingPrevPlugins_andNextPlugins(prevPlugins, nextPlugins) {
  var prevPluginNames = _.reduce(prevPlugins, function (result, curr) {
    return [].concat(result, curr.name);
  }, []);
  var nextPluginNames = _.reduce(nextPlugins, function (result, curr) {
    return [].concat(result, curr.name);
  }, []);
  return !_.isEqual(prevPluginNames, nextPluginNames);
}